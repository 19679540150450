import React, { useEffect, useRef, useState } from "react";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import { MdMic } from "react-icons/md";
import { appModes } from "../../utils/constants";

const MainViewAudioOnlyParticipantsPopupContainer = () => {
  const {
    appMode,
    audioOnlyWebcamStreamIds,
    modifiedParticipantsNames,
    showAudioAvatars,
    joinedParticipants,
  } = useAppContext();

  const { localParticipantId } = useLocalParticipantId();

  const [message, setMessage] = useState("");

  const appModeRef = useRef(appMode);
  const audioOnlyWebcamStreamIdsRef = useRef(audioOnlyWebcamStreamIds);
  const localParticipantIdRef = useRef(localParticipantId);
  const modifiedParticipantsNamesRef = useRef(modifiedParticipantsNames);
  const showAudioAvatarsRef = useRef(showAudioAvatars);
  const joinedParticipantsRef = useRef(joinedParticipants);

  useEffect(() => {
    appModeRef.current = appMode;
  }, [appMode]);
  useEffect(() => {
    audioOnlyWebcamStreamIdsRef.current = audioOnlyWebcamStreamIds;
  }, [audioOnlyWebcamStreamIds]);
  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);
  useEffect(() => {
    modifiedParticipantsNamesRef.current = modifiedParticipantsNames;
  }, [modifiedParticipantsNames]);

  useEffect(() => {
    joinedParticipantsRef.current = joinedParticipants;
  }, [joinedParticipants]);
  useEffect(() => {
    showAudioAvatarsRef.current = showAudioAvatars;
  }, [showAudioAvatars]);

  const _handleSetAndUnsetMessageAfterTimeout = (message: string) => {
    setMessage(message);

    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  const _handleMainViewAudioOnlyParticipantsPopup = () => {
    const appMode = appModeRef.current;
    const audioOnlyWebcamStreamIds = audioOnlyWebcamStreamIdsRef.current;
    const localParticipantId = localParticipantIdRef.current;
    const modifiedParticipantsNames = modifiedParticipantsNamesRef.current;

    const showAudioAvatars = showAudioAvatarsRef.current;
    const joinedParticipants = joinedParticipantsRef.current;

    if (
      (appMode === appModes.HOST || appMode === appModes.SPEAKER) &&
      audioOnlyWebcamStreamIds.length &&
      !showAudioAvatars
    ) {
      const localParticipantAudioOnlyWebcamStream =
        audioOnlyWebcamStreamIds.findIndex(
          (streamId) => streamId.split("_")[0] === localParticipantId
        );

      if (localParticipantAudioOnlyWebcamStream === -1) {
        const firstParticipantId = audioOnlyWebcamStreamIds[0].split("_")[0];

        const name =
          modifiedParticipantsNames.find(
            ({ participantId: _participantId }) =>
              _participantId === firstParticipantId
          )?.name ||
          joinedParticipants.find(
            ({ participantId }) => firstParticipantId === participantId
          )?.participantName;

        const remainingParticipants = audioOnlyWebcamStreamIds.length - 1;

        const message = `${name} ${
          remainingParticipants
            ? remainingParticipants === 1
              ? `and 1 other are `
              : `and ${remainingParticipants} others are `
            : `is `
        }in the stream with audio only`;

        _handleSetAndUnsetMessageAfterTimeout(message);
      } else {
        const remainingParticipants = audioOnlyWebcamStreamIds.length - 1;

        const message = `You ${
          remainingParticipants
            ? remainingParticipants === 1
              ? `and 1 other are `
              : `and ${remainingParticipants} others are `
            : `are `
        }in the stream with audio only`;

        _handleSetAndUnsetMessageAfterTimeout(message);
      }
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.MAIN_VIEW_AUDIO_ONLY_PARTICIPANTS_POPUP,
      _handleMainViewAudioOnlyParticipantsPopup
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.MAIN_VIEW_AUDIO_ONLY_PARTICIPANTS_POPUP,
        _handleMainViewAudioOnlyParticipantsPopup
      );
    };
  }, []);

  return (
    <div className="absolute top-0 left-0 right-0 flex items-center justify-center">
      {message ? (
        <div className="animate-bounce mt-2 p-2 rounded-md flex items-center justify-center gap-2 bg-primary text-white font-bold">
          <MdMic size={20} />
          <div>
            <p>{message}</p>
          </div>
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default MainViewAudioOnlyParticipantsPopupContainer;
