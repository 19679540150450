import React, { useMemo } from "react";
import { createUID } from "../utils/createUID";
import { Tooltip as RMWCTooltip } from "@rmwc/tooltip";

const Tooltip = ({
  children,
  title,
  disabled,
  titlen,
  placement,
}: {
  children: React.ReactElement;
  title?: string;
  disabled?: boolean;
  titlen?: string[];
  placement?:
    | "start"
    | "center"
    | "end"
    | "above"
    | "below"
    | "startAbove"
    | "startBelow"
    | "centerAbove"
    | "centerBelow"
    | "endAbove"
    | "endBelow";
}) => {
  const id = useMemo(() => createUID(), []);

  return disabled ? (
    children
  ) : (
    <RMWCTooltip
      align={placement}
      rich={false}
      overlay={
        <div
          style={{
            maxWidth: 400,
            fontFamily: "Montserrat",
            borderRadius: 4,
            marginLeft: -8,
            marginRight: -8,
            marginTop: -4,
            marginBottom: -4,
            paddingTop: 4,
            paddingBottom: 4,
            paddingRight: 8,
            paddingLeft: 8,
            backgroundColor: "#6b7280",
          }}
        >
          <p className="font-medium" style={{ fontSize: 16, lineHeight: 1.5 }}>
            {title}
          </p>

          {titlen && titlen?.length ? (
            titlen.map((t: string, i) => (
              <p
                key={`Tooltip-titlen-${id}-${i}`}
                className="font-medium"
                style={{ fontSize: 16, lineHeight: 1.5 }}
              >
                {t}
              </p>
            ))
          ) : (
            <React.Fragment />
          )}
        </div>
      }
    >
      {children}
    </RMWCTooltip>
  );
};

export default Tooltip;
