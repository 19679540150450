// import { usePubSub } from "@videosdk.live/react-sdk";
import React, { useEffect, useRef } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
// import groupBy from "lodash.groupby";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const activeBannerActions = {
  TOGGLE_ACTIVE_LOWER_THIRD: "TOGGLE_ACTIVE_LOWER_THIRD",
  TOGGLE_ACTIVE_TICKER: "TOGGLE_ACTIVE_TICKER",
};

export const bannerTypes = {
  lowerThird: "lower-third",
  ticker: "ticker",
};

const ActiveBannerListner = () => {
  const { setActiveLowerThirtdBannerId, setActiveTickerBannerId, banners } =
    useAppContext();

  const bannersRef = useRef(banners);

  useEffect(() => {
    bannersRef.current = banners;
  }, [banners]);

  const _handleEnableLowerThird = ({ bannerId }: { bannerId: string }) => {
    setActiveLowerThirtdBannerId(bannerId);
  };

  const _handleDisableLowerThird = () => {
    setActiveLowerThirtdBannerId(null);
  };

  const _handleEnableTicker = ({ bannerId }: { bannerId: string }) => {
    setActiveTickerBannerId(bannerId);
  };

  const _handleDisableTicker = () => {
    setActiveTickerBannerId(null);
  };

  const _handleToggleLowerThird = ({
    payload,
  }: {
    payload: { enable: boolean; bannerId: string };
  }) => {
    const { enable, bannerId } = payload;

    if (enable) {
      _handleEnableLowerThird({ bannerId });
    } else {
      _handleDisableLowerThird();
    }
  };

  const _handleToggleTicker = ({
    payload,
  }: {
    payload: { enable: boolean; bannerId: string };
  }) => {
    const { enable, bannerId } = payload;

    if (enable) {
      _handleEnableTicker({ bannerId });
    } else {
      _handleDisableTicker();
    }
  };

  useAppSingalingSubscribe(
    appPubSubTopics.ACTIVE_BANNER,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case activeBannerActions.TOGGLE_ACTIVE_LOWER_THIRD:
          _handleToggleLowerThird({
            payload: message.payload,
          });
          break;

        case activeBannerActions.TOGGLE_ACTIVE_TICKER:
          _handleToggleTicker({
            payload: message.payload,
          });
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default ActiveBannerListner;
