// import { usePubSub } from "@videosdk.live/react-sdk";
import activateBrand from "../../apis/brands/activate-brand";
import createBrand from "../../apis/brands/create-brand";
import deleteBrand from "../../apis/brands/delete-brand";
import getBrandAssets from "../../apis/brands/get-brand-assets";
import getUserBrands from "../../apis/brands/get-user-brands";
import updateBrand from "../../apis/brands/update-brand";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { brandsActions } from "../../listners/brands/AllBrandsListner";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useBrandColor from "../activeBrand/useBrandColor";
import useBrandLogo from "../activeBrand/useBrandLogo";
import useMainViewBackground from "../activeBrand/useMainViewBackground";
import useMainViewOverlay from "../activeBrand/useMainViewOverlay";
import useIsFreePlanActive from "../plans/useIsFreePlanActive";
import { useEffect, useRef } from "react";
import useBrandShowDisplayNames from "../activeBrand/useBrandShowDisplayNames";
import useBrandShowHeadlines from "../activeBrand/useBrandShowHeadlines";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import useMainViewVideoClip from "../activeBrand/useMainViewVideoClip";
import useBackgroundMusic from "../activeBrand/useBackgroundMusic";
import useBrandMuteGuestsWhenVideoPlays from "../activeBrand/useBrandMuteGuestsWhenVideoPlays";
import useBrandMuteGuestsWhenAudioPlays from "../activeBrand/useBrandMuteGuestsWhenAudioPlays";
import sleep from "../../utils/sleep";
import {
  mainViewBackgroundTypes,
  mainViewOverlayTypes,
} from "../../utils/constants";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useBrands = () => {
  // const { publish } = usePubSub(appPubSubTopics.BRANDS);

  const { publish } = useAppSingalingPublish(appPubSubTopics.BRANDS);

  const { userId } = useAppConfigContext();

  const { changeBrandColor } = useBrandColor();
  const { changeOverlay } = useMainViewOverlay();
  const { changeLogo } = useBrandLogo();
  const { changeBackground } = useMainViewBackground();
  const { setShowDisplayNames } = useBrandShowDisplayNames();
  const { setShowHeadlines } = useBrandShowHeadlines();
  const {
    changeActiveIdAndRemoteUrl: changeActiveIdAndRemoteUrlMainViewVideoClip,
    changeLoopState: changeLoopStateMainViewVideoClip,
    changeVolume: changeVolumeMainViewVideoClip,
  } = useMainViewVideoClip();
  const {
    changeActiveIdAndRemoteUrl: changeActiveIdAndRemoteUrlBackgroundMusic,
    changeLoopState: changeLoopStateBackgroundMusic,
    changeVolume: changeVolumeBackgroundMusic,
  } = useBackgroundMusic();
  const { setMuteGuestsWhenVideoPlays } = useBrandMuteGuestsWhenVideoPlays();
  const { setMuteGuestsWhenAudioPlays } = useBrandMuteGuestsWhenAudioPlays();

  const { isFreePlanActive } = useIsFreePlanActive();

  const isFreePlanActiveRef = useRef(isFreePlanActive);

  useEffect(() => {
    isFreePlanActiveRef.current = isFreePlanActive;
  }, [isFreePlanActive]);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const add = async ({
    name,
    defaultOpen,
  }: {
    name: string;
    defaultOpen?: boolean;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const { success, brandId } = await createBrand({ userId, name });

    if (success) {
      publish(
        JSON.stringify({
          action: brandsActions.ADD,
          payload: { id: brandId, name, defaultOpen },
        })
        // { persist: true }
      );

      return brandId;
    }
  };

  const remove = async ({ id, force }: { id: string; force?: boolean }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const { success } = await deleteBrand({ brandId: id, userId });

    if (success || force) {
      publish(
        JSON.stringify({ action: brandsActions.REMOVE, payload: { id } })
        // { persist: true }
      );
    }
  };

  const rename = async ({ id, name }: { id: string; name: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const { success } = await updateBrand({ brandId: id, name, userId });

    if (success) {
      publish(
        JSON.stringify({ action: brandsActions.RENAME, payload: { id, name } })
        // { persist: true }
      );
    }
  };

  const activatAssetsOfBrand = async ({ id }: { id: string }) => {
    const { brands, success } = await getUserBrands({ userId });

    if (success && brands) {
      const brand = brands.find(({ id: _id }) => _id === id);

      if (brand) {
        const {
          color,
          showDisplayNames,
          showHeadlines,
          muteGuestsWhenVideoPlays,
          muteGuestsWhenAudioPlays,
        } = brand;

        const { success: getBrandAssetsSuccess, data } = await getBrandAssets({
          brandId: id,
          userId,
        });

        let activeOverlay = null;
        let activeLogo = null;
        let activeBackground = null;

        if (getBrandAssetsSuccess && data) {
          const { background, logo, overlay } = data;

          const activeBackgroundFound = background.find(
            (b: { isActive: number | boolean }) => b.isActive
          );
          const _activeLogoFound = logo.find(
            (b: { isActive: number | boolean }) => b.isActive
          );
          const _activeOverlayFound = overlay.find(
            (b: { isActive: number | boolean }) => b.isActive
          );

          if (_activeOverlayFound) {
            activeOverlay = {
              id: _activeOverlayFound.id,
              remoteUrl: _activeOverlayFound.file,
            };
          }

          if (_activeLogoFound) {
            activeLogo = {
              id: _activeLogoFound.id,
              remoteUrl: _activeLogoFound.file,
            };
          }

          if (activeBackgroundFound) {
            activeBackground = {
              id: activeBackgroundFound.id,
              remoteUrl: activeBackgroundFound.file,
            };
          }
        }

        if (isFreePlanActiveRef.current) {
          changeOverlay(
            {
              id: "free",
              type: mainViewOverlayTypes.FREE_PLAN_OVERLAY,
              value: null,
            },
            { force: true }
          );
        } else {
          changeOverlay(
            activeOverlay
              ? {
                  id: activeOverlay.id,
                  type: mainViewOverlayTypes.IMAGE,
                  value: activeOverlay.remoteUrl,
                }
              : {
                  id: null,
                  type: mainViewOverlayTypes.NO_OVERLAY,
                  value: null,
                },
            { force: true }
          );
        }

        await sleep(250);

        changeLogo(
          activeLogo
            ? { id: activeLogo.id, remoteUrl: activeLogo.remoteUrl }
            : { id: null, remoteUrl: null },
          { force: true }
        );

        await sleep(250);

        changeBackground(
          activeBackground
            ? ({
                type: mainViewBackgroundTypes.IMAGE,
                id: activeBackground.id,
                value: activeBackground.remoteUrl,
              } as singleBrandBackgroundType)
            : ({
                id: null,
                remoteUrl: null,
                type: mainViewBackgroundTypes.NO_BACKGROUND,
              } as singleBrandBackgroundType),
          { force: true }
        );

        await sleep(250);

        changeBrandColor({ hexColorCode: color }, { force: true });

        await sleep(250);

        setShowDisplayNames({ showDisplayNames }, { force: true });

        await sleep(250);

        setShowHeadlines({ showHeadlines }, { force: true });

        await sleep(250);

        changeActiveIdAndRemoteUrlMainViewVideoClip(
          {
            id: null,
            playedAt: null,
            remoteUrl: null,
          },
          { force: true }
        );

        await sleep(250);

        changeActiveIdAndRemoteUrlBackgroundMusic(
          {
            id: null,
            playedAt: null,
            remoteUrl: null,
          },
          { force: true }
        );

        await sleep(250);

        changeLoopStateMainViewVideoClip(
          { loop: false, loopedAt: null },
          { force: true }
        );

        await sleep(250);

        changeVolumeMainViewVideoClip({ volume: 1 }, { force: true });

        await sleep(250);

        changeLoopStateBackgroundMusic(
          { loop: false, loopedAt: null },
          { force: true }
        );

        await sleep(250);

        changeVolumeBackgroundMusic({ volume: 1 }, { force: true });

        await sleep(250);

        setMuteGuestsWhenVideoPlays(
          { muteGuestsWhenVideoPlays },
          { force: true }
        );

        await sleep(250);

        setMuteGuestsWhenAudioPlays(
          { muteGuestsWhenAudioPlays },
          { force: true }
        );
      }
    }
  };

  const active = async ({ id }: { id: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const { success: activateBrandSuccess } = await activateBrand({
      brandId: id,
      userId,
    });

    if (activateBrandSuccess) {
      await activatAssetsOfBrand({ id });

      publish(
        JSON.stringify({ action: brandsActions.ACTIVE, payload: { id } })
        // { persist: true }
      );
    }
  };

  const deactivate = async () => {
    if (isFreePlanActiveRef.current) {
      changeOverlay({
        id: "free",
        type: mainViewOverlayTypes.FREE_PLAN_OVERLAY,
        value: null,
      });
    } else {
      changeOverlay({
        id: null,
        type: mainViewOverlayTypes.NO_OVERLAY,
        value: null,
      });
    }

    await sleep(250);

    changeLogo({ id: null, remoteUrl: null });

    await sleep(250);

    changeBackground({
      id: null,
      remoteUrl: null,
      type: mainViewBackgroundTypes.NO_BACKGROUND,
    } as singleBrandBackgroundType);

    await sleep(250);

    changeBrandColor({ hexColorCode: "#FF2445" });

    await sleep(250);

    setShowDisplayNames({ showDisplayNames: true });

    await sleep(250);

    setShowHeadlines({ showHeadlines: false });

    await sleep(250);

    changeActiveIdAndRemoteUrlMainViewVideoClip({
      id: null,
      playedAt: null,
      remoteUrl: null,
    });

    await sleep(250);

    changeActiveIdAndRemoteUrlBackgroundMusic({
      id: null,
      playedAt: null,
      remoteUrl: null,
    });

    await sleep(250);

    changeLoopStateMainViewVideoClip({ loop: false, loopedAt: null });

    await sleep(250);

    changeVolumeMainViewVideoClip({ volume: 1 });

    await sleep(250);

    changeLoopStateBackgroundMusic({ loop: false, loopedAt: null });

    await sleep(250);

    changeVolumeBackgroundMusic({ volume: 1 });

    await sleep(250);

    setMuteGuestsWhenVideoPlays({ muteGuestsWhenVideoPlays: true });

    await sleep(250);

    setMuteGuestsWhenAudioPlays({ muteGuestsWhenAudioPlays: false });

    publish(
      JSON.stringify({ action: brandsActions.ACTIVE, payload: { id: null } })
      // { persist: true }
    );
  };

  return { add, remove, rename, active, deactivate, activatAssetsOfBrand };
};

export default useBrands;
