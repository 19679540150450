// @ts-nocheck

// import { useMeeting } from "@videosdk.live/react-sdk";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import IconButton, { iconButtonVariants } from "./IconButton";
import ShareContainer from "./ShareContainer";
import {
  MdMic,
  MdMicOff,
  MdVideocam,
  MdVideocamOff,
  MdOutlineDesktopWindows,
  MdSettings,
  MdPanTool,
  MdOutlinePanTool,
  MdCancel,
  MdCallEnd,
  MdColorLens,
  MdChatBubble,
  MdMoreVert,
  MdPersonAdd,
  MdQuestionAnswer,
  MdApps,
  MdPriorityHigh,
  MdPeopleAlt,
} from "react-icons/md";
import SettingsContainer from "../settings/SettingsContainer";
import { useAppContext } from "../../contexts/appContextDef";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { getPdfInfoFromImages } from "../../utils/getPdfInfoFromImages";
import addBroadcastAsset from "../../apis/broadcasts/add-broadcast-asset";
import useBroadcastAssets from "../../hooks/appState/useBroadcastAssets";
import useRaiseHand from "../../hooks/appState/useRaiseHand";
import { safeParseJson } from "../../utils/safeParseJson";
import useFileShareStreams, {
  fileShareStreamTypes,
} from "../../hooks/streams/useFileShareStreams";
import useVideoShareStreams from "../../hooks/streams/useVideoShareStreams";
// import useWebcam from "../../hooks/appState/useWebcam";
import InterpretationsContainer from "../../components/InterpretationsContainer";
import { getSidePanelPrivateChatMessageListMode } from "../sideContainer/SideContainer";
import FullScreenModalContainer from "../../components/FullScreenModalContainer";
import BrandPanel from "../sidePanel/BrandPanel";
import useIsMobilePortraitOrLandscape from "../../hooks/responsive/useIsMobilePortraitOrLandscape";
import Modal from "../../components/Modal";
import useClipboard from "react-use-clipboard";
import getBroadcastAssets from "../../apis/broadcasts/get-broadcast-assets";
import sleep from "../../utils/sleep";
import { createUID } from "../../utils/createUID";
import QNAPanel from "../sidePanel/QNAPanel";
import useShouldRenderSidePanelItemContainer from "../../hooks/utils/useShouldRenderSidePanelItemContainer";
import ParticipantsPanel from "../sidePanel/ParticipantsPanel";
import PrivateChatGroupMessages from "../privateChatContainer/PrivateChatGroupMessages";
import SidePanelWordcloudDetail from "../sidePanel/wordclouds/SidePanelWordcloudDetail";
import SidePanelPollDetail from "../sidePanel/polls/SidePanelPollDetail";
import WordcloudContainer from "../sidePanel/wordclouds/WordcloudContainer";
import PollsContainer from "../sidePanel/polls/PollsContainer";
import BannersContainer from "../sidePanel/banners/banners/BannersContainer";
import BannerFoldersContainer from "../sidePanel/banners/bannerFolders/BannerFoldersContainer";
import AppsPanel from "../sidePanel/AppsPanel";
import PrivateChatGroupsContainer from "../privateChatContainer/PrivateChatGroupsContainer";
// import chunk from "lodash.chunk";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
// import useMic from "../../hooks/appState/useMic";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import { getReadyToBePlayedVideoIds } from "../../components/WaitUntilVideosAreReadyToBePlayed";
import Tooltip from "../../components/Tooltip";
import waitForRemoteUrlAccessible from "../../utils/waitForRemoteUrlAccessible";
import { appQueueEvents } from "../../listners/appState/AppQueueListner";
import useIsChangeLocalWebcamInProgress from "../../hooks/appState/useIsChangeLocalWebcamInProgress";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import useIsParticipantMicDenied from "../../hooks/appState/useIsParticipantMicDenied";
import useIsParticipantCameraDenied from "../../hooks/appState/useIsParticipantCameraDenied";
import stopBroadcastEgresses from "../../apis/egress/stopBroadcastEgresses";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";
import { appModes, sidePanelModes } from "../../utils/constants";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useRtcLocalParticipantMediaStatsActions from "../../appRtc/useRtcLocalParticipantMediaStatsActions";
import useInputFileVideoShareStreams from "../../hooks/streams/useInputFileVideoShareStreams";
import ResourcesContainer from "../resourcesContainer/ResourcesContainer";

const chunk = (arr: string[], size: number) => {
  const chunkArr: string[][] = [];

  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];

    if (!chunkArr.length) {
      chunkArr.push([element]);
    } else {
      const chunkNIndex = chunkArr.length - 1;

      const chunkN = chunkArr[chunkNIndex];

      if (chunkN.length === size) {
        chunkArr.push([element]);
      } else {
        chunkArr[chunkNIndex] = [...chunkArr[chunkNIndex], element];
      }
    }
  }

  return chunkArr;
};

const bottomControlsButtonTypes = {
  interpretations: "interpretations",
  mic: "mic",
  webcam: "webcam",
  turnOffScreenShare: "turnOffScreenShare",
  settings: "settings",
  share: "share",
  raiseHand: "raiseHand",
  leave: "leave",
  end: "end",
  chat: "chat",
  brand: "brand",
  more: "more",
  hideMore: "hideMore",
  invite: "invite",
  qna: "qna",
  privateChat: "privateChat",
  apps: "apps",
  participants: "participants",
};

export const VolumeIndicationMicIcon = ({
  size,
  className,
  volume,
  muted,
}: {
  size: number;
  className?: string;
  volume: number | null;
  muted: boolean;
}) => {
  return (
    <div className="relative" style={{ height: size, width: size }}>
      <div style={{ height: size, width: size, opacity: muted ? 0.4 : 1 }}>
        <MdMic size={size} className="fill-green-600" />
      </div>

      <div
        style={{
          height:
            size * (1 - (!volume ? 0 : volume > 100 ? 100 : volume) / 100),
          width: size,
          opacity: muted ? 0.4 : 1,
        }}
        className="transition-all absolute overflow-hidden top-0 left-0"
      >
        <MdMic size={size} className={className} />
      </div>

      {muted ? (
        <Tooltip
          // placement={"top"}
          placement="centerAbove"
          title={"Your mic is muted by your system settings."}
        >
          <div className="absolute top-0 right-0 h-5 w-5 bg-orange-400 text-white font-bold text-sm rounded-full flex items-center justify-center">
            <MdPriorityHigh size={size / 2} />
          </div>
        </Tooltip>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

const LocalMicIcon = ({
  size,
  className,
}: {
  size: number;
  className?: string;
}) => {
  const { localMicMuted } = useAppContext();

  const [volume, setVolume] = useState<number | null>(null);

  const _handleLocalMicVolumeChanged = ({ volume }: { volume: number }) => {
    setVolume(volume);
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.LOCAL_MIC_VOLUME_CHANGED,
      _handleLocalMicVolumeChanged
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.LOCAL_MIC_VOLUME_CHANGED,
        _handleLocalMicVolumeChanged
      );
    };
  }, []);

  // const muted = useMemo(
  //   () =>
  //     !volume
  //       ? true
  //       : volume > 0
  //       ? false
  //       : typeof localMicMuted === "boolean"
  //       ? localMicMuted
  //       : false,
  //   [localMicMuted, volume]
  // );

  const muted = useMemo(
    () => (typeof localMicMuted === "boolean" ? localMicMuted : false),
    [localMicMuted]
  );

  return <VolumeIndicationMicIcon {...{ className, size, volume, muted }} />;
};

const LocalWebcamIcon = ({
  size,
  className,
}: {
  size: number;
  className?: string;
}) => {
  const { localWebcamMuted } = useAppContext();

  const muted = useMemo(
    () => (typeof localWebcamMuted === "boolean" ? localWebcamMuted : false),
    [localWebcamMuted]
  );

  return (
    <div className="relative" style={{ height: size, width: size }}>
      <div style={{ height: size, width: size, opacity: muted ? 0.4 : 1 }}>
        <MdVideocam size={size} className={className} />
      </div>

      {muted ? (
        <Tooltip
          // placement={"top"}
          placement="centerAbove"
          title={"Your camera is muted by your system settings."}
        >
          <div className="absolute top-0 right-0 h-5 w-5 bg-orange-400 text-white font-bold text-sm rounded-full flex items-center justify-center">
            <MdPriorityHigh size={size / 2} />
          </div>
        </Tooltip>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

const BottomControlsContainer = ({
  onContainerHeight,
}: {
  onContainerHeight: (t: number) => void;
}) => {
  // const mMeeting = useMeeting();
  const {
    setFileUploadError,
    selectedWebcamDeviceId,
    selectedMicDeviceId,
    raiseHandParticipantIds,
    sidePanelMode,
    setSidePanelMode,
    setSidePanelPrivateChatMode,
    appMode,
    raiseHandEnabled,
    participatedPrivateChatGroups,
    sidePanelActivePollId,
    sidePanelActiveWordcloudId,
    setWaitingForVideosRemoteUrlReadyToBePlayed,
  } = useAppContext();

  const { publish: endCallPublish } = useAppSingalingPublish(
    appPubSubTopics.END_CALL
  );

  const href = useMemo(() => window?.location?.href, []);

  const { enableFileShareStream } = useFileShareStreams();
  const { enableVideoShareStream } = useVideoShareStreams();
  const { enableInputFileVideoShareStream } = useInputFileVideoShareStreams();

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [isMoreOn, setIsMoreOn] = useState(false);
  const [leaveConfirmationModalVisible, setLeaveConfirmationModalVisible] =
    useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  // const [needHelpOpen, setNeedHelpOpen] = useState(false);

  const [isCopied, setCopied] = useClipboard(href, { successDuration: 2000 });

  // const { enableScreenShare, disableScreenShare } = useMemo(() => {
  //   return {
  //     enableScreenShare: mMeeting?.enableScreenShare,
  //     disableScreenShare: mMeeting?.disableScreenShare,
  //   };
  // }, [mMeeting]);

  const { enableScreenShare, disableScreenShare, toggleMic, toggleWebcam } =
    useRtcLocalParticipantMediaStatsActions();

  const { localParticipantId } = useLocalParticipantId();

  const isChatPanelOn = useMemo(
    () => sidePanelMode === sidePanelModes.CHAT,
    [sidePanelMode]
  );
  const isBrandPanelOn = useMemo(
    () => sidePanelMode === sidePanelModes.BRAND,
    [sidePanelMode]
  );

  const micOnRef = useRef<boolean>(false);
  const webcamOnRef = useRef<boolean>(false);
  const screenShareOnRef = useRef<boolean>(false);
  const localParticipantIdRef = useRef(localParticipantId);
  const selectedWebcamDeviceIdRef = useRef(selectedWebcamDeviceId);
  const selectedMicDeviceIdRef = useRef(selectedMicDeviceId);
  const isChatPanelOnRef = useRef(isChatPanelOn);
  const isBrandPanelOnRef = useRef(isBrandPanelOn);
  const inviteLinkInputRef = useRef<HTMLInputElement>();
  const appModeRef = useRef(appMode);
  const participatedPrivateChatGroupsRef = useRef(
    participatedPrivateChatGroups
  );

  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);

  useEffect(() => {
    appModeRef.current = appMode;
  }, [appMode]);
  useEffect(() => {
    participatedPrivateChatGroupsRef.current = participatedPrivateChatGroups;
  }, [participatedPrivateChatGroups]);

  const { screenShareOn, micOn, webcamOn } =
    useParticipantMediaStats(localParticipantId);

  // const { toggleWebcam } = useWebcam();
  // const { toggleMic } = useMic();

  const { broadcastId, studioUserId, interpretations, meetingMode } =
    useAppConfigContext();
  const { disableRaiseHand, enableRaiseHand } =
    useRaiseHand(localParticipantId);

  const { addAsset } = useBroadcastAssets();

  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  useEffect(() => {
    micOnRef.current = micOn;
  }, [micOn]);
  useEffect(() => {
    webcamOnRef.current = webcamOn;
  }, [webcamOn]);
  useEffect(() => {
    screenShareOnRef.current = screenShareOn;
  }, [screenShareOn]);
  useEffect(() => {
    selectedWebcamDeviceIdRef.current = selectedWebcamDeviceId;
  }, [selectedWebcamDeviceId]);
  useEffect(() => {
    selectedMicDeviceIdRef.current = selectedMicDeviceId;
  }, [selectedMicDeviceId]);
  useEffect(() => {
    isChatPanelOnRef.current = isChatPanelOn;
  }, [isChatPanelOn]);
  useEffect(() => {
    isBrandPanelOnRef.current = isBrandPanelOn;
  }, [isBrandPanelOn]);

  const _handleToggleMic = async () => {
    toggleMic();
  };

  const _handleToggleWebcam = () => {
    toggleWebcam();
  };

  const _handleToggleScreenshare = () => {
    const screenShareOn = screenShareOnRef.current;

    if (screenShareOn) {
      disableScreenShare();
    } else {
      enableScreenShare();
    }
  };

  const waitForPDFConversion = async ({
    id,
  }: {
    id: string;
  }): Promise<{ fileData: string[] }> => {
    const { success, assets } = await getBroadcastAssets({ broadcastId });

    if (success && assets) {
      const asset = assets.find(({ id: _id }) => id === _id);

      if (asset) {
        const { fileData } = asset;

        if (fileData?.length) {
          return { fileData };
        } else {
          await sleep(10000);

          return waitForPDFConversion({ id });
        }
      }
    }

    return { fileData: [] };
  };

  const waitForVideoTranscoding = async ({
    id,
  }: {
    id: string;
  }): Promise<{
    asset?: broadcastAssetType;
    metaData?: { type: string };
    owners?: string[];
    videoOriginal?: string;
    video360?: string;
    video480?: string;
    video720?: string;
    videoThumbnail?: string;
    success: boolean;
  }> => {
    const { success, assets } = await getBroadcastAssets({ broadcastId });

    const asset =
      success && assets ? assets.find(({ id: _id }) => id === _id) : undefined;

    if (success && asset) {
      const metaData = asset.metaData;
      const owners = asset.owners;

      const videoOriginal =
        typeof asset.file !== "string" ? asset.file["original"] : asset.file;

      const video480 =
        typeof asset.file !== "string" ? asset.file["480"] : asset.file;
      const video720 =
        typeof asset.file !== "string" ? asset.file["720"] : asset.file;
      const videoThumbnail =
        typeof asset.file !== "string" ? asset.file["thumbnail"] : asset.file;

      if (video480 && video720 && videoThumbnail) {
        const { success: success1 } = await waitForRemoteUrlAccessible({
          remoteUrl: videoThumbnail,
        });

        const { success: success2 } = await waitForRemoteUrlAccessible({
          remoteUrl: video480,
        });

        const { success: success3 } = await waitForRemoteUrlAccessible({
          remoteUrl: video720,
        });

        if (success1 && success2 && success3) {
          return {
            asset,
            metaData,
            owners,
            videoOriginal,
            video480,
            video720,
            videoThumbnail,
            success,
          };
        } else {
          await sleep(10000);

          return waitForVideoTranscoding({ id });
        }
      } else {
        await sleep(10000);

        return waitForVideoTranscoding({ id });
      }
    }

    return { success: false };
  };

  const _handlePdfAndPptFileEvent = async ({
    singleFile,
    fileName,
  }: {
    singleFile: File | null;
    fileName: string;
  }) => {
    const uploadId = createUID();

    try {
      appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
        event: appQueueEvents.BROADCAST_PDF,
        //
        isUploading: true,
        isProcessing: false,
        isCompleted: false,
        //
        uploadId,
        name: fileName,
      });

      const { remoteUrl, success, data, error, id } = await addBroadcastAsset({
        broadcastId,
        owner: studioUserId,
        metaData: { type: "file-share-stream" },
        uploadId,
        singleFile,
      });

      appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
        event: appQueueEvents.BROADCAST_PDF,
        //
        isUploading: false,
        isProcessing: true,
        isCompleted: false,
        //
        uploadId,
        name: fileName,
      });

      const { fileData: images } = await waitForPDFConversion({ id });

      if (success) {
        addAsset({
          data: {
            ...data,
            owners: [studioUserId],
            fileData: images,
            metaData: safeParseJson(data.metaData),
          },
        });

        const pdfInfo = getPdfInfoFromImages(images);

        enableFileShareStream({
          type: fileShareStreamTypes.PDF,
          pdfInfo,
          remoteUrl,
          resourceId: id,
        });
      } else if (error) {
        setFileUploadError({
          visible: true,
          errorMessage: error,
        });
      }
    } catch (error) {
      console.log("file upload error", error);
    }

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BROADCAST_PDF,
      //
      isUploading: false,
      isProcessing: false,
      isCompleted: true,
      //
      uploadId,
      name: fileName,
    });
  };

  const _handleWaitUntilVideoIsReady = async ({
    remoteUrl,
    id: _id,
  }: {
    remoteUrl: string;
    id: string;
  }) => {
    const id = getReadyToBePlayedVideoIds({ broadcastVideo: true, id: _id });

    setWaitingForVideosRemoteUrlReadyToBePlayed((s) => [
      ...s,
      { remoteUrl, id },
    ]);

    const { durationInSec }: { durationInSec: number } = await new Promise(
      (resolve) => {
        appEventEmitter.once(
          appEventEmitterEvents.VIDEO_READY_TO_BE_PLAYED(id),
          resolve
        );
      }
    );

    return { durationInSec };
  };

  const _handleVideoFileEvent = async ({
    singleFile,
    fileName,
  }: {
    fileName: string;
    singleFile: File | null;
  }) => {
    const uploadId = createUID();

    try {
      appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
        event: appQueueEvents.BROADCAST_VIDEO,
        //
        isUploading: true,
        isProcessing: false,
        isCompleted: false,
        //

        uploadId,
        name: fileName,
      });

      const { success, data, error, id } = await addBroadcastAsset({
        broadcastId,
        owner: studioUserId,
        metaData: { type: "video-share-stream" },
        uploadId,
        singleFile,
        setWaitingForVideosRemoteUrlReadyToBePlayed,
      });

      appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
        event: appQueueEvents.BROADCAST_VIDEO,
        //
        isUploading: false,
        isProcessing: true,
        isCompleted: false,
        //
        uploadId,
        name: fileName,
      });

      if (success) {
        const {
          asset,
          videoOriginal,
          video360,
          video480,
          video720,
          success: waitForVideoTranscodingSuccess,
        } = await waitForVideoTranscoding({ id });

        if (success && waitForVideoTranscodingSuccess) {
          const remoteUrl = video360 || video480 || video720 || videoOriginal;

          if (remoteUrl) {
            addAsset({
              data: { ...asset, owners: [studioUserId] } as broadcastAssetType,
            });

            const { durationInSec } = await _handleWaitUntilVideoIsReady({
              id: data.id,
              remoteUrl,
            });

            enableVideoShareStream({ remoteUrl, durationInSec });
          }
        } else if (error) {
          setFileUploadError({
            visible: true,
            errorMessage: error,
          });
        }
      } else {
        setFileUploadError({
          visible: true,
          errorMessage: error || null,
        });
      }
    } catch (error) {
      console.log("file upload error", error);
    }

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BROADCAST_VIDEO,
      //
      isUploading: false,
      isProcessing: false,
      isCompleted: true,
      //

      uploadId,
      name: fileName,
    });
  };

  const _handlePdfSelect = ({
    remoteUrl,
    pdfInfo,
    resourceId,
  }: {
    remoteUrl: string;
    pdfInfo: pdfInfoType;
    resourceId: string;
  }) => {
    enableFileShareStream({
      type: fileShareStreamTypes.PDF,
      pdfInfo,
      remoteUrl,
      resourceId,
    });
  };

  const _handleVideoSelect = async ({
    remoteUrl,
    id,
  }: {
    remoteUrl: string;
    id: string;
  }) => {
    const { durationInSec } = await _handleWaitUntilVideoIsReady({
      id,
      remoteUrl,
    });

    enableVideoShareStream({ remoteUrl, durationInSec });
  };

  const _handleInputFileVideoFileEvent = async (t: {
    fileObjectUrl: string;
  }) => {
    const id = createUID();
    const { fileObjectUrl } = t;

    const { durationInSec } = await _handleWaitUntilVideoIsReady({
      id,
      remoteUrl: fileObjectUrl,
    });

    enableInputFileVideoShareStream({ durationInSec, fileObjectUrl });
  };

  const _handleToggleSettingsModal = () => {
    setSettingsModalOpen((s) => !s);
  };

  const localParticipantRaisedHand = useMemo(
    () => raiseHandParticipantIds.includes(localParticipantId),
    [raiseHandParticipantIds, localParticipantId]
  );

  const localParticipantRaisedHandRef = useRef(localParticipantRaisedHand);

  useEffect(() => {
    localParticipantRaisedHandRef.current = localParticipantRaisedHand;
  }, [localParticipantRaisedHand]);

  useEffect(() => {
    if (isCopied) {
      inviteLinkInputRef.current?.select();
    }
  }, [isCopied]);

  const toggleRaiseHand = () => {
    if (localParticipantRaisedHandRef.current) {
      disableRaiseHand();
    } else {
      enableRaiseHand();
    }
  };

  const _handleLeave = () => {
    setLeaveConfirmationModalVisible(true);

    // appEventEmitter.emit(appEventEmitterEvents.LEAVE_FROM_SESSION, {});
  };

  const _handleEnd = async () => {
    await stopBroadcastEgresses({ broadcastId });

    // end();

    endCallPublish(
      JSON.stringify({ end: true, endedReason: "host_ended" })
      // , {
      //   persist: false,
      // }
    );

    // end();
  };

  const _handleToggleChatPanel = () =>
    setSidePanelMode((s) =>
      s === sidePanelModes.CHAT ? null : sidePanelModes.CHAT
    );

  const _handleToggleBrandPanel = () =>
    setSidePanelMode((s) =>
      s === sidePanelModes.BRAND ? null : sidePanelModes.BRAND
    );

  const _handleToggleQNAPanel = () =>
    setSidePanelMode((s) =>
      s === sidePanelModes.QNA ? null : sidePanelModes.QNA
    );

  const _handleTogglePrivateChatPanel = async () => {
    const appMode = appModeRef.current;
    const participatedPrivateChatGroups =
      participatedPrivateChatGroupsRef.current;

    let mode;

    setSidePanelMode((s) => {
      const newMode =
        s === sidePanelModes.PRIVATE_CHAT ? null : sidePanelModes.PRIVATE_CHAT;

      mode = newMode;

      return newMode;
    });

    await sleep(100);

    if (
      mode === sidePanelModes.PRIVATE_CHAT &&
      appMode !== appModes.HOST &&
      participatedPrivateChatGroups.length === 1
    ) {
      setSidePanelPrivateChatMode(
        getSidePanelPrivateChatMessageListMode(
          participatedPrivateChatGroups[0].id
        )
      );
    }
  };
  const _handleToggleAppsPanel = () =>
    setSidePanelMode((s) =>
      s === sidePanelModes.APPS ? null : sidePanelModes.APPS
    );

  const _handleToggleParticipantsPanel = () =>
    setSidePanelMode((s) =>
      s === sidePanelModes.PARTICIPANTS ? null : sidePanelModes.PARTICIPANTS
    );

  const _handleToggleMore = () => setIsMoreOn((s) => !s);

  const _handleToggleInviteModal = () => setInviteModalOpen((s) => !s);

  const _renderInterpretationsContainer = () => <InterpretationsContainer />;

  const _renderMicButton = ({
    _handleToggleMic,
    micOn,
    isMobilePortraitOrLandscape,
  }: {
    _handleToggleMic: () => void;
    micOn: boolean;
    isMobilePortraitOrLandscape: boolean;
  }) => {
    const { localParticipantId } = useLocalParticipantId();

    const { micDenied } = useIsParticipantMicDenied({
      participantId: localParticipantId,
    });

    const askMicPermission = () => {
      appEventEmitter.emit(appEventEmitterEvents.ASK_MIC_PERMISSION);
    };

    return (
      <span
        className={`${quickstartTargetComponentClassNames.local_mic_toggle_bottom_controls_button}`}
      >
        <IconButton
          {...{
            onClick: micDenied ? askMicPermission : _handleToggleMic,
            Icon: micOn ? LocalMicIcon : MdMicOff,
            title: isMobilePortraitOrLandscape
              ? "Mic"
              : micOn
              ? "Mute"
              : "Unmute",
            popperTitle: "",
            variant: micOn ? iconButtonVariants.info : iconButtonVariants.error,
            maxTitle: isMobilePortraitOrLandscape ? "Mic" : "Unmute",
            rounded: interpretations?.length ? "" : "rounded-l-md",

            disabled: micDenied,
            disabledTitle: "Mic permission not granted",
            // tooltipPlacement: "top",
            tooltipPlacement: "centerAbove",
          }}
        />
      </span>
    );
  };

  const _renderWebcamButton = ({
    _handleToggleWebcam,
    webcamOn: _webcamOn,
    isMobilePortraitOrLandscape,
  }: {
    _handleToggleWebcam: () => void;
    webcamOn: boolean;
    isMobilePortraitOrLandscape: boolean;
  }) => {
    const { isChangeLocalWebcamInProgress } =
      useIsChangeLocalWebcamInProgress();

    const webcamOn = useMemo(
      () => _webcamOn || isChangeLocalWebcamInProgress,
      [_webcamOn, isChangeLocalWebcamInProgress]
    );

    const { cameraDenied } = useIsParticipantCameraDenied({
      participantId: localParticipantId,
    });

    const askCameraPermission = () => {
      appEventEmitter.emit(appEventEmitterEvents.ASK_CAMERA_PERMISSION);
    };

    return (
      <span
        className={`${quickstartTargetComponentClassNames.local_webcam_toggle_bottom_controls_button}`}
      >
        <IconButton
          {...{
            onClick: cameraDenied ? askCameraPermission : _handleToggleWebcam,
            Icon: webcamOn ? LocalWebcamIcon : MdVideocamOff,
            title: isMobilePortraitOrLandscape
              ? "Cam"
              : webcamOn
              ? "Stop Cam"
              : "Start Cam",
            popperTitle: "",
            variant: webcamOn
              ? iconButtonVariants.info
              : iconButtonVariants.error,
            maxTitle: isMobilePortraitOrLandscape ? "Cam" : "Start Cam",
            disabled: cameraDenied,
            disabledTitle: "Webcam permission not granted",
            // tooltipPlacement: "top",
            tooltipPlacement: "centerAbove",
          }}
        />
      </span>
    );
  };

  const _renderTurnOffScreenShareButton = ({
    _handleToggleScreenshare,
  }: {
    _handleToggleScreenshare: () => void;
  }) => (
    <IconButton
      {...{
        onClick: _handleToggleScreenshare,
        Icon: MdOutlineDesktopWindows,
        title: "Stop Screen",
        popperTitle: "",
        variant: iconButtonVariants.primary,
        maxTitle: "Stop Screen",
      }}
    />
  );

  const _renderSettingsButton = ({
    _handleToggleSettingsModal,
  }: {
    _handleToggleSettingsModal: () => void;
  }) => (
    <IconButton
      {...{
        onClick: _handleToggleSettingsModal,
        Icon: MdSettings,
        title: "Settings",
        popperTitle: "",
        variant: iconButtonVariants.info,
        maxTitle: "Settings",
      }}
    />
  );

  const _renderShareButton = ({
    screenShareOn,
    _handleToggleScreenshare,
    _handleVideoFileEvent,
    _handleVideoSelect,
    _handleInputFileVideoFileEvent,
  }: {
    screenShareOn: boolean;
    _handleToggleScreenshare: () => void;
    _handleVideoFileEvent: (t: {
      singleFile: File | null;
      fileName: string;
    }) => void;
    _handleVideoSelect: (t: { remoteUrl: string; id: string }) => void;
    _handleInputFileVideoFileEvent: (t: { fileObjectUrl: string }) => void;
  }) => (
    <span
      className={`${quickstartTargetComponentClassNames.local_share_bottom_controls_button}`}
    >
      <ShareContainer
        {...{
          screenShareOn,
          _handleToggleScreenshare,
          _handleVideoFileEvent,
          _handleVideoSelect,
          _handleInputFileVideoFileEvent,
        }}
      />
    </span>
  );

  const _renderRaiseHandButton = ({
    toggleRaiseHand,
    localParticipantRaisedHand,
    isMobilePortraitOrLandscape,
  }: {
    toggleRaiseHand: () => void;
    localParticipantRaisedHand: boolean;
    isMobilePortraitOrLandscape: boolean;
  }) => (
    <IconButton
      {...{
        onClick: toggleRaiseHand,
        Icon: localParticipantRaisedHand ? MdPanTool : MdOutlinePanTool,
        title: isMobilePortraitOrLandscape ? "Raise" : "Raise hand",
        popperTitle: "",
        variant: localParticipantRaisedHand
          ? iconButtonVariants.active
          : iconButtonVariants.info,
        maxTitle: isMobilePortraitOrLandscape ? "Raise" : "Raise hand",
      }}
    />
  );

  const _renderLeaveButton = ({
    _handleLeave,
    isMobilePortraitOrLandscape,
  }: {
    _handleLeave: () => void;
    isMobilePortraitOrLandscape: boolean;
  }) => (
    <IconButton
      {...{
        onClick: _handleLeave,
        Icon: MdCancel,
        title: isMobilePortraitOrLandscape ? "Leave" : "Leave Studio",
        popperTitle: "",
        variant: iconButtonVariants.info,
        maxTitle: isMobilePortraitOrLandscape ? "Leave" : "Leave Studio",
        iconFillVarient: iconButtonVariants.error,
        rounded: "rounded-r-md",
      }}
    />
  );

  const _renderEndButton = ({ _handleEnd }: { _handleEnd: () => void }) => (
    <IconButton
      {...{
        onClick: async () => {
          await stopBroadcastEgresses({ broadcastId });

          _handleEnd();
        },
        Icon: MdCallEnd,
        title: "End Studio",
        popperTitle: "",
        variant: iconButtonVariants.info,
        maxTitle: "End Studio",
        iconFillVarient: iconButtonVariants.error,
        rounded: "rounded-r-md",
      }}
    />
  );

  const _renderChatButton = ({
    isChatPanelOn,
    _handleToggleChatPanel,
  }: {
    isChatPanelOn: boolean;
    _handleToggleChatPanel: () => void;
  }) => (
    <IconButton
      {...{
        onClick: _handleToggleChatPanel,
        Icon: MdChatBubble,
        title: "Chat",
        popperTitle: "",
        variant: isChatPanelOn
          ? iconButtonVariants.active
          : iconButtonVariants.info,
        maxTitle: "Chat",
      }}
    />
  );

  const _renderBrandButton = ({
    isBrandPanelOn,
    _handleToggleBrandPanel,
  }: {
    isBrandPanelOn: boolean;
    _handleToggleBrandPanel: () => void;
  }) => (
    <IconButton
      {...{
        onClick: _handleToggleBrandPanel,
        Icon: MdColorLens,
        title: "Brand",
        popperTitle: "",
        variant: isBrandPanelOn
          ? iconButtonVariants.active
          : iconButtonVariants.info,
        maxTitle: "Brand",
      }}
    />
  );

  const _renderMoreButton = ({
    _handleToggleMore,
    isMoreOn,
  }: {
    _handleToggleMore: () => void;
    isMoreOn: boolean;
  }) => (
    <IconButton
      {...{
        onClick: _handleToggleMore,
        Icon: MdMoreVert,
        title: "More",
        popperTitle: "",
        variant: isMoreOn ? iconButtonVariants.active : iconButtonVariants.info,
        maxTitle: "More",
      }}
    />
  );

  const _renderHideMoreButtom = ({
    _handleToggleMore,
    isMoreOn,
  }: {
    _handleToggleMore: () => void;
    isMoreOn: boolean;
  }) => (
    <IconButton
      {...{
        onClick: _handleToggleMore,
        Icon: MdMoreVert,
        title: "More",
        popperTitle: "",
        variant: isMoreOn ? iconButtonVariants.active : iconButtonVariants.info,
        maxTitle: "More",
      }}
    />
  );

  const _renderInviteButtom = ({
    _handleToggleInviteModal,
  }: {
    _handleToggleInviteModal: () => void;
  }) => (
    <span
      className={`${quickstartTargetComponentClassNames.invite_speakers_and_add_to_stream}`}
    >
      <IconButton
        {...{
          onClick: _handleToggleInviteModal,
          Icon: MdPersonAdd,
          title: "Invite",
          popperTitle: "",
          variant: iconButtonVariants.info,
          maxTitle: "Invite",
        }}
      />
    </span>
  );

  const _renderQNAButton = ({
    _handleToggleQNAPanel,
  }: {
    _handleToggleQNAPanel: () => void;
  }) => (
    <IconButton
      {...{
        onClick: _handleToggleQNAPanel,
        Icon: MdQuestionAnswer,
        title: "Q&A",
        popperTitle: "",
        variant: iconButtonVariants.info,
        maxTitle: "Q&A",
      }}
    />
  );

  const _renderPrivateChatButton = ({
    _handleTogglePrivateChatPanel,
  }: {
    _handleTogglePrivateChatPanel: () => void;
  }) => (
    <IconButton
      {...{
        onClick: _handleTogglePrivateChatPanel,
        Icon: MdChatBubble,
        title: "Private Chat",
        popperTitle: "",
        variant: iconButtonVariants.info,
        maxTitle: "Private Chat",
      }}
    />
  );

  const _renderAppsButton = ({
    _handleToggleAppsPanel,
  }: {
    _handleToggleAppsPanel: () => void;
  }) => (
    <IconButton
      {...{
        onClick: _handleToggleAppsPanel,
        Icon: MdApps,
        title: "Apps",
        popperTitle: "",
        variant: iconButtonVariants.info,
        maxTitle: "Apps",
      }}
    />
  );

  const _renderParticipantsButton = ({
    _handleToggleParticipantsPanel,
  }: {
    _handleToggleParticipantsPanel: () => void;
  }) => (
    <IconButton
      {...{
        onClick: _handleToggleParticipantsPanel,
        Icon: MdPeopleAlt,
        title: "Participants",
        popperTitle: "",
        variant: iconButtonVariants.info,
        maxTitle: "Participants",
      }}
    />
  );

  const { bottomBar, moreBar } = useMemo(() => {
    const bottomBar = [];
    const moreBar = [];

    const shouldRenderInterpretationsContainer = !!interpretations?.length;
    const shouldRenderMicButton = true;
    const shouldRenderWebcamButton = true;
    const shouldRenderTurnOffScreenShareButton = !!screenShareOn;
    const shouldRenderSettingsButton = true;
    const shouldRenderShareButton = true;
    const shouldRenderRaiseHandButton = true;
    const shouldRenderLeaveButton = true;
    const shouldRenderInviteButton = appMode === appModes.HOST;
    const shouldRenderQNAButton = true;
    const shouldRenderPrivateChatButton =
      appMode === appModes.HOST ? true : participatedPrivateChatGroups.length;

    const shouldRenderAppsButton = appMode === appModes.HOST;
    const shouldRenderParticipantsButton = appMode === appModes.HOST;
    // const shouldRenderEndButton = false;

    if (isMobilePortraitOrLandscape) {
      if (shouldRenderInterpretationsContainer) {
        bottomBar.push(bottomControlsButtonTypes.interpretations);
      }
      if (shouldRenderMicButton) {
        bottomBar.push(bottomControlsButtonTypes.mic);
      }
      if (shouldRenderWebcamButton) {
        bottomBar.push(bottomControlsButtonTypes.webcam);
      }
      if (shouldRenderRaiseHandButton && raiseHandEnabled) {
        bottomBar.push(bottomControlsButtonTypes.raiseHand);
      }
      if (shouldRenderLeaveButton) {
        bottomBar.push(bottomControlsButtonTypes.leave);
      }
      bottomBar.push(bottomControlsButtonTypes.more);
      //
      if (shouldRenderInviteButton) {
        moreBar.push(bottomControlsButtonTypes.invite);
      }
      if (shouldRenderSettingsButton) {
        moreBar.push(bottomControlsButtonTypes.settings);
      }
      if (shouldRenderShareButton) {
        moreBar.push(bottomControlsButtonTypes.share);
      }
      if (shouldRenderPrivateChatButton) {
        moreBar.push(bottomControlsButtonTypes.privateChat);
      }
      if (appMode === appModes.HOST) {
        moreBar.push(bottomControlsButtonTypes.brand);
      }
      if (shouldRenderQNAButton) {
        moreBar.push(bottomControlsButtonTypes.qna);
      }
      if (shouldRenderAppsButton) {
        moreBar.push(bottomControlsButtonTypes.apps);
      }
      if (shouldRenderParticipantsButton) {
        moreBar.push(bottomControlsButtonTypes.participants);
      }

      //
      moreBar.push(bottomControlsButtonTypes.hideMore);
    } else {
      if (shouldRenderInterpretationsContainer) {
        bottomBar.push(bottomControlsButtonTypes.interpretations);
      }
      if (shouldRenderMicButton) {
        bottomBar.push(bottomControlsButtonTypes.mic);
      }
      if (shouldRenderWebcamButton) {
        bottomBar.push(bottomControlsButtonTypes.webcam);
      }
      if (shouldRenderTurnOffScreenShareButton) {
        bottomBar.push(bottomControlsButtonTypes.turnOffScreenShare);
      }
      if (shouldRenderSettingsButton) {
        bottomBar.push(bottomControlsButtonTypes.settings);
      }
      if (shouldRenderShareButton) {
        bottomBar.push(bottomControlsButtonTypes.share);
      }
      if (shouldRenderRaiseHandButton && raiseHandEnabled) {
        bottomBar.push(bottomControlsButtonTypes.raiseHand);
      }
      if (shouldRenderInviteButton) {
        bottomBar.push(bottomControlsButtonTypes.invite);
      }
      if (shouldRenderLeaveButton) {
        bottomBar.push(bottomControlsButtonTypes.leave);
      }
    }

    return { bottomBar, moreBar };
  }, [
    interpretations,
    appMode,
    isMobilePortraitOrLandscape,
    screenShareOn,
    raiseHandEnabled,
    participatedPrivateChatGroups,
  ]);

  const loadHubspotScript = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;

    script.src = "//js-eu1.hs-scripts.com/25877653.js";

    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const renderButtomsFromTypesArray = ({
    keyPrefix,
    typesArray,
    renderInChunk,
  }: {
    keyPrefix: string;
    typesArray: string[];
    renderInChunk?: boolean;
  }) => {
    return (renderInChunk ? chunk(typesArray, 5) : [typesArray]).map(
      (typesArray, i) => {
        return (
          <div
            key={`ButtonsFromTypesArray-${i}`}
            className="flex flex-1 items-center justify-center"
          >
            {typesArray.map((bottomControlsButtonType) => {
              return (
                <React.Fragment
                  key={`${keyPrefix}-${bottomControlsButtonType}`}
                >
                  {bottomControlsButtonType ===
                  bottomControlsButtonTypes.interpretations
                    ? _renderInterpretationsContainer()
                    : bottomControlsButtonType === bottomControlsButtonTypes.mic
                    ? _renderMicButton({
                        _handleToggleMic,
                        micOn,
                        isMobilePortraitOrLandscape,
                      })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.webcam
                    ? _renderWebcamButton({
                        _handleToggleWebcam,
                        webcamOn,
                        isMobilePortraitOrLandscape,
                      })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.turnOffScreenShare
                    ? _renderTurnOffScreenShareButton({
                        _handleToggleScreenshare,
                      })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.settings
                    ? _renderSettingsButton({ _handleToggleSettingsModal })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.share
                    ? _renderShareButton({
                        screenShareOn,
                        _handleToggleScreenshare,
                        _handleVideoFileEvent,
                        _handleVideoSelect,
                        _handleInputFileVideoFileEvent,
                      })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.raiseHand
                    ? _renderRaiseHandButton({
                        toggleRaiseHand,
                        localParticipantRaisedHand,
                        isMobilePortraitOrLandscape,
                      })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.leave
                    ? _renderLeaveButton({
                        _handleLeave,
                        isMobilePortraitOrLandscape,
                      })
                    : bottomControlsButtonType === bottomControlsButtonTypes.end
                    ? _renderEndButton({ _handleEnd })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.chat
                    ? _renderChatButton({
                        isChatPanelOn,
                        _handleToggleChatPanel,
                      })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.brand
                    ? _renderBrandButton({
                        isBrandPanelOn,
                        _handleToggleBrandPanel,
                      })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.more
                    ? _renderMoreButton({ _handleToggleMore, isMoreOn })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.hideMore
                    ? _renderHideMoreButtom({ _handleToggleMore, isMoreOn })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.invite
                    ? _renderInviteButtom({ _handleToggleInviteModal })
                    : bottomControlsButtonType === bottomControlsButtonTypes.qna
                    ? _renderQNAButton({ _handleToggleQNAPanel })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.privateChat
                    ? _renderPrivateChatButton({
                        _handleTogglePrivateChatPanel,
                      })
                    : bottomControlsButtonType ===
                      bottomControlsButtonTypes.apps
                    ? _renderAppsButton({ _handleToggleAppsPanel })
                    : bottomControlsButtonTypes.participants
                    ? _renderParticipantsButton({
                        _handleToggleParticipantsPanel,
                      })
                    : null}
                </React.Fragment>
              );
            })}
          </div>
        );
      }
    );
  };

  const {
    shouldRenderPrivateChatGroupsContainer,
    shouldRenderBrandPanel,
    shouldRenderQNAPanel,
    shouldRenderAppsPanel,
    shouldRenderBannerFoldersContainer,
    shouldRenderBannersContainer,
    shouldRenderPollsContainer,
    shouldRenderWordcloudContainer,
    shouldRenderSidePanelPollDetail,
    shouldRenderSidePanelWordcloudDetail,
    shouldRenderParticipantsPanel,
    shouldRenderPrivateChatGroupMessages,
    shouldRenderResourcesContainer,
  } = useShouldRenderSidePanelItemContainer();

  const fullScreenModalContainerItems = useMemo(() => {
    return [
      {
        Component: PrivateChatGroupsContainer,
        props: {
          onCloseButtonClick: () => {
            setSidePanelMode(null);
          },
        },
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-PrivateChatGroupsContainer",
          isOpen: shouldRenderPrivateChatGroupsContainer,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: BrandPanel,
        props: {},
        containerProps: {
          title: "Brands",
          handleCloseModal: () => {
            setSidePanelMode(null);
          },
          key: "BottomControlsContainerFullscreenItem-BrandPanel",
          isOpen: shouldRenderBrandPanel,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: QNAPanel,
        props: {
          onCloseButtonClick: () => {
            setSidePanelMode(null);
          },
        },
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-QNAPanel",
          isOpen: shouldRenderQNAPanel,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: AppsPanel,
        props: {
          onCloseButtonClick: () => {
            setSidePanelMode(null);
          },
        },
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-AppsPanel",
          isOpen: shouldRenderAppsPanel,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: BannerFoldersContainer,
        props: {},
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-BannerFoldersContainer",
          isOpen: shouldRenderBannerFoldersContainer,
          animationDisabled: true,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: BannersContainer,
        props: {},
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-BannersContainer",
          isOpen: shouldRenderBannersContainer,
          animationDisabled: true,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: PollsContainer,
        props: {},
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-PollsContainer",
          isOpen: shouldRenderPollsContainer,
          animationDisabled: true,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: WordcloudContainer,
        props: {},
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-WordcloudContainer",
          isOpen: shouldRenderWordcloudContainer,
          animationDisabled: true,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: SidePanelPollDetail,
        props: { id: sidePanelActivePollId },
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-SidePanelPollDetail",
          isOpen: shouldRenderSidePanelPollDetail,
          animationDisabled: true,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: SidePanelWordcloudDetail,
        props: { id: sidePanelActiveWordcloudId },
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-SidePanelWordcloudDetail",
          isOpen: shouldRenderSidePanelWordcloudDetail,
          animationDisabled: true,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: ParticipantsPanel,
        props: {
          onCloseButtonClick: () => {
            setSidePanelMode(null);
          },
        },
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-ParticipantsPanel",
          isOpen: shouldRenderParticipantsPanel,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: PrivateChatGroupMessages,
        props: {},
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-PrivateChatGroupMessages",
          isOpen: shouldRenderPrivateChatGroupMessages,
          animationDisabled: true,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
      {
        Component: ResourcesContainer,
        props: {},
        containerProps: {
          key: "BottomControlsContainerFullscreenItem-ResourcesContainer",
          isOpen: shouldRenderResourcesContainer,
          animationDisabled: true,
        } as unknown as {
          isOpen: boolean;
          children: (t: {
            containerHeight: number;
            containerWidth: number;
          }) => React.ReactNode;
          title: string;
          handleCloseModal?: () => void;
          hideTopBar?: boolean;
        },
      },
    ];
  }, [
    shouldRenderPrivateChatGroupsContainer,
    shouldRenderBrandPanel,
    shouldRenderQNAPanel,
    shouldRenderAppsPanel,
    shouldRenderBannerFoldersContainer,
    shouldRenderBannersContainer,
    shouldRenderPollsContainer,
    shouldRenderWordcloudContainer,
    shouldRenderSidePanelPollDetail,
    shouldRenderSidePanelWordcloudDetail,
    shouldRenderParticipantsPanel,
    shouldRenderPrivateChatGroupMessages,
    shouldRenderResourcesContainer,
    sidePanelActivePollId,
    sidePanelActiveWordcloudId,
    setSidePanelMode,
  ]);

  // const { changeWebcamAndScreenShareStreamQuality } =
  //   useParticipantWebcamAndScreenShareStreamQuality();

  const containerRef = createRef<HTMLDivElement>();

  const [containerHeight, setContainerHeight] = useState(0);

  const containerHeightRef = useRef(containerHeight);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
    }
  }, [containerRef]);

  useEffect(() => {
    if (typeof onContainerHeight === "function") {
      onContainerHeight(containerHeight);
    }
  }, [containerHeight]);

  const _handleOnPdfAndPptFileEvent = ({
    fileName,
    singleFile,
  }: {
    singleFile: File | null;
    fileName: string;
  }) => {
    _handlePdfAndPptFileEvent({ fileName, singleFile });
  };

  const _handleOnPdfSelect = ({
    remoteUrl,
    pdfInfo,
    resourceId,
  }: {
    remoteUrl: string;
    pdfInfo: pdfInfoType;
    resourceId: string;
  }) => {
    _handlePdfSelect({ remoteUrl, pdfInfo, resourceId });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.ADD_PDF_AND_PPT_FILE_EVENT,
      _handleOnPdfAndPptFileEvent
    );
    appEventEmitter.on(
      appEventEmitterEvents.ADD_PDF_FROM_UPLOADED,
      _handleOnPdfSelect
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.ADD_PDF_AND_PPT_FILE_EVENT,
        _handleOnPdfAndPptFileEvent
      );
      appEventEmitter.off(
        appEventEmitterEvents.ADD_PDF_FROM_UPLOADED,
        _handleOnPdfSelect
      );
    };
  }, []);

  return (
    <React.Fragment>
      <div
        ref={containerRef as React.LegacyRef<HTMLDivElement>}
        className="flex items-center justify-center relative select-none"
      >
        {/* <button
          className="btn btn-primary"
          onClick={() => {
            appEventEmitter.emit(
              appEventEmitterEvents.RTC_TURN_ON_EXTRA_WEBCAM
            );
          }}
        >
          turn on extra webcam
        </button> */}
        {bottomBar.length ? (
          <div className="flex items-center justify-center border border-gray-200 rounded-md shadow-md">
            {renderButtomsFromTypesArray({
              keyPrefix: "bottom-controls-main-bar",
              typesArray: bottomBar,
            })}
          </div>
        ) : (
          <React.Fragment />
        )}

        {moreBar.length ? (
          <div
            style={{
              top: -46,
              transform: isMoreOn ? undefined : "translate(100%, 0%)",
              opacity: isMoreOn ? 1 : 0,
              transition: "transform .2s linear, opacity .2s linear",
            }}
            className={`absolute bottom-0 right-0 left-0 flex flex-col bg-white items-center justify-center border border-gray-200 rounded-md shadow-md`}
          >
            {renderButtomsFromTypesArray({
              keyPrefix: "bottom-controls-more-bar",
              typesArray: moreBar,
              renderInChunk: true,
            })}
          </div>
        ) : (
          <React.Fragment />
        )}

        {appMode === appModes.HOST && meetingMode === "full" ? (
          <div className="h-full flex items-end">
            <button
              onClick={() => {
                loadHubspotScript();
              }}
              className="btn btn-xs btn-link normal-case"
            >
              Need help?
            </button>
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>

      <Modal
        {...{
          isOpen: leaveConfirmationModalVisible,
          showSecondaryButton: appMode === appModes.HOST,
          secondaryButtonText: "Close room for everyone",
          onSecondaryButtonClick: async () => {
            await stopBroadcastEgresses({ broadcastId });

            // end();

            endCallPublish(
              JSON.stringify({ end: true, endedReason: "host_ended" })
              // , {
              //   persist: false,
              // }
            );

            setLeaveConfirmationModalVisible(false);
          },
          onSuccess: () => {
            // leave();

            appEventEmitter.emit(appEventEmitterEvents.LEAVE_FROM_SESSION, {});

            setLeaveConfirmationModalVisible(false);
          },
          onClose: () => {
            setLeaveConfirmationModalVisible(false);
          },
          cancelText: "No, stay",
          successText: "Yes, leave",
          title: "Are you sure to leave the studio?",
          renderBody: () => (appMode === appModes.HOST ? <p></p> : undefined),
        }}
      />

      <Modal
        {...{
          isOpen: inviteModalOpen,
          onSuccess: () => {
            // leave();

            // appEventEmitter.emit(appEventEmitterEvents.LEAVE_FROM_SESSION, {});

            setInviteModalOpen(false);
          },
          onClose: () => {
            setInviteModalOpen(false);
          },
          cancelText: "",
          successText: "",
          title: "Invite guests",
          renderOnlyBody: true,
          showCloseIcon: true,
          renderBody: () => {
            return (
              <div className="w-full flex items-center justify-center">
                <input
                  ref={inviteLinkInputRef as React.LegacyRef<HTMLInputElement>}
                  value={href}
                  className="flex flex-1 input input-bordered input-primary"
                />
                <div className="ml-3">
                  <button
                    className="btn btn-primary text-white normal-case"
                    onClick={setCopied}
                  >
                    {isCopied ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>
            );
          },
        }}
      />

      <SettingsContainer
        isOpen={settingsModalOpen}
        handleCancel={() => {
          setSettingsModalOpen(false);
        }}
      />

      {isMobilePortraitOrLandscape ? (
        <React.Fragment>
          {fullScreenModalContainerItems.map(
            ({
              Component,
              containerProps,
              props,
            }: {
              Component: React.ElementType;
              containerProps: {
                isOpen: boolean;
                children: (t: {
                  containerHeight: number;
                  containerWidth: number;
                }) => React.ReactNode;
                title: string;
                handleCloseModal?: () => void;
                hideTopBar?: boolean;
              };
              props:
                | { onCloseButtonClick: () => void }
                | object
                | { id: string };
            }) => {
              return (
                <FullScreenModalContainer {...containerProps}>
                  {({ containerHeight, containerWidth }) => {
                    return (
                      <Component
                        {...{ ...props, containerHeight, containerWidth }}
                      />
                    );
                  }}
                </FullScreenModalContainer>
              );
            }
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default BottomControlsContainer;
