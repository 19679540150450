import React, { useCallback, useEffect, useRef } from "react";
import { firebaseDB } from "../../../utils/firebase";
import { ref, onChildAdded, onChildRemoved } from "firebase/database";
import PollListner from "./PollListner";
import { useAppContext } from "../../../contexts/appContextDef";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";
import createPoll from "../../../apis/apps/polls/create-poll";
import useMainViewParticipants from "../../../hooks/appState/useMainViewParticipants";
import {
  streamModes,
  streamTypes,
} from "../../appState/MainViewParticipantsListner";
import useLocalParticipantId from "../../../hooks/utils/useLocalParticipantId";
import deletePoll from "../../../apis/apps/polls/delete-poll";
import deactivatePoll from "../../../apis/apps/polls/deactivate-poll";
import sleep from "../../../utils/sleep";
import {
  getMSQuickstartPolls,
  removeMSQuickstartPolls,
} from "../../../apis/quickstart";

const PollsListner = () => {
  const {
    polls,
    setPolls,
    sidePanelActivePollId,
    setSidePanelActivePollId,
    setUnseenActivatedApps,
  } = useAppContext();

  const oldPollDataForCopyRef = useRef<{
    oldPollId: string;
    question: string;
    options: { option: string }[];
    isActive: boolean;
    isInMainView: boolean;
    listenForNew: boolean;
  }>();

  const { localParticipantId } = useLocalParticipantId();

  const { broadcastId } = useAppConfigContext();

  const broadcastIdRef = useRef(broadcastId);
  const sidePanelActivePollIdRef = useRef(sidePanelActivePollId);
  const localParticipantIdRef = useRef(localParticipantId);

  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);
  useEffect(() => {
    sidePanelActivePollIdRef.current = sidePanelActivePollId;
  }, [sidePanelActivePollId]);
  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const onPollCreated = async (newPoll: pollType) => {
    const sidePanelActivePollId = sidePanelActivePollIdRef.current;

    if (oldPollDataForCopyRef.current?.listenForNew) {
      await sleep(200);

      const { oldPollId, isActive, isInMainView } =
        oldPollDataForCopyRef.current;

      await deletePoll({
        broadcastId: broadcastIdRef.current,
        pollId: oldPollId,
      });

      if (isActive) {
        await deactivatePoll({
          broadcastId: broadcastIdRef.current,
          pollId: oldPollId,
        });

        await sleep(200);

        // await activatePoll({
        //   broadcastId: broadcastIdRef.current,
        //   pollId: newPoll.id,
        // });
      }

      if (isInMainView) {
        removeFromMainViewStreams({
          mode: streamModes.APPPOLL,
          participantId: localParticipantIdRef.current,
          type: streamTypes.SHARE,
          pollId: oldPollId,
        });

        await sleep(200);

        addToMainViewStreams({
          mode: streamModes.APPPOLL,
          participantId: localParticipantIdRef.current,
          type: streamTypes.SHARE,
          pollId: `${newPoll.id}`,
        });
      }

      if (sidePanelActivePollId === oldPollId) {
        setSidePanelActivePollId(`${newPoll.id}`);
      }

      oldPollDataForCopyRef.current = undefined;
    }
  };

  const subscribePolls = useCallback(() => {
    const pollRef = ref(firebaseDB, `broadcastPoll/${broadcastIdRef.current}`);

    const unsubscribeAdded = onChildAdded(
      pollRef,
      async (snapshot) => {
        const value = snapshot.val();

        const msQuickstartPolls = await getMSQuickstartPolls({
          broadcastId: broadcastIdRef.current,
        });

        if (msQuickstartPolls?.includes(value.id)) {
          await deletePoll({
            broadcastId: broadcastIdRef.current,
            pollId: value.id,
          });

          await removeMSQuickstartPolls({
            broadcastId: broadcastIdRef.current,
          });
        } else {
          appEventEmitter.emit(appEventEmitterEvents.POLL_CREATED, value);

          onPollCreated(value);

          setPolls((s) => [...s, { ...value, selfSubmittedOptionId: null }]);

          await sleep(1000);

          if (value?.status) {
            setUnseenActivatedApps((s) => {
              const _s = [...s].filter(({ type }) => type !== "poll");

              _s.push({ type: "poll", id: value?.id });

              return _s;
            });
          }
        }
      },
      () => {}
    );

    const unsubscribeRemoved = onChildRemoved(
      pollRef,
      (snapshot) => {
        setPolls((s) => {
          const index = s.findIndex(
            ({ id: _id }) => `${_id}` === `${snapshot.key}`
          );

          if (index !== -1) {
            s.splice(index, 1);
          }

          return [...s];
        });

        if (`${sidePanelActivePollIdRef.current}` === `${snapshot.key}`) {
          setSidePanelActivePollId(null);
        }
      },
      () => {
        //
      }
    );

    return { unsubscribeAdded, unsubscribeRemoved };
  }, [setPolls, setSidePanelActivePollId, setUnseenActivatedApps]);

  useEffect(() => {
    const { unsubscribeAdded, unsubscribeRemoved } = subscribePolls();

    return () => {
      unsubscribeAdded();
      unsubscribeRemoved();
    };
  }, [subscribePolls]);

  const copyPollAndDeleteOld = async ({
    oldPollId,
    question,
    options,
    isActive,
    isInMainView,
  }: {
    oldPollId: string;
    question: string;
    options: { option: string }[];
    isActive: boolean;
    isInMainView: boolean;
  }) => {
    oldPollDataForCopyRef.current = {
      listenForNew: true,
      oldPollId,
      question,
      options,
      isActive,
      isInMainView,
    };

    createPoll({
      broadcastId: broadcastIdRef.current,
      options: options.map(({ option }) => option),
      question,
    });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.COPY_POLL_AND_DELETE_OLD,
      copyPollAndDeleteOld
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.COPY_POLL_AND_DELETE_OLD,
        copyPollAndDeleteOld
      );
    };
  }, []);

  return (
    <React.Fragment>
      {polls.map((poll) => (
        <PollListner
          {...{ key: `poll-listner-${poll.id}`, id: `${poll.id}` }}
        />
      ))}
    </React.Fragment>
  );
};

export default PollsListner;
