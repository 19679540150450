import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";

const BrandUploadingAndProcessingItem = ({
  name,
  id,
  isProcessing,
  isUploading,
}: {
  name: string;
  id: string;
  isProcessing?: boolean;
  isUploading?: boolean;
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const _handleAssetUploadingAndProcessingProgress = ({
    progress,
    upload,
  }: {
    progress: number;
    upload?: boolean;
  }) => {
    if (upload) {
      setUploadProgress(progress);
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.ASSET_UPLOADING_AND_PROCESSING_PROGRESS(id),
      _handleAssetUploadingAndProcessingProgress
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.ASSET_UPLOADING_AND_PROCESSING_PROGRESS(id),
        _handleAssetUploadingAndProcessingProgress
      );
    };
  }, [id]);

  return (
    <div
      key={`${id}`}
      style={{ borderWidth: 3 }}
      className="w-full font-medium text-primary bg-custom-blue-50 mt-2 rounded-md bg-opacity-50 border-custom-blue-50 rounded p-3 flex items-center justify-center"
    >
      <div>
        {isUploading ? (
          <div
            className="radial-progress text-primary"
            style={
              {
                "--value": parseInt(`${uploadProgress * 100}`),
                "--size": "20px",
                "--thickness": "2px",
              } as React.CSSProperties
            }
          ></div>
        ) : (
          <Spinner h={"h-5"} w={"h-5"} />
        )}
      </div>
      <div className="flex flex-1 ml-3 flex-col truncate text-ellipsis">
        <p>{`${name ? `${name}` : ""}`}</p>

        <p className="text-xs text-custom-blue-600">{`${
          isUploading ? "uploading..." : isProcessing ? "processing..." : ""
        }`}</p>
      </div>
    </div>
  );
};

const BrandUploadingAndProcessingItems = ({
  newItemsState,
}: {
  newItemsState: {
    id: string;
    isProcessing: boolean;
    isUploading: boolean;
    name: string;
    brandId: string;
  }[];
}) =>
  newItemsState.map(({ name, id, isProcessing, isUploading }) => (
    <BrandUploadingAndProcessingItem
      {...{
        key: `BrandUploadingAndProcessingItem-${id}`,
        name,
        id,
        isProcessing,
        isUploading,
      }}
    />
  ));
export default BrandUploadingAndProcessingItems;
