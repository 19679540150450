import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../utils/useLocalParticipantId";
import { useEffect, useMemo, useRef } from "react";
import useParticipantIdsAndCount from "./useParticipantIdsAndCount";
import { appModes } from "../../utils/constants";
import { Room as LivekitRoom } from "livekit-client";
import { useGetJoinedParticipants } from "./useJoinedParticipantsCalculator";

const useIsOldest = (args?: {
  appMode: appModeType;
  joinedParticipants: joinedParticipantsType;
  localParticipantId: string;
  allowedEntryRequestParticipantIds: allowedEntryRequestParticipantIdsType;
  signalingClient: LivekitRoom;
  studioParticipantIdLivekitSidMapRef: React.MutableRefObject<
    Map<string, string>
  >;
}) => {
  const appContext = useAppContext();

  const {
    appMode,
    joinedParticipants,
    // allowedEntryRequestParticipantIds,
    signalingClient,
    studioParticipantIdLivekitSidMapRef,
  } = useMemo(() => {
    const appMode = appContext?.appMode || args?.appMode;
    const joinedParticipants =
      appContext?.joinedParticipants || args?.joinedParticipants;
    const allowedEntryRequestParticipantIds =
      appContext?.allowedEntryRequestParticipantIds ||
      args?.allowedEntryRequestParticipantIds;
    const signalingClient =
      appContext?.signalingClient || args?.signalingClient;
    const studioParticipantIdLivekitSidMapRef =
      appContext?.studioParticipantIdLivekitSidMapRef ||
      args?.studioParticipantIdLivekitSidMapRef;

    return {
      appMode,
      joinedParticipants,
      allowedEntryRequestParticipantIds,
      signalingClient,
      studioParticipantIdLivekitSidMapRef,
    };
  }, [appContext, args]);

  const localParticipantId =
    useLocalParticipantId()?.localParticipantId || args?.localParticipantId;

  const { participantIds } = useParticipantIdsAndCount(
    args?.joinedParticipants
      ? {
          joinedParticipants: args?.joinedParticipants,
        }
      : undefined
  );

  const { getJoinedParticipants } = useGetJoinedParticipants({
    // allowedEntryRequestParticipantIds,
    signalingClient,
    studioParticipantIdLivekitSidMapRef,
  });

  const getJoinedParticipantsRef = useRef(getJoinedParticipants);

  useEffect(() => {
    getJoinedParticipantsRef.current = getJoinedParticipants;
  }, [getJoinedParticipants]);

  const appModeRef = useRef(appMode);
  const joinedParticipantsRef = useRef(joinedParticipants);
  const localParticipantIdRef = useRef(localParticipantId);
  const participantIdsRef = useRef(participantIds);

  useEffect(() => {
    appModeRef.current = appMode;
  }, [appMode]);
  useEffect(() => {
    joinedParticipantsRef.current = joinedParticipants;
  }, [joinedParticipants]);
  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);
  useEffect(() => {
    participantIdsRef.current = participantIds;
  }, [participantIds]);

  const getIsOldest = (args?: { appMode: appModeType }) => {
    const participantAppMode = args?.appMode;

    const appMode = appModeRef.current;
    // const joinedParticipants = joinedParticipantsRef.current;
    const { joinedParticipants } = getJoinedParticipantsRef.current();
    const localParticipantId = localParticipantIdRef.current;

    if (participantAppMode && participantAppMode !== appMode) {
      return false;
    }

    const participantIds = participantIdsRef.current;

    let oldest: {
      timestamp: number;
      participantId: string | null;
    } = {
      timestamp: new Date().getTime() + 3 * 1000,
      participantId: null,
    };

    joinedParticipants.forEach(({ timestamp, participantId, appMode }) => {
      if (
        timestamp < oldest.timestamp &&
        participantIds.includes(participantId) &&
        (args?.appMode ? appMode === args?.appMode : true)
      ) {
        oldest = { timestamp, participantId };
      }
    });

    if (oldest.participantId === localParticipantId) {
      return true;
    } else {
      return false;
    }
  };

  const getIsOldestHost = () => ({
    isOldestHost: getIsOldest({ appMode: appModes.HOST }),
  });

  const getIsOldestSpeaker = () => ({
    isOldestSpeaker: getIsOldest({ appMode: appModes.SPEAKER }),
  });

  const getIsOldestViewer = () => ({
    isOldestViewer: getIsOldest({ appMode: appModes.VIEWER }),
  });

  const getIsOldestParticipant = () => ({ isOldestParticipant: getIsOldest() });

  return {
    getIsOldestHost,
    getIsOldestViewer,
    getIsOldestSpeaker,
    getIsOldestParticipant,
  };
};

export default useIsOldest;
