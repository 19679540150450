import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { getBrandBackgroundMusicsTopicById } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const backgroundMusicsActions = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  RENAME: "RENAME",
  SORT: "SORT",
};

const BrandBackgroundMusicsListner = ({ id: brandId }: { id: string }) => {
  const { setBackgroundMusics, setSortedBackgroundMusicIds } = useAppContext();

  const _handleAdd = (backgroundMusic: singleBrandBackgroundMusicsType) => {
    setBackgroundMusics(
      (s) =>
        ({
          ...s,
          [brandId]: [...(s[brandId] || []), backgroundMusic],
        } as backgroundMusicsType)
    );
  };

  const _handleRemove = ({ id }: { id: string }) => {
    setBackgroundMusics((s) => ({
      ...s,
      [brandId]: s[brandId].filter(({ id: _id }) => _id !== id),
    }));
  };

  const _handleRename = ({ id, name }: { id: string; name: string }) => {
    setBackgroundMusics((s) => ({
      ...s,
      [brandId]: s[brandId].map((backgroundMusic) =>
        id === backgroundMusic.id
          ? { ...backgroundMusic, name }
          : backgroundMusic
      ),
    }));
  };

  const _handleSort = ({ ids }: { ids: string[] }) => {
    setSortedBackgroundMusicIds((s) => ({ ...s, [brandId]: ids }));
  };

  useAppSingalingSubscribe(
    getBrandBackgroundMusicsTopicById(brandId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case backgroundMusicsActions.ADD:
          _handleAdd(message.payload);
          break;

        case backgroundMusicsActions.REMOVE:
          _handleRemove(message.payload);
          break;

        case backgroundMusicsActions.RENAME:
          _handleRename(message.payload);
          break;

        case backgroundMusicsActions.SORT:
          _handleSort(message.payload);
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default BrandBackgroundMusicsListner;
