import React, { useEffect, useMemo } from "react";
import InQueueFileStreamContainer from "../inQueueStreams/InQueueFileStreamContainer";
import InQueueScreenShareStreamContainer from "../inQueueStreams/InQueueScreenShareStreamContainer";
import InQueueUploadingContainer from "../inQueueStreams/InQueueUploadingContainer";
import InQueueVideoStreamContainer from "../inQueueStreams/InQueueVideoStreamContainer";
import InQueueWebcamStreamContainer from "../inQueueStreams/InQueueWebcamStreamContainer";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import { inQueueStreamTypes } from "../../utils/constants";
import InQueueInputFileVideoShareStreamContainer from "../inQueueStreams/InQueueInputFileVideoShareStreamContainer";
import useSpeakerInQueueStreams from "../../hooks/appState/useSpeakerInQueueStreams";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import InQueueExtraWebcamStreamContainer from "../inQueueStreams/InQueueExtraWebcamStreamContainer";

const SpeakerControlsItemContainer = ({
  participantId,
  type,
  id,
}: {
  participantId: string;
  type: string;
  id: string;
}) => {
  const {
    Component,
    props,
  }: {
    Component: React.ElementType;
    props:
      | object
      | { participantId: string }
      | { fileId: string; id: string; participantId: string }
      | { videoId: string; id: string; participantId: string }
      | { inputFileVideoId: string; id: string; participantId: string }
      | { extraWebcamId: string; participantId: string }
      | { rtmpInId: string; participantId: string };
  } = useMemo(() => {
    const { Component, props } =
      type === inQueueStreamTypes.WEBCAM
        ? {
            Component: InQueueWebcamStreamContainer,
            props: { participantId },
          }
        : type === inQueueStreamTypes.SHARE_SCREEN
        ? {
            Component: InQueueScreenShareStreamContainer,
            props: { participantId },
          }
        : type === inQueueStreamTypes.SHARE_PDF
        ? {
            Component: InQueueFileStreamContainer,
            props: { fileId: id, participantId },
          }
        : type === inQueueStreamTypes.SHARE_VIDEO
        ? {
            Component: InQueueVideoStreamContainer,
            props: { videoId: id, participantId },
          }
        : type === inQueueStreamTypes.SHARE_INPUT_FILE_VIDEO
        ? {
            Component: InQueueInputFileVideoShareStreamContainer,
            props: { inputFileVideoId: id, participantId },
          }
        : type === inQueueStreamTypes.EXTRAWBCM
        ? {
            Component: InQueueExtraWebcamStreamContainer,
            props: { extraWebcamId: id, participantId },
          }
        : { Component: React.Fragment, props: {} };

    return { Component, props };
  }, [type, participantId, id]);

  return (
    <div className="m-1 border-gray-400 rounded-md border bg-gray-600">
      <Component {...props} />
    </div>
  );
};

const SpeakerControlsContainer = ({
  onContainerHeight,
}: {
  onContainerHeight?: (height: number) => void;
}) => {
  const { localParticipantId } = useLocalParticipantId();
  const { meetingMode } = useAppConfigContext();

  const {
    newBroadcastAssetsStates,
    isLocalParticipantInQueue,
    localParticipantFilteredInQueueStreams,
    speakerInQueueStreamsContainerHiddenInMeetingMode,
  } = useSpeakerInQueueStreams();

  useEffect(() => {
    if (typeof onContainerHeight === "function") {
      onContainerHeight(
        speakerInQueueStreamsContainerHiddenInMeetingMode ? 0 : 140
      );
    }
  }, [speakerInQueueStreamsContainerHiddenInMeetingMode]);

  return speakerInQueueStreamsContainerHiddenInMeetingMode ? (
    <React.Fragment />
  ) : (
    <div
      style={{ maxHeight: 140, minHeight: 140 }}
      className="select-none flex items-center overflow-y-auto flex-wrap p-1"
    >
      {!isLocalParticipantInQueue && meetingMode !== "meeting" && (
        <div className="m-1 border-gray-400 rounded-md border bg-gray-600">
          <InQueueWebcamStreamContainer
            {...{ participantId: localParticipantId, isDummy: true }}
          />
        </div>
      )}

      {localParticipantFilteredInQueueStreams.map(
        ({ participantId, type, id }, i) => (
          <SpeakerControlsItemContainer
            {...{
              participantId,
              type,
              id: id as string,
              key: `local_participant_inqueue_stream_${i}`,
            }}
          />
        )
      )}

      {newBroadcastAssetsStates.map((state) => (
        <div
          key={`${state.id}`}
          className="m-1 border-gray-400 rounded-md border bg-gray-600"
        >
          <InQueueUploadingContainer {...state} />
        </div>
      ))}
    </div>
  );
};

export default SpeakerControlsContainer;
