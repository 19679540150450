import React, { useEffect, useMemo, useRef } from "react";
import {
  appSignalingClient,
  appSignalingClients,
  maxParticipantsCountCapacity,
} from "../../utils/constants";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import useIsOldest from "../../hooks/appState/useIsOldest";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";
import sleep from "../../utils/sleep";
import { Room as LivekitRoom } from "livekit-client";
import { safeParseJson } from "../../utils/safeParseJson";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAllowParticipantEntryRequest from "../../hooks/appState/useAllowParticipantEntryRequest";

export const useJoinedParticipantsCountAndIsMaxedOutjoinedParticipants = () => {
  const { maxParticipantsCount } = useAppConfigContext();

  const maxParticipantsCountRef = useRef(maxParticipantsCount);

  useEffect(() => {
    maxParticipantsCountRef.current = maxParticipantsCount;
  }, [maxParticipantsCount]);

  const { participantsCount: joinedParticipantsCount } =
    useParticipantIdsAndCount();

  const { isMaxedOutjoinedParticipants } = useMemo(() => {
    const isMaxedOutjoinedParticipants =
      maxParticipantsCountRef.current === joinedParticipantsCount;

    return { joinedParticipantsCount, isMaxedOutjoinedParticipants };
  }, [joinedParticipantsCount]);

  return { joinedParticipantsCount, isMaxedOutjoinedParticipants };
};

export const useShowUpgradeToFitMorePeopleText = () => {
  const { isMaxedOutjoinedParticipants } =
    useJoinedParticipantsCountAndIsMaxedOutjoinedParticipants();
  const { participantsCount: joinedParticipantsCount } =
    useParticipantIdsAndCount();

  const showUpgradeToFitMorePeopleText = useMemo(() => {
    return isMaxedOutjoinedParticipants
      ? joinedParticipantsCount < maxParticipantsCountCapacity
      : false;
  }, [isMaxedOutjoinedParticipants, joinedParticipantsCount]);

  return { showUpgradeToFitMorePeopleText };
};

const WaitingRoomParticipantsListner = () => {
  const {
    waitingRoomEnabled,
    joinedParticipants,
    requestedEntries,
    allowedEntryRequestParticipantIds,
    setRequestedEntries,
    signalingClient,
  } = useAppContext();

  const { localParticipantId } = useLocalParticipantId();
  const { maxParticipantsCount } = useAppConfigContext();

  const checkWaitingRoomParticipantInProgress = useRef(false);

  const waitingRoomEnabledRef = useRef(waitingRoomEnabled);
  const joinedParticipantsRef = useRef(joinedParticipants);
  const localParticipantIdRef = useRef(localParticipantId);
  const maxParticipantsCountRef = useRef(maxParticipantsCount);
  const signalingClientRef = useRef(signalingClient);
  const allowedEntryRequestParticipantIdsRef = useRef(
    allowedEntryRequestParticipantIds
  );

  useEffect(() => {
    waitingRoomEnabledRef.current = waitingRoomEnabled;
  }, [waitingRoomEnabled]);
  useEffect(() => {
    joinedParticipantsRef.current = joinedParticipants;
  }, [joinedParticipants]);
  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);
  useEffect(() => {
    maxParticipantsCountRef.current = maxParticipantsCount;
  }, [maxParticipantsCount]);
  useEffect(() => {
    signalingClientRef.current = signalingClient;
  }, [signalingClient]);
  useEffect(() => {
    allowedEntryRequestParticipantIdsRef.current =
      allowedEntryRequestParticipantIds;
  }, [allowedEntryRequestParticipantIds]);

  const { joinedParticipantsCount, isMaxedOutjoinedParticipants } =
    useJoinedParticipantsCountAndIsMaxedOutjoinedParticipants();

  const joinedParticipantsCountRef = useRef(joinedParticipantsCount);
  const requestedEntriesRef = useRef(requestedEntries);
  const isMaxedOutjoinedParticipantsRef = useRef(isMaxedOutjoinedParticipants);

  useEffect(() => {
    joinedParticipantsCountRef.current = joinedParticipantsCount;
  }, [joinedParticipantsCount]);
  useEffect(() => {
    requestedEntriesRef.current = requestedEntries;
  }, [requestedEntries]);

  useEffect(() => {
    isMaxedOutjoinedParticipantsRef.current = isMaxedOutjoinedParticipants;
  }, [isMaxedOutjoinedParticipants]);

  const { getIsOldestHost } = useIsOldest();

  const { allowParticipantEntryRequest } = useAllowParticipantEntryRequest();

  const _handleAllowParticipant = ({
    participantId,
  }: {
    participantId: string;
    sid: string;
  }) => {
    allowParticipantEntryRequest(participantId);
  };

  useAppSingalingSubscribe(
    appPubSubTopics.PARTICIPANT_JOIN_REQUEST_ENTRY,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { participantId, name, sid } = message;

      const requestedEntries = requestedEntriesRef.current;

      const requested = requestedEntries.find(
        ({ participantId: _participantId, sid: _sid }) =>
          participantId === _participantId && sid === _sid
      );

      if (!requested) {
        setRequestedEntries((s) => [...s, { participantId, name, sid }]);
      }
    }
  );

  const checkWaitingRoomParticipant = async () => {
    if (checkWaitingRoomParticipantInProgress.current) {
      return;
    }

    checkWaitingRoomParticipantInProgress.current = true;

    const joinedParticipantsCount = joinedParticipantsCountRef.current;
    const requestedEntries = requestedEntriesRef.current;
    const waitingRoomEnabled = waitingRoomEnabledRef.current;

    const { isOldestHost } = getIsOldestHost();

    const isMaxedOutjoinedParticipants =
      isMaxedOutjoinedParticipantsRef.current;

    if (
      isOldestHost &&
      !isMaxedOutjoinedParticipants &&
      requestedEntries.length
    ) {
      const maxAllowedRequestedParticipantCount =
        maxParticipantsCountRef.current - joinedParticipantsCount;

      if (!waitingRoomEnabled) {
        for (
          let index = 0;
          index < maxAllowedRequestedParticipantCount;
          index++
        ) {
          const requestedEntry = requestedEntries[index];

          if (requestedEntry) {
            const { participantId, sid } = requestedEntry;

            if (!isMaxedOutjoinedParticipants && sid) {
              _handleAllowParticipant({ participantId, sid });
            }

            await sleep(500);
          }
        }
      }
    }

    checkWaitingRoomParticipantInProgress.current = false;
  };

  const refreshRequestedEntriesState = () => {
    const allowedEntryRequestParticipantIds =
      allowedEntryRequestParticipantIdsRef.current;

    if (appSignalingClient === appSignalingClients.livekit) {
      const signalingClient = signalingClientRef.current as LivekitRoom;

      const filterRequestedEntries = requestedEntriesRef.current.filter(
        ({ participantId, sid }) =>
          !allowedEntryRequestParticipantIds.find(
            ({ participantId: _participantId, sid: _sid }) =>
              participantId === _participantId && sid === _sid
          ) && signalingClient.participants.get(sid)
      );

      if (
        requestedEntriesRef.current
          .map(({ participantId }) => participantId)
          .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
          .join("") !==
        filterRequestedEntries
          .map(({ participantId }) => participantId)
          .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
          .join("")
      ) {
        setRequestedEntries(requestedEntries);
      }
    }
  };

  const checkWaitingRoomParticipantInterval = () => {
    setInterval(() => {
      checkWaitingRoomParticipant();
    }, 5000);

    setInterval(() => {
      refreshRequestedEntriesState();
    }, 1000);
  };

  useEffect(() => {
    checkWaitingRoomParticipantInterval();
  }, []);

  return <React.Fragment />;
};

export default WaitingRoomParticipantsListner;
