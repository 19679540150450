// import { usePubSub } from "@videosdk.live/react-sdk";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";

const useConferenceModeMaxParticipantCount = () => {
  // const { publish } = usePubSub(
  //   appPubSubTopics.CONFERENCE_MODE_MAX_PARTICIPANT_COUNT
  // );

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.CONFERENCE_MODE_MAX_PARTICIPANT_COUNT
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const changeConferenceModeMaxParticipantCount = async ({
    conferenceModeMaxParticipantCount,
    conferenceModeParticipantAutoCountEnabled,
  }: {
    conferenceModeMaxParticipantCount?: number;
    conferenceModeParticipantAutoCountEnabled?: boolean;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        conferenceModeMaxParticipantCount,
        conferenceModeParticipantAutoCountEnabled,
      })
      // { persist: true }
    );
  };

  return { changeConferenceModeMaxParticipantCount };
};

export default useConferenceModeMaxParticipantCount;
