import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { MdVolumeOff } from "react-icons/md";
import { useAppContext } from "../../contexts/appContextDef";
import {
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";

const MainViewUserInteractButtonInputFileVideoShareRendered = () => {
  const [muteDueToError, setMuteDueToError] = useState(false);

  const muteDueToErrorRef = useRef(muteDueToError);

  useEffect(() => {
    muteDueToErrorRef.current = muteDueToError;
  }, [muteDueToError]);

  const _handleMainViewVideoShareStreamPlayErrored = () => {
    if (muteDueToErrorRef.current) return;

    setMuteDueToError(true);
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.MAIN_VIEW_INPUT_FILE_VIDEO_SHARE_STREAM_PLAY_ERRORED,
      _handleMainViewVideoShareStreamPlayErrored
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.MAIN_VIEW_INPUT_FILE_VIDEO_SHARE_STREAM_PLAY_ERRORED,
        _handleMainViewVideoShareStreamPlayErrored
      );
    };
  }, []);

  return muteDueToError ? (
    <div className="absolute bottom-3 left-0 right-0 flex items-center justify-center">
      <button
        onClick={() => {
          setMuteDueToError(false);

          appEventEmitter.emit(
            appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED
          );
        }}
        className="btn btn-outline text-white normal-case gap-2 bg-black bg-opacity-40"
      >
        <MdVolumeOff size={20} />
        Tap for sound
      </button>
    </div>
  ) : (
    <React.Fragment />
  );
};

const MainViewUserInteractButtonInputFileVideoShare = () => {
  const { gridWithStreamsPosition } = useAppContext();

  const isInputFileVideoShareStreamInMainView = useMemo(
    () =>
      gridWithStreamsPosition.find(
        ({ streamId }) =>
          streamId.includes(streamModes.INPUTFILEVIDYO) &&
          streamId.includes(streamTypes.SHARE)
      ),
    [gridWithStreamsPosition]
  );

  return isInputFileVideoShareStreamInMainView ? (
    <MainViewUserInteractButtonInputFileVideoShareRendered />
  ) : (
    <React.Fragment />
  );
};

export default MainViewUserInteractButtonInputFileVideoShare;
