import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import useInputFileVideoShareStream from "../../hooks/streams/useInputFileVideoShareStream";
import { useAppContext } from "../../contexts/appContextDef";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import {
  MdDelete,
  MdMoreVert,
  MdMovie,
  MdPause,
  MdPlayArrow,
  MdRemoveCircle,
} from "react-icons/md";
import useParticipantName from "../../hooks/appState/useParticipantName";
import { appModes } from "../../utils/constants";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import { VideoStreamVolumeControl } from "./InQueueVideoStreamContainer";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
import useInputFileVideoShareStreams from "../../hooks/streams/useInputFileVideoShareStreams";
import { Menu, MenuItem } from "@szhsin/react-menu";
import ChangeNameModal from "../../components/ChangeNameModal";
import useAskToLeave from "../../hooks/appState/useAskToLeave";

const ItemMenuContainer = ({
  isInMainView,
  removeFromMainViewStreams,
  isLocal,
  onStopVideo,
}: {
  setChangeNameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRemove: () => void;
  isInMainView: boolean;
  removeFromMainViewStreams: () => void;
  isLocal: boolean;
  onStopVideo: () => void;
}) => {
  const { appMode } = useAppContext();

  return (
    <Menu
      menuButton={
        <span>
          <button
            className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
          >
            <div>
              <MdMoreVert size={16} />
            </div>
          </button>
        </span>
      }
      transition
    >
      {appMode === appModes.HOST && isInMainView && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={removeFromMainViewStreams}
        >
          <div className="flex items-center justify-center">
            <MdRemoveCircle />
            <p className="ml-2">Remove from stream</p>
          </div>
        </MenuItem>
      )}
      {/* {(appMode === appModes.HOST || isLocal) && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            setChangeNameModalOpen(true);
          }}
        >
          <div className="flex items-center justify-center">
            <MdEdit />
            <p className="ml-2">Edit name</p>
          </div>
        </MenuItem>
      )} */}
      {(appMode === appModes.HOST || isLocal) && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={onStopVideo}
        >
          <div className="flex items-center justify-center">
            <MdDelete />
            <p className="ml-2">Remove from studio</p>
          </div>
        </MenuItem>
      )}
      {/* {appMode === appModes.HOST && !isLocal && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={onRemove}
        >
          <div className="flex items-center justify-center">
            <MdCancel />
            <p className="ml-2">Kick from studio</p>
          </div>
        </MenuItem>
      )} */}
    </Menu>
  );
};

const InQueueInputFileVideoShareStreamContainer = ({
  inputFileVideoId,
  participantId,
}: {
  inputFileVideoId: string;
  participantId: string;
}) => {
  const { mainViewSelectedStreams, inQueueItemContainerWidth, appMode } =
    useAppContext();
  const [changeNameModalOpen, setChangeNameModalOpen] = useState(false);

  const [isMouseOver, setIsMouseOver] = useState(false);

  const { isLocal } = useIsLocalParticipantId(participantId);

  const {
    videoTrack,
    playing,
    volume,
    changeVolume,
    seekTo,
    currentTime,
    durationInSec,
    pause,
    play,
  } = useInputFileVideoShareStream({ inputFileVideoId });

  const { disableInputFileVideoShareStream } = useInputFileVideoShareStreams();

  const { name } = useParticipantName(participantId);

  const { askToLeave } = useAskToLeave(participantId);

  const { isInMainView } = useMemo(() => {
    const streamId = generateStreamId({
      participantId,
      type: streamTypes.SHARE,
      mode: streamModes.INPUTFILEVIDYO,
      inputFileVideoId,
    });

    const isInMainView = !!mainViewSelectedStreams.get(streamId);

    return { isInMainView };
  }, [participantId, inputFileVideoId, mainViewSelectedStreams]);

  const isInMainViewRef = useRef(isInMainView);

  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const _handleAddToMainViewStreams = () => {
    addToMainViewStreams({
      mode: streamModes.INPUTFILEVIDYO,
      participantId,
      type: streamTypes.SHARE,
      inputFileVideoId,
    });
  };

  const _handleRemoveFromMainViewStreams = () => {
    removeFromMainViewStreams({
      mode: streamModes.INPUTFILEVIDYO,
      participantId,
      type: streamTypes.SHARE,
      inputFileVideoId,
    });
  };

  const _handleToggleFromMainViewStreams = () => {
    const _isInMainView = isInMainViewRef.current;

    if (_isInMainView) {
      _handleRemoveFromMainViewStreams();
    } else {
      _handleAddToMainViewStreams();
    }
  };

  const [containerHeight, setContainerHeight] = useState(0);

  const containerRef = createRef<HTMLDivElement>();

  const containerHeightRef = useRef(containerHeight);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
    }
  }, [containerRef]);

  const _handleRemoveParticipant = () => {
    // TODO: confirm to remove
    // participantRef.current.remove();

    askToLeave();
  };

  const _handleStopInputFileVideoShare = () => {
    disableInputFileVideoShareStream({ id: inputFileVideoId });

    _handleRemoveFromMainViewStreams();
  };

  return (
    <React.Fragment>
      <div
        onMouseEnter={() => {
          setIsMouseOver(true);
        }}
        onMouseLeave={() => {
          setIsMouseOver(false);
        }}
        className="rounded"
        style={{ width: inQueueItemContainerWidth }}
      >
        <div
          style={{
            height: (inQueueItemContainerWidth * 9) / 16,
            width: inQueueItemContainerWidth,
          }}
          className="relative video-cover bg-custom-blue-50"
        >
          {videoTrack && (
            <React.Fragment>
              <VideoMediaStreamPlayer
                {...{
                  videoOn: true,
                  videoTrack: videoTrack,
                  key: `VideoMediaStreamPlayer-inputfilevideoshare-stream-${videoTrack.id}-${participantId}-${inputFileVideoId}`,
                  participantId,
                  isShare: true,
                }}
              />
            </React.Fragment>
          )}

          {!isInMainView && (
            <div className="top-0 left-0 right-0 bottom-0 absolute bg-opacity-50 bg-white"></div>
          )}

          <div className="top-0 left-0 right-0 bottom-0 absolute flex justify-between">
            {appMode === appModes.HOST && (
              <div className="p-3 flex flex-1 hover:opacity-100 opacity-0 items-end justify-center">
                <button
                  className="btn btn-primary text-white btn-active"
                  onClick={_handleToggleFromMainViewStreams}
                >
                  {isInMainView ? "Remove" : "Add to stream"}
                </button>
              </div>
            )}

            <div className="absolute top-2 right-2 flex">
              <ItemMenuContainer
                isLocal={isLocal}
                onRemove={_handleRemoveParticipant}
                onStopVideo={_handleStopInputFileVideoShare}
                setChangeNameModalOpen={setChangeNameModalOpen}
                removeFromMainViewStreams={_handleRemoveFromMainViewStreams}
                isInMainView={isInMainView}
              />
            </div>

            <div className="absolute top-2 right-2 flex">
              {/* <ItemMenuContainer
            isLocal={isLocal}
            onRemove={_handleRemoveParticipant}
            onStopVideo={_handleStopInputFileVideoShare}
            setChangeNameModalOpen={setChangeNameModalOpen}
            removeFromMainViewStreams={_handleRemoveFromMainViewStreams}
            isInMainView={isInMainView}
          /> */}
            </div>
          </div>
        </div>

        <div
          ref={containerRef as React.LegacyRef<HTMLDivElement>}
          style={{ width: inQueueItemContainerWidth }}
          className={`flex items-center p-0.5 ${
            isInMainView
              ? "bg-primary text-white fill-white"
              : "bg-white text-gray-800 fill-gray-800"
          } relative overflow-hidden rounded-b-md`}
        >
          <div
            style={{ width: inQueueItemContainerWidth }}
            className="flex items-center rounded-b-md overflow-hidden relative"
          >
            <div className="w-6 flex items-center justify-center">
              <MdMovie size={16} />
            </div>
            <p className="truncate text-ellipsis ml-0.5 text-sm">{name}</p>
            {isMouseOver ? (
              <div className="absolute top-0 left-0 right-0 bottom-0 rounded-b-md overflow-hidden fill-gray-800 bg-white items-center flex">
                <button
                  onClick={() => {
                    if (playing) {
                      pause();
                    } else {
                      play();
                    }
                  }}
                >
                  {playing ? (
                    <MdPause className="fill-gray-800" />
                  ) : (
                    <MdPlayArrow className="fill-gray-800" />
                  )}
                </button>

                <VideoStreamVolumeControl
                  volume={volume as number}
                  changeVolume={changeVolume}
                />
              </div>
            ) : null}
          </div>
        </div>

        {isMouseOver && (
          <div
            style={{ bottom: containerHeight / 2 }}
            className="absolute right-0 left-0 -translate-y-1"
          >
            <input
              className="w-full"
              type="range"
              min={0}
              max={durationInSec}
              step={1}
              value={currentTime || 0}
              onChange={(e) => {
                const seekToSec = parseInt(
                  (e.target as HTMLInputElement).value
                );

                seekTo({ seekToSec });
              }}
            />
          </div>
        )}
      </div>

      {changeNameModalOpen && (
        <ChangeNameModal
          {...{
            participantId,
            handleCancel: () => {
              setChangeNameModalOpen(false);
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

export default InQueueInputFileVideoShareStreamContainer;
