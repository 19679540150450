export const getPdfInfoFromImages = (images: string[]): pdfInfoType => {
  const totalPages = images.length;

  const pagesRemoteUrls: { high: string }[] = [];

  images.forEach((image, i) => {
    pagesRemoteUrls[i + 1] = { high: image };
  });

  return { totalPages, pagesRemoteUrls };
};
