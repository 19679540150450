import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { LocalTrackPublication, RemoteTrackPublication } from "livekit-client";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppRtcAllParticipantsExtraWebcamStreams from "./useLivekitRtcAllParticipantsExtraWebcamStreams";

export const checkIsWebcamLivekitPublication = (
  publication: RemoteTrackPublication | LocalTrackPublication
) => {
  const { source, kind, trackName } = publication;

  if (
    kind === "video" &&
    source === "camera" &&
    trackName.includes("ogWebcam")
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkIsExtraWebcamLivekitPublication = (
  publication: RemoteTrackPublication | LocalTrackPublication
) => {
  const { source, kind, trackName } = publication;

  if (
    kind === "video" &&
    source === "camera" &&
    trackName.includes("extraWebcam")
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkIsMicLivekitPublication = (
  publication: RemoteTrackPublication | LocalTrackPublication
) => {
  const { source, kind, trackName } = publication;

  if (
    kind === "audio" &&
    source === "microphone" &&
    trackName.includes("ogMic")
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkIsScreenShareVideoLivekitPublication = (
  publication: RemoteTrackPublication | LocalTrackPublication
) => {
  const { source, kind, trackName } = publication;

  if (
    kind === "video" &&
    source === "screen_share" &&
    trackName.includes("ogScreenShareVideo")
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkIsScreenShareAudioLivekitPublication = (
  publication: RemoteTrackPublication | LocalTrackPublication
) => {
  const { source, kind, trackName } = publication;

  if (
    kind === "audio" &&
    source === "screen_share_audio" &&
    trackName.includes("ogScreenShareAudio")
  ) {
    return true;
  } else {
    return false;
  }
};

const useLivekitRtcRemoteParticipantMediaStats = (
  participantId: string
): {
  webcamOn: boolean;
  micOn: boolean;
  screenShareVideoOn: boolean;
  screenShareAudioOn: boolean;
  webcamTrack?: MediaStreamTrack;
  micTrack?: MediaStreamTrack;
  screenShareVideoTrack?: MediaStreamTrack;
  screenShareAudioTrack?: MediaStreamTrack;
  inputFileVideoShareStreamPublications: {
    id: string;
    fileObjectUrl: string;
    inputFileVideoSourceElementId: string;
    videoTrack?: MediaStreamTrack;
    audioTrack?: MediaStreamTrack;
    videoPublication?: RemoteTrackPublication;
    audioPublication?: RemoteTrackPublication;
  }[];
  extraWebcamStreams: {
    extraWebcamId: string;
    videoTrack?: MediaStreamTrack;
  }[];
} => {
  const appContext = useAppContext();

  const {
    livekitRemoteParticipantsTrackPublications,
  }: {
    livekitRemoteParticipantsTrackPublications: Map<
      string,
      RemoteTrackPublication[]
    >;
  } = appContext;

  const { activeInputFileVideoShareStreamAllParticipants } = appContext;

  const participantActiveInputFileVideoShareStreams = useMemo(() => {
    return activeInputFileVideoShareStreamAllParticipants.filter(
      ({ participantId: _participantId }) => participantId === _participantId
    );
  }, [activeInputFileVideoShareStreamAllParticipants, participantId]);

  const { allParticipantsExtraWebcamStreams } =
    useAppRtcAllParticipantsExtraWebcamStreams();

  const {
    webcamOn,
    webcamTrack,
    micOn,
    micTrack,
    screenShareVideoOn,
    screenShareVideoTrack,
    screenShareAudioOn,
    screenShareAudioTrack,
    inputFileVideoShareStreamPublications,
    extraWebcamStreams,
  }: {
    webcamOn: boolean;
    webcamTrack?: MediaStreamTrack;
    micOn: boolean;
    micTrack?: MediaStreamTrack;
    screenShareVideoOn: boolean;
    screenShareVideoTrack?: MediaStreamTrack;
    screenShareAudioOn: boolean;
    screenShareAudioTrack?: MediaStreamTrack;
    webcamTrackPublication?: RemoteTrackPublication;
    micTrackPublication?: RemoteTrackPublication;
    screenShareVideoTrackPublication?: RemoteTrackPublication;
    screenShareAudioTrackPublication?: RemoteTrackPublication;
    inputFileVideoShareStreamPublications: {
      id: string;
      fileObjectUrl: string;
      inputFileVideoSourceElementId: string;
      videoTrack?: MediaStreamTrack;
      audioTrack?: MediaStreamTrack;
      videoPublication?: RemoteTrackPublication;
      audioPublication?: RemoteTrackPublication;
    }[];
    extraWebcamStreams: {
      extraWebcamId: string;
      videoTrack?: MediaStreamTrack;
    }[];
  } = useMemo(() => {
    const livekitRemoteParticipantTrackPublications =
      livekitRemoteParticipantsTrackPublications.get(participantId);

    if (livekitRemoteParticipantTrackPublications) {
      let webcamOn: boolean = false;
      let webcamTrack: MediaStreamTrack | undefined = undefined;
      let micOn: boolean = false;
      let micTrack: MediaStreamTrack | undefined = undefined;
      let screenShareVideoOn: boolean = false;
      let screenShareVideoTrack: MediaStreamTrack | undefined = undefined;
      let screenShareAudioOn: boolean = false;
      let screenShareAudioTrack: MediaStreamTrack | undefined = undefined;
      let webcamTrackPublication: RemoteTrackPublication | undefined =
        undefined;
      let micTrackPublication: RemoteTrackPublication | undefined = undefined;
      let screenShareVideoTrackPublication: RemoteTrackPublication | undefined =
        undefined;
      let screenShareAudioTrackPublication: RemoteTrackPublication | undefined =
        undefined;

      const inputFileVideoShareStreamPublications: {
        id: string;
        fileObjectUrl: string;
        inputFileVideoSourceElementId: string;
        videoTrack?: MediaStreamTrack;
        audioTrack?: MediaStreamTrack;
        videoPublication?: RemoteTrackPublication;
        audioPublication?: RemoteTrackPublication;
      }[] = [];

      const extraWebcamPublications =
        livekitRemoteParticipantTrackPublications.filter((publication) =>
          checkIsExtraWebcamLivekitPublication(publication)
        );

      const uniqueExtraWebcamPublications: {
        extraWebcamId: string;
        timestamp: number;
        publication: RemoteTrackPublication;
      }[] = [];

      extraWebcamPublications.forEach((publication) => {
        const { trackName } = publication;
        const splitted = trackName.split("_");

        const extraWebcamId = splitted[2];
        const timestamp = parseInt(splitted[3]);

        const foundIndex = uniqueExtraWebcamPublications.findIndex(
          ({ extraWebcamId: _extraWebcamId }) =>
            _extraWebcamId === extraWebcamId
        );

        const found = uniqueExtraWebcamPublications[foundIndex];

        if (found) {
          if (timestamp > found.timestamp) {
            uniqueExtraWebcamPublications[foundIndex] = {
              extraWebcamId,
              timestamp,
              publication,
            };
          }
        } else {
          uniqueExtraWebcamPublications.push({
            extraWebcamId,
            timestamp,
            publication,
          });
        }
      });

      livekitRemoteParticipantTrackPublications.forEach((publication) => {
        const { track, trackSid } = publication;

        if (checkIsWebcamLivekitPublication(publication)) {
          webcamOn = true;

          if (track) {
            webcamTrack = track.mediaStreamTrack;
          }

          webcamTrackPublication = publication;
        } else if (checkIsMicLivekitPublication(publication)) {
          micOn = true;

          if (track) {
            micTrack = track.mediaStreamTrack;
          }

          micTrackPublication = publication;
        } else if (checkIsScreenShareVideoLivekitPublication(publication)) {
          screenShareVideoOn = true;

          if (track) {
            screenShareVideoTrack = track.mediaStreamTrack;
          }

          screenShareVideoTrackPublication = publication;
        } else if (checkIsScreenShareAudioLivekitPublication(publication)) {
          screenShareAudioOn = true;

          if (track) {
            screenShareAudioTrack = track.mediaStreamTrack;
          }

          screenShareAudioTrackPublication = publication;
        } else {
          participantActiveInputFileVideoShareStreams.find(
            ({ rtcClientMetadata }) => {
              const {
                id,
                fileObjectUrl,
                inputFileVideoSourceElementId,
                videoPublicationTrackSid,
                audioPublicationTrackSid,
              } = safeParseJson(rtcClientMetadata);

              if (trackSid === videoPublicationTrackSid) {
                const indexFound =
                  inputFileVideoShareStreamPublications.findIndex(
                    ({ id: _id }) => id === _id
                  );

                if (indexFound === -1) {
                  inputFileVideoShareStreamPublications.push({
                    id,
                    fileObjectUrl,
                    inputFileVideoSourceElementId,
                    videoPublication: publication,
                    videoTrack: track?.mediaStreamTrack,
                  });
                } else {
                  inputFileVideoShareStreamPublications[indexFound] = {
                    ...inputFileVideoShareStreamPublications[indexFound],
                    videoPublication: publication,
                    videoTrack: track?.mediaStreamTrack,
                  };
                }
              } else if (trackSid === audioPublicationTrackSid) {
                const indexFound =
                  inputFileVideoShareStreamPublications.findIndex(
                    ({ id: _id }) => id === _id
                  );

                if (indexFound === -1) {
                  inputFileVideoShareStreamPublications.push({
                    id,
                    fileObjectUrl,
                    inputFileVideoSourceElementId,
                    audioPublication: publication,
                    audioTrack: track?.mediaStreamTrack,
                  });
                } else {
                  inputFileVideoShareStreamPublications[indexFound] = {
                    ...inputFileVideoShareStreamPublications[indexFound],
                    audioPublication: publication,
                    audioTrack: track?.mediaStreamTrack,
                  };
                }
              }
            }
          );
        }
      });

      return {
        webcamOn,
        webcamTrack,
        micOn,
        micTrack,
        screenShareVideoOn,
        screenShareVideoTrack,
        screenShareAudioOn,
        screenShareAudioTrack,
        webcamTrackPublication,
        micTrackPublication,
        screenShareVideoTrackPublication,
        screenShareAudioTrackPublication,
        inputFileVideoShareStreamPublications,
        extraWebcamStreams:
          allParticipantsExtraWebcamStreams.get(participantId) || [],
      };
    } else {
      return {
        webcamOn: false,
        webcamTrack: undefined,
        micOn: false,
        micTrack: undefined,
        screenShareVideoOn: false,
        screenShareVideoTrack: undefined,
        screenShareAudioOn: false,
        screenShareAudioTrack: undefined,
        webcamTrackPublication: undefined,
        micTrackPublication: undefined,
        screenShareVideoTrackPublication: undefined,
        screenShareAudioTrackPublication: undefined,
        inputFileVideoShareStreamPublications: [],
        extraWebcamStreams: [],
      };
    }
  }, [
    allParticipantsExtraWebcamStreams,
    participantId,
    livekitRemoteParticipantsTrackPublications,
    participantActiveInputFileVideoShareStreams,
  ]);

  return {
    webcamOn,
    webcamTrack,
    micOn,
    micTrack,
    screenShareVideoOn,
    screenShareVideoTrack,
    screenShareAudioOn,
    screenShareAudioTrack,
    inputFileVideoShareStreamPublications,
    extraWebcamStreams,
  };
};

export default useLivekitRtcRemoteParticipantMediaStats;
