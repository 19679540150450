// import { usePubSub } from "@videosdk.live/react-sdk";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "./useValidateHostCanTakeAction";

const useRaiseHandSettings = () => {
  // const { publish } = usePubSub(appPubSubTopics.RAISE_HAND_ENABLED);

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.RAISE_HAND_ENABLED
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const enable = async (opt?: { force: true }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(
      JSON.stringify({ payload: { enabled: true } })
      // , { persist: true }
    );
  };

  const disable = async (opt?: { force: true }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(
      JSON.stringify({ payload: { enabled: false } })
      // , { persist: true }
    );
  };

  return { enable, disable };
};

export default useRaiseHandSettings;
