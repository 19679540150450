// @ts-nocheck

import { useEffect, useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import {
  appModes,
  inQueueStreamTypes,
  mainViewShareLayouts,
} from "../../utils/constants";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";

const RtmpInStreamPlayer = () => {
  const {
    allParticipantsInQueueStreams,
    mainViewSelectedStreams,
    appMode,
    mainViewLayoutActive,
  } = useAppContext();

  const { thirdPartyRtmpData } = useAppConfigContext();
  const { removeFromMainViewStreams } = useMainViewParticipants();

  const { isInMainView } = useMemo(() => {
    const streamId = generateStreamId({
      participantId: thirdPartyRtmpData?.playbackId as string,
      type: streamTypes.SHARE,
      rtmpInId: thirdPartyRtmpData?.playbackId as string,
      mode: streamModes.RTMP_IN,
    });

    return { isInMainView: !!mainViewSelectedStreams.get(streamId) };
  }, [mainViewSelectedStreams, thirdPartyRtmpData]);

  const { shouldPlay, shouldMute } = useMemo(() => {
    const isLive = !!allParticipantsInQueueStreams.find(
      ({ type }) => type === inQueueStreamTypes.SHARE_RTMP_IN
    );

    const shouldPlay = (appMode === appModes.HOST || isInMainView) && isLive;

    const shouldMute = !(
      isInMainView &&
      isLive &&
      (mainViewLayoutActive === mainViewShareLayouts.CINEMA ||
        mainViewLayoutActive === mainViewShareLayouts.NEWS ||
        mainViewLayoutActive === mainViewShareLayouts.PICTURE_IN_PICTURE ||
        mainViewLayoutActive === mainViewShareLayouts.SCREEN)
    );

    return { shouldPlay, shouldMute };
  }, [
    allParticipantsInQueueStreams,
    appMode,
    mainViewLayoutActive,
    isInMainView,
  ]);

  useEffect(() => {
    if (shouldPlay) {
      const videoSrc = `https://stream.mux.com/${thirdPartyRtmpData?.playbackId}.m3u8`;

      const videoTagId = `RtmpInStreamPlayer-videotag-${thirdPartyRtmpData?.playbackId}`;

      const video = document.getElementById(
        videoTagId
      ) as StudioHTMLVideoElement;

      if (window.Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(video);
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc;
      }

      video
        .play()
        .then(() => {})
        .catch(() => {});
    }
  }, [thirdPartyRtmpData, shouldPlay]);

  const _handleRemoveFromMainViewStreams = () => {
    removeFromMainViewStreams({
      participantId: thirdPartyRtmpData?.playbackId as string,
      type: streamTypes.SHARE,
      rtmpInId: thirdPartyRtmpData?.playbackId as string,
      mode: streamModes.RTMP_IN,
    });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.REMOVE_FROM_MAIN_VIEW_STREAMS,
      _handleRemoveFromMainViewStreams
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.REMOVE_FROM_MAIN_VIEW_STREAMS,
        _handleRemoveFromMainViewStreams
      );
    };
  }, []);

  return (
    <video
      muted={shouldMute}
      playsInline
      autoPlay
      height={shouldPlay ? "100vh" : 0}
      width={shouldPlay ? "100vw" : 0}
      style={{
        height: shouldPlay ? "100vh" : 0,
        width: shouldPlay ? "100vw" : 0,
        backgroundColor: "transparent",
        opacity: 0,
        zIndex: -1000000,
      }}
      id={`RtmpInStreamPlayer-videotag-${thirdPartyRtmpData?.playbackId}`}
    />
  );
};

export default RtmpInStreamPlayer;
