import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MdRemoveCircleOutline,
  MdAddCircleOutline,
  MdDelete,
  MdEdit,
  MdMoreVert,
} from "react-icons/md";
import BackgroundMusicsContainer from "./brandItems/BackgroundMusicsContainer";
import VideoClipsContainer from "./brandItems/VideoClipsContainer";
import BackgroundsContainer from "./brandItems/BackgroundsContainer";
import OverlaysContainer from "./brandItems/OverlaysContainer";
import BrandLogosContainer from "./brandItems/BrandLogosContainer";
import BrandColorContainer from "./brandItems/BrandColorContainer";
import { useAppContext } from "../../contexts/appContextDef";
import Select from "../../components/Select";
import useBrands from "../../hooks/brands/useBrands";
import Modal from "../../components/Modal";
import { Menu, MenuItem } from "@szhsin/react-menu";

import { appEventEmitterEvents } from "../../utils/appEventEmitter";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";

export const OverlayItemContainer = ({
  isSelected,
  id,
  remoteUrl,
  title,
  onClick,
  onDeSelect,
}: {
  isSelected: boolean;
  id: string;
  remoteUrl: string;
  title: string;
  onClick: () => void;
  onDeSelect: () => void;
}) => {
  const [isMouseOver, setIsMouseOver] = useState<boolean>();

  return (
    <div
      onMouseEnter={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
      className="relative"
    >
      <div
        onClick={onClick}
        className={`flex border-4 rounded-md overflow-hidden ${
          isSelected ? "border-primary bg-primary" : ""
        }`}
      >
        <div className="aspect-video rounded-md overflow-hidden max-h-16 bg-gray-300">
          <img src={remoteUrl} alt={id} />
        </div>
        <div className="flex flex-1 p-3 items-center justify-start relative">
          <p className={isSelected ? "text-white" : ""}>{title}</p>
          {isMouseOver ? (
            isSelected ? (
              <div className="absolute  overflow-hidden top-0 right-0 left-0 bottom-0 bg-primary text-white fill-white flex items-center justify-center">
                <div>
                  <MdRemoveCircleOutline size={24} />
                </div>
                <p className="p-2">Hide</p>
              </div>
            ) : (
              <div
                onClick={onDeSelect}
                className="absolute overflow-hidden top-0 right-0 left-0 bottom-0 bg-primary  text-white fill-white  flex items-center justify-center"
              >
                <div>
                  <MdAddCircleOutline size={24} />
                </div>
                <p className="p-2">Show</p>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

const NewBrandContainer = ({
  createBrandModelOpen,
  setCreateBrandModelOpen,
}: {
  createBrandModelOpen: boolean;
  setCreateBrandModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [brandName, setBrandName] = useState("");
  const [brandLimitReachedErr, setBrandLimitReachedErr] = useState(false);

  const brandNameRef = useRef(brandName);

  const { brands } = useAppContext();

  const brandsRef = useRef(brands);

  useEffect(() => {
    brandsRef.current = brands;
  }, [brands]);

  useEffect(() => {
    brandNameRef.current = brandName;
  }, [brandName]);

  const { add, active } = useBrands();

  const _handleAdd = async () => {
    if (brandsRef.current?.length === 5) {
      setBrandLimitReachedErr(true);
    } else {
      const name = brandNameRef.current;

      if (name.length) {
        const id = await add({ name });

        await new Promise((resolve) => setTimeout(resolve, 100));

        await active({ id });

        setBrandName("");
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        {...{
          onClose: () => {
            setCreateBrandModelOpen(false);
            setBrandName("");
          },
          onSuccess: _handleAdd,
          successText: "Create brand",
          cancelText: "Cancel",
          title: "Create new brand",
          isOpen: createBrandModelOpen,
          setIsOpen: setCreateBrandModelOpen,
          renderBody: () => {
            return (
              <div>
                <input
                  value={brandName}
                  onChange={(e) => {
                    setBrandName(e.target.value);
                  }}
                  type="text"
                  placeholder="Brand name"
                  className="input input-bordered w-full"
                />
              </div>
            );
          },
        }}
      />

      <Modal
        {...{
          onClose: () => {
            setBrandLimitReachedErr(false);
            setBrandName("");
          },
          renderOnlyBody: true,
          showCloseIcon: true,

          title: "Limit reached!",
          isOpen: brandLimitReachedErr,
          setIsOpen: setBrandLimitReachedErr,
          renderBody: () => {
            return <div>You can only create upto 5 brands!</div>;
          },
        }}
      />
    </React.Fragment>
  );
};

const EditBrandNameContainer = ({
  editBrandNameModelOpen,
  setEditBrandNameModelOpen,
  name,
  id,
}: {
  editBrandNameModelOpen: boolean;
  setEditBrandNameModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  id: string;
}) => {
  const [brandName, setBrandName] = useState(name);

  const brandNameRef = useRef(brandName);

  useEffect(() => {
    brandNameRef.current = brandName;
  }, [brandName]);

  const { rename } = useBrands();

  const _handleRename = async () => {
    const name = brandNameRef.current;

    if (name?.length) {
      await rename({ id, name });
    }
  };

  return (
    <Modal
      {...{
        onClose: () => {
          setEditBrandNameModelOpen(false);
          setBrandName("");
        },
        onSuccess: _handleRename,
        successText: "Rename brand ",
        cancelText: "Cancel",
        title: "Rename brand",
        isOpen: editBrandNameModelOpen,
        setIsOpen: setEditBrandNameModelOpen,
        renderBody: () => {
          return (
            <div>
              <input
                value={brandName}
                onChange={(e) => {
                  setBrandName(e.target.value);
                }}
                type="text"
                placeholder="Rename brand"
                className="input input-bordered w-full"
              />
            </div>
          );
        },
      }}
    />
  );
};

const BrandPanel = () => {
  const [cannotDeleteLastBrandOpen, setCannotDeleteLastBrandOpen] =
    useState(false);
  const { brands, activeBrandId, settingBrandsAndAssets } = useAppContext();

  const [createBrandModelOpen, setCreateBrandModelOpen] = useState(false);
  const [editBrandNameModelOpen, setEditBrandNameModelOpen] = useState(false);

  const { active, deactivate, remove } = useBrands();

  const activeBrand = useMemo(
    () => brands.find(({ id }) => id === activeBrandId),
    [brands, activeBrandId]
  );

  const activeBrandIdRef = useRef(activeBrandId);
  const brandsRef = useRef(brands);

  useEffect(() => {
    activeBrandIdRef.current = activeBrandId;
  }, [activeBrandId]);
  useEffect(() => {
    brandsRef.current = brands;
  }, [brands]);

  const _handleRemoveBrand = async () => {
    if (brandsRef.current.length === 1) {
      setCannotDeleteLastBrandOpen(true);
    } else {
      const brandIdToBedeleted = activeBrandIdRef.current;

      if (brandIdToBedeleted) {
        await remove({ id: brandIdToBedeleted });

        await new Promise((resolve) => setTimeout(resolve, 100));

        const newBrand = brandsRef.current.find(
          ({ id: _id }) => _id !== brandIdToBedeleted
        );

        if (newBrand) {
          await active({ id: newBrand.id });
        } else {
          deactivate();
        }
      }
    }
  };

  return (
    <div>
      <div
        className={`py-3 px-2 flex justify-between items-center border-b ${quickstartTargetComponentClassNames.brand_list}`}
      >
        {brands.length && activeBrandId ? (
          <React.Fragment>
            <div className="">
              <Select
                value={activeBrandId as string}
                setValue={(id) => {
                  if (id === "ADD") {
                    setCreateBrandModelOpen(true);
                  } else {
                    active({ id });
                  }
                }}
                items={[
                  ...brands.map(({ id, name }) => ({ id, title: name })),
                  { id: "ADD", title: "New Brand", icon: "add" },
                ]}
              />
            </div>

            <Menu
              menuButton={
                <span>
                  <button
                    className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
                  >
                    <div>
                      <MdMoreVert size={16} />
                    </div>
                  </button>
                </span>
              }
              transition
            >
              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={() => {
                  setEditBrandNameModelOpen(true);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdEdit />
                  <p className="ml-2">Rename brand</p>
                </div>
              </MenuItem>

              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={() => {
                  _handleRemoveBrand();
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdDelete />
                  <p className="ml-2">Delete brand</p>
                </div>
              </MenuItem>
            </Menu>
          </React.Fragment>
        ) : settingBrandsAndAssets ? (
          <div>
            <p>Fetching brands...</p>
          </div>
        ) : (
          <React.Fragment>
            <button
              onClick={() => {
                setCreateBrandModelOpen(true);
              }}
              className="btn btn-primary text-white"
            >
              New Brand
            </button>
          </React.Fragment>
        )}
      </div>
      <div className="pb-3">
        {brands.map((brand) => {
          const { id, defaultOpen } = brand;

          const isActive = id === activeBrandId;

          return (
            <div
              className={`${isActive ? "" : "hidden"}`}
              key={`brand-panel-items-${id}`}
            >
              <span
                className={`${
                  isActive
                    ? quickstartTargetComponentClassNames.brand_theme
                    : ""
                }`}
              >
                <BrandColorContainer
                  {...{
                    defaultOpen,
                    listenToCloseEvent: isActive
                      ? appEventEmitterEvents.QUICKSTART_CLOSE_BRAND_THEME
                      : undefined,
                    brandId: id,
                  }}
                />
              </span>
              <span
                className={`${
                  isActive ? quickstartTargetComponentClassNames.brand_logo : ""
                }`}
              >
                <BrandLogosContainer
                  {...{
                    defaultOpen,
                    listenToCloseEvent: isActive
                      ? appEventEmitterEvents.QUICKSTART_CLOSE_BRAND_LOGO
                      : undefined,
                    brandId: id,
                  }}
                />
              </span>
              <span
                className={`${
                  isActive
                    ? quickstartTargetComponentClassNames.brand_overlay
                    : ""
                }`}
              >
                <OverlaysContainer
                  {...{
                    defaultOpen,
                    listenToCloseEvent: isActive
                      ? appEventEmitterEvents.QUICKSTART_CLOSE_BRAND_OVERLAY
                      : undefined,
                    brandId: id,
                  }}
                />
              </span>
              <span
                className={`${
                  isActive
                    ? quickstartTargetComponentClassNames.brand_video
                    : ""
                }`}
              >
                <VideoClipsContainer
                  {...{
                    defaultOpen,
                    listenToCloseEvent: isActive
                      ? appEventEmitterEvents.QUICKSTART_CLOSE_BRAND_VIDEO
                      : undefined,
                    brandId: id,
                  }}
                />
              </span>
              <span
                className={`${
                  isActive
                    ? quickstartTargetComponentClassNames.brand_background
                    : ""
                }`}
              >
                <BackgroundsContainer
                  {...{
                    defaultOpen,
                    listenToCloseEvent: isActive
                      ? appEventEmitterEvents.QUICKSTART_CLOSE_BRAND_BACKGROUND
                      : undefined,
                    brandId: id,
                  }}
                />
              </span>
              <span
                className={`${
                  isActive
                    ? quickstartTargetComponentClassNames.brand_music
                    : ""
                }`}
              >
                <BackgroundMusicsContainer
                  {...{
                    defaultOpen,
                    listenToCloseEvent: isActive
                      ? appEventEmitterEvents.QUICKSTART_CLOSE_BRAND_MUSIC
                      : undefined,
                    brandId: id,
                  }}
                />
              </span>
            </div>
          );
        })}
      </div>

      <NewBrandContainer
        {...{ createBrandModelOpen, setCreateBrandModelOpen }}
      />

      {editBrandNameModelOpen && (
        <EditBrandNameContainer
          {...{
            editBrandNameModelOpen,
            setEditBrandNameModelOpen,
            name: activeBrand?.name as string,
            id: activeBrandId as string,
          }}
        />
      )}

      <Modal
        {...{
          onClose: () => {
            setCannotDeleteLastBrandOpen(false);
          },
          title: "Cannot delete brand",
          isOpen: cannotDeleteLastBrandOpen,
          setIsOpen: setCannotDeleteLastBrandOpen,
          showCloseIcon: true,
          renderOnlyBody: true,
          renderBody: () => {
            return (
              <p>
                You cannot delete the last brand, create a new brand to delete
                this brand.
              </p>
            );
          },
        }}
      />
    </div>
  );
};

export default BrandPanel;
