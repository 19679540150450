import {
  mainViewItemsAnimationDuration,
  mainViewItemsAnimationFunctionName,
  mainViewItemsAnimationDuration_fast,
  mainViewItemsAnimationDuration_normal,
  mainViewItemsAnimationDuration_slow,
} from "./constants";

export const transitionSpeeds = {
  fast: "fast",
  normal: "normal",
  slow: "slow",
};

const getTransitionStyle = (args: string[], speed?: string) =>
  args
    .map(
      (p) =>
        `${p} ${
          speed === transitionSpeeds.fast
            ? mainViewItemsAnimationDuration_fast
            : speed === transitionSpeeds.normal
            ? mainViewItemsAnimationDuration_normal
            : speed === transitionSpeeds.slow
            ? mainViewItemsAnimationDuration_slow
            : mainViewItemsAnimationDuration
        } ${mainViewItemsAnimationFunctionName}`
    )
    .join(", ");

export default getTransitionStyle;
