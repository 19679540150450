import PollDetail from "../sidePanel/polls/PollDetail";

const MainViewAppPollShareStreamContainer = ({
  pollId,
  relativeWidth,
  mainViewContainerWidth,
}: {
  pollId: string;
  relativeWidth: number;
  mainViewContainerWidth: number;
}) => {
  return (
    <div className="flex flex-1 flex-col p-3 h-full">
      <div className="flex items-center justify-center text-primary">
        <div className="flex flex-1 items-center justify-center">
          <p
            style={{
              fontSize: relativeWidth * mainViewContainerWidth * 0.00045,
            }}
            className="text-center font-bold text-center"
          >
            Poll
          </p>
        </div>
      </div>
      <PollDetail
        {...{
          relativeWidth,
          mainViewContainerWidth,
          streamingView: true,
          id: parseInt(pollId),
        }}
      />
    </div>
  );
};

export default MainViewAppPollShareStreamContainer;
