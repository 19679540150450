// import { usePubSub } from "@videosdk.live/react-sdk";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useEffect, useRef } from "react";
import sleep from "../../utils/sleep";
import {
  generateStreamId,
  mainStreamActions,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import {
  mainViewMaxWebcamStreamsCount,
  mainViewShareLayouts,
} from "../../utils/constants";
import useMainViewLayout from "./useMainViewLayout";
import { getShareEquivalantLayout } from "../../listners/appState/MainViewLayoutListner";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useValidateHostCanTakeAction from "./useValidateHostCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useMainViewParticipants = () => {
  const {
    mainViewGrid,
    mainViewSelectedStreams,
    conferenceAutoMainVideoLayoutEnabled,
  } = useAppContext();

  const { changeMainViewLayout, mainViewLayoutSelected } = useMainViewLayout();

  const mainViewSelectedStreamsRef = useRef(mainViewSelectedStreams);
  const mainViewGridRef = useRef(mainViewGrid);
  const mainViewLayoutSelectedRef = useRef(mainViewLayoutSelected);
  const conferenceAutoMainVideoLayoutEnabledRef = useRef(
    conferenceAutoMainVideoLayoutEnabled
  );

  useEffect(() => {
    mainViewSelectedStreamsRef.current = mainViewSelectedStreams;
  }, [mainViewSelectedStreams]);

  useEffect(() => {
    mainViewGridRef.current = mainViewGrid;
  }, [mainViewGrid]);

  useEffect(() => {
    mainViewLayoutSelectedRef.current = mainViewLayoutSelected;
  }, [mainViewLayoutSelected]);
  useEffect(() => {
    conferenceAutoMainVideoLayoutEnabledRef.current =
      conferenceAutoMainVideoLayoutEnabled;
  }, [conferenceAutoMainVideoLayoutEnabled]);

  // const { publish: mainViewStreamsPublish } = usePubSub(
  //   appPubSubTopics.MAIN_VIEW_STREAMS
  // );
  const { publish: mainViewStreamsPublish } = useAppSingalingPublish(
    appPubSubTopics.MAIN_VIEW_STREAMS
  );

  // const { publish: mainViewGridPublish } = usePubSub(
  //   appPubSubTopics.MAIN_VIEW_GRID
  // );
  const { publish: mainViewGridPublish } = useAppSingalingPublish(
    appPubSubTopics.MAIN_VIEW_GRID
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const addToMainViewStreams = async ({
    participantId,
    type,
    mode,
    fileId,
    videoId,
    pollId,
    wordcloudId,
    qnaId,
    inputFileVideoId,
    extraWebcamId,
    rtmpInId,
  }: {
    participantId: string;
    type: string;
    mode?: string;
    fileId?: string;
    videoId?: string;
    pollId?: string;
    wordcloudId?: string;
    qnaId?: string;
    inputFileVideoId?: string;
    extraWebcamId?: string;
    rtmpInId?: string;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const mainViewLayoutSelected = mainViewLayoutSelectedRef.current;

    if (!participantId || !type) return;

    if (type === streamTypes.SHARE && !mode) return;

    const _mainViewSelectedStreams = new Map(
      mainViewSelectedStreamsRef.current
    );

    const _mainViewSelectedWebcamStreamIds = [
      ..._mainViewSelectedStreams.keys(),
    ].filter((streamId) => streamId.includes(streamTypes.WEBCAM));

    const streamId = generateStreamId({
      participantId,
      type,
      mode,
      fileId,
      videoId,
      pollId,
      wordcloudId,
      qnaId,
      inputFileVideoId,
      extraWebcamId,
      rtmpInId,
    });

    const streamAlreadyExists = !!_mainViewSelectedStreams.get(streamId);

    if (streamAlreadyExists) return;

    if (type === streamTypes.SHARE) {
      const shareStreamIdToBeDeleted = [
        ..._mainViewSelectedStreams.keys(),
      ].find((key) => key.includes(`_${streamTypes.SHARE}_`));

      if (shareStreamIdToBeDeleted) {
        removeStreamIdFromMainViewStreams({
          streamId: shareStreamIdToBeDeleted,
        });

        await sleep();
      }
    } else if (
      (type === streamTypes.WEBCAM || type === streamTypes.EXTRAWBCM) &&
      _mainViewSelectedWebcamStreamIds.length === mainViewMaxWebcamStreamsCount
    ) {
      return;
    }

    const action = mainStreamActions.ADD_STREAM;

    mainViewStreamsPublish(
      JSON.stringify(
        type === streamTypes.SHARE
          ? {
              type,
              participantId,
              action,
              streamId,
              mode,
              fileId,
              videoId,
              pollId,
              wordcloudId,
              qnaId,
              inputFileVideoId,
              extraWebcamId,
              rtmpInId,
            }
          : { type, participantId, action, streamId }
      )
      // { persist: true }
    );

    if (type === streamTypes.WEBCAM || type === streamTypes.EXTRAWBCM) {
      const _mainViewGrid = [...mainViewGridRef.current, streamId];
      mainViewGridPublish(
        JSON.stringify(_mainViewGrid)
        // , { persist: true }
      );
    }

    const isShareLayoutSelected = Object.keys(mainViewShareLayouts).includes(
      mainViewLayoutSelected
    );

    if (type === streamTypes.SHARE && !isShareLayoutSelected) {
      const conferenceAutoMainVideoLayoutEnabled =
        conferenceAutoMainVideoLayoutEnabledRef.current;

      changeMainViewLayout({
        layout: getShareEquivalantLayout(mainViewLayoutSelected),
        conferenceAutoMainVideoLayoutEnabled,
      });
    }

    appEventEmitter.emit(
      appEventEmitterEvents.HIDE_HOST_IN_QUEUE_POPUP_MESSAGE
    );
  };

  const removeFromMainViewStreams = async ({
    participantId,
    type,
    mode,
    fileId,
    videoId,
    pollId,
    wordcloudId,
    qnaId,
    inputFileVideoId,
    extraWebcamId,
    rtmpInId,
  }: {
    participantId: string;
    type: string;
    mode?: string;
    fileId?: string;
    videoId?: string;
    pollId?: string;
    wordcloudId?: string;
    qnaId?: string;
    inputFileVideoId?: string;
    extraWebcamId?: string;
    rtmpInId?: string;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (!participantId || !type) return;

    if (type === streamTypes.SHARE && !mode) return;

    const _mainViewSelectedStreams = new Map(
      mainViewSelectedStreamsRef.current
    );

    const streamId = generateStreamId({
      participantId,
      type,
      mode,
      fileId,
      videoId,
      pollId,
      wordcloudId,
      qnaId,
      inputFileVideoId,
      extraWebcamId,
      rtmpInId,
    });

    const streamDoesntExists = !_mainViewSelectedStreams.get(streamId);

    if (streamDoesntExists) return;

    const action = mainStreamActions.REMOVE_STREAM;

    mainViewStreamsPublish(
      JSON.stringify(
        type === streamTypes.SHARE
          ? {
              type,
              participantId,
              action,
              streamId,
              mode,
              fileId,
              videoId,
              pollId,
              wordcloudId,
              qnaId,
              inputFileVideoId,
              extraWebcamId,
              rtmpInId,
            }
          : { type, participantId, action, streamId }
      )
      // { persist: true }
    );

    if (type === streamTypes.WEBCAM || type === streamTypes.EXTRAWBCM) {
      const _mainViewGrid = [...mainViewGridRef.current];

      const filteredMainViewGrid = _mainViewGrid.filter(
        (_streamId) => _streamId !== streamId
      );

      mainViewGridPublish(
        JSON.stringify(filteredMainViewGrid)
        // , {
        //   persist: true,
        // }
      );
    }
  };

  const removeStreamIdFromMainViewStreams = ({
    streamId,
  }: {
    streamId: string;
  }) => {
    const [participantId, type, mode, fileIdOrVideoId] = streamId.split("_");

    removeFromMainViewStreams({
      participantId,
      type,
      mode: type === streamTypes.EXTRAWBCM ? undefined : mode,
      videoId: fileIdOrVideoId,
      fileId: fileIdOrVideoId,
      pollId: fileIdOrVideoId,
      wordcloudId: fileIdOrVideoId,
      qnaId: fileIdOrVideoId,
      inputFileVideoId: fileIdOrVideoId,
      extraWebcamId: type === streamTypes.EXTRAWBCM ? mode : fileIdOrVideoId,
      rtmpInId: fileIdOrVideoId,
    });
  };

  const swapMainViewStreams = async ({
    streamId1,
    streamId2,
  }: {
    streamId1: string;
    streamId2: string;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (
      streamId1.includes(`_${streamTypes.SHARE}_`) ||
      streamId2.includes(`_${streamTypes.SHARE}_`)
    )
      return;

    const _mainViewGrid = [...mainViewGridRef.current];

    const streamIndex1 = _mainViewGrid.findIndex(
      (streamId) => streamId === streamId1
    );
    const streamIndex2 = _mainViewGrid.findIndex(
      (streamId) => streamId === streamId2
    );

    _mainViewGrid[streamIndex1] = streamId2;
    _mainViewGrid[streamIndex2] = streamId1;

    mainViewGridPublish(
      JSON.stringify(_mainViewGrid)
      // , { persist: true }
    );
  };

  const swapWebcamStreamWithTopStream = ({
    streamId,
  }: {
    streamId: string;
  }) => {
    if (streamId.includes(`_${streamTypes.SHARE}_`)) return;

    const _mainViewGrid = [...mainViewGridRef.current];

    const firstWebcamStream = _mainViewGrid.find(
      (_streamId) =>
        _streamId.includes(streamTypes.WEBCAM) ||
        _streamId.includes(streamTypes.EXTRAWBCM)
    );

    if (firstWebcamStream === streamId) return;

    if (streamId && firstWebcamStream) {
      swapMainViewStreams({
        streamId1: streamId,
        streamId2: firstWebcamStream,
      });
    }
  };

  return {
    swapWebcamStreamWithTopStream,
    swapMainViewStreams,
    addToMainViewStreams,
    removeFromMainViewStreams,
  };
};

export default useMainViewParticipants;
