import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import stopBroadcastEgresses from "../../apis/egress/stopBroadcastEgresses";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useInterpretationViaAiData } from "./LocalParticipantTranslatedAudioBroadcasterContainer";
import stopAIInterpreter from "../../apis/egress/stopAIInterpreter";

const RecorderParticipantOnlyTimeoutAutoCloseRoom = () => {
  const autoCloseTimeoutStartedAt = useRef<number | null>(null);

  const { broadcastId } = useAppConfigContext();
  const { localParticipantId, joinedParticipants, interpreterParticipantIds } =
    useAppContext();

  const { interpretationViaAiData } = useInterpretationViaAiData();

  const localParticipantIdRef = useRef(localParticipantId);
  const joinedParticipantsRef = useRef(joinedParticipants);
  const interpreterParticipantIdsRef = useRef(interpreterParticipantIds);
  const broadcastIdRef = useRef(broadcastId);
  const interpretationViaAiDataRef = useRef(interpretationViaAiData);

  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);
  useEffect(() => {
    joinedParticipantsRef.current = joinedParticipants;
  }, [joinedParticipants]);
  useEffect(() => {
    interpreterParticipantIdsRef.current = interpreterParticipantIds;
  }, [interpreterParticipantIds]);
  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);
  useEffect(() => {
    interpretationViaAiDataRef.current = interpretationViaAiData;
  }, [interpretationViaAiData]);

  const { publish: endCallPublish } = useAppSingalingPublish(
    appPubSubTopics.END_CALL
  );

  const checkShouldAutoCloseRoom = async () => {
    const joinedParticipants = joinedParticipantsRef.current;
    const interpreterParticipantIds = interpreterParticipantIdsRef.current;
    const interpretationViaAiData = interpretationViaAiDataRef.current;

    if (
      !joinedParticipants.length &&
      (interpretationViaAiData.length
        ? true
        : !interpreterParticipantIds.length)
    ) {
      if (autoCloseTimeoutStartedAt.current) {
        if (
          new Date().getTime() - 5 * 60 * 1000 >
          autoCloseTimeoutStartedAt.current
        ) {
          // stop session and close all egresses

          await stopBroadcastEgresses({ broadcastId });

          await stopAIInterpreter({ broadcastId });

          endCallPublish(
            JSON.stringify({ end: true, endedReason: "empty_room_auto_closed" })
          );
        }
      } else {
        autoCloseTimeoutStartedAt.current = new Date().getTime();
      }
    } else {
      autoCloseTimeoutStartedAt.current = null;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkShouldAutoCloseRoom();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <React.Fragment />;
};

export default RecorderParticipantOnlyTimeoutAutoCloseRoom;
