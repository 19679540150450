import { useState } from "react";
import { MdDelete, MdEdit, MdFolder, MdMoreVert } from "react-icons/md";
import useBannerFolders from "../../../../hooks/banners/useBannerFolders";
import { useAppContext } from "../../../../contexts/appContextDef";
import { Menu, MenuItem } from "@szhsin/react-menu";
import Modal from "../../../../components/Modal";
import { sidePanelBannersModes } from "../../../../utils/constants";

const BannerFolderItem = ({
  id,
  name,
  banners,
}: {
  id: string;
  name: string;
  banners: bannersType;
}) => {
  const { setSidePanelBannersMode, setSidePanelActiveBanerId } =
    useAppContext();

  const [editNameText, setEditNameText] = useState("");
  const [editNameModalOpen, setEditNameModalOpen] = useState(false);

  const { deleteBannerFolder, updateBannerFolder } = useBannerFolders();

  const _handleDeleteFolder = async () => {
    await deleteBannerFolder({ folderId: id });
  };

  const _handleUpdateBannerFolder = async ({ name }: { name: string }) => {
    await updateBannerFolder({ folderId: id, name });
  };

  return (
    <div className="p-3 flex items-center justify-center border-b cursor-pointer">
      <div
        className="flex items-center justify-center flex-1"
        onClick={() => {
          setSidePanelBannersMode(sidePanelBannersModes.BANNERS_LIST);

          setSidePanelActiveBanerId(id);
        }}
      >
        <div>
          <MdFolder size={20} />
        </div>

        <div className="flex flex-1 flex-col ml-3">
          <p className="font-bold">{name}</p>
          <p className="text-xs font-gray-500 mt-0.5">
            {banners.length} banners
          </p>
        </div>
      </div>

      <Menu
        menuButton={
          <span>
            <button
              className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
            >
              <MdMoreVert size={20} />
            </button>
          </span>
        }
        transition
      >
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            setEditNameText(name);
            setEditNameModalOpen(true);
          }}
        >
          <div className="flex items-center justify-center gap-1">
            <MdEdit />
            <p className="ml-2">Rename folder</p>
          </div>
        </MenuItem>

        {/* <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {}}
        >
          <div className="flex items-center justify-center gap-1">
            <MdCopyAll />
            <p className="ml-2">Duplicate folder</p>
          </div>
        </MenuItem> */}

        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            _handleDeleteFolder();
          }}
        >
          <div className="flex items-center justify-center gap-1">
            <MdDelete />
            <p className="ml-2">Delete folder</p>
          </div>
        </MenuItem>
      </Menu>

      <Modal
        title={"Edit folder name"}
        isOpen={editNameModalOpen}
        onClose={() => {
          setEditNameText("");
          setEditNameModalOpen(false);
        }}
        onSuccess={() => {
          const _newFolderName = editNameText;

          setEditNameText("");
          setEditNameModalOpen(false);

          _handleUpdateBannerFolder({ name: _newFolderName });
        }}
        successText={"Save"}
        cancelText={"Cancel"}
        showCloseIcon
        renderBody={() => {
          return (
            <input
              value={editNameText}
              onChange={(e) => {
                setEditNameText(e.target.value);
              }}
              placeholder="Folder name"
              className="input w-full input-bordered"
            />
          );
        }}
      />
    </div>
  );
};

export default BannerFolderItem;
