import { useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { MdAdd, MdAddCircleOutline } from "react-icons/md";
import activateBrandAsset from "../../../apis/brands/activate-brand-asset";
import deactivateBrandAsset from "../../../apis/brands/deactivate-brand-asset";
import deleteBrandAsset from "../../../apis/brands/delete-brand-asset";
import updateBrandAsset from "../../../apis/brands/update-brand-asset";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import { useAppContext } from "../../../contexts/appContextDef";
import useOverlays from "../../../hooks/brands/useOverlays";
import useMainViewOverlay from "../../../hooks/activeBrand/useMainViewOverlay";
import useIsFreePlanActive from "../../../hooks/plans/useIsFreePlanActive";
import { momentoStreamDefaultOverlay } from "../../../static/images";
import UpgradeNowLine from "../../../components/UpgradeNowLine";
import AddNewOrSelectDefaultAssetsModal from "../../../components/AddNewOrSelectDefaultAssetsModal";
import BrandAssetsDraggableItem from "./BrandAssetsDraggableItem";
import useIsProfessionalPlanActive from "../../../hooks/plans/useIsProfessionalPlanActive";
import Tooltip from "../../../components/Tooltip";
import { createUID } from "../../../utils/createUID";
import BrandUploadingAndProcessingItems from "./BrandUploadingAndProcessingItems";
import removeExtensionFromFileName from "../../../utils/removeExtensionFromFileName";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";
import { appQueueEvents } from "../../../listners/appState/AppQueueListner";
import { mainViewOverlayTypes } from "../../../utils/constants";
import DisclosureContainer from "../../../components/DisclosureContainer";

const OverlaysContainer = ({
  listenToCloseEvent,
  defaultOpen,
  brandId,
}: {
  listenToCloseEvent?: string;
  defaultOpen?: boolean;
  brandId: string;
}) => {
  const {
    mainViewOverlay,
    sortedOverlayIds: allBrandsSortedOverlayIds,
    sortedOverlays: allBrandSortedOverlays,
    newOverlaysState,
  } = useAppContext();

  const { userId } = useAppConfigContext();

  const sortedOverlays = useMemo(
    () => allBrandSortedOverlays[brandId] || [],
    [allBrandSortedOverlays, brandId]
  );

  const sortedOverlayIds = useMemo(
    () => allBrandsSortedOverlayIds[brandId] || [],
    [allBrandsSortedOverlayIds, brandId]
  );

  const { add, sort, remove, rename } = useOverlays({ id: brandId });
  const { changeOverlay } = useMainViewOverlay();

  const { isProfessionalPlanActive } = useIsProfessionalPlanActive();

  const { isFreePlanActive } = useIsFreePlanActive();

  const [_sortedOverlays, setSortedOverlays] = useState(sortedOverlays);

  const [assetsSelectionModalOpen, setAssetsSelectionModalOpen] =
    useState(false);

  useEffect(() => {
    setSortedOverlays(sortedOverlays);
  }, [sortedOverlays]);

  const sortedOverlayIdsRef = useRef(sortedOverlayIds);

  useEffect(() => {
    sortedOverlayIdsRef.current = sortedOverlayIds;
  }, [sortedOverlayIds]);

  const onFileEvent = async ({
    remoteUrl,
    singleFile,
    fileName,
  }: {
    remoteUrl?: string;
    singleFile?: File;
    fileName?: string;
  }) => {
    const uploadId = createUID();

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BRAND_OVERLAY,
      //
      isUploading: true,
      isProcessing: false,
      isCompleted: false,
      //
      brandId,
      uploadId,
      name: fileName,
    });

    const newId = await add({ singleFile, remoteUrl, uploadId });

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BRAND_OVERLAY,
      //
      isUploading: false,
      isProcessing: false,
      isCompleted: true,
      //
      brandId,
      uploadId,
      name: fileName,
    });

    if (newId) {
      await new Promise((resolve) => setTimeout(resolve, 100));

      const _sortedOverlayIds = [...sortedOverlayIdsRef.current];

      sort({ ids: [..._sortedOverlayIds, newId] });
    }
  };

  const reorder = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (
      typeof result?.source?.index == "number" &&
      typeof result?.destination?.index === "number"
    ) {
      const _sortedBackgroundIds = reorder(
        [...sortedOverlayIdsRef.current],
        result.source.index,
        result.destination.index
      );

      setSortedOverlays((s) =>
        _sortedBackgroundIds.map(
          (id) => s.find(({ id: _id }) => id === _id) as singleBrandOverlayType
        )
      );

      sort({ ids: _sortedBackgroundIds });
    }
  };

  const _handleRemove = async (id: string) => {
    const { success } = await deleteBrandAsset({
      brandId,
      fileId: id,
      userId,
    });

    if (success) {
      const _sortedBackgroundIds = [...sortedOverlayIdsRef.current];

      remove({ id });

      await new Promise((resolve) => setTimeout(resolve, 100));

      sort({ ids: _sortedBackgroundIds.filter((_id) => id !== _id) });
    }
  };

  const _handleRename = async ({ id, name }: { id: string; name: string }) => {
    const { success } = await updateBrandAsset({
      brandId,
      fileId: id,
      name,
      userId,
    });

    if (success) {
      rename({ name, id });
    }
  };
  const _handleSelectOverlay = async ({
    id,
    value,
  }: {
    id: string;
    value: string;
  }) => {
    const { success } = await activateBrandAsset({
      brandId,
      fileId: id,
      type: "overlay",
      userId,
    });

    if (success) {
      const type = mainViewOverlayTypes.IMAGE;

      changeOverlay({ id, type, value });
    }
  };

  const _handleDeSelectOverlay = async ({ id }: { id: string }) => {
    const { success } = await deactivateBrandAsset({
      brandId,
      fileId: id,
      type: "overlay",
      userId,
    });

    if (success) {
      const type = mainViewOverlayTypes.NO_OVERLAY;

      changeOverlay({ id: null, type, value: null });
    }
  };

  const newBrandOverlaysState = useMemo(
    () =>
      newOverlaysState.filter(({ brandId: _brandId }) => brandId === _brandId),
    [newOverlaysState, brandId]
  );

  const canAddNewItem = useMemo(
    () =>
      !(
        (_sortedOverlays?.length || 0) + (newBrandOverlaysState?.length || 0) >=
        (isProfessionalPlanActive ? 10 : 5)
      ),
    [_sortedOverlays, newBrandOverlaysState, isProfessionalPlanActive]
  );

  return (
    <DisclosureContainer
      listenToCloseEvent={listenToCloseEvent}
      defaultOpen={defaultOpen}
      title={"Overlay"}
      dataTip={`Overlays are custom graphics on top of your stream. Use a PNG/GIF with a transparent background so you don't cover yourself.`}
      dataTipn={["Recommended size: 1280 x 720", "Max file size: 20 MB"]}
      renderPanel={() => {
        return (
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className="flex flex-1 flex-col"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {isFreePlanActive ? (
                      <BrandAssetsDraggableItem
                        {...{
                          key: `brand-overlay-item-free`,
                          id: "free-id",
                          index: 0,
                          isActive: true,
                          landscapeThumbnail: true,
                          name: "Momento Stream",
                          playText: "Show",
                          thumbnailImage: momentoStreamDefaultOverlay,
                          PlayIcon: MdAddCircleOutline,
                          isDragDisabled: true,
                        }}
                      />
                    ) : (
                      _sortedOverlays.map((item, index) => {
                        const id = item.id;
                        const isSelected = mainViewOverlay.id === id;
                        const remoteUrl = item.remoteUrl;
                        const title = item.name;

                        return (
                          <BrandAssetsDraggableItem
                            {...{
                              key: `brand-overlay-item-${id}`,
                              id,
                              index,
                              isActive: isSelected,
                              landscapeThumbnail: true,
                              name: removeExtensionFromFileName(title),
                              onDelete: isFreePlanActive
                                ? null
                                : () => {
                                    _handleRemove(id);
                                  },
                              onPlay: isFreePlanActive
                                ? null
                                : () => {
                                    _handleSelectOverlay({
                                      id,
                                      value: remoteUrl,
                                    });
                                  },
                              onPause: isFreePlanActive
                                ? null
                                : () => {
                                    _handleDeSelectOverlay({ id });
                                  },
                              onRename: isFreePlanActive
                                ? null
                                : (name) => {
                                    _handleRename({ id, name });
                                  },
                              playText: "Show",
                              thumbnailImage: remoteUrl,
                              PlayIcon: MdAddCircleOutline,
                            }}
                          />
                        );
                      })
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <BrandUploadingAndProcessingItems
              {...{ newItemsState: newBrandOverlaysState }}
            />

            <div className="mt-1">
              {isFreePlanActive ? (
                <UpgradeNowLine
                  preText={"Do you want to remove our overlay? "}
                  postText={" and upload your own overlays."}
                />
              ) : (
                <Tooltip
                  {...{
                    disabled: canAddNewItem,
                    title:
                      "You have reached the maximum overlays upload limit. Delete one to upload a new one.",
                  }}
                >
                  <button
                    onClick={() => {
                      if (canAddNewItem) {
                        setAssetsSelectionModalOpen(true);
                      }
                    }}
                    className={`gap-2 btn btn-sm btn-primary btn-outline normal-case ${
                      canAddNewItem ? "" : "opacity-60 cursor-not-allowed"
                    }`}
                  >
                    <MdAdd size={24} />

                    {"Add new overlay"}
                  </button>
                </Tooltip>
              )}
            </div>
            <AddNewOrSelectDefaultAssetsModal
              {...{
                maxFileSizeInMB: 20,
                isOpen: assetsSelectionModalOpen,
                onClose: () => {
                  setAssetsSelectionModalOpen(false);
                },
                onSuccess: () => {
                  setAssetsSelectionModalOpen(false);
                },
                title: "Add Overlay",
                acceptFileType: ".png,.jpeg,.jpg,.gif",
                inputButtonText: "Select image",
                onFileEvent,
                type: "image",
                defaultItems: [
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/backgrounds/Momento.png",
                    useRemoteUrl: true,
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/overlays/Confetti-1.gif",
                    useRemoteUrl: true,
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/overlays/Confetti-2.gif",
                    useRemoteUrl: true,
                  },
                ],
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default OverlaysContainer;
