// import { usePubSub } from "@videosdk.live/react-sdk";
import createBanner from "../../apis/apps/banners/create-banner";
import deleteBanner from "../../apis/apps/banners/delete-banner";
import updateBanner from "../../apis/apps/banners/update-banner";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { bannersActions } from "../../listners/banners/BannersListner";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useBanners = () => {
  const { userId } = useAppConfigContext();

  // const { publish } = usePubSub(appPubSubTopics.BANNERS);

  const { publish } = useAppSingalingPublish(appPubSubTopics.BANNERS);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const _createBanner = async ({
    folderId,
    position,
    primaryText,
    secondaryText,
    type,
  }: {
    folderId: string | number;
    position: string;
    primaryText: string;
    secondaryText?: string;
    type: string;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const { success, data } = await createBanner({
      folderId,
      position,
      primaryText,
      secondaryText,
      type,
      userId,
    });

    if (success) {
      publish(
        JSON.stringify({
          payload: { banner: data },
          action: bannersActions.CREATE_BANNER,
        })
        // { persist: true }
      );
    }

    return data?.id;
  };

  const _deleteBanner = async ({ bannerId }: { bannerId: string | number }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const { success } = await deleteBanner({ bannerId, userId });

    if (success) {
      publish(
        JSON.stringify({
          payload: { bannerId },
          action: bannersActions.DELETE_BANNER,
        })
        // { persist: true }
      );
    }
  };

  const _updateBanner = async ({
    bannerId,
    position,
    primaryText,
    secondaryText,
    type,
  }: {
    bannerId: string;
    position: string;
    primaryText: string;
    secondaryText: string;
    type: string;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const { success } = await updateBanner({
      bannerId,
      position,
      primaryText,
      secondaryText,
      type,
      userId,
    });

    if (success) {
      //
    }
  };

  return {
    createBanner: _createBanner,
    deleteBanner: _deleteBanner,
    updateBanner: _updateBanner,
  };
};

export default useBanners;
