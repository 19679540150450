import { customBlueColor } from "../../utils/colors";

const CroppedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101.223 69.818"
    enableBackground="new 0 0 101.223 69.818"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        style={{ fill: customBlueColor[100] }}
        d="M4.578 5.165h44.22v59.487H4.578z"
      />
      <path
        style={{ fill: "#fff" }}
        d="M26.688 39.577c-6.663 0-12.065 4.06-12.065 9.067h24.13c0-5.007-5.402-9.067-12.065-9.067z"
      />
      <circle style={{ fill: "#fff" }} cx={26.688} cy={28.79} r={7.617} />
      <path
        style={{ fill: customBlueColor[100] }}
        d="M52.425 5.165h44.22v59.487h-44.22z"
      />
      <path
        style={{ fill: "#fff" }}
        d="M74.535 39.577c-6.663 0-12.065 4.06-12.065 9.067H86.6c0-5.007-5.402-9.067-12.065-9.067z"
      />
      <circle style={{ fill: "#fff" }} cx={74.535} cy={28.79} r={7.617} />
    </g>
  </svg>
);

export default CroppedIcon;
