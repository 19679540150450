import useFileShareStream from "../../hooks/streams/useFileShareStream";

const MainViewFileShareStreamContainer = ({ fileId }: { fileId: string }) => {
  const { currentPage, currentPageRemoteUrl } = useFileShareStream({
    fileId,
    imageQuality: "high",
  });

  return (
    <div
      className="relative flex items-center justify-center"
      style={{ height: "100%", width: "100%" }}
    >
      <img
        alt={`mainview_pdfviewer_${fileId}_${currentPage}`}
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          height: "auto",
          width: "auto",
          display: "block",
        }}
        className={"object-cover"}
        src={currentPageRemoteUrl || ""}
      />
    </div>
  );
};

export default MainViewFileShareStreamContainer;
