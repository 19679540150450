import { useAppContext } from "../../contexts/appContextDef";
import BrandBackgroundMusicsListner from "./BrandBackgroundMusicsListner";

const AllBrandsBackgroundMusicsListner = () => {
  const { brands } = useAppContext();

  return brands.map(({ id }) => (
    <BrandBackgroundMusicsListner
      key={`BrandBackgroundMusicsListner_${id}`}
      id={id}
    />
  ));
};

export default AllBrandsBackgroundMusicsListner;
