import { egressManagerApiServerBaseUrl } from "../../utils/constants";

const getBroadcastEgresses = async ({
  broadcastId,
}: {
  broadcastId: string;
}): Promise<{ success: boolean; egresses?: broadcastEgressType[] }> => {
  try {
    const url = `${egressManagerApiServerBaseUrl}/broadcast-egresses/${broadcastId}`;

    const res = await fetch(url);

    const json = await res.json();

    if (json.success) {
      return { success: true, egresses: json.egresses };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default getBroadcastEgresses;
