import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import sleep from "../../utils/sleep";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

const MainViewEgressesProgressContainer = ({
  mainViewContainerWidth,
}: {
  mainViewContainerWidth: number;
}) => {
  const animatingStartingRef = useRef(false);
  const animatingStartedRef = useRef(false);
  const transformScaleAnimatingStartedRef = useRef(false);

  const [
    startedWithOnlyRecordingAnimationState,
    setStartedWithOnlyRecordingAnimationState,
  ] = useState({ animate: 0 });
  const [
    startedWithLivestreamAnimationState,
    setStartedWithLivestreamAnimationState,
  ] = useState({ animate: 0 });

  const [startingWithOnlyRecording, setStartingWithOnlyRecording] =
    useState(false);
  const [startedWithOnlyRecording, setStartedWithOnlyRecording] =
    useState(false);
  const [startingWithLivestream, setStartingWithLivestream] = useState(false);
  const [startedWithLivestream, setStartedWithLivestream] = useState(false);

  const startingWithOnlyRecordingRef = useRef(startingWithOnlyRecording);
  const startedWithOnlyRecordingRef = useRef(startedWithOnlyRecording);
  const startingWithLivestreamRef = useRef(startingWithLivestream);
  const startedWithLivestreamRef = useRef(startedWithLivestream);

  useEffect(() => {
    startingWithOnlyRecordingRef.current = startingWithOnlyRecording;
  }, [startingWithOnlyRecording]);
  useEffect(() => {
    startedWithOnlyRecordingRef.current = startedWithOnlyRecording;
  }, [startedWithOnlyRecording]);
  useEffect(() => {
    startingWithLivestreamRef.current = startingWithLivestream;
  }, [startingWithLivestream]);
  useEffect(() => {
    startedWithLivestreamRef.current = startedWithLivestream;
  }, [startedWithLivestream]);

  const { egressesProgressState } = useAppContext();

  const stopAnimatingStarting = () => {
    if (!animatingStartingRef.current) {
      return;
    }

    animatingStartingRef.current = false;

    if (startingWithOnlyRecordingRef.current) {
      setStartingWithOnlyRecording(false);
    }
    if (startingWithLivestreamRef.current) {
      setStartingWithLivestream(false);
    }
  };

  const stopAnimatingStarted = () => {
    if (!animatingStartedRef.current) {
      return;
    }

    animatingStartedRef.current = false;

    if (startedWithOnlyRecordingRef.current) {
      setStartedWithOnlyRecording(false);
    }
    if (startedWithLivestreamRef.current) {
      setStartedWithLivestream(false);
    }

    appEventEmitter.emit(
      appEventEmitterEvents.PUBLISH_EGRESSES_PROGRESS_STATE,
      {
        starting: false,
        started: false,
        startingWithOnlyRecording: false,
        startedWithOnlyRecording: false,
      }
    );
  };

  const initAnimatingStarting = ({
    startingWithOnlyRecording,
  }: {
    startingWithOnlyRecording: boolean;
  }) => {
    if (animatingStartingRef.current) {
      return;
    }

    animatingStartingRef.current = true;

    stopAnimatingStarted();

    if (startingWithOnlyRecording) {
      setStartingWithOnlyRecording(true);
    } else {
      setStartingWithLivestream(true);
    }
  };
  const initAnimatingStarted = ({
    startedWithOnlyRecording,
  }: {
    startedWithOnlyRecording: boolean;
  }) => {
    if (animatingStartedRef.current) {
      return;
    }

    animatingStartedRef.current = true;

    stopAnimatingStarting();

    if (startedWithOnlyRecording) {
      setStartedWithOnlyRecording(true);
    } else {
      setStartedWithLivestream(true);
    }
  };

  useEffect(() => {
    if (egressesProgressState?.starting) {
      initAnimatingStarting({
        startingWithOnlyRecording:
          egressesProgressState.startingWithOnlyRecording,
      });
    } else if (egressesProgressState?.started) {
      initAnimatingStarted({
        startedWithOnlyRecording:
          egressesProgressState.startedWithOnlyRecording,
      });
    } else {
      stopAnimatingStarting();
      stopAnimatingStarted();
    }
  }, [egressesProgressState]);

  const initTransformScaleAnimatingStartedWithOnlyRecording = async () => {
    transformScaleAnimatingStartedRef.current = true;

    setStartedWithOnlyRecordingAnimationState({ animate: 0 });

    const animationDuration = 1000;
    const transformOnDuration = 10;

    await sleep(100);

    const interval = setInterval(() => {
      setStartedWithOnlyRecordingAnimationState((s) => ({
        animate: s.animate + transformOnDuration / animationDuration,
      }));
    }, transformOnDuration);

    setTimeout(() => {
      clearInterval(interval);

      transformScaleAnimatingStartedRef.current = false;

      setStartedWithOnlyRecordingAnimationState({ animate: 0 });

      stopAnimatingStarted();
    }, animationDuration);
  };

  const initTransformScaleAnimatingStartedWithLivestream = async () => {
    transformScaleAnimatingStartedRef.current = true;

    setStartedWithLivestreamAnimationState({ animate: 0 });

    const animationDuration = 1000;
    const transformOnDuration = 10;

    await sleep(100);

    const interval = setInterval(() => {
      setStartedWithLivestreamAnimationState((s) => ({
        animate: s.animate + transformOnDuration / animationDuration,
      }));
    }, transformOnDuration);

    setTimeout(() => {
      clearInterval(interval);

      transformScaleAnimatingStartedRef.current = false;

      setStartedWithLivestreamAnimationState({ animate: 0 });

      stopAnimatingStarted();
    }, animationDuration);
  };

  useEffect(() => {
    if (
      startedWithOnlyRecording &&
      !transformScaleAnimatingStartedRef.current
    ) {
      initTransformScaleAnimatingStartedWithOnlyRecording();
    }
  }, [startedWithOnlyRecording]);

  useEffect(() => {
    if (startedWithLivestream && !transformScaleAnimatingStartedRef.current) {
      initTransformScaleAnimatingStartedWithLivestream();
    }
  }, [startedWithLivestream]);

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center pointer-events-none">
      <div
        style={{
          height:
            startingWithLivestream || startingWithOnlyRecording
              ? "30%"
              : startedWithLivestream || startedWithOnlyRecording
              ? "100%"
              : "30%",
          opacity:
            startingWithLivestream ||
            startingWithOnlyRecording ||
            startedWithLivestream ||
            startedWithOnlyRecording
              ? 1
              : 0,
          transition: "opacity 0.2s ease-in-out, height 0.5s ease-in-out",
        }}
        className="w-full text-center flex items-center justify-center p-6 bg-black bg-opacity-30"
      >
        {startingWithLivestream || startingWithOnlyRecording ? (
          <p
            style={{ fontSize: mainViewContainerWidth * 0.025 }}
            className="animate-pulse font-bold text-white"
          >
            {startingWithOnlyRecording
              ? "Preparing for Recording..."
              : "Preparing for Live streaming..."}
          </p>
        ) : (
          <React.Fragment />
        )}

        {startedWithLivestream ? (
          <p
            className="font-bold text-white"
            style={{
              fontSize: mainViewContainerWidth * 0.08,
              transition: "all 0.2s linear",
              zoom:
                startedWithLivestreamAnimationState.animate < 0.2
                  ? 0.2
                  : startedWithLivestreamAnimationState.animate,
              opacity:
                startedWithLivestreamAnimationState.animate <= 0.55
                  ? startedWithLivestreamAnimationState.animate / 0.55
                  : startedWithLivestreamAnimationState.animate > 0.55 &&
                    startedWithLivestreamAnimationState.animate <= 0.8
                  ? 1
                  : 0,
            }}
          >
            Now Live !
          </p>
        ) : (
          <React.Fragment />
        )}
        {startedWithOnlyRecording ? (
          <p
            className="font-bold text-white"
            style={{
              fontSize: mainViewContainerWidth * 0.08,
              transition: "all 0.2s linear",
              zoom:
                startedWithOnlyRecordingAnimationState.animate < 0.2
                  ? 0.2
                  : startedWithOnlyRecordingAnimationState.animate,
              opacity:
                startedWithOnlyRecordingAnimationState.animate <= 0.55
                  ? startedWithOnlyRecordingAnimationState.animate / 0.55
                  : startedWithOnlyRecordingAnimationState.animate > 0.55 &&
                    startedWithOnlyRecordingAnimationState.animate <= 0.8
                  ? 1
                  : 0,
            }}
          >
            Now
            <br />
            Recording!
          </p>
        ) : (
          <React.Fragment />
        )}
      </div>

      {/* <div className="absolute top-0 left-0 right-0 bottom-0 ">
        <button
          className="btn btn-primary"
          onClick={() => {
            appEventEmitter.emit(
              appEventEmitterEvents.PUBLISH_EGRESSES_PROGRESS_STATE,
              {
                starting: true,
                started: false,
                startingWithOnlyRecording: true,
                startedWithOnlyRecording: false,
              }
            );
          }}
        >
          ONE
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            appEventEmitter.emit(
              appEventEmitterEvents.PUBLISH_EGRESSES_PROGRESS_STATE,
              {
                starting: true,
                started: false,
                startingWithOnlyRecording: false,
                startedWithOnlyRecording: false,
              }
            );
          }}
        >
          TWO
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            appEventEmitter.emit(
              appEventEmitterEvents.PUBLISH_EGRESSES_PROGRESS_STATE,
              {
                starting: false,
                started: true,
                startingWithOnlyRecording: false,
                startedWithOnlyRecording: true,
              }
            );
          }}
        >
          THREE
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            appEventEmitter.emit(
              appEventEmitterEvents.PUBLISH_EGRESSES_PROGRESS_STATE,
              {
                starting: false,
                started: true,
                startingWithOnlyRecording: false,
                startedWithOnlyRecording: false,
              }
            );
          }}
        >
          FOUR
        </button>
      </div> */}
    </div>
  );
};

export default MainViewEgressesProgressContainer;
