// import { usePubSub } from "@videosdk.live/react-sdk";
import { useEffect, useMemo, useRef } from "react";
import { getFileStreamTopicById } from "../../utils/pubSubTopics";
// import groupBy from "lodash.groupby";
import { useAppContext } from "../../contexts/appContextDef";
import useValidateSpeakerCanTakeAction from "../appState/useValidateSpeakerCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { useAppConfigContext } from "../../contexts/appConfigDef";
// import { safeParseJson } from "../../utils/safeParseJson";

export const fileShareStreamPubSubActions = {
  GOTO_PAGE: "GOTO_PAGE",
};

const useFileShareStream = ({
  fileId,
  imageQuality: _imageQuality,
}: {
  fileId: string;
  imageQuality: string;
}) => {
  const {
    activeFileShareStreamAllParticipants: activeFileShareStreams,
    fileShareStreamsCurrentPage,
    broadcastAssets,
  } = useAppContext();

  // const [currentPage, setCurrentPage] = useState(1);

  const { studioUserId } = useAppConfigContext();

  const currentPage = useMemo(
    () => fileShareStreamsCurrentPage[fileId] || 1,
    [fileShareStreamsCurrentPage, fileId]
  );

  const { totalPages, pagesRemoteUrls, owners, isOwner, resourceId } =
    useMemo(() => {
      const _activeFileShareStream = activeFileShareStreams.find(
        ({ id }) => id === fileId
      );
      if (_activeFileShareStream) {
        const { totalPages, pagesRemoteUrls } = _activeFileShareStream.pdfInfo;

        const broadcastAsset = broadcastAssets.find(
          ({ id }) => `${id}` === `${_activeFileShareStream.resourceId}`
        );

        const owners = broadcastAsset?.owners || [];

        const isOwner = owners?.includes(studioUserId);

        return {
          totalPages,
          pagesRemoteUrls,
          owners,
          isOwner,
          resourceId: _activeFileShareStream.resourceId,
        };
      } else
        return {
          totalPages: null,
          pagesRemoteUrls: null,
          owners: null,
          isOwner: false,
        };
    }, [activeFileShareStreams, fileId, broadcastAssets, studioUserId]);

  const imageQuality = useMemo(
    () =>
      _imageQuality === "high" ||
      _imageQuality === "med" ||
      _imageQuality === "low"
        ? _imageQuality
        : "high",
    [_imageQuality]
  );

  const { isFirstPage, isLastPage } = useMemo(() => {
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return { isFirstPage, isLastPage };
  }, [currentPage, totalPages]);

  const currentPageRef = useRef(currentPage);
  const totalPagesRef = useRef(totalPages);
  const isFirstPageRef = useRef(isFirstPage);
  const isLastPageRef = useRef(isLastPage);

  useEffect(() => {
    isFirstPageRef.current = isFirstPage;
  }, [isFirstPage]);
  useEffect(() => {
    isLastPageRef.current = isLastPage;
  }, [isLastPage]);
  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);
  useEffect(() => {
    totalPagesRef.current = totalPages;
  }, [totalPages]);

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  // const _handleGotoPage = (page: number) => {
  //   setCurrentPage(page);
  // };

  const { publish: fileStreamPublish } = useAppSingalingPublish(
    getFileStreamTopicById(fileId)
  );

  const goto = async (page: number) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    const currentPage = currentPageRef.current;
    const totalPages = totalPagesRef.current;

    if (page === currentPage) return;

    if (page >= 1 && page <= (totalPages as number)) {
      fileStreamPublish(
        JSON.stringify({
          payload: { currentPage: page },
          action: fileShareStreamPubSubActions.GOTO_PAGE,
        })
      );
    }
  };

  const gotoNext = () => {
    const isLastPage = isLastPageRef.current;
    const currentPage = currentPageRef.current;

    if (isLastPage) return;

    goto(currentPage + 1);
  };

  const gotoPrevious = () => {
    const isFirstPage = isFirstPageRef.current;
    const currentPage = currentPageRef.current;

    if (isFirstPage) return;

    goto(currentPage - 1);
  };

  const { currentPageRemoteUrl, allPagesUrl } = useMemo(() => {
    if (
      pagesRemoteUrls &&
      pagesRemoteUrls[currentPage] &&
      pagesRemoteUrls[currentPage][imageQuality as "high"]
    ) {
      const currentPageRemoteUrl =
        pagesRemoteUrls[currentPage][imageQuality as "high"];

      const allPagesUrl = Object.keys(pagesRemoteUrls)
        .filter((pageNumber) => pagesRemoteUrls[parseInt(pageNumber)])
        .map(
          (pageNumber) =>
            pagesRemoteUrls[parseInt(pageNumber)][imageQuality as "high"]
        );

      return { currentPageRemoteUrl, allPagesUrl };
    } else {
      return {
        currentPageRemoteUrl: null,
        allPagesUrl: null,
      };
    }
  }, [pagesRemoteUrls, currentPage, imageQuality]);

  return {
    isFirstPage,
    isLastPage,
    currentPage,
    totalPages,
    goto,
    gotoNext,
    gotoPrevious,
    currentPageRemoteUrl,
    allPagesUrl,
    owners,
    isOwner,
    resourceId,
  };
};

export default useFileShareStream;
