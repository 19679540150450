import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const updateBrand = async ({
  brandId,
  userId,
  name,
  color,
  showDisplayNames,
  showHeadlines,
  muteGuestsWhenAudioPlays,
  muteGuestsWhenVideoPlays,
}: {
  brandId: string;
  userId: string;
  name?: string;
  color?: string;
  showDisplayNames?: boolean;
  showHeadlines?: boolean;
  muteGuestsWhenAudioPlays?: boolean;
  muteGuestsWhenVideoPlays?: boolean;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/update-brand`;

    const reqBody: {
      brandId: string;
      userId: string;
      name?: string;
      color?: string;
      showDisplayNames?: boolean | number;
      showHeadlines?: boolean | number;
      muteGuestsWhenAudioPlays?: boolean | number;
      muteGuestsWhenVideoPlays?: boolean | number;
    } = { brandId, userId };

    if (name) {
      reqBody["name"] = name;
    }

    if (color) {
      reqBody["color"] = color;
    }

    if (typeof showDisplayNames === "boolean") {
      reqBody["showDisplayNames"] = showDisplayNames ? 1 : 0;
    }
    if (typeof showHeadlines === "boolean") {
      reqBody["showHeadlines"] = showHeadlines ? 1 : 0;
    }

    if (typeof muteGuestsWhenAudioPlays === "boolean") {
      reqBody["muteGuestsWhenAudioPlays"] = muteGuestsWhenAudioPlays ? 1 : 0;
    }

    if (typeof muteGuestsWhenVideoPlays === "boolean") {
      reqBody["muteGuestsWhenVideoPlays"] = muteGuestsWhenVideoPlays ? 1 : 0;
    }

    const body = JSON.stringify(reqBody);

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (err) {
    return { success: false };
  }
};

export default updateBrand;
