import { momentostreamApiServerBaseUrl } from "../../../utils/constants";

const deleteBanner = async ({
  userId,
  bannerId,
}: {
  userId: string;
  bannerId: string | number;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/${userId}/banner/${bannerId}`;

    const res = await fetch(url, {
      method: "DELETE",
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default deleteBanner;
