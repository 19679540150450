import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const updateRemainingTime = async ({
  broadcastId,
  remainingTime,
}: {
  broadcastId: string;
  remainingTime: number;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/remaining-time/${broadcastId}`;

    const body = JSON.stringify({ remainingTime });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default updateRemainingTime;
