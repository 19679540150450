import React, { useEffect, useMemo, useRef, useState } from "react";
import { isHexLight } from "../utils/isHexLight";
import { MdMicOff } from "react-icons/md";
import { useAppContext } from "../contexts/appContextDef";
import getUserBrands from "../apis/brands/get-user-brands";
import { useAppConfigContext } from "../contexts/appConfigDef";

const JoinScreenCornerDisplayName = ({
  micOn,
  name,
}: {
  micOn: boolean;
  name: string;
}) => {
  const { userId } = useAppConfigContext();

  const userIdRef = useRef(userId);

  useEffect(() => {
    userIdRef.current = userId;
  }, [userId]);

  const [activeBrandColor, setActiveBrandColor] = useState({
    fetched: false,
    brandColor: "",
  });

  const setBrandColor = async () => {
    const { success, brands } = await getUserBrands({
      userId: userIdRef.current,
    });

    if (success && brands) {
      let activeBrand = brands.find(({ isActive }) => isActive);

      if (!activeBrand) {
        activeBrand = brands[0];
      }

      setActiveBrandColor({
        fetched: true,
        brandColor: activeBrand?.color || "#000000",
      });
    }
  };

  useEffect(() => {
    setBrandColor();
  }, []);

  const appContext = useAppContext();

  const { backgroundColor, isBackgroundLight, showDisplayName } =
    useMemo(() => {
      const backgroundColor = activeBrandColor.brandColor;

      const isBackgroundLight = isHexLight(backgroundColor);

      return {
        backgroundColor,
        isBackgroundLight,
        showDisplayName:
          appContext?.showDisplayName && name && name?.length > 0,
        showParticipantHeadline:
          appContext?.showParticipantHeadline && name && name?.length > 0,
      };
    }, [appContext, activeBrandColor, name]);

  const showMicIcon = useMemo(() => !micOn, [micOn]);

  return (showMicIcon || showDisplayName) && activeBrandColor.fetched ? (
    <div
      style={{
        backgroundColor,
      }}
      className={`max-w-full p-2 rounded-full ${
        isBackgroundLight ? "text-black fill-black" : "text-white fill-white"
      } flex items-center justify-center gap-1`}
    >
      {showMicIcon && <MdMicOff size={16} />}
      {showDisplayName && (
        <p className={`font-extrabold truncate text-ellipsis`}>{name}</p>
      )}
    </div>
  ) : (
    <React.Fragment />
  );
};

export default JoinScreenCornerDisplayName;
