import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const brandsActions = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  RENAME: "RENAME",
  ACTIVE: "ACTIVE",
};

const AllBrandsListner = () => {
  const {
    setBrands,
    setActiveBrandId,
    setBackgroundMusics,
    setSortedBackgroundMusicIds,
    setBackgrounds,
    setSortedBackgroundIds,
    setBrandLogos,
    setSortedBrandLogoIds,
    setSortedOverlayIds,
    setOverlays,
    setVideoClips,
    setSortedVideoClipIds,
  } = useAppContext();

  const _handleAdd = ({
    id,
    name,
    defaultOpen,
  }: {
    id: string;
    name: string;
    defaultOpen?: boolean;
  }) => {
    setBrands((s) => [...s, { id, name, defaultOpen }]);
  };

  const _handleRemove = ({ id }: { id: string }) => {
    setBrands((s) => s.filter(({ id: _id }) => `${id}` !== `${_id}`));

    setBackgroundMusics((s) => {
      const _s = { ...s };
      delete _s[id];
      return _s;
    });

    setSortedBackgroundMusicIds((s) => {
      const _s = { ...s };
      delete _s[id];
      return _s;
    });

    setBackgrounds((s) => {
      const _s = { ...s };
      delete _s[id];
      return _s;
    });

    setSortedBackgroundIds((s) => {
      const _s = { ...s };
      delete _s[id];
      return _s;
    });

    setBrandLogos((s) => {
      const _s = { ...s };
      delete _s[id];
      return _s;
    });

    setSortedBrandLogoIds((s) => {
      const _s = { ...s };
      delete _s[id];
      return _s;
    });

    setSortedOverlayIds((s) => {
      const _s = { ...s };
      delete _s[id];
      return _s;
    });

    setOverlays((s) => {
      const _s = { ...s };
      delete _s[id];
      return _s;
    });

    setVideoClips((s) => {
      const _s = { ...s };
      delete _s[id];
      return _s;
    });

    setSortedVideoClipIds((s) => {
      const _s = { ...s };
      delete _s[id];
      return _s;
    });
  };

  const _handleRename = ({ id, name }: { id: string; name: string }) => {
    setBrands((s) =>
      s.map((brand) => (brand.id === id ? { ...brand, name } : brand))
    );
  };

  const _handleActive = ({ id }: { id: string }) => {
    setActiveBrandId(id);
  };

  useAppSingalingSubscribe(
    appPubSubTopics.BRANDS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case brandsActions.ADD:
          _handleAdd(message.payload);
          break;

        case brandsActions.REMOVE:
          _handleRemove(message.payload);
          break;

        case brandsActions.RENAME:
          _handleRename(message.payload);
          break;

        case brandsActions.ACTIVE:
          _handleActive(message.payload);
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default AllBrandsListner;
