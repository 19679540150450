import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";

const useParticipantIdsAndCount = (args?: {
  joinedParticipants: joinedParticipantsType;
}) => {
  const joinedParticipants =
    useAppContext()?.joinedParticipants || args?.joinedParticipants;

  const { participantsCount, participantIds } = useMemo(() => {
    const participantIds = joinedParticipants.map(
      ({ participantId }) => participantId
    );

    return {
      participantIds,
      participantsCount: participantIds?.length || 0,
    };
  }, [joinedParticipants]);

  return { participantsCount, joinedParticipants, participantIds };
};

export default useParticipantIdsAndCount;
