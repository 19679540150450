export const getLivekitStreamUrlFromOriginalRtmpUrl = ({
  url,
}: {
  url: string;
}) => {
  try {
    const splittedUrl = url.split("/");

    const key = splittedUrl[splittedUrl.length - 1];

    splittedUrl.pop();

    return {
      livekitStreamUrl: `${splittedUrl.join("/")}/{${key.substring(
        0,
        3
      )}...${key.substring(key.length - 3)}}`,
    };
  } catch (error) {
    return { livekitStreamUrl: "" };
  }
};
