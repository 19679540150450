import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const ModifiedParticipantsNamesListner = () => {
  const { setModifiedParticipantsNames } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.MODIFY_PARTICIPANT_NAME,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { participantId, name } = message;

      setModifiedParticipantsNames((modifiedParticipantsNames) => {
        const _modifiedParticipantsNames = [...modifiedParticipantsNames];

        const participantNameModefiedIndex_old =
          _modifiedParticipantsNames.findIndex(
            ({ participantId: _participantId }) =>
              _participantId === participantId
          );

        if (participantNameModefiedIndex_old === -1) {
          _modifiedParticipantsNames.push({ participantId, name });
        } else {
          _modifiedParticipantsNames[participantNameModefiedIndex_old] = {
            participantId,
            name,
          };
        }

        return _modifiedParticipantsNames;
      });
    }
  );

  return <React.Fragment />;
};

export default ModifiedParticipantsNamesListner;
