import React from "react";
import AllBrandsBackgroundMusicsListner from "./AllBrandsBackgroundMusicsListner";
import AllBrandsBackgroundsListner from "./AllBrandsBackgroundsListner";
import AllBrandsListner from "./AllBrandsListner";
import AllBrandsLogosListner from "./AllBrandsLogosListner";
import AllBrandsOverlaysListner from "./AllBrandsOverlaysListner";
import AllBrandsVideoClipsListner from "./AllBrandsVideoClipsListner";

const BrandsListner = () => {
  return (
    <React.Fragment>
      <AllBrandsBackgroundMusicsListner />
      <AllBrandsOverlaysListner />
      <AllBrandsBackgroundsListner />
      <AllBrandsVideoClipsListner />
      <AllBrandsListner />
      <AllBrandsLogosListner />
    </React.Fragment>
  );
};

export default BrandsListner;
