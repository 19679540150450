import { useMemo } from "react";
import {
  croppedGridMetaSize,
  groupGridMetaSize,
} from "../../listners/appState/MainViewLayoutListner";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import { mediaStreamKinds } from "../../listners/streams/QueuedStreamsListnerAllPeers";
import {
  interactivityModes,
  mainViewShareLayouts,
  mainViewWebcamLayouts,
} from "../../utils/constants";

const generateCroppedGridStreamsPosition = ({
  mainViewGrid,
  gridMetaSize,
}: {
  mainViewGrid: string[];
  gridMetaSize: {
    [t: number]: {
      r?: number | undefined;
      c?: number | undefined;
      r0?: number | undefined;
      r1?: number | undefined;
      r2?: number | undefined;
    };
  };
}) => {
  const numStreams = mainViewGrid.length;

  if (numStreams === 0) {
    return [];
  }

  const grid = gridMetaSize[numStreams];

  const gridWithStreamsPosition: gridWithStreamsPositionWOMetaType = [];

  for (let index = 0; index < (grid.r as number); index++) {
    const columnForCurrentRow = grid[
      `r${index}` as "r" | "c" | "r0" | "r1" | "r2"
    ] as number;

    const columns = mainViewGrid.splice(0, columnForCurrentRow);

    columns.forEach((streamId, i) => {
      const relativeWidth = 100 / columnForCurrentRow;
      const relativeHeight = 100 / (grid.r as number);
      const relativeTop = (100 * index) / (grid.r as number);
      const relativeLeft = (100 / columnForCurrentRow) * i;

      gridWithStreamsPosition.push({
        streamId,
        relativeHeight,
        relativeWidth,
        relativeTop,
        relativeLeft,
      });
    });
  }

  return gridWithStreamsPosition;
};

const generateGroupGridStreamsPosition = ({
  mainViewGrid,
  gridMetaSize,
}: {
  mainViewGrid: string[];
  gridMetaSize: {
    [t: number]: {
      r?: number | undefined;
      c?: number | undefined;
      r0?: number | undefined;
      r1?: number | undefined;
      r2?: number | undefined;
    };
  };
}) => {
  const numStreams = mainViewGrid.length;

  if (numStreams === 0) {
    return [];
  }

  const grid = gridMetaSize[numStreams] as {
    r: number;
    c: number;
    r0: number;
    r1: number;
    r2: number;
  };

  const gridWithStreamsPosition: gridWithStreamsPositionWOMetaType = [];

  for (let index = 0; index < (grid.r as number); index++) {
    const columnForCurrentRow = grid[
      `r${index}` as "r" | "c" | "r0" | "r1" | "r2"
    ] as number;

    const columns = mainViewGrid.splice(0, columnForCurrentRow);

    columns.forEach((streamId, i) => {
      const diff = grid.c - columnForCurrentRow;

      const relativeWidth = 100 / grid.c;
      const relativeLeft =
        (i * 100) / grid.c + (diff > 0 ? relativeWidth / 2 : 0);

      gridWithStreamsPosition.push({
        streamId,
        relativeHeight: 100 / grid.r,
        relativeWidth,
        relativeTop: (100 * index) / grid.r,
        relativeLeft,
      });
    });
  }

  return gridWithStreamsPosition;
};

const generateGridStreamsPosition = ({
  layout,
  mainViewGrid,
}: {
  mainViewGrid: string[];
  layout: mainViewLayoutType;
}) => {
  const gridWithStreamsPosition =
    layout === "CROPPED"
      ? generateCroppedGridStreamsPosition({
          mainViewGrid: [...mainViewGrid],
          gridMetaSize: croppedGridMetaSize,
        })
      : generateGroupGridStreamsPosition({
          mainViewGrid: [...mainViewGrid],
          gridMetaSize: groupGridMetaSize,
        });

  return gridWithStreamsPosition;
};

const generateBottomStackStreamsPosition = ({ stack }: { stack: string[] }) => {
  const gridWithStreamsPosition = [];
  const horizontalStackMaxStreamsToFitAspectRatio = 3;
  const horizontalStackHeightPercentage =
    ((100 / horizontalStackMaxStreamsToFitAspectRatio) * 9) / 16;

  const relativeWidth =
    stack.length > horizontalStackMaxStreamsToFitAspectRatio
      ? 100 / stack.length
      : (horizontalStackHeightPercentage * 16) / 9;

  for (let index = 0; index < stack.length; index++) {
    const streamId = stack[index];

    gridWithStreamsPosition.push({
      streamId,
      relativeHeight: horizontalStackHeightPercentage,
      relativeWidth,
      relativeTop: 100 - horizontalStackHeightPercentage,
      relativeLeft: relativeWidth * index,
    });
  }

  return gridWithStreamsPosition;
};

const generateVerticalStackStreamsPosition = ({
  stack,
  stackMainViewPosition,
}: {
  stack: string[];
  stackMainViewPosition: string;
}) => {
  const gridWithStreamsPosition = [];
  const doubleStackThreshold = 6;
  let doubleStackMaxCount;

  const shouldCreateDoubleStack = stack.length >= doubleStackThreshold;

  if (shouldCreateDoubleStack) {
    const stackOneMaxCount =
      stackMainViewPosition === "left"
        ? Math.ceil(stack.length / 2)
        : Math.floor(stack.length / 2);

    doubleStackMaxCount = Math.ceil(stack.length / 2);

    for (let index = 0; index < stack.length; index++) {
      const isInStackOne = index < stackOneMaxCount;
      const relativeHeight = 100 / doubleStackMaxCount;
      const streamId = stack[index];

      if (isInStackOne) {
        gridWithStreamsPosition.push({
          streamId,
          relativeHeight: relativeHeight,
          relativeWidth: 50,
          relativeTop: relativeHeight * index,
          relativeLeft: 0,
        });
      } else {
        gridWithStreamsPosition.push({
          streamId,
          relativeHeight,
          relativeWidth: 50,
          relativeTop: relativeHeight * (index - stackOneMaxCount),
          relativeLeft: 50,
        });
      }
    }
  } else {
    for (let index = 0; index < stack.length; index++) {
      const relativeHeight = 100 / stack.length;

      const streamId = stack[index];

      gridWithStreamsPosition.push({
        streamId,
        relativeHeight,
        relativeWidth: 100,
        relativeTop: relativeHeight * index,
        relativeLeft: 0,
      });
    }
  }

  return {
    gridWithStreamsPosition,
    shouldCreateDoubleStack,
    stackMaxCount: shouldCreateDoubleStack
      ? (doubleStackMaxCount as number)
      : stack.length,
    doubleStackThreshold,
  };
};

const generateStackStreamsPosition = ({
  layout,
  mainViewGrid,
  isShareStreamActive,
}: {
  layout: mainViewLayoutType;
  mainViewGrid: string[];
  isShareStreamActive: boolean;
}) => {
  let stack;
  let gridWithStreamsPosition: gridWithStreamsPositionWOMetaType = [];
  let shouldCreateDoubleStack;
  let stackMaxCount;
  let doubleStackThreshold;
  //
  // here in last else condition layuoyt could be "PICTURE_IN_PICTURE"
  const stackMainViewPosition =
    layout === "SCREEN" ? "left" : layout === "SPOTLIGHT" ? "right" : "bottom";

  if (isShareStreamActive) {
    stack = [...mainViewGrid];
  } else {
    const _mainViewGridForStack = [...mainViewGrid];

    _mainViewGridForStack.shift();

    stack = [..._mainViewGridForStack];
  }

  if (stackMainViewPosition === "bottom") {
    const bottomStackStreamsPosition = generateBottomStackStreamsPosition({
      stack,
    });

    gridWithStreamsPosition = [...bottomStackStreamsPosition];
  } else if (
    stackMainViewPosition === "left" ||
    stackMainViewPosition === "right"
  ) {
    const {
      gridWithStreamsPosition: verticalStackStreamsPosition,
      shouldCreateDoubleStack: _shouldCreateDoubleStack,
      stackMaxCount: _stackMaxCount,
      doubleStackThreshold: _doubleStackThreshold,
    } = generateVerticalStackStreamsPosition({
      stack,
      stackMainViewPosition,
    });

    gridWithStreamsPosition = [...verticalStackStreamsPosition];

    shouldCreateDoubleStack = _shouldCreateDoubleStack;
    stackMaxCount = _stackMaxCount;
    doubleStackThreshold = _doubleStackThreshold;
  }

  return {
    gridWithStreamsPosition,
    shouldCreateDoubleStack,
    stackMaxCount: stackMaxCount as number,
    doubleStackThreshold,
    stackMainViewPosition,
  };
};

const generateGridFromStreamIdsAndLayout = ({
  layout,
  mainViewSelectedStreams,
  mainViewGrid,
  activeSpeakerParticipantId,
  interactivityMode,
  conferenceModeParticipantAutoCountEnabled, // no need but accepting for re-calculatiing when change
}: {
  layout: mainViewLayoutType;
  mainViewSelectedStreams: mainViewSelectedStreamsType;
  mainViewGrid: string[];
  activeSpeakerParticipantId?: string | null;
  interactivityMode: interactivityModeType;
  conferenceModeParticipantAutoCountEnabled: boolean;
}) => {
  conferenceModeParticipantAutoCountEnabled;

  const sideStackWidthPercentage = 20;
  const doubleSideStackWidthPercentage = 36;

  let shouldCreateDoubleStack: boolean | undefined | null;
  //

  const activeShareStreamId = [...mainViewSelectedStreams.keys()].find(
    (streamId) => streamId.includes(streamTypes.SHARE)
  );

  const isShareStreamActive = !!activeShareStreamId;

  const shouldCreateVerticalStack =
    layout === "SPOTLIGHT" || layout === "SCREEN";
  const shouldCreateHorizontalStack = layout === "PICTURE_IN_PICTURE";

  const shouldCreateStack =
    shouldCreateVerticalStack || shouldCreateHorizontalStack;

  const shouldCreateGrid = layout === "CROPPED" || layout === "GROUP";

  const isStreamsGrid = shouldCreateStack || shouldCreateGrid;

  let gridWithStreamsPosition: gridWithStreamsPositionWOMetaType = [];

  if (shouldCreateStack) {
    const {
      stackMainViewPosition,
      gridWithStreamsPosition: stackStreamsPosition,
      shouldCreateDoubleStack: _shouldCreateDoubleStack,
      stackMaxCount,
    } = generateStackStreamsPosition({
      layout,
      mainViewGrid: [...mainViewGrid],
      isShareStreamActive,
    });

    shouldCreateDoubleStack = _shouldCreateDoubleStack;

    let _stackStreamsPosition = [...stackStreamsPosition];

    if (stackMainViewPosition === "left" || stackMainViewPosition === "right") {
      _stackStreamsPosition = _stackStreamsPosition.map(
        ({
          streamId,
          relativeHeight,
          relativeWidth,
          relativeTop,
          relativeLeft,
        }) => {
          const _relativeWidth =
            (relativeWidth *
              (shouldCreateDoubleStack
                ? doubleSideStackWidthPercentage
                : sideStackWidthPercentage)) /
            100;

          const _relativeLeft =
            stackMainViewPosition === "left"
              ? (relativeLeft *
                  (shouldCreateDoubleStack
                    ? doubleSideStackWidthPercentage
                    : sideStackWidthPercentage)) /
                100
              : 100 -
                ((100 - relativeLeft) *
                  (shouldCreateDoubleStack
                    ? doubleSideStackWidthPercentage
                    : sideStackWidthPercentage)) /
                  100;

          const _relativeHeight =
            _relativeWidth * stackMaxCount > 100
              ? relativeHeight
              : _relativeWidth;

          const stackHeight = _relativeHeight * stackMaxCount;

          const firstTop = (100 - _relativeHeight * stackMaxCount) / 2;

          const _relativeTop = firstTop + (stackHeight * relativeTop) / 100;

          return {
            streamId,
            relativeHeight: _relativeHeight,
            relativeWidth: _relativeWidth,
            relativeTop: _relativeTop,
            relativeLeft: _relativeLeft,
          };
        }
      );
    }

    gridWithStreamsPosition = [..._stackStreamsPosition];
  }

  if (shouldCreateGrid) {
    const gridStreamsPosition = generateGridStreamsPosition({
      layout,
      mainViewGrid: [...mainViewGrid],
    });

    gridWithStreamsPosition = [...gridStreamsPosition];
  }

  if (isShareStreamActive) {
    if (layout === "NEWS") {
      gridWithStreamsPosition = [];

      const streamId =
        interactivityMode === interactivityModes.CONFERENCE &&
        activeSpeakerParticipantId
          ? generateStreamId({
              participantId: activeSpeakerParticipantId,
              type: streamTypes.WEBCAM,
            })
          : mainViewGrid[0];

      if (streamId) {
        gridWithStreamsPosition.push({
          streamId,
          relativeHeight: 100,
          relativeWidth: 50,
          relativeTop: 0,
          relativeLeft: 0,
        });
      }

      gridWithStreamsPosition.push({
        streamId: activeShareStreamId,
        relativeHeight: 100,
        relativeWidth: 50,
        relativeTop: 0,
        relativeLeft: 50,
      });
    } else if (layout === "SCREEN") {
      gridWithStreamsPosition.unshift({
        streamId: activeShareStreamId,
        relativeHeight: 100,
        relativeWidth:
          100 -
          (shouldCreateDoubleStack
            ? doubleSideStackWidthPercentage
            : sideStackWidthPercentage),
        relativeTop: 0,
        relativeLeft: shouldCreateDoubleStack
          ? doubleSideStackWidthPercentage
          : sideStackWidthPercentage,
      });
    } else if (layout === "PICTURE_IN_PICTURE") {
      gridWithStreamsPosition.unshift({
        streamId: activeShareStreamId,
        relativeHeight: 100,
        relativeWidth: 100,
        relativeTop: 0,
        relativeLeft: 0,
      });
    } else if (layout === "CINEMA") {
      gridWithStreamsPosition = [
        {
          streamId: activeShareStreamId,
          relativeHeight: 100,
          relativeWidth: 100,
          relativeTop: 0,
          relativeLeft: 0,
        },
      ];
    }
  } else {
    if (layout === "SOLO") {
      gridWithStreamsPosition = [];

      const streamId =
        interactivityMode === interactivityModes.CONFERENCE &&
        activeSpeakerParticipantId
          ? generateStreamId({
              participantId: activeSpeakerParticipantId,
              type: streamTypes.WEBCAM,
            })
          : mainViewGrid[0];

      if (streamId) {
        gridWithStreamsPosition.push({
          streamId,
          relativeHeight: 100,
          relativeWidth: 100,
          relativeTop: 0,
          relativeLeft: 0,
        });
      }
    } else if (layout === "SPOTLIGHT") {
      if (mainViewGrid[0]) {
        gridWithStreamsPosition.unshift({
          streamId: mainViewGrid[0],
          relativeHeight: 100,
          relativeWidth:
            100 -
            (shouldCreateDoubleStack
              ? doubleSideStackWidthPercentage
              : sideStackWidthPercentage),
          relativeTop: 0,
          relativeLeft: 0,
        });
      }
    }
  }

  return {
    gridWithStreamsPosition,
    isStreamsGrid,
    sideStackWidthPercentage,
  };
};

const useCalculateMainViewLayoutStates = ({
  mainViewGrid: _mainViewGrid,
  mainViewLayout: _mainViewLayout,
  mainViewSelectedStreams: _mainViewSelectedStreams,
  interactivityMode,
  conferenceMainViewVisibleWebcamStreams,
  mainViewSoloModeParticipantId,
  inQueueStreams,
  showAudioAvatars,
  activeSpeakerParticipantId,
  conferenceModeParticipantAutoCountEnabled,
}: {
  mainViewGrid: string[];
  mainViewLayout: null | mainViewLayoutType;
  mainViewSelectedStreams: mainViewSelectedStreamsType;
  interactivityMode: interactivityModeType;
  conferenceMainViewVisibleWebcamStreams: conferenceMainViewVisibleWebcamStreamsType;
  mainViewSoloModeParticipantId: string | null;
  inQueueStreams: inQueueStreamsType;
  showAudioAvatars: boolean;
  activeSpeakerParticipantId: null | string;
  conferenceModeParticipantAutoCountEnabled: boolean;
}) => {
  const mainViewLayout = useMemo(
    () => _mainViewLayout || mainViewWebcamLayouts.GROUP,
    [_mainViewLayout]
  );

  const {
    mainViewGrid: __mainViewGrid,
    mainViewSelectedStreams: __mainViewSelectedStreams,
  } = useMemo(() => {
    if (interactivityMode === interactivityModes.CONFERENCE) {
      const __mainViewSelectedStreams = new Map(_mainViewSelectedStreams);
      const mainViewGrid: string[] = [];

      conferenceMainViewVisibleWebcamStreams.forEach(({ participantId }) => {
        const type = streamTypes.WEBCAM;

        const key = `${participantId}_${type}`;
        mainViewGrid.push(key);

        __mainViewSelectedStreams.set(key, {
          action: "ADD_STREAM",
          participantId,
          key,
          type,
        } as {
          action: string;
          mode: string;
          participantId: string;
          streamId: string;
          type: string;
          key?: string;
        });
      });

      return {
        mainViewSelectedStreams: __mainViewSelectedStreams,
        mainViewGrid,
        inQueueStreams,
        showAudioAvatars,
      };
    } else {
      if (mainViewSoloModeParticipantId) {
        return {
          mainViewSelectedStreams: new Map(_mainViewSelectedStreams),
          mainViewGrid: [
            generateStreamId({
              participantId: mainViewSoloModeParticipantId,
              type: streamTypes.WEBCAM,
            }),
          ],
          inQueueStreams,
          showAudioAvatars,
        };
      } else {
        return {
          mainViewSelectedStreams: new Map(_mainViewSelectedStreams),
          mainViewGrid: [..._mainViewGrid],
          inQueueStreams,
          showAudioAvatars,
        };
      }
    }
  }, [
    _mainViewGrid,
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
    _mainViewSelectedStreams,
    mainViewSoloModeParticipantId,
    inQueueStreams,
    showAudioAvatars,
  ]);

  const layout = useMemo(() => {
    const _mainViewSelectedStreams = new Map(__mainViewSelectedStreams);

    const isShareStreamActive = [..._mainViewSelectedStreams.keys()].find(
      (streamId) => streamId.includes(streamTypes.SHARE)
    );

    const webcamStreamsCount = [..._mainViewSelectedStreams.keys()].filter(
      (streamId) => streamId.includes(streamTypes.WEBCAM)
    ).length;

    const isOnlyWebcamStreamActive = !isShareStreamActive;

    const isShareLayoutSelected =
      Object.keys(mainViewShareLayouts).includes(mainViewLayout);

    const isWebcamLayoutSelected = Object.keys(mainViewWebcamLayouts).includes(
      mainViewLayout
    );

    // is only webcam stream active and webcam layout selected
    if (isOnlyWebcamStreamActive && isWebcamLayoutSelected) {
      if (
        webcamStreamsCount === 1 &&
        mainViewLayout === mainViewWebcamLayouts.SPOTLIGHT
      ) {
        return mainViewWebcamLayouts.GROUP;
      } else {
        return mainViewLayout;
      }
    }

    // is only webcam stream active but share layout selected
    if (isOnlyWebcamStreamActive && isShareLayoutSelected) {
      if (mainViewLayout === mainViewShareLayouts.NEWS) {
        return mainViewWebcamLayouts.SOLO;
      } else {
        return mainViewWebcamLayouts.GROUP;
      }
    }

    // is share stream active and share layout selected
    if (isShareStreamActive && isShareLayoutSelected) {
      if (webcamStreamsCount === 0) {
        return mainViewShareLayouts.CINEMA;
      } else {
        return mainViewLayout;
      }
    }

    return mainViewLayout;

    // commented
    // // is share stream active but webcam layout selected
    // if (isShareStreamActive && isWebcamLayoutSelected) {
    //   if (mainViewLayout === mainViewWebcamLayouts.SOLO) {
    //     return mainViewShareLayouts.NEWS;
    //   } else {
    //     return mainViewShareLayouts.SCREEN;
    //   }
    // }
    //
    //
    // console.error("LAYOUT SELECTION FAILED!", {
    //   _mainViewSelectedStreams,
    //   mainViewLayout,
    // });
  }, [mainViewLayout, __mainViewSelectedStreams]);

  // remapping layout for default selections
  const { mainViewSelectedStreams, mainViewGrid, audioOnlyWebcamStreamIds } =
    useMemo(() => {
      const audioOnlyWebcamStreamIds: string[] = [];

      const _mainViewSelectedStreams = new Map(__mainViewSelectedStreams);

      const isWebcamLayoutSelected = Object.keys(
        mainViewWebcamLayouts
      ).includes(layout);

      const activeShareStreamId = [..._mainViewSelectedStreams.keys()].find(
        (streamId) => streamId.includes(streamTypes.SHARE)
      );

      if (activeShareStreamId && isWebcamLayoutSelected) {
        _mainViewSelectedStreams.delete(activeShareStreamId);
      }

      if (
        !showAudioAvatars &&
        interactivityMode === interactivityModes.STUDIO
      ) {
        const webcamStreamIds = [..._mainViewSelectedStreams.keys()].filter(
          (streamId) => streamId.includes(streamTypes.WEBCAM)
        );

        webcamStreamIds.forEach((webcamStreamId) => {
          const participantId = webcamStreamId.split("_")[0];

          const isWebcamOn = inQueueStreams.find(
            ({ participantId: _participantId, kind }) =>
              participantId === _participantId &&
              kind === mediaStreamKinds.video
          );

          if (!isWebcamOn) {
            _mainViewSelectedStreams.delete(webcamStreamId);

            const indexToBeDeleted = __mainViewGrid.findIndex(
              (streamId) => webcamStreamId === streamId
            );

            if (indexToBeDeleted !== -1) {
              __mainViewGrid.splice(indexToBeDeleted, 1);
            }

            audioOnlyWebcamStreamIds.push(webcamStreamId);
          }
        });
      }

      return {
        mainViewSelectedStreams: _mainViewSelectedStreams,
        mainViewGrid: __mainViewGrid,
        audioOnlyWebcamStreamIds,
      };
    }, [
      layout,
      __mainViewSelectedStreams,
      __mainViewGrid,
      inQueueStreams,
      showAudioAvatars,
      interactivityMode,
    ]);

  const {
    gridWithStreamsPosition: _gridWithStreamsPosition,
    isStreamsGrid,
    sideStackWidthPercentage,
  } = useMemo(
    () =>
      generateGridFromStreamIdsAndLayout({
        layout,
        mainViewSelectedStreams: new Map(mainViewSelectedStreams),
        mainViewGrid: [...mainViewGrid],
        activeSpeakerParticipantId,
        interactivityMode,
        conferenceModeParticipantAutoCountEnabled, // used for re-calculation on change
      }),
    [
      layout,
      mainViewSelectedStreams,
      mainViewGrid,
      activeSpeakerParticipantId,
      interactivityMode,
      conferenceModeParticipantAutoCountEnabled,
    ]
  );

  const gridWithStreamsPosition = useMemo(
    () =>
      _gridWithStreamsPosition.map((item) => {
        const { streamId } = item;

        const isWebcamStream = streamId.includes(streamTypes.WEBCAM);
        const isShareStream = streamId.includes(streamTypes.SHARE);

        const isScreenShareStream = streamId.includes(streamModes.SCREEN);
        const isFileShareStream =
          streamId.includes(streamModes.PDF) ||
          streamId.includes(streamModes.PPT);
        const isVideoShareStream = streamId.includes(streamModes.VIDEO);
        const isAppPollShareStream = streamId.includes(streamModes.APPPOLL);
        const isAppWordcloudShareStream = streamId.includes(
          streamModes.APPWORDCLOUD
        );
        const isQNAShareStream = streamId.includes(streamModes.QNA);
        const isInputFileVideoShareStream = streamId.includes(
          streamModes.INPUTFILEVIDYO
        );
        const isExtraWebcamStream = streamId.includes(streamTypes.EXTRAWBCM);
        const isRtmpInShareStream = streamId.includes(streamModes.RTMP_IN);

        const fileId = isFileShareStream ? streamId.split("_")[3] : null;
        const videoId = isVideoShareStream ? streamId.split("_")[3] : null;
        const pollId = isAppPollShareStream ? streamId.split("_")[3] : null;
        const wordcloudId = isAppWordcloudShareStream
          ? streamId.split("_")[3]
          : null;
        const qnaId = isQNAShareStream ? streamId.split("_")[3] : null;
        const inputFileVideoId = isInputFileVideoShareStream
          ? streamId.split("_")[3]
          : null;
        const extraWebcamId = isExtraWebcamStream
          ? streamId.split("_")[2]
          : null;

        const rtmpInId = isRtmpInShareStream ? streamId.split("_")[3] : null;

        const shareMode = isShareStream ? streamId.split("_")[2] : null;

        const participantId = streamId.split("_")[0];

        const streamIdMeta = {
          participantId,
          isWebcamStream,
          isShareStream,
          isScreenShareStream,
          isFileShareStream,
          isVideoShareStream,
          isAppPollShareStream,
          isAppWordcloudShareStream,
          isQNAShareStream,
          fileId,
          videoId,
          pollId,
          wordcloudId,
          shareMode,
          qnaId,
          inputFileVideoId,
          extraWebcamId,
          isInputFileVideoShareStream,
          isExtraWebcamStream,
          rtmpInId,
          isRtmpInShareStream,
        };

        return { ...item, streamIdMeta };
      }),
    [_gridWithStreamsPosition]
  );

  return {
    sideStackWidthPercentage,
    isStreamsGrid,
    gridWithStreamsPosition,
    mainViewLayoutActive: layout,
    mainViewLayoutSelected: mainViewLayout,
    audioOnlyWebcamStreamIds,
  };
};

export default useCalculateMainViewLayoutStates;
