import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";

const useShowAudioAvatars = () => {
  const { publish } = useAppSingalingPublish(
    appPubSubTopics.SHOW_AUDIO_AVATARS
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const enable = async (opt?: { force: true }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(JSON.stringify({ payload: { enabled: true } }));
  };

  const disable = async (opt?: { force: true }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(JSON.stringify({ payload: { enabled: false } }));
  };

  return { enable, disable };
};

export default useShowAudioAvatars;
