import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { getBrandBackgroundsTopicById } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const backgroundsActions = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  RENAME: "RENAME",
  SORT: "SORT",
};

const BrandBackgroundsListner = ({ id: brandId }: { id: string }) => {
  const { setBackgrounds, setSortedBackgroundIds } = useAppContext();

  const _handleAdd = (background: singleBrandBackgroundsType) => {
    setBackgrounds(
      (s) =>
        ({
          ...s,
          [brandId]: [...(s[brandId] || []), background],
        } as backgroundsType)
    );
  };

  const _handleRemove = ({ id }: { id: string }) => {
    setBackgrounds((s) => ({
      ...s,
      [brandId]: s[brandId].filter(({ id: _id }) => _id !== id),
    }));
  };

  const _handleRename = ({ id, name }: { id: string; name: string }) => {
    setBackgrounds((s) => ({
      ...s,
      [brandId]: s[brandId].map((background) =>
        id === background.id ? { ...background, name } : background
      ),
    }));
  };

  const _handleSort = ({ ids }: { ids: string[] }) => {
    setSortedBackgroundIds((s) => ({ ...s, [brandId]: ids }));
  };

  useAppSingalingSubscribe(
    getBrandBackgroundsTopicById(brandId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case backgroundsActions.ADD:
          _handleAdd(message.payload);
          break;

        case backgroundsActions.REMOVE:
          _handleRemove(message.payload);
          break;

        case backgroundsActions.RENAME:
          _handleRename(message.payload);
          break;

        case backgroundsActions.SORT:
          _handleSort(message.payload);
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default BrandBackgroundsListner;
