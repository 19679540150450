import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import getUserBrands from "../../apis/brands/get-user-brands";
import activateBrand from "../../apis/brands/activate-brand";
import getBrandAssets from "../../apis/brands/get-brand-assets";
import { useEffect, useRef } from "react";
import sleep from "../../utils/sleep";

const useSetBrandsAndAssets = () => {
  const {
    setBrands,
    //
    setActiveBrandId,
    setBackgroundMusics,
    setSortedBackgroundMusicIds,
    setBackgrounds,
    setSortedBackgroundIds,
    setBrandLogos,
    setSortedBrandLogoIds,
    setSortedOverlayIds,
    setOverlays,
    setVideoClips,
    setSortedVideoClipIds,
    settingBrandsAndAssets,
    setSettingBrandsAndAssets,
  } = useAppContext();

  const { userId } = useAppConfigContext();

  const settingBrandsAndAssetsRef = useRef(settingBrandsAndAssets);

  useEffect(() => {
    settingBrandsAndAssetsRef.current = settingBrandsAndAssets;
  }, [settingBrandsAndAssets]);

  // const { activatAssetsOfBrand } = useBrands();

  const setBrandsAndAssets = async (): Promise<string | undefined> => {
    if (settingBrandsAndAssetsRef.current) {
      return;
    }

    let activeBrandIdToReturn: string | undefined = undefined;

    await sleep(100);

    setSettingBrandsAndAssets(true);

    await sleep(100);

    try {
      const { brands, success } = await getUserBrands({ userId });

      if (success && brands && brands?.length) {
        setBrands(brands);

        const activeBrand = brands.find(({ isActive }) => isActive);

        let activeBrandId = activeBrand?.id;

        if (!activeBrand) {
          activeBrandId = brands[0].id;

          await activateBrand({ brandId: activeBrandId, userId });
        }

        const brandsAssets = await Promise.all(
          brands.map((brand) => {
            return getBrandAssets({ brandId: brand.id, userId });
          })
        );

        const backgroundMusics: backgroundMusicsType = {};
        const sortedBackgroundMusicIds: sortedBackgroundMusicIdsType = {};
        const _backgrounds: backgroundsType = {};
        const sortedBackgroundIds: sortedBackgroundIdsType = {};
        const brandLogos: brandLogosType = {};
        const sortedBrandLogoIds: sortedBrandLogoIdsType = {};
        const sortedOverlayIds: sortedOverlayIdsType = {};
        const _overlays: overlaysType = {};
        const videoClips: videoClipsType = {};
        const sortedVideoClipIds: sortedVideoClipIdsType = {};

        for (let index = 0; index < brandsAssets.length; index++) {
          const brandAssets = brandsAssets[index];

          const { success, data, brandId } = brandAssets;

          if (success && data && brandId) {
            const {
              background: backgrounds,
              logo: logos,
              music: musics,
              overlay: overlays,
              video: videos,
            } = data;

            sortedBackgroundIds[brandId] = backgrounds.map(({ id }) => id);
            sortedBrandLogoIds[brandId] = logos.map(({ id }) => id);
            sortedBackgroundMusicIds[brandId] = musics.map(({ id }) => id);
            sortedOverlayIds[brandId] = overlays.map(({ id }) => id);
            sortedVideoClipIds[brandId] = videos.map(({ id }) => id);

            _backgrounds[brandId] = backgrounds.map(
              ({ id, file: remoteUrl, name }) => ({
                id,
                remoteUrl,
                name,
              })
            );

            brandLogos[brandId] = logos.map(
              ({ id, file: remoteUrl, name }) => ({
                id,
                remoteUrl,
                name,
              })
            );

            backgroundMusics[brandId] = musics.map(
              ({ id, file: remoteUrl, name }) => ({
                id,
                remoteUrl,
                name,
              })
            );

            _overlays[brandId] = overlays.map(
              ({ id, file: remoteUrl, name }) => ({
                id,
                remoteUrl,
                name,
              })
            );

            videoClips[brandId] = videos.map(
              ({ id, file: remoteUrl, name }) => {
                const videoOriginal =
                  typeof remoteUrl !== "string"
                    ? remoteUrl["original"]
                    : remoteUrl;
                const video360 =
                  typeof remoteUrl !== "string" ? remoteUrl["360"] : remoteUrl;
                const video480 =
                  typeof remoteUrl !== "string" ? remoteUrl["480"] : remoteUrl;
                const video720 =
                  typeof remoteUrl !== "string" ? remoteUrl["720"] : remoteUrl;
                const videoThumbnail =
                  typeof remoteUrl !== "string"
                    ? remoteUrl["thumbnail"]
                    : remoteUrl;

                return {
                  id,
                  remoteUrl: remoteUrl as string,
                  videoOriginal,
                  video360,
                  video480,
                  video720,
                  videoThumbnail,
                  name,
                };
              }
            );
          }
        }

        setBackgroundMusics(backgroundMusics);

        setSortedBackgroundMusicIds(sortedBackgroundMusicIds);

        setBackgrounds(_backgrounds);

        setSortedBackgroundIds(sortedBackgroundIds);

        setBrandLogos(brandLogos);

        setSortedBrandLogoIds(sortedBrandLogoIds);

        setSortedOverlayIds(sortedOverlayIds);

        setOverlays(_overlays);

        setVideoClips(videoClips);

        setSortedVideoClipIds(sortedVideoClipIds);

        // await activatAssetsOfBrand({ id: activeBrandId as string });

        setActiveBrandId(activeBrandId);

        activeBrandIdToReturn = activeBrandId;
      }
    } catch (error) {
      //
    }

    await sleep(100);

    setSettingBrandsAndAssets(false);

    await sleep(100);

    return activeBrandIdToReturn;
  };

  return { setBrandsAndAssets };
};

export default useSetBrandsAndAssets;
