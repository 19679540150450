import { useMemo } from "react";
// import { usePubSub } from "@videosdk.live/react-sdk";
import { useAppContext } from "../../contexts/appContextDef";
import { getInputFileVideoStreamTopicById } from "../../utils/pubSubTopics";
import useValidateSpeakerCanTakeAction from "../appState/useValidateSpeakerCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { inputFileVideoShareStreamPubSubActions } from "../../listners/streams/AllInputFileVideoShareStreamListner";
import useAppRtcParticipantMediaStats from "../../appRtc/useAppRtcParticipantMediaStats";

const useInputFileVideoShareStream = ({
  inputFileVideoId,
}: {
  inputFileVideoId: string;
}) => {
  const {
    activeInputFileVideoShareStreamAllParticipants:
      activeInputFileVideoShareStreams,
    allInputFileVideoShareStreamStates,
  } = useAppContext();

  const currentInputFileVideoStream = useMemo(() => {
    const currentInputFileVideoStream = activeInputFileVideoShareStreams.find(
      ({ id }) => id === inputFileVideoId
    );

    return currentInputFileVideoStream;
  }, [activeInputFileVideoShareStreams, inputFileVideoId]);

  const { _height, _width, _id, _durationInSec, _participantId } =
    useMemo(() => {
      const _height = currentInputFileVideoStream?.height;
      const _width = currentInputFileVideoStream?.width;
      const _id = currentInputFileVideoStream?.id;

      const _durationInSec = currentInputFileVideoStream?.durationInSec;

      const _participantId = currentInputFileVideoStream?.participantId;

      return { _height, _width, _id, _durationInSec, _participantId };
    }, [currentInputFileVideoStream]);

  const { inputFileVideoShareStreamPublications } =
    useAppRtcParticipantMediaStats(_participantId as string);

  const { _playing, _volume, _currentTime, audioTrack, videoTrack } =
    useMemo(() => {
      const inputFileVideoShareStreamPublication =
        inputFileVideoShareStreamPublications.find(
          ({ id }) => id === inputFileVideoId
        );

      const currentInputFileVideoStream =
        allInputFileVideoShareStreamStates.find(
          ({ id }) => id === inputFileVideoId
        );

      const _playing = currentInputFileVideoStream?.playing;
      const _volume = currentInputFileVideoStream?.volume;
      const _currentTime = currentInputFileVideoStream?.currentTime;
      const audioTrack = inputFileVideoShareStreamPublication?.audioTrack;
      const videoTrack = inputFileVideoShareStreamPublication?.videoTrack;

      return { _playing, _volume, _currentTime, audioTrack, videoTrack };
    }, [
      allInputFileVideoShareStreamStates,
      inputFileVideoShareStreamPublications,
      inputFileVideoId,
    ]);

  const { publish: inputFileVideoStreamPublish } = useAppSingalingPublish(
    getInputFileVideoStreamTopicById(inputFileVideoId)
  );

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  const play = async () => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    inputFileVideoStreamPublish(
      JSON.stringify({
        action: inputFileVideoShareStreamPubSubActions.PLAY,
        payload: {},
      })
    );
  };

  const pause = async () => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    inputFileVideoStreamPublish(
      JSON.stringify({
        action: inputFileVideoShareStreamPubSubActions.PAUSE,
        payload: {},
      })
    );
  };

  const seekTo = async ({ seekToSec }: { seekToSec: number }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    inputFileVideoStreamPublish(
      JSON.stringify({
        action: inputFileVideoShareStreamPubSubActions.SEEK_TO,
        payload: { seekToSec },
      })
    );
  };

  const currentTimeChanged = async ({
    currentTime,
  }: {
    currentTime: number;
  }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    inputFileVideoStreamPublish(
      JSON.stringify({
        action: inputFileVideoShareStreamPubSubActions.CURRENT_TIME,
        payload: { currentTime },
      })
    );
  };

  const changeVolume = async ({ volume }: { volume: number }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    inputFileVideoStreamPublish(
      JSON.stringify({
        action: inputFileVideoShareStreamPubSubActions.VOLUME,
        payload: { volume },
      })
    );
  };

  return {
    play,
    pause,
    seekTo,
    currentTimeChanged,
    changeVolume,
    //

    playing: _playing,
    volume: _volume,
    height: _height,
    width: _width,
    durationInSec: _durationInSec,
    id: _id,
    currentTime: _currentTime,
    audioTrack,
    videoTrack,
  };
};

export default useInputFileVideoShareStream;
