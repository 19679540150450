import { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";

const useGetParticipantName = () => {
  const { modifiedParticipantsNames, joinedParticipants } = useAppContext();

  const joinedParticipantsRef = useRef(joinedParticipants);

  const modifiedParticipantsNamesRef = useRef(modifiedParticipantsNames);

  useEffect(() => {
    joinedParticipantsRef.current = joinedParticipants;
  }, [joinedParticipants]);

  const getParticipantName = (participantId: string) => {
    const modifiedName = modifiedParticipantsNamesRef.current.find(
      ({ participantId: _participantId }) => _participantId === participantId
    );

    return (
      modifiedName?.name ||
      joinedParticipantsRef.current.find(
        ({ participantId: _participantId }) => _participantId === participantId
      )?.participantName ||
      ""
    );
  };

  return { getParticipantName };
};

export default useGetParticipantName;
