import React, { useEffect, useMemo, useRef } from "react";
import { useAppContext } from "../../../contexts/appContextDef";
import useWordcloud from "../../../hooks/apps/wordcloud/useWordcloud";
import {
  MdArrowBack,
  MdDelete,
  MdGroup,
  MdListAlt,
  MdMoreVert,
  MdOutlineWbCloudy,
  MdScreenShare,
  MdStream,
} from "react-icons/md";
import { Menu, MenuItem } from "@szhsin/react-menu";
import useMainViewLayout from "../../../hooks/appState/useMainViewLayout";
import { appModes, mainViewLayouts } from "../../../utils/constants";

export const WordcloudControlsMenu = ({ id }: { id: string }) => {
  const {
    toggleActive,
    isActive,
    isInMainView,
    toggleMainViewStreams,
    deleteWordcloud,
  } = useWordcloud({ id });

  const { conferenceAutoMainVideoLayoutEnabled } = useAppContext();

  const { changeMainViewLayout, mainViewLayoutActive } = useMainViewLayout();

  const conferenceAutoMainVideoLayoutEnabledRef = useRef(
    conferenceAutoMainVideoLayoutEnabled
  );

  const isCinemaLayoutActive = useMemo(
    () => mainViewLayoutActive === mainViewLayouts.CINEMA,
    [mainViewLayoutActive]
  );

  const _handleEnterFullscreenLayout = () => {
    const conferenceAutoMainVideoLayoutEnabled =
      conferenceAutoMainVideoLayoutEnabledRef.current;

    changeMainViewLayout({
      layout: mainViewLayouts.CINEMA,
      conferenceAutoMainVideoLayoutEnabled,
    });
  };

  const _handleExitFullscreenLayout = () => {
    const conferenceAutoMainVideoLayoutEnabled =
      conferenceAutoMainVideoLayoutEnabledRef.current;

    changeMainViewLayout({
      layout: mainViewLayouts.SCREEN,
      conferenceAutoMainVideoLayoutEnabled,
    });
  };

  useEffect(() => {
    conferenceAutoMainVideoLayoutEnabledRef.current =
      conferenceAutoMainVideoLayoutEnabled;
  }, [conferenceAutoMainVideoLayoutEnabled]);

  return (
    <Menu
      menuButton={
        <span>
          <button
            className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
          >
            <div>
              <MdMoreVert size={16} />
            </div>
          </button>
        </span>
      }
      transition
    >
      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={deleteWordcloud}
      >
        <div className="flex items-center justify-center gap-1">
          <MdDelete />
          Delete Wordcloud
        </div>
      </MenuItem>

      {/* <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={clearWordcloud}
      >
        <div className="flex items-center justify-center gap-1">
          <MdClear />
          Clear Wordcloud Results
        </div>
      </MenuItem> */}

      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={toggleActive}
      >
        <div className="flex items-center justify-center gap-1">
          <MdListAlt />
          {isActive ? "Deactivate Wordcloud" : "Activate Wordcloud"}
        </div>
      </MenuItem>

      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={toggleMainViewStreams}
      >
        <div className="flex items-center justify-center gap-1">
          <MdStream />
          {isInMainView ? "Remove from stream" : "Add to stream"}
        </div>
      </MenuItem>

      {isInMainView ? (
        isCinemaLayoutActive ? (
          <MenuItem
            className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
            onClick={_handleExitFullscreenLayout}
          >
            <div className="flex items-center justify-center gap-1">
              <MdScreenShare />
              Exit fullscreen layout
            </div>
          </MenuItem>
        ) : (
          <MenuItem
            className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
            onClick={_handleEnterFullscreenLayout}
          >
            <div className="flex items-center justify-center gap-1">
              <MdScreenShare />
              Fullscreen layout
            </div>
          </MenuItem>
        )
      ) : (
        <React.Fragment />
      )}
    </Menu>
  );
};

const WordcloudsListItem = ({
  id,
  showBackIcon,
}: {
  id: string;
  showBackIcon?: boolean;
}) => {
  const { appMode, setSidePanelActiveWordcloudId } = useAppContext();

  const { isActive, question, isInMainView, answers } = useWordcloud({
    id,
  });

  const numAnswers = useMemo(
    () =>
      answers
        ? Object.keys(answers)
            .map((key) => answers[key])
            .reduce((a, b) => a + b, 0)
        : 0,
    [answers]
  );

  return (
    <div
      onClick={() => {
        setSidePanelActiveWordcloudId(id);
      }}
      className={`flex flex-1 ${
        showBackIcon ? "" : "hover:bg-gray-200"
      } p-2 cursor-pointer`}
    >
      <div className="flex items-center justify-center p-1 aspect-square">
        {showBackIcon ? (
          <button
            onClick={(e) => {
              e.stopPropagation();

              setSidePanelActiveWordcloudId(null);
            }}
            className="btn btn-outline btn-primary btn-circle btn-sm"
          >
            <MdArrowBack size={18} />
          </button>
        ) : (
          <MdOutlineWbCloudy size={24} />
        )}
      </div>

      <div className="flex flex-1 flex-col ml-2">
        <div>
          <p className="font-bold">{showBackIcon ? "Wordcloud" : question}</p>
        </div>

        <div className="flex gap-2 mt-1 items-center justify-left">
          <div className="flex items-center justify-center">
            <MdGroup size={16} />
            <p className="ml-1 text-xs font-bold">{numAnswers}</p>
          </div>

          <div
            className={`badge ${
              isActive ? "badge-success text-white" : "badge text-gray-300"
            } badge-outline text-xs font-bold`}
          >
            ACTIVE
          </div>

          <div
            className={`badge ${
              isInMainView ? "badge-success text-white" : "text-gray-300"
            } badge-outline text-xs font-bold`}
          >
            STREAMING
          </div>
        </div>
      </div>
      {appMode === appModes.HOST && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="flex items-center justify-center"
        >
          <WordcloudControlsMenu {...{ id }} />
        </div>
      )}
    </div>
  );
};

export default WordcloudsListItem;
