// import { usePubSub } from "@videosdk.live/react-sdk";
import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const BrandShowDisplayNamesListner = () => {
  const { setShowDisplayName } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.BRAND_SHOW_DISPLAY_NAMES,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setShowDisplayName(message.showDisplayNames);
    }
  );

  return <React.Fragment />;
};

export default BrandShowDisplayNamesListner;
