// import { useMeeting } from "@videosdk.live/react-sdk";
// import { useEffect, useRef } from "react";
import { createUID } from "../../utils/createUID";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
// import { useAppContext } from "../../contexts/appContextDef";
// import _getCurrentTime from "../../apis/studio/get-current-time";

const useGetCurrentTime = () => {
  // const { currentTimeThreshold, setCurrentTimeThreshold } = useAppContext();

  // const { meeting } = useMeeting();

  // const pubSubRef = useRef(meeting?.pubSub);
  // const currentTimeThresholdRef = useRef(currentTimeThreshold);

  // useEffect(() => {
  //   pubSubRef.current = meeting?.pubSub;
  // }, [meeting]);

  // useEffect(() => {
  //   currentTimeThresholdRef.current = currentTimeThreshold;
  // }, [currentTimeThreshold]);

  const getCurrentTime = async () => {
    // await _getCurrentTime();

    // if (currentTimeThresholdRef.current) {
    //   const currentTime =
    //     new Date().getTime() - currentTimeThresholdRef.current;

    //   return { currentTime };
    // } else {
    const currentTime: number = await new Promise((resolve) => {
      const id = createUID();

      const handler = ({ timestamp }: { timestamp: number }) => {
        appEventEmitter.off(
          appEventEmitterEvents.RESOLVE_REQ_CURRENT_TIME(id),
          handler
        );

        resolve(timestamp);
      };

      appEventEmitter.on(
        appEventEmitterEvents.RESOLVE_REQ_CURRENT_TIME(id),
        handler
      );

      appEventEmitter.emit(appEventEmitterEvents.INIT_REQ_CURRENT_TIME, { id });
    });

    // setCurrentTimeThreshold(
    //   new Date().getTime() - new Date(currentTime).getTime()
    // );

    return { currentTime: new Date(currentTime).getTime() };
    // }
  };

  return { getCurrentTime };
};

export default useGetCurrentTime;
