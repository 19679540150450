import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useIsOldest from "../../hooks/appState/useIsOldest";
import useGetCalculatedConferenceModeMaxParticipantCount from "../../hooks/conference/useGetCalculatedConferenceModeMaxParticipantCount";
import useValidateConnection from "../../hooks/appState/useValidateConnection";
import { interactivityModes } from "../../utils/constants";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const ConferenceModeMaxParticipantCount = () => {
  const calculatedConferenceModeMaxParticipantCountIntervalRef =
    useRef<null | ReturnType<typeof setInterval>>();

  const {
    conferenceModeMaxParticipantCount,
    interactivityMode,
    conferenceModeParticipantAutoCountEnabled,
  } = useAppContext();

  const { getIsOldestParticipant } = useIsOldest();
  const { getCalculatedConferenceModeMaxParticipantCount } =
    useGetCalculatedConferenceModeMaxParticipantCount();
  const { getIsSignalLevelValid } = useValidateConnection();

  const { localParticipantId } = useLocalParticipantId();

  const localParticipantIdRef = useRef(localParticipantId);
  const conferenceModeMaxParticipantCountRef = useRef(
    conferenceModeMaxParticipantCount
  );

  const publishRef = useRef<appSingalingPublishType>();

  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);

  useEffect(() => {
    conferenceModeMaxParticipantCountRef.current =
      conferenceModeMaxParticipantCount;
  }, [conferenceModeMaxParticipantCount]);

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.CONFERENCE_MODE_MAX_PARTICIPANT_COUNT
  );

  const calculateAutoConferenceModeMaxParticipantCount = async () => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) return;

    const oldConferenceModeMaxParticipantCount =
      conferenceModeMaxParticipantCountRef.current;

    const {
      conferenceModeMaxParticipantCount: newConferenceModeMaxParticipantCount,
    } = getCalculatedConferenceModeMaxParticipantCount();

    if (
      newConferenceModeMaxParticipantCount ===
      oldConferenceModeMaxParticipantCount
    )
      return;

    const { isSignalLevelValid } = getIsSignalLevelValid();

    if (!isSignalLevelValid) return;

    if (publishRef.current) {
      publishRef.current(
        JSON.stringify({
          conferenceModeMaxParticipantCount:
            newConferenceModeMaxParticipantCount,
          conferenceModeParticipantAutoCountEnabled: true,
        })
      );
    }
  };

  const startCalculateAutoConferenceModeMaxParticipantCountInterval = () => {
    if (calculatedConferenceModeMaxParticipantCountIntervalRef.current) return;

    calculatedConferenceModeMaxParticipantCountIntervalRef.current =
      setInterval(() => {
        calculateAutoConferenceModeMaxParticipantCount();
      }, 1000);
  };

  const stopCalculateAutoConferenceModeMaxParticipantCountInterval = () => {
    if (!calculatedConferenceModeMaxParticipantCountIntervalRef.current) return;

    const calculatedConferenceModeMaxParticipantCountInterval =
      calculatedConferenceModeMaxParticipantCountIntervalRef.current;

    calculatedConferenceModeMaxParticipantCountIntervalRef.current = null;

    clearInterval(calculatedConferenceModeMaxParticipantCountInterval);
  };

  useEffect(() => {
    if (
      interactivityMode === interactivityModes.CONFERENCE &&
      conferenceModeParticipantAutoCountEnabled
    ) {
      startCalculateAutoConferenceModeMaxParticipantCountInterval();
    } else {
      stopCalculateAutoConferenceModeMaxParticipantCountInterval();
    }
  }, [interactivityMode, conferenceModeParticipantAutoCountEnabled]);

  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  return <React.Fragment />;
};

export default ConferenceModeMaxParticipantCount;
