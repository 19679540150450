import { useContext, createContext, useState } from "react";
import CachedUserData from "../utils/CachedUserData";
import { nanoid } from "nanoid";
import { businessPlansConfig } from "../utils/constants";
import getBroadcastData from "../apis/broadcasts/get-broadcast-data";
import { validateBroadcastId } from "../utils/validateBroadcastId";

export const AppConfigContext = createContext({} as appConfigContextType);

export const prepareConfig = async (): Promise<{
  config: null | appConfigType;
  success: boolean;
}> => {
  const first = window.location.pathname.split("/")[1];
  const second = window.location.pathname.split("/")[2];

  const isFirstBroadcastId = validateBroadcastId({ broadcastId: first });
  const isSecondBroadcastId = validateBroadcastId({ broadcastId: second });

  const broadcastId = isFirstBroadcastId
    ? first
    : isSecondBroadcastId
    ? second
    : "";

  const { success, data: rawConfig } = await getBroadcastData({ broadcastId });

  if (success && rawConfig) {
    let userId = rawConfig.userId;

    const localStorageUserData = CachedUserData.getItem();

    let cachedName = null;
    let cachedParticipantHeadline = null;

    if (localStorageUserData?.userId === userId) {
      cachedName = localStorageUserData.name;
      cachedParticipantHeadline = localStorageUserData.participantHeadline;
    } else {
      if (userId) {
        CachedUserData.setItem({ userId });
      } else if (localStorageUserData?.userId) {
        userId = localStorageUserData?.userId;

        cachedName = localStorageUserData.name;
        cachedParticipantHeadline = localStorageUserData.participantHeadline;
      } else {
        userId = nanoid();

        CachedUserData.setItem({ userId });
      }
    }

    const config = {
      ...rawConfig,
      userId: rawConfig.broadcastUserId,
      studioUserId: userId,
      name: cachedName,
      participantHeadline: cachedParticipantHeadline,
      broadcastId: rawConfig.broadcastId || broadcastId,
      ...businessPlansConfig[rawConfig.planType],
      interpretations: rawConfig.interpretations.filter(
        ({ isOriginal }) => !isOriginal
      ),
      originalAudioPublishAgoraChannelId: rawConfig.interpretations?.find(
        ({ isOriginal }) => isOriginal
      )?.agoraChannelId as string,
    };

    return { config, success };
  } else {
    return { config: null, success };
  }
};

export const useAppConfigContext = (): appConfigContextType =>
  useContext(AppConfigContext);

export const AppConfigProvider = ({
  cometChatUserId,
  meetingId,
  // token,
  speakerChatEnabled,
  audienceChatEnabled,
  speakerChatGroupId,
  audienceChatGroupId,
  cometChatAppID,
  cometChatRegion,
  cometChatAuthKey,
  destinations: _destinations,
  children,
  userId,
  broadcastId,
  studioUserId,
  webcamState,
  micState,
  isRecorder: _isRecorder,
  isInterpreter: _isInterpreter,
  participantId,
  embedded,
  defaultName,
  interpretations,
  interpretationLanguageCode,
  debug,
  meetingName,
  broadcastDestinations,
  maxParticipantsCount,
  recordingEnabled,
  planType,
  defaultDestination,
  chatUrl,
  quickstartStepsCompleted: _quickstartStepsCompleted,
  quickstartStepsSkipped: _quickstartStepsSkipped,
  metaData,
  meetingMode,
  thirdPartyRtmpData,
  originalAudioPublishAgoraChannelId,
}: appConfigProviderPropsType) => {
  const [isRecorder, setIsRecorder] = useState(_isRecorder);
  const [isInterpreter, setIsInterpreter] = useState(_isInterpreter);

  const [destinations, setDestinations] = useState(_destinations);

  const [sessionEndedDueToTimeout, setSessionEndedDueToTimeout] =
    useState(false);

  const [quickstartStepsCompleted, setQuickstartStepsCompleted] = useState(
    _quickstartStepsCompleted?.filter((step) => step !== "N/A") || []
  );

  const [quickstartStepsSkipped, setQuickstartStepsSkipped] = useState(
    _quickstartStepsSkipped?.filter((step) => step !== "N/A") || []
  );

  const [shouldStartRecordingWhenGoLive, setShouldStartRecordingWhenGoLive] =
    useState(recordingEnabled);

  return (
    <AppConfigContext.Provider
      value={{
        cometChatUserId,
        meetingId,
        // token,
        speakerChatEnabled,
        audienceChatEnabled,
        speakerChatGroupId,
        audienceChatGroupId,
        cometChatAppID,
        cometChatRegion,
        cometChatAuthKey,
        destinations,
        userId,
        broadcastId,
        studioUserId,
        webcamState,
        micState,
        isRecorder,
        participantId,
        embedded,
        interpretationLanguageCode,
        defaultName,
        interpretations:
          interpretations?.filter(
            ({
              agoraChannelId,
              name,
              shortName,
              type,
              icon,
              // rtmpUrl,
              // rtmpKey,
            }) => agoraChannelId && name && shortName && type && icon
            // &&
            // rtmpUrl &&
            // rtmpKey
          ) || [],
        interpretationsOriginalDestination: interpretations?.find(
          ({
            agoraChannelId,
            name,
            shortName,
            type,
            icon,
            //  rtmpUrl, rtmpKey
          }) =>
            !(
              (agoraChannelId && name && shortName && type && icon)
              //  &&
              // rtmpUrl &&
              // rtmpKey
            )
        ),
        defaultActiveInterpretationOutputAgoraChannelId:
          interpretationLanguageCode
            ? interpretations?.find(
                ({ shortName }) =>
                  `${shortName}`.toLowerCase() ===
                  `${interpretationLanguageCode}`.toLowerCase()
              )?.agoraChannelId || null
            : null,
        defaultActiveInterpretationInputAgoraChannelId: isInterpreter
          ? (interpretations.filter(({ agoraChannelId }) => agoraChannelId)[0]
              ?.agoraChannelId as string)
          : null,
        isInterpreter,
        debug,
        meetingName,
        broadcastDestinations,
        maxParticipantsCount,
        recordingEnabled,
        planType,
        chatUrl,
        defaultDestination,
        setIsRecorder,
        setIsInterpreter,
        setDestinations,
        sessionEndedDueToTimeout,
        setSessionEndedDueToTimeout,
        quickstartStepsCompleted,
        setQuickstartStepsCompleted,
        quickstartStepsSkipped,
        setQuickstartStepsSkipped,
        shouldStartRecordingWhenGoLive,
        setShouldStartRecordingWhenGoLive,

        metaData,
        meetingMode,
        thirdPartyRtmpData,
        originalAudioPublishAgoraChannelId,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};
