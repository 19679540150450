import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const BrandLogoListner = () => {
  const { setBrandLogo } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.BRAND_LOGO,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setBrandLogo(message);
    }
  );

  return <React.Fragment />;
};

export default BrandLogoListner;
