import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import { getReadyToBePlayedVideoIds } from "../../components/WaitUntilVideosAreReadyToBePlayed";
import { createUID } from "../../utils/createUID";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { useAppContext } from "../../contexts/appContextDef";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

export const backgroundMusicPubSubActions = {
  CHANGE_ACTIVE_ID_AND_REMOTE_URL: "CHANGE_ACTIVE_ID_AND_REMOTE_URL",
  CHANGE_VOLUME: "CHANGE_VOLUME",
  LOOP: "LOOP",
};

const getAudioDurationInSeconds = async ({
  remoteUrl,
  setWaitingForVideosRemoteUrlReadyToBePlayed,
}: {
  remoteUrl: string;
  setWaitingForVideosRemoteUrlReadyToBePlayed: React.Dispatch<
    React.SetStateAction<{ remoteUrl: string; id: string }[]>
  >;
}) => {
  const id = getReadyToBePlayedVideoIds({
    broadcastVideo: true,
    id: createUID(),
  });

  setWaitingForVideosRemoteUrlReadyToBePlayed((s) => [...s, { remoteUrl, id }]);

  const { durationInSec }: { durationInSec: number } = await new Promise(
    (resolve) => {
      appEventEmitter.on(
        appEventEmitterEvents.VIDEO_READY_TO_BE_PLAYED(id),
        resolve
      );
    }
  );

  return durationInSec;
};

const useBackgroundMusic = () => {
  const { publish } = useAppSingalingPublish(appPubSubTopics.BACKGROUND_MUSIC);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();
  const { setWaitingForVideosRemoteUrlReadyToBePlayed } = useAppContext();

  const changeActiveIdAndRemoteUrl = async (
    {
      id,
      remoteUrl,
      playedAt,
    }: {
      id: string | null;
      remoteUrl: string | null;
      playedAt: number | null;
    },
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    let durationInSeconds;

    if (remoteUrl) {
      durationInSeconds = await getAudioDurationInSeconds({
        remoteUrl,
        setWaitingForVideosRemoteUrlReadyToBePlayed,
      });

      if (!durationInSeconds) return;
    }

    publish(
      JSON.stringify({
        payload: {
          id,
          remoteUrl,
          playedAt,
          durationInSeconds,
        },
        action: backgroundMusicPubSubActions.CHANGE_ACTIVE_ID_AND_REMOTE_URL,
      })
    );
  };

  const changeVolume = async (
    { volume }: { volume: number },
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(
      JSON.stringify({
        payload: { volume },
        action: backgroundMusicPubSubActions.CHANGE_VOLUME,
      })
    );
  };

  const changeLoopState = async (
    {
      loop,
      loopedAt,
    }: {
      loop: boolean | null;
      loopedAt: number | null;
    },
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(
      JSON.stringify({
        payload: { loop, loopedAt },
        action: backgroundMusicPubSubActions.LOOP,
      })
    );
  };

  return {
    changeActiveIdAndRemoteUrl,
    changeVolume,
    changeLoopState,
  };
};

export default useBackgroundMusic;
