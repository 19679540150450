// import { usePubSub } from "@videosdk.live/react-sdk";
import { useEffect, useRef } from "react";
import { raiseHandActions } from "../../listners/appState/RaiseHandListner";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateSpeakerCanTakeAction from "./useValidateSpeakerCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useRaiseHand = (participantId: string) => {
  // const { publish: raiseHandPublish } = usePubSub(appPubSubTopics.RAISE_HAND);

  const { publish: raiseHandPublish } = useAppSingalingPublish(
    appPubSubTopics.RAISE_HAND
  );

  // const raiseHandPublishRef = useRef<videosdkPubsubPublishType>(raiseHandPublish);

  const raiseHandPublishRef = useRef<appSingalingPublishType>(raiseHandPublish);

  useEffect(() => {
    raiseHandPublishRef.current = raiseHandPublish;
  }, [raiseHandPublish]);

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  const enableRaiseHand = async () => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    raiseHandPublishRef.current(
      JSON.stringify({
        action: raiseHandActions.ENABLE,
        payload: { participantId },
      })
      // { persist: true }
    );
  };

  const disableRaiseHand = async () => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    raiseHandPublishRef.current(
      JSON.stringify({
        action: raiseHandActions.DISABLE,
        payload: { participantId },
      })
      // { persist: true }
    );
  };

  return { enableRaiseHand, disableRaiseHand };
};

export default useRaiseHand;
