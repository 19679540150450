import React, { useEffect, useMemo, useRef } from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const AudioAndVideoPermissionStatesListner = () => {
  const { webcamState, micState } = useAppConfigContext();

  const {
    setParticipantsAudioVideoCapturerState,
    participantsAudioVideoCapturerState,
  } = useAppContext();

  const setParticipantsAudioVideoCapturerStateRef = useRef(
    setParticipantsAudioVideoCapturerState
  );

  useEffect(() => {
    setParticipantsAudioVideoCapturerStateRef.current =
      setParticipantsAudioVideoCapturerState;
  }, [setParticipantsAudioVideoCapturerState]);

  const { localParticipantId } = useLocalParticipantId();

  const localParticipantVideoCapturerState = useMemo(
    () => participantsAudioVideoCapturerState[localParticipantId]?.camera,
    [participantsAudioVideoCapturerState, localParticipantId]
  );

  const localParticipantAudioCapturerState = useMemo(
    () => participantsAudioVideoCapturerState[localParticipantId]?.mic,
    [participantsAudioVideoCapturerState, localParticipantId]
  );

  const localParticipantVideoCapturerStateRef = useRef(
    localParticipantVideoCapturerState
  );
  const localParticipantAudioCapturerStateRef = useRef(
    localParticipantAudioCapturerState
  );
  const webcamStateRef = useRef(webcamState);
  const micStateRef = useRef(micState);

  useEffect(() => {
    localParticipantVideoCapturerStateRef.current =
      localParticipantVideoCapturerState;
  }, [localParticipantVideoCapturerState]);
  useEffect(() => {
    localParticipantAudioCapturerStateRef.current =
      localParticipantAudioCapturerState;
  }, [localParticipantAudioCapturerState]);
  useEffect(() => {
    webcamStateRef.current = webcamState;
  }, [webcamState]);
  useEffect(() => {
    micStateRef.current = micState;
  }, [micState]);

  const localParticipantIdRef = useRef(localParticipantId);

  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);

  useAppSingalingSubscribe(
    appPubSubTopics.AUDIO_AND_VIDEO_PERMISSION_STATES,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const {
        participantId,
        state,
        capturer,
      }: {
        participantId: string;
        state: PermissionState;
        capturer: "camera" | "mic";
      } = message;

      setParticipantsAudioVideoCapturerStateRef.current((s) => {
        const participantsState = { ...s };

        const participant = participantsState[participantId];

        if (participant) {
          participantsState[participantId] = {
            ...participantsState[participantId],
            [capturer]: state,
          };
        } else {
          participantsState[participantId] = { [capturer]: state };
        }

        return participantsState;
      });
    }
  );

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.AUDIO_AND_VIDEO_PERMISSION_STATES
  );

  const previousWebcamState = useRef<PermissionState>();
  const previousMicState = useRef<PermissionState>();

  const publishRef = useRef<appSingalingPublishType>();

  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  const setMicPermissionState = ({ state }: { state: PermissionState }) => {
    if (publishRef.current) {
      publishRef.current(
        JSON.stringify({
          participantId: localParticipantIdRef.current,
          state,
          capturer: "mic",
        })
      );
    }
  };

  const setWebcamPermissionState = ({ state }: { state: PermissionState }) => {
    if (publishRef.current) {
      publishRef.current(
        JSON.stringify({
          participantId: localParticipantIdRef.current,
          state,
          capturer: "camera",
        })
      );
    }
  };
  const setMicPermissionStateRef = useRef(setMicPermissionState);
  const setWebcamPermissionStateRef = useRef(setWebcamPermissionState);

  useEffect(() => {
    const interval = setInterval(() => {
      const webcamState = webcamStateRef.current;
      const setWebcamPermissionState = setWebcamPermissionStateRef.current;
      const localParticipantVideoCapturerState =
        localParticipantVideoCapturerStateRef.current;

      if (
        previousWebcamState.current !== webcamState ||
        localParticipantVideoCapturerState !== webcamState
      ) {
        setWebcamPermissionState({ state: webcamState });

        previousWebcamState.current = webcamState;
      }

      //

      const micState = micStateRef.current;
      const setMicPermissionState = setMicPermissionStateRef.current;
      const localParticipantAudioCapturerState =
        localParticipantAudioCapturerStateRef.current;

      if (
        previousMicState.current !== micState ||
        localParticipantAudioCapturerState !== micState
      ) {
        setMicPermissionState({ state: micState });

        previousMicState.current = micState;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <React.Fragment />;
};

export default AudioAndVideoPermissionStatesListner;
