import { momentostreamApiServerBaseUrl } from "../../../utils/constants";

const answerPoll = async ({
  broadcastId,
  pollId,
  optionId,
}: {
  broadcastId: string;
  pollId: string;
  optionId: string;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/answer-poll`;

    const body = JSON.stringify({ broadcastId, pollId, optionId });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default answerPoll;
