import React from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const DownloadPartListner = () => {
  useAppSingalingSubscribe(
    appPubSubTopics.DOWNLOAD_PART,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      if (message?.remoteUrl) {
        const controller = new AbortController();
        const signal = controller.signal;

        try {
          fetch(message?.remoteUrl, { signal });
        } catch (error) {
          //
        }

        setTimeout(() => {
          controller.abort();
        }, 3000);
      }
    }
  );

  return <React.Fragment />;
};

export default DownloadPartListner;
