import { egressManagerApiServerBaseUrl } from "../../utils/constants";

const updateRtmpLivestreamDestinations = async ({
  broadcastId,
  rtmpDestinationUrlsToBeAdded,
  rtmpDestinationUrlsToBeRemoved,
}: {
  broadcastId: string;
  rtmpDestinationUrlsToBeAdded: string[];
  rtmpDestinationUrlsToBeRemoved: string[];
}) => {
  try {
    const url = `${egressManagerApiServerBaseUrl}/update-broadcast-rtmp-livestream-destinations`;
    const body = JSON.stringify({
      broadcastId,
      rtmpDestinationUrlsToBeAdded,
      rtmpDestinationUrlsToBeRemoved,
    });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default updateRtmpLivestreamDestinations;
