import { useEffect, useRef, useState } from "react";
import useBroadcastEgresses from "./useBroadcastEgresses";

const useBroadcastEgressesTimer = () => {
  const timerStartedCheckerIntervalRef = useRef<null | ReturnType<
    typeof setInterval
  >>(null);

  const { egressesActivatedAt, egressesStarted } = useBroadcastEgresses();

  const [hhmmss, setHhmmss] = useState("");

  const egressesActivatedAtRef = useRef(egressesActivatedAt);
  const egressesStartedRef = useRef(egressesStarted);
  const hhmmssRef = useRef(hhmmss);

  useEffect(() => {
    hhmmssRef.current = hhmmss;
  }, [hhmmss]);
  useEffect(() => {
    egressesActivatedAtRef.current = egressesActivatedAt;
  }, [egressesActivatedAt]);
  useEffect(() => {
    egressesStartedRef.current = egressesStarted;
  }, [egressesStarted]);

  const checkTimerStarted = () => {
    timerStartedCheckerIntervalRef.current = setInterval(() => {
      const egressesStarted = egressesStartedRef.current;
      const egressesActivatedAt = egressesActivatedAtRef.current;

      if (egressesStarted && egressesActivatedAt) {
        const diff =
          new Date().getTime() - new Date(egressesActivatedAt).getTime();

        const hhmmss = new Date(diff)
          .toISOString()
          .slice(diff / 1000 > 3600 ? 11 : 14, 19);

        setHhmmss(hhmmss);
      } else {
        if (hhmmssRef.current) {
          setHhmmss("");
        }
      }
    }, 2500);
  };

  useEffect(() => {
    checkTimerStarted();

    return () => {
      if (timerStartedCheckerIntervalRef.current) {
        clearInterval(timerStartedCheckerIntervalRef.current);
      }
    };
  }, []);

  return { hhmmss };
};

export default useBroadcastEgressesTimer;
