import { useMemo } from "react";
import SidePanelTopBar from "../../SidePanelTopBar";
import { useAppContext } from "../../../../contexts/appContextDef";
import BannerItem from "./BannerItem";
import CreateNewBanner from "./CreateNewBanner";
import { sidePanelBannersModes } from "../../../../utils/constants";

const BannersContainer = () => {
  const { setSidePanelBannersMode, sidePanelActiveBanerId, foldersBanners } =
    useAppContext();

  const activeBannerFolder = useMemo(() => {
    return foldersBanners.find(
      ({ id }) => `${id}` === `${sidePanelActiveBanerId}`
    );
  }, [foldersBanners, sidePanelActiveBanerId]);

  return (
    <div className="flex flex-1 flex-col">
      <SidePanelTopBar
        title={activeBannerFolder?.name || ""}
        onBackClick={() => {
          setSidePanelBannersMode(sidePanelBannersModes.BANNER_FOLDERS_LIST);
        }}
      />

      <div>
        {activeBannerFolder?.banners?.map((banner) => (
          <BannerItem key={`banner-item-${banner.id}`} {...banner} />
        ))}
      </div>

      <div className="mt-3">
        <CreateNewBanner folderId={`${activeBannerFolder?.id}`} />
      </div>
    </div>
  );
};

export default BannersContainer;
