import { RemoteTrackPublication } from "livekit-client";
import { useAppContext } from "../../contexts/appContextDef";
import { useMemo } from "react";

const useLivekitRtcLocalParticipantTranslatedAudioStreams = () => {
  const appContext = useAppContext();

  const {
    livekitRemoteParticipantsTrackPublications,
  }: {
    livekitRemoteParticipantsTrackPublications: Map<
      string,
      RemoteTrackPublication[]
    >;
  } = appContext;

  const { interpreterParticipantIds, localParticipantId } = appContext;

  const { localParticipantTranslatedAudioStreams } = useMemo(() => {
    const localParticipantTranslatedAudioStreams: {
      language: string;
      audioMediaStreamTrack: MediaStreamTrack;
    }[] = [];

    for (let index = 0; index < interpreterParticipantIds.length; index++) {
      const interpreterParticipantId = interpreterParticipantIds[index];

      const publications = livekitRemoteParticipantsTrackPublications.get(
        interpreterParticipantId
      );

      if (publications) {
        publications.forEach((publication) => {
          if (publication.trackName.includes("translatedAudio")) {
            const splittedTrackName = publication.trackName.split("_");

            const participantId = splittedTrackName[1];
            const language = splittedTrackName[2];

            if (participantId === localParticipantId) {
              if (publication.track?.mediaStreamTrack) {
                localParticipantTranslatedAudioStreams.push({
                  language,
                  audioMediaStreamTrack: publication.track?.mediaStreamTrack,
                });
              } else {
                publication.setSubscribed(true);
              }
            }
          }
        });
      }
    }

    return { localParticipantTranslatedAudioStreams };
  }, [
    livekitRemoteParticipantsTrackPublications,
    localParticipantId,
    interpreterParticipantIds,
  ]);

  return { localParticipantTranslatedAudioStreams };
};

export default useLivekitRtcLocalParticipantTranslatedAudioStreams;
