// import { usePubSub } from "@videosdk.live/react-sdk";
import { useRef } from "react";
import { createUID } from "../../utils/createUID";
import sleep from "../../utils/sleep";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import useValidateSpeakerCanTakeAction from "../appState/useValidateSpeakerCanTakeAction";
import { inQueueStreamTypes } from "../../utils/constants";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

export const videoShareStreamActions = {
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
};

const useVideoShareStreams = () => {
  const { allParticipantsInQueueStreams } = useAppContext();

  const allParticipantsInQueueStreamsRef = useRef(
    allParticipantsInQueueStreams
  );

  // const { publish: videoShareStreamPublish } = usePubSub(
  //   appPubSubTopics.VIDEO_SHARE_STREAM_ENABLED
  // );

  const { publish: videoShareStreamPublish } = useAppSingalingPublish(
    appPubSubTopics.VIDEO_SHARE_STREAM_ENABLED
  );

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  const enableVideoShareStream = async ({
    remoteUrl,
    durationInSec,
  }: {
    remoteUrl: string;
    durationInSec: number;
  }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    const id = createUID();

    videoShareStreamPublish(
      JSON.stringify({
        id,
        remoteUrl,
        durationInSec,
        action: videoShareStreamActions.ENABLE,
      })
      // { persist: true }
    );
  };

  const disableVideoShareStream = async ({ id }: { id: string }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    videoShareStreamPublish(
      JSON.stringify({ id, action: videoShareStreamActions.DISABLE })
      // { persist: true }
    );
  };

  const disableVideoShareStreamsOfParticipant = async ({
    participantId,
  }: {
    participantId: string;
  }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    const participantInQueueVideoStreams =
      allParticipantsInQueueStreamsRef.current.filter(
        ({ participantId: _participantId, type }) =>
          _participantId === participantId &&
          type === inQueueStreamTypes.SHARE_VIDEO
      );

    for (
      let index = 0;
      index < participantInQueueVideoStreams.length;
      index++
    ) {
      const participantInQueueVideoStream =
        participantInQueueVideoStreams[index];

      disableVideoShareStream({ id: `${participantInQueueVideoStream.id}` });

      await sleep();
    }
  };

  return {
    enableVideoShareStream,
    disableVideoShareStream,
    disableVideoShareStreamsOfParticipant,
  };
};

export default useVideoShareStreams;
