import useIsMobilePortrait from "../../../hooks/responsive/useIsMobilePortrait";
import EditYourBroadcast from "./EditYourBroadcast";
import GoLiveButton from "./GoLiveButton";
import SelectedBroadcastsContainer from "./SelectedBroadcastsContainer";
import { quickstartTargetComponentClassNames } from "../../quickStartTourContainer/QuickStartTourContainer";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import React from "react";

const StartStreamingContainer = () => {
  const { meetingMode } = useAppConfigContext();

  const { isMobilePortrait } = useIsMobilePortrait();

  return (
    <div
      className={`flex gap-3 items-center justify-center ${
        isMobilePortrait ? "flex-col" : ""
      } ${quickstartTargetComponentClassNames.broadcast_section}`}
    >
      <div className="flex gap-3 items-center justify-center">
        <div>
          <SelectedBroadcastsContainer />
        </div>

        {meetingMode === "full" ? (
          <div>
            <EditYourBroadcast />
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
      <div className={`${isMobilePortrait ? "mt-2" : ""}`}>
        <GoLiveButton />
      </div>
    </div>
  );
};

export default StartStreamingContainer;
