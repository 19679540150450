// import { usePubSub } from "@videosdk.live/react-sdk";
import { useEffect, useRef } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useSwappedParticipants = () => {
  // const { publish } = usePubSub(appPubSubTopics.SWAPPED_PARTICIPANTS);

  // const publishRef = useRef<videosdkPubsubPublishType>(publish);

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.SWAPPED_PARTICIPANTS
  );

  const publishRef = useRef<appSingalingPublishType>(publish);

  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const swap = async ({
    participantId1,
    index1,
    participantId2,
    index2,
  }: {
    participantId1: string;
    index1: number;
    participantId2: string;
    index2: number;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publishRef.current(
      JSON.stringify({
        payload: { participantId1, index1, participantId2, index2 },
      })
      // { persist: false }
    );
  };

  return { swap };
};

export default useSwappedParticipants;
