import React, { useEffect, useRef } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import { safeParseJson } from "../../utils/safeParseJson";
import { inputFileVideoShareStreamActions } from "../../hooks/streams/useInputFileVideoShareStreams";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

const InputFileVideoShareStreamsListner = () => {
  const { setActiveInputFileVideoShareStreams } = useAppContext();

  const { localParticipantId } = useLocalParticipantId();

  const localParticipantIdRef = useRef(localParticipantId);

  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);

  useAppSingalingSubscribe(
    appPubSubTopics.INPUT_FILE_VIDEO_SHARE_STREAM_ENABLED,
    ({ message: receivedMessage, participantId }) => {
      const message = safeParseJson(receivedMessage);

      if (participantId === localParticipantId) {
        setActiveInputFileVideoShareStreams((streams) =>
          message.action === inputFileVideoShareStreamActions.ENABLE
            ? [...streams, message]
            : streams.filter((stream) => stream.id !== message.id)
        );

        if (message.action === inputFileVideoShareStreamActions.DISABLE) {
          appEventEmitter.emit(
            appEventEmitterEvents.RTC_DISABLE_INPUT_FILE_VIDEO_SHARE_STREAM,
            { id: message.id }
          );
        }
      }
    }
  );

  return <React.Fragment />;
};

export default InputFileVideoShareStreamsListner;
