import { useWindowSize } from "@react-hook/window-size";
import React, { useEffect, useMemo, useState } from "react";
import AgoraSettingsContainer from "../agoraSettings/AgoraSettingsContainer";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import AskMicPermissionModal from "../../components/AskMicPermissionModal";
import { MOMENTO_LOGO_FULL } from "../../static/images";
import { audioAndVideoPermissionStates } from "../../utils/constants";
import Spinner from "../../components/Spinner";
import IconButton, {
  iconButtonVariants,
} from "../bottomControlsContainer/IconButton";
import CachedUserData from "../../utils/CachedUserData";
import { AgoraInterpreterMicIconButton } from "../interpreterBottomControlsContainer/InterpreterBottomControlsContainer";
import { MdSettings } from "react-icons/md";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import InputInterpretationsContainer from "../../components/InputInterpretationsContainer";
import { useInterpretationViaAiData } from "../../listners/appState/LocalParticipantTranslatedAudioBroadcasterContainer";

const InterpreterJoinScreenContainer = ({
  _handleJoin,
  name: _name,
}: {
  _handleJoin: (t: joinStateType) => void;
  name?: string;
  participantId: string;
}) => {
  const { webcamState, micState, defaultName } = useAppConfigContext();

  const [name, setName] = useState<string>(_name || defaultName || "");

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const [nameError, setNameError] = useState(false);

  const [windowWidth, windowHeight] = useWindowSize();

  const micCamStateAvailable = useMemo(
    () => webcamState && micState,
    [webcamState, micState]
  );

  const micDenied = useMemo(
    () => micState === audioAndVideoPermissionStates.denied,
    [micState]
  );

  useEffect(() => {
    setTimeout(() => {
      if (!micDenied) {
        appEventEmitter.emit(
          appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_ON_MIC
        );
      }
    }, 5000);
  }, [micDenied]);

  const { interpretationViaAiData } = useInterpretationViaAiData();

  useEffect(() => {
    setTimeout(() => {
      if (interpretationViaAiData.length) {
        _handleJoin({
          name: `AI Interpreter`,
          selectedMicDeviceId: null,
          selectedWebcamDeviceId: null,
          mirrorLocalWebcam: true,
          webcamOn: false,
          micOn: false,
        } as joinStateType);
      }
    }, 500);
  }, [interpretationViaAiData]);

  return interpretationViaAiData.length ? (
    <React.Fragment />
  ) : (
    <React.Fragment>
      <div
        style={{ width: windowWidth, height: windowHeight }}
        className="flex flex-1 flex-col"
      >
        <div className="p-5">
          <a target="_blank" href="http://momentostream.com/" rel="noreferrer">
            <img
              className="h-8"
              src={MOMENTO_LOGO_FULL}
              alt="momento logo full"
            />
          </a>
        </div>

        {micCamStateAvailable ? (
          <div className="flex flex-1 items-center justify-center">
            <div
              className={`flex items-center justify-center flex-col w-64 md:w-72 lg:w-96 xl:w-96 2xl:w-96`}
            >
              <div className={`flex items-center justify-center flex-col`}>
                <div className={`flex items-center justify-center flex-col`}>
                  <React.Fragment>
                    <div className="mt-5 flex items-center justify-center shadow-lg rounded-lg border border-gray-200">
                      <AgoraInterpreterMicIconButton />

                      <IconButton
                        {...{
                          onClick: () => {
                            setSettingsModalOpen((s) => !s);
                          },
                          Icon: MdSettings,
                          title: "Settings",
                          popperTitle: "",
                          variant: iconButtonVariants.info,
                          maxTitle: "Settings",
                        }}
                      />

                      <InputInterpretationsContainer />
                    </div>
                  </React.Fragment>
                </div>

                <div
                  className={`flex items-center justify-center flex-col w-full`}
                >
                  <React.Fragment>
                    {defaultName ? null : (
                      <React.Fragment>
                        <div className="mt-5 flex flex-col w-full">
                          <div className="form-control w-full">
                            <label className="label">
                              <span className="label-text">Display name</span>
                            </label>
                            <input
                              onKeyUp={async (e) => {
                                if (e.key === "Enter") {
                                  if (name && String(name).trim().length > 1) {
                                    _handleJoin({
                                      name,
                                      selectedMicDeviceId: null,
                                      selectedWebcamDeviceId: null,
                                      mirrorLocalWebcam: true,
                                      webcamOn: false,
                                      micOn: false,
                                    } as joinStateType);

                                    CachedUserData.updateName(name);

                                    setNameError(false);
                                  } else {
                                    setNameError(true);
                                  }
                                }
                              }}
                              value={name || undefined}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              type="text"
                              className="input input-bordered w-full"
                            />
                            {nameError && (
                              <p className="text-red-500">
                                Please enter your name
                              </p>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    <div className="mt-5 w-full">
                      <button
                        onClick={() => {
                          if (name && String(name).trim().length > 1) {
                            _handleJoin({
                              name,
                              selectedMicDeviceId: null,
                              selectedWebcamDeviceId: null,
                              mirrorLocalWebcam: true,
                              webcamOn: false,
                              micOn: false,
                            } as joinStateType);

                            CachedUserData.updateName(name);

                            setNameError(false);
                          } else {
                            setNameError(true);
                          }
                        }}
                        className="btn btn-primary text-white btn-block"
                      >
                        {micDenied ? "Enter without mic/cam" : "Enter Studio"}
                      </button>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 items-center justify-center">
            <Spinner h={"h-20"} w={"w-20"} />
          </div>
        )}
      </div>

      <AgoraSettingsContainer
        {...{
          isOpen: settingsModalOpen,
          handleCancel: () => {
            setSettingsModalOpen(false);
          },
        }}
      />

      <AskMicPermissionModal micDenied={micDenied} />
    </React.Fragment>
  );
};

export default InterpreterJoinScreenContainer;
