import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import { useAppContext } from "../../../contexts/appContextDef";
import useIsMobilePortraitOrLandscape from "../../../hooks/responsive/useIsMobilePortraitOrLandscape";
import UpgradeNowLine from "../../../components/UpgradeNowLine";
import { MdClose } from "react-icons/md";
import useIsFreePlanActive from "../../../hooks/plans/useIsFreePlanActive";
import Tooltip from "../../../components/Tooltip";
import Switch from "react-switch";
import { primaryColor } from "../../../utils/colors";
import useLiveStreamDestinations from "../../../hooks/appState/useLiveStreamDestinations";
import ReactModal from "react-modal";

import useBroadcastEgresses from "../../../hooks/appState/useBroadcastEgresses";

const ConfirmStartComponent = ({
  topBarHeight,
  recordingEnabled,
  setShouldStartRecordingWhenGoLive,
  shouldStartRecordingWhenGoLive,
  setConfirmStartModalOpen,
  _handleStartRecordingOrLiveStreaming,
}: {
  topBarHeight: number;
  recordingEnabled: boolean;
  setShouldStartRecordingWhenGoLive: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  shouldStartRecordingWhenGoLive: boolean;
  setConfirmStartModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  _handleStartRecordingOrLiveStreaming: () => void;
}) => {
  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  return (
    <div
      style={
        isMobilePortraitOrLandscape
          ? {}
          : {
              position: "fixed",
              top: topBarHeight,
              zIndex: 99999,
            }
      }
      className={
        "p-3 flex flex-col gap-3 bg-white right-3 border-2 border-custom-blue-900 rounded-2xl shadow-md max-w-sm"
      }
    >
      <div className="flex justify-between items-center">
        <div className="flex items-start">
          <label className="cursor-pointer label gap-5">
            <Switch
              disabled={!recordingEnabled}
              checkedIcon={false}
              uncheckedIcon={false}
              className="ml-2"
              onColor={primaryColor}
              onChange={() => {
                setShouldStartRecordingWhenGoLive((s) => !s);
              }}
              checked={shouldStartRecordingWhenGoLive}
              defaultChecked={shouldStartRecordingWhenGoLive}
            />

            <span
              className={`label-text ${
                recordingEnabled ? "" : "cursor-not-allowed"
              }`}
            >
              Recording
            </span>
          </label>
        </div>

        <div>
          <button
            onClick={() => {
              setConfirmStartModalOpen((s) => !s);
            }}
            className="btn btn-ghost btn-sm"
          >
            <MdClose />
          </button>
        </div>
      </div>

      <div>
        <button
          onClick={() => {
            setConfirmStartModalOpen((s) => !s);

            _handleStartRecordingOrLiveStreaming();
          }}
          className="btn btn-primary text-white normal-case w-full"
        >
          Start
        </button>
      </div>

      <div className="">
        {recordingEnabled ? (
          <p className="p-2 rounded-xl bg-custom-blue-50 text-sm">
            Before going live make sure you set the right RTMP destinations and
            recording toggle is in the right state
          </p>
        ) : (
          <span>
            <UpgradeNowLine
              preText={"Recording is not enabled! "}
              postText={" to start recordings."}
            />

            <p className="p-2 rounded-xl bg-custom-blue-50 text-sm">
              You can go live when recording is not enabled!
            </p>
          </span>
        )}
      </div>
    </div>
  );
};

const GoLiveButtonConfirmBox = ({
  visible,
  title,
  rightBtnText,
  rightBtnClick,
  leftBtnText,
  leftBtnClick,
}: {
  visible: boolean;
  title: string;
  rightBtnText?: string;
  rightBtnClick?: () => void;
  leftBtnText?: string;
  leftBtnClick?: () => void;
}) => {
  const { topBarHeight } = useAppContext();

  return visible ? (
    <div
      style={{ position: "fixed", top: topBarHeight, zIndex: 99999 }}
      className={
        "p-3 bg-white right-3 border-2 border-custom-blue-900 rounded-2xl shadow-md"
      }
    >
      <p className="font-bold">{title}</p>

      <div className="flex mt-3 justify-end">
        {leftBtnText ? (
          <button
            onClick={leftBtnClick}
            className="btn btn-sm text-primary btn-ghost"
          >
            {leftBtnText}
          </button>
        ) : (
          <React.Fragment />
        )}
        {rightBtnText ? (
          <button
            onClick={rightBtnClick}
            className="btn btn-sm btn-primary text-white ml-2"
          >
            {rightBtnText}
          </button>
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

const getGoLiveButtonTitle = ({
  shouldStartRecordingWhenGoLive,
  selectedLiveStreamDestinations,
  egressesEditing,
  egressesStarted,
  egressesStarting,
  egressesStopped,
  egressesStopping,
}: {
  shouldStartRecordingWhenGoLive: boolean;
  selectedLiveStreamDestinations: unknown[];
  egressesEditing: boolean;
  egressesStarted: boolean;
  egressesStarting: boolean;
  egressesStopped: boolean;
  egressesStopping: boolean;
}) => {
  const liveOnly =
    selectedLiveStreamDestinations.length > 0 &&
    !shouldStartRecordingWhenGoLive;

  const recordOnly = selectedLiveStreamDestinations.length === 0;

  const isRequestProcessing =
    egressesEditing || egressesStarting || egressesStopping;

  const title = liveOnly
    ? egressesEditing
      ? "Editing Broadcast..."
      : egressesStarted
      ? "Stop Broadcast"
      : egressesStarting
      ? "Starting Broadcast..."
      : egressesStopped
      ? "Go Live"
      : egressesStopping
      ? "Stopping Broadcast..."
      : "Go Live"
    : recordOnly
    ? egressesEditing
      ? "Editing Broadcast..."
      : egressesStarted
      ? "Stop Recording"
      : egressesStarting
      ? "Starting Recording..."
      : egressesStopped
      ? "Start Recording"
      : egressesStopping
      ? "Stopping Recording..."
      : "Start Recording"
    : egressesEditing
    ? "Editing Broadcast..."
    : egressesStarted
    ? "Stop Broadcast"
    : egressesStarting
    ? "Starting Broadcast..."
    : egressesStopped
    ? "Go Live"
    : egressesStopping
    ? "Stopping Broadcast..."
    : "Go Live";

  const isRecordingOrLiveStreaming = egressesStarted;

  return { title, isRequestProcessing, isRecordingOrLiveStreaming };
};

const GoLiveButton = () => {
  const { selectedLiveStreamDestinations: _selectedLiveStreamDestinations } =
    useLiveStreamDestinations();

  const [recordingFailedError, setRecordingFailedError] = useState({
    visible: false,
    errorMessage: "",
  });
  const [livestreamFailedError, setLivestreamFailedError] = useState({
    visible: false,
    errorMessage: "",
  });
  const [failedDueToNoOutputs, setFailedDueToNoOutputs] = useState(false);

  const [confirmStartModalOpen, setConfirmStartModalOpen] = useState(false);
  const [confirmStopModalOpen, setConfirmStopModalOpen] = useState(false);
  const [
    upgradeToStartRecordingModalOpen,
    setUpgradeToStartRecordingModalOpen,
  ] = useState(false);

  const {
    broadcastId,
    defaultDestination,
    recordingEnabled,
    shouldStartRecordingWhenGoLive,
    setShouldStartRecordingWhenGoLive,
    interpretationsOriginalDestination,
    interpretations,
  } = useAppConfigContext();

  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  const selectedLiveStreamDestinations = useMemo(() => {
    const selectedLiveStreamDestinations = [];

    if (_selectedLiveStreamDestinations?.length) {
      selectedLiveStreamDestinations.push(..._selectedLiveStreamDestinations);
    }

    if (defaultDestination) {
      selectedLiveStreamDestinations.push({
        streamKey: defaultDestination.streamKey,
        url: defaultDestination.streamUrl,
      });
    }

    if (
      interpretationsOriginalDestination?.rtmpKey &&
      interpretationsOriginalDestination?.rtmpUrl
    ) {
      selectedLiveStreamDestinations.push({
        streamKey: interpretationsOriginalDestination?.rtmpKey,
        url: interpretationsOriginalDestination?.rtmpUrl,
      });
    }

    if (interpretations?.length) {
      interpretations.forEach((interpretation) => {
        if (interpretation?.rtmpKey && interpretation?.rtmpUrl) {
          selectedLiveStreamDestinations.push({
            streamKey: interpretation?.rtmpKey,
            url: interpretation?.rtmpUrl,
          });
        }
      });
    }

    return selectedLiveStreamDestinations;
  }, [
    _selectedLiveStreamDestinations,
    interpretationsOriginalDestination,
    interpretations,
    defaultDestination,
  ]);

  const {
    startBroadcastEgresses,
    stopBroadcastEgresses,
    egressesEditing,
    egressesStarted,
    egressesStarting,
    egressesStopped,
    egressesStopping,
  } = useBroadcastEgresses({
    startEgressesFailedDueToNoOutputs: () => {
      setFailedDueToNoOutputs(true);
    },
  });

  const {
    topBarHeight,
    erroredLiveStreamDestinationsIds,
    egressesStartRetryingCountdown,
  } = useAppContext();

  const { isFreePlanActive } = useIsFreePlanActive();

  const isFreePlanActiveRef = useRef(isFreePlanActive);
  const shouldStartRecordingWhenGoLiveRef = useRef(
    shouldStartRecordingWhenGoLive
  );
  const erroredLiveStreamDestinationsIdsRef = useRef(
    erroredLiveStreamDestinationsIds
  );
  const egressesStartRetryingCountdownRef = useRef(
    egressesStartRetryingCountdown
  );

  useEffect(() => {
    isFreePlanActiveRef.current = isFreePlanActive;
  }, [isFreePlanActive]);
  useEffect(() => {
    shouldStartRecordingWhenGoLiveRef.current = shouldStartRecordingWhenGoLive;
  }, [shouldStartRecordingWhenGoLive]);
  useEffect(() => {
    erroredLiveStreamDestinationsIdsRef.current =
      erroredLiveStreamDestinationsIds;
  }, [erroredLiveStreamDestinationsIds]);
  useEffect(() => {
    egressesStartRetryingCountdownRef.current = egressesStartRetryingCountdown;
  }, [egressesStartRetryingCountdown]);

  const { title, isRequestProcessing, isRecordingOrLiveStreaming } = useMemo(
    () =>
      getGoLiveButtonTitle({
        egressesEditing,
        egressesStarted,
        egressesStarting,
        egressesStopped,
        egressesStopping,
        shouldStartRecordingWhenGoLive,
        selectedLiveStreamDestinations,
      }),
    [
      egressesEditing,
      egressesStarted,
      egressesStarting,
      egressesStopped,
      egressesStopping,
      shouldStartRecordingWhenGoLive,
      selectedLiveStreamDestinations,
    ]
  );

  const { isGoLiveButtonDisabled } = useMemo(
    () => ({
      isGoLiveButtonDisabled:
        isRequestProcessing || egressesStartRetryingCountdown?.active,
    }),
    [isRequestProcessing, egressesStartRetryingCountdown]
  );

  const selectedLiveStreamDestinationsRef = useRef(
    selectedLiveStreamDestinations
  );
  const isRecordingOrLiveStreamingRef = useRef(isRecordingOrLiveStreaming);
  const broadcastIdRef = useRef<string>();
  const isRequestProcessingRef = useRef(isRequestProcessing);
  const isGoLiveButtonDisabledRef = useRef(isGoLiveButtonDisabled);
  const recordingEnabledRef = useRef(recordingEnabled);

  useEffect(() => {
    selectedLiveStreamDestinationsRef.current = selectedLiveStreamDestinations;
  }, [selectedLiveStreamDestinations]);
  useEffect(() => {
    isRecordingOrLiveStreamingRef.current = isRecordingOrLiveStreaming;
  }, [isRecordingOrLiveStreaming]);
  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);
  useEffect(() => {
    isRequestProcessingRef.current = isRequestProcessing;
  }, [isRequestProcessing]);
  useEffect(() => {
    isGoLiveButtonDisabledRef.current = isGoLiveButtonDisabled;
  }, [isGoLiveButtonDisabled]);
  useEffect(() => {
    recordingEnabledRef.current = recordingEnabled;
  }, [recordingEnabled]);

  const _handleStartRecordingOrLiveStreaming = async () => {
    if (
      (shouldStartRecordingWhenGoLiveRef.current ||
        !selectedLiveStreamDestinationsRef.current?.length) &&
      !recordingEnabledRef.current
    ) {
      setUpgradeToStartRecordingModalOpen(true);
    } else {
      startBroadcastEgresses({
        recordingEnabled: recordingEnabledRef.current
          ? selectedLiveStreamDestinationsRef.current?.length
            ? shouldStartRecordingWhenGoLiveRef.current
            : true
          : false,
      });
    }
  };

  const _handleStopRecordingOrLiveStreaming = async () => {
    stopBroadcastEgresses();
  };

  const _handleClick = async () => {
    if (isGoLiveButtonDisabledRef.current) return;

    if (!isRequestProcessingRef.current) {
      const isRecordingOrLiveStreaming = isRecordingOrLiveStreamingRef.current;

      if (isRecordingOrLiveStreaming) {
        setConfirmStopModalOpen(true);
      } else {
        if (selectedLiveStreamDestinationsRef.current?.length) {
          setConfirmStartModalOpen(true);
        } else {
          await _handleStartRecordingOrLiveStreaming();
        }
      }
    }
  };

  return (
    <div className="flex">
      <Tooltip
        disabled={!isGoLiveButtonDisabled}
        title={"Please select Broadcast channels to Go Live"}
      >
        <button
          style={{
            backgroundColor: "#FF2445",
            opacity: isGoLiveButtonDisabled ? 0.5 : 1,
          }}
          className={`flex btn btn-ghost text-white font-bold btn-sm normal-case`}
          onClick={_handleClick}
        >
          {isGoLiveButtonDisabled ? (
            <span className="loading loading-spinner"></span>
          ) : (
            <React.Fragment />
          )}
          <p className="block">
            {egressesStartRetryingCountdown.active
              ? `Failed to start Recording, retrying in ${
                  egressesStartRetryingCountdown.countdown || 0
                } seconds...`
              : title}
          </p>
        </button>
      </Tooltip>

      {isMobilePortraitOrLandscape ? (
        <React.Fragment>
          <Modal
            {...{
              onClose: () => {
                setConfirmStopModalOpen(false);
              },
              onSuccess: _handleStopRecordingOrLiveStreaming,
              successText: title,
              cancelText: "Cancel",
              title: `${title}?`,
              isOpen: confirmStopModalOpen,
              setIsOpen: setConfirmStopModalOpen,
              renderBody: () => {
                return (
                  <React.Fragment>
                    Are you sure you want to {title}?
                  </React.Fragment>
                );
              },
            }}
          />

          <Modal
            {...{
              onClose: () => {
                setUpgradeToStartRecordingModalOpen(false);
              },
              onSuccess: () => {},
              successText: title,
              cancelText: "",
              title: "",
              isOpen: upgradeToStartRecordingModalOpen,
              setIsOpen: setUpgradeToStartRecordingModalOpen,
              showCloseIcon: true,
              renderOnlyBody: true,
              renderBody: () => {
                return (
                  <div className="flex flex-1 w-full">
                    <UpgradeNowLine
                      preText={"Recording is not enabled! "}
                      postText={" to start recordings."}
                    />
                  </div>
                );
              },
            }}
          />

          <ReactModal
            isOpen={confirmStartModalOpen}
            onRequestClose={() => {
              setConfirmStartModalOpen(false);
            }}
            shouldFocusAfterRender={false}
            style={{
              overlay: { backgroundColor: "#00000066" },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <ConfirmStartComponent
              {...{
                topBarHeight,
                recordingEnabled,
                setShouldStartRecordingWhenGoLive,
                shouldStartRecordingWhenGoLive,
                setConfirmStartModalOpen,
                _handleStartRecordingOrLiveStreaming,
              }}
            />
          </ReactModal>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {upgradeToStartRecordingModalOpen ? (
            <div
              style={{
                position: "fixed",
                top: topBarHeight,
                zIndex: 99999,
              }}
              className={
                "p-3 bg-white right-3 border-2 border-custom-blue-900 rounded-2xl shadow-md flex items-center justify-center"
              }
            >
              <UpgradeNowLine
                preText={"Recording is not enabled! "}
                postText={" to start recordings."}
              />

              <button
                onClick={() => {
                  setUpgradeToStartRecordingModalOpen(false);
                }}
                className="btn btn-ghost btn-sm"
              >
                <MdClose />
              </button>
            </div>
          ) : (
            <React.Fragment />
          )}

          {confirmStartModalOpen ? (
            <ConfirmStartComponent
              {...{
                topBarHeight,
                recordingEnabled,
                setShouldStartRecordingWhenGoLive,
                shouldStartRecordingWhenGoLive,
                setConfirmStartModalOpen,
                _handleStartRecordingOrLiveStreaming,
              }}
            />
          ) : (
            <React.Fragment />
          )}

          <GoLiveButtonConfirmBox
            leftBtnClick={() => {
              setConfirmStopModalOpen(false);
            }}
            leftBtnText={"Not Yet"}
            title={`Are you sure you want to ${title}?`}
            visible={confirmStopModalOpen}
            rightBtnClick={() => {
              _handleStopRecordingOrLiveStreaming();

              setConfirmStopModalOpen(false);
            }}
            rightBtnText={title}
          />

          <GoLiveButtonConfirmBox
            title={recordingFailedError.errorMessage}
            visible={recordingFailedError.visible}
            rightBtnClick={() => {
              setRecordingFailedError({
                visible: false,
                errorMessage: "",
              });
            }}
            rightBtnText={recordingFailedError.errorMessage}
          />

          <GoLiveButtonConfirmBox
            title={livestreamFailedError.errorMessage}
            visible={livestreamFailedError.visible}
            rightBtnClick={() => {
              setLivestreamFailedError({
                visible: false,
                errorMessage: "",
              });
            }}
            rightBtnText={"Okay"}
          />

          <GoLiveButtonConfirmBox
            title={
              "Failed to start livestream due to no livestream destinations are valid!"
            }
            visible={failedDueToNoOutputs}
            rightBtnClick={() => {
              setFailedDueToNoOutputs(false);
            }}
            rightBtnText={"Okay"}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default GoLiveButton;
