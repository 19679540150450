import React, { useEffect, useMemo, useRef } from "react";
import Queue from "queue";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { useAppContext } from "../../contexts/appContextDef";

export const appQueueEvents = {
  BRAND_BACKGROUND: "BRAND_BACKGROUND",
  BRAND_OVERLAY: "BRAND_OVERLAY",
  BRAND_LOGO: "BRAND_LOGO",
  BRAND_BACKGROUND_MUSIC: "BRAND_BACKGROUND_MUSIC",
  BRAND_VIDEO_CLIP: "BRAND_VIDEO_CLIP",
  BROADCAST_VIDEO: "BROADCAST_VIDEO",
  BROADCAST_PDF: "BROADCAST_PDF",
};

const AppQueueListner = () => {
  const {
    setNewPdfsState,
    setNewVideoStreamsState,
    setNewBackgroundsState,
    setNewOverlaysState,
    setNewLogosState,
    setNewBackgroundMusicsState,
    setNewMainViewVideoClipsState,
  } = useAppContext();

  const queue = useMemo(() => {
    const queue = new Queue({ autostart: true, concurrency: 1 });

    return queue;
  }, []);

  const queueRef = useRef(queue);

  useEffect(() => {
    queueRef.current = queue;
  }, [queue]);

  const _handleAppQueueEvents = ({
    event,
    isProcessing,
    isUploading,
    brandId,
    uploadId,
    isCompleted,
    name,
  }: {
    event: string;
    isProcessing: boolean;
    isUploading: boolean;
    brandId: string;
    uploadId: string;
    isCompleted: boolean;
    name: string;
  }) => {
    const queue = queueRef.current;

    queue.push(
      () =>
        new Promise((resolve) => {
          switch (event) {
            case appQueueEvents.BRAND_BACKGROUND:
              if (isUploading) {
                setNewBackgroundsState((s) => [
                  ...s,
                  {
                    id: uploadId,
                    brandId,
                    isProcessing: false,
                    isUploading: true,
                    name,
                  },
                ]);
              } else if (isProcessing) {
                setNewBackgroundsState((s) =>
                  s.map((item) =>
                    item.id === uploadId
                      ? { ...item, isProcessing: true, isUploading: false }
                      : item
                  )
                );
              } else if (isCompleted) {
                setNewBackgroundsState((s) =>
                  s.filter((item) => item.id !== uploadId)
                );
              }
              break;
            //
            case appQueueEvents.BRAND_OVERLAY:
              if (isUploading) {
                setNewOverlaysState((s) => [
                  ...s,
                  {
                    id: uploadId,
                    brandId,
                    isProcessing: false,
                    isUploading: true,
                    name,
                  },
                ]);
              } else if (isProcessing) {
                setNewOverlaysState((s) =>
                  s.map((item) =>
                    item.id === uploadId
                      ? { ...item, isProcessing: true, isUploading: false }
                      : item
                  )
                );
              } else if (isCompleted) {
                setNewOverlaysState((s) =>
                  s.filter((item) => item.id !== uploadId)
                );
              }
              break;
            //
            case appQueueEvents.BRAND_LOGO:
              if (isUploading) {
                setNewLogosState((s) => [
                  ...s,
                  {
                    id: uploadId,
                    brandId,
                    isProcessing: false,
                    isUploading: true,
                    name,
                  },
                ]);
              } else if (isProcessing) {
                setNewLogosState((s) =>
                  s.map((item) =>
                    item.id === uploadId
                      ? { ...item, isProcessing: true, isUploading: false }
                      : item
                  )
                );
              } else if (isCompleted) {
                setNewLogosState((s) =>
                  s.filter((item) => item.id !== uploadId)
                );
              }
              break;
            //
            case appQueueEvents.BRAND_BACKGROUND_MUSIC:
              if (isUploading) {
                setNewBackgroundMusicsState((s) => [
                  ...s,
                  {
                    id: uploadId,
                    brandId,
                    isProcessing: false,
                    isUploading: true,
                    name,
                  },
                ]);
              } else if (isProcessing) {
                setNewBackgroundMusicsState((s) =>
                  s.map((item) =>
                    item.id === uploadId
                      ? { ...item, isProcessing: true, isUploading: false }
                      : item
                  )
                );
              } else if (isCompleted) {
                setNewBackgroundMusicsState((s) =>
                  s.filter((item) => item.id !== uploadId)
                );
              }
              break;
            //
            case appQueueEvents.BRAND_VIDEO_CLIP:
              if (isUploading) {
                setNewMainViewVideoClipsState((s) => [
                  ...s,
                  {
                    id: uploadId,
                    brandId,
                    isProcessing: false,
                    isUploading: true,
                    name,
                  },
                ]);
              } else if (isProcessing) {
                setNewMainViewVideoClipsState((s) =>
                  s.map((item) =>
                    item.id === uploadId
                      ? { ...item, isProcessing: true, isUploading: false }
                      : item
                  )
                );
              } else if (isCompleted) {
                setNewMainViewVideoClipsState((s) =>
                  s.filter((item) => item.id !== uploadId)
                );
              }
              break;
            //
            case appQueueEvents.BROADCAST_VIDEO:
              if (isUploading) {
                setNewVideoStreamsState((s) => [
                  ...s,
                  {
                    id: uploadId,
                    isProcessing: false,
                    isUploading: true,
                    name,
                  },
                ]);
              } else if (isProcessing) {
                setNewVideoStreamsState((s) =>
                  s.map((item) =>
                    item.id === uploadId
                      ? { ...item, isProcessing: true, isUploading: false }
                      : item
                  )
                );
              } else if (isCompleted) {
                setNewVideoStreamsState((s) =>
                  s.filter((item) => item.id !== uploadId)
                );
              }
              break;
            //
            case appQueueEvents.BROADCAST_PDF:
              if (isUploading) {
                setNewPdfsState((s) => [
                  ...s,
                  {
                    id: uploadId,
                    isProcessing: false,
                    isUploading: true,
                    name,
                  },
                ]);
              } else if (isProcessing) {
                setNewPdfsState((s) =>
                  s.map((item) =>
                    item.id === uploadId
                      ? { ...item, isProcessing: true, isUploading: false }
                      : item
                  )
                );
              } else if (isCompleted) {
                setNewPdfsState((s) =>
                  s.filter((item) => item.id !== uploadId)
                );
              }
              break;
            //
            default:
              break;
          }

          setTimeout(() => {
            resolve(true);
          }, 250);
        })
    );
  };

  useEffect(() => {
    appEventEmitter.on(appEventEmitterEvents.APP_QUEUE, _handleAppQueueEvents);

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.APP_QUEUE,
        _handleAppQueueEvents
      );
    };
  }, []);

  return <React.Fragment />;
};

export default AppQueueListner;
