import React, { useEffect, useRef, useState } from "react";
import MeetingContainer from "./containers/MeetingContainer";
import { AppProvider } from "./contexts/appContextDef";
import JoinScreenContainer from "./containers/joinScreenContainer/JoinScreenContainer";
import WaitingToJoinContainer from "./containers/waitingToJoinContainer/WaitingToJoinContainer";
import ViewerJoinScreenContainer from "./containers/viewerJoinScreenContainer/ViewerJoinScreenContainer";
import LeftScreenContainer from "./containers/leftScreenContainer/LeftScreenContainer";
import { useAppConfigContext } from "./contexts/appConfigDef";
import InterpreterJoinScreenContainer from "./containers/interpreterJoinScreenContainer/InterpreterJoinScreenContainer";
import { APP_ENV, APP_ENVS } from "./apis/broadcasts/get-broadcast-data";
import { toast } from "react-toastify";
import UpgradeNowLine from "./components/UpgradeNowLine";
import Modal from "./components/Modal";
import { appModes } from "./utils/constants";
import AppSignalingListner from "./appSingaling/AppSignalingListner";
import AppRtcListner from "./appRtc/AppRtcListner";
import useParticipantName from "./hooks/appState/useParticipantName";
import AppEventsAndPubSubListnersContainer from "./listners/AppEventsAndPubSubListnersContainer";
import AppRemoteRtcStreamsListner from "./containers/appRemoteRtcStreamsListner/AppRemoteRtcStreamsListner";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "./utils/appEventEmitter";
import useAppSingalingPublish from "./appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "./utils/pubSubTopics";
import sleep from "./utils/sleep";
import AgoraInterpreterListner from "./containers/agoraInterpreterListner/AgoraInterpreterListner";
import { useInterpretationViaAiData } from "./listners/appState/LocalParticipantTranslatedAudioBroadcasterContainer";
import OriginalAudioPublisherAgora from "./containers/originalAudioPublisherAgora/OriginalAudioPublisherAgora";

const defaultJoinState: joinStateType = {
  isJoined: false,
  name: "",
  selectedMicDeviceId: null,
  selectedWebcamDeviceId: null,
  selectedSpeakerDeviceId: null,
  mirrorLocalWebcam: true,
  webcamOn: false,
  micOn: false,
  participantHeadline: null,
};

const MeetingAppContainer = ({
  name,
  participantHeadline,
  signalingClient,
  rtcClient,
  localParticipantId,
  signalingRoomInfo,
  localParticipantSid,
  appMode,
}: {
  name?: string | null;
  participantHeadline?: string | null;
  signalingClient: appSignalingClientType;
  rtcClient: appRtcClientType;
  localParticipantId: string;
  signalingRoomInfo: {
    roomId: string;
    roomCreatedAt: string;
  };
  localParticipantSid: string;
  appMode: appModeType;
}) => {
  const {
    isInterpreter,
    sessionEndedDueToTimeout,
    setSessionEndedDueToTimeout,
    broadcastId,
    studioUserId,
    isRecorder,
    originalAudioPublishAgoraChannelId,
  } = useAppConfigContext();

  const [joinState, setJoinState] = useState(defaultJoinState);
  const [leftState, setLeftState] = useState({ isLeft: false });
  const [entryAllowed, setEntryAllowed] = useState(false);

  const appModeRef = useRef(appMode);
  const broadcastIdRef = useRef(broadcastId);
  const studioUserIdRef = useRef(studioUserId);
  const interpreterRef = useRef(isInterpreter);
  const isRecorderRef = useRef(isRecorder);

  useEffect(() => {
    appModeRef.current = appMode;
  }, [appMode]);
  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);
  useEffect(() => {
    studioUserIdRef.current = studioUserId;
  }, [studioUserId]);
  useEffect(() => {
    interpreterRef.current = isInterpreter;
  }, [isInterpreter]);
  useEffect(() => {
    isRecorderRef.current = isRecorder;
  }, [isRecorder]);

  const { changeName } = useParticipantName(localParticipantId);

  const { publish: participantHeadlinePublish } = useAppSingalingPublish(
    appPubSubTopics.PARTICIPANT_HEADLINE
  );

  const participantHeadlinePublishRef = useRef<appSingalingPublishType>(
    participantHeadlinePublish
  );

  useEffect(() => {
    participantHeadlinePublishRef.current = participantHeadlinePublish;
  }, [participantHeadlinePublish]);

  const _handleJoin = async ({
    name,
    selectedMicDeviceId,
    selectedWebcamDeviceId,
    mirrorLocalWebcam,
    webcamOn,
    micOn,
    participantHeadline,
    selectedSpeakerDeviceId,
  }: joinStateType) => {
    changeName(name, { force: true });

    await sleep(50);

    participantHeadlinePublishRef.current(
      JSON.stringify({
        participantHeadline: participantHeadline || "",
        participantId: localParticipantId,
      })
    );

    await sleep(50);

    setJoinState({
      name,
      selectedMicDeviceId,
      selectedWebcamDeviceId,
      mirrorLocalWebcam,
      isJoined: true,
      webcamOn,
      micOn,
      participantHeadline,
      selectedSpeakerDeviceId,
    });
  };

  useEffect(() => {
    if (APP_ENV === APP_ENVS.DEV) {
      toast.warn("THIS IS DEV ENV");
    }
  }, []);

  const _handleOnLocalParticipantLeft = () => {
    setJoinState(defaultJoinState);
    setLeftState({ isLeft: true });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.LOCAL_PARTICIPANT_LEFT,
      _handleOnLocalParticipantLeft
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.LOCAL_PARTICIPANT_LEFT,
        _handleOnLocalParticipantLeft
      );
    };
  }, []);

  const { interpretationViaAiData } = useInterpretationViaAiData();

  return (
    <React.Fragment>
      {leftState.isLeft ? (
        <LeftScreenContainer />
      ) : (
        <AppProvider
          {...{
            ...joinState,
            appMode,
            signalingClient,
            rtcClient,
            localParticipantId,
            signalingRoomInfo,
            isJoined: entryAllowed && joinState.isJoined,
          }}
        >
          <React.Fragment>
            {joinState.isJoined ? (
              <React.Fragment>
                {entryAllowed ? (
                  <MeetingContainer />
                ) : (
                  <WaitingToJoinContainer
                    {...{
                      name: joinState.name,
                      participantId: localParticipantId,
                      sid: localParticipantSid,
                      appMode,
                      onEntryAllowed: () => {
                        setEntryAllowed(true);
                      },
                    }}
                  />
                )}
              </React.Fragment>
            ) : isInterpreter ? (
              <InterpreterJoinScreenContainer
                {...{
                  _handleJoin,
                  participantId: localParticipantId,
                  name: name || undefined,
                }}
              />
            ) : appMode === appModes.VIEWER ? (
              <ViewerJoinScreenContainer
                {...{ _handleJoin, name: appModes.VIEWER.toLowerCase() }}
              />
            ) : (
              <JoinScreenContainer
                {...{
                  _handleJoin,
                  name: name || undefined,
                  participantHeadline: participantHeadline || undefined,
                }}
              />
            )}

            {AppSignalingListner ? <AppSignalingListner /> : <React.Fragment />}

            {AppRtcListner ? <AppRtcListner /> : <React.Fragment />}

            {isInterpreter && interpretationViaAiData.length ? (
              <React.Fragment />
            ) : (
              <AgoraInterpreterListner />
            )}
            {(appMode === appModes.HOST || appMode === appModes.SPEAKER) &&
            originalAudioPublishAgoraChannelId ? (
              <OriginalAudioPublisherAgora />
            ) : (
              <React.Fragment />
            )}
            <AppEventsAndPubSubListnersContainer />
            <AppRemoteRtcStreamsListner />
          </React.Fragment>
        </AppProvider>
      )}

      <Modal
        isOpen={sessionEndedDueToTimeout}
        onClose={() => {
          setSessionEndedDueToTimeout(false);
        }}
        renderOnlyBody
        showCloseIcon
        title={"Your time has expired"}
        renderBody={() => (
          <UpgradeNowLine
            breakText
            preText={
              "Free plan has 40 minutes limited time per session. Your guests will no longer be able to join this session."
            }
            postText={" to run unlimited time sessions."}
          />
        )}
      />
    </React.Fragment>
  );
};

export default MeetingAppContainer;
