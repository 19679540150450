import { useState } from "react";
import {
  MdOutlineCreateNewFolder,
  MdRemoveCircleOutline,
} from "react-icons/md";
import { useAppContext } from "../../../../contexts/appContextDef";
import Modal from "../../../../components/Modal";
import useBannerFolders from "../../../../hooks/banners/useBannerFolders";
import BannerFolderItem from "./BannerFolderItem";
import SidePanelTopBar from "../../SidePanelTopBar";
import useActiveBanner from "../../../../hooks/banners/useActiveBanner";
import { quickstartTargetComponentClassNames } from "../../../quickStartTourContainer/QuickStartTourContainer";

const BannerFoldersContainer = () => {
  const {
    setSidePanelAppMode,
    setSidePanelBannersMode,
    foldersBanners,
    activeLowerThirtdBanner,
    activeTickerBanner,
  } = useAppContext();
  const [newFolderName, setNewFolderName] = useState("");
  const [newFolderModalOpen, setNewFolderModalOpen] = useState(false);
  const { createBannerFolder } = useBannerFolders();

  const { toggleActiveLowerThird, toggleActiveTicker } = useActiveBanner();

  const _handleCreateNewFolder = async (name: string) => {
    await createBannerFolder({ name });
  };

  return (
    <div className="flex flex-1 flex-col">
      <SidePanelTopBar
        title={"Banners"}
        onBackClick={() => {
          setSidePanelAppMode(null);

          setSidePanelBannersMode(null);
        }}
        renderRightComponent={() => (
          <button
            onClick={() => {
              setNewFolderModalOpen(true);
            }}
            className={`gap-2 btn btn-primary btn-outline normal-case btn-sm ${quickstartTargetComponentClassNames.create_banner_folder}`}
          >
            <MdOutlineCreateNewFolder size={24} />
          </button>
        )}
      />

      <div className="flex flex-1 flex-col w-full">
        {foldersBanners.map(({ name, id, banners }) => (
          <BannerFolderItem
            {...{ banners, id: `${id}`, name, key: `banner-folder-${id}` }}
          />
        ))}
      </div>

      <div className="flex flex-col items-end">
        {activeLowerThirtdBanner ? (
          <button
            onClick={() => {
              toggleActiveLowerThird({ enable: false });
            }}
            className="btn btn-primary btn-outline btn-sm mt-2"
          >
            <MdRemoveCircleOutline />
            <p className="ml-1">Hide current banner</p>
          </button>
        ) : null}

        {activeTickerBanner ? (
          <button
            onClick={() => {
              toggleActiveTicker({ enable: false });
            }}
            className="btn btn-primary btn-outline btn-sm mt-2"
          >
            <MdRemoveCircleOutline />
            <p className="ml-1">Hide current ticker</p>
          </button>
        ) : null}
      </div>

      <Modal
        title={"Create a folder"}
        isOpen={newFolderModalOpen}
        onClose={() => {
          setNewFolderName("");
          setNewFolderModalOpen(false);
        }}
        onSuccess={() => {
          const _newFolderName = newFolderName;

          setNewFolderName("");
          setNewFolderModalOpen(false);

          _handleCreateNewFolder(_newFolderName);
        }}
        successText={"Create folder"}
        cancelText={"Cancel"}
        showCloseIcon
        renderBody={() => {
          return (
            <input
              value={newFolderName}
              onChange={(e) => {
                setNewFolderName(e.target.value);
              }}
              placeholder="Folder name"
              className="input w-full input-bordered"
            />
          );
        }}
      />
    </div>
  );
};

export default BannerFoldersContainer;
