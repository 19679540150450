import { useAppContext } from "../../contexts/appContextDef";
import { getInputFileVideoStreamTopicById } from "../../utils/pubSubTopics";
import React, { useEffect } from "react";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import { safeParseJson } from "../../utils/safeParseJson";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useInputFileVideoShareStream from "../../hooks/streams/useInputFileVideoShareStream";

export const inputFileVideoShareStreamPubSubActions = {
  SEEK_TO: "SEEK_TO",
  PLAY: "PLAY",
  PAUSE: "PAUSE",
  VOLUME: "VOLUME",
  CURRENT_TIME: "CURRENT_TIME",
};

const InputFileVideoShareStreamListner = ({
  inputFileVideoId,
}: {
  inputFileVideoId: string;
}) => {
  const { setAllInputFileVideoShareStreamStates } = useAppContext();

  const { currentTimeChanged } = useInputFileVideoShareStream({
    inputFileVideoId,
  });

  const _handlePlayingStateChanged = ({ playing }: { playing: boolean }) => {
    setAllInputFileVideoShareStreamStates((s) => {
      const arr = [...s];
      const index = arr.findIndex(({ id }) => id === inputFileVideoId);

      if (index === -1) {
        arr.push({ id: inputFileVideoId, playing });
      } else {
        arr[index] = { ...arr[index], playing };
      }

      return arr;
    });
  };

  const _handleCurrentTimeChanged = ({
    currentTime,
  }: {
    currentTime: number;
  }) => {
    setAllInputFileVideoShareStreamStates((s) => {
      const arr = [...s];
      const index = arr.findIndex(({ id }) => id === inputFileVideoId);

      if (index === -1) {
        arr.push({ id: inputFileVideoId, currentTime });
      } else {
        arr[index] = { ...arr[index], currentTime };
      }

      return arr;
    });
  };

  const _handleSeekTo = ({ seekToSec }: { seekToSec: number }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_INPUT_FILE_VIDEO_SHARE_STREAM_SEEK_TO(
        inputFileVideoId
      ),
      { seekToSec }
    );
  };

  const _handleVolumeChanged = ({ volume }: { volume: number }) => {
    setAllInputFileVideoShareStreamStates((s) => {
      const arr = [...s];
      const index = arr.findIndex(({ id }) => id === inputFileVideoId);

      if (index === -1) {
        arr.push({ volume, id: inputFileVideoId });
      } else {
        arr[index] = { ...arr[index], volume };
      }

      return arr;
    });
  };

  useAppSingalingSubscribe(
    getInputFileVideoStreamTopicById(inputFileVideoId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      if (message.action === inputFileVideoShareStreamPubSubActions.PAUSE) {
        _handlePlayingStateChanged({ playing: false });
      } else if (
        message.action === inputFileVideoShareStreamPubSubActions.PLAY
      ) {
        _handlePlayingStateChanged({ playing: true });
      } else if (
        message.action === inputFileVideoShareStreamPubSubActions.SEEK_TO
      ) {
        _handleSeekTo({ seekToSec: message.payload.seekToSec });
      } else if (
        message.action === inputFileVideoShareStreamPubSubActions.CURRENT_TIME
      ) {
        _handleCurrentTimeChanged({ currentTime: message.payload.currentTime });
      } else if (
        message.action === inputFileVideoShareStreamPubSubActions.VOLUME
      ) {
        _handleVolumeChanged({ volume: message.payload.volume });
      }
    }
  );

  const _handleSourceInputVideoCurrentTimeChanged = ({
    currentTime,
  }: {
    currentTime: number;
  }) => {
    currentTimeChanged({ currentTime });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.RTC_INPUT_FILE_VIDEO_SHARE_STREAM_CURRENT_TIME_CHANGED(
        inputFileVideoId
      ),
      _handleSourceInputVideoCurrentTimeChanged
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.RTC_INPUT_FILE_VIDEO_SHARE_STREAM_CURRENT_TIME_CHANGED(
          inputFileVideoId
        ),
        _handleSourceInputVideoCurrentTimeChanged
      );
    };
  }, [inputFileVideoId]);

  return <React.Fragment />;
};

const AllInputFileVideoShareStreamListner = () => {
  const { activeInputFileVideoShareStreamAllParticipants } = useAppContext();

  return activeInputFileVideoShareStreamAllParticipants.map(({ id }) => (
    <InputFileVideoShareStreamListner
      {...{
        inputFileVideoId: id,
        key: `InputFileVideoShareStreamListner_${id}`,
      }}
    />
  ));
};

export default AllInputFileVideoShareStreamListner;
