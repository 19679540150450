import React, { useEffect, useRef } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import { NOTIFICATION_AUDIO } from "../../static/audios";
import useGetParticipantName from "../../hooks/appState/useGetParticipantName";
import { toast } from "react-toastify";
import { appModes } from "../../utils/constants";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const raiseHandActions = {
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
};

const RaiseHandListner = () => {
  const { setRaiseHandParticipantIds, appMode } = useAppContext();

  const appModeRef = useRef(appMode);

  useEffect(() => {
    appModeRef.current = appMode;
  }, [appMode]);

  const { getParticipantName } = useGetParticipantName();

  useAppSingalingSubscribe(
    appPubSubTopics.RAISE_HAND,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const participantId = message.payload.participantId;
      const action = message.action;

      const enable = action === raiseHandActions.ENABLE;
      const disable = action === raiseHandActions.DISABLE;

      setRaiseHandParticipantIds((s) =>
        disable
          ? s.filter((p) => p !== participantId)
          : enable
          ? [...s, participantId]
          : s
      );

      if (enable && appModeRef.current === appModes.HOST) {
        toast.info(`${getParticipantName(participantId)} raised hand`, {
          icon: false,
        });

        const joinAudio = new Audio(NOTIFICATION_AUDIO);

        const playPromise = joinAudio.play();

        // error could occur in this play

        if (playPromise !== undefined) {
          playPromise
            .then(() => {})
            .catch(() => {
              // no need to handle the play failure
            });
        }
      }
    }
  );

  return <React.Fragment />;
};

export default RaiseHandListner;
