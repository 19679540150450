// @ts-nocheck

import React from "react";
import useIsMobilePortraitOrLandscape from "../../hooks/responsive/useIsMobilePortraitOrLandscape";
import Tooltip from "../../components/Tooltip";
import { MdKeyboardArrowUp, MdPriorityHigh } from "react-icons/md";
import Spinner from "../../components/Spinner";

export const iconButtonVariants = {
  info: "info",
  error: "error",
  primary: "primary",
  active: "active",
};

const IconButton = ({
  Icon,
  title,
  onClick,
  variant,
  maxTitle,
  iconFillVarient,
  rounded,
  disabled,
  disabledTitle,
  tooltipPlacement,
  isProcessing,
  isMenuButton,
  imageIcon,
}: {
  Icon?: React.ComponentType<{ className?: string; size?: number }>;
  imageIcon?: string;
  title: string;
  onClick?: () => void;
  variant?: string;
  maxTitle: string;
  iconFillVarient?: string;
  rounded?: string;
  disabled?: boolean;
  disabledTitle?: string;
  tooltipPlacement?:
    | "start"
    | "center"
    | "end"
    | "above"
    | "below"
    | "startAbove"
    | "startBelow"
    | "centerAbove"
    | "centerBelow"
    | "endAbove"
    | "endBelow";
  isProcessing?: boolean;
  isMenuButton?: boolean;
}) => {
  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  return (
    <React.Fragment>
      <div
        onClick={onClick}
        className={`${
          disabled ? "bg-opacity-50" : ""
        } ${rounded} cursor-pointer ${
          isMobilePortraitOrLandscape ? "py-1 px-2" : "py-1 px-3"
        } flex justify-center items-center flex-col ${
          variant === iconButtonVariants.info
            ? "bg-white"
            : variant === iconButtonVariants.error
            ? "bg-red-200"
            : variant === iconButtonVariants.primary
            ? "bg-primary-content"
            : variant === iconButtonVariants.active
            ? "bg-custom-blue-100"
            : ""
        } relative`}
      >
        <span className={`${disabled ? "opacity-50" : ""}`}>
          {Icon ? (
            <Icon
              className={`${
                iconFillVarient
                  ? "fill-red-600"
                  : variant === iconButtonVariants.info
                  ? "fill-gray-600"
                  : variant === iconButtonVariants.error
                  ? "fill-red-600"
                  : variant === iconButtonVariants.primary
                  ? "fill-primary"
                  : variant === iconButtonVariants.active
                  ? "fill-custom-blue-700"
                  : ""
              }`}
              size={isMobilePortraitOrLandscape ? 16 : 24}
            />
          ) : imageIcon ? (
            <img height={24} width={24} src={imageIcon} />
          ) : (
            <React.Fragment />
          )}
        </span>
        <div
          className={`relative flex items-center justify-center ${
            disabled ? "opacity-50" : ""
          }`}
        >
          <p
            className={`${
              isMobilePortraitOrLandscape ? "text-sm" : ""
            } text-gray-600 text-center opacity-0`}
          >
            {maxTitle}
          </p>
          <p
            className={`${
              isMobilePortraitOrLandscape ? "text-sm" : ""
            } text-gray-600 text-center absolute top-0 right-0 left-0 bottom-0`}
          >
            {title}
          </p>
        </div>

        {disabled && disabledTitle ? (
          <Tooltip placement={tooltipPlacement} title={disabledTitle}>
            <div className="absolute top-0 right-0 h-5 w-5 bg-orange-400 text-white font-bold text-sm rounded-full flex items-center justify-center">
              <MdPriorityHigh
                size={(isMobilePortraitOrLandscape ? 16 : 24) / 2}
              />
            </div>
          </Tooltip>
        ) : isMenuButton ? (
          <div className="absolute top-0.5 right-0.5 flex items-center justify-center">
            <MdKeyboardArrowUp
              className="fill-gray-500"
              size={((isMobilePortraitOrLandscape ? 16 : 24) * 2) / 3}
            />
          </div>
        ) : (
          <React.Fragment />
        )}

        {isProcessing ? (
          <div className="absolute top-0 right-0 left-0 bottom-0 bg-white bg-opacity-50 flex items-center justify-center">
            <Spinner
              w={isMobilePortraitOrLandscape ? "w-6" : "w-8"}
              h={isMobilePortraitOrLandscape ? "h-6" : "h-8"}
            />
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </React.Fragment>
  );
};

export default IconButton;
