import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import PrivateChatGroupListner from "./PrivateChatGroupListner";
import PrivateChatGroupMessagesListner from "./PrivateChatGroupMessagesListner";

const PrivateChatListner = () => {
  const { participatedPrivateChatGroups } = useAppContext();

  return (
    <React.Fragment>
      <PrivateChatGroupListner />

      {participatedPrivateChatGroups.map(({ id }) => (
        <PrivateChatGroupMessagesListner
          key={`PrivateChatGroupMessagesListner_${id}`}
          id={id}
        />
      ))}
    </React.Fragment>
  );
};

export default PrivateChatListner;
