import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import { audioAndVideoPermissionStates } from "../../utils/constants";
import CachedUserData from "../../utils/CachedUserData";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import useRtcLocalParticipantMediaStatsActions from "../../appRtc/useRtcLocalParticipantMediaStatsActions";
import { useInterpretationViaAiData } from "../../listners/appState/LocalParticipantTranslatedAudioBroadcasterContainer";

const AudioSettingsContainer = ({
  activeTabIndex,
}: {
  activeTabIndex: number;
}) => {
  const [mics, setMics] = useState<MediaDeviceInfo[]>([]);
  const [audioOutputDevice, setAudioOutputDevice] = useState<MediaDeviceInfo[]>(
    []
  );

  const {
    setSelectedSpeakerDeviceId,
    selectedSpeakerDeviceId,
    selectedMicDeviceId,

    setSelectedAudioLanguage,
    selectedAudioLanguage,
  } = useAppContext();

  const { localParticipantId } = useLocalParticipantId();

  const { micOn } = useParticipantMediaStats(localParticipantId);

  const micOnRef = useRef(micOn);
  const selectedMicDeviceIdRef = useRef(selectedMicDeviceId);

  const { getMics: _getMics, changeMicDeviceId } =
    useRtcLocalParticipantMediaStatsActions();

  useEffect(() => {
    micOnRef.current = micOn;
  }, [micOn]);
  useEffect(() => {
    selectedMicDeviceIdRef.current = selectedMicDeviceId;
  }, [selectedMicDeviceId]);

  const getMics = async () => {
    const mics = await _getMics();

    mics && mics?.length && setMics(mics);
  };

  const getAudioOutputDevices = async () => {
    if (!navigator?.mediaDevices?.enumerateDevices) return;

    const audioOutputDevice: MediaDeviceInfo[] = [];

    const devices = await navigator.mediaDevices.enumerateDevices();

    for (const device of devices) {
      if (device.kind === "audiooutput") {
        audioOutputDevice.push(device);
      }
    }

    setAudioOutputDevice(audioOutputDevice);
  };

  const _handleChangeMic = async (deviceId: string) => {
    changeMicDeviceId(deviceId);
  };

  const changeSpeaker = (deviceId: string) => {
    CachedUserData.updateSelectedSpeakerDeviceId(deviceId);

    setSelectedSpeakerDeviceId(deviceId);
  };

  const getDevices = async () => {
    await getMics();

    await getAudioOutputDevices();
  };

  useEffect(() => {
    getDevices();
  }, [activeTabIndex]);

  const { micState } = useAppConfigContext();

  const micDenied = useMemo(
    () => micState === audioAndVideoPermissionStates.denied,
    [micState]
  );

  const { interpretationViaAiData } = useInterpretationViaAiData();

  return (
    <div className="flex flex-1 flex-col relative">
      {micDenied ? (
        <p className="text-red-600 font-bold">
          Your browser has blocked your microphone.
        </p>
      ) : (
        <React.Fragment />
      )}

      {micDenied ? (
        <React.Fragment />
      ) : (
        <div className={`mt-4`}>
          <div>
            <p>Mic</p>
            <select
              onChange={(e) => {
                _handleChangeMic(e.target.value);
              }}
              value={selectedMicDeviceId || ""}
              className="select w-full max-w-xs select-bordered mt-1"
            >
              {mics.map((item) => (
                <option
                  key={`mic-selector-item-${item?.deviceId}`}
                  value={item?.deviceId}
                >
                  {item?.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-4">
            <p>Speaker</p>
            <select
              onChange={(e) => {
                changeSpeaker(e.target.value);
              }}
              value={selectedSpeakerDeviceId || ""}
              className="select w-full max-w-xs select-bordered mt-1"
            >
              {audioOutputDevice.map((item) => (
                <option
                  key={`mic-selector-item-${item?.deviceId}`}
                  value={item?.deviceId}
                >
                  {item?.label}
                </option>
              ))}
            </select>
          </div>

          {interpretationViaAiData?.length || true ? (
            <div className="mt-4 relative">
              <p>Speech Language</p>

              <select
                onChange={(e) => {
                  CachedUserData.updateSelectedAudioLanguage(e.target.value);

                  setSelectedAudioLanguage(e.target.value);
                }}
                value={selectedAudioLanguage || ""}
                className="select w-full max-w-xs select-bordered mt-1"
              >
                {[
                  { language: null, languageName: "Auto" },
                  { language: "en", languageName: "English" },
                  { language: "fr", languageName: "French" },
                  { language: "es", languageName: "Spanish" },
                ].map(({ languageName, language }) => (
                  <option
                    key={`audio-language-selector-item-${language}`}
                    value={language as string}
                  >
                    {languageName}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
      )}
      {/* {!micOn && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-opacity-30 bg-white"></div>
      )} */}
    </div>
  );
};

export default AudioSettingsContainer;
