const UrlBarMediaPermissionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={2}>
      <circle cx={18} cy={11} r={2.6} />
      <path strokeLinecap="round" d="M5.5 11H12" />
      <circle cx={8} cy={18} r={2.6} />
      <path strokeLinecap="round" d="M20.5 18H14" />
    </g>
  </svg>
);
export default UrlBarMediaPermissionIcon;
