import React from "react";
import PollsListner from "./polls/PollsListner";
import WordcloudsListner from "./wordclouds/WordcloudsListner";

const AppsListner = () => {
  return (
    <React.Fragment>
      <PollsListner />
      <WordcloudsListner />
    </React.Fragment>
  );
};

export default AppsListner;
