import React, { useEffect, useMemo, useRef, useState } from "react";
import { AppConfigProvider, prepareConfig } from "./contexts/appConfigDef";
import MeetingAppContainer from "./MeetingAppContainer";
import getWebcamPermissionState from "./utils/getWebcamPermissionState";
import getMicPermissionState from "./utils/getMicPermissionState";
import Error404Page from "./components/Error404Page";
import Modal from "react-modal";
import { useWindowSize } from "@react-hook/window-size";
import AccountFreezedContainer from "./containers/AccountFreezedContainer";
import { ToastContainer, toast } from "react-toastify";
import {
  appRtcClient,
  appSignalingClient,
  audioAndVideoPermissionStates,
  permissionNames,
} from "./utils/constants";
import { createAppSignalingClientAndJoinChannel } from "./appSingaling/appSingaling.client";
import InitializingAppContainer from "./containers/initializingAppContainer/InitializingAppContainer";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "./utils/appEventEmitter";
import DisconnectedOrFailedToJoinContainer from "./containers/disconnectedOrFailedToJoinContainer/DisconnectedOrFailedToJoinContainer";
import LocalParticipantEntryRequestDenied from "./containers/localParticipantEntryRequestDenied/LocalParticipantEntryRequestDenied";

Modal.setAppElement("#root");

function App() {
  const [appConfigState, setAppConfigState] = useState<{
    fetching: boolean;
    success: boolean;
    error: null | string;
    appConfig: null | appConfigType;
  }>({
    fetching: true,
    success: false,
    error: null,
    appConfig: null,
  });

  const [disconnectedOrFailedToJoin, setDisconnectedOrFailedToJoin] =
    useState(false);

  const [
    localParticipantEntryRequestDenied,
    setLocalParticipantEntryRequestDenied,
  ] = useState(false);

  const [webcamState, setWebcamState] = useState<null | PermissionState>();
  const [micState, setMicState] = useState<null | PermissionState>();

  const webcamStateRef = useRef(webcamState);
  const micStateRef = useRef(micState);

  useEffect(() => {
    webcamStateRef.current = webcamState;
  }, [webcamState]);
  useEffect(() => {
    micStateRef.current = micState;
  }, [micState]);

  const { defaultName, defaultParticipantHeadline, debug } = useMemo(() => {
    const location = window.location;

    const urlParams = new URLSearchParams(location.search);

    let defaultName = urlParams.get("name");
    let defaultParticipantHeadline = urlParams.get("participantHeadline");

    const debug = urlParams.get("debug") === "true";

    if (typeof defaultName === "string") {
      defaultName = defaultName.split("+").join(" ");
    }

    if (typeof defaultParticipantHeadline === "string") {
      defaultParticipantHeadline = defaultParticipantHeadline
        .split("+")
        .join(" ");
    }

    return {
      defaultName,
      debug,
      defaultParticipantHeadline,
    };
  }, []);

  const _handleInitApp = async () => {
    const { config, success } = await prepareConfig();

    if (success && config) {
      const appMode = config.appMode;
      const broadcastId = config.broadcastId;
      const participantName = config.name;
      const studioUserId = config.studioUserId;
      const isInterpreter = config.isInterpreter;
      const isRecorder = config.isRecorder;
      const participantId = config.participantId;

      if (
        appMode &&
        broadcastId &&
        studioUserId &&
        participantId &&
        createAppSignalingClientAndJoinChannel
      ) {
        const { success, sid, signalingClient, signalingRoomInfo } =
          await createAppSignalingClientAndJoinChannel({
            appMode,
            broadcastId,
            participantName: participantName || "",
            studioUserId,
            isInterpreter,
            isRecorder,
            participantId,
          });

        if (success && participantId && signalingClient) {
          const rtcClient =
            appRtcClient.toString() === appSignalingClient.toString()
              ? signalingClient
              : null;

          if (rtcClient) {
            setAppConfigState({
              fetching: false,
              error: "",
              success: true,
              appConfig: {
                ...config,
                signalingClient,
                rtcClient,
                participantId,
                signalingRoomInfo,
                sid,
              },
            });

            return;
          }
        }
      }
    }

    setDisconnectedOrFailedToJoin(true);

    setAppConfigState({
      fetching: false,
      error: "",
      success: false,
      appConfig: null,
    });
  };

  useEffect(() => {
    _handleInitApp();
  }, []);

  const startCheckingCameraMediaPermissionInterval = () => {
    setInterval(async () => {
      let micState;
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });

        micState = audioAndVideoPermissionStates.granted;
      } catch (error) {
        micState = audioAndVideoPermissionStates.denied;
      }

      if (micState !== micStateRef.current) {
        setMicState(micState);
      }
    }, 5000);
  };

  const startCheckingMicMediaPermissionInterval = () => {
    setInterval(async () => {
      let webcamState;

      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });

        webcamState = audioAndVideoPermissionStates.granted;
      } catch (error) {
        webcamState = audioAndVideoPermissionStates.denied;
      }

      if (webcamState !== webcamStateRef.current) {
        setWebcamState(webcamState);
      }
    }, 5000);
  };

  const listenToMediaPermission = async () => {
    const webcamState = await getWebcamPermissionState();
    const micState = await getMicPermissionState();

    setWebcamState(webcamState);
    setMicState(micState);

    navigator.permissions
      .query({ name: permissionNames.microphone })
      .then(function (permissionStatus) {
        permissionStatus.onchange = function () {
          const micState = this.state;

          setMicState(micState);
        };
      })
      .catch(() => {
        startCheckingMicMediaPermissionInterval();
      });

    navigator.permissions
      .query({ name: permissionNames.camera })
      .then(function (permissionStatus) {
        permissionStatus.onchange = function () {
          const webcamState = this.state;

          setWebcamState(webcamState);
        };
      })
      .catch(() => {
        startCheckingCameraMediaPermissionInterval();
      });
  };

  const _handleDisconnectedOrFailedToJoin = () => {
    setDisconnectedOrFailedToJoin(true);
  };

  const _handleLocalParticipantEntryRequestDenied = () => {
    setLocalParticipantEntryRequestDenied(true);
  };

  useEffect(() => {
    listenToMediaPermission();

    appEventEmitter.on(
      appEventEmitterEvents.DISCONNECTED_OR_FAILED_TO_JOIN,
      _handleDisconnectedOrFailedToJoin
    );

    appEventEmitter.on(
      appEventEmitterEvents.LOCAL_PARTICIPANT_ENTRY_REQUEST_DENIED,
      _handleLocalParticipantEntryRequestDenied
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.DISCONNECTED_OR_FAILED_TO_JOIN,
        _handleDisconnectedOrFailedToJoin
      );

      appEventEmitter.off(
        appEventEmitterEvents.LOCAL_PARTICIPANT_ENTRY_REQUEST_DENIED,
        _handleLocalParticipantEntryRequestDenied
      );
    };
  }, []);

  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <React.Fragment>
      {disconnectedOrFailedToJoin ? (
        <DisconnectedOrFailedToJoinContainer />
      ) : localParticipantEntryRequestDenied ? (
        <LocalParticipantEntryRequestDenied />
      ) : appConfigState.fetching ? (
        <InitializingAppContainer />
      ) : appConfigState.success && appConfigState.appConfig ? (
        appConfigState.appConfig.isFreeze ? (
          <AccountFreezedContainer />
        ) : (
          <div
            style={{
              width: windowWidth,
              height: windowHeight,
              overflow: "hidden",
              position: "relative",
            }}
          >
            <AppConfigProvider
              {...({
                ...appConfigState.appConfig,
                webcamState,
                micState,
                defaultName,
                debug,
              } as appConfigProviderPropsType)}
            >
              <MeetingAppContainer
                {...{
                  name: defaultName || appConfigState.appConfig.name,
                  participantHeadline:
                    defaultParticipantHeadline ||
                    appConfigState.appConfig.participantHeadline,
                  signalingClient: appConfigState.appConfig.signalingClient,
                  rtcClient: appConfigState.appConfig.rtcClient,
                  localParticipantId: appConfigState.appConfig
                    .participantId as string,
                  localParticipantSid: appConfigState.appConfig.sid as string,
                  signalingRoomInfo: appConfigState.appConfig
                    .signalingRoomInfo as {
                    roomId: string;
                    roomCreatedAt: string;
                  },
                  appMode: appConfigState.appConfig.appMode as appModeType,
                }}
              />
              <ToastContainer
                {...{
                  limit: 2,
                  pauseOnFocusLoss: false,
                  position: toast.POSITION.TOP_LEFT,
                  draggable: false,
                  hideProgressBar: true,
                  closeButton: false,
                  pauseOnHover: false,
                }}
              />
            </AppConfigProvider>
          </div>
        )
      ) : (
        <Error404Page />
      )}
    </React.Fragment>
  );
}

export default App;
