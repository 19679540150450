import { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { mediaStreamKinds } from "../../listners/streams/QueuedStreamsListnerAllPeers";
import {
  mainViewLayouts,
  mainViewMaxWebcamStreamsCount,
} from "../../utils/constants";
import { streamTypes } from "../../listners/appState/MainViewParticipantsListner";

const useGetCalculatedConferenceModeMaxParticipantCount = () => {
  const {
    inQueueStreams,
    conferenceAutoMainVideoLayoutEnabled,
    mainViewLayout,
    gridWithStreamsPosition,
    conferenceMainViewVisibleWebcamStreams,
  } = useAppContext();

  const inQueueStreamsRef = useRef(inQueueStreams);
  const conferenceAutoMainVideoLayoutEnabledRef = useRef(
    conferenceAutoMainVideoLayoutEnabled
  );
  const mainViewLayoutRef = useRef(mainViewLayout);
  const gridWithStreamsPositionRef = useRef(gridWithStreamsPosition);
  const conferenceMainViewVisibleWebcamStreamsRef = useRef(
    conferenceMainViewVisibleWebcamStreams
  );

  useEffect(() => {
    inQueueStreamsRef.current = inQueueStreams;
  }, [inQueueStreams]);
  useEffect(() => {
    conferenceAutoMainVideoLayoutEnabledRef.current =
      conferenceAutoMainVideoLayoutEnabled;
  }, [conferenceAutoMainVideoLayoutEnabled]);
  useEffect(() => {
    mainViewLayoutRef.current = mainViewLayout;
  }, [mainViewLayout]);
  useEffect(() => {
    gridWithStreamsPositionRef.current = gridWithStreamsPosition;
  }, [gridWithStreamsPosition]);
  useEffect(() => {
    conferenceMainViewVisibleWebcamStreamsRef.current =
      conferenceMainViewVisibleWebcamStreams;
  }, [conferenceMainViewVisibleWebcamStreams]);

  const getCalculatedConferenceModeMaxParticipantCount = () => {
    const gridWithStreamsPosition = gridWithStreamsPositionRef.current;
    const conferenceMainViewVisibleWebcamStreams =
      conferenceMainViewVisibleWebcamStreamsRef.current;

    const inQueueStreams = inQueueStreamsRef.current;
    const conferenceAutoMainVideoLayoutEnabled =
      conferenceAutoMainVideoLayoutEnabledRef.current;

    const mainViewLayout = mainViewLayoutRef.current;

    const audioStreams = inQueueStreams.filter(
      ({ kind }) => kind === mediaStreamKinds.audio
    );

    const audioStreamsCount = audioStreams.length;

    const hasShareStream = !!gridWithStreamsPosition?.find(({ streamId }) =>
      streamId.includes(streamTypes.SHARE)
    );

    const pinnedButMutedParticipants =
      conferenceMainViewVisibleWebcamStreams.filter(
        ({ pinned, participantId }) =>
          pinned &&
          !audioStreams.find(
            ({ participantId: _participantId }) =>
              _participantId === participantId
          )
      ).length;

    const resultantAudioStreamsCount =
      (audioStreamsCount || 0) + (pinnedButMutedParticipants || 0);

    if (
      !conferenceAutoMainVideoLayoutEnabled &&
      (mainViewLayout === mainViewLayouts.PICTURE_IN_PICTURE ||
        mainViewLayout === mainViewLayouts.NEWS) &&
      hasShareStream &&
      resultantAudioStreamsCount
    ) {
      return { conferenceModeMaxParticipantCount: 1 };
    }

    const conferenceModeMaxParticipantCount = resultantAudioStreamsCount
      ? resultantAudioStreamsCount > mainViewMaxWebcamStreamsCount
        ? mainViewMaxWebcamStreamsCount
        : resultantAudioStreamsCount
      : 1;

    return { conferenceModeMaxParticipantCount };
  };

  return { getCalculatedConferenceModeMaxParticipantCount };
};

export default useGetCalculatedConferenceModeMaxParticipantCount;
