import React from "react";

import StreamsListner from "./streams";
import ConferenceListner from "./conference";
import BrandsListner from "./brands";
import AppStateListner from "./appState";
import ActiveBrandListner from "./activeBrand";
import AllBannersListner from "./banners/AllBannersListner";
import AppsListner from "./apps";
import PrivateChatListner from "./privateChat";

const AppEventsAndPubSubListnersContainer = () => {
  return (
    <React.Fragment>
      <StreamsListner />
      <ConferenceListner />
      <BrandsListner />
      <AppStateListner />
      <ActiveBrandListner />
      <AllBannersListner />
      <AppsListner />
      <PrivateChatListner />
    </React.Fragment>
  );
};

export default AppEventsAndPubSubListnersContainer;
