import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { fileShareStreamActions } from "../../hooks/streams/useFileShareStreams";
import useVideoShareStreams, {
  videoShareStreamActions,
} from "../../hooks/streams/useVideoShareStreams";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import {
  streamModes,
  streamTypes,
} from "../appState/MainViewParticipantsListner";
import sleep from "../../utils/sleep";
import useIsOldest from "../../hooks/appState/useIsOldest";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { appModes, interactivityModes } from "../../utils/constants";
import { FileShareStreamListner } from "./FileShareStreamsListner";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import { safeParseJson } from "../../utils/safeParseJson";
import useInputFileVideoShareStreams from "../../hooks/streams/useInputFileVideoShareStreams";

export const mediaStreamKinds = {
  video: "video",
  audio: "audio",
  share: "share",
  shareAudio: "shareAudio",
};

const filterDuplicateInQueueStreams = (inQueueStreams: inQueueStreamsType) => {
  const filteredDuplicateInQueueStreams: inQueueStreamsType = [];

  inQueueStreams.forEach(({ kind, participantId, timestamp }) => {
    const isInQueueStreams = filteredDuplicateInQueueStreams.find(
      ({ kind: _kind, participantId: _participantId }) =>
        kind === _kind && participantId === _participantId
    );

    if (!isInQueueStreams) {
      filteredDuplicateInQueueStreams.push({ kind, participantId, timestamp });
    }
  });

  return filteredDuplicateInQueueStreams;
};

const QueuedStreamsListnerAllPeers = () => {
  const {
    allPdfs,
    inQueueStreams,
    setInQueueStreams,
    setActiveFileShareStreamAllParticipants,
    setActiveVideoShareStreamAllParticipants,
    setActiveInputFileVideoShareStreamAllParticipants,
    interactivityMode,
    mainViewSelectedStreams,
    activeFileShareStreamAllParticipants,
    joinedParticipants,
    broadcastAssets,
  } = useAppContext();

  const { disableVideoShareStreamsOfParticipant } = useVideoShareStreams();
  const { disableInputFileVideoShareStreamsOfParticipant } =
    useInputFileVideoShareStreams();

  const interactivityModeRef = useRef(interactivityMode);

  const mainViewSelectedStreamsRef = useRef(mainViewSelectedStreams);
  const inQueueStreamsRef = useRef(inQueueStreams);
  const activeFileShareStreamAllParticipantsRef = useRef(
    activeFileShareStreamAllParticipants
  );
  const allPdfsRef = useRef(allPdfs);
  const joinedParticipantsRef = useRef(joinedParticipants);
  const broadcastAssetsRef = useRef(broadcastAssets);

  useEffect(() => {
    interactivityModeRef.current = interactivityMode;
  }, [interactivityMode]);
  useEffect(() => {
    mainViewSelectedStreamsRef.current = mainViewSelectedStreams;
  }, [mainViewSelectedStreams]);
  useEffect(() => {
    inQueueStreamsRef.current = inQueueStreams;
  }, [inQueueStreams]);
  useEffect(() => {
    activeFileShareStreamAllParticipantsRef.current =
      activeFileShareStreamAllParticipants;
  }, [activeFileShareStreamAllParticipants]);
  useEffect(() => {
    allPdfsRef.current = allPdfs;
  }, [allPdfs]);
  useEffect(() => {
    joinedParticipantsRef.current = joinedParticipants;
  }, [joinedParticipants]);
  useEffect(() => {
    broadcastAssetsRef.current = broadcastAssets;
  }, [broadcastAssets]);

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const { getIsOldestHost } = useIsOldest();

  const addShareToMainViewStreamsForConferenceMode = ({
    participantId,
    kind,
  }: {
    participantId: string;
    kind: string;
  }) => {
    if (
      interactivityModeRef.current === interactivityModes.CONFERENCE &&
      kind === mediaStreamKinds.share
    ) {
      const { isOldestHost } = getIsOldestHost();

      if (isOldestHost) {
        const mainViewSelectedStreams = mainViewSelectedStreamsRef.current;

        const isShareStreamActive = [...mainViewSelectedStreams.keys()].find(
          (key) => key.includes(streamTypes.SHARE)
        );

        if (!isShareStreamActive) {
          addToMainViewStreams({
            mode: streamModes.SCREEN,
            participantId,
            type: streamTypes.SHARE,
          });
        }
      }
    }
  };

  const addToInQueueStreams = async ({
    participantId,
    kind,
    timestamp,
  }: {
    participantId: string;
    kind: string;
    timestamp: number;
  }) => {
    const isInQueueStreams = inQueueStreamsRef.current.find(
      ({ kind: _kind, participantId: _participantId }) =>
        kind === _kind && participantId === _participantId
    );

    if (!isInQueueStreams) {
      setInQueueStreams((inQueueStreams) =>
        filterDuplicateInQueueStreams([
          ...inQueueStreams,
          { participantId, kind, timestamp },
        ])
      );

      await sleep(100);

      addShareToMainViewStreamsForConferenceMode({ participantId, kind });
    }
  };

  const removeFromInQueueStreams = async ({
    participantId,
    kind,
  }: {
    participantId: string;
    kind: string;
  }) => {
    setInQueueStreams((inQueueStreams) =>
      inQueueStreams.filter(
        ({ participantId: _participantId, kind: _kind }) =>
          !(_participantId === participantId && _kind === kind)
      )
    );

    await sleep(100);

    const { isOldestHost } = getIsOldestHost();

    if (isOldestHost) {
      if (
        kind === mediaStreamKinds.share
        // ||
        // kind === mediaStreamKinds.shareAudio
      ) {
        removeFromMainViewStreams({
          participantId: participantId,
          mode: streamModes.SCREEN,
          type: streamTypes.SHARE,
        });
      }
    }
  };

  const removeParticipantFromInQueueStreams = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    setInQueueStreams((inQueueStreams) =>
      inQueueStreams.filter(
        ({ participantId: _participantId }) => _participantId !== participantId
      )
    );
  };

  const removeParticipantFromActiveFileShareStream = ({
    studioUserId,
  }: {
    studioUserId: string;
  }) => {
    const activeFileShareStreamAllParticipants =
      activeFileShareStreamAllParticipantsRef.current;
    const allPdfs = allPdfsRef.current;
    const joinedParticipants = joinedParticipantsRef.current;

    const fileShareStreamsToBeRemoved =
      activeFileShareStreamAllParticipants.filter(({ resourceId }) => {
        const pdf = allPdfs.find(({ id }) => `${id}` === `${resourceId}`);

        if (pdf) {
          const isOwner = pdf.owners.includes(studioUserId);

          if (isOwner) {
            const otherJoinedOwners = joinedParticipants.filter(
              ({ studioUserId: _studioUserId }) =>
                pdf.owners.includes(_studioUserId)
                  ? _studioUserId !== studioUserId
                  : false
            );

            return otherJoinedOwners.length === 0;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });

    if (fileShareStreamsToBeRemoved.length) {
      setActiveFileShareStreamAllParticipants(
        (activeFileShareStreamAllParticipants) =>
          activeFileShareStreamAllParticipants.filter(
            ({ resourceId }) =>
              !fileShareStreamsToBeRemoved.find(
                ({ resourceId: _resourceId }) =>
                  `${resourceId}` === `${_resourceId}`
              )
          )
      );

      appEventEmitter.emit(
        appEventEmitterEvents.REMOVE_FILE_SHARE_STREAMS_FROM_MAIN_VIEW_STREAMS,
        { fileShareStreamsToBeRemoved }
      );
    }
  };

  const removeParticipantFromActiveVideoShareStream = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    disableVideoShareStreamsOfParticipant({ participantId });

    setTimeout(() => {
      setActiveVideoShareStreamAllParticipants(
        (activeVideoShareStreamAllParticipants) =>
          activeVideoShareStreamAllParticipants.filter(
            ({ participantId: _participantId }) =>
              _participantId !== participantId
          )
      );
    }, 1000);
  };

  const removeParticipantFromActiveInputFileVideoShareStream = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    disableInputFileVideoShareStreamsOfParticipant({ participantId });

    setTimeout(() => {
      setActiveInputFileVideoShareStreamAllParticipants(
        (activeInputFileVideoShareStreamAllParticipants) =>
          activeInputFileVideoShareStreamAllParticipants.filter(
            ({ participantId: _participantId }) =>
              _participantId !== participantId
          )
      );
    }, 1000);
  };

  const _handleOnParticipantLeft = async ({
    participantId,
    studioUserId,
  }: {
    participantId: string;
    studioUserId: string;
  }) => {
    removeParticipantFromInQueueStreams({ participantId });
    await sleep(50);
    removeParticipantFromActiveFileShareStream({ studioUserId });
    await sleep(50);
    removeParticipantFromActiveVideoShareStream({ participantId });
    await sleep(50);
    removeParticipantFromActiveInputFileVideoShareStream({ participantId });
    // await sleep(50);
    // removeParticipantFromJoinedParticipants({ participantId });
  };

  useAppSingalingSubscribe(
    appPubSubTopics.FILE_SHARE_STREAM_ENABLED,
    ({
      message: receivedMessage,
      // participantId
    }) => {
      const message = safeParseJson(receivedMessage);

      setActiveFileShareStreamAllParticipants((streams) =>
        message.action === fileShareStreamActions.ENABLE
          ? [
              ...streams,
              {
                ...message,
                // participantId,
                participantId: message.resourceId,
                timestamp: new Date().getTime(),
              },
            ]
          : streams.filter((stream) => stream.id !== message.id)
      );
    }
  );

  useAppSingalingSubscribe(
    appPubSubTopics.VIDEO_SHARE_STREAM_ENABLED,
    ({ message: receivedMessage, participantId }) => {
      const message = safeParseJson(receivedMessage);

      setActiveVideoShareStreamAllParticipants((streams) =>
        message.action === videoShareStreamActions.ENABLE
          ? [
              ...streams,
              {
                ...message,
                participantId,
                timestamp: new Date().getTime(),
              },
            ]
          : streams.filter((stream) => stream.id !== message.id)
      );
    }
  );

  useAppSingalingSubscribe(
    appPubSubTopics.INPUT_FILE_VIDEO_SHARE_STREAM_ENABLED,
    ({ message: receivedMessage, participantId }) => {
      const message = safeParseJson(receivedMessage);

      setActiveInputFileVideoShareStreamAllParticipants((streams) =>
        message.action === videoShareStreamActions.ENABLE
          ? [
              ...streams,
              {
                ...message,
                participantId,
                timestamp: new Date().getTime(),
              },
            ]
          : streams.filter((stream) => stream.id !== message.id)
      );
    }
  );

  const _handleParticipantMediaStatsChanged = ({
    enabled,
    participantId,
    kind,
    timestamp,
  }: {
    enabled: boolean;
    participantId: string;
    kind: string;
    timestamp: number;
  }) => {
    if (enabled) {
      addToInQueueStreams({ kind, participantId, timestamp });
    } else {
      removeFromInQueueStreams({ kind, participantId });
    }
  };

  const _handleAddShareToMainViewStreamsForConferenceMode = ({
    kind,
    participantId,
  }: {
    kind: string;
    participantId: string;
  }) => {
    addShareToMainViewStreamsForConferenceMode({ kind, participantId });
  };

  const remapActiveFileShareStreamAllParticipants = () => {
    const activeFileShareStreamAllParticipants =
      activeFileShareStreamAllParticipantsRef.current;
    const joinedParticipants = joinedParticipantsRef.current;
    const broadcastAssets = broadcastAssetsRef.current;

    const fileShareStreamsToBeRemoved =
      activeFileShareStreamAllParticipants.filter(({ resourceId }) => {
        const broadcastAsset = broadcastAssets.find(
          ({ id }) => `${id}` === `${resourceId}`
        );

        if (broadcastAsset) {
          const joinedParticipantsOwners = joinedParticipants.filter(
            ({ studioUserId, appMode }) => {
              if (appMode === appModes.HOST) {
                return true;
              } else {
                const isOwner = broadcastAsset.owners.includes(studioUserId);

                if (isOwner) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          );

          return !joinedParticipantsOwners.length;
        } else {
          return true;
        }
      });

    if (fileShareStreamsToBeRemoved.length) {
      setActiveFileShareStreamAllParticipants(
        (activeFileShareStreamAllParticipants) =>
          activeFileShareStreamAllParticipants.filter(
            ({ resourceId }) =>
              !fileShareStreamsToBeRemoved.find(
                ({ resourceId: _resourceId }) =>
                  `${resourceId}` === `${_resourceId}`
              )
          )
      );

      appEventEmitter.emit(
        appEventEmitterEvents.REMOVE_FILE_SHARE_STREAMS_FROM_MAIN_VIEW_STREAMS,
        { fileShareStreamsToBeRemoved }
      );
    }
  };

  const initRemapInterval = () => {
    const interval = setInterval(() => {
      remapActiveFileShareStreamAllParticipants();
    }, 5000);

    return interval;
  };

  useEffect(() => {
    const interval = initRemapInterval();

    appEventEmitter.on(
      appEventEmitterEvents.PARTICIPANT_MEDIA_STATS_CHANGED,
      _handleParticipantMediaStatsChanged
    );

    appEventEmitter.on(
      appEventEmitterEvents.ADD_SHARE_TO_MAIN_VIDEO_STREAMS_FOR_CONFERENCE_MODE,
      _handleAddShareToMainViewStreamsForConferenceMode
    );

    appEventEmitter.on(
      appEventEmitterEvents.SIGNALING_PARTICIPANT_LEFT,
      _handleOnParticipantLeft
    );

    return () => {
      clearInterval(interval);

      appEventEmitter.off(
        appEventEmitterEvents.PARTICIPANT_MEDIA_STATS_CHANGED,
        _handleParticipantMediaStatsChanged
      );

      appEventEmitter.off(
        appEventEmitterEvents.ADD_SHARE_TO_MAIN_VIDEO_STREAMS_FOR_CONFERENCE_MODE,
        _handleAddShareToMainViewStreamsForConferenceMode
      );

      appEventEmitter.off(
        appEventEmitterEvents.SIGNALING_PARTICIPANT_LEFT,
        _handleOnParticipantLeft
      );
    };
  }, []);

  return (
    <React.Fragment>
      {activeFileShareStreamAllParticipants.map(({ id }) => {
        return (
          <FileShareStreamListner
            {...{
              key: `activeFileShareStreamAllParticipants_${id}`,
              fileId: id,
            }}
          />
        );
      })}
    </React.Fragment>
  );
};

export default QueuedStreamsListnerAllPeers;
