import React, { useEffect, useMemo, useRef, useState } from "react";
import { mainViewMaxWebcamStreamsCount } from "../../utils/constants";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import sleep from "../../utils/sleep";
import { mediaStreamKinds } from "../../listners/streams/QueuedStreamsListnerAllPeers";
import useIsOldest from "../appState/useIsOldest";
import useParticipantIdsAndCount from "../appState/useParticipantIdsAndCount";
import { safeParseJson } from "../../utils/safeParseJson";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { Room as LivekitRoom } from "livekit-client";

export const pinnedParticipantsPositionActions = {
  PIN: "PIN",
  UNPIN: "UNPIN",
  RESET: "RESET",
};

const conferenceOldDataRequstActions = {
  REQUEST: "REQUEST",
  RESPONSE: "RESPONSE",
};

const removeDuplicateConferenceMainViewVisibleWebcamStreams = (
  conferenceMainViewVisibleWebcamStreams: conferenceMainViewVisibleWebcamStreamsType
) => {
  const filteredConferenceMainViewVisibleWebcamStreams: conferenceMainViewVisibleWebcamStreamsType =
    [];

  conferenceMainViewVisibleWebcamStreams.forEach(
    (conferenceMainViewVisibleWebcamStream) => {
      const { participantId, timestamp } =
        conferenceMainViewVisibleWebcamStream;

      const foundIndex =
        filteredConferenceMainViewVisibleWebcamStreams.findIndex(
          ({ participantId: _participantId }) =>
            _participantId === participantId
        );

      if (foundIndex !== -1) {
        const foundTimestamp =
          filteredConferenceMainViewVisibleWebcamStreams[foundIndex].timestamp;

        if (timestamp && foundTimestamp && timestamp > foundTimestamp) {
          filteredConferenceMainViewVisibleWebcamStreams.splice(foundIndex);

          filteredConferenceMainViewVisibleWebcamStreams.push(
            conferenceMainViewVisibleWebcamStream
          );
        }
      } else {
        filteredConferenceMainViewVisibleWebcamStreams.push(
          conferenceMainViewVisibleWebcamStream
        );
      }
    }
  );

  return filteredConferenceMainViewVisibleWebcamStreams;
};

const removeDuplicateSortedActiveParticipants = (
  sortedActiveParticipants: sortedActiveParticipantsType
) => {
  const filteredSortedActiveParticipants: sortedActiveParticipantsType = [];

  sortedActiveParticipants.forEach((sortedActiveParticipant) => {
    const { participantId, timestamp } = sortedActiveParticipant;

    const foundIndex = filteredSortedActiveParticipants.findIndex(
      ({ participantId: _participantId }) => _participantId === participantId
    );

    if (foundIndex !== -1) {
      const foundTimestamp =
        filteredSortedActiveParticipants[foundIndex].timestamp;

      if (timestamp && foundTimestamp && timestamp > foundTimestamp) {
        filteredSortedActiveParticipants.splice(foundIndex);

        filteredSortedActiveParticipants.push(sortedActiveParticipant);
      }
    } else {
      filteredSortedActiveParticipants.push(sortedActiveParticipant);
    }
  });

  return filteredSortedActiveParticipants;
};

const useCalculateMainViewConferenceVisibleWebcamStreams = ({
  inQueueStreams,
  appMode,
  joinedParticipants,
  conferenceModeMaxParticipantCount,
  conferenceModeParticipantAutoCountEnabled,
  localParticipantId,
  allowedEntryRequestParticipantIds,
  signalingClient,
  studioParticipantIdLivekitSidMapRef,
  //
  setActiveSpeakerParticipantId,
  setConferenceModeMaxParticipantCount,
  setConferenceModeParticipantAutoCountEnabled,
}: {
  localParticipantId: string;
  inQueueStreams: inQueueStreamsType;
  appMode: appModeType;
  joinedParticipants: joinedParticipantsType;
  conferenceModeMaxParticipantCount: number;
  conferenceModeParticipantAutoCountEnabled: boolean;
  allowedEntryRequestParticipantIds: allowedEntryRequestParticipantIdsType;
  signalingClient: LivekitRoom;
  studioParticipantIdLivekitSidMapRef: React.MutableRefObject<
    Map<string, string>
  >;
  setActiveSpeakerParticipantId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setConferenceModeMaxParticipantCount: React.Dispatch<
    React.SetStateAction<number>
  >;
  setConferenceModeParticipantAutoCountEnabled: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) => {
  const { participantIds } = useParticipantIdsAndCount({ joinedParticipants });

  const [
    conferenceMainViewVisibleWebcamStreams,
    setConferenceMainViewVisibleWebcamStreams,
  ] = useState<conferenceMainViewVisibleWebcamStreamsType>([]);
  const [sortedActiveParticipants, setSortedActiveParticipants] =
    useState<sortedActiveParticipantsType>([]);
  const [conferenceOldDataFetched, setConferenceOldDataFetched] =
    useState(false);

  const audioStreams = useMemo(() => {
    const audioStreams = inQueueStreams.filter(
      ({ kind }) => kind === mediaStreamKinds.audio
    );

    return audioStreams;
  }, [inQueueStreams]);

  const { publish: conferenceCalculatedStatesPublish } = useAppSingalingPublish(
    appPubSubTopics.CONFERENCE_CALCULATED_STATES
  );

  const audioStreamsRef = useRef(audioStreams);
  // const conferenceCalculatedStatesPublishRef =
  //   useRef<videosdkPubsubPublishType>();
  // const conferenceOldDataFetchedPublishRef =
  //   useRef<videosdkPubsubPublishType>();

  const conferenceCalculatedStatesPublishRef = useRef<appSingalingPublishType>(
    conferenceCalculatedStatesPublish
  );
  const conferenceOldDataFetchedPublishRef = useRef<appSingalingPublishType>();

  const conferenceModeMaxParticipantCountRef = useRef(
    conferenceModeMaxParticipantCount
  );
  const conferenceModeParticipantAutoCountEnabledRef = useRef(
    conferenceModeParticipantAutoCountEnabled
  );
  const conferenceMainViewVisibleWebcamStreamsRef = useRef(
    conferenceMainViewVisibleWebcamStreams
  );
  const sortedActiveParticipantsRef = useRef(sortedActiveParticipants);
  const conferenceOldDataFetchedRef = useRef(conferenceOldDataFetched);
  const participantIdsRef = useRef(participantIds);

  useEffect(() => {
    audioStreamsRef.current = audioStreams;
  }, [audioStreams]);
  useEffect(() => {
    conferenceModeMaxParticipantCountRef.current =
      conferenceModeMaxParticipantCount;
  }, [conferenceModeMaxParticipantCount]);
  useEffect(() => {
    conferenceModeParticipantAutoCountEnabledRef.current =
      conferenceModeParticipantAutoCountEnabled;
  }, [conferenceModeParticipantAutoCountEnabled]);
  useEffect(() => {
    conferenceMainViewVisibleWebcamStreamsRef.current =
      conferenceMainViewVisibleWebcamStreams;
  }, [conferenceMainViewVisibleWebcamStreams]);
  useEffect(() => {
    sortedActiveParticipantsRef.current = sortedActiveParticipants;
  }, [sortedActiveParticipants]);
  useEffect(() => {
    conferenceOldDataFetchedRef.current = conferenceOldDataFetched;
  }, [conferenceOldDataFetched]);
  useEffect(() => {
    participantIdsRef.current = participantIds;
  }, [participantIds]);

  const { getIsOldestParticipant } = useIsOldest({
    appMode,
    joinedParticipants,
    localParticipantId,
    allowedEntryRequestParticipantIds,
    signalingClient,
    studioParticipantIdLivekitSidMapRef,
  });

  const onActiveSpeakerChanged = ({
    participantId,
    timestamp,
  }: {
    participantId: string;
    timestamp: number;
  }) => {
    if (!participantId) return;

    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) return;

    const participantIds = participantIdsRef.current;

    const sortedActiveParticipants = removeDuplicateSortedActiveParticipants([
      ...sortedActiveParticipantsRef.current,
    ]);

    const prevActiveParticipantIndex = sortedActiveParticipants.findIndex(
      ({ participantId: _participantId }) => participantId === _participantId
    );

    if (prevActiveParticipantIndex !== -1) {
      sortedActiveParticipants.splice(prevActiveParticipantIndex, 1);
    }

    if (conferenceCalculatedStatesPublishRef.current) {
      conferenceCalculatedStatesPublishRef.current(
        JSON.stringify({
          sortedActiveParticipants: removeDuplicateSortedActiveParticipants(
            [{ timestamp, participantId }, ...sortedActiveParticipants].filter(
              ({ participantId: _p }) => participantIds.find((p) => p === _p)
            )
          ),
        })
        // { persist: false }
      );
    }

    //
    //
    //

    const confMainViewStreams =
      conferenceMainViewVisibleWebcamStreamsRef.current;

    const conferenceModeMaxParticipantCount =
      conferenceModeMaxParticipantCountRef.current;

    if (confMainViewStreams.length === 0) {
      confMainViewStreams[0] = {
        timestamp,
        wasActive: true,
        participantId,
        pinned: false,
      };
    } else {
      const isInsideStreamIndex = confMainViewStreams.findIndex(
        ({ participantId: _participantId }) => _participantId === participantId
      );

      const isInsideStream = isInsideStreamIndex !== -1;

      if (isInsideStream) {
        confMainViewStreams[isInsideStreamIndex] = {
          ...confMainViewStreams[isInsideStreamIndex],
          timestamp,
          wasActive: true,
        };
      } else {
        const conferenceModeParticipantAutoCountEnabled =
          conferenceModeParticipantAutoCountEnabledRef.current;

        if (
          confMainViewStreams.length === conferenceModeMaxParticipantCount ||
          (conferenceModeParticipantAutoCountEnabled &&
            confMainViewStreams.length === mainViewMaxWebcamStreamsCount)
        ) {
          const inActiveAndUnPinnedStreams = confMainViewStreams.filter(
            ({ wasActive, pinned }) => !wasActive && !pinned
          );

          if (inActiveAndUnPinnedStreams.length) {
            // pick first inactive stream and replace

            const inActiveStreamParticipantId =
              inActiveAndUnPinnedStreams[0].participantId;

            const inActiveStreamParticipantIdIndex =
              confMainViewStreams.findIndex(
                ({ participantId: _participantId }) =>
                  _participantId === inActiveStreamParticipantId
              );

            confMainViewStreams[inActiveStreamParticipantIdIndex] = {
              timestamp,
              wasActive: true,
              participantId,
              pinned: false,
            };
          } else {
            // find last active and replace

            const lastActiveSortedStreams = [...confMainViewStreams];

            lastActiveSortedStreams.sort((a, b) =>
              (a.timestamp || 0) > (b.timestamp || 0)
                ? -1
                : (a.timestamp || 0) < (b.timestamp || 0)
                ? 1
                : 0
            );

            const unPinnedLastActiveSortedStreams =
              lastActiveSortedStreams.filter(({ pinned }) => !pinned);

            const lastActiveStreamUnpinned =
              unPinnedLastActiveSortedStreams[
                unPinnedLastActiveSortedStreams.length - 1
              ];

            const lastActiveParticipantId =
              lastActiveStreamUnpinned.participantId;

            const lastActiveParticipantIndex = confMainViewStreams.findIndex(
              ({ participantId: _participantId }) =>
                _participantId === lastActiveParticipantId
            );

            confMainViewStreams[lastActiveParticipantIndex] = {
              timestamp,
              wasActive: true,
              participantId,
              pinned: false,
            };
          }
        } else if (
          confMainViewStreams.length < conferenceModeMaxParticipantCount ||
          (conferenceModeParticipantAutoCountEnabled &&
            confMainViewStreams.length < mainViewMaxWebcamStreamsCount)
        ) {
          confMainViewStreams.push({
            timestamp,
            wasActive: true,
            participantId,
            pinned: false,
          });
        }
      }
    }

    if (conferenceCalculatedStatesPublishRef.current) {
      conferenceCalculatedStatesPublishRef.current(
        JSON.stringify({
          conferenceMainViewVisibleWebcamStreams:
            removeDuplicateConferenceMainViewVisibleWebcamStreams([
              ...confMainViewStreams,
            ]),
        })
        // { persist: false }
      );
    }
  };

  const onParticipantPinned = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) return;

    const conferenceMainViewVisibleWebcamStreams =
      removeDuplicateConferenceMainViewVisibleWebcamStreams(
        conferenceMainViewVisibleWebcamStreamsRef.current
      );
    const conferenceModeMaxParticipantCount =
      conferenceModeMaxParticipantCountRef.current;
    const conferenceModeParticipantAutoCountEnabled =
      conferenceModeParticipantAutoCountEnabledRef.current;
    const sortedActiveParticipants = removeDuplicateSortedActiveParticipants(
      sortedActiveParticipantsRef.current
    );

    const index = conferenceMainViewVisibleWebcamStreams.findIndex(
      ({ participantId: _participantId }) => _participantId === participantId
    );

    if (index === -1) {
      if (
        conferenceMainViewVisibleWebcamStreams.length <
          conferenceModeMaxParticipantCount ||
        (conferenceModeParticipantAutoCountEnabled &&
          conferenceMainViewVisibleWebcamStreams.length <
            mainViewMaxWebcamStreamsCount)
      ) {
        const found = sortedActiveParticipants.find(
          ({ participantId: _participantId }) =>
            participantId === _participantId
        );

        conferenceMainViewVisibleWebcamStreams.push({
          participantId,
          timestamp: found?.timestamp || null,
          wasActive: !!found,
          pinned: true,
        });
      }
    } else {
      conferenceMainViewVisibleWebcamStreams[index] = {
        ...conferenceMainViewVisibleWebcamStreams[index],
        pinned: true,
      };
    }

    if (conferenceCalculatedStatesPublishRef.current) {
      conferenceCalculatedStatesPublishRef.current(
        JSON.stringify({
          conferenceMainViewVisibleWebcamStreams:
            removeDuplicateConferenceMainViewVisibleWebcamStreams([
              ...conferenceMainViewVisibleWebcamStreams,
            ]),
        })
        // { persist: false }
      );
    }
  };

  const onParticipantUnpinned = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) return;

    const conferenceMainViewVisibleWebcamStreams =
      removeDuplicateConferenceMainViewVisibleWebcamStreams(
        conferenceMainViewVisibleWebcamStreamsRef.current
      );

    const index = conferenceMainViewVisibleWebcamStreams.findIndex(
      ({ participantId: _participantId }) => _participantId === participantId
    );

    conferenceMainViewVisibleWebcamStreams[index] = {
      ...conferenceMainViewVisibleWebcamStreams[index],
      pinned: false,
    };

    if (conferenceCalculatedStatesPublishRef.current) {
      conferenceCalculatedStatesPublishRef.current(
        JSON.stringify({
          conferenceMainViewVisibleWebcamStreams:
            removeDuplicateConferenceMainViewVisibleWebcamStreams([
              ...conferenceMainViewVisibleWebcamStreams,
            ]),
        })
        // { persist: false }
      );
    }
  };

  const onParticipantsSwapped = ({
    participantId1,
    participantId2,
  }: {
    participantId1: string;
    participantId2: string;
  }) => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) return;

    const conferenceMainViewVisibleWebcamStreams =
      removeDuplicateConferenceMainViewVisibleWebcamStreams(
        conferenceMainViewVisibleWebcamStreamsRef.current
      );

    const participantId1Index =
      conferenceMainViewVisibleWebcamStreams.findIndex(
        ({ participantId }) => participantId === participantId1
      );
    const participantId2Index =
      conferenceMainViewVisibleWebcamStreams.findIndex(
        ({ participantId }) => participantId === participantId2
      );

    const participantId1Data =
      conferenceMainViewVisibleWebcamStreams[participantId1Index];
    const participantId2Data =
      conferenceMainViewVisibleWebcamStreams[participantId2Index];

    conferenceMainViewVisibleWebcamStreams[participantId1Index] =
      participantId2Data;
    conferenceMainViewVisibleWebcamStreams[participantId2Index] =
      participantId1Data;

    if (conferenceCalculatedStatesPublishRef.current) {
      conferenceCalculatedStatesPublishRef.current(
        JSON.stringify({
          conferenceMainViewVisibleWebcamStreams:
            removeDuplicateConferenceMainViewVisibleWebcamStreams([
              ...conferenceMainViewVisibleWebcamStreams,
            ]),
        })
        // { persist: false }
      );
    }
  };

  const onSignalingParticipantJoined = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    if (!participantId) return;

    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) return;

    const conferenceMainViewVisibleWebcamStreams =
      removeDuplicateConferenceMainViewVisibleWebcamStreams(
        conferenceMainViewVisibleWebcamStreamsRef.current
      );
    const conferenceModeMaxParticipantCount =
      conferenceModeMaxParticipantCountRef.current;
    const conferenceModeParticipantAutoCountEnabled =
      conferenceModeParticipantAutoCountEnabledRef.current;

    if (
      conferenceMainViewVisibleWebcamStreams.length <
        conferenceModeMaxParticipantCount &&
      !conferenceModeParticipantAutoCountEnabled &&
      conferenceCalculatedStatesPublishRef.current
    ) {
      conferenceCalculatedStatesPublishRef.current(
        JSON.stringify({
          conferenceMainViewVisibleWebcamStreams:
            removeDuplicateConferenceMainViewVisibleWebcamStreams([
              ...conferenceMainViewVisibleWebcamStreams,
              {
                participantId,
                timestamp: null,
                wasActive: false,
                pinned: false,
              },
            ]),
        })
        // { persist: false }
      );
    }
  };

  const onSignalingParticipantLeft = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    if (!participantId) return;

    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) return;

    const conferenceMainViewVisibleWebcamStreams =
      removeDuplicateConferenceMainViewVisibleWebcamStreams(
        conferenceMainViewVisibleWebcamStreamsRef.current
      );
    const participantIds = [...participantIdsRef.current].filter(
      (p) => p !== participantId
    );
    const sortedActiveParticipants = removeDuplicateSortedActiveParticipants(
      sortedActiveParticipantsRef.current
    );
    const conferenceModeParticipantAutoCountEnabled =
      conferenceModeParticipantAutoCountEnabledRef.current;

    const index = conferenceMainViewVisibleWebcamStreams.findIndex(
      ({ participantId: _participantId }) => participantId === _participantId
    );

    const isInsideStream = index !== -1;

    if (isInsideStream) {
      const inactiveParticipantIds = participantIds.filter(
        (participantId) =>
          !conferenceMainViewVisibleWebcamStreams.find(
            ({ participantId: _participantId }) =>
              participantId === _participantId
          )
      );

      if (
        inactiveParticipantIds.length &&
        !conferenceModeParticipantAutoCountEnabled
      ) {
        const activeNotVisible = removeDuplicateSortedActiveParticipants(
          sortedActiveParticipants.filter(
            ({ participantId: _participantId }) =>
              !conferenceMainViewVisibleWebcamStreams.find(
                ({ participantId }) => participantId === _participantId
              )
          )
        );

        if (activeNotVisible.length) {
          const { participantId, timestamp } = activeNotVisible[0];

          conferenceMainViewVisibleWebcamStreams[index] = {
            participantId,
            timestamp,
            wasActive: true,
            pinned: false,
          };
        } else {
          conferenceMainViewVisibleWebcamStreams[index] = {
            participantId: inactiveParticipantIds[0],
            timestamp: null,
            wasActive: true,
            pinned: false,
          };
        }

        if (conferenceCalculatedStatesPublishRef.current) {
          conferenceCalculatedStatesPublishRef.current(
            JSON.stringify({
              conferenceMainViewVisibleWebcamStreams:
                removeDuplicateConferenceMainViewVisibleWebcamStreams(
                  conferenceMainViewVisibleWebcamStreams
                ),
            })
            // { persist: false }
          );
        }
      } else {
        if (conferenceCalculatedStatesPublishRef.current) {
          conferenceCalculatedStatesPublishRef.current(
            JSON.stringify({
              conferenceMainViewVisibleWebcamStreams:
                removeDuplicateConferenceMainViewVisibleWebcamStreams([
                  ...conferenceMainViewVisibleWebcamStreams.filter(
                    ({ participantId: _participantId }) =>
                      participantId !== _participantId
                  ),
                ]),
            })
            // { persist: false }
          );
        }
      }
    }
  };

  const onMaxParticipantCountChanged = ({
    conferenceModeMaxParticipantCount,
    conferenceModeParticipantAutoCountEnabled,
  }: {
    conferenceModeMaxParticipantCount: number;
    conferenceModeParticipantAutoCountEnabled: boolean;
  }) => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) return;

    const sortedActiveParticipants = removeDuplicateSortedActiveParticipants(
      sortedActiveParticipantsRef.current
    );

    const conferenceMainViewVisibleWebcamStreams =
      removeDuplicateConferenceMainViewVisibleWebcamStreams(
        conferenceMainViewVisibleWebcamStreamsRef.current
      );

    const audioStreams = audioStreamsRef.current;

    if (conferenceModeParticipantAutoCountEnabled) {
      const pinnedOrUnMutedParticipants =
        conferenceMainViewVisibleWebcamStreams.filter(
          ({ pinned, participantId }) =>
            pinned ||
            !!audioStreams.find(
              ({ participantId: _participantId }) =>
                _participantId === participantId
            )
        );

      if (pinnedOrUnMutedParticipants.length < mainViewMaxWebcamStreamsCount) {
        const diff =
          mainViewMaxWebcamStreamsCount - pinnedOrUnMutedParticipants.length;

        pinnedOrUnMutedParticipants.push(
          ...sortedActiveParticipants
            .filter(
              ({ participantId: _participantId }) =>
                !pinnedOrUnMutedParticipants.find(
                  ({ participantId }) => participantId === _participantId
                ) &&
                !!audioStreams.find(
                  ({ participantId }) => participantId === _participantId
                )
            )
            .slice(0, diff)
            .map(({ participantId, timestamp }) => ({
              participantId,
              timestamp,
              wasActive: true,
              pinned: false,
            }))
        );
      }

      if (conferenceCalculatedStatesPublishRef.current) {
        conferenceCalculatedStatesPublishRef.current(
          JSON.stringify({
            conferenceMainViewVisibleWebcamStreams:
              removeDuplicateConferenceMainViewVisibleWebcamStreams(
                pinnedOrUnMutedParticipants
              ),
          })
          // { persist: false }
        );
      }
    } else {
      if (
        conferenceMainViewVisibleWebcamStreams.length >
        conferenceModeMaxParticipantCount
      ) {
        const diff =
          conferenceMainViewVisibleWebcamStreams.length -
          conferenceModeMaxParticipantCount;

        const pinned = conferenceMainViewVisibleWebcamStreams
          .filter(({ pinned }) => pinned)
          .sort((a, b) =>
            (a.timestamp || 0) > (b.timestamp || 0)
              ? 1
              : (a.timestamp || 0) < (b.timestamp || 0)
              ? -1
              : 0
          );

        const unPinned = conferenceMainViewVisibleWebcamStreams
          .filter(({ pinned }) => !pinned)
          .sort((a, b) =>
            (a.timestamp || 0) > (b.timestamp || 0)
              ? 1
              : (a.timestamp || 0) < (b.timestamp || 0)
              ? -1
              : 0
          );

        if (diff >= unPinned.length) {
          const maxToBeRemovedFromUnpinned = unPinned.length;

          const unPinnedToBeRemoved = unPinned.slice(
            0,
            maxToBeRemovedFromUnpinned
          );

          const maxToBeRemovedFromPinned = diff - unPinned.length;

          const pinnedToBeRemoved = pinned.slice(0, maxToBeRemovedFromPinned);

          const toBeRemoved = [...unPinnedToBeRemoved, ...pinnedToBeRemoved];

          if (conferenceCalculatedStatesPublishRef.current) {
            conferenceCalculatedStatesPublishRef.current(
              JSON.stringify({
                conferenceMainViewVisibleWebcamStreams:
                  removeDuplicateConferenceMainViewVisibleWebcamStreams(
                    conferenceMainViewVisibleWebcamStreams.filter(
                      ({ participantId }) =>
                        !toBeRemoved.find(
                          ({ participantId: _participantId }) =>
                            _participantId === participantId
                        )
                    )
                  ),
              })
              // { persist: false }
            );
          }
        } else {
          const maxToBeRemovedFromUnpinned = diff;

          const toBeRemoved = unPinned.slice(0, maxToBeRemovedFromUnpinned);

          if (conferenceCalculatedStatesPublishRef.current) {
            conferenceCalculatedStatesPublishRef.current(
              JSON.stringify({
                conferenceMainViewVisibleWebcamStreams:
                  removeDuplicateConferenceMainViewVisibleWebcamStreams(
                    conferenceMainViewVisibleWebcamStreams.filter(
                      ({ participantId }) =>
                        !toBeRemoved.find(
                          ({ participantId: _participantId }) =>
                            _participantId === participantId
                        )
                    )
                  ),
              })
              // { persist: false }
            );
          }
        }
      } else if (
        conferenceMainViewVisibleWebcamStreams.length <
        conferenceModeMaxParticipantCount
      ) {
        const diff =
          conferenceModeMaxParticipantCount -
          conferenceMainViewVisibleWebcamStreams.length;

        const streamsToBeAdded = audioStreams
          .filter(
            ({ participantId }) =>
              !conferenceMainViewVisibleWebcamStreams.find(
                ({ participantId: _participantId }) =>
                  participantId === _participantId
              )
          )
          .map(({ participantId }) => {
            const found = sortedActiveParticipants.find(
              ({ participantId: _participantId }) =>
                participantId === _participantId
            );

            return {
              participantId,
              timestamp: found?.timestamp,
              wasActive: !!found,
              pinned: false,
            };
          })
          .sort((a, b) =>
            (a.timestamp || 0) > (b.timestamp || 0)
              ? -1
              : (a.timestamp || 0) < (b.timestamp || 0)
              ? 1
              : 0
          );

        if (streamsToBeAdded.length <= diff) {
          if (conferenceCalculatedStatesPublishRef.current) {
            conferenceCalculatedStatesPublishRef.current(
              JSON.stringify({
                conferenceMainViewVisibleWebcamStreams:
                  removeDuplicateConferenceMainViewVisibleWebcamStreams([
                    ...conferenceMainViewVisibleWebcamStreams,
                    ...(streamsToBeAdded as conferenceMainViewVisibleWebcamStreamsType),
                  ]),
              })
              // { persist: false }
            );
          }
        } else {
          if (conferenceCalculatedStatesPublishRef.current) {
            conferenceCalculatedStatesPublishRef.current(
              JSON.stringify({
                conferenceMainViewVisibleWebcamStreams:
                  removeDuplicateConferenceMainViewVisibleWebcamStreams([
                    ...conferenceMainViewVisibleWebcamStreams,
                    ...(streamsToBeAdded.slice(
                      0,
                      diff
                    ) as conferenceMainViewVisibleWebcamStreamsType),
                  ]),
              })
              // { persist: false }
            );
          }
        }
      }
    }
  };

  const onAudioStreamsChanged = ({
    audioStreams,
  }: {
    audioStreams: { participantId: string; timestamp: number }[];
  }) => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) return;

    const conferenceModeParticipantAutoCountEnabled =
      conferenceModeParticipantAutoCountEnabledRef.current;

    if (!conferenceModeParticipantAutoCountEnabled) return;

    const _conferenceMainViewVisibleWebcamStreams =
      conferenceMainViewVisibleWebcamStreamsRef.current;
    const sortedActiveParticipants = removeDuplicateSortedActiveParticipants(
      sortedActiveParticipantsRef.current
    );

    const conferenceMainViewVisibleWebcamStreams: conferenceMainViewVisibleWebcamStreamsType =
      [];

    const streamsToBeRemoved = [];

    _conferenceMainViewVisibleWebcamStreams.forEach((visibleWebcamStream) => {
      const { pinned, participantId } = visibleWebcamStream;

      if (
        pinned ||
        audioStreams.find(
          ({ participantId: _participantId }) =>
            participantId === _participantId
        )
      ) {
        conferenceMainViewVisibleWebcamStreams.push(visibleWebcamStream);
      } else {
        streamsToBeRemoved.push(visibleWebcamStream);
      }
    });

    if (
      conferenceMainViewVisibleWebcamStreams.length <
      mainViewMaxWebcamStreamsCount
    ) {
      const diff =
        mainViewMaxWebcamStreamsCount -
        conferenceMainViewVisibleWebcamStreams.length;

      const streamsToBeAdded = audioStreams
        .filter(
          ({ participantId }) =>
            !conferenceMainViewVisibleWebcamStreams.find(
              ({ participantId: _participantId }) =>
                participantId === _participantId
            )
        )
        .map(({ participantId }) => {
          const found = sortedActiveParticipants.find(
            ({ participantId: _participantId }) =>
              participantId === _participantId
          );

          return {
            participantId,
            timestamp: found?.timestamp,
            wasActive: !!found,
            pinned: false,
          };
        })
        .sort((a, b) =>
          (a.timestamp || 0) > (b.timestamp || 0)
            ? -1
            : (a.timestamp || 0) < (b.timestamp || 0)
            ? 1
            : 0
        )
        .slice(0, diff);

      if (streamsToBeAdded.length || streamsToBeRemoved.length) {
        if (conferenceCalculatedStatesPublishRef.current) {
          conferenceCalculatedStatesPublishRef.current(
            JSON.stringify({
              conferenceMainViewVisibleWebcamStreams:
                removeDuplicateConferenceMainViewVisibleWebcamStreams([
                  ...conferenceMainViewVisibleWebcamStreams,
                  ...(streamsToBeAdded as conferenceMainViewVisibleWebcamStreamsType),
                ]),
            })
            // { persist: false }
          );
        }
      }
    } else if (
      _conferenceMainViewVisibleWebcamStreams.length !==
      conferenceMainViewVisibleWebcamStreams.length
    ) {
      if (conferenceCalculatedStatesPublishRef.current) {
        conferenceCalculatedStatesPublishRef.current(
          JSON.stringify({
            conferenceMainViewVisibleWebcamStreams:
              removeDuplicateConferenceMainViewVisibleWebcamStreams([
                ...conferenceMainViewVisibleWebcamStreams,
              ]),
          })
          // { persist: false }
        );
      }
    }
  };

  useAppSingalingSubscribe(
    appPubSubTopics.ACTIVE_PARTICIPANT,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const participantId = message.participantId;

      setActiveSpeakerParticipantId(participantId);

      onActiveSpeakerChanged({
        participantId,
        timestamp: new Date().getTime(),
      });
    }
  );

  useAppSingalingSubscribe(
    appPubSubTopics.SWAPPED_PARTICIPANTS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { payload } = message;

      const { participantId1, participantId2 } = payload;

      onParticipantsSwapped({ participantId1, participantId2 });
    }
  );

  useAppSingalingSubscribe(
    appPubSubTopics.PINNED_PARTICIPANTS_POSITION,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { participantId } = message.payload;

      if (message.action === pinnedParticipantsPositionActions.PIN) {
        onParticipantPinned({ participantId });
      } else if (message.action === pinnedParticipantsPositionActions.UNPIN) {
        onParticipantUnpinned({ participantId });
      }
    }
  );

  useAppSingalingSubscribe(
    appPubSubTopics.CONFERENCE_MODE_MAX_PARTICIPANT_COUNT,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setConferenceModeMaxParticipantCount(
        message.conferenceModeMaxParticipantCount
      );

      setConferenceModeParticipantAutoCountEnabled(
        message.conferenceModeParticipantAutoCountEnabled
      );

      onMaxParticipantCountChanged({
        conferenceModeMaxParticipantCount:
          message.conferenceModeMaxParticipantCount,
        conferenceModeParticipantAutoCountEnabled:
          message.conferenceModeParticipantAutoCountEnabled,
      });
    }
  );

  useAppSingalingSubscribe(
    appPubSubTopics.CONFERENCE_CALCULATED_STATES,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const {
        conferenceMainViewVisibleWebcamStreams,
        sortedActiveParticipants,
      } = message;

      if (conferenceMainViewVisibleWebcamStreams) {
        setConferenceMainViewVisibleWebcamStreams(
          removeDuplicateConferenceMainViewVisibleWebcamStreams(
            conferenceMainViewVisibleWebcamStreams
          )
        );
      }

      if (sortedActiveParticipants) {
        setSortedActiveParticipants(
          removeDuplicateSortedActiveParticipants(sortedActiveParticipants)
        );
      }
    }
  );

  useAppSingalingSubscribe(
    appPubSubTopics.CONFERENCE_OLD_DATA_REQUEST,
    async ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const {
        action,
        payload,
      }: {
        action: string;
        payload: {
          sortedActiveParticipants?: sortedActiveParticipantsType;
          conferenceMainViewVisibleWebcamStreams?: conferenceMainViewVisibleWebcamStreamsType;
        };
      } = message;

      if (action === conferenceOldDataRequstActions.REQUEST) {
        const { isOldestParticipant } = getIsOldestParticipant();

        if (isOldestParticipant) {
          if (conferenceOldDataFetchedPublishRef.current) {
            conferenceOldDataFetchedPublishRef.current(
              JSON.stringify({
                action: conferenceOldDataRequstActions.RESPONSE,
                payload: {
                  sortedActiveParticipants:
                    removeDuplicateSortedActiveParticipants(
                      sortedActiveParticipantsRef.current
                    ),
                  conferenceMainViewVisibleWebcamStreams:
                    removeDuplicateConferenceMainViewVisibleWebcamStreams(
                      conferenceMainViewVisibleWebcamStreamsRef.current
                    ),
                },
              })
              // { persist: false }
            );
          }
        }
      } else if (
        action === conferenceOldDataRequstActions.RESPONSE &&
        !conferenceOldDataFetchedRef.current
      ) {
        const {
          sortedActiveParticipants,
          conferenceMainViewVisibleWebcamStreams,
        } = payload;

        if (
          sortedActiveParticipants &&
          conferenceMainViewVisibleWebcamStreams
        ) {
          setSortedActiveParticipants(
            removeDuplicateSortedActiveParticipants([
              ...sortedActiveParticipants,
            ])
          );

          await sleep(50);

          setConferenceMainViewVisibleWebcamStreams(
            removeDuplicateConferenceMainViewVisibleWebcamStreams([
              ...conferenceMainViewVisibleWebcamStreams,
            ])
          );

          await sleep(50);

          setConferenceOldDataFetched(true);
        }
      }
    }
  );

  const { publish: conferenceOldDataFetchedPublish } = useAppSingalingPublish(
    appPubSubTopics.CONFERENCE_OLD_DATA_REQUEST
  );

  const _handleGetOldData = () => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) {
      if (conferenceOldDataFetchedPublishRef.current) {
        conferenceOldDataFetchedPublishRef.current(
          JSON.stringify({
            action: conferenceOldDataRequstActions.REQUEST,
          })
          // { persist: false }
        );
      }
    } else {
      setConferenceOldDataFetched(true);
    }
  };

  const _handleSignalingParticipantJoined = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    onSignalingParticipantJoined({ participantId });
  };
  const _handleSignalingParticipantLeft = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    onSignalingParticipantLeft({ participantId });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.SIGNALING_PARTICIPANT_JOINED,
      _handleSignalingParticipantJoined
    );
    appEventEmitter.on(
      appEventEmitterEvents.SIGNALING_PARTICIPANT_LEFT,
      _handleSignalingParticipantLeft
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.SIGNALING_PARTICIPANT_JOINED,
        _handleSignalingParticipantJoined
      );
      appEventEmitter.off(
        appEventEmitterEvents.SIGNALING_PARTICIPANT_LEFT,
        _handleSignalingParticipantLeft
      );
    };
  }, []);

  useEffect(() => {
    setTimeout(_handleGetOldData, 3000);
  }, []);

  useEffect(() => {
    conferenceOldDataFetchedPublishRef.current =
      conferenceOldDataFetchedPublish;
  }, [conferenceOldDataFetchedPublish]);

  useEffect(() => {
    conferenceCalculatedStatesPublishRef.current =
      conferenceCalculatedStatesPublish;
  }, [conferenceCalculatedStatesPublish]);

  useEffect(() => {
    onAudioStreamsChanged({ audioStreams });
  }, [audioStreams]);

  const conferenceMainViewVisibleWebcamStreamsFiltered = useMemo(
    () =>
      removeDuplicateConferenceMainViewVisibleWebcamStreams(
        conferenceMainViewVisibleWebcamStreams.filter(({ participantId }) =>
          joinedParticipants.find(
            ({ participantId: _participantId }) =>
              _participantId === participantId
          )
        )
      ),
    [conferenceMainViewVisibleWebcamStreams, joinedParticipants]
  );

  return {
    conferenceMainViewVisibleWebcamStreams:
      conferenceMainViewVisibleWebcamStreamsFiltered,
  };
};

export default useCalculateMainViewConferenceVisibleWebcamStreams;
