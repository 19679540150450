import EventEmitter from "events";

export const appEventEmitter = new EventEmitter();

appEventEmitter.setMaxListeners(Infinity);

export const appEventEmitterEvents = {
  INQUEUE_STREAM_VISIBLE: "INQUEUE_STREAM_VISIBLE",
  CONFERENCE_EVENTS: "CONFERENCE_EVENTS",
  TOTAL_TIME_REMAINING_IN_SECONDS: "TOTAL_TIME_REMAINING_IN_SECONDS",
  TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
  BRAND_VIDEO_CLIP_PROGRESS: (id: string) => `BRAND_VIDEO_CLIP_PROGRESS_${id}`,
  BRAND_BACKGROUND_MUSIC_PROGRESS: (id: string) =>
    `BRAND_BACKGROUND_MUSIC_PROGRESS_${id}`,
  MAIN_VIDEO_CONTAINER_MOUSE_OVER: "MAIN_VIDEO_CONTAINER_MOUSE_OVER",
  HIDE_HOST_IN_QUEUE_POPUP_MESSAGE: "HIDE_HOST_IN_QUEUE_POPUP_MESSAGE",
  TOGGLED_LATERAL_SIDEBAR: "TOGGLED_LATERAL_SIDEBAR",
  ASSET_UPLOADING_AND_PROCESSING_PROGRESS: (id: string) =>
    `ASSET_UPLOADING_AND_PROCESSING_PROGRESS_${id}`,
  MAIN_VIEW_AUDIO_ONLY_PARTICIPANTS_POPUP: `MAIN_VIEW_AUDIO_ONLY_PARTICIPANTS_POPUP`,
  QNA_LOAD_PREV_CHAT: "QNA_LOAD_PREV_CHAT",
  QNA_NEW_MESSAGE: "QNA_NEW_MESSAGE",
  VALIDATE_CONNECTION_RESPONSE: (id: string) =>
    `VALIDATE_CONNECTION_RESPONSE_${id}`,
  LOCAL_MIC_VOLUME_CHANGED: "LOCAL_MIC_VOLUME_CHANGED",
  PARTICIPANT_MIC_AUDIO_LEVEL: (id: string) =>
    `PARTICIPANT_MIC_AUDIO_LEVEL_${id}`,
  VIDEO_READY_TO_BE_PLAYED: (id: string) => `VIDEO_READY_TO_BE_PLAYED_${id}`,
  BAD_NETWORK_TOAST: "BAD_NETWORK_TOAST",

  MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED:
    "MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED",

  REQUEST_MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED:
    "REQUEST_MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED",

  MAIN_VIEW_VIDEO_SHARE_STREAM_PLAY_ERRORED:
    "MAIN_VIEW_VIDEO_SHARE_STREAM_PLAY_ERRORED",
  MAIN_VIEW_INPUT_FILE_VIDEO_SHARE_STREAM_PLAY_ERRORED:
    "MAIN_VIEW_INPUT_FILE_VIDEO_SHARE_STREAM_PLAY_ERRORED",
  MAIN_VIEW_VIDEO_CLIP_PLAY_ERRORED: "MAIN_VIEW_VIDEO_CLIP_PLAY_ERRORED",

  UPDATE_MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME:
    "UPDATE_MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME",
  MAIN_VIEW_VIDEO_SHARE_STREAM_SEEK_PLAYER_TO_SEC:
    "MAIN_VIEW_VIDEO_SHARE_STREAM_SEEK_PLAYER_TO_SEC",

  USER_INTERACT_BUTTON_CLICKED: "USER_INTERACT_BUTTON_CLICKED",

  APP_QUEUE: "APP_QUEUE",
  CHANGE_WEBCAM_IN_PROGRESS: "CHANGE_WEBCAM_IN_PROGRESS",
  CHANGE_MIC_IN_PROGRESS: "CHANGE_MIC_IN_PROGRESS",

  PARTICIPANT_MEDIA_STATS_CHANGED: "PARTICIPANT_MEDIA_STATS_CHANGED",
  ADD_SHARE_TO_MAIN_VIDEO_STREAMS_FOR_CONFERENCE_MODE:
    "ADD_SHARE_TO_MAIN_VIDEO_STREAMS_FOR_CONFERENCE_MODE",

  REPORTE_UNPLAYABLE_MEDIA_STREAMS: "REPORTE_UNPLAYABLE_MEDIA_STREAMS",

  PARTICIPANTS_AUDIO_TRACK_PLAYING: "PARTICIPANTS_AUDIO_TRACK_PLAYING",

  START_BROADCAST_EGRESSES: "START_BROADCAST_EGRESSES",
  STOP_BROADCAST_EGRESSES: "STOP_BROADCAST_EGRESSES",
  EDIT_BROADCAST_DESTINATIONS: "EDIT_BROADCAST_DESTINATIONS",
  PUBLISH_EGRESSES_PROGRESS_STATE: "PUBLISH_EGRESSES_PROGRESS_STATE",
  START_EGRESSES_FAILED_DUE_TO_NO_OUTPUTS:
    "START_EGRESSES_FAILED_DUE_TO_NO_OUTPUTS",

  ASK_MIC_PERMISSION: "ASK_MIC_PERMISSION",
  ASK_CAMERA_PERMISSION: "ASK_CAMERA_PERMISSION",

  JOYRIDE_CALLBACK: "JOYRIDE_CALLBACK",

  QUICKSTART_EVENT_EMITTED: "QUICKSTART_EVENT_EMITTED",
  QUICKSTART_SKIP_ALL: "QUICKSTART_SKIP_ALL",
  QUICKSTART_CONTINUE: "QUICKSTART_CONTINUE",
  QUICKSTART_RESTART_FROM_BEGINNING: "QUICKSTART_RESTART_FROM_BEGINNING",

  QUICKSTART_CHANGE_LAYOUTS_COMPLETED: "QUICKSTART_CHANGE_LAYOUTS_COMPLETED",
  QUICKSTART_ADD_DUMMY_WEBCAM_STREAMS: "QUICKSTART_ADD_DUMMY_WEBCAM_STREAMS",
  QUICKSTART_ADD_DUMMY_PRESENTATION: "QUICKSTART_ADD_DUMMY_PRESENTATION",
  QUICKSTART_CREATE_NEW_BRAND: "QUICKSTART_CREATE_NEW_BRAND",
  QUICKSTART_CLOSE_BRAND_THEME: "QUICKSTART_CLOSE_BRAND_THEME",
  QUICKSTART_CLOSE_BRAND_LOGO: "QUICKSTART_CLOSE_BRAND_LOGO",
  QUICKSTART_CLOSE_BRAND_OVERLAY: "QUICKSTART_CLOSE_BRAND_OVERLAY",
  QUICKSTART_CLOSE_BRAND_VIDEO: "QUICKSTART_CLOSE_BRAND_VIDEO",
  QUICKSTART_CLOSE_BRAND_BACKGROUND: "QUICKSTART_CLOSE_BRAND_BACKGROUND",
  QUICKSTART_CLOSE_BRAND_MUSIC: "QUICKSTART_CLOSE_BRAND_MUSIC",
  QUICKSTART_SCROLL_BRAND_PANEL_TO_BOTTOM:
    "QUICKSTART_SCROLL_BRAND_PANEL_TO_BOTTOM",
  QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_LOGO:
    "QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_LOGO",
  QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_VIDEO_CLIP:
    "QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_VIDEO_CLIP",
  QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_BACKGROUND:
    "QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_BACKGROUND",
  QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_BACKGROUND_MUSIC:
    "QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_BACKGROUND_MUSIC",
  QUICKSTART_APP_OPEN_BANNER_SECTION: "QUICKSTART_APP_OPEN_BANNER_SECTION",
  QUICKSTART_APP_OPEN_POLL_SECTION: "QUICKSTART_APP_OPEN_POLL_SECTION",
  QUICKSTART_APP_OPEN_WORDCLOUD_SECTION:
    "QUICKSTART_APP_OPEN_WORDCLOUD_SECTION",
  QUICKSTART_APP_CREATE_POLL: "QUICKSTART_APP_CREATE_POLL",
  QUICKSTART_APP_CREATE_WORDCLOUD: "QUICKSTART_APP_CREATE_WORDCLOUD",
  QUICKSTART_SUBMIT_POLL_ANSWER: "QUICKSTART_SUBMIT_POLL_ANSWER",
  QUICKSTART_SUBMIT_WORDCLOUD_ANSWER: "QUICKSTART_SUBMIT_WORDCLOUD_ANSWER",
  QUICHSTART_CREATE_BANNER_FOLDER: "QUICHSTART_CREATE_BANNER_FOLDER",
  QUICHSTART_CREATE_BANNERS: "QUICHSTART_CREATE_BANNERS",

  BRAND_ADD_AND_ACTIVATE_LOGO: (id: string) =>
    `BRAND_ADD_AND_ACTIVATE_LOGO_${id}`,
  BRAND_ADD_AND_ACTIVATE_VIDEO_CLIP: (id: string) =>
    `BRAND_ADD_AND_ACTIVATE_VIDEO_CLIP_${id}`,
  BRAND_ADD_AND_ACTIVATE_BACKGROUND: (id: string) =>
    `BRAND_ADD_AND_ACTIVATE_BACKGROUND_${id}`,
  BRAND_ADD_AND_ACTIVATE_BACKGROUND_MUSIC: (id: string) =>
    `BRAND_ADD_AND_ACTIVATE_BACKGROUND_MUSIC_${id}`,

  POLL_CREATED: "POLL_CREATED",
  WORDCLOUD_CREATED: "WORDCLOUD_CREATED",
  COPY_POLL_AND_DELETE_OLD: "COPY_POLL_AND_DELETE_OLD",
  COPY_WORDCLOUD_AND_DELETE_OLD: "COPY_WORDCLOUD_AND_DELETE_OLD",

  LEAVE_AGORA_CHANNEL_AND_SWITCH_CHANNEL:
    "LEAVE_AGORA_CHANNEL_AND_SWITCH_CHANNEL",

  SIGNALING_PARTICIPANT_JOINED: "SIGNALING_PARTICIPANT_JOINED",
  INTERPRETER_PARTICIPANT_JOINED: "INTERPRETER_PARTICIPANT_JOINED",
  RECORDER_PARTICIPANT_JOINED: "RECORDER_PARTICIPANT_JOINED",
  SIGNALING_PARTICIPANT_LEFT: "SIGNALING_PARTICIPANT_LEFT",
  INTERPRETER_PARTICIPANT_LEFT: "INTERPRETER_PARTICIPANT_LEFT",
  RECORDER_PARTICIPANT_LEFT: "RECORDER_PARTICIPANT_LEFT",

  LOCAL_PARTICIPANT_JOINED: "LOCAL_PARTICIPANT_JOINED",
  LOCAL_PARTICIPANT_LEFT: "LOCAL_PARTICIPANT_LEFT",

  SIGNALING_PUBSUB_PUBLISH: `SIGNALING_PUBSUB_PUBLISH`,
  SIGNALING_PUBSUB_SUBSCRIBE: (topic: string) =>
    `SIGNALING_PUBSUB_SUBSCRIBE_${topic}`,

  INIT_REQ_CURRENT_TIME: `INIT_REQ_CURRENT_TIME`,
  RESOLVE_REQ_CURRENT_TIME: (reqId: string) =>
    `RESOLVE_REQ_CURRENT_TIME_${reqId}`,

  INIT_REQUEST_ENTRY: `INIT_REQUEST_ENTRY`,
  RESOLVE_REQUEST_ENTRY: `RESOLVE_REQUEST_ENTRY`,

  LEAVE_FROM_SESSION: "LEAVE_FROM_SESSION",
  DISCONNECTED_OR_FAILED_TO_JOIN: "DISCONNECTED_OR_FAILED_TO_JOIN",

  LOCAL_PARTICIPANT_ENTRY_REQUEST_DENIED:
    "LOCAL_PARTICIPANT_ENTRY_REQUEST_DENIED",

  ADD_PDF_AND_PPT_FILE_EVENT: "ADD_PDF_AND_PPT_FILE_EVENT",
  ADD_PDF_FROM_UPLOADED: "ADD_PDF_FROM_UPLOADED",

  REMOVE_FILE_SHARE_STREAMS_FROM_MAIN_VIEW_STREAMS:
    "REMOVE_FILE_SHARE_STREAMS_FROM_MAIN_VIEW_STREAMS",

  AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_ON_MIC:
    "AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_ON_MIC",
  AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_OFF_MIC:
    "AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_OFF_MIC",
  AGORA_INTERPRETATION_INPUT_CHANNEL_CHANGE_MIC_DEVICE_ID:
    "AGORA_INTERPRETATION_INPUT_CHANNEL_CHANGE_MIC_DEVICE_ID",
  OPEN_EXTRA_WEBCAM_SETTINGS: "OPEN_EXTRA_WEBCAM_SETTINGS",

  RTC_TURN_ON_WEBCAM: "RTC_TURN_ON_WEBCAM",
  RTC_TURN_OFF_WEBCAM: "RTC_TURN_OFF_WEBCAM",
  RTC_TOGGLE_WEBCAM: "RTC_TOGGLE_WEBCAM",
  RTC_TURN_ON_MIC: "RTC_TURN_ON_MIC",
  RTC_TURN_OFF_MIC: "RTC_TURN_OFF_MIC",
  RTC_TOGGLE_MIC: "RTC_TOGGLE_MIC",
  RTC_TURN_ON_SCREEN_SHARE: "RTC_TURN_ON_SCREEN_SHARE",
  RTC_TURN_OFF_SCREEN_SHARE: "RTC_TURN_OFF_SCREEN_SHARE",
  RTC_TOGGLE_SCREEN_SHARE: "RTC_TOGGLE_SCREEN_SHARE",
  RTC_SET_VIRTUAL_BACKGROUND: "RTC_SET_VIRTUAL_BACKGROUND",
  RTC_TURN_ON_VIDEO_FILE_SHARE: "RTC_TURN_ON_VIDEO_FILE_SHARE",
  RTC_TURN_OFF_VIDEO_FILE_SHARE: "RTC_TURN_OFF_VIDEO_FILE_SHARE",
  RTC_CHANGE_WEBCAM_DEVICE_ID: "RTC_CHANGE_WEBCAM_DEVICE_ID",
  RTC_CHANGE_MIC_DEVICE_ID: "RTC_CHANGE_MIC_DEVICE_ID",

  RTC_SUBSCRIBE_REMOTE_WEBCAM_STREAM: "RTC_SUBSCRIBE_REMOTE_WEBCAM_STREAM",
  RTC_SUBSCRIBE_REMOTE_MIC_STREAM: "RTC_SUBSCRIBE_REMOTE_MIC_STREAM",
  RTC_SUBSCRIBE_REMOTE_SCREEN_SHARE_VIDEO_STREAM:
    "RTC_SUBSCRIBE_REMOTE_SCREEN_SHARE_VIDEO_STREAM",
  RTC_SUBSCRIBE_REMOTE_SCREEN_SHARE_AUDIO_STREAM:
    "RTC_SUBSCRIBE_REMOTE_SCREEN_SHARE_AUDIO_STREAM",
  RTC_UNSUBSCRIBE_REMOTE_WEBCAM_STREAM: "RTC_UNSUBSCRIBE_REMOTE_WEBCAM_STREAM",
  RTC_UNSUBSCRIBE_REMOTE_MIC_STREAM: "RTC_UNSUBSCRIBE_REMOTE_MIC_STREAM",
  RTC_UNSUBSCRIBE_REMOTE_SCREEN_SHARE_VIDEO_STREAM:
    "RTC_UNSUBSCRIBE_REMOTE_SCREEN_SHARE_VIDEO_STREAM",
  RTC_UNSUBSCRIBE_REMOTE_SCREEN_SHARE_AUDIO_STREAM:
    "RTC_UNSUBSCRIBE_REMOTE_SCREEN_SHARE_AUDIO_STREAM",
  RTC_SET_REMOTE_WEBCAM_QUALITY: "RTC_SET_REMOTE_WEBCAM_QUALITY",
  RTC_SET_REMOTE_SCREEN_SHARE_VIDEO_QUALITY:
    "RTC_SET_REMOTE_SCREEN_SHARE_VIDEO_QUALITY",
  RTC_ENABLE_INPUT_FILE_VIDEO_SHARE_STREAM:
    "RTC_ENABLE_INPUT_FILE_VIDEO_SHARE_STREAM",
  RTC_DISABLE_INPUT_FILE_VIDEO_SHARE_STREAM:
    "RTC_DISABLE_INPUT_FILE_VIDEO_SHARE_STREAM",
  RTC_SUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_STREAM:
    "RTC_SUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_STREAM",
  RTC_UNSUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_STREAM:
    "RTC_UNSUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_STREAM",
  RTC_SUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_AUDIO_STREAM:
    "RTC_SUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_AUDIO_STREAM",
  RTC_UNSUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_AUDIO_STREAM:
    "RTC_UNSUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_AUDIO_STREAM",
  RTC_SET_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_QUALITY:
    "RTC_SET_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_QUALITY",
  RTC_TURN_OFF_MEDIA_AND_PUBLICATIONS: "RTC_TURN_OFF_MEDIA_AND_PUBLICATIONS",
  RTC_TURN_ON_EXTRA_WEBCAM: "RTC_TURN_ON_EXTRA_WEBCAM",
  RTC_TURN_OFF_EXTRA_WEBCAM: "RTC_TURN_OFF_EXTRA_WEBCAM",
  RTC_EXTRA_WEBCAM_TURNED_OFF: "RTC_EXTRA_WEBCAM_TURNED_OFF",
  RTC_CHANGE_EXTRA_WEBCAM_DEVICE_ID: "RTC_CHANGE_EXTRA_WEBCAM_DEVICE_ID",
  RTC_SUBSCRIBE_REMOTE_EXTRA_WEBCAM_VIDEO_STREAM:
    "RTC_SUBSCRIBE_REMOTE_EXTRA_WEBCAM_VIDEO_STREAM",
  RTC_UNSUBSCRIBE_REMOTE_EXTRA_WEBCAM_VIDEO_STREAM:
    "RTC_UNSUBSCRIBE_REMOTE_EXTRA_WEBCAM_VIDEO_STREAM",
  RTC_SET_REMOTE_EXTRA_WEBCAM_VIDEO_QUALITY:
    "RTC_SET_REMOTE_EXTRA_WEBCAM_VIDEO_QUALITY",
  RTC_TURN_ON_PARTICIPANT_TRANSLATED_AUDIO_STREAM:
    "RTC_TURN_ON_PARTICIPANT_TRANSLATED_AUDIO_STREAM",
  RTC_TURN_OFF_PARTICIPANT_ALL_TRANSLATED_AUDIO_STREAMS:
    "RTC_TURN_OFF_PARTICIPANT_ALL_TRANSLATED_AUDIO_STREAMS",

  RTC_INPUT_FILE_VIDEO_SHARE_STREAM_ENABLED: (id: string) =>
    `RTC_INPUT_FILE_VIDEO_SHARE_STREAM_ENABLED_${id}`,

  RTC_INPUT_FILE_VIDEO_SHARE_STREAM_CURRENT_TIME_CHANGED: (id: string) =>
    `RTC_INPUT_FILE_VIDEO_SHARE_STREAM_CURRENT_TIME_CHANGED_${id}`,

  RTC_INPUT_FILE_VIDEO_SHARE_STREAM_SEEK_TO: (id: string) =>
    `RTC_INPUT_FILE_VIDEO_SHARE_STREAM_SEEK_TO_${id}`,

  REMOVE_FROM_MAIN_VIEW_STREAMS: "REMOVE_FROM_MAIN_VIEW_STREAMS",
};
