import React, { useCallback, useEffect, useRef } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useIsOldest from "../../hooks/appState/useIsOldest";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const volumeLevelChangeThreshold = 3000;
const calculationInterval = 1000;

const AllParticipantsMicVolumeLevelListner = () => {
  const { participantIds } = useParticipantIdsAndCount();

  const { publish: activeParticipantPublish } = useAppSingalingPublish(
    appPubSubTopics.ACTIVE_PARTICIPANT
  );

  const allParticipantsMicVolumeLevels = useRef<
    {
      micVolumeLevel: number;
      participantId: string;
      timestamp: number;
    }[]
  >([]);

  const activeSpeakerParticipantIdRef = useRef<string | null>();

  const participantIdsRef = useRef(participantIds);
  // const activeParticipantPublishRef = useRef<videosdkPubsubPublishType>(
  //   activeParticipantPublish
  // );

  const activeParticipantPublishRef = useRef<appSingalingPublishType>(
    activeParticipantPublish
  );

  useAppSingalingSubscribe(
    appPubSubTopics.ALL_PARTICIPANTS_MIC_VOLUME_LEVEL,
    ({ message: receivedMessage, participantId }) => {
      const message = safeParseJson(receivedMessage);

      const currentTimestamp = new Date().getTime();

      const { micVolumeLevel } = message;

      const _allParticipantsMicVolumeLevels: {
        micVolumeLevel: number;
        participantId: string;
        timestamp: number;
      }[] = [...allParticipantsMicVolumeLevels.current].filter(
        ({ participantId: _participantId }) => participantId !== _participantId
      );

      if (micVolumeLevel) {
        _allParticipantsMicVolumeLevels.unshift({
          micVolumeLevel,
          participantId,
          timestamp: currentTimestamp,
        });
      }

      allParticipantsMicVolumeLevels.current = [
        ..._allParticipantsMicVolumeLevels,
      ].sort(({ timestamp: a }, { timestamp: b }) =>
        a > b ? -1 : a < b ? 1 : 0
      );

      appEventEmitter.emit(
        appEventEmitterEvents.PARTICIPANT_MIC_AUDIO_LEVEL(participantId),
        { micAudioLevel: micVolumeLevel }
      );
    }
  );

  const { getIsOldestParticipant } = useIsOldest();

  const onActiveSpeakerParticipantIdChanged = ({
    activeSpeakerParticipantId,
  }: {
    activeSpeakerParticipantId: string | null;
  }) => {
    if (activeSpeakerParticipantIdRef.current === activeSpeakerParticipantId) {
      return;
    }

    //

    activeSpeakerParticipantIdRef.current = activeSpeakerParticipantId;

    const activeParticipantPublish = activeParticipantPublishRef.current;

    activeParticipantPublish(
      JSON.stringify({ participantId: activeSpeakerParticipantId })
    );
  };

  const selectTopParticipantUsingMicVolumeLevel = ({
    participants,
  }: {
    participants: {
      participantId: string;
      micVolumeLevel: number;
      timestamp: number;
    }[];
  }) => {
    if (participants.length === 0) {
      return { participantId: null };
    } else if (participants.length === 1) {
      const selectedParticipantId = participants[0].participantId;

      return { participantId: selectedParticipantId };
    } else {
      const participantsVolumeLevelSorted = participants.sort(
        ({ micVolumeLevel: a }, { micVolumeLevel: b }) =>
          a > b ? -1 : a < b ? 1 : 0
      );

      const selectedParticipantId =
        participantsVolumeLevelSorted[0].participantId;

      return { participantId: selectedParticipantId };
    }
  };

  const calculateActiveSpeaker = useCallback(() => {
    const currentTimestamp = new Date().getTime();

    const _allParticipantsMicVolumeLevels = [
      ...allParticipantsMicVolumeLevels.current,
    ];

    const participantIds = participantIdsRef.current;

    if (
      activeSpeakerParticipantIdRef.current &&
      !participantIds.includes(activeSpeakerParticipantIdRef.current)
    ) {
      allParticipantsMicVolumeLevels.current = [
        ...allParticipantsMicVolumeLevels.current,
      ].filter(
        ({ participantId: _participantId }) =>
          activeSpeakerParticipantIdRef.current !== _participantId
      );

      onActiveSpeakerParticipantIdChanged({ activeSpeakerParticipantId: null });
    }

    //

    const filteredParticipantsMicVolumeLevels =
      _allParticipantsMicVolumeLevels.filter(
        ({ timestamp, participantId }) =>
          currentTimestamp - timestamp < volumeLevelChangeThreshold &&
          !!participantIds.includes(participantId)
      );

    if (filteredParticipantsMicVolumeLevels.length === 0) {
      onActiveSpeakerParticipantIdChanged({ activeSpeakerParticipantId: null });

      return;
    }

    //

    const { participantId: quarterThresholdSelectedParticipantId } =
      selectTopParticipantUsingMicVolumeLevel({
        participants: filteredParticipantsMicVolumeLevels.filter(
          ({ timestamp }) =>
            currentTimestamp - timestamp < volumeLevelChangeThreshold / 4
        ),
      });

    if (quarterThresholdSelectedParticipantId) {
      onActiveSpeakerParticipantIdChanged({
        activeSpeakerParticipantId: quarterThresholdSelectedParticipantId,
      });

      return;
    }

    //

    const { participantId: halfThresholdSelectedParticipantId } =
      selectTopParticipantUsingMicVolumeLevel({
        participants: filteredParticipantsMicVolumeLevels.filter(
          ({ timestamp }) =>
            currentTimestamp - timestamp < volumeLevelChangeThreshold / 2
        ),
      });

    if (halfThresholdSelectedParticipantId) {
      onActiveSpeakerParticipantIdChanged({
        activeSpeakerParticipantId: halfThresholdSelectedParticipantId,
      });

      return;
    }

    //

    const { participantId: thresholdSelectedParticipantId } =
      selectTopParticipantUsingMicVolumeLevel({
        participants: filteredParticipantsMicVolumeLevels,
      });

    onActiveSpeakerParticipantIdChanged({
      activeSpeakerParticipantId: thresholdSelectedParticipantId,
    });

    return;
  }, []);

  const initCalculateActiveSpeakerInterval = useCallback(() => {
    setInterval(() => {
      const { isOldestParticipant } = getIsOldestParticipant();

      if (isOldestParticipant) {
        calculateActiveSpeaker();
      }
    }, calculationInterval);
  }, []);

  useEffect(() => {
    initCalculateActiveSpeakerInterval();
  }, []);

  useEffect(() => {
    participantIdsRef.current = participantIds;
  }, [participantIds]);

  useEffect(() => {
    activeParticipantPublishRef.current = activeParticipantPublish;
  }, [activeParticipantPublish]);

  return <React.Fragment />;
};

export default AllParticipantsMicVolumeLevelListner;
