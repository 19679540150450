// @ts-nocheck

import React, { useEffect, useMemo } from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import { MdMovie } from "react-icons/md";
import { appModes } from "../../utils/constants";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";

const InQueueRtmpInShareStreamContainer = () => {
  const { thirdPartyRtmpData } = useAppConfigContext();

  const { mainViewSelectedStreams, inQueueItemContainerWidth, appMode } =
    useAppContext();

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const { isInMainView } = useMemo(() => {
    const streamId = generateStreamId({
      participantId: thirdPartyRtmpData?.playbackId as string,
      type: streamTypes.SHARE,
      rtmpInId: thirdPartyRtmpData?.playbackId as string,
      mode: streamModes.RTMP_IN,
    });

    return { isInMainView: !!mainViewSelectedStreams.get(streamId) };
  }, [mainViewSelectedStreams, thirdPartyRtmpData]);

  useEffect(() => {
    const videoTagId = `RtmpInStreamPlayer-videotag-${thirdPartyRtmpData?.playbackId}`;
    const video = document.getElementById(videoTagId) as StudioHTMLVideoElement;

    if (video?.captureStream) {
      const mediaStream = video.captureStream();

      const inQueueVideoTagId = `InQueueRtmpInShareStreamContainer-videotag-${thirdPartyRtmpData?.playbackId}`;

      const inQueueVideo = document.getElementById(
        inQueueVideoTagId
      ) as StudioHTMLVideoElement;
      inQueueVideo.srcObject = mediaStream;
    }
  }, [thirdPartyRtmpData]);

  return (
    <React.Fragment>
      <div style={{ width: inQueueItemContainerWidth }}>
        <div
          style={{
            height: (inQueueItemContainerWidth * 9) / 16,
            width: inQueueItemContainerWidth,
          }}
          className="relative video-cover bg-custom-blue-50"
        >
          <div
            id={`InQueueRtmpInShareStreamContainer-${thirdPartyRtmpData?.playbackId}`}
            className="h-full w-full rounded-t-md overflow-hidden relative"
          >
            <video
              id={`InQueueRtmpInShareStreamContainer-videotag-${thirdPartyRtmpData?.playbackId}`}
              autoPlay
              playsInline
              muted
            />
          </div>

          <div className="top-0 left-0 right-0 bottom-0 absolute flex justify-between">
            {appMode === appModes.HOST ? (
              <div className="p-3 flex flex-1 hover:opacity-100 opacity-0 items-end justify-center">
                <button
                  className="btn btn-primary text-white btn-active"
                  onClick={() => {
                    if (isInMainView) {
                      removeFromMainViewStreams({
                        participantId: thirdPartyRtmpData?.playbackId as string,
                        type: streamTypes.SHARE,
                        rtmpInId: thirdPartyRtmpData?.playbackId as string,
                        mode: streamModes.RTMP_IN,
                      });
                    } else {
                      addToMainViewStreams({
                        participantId: thirdPartyRtmpData?.playbackId as string,
                        type: streamTypes.SHARE,
                        rtmpInId: thirdPartyRtmpData?.playbackId as string,
                        mode: streamModes.RTMP_IN,
                      });
                    }
                  }}
                >
                  {isInMainView ? "Remove" : "Add to stream"}
                </button>
              </div>
            ) : (
              <React.Fragment />
            )}

            {/* <div className="absolute top-2 right-2 flex">
              <ItemMenuContainer
                {...{
                  setChangeNameModalOpen,
                  isLocal,
                  extraWebcamId,
                  participantId,
                }}
              />
            </div> */}
          </div>
        </div>

        <div
          className={`flex items-center p-0.5 ${
            isInMainView
              ? "bg-primary text-white fill-white"
              : "bg-white fill-gray-800 text-gray-800"
          } rounded-b-md overflow-hidden gap-0.5`}
        >
          <div className="w-6 flex items-center justify-center">
            <MdMovie size={16} />
          </div>
          <p className="truncate text-ellipsis text-sm flex-1">RTMP Source</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InQueueRtmpInShareStreamContainer;
