import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { defaultAvatarImage } from "../../static/images";
import { Dialog, Transition } from "@headlessui/react";
import { MdAdd, MdRemove } from "react-icons/md";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";
import useGetParticipantName from "../../hooks/appState/useGetParticipantName";

const ParticipantListItem = ({
  selectedList,
  name,
  remove,
  add,
}: {
  selectedList?: boolean;
  name?: string;
  remove?: () => void;
  add?: () => void;
}) => {
  return (
    <div className="flex items-center p-1 hover:bg-gray-100">
      <div
        style={{ height: 40, width: 40 }}
        className={`h-full aspect-square rounded-md bg-custom-blue-100 text-white flex items-center justify-center font-bold text-white`}
      >
        {name?.length ? String(name[0]).toUpperCase() : "U"}
      </div>

      <div
        style={{ marginLeft: 8, width: `calc(100% - 40px - 8px - 8px )` }}
        className={`truncate text-ellipsis text-primary font-medium `}
      >
        {name}
      </div>

      <div
        style={{ marginLeft: 8, height: 40, width: 40 }}
        onClick={() => {
          if (selectedList) {
            if (remove) {
              remove();
            }
          } else {
            if (add) {
              add();
            }
          }
        }}
        className="cursor-pointer flex items-center justify-center "
      >
        {selectedList ? <MdRemove size={20} /> : <MdAdd size={20} />}
      </div>
    </div>
  );
};

const PrivateChatGroupInfoEditor = ({
  defaultName,
  defaulSelectedParticipantIds,
  isCreating,
  handleCancle,
  handleSave,
  editingInProgress,
}: {
  defaultName: string | null;
  defaulSelectedParticipantIds: string[];
  isCreating?: boolean;
  handleCancle: () => void;
  handleSave: (t: {
    name: string;
    participantIds: string[];
    removedParticipantIds: string[];
    newParticipantIds: string[];
    isRenamed: boolean;
  }) => void;
  editingInProgress?: boolean;
}) => {
  const [name, setName] = useState(defaultName || "");
  const [selectedParticipantIds, setSelectedParticipantIds] = useState(
    defaulSelectedParticipantIds || []
  );
  const [searchQuery, setSearchQuery] = useState("");

  const { modifiedParticipantsAvatars, ghostParticipantIds } = useAppContext();

  const selectedParticipantIdsRef = useRef(selectedParticipantIds);

  useEffect(() => {
    selectedParticipantIdsRef.current = selectedParticipantIds;
  }, [selectedParticipantIds]);

  const { getParticipantName } = useGetParticipantName();

  const { participantIds } = useParticipantIdsAndCount();

  const { selectedParticipants, unSelectedParticipants } = useMemo(() => {
    const participants = participantIds
      .map((participantId) => {
        return {
          participantId,
          name: getParticipantName(participantId),
        };
      })
      .filter(
        ({ participantId }) => !ghostParticipantIds.includes(participantId)
      )
      .map((participant) => {
        return {
          ...participant,
          isSelected: selectedParticipantIds.includes(
            participant.participantId
          ),
          avatarRemoteUrl:
            modifiedParticipantsAvatars.find(
              ({ participantId: _participantId }) =>
                _participantId === participant.participantId
            )?.avatarRemoteUrl || defaultAvatarImage,
        };
      });

    const selectedParticipants = participants.filter(
      ({ isSelected }) => isSelected
    );

    const unSelectedParticipants = participants.filter(
      ({ name, isSelected }) => {
        const pattern = new RegExp(searchQuery, "i");

        const regexActive = name.match(pattern);

        return !!regexActive && !isSelected;
      }
    );

    return { selectedParticipants, unSelectedParticipants };
  }, [
    participantIds,
    modifiedParticipantsAvatars,
    searchQuery,
    ghostParticipantIds,
    selectedParticipantIds,
    getParticipantName,
  ]);

  const selectParticipantId = (participantId: string) => {
    setSelectedParticipantIds((s) => [...s, participantId]);
  };

  const unselectParticipantId = (participantId: string) => {
    const index = selectedParticipantIdsRef.current.findIndex(
      (id) => id === participantId
    );

    if (index !== -1) {
      setSelectedParticipantIds((s) => {
        const _s = [...s];

        _s.splice(index, 1);

        return [..._s];
      });
    }
  };

  return (
    <Transition appear show as={Fragment}>
      <Dialog
        style={{ zIndex: 99999 }}
        as="div"
        className="relative"
        onClose={() => {
          handleCancle();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all border-2 border-custom-blue-900">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <div className="flex items-center justify-center">
                    <p className="flex flex-1">
                      {isCreating
                        ? "Create a private Chat Group"
                        : "Edit private Chat Group"}
                    </p>
                  </div>
                </Dialog.Title>

                <div className={"mt-6"}>
                  <div className="form-control w-full max-w-xs">
                    <label className="label">
                      <span className="label-text">Group name</span>
                    </label>
                    <input
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      type="text"
                      placeholder="eg: Internal Discussion"
                      className="input input-bordered w-full max-w-xs"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-1 mt-3 divide-x p-2">
                    <div>
                      <div className="h-20 m-2 flex items-end">
                        Group members ({selectedParticipantIds?.length || 0})
                      </div>
                      <div className="overflow-y-scroll mt-2 h-72">
                        {selectedParticipants?.length ? (
                          selectedParticipants.map((participant) => {
                            return (
                              <ParticipantListItem
                                {...{
                                  remove: () => {
                                    unselectParticipantId(
                                      participant.participantId
                                    );
                                  },
                                  selectedList: true,
                                  ...participant,
                                  key: `selected-participant-pgc-${participant.participantId}`,
                                }}
                              />
                            );
                          })
                        ) : (
                          <div className="p-3 text-center flex h-full text-gray-400 flex-1 items-center justify-center">
                            No attendees added yet
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <div className="h-20 m-2">
                        <div className="form-control w-full max-w-xs">
                          <label className="label">
                            <span className="label-text">
                              Search Participants
                            </span>
                          </label>
                          <input
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                            }}
                            type="text"
                            placeholder="eg: john"
                            className="input input-sm input-bordered w-full max-w-xs"
                          />
                        </div>
                      </div>

                      <div className="overflow-y-scroll mt-2 h-72">
                        {unSelectedParticipants?.length ? (
                          unSelectedParticipants.map((participant) => {
                            return (
                              <ParticipantListItem
                                {...{
                                  add: () => {
                                    selectParticipantId(
                                      participant.participantId
                                    );
                                  },
                                  unselectedList: true,
                                  ...participant,
                                  key: `unselected-participant-pgc-${participant.participantId}`,
                                }}
                              />
                            );
                          })
                        ) : (
                          <div className="p-3 text-center flex h-full text-gray-400 flex-1 items-center justify-center">
                            No participants found, please remove search filter.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex gap-3">
                  <button
                    disabled={
                      !(
                        selectedParticipantIds?.length > 1 &&
                        name &&
                        !editingInProgress
                      )
                    }
                    type="button"
                    className="btn btn-primary text-white normal-case btn-outline btn-sm"
                    onClick={() => {
                      const removedParticipantIds =
                        defaulSelectedParticipantIds.filter(
                          (id) => !selectedParticipantIds.includes(id)
                        );

                      const newParticipantIds = selectedParticipantIds.filter(
                        (id) => !defaulSelectedParticipantIds.includes(id)
                      );

                      handleSave({
                        name,
                        participantIds: selectedParticipantIds,
                        removedParticipantIds,
                        newParticipantIds,
                        isRenamed: name !== defaultName,
                      });
                    }}
                  >
                    {isCreating
                      ? "Create"
                      : editingInProgress
                      ? "Saving..."
                      : "Save"}
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary text-white normal-case btn-outline btn-error btn-sm"
                    onClick={() => {
                      handleCancle();
                    }}
                  >
                    {"Cancel"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PrivateChatGroupInfoEditor;
