import React, { useEffect } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import getAllBannerFolders from "../../apis/apps/banners/get-all-banner-folders";
import getAllBanners from "../../apis/apps/banners/get-all-banners";

import useBannerFolders from "../../hooks/banners/useBannerFolders";
import {
  getMSQuickstartBannerFolders,
  removeMSQuickstartBannerFolders,
} from "../../apis/quickstart";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const bannerFoldersActions = {
  CREATE_FOLDER: "CREATE_FOLDER",
  DELETE_FOLDER: "DELETE_FOLDER",
  UPDATE_FOLDER: "UPDATE_FOLDER",
};

const BannerFoldersListner = () => {
  const { setBannerFolders, setBanners } = useAppContext();
  const { userId } = useAppConfigContext();

  const { deleteBannerFolder } = useBannerFolders();

  const _handleCreateFolder = ({
    payload,
  }: {
    payload: { folder: { id: number; name: string } };
  }) => {
    setBannerFolders((s) => [
      ...s,
      { ...payload.folder, id: parseInt(`${payload.folder.id}`) },
    ]);
  };

  const _handleDeleteFolder = ({
    payload,
  }: {
    payload: { folderId: number };
  }) => {
    setBannerFolders((s) => s.filter(({ id }) => id != payload.folderId));

    setBanners((s) =>
      s.filter(({ folderId: _folderId }) => _folderId != payload.folderId)
    );
  };

  const _handleUpdateFolder = ({
    payload,
  }: {
    payload: { folder: { id: number; name: string } };
  }) => {
    setBannerFolders((s) => {
      const bannerFolders = [...s];

      const index = bannerFolders.findIndex(
        ({ id }) => parseInt(`${id}`) === parseInt(`${payload.folder.id}`)
      );

      if (index !== -1) {
        bannerFolders[index] = {
          ...payload.folder,
          id: parseInt(`${payload.folder.id}`),
        };
      }

      return bannerFolders;
    });
  };

  useAppSingalingSubscribe(
    appPubSubTopics.BANNER_FOLDERS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case bannerFoldersActions.CREATE_FOLDER:
          _handleCreateFolder({
            payload: message.payload,
          });
          break;

        case bannerFoldersActions.DELETE_FOLDER:
          _handleDeleteFolder({
            payload: message.payload,
          });
          break;

        case bannerFoldersActions.UPDATE_FOLDER:
          _handleUpdateFolder({
            payload: message.payload,
          });
          break;

        default:
      }
    }
  );

  const _handleFetchOldBanners = async () => {
    const { success: successAllBannerFolder, data: dataAllBannerFolder } =
      await getAllBannerFolders({ userId });

    let bannerFolders = dataAllBannerFolder;

    if (successAllBannerFolder && bannerFolders) {
      const msQuickstartBannerFolders = await getMSQuickstartBannerFolders({
        userId,
      });

      if (msQuickstartBannerFolders?.length) {
        bannerFolders = bannerFolders.filter(
          ({ id }) => !msQuickstartBannerFolders.includes(`${id}`)
        );

        await removeMSQuickstartBannerFolders({ userId });

        for (let index = 0; index < msQuickstartBannerFolders.length; index++) {
          const msQuickstartBannerFolderId = msQuickstartBannerFolders[index];

          await deleteBannerFolder({ folderId: msQuickstartBannerFolderId });
        }
      }

      const { success: successAllBanners, data: dataAllBanners } =
        await getAllBanners({ userId });

      if (successAllBanners) {
        setBannerFolders(bannerFolders);
        setBanners(dataAllBanners);
      }
    }
  };

  useEffect(() => {
    _handleFetchOldBanners();
  }, []);

  return <React.Fragment />;
};

export default BannerFoldersListner;
