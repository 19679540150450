import React, { useEffect, useMemo, useRef, useState } from "react";
// import { usePubSub } from "@videosdk.live/react-sdk";
import MainViewContainer from "./mainViewContainer/MainViewContainer";
import HostControlsContainer from "./hostControlsContainer/HostControlsContainer";
import BottomControlsContainer from "./bottomControlsContainer/BottomControlsContainer";
import { useAppContext } from "../contexts/appContextDef";
import SpeakerControlsContainer from "./speakerControlsContainer/SpeakerControlsContainer";
import MainViewLayoutsControlContainer from "./mainViewLayoutsControlContainer/MainViewLayoutsControlContainer";
import Topbar from "./topbar/Topbar";
import SideContainer from "./sideContainer/SideContainer";
// import AppEventsAndPubSubListnersContainer from "../listners/AppEventsAndPubSubListnersContainer";
import { JOIN_AUDIO } from "../static/audios";
import { useAppConfigContext } from "../contexts/appConfigDef";
import InterpreterBottomControlsContainer from "./interpreterBottomControlsContainer/InterpreterBottomControlsContainer";
import { appPubSubTopics } from "../utils/pubSubTopics";
import { raiseHandActions } from "../listners/appState/RaiseHandListner";
// import AppRemoteRtcStreamsListner from "./appRemoteRtcStreamsListner/AppRemoteRtcStreamsListner";
import useIsMobilePortrait from "../hooks/responsive/useIsMobilePortrait";
import { useWindowSize } from "@react-hook/window-size";
import useIsMobileLandscape from "../hooks/responsive/useIsMobileLandscape";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../utils/appEventEmitter";
import { AskCameraPermissionModalInMeeting } from "../components/AskCameraPermissionModal";
import { AskMicPermissionModalInMeeting } from "../components/AskMicPermissionModal";
import Joyride from "react-joyride";

// import getActiveVideosdkSessionFromMeetingId from "../apis/videosdk/get-active-videosdk-session-from-meeting-id";
import { appModes } from "../utils/constants";
import useLocalParticipantId from "../hooks/utils/useLocalParticipantId";
import useAppSingalingPublish from "../appSingaling/useAppSingalingPublish";
import Spinner from "../components/Spinner";
import ExtraWebcamSettingsModalContainer from "../components/ExtraWebcamSettingsModalContainer";
import RtmpInStreamPlayer from "./mainViewContainer/RtmpInStreamPlayer";
import RtmpSourceModal from "../components/RtmpSourceModal";
// import { useInterpretationViaAiData } from "../listners/appState/LocalParticipantTranslatedAudioBroadcasterContainer";

const MobilePortraitMeetingContainer = () => {
  const [containerWidth, containerHeight] = useWindowSize();

  const { isInterpreter, isRecorder, meetingMode } = useAppConfigContext();

  const { appMode } = useAppContext();

  //
  //
  //

  const [topbarHeight, setTopbarHeight] = useState(0);
  const [
    mainViewLayoutsControlContainerHeight,
    setMainViewLayoutsControlContainerHeight,
  ] = useState(0);
  const [hostControlsContainerHeight, setHostControlsContainerHeight] =
    useState(0);
  const [bottomControlsContainerHeight, setBottomControlsContainerHeight] =
    useState(0);
  const [
    interpreterBottomControlsContainerHeight,
    setInterpreterBottomControlsContainerHeight,
  ] = useState(0);

  //
  //
  //

  const shouldRenderTopbar = useMemo(
    () => appMode === appModes.HOST || appMode === appModes.SPEAKER,
    [appMode]
  );

  const shouldRenderMainViewLayoutsControlContainer = useMemo(
    () =>
      appMode === appModes.HOST &&
      (meetingMode === "full" || meetingMode === "minimal"),
    [appMode, meetingMode]
  );

  const shouldRenderBottomControlsContainer = useMemo(
    () => appMode === appModes.HOST || appMode === appModes.SPEAKER,
    [appMode]
  );

  const shouldRenderInterpreterBottomControlsContainer = useMemo(
    () => !!isInterpreter,
    [isInterpreter]
  );

  const mainViewContainerHeight = useMemo(
    () =>
      (shouldRenderTopbar ? topbarHeight === 0 : false) ||
      (shouldRenderMainViewLayoutsControlContainer
        ? mainViewLayoutsControlContainerHeight === 0
        : false) ||
      (shouldRenderBottomControlsContainer
        ? bottomControlsContainerHeight === 0
        : false) ||
      (shouldRenderInterpreterBottomControlsContainer
        ? interpreterBottomControlsContainerHeight === 0
        : false) ||
      hostControlsContainerHeight === 0
        ? 0
        : containerHeight -
          (topbarHeight +
            mainViewLayoutsControlContainerHeight +
            interpreterBottomControlsContainerHeight +
            hostControlsContainerHeight +
            2 * bottomControlsContainerHeight),
    [
      containerHeight,
      topbarHeight,
      mainViewLayoutsControlContainerHeight,

      hostControlsContainerHeight,
      bottomControlsContainerHeight,
      interpreterBottomControlsContainerHeight,

      shouldRenderTopbar,
      shouldRenderMainViewLayoutsControlContainer,
      shouldRenderBottomControlsContainer,
      shouldRenderInterpreterBottomControlsContainer,
    ]
  );

  return (
    <React.Fragment>
      <span>
        {shouldRenderTopbar ? (
          <Topbar
            onContainerHeight={(topbarHeight) => {
              setTopbarHeight(topbarHeight);
            }}
          />
        ) : null}
      </span>

      <div className="flex flex-1 flex-col">
        <div className={`aspect-video flex ${isRecorder ? "" : "mt-1"}`}>
          <MainViewContainer
            {...{
              height: mainViewContainerHeight,
              width: containerWidth,
            }}
          />
        </div>

        <span>
          {shouldRenderMainViewLayoutsControlContainer && (
            <MainViewLayoutsControlContainer
              onContainerHeight={(mainViewLayoutsControlContainerHeight) => {
                setMainViewLayoutsControlContainerHeight(
                  mainViewLayoutsControlContainerHeight
                );
              }}
            />
          )}
        </span>

        <div style={{ height: 1 }} className="w-full bg-gray-300 my-2"></div>

        {appMode === appModes.HOST ? (
          <HostControlsContainer
            onContainerHeight={(hostControlsContainerHeight) => {
              setHostControlsContainerHeight(hostControlsContainerHeight);
            }}
          />
        ) : appMode === appModes.SPEAKER ? (
          <SpeakerControlsContainer
            onContainerHeight={(speakerControlsContainerHeight) => {
              setHostControlsContainerHeight(speakerControlsContainerHeight);
            }}
          />
        ) : null}
      </div>

      <span>
        {shouldRenderBottomControlsContainer ? (
          <BottomControlsContainer
            onContainerHeight={(bottomControlsContainerHeight) => {
              setBottomControlsContainerHeight(bottomControlsContainerHeight);
            }}
          />
        ) : null}
      </span>

      <span>
        {shouldRenderInterpreterBottomControlsContainer ? (
          <InterpreterBottomControlsContainer
            onContainerHeight={(interpreterBottomControlsContainerHeight) => {
              setInterpreterBottomControlsContainerHeight(
                interpreterBottomControlsContainerHeight
              );
            }}
          />
        ) : null}
      </span>
    </React.Fragment>
  );
};

const MobileLandscapeMeetingContainer = () => {
  const [containerWidth, containerHeight] = useWindowSize();

  const { isInterpreter, isRecorder, meetingMode } = useAppConfigContext();

  const { appMode } = useAppContext();

  //
  //
  //

  const [topbarHeight, setTopbarHeight] = useState(0);
  const [
    mainViewLayoutsControlContainerHeight,
    setMainViewLayoutsControlContainerHeight,
  ] = useState(0);
  const [bottomControlsContainerHeight, setBottomControlsContainerHeight] =
    useState(0);
  const [
    interpreterBottomControlsContainerHeight,
    setInterpreterBottomControlsContainerHeight,
  ] = useState(0);

  //
  //
  //

  //
  const mainViewContainerHeight = useMemo(
    () =>
      containerHeight -
      (topbarHeight +
        mainViewLayoutsControlContainerHeight +
        bottomControlsContainerHeight +
        interpreterBottomControlsContainerHeight),
    [
      containerHeight,
      topbarHeight,
      mainViewLayoutsControlContainerHeight,
      bottomControlsContainerHeight,
      interpreterBottomControlsContainerHeight,
    ]
  );

  const mainViewContainerWidth = useMemo(
    () =>
      containerWidth -
      (appMode === appModes.HOST || appMode === appModes.SPEAKER ? 240 : 0),
    [containerWidth, appMode]
  );

  return (
    <React.Fragment>
      <span>
        {appMode === appModes.HOST || appMode === appModes.SPEAKER ? (
          <Topbar
            onContainerHeight={(topbarHeight: number) => {
              setTopbarHeight(topbarHeight);
            }}
          />
        ) : null}
      </span>

      <div className="flex flex-1">
        <div className={`flex flex-1 flex-col ${isRecorder ? "" : "mmt-1"}`}>
          <MainViewContainer
            {...{
              height: mainViewContainerHeight,
              width: mainViewContainerWidth,
            }}
          />

          <span>
            {appMode === appModes.HOST &&
            (meetingMode === "full" || meetingMode === "minimal") ? (
              <MainViewLayoutsControlContainer
                onContainerHeight={(
                  mainViewLayoutsControlContainerHeight: number
                ) => {
                  setMainViewLayoutsControlContainerHeight(
                    mainViewLayoutsControlContainerHeight
                  );
                }}
              />
            ) : (
              <React.Fragment />
            )}
          </span>

          <span>
            {appMode === appModes.HOST || appMode === appModes.SPEAKER ? (
              <BottomControlsContainer
                onContainerHeight={(bottomControlsContainerHeight: number) => {
                  setBottomControlsContainerHeight(
                    bottomControlsContainerHeight
                  );
                }}
              />
            ) : (
              <React.Fragment />
            )}
          </span>

          <span>
            {isInterpreter ? (
              <InterpreterBottomControlsContainer
                onContainerHeight={(
                  interpreterBottomControlsContainerHeight: number
                ) => {
                  setInterpreterBottomControlsContainerHeight(
                    interpreterBottomControlsContainerHeight
                  );
                }}
              />
            ) : (
              <React.Fragment />
            )}
          </span>
        </div>

        {appMode === appModes.HOST || appMode === appModes.SPEAKER ? (
          <div style={{ width: 240 }} className={"flex flex-col"}>
            {appMode === appModes.HOST ? (
              <HostControlsContainer />
            ) : appMode === appModes.SPEAKER ? (
              <SpeakerControlsContainer />
            ) : (
              <React.Fragment />
            )}
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </React.Fragment>
  );
};

const DesktopMeetingContainer = () => {
  const [containerWidth, containerHeight] = useWindowSize();

  const { isInterpreter, isRecorder, meetingMode } = useAppConfigContext();

  const {
    appMode,
    topBarHeight,
    sidePanelMode,
    lateralSidebarWidth,
    sidePanelWidth,
  } = useAppContext();

  //
  //
  //

  const [topbarHeight, setTopbarHeight] = useState(0);
  const [
    mainViewLayoutsControlContainerHeight,
    setMainViewLayoutsControlContainerHeight,
  ] = useState(0);
  const [hostControlsContainerHeight, setHostControlsContainerHeight] =
    useState(0);
  const [bottomControlsContainerHeight, setBottomControlsContainerHeight] =
    useState(0);
  const [
    interpreterBottomControlsContainerHeight,
    setInterpreterBottomControlsContainerHeight,
  ] = useState(0);

  //
  //
  //

  const mainViewContainerHeight = useMemo(
    () =>
      containerHeight -
      ((appMode === appModes.HOST || appMode === appModes.SPEAKER
        ? topbarHeight
        : 0) +
        (appMode === appModes.HOST
          ? mainViewLayoutsControlContainerHeight
          : 0) +
        (appMode === appModes.HOST || appMode === appModes.SPEAKER
          ? hostControlsContainerHeight + bottomControlsContainerHeight
          : 0) +
        (isInterpreter ? interpreterBottomControlsContainerHeight : 0) +
        (appMode === appModes.HOST || appMode === appModes.SPEAKER ? 8 : 0)),
    [
      appMode,
      // isRecorder,
      containerHeight,
      topbarHeight,
      mainViewLayoutsControlContainerHeight,
      hostControlsContainerHeight,
      bottomControlsContainerHeight,
      interpreterBottomControlsContainerHeight,
      isInterpreter,
    ]
  );

  const mainViewContainerWidth = useMemo(
    () =>
      isRecorder || (appMode !== appModes.HOST && appMode !== appModes.SPEAKER)
        ? containerWidth
        : containerWidth -
          lateralSidebarWidth -
          (sidePanelMode ? sidePanelWidth : 0),
    [
      appMode,
      containerWidth,
      isRecorder,
      lateralSidebarWidth,
      sidePanelWidth,
      sidePanelMode,
    ]
  );

  return (
    <React.Fragment>
      <span>
        {appMode === appModes.HOST || appMode === appModes.SPEAKER ? (
          <Topbar
            onContainerHeight={(topbarHeight) => {
              setTopbarHeight(topbarHeight);
            }}
          />
        ) : null}
      </span>
      <div className="flex flex-1">
        <div
          style={{
            marginTop:
              appMode === appModes.HOST || appMode === appModes.SPEAKER ? 8 : 0,
          }}
          className={`flex flex-1 flex-col`}
        >
          <MainViewContainer
            {...{
              height: mainViewContainerHeight,
              width: mainViewContainerWidth,
            }}
          />
          <span>
            {appMode === appModes.HOST &&
              (meetingMode === "full" || meetingMode === "minimal") && (
                <MainViewLayoutsControlContainer
                  onContainerHeight={(
                    mainViewLayoutsControlContainerHeight
                  ) => {
                    setMainViewLayoutsControlContainerHeight(
                      mainViewLayoutsControlContainerHeight
                    );
                  }}
                />
              )}
          </span>
          <span>
            {appMode === appModes.HOST ? (
              <HostControlsContainer
                onContainerHeight={(hostControlsContainerHeight) => {
                  setHostControlsContainerHeight(hostControlsContainerHeight);
                }}
              />
            ) : appMode === appModes.SPEAKER ? (
              <SpeakerControlsContainer
                onContainerHeight={(speakerControlsContainerHeight) => {
                  setHostControlsContainerHeight(
                    speakerControlsContainerHeight
                  );
                }}
              />
            ) : null}
          </span>
          <span>
            {appMode === appModes.HOST || appMode === appModes.SPEAKER ? (
              <BottomControlsContainer
                onContainerHeight={(bottomControlsContainerHeight) => {
                  setBottomControlsContainerHeight(
                    bottomControlsContainerHeight
                  );
                }}
              />
            ) : null}
          </span>
          <span>
            {isInterpreter ? (
              <InterpreterBottomControlsContainer
                onContainerHeight={(
                  interpreterBottomControlsContainerHeight
                ) => {
                  setInterpreterBottomControlsContainerHeight(
                    interpreterBottomControlsContainerHeight
                  );
                }}
              />
            ) : null}
          </span>
        </div>
        <span>
          {appMode === appModes.HOST || appMode === appModes.SPEAKER ? (
            <SideContainer containerHeight={containerHeight - topBarHeight} />
          ) : null}
        </span>
      </div>
    </React.Fragment>
  );
};

const MeetingContainer = () => {
  const {
    isInterpreter,
    isRecorder,
    //  meetingId, token
  } = useAppConfigContext();

  const {
    appMode,
    joyrideProps,
    // setActiveVideosdkSessionId,
    ghostParticipantIds,
    connectionState,
  } = useAppContext();

  // const { interpretationViaAiData } = useInterpretationViaAiData();

  // const [joinedTimeout, setJoinedTimeout] = useState(false);

  // const { publish: raiseHandPublish } = usePubSub(appPubSubTopics.RAISE_HAND);
  const { publish: raiseHandPublish } = useAppSingalingPublish(
    appPubSubTopics.RAISE_HAND
  );

  // const joinedTimeoutRef = useRef(joinedTimeout);
  const interpreterRef = useRef(isInterpreter);
  const isRecorderRef = useRef(isRecorder);
  // const raiseHandPublishRef =
  //   useRef<videosdkPubsubPublishType>(raiseHandPublish);

  const raiseHandPublishRef = useRef<appSingalingPublishType>(raiseHandPublish);

  const ghostParticipantIdsRef = useRef(ghostParticipantIds);

  // useEffect(() => {
  //   joinedTimeoutRef.current = joinedTimeout;
  // }, [joinedTimeout]);
  useEffect(() => {
    interpreterRef.current = isInterpreter;
  }, [isInterpreter]);
  useEffect(() => {
    isRecorderRef.current = isRecorder;
  }, [isRecorder]);
  useEffect(() => {
    raiseHandPublishRef.current = raiseHandPublish;
  }, [raiseHandPublish]);
  useEffect(() => {
    ghostParticipantIdsRef.current = ghostParticipantIds;
  }, [ghostParticipantIds]);

  const localParticipantIdRef = useRef<string>();

  const _handleOnSignalingParticipantJoined = async () => {
    if (
      appMode === appModes.HOST
      // && joinedTimeoutRef.current
    ) {
      const joinAudio = new Audio(JOIN_AUDIO);

      const playPromise = joinAudio.play();

      if (playPromise !== undefined) {
        playPromise.then(() => {}).catch(() => {});
      }
    }

    appEventEmitter.emit(
      appEventEmitterEvents.HIDE_HOST_IN_QUEUE_POPUP_MESSAGE
    );
  };

  const _handleOnInterpreterJoined = async () => {
    if (
      appMode === appModes.HOST
      // && joinedTimeoutRef.current
    ) {
      const joinAudio = new Audio(JOIN_AUDIO);

      const playPromise = joinAudio.play();

      if (playPromise !== undefined) {
        playPromise.then(() => {}).catch(() => {});
      }
    }
  };

  const _handleOnMeetingJoined = () => {
    // getActiveVideosdkSessionFromMeetingId({
    //   videosdkMeetingId: meetingId,
    //   token,
    // }).then((e) => {
    //   if (e.activeVideosdkSessionId) {
    //     setActiveVideosdkSessionId(e.activeVideosdkSessionId);
    //   }
    // });

    raiseHandPublishRef.current(
      JSON.stringify({
        action: raiseHandActions.DISABLE,
        payload: {
          participantId: localParticipantIdRef.current,
        },
      })
      // { persist: true }
    );
  };

  const [containerWidth, containerHeight] = useWindowSize();

  const { localParticipantId } = useLocalParticipantId();

  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);

  useEffect(() => {
    // setTimeout(() => {
    //   setJoinedTimeout(true);
    _handleOnMeetingJoined();
    // }, 1000);
  }, []);

  const { isMobilePortrait } = useIsMobilePortrait();
  const { isMobileLandscape } = useIsMobileLandscape();

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.SIGNALING_PARTICIPANT_JOINED,
      _handleOnSignalingParticipantJoined
    );
    appEventEmitter.on(
      appEventEmitterEvents.INTERPRETER_PARTICIPANT_JOINED,
      _handleOnInterpreterJoined
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.SIGNALING_PARTICIPANT_JOINED,
        _handleOnSignalingParticipantJoined
      );

      appEventEmitter.off(
        appEventEmitterEvents.INTERPRETER_PARTICIPANT_JOINED,
        _handleOnInterpreterJoined
      );
    };
  }, []);

  return (
    <React.Fragment>
      <div
        style={{ width: containerWidth, height: containerHeight }}
        className="flex flex-1 flex-col bg-white"
      >
        {isMobilePortrait ? (
          <MobilePortraitMeetingContainer />
        ) : isMobileLandscape ? (
          <MobileLandscapeMeetingContainer />
        ) : (
          <DesktopMeetingContainer />
        )}
      </div>

      <AskMicPermissionModalInMeeting />

      <AskCameraPermissionModalInMeeting />

      <ExtraWebcamSettingsModalContainer />

      <RtmpSourceModal />

      {joyrideProps.enabled ? (
        <Joyride
          {...joyrideProps.props}
          callback={(data) =>
            appEventEmitter.emit(appEventEmitterEvents.JOYRIDE_CALLBACK, data)
          }
        />
      ) : (
        <React.Fragment />
      )}

      {connectionState === "reconnecting" ? (
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-60 flex items-center justify-center text-white gap-3">
          <Spinner h={"h-8"} w={"w-8"} />
          <p className="text-2xl font-medium">
            You lost your network connection. Trying to reconnect.
          </p>
        </div>
      ) : (
        <React.Fragment />
      )}

      <RtmpInStreamPlayer />
    </React.Fragment>
  );
};

export default MeetingContainer;
