import React from "react";
import { askToLeaveTopicByParticipantId } from "../../utils/pubSubTopics";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import { safeParseJson } from "../../utils/safeParseJson";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";

const AskToLeaveListner = () => {
  const { localParticipantId } = useLocalParticipantId();

  useAppSingalingSubscribe(
    askToLeaveTopicByParticipantId(localParticipantId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { leave } = message;

      if (leave) {
        appEventEmitter.emit(appEventEmitterEvents.LEAVE_FROM_SESSION, {});
      }
    }
  );

  return <React.Fragment />;
};

export default AskToLeaveListner;
