import { appRtcClient, appRtcClients } from "../utils/constants";
import useLivekitRtcAllParticipantsExtraWebcamStreams from "./livekitRtc/useLivekitRtcAllParticipantsExtraWebcamStreams";

const useAppRtcAllParticipantsExtraWebcamStreams =
  appRtcClient === appRtcClients.livekit
    ? useLivekitRtcAllParticipantsExtraWebcamStreams
    : (): {
        allParticipantsExtraWebcamStreams: allParticipantsExtraWebcamStreamsType;
      } => ({
        allParticipantsExtraWebcamStreams: new Map(),
      });

export default useAppRtcAllParticipantsExtraWebcamStreams;
