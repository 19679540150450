import React, { useCallback, useEffect, useRef } from "react";
import getBroadcastAssets from "../../apis/broadcasts/get-broadcast-assets";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const broadcastAssetsActions = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  UPDATE: "UPDATE",
};

const BroadcastAssetsListner = () => {
  const { setBroadcastAssets } = useAppContext();

  const { broadcastId, studioUserId } = useAppConfigContext();

  const studioUserIdRef = useRef(studioUserId);
  const broadcastIdRef = useRef(broadcastId);
  const setBroadcastAssetsRef = useRef(setBroadcastAssets);

  useEffect(() => {
    studioUserIdRef.current = studioUserId;
  }, [studioUserId]);
  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);
  useEffect(() => {
    setBroadcastAssetsRef.current = setBroadcastAssets;
  }, [setBroadcastAssets]);

  const _handleFirstLoad = useCallback(async () => {
    if (broadcastIdRef.current) {
      const { success, assets } = await getBroadcastAssets({
        broadcastId: broadcastIdRef.current,
      });

      if (success && assets) {
        setBroadcastAssetsRef.current(assets);
      }
    }
  }, []);

  useEffect(() => {
    _handleFirstLoad();
  }, [_handleFirstLoad]);

  const _handleAddAsset = (data: broadcastAssetType) => {
    setBroadcastAssets((s) => [...s, data]);
  };

  const _handleUpdateAsset = (data: broadcastAssetType) => {
    setBroadcastAssets((s) =>
      s.map((asset) => (`${asset.id}` === `${data.id}` ? data : asset))
    );
  };

  const _handleRemoveAsset = (fileId: string) => {
    setBroadcastAssets((s) => s.filter(({ id }) => id !== fileId));
  };

  useAppSingalingSubscribe(
    appPubSubTopics.BROADCAST_ASSETS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case broadcastAssetsActions.ADD:
          _handleAddAsset(message.payload);
          break;

        case broadcastAssetsActions.UPDATE:
          _handleUpdateAsset(message.payload);
          break;

        case broadcastAssetsActions.REMOVE:
          _handleRemoveAsset(message.payload.fileId);
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default BroadcastAssetsListner;
