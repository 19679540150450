import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { MdPlayArrow, MdPause } from "react-icons/md";
import {
  VideoStreamVolumeControl,
  getSettedCurrentTimeOfVideoShareStream,
} from "../inQueueStreams/InQueueVideoStreamContainer";
import useVideoShareStream from "../../hooks/streams/useVideoShareStream";
import validateSpeakerDeviceId from "../../utils/validateSpeakerDeviceId";
import useIsOldest from "../../hooks/appState/useIsOldest";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

export const MainViewVideoShareStreamBottomContainer = ({
  videoId,
}: {
  videoId: string;
}) => {
  const [currentTime, setCurrentTime] = useState(0);

  const currentTimeRef = useRef(currentTime);

  useEffect(() => {
    currentTimeRef.current = currentTime;
  }, [currentTime]);

  const {
    durationInSec,
    playing,
    volume,
    //
    changeVolume,
    playAndSeek,
    pauseAndSeek,
    togglePlayingAndSeek,
  } = useVideoShareStream({ videoId });

  const _handleOnMainViewVideoShareStreamCurrentTimeUpdated = ({
    currentTime,
  }: {
    currentTime: number;
  }) => {
    setCurrentTime(currentTime);
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED,
      _handleOnMainViewVideoShareStreamCurrentTimeUpdated
    );

    appEventEmitter.emit(
      appEventEmitterEvents.REQUEST_MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED,
        _handleOnMainViewVideoShareStreamCurrentTimeUpdated
      );
    };
  }, []);

  return (
    <div className={`p-3 bg-opacity-50 bg-black`}>
      <div>
        <input
          type="range"
          min={0}
          max={1}
          step={0.001}
          value={currentTime / (durationInSec || 0)}
          onChange={(e) => {
            const seekToSec =
              parseInt((e.target as HTMLInputElement).value) *
              (durationInSec || 0);

            appEventEmitter.emit(
              appEventEmitterEvents.MAIN_VIEW_VIDEO_SHARE_STREAM_SEEK_PLAYER_TO_SEC,
              { seekToSec }
            );

            setCurrentTime(seekToSec);
          }}
          onMouseUp={async (e) => {
            const seekToSec =
              parseInt((e.target as HTMLInputElement).value) *
              (durationInSec || 0);

            if (playing) {
              playAndSeek({ seekToSec });
            } else {
              pauseAndSeek({ seekToSec });
            }
          }}
          className={"w-full"}
        />
      </div>
      <div className="flex items-center justify-start">
        <button
          onClick={async () => {
            togglePlayingAndSeek({ seekToSec: currentTimeRef.current });
          }}
        >
          {playing ? (
            <MdPause className="fill-white" />
          ) : (
            <MdPlayArrow className="fill-white" />
          )}
        </button>

        <VideoStreamVolumeControl
          {...{ volume: volume as number, changeVolume, fill: "fill-white" }}
        />
      </div>
    </div>
  );
};

const MainViewVideoShareStreamContainer = ({
  videoId,
}: {
  videoId: string;
}) => {
  const [isReadyAndDurationFound, setIsReadyAndDurationFound] = useState({
    isReady: false,
    isDurationFound: false,
  });

  const [muteDueToError, setMuteDueToError] = useState(false);

  const {
    appAudioMuted,
    selectedSpeakerDeviceId,
    isAnyAgoraActiveSpeaker,
    muteOriginalAudioWhenInterpretationActive,
    activeInterpretationOutputAgoraChannelId,
  } = useAppContext();

  const {
    durationInSec,
    playing,
    remoteUrl,
    volume: _volume,
    latestSeekDataIimestamp,
    latestSeekDataSeekToSec,
    playingStateChangedTimestamp,
    //
    pauseAndSeek,
  } = useVideoShareStream({ videoId });

  const videoPlayerId = useMemo(
    () => `MainViewVideoShareStream-${videoId}`,
    [videoId]
  );

  const volume = useMemo(
    () =>
      muteDueToError ||
      appAudioMuted ||
      (muteOriginalAudioWhenInterpretationActive &&
        activeInterpretationOutputAgoraChannelId)
        ? 0
        : isAnyAgoraActiveSpeaker
        ? 0.1
        : _volume,
    [
      muteDueToError,
      appAudioMuted,
      _volume,
      isAnyAgoraActiveSpeaker,
      muteOriginalAudioWhenInterpretationActive,
      activeInterpretationOutputAgoraChannelId,
    ]
  );

  const videoPlayerIdRef = useRef(videoPlayerId);

  useEffect(() => {
    videoPlayerIdRef.current = videoPlayerId;
  }, [videoPlayerId]);

  const durationInSecRef = useRef(durationInSec);
  const isReadyAndDurationFoundRef = useRef(isReadyAndDurationFound);

  useEffect(() => {
    durationInSecRef.current = durationInSec;
  }, [durationInSec]);

  const playingRef = useRef(playing);
  const muteDueToErrorRef = useRef(muteDueToError);
  const playingStateChangedTimestampRef = useRef(playingStateChangedTimestamp);
  const appAudioMutedRef = useRef(appAudioMuted);
  const volumeRef = useRef(volume);
  const audioMuteDueToError = useRef(false);

  const getVideoTag = () => {
    return document.getElementById(
      videoPlayerIdRef.current
    ) as StudioHTMLVideoElement | null;
  };

  useEffect(() => {
    playingRef.current = playing;
  }, [playing]);
  useEffect(() => {
    isReadyAndDurationFoundRef.current = isReadyAndDurationFound;
  }, [isReadyAndDurationFound]);
  useEffect(() => {
    muteDueToErrorRef.current = muteDueToError;
  }, [muteDueToError]);
  useEffect(() => {
    playingStateChangedTimestampRef.current = playingStateChangedTimestamp;
  }, [playingStateChangedTimestamp]);
  useEffect(() => {
    appAudioMutedRef.current = appAudioMuted;
  }, [appAudioMuted]);
  useEffect(() => {
    volumeRef.current = volume;
  }, [volume]);

  const playedPerRef = useRef<number | null>();

  useEffect(() => {
    if (
      latestSeekDataSeekToSec &&
      latestSeekDataIimestamp &&
      isReadyAndDurationFound.isDurationFound &&
      isReadyAndDurationFound.isReady
    ) {
      const { settedCurrentTimeOfVideo } =
        getSettedCurrentTimeOfVideoShareStream({
          durationInSec: durationInSecRef.current as number,
          latestSeekDataIimestamp,
          latestSeekDataSeekToSec,
          playing: !!playing,
        });

      const videoTag = getVideoTag();

      if (videoTag) {
        videoTag.currentTime = settedCurrentTimeOfVideo;
      }
    }
  }, [
    playing,
    latestSeekDataIimestamp,
    latestSeekDataSeekToSec,
    isReadyAndDurationFound,
  ]);

  const setSinkIdForVideo = async ({ deviceId }: { deviceId: string }) => {
    const { isValid } = await validateSpeakerDeviceId({ deviceId });
    const VideoTag = getVideoTag();

    if (isValid && typeof VideoTag?.setSinkId === "function") {
      VideoTag.setSinkId(deviceId);
    }
  };

  useEffect(() => {
    setSinkIdForVideo({ deviceId: selectedSpeakerDeviceId as string });
  }, [selectedSpeakerDeviceId]);

  const { getIsOldestParticipant } = useIsOldest();

  const _handleOnEnded = () => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (isOldestParticipant) {
      pauseAndSeek({ seekToSec: 0.000001 });
    }
  };

  const _handleMainViewVideoShareStreamSeekPlayerToSec = ({
    seekToSec,
  }: {
    seekToSec: number;
  }) => {
    const videoTag = getVideoTag();

    if (videoTag) {
      videoTag.currentTime = seekToSec;
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.MAIN_VIEW_VIDEO_SHARE_STREAM_SEEK_PLAYER_TO_SEC,
      _handleMainViewVideoShareStreamSeekPlayerToSec
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.MAIN_VIEW_VIDEO_SHARE_STREAM_SEEK_PLAYER_TO_SEC,
        _handleMainViewVideoShareStreamSeekPlayerToSec
      );
    };
  }, []);

  const getVolume = (args?: { volume: number }) => {
    const volume =
      audioMuteDueToError.current || appAudioMutedRef.current
        ? 0
        : typeof args?.volume === "number"
        ? args?.volume
        : typeof volumeRef.current === "number"
        ? volumeRef.current
        : 1;

    return volume;
  };

  const _playVideo = () => {
    const videoTag = getVideoTag();

    if (videoTag && typeof videoTag.play === "function") {
      const volume = getVolume();

      videoTag.volume = volume;

      videoTag.muted = volume === 0;

      videoTag.playsInline = true;

      const playPromise = videoTag.play();

      // error could occur in this play

      if (playPromise !== undefined) {
        playPromise
          .then(() => {})
          .catch(async () => {
            // have to handle the play failure

            appEventEmitter.emit(
              appEventEmitterEvents.MAIN_VIEW_VIDEO_SHARE_STREAM_PLAY_ERRORED
            );

            setMuteDueToError(true);

            audioMuteDueToError.current = true;

            const volume = getVolume();

            videoTag.volume = volume;

            videoTag.muted = volume === 0;

            videoTag.playsInline = true;

            const mutedPlayPromise = videoTag.play();

            if (mutedPlayPromise !== undefined) {
              mutedPlayPromise.then(() => {}).catch(async () => {});
            }
          });
      }
    }
  };

  const _pauseVideo = () => {
    const videoTag = getVideoTag();

    if (videoTag) {
      if (typeof videoTag.pause === "function") {
        videoTag.pause();
      }
    }
  };

  useEffect(() => {
    const videoTag = getVideoTag();

    if (!videoTag) return;

    const isVideoPaused = videoTag.paused;

    if (playing && isVideoPaused) {
      _playVideo();
    } else if (!playing && !isVideoPaused) {
      _pauseVideo();
    }
  }, [playing]);

  const _handleUserInteractButtonClicked = () => {
    setMuteDueToError(false);
  };

  const _handleOnRequestMainViewVideoShareStreamCurrentTimeUpdated = () => {
    appEventEmitter.emit(
      appEventEmitterEvents.MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED,
      {
        currentTime:
          (playedPerRef.current || 0) * (durationInSecRef.current || 0),
      }
    );
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED,
      _handleUserInteractButtonClicked
    );

    appEventEmitter.on(
      appEventEmitterEvents.REQUEST_MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED,
      _handleOnRequestMainViewVideoShareStreamCurrentTimeUpdated
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED,
        _handleUserInteractButtonClicked
      );

      appEventEmitter.off(
        appEventEmitterEvents.REQUEST_MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED,
        _handleOnRequestMainViewVideoShareStreamCurrentTimeUpdated
      );
    };
  }, []);

  useEffect(() => {
    const videoTag = getVideoTag();

    if (videoTag) {
      const _volume = getVolume(volume ? { volume } : undefined);

      videoTag.volume = _volume;

      videoTag.muted = _volume === 0;
    }
  }, [volume, muteDueToError, appAudioMuted]);

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      className="relative cursor-pointer items-center justify-center flex"
    >
      {remoteUrl ? (
        <React.Fragment>
          <video
            onDurationChange={() => {
              if (!isReadyAndDurationFoundRef.current.isDurationFound) {
                setIsReadyAndDurationFound((s) => ({
                  ...s,
                  isDurationFound: true,
                }));
              }
            }}
            onCanPlay={() => {
              if (!isReadyAndDurationFoundRef.current.isReady) {
                setIsReadyAndDurationFound((s) => ({ ...s, isReady: true }));
              }
            }}
            onTimeUpdate={(e) => {
              const played =
                (e.target as StudioHTMLVideoElement).currentTime /
                (durationInSecRef.current || 0);
              const playedSeconds = (e.target as StudioHTMLVideoElement)
                .currentTime;

              appEventEmitter.emit(
                appEventEmitterEvents.MAIN_VIEW_VIDEO_SHARE_STREAM_CURRENT_TIME_UPDATED,
                { currentTime: playedSeconds }
              );

              playedPerRef.current = played;

              const muteDueToError = muteDueToErrorRef.current;

              if (muteDueToError) {
                setMuteDueToError(false);
              }
            }}
            id={videoPlayerId}
            src={remoteUrl}
            onEnded={_handleOnEnded}
            onPlay={() => {
              if (!playing) {
                _pauseVideo();
              }
            }}
            onPause={() => {
              if (playing) {
                _playVideo();
              }
            }}
            // playsinline // very very imp prop
            playsInline // very very imp prop
            controls={false}
            height={"100%"}
            width={"100%"}
            autoPlay
          />
        </React.Fragment>
      ) : (
        <p>loading...</p>
      )}
    </div>
  );
};

export default MainViewVideoShareStreamContainer;
