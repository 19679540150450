export const appPubSubTopics = {
  MAIN_VIEW_STREAMS: "MAIN_VIEW_STREAMS",
  MAIN_VIEW_LAYOUT: "MAIN_VIEW_LAYOUT",
  MAIN_VIEW_GRID: "MAIN_VIEW_GRID",
  FILE_SHARE_STREAM_ENABLED: "FILE_SHARE_STREAM_ENABLED",
  VIDEO_SHARE_STREAM_ENABLED: "VIDEO_SHARE_STREAM_ENABLED",
  INPUT_FILE_VIDEO_SHARE_STREAM_ENABLED:
    "INPUT_FILE_VIDEO_SHARE_STREAM_ENABLED",
  MODIFY_PARTICIPANT_NAME: "MODIFY_PARTICIPANT_NAME",
  MODIFY_PARTICIPANT_AVATAR: "MODIFY_PARTICIPANT_AVATAR",
  MAIN_VIEW_BACKGROUND: "MAIN_VIEW_BACKGROUND",
  MAIN_VIEW_OVERLAY: "MAIN_VIEW_OVERLAY",
  MAIN_VIEW_VIDEO_CLIP: "MAIN_VIEW_VIDEO_CLIP",
  MAIN_VIEW_VIDEO_CLIP_RESET_STATE: "MAIN_VIEW_VIDEO_CLIP_RESET_STATE",
  MAIN_VIEW_SOLO_MODE_SELECTED_PARTICIPANT_ID:
    "MAIN_VIEW_SOLO_MODE_SELECTED_PARTICIPANT_ID",
  BACKGROUND_MUSIC: "BACKGROUND_MUSIC",
  BRAND_LOGO: "BRAND_LOGO",
  BRAND_COLOR: "BRAND_COLOR",
  BRAND_SHOW_DISPLAY_NAMES: "BRAND_SHOW_DISPLAY_NAMES",
  BRAND_SHOW_HEADLINES: "BRAND_SHOW_HEADLINES",
  PARTICIPANT_HEADLINE: "PARTICIPANT_HEADLINE",
  MUTE_GUESTS_WHEN_VIDEO_PLAYS: "MUTE_GUESTS_WHEN_VIDEO_PLAYS",
  MUTE_GUESTS_WHEN_AUDIO_PLAYS: "MUTE_GUESTS_WHEN_AUDIO_PLAYS",

  //
  BRANDS: "BRANDS",
  // brands items
  VIDEO_CLIPS: "VIDEO_CLIPS",
  BACKGROUNDS: "BACKGROUNDS",
  BACKGROUND_MUSICS: "BACKGROUND_MUSICS",
  OVERLAYS: "OVERLAYS",
  BRAND_LOGOS: "BRAND_LOGOS",
  //
  BROADCAST_ASSETS: "BROADCAST_ASSETS",
  //
  AUDIO_AND_VIDEO_PERMISSION_STATES: "AUDIO_AND_VIDEO_PERMISSION_STATES",
  //
  RECORDING_OR_LIVESTREAM_STARTED: "RECORDING_OR_LIVESTREAM_STARTED",
  RAISE_HAND: "RAISE_HAND",
  RAISE_HAND_ENABLED: "RAISE_HAND_ENABLED",

  INTERACTIVITY_MODE: "INTERACTIVITY_MODE",
  CONFERENCE_MODE_MAX_PARTICIPANT_COUNT:
    "CONFERENCE_MODE_MAX_PARTICIPANT_COUNT",
  ACTIVE_PARTICIPANT: "ACTIVE_PARTICIPANT",
  PINNED_PARTICIPANTS_POSITION: "PINNED_PARTICIPANTS_POSITION",
  SWAPPED_PARTICIPANTS: "SWAPPED_PARTICIPANTS",
  CONFERENCE_LAYOUT_RESET: "CONFERENCE_LAYOUT_RESET",
  CONFERENCE_OLD_DATA_REQUEST: "CONFERENCE_OLD_DATA_REQUEST",
  NON_HOST_CAN_SEE_PARTICIIPANT_COUNT: "NON_HOST_CAN_SEE_PARTICIIPANT_COUNT",
  ENABLE_WAITING_ROOM: "ENABLE_WAITING_ROOM",
  SHOW_AUDIO_AVATARS: "SHOW_AUDIO_AVATARS",
  INIT_BROADCAST_REMAINING_TIME: "INIT_BROADCAST_REMAINING_TIME",
  BROADCAST_REMAINING_TIME: "BROADCAST_REMAINING_TIME",
  END_CALL: "END_CALL",

  //
  BANNER_FOLDERS: "BANNER_FOLDERS",
  BANNERS: "BANNERS",
  ACTIVE_BANNER: "ACTIVE_BANNER",
  HIGHLIGHTED_CHAT: "HIGHLIGHTED_CHAT",
  DOWNLOAD_PART: "DOWNLOAD_PART",
  //
  PRIVATE_CHAT_GROUP: "PRIVATE_CHAT_GROUP",
  ALL_PARTICIPANTS_NETWORK_SIGNAL_LEVEL:
    "ALL_PARTICIPANTS_NETWORK_SIGNAL_LEVEL",
  ALL_PARTICIPANTS_MIC_VOLUME_LEVEL: "ALL_PARTICIPANTS_MIC_VOLUME_LEVEL",
  VIDEOS_READY_TO_BE_PLAYED: "VIDEOS_READY_TO_BE_PLAYED",
  //
  CONFERENCE_CALCULATED_STATES: "CONFERENCE_CALCULATED_STATES",
  PREVIOUS_STUDIO_MAIN_VIEW_LAYOUT: "PREVIOUS_STUDIO_MAIN_VIEW_LAYOUT",
  //
  PROCESSING_BRAND_ASSETS: "PROCESSING_BRAND_ASSETS",

  PARTICIPANT_WEBCAM_AND_SCREEN_SHARE_STREAM_QUALITY:
    "PARTICIPANT_WEBCAM_AND_SCREEN_SHARE_STREAM_QUALITY",

  ALL_PARTICIPANTS_WEBCAM_KEY_FRAME: "ALL_PARTICIPANTS_WEBCAM_KEY_FRAME",

  ALL_PARTICIPANTS_CHANGE_MEDIA_DEVICE_IN_PROGRESS:
    "ALL_PARTICIPANTS_CHANGE_MEDIA_DEVICE_IN_PROGRESS",

  SELECTED_LIVE_STREAM_DESTINATIONS_IDS:
    "SELECTED_LIVE_STREAM_DESTINATIONS_IDS",

  ERRORED_LIVESTREAM_DESTINATIONS_IDS: "ERRORED_LIVESTREAM_DESTINATIONS_IDS",
  ALL_PARTICIPANTS_MEDIA_STATS: "ALL_PARTICIPANTS_MEDIA_STATS",

  REPORTED_UNPLAYABLE_MEDIA_STREAMS: "REPORTED_UNPLAYABLE_MEDIA_STREAMS",
  LIVESTREAM_DESTINATIONS: "LIVESTREAM_DESTINATIONS",

  EGRESS_STATE: "EGRESS_STATE",
  STARTING_EGRESSES: "STARTING_EGRESSES",
  STOPPING_EGRESSES: "STOPPING_EGRESSES",
  EDITING_EGRESSES: "EDITING_EGRESSES",
  EGRESSES_PROGRESS_STATE: "EGRESSES_PROGRESS_STATE",

  RTMP_DESTINATION_ID_AND_URLS: "RTMP_DESTINATION_ID_AND_URLS",
  FAILED_RTMP_DESTINATION_URLS: "FAILED_RTMP_DESTINATION_URLS",

  ALLOWED_ENTRY_REQUEST_PARTICIPANT_IDS:
    "ALLOWED_ENTRY_REQUEST_PARTICIPANT_IDS",

  PARTICIPANT_JOIN_REQUEST_ENTRY: "PARTICIPANT_JOIN_REQUEST_ENTRY",

  PARTICIPANT_ENTRY_REQUEST_DENIED: "PARTICIPANT_ENTRY_REQUEST_DENIED",

  INTERPRETER_AGORA_STATS: "INTERPRETER_AGORA_STATS",
};

export const getFileStreamTopicById = (fileId: string) =>
  `FILE_STREAM_${fileId}`;
export const getVideoStreamTopicById = (videoId: string) =>
  `VIDEO_STREAM_${videoId}`;
export const getInputFileVideoStreamTopicById = (inputFileVideoId: string) =>
  `INPUT_FILE_VIDEO_STREAM_${inputFileVideoId}`;
export const getToggleParticipantMediaTopicById = (participantId: string) =>
  `TOGGLE_PARTICIPANT_MEDIA_${participantId}`;
export const getBrandVideoClipsTopicById = (id: string) => `VIDEO_CLIPS_${id}`;
export const getBrandBackgroundsTopicById = (id: string) => `BACKGROUNDS_${id}`;
export const getBrandBackgroundMusicsTopicById = (id: string) =>
  `BACKGROUND_MUSICS_${id}`;
export const getBrandOverlaysTopicById = (id: string) => `OVERLAYS_${id}`;
export const getBrandLogosTopicById = (id: string) => `BRAND_LOGOS_${id}`;
export const getChatTopicByType = (chatType: string) => `CHAT_${chatType}`;
export const getPrivateChatGroupMessageTopicById = (id: string) =>
  `PRIVATE_CHAT_GROUP_MSG_${id}`;
export const getValidateConnectionTopicById = (participantId: string) =>
  `VALIDATE_CONNECTION_${participantId}`;

export const initReqOldDataFrom = (participantId: string) =>
  `INIT_REQ_OLD_DATA_FROM_${participantId}`;

export const resolveReqOldDataTo = (participantId: string) =>
  `RESOLVE_REQ_OLD_DATA_TO_${participantId}`;

export const initReqCurrentTimeFrom = (participantId: string) =>
  `INIT_REQ_CURRENT_TIME_FROM_${participantId}`;

export const resolveReqCurrentTimeTo = (participantId: string) =>
  `RESOLVE_REQ_CURRENT_TIME_TO_${participantId}`;

export const askToLeaveTopicByParticipantId = (participantId: string) =>
  `ASK_TO_LEAVE_${participantId}`;

export const allowParticipantEntryRequestTopicByParticipantId = (
  participantId: string
) => `ALLOW_PARTICIPANT_ENTRY_REQUEST${participantId}`;

export const assignSelfRoleTopicByParticipantId = (participantId: string) =>
  `ASSIGN_SELF_ROLE_${participantId}`;
