import { customBlueColor } from "../../utils/colors";

const SoloIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101.223 69.818"
    enableBackground="new 0 0 101.223 69.818"
    xmlSpace="preserve"
    {...props}
  >
    <g id="Capa_8">
      <path
        style={{ fill: customBlueColor[100] }}
        d="M50.611 39.577c-6.663 0-12.065 4.06-12.065 9.067h24.13c.001-5.007-5.401-9.067-12.065-9.067z"
      />
      <circle
        style={{ fill: customBlueColor[100] }}
        cx={50.611}
        cy={28.79}
        r={7.617}
      />
    </g>
  </svg>
);

export default SoloIcon;
