import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { allowParticipantEntryRequestTopicByParticipantId } from "../../utils/pubSubTopics";

const useAllowParticipantEntryRequest = () => {
  const allowParticipantEntryRequest = (participantId: string) => {
    appEventEmitter.emit(appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH, {
      topic: allowParticipantEntryRequestTopicByParticipantId(participantId),
      message: JSON.stringify({}),
    });
  };

  return { allowParticipantEntryRequest };
};

export default useAllowParticipantEntryRequest;
