import React, { useEffect, useRef } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const AllParticipantsChangeMediaDeviceInProgressListner = () => {
  const removeOldProcessInterval = useRef<null | ReturnType<
    typeof setInterval
  >>();

  const {
    allParticipantsChangeWebcamInProgress,
    setAllParticipantsChangeWebcamInProgress,
    allParticipantsChangeMicInProgress,
    setAllParticipantsChangeMicInProgress,
  } = useAppContext();

  const allParticipantsChangeWebcamInProgressRef = useRef(
    allParticipantsChangeWebcamInProgress
  );
  const allParticipantsChangeMicInProgressRef = useRef(
    allParticipantsChangeMicInProgress
  );

  useAppSingalingSubscribe(
    appPubSubTopics.ALL_PARTICIPANTS_CHANGE_MEDIA_DEVICE_IN_PROGRESS,
    ({ message: receivedMessage, participantId }) => {
      const message = safeParseJson(receivedMessage);

      const { processId, isWebcam, isMic, isInProgress, isCompleted } = message;

      if (!isWebcam && !isMic) {
        return;
      }

      const setter = isWebcam
        ? setAllParticipantsChangeWebcamInProgress
        : setAllParticipantsChangeMicInProgress;

      if (!isCompleted && !isInProgress) {
        return;
      }

      setter((s) =>
        isCompleted
          ? [...s].filter(
              ({ processId: _processId }) => processId !== _processId
            )
          : [
              ...s,
              {
                participantId,
                processId,
                initAt: new Date().getTime(),
              },
            ]
      );
    }
  );

  useEffect(() => {
    removeOldProcessInterval.current = setInterval(() => {
      const allParticipantsChangeWebcamInProgress =
        allParticipantsChangeWebcamInProgressRef.current;
      const allParticipantsChangeMicInProgress =
        allParticipantsChangeMicInProgressRef.current;

      const filteredAllParticipantsChangeWebcamInProgress =
        allParticipantsChangeWebcamInProgress.filter(
          (s) => s.initAt > new Date().getTime() - 2000
        );

      const filteredAllParticipantsChangeMicInProgress =
        allParticipantsChangeMicInProgress.filter(
          (s) => s.initAt > new Date().getTime() - 2000
        );

      if (
        filteredAllParticipantsChangeWebcamInProgress.length !==
        allParticipantsChangeWebcamInProgress.length
      ) {
        setAllParticipantsChangeWebcamInProgress(
          filteredAllParticipantsChangeWebcamInProgress
        );
      }

      if (
        filteredAllParticipantsChangeMicInProgress.length !==
        allParticipantsChangeMicInProgress.length
      ) {
        setAllParticipantsChangeMicInProgress(
          filteredAllParticipantsChangeMicInProgress
        );
      }
    }, 2000);

    return () => {
      if (removeOldProcessInterval.current) {
        clearInterval(removeOldProcessInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    allParticipantsChangeWebcamInProgressRef.current =
      allParticipantsChangeWebcamInProgress;
  }, [allParticipantsChangeWebcamInProgress]);

  useEffect(() => {
    allParticipantsChangeMicInProgressRef.current =
      allParticipantsChangeMicInProgress;
  }, [allParticipantsChangeMicInProgress]);

  return <React.Fragment />;
};

export default AllParticipantsChangeMediaDeviceInProgressListner;
