import { businessPlanTypes } from "../../utils/constants";
import useActivePlanType from "./useActivePlanType";

const useIsFreePlanActive = () => {
  const { activePlanType } = useActivePlanType();

  return {
    isFreePlanActive: activePlanType === businessPlanTypes.free,
  };
};

export default useIsFreePlanActive;
