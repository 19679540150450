import sleep from "./sleep";

const waitForRemoteUrlAccessible = async (args: {
  remoteUrl: string;
  turn?: number | null;
}): Promise<{ success: boolean }> => {
  const remoteUrl = args?.remoteUrl;
  const turn = args?.turn || 0;

  const maxTurn = 3;

  if (turn >= maxTurn) {
    return { success: false };
  }

  try {
    const res = await fetch(remoteUrl, { method: "HEAD" });

    if (res.status >= 200 && res.status <= 299 && res.ok) {
      return { success: true };
    } else {
      await sleep(2000);

      return waitForRemoteUrlAccessible({ remoteUrl, turn: turn + 1 });
    }
  } catch (error) {
    await sleep(2000);

    return waitForRemoteUrlAccessible({ remoteUrl, turn: turn + 1 });
  }
};

export default waitForRemoteUrlAccessible;
