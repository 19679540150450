// @ts-nocheck

import React, { useEffect, useMemo, useRef, useState } from "react";
import useLivekitRtcAllParticipantsOgMicStreams from "../../appRtc/livekitRtc/useLivekitRtcAllParticipantsOgMicStreams";
import { useAppContext } from "../../contexts/appContextDef";
import { appModes, interactivityModes } from "../../utils/constants";
import { createUID } from "../../utils/createUID";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { generateStreamId, streamTypes } from "./MainViewParticipantsListner";

const RemoteParticipantOgMicStreamProcessingContainer = ({
  ogMicTrack,
  languages,
  participantId,
  onTranslatedAudioTrack,
  onOriginalAudioStopped,
}: {
  ogMicTrack: MediaStreamTrack | undefined;
  participantId: string;
  languages: string[];
  onTranslatedAudioTrack: (t: {
    audioMediaStreamTrack: MediaStreamTrack;
    language: string;
  }) => void;
  onOriginalAudioStopped: () => void;
}) => {
  const { interactivityMode, mainViewSelectedStreams, selectedAudioLanguage } =
    useAppContext();

  const { shouldProcess } = useMemo(() => {
    try {
      let shouldProcess = false;

      if (interactivityMode === interactivityModes.STUDIO) {
        const streamId = generateStreamId({
          participantId,
          type: streamTypes.WEBCAM,
        });

        shouldProcess = !!mainViewSelectedStreams.get(streamId);
      } else {
        shouldProcess = true;
      }

      return { shouldProcess };
    } catch (error) {
      return { shouldProcess: false };
    }
  }, [participantId, mainViewSelectedStreams, interactivityMode]);

  const languageWiseChunkPlayerId = useMemo(() => createUID(), []);
  const languageWiseMergedAudioPlayerId = useMemo(() => createUID(), []);

  const languageWiseTranslatedBase64Audio = useRef<
    Map<string, { audios: { base64Src: string }[] }>
  >(new Map());
  const processingMicTrackId = useRef<null | string>(null);
  const momentoTranslationRef = useRef(null);

  const translatedLanguageMediaStreams = useRef<Map<string, MediaStream>>(
    new Map()
  );
  const originalAudioPlayer = useRef<StudioHTMLAudioElement>();
  const shouldProcessRef = useRef(shouldProcess);
  const selectedAudioLanguageRef = useRef(selectedAudioLanguage);

  useEffect(() => {
    shouldProcessRef.current = shouldProcess;
  }, [shouldProcess]);
  useEffect(() => {
    selectedAudioLanguageRef.current = selectedAudioLanguage;
  }, [selectedAudioLanguage]);

  const createMediaStreamTrack = async ({ language }: { language: string }) => {
    try {
      const ctx = new AudioContext();

      const videoEl = document.getElementById(
        `${languageWiseChunkPlayerId}-${language.toLowerCase()}`
      ) as StudioHTMLVideoElement;

      const source = ctx.createMediaElementSource(videoEl);

      const stream_dest = ctx.createMediaStreamDestination();

      source.connect(stream_dest);

      const audioEl = document.getElementById(
        `${languageWiseMergedAudioPlayerId}-${language.toLowerCase()}`
      ) as StudioHTMLAudioElement;

      audioEl.srcObject = stream_dest.stream;

      audioEl.volume = 1;

      audioEl.muted = false;

      audioEl.play();

      const tracks = stream_dest.stream.getTracks();

      if (tracks.length) {
        const audioTrack = tracks[0];

        onTranslatedAudioTrack({
          audioMediaStreamTrack: audioTrack,
          language: language.toLowerCase(),
        });
      }

      translatedLanguageMediaStreams.current.set(
        language.toLowerCase(),
        stream_dest.stream
      );
    } catch (error) {
      //
    }
  };

  const playNextAudio = async ({ language }: { language: string }) => {
    try {
      const videoEl = document.getElementById(
        `${languageWiseChunkPlayerId}-${language.toLowerCase()}`
      ) as StudioHTMLVideoElement;

      if (videoEl) {
        if (videoEl.paused || videoEl.ended) {
          const audios = languageWiseTranslatedBase64Audio.current.get(
            language.toLowerCase()
          )?.audios;

          const latestAudio = audios[0];

          if (latestAudio) {
            audios.shift();

            languageWiseTranslatedBase64Audio.current.set(
              String(language).toLowerCase(),
              { audios }
            );

            videoEl.src = latestAudio.base64Src;

            videoEl.volume = 1;

            videoEl.muted = false;

            videoEl.play();
          }
        }
      }
    } catch (error) {
      //
    }
  };

  const startProcessing = ({ micTrack }: { micTrack: MediaStreamTrack }) => {
    try {
      processingMicTrackId.current = micTrack.id;

      const mediaStream = new MediaStream();

      mediaStream.addTrack(micTrack);

      momentoTranslationRef.current = new window.MomentoTranslation.New({
        audioMediaStream: mediaStream,
        relativeStartTime: new Date().getTime(),
        config: {
          translationEnabled: true,
          translationLanguages: languages.map((language) => ({ language })),
        },
      });

      // momentoTranslationRef.current.onOriginalTranscript = ({
      //   language,
      //   text,
      // }: {
      //   language: string;
      //   text: string;
      // }) => {
      // };

      // momentoTranslationRef.current.onTranslatedTranscript = ({
      //   language,
      //   text,
      // }: {
      //   language: string;
      //   text: string;
      // }) => {
      // };

      momentoTranslationRef.current.onTranslatedAudio = ({
        language,
        base64AudioWavSrc,
      }: {
        language: string;
        base64AudioWavSrc: string;
      }) => {
        const audiosObj = languageWiseTranslatedBase64Audio.current.get(
          String(language).toLowerCase()
        );

        const audios = audiosObj?.audios || [];

        audios.push({ base64Src: base64AudioWavSrc });

        languageWiseTranslatedBase64Audio.current.set(
          String(language).toLowerCase(),
          { audios }
        );

        playNextAudio({ language });
      };
    } catch (error) {
      //
    }
  };

  const stopProcessing = () => {
    try {
      processingMicTrackId.current = null;

      if (momentoTranslationRef.current) {
        momentoTranslationRef.current.destroy();
      }

      onOriginalAudioStopped();
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    try {
      if (ogMicTrack) {
        if (processingMicTrackId.current !== ogMicTrack.id) {
          // stopProcessing();

          startProcessing({ micTrack: ogMicTrack });
        }
      } else {
        stopProcessing();
      }
    } catch (error) {
      //
    }
  }, [ogMicTrack]);

  const createMediaStreamTracks = ({ languages }: { languages: string[] }) => {
    for (let index = 0; index < languages.length; index++) {
      const language = languages[index];

      createMediaStreamTrack({ language });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      createMediaStreamTracks({ languages });
    }, 3000);
  }, []);

  useEffect(() => {
    try {
      if (originalAudioPlayer.current) {
        if (ogMicTrack) {
          const mediaStream = new MediaStream();

          mediaStream.addTrack(ogMicTrack);

          originalAudioPlayer.current.srcObject = mediaStream;

          originalAudioPlayer.current.volume = 0;

          originalAudioPlayer.current.muted = true;

          originalAudioPlayer.current.play();
        } else {
          originalAudioPlayer.current.srcObject = null;

          originalAudioPlayer.current.pause();
        }
      }
    } catch (error) {
      //
    }
  }, [ogMicTrack]);

  const initShouldProcess = () => {
    setInterval(() => {
      if (momentoTranslationRef.current) {
        if (momentoTranslationRef.current.setInputLanguage) {
          momentoTranslationRef.current.setInputLanguage(
            selectedAudioLanguageRef.current
              ? (selectedAudioLanguageRef.current || "").toLowerCase()
              : null
          );
        }

        if (shouldProcessRef.current) {
          if (!momentoTranslationRef.current.listening) {
            momentoTranslationRef.current.start();
          }
        } else {
          if (momentoTranslationRef.current.listening) {
            momentoTranslationRef.current.pause();
          }
        }
      }
    }, 5000);
  };

  useEffect(() => {
    initShouldProcess();
  }, []);

  return (
    <React.Fragment>
      <audio
        controls={false}
        hidden
        ref={originalAudioPlayer as React.LegacyRef<StudioHTMLAudioElement>}
      />
      {languages.map((language) => (
        <React.Fragment
          key={`RemoteParticipantOgMicStreamProcessingContainer-${language}-${participantId}`}
        >
          <audio
            controls={false}
            autoPlay
            hidden
            id={`${languageWiseMergedAudioPlayerId}-${language.toLowerCase()}`}
          />
          <video
            controls={false}
            autoPlay
            hidden
            id={`${languageWiseChunkPlayerId}-${language.toLowerCase()}`}
            onEnded={() => {
              playNextAudio({ language });
            }}
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const AIInterpreterContainer = ({ languages }: { languages: string[] }) => {
  const [loaded, setLoaded] = useState(false);

  const { allParticipantsOgMicStreams } =
    useLivekitRtcAllParticipantsOgMicStreams();

  const { joinedParticipants, localParticipantId } = useAppContext();

  const remoteParticipants = useMemo(() => {
    return joinedParticipants
      .filter(
        ({ participantId, appMode }) =>
          participantId !== localParticipantId &&
          (appMode === appModes.HOST || appMode === appModes.SPEAKER)
      )
      .map(({ participantId }) => ({
        participantId,
        ogMicTrack: allParticipantsOgMicStreams.get(participantId)?.ogMicTrack,
      }));
  }, [joinedParticipants, localParticipantId, allParticipantsOgMicStreams]);

  const onTranslatedAudioTrack = ({
    audioMediaStreamTrack,
    language,
    participantId,
  }: {
    audioMediaStreamTrack: MediaStreamTrack;
    language: string;
    participantId: string;
  }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_TURN_ON_PARTICIPANT_TRANSLATED_AUDIO_STREAM,
      { participantId, audioMediaStreamTrack, language }
    );
  };

  const onOriginalAudioStopped = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_TURN_OFF_PARTICIPANT_ALL_TRANSLATED_AUDIO_STREAMS,
      { participantId }
    );
  };

  const _init = async () => {
    await window.MomentoTranslation.init();

    setLoaded(true);
  };

  useEffect(() => {
    _init();
  }, []);

  return loaded ? (
    remoteParticipants.map(({ ogMicTrack, participantId }) => (
      <RemoteParticipantOgMicStreamProcessingContainer
        {...{
          key: `RemoteParticipantOgMicStreamProcessingContainer-${participantId}`,
          ogMicTrack,
          participantId,
          languages,
          onTranslatedAudioTrack: ({ audioMediaStreamTrack, language }) => {
            onTranslatedAudioTrack({
              audioMediaStreamTrack,
              language,
              participantId,
            });
          },
          onOriginalAudioStopped: () => {
            onOriginalAudioStopped({ participantId });
          },
        }}
      />
    ))
  ) : (
    <React.Fragment />
  );
};

export default AIInterpreterContainer;
