export const defaultAvatarImage =
  "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/user-avatar.png"; // require("./defaultAvatar.png");

import MOMENTO_LOGO from "./momento_logo.png";
import MOMENTO_LOGO_FULL from "./momento_logo_full.png";
import GALLERY from "./gallery.png";
import ERROR_404 from "./404.png";
import micCamPermissionToolbar from "./mic-cam-permission-toolbar.jpg";
import momentoStreamDefaultOverlay from "./momento-stream-default-overlay.png";
import momento_translate_logo from "./momento_translate_logo.png";
import momento_stream_logo from "./momento_stream_logo.png";
import momento_live_logo from "./momento_live_logo.png";

export {
  MOMENTO_LOGO,
  MOMENTO_LOGO_FULL,
  GALLERY,
  ERROR_404,
  micCamPermissionToolbar,
  momentoStreamDefaultOverlay,
  momento_translate_logo,
  momento_stream_logo,
  momento_live_logo,
};
