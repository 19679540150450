import { useAppContext } from "../../../contexts/appContextDef";
import { MdPeople } from "react-icons/md";
import { useAppConfigContext } from "../../../contexts/appConfigDef";

import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";
import useParticipantIdsAndCount from "../../../hooks/appState/useParticipantIdsAndCount";
import { sidePanelModes } from "../../../utils/constants";

const ParticipantCountContainer = () => {
  const { setSidePanelMode } = useAppContext();

  const { maxParticipantsCount } = useAppConfigContext();

  const { participantsCount } = useParticipantIdsAndCount();

  return (
    <div
      onClick={() => {
        setSidePanelMode(sidePanelModes.PARTICIPANTS);

        appEventEmitter.emit(appEventEmitterEvents.TOGGLED_LATERAL_SIDEBAR, {
          mode: sidePanelModes.PARTICIPANTS,
        });
      }}
      className="flex items-center justify-center px-2 py-0.5 border-2 border-primary rounded-full cursor-pointer"
    >
      <p className="font-semibold">{`${participantsCount}/${maxParticipantsCount}`}</p>
      <div className="ml-1">
        <MdPeople size={20} />
      </div>
    </div>
  );
};

export default ParticipantCountContainer;
