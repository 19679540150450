import { useEffect, useMemo, useRef } from "react";
import { useAppContext } from "../contexts/appContextDef";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../utils/appEventEmitter";

export const getReadyToBePlayedVideoIds = ({
  id,
  brandVideo,
  broadcastVideo,
  other,
}: {
  id: string;
  brandVideo?: boolean;
  broadcastVideo?: boolean;
  other?: boolean;
}) =>
  `${
    brandVideo
      ? "brandVideo"
      : broadcastVideo
      ? "broadcastVideo"
      : other
      ? "other"
      : ""
  }_${id}`;

const WaitUntilVideoAreReadyToBePlayed = ({
  id,
  remoteUrl,
  _handleOnReady,
}: {
  id: string;
  remoteUrl: string;
  _handleOnReady: ({
    id,
    remoteUrl,
    durationInSec,
  }: {
    id: string;
    remoteUrl: string;
    durationInSec: number;
  }) => void;
}) => {
  const videoPlayerId = useMemo(
    () => `WaitUntilVideoAreReadyToBePlayed-${id}`,
    [id]
  );

  const videoPlayerIdRef = useRef(videoPlayerId);

  useEffect(() => {
    videoPlayerIdRef.current = videoPlayerId;
  }, [videoPlayerId]);

  const playVideo = () => {
    const videoTag = document.getElementById(
      videoPlayerIdRef.current
    ) as StudioHTMLVideoElement | null;

    if (!videoTag) return;

    if (!videoTag?.paused) return;

    if (typeof videoTag.play === "function") {
      videoTag.volume = 0;

      videoTag.muted = true;

      videoTag.playsInline = true;

      const playPromise = videoTag.play();

      if (playPromise !== undefined) {
        playPromise.then(() => {}).catch(() => {});
      }
    }
  };

  return (
    <span style={{ overflow: "hidden", height: 0, width: 0 }}>
      <video
        ref={() => {
          playVideo();
        }}
        src={remoteUrl}
        id={videoPlayerId}
        height={0}
        width={0}
        controls={false}
        style={{ opacity: 1, height: 0, width: 0 }}
        muted
        autoPlay
        onDurationChange={(e) => {
          _handleOnReady({
            id,
            remoteUrl,
            durationInSec: (e.target as StudioHTMLVideoElement).duration,
          });
        }}
      />
    </span>
  );
};

const WaitUntilVideosAreReadyToBePlayed = () => {
  const {
    waitingForVideosRemoteUrlReadyToBePlayed,
    setWaitingForVideosRemoteUrlReadyToBePlayed,
    setVideosRemoteUrlReadyToBePlayed,
  } = useAppContext();

  const _handleOnReady = async ({
    id,
    remoteUrl,
    durationInSec,
  }: {
    id: string;
    remoteUrl: string;
    durationInSec: number;
  }) => {
    setWaitingForVideosRemoteUrlReadyToBePlayed((s) =>
      s.filter(({ id: _id }: { id: string }) => _id !== id)
    );

    setVideosRemoteUrlReadyToBePlayed((s) => [
      ...s,
      { id, remoteUrl, durationInSec },
    ]);

    appEventEmitter.emit(appEventEmitterEvents.VIDEO_READY_TO_BE_PLAYED(id), {
      durationInSec,
    });
  };

  const _waitingForVideosRemoteUrlReadyToBePlayed = useMemo(() => {
    const arr: { remoteUrl: string; id: string }[] = [];

    waitingForVideosRemoteUrlReadyToBePlayed.forEach((item) => {
      const { id } = item;
      if (!arr.find(({ id: _id }) => _id === id)) {
        arr.push(item);
      }
    });

    return arr;
  }, [waitingForVideosRemoteUrlReadyToBePlayed]);

  return _waitingForVideosRemoteUrlReadyToBePlayed.map(({ remoteUrl, id }) => (
    <WaitUntilVideoAreReadyToBePlayed
      {...{
        remoteUrl,
        id,
        key: `WaitUntilVideoAreReadyToBePlayed_${id}`,
        _handleOnReady,
      }}
    />
  ));
};

export default WaitUntilVideosAreReadyToBePlayed;
