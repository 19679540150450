import React from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import useBroadcastEgresses from "../../hooks/appState/useBroadcastEgresses";
import useBroadcastEgressesTimer from "../../hooks/appState/useBroadcastEgressesTimer";

const MainViewLiveTimer = () => {
  const { isRecorder } = useAppConfigContext();

  const {
    egressesStarted,
    isRecordingOriginalAudio,
    isLivestreamingOriginalAudio,
    isLivestreamingInterpretedAudio,
  } = useBroadcastEgresses();

  const { hhmmss } = useBroadcastEgressesTimer();

  return (isLivestreamingOriginalAudio ||
    isLivestreamingInterpretedAudio ||
    isRecordingOriginalAudio) &&
    !isRecorder &&
    egressesStarted ? (
    <div className="absolute top-0 left-0 pointer-events-none">
      <div className="m-3 flex gap-1">
        {isLivestreamingOriginalAudio || isLivestreamingInterpretedAudio ? (
          <div>
            <p className="bg-red-500 p-1 rounded-md font-bold text-white text-sm">
              Live
            </p>
          </div>
        ) : (
          <React.Fragment />
        )}
        {isRecordingOriginalAudio ? (
          <div>
            <p className="bg-primary p-1 rounded-md font-bold text-white text-sm">
              Recording
            </p>
          </div>
        ) : (
          <React.Fragment />
        )}
        {hhmmss ? (
          <div>
            <p className="bg-black bg-opacity-70 p-1 rounded-md font-bold text-white text-sm">
              {hhmmss}
            </p>
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default MainViewLiveTimer;
