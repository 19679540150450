import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../../contexts/appContextDef";
import useBackgroundMusic from "../../../hooks/activeBrand/useBackgroundMusic";
import useBackgroundMusics from "../../../hooks/brands/useBackgroundMusics";
import { MdMusicNote, MdPlayCircleOutline, MdAdd } from "react-icons/md";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { VideoStreamVolumeControl } from "../../inQueueStreams/InQueueVideoStreamContainer";
import updateBrandAsset from "../../../apis/brands/update-brand-asset";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import deleteBrandAsset from "../../../apis/brands/delete-brand-asset";
import AddNewOrSelectDefaultAssetsModal from "../../../components/AddNewOrSelectDefaultAssetsModal";
import BrandAssetsDraggableItem from "./BrandAssetsDraggableItem";
import useIsProfessionalPlanActive from "../../../hooks/plans/useIsProfessionalPlanActive";
import Tooltip from "../../../components/Tooltip";
import { createUID } from "../../../utils/createUID";
import BrandUploadingAndProcessingItems from "./BrandUploadingAndProcessingItems";
import removeExtensionFromFileName from "../../../utils/removeExtensionFromFileName";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";
import { appQueueEvents } from "../../../listners/appState/AppQueueListner";
import sleep from "../../../utils/sleep";
import updateBrand from "../../../apis/brands/update-brand";
import useBrandMuteGuestsWhenAudioPlays from "../../../hooks/activeBrand/useBrandMuteGuestsWhenAudioPlays";
import DisclosureContainer from "../../../components/DisclosureContainer";
import useGetCurrentTime from "../../../hooks/appState/useGetCurrentTime";

const BackgroundMusicsContainer = ({
  listenToCloseEvent,
  brandId,
  defaultOpen,
}: {
  listenToCloseEvent?: string;
  brandId: string;
  defaultOpen?: boolean;
}) => {
  const {
    sortedBackgroundMusicIds: allBrandsSortedBackgroundMusicIds,
    sortedBackgroundMusics: allBrandsSortedBackgroundMusics,
    backgroundMusic,
    newBackgroundMusicsState,
    muteGuestsWhenAudioPlays,
  } = useAppContext();

  const { userId } = useAppConfigContext();

  const sortedBackgroundMusics = useMemo(
    () => allBrandsSortedBackgroundMusics[brandId] || [],
    [allBrandsSortedBackgroundMusics, brandId]
  );

  const sortedBackgroundMusicIds = useMemo(
    () => allBrandsSortedBackgroundMusicIds[brandId] || [],
    [allBrandsSortedBackgroundMusicIds, brandId]
  );

  const { add, remove, sort, rename } = useBackgroundMusics({ id: brandId });
  const { changeActiveIdAndRemoteUrl, changeLoopState, changeVolume } =
    useBackgroundMusic();

  const { isProfessionalPlanActive } = useIsProfessionalPlanActive();
  const { setMuteGuestsWhenAudioPlays } = useBrandMuteGuestsWhenAudioPlays();

  const [_sortedBackgroundMusics, setSortedBackgroundMusics] = useState(
    sortedBackgroundMusics
  );
  const [assetsSelectionModalOpen, setAssetsSelectionModalOpen] =
    useState(false);

  const brandIdRef = useRef(brandId);

  useEffect(() => {
    brandIdRef.current = brandId;
  }, [brandId]);

  useEffect(() => {
    setSortedBackgroundMusics(sortedBackgroundMusics);
  }, [sortedBackgroundMusics]);

  const sortedBackgroundMusicIdsRef = useRef(sortedBackgroundMusicIds);

  useEffect(() => {
    sortedBackgroundMusicIdsRef.current = sortedBackgroundMusicIds;
  }, [sortedBackgroundMusicIds]);

  const reorder = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (
      typeof result?.source?.index == "number" &&
      typeof result?.destination?.index === "number"
    ) {
      const _sortedBackgroundMusicIds = reorder(
        [...sortedBackgroundMusicIdsRef.current],
        result.source.index,
        result.destination.index
      );

      setSortedBackgroundMusics((sortedBackgroundMusics) =>
        _sortedBackgroundMusicIds.map(
          (id) =>
            sortedBackgroundMusics.find(
              ({ id: _id }) => id === _id
            ) as singleBrandBackgroundMusicType
        )
      );

      sort({ ids: _sortedBackgroundMusicIds });
    }
  };

  const _handleAdd = async ({
    remoteUrl,
    singleFile,
    fileName,
  }: {
    remoteUrl?: string;
    singleFile?: File;
    fileName?: string;
  }) => {
    const uploadId = createUID();

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BRAND_BACKGROUND_MUSIC,
      //
      isUploading: true,
      isProcessing: false,
      isCompleted: false,
      //
      brandId,
      uploadId,
      name: fileName,
    });

    const newId = await add({ singleFile, remoteUrl, uploadId });

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BRAND_BACKGROUND_MUSIC,
      //
      isUploading: false,
      isProcessing: false,
      isCompleted: true,
      //
      brandId,
      uploadId,
      name: fileName,
    });

    if (newId) {
      await new Promise((resolve) => setTimeout(resolve, 100));

      const _sortedBackgroundMusicIds = [
        ...sortedBackgroundMusicIdsRef.current,
      ];

      sort({ ids: [..._sortedBackgroundMusicIds, newId] });
    }

    return { id: newId };
  };

  const _handleRemove = async (id: string) => {
    const { success } = await deleteBrandAsset({
      brandId,
      fileId: id,
      userId,
    });

    if (success) {
      remove({ id });

      await new Promise((resolve) => setTimeout(resolve, 100));

      const _sortedBackgroundMusicIds = [
        ...sortedBackgroundMusicIdsRef.current,
      ];

      sort({ ids: _sortedBackgroundMusicIds.filter((_id) => id !== _id) });
    }
  };

  const _handleRename = async ({ id, name }: { id: string; name: string }) => {
    const { success } = await updateBrandAsset({
      brandId,
      fileId: id,
      name,
      userId,
    });

    if (success) {
      rename({ name, id });
    }
  };

  const _handlePlay = ({
    id,
    remoteUrl,
    playedAt,
  }: {
    id: string;
    remoteUrl: string;
    playedAt: number;
  }) => {
    changeActiveIdAndRemoteUrl({ id, remoteUrl, playedAt });
  };

  const _handlePause = () => {
    changeActiveIdAndRemoteUrl({
      id: null,
      remoteUrl: null,
      playedAt: null,
    });
  };

  const newBrandBackgroundMusicsState = useMemo(
    () =>
      newBackgroundMusicsState.filter(
        ({ brandId: _brandId }) => brandId === _brandId
      ),
    [newBackgroundMusicsState, brandId]
  );

  const canAddNewItem = useMemo(
    () =>
      !(
        (_sortedBackgroundMusics?.length || 0) +
          (newBrandBackgroundMusicsState?.length || 0) >=
        (isProfessionalPlanActive ? 10 : 5)
      ),
    [
      _sortedBackgroundMusics,
      newBrandBackgroundMusicsState,
      isProfessionalPlanActive,
    ]
  );

  const { getCurrentTime } = useGetCurrentTime();

  const _handleAddAndActivate = async ({
    remoteUrl,
  }: {
    remoteUrl: string;
  }) => {
    const { id } = await _handleAdd({ remoteUrl });

    if (id) {
      await sleep(500);

      const { currentTime } = await getCurrentTime();

      _handlePlay({ id, remoteUrl, playedAt: new Date(currentTime).getTime() });
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_BACKGROUND_MUSIC(brandId),
      _handleAddAndActivate
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_BACKGROUND_MUSIC(brandId),
        _handleAddAndActivate
      );
    };
  }, [brandId]);

  const _handleSetMuteGuestsWhenAudioPlays = async ({
    muteGuestsWhenAudioPlays,
  }: {
    muteGuestsWhenAudioPlays: boolean;
  }) => {
    const { success } = await updateBrand({
      brandId: brandIdRef.current,
      muteGuestsWhenAudioPlays,
      userId,
    });

    if (success) {
      setMuteGuestsWhenAudioPlays({ muteGuestsWhenAudioPlays });
    }
  };

  return (
    <DisclosureContainer
      listenToCloseEvent={listenToCloseEvent}
      defaultOpen={defaultOpen}
      title={"Background music"}
      dataTip={`Select a music track to use as background music.`}
      dataTipn={["Max file size: 30 MB", "File type must be: mp3, wav, ogg"]}
      renderPanel={() => {
        return (
          <React.Fragment>
            <div className="flex flex-col">
              <div className="flex gap-4">
                <div>
                  <VideoStreamVolumeControl
                    iconSize={22}
                    fill={"fill-primary"}
                    volume={backgroundMusic.volume}
                    changeVolume={(volume) => {
                      changeVolume(volume);
                    }}
                    alwaysShowSlider
                  />
                </div>
                <div className="flex gap-2">
                  <input
                    checked={backgroundMusic.loop}
                    onChange={() => {
                      changeLoopState({
                        loop: !backgroundMusic.loop,
                        loopedAt: new Date().getTime(),
                      });
                    }}
                    type={"checkbox"}
                  />
                  <p>Loop</p>
                </div>
              </div>
              <div className="flex gap-2">
                <input
                  checked={muteGuestsWhenAudioPlays}
                  onChange={() => {
                    _handleSetMuteGuestsWhenAudioPlays({
                      muteGuestsWhenAudioPlays: !muteGuestsWhenAudioPlays,
                    });
                  }}
                  type={"checkbox"}
                />
                <p>Mute guests when audio plays</p>
              </div>
            </div>

            <div className="mt-3">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      className="flex flex-1 flex-col"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {_sortedBackgroundMusics.map((item, index) => {
                        const isActive = item.id === backgroundMusic.id;

                        return (
                          <BrandAssetsDraggableItem
                            {...{
                              key: `brand-background-music-item-${item.id}`,
                              item,
                              index,
                              onDelete: () => _handleRemove(item.id),
                              isActive,
                              remoteUrl: item.remoteUrl,
                              onPlay: async () => {
                                const { currentTime } = await getCurrentTime();

                                _handlePlay({
                                  id: item.id,
                                  remoteUrl: item.remoteUrl,
                                  playedAt: new Date(currentTime).getTime(),
                                });
                              },
                              onPause: _handlePause,
                              name: removeExtensionFromFileName(item.name),
                              id: item.id,
                              onRename: (name) => {
                                _handleRename({ name, id: item.id });
                              },
                              ThumbnailIcon: MdMusicNote,
                              PlayIcon: MdPlayCircleOutline,
                              playText: "Play",
                              subTitleEnabled: isActive,
                              subTitleTopic:
                                appEventEmitterEvents.BRAND_BACKGROUND_MUSIC_PROGRESS(
                                  item.id
                                ),
                            }}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <BrandUploadingAndProcessingItems
              {...{
                newItemsState: newBrandBackgroundMusicsState,
              }}
            />

            <div className="mt-1">
              <Tooltip
                {...{
                  disabled: canAddNewItem,
                  title:
                    "You have reached the maximum background musics upload limit. Delete one to upload a new one.",
                }}
              >
                <button
                  onClick={() => {
                    if (canAddNewItem) {
                      setAssetsSelectionModalOpen(true);
                    }
                  }}
                  className={`gap-2 btn btn-sm btn-primary btn-outline normal-case ${
                    canAddNewItem ? "" : "opacity-60 cursor-not-allowed"
                  }`}
                >
                  <MdAdd size={24} />
                  {"Add new audio"}
                </button>
              </Tooltip>
            </div>

            <AddNewOrSelectDefaultAssetsModal
              {...{
                maxFileSizeInMB: 30,
                isOpen: assetsSelectionModalOpen,
                onClose: () => {
                  setAssetsSelectionModalOpen(false);
                },
                onSuccess: () => {
                  setAssetsSelectionModalOpen(false);
                },
                title: "Add Music",
                // acceptFileType: "audio/*",
                acceptFileType: ".mp3,.ogg,.wav",
                inputButtonText: "Select audio",
                onFileEvent: _handleAdd,
                type: "audio",
                defaultItems: [
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/abstract-fashion-pop.mp3",
                    useRemoteUrl: true,
                    name: "Abstract fashion pop",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/lofi-hip-hop.mp3",
                    useRemoteUrl: true,
                    name: "Lofi hip hop",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/beautiful-trip.mp3",
                    useRemoteUrl: true,
                    name: "Beautiful trip",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/lofi-night-dreaming.mp3",
                    useRemoteUrl: true,
                    name: "Lofi night dreaming",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/cinematic-mood.mp3",
                    useRemoteUrl: true,
                    name: "Cinematic Mood",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/pink-cloud.mp3",
                    useRemoteUrl: true,
                    name: "Pink cloud",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/documentary.mp3",
                    useRemoteUrl: true,
                    name: "Documentary",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/podcast.mp3",
                    useRemoteUrl: true,
                    name: "Podcast",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/inspirational-piano.mp3",
                    useRemoteUrl: true,
                    name: "Inspirational piano",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/positive-corporate.mp3",
                    useRemoteUrl: true,
                    name: "Positive corporate",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/inspiring-science-technology.mp3",
                    useRemoteUrl: true,
                    name: "Inspiring science technology",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/space-chillout.mp3",
                    useRemoteUrl: true,
                    name: "Space chillout",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/little-break-lofi-beat.mp3",
                    useRemoteUrl: true,
                    name: "Little break lofi beat",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/this-science-abstract.mp3",
                    useRemoteUrl: true,
                    name: "This science abstract",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/lo-flowd.mp3",
                    useRemoteUrl: true,
                    name: "Lo flowd",
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/wild-life-documentary.mp3",
                    useRemoteUrl: true,
                    name: "Wild life documentary",
                  },
                ],
              }}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

export default BackgroundMusicsContainer;
