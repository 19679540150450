import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useMainViewVideoClip from "../../hooks/activeBrand/useMainViewVideoClip";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { msToHMS } from "../../utils/msToHMS";
import validateSpeakerDeviceId from "../../utils/validateSpeakerDeviceId";
import useGetCurrentTime from "../../hooks/appState/useGetCurrentTime";
import { createUID } from "../../utils/createUID";
import sleep from "../../utils/sleep";
import { appModes } from "../../utils/constants";

const MainViewVideoClipContainerRenderer = ({
  id,
  loop,
  remoteUrl,
  volume: _volume,
  playedAt,
  loopedAt,
}: {
  id: string;
  loop: boolean;
  remoteUrl: string;
  volume: number;
  playedAt: number;
  loopedAt: number;
}) => {
  const [playedPer, setPlayedPer] = useState(0);
  const [muteDueToError, setMuteDueToError] = useState(false);

  const {
    appAudioMuted,
    selectedSpeakerDeviceId,
    appMode,
    isAnyAgoraActiveSpeaker,
    muteOriginalAudioWhenInterpretationActive,
    activeInterpretationOutputAgoraChannelId,
  } = useAppContext();

  const { changeActiveIdAndRemoteUrl } = useMainViewVideoClip();

  const videoPlayerId = useMemo(
    () => `MainViewVideoClipContainerRenderer-videoPlayer-${createUID()}`,
    []
  );

  const volume = useMemo(
    () =>
      muteDueToError ||
      appAudioMuted ||
      (muteOriginalAudioWhenInterpretationActive &&
        activeInterpretationOutputAgoraChannelId)
        ? 0
        : isAnyAgoraActiveSpeaker
        ? 0.1
        : _volume,
    [
      muteDueToError,
      appAudioMuted,
      _volume,
      isAnyAgoraActiveSpeaker,
      muteOriginalAudioWhenInterpretationActive,
      activeInterpretationOutputAgoraChannelId,
    ]
  );

  const playUntilVideoTagPlayIdRef = useRef<string | null>();
  const idRef = useRef(id);
  const loopRef = useRef(loop);
  const remoteUrlRef = useRef(remoteUrl);
  const volumeRef = useRef(volume);
  const playedAtRef = useRef(playedAt);
  const loopedAtRef = useRef(loopedAt);
  const videoDurationInSecondsRef = useRef<number | null>(null);
  const playedPerRef = useRef(playedPer);
  const videoPlayerIdRef = useRef(videoPlayerId);
  const appAudioMutedRef = useRef(appAudioMuted);
  const audioMuteDueToError = useRef(false);

  const syncedOnDuration = useRef<boolean | string>(false);
  const syncedOnReady = useRef<boolean | string>(false);

  const { getCurrentTime } = useGetCurrentTime();

  const getVolume = (args?: { volume: number }) => {
    const volume =
      audioMuteDueToError.current || appAudioMutedRef.current
        ? 0
        : typeof args?.volume === "number"
        ? args?.volume
        : volumeRef.current;

    return volume;
  };

  const getCurrentTimeToBeSeeked = async () => {
    const videoDurationInSeconds = videoDurationInSecondsRef.current;

    const playedAt = playedAtRef.current;

    const { currentTime } = await getCurrentTime();

    const playedSeconds = (currentTime - playedAt) / 1000;

    const playedSecondsLatestLoop =
      playedSeconds -
      (videoDurationInSeconds || 0) *
        parseInt(`${playedSeconds / (videoDurationInSeconds || 0)}`);

    return playedSecondsLatestLoop;
  };

  const getVideoTag = (): StudioHTMLVideoElement | null => {
    return document.getElementById(
      videoPlayerIdRef.current
    ) as StudioHTMLVideoElement | null;
  };

  const seekVideoToCurrentTime = async (
    videoTag: StudioHTMLVideoElement | null
  ) => {
    videoTag = videoTag || getVideoTag();

    if (videoTag) {
      const currentTimeToBeSeeked = await getCurrentTimeToBeSeeked();

      videoTag.currentTime = currentTimeToBeSeeked;
    }
  };

  const playUsingVideoTag = async (args?: { preventSeek: boolean }) => {
    if (videoDurationInSecondsRef.current === null) return;

    const videoTag = getVideoTag();

    if (!videoTag) return;

    const preventSeek = args?.preventSeek;

    if (videoTag && typeof videoTag.play === "function") {
      const volume = getVolume();

      videoTag.volume = volume;

      videoTag.muted = volume === 0;

      videoTag.playsInline = true;

      const playPromise = videoTag.play();

      // error could occur in this play

      if (playPromise !== undefined) {
        await new Promise((resolve) => {
          playPromise
            .then(() => {
              if (!preventSeek) {
                seekVideoToCurrentTime(videoTag);
              }

              resolve(undefined);
            })
            .catch((e) => {
              console.log("play-error", e);

              // have to handle the play failure

              setMuteDueToError(true);

              audioMuteDueToError.current = true;

              const volume = getVolume();

              videoTag.volume = volume;

              videoTag.muted = volume === 0;

              appEventEmitter.emit(
                appEventEmitterEvents.MAIN_VIEW_VIDEO_CLIP_PLAY_ERRORED
              );

              videoTag.playsInline = true;

              const mutedPlayPromise = videoTag.play();

              if (mutedPlayPromise !== undefined) {
                mutedPlayPromise
                  .then(() => {
                    if (!preventSeek) {
                      seekVideoToCurrentTime(videoTag);
                    }

                    resolve(undefined);
                  })
                  .catch((e) => {
                    console.log("play-error-again", e);

                    resolve(undefined);
                  });
              }
            });
        });
      }
    }
  };

  const _handleProgress = ({ played }: { played: number }) => {
    const playedHMS = msToHMS(
      parseInt(`${(videoDurationInSecondsRef.current || 0) * (played || 0)}`) *
        1000
    );
    const totalHMS = msToHMS(
      parseInt(`${videoDurationInSecondsRef.current || 0}`) * 1000
    );

    const subTitle = `${playedHMS} / ${totalHMS}`;

    appEventEmitter.emit(
      appEventEmitterEvents.BRAND_VIDEO_CLIP_PROGRESS(idRef.current),
      subTitle
    );
  };

  const setSinkIdForVideo = async ({ deviceId }: { deviceId: string }) => {
    const { isValid } = await validateSpeakerDeviceId({ deviceId });

    const videoTag = getVideoTag();

    if (isValid && typeof videoTag?.setSinkId === "function") {
      videoTag.setSinkId(deviceId);
    }
  };

  useEffect(() => {
    setSinkIdForVideo({ deviceId: selectedSpeakerDeviceId as string });
  }, [selectedSpeakerDeviceId]);

  const _handleUserInteractButtonClicked = () => {
    setMuteDueToError(false);

    audioMuteDueToError.current = false;

    const videoTag = getVideoTag();

    if (videoTag) {
      const volume = getVolume();

      videoTag.volume = volume;

      videoTag.muted = volume === 0;
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED,
      _handleUserInteractButtonClicked
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED,
        _handleUserInteractButtonClicked
      );
    };
  }, []);

  useEffect(() => {
    const videoTag = getVideoTag();

    if (videoTag) {
      const _volume = getVolume({ volume });

      videoTag.volume = _volume;

      videoTag.muted = _volume === 0;
    }
  }, [volume, muteDueToError, appAudioMuted]);

  useEffect(() => {
    idRef.current = id;
  }, [id]);
  useEffect(() => {
    loopRef.current = loop;
  }, [loop]);
  useEffect(() => {
    remoteUrlRef.current = remoteUrl;
  }, [remoteUrl]);
  useEffect(() => {
    volumeRef.current = volume;
  }, [volume]);
  useEffect(() => {
    playedAtRef.current = playedAt;
  }, [playedAt]);
  useEffect(() => {
    loopedAtRef.current = loopedAt;
  }, [loopedAt]);
  useEffect(() => {
    playedPerRef.current = playedPer;
  }, [playedPer]);
  useEffect(() => {
    videoPlayerIdRef.current = videoPlayerId;
  }, [videoPlayerId]);
  useEffect(() => {
    appAudioMutedRef.current = appAudioMuted;

    const videoTag = getVideoTag();

    if (videoTag) {
      const volume = getVolume();

      videoTag.volume = volume;

      videoTag.muted = volume === 0;
    }
  }, [appAudioMuted]);

  const _handleOnEnded = () => {
    if (loopRef.current) {
      playUsingVideoTag();
    } else {
      setPlayedPer(0);
      changeActiveIdAndRemoteUrl({
        id: null,
        playedAt: null,
        remoteUrl: null,
      });

      syncedOnReady.current = false;
      syncedOnDuration.current = false;
    }
  };

  const playUntilVideoTagPlay = async (
    checkFor: number,
    playUntilVideoTagPlayId: string
  ): Promise<void> => {
    if (playUntilVideoTagPlayId !== playUntilVideoTagPlayIdRef.current) {
      return;
    }

    const videoTag = getVideoTag();

    if (!videoTag || !remoteUrlRef.current) {
      return;
    }

    if (getVideoTag()?.paused) {
      await playUsingVideoTag({ preventSeek: true });
    }

    if (checkFor !== 0) {
      await sleep(10);

      return playUntilVideoTagPlay(checkFor - 1, playUntilVideoTagPlayId);
    } else {
      return;
    }
  };

  const _handleVisibilitychange = () => {
    playUntilVideoTagPlayIdRef.current = createUID();
    playUntilVideoTagPlay(5, playUntilVideoTagPlayIdRef.current);
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", _handleVisibilitychange);

    return () => {
      document.removeEventListener("visibilitychange", _handleVisibilitychange);
    };
  }, []);

  return (
    <div
      onDragStart={(e) => {
        e.stopPropagation();
      }}
      onDragOver={(e) => {
        e.stopPropagation();
      }}
      onDragEnd={(e) => {
        e.stopPropagation();
      }}
      className="absolute top-0 right-0 left-0 bottom-0 pointer-events-none"
    >
      <div className={`border-0 h-full w-full absolute p-0 m-0`}>
        <video
          autoPlay={false}
          playsInline // very very imp prop
          // playsinline // very very imp prop
          controls={false}
          id={videoPlayerId}
          onEnded={_handleOnEnded}
          src={remoteUrl}
          onDurationChange={(e) => {
            videoDurationInSecondsRef.current = (
              e.target as StudioHTMLVideoElement
            ).duration;

            if (
              syncedOnDuration.current ===
              `${idRef.current}-${playedAtRef.current}`
            )
              return;

            syncedOnDuration.current = `${idRef.current}-${playedAtRef.current}`;

            playUsingVideoTag();
          }}
          onCanPlay={() => {
            if (
              syncedOnReady.current ===
              `${idRef.current}-${playedAtRef.current}`
            )
              return;

            syncedOnReady.current = `${idRef.current}-${playedAtRef.current}`;

            playUsingVideoTag();
          }}
          onTimeUpdate={(e) => {
            const played =
              (e.target as StudioHTMLVideoElement).currentTime /
              (videoDurationInSecondsRef.current || 0);

            setPlayedPer(played);

            _handleProgress({ played });
          }}
          style={{ height: "100%", width: "100%" }}
        />

        {playedPer && id && remoteUrl && playedAt ? (
          (appMode === appModes.HOST || appMode === appModes.SPEAKER) && (
            <div className="absolute bottom-0 left-0 right-0 h-1.5 bg-gray-300">
              <div
                className="absolute top-0 bottom-0 bg-primary transition-all"
                style={{
                  width: `${playedPer * 100}%`,
                }}
              ></div>
            </div>
          )
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  );
};

const MainViewVideoClipContainer = () => {
  const { mainViewVideoClip } = useAppContext();

  return mainViewVideoClip.remoteUrl && mainViewVideoClip.playedAt ? (
    <MainViewVideoClipContainerRenderer
      {...{
        ...(mainViewVideoClip as {
          id: string;
          loop: boolean;
          remoteUrl: string;
          volume: number;
          playedAt: number;
          loopedAt: number;
        }),
        key: `MainViewVideoClipContainerRenderer-${mainViewVideoClip.id}-${mainViewVideoClip.playedAt}`,
      }}
    />
  ) : (
    <React.Fragment />
  );
};

export default MainViewVideoClipContainer;
