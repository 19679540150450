import { useEffect, useMemo, useRef, useState } from "react";
import { mediaStreamKinds } from "../../listners/streams/QueuedStreamsListnerAllPeers";
import { inQueueStreamTypes } from "../../utils/constants";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

const useAllParticipantsInQueueStreams = ({
  inQueueStreams,
  activeFileShareStreamAllParticipants,
  activeVideoShareStreamAllParticipants,
  activeInputFileVideoShareStreamAllParticipants,
  allParticipantsExtraWebcamStreams,
}: {
  inQueueStreams: inQueueStreamsType;
  activeFileShareStreamAllParticipants: activeFileShareStreamAllParticipantsType;
  activeVideoShareStreamAllParticipants: activeVideoShareStreamAllParticipantsType;
  activeInputFileVideoShareStreamAllParticipants: activeInputFileVideoShareStreamAllParticipantsType;
  allParticipantsExtraWebcamStreams: allParticipantsExtraWebcamStreamsType;
}) => {
  const initTimestamp = useRef(0);

  const [isThirdPartyRtmpLive, setIsThirdPartyRtmpLive] = useState(false);

  const { thirdPartyRtmpData } = useAppConfigContext();

  const thirdPartyRtmpDataRef = useRef(thirdPartyRtmpData);
  const isThirdPartyRtmpLiveRef = useRef(isThirdPartyRtmpLive);

  useEffect(() => {
    thirdPartyRtmpDataRef.current = thirdPartyRtmpData;
  }, [thirdPartyRtmpData]);
  useEffect(() => {
    isThirdPartyRtmpLiveRef.current = isThirdPartyRtmpLive;
  }, [isThirdPartyRtmpLive]);

  const getHlsStatus = async () => {
    const url = `https://stream.mux.com/${thirdPartyRtmpDataRef.current?.playbackId}.m3u8`;

    const res = await fetch(url);

    if (!isThirdPartyRtmpLiveRef.current && res.status === 200) {
      setIsThirdPartyRtmpLive(true);
    } else if (isThirdPartyRtmpLiveRef.current && res.status !== 200) {
      setIsThirdPartyRtmpLive(false);

      setTimeout(() => {
        appEventEmitter.emit(
          appEventEmitterEvents.REMOVE_FROM_MAIN_VIEW_STREAMS,
          {}
        );
      }, 1000);
    }
  };

  useEffect(() => {
    initTimestamp.current = new Date().getTime();

    getHlsStatus();

    const interval = setInterval(() => {
      getHlsStatus();
    }, 30_000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const allParticipantsInQueueStreams = useMemo(() => {
    const allParticipantsStreams: allParticipantsInQueueStreamsType = [];

    if (isThirdPartyRtmpLive) {
      allParticipantsStreams.push({
        type: inQueueStreamTypes.SHARE_RTMP_IN,
        participantId: thirdPartyRtmpDataRef.current?.playbackId as string,
        timestamp: initTimestamp.current,
      });
    }

    inQueueStreams.forEach(({ kind, participantId, timestamp }) => {
      if (kind === mediaStreamKinds.video || kind === mediaStreamKinds.audio) {
        const webcamFound = allParticipantsStreams.find(
          ({ participantId: _participantId, type }) =>
            participantId === _participantId && type === "WEBCAM"
        );

        if (!webcamFound) {
          allParticipantsStreams.push({
            type: inQueueStreamTypes.WEBCAM,
            participantId,
            timestamp,
          });
        }
      } else if (
        kind === mediaStreamKinds.share ||
        kind === mediaStreamKinds.shareAudio
      ) {
        const shareScreenFound = allParticipantsStreams.find(
          ({ participantId: _participantId, type }) =>
            participantId === _participantId && type === "SHARE_SCREEN"
        );

        if (!shareScreenFound) {
          allParticipantsStreams.push({
            type: inQueueStreamTypes.SHARE_SCREEN,
            participantId,
            timestamp,
          });
        }
      }
    });

    activeFileShareStreamAllParticipants.forEach(
      ({ id, participantId, remoteUrl, type, timestamp, resourceId }) => {
        allParticipantsStreams.push({
          id,
          participantId,
          remoteUrl,
          type: `SHARE_${type}` as inQueueStreamTypeType,
          timestamp,
          resourceId,
        });
      }
    );

    activeVideoShareStreamAllParticipants.forEach(
      ({ id, participantId, remoteUrl, timestamp }) => {
        allParticipantsStreams.push({
          id,
          participantId,
          remoteUrl,
          type: inQueueStreamTypes.SHARE_VIDEO,
          timestamp,
        });
      }
    );

    activeInputFileVideoShareStreamAllParticipants.forEach(
      ({ id, participantId, timestamp, rtcClientMetadata }) => {
        allParticipantsStreams.push({
          participantId,
          type: inQueueStreamTypes.SHARE_INPUT_FILE_VIDEO,
          timestamp,
          id,
          rtcClientMetadata,
        });
      }
    );

    [...allParticipantsExtraWebcamStreams.keys()].forEach((participantId) => {
      const participantExtraWebcamStreams =
        allParticipantsExtraWebcamStreams.get(participantId);

      if (participantExtraWebcamStreams) {
        allParticipantsStreams.push(
          ...participantExtraWebcamStreams.map(
            ({ extraWebcamId, timestamp }) => ({
              participantId,
              type: inQueueStreamTypes.EXTRAWBCM,
              timestamp,
              extraWebcamId,
            })
          )
        );
      }
    });

    const sortedAllParticipantsStreams = allParticipantsStreams.sort((a, b) =>
      (a.timestamp as number) > (b.timestamp as number)
        ? 1
        : (a.timestamp as number) < (b.timestamp as number)
        ? -1
        : 0
    );

    return sortedAllParticipantsStreams;
  }, [
    isThirdPartyRtmpLive,
    inQueueStreams,
    activeFileShareStreamAllParticipants,
    activeVideoShareStreamAllParticipants,
    allParticipantsExtraWebcamStreams,
    activeInputFileVideoShareStreamAllParticipants,
  ]);

  return { allParticipantsInQueueStreams };
};

export default useAllParticipantsInQueueStreams;
