import { useMemo } from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../utils/useLocalParticipantId";
import useParticipantIdsAndCount from "./useParticipantIdsAndCount";
import { inQueueStreamTypes } from "../../utils/constants";
import InQueueWebcamStreamContainer from "../../containers/inQueueStreams/InQueueWebcamStreamContainer";
import InQueueScreenShareStreamContainer from "../../containers/inQueueStreams/InQueueScreenShareStreamContainer";
import InQueueFileStreamContainer from "../../containers/inQueueStreams/InQueueFileStreamContainer";
import InQueueVideoStreamContainer from "../../containers/inQueueStreams/InQueueVideoStreamContainer";
import InQueueInputFileVideoShareStreamContainer from "../../containers/inQueueStreams/InQueueInputFileVideoShareStreamContainer";
import { streamModes } from "../../listners/appState/MainViewParticipantsListner";
import InQueueExtraWebcamStreamContainer from "../../containers/inQueueStreams/InQueueExtraWebcamStreamContainer";
import InQueueRtmpInShareStreamContainer from "../../containers/inQueueStreams/InQueueRtmpInShareStreamContainer";

const useHostInQueueStreams = () => {
  const { localParticipantId } = useLocalParticipantId();

  const { participantsCount } = useParticipantIdsAndCount();

  const { meetingMode } = useAppConfigContext();

  const {
    allParticipantsInQueueStreams,
    filteredInQueueStreams,
    joinedParticipants,
    newPdfsState,
    newVideoStreamsState,
    inQueueStreamsContainerHidden,
  } = useAppContext();

  const filteredHostInQueueStreams = useMemo(() => {
    const filteredHostInQueueStreams: allParticipantsInQueueStreamsType = [];

    if (
      allParticipantsInQueueStreams.find(
        ({ type }) => type === inQueueStreamTypes.SHARE_RTMP_IN
      )
    ) {
      filteredHostInQueueStreams.push({
        type: inQueueStreamTypes.SHARE_RTMP_IN,
        participantId: "",
      });
    }

    const allParticipantsInQueueNonWebcamStreams =
      allParticipantsInQueueStreams.filter(
        ({ type }) =>
          type !== inQueueStreamTypes.WEBCAM &&
          type !== inQueueStreamTypes.SHARE_PDF
      );

    const allInQueuePdfStreams = allParticipantsInQueueStreams.filter(
      ({ type }) => type === inQueueStreamTypes.SHARE_PDF
    );

    const joinedParticipantIds = joinedParticipants
      .sort((a, b) =>
        a.timestamp > b.timestamp ? 1 : a.timestamp < b.timestamp ? -1 : 0
      )
      .map(({ participantId }) => participantId);

    const indexFound = joinedParticipantIds.findIndex(
      (participantId) => participantId === localParticipantId
    );

    if (indexFound !== -1) {
      joinedParticipantIds.splice(indexFound, 1);
    }

    joinedParticipantIds.unshift(localParticipantId);

    joinedParticipantIds
      .filter(
        (participantId) =>
          participantId === localParticipantId ||
          filteredInQueueStreams.includes(participantId)
      )
      .forEach((participantId) => {
        const participantNonWebcamStreams =
          allParticipantsInQueueNonWebcamStreams.filter(
            ({ participantId: _participantId }) =>
              _participantId === participantId
          );

        filteredHostInQueueStreams.push(
          ...[
            { participantId, type: inQueueStreamTypes.WEBCAM },
            ...participantNonWebcamStreams,
          ]
        );
      });

    filteredHostInQueueStreams.push(...allInQueuePdfStreams);

    return filteredHostInQueueStreams
      .map(({ participantId, type, id, extraWebcamId }, i) => {
        const key = `inqueue_stream_${participantId}_${type}_${i}`;

        let Component;
        let props;
        let filter = false;

        if (type === inQueueStreamTypes.WEBCAM && meetingMode !== "meeting") {
          Component = InQueueWebcamStreamContainer;
          props = {
            type,
            participantId,
            addToStreamTooltipOpen:
              participantsCount === 1 && localParticipantId === participantId,
          };
          filter = true;
        }

        if (type === inQueueStreamTypes.SHARE_SCREEN) {
          Component = InQueueScreenShareStreamContainer;
          props = { type, participantId };
          filter = true;
        }

        if (type === inQueueStreamTypes.SHARE_PDF) {
          Component = InQueueFileStreamContainer;
          props = { type, fileId: id, participantId };
          filter = true;
        }

        if (type === inQueueStreamTypes.SHARE_VIDEO) {
          Component = InQueueVideoStreamContainer;
          props = { type, videoId: id, participantId };
          filter = true;
        }

        if (type === inQueueStreamTypes.SHARE_INPUT_FILE_VIDEO) {
          Component = InQueueInputFileVideoShareStreamContainer;
          props = { type, inputFileVideoId: id, participantId };
          filter = true;
        }

        if (type === inQueueStreamTypes.EXTRAWBCM) {
          Component = InQueueExtraWebcamStreamContainer;
          props = { type, extraWebcamId, participantId };
          filter = true;
        }

        if (type === inQueueStreamTypes.SHARE_RTMP_IN) {
          Component = InQueueRtmpInShareStreamContainer;
          props = { type };
          filter = true;
        }

        return {
          key,
          Component: Component as React.ElementType,
          props,
          filter,
        };
      })
      .filter(({ filter }) => filter);
  }, [
    filteredInQueueStreams,
    localParticipantId,
    joinedParticipants,
    allParticipantsInQueueStreams,
    participantsCount,
    meetingMode,
  ]);

  const newBroadcastAssetsStates = useMemo(
    () => [
      ...(newPdfsState?.length
        ? newPdfsState.map((s) => ({ ...s, mode: streamModes.PDF }))
        : []),
      ...(newVideoStreamsState?.length
        ? newVideoStreamsState.map((s) => ({ ...s, mode: streamModes.VIDEO }))
        : []),
    ],
    [newPdfsState, newVideoStreamsState]
  );

  const toggleHostInQueueStreamsButtonVisible = useMemo(
    () => newBroadcastAssetsStates.length || filteredHostInQueueStreams.length,
    [newBroadcastAssetsStates, filteredHostInQueueStreams]
  );

  const hostInQueueStreamsContainerHiddenInMeetingMode = useMemo(
    () =>
      meetingMode === "meeting" &&
      ((!newBroadcastAssetsStates.length &&
        !filteredHostInQueueStreams.length) ||
        inQueueStreamsContainerHidden),
    [
      meetingMode,
      inQueueStreamsContainerHidden,

      newBroadcastAssetsStates,
      filteredHostInQueueStreams,
    ]
  );

  return {
    newBroadcastAssetsStates,
    filteredHostInQueueStreams,
    toggleHostInQueueStreamsButtonVisible,
    hostInQueueStreamsContainerHiddenInMeetingMode,
  };
};

export default useHostInQueueStreams;
