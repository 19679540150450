import { useEffect } from "react";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../utils/appEventEmitter";

const useAppSingalingSubscribe = (
  topic: string,
  callback: ({
    message,
    participantId,
  }: {
    message: unknown;
    participantId: string;
  }) => void
) => {
  const _handleSignalingPubSubSubscribe = ({
    message,
    participantId,
  }: {
    message: unknown;
    participantId: string;
  }) => {
    callback({ message, participantId });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.SIGNALING_PUBSUB_SUBSCRIBE(topic),
      _handleSignalingPubSubSubscribe
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.SIGNALING_PUBSUB_SUBSCRIBE(topic),
        _handleSignalingPubSubSubscribe
      );
    };
  }, []);
};

export default useAppSingalingSubscribe;
