export const msToHMS = (ms: number) => {
  // 1- Convert to seconds:
  let seconds = ms / 1000;

  // 2- Extract hours:
  // const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
  // seconds = seconds % 3600; // seconds remaining after extracting hours

  // 3- Extract minutes:
  const minutes = parseInt(`${seconds / 60}`); // 60 seconds in 1 minute

  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;

  const arr = [];

  arr.push(`${parseInt(`${minutes}`)}`.padStart(2, "0"));
  arr.push(`${parseInt(`${seconds}`)}`.padStart(2, "0"));

  return arr.join(":");
};
