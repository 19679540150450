import { useEffect, useRef } from "react";
import updateBrand from "../../../apis/brands/update-brand";
import ColorPicker from "../../../components/ColorPicker";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import { useAppContext } from "../../../contexts/appContextDef";
import useBrandColor from "../../../hooks/activeBrand/useBrandColor";
import useBrandShowDisplayNames from "../../../hooks/activeBrand/useBrandShowDisplayNames";
import useBrandShowHeadlines from "../../../hooks/activeBrand/useBrandShowHeadlines";
import sleep from "../../../utils/sleep";
import Switch from "react-switch";
import { primaryColor } from "../../../utils/colors";
import DisclosureContainer from "../../../components/DisclosureContainer";

const BrandColorContainer = ({
  listenToCloseEvent,
  defaultOpen,
  brandId,
}: {
  listenToCloseEvent?: string;
  defaultOpen?: boolean;
  brandId: string;
}) => {
  const { brandColor, showDisplayName, showParticipantHeadline } =
    useAppContext();

  const { userId } = useAppConfigContext();

  const { changeBrandColor } = useBrandColor();
  const { setShowDisplayNames } = useBrandShowDisplayNames();
  const { setShowHeadlines } = useBrandShowHeadlines();

  const brandIdRef = useRef(brandId);
  const showDisplayNameRef = useRef(showDisplayName);
  const showParticipantHeadlineRef = useRef(showParticipantHeadline);

  useEffect(() => {
    brandIdRef.current = brandId;
  }, [brandId]);
  useEffect(() => {
    showDisplayNameRef.current = showDisplayName;
  }, [showDisplayName]);
  useEffect(() => {
    showParticipantHeadlineRef.current = showParticipantHeadline;
  }, [showParticipantHeadline]);

  const _handleChangeColor = async (color: string) => {
    const { success } = await updateBrand({
      brandId: brandIdRef.current,
      color,
      userId,
    });

    if (success) {
      changeBrandColor({ hexColorCode: color });
    }
  };

  const _handleSetShowDisplayNames = async (showDisplayNames: boolean) => {
    const { success } = await updateBrand({
      brandId: brandIdRef.current,
      showDisplayNames,
      userId,
    });

    if (success) {
      setShowDisplayNames({ showDisplayNames });

      if (!showDisplayNames) {
        await sleep(500);

        await _handleSetShowHeadlines(false);
      }
    }
  };

  const _handleSetShowHeadlines = async (showHeadlines: boolean) => {
    const { success } = await updateBrand({
      brandId: brandIdRef.current,
      showHeadlines,
      userId,
    });

    if (success) {
      setShowHeadlines({ showHeadlines });
    }
  };

  return (
    <DisclosureContainer
      listenToCloseEvent={listenToCloseEvent}
      defaultOpen={defaultOpen}
      title={"Theme"}
      dataTip={`It's important to brand your broadcast. This color is used in your banners, on-screen comments, and display name.`}
      renderPanel={() => {
        return (
          <div>
            <ColorPicker
              color={brandColor.hexColorCode}
              setColor={(color) => {
                _handleChangeColor(color);
              }}
            />

            <div className="form-control w-64">
              <label className="cursor-pointer label">
                <span className="label-text">Show display names</span>
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  className="ml-2"
                  onColor={primaryColor}
                  onChange={_handleSetShowDisplayNames}
                  checked={showDisplayName}
                />
              </label>
            </div>

            <div className="form-control w-64">
              <label className="cursor-pointer label">
                <span className="label-text">Show headlines</span>
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  className="ml-2"
                  onColor={primaryColor}
                  disabled={!showDisplayName}
                  onChange={_handleSetShowHeadlines}
                  checked={showParticipantHeadline}
                />
              </label>
            </div>
          </div>
        );
      }}
    ></DisclosureContainer>
  );
};

export default BrandColorContainer;
