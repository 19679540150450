import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";

const useIsActiveSpeaker = ({ participantId }: { participantId: string }) => {
  const { activeSpeakerParticipantId } = useAppContext();

  const isActiveSpeaker = useMemo(
    () => participantId === activeSpeakerParticipantId,
    [participantId, activeSpeakerParticipantId]
  );

  return { isActiveSpeaker };
};

export default useIsActiveSpeaker;
