import React, { createRef, useEffect, useRef, useState } from "react";
import InQueueUploadingContainer from "../inQueueStreams/InQueueUploadingContainer";
import FilterInqueueStreams from "./FilterInqueueStreams";
import VisibilitySensor from "react-visibility-sensor";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useIsMobilePortraitOrLandscape from "../../hooks/responsive/useIsMobilePortraitOrLandscape";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";
import useHostInQueueStreams from "../../hooks/appState/useHostInQueueStreams";

const HostControlsContainer = ({
  onContainerHeight,
}: {
  onContainerHeight?: (height: number) => void;
}) => {
  const {
    filteredHostInQueueStreams,
    newBroadcastAssetsStates,
    hostInQueueStreamsContainerHiddenInMeetingMode,
  } = useHostInQueueStreams();

  const inQueueStreamsContainerRef = useRef<HTMLDivElement>();

  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  const [containerHeight, setContainerHeight] = useState(0);
  const [filterContainerHeight, setFilterContainerHeight] = useState(0);

  const containerRef = createRef<HTMLDivElement>();
  const filterContainerRef = createRef<HTMLDivElement>();

  const containerHeightRef = useRef(containerHeight);
  const filterContainerHeightRef = useRef(filterContainerHeight);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);
  useEffect(() => {
    filterContainerHeightRef.current = filterContainerHeight;
  }, [filterContainerHeight]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
    }
  }, [containerRef]);

  useEffect(() => {
    if (filterContainerRef.current) {
      const { current } = filterContainerRef;

      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;

      filterContainerHeightRef.current !== height &&
        setFilterContainerHeight(height);
    }
  }, [filterContainerRef]);

  useEffect(() => {
    if (typeof onContainerHeight === "function") {
      onContainerHeight(containerHeight);
    }
  }, [containerHeight]);

  return (
    <div
      ref={containerRef as React.LegacyRef<HTMLDivElement>}
      style={{
        maxHeight:
          isMobilePortraitOrLandscape ||
          hostInQueueStreamsContainerHiddenInMeetingMode
            ? undefined
            : 140,
        minHeight:
          isMobilePortraitOrLandscape ||
          hostInQueueStreamsContainerHiddenInMeetingMode
            ? undefined
            : 140,
      }}
      className={`select-none flex ${
        isMobilePortraitOrLandscape ? "flex-1 flex-col" : ""
      } ${quickstartTargetComponentClassNames.host_controls_container}`}
    >
      <div ref={filterContainerRef as React.LegacyRef<HTMLDivElement>}>
        {isMobilePortraitOrLandscape ||
        hostInQueueStreamsContainerHiddenInMeetingMode ? (
          <React.Fragment />
        ) : (
          <FilterInqueueStreams />
        )}
      </div>

      {hostInQueueStreamsContainerHiddenInMeetingMode ? (
        <React.Fragment />
      ) : (
        <div
          ref={inQueueStreamsContainerRef as React.LegacyRef<HTMLDivElement>}
          style={{
            maxHeight: isMobilePortraitOrLandscape
              ? containerHeight - filterContainerHeight
              : 140,
            minHeight: isMobilePortraitOrLandscape
              ? containerHeight - filterContainerHeight
              : 140,
            overflowY: "scroll",
          }}
          className="flex flex-1 items-center flex-wrap p-1"
        >
          {filteredHostInQueueStreams.map(
            ({
              key,
              Component,
              props,
            }: {
              Component: React.ElementType;
              key: string;
              props:
                | undefined
                | {
                    type: string;
                    participantId: string;
                    addToStreamTooltipOpen: boolean;
                  }
                | { type: string; participantId: string }
                | { type: string; fileId: string; participantId: string }
                | { type: string; videoId: string; participantId: string }
                | {
                    type: string;
                    inputFileVideoId: string;
                    participantId: string;
                  }
                | { type: string };
            }) => {
              return Component && props ? (
                <VisibilitySensor
                  key={key}
                  containment={inQueueStreamsContainerRef.current}
                  partialVisibility
                >
                  {({ isVisible }: { isVisible: boolean }) => {
                    appEventEmitter.emit(
                      appEventEmitterEvents.INQUEUE_STREAM_VISIBLE,
                      {
                        isVisible,
                        streamType: props.type,
                        ...props,
                      }
                    );

                    return (
                      <div className="m-1 border-gray-400 rounded-md border bg-gray-600 relative">
                        <Component {...props} />
                      </div>
                    );
                  }}
                </VisibilitySensor>
              ) : (
                <React.Fragment />
              );
            }
          )}

          {newBroadcastAssetsStates.map((state) => (
            <div
              key={`${state.id}`}
              className="m-1 border-gray-400 rounded-md border bg-gray-600"
            >
              <InQueueUploadingContainer {...state} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HostControlsContainer;
