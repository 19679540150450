import React, { useEffect, useMemo, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import {
  getSidePanelPrivateChatMessageListMode,
  sidePanelModesWithTitleAndIcon,
} from "../sideContainer/SideContainer";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";
import Tooltip from "../../components/Tooltip";
import { useJoinedInterpreterParticipantIds } from "../sidePanel/ParticipantsPanel";
import { appModes, sidePanelModes } from "../../utils/constants";

const LateralSidebar = () => {
  const {
    lateralSidebarWidth,
    setSidePanelMode,
    sidePanelMode,
    chatEnabled,
    appMode,
    requestedEntries,
    nonHostCanSeeParticipantsCount,
    participatedPrivateChatGroups,
    isAnyGroupsChatUnreaded,
    setSidePanelPrivateChatMode,
    qnaMessages,
    unseenActivatedApps,
    qnaLastSeenMessage,
  } = useAppContext();

  const { participantsCount } = useParticipantIdsAndCount();

  const { requestedEntriesCount } = useMemo(
    () => ({ requestedEntriesCount: requestedEntries?.length || 0 }),
    [requestedEntries]
  );

  const visibleSidePanelModes = useMemo(
    () =>
      Object.keys(sidePanelModesWithTitleAndIcon).filter((key) => {
        const { hostOnly } =
          sidePanelModesWithTitleAndIcon[
            key as sidePanelModesWithTitleAndIconKeysType
          ];

        return key === sidePanelModes.PRIVATE_CHAT
          ? appMode === appModes.HOST
            ? true
            : participatedPrivateChatGroups.length
          : key === sidePanelModes.CHAT
          ? chatEnabled
          : hostOnly
          ? appMode === appModes.HOST
          : key === sidePanelModes.PARTICIPANTS
          ? appMode === appModes.HOST
            ? true
            : nonHostCanSeeParticipantsCount
          : true;
      }) as sidePanelModesWithTitleAndIconKeysType[],
    [
      appMode,
      participatedPrivateChatGroups,
      nonHostCanSeeParticipantsCount,
      chatEnabled,
    ]
  );

  const sidePanelModeRef = useRef(sidePanelMode);

  useEffect(() => {
    sidePanelModeRef.current = sidePanelMode;
  }, [sidePanelMode]);

  const unseenQNAMessagesOrApps = useMemo(
    () =>
      (qnaLastSeenMessage?.index || 0) + 1 < qnaMessages?.length ||
      unseenActivatedApps.length > 0,
    [qnaMessages, qnaLastSeenMessage, unseenActivatedApps]
  );

  const { joinedInterpreterParticipantIds } =
    useJoinedInterpreterParticipantIds();

  return (
    <div
      style={{ width: lateralSidebarWidth }}
      className={`border-l border-gray-300 select-none`}
    >
      {visibleSidePanelModes.map((mode, i) => {
        const {
          Icon,
          title,
          showChatBadge,
          showQNABadge,
          showParticipantBadge,
          quickstartTargetComponentClassName,
        } = sidePanelModesWithTitleAndIcon[mode];

        const isSelected = sidePanelMode === mode;

        return (
          <div
            key={`visibleSidePanelMode-${mode}-${i}`}
            onClick={() => {
              const currentSidePanelMode = sidePanelModeRef.current;

              setSidePanelMode(
                (s) => (s === mode ? null : mode) as sidePanelModeType | null
              );

              if (
                mode === sidePanelModes.PRIVATE_CHAT &&
                appMode !== appModes.HOST &&
                participatedPrivateChatGroups.length === 1
              ) {
                setSidePanelPrivateChatMode(
                  getSidePanelPrivateChatMessageListMode(
                    participatedPrivateChatGroups[0].id
                  )
                );
              }

              appEventEmitter.emit(
                appEventEmitterEvents.TOGGLED_LATERAL_SIDEBAR,
                {
                  mode: currentSidePanelMode === mode ? null : mode,
                }
              );
            }}
            className={`relative m-1 aspect-square cursor-pointer flex items-center justify-center flex-col ${
              isSelected ? "bg-custom-blue-50" : "hover:bg-custom-blue-50"
            } ${quickstartTargetComponentClassName || ""}`}
          >
            <div className="relative">
              <Icon
                className={
                  isSelected ? "fill-custom-blue-800" : "fill-custom-blue-800"
                }
                size={24}
              />

              {showParticipantBadge && requestedEntriesCount ? (
                <div className="animate-ping absolute -top-1 -right-1 bg-red-500 h-2 w-2 rounded-full"></div>
              ) : (
                <React.Fragment />
              )}
            </div>
            <p
              className={`mt-1 text-center font-normal text-xs ${
                isSelected ? "text-custom-blue-800" : "text-custom-blue-800"
              }`}
            >
              {title}
            </p>

            {isAnyGroupsChatUnreaded && showChatBadge ? (
              <div className="absolute top-1 left-1 h-3 w-3 bg-orange-400 text-white font-bold text-sm rounded-full flex items-center justify-center"></div>
            ) : (
              <React.Fragment />
            )}

            {unseenQNAMessagesOrApps && showQNABadge ? (
              <div className="absolute top-1 left-1 h-3 w-3 bg-orange-400 text-white font-bold text-sm rounded-full flex items-center justify-center"></div>
            ) : (
              <React.Fragment />
            )}

            {showParticipantBadge ? (
              <div className="absolute top-0 left-0 flex gap-0.5 flex-col">
                <Tooltip
                  title={`${participantsCount} speaker${
                    participantsCount > 1 ? "s" : ""
                  }`}
                >
                  <div
                    className={`h-5 ${
                      participantsCount >= 100
                        ? "w-8"
                        : participantsCount >= 10
                        ? "w-6"
                        : "w-5"
                    } bg-primary text-white font-bold text-sm rounded-full flex items-center justify-center`}
                  >
                    <p>{`${participantsCount}`} </p>
                  </div>
                </Tooltip>

                {joinedInterpreterParticipantIds?.length ? (
                  <Tooltip
                    title={`${
                      joinedInterpreterParticipantIds?.length
                    } Interpreter${
                      joinedInterpreterParticipantIds?.length > 1 ? "s" : ""
                    }`}
                  >
                    <div
                      className={`h-5 ${
                        joinedInterpreterParticipantIds?.length >= 100
                          ? "w-8"
                          : joinedInterpreterParticipantIds?.length >= 10
                          ? "w-6"
                          : "w-5"
                      } bg-orange-400 text-white font-bold text-sm rounded-full flex items-center justify-center`}
                    >
                      <p>{`${joinedInterpreterParticipantIds?.length}`} </p>
                    </div>
                  </Tooltip>
                ) : (
                  <React.Fragment />
                )}
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LateralSidebar;
