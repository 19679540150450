// import { usePubSub } from "@videosdk.live/react-sdk";
import { getPrivateChatGroupMessageTopicById } from "../../utils/pubSubTopics";
import { createUID } from "../../utils/createUID";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useEffect, useRef } from "react";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const usePrivateChatGroupMessages = (id: string) => {
  const { studioUserId } = useAppConfigContext();

  const studioUserIdRef = useRef(studioUserId);

  useEffect(() => {
    studioUserIdRef.current = studioUserId;
  }, [studioUserId]);

  // const { publish } = usePubSub(getPrivateChatGroupMessageTopicById(id));

  const { publish } = useAppSingalingPublish(
    getPrivateChatGroupMessageTopicById(id)
  );

  const sendMessage = ({ text }: { text: string }) => {
    const id = createUID();

    publish(
      JSON.stringify({
        payload: {
          id,
          type: "msg",
          text,
          senderId: studioUserIdRef.current,
        },
      })
      // { persist: true }
    );
  };

  return { sendMessage };
};

export default usePrivateChatGroupMessages;
