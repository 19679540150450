import { useEffect, useMemo, useRef, useState } from "react";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

const useIsChangeLocalWebcamInProgress = () => {
  const [changeWebcamInProgress, setChangeWebcamInProgress] = useState<
    { processId: string; initAt: number }[]
  >([]);

  const changeWebcamInProgressRef = useRef(changeWebcamInProgress);

  useEffect(() => {
    changeWebcamInProgressRef.current = changeWebcamInProgress;
  }, [changeWebcamInProgress]);

  const webcamProcessInterval = useRef<null | ReturnType<typeof setInterval>>();

  const _handleChangeWebcamInProgressEvent = ({
    changeWebcamInProgress,
    processId,
    initAt,
  }: {
    changeWebcamInProgress: boolean;
    processId: string;
    initAt: number;
  }) => {
    setChangeWebcamInProgress((s) =>
      changeWebcamInProgress
        ? [...s, { processId, initAt }]
        : s.filter(({ processId: _processId }) => processId !== _processId)
    );
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.CHANGE_WEBCAM_IN_PROGRESS,
      _handleChangeWebcamInProgressEvent
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.CHANGE_WEBCAM_IN_PROGRESS,
        _handleChangeWebcamInProgressEvent
      );
    };
  }, []);

  useEffect(() => {
    webcamProcessInterval.current = setInterval(() => {
      const changeWebcamInProgress = changeWebcamInProgressRef.current;

      const filteredChangeWebcamInProgress = changeWebcamInProgress.filter(
        (s) => s.initAt > new Date().getTime() - 2000
      );

      if (
        filteredChangeWebcamInProgress.length !== changeWebcamInProgress.length
      ) {
        setChangeWebcamInProgress(filteredChangeWebcamInProgress);
      }
    }, 2000);

    return () => {
      if (webcamProcessInterval.current) {
        clearInterval(webcamProcessInterval.current);
      }
    };
  }, []);

  const isChangeLocalWebcamInProgress = useMemo(
    () => changeWebcamInProgress.length > 0,
    [changeWebcamInProgress]
  );

  return { isChangeLocalWebcamInProgress };
};

export default useIsChangeLocalWebcamInProgress;
