import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import useParticipantName from "../hooks/appState/useParticipantName";
import { useAppContext } from "../contexts/appContextDef";
import { appPubSubTopics } from "../utils/pubSubTopics";
import useAppSingalingPublish from "../appSingaling/useAppSingalingPublish";

const ChangeNameModal = ({
  handleCancel,
  participantId,
}: {
  handleCancel: () => void;
  participantId: string;
}) => {
  const { name: _name, changeName } = useParticipantName(participantId);
  const { participantHeadlines } = useAppContext();

  const [name, setName] = useState(_name);
  const [participantHeadline, setParticipantHeadline] = useState(
    participantHeadlines[participantId] || ""
  );

  const { publish: participantHeadlinePublish } = useAppSingalingPublish(
    appPubSubTopics.PARTICIPANT_HEADLINE
  );

  const participantHeadlinePublishRef = useRef<appSingalingPublishType>(
    participantHeadlinePublish
  );

  useEffect(() => {
    participantHeadlinePublishRef.current = participantHeadlinePublish;
  }, [participantHeadlinePublish]);

  return (
    <React.Fragment>
      <Transition appear show={true} as={Fragment}>
        <Dialog
          style={{ zIndex: 99999 }}
          as="div"
          className="relative z-10"
          onClose={handleCancel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Edit Display Name
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="form-control w-full max-w-xs">
                      <label className="label">
                        <span className="label-text">Display name</span>
                      </label>
                      <input
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        type="text"
                        placeholder="Edit Display Name"
                        className="input input-bordered w-full max-w-xs"
                      />
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className="form-control w-full max-w-xs">
                      <label className="label">
                        <span className="label-text">Headline (Optional)</span>
                      </label>
                      <input
                        value={participantHeadline}
                        onChange={(e) => {
                          setParticipantHeadline(e.target.value);
                        }}
                        type="text"
                        placeholder="Edit Display Name"
                        className="input input-bordered w-full max-w-xs"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-custom-blue-100 px-4 py-2 text-sm font-medium text-custom-blue-900 hover:bg-custom-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-custom-blue-500 focus-visible:ring-offset-2"
                      onClick={async () => {
                        changeName(name);

                        participantHeadlinePublishRef.current(
                          JSON.stringify({
                            participantHeadline: participantHeadline || "",
                            participantId,
                          })
                        );
                        handleCancel();
                      }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-custom-blue-100 px-4 py-2 text-sm font-medium text-custom-blue-900 hover:bg-custom-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-custom-blue-500 focus-visible:ring-offset-2"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};

export default ChangeNameModal;
