import React, { useEffect, useMemo, useRef, useState } from "react";
import MainViewFileShareStreamContainer from "./MainViewFileShareStreamContainer";
import MainViewScreenShareStreamContainer from "./MainViewScreenShareStreamContainer";
import MainViewWebcamStreamContainer from "./MainViewWebcamStreamContainer";
import MainViewVideoShareStreamContainer from "./MainViewVideoShareStreamContainer";
import { useAppContext } from "../../contexts/appContextDef";
import useMainViewLayout from "../../hooks/appState/useMainViewLayout";
import MainViewAppWordcloudShareStreamContainer from "./MainViewAppWordcloudShareStreamContainer";
import MainViewAppPollShareStreamContainer from "./MainViewAppPollShareStreamContainer";
import MainViewQNAShareStreamContainer from "./MainViewQNAShareStreamContainer";
import getTransitionStyle from "../../utils/getTransitionStyle";
import { mainViewLayouts } from "../../utils/constants";
import MainViewInputFileVideoShareStreamContainer from "./MainViewInputFileVideoShareStreamContainer";
import MainViewExtraWebcamStreamContainer from "./MainViewExtraWebcamStreamContainer";
import MainViewRtmpInShareStreamContainer from "./MainViewRtmpInShareStreamContainer";

const MainViewParticipantContainer = ({
  relativeHeight,
  relativeLeft,
  relativeTop,
  relativeWidth,
  streamId,
  streamIdMeta,
  mainViewContainerWidth,
}: {
  relativeHeight: number;
  relativeLeft: number;
  relativeTop: number;
  relativeWidth: number;
  streamId: string;
  streamIdMeta: streamIdMetaType;
  mainViewContainerHeight: number;
  mainViewContainerWidth: number;
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => setMounted(true), 50);

    return () => {
      setTimeout(() => setMounted(false), 50);
    };
  }, []);

  const { interactivityMode, conferenceMainViewVisibleWebcamStreams } =
    useAppContext();

  const {
    isWebcamStream,
    isVideoShareStream,
    isScreenShareStream,
    isFileShareStream,
    fileId,
    videoId,
    participantId,
    isShareStream,
    isAppPollShareStream,
    isAppWordcloudShareStream,
    isQNAShareStream,
    pollId,
    wordcloudId,
    qnaId,
    inputFileVideoId,
    isInputFileVideoShareStream,
    extraWebcamId,
    isExtraWebcamStream,
    rtmpInId,
    isRtmpInShareStream,
  } = useMemo(() => streamIdMeta, [streamIdMeta]);

  const interactivityModeRef = useRef(interactivityMode);
  const conferenceMainViewVisibleWebcamStreamsRef = useRef(
    conferenceMainViewVisibleWebcamStreams
  );

  useEffect(() => {
    interactivityModeRef.current = interactivityMode;
  }, [interactivityMode]);
  useEffect(() => {
    conferenceMainViewVisibleWebcamStreamsRef.current =
      conferenceMainViewVisibleWebcamStreams;
  }, [conferenceMainViewVisibleWebcamStreams]);

  const { mainViewLayoutActive } = useMainViewLayout();

  const { shouldMaintainVideoAspectRatio } = useMemo(
    () => ({
      shouldMaintainVideoAspectRatio:
        !isShareStream &&
        (mainViewLayoutActive === mainViewLayouts.CROPPED
          ? false
          : mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE
          ? false
          : true),
    }),
    [mainViewLayoutActive, isShareStream]
  );

  const streamParentContainerEdgeStyle = useMemo(() => {
    const edge = isShareStream
      ? 0
      : isWebcamStream || isExtraWebcamStream
      ? mainViewLayoutActive === mainViewLayouts.CROPPED
        ? 0
        : mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE
        ? 0
        : "1.5%"
      : undefined;

    return {
      bottom: edge,
      left: edge,
      top: edge,
      right: edge,
      transition: getTransitionStyle(["bottom", "left", "top", "right"]),
    };
  }, [
    isShareStream,
    isExtraWebcamStream,
    isWebcamStream,
    mainViewLayoutActive,
  ]);

  const streamParentContainerClassName = useMemo(
    () =>
      `absolute ${
        isAppPollShareStream || isAppWordcloudShareStream || isQNAShareStream
          ? "bg-white"
          : isScreenShareStream
          ? "bg-gray-700"
          : "bg-transparent"
      }`,
    [
      isAppPollShareStream,
      isAppWordcloudShareStream,
      isQNAShareStream,
      isScreenShareStream,
    ]
  );

  const streamGrandParentContainerStyle = useMemo(
    () => ({
      top:
        shouldMaintainVideoAspectRatio &&
        (isWebcamStream || isExtraWebcamStream)
          ? "50%"
          : undefined,
      transform:
        shouldMaintainVideoAspectRatio &&
        (isWebcamStream || isExtraWebcamStream)
          ? "translate(0%, -50%)"
          : undefined,
      transition: getTransitionStyle([
        "transform",
        "top",
        "right",
        "left",
        "bottom",
        "aspect-ratio",
        "height",
        "padding-top",
      ]),
    }),
    [shouldMaintainVideoAspectRatio, isWebcamStream, isExtraWebcamStream]
  );

  const { streamGrandParentContainerClassName } = useMemo(() => {
    const aspectVideoClassName = "aspect-video";

    const streamGrandParentContainerClassName = `absolute ${
      isShareStream
        ? "top-0 bottom-0 right-0 left-0"
        : mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE &&
          relativeWidth > relativeHeight &&
          (isWebcamStream || isExtraWebcamStream)
        ? `top-0 bottom-0 ${aspectVideoClassName}`
        : shouldMaintainVideoAspectRatio
        ? `${aspectVideoClassName} right-0 left-0`
        : "top-0 bottom-0 right-0 left-0"
    }`;

    const streamGrandParentContainerAspectVideoStyles =
      streamGrandParentContainerClassName.includes(aspectVideoClassName)
        ? { height: 0, paddingTop: `56.25%`, overflow: "hidden" }
        : { height: "100%", paddingTop: 0, overflow: undefined };

    return {
      streamGrandParentContainerAspectVideoStyles,
      streamGrandParentContainerClassName: streamGrandParentContainerClassName, //.replace(aspectVideoClassName, ""),
    };
  }, [
    isShareStream,
    mainViewLayoutActive,
    relativeWidth,
    relativeHeight,
    isWebcamStream,
    isExtraWebcamStream,
    shouldMaintainVideoAspectRatio,
  ]);

  const { Component, props } = useMemo(() => {
    const {
      Component,
      props,
    }: {
      Component: React.ElementType;
      props:
        | {
            participantId: string;
            relativeWidth: number;
            mainViewContainerWidth: number;
          }
        | { participantId: string; fileId: string }
        | { participantId: string; videoId: string }
        | {
            pollId: string;
            relativeWidth: number;
            mainViewContainerWidth: number;
          }
        | {
            wordcloudId: string;
            relativeWidth: number;
            mainViewContainerWidth: number;
          }
        | {
            qnaId: string;
            relativeWidth: number;
            mainViewContainerWidth: number;
          }
        | {
            inputFileVideoId: string;
            relativeWidth: number;
            mainViewContainerWidth: number;
          }
        | {
            rtmpInId: string;
            relativeWidth: number;
            mainViewContainerWidth: number;
          }
        | object;
    } = isWebcamStream
      ? {
          Component: MainViewWebcamStreamContainer,
          props: {
            streamId,
            participantId,
            relativeWidth,
            mainViewContainerWidth,
          },
        }
      : isScreenShareStream
      ? {
          Component: MainViewScreenShareStreamContainer,
          props: { participantId, relativeWidth, mainViewContainerWidth },
        }
      : isFileShareStream
      ? {
          Component: MainViewFileShareStreamContainer,
          props: { participantId, fileId },
        }
      : isVideoShareStream
      ? {
          Component: MainViewVideoShareStreamContainer,
          props: { participantId, videoId },
        }
      : isAppPollShareStream
      ? {
          Component: MainViewAppPollShareStreamContainer,
          props: { pollId, relativeWidth, mainViewContainerWidth },
        }
      : isAppWordcloudShareStream
      ? {
          Component: MainViewAppWordcloudShareStreamContainer,
          props: { wordcloudId, relativeWidth, mainViewContainerWidth },
        }
      : isQNAShareStream
      ? {
          Component: MainViewQNAShareStreamContainer,
          props: { qnaId, relativeWidth, mainViewContainerWidth },
        }
      : isInputFileVideoShareStream
      ? {
          Component: MainViewInputFileVideoShareStreamContainer,
          props: {
            participantId,
            inputFileVideoId,
            relativeWidth,
            mainViewContainerWidth,
          },
        }
      : isExtraWebcamStream
      ? {
          Component: MainViewExtraWebcamStreamContainer,
          props: {
            streamId,
            participantId,
            relativeWidth,
            mainViewContainerWidth,
            extraWebcamId,
          },
        }
      : isRtmpInShareStream
      ? {
          Component: MainViewRtmpInShareStreamContainer,
          props: {
            relativeWidth,
            mainViewContainerWidth,
            rtmpInId,
          },
        }
      : { Component: React.Fragment, props: {} };

    return { Component, props };
  }, [
    streamId,

    participantId,
    relativeWidth,
    mainViewContainerWidth,
    fileId,
    videoId,
    pollId,
    wordcloudId,
    qnaId,
    inputFileVideoId,
    extraWebcamId,
    rtmpInId,
    isInputFileVideoShareStream,
    isWebcamStream,
    isScreenShareStream,
    isFileShareStream,
    isVideoShareStream,
    isAppPollShareStream,
    isAppWordcloudShareStream,
    isQNAShareStream,
    isExtraWebcamStream,
    isRtmpInShareStream,
  ]);

  return (
    <div
      style={{
        position: "absolute",
        height: `${relativeHeight}%`,
        left: `${relativeLeft}%`,
        top: `${relativeTop}%`,
        width: `${relativeWidth}%`,
        transform: `scale(${mounted ? 1 : 0})`,
        transition: getTransitionStyle([
          "height",
          "left",
          "top",
          "width",
          "transform",
        ]),
      }}
    >
      <div className={`h-full relative w-full`}>
        <div
          id={`MVPCD-${streamId}`}
          style={{ ...streamGrandParentContainerStyle }}
          className={streamGrandParentContainerClassName}
        >
          <div
            style={streamParentContainerEdgeStyle}
            className={streamParentContainerClassName}
          >
            <Component {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainViewParticipantContainer;
