import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../utils/appEventEmitter";

const useAppSingalingPublish = (topic: string) => {
  const publish: appSingalingPublishType = (message: string) => {
    appEventEmitter.emit(appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH, {
      topic,
      message,
    });
  };

  return { publish };
};

export default useAppSingalingPublish;
