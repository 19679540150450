import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { MdClose } from "react-icons/md";

export default function Modal({
  title,
  isOpen,
  renderBody,
  cancelText,
  successText,
  onSuccess,
  onClose,
  renderOnlyBody,
  showCloseIcon,
  showSecondaryButton,
  onSecondaryButtonClick,
  secondaryButtonText,
}: {
  title?: string;
  isOpen: boolean;
  renderBody?: () => React.ReactNode;
  cancelText?: string;
  successText?: string;
  onSuccess?: () => void;
  onClose: () => void;
  renderOnlyBody?: boolean;
  showCloseIcon?: boolean;
  showSecondaryButton?: boolean;
  onSecondaryButtonClick?: () => void;
  secondaryButtonText?: string;
}) {
  return (
    <React.Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          style={{ zIndex: 99999 }}
          as="div"
          className="relative"
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all border-2 border-custom-blue-900">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="flex items-center justify-center">
                      <p className="flex flex-1">{title}</p>
                      {showCloseIcon ? (
                        <button
                          onClick={() => {
                            onClose();
                          }}
                          className="btn btn-ghost btn-sm"
                        >
                          <MdClose />
                        </button>
                      ) : null}
                    </div>
                  </Dialog.Title>
                  <div className={renderOnlyBody && !title ? "" : "mt-6"}>
                    {typeof renderBody === "function" && renderBody()}
                  </div>

                  {renderOnlyBody ? null : (
                    <React.Fragment>
                      <div className="mt-6 flex gap-3">
                        <button
                          type="button"
                          className="btn btn-primary text-white normal-case btn-outline btn-sm"
                          onClick={() => {
                            if (typeof onSuccess === "function") {
                              onSuccess();
                            }

                            onClose();
                          }}
                        >
                          {successText}
                        </button>

                        {showSecondaryButton ? (
                          <button
                            type="button"
                            className="btn btn-primary text-white normal-case btn-outline btn-sm"
                            onClick={() => {
                              if (
                                typeof onSecondaryButtonClick === "function"
                              ) {
                                onSecondaryButtonClick();
                              }

                              onClose();
                            }}
                          >
                            {secondaryButtonText}
                          </button>
                        ) : (
                          <React.Fragment />
                        )}

                        <button
                          type="button"
                          className="btn btn-primary text-white normal-case btn-outline btn-error btn-sm"
                          onClick={onClose}
                        >
                          {cancelText}
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
}
