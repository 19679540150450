import React, { useCallback, useEffect, useRef } from "react";
import { firebaseDB } from "../../../utils/firebase";
import { ref, onChildChanged } from "firebase/database";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import { useAppContext } from "../../../contexts/appContextDef";

const PollListner = ({ id }: { id: string }) => {
  const { broadcastId } = useAppConfigContext();
  const { setPolls, setUnseenActivatedApps } = useAppContext();

  const broadcastIdRef = useRef(broadcastId);

  const idRef = useRef(id);

  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);

  useEffect(() => {
    idRef.current = id;
  }, [id]);

  const subscribePoll = useCallback(() => {
    const pollRef = ref(
      firebaseDB,
      `broadcastPoll/${broadcastIdRef.current}/${idRef.current}`
    );

    const unsubscribe = onChildChanged(
      pollRef,
      (snapshot) => {
        const value = snapshot.val();

        const pollId = idRef.current;

        setPolls((s) => {
          const index = s.findIndex(({ id }) => `${id}` === `${pollId}`);

          if (index !== -1) {
            s[index] = {
              ...s[index],
              [`${snapshot.key}`]: value,
            };

            return [...s];
          } else {
            return [...s];
          }
        });

        setTimeout(() => {
          if (snapshot.key === "status") {
            setUnseenActivatedApps((s) => {
              const _s = [...s].filter(({ type }) => type !== "poll");

              if (value) {
                _s.push({ type: "poll", id: pollId });
              }

              return _s;
            });
          }
        }, 1000);
      },
      () => {}
    );

    return { unsubscribe };
  }, [setPolls, setUnseenActivatedApps]);

  useEffect(() => {
    const { unsubscribe } = subscribePoll();

    return () => {
      unsubscribe();
    };
  }, [subscribePoll]);

  return <React.Fragment />;
};

export default PollListner;
