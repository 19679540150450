import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import ChangeNameModal from "../../components/ChangeNameModal";
import { Menu, MenuItem } from "@szhsin/react-menu";
import useParticipantAvatar from "../../hooks/appState/useParticipantAvatar";
import ChangeAvatarModal from "../../components/ChangeAvatarModal";
import useToggleParticipantMedia from "../../hooks/appState/useToggleParticipantMedia";
import {
  MdPerson,
  MdMoreVert,
  MdEdit,
  MdMic,
  MdMicOff,
  MdAccountCircle,
  MdCancel,
  MdRemoveCircle,
  MdWarning,
  MdVideocam,
  MdVideocamOff,
  MdPanTool,
  MdPushPin,
  MdOutlinePushPin,
  MdOutlinePersonOutline,
} from "react-icons/md";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import useRaiseHand from "../../hooks/appState/useRaiseHand";
import {
  generateStreamId,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import useParticipantName from "../../hooks/appState/useParticipantName";
import usePinnedParticipantsPosition from "../../hooks/conference/usePinnedParticipantsPosition";
import useMainViewSoloModeSelectedParticipantId from "../../hooks/appState/useMainViewSoloModeSelectedParticipantId";
import { signalLevels } from "../../hooks/appState/useValidateConnection";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import useIsParticipantMicDenied from "../../hooks/appState/useIsParticipantMicDenied";
import useIsParticipantCameraDenied from "../../hooks/appState/useIsParticipantCameraDenied";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";
import { appModes, interactivityModes } from "../../utils/constants";
import useAskToLeave from "../../hooks/appState/useAskToLeave";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
import useAppRtcParticipantMediaStats from "../../appRtc/useAppRtcParticipantMediaStats";

const SignalBarIcon = ({ signalLevel }: { signalLevel?: string }) => {
  const barClassName = useMemo(
    () =>
      `${
        signalLevel === signalLevels.good
          ? "bg-green-500"
          : signalLevel === signalLevels.average
          ? "bg-orange-400"
          : "bg-red-500"
      }`,
    [signalLevel]
  );

  return (
    <div
      style={{ height: 16, width: 16 }}
      className="flex items-center justify-center"
    >
      <div style={{ height: 12, width: 16 }} className="flex items-end">
        <div
          style={{ width: "20%", height: `${(100 / 6) * 2}%` }}
          className={barClassName}
        ></div>
        <div style={{ width: "15%" }} className="h-0"></div>
        <div
          style={{
            width: "20%",
            height: `${(100 / 6) * 4}%`,
            opacity: signalLevel === signalLevels.bad ? 0.3 : 1,
          }}
          className={barClassName}
        ></div>
        <div style={{ width: "15%" }} className="h-0"></div>
        <div
          style={{
            width: "20%",
            height: `${100}%`,
            opacity: signalLevel === signalLevels.good ? 1 : 0.3,
          }}
          className={barClassName}
        ></div>
      </div>
    </div>
  );
};

export const ParticipantNetworkScoreIconContainer = ({
  participantId,
}: {
  participantId: string;
}) => {
  const { allParticipantsNetworkSignalLevel } = useAppContext();

  const { props, Component } = useMemo(() => {
    const signalLevel = allParticipantsNetworkSignalLevel.get(participantId);

    return signalLevel === signalLevels.good
      ? { props: { signalLevel }, Component: SignalBarIcon }
      : signalLevel === signalLevels.average
      ? { props: { signalLevel }, Component: SignalBarIcon }
      : signalLevel === signalLevels.bad
      ? { props: { signalLevel }, Component: SignalBarIcon }
      : { props: {}, Component: React.Fragment };
  }, [allParticipantsNetworkSignalLevel, participantId]);

  return <Component {...props} />;
};

const ItemMenuContainer = ({
  setChangeNameModalOpen,
  onRemove,
  isInMainView,
  removeFromMainViewStreams,
  isLocal,
  onStopMicAndCam,
  onEditAvatar,
  isDummy,
  cameraAndMicDenied,
  webcamOn,
  micOn,
}: {
  setChangeNameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRemove: () => void;
  isInMainView: boolean;
  removeFromMainViewStreams: () => void;
  isLocal: boolean;
  onStopMicAndCam: () => void;
  onEditAvatar: () => void;
  isDummy: boolean;
  cameraAndMicDenied: boolean;
  webcamOn: boolean;
  micOn: boolean;
}) => {
  const { appMode } = useAppContext();

  return (
    <Menu
      arrow
      direction="right"
      position="initial"
      menuButton={
        <span>
          <button
            className={`btn btn-primary text-white btn-square btn-xs rounded-none rounded-r-md ${
              appMode === appModes.SPEAKER
                ? "rounded-l-md"
                : appMode === appModes.HOST && cameraAndMicDenied
                ? "rounded-l-md"
                : ""
            }`}
          >
            <div>
              <MdMoreVert size={16} />
            </div>
          </button>
        </span>
      }
      transition
    >
      {appMode === appModes.SPEAKER && isInMainView && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={removeFromMainViewStreams}
        >
          <div className="flex items-center justify-center">
            <MdRemoveCircle />
            <p className="ml-2">Remove from stream</p>
          </div>
        </MenuItem>
      )}
      {(appMode === appModes.HOST || isLocal) && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            setChangeNameModalOpen(true);
          }}
        >
          <div className="flex items-center justify-center">
            <MdEdit />
            <p className="ml-2">Edit name</p>
          </div>
        </MenuItem>
      )}
      {isLocal && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={onEditAvatar}
        >
          <div className="flex items-center justify-center">
            <MdAccountCircle />
            <p className="ml-2">Edit audio avatar</p>
          </div>
        </MenuItem>
      )}
      {isLocal && !isDummy && (webcamOn || micOn) && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={onStopMicAndCam}
        >
          <div className="flex items-center justify-center">
            {webcamOn ? <MdVideocamOff /> : <MdMicOff />}
            <p className="ml-2">Stop mic/cam</p>
          </div>
        </MenuItem>
      )}
      {appMode === appModes.HOST && !isLocal && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={onRemove}
        >
          <div className="flex items-center justify-center">
            <MdCancel />
            <p className="ml-2">Kick from studio</p>
          </div>
        </MenuItem>
      )}
    </Menu>
  );
};

const InQueueWebcamStreamContainer = ({
  participantId,
  isDummy,
}: {
  participantId: string;
  isDummy: boolean;
}) => {
  const { webcamTrack } = useAppRtcParticipantMediaStats(participantId);

  const { isLocal } = useIsLocalParticipantId(participantId);

  const { webcamOn, micOn } = useParticipantMediaStats(participantId);

  const micOnRef = useRef(micOn);
  const webcamOnRef = useRef(webcamOn);

  useEffect(() => {
    webcamOnRef.current = webcamOn;
  }, [webcamOn]);
  useEffect(() => {
    micOnRef.current = micOn;
  }, [micOn]);

  const { turnOnMic, turnOffMic, turnOffWebcam, turnOnWebcam } =
    useToggleParticipantMedia(participantId);

  const {
    appMode,
    mainViewSelectedStreams,
    inQueueItemContainerWidth,
    mirrorLocalWebcam,
    raiseHandParticipantIds,
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
    mainViewSoloModeParticipantId,
  } = useAppContext();

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const { askToLeave } = useAskToLeave(participantId);

  const { micDenied } = useIsParticipantMicDenied({ participantId });
  const { cameraDenied } = useIsParticipantCameraDenied({ participantId });

  const cameraOrMicDenied = useMemo(
    () => cameraDenied || micDenied,
    [cameraDenied, micDenied]
  );

  const cameraAndMicDenied = useMemo(
    () => cameraDenied && micDenied,
    [cameraDenied, micDenied]
  );

  const { name } = useParticipantName(participantId);
  const { avatarRemoteUrl, changeAvatar } = useParticipantAvatar(participantId);

  const [changeNameModalOpen, setChangeNameModalOpen] = useState(false);
  const [changeAvatarModalOpen, setChangeAvatarModalOpen] = useState(false);

  const {
    isInMainView,
    pinned: confPinned,
    index: confPinnedIndex,
  } = useMemo(() => {
    if (interactivityMode === interactivityModes.STUDIO) {
      const streamId = generateStreamId({
        participantId,
        type: streamTypes.WEBCAM,
      });

      const isInMainView = !!mainViewSelectedStreams.get(streamId);

      return { isInMainView };
    } else {
      const isInConferenceMainViewIndex =
        conferenceMainViewVisibleWebcamStreams.findIndex(
          ({ participantId: _participantId }) =>
            _participantId === participantId
        );

      const isInConferenceMainView =
        conferenceMainViewVisibleWebcamStreams[isInConferenceMainViewIndex];

      return {
        pinned: isInConferenceMainView?.pinned,
        index: isInConferenceMainViewIndex,
        isInMainView: isInConferenceMainViewIndex !== -1,
      };
    }
  }, [
    participantId,
    mainViewSelectedStreams,
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
  ]);

  const isInMainViewRef = useRef(isInMainView);

  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);

  const flipStyle = useMemo(
    () =>
      isLocal && mirrorLocalWebcam
        ? { transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }
        : {},
    [isLocal, mirrorLocalWebcam]
  );

  const _handleAddToMainViewStreams = () => {
    addToMainViewStreams({
      participantId,
      type: streamTypes.WEBCAM,
    });
  };

  const _handleRemoveFromMainViewStreams = () => {
    removeFromMainViewStreams({
      participantId,
      type: streamTypes.WEBCAM,
    });
  };

  const _handleToggleFromMainViewStreams = () => {
    const _isInMainView = isInMainViewRef.current;

    if (_isInMainView) {
      _handleRemoveFromMainViewStreams();
    } else {
      _handleAddToMainViewStreams();
    }
  };

  const _handleRemoveParticipant = () => {
    // TODO: confirm to remove
    // participant.remove();

    askToLeave();
  };

  const _handleEditAvatar = () => {
    setChangeAvatarModalOpen(true);
  };

  const _handleStopMicAndCam = () => {
    turnOffMic();
    setTimeout(turnOffWebcam, 1000);
  };

  const _handleToggleMic = () => {
    if (micOnRef.current) {
      turnOffMic();
    } else {
      turnOnMic();
    }
  };

  const _handleToggleWebcam = () => {
    if (webcamOnRef.current) {
      turnOffWebcam();
    } else {
      turnOnWebcam();
    }
  };

  const isSoloParticipant = useMemo(
    () => mainViewSoloModeParticipantId === participantId,
    [mainViewSoloModeParticipantId, participantId]
  );

  const { disableRaiseHand, enableRaiseHand } = useRaiseHand(participantId);

  const participantRaisedHand = useMemo(
    () => raiseHandParticipantIds.includes(participantId),
    [raiseHandParticipantIds, participantId]
  );

  const participantRaisedHandRef = useRef(participantRaisedHand);
  const isSoloParticipantRef = useRef(isSoloParticipant);

  useEffect(() => {
    participantRaisedHandRef.current = participantRaisedHand;
  }, [participantRaisedHand]);

  const toggleRaiseHand = () => {
    if (participantRaisedHandRef.current) {
      disableRaiseHand();
    } else {
      enableRaiseHand();
    }
  };

  const pinnedRef = useRef(confPinned);
  const indexRef = useRef(confPinnedIndex);

  useEffect(() => {
    pinnedRef.current = confPinned;
  }, [confPinned]);
  useEffect(() => {
    indexRef.current = confPinnedIndex;
  }, [confPinnedIndex]);
  useEffect(() => {
    isSoloParticipantRef.current = isSoloParticipant;
  }, [isSoloParticipant]);

  const { pin, unPin } = usePinnedParticipantsPosition();

  const _handleTogglePin = () => {
    if (pinnedRef.current) {
      unPin({ participantId });
    } else {
      pin({ participantId, positionIndex: parseInt(`${indexRef.current}`) });
    }
  };

  const { deselect: deselectFromSoloMode, select: selectAsSoloMode } =
    useMainViewSoloModeSelectedParticipantId();

  const _addToSoloMode = () => {
    selectAsSoloMode({ participantId });
  };

  const _removeFromSoloMode = () => {
    deselectFromSoloMode();
  };

  const _handleToggleSoloMode = () => {
    if (isSoloParticipantRef.current) {
      _removeFromSoloMode();
    } else {
      _addToSoloMode();
    }
  };

  return (
    <React.Fragment>
      <div
        className={`${
          isLocal
            ? quickstartTargetComponentClassNames.in_queue_local_participant_webcam_stream_container
            : ""
        }`}
        style={{ width: inQueueItemContainerWidth }}
      >
        <div
          style={{
            height: (inQueueItemContainerWidth * 9) / 16,
            width: inQueueItemContainerWidth,
          }}
          className="relative video-cover bg-custom-blue-50"
        >
          {webcamOn ? (
            <div
              id={`InQueueWebcamStreamContainer-${participantId}`}
              className="h-full w-full rounded-t-md overflow-hidden relative"
            >
              <VideoMediaStreamPlayer
                {...{
                  flipStyle,
                  videoOn: webcamOn,
                  videoTrack: webcamTrack,
                  key: `VideoMediaStreamPlayer-webcam-stream-${webcamTrack?.id}-${participantId}`,
                  participantId,
                  isWebcam: true,
                }}
              />
            </div>
          ) : (
            <div className="flex h-full w-full items-center justify-center rounded-t-md overflow-hidden">
              <div className="rounded-full p-3">
                {isDummy || cameraAndMicDenied ? (
                  <div className="flex flex-col items-center justify-center">
                    {cameraOrMicDenied ? (
                      <MdWarning size={32} className={"fill-custom-blue-400"} />
                    ) : (
                      <div className="h-18 w-18 aspect-square rounded-full">
                        <img
                          alt={`in-queue-webcam-stream-avatar`}
                          className="w-10 h-10 rounded-full"
                          src={avatarRemoteUrl}
                        />
                      </div>
                    )}
                    <p className="text-xs text-custom-blue-400">
                      {cameraOrMicDenied ? "Device not connected" : ""}
                    </p>
                  </div>
                ) : (
                  <img
                    alt={`in-queue-webcam-stream-avatar`}
                    className="w-10 h-10 rounded-full"
                    src={avatarRemoteUrl}
                  />
                )}
              </div>
            </div>
          )}

          <div className="top-0 left-0 right-0 bottom-0 absolute flex justify-between">
            {/* only if host */}
            {appMode === appModes.HOST &&
              interactivityMode === interactivityModes.STUDIO &&
              (!cameraDenied || !micDenied) &&
              !isDummy && (
                <div className="p-3 flex flex-1 hover:opacity-100 opacity-0 items-end justify-center">
                  <button
                    className="btn btn-primary text-white btn-active"
                    onClick={_handleToggleFromMainViewStreams}
                  >
                    {isInMainView ? "Remove" : "Add to stream"}
                  </button>
                </div>
              )}

            {(appMode === appModes.HOST || isLocal) && (
              <div className="absolute top-2 left-2 flex items-center justify-center ">
                {appMode === appModes.HOST &&
                interactivityMode === interactivityModes.CONFERENCE ? (
                  <div
                    onClick={() => {
                      _handleTogglePin();
                    }}
                    className="cursor-pointer  rounded-md p-0.5 fill-custom-blue-600 bg-custom-blue-200"
                  >
                    {confPinned ? (
                      <MdPushPin className={"h-4 w-4 fill-custom-blue-600"} />
                    ) : (
                      <MdOutlinePushPin
                        className={"h-4 w-4 fill-custom-blue-600"}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      _handleToggleSoloMode();
                    }}
                    className={`cursor-pointer h-8 flex items-center justify-center aspect-square rounded p-0.5 fill-custom-blue-600 ${
                      isSoloParticipant ? "bg-primary" : "bg-custom-blue-100"
                    }`}
                  >
                    {isSoloParticipant ? (
                      <MdPerson className={"h-6 w-6 fill-white"} />
                    ) : (
                      <MdOutlinePersonOutline
                        className={"h-6 w-6 fill-custom-blue-400"}
                      />
                    )}
                  </div>
                )}

                {participantRaisedHand ? (
                  <div
                    onClick={() => {
                      toggleRaiseHand();
                    }}
                    className="cursor-pointer ml-1 rounded-md p-0.5 fill-custom-blue-700 bg-custom-blue-100"
                  >
                    <MdPanTool className={"h-4 w-4 fill-custom-blue-700"} />
                  </div>
                ) : null}
              </div>
            )}

            <div className="absolute top-2 right-2 flex">
              {appMode === appModes.HOST && !cameraDenied && (
                <button
                  className="btn btn-primary text-white btn-square btn-xs rounded-none rounded-l-md"
                  onClick={_handleToggleWebcam}
                >
                  <div>
                    {webcamOn ? (
                      <MdVideocam size={16} />
                    ) : (
                      <MdVideocamOff size={16} />
                    )}
                  </div>
                </button>
              )}
              {appMode === appModes.HOST && !micDenied && (
                <button
                  className={`btn btn-primary text-white btn-square btn-xs rounded-none ${
                    cameraDenied ? "rounded-l-md" : ""
                  }`}
                  onClick={_handleToggleMic}
                >
                  <div>
                    {micOn ? <MdMic size={16} /> : <MdMicOff size={16} />}
                  </div>
                </button>
              )}
              <ItemMenuContainer
                {...{
                  isDummy,
                  isInMainView: isInMainView,
                  isLocal: isLocal,
                  onEditAvatar: _handleEditAvatar,
                  onRemove: _handleRemoveParticipant,
                  onStopMicAndCam: _handleStopMicAndCam,
                  setChangeNameModalOpen: setChangeNameModalOpen,
                  removeFromMainViewStreams: _handleRemoveFromMainViewStreams,
                  cameraAndMicDenied,
                  webcamOn,
                  micOn,
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex items-center p-0.5 ${
            isInMainView
              ? "bg-primary text-white fill-white"
              : "bg-white fill-gray-800 text-gray-800"
          } rounded-b-md overflow-hidden gap-0.5`}
        >
          <div className="w-6 flex items-center justify-center">
            <MdPerson size={16} />
          </div>
          <p className="truncate text-ellipsis text-sm flex-1">{name}</p>
          <ParticipantNetworkScoreIconContainer {...{ participantId }} />
        </div>
      </div>
      {changeAvatarModalOpen && (
        <ChangeAvatarModal
          {...{
            isOpen: changeAvatarModalOpen,
            handleCancel: () => {
              setChangeAvatarModalOpen(false);
            },
            handleSave: ({ remoteUrl, isOld }) => {
              changeAvatar({ avatarRemoteUrl: remoteUrl, isOld });
              setChangeAvatarModalOpen(false);
            },
            src: avatarRemoteUrl,
          }}
        />
      )}
      {changeNameModalOpen && (
        <ChangeNameModal
          {...{
            participantId,
            handleCancel: () => {
              setChangeNameModalOpen(false);
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

export default InQueueWebcamStreamContainer;
