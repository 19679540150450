import React from "react";
import BannerFoldersListner from "./BannerFoldersListner";
import BannersListner from "./BannersListner";
import ActiveBannerListner from "./ActiveBannerListner";

const AllBannersListner = () => {
  return (
    <React.Fragment>
      <ActiveBannerListner />
      <BannerFoldersListner />
      <BannersListner />
    </React.Fragment>
  );
};

export default AllBannersListner;
