import React, { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { isHexLight } from "../../utils/isHexLight";
import Ticker from "react-ticker";
import useHighlightedChat from "../../hooks/appState/useHighlightedChat";
import Youtube from "../../static/brand-icons/Youtube";
import Facebook from "../../static/brand-icons/Facebook";
import Twitch from "../../static/brand-icons/Twitch";
import LinkedIn from "../../static/brand-icons/LinkedIn";

const LowerThirdBannerContainer = ({
  mainViewContainerWidth,
}: {
  mainViewContainerWidth: number;
}) => {
  const { activeLowerThirtdBanner, brandColor } = useAppContext();

  const { backgroundColor, isBackgroundLight } = useMemo(() => {
    const backgroundColor = brandColor?.hexColorCode || "#000000";

    const isBackgroundLight = isHexLight(backgroundColor);

    return {
      backgroundColor,
      isBackgroundLight,
    };
  }, [brandColor]);

  return (
    <React.Fragment>
      {activeLowerThirtdBanner ? (
        <div
          style={{
            marginBottom: mainViewContainerWidth * 0.01,
            marginLeft: mainViewContainerWidth * 0.01,
          }}
          className="flex items-start flex-col"
        >
          <div
            style={{
              paddingBottom: mainViewContainerWidth * 0.005,
              paddingTop: mainViewContainerWidth * 0.005,
              paddingLeft: mainViewContainerWidth * 0.01,
              paddingRight: mainViewContainerWidth * 0.01,
              borderRadius: mainViewContainerWidth * 0.006,
            }}
            className="bg-white text-black fill-black"
          >
            <p
              style={{ fontSize: mainViewContainerWidth * 0.035 }}
              className="font-bold"
            >
              {activeLowerThirtdBanner.primaryText}
            </p>
          </div>

          {activeLowerThirtdBanner.secondaryText ? (
            <div
              style={{
                backgroundColor,
                color: isBackgroundLight ? "#000" : "#fff",
                paddingTop: mainViewContainerWidth * 0.01,
                paddingBottom: mainViewContainerWidth * 0.01,
                paddingLeft: mainViewContainerWidth * 0.015,
                paddingRight: mainViewContainerWidth * 0.015,
                marginTop: mainViewContainerWidth * 0.005,
                borderRadius: mainViewContainerWidth * 0.006,
              }}
            >
              <p
                style={{ fontSize: mainViewContainerWidth * 0.02 }}
                className="font-medium"
              >
                {activeLowerThirtdBanner.secondaryText}
              </p>
            </div>
          ) : null}
        </div>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const TickerBannerContainer = ({
  mainViewContainerHeight,
  mainViewContainerWidth,
}: {
  mainViewContainerHeight: number;
  mainViewContainerWidth: number;
}) => {
  const { activeTickerBanner, brandColor } = useAppContext();

  const { backgroundColor, isBackgroundLight } = useMemo(() => {
    const backgroundColor = brandColor?.hexColorCode || "#000000";

    const isBackgroundLight = isHexLight(backgroundColor);

    return {
      backgroundColor,
      isBackgroundLight,
    };
  }, [brandColor]);

  return (
    <React.Fragment>
      {activeTickerBanner ? (
        <div
          style={{
            fontSize: mainViewContainerHeight * 0.035,
            backgroundColor,
            color: isBackgroundLight ? "#000" : "#fff",
          }}
          className="font-bold"
        >
          <Ticker
            key={`${activeTickerBanner?.appBannerId}`}
            height={mainViewContainerHeight * 0.055}
            mode="await"
            offset={mainViewContainerWidth}
          >
            {() => <p>{activeTickerBanner?.primaryText}</p>}
          </Ticker>
        </div>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const HighlightedChatContainer = ({
  mainViewContainerWidth,
}: {
  mainViewContainerWidth: number;
}) => {
  const { highlightedChat } = useHighlightedChat();

  const { text, senderName, initials, socialPlatform } = useMemo(() => {
    const text = highlightedChat?.text;
    const senderName = highlightedChat?.senderName;
    const initials = highlightedChat?.initials;
    const socialPlatform = highlightedChat?.socialPlatform;

    return {
      text,
      senderName,
      initials,
      socialPlatform,
    };
  }, [highlightedChat]);

  const { brandColor } = useAppContext();

  const { backgroundColor, isBackgroundLight } = useMemo(() => {
    const backgroundColor = brandColor?.hexColorCode || "#000000";

    const isBackgroundLight = isHexLight(backgroundColor);

    return {
      backgroundColor,
      isBackgroundLight,
    };
  }, [brandColor]);

  const { socialPlatformWithInitials, socialType } = useMemo(() => {
    if (socialPlatform) {
      if (socialPlatform.length) {
        if (socialPlatform.length === 1) {
          return {
            socialPlatformWithInitials: true,
            socialType: socialPlatform[0],
          };
        } else {
          return {
            socialPlatformWithInitials: false,
            socialPlatformWithMessage: true,
            socialPlatforms: socialPlatform,
          };
        }
      } else {
        return { socialPlatformWithInitials: false };
      }
    } else {
      return { socialPlatformWithInitials: false };
    }
  }, [socialPlatform]);

  return highlightedChat?.enabled ? (
    <div
      style={{
        marginLeft: mainViewContainerWidth * 0.01,
        marginBottom: mainViewContainerWidth * 0.01,
      }}
      className="flex flex-col items-start justify-start"
    >
      <div
        style={{
          padding: mainViewContainerWidth * 0.007,
          backgroundColor,
          color: isBackgroundLight ? "#000" : "#fff",
          zIndex: 10,
        }}
        className="flex rounded-full items-center"
      >
        <div
          style={{
            height: mainViewContainerWidth * 0.04,
            width: mainViewContainerWidth * 0.04,
            fontSize: mainViewContainerWidth * 0.02,
            backgroundColor: isBackgroundLight ? "#00000033" : "#ffffff33",
          }}
          className="flex items-center justify-center rounded-full relative"
        >
          {initials}

          {socialPlatformWithInitials ? (
            <div
              style={{
                right: -mainViewContainerWidth * 0.004,
                bottom: -mainViewContainerWidth * 0.004,
                height: mainViewContainerWidth * 0.016,
                width: mainViewContainerWidth * 0.016,
                backgroundColor:
                  socialType === "youtube"
                    ? "rgb(254,1,0)"
                    : socialType === "facebook"
                    ? "#1877f2"
                    : socialType === "twitch"
                    ? "#6441A4"
                    : socialType === "linkedin"
                    ? "#0A66C2"
                    : "",
              }}
              className="absolute flex items-center justify-center rounded-sm"
            >
              {socialType === "youtube" ? (
                <Youtube
                  style={{
                    height: mainViewContainerWidth * 0.012,
                    width: mainViewContainerWidth * 0.012,
                  }}
                  className={"fill-white"}
                />
              ) : socialType === "facebook" ? (
                <Facebook
                  style={{
                    height: mainViewContainerWidth * 0.012,
                    width: mainViewContainerWidth * 0.012,
                  }}
                  className={"fill-white"}
                />
              ) : socialType === "twitch" ? (
                <Twitch
                  style={{
                    height: mainViewContainerWidth * 0.012,
                    width: mainViewContainerWidth * 0.012,
                  }}
                  className={"fill-white"}
                />
              ) : socialType === "linkedin" ? (
                <LinkedIn
                  style={{
                    height: mainViewContainerWidth * 0.012,
                    width: mainViewContainerWidth * 0.012,
                  }}
                  className={"fill-white"}
                />
              ) : (
                <React.Fragment />
              )}
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
        <div style={{ marginLeft: mainViewContainerWidth * 0.005 }}>
          <p
            style={{ fontSize: mainViewContainerWidth * 0.015 }}
            className="font-extrabold"
          >
            {senderName}
          </p>
        </div>
      </div>
      <div
        className="flex bg-white  text-black flex-col relative"
        style={{
          padding: mainViewContainerWidth * 0.015,
          maxWidth: mainViewContainerWidth * 0.4,
          marginTop: -mainViewContainerWidth * 0.01,
          marginLeft: mainViewContainerWidth * 0.02,
          borderRadius: mainViewContainerWidth * 0.02,
        }}
      >
        <p
          className="line-clamp-6"
          style={{ fontSize: mainViewContainerWidth * 0.017 }}
        >
          {text}
        </p>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

const BannerContainer = ({
  mainViewContainerHeight,
  mainViewContainerWidth,
}: {
  mainViewContainerHeight: number;
  mainViewContainerWidth: number;
}) => {
  return (
    <div
      style={{ fontFamily: "Lato" }}
      className="absolute  left-0 right-0 bottom-0 pointer-events-none"
    >
      <HighlightedChatContainer
        {...{ mainViewContainerHeight, mainViewContainerWidth }}
      />

      <LowerThirdBannerContainer
        {...{ mainViewContainerHeight, mainViewContainerWidth }}
      />

      <TickerBannerContainer
        {...{ mainViewContainerHeight, mainViewContainerWidth }}
      />
    </div>
  );
};

export default BannerContainer;
