import { useAppContext } from "../../../contexts/appContextDef";
import WordcloudsListItem from "./WordcloudsListItem";

const WordcloudsList = () => {
  const { wordclouds } = useAppContext();

  return wordclouds?.length ? (
    <div className="p-1">
      {wordclouds.map((wordcloud) => (
        <WordcloudsListItem
          id={`${wordcloud.id}`}
          key={`wordcloud-list-item-${wordcloud.id}`}
        />
      ))}
    </div>
  ) : (
    <div className="h-full w-full flex flex-1 items-center justify-center">
      No wordclouds
    </div>
  );
};

export default WordcloudsList;
