import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import React, { useEffect, useRef } from "react";
import sleep from "../../utils/sleep";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useIsOldest from "../../hooks/appState/useIsOldest";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";
import { quickstartDummyParticipants } from "../../utils/constants";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import useAppRtcAllParticipantsExtraWebcamStreams from "../../appRtc/useAppRtcAllParticipantsExtraWebcamStreams";

export const mainStreamActions = {
  ADD_STREAM: "ADD_STREAM",
  REMOVE_STREAM: "REMOVE_STREAM",
};

export const streamTypes = {
  WEBCAM: "WEBCAM",
  SHARE: "SHARE",
  EXTRAWBCM: "EXTRAWBCM",
};

// these should only include alphabets, no numbers, no symbols only alphabets
export const streamModes = {
  SCREEN: "SCREEN",
  PDF: "PDF",
  PPT: "PPT",
  VIDEO: "VIDEO",
  INPUTFILEVIDYO: "INPUTFILEVIDYO",
  APPPOLL: "APPPOLL",
  APPWORDCLOUD: "APPWORDCLOUD",
  QNA: "QNA",
  RTMP_IN: "RTMP_IN",
};

export const generateStreamId = ({
  participantId,
  type,
  mode,
  fileId,
  videoId,
  pollId,
  wordcloudId,
  qnaId,
  inputFileVideoId,
  extraWebcamId,
  rtmpInId,
}: {
  participantId: string;
  type: string;
  mode?: string;
  fileId?: string;
  videoId?: string;
  pollId?: string;
  wordcloudId?: string;
  qnaId?: string;
  inputFileVideoId?: string;
  extraWebcamId?: string;
  rtmpInId?: string;
}) => {
  const streamId =
    type === streamTypes.SHARE
      ? fileId
        ? `${participantId}_${type}_${mode}_${fileId}`
        : videoId
        ? `${participantId}_${type}_${mode}_${videoId}`
        : pollId
        ? `${participantId}_${type}_${mode}_${pollId}`
        : wordcloudId
        ? `${participantId}_${type}_${mode}_${wordcloudId}`
        : qnaId
        ? `${participantId}_${type}_${mode}_${qnaId}`
        : inputFileVideoId
        ? `${participantId}_${type}_${mode}_${inputFileVideoId}`
        : rtmpInId
        ? `${participantId}_${type}_${mode}_${rtmpInId}`
        : `${participantId}_${type}_${mode}`
      : type === streamTypes.EXTRAWBCM
      ? `${participantId}_${type}_${extraWebcamId}`
      : `${participantId}_${type}`;

  return streamId;
};

const MainViewParticipantsListner = () => {
  const {
    mainViewGrid,
    setMainViewGrid,
    mainViewSelectedStreams,
    setMainViewSelectedStreams,
  } = useAppContext();

  const { getIsOldestParticipant } = useIsOldest();
  const { participantIds } = useParticipantIdsAndCount();
  const { allParticipantsExtraWebcamStreams } =
    useAppRtcAllParticipantsExtraWebcamStreams();

  const mainViewSelectedStreamsRef = useRef(mainViewSelectedStreams);
  const mainViewGridRef = useRef(mainViewGrid);
  const participantIdsRef = useRef(participantIds);
  const allParticipantsExtraWebcamStreamsRef = useRef(
    allParticipantsExtraWebcamStreams
  );

  useEffect(() => {
    mainViewSelectedStreamsRef.current = mainViewSelectedStreams;
  }, [mainViewSelectedStreams]);
  useEffect(() => {
    mainViewGridRef.current = mainViewGrid;
  }, [mainViewGrid]);
  useEffect(() => {
    participantIdsRef.current = participantIds;
  }, [participantIds]);
  useEffect(() => {
    allParticipantsExtraWebcamStreamsRef.current =
      allParticipantsExtraWebcamStreams;
  }, [allParticipantsExtraWebcamStreams]);

  useAppSingalingSubscribe(
    appPubSubTopics.MAIN_VIEW_STREAMS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      let isWebcamStreamAdded = false;

      const _mainViewSelectedStreams = new Map(
        mainViewSelectedStreamsRef.current
      );

      if (message.action === mainStreamActions.ADD_STREAM) {
        _mainViewSelectedStreams.set(message.streamId, message);

        if (message.streamId.split("_")[1] === streamTypes.WEBCAM) {
          isWebcamStreamAdded = true;
        }
      } else if (message.action === mainStreamActions.REMOVE_STREAM) {
        _mainViewSelectedStreams.delete(message.streamId);
      }

      setMainViewSelectedStreams(_mainViewSelectedStreams);

      if (isWebcamStreamAdded) {
        setTimeout(() => {
          appEventEmitter.emit(
            appEventEmitterEvents.MAIN_VIEW_AUDIO_ONLY_PARTICIPANTS_POPUP,
            {}
          );
        }, 500);
      }
    }
  );

  const { publish: mainViewStreamsPublish } = useAppSingalingPublish(
    appPubSubTopics.MAIN_VIEW_STREAMS
  );

  useAppSingalingSubscribe(
    appPubSubTopics.MAIN_VIEW_GRID,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setMainViewGrid([...new Set(message as string[])]);
    }
  );

  const { publish: mainViewGridPublish } = useAppSingalingPublish(
    appPubSubTopics.MAIN_VIEW_GRID
  );

  const removeFromMainViewStreams = ({
    participantId,
    type,
    mode,
    fileId,
    videoId,
    pollId,
    wordcloudId,
    qnaId,
    inputFileVideoId,
    extraWebcamId,
    rtmpInId,
  }: {
    participantId?: string;
    type?: string;
    mode?: string;
    fileId?: string;
    videoId?: string;
    pollId?: string;
    wordcloudId?: string;
    qnaId?: string;
    inputFileVideoId?: string;
    extraWebcamId?: string;
    rtmpInId?: string;
  }) => {
    if (!participantId || !type) return;

    if (type === streamTypes.SHARE && !mode) return;

    const _mainViewSelectedStreams = new Map(
      mainViewSelectedStreamsRef.current
    );

    const streamId = generateStreamId({
      participantId,
      type,
      mode,
      fileId,
      videoId,
      pollId,
      wordcloudId,
      qnaId,
      inputFileVideoId,
      extraWebcamId,
      rtmpInId,
    });

    const streamDoesntExists = !_mainViewSelectedStreams.get(streamId);

    if (streamDoesntExists) return;

    const action = mainStreamActions.REMOVE_STREAM;

    mainViewStreamsPublish(
      JSON.stringify(
        type === streamTypes.SHARE
          ? {
              type,
              participantId,
              action,
              streamId,
              mode,
              fileId,
              videoId,
              pollId,
              wordcloudId,
              qnaId,
              inputFileVideoId,
              extraWebcamId,
              rtmpInId,
            }
          : { type, participantId, action, streamId }
      )
    );

    if (type === streamTypes.WEBCAM || type === streamTypes.EXTRAWBCM) {
      const _mainViewGrid = [...mainViewGridRef.current];

      const filteredMainViewGrid = _mainViewGrid.filter(
        (_streamId) => _streamId !== streamId
      );

      mainViewGridPublish(JSON.stringify(filteredMainViewGrid));
    }
  };

  const removeStreamIdFromMainViewStreams = ({
    streamId,
  }: {
    streamId: string;
  }) => {
    const [participantId, type, mode, fileIdOrVideoId] = streamId.split("_");

    removeFromMainViewStreams({
      participantId,
      type,
      mode: type === streamTypes.EXTRAWBCM ? undefined : mode,
      videoId: fileIdOrVideoId,
      fileId: fileIdOrVideoId,
      pollId: fileIdOrVideoId,
      wordcloudId: fileIdOrVideoId,
      qnaId: fileIdOrVideoId,
      inputFileVideoId: fileIdOrVideoId,
      extraWebcamId: type === streamTypes.EXTRAWBCM ? mode : fileIdOrVideoId,
      rtmpInId: fileIdOrVideoId,
    });
  };

  const removeParticipantFromMainViewStreams = async ({
    participantId,
  }: {
    participantId: string;
  }) => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) {
      return;
    }

    const _mainViewSelectedStreams = new Map(
      mainViewSelectedStreamsRef.current
    );

    const streamIdsToBeDeleted = [..._mainViewSelectedStreams.keys()].filter(
      (key) => key.includes(participantId)
    );

    for (let index = 0; index < streamIdsToBeDeleted.length; index++) {
      const streamId = streamIdsToBeDeleted[index];

      removeStreamIdFromMainViewStreams({ streamId });

      await sleep();
    }
  };

  const _handleOnParticipantLeft = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    removeParticipantFromMainViewStreams({ participantId });
  };

  const remapMainViewSelectedStreamsAndMainViewGrid = async () => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) {
      return;
    }

    const mainViewSelectedStreams = mainViewSelectedStreamsRef.current;
    const mainViewGrid = mainViewGridRef.current;
    const participantIds = [
      ...participantIdsRef.current,
      ...quickstartDummyParticipants.map(({ participantId }) => participantId),
    ];

    const mainViewGridValidStreamIds = mainViewGrid.filter((streamId) => {
      const participantId = streamId.split("_")[0];
      const cond =
        mainViewSelectedStreams.get(streamId) &&
        participantIds.includes(participantId);

      if (streamId.includes(streamTypes.EXTRAWBCM)) {
        const allParticipantsExtraWebcamStreams =
          allParticipantsExtraWebcamStreamsRef.current;

        const extraWebcamId = streamId.split("_")[2];

        const found = allParticipantsExtraWebcamStreams
          .get(participantId)
          ?.find(
            ({ extraWebcamId: _extraWebcamId }) =>
              extraWebcamId === _extraWebcamId
          );

        return !!found && cond;
      } else {
        return cond;
      }
    });

    const mainViewGridInvalidStreamIds = mainViewGrid.filter(
      (streamId) => !mainViewGridValidStreamIds.includes(streamId)
    );

    const presentInMainViewSelectedStreamsButNotInMainViewGrid = [
      ...mainViewSelectedStreams.keys(),
    ].filter((streamId) =>
      streamId.includes(streamTypes.SHARE)
        ? false
        : !mainViewGridValidStreamIds.includes(streamId)
    );

    if (mainViewGridInvalidStreamIds.length) {
      mainViewGridPublish(JSON.stringify(mainViewGridValidStreamIds));

      await sleep(200);
    }

    if (presentInMainViewSelectedStreamsButNotInMainViewGrid.length) {
      for (
        let index = 0;
        index < presentInMainViewSelectedStreamsButNotInMainViewGrid.length;
        index++
      ) {
        const streamId =
          presentInMainViewSelectedStreamsButNotInMainViewGrid[index];

        const [participantId, type] = streamId.split("_");

        const action = mainStreamActions.REMOVE_STREAM;

        mainViewStreamsPublish(
          JSON.stringify({ type, participantId, action, streamId })
        );

        await sleep(200);
      }
    }
  };

  const setRemapMainViewSelectedStreamsAndMainViewGridInterval = () => {
    const interval = setInterval(() => {
      remapMainViewSelectedStreamsAndMainViewGrid();
    }, 5000);

    return interval;
  };

  const _handleRemoveFileShareStreamsFromMainViewStreams = ({
    fileShareStreamsToBeRemoved,
  }: {
    fileShareStreamsToBeRemoved: { id: string }[];
  }) => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) {
      return;
    }

    const mainViewSelectedStreams = mainViewSelectedStreamsRef.current;

    const fileShareStreamId = [...mainViewSelectedStreams.keys()].find(
      (key) =>
        key.includes(streamTypes.SHARE) &&
        streamModes.PDF &&
        fileShareStreamsToBeRemoved.find(({ id }) => key.includes(id))
    );

    if (fileShareStreamId) {
      removeStreamIdFromMainViewStreams({ streamId: fileShareStreamId });
    }
  };

  const _handleRtcExtraWebcamTurnedOff = async ({
    participantId,
    extraWebcamId,
  }: {
    participantId: string;
    extraWebcamId: string;
  }) => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) {
      return;
    }

    const streamId = generateStreamId({
      participantId,
      type: streamTypes.EXTRAWBCM,
      extraWebcamId,
    });

    const _mainViewSelectedStreams = new Map(
      mainViewSelectedStreamsRef.current
    );

    const streamDoesntExists = !_mainViewSelectedStreams.get(streamId);

    if (streamDoesntExists) return;

    removeStreamIdFromMainViewStreams({ streamId });
  };

  useEffect(() => {
    const interval = setRemapMainViewSelectedStreamsAndMainViewGridInterval();

    appEventEmitter.on(
      appEventEmitterEvents.SIGNALING_PARTICIPANT_LEFT,
      _handleOnParticipantLeft
    );

    appEventEmitter.on(
      appEventEmitterEvents.REMOVE_FILE_SHARE_STREAMS_FROM_MAIN_VIEW_STREAMS,
      _handleRemoveFileShareStreamsFromMainViewStreams
    );

    appEventEmitter.on(
      appEventEmitterEvents.RTC_EXTRA_WEBCAM_TURNED_OFF,
      _handleRtcExtraWebcamTurnedOff
    );

    return () => {
      clearInterval(interval);

      appEventEmitter.off(
        appEventEmitterEvents.SIGNALING_PARTICIPANT_LEFT,
        _handleOnParticipantLeft
      );

      appEventEmitter.off(
        appEventEmitterEvents.REMOVE_FILE_SHARE_STREAMS_FROM_MAIN_VIEW_STREAMS,
        _handleRemoveFileShareStreamsFromMainViewStreams
      );

      appEventEmitter.off(
        appEventEmitterEvents.RTC_EXTRA_WEBCAM_TURNED_OFF,
        _handleRtcExtraWebcamTurnedOff
      );
    };
  }, []);

  return <React.Fragment />;
};

export default MainViewParticipantsListner;
