import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import {
  useJoinedParticipantsCountAndIsMaxedOutjoinedParticipants,
  useShowUpgradeToFitMorePeopleText,
} from "../../listners/appState/WaitingRoomParticipantsListner";
import { useAppContext } from "../../contexts/appContextDef";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import UpgradeNowLine from "../../components/UpgradeNowLine";
import useParticipantName from "../../hooks/appState/useParticipantName";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import useToggleParticipantMedia from "../../hooks/appState/useToggleParticipantMedia";
import {
  MdFilePresent,
  MdHourglassDisabled,
  MdHourglassEmpty,
  MdInterpreterMode,
  MdLogout,
  MdMic,
  MdMicOff,
  MdMoreVert,
  MdOutlineDesktopWindows,
  MdOutlinePanTool,
  MdOutlinePushPin,
  MdPanTool,
  MdPerson,
  MdPushPin,
  MdRemoveCircle,
  MdSlideshow,
  MdStream,
  MdVideocam,
  MdVideocamOff,
  MdPriorityHigh,
  MdSupervisorAccount,
} from "react-icons/md";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import useRaiseHand from "../../hooks/appState/useRaiseHand";
import useVideoShareStreams from "../../hooks/streams/useVideoShareStreams";
import useFileShareStreams from "../../hooks/streams/useFileShareStreams";
import usePinnedParticipantsPosition from "../../hooks/conference/usePinnedParticipantsPosition";
import Modal from "../../components/Modal";
import SidePanelTopBar from "./SidePanelTopBar";
import useEnableWaitingRoom from "../../hooks/activeBrand/useEnableWaitingRoom";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { ParticipantNetworkScoreIconContainer } from "../inQueueStreams/InQueueWebcamStreamContainer";
import useParticipantMicAudioLevel from "../../hooks/appState/useParticipantMicAudioLevel";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import useIsParticipantMicDenied from "../../hooks/appState/useIsParticipantMicDenied";
import useIsParticipantCameraDenied from "../../hooks/appState/useIsParticipantCameraDenied";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";
import useGetParticipantName from "../../hooks/appState/useGetParticipantName";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";
import {
  appModes,
  inQueueStreamTypes,
  interactivityModes,
  sidePanelModes,
} from "../../utils/constants";
import useAskToLeave from "../../hooks/appState/useAskToLeave";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
import DisclosureContainer from "../../components/DisclosureContainer";
import useAllowParticipantEntryRequest from "../../hooks/appState/useAllowParticipantEntryRequest";
import useInputFileVideoShareStreams from "../../hooks/streams/useInputFileVideoShareStreams";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import sleep from "../../utils/sleep";
import useAssignRoleToParticipant from "../../hooks/appState/useAssignRoleToParticipant";
import Tooltip from "../../components/Tooltip";
import { useInterpretationViaAiData } from "../../listners/appState/LocalParticipantTranslatedAudioBroadcasterContainer";

export const useJoinedInterpreterParticipantIds = () => {
  const { interpreterParticipantIds } = useAppContext();

  return { joinedInterpreterParticipantIds: interpreterParticipantIds };
};

const AssignRoleMenuItemsContainer = ({
  participantId,
}: {
  participantId: string;
}) => {
  const {
    assignAsHost,
    assignAsInterpreter,
    assignAsSpeaker,
    canBeAssignedAsHost,
    canBeAssignedAsInterpreter,
    canBeAssignedAsSpeaker,
  } = useAssignRoleToParticipant({
    participantId,
  });

  return (
    <React.Fragment>
      {canBeAssignedAsHost ||
      canBeAssignedAsInterpreter ||
      canBeAssignedAsSpeaker ? (
        <SubMenu
          className={"m-0 p-0"}
          itemProps={{ className: "hover:bg-custom-blue-50 text-primary" }}
          label={() => {
            return (
              <div className="flex items-center justify-center gap-1">
                <MdSupervisorAccount />

                <p className="ml-2">Assign as</p>
              </div>
            );
          }}
        >
          {canBeAssignedAsHost ? (
            <React.Fragment>
              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={() => {
                  assignAsHost();
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdSupervisorAccount />

                  <p className="ml-2">Host</p>
                </div>
              </MenuItem>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}

          {canBeAssignedAsInterpreter ? (
            <React.Fragment>
              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={() => {
                  assignAsInterpreter();
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdInterpreterMode />

                  <p className="ml-2">Interpreter</p>
                </div>
              </MenuItem>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}

          {canBeAssignedAsSpeaker ? (
            <React.Fragment>
              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={() => {
                  assignAsSpeaker();
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdPerson />

                  <p className="ml-2">Speaker</p>
                </div>
              </MenuItem>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </SubMenu>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const WaitingRoomParticipantsListItem = ({
  name,
  participantId,
}: {
  name: string;
  participantId: string;
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const { allowedEntryRequestParticipantIds } = useAppContext();

  const { isMaxedOutjoinedParticipants } =
    useJoinedParticipantsCountAndIsMaxedOutjoinedParticipants();

  const containerRef = createRef<HTMLDivElement>();
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const containerHeightRef = useRef(containerHeight);
  const containerWidthRef = useRef(containerWidth);
  const allowedEntryRequestParticipantIdsRef = useRef(
    allowedEntryRequestParticipantIds
  );

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);
  useEffect(() => {
    containerWidthRef.current = containerWidth;
  }, [containerWidth]);
  useEffect(() => {
    allowedEntryRequestParticipantIdsRef.current =
      allowedEntryRequestParticipantIds;
  }, [allowedEntryRequestParticipantIds]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { width, height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
      containerWidthRef.current !== width && setContainerWidth(width);
    }
  }, [containerRef]);

  const { allowParticipantEntryRequest } = useAllowParticipantEntryRequest();

  const { askToLeave } = useAskToLeave(participantId);

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.PARTICIPANT_ENTRY_REQUEST_DENIED
  );

  return (
    <div
      onMouseEnter={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
      className="mt-2 p-1 hover:bg-custom-blue-50 rounded-md"
    >
      <div className="flex items-center ">
        <div
          style={{ height: containerHeight, marginRight: 12 }}
          className="h-full aspect-square rounded-md bg-custom-blue-100 flex items-center justify-center font-bold text-white"
        >
          {name?.length ? String(name[0]).toUpperCase() : "U"}
        </div>
        <div
          style={{
            width: `calc(100% - ${containerWidth + containerHeight + 12}px)`,
          }}
          className="truncate text-ellipsis text-primary font-medium"
        >
          {name}
        </div>
        <div ref={containerRef as React.LegacyRef<HTMLDivElement>}>
          {isMouseOver ? (
            <div className="flex">
              <div className="ml-2">
                <button
                  disabled={isMaxedOutjoinedParticipants}
                  onClick={() => {
                    if (!isMaxedOutjoinedParticipants) {
                      allowParticipantEntryRequest(participantId);
                    }
                  }}
                  className={`normal-case btn btn-sm ${
                    isMaxedOutjoinedParticipants
                      ? "btn-disabled"
                      : "btn-primary"
                  }`}
                >
                  Admit
                </button>
              </div>
              <div className="ml-2">
                <button
                  onClick={async () => {
                    publish(JSON.stringify({ participantId }));

                    await sleep(100);

                    askToLeave();
                  }}
                  className="normal-case btn btn-sm btn-ghost"
                >
                  Remove
                </button>
              </div>
            </div>
          ) : (
            <div className="flex">
              <div className="ml-2 opacity-0">
                <button
                  style={{ width: 0 }}
                  onClick={() => {}}
                  className="btn btn-sm btn-primary"
                >
                  Remove
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const WaitingRoomParticipantsList = () => {
  const { requestedEntries } = useAppContext();

  const { showUpgradeToFitMorePeopleText } =
    useShowUpgradeToFitMorePeopleText();

  return (
    <div>
      {showUpgradeToFitMorePeopleText ? (
        <div
          style={{ minWidth: 200 }}
          className="my-3 text-xs font-medium text-custom-blue-700"
        >
          <UpgradeNowLine
            preText={"We can fit more people in the studio! "}
            postText={" and fit up to 100 people."}
          />
        </div>
      ) : (
        <React.Fragment />
      )}
      {requestedEntries?.length ? (
        requestedEntries.map(({ name, participantId, sid }) => (
          <WaitingRoomParticipantsListItem
            {...{
              name,
              participantId,
              sid,
              key: `requested-entry-participant-list-sidepanel-item-${participantId}`,
            }}
          />
        ))
      ) : (
        <div>
          <p className="text-sm text-gray-500">
            No participants in waiting room.
          </p>
        </div>
      )}
    </div>
  );
};

const JoinedParticipantsListItem = ({
  participantId,
}: {
  participantId: string;
}) => {
  const [kickModalVisible, setKickModalVisible] = useState(false);

  const { name } = useParticipantName(participantId);

  const containerRef = createRef<HTMLDivElement>();
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const containerHeightRef = useRef(containerHeight);
  const containerWidthRef = useRef(containerWidth);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);
  useEffect(() => {
    containerWidthRef.current = containerWidth;
  }, [containerWidth]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { width, height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
      containerWidthRef.current !== width && setContainerWidth(width);
    }
  }, [containerRef]);

  const { askToLeave } = useAskToLeave(participantId);

  const { webcamOn, micOn } = useParticipantMediaStats(participantId);

  const { micAudioLevel } = useParticipantMicAudioLevel({ participantId });

  const { isLocal } = useIsLocalParticipantId(participantId);

  const micOnRef = useRef(micOn);
  const webcamOnRef = useRef(webcamOn);

  useEffect(() => {
    webcamOnRef.current = webcamOn;
  }, [webcamOn]);
  useEffect(() => {
    micOnRef.current = micOn;
  }, [micOn]);

  const { turnOnMic, turnOffMic, turnOffWebcam, turnOnWebcam } =
    useToggleParticipantMedia(participantId);

  const {
    appMode,
    mainViewSelectedStreams,
    raiseHandParticipantIds,
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
    allParticipantsInQueueStreams,
  } = useAppContext();

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();
  const { disableVideoShareStream } = useVideoShareStreams();
  const { disableInputFileVideoShareStream } = useInputFileVideoShareStreams();
  const { disableFileShareStream } = useFileShareStreams();

  const { micDenied } = useIsParticipantMicDenied({ participantId });
  const { cameraDenied } = useIsParticipantCameraDenied({ participantId });

  const cameraOrMicDenied = useMemo(
    () => cameraDenied || micDenied,
    [cameraDenied, micDenied]
  );

  const { isInMainView } = useMemo(() => {
    if (interactivityMode === interactivityModes.STUDIO) {
      const streamId = generateStreamId({
        participantId,
        type: streamTypes.WEBCAM,
      });

      const isInMainView = !!mainViewSelectedStreams.get(streamId);

      return { isInMainView };
    } else {
      const isInConferenceMainViewIndex =
        conferenceMainViewVisibleWebcamStreams.findIndex(
          ({ participantId: _participantId }) =>
            _participantId === participantId
        );

      const isInConferenceMainView =
        conferenceMainViewVisibleWebcamStreams[isInConferenceMainViewIndex];

      return {
        pinned: isInConferenceMainView?.pinned,
        index: isInConferenceMainViewIndex,
        isInMainView: isInConferenceMainViewIndex !== -1,
      };
    }
  }, [
    participantId,
    mainViewSelectedStreams,
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
  ]);

  const isInMainViewRef = useRef(isInMainView);

  const { disableRaiseHand } = useRaiseHand(participantId);

  const participantRaisedHand = useMemo(
    () => raiseHandParticipantIds.includes(participantId),
    [raiseHandParticipantIds, participantId]
  );

  const participantRaisedHandRef = useRef(participantRaisedHand);

  useEffect(() => {
    participantRaisedHandRef.current = participantRaisedHand;
  }, [participantRaisedHand]);

  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);

  const _handleAddToMainViewStreams = () => {
    addToMainViewStreams({
      participantId,
      type: streamTypes.WEBCAM,
    });
  };

  const _handleRemoveFromMainViewStreams = () => {
    removeFromMainViewStreams({
      participantId,
      type: streamTypes.WEBCAM,
    });
  };

  const _handleToggleFromMainViewStreams = () => {
    const _isInMainView = isInMainViewRef.current;

    if (_isInMainView) {
      _handleRemoveFromMainViewStreams();
    } else {
      _handleAddToMainViewStreams();
    }
  };

  const _handleToggleMic = () => {
    if (micOnRef.current) {
      turnOffMic();
    } else {
      turnOnMic();
    }
  };

  const _handleToggleWebcam = () => {
    if (webcamOnRef.current) {
      turnOffWebcam();
    } else {
      turnOnWebcam();
    }
  };

  const _handleRemoveVideoShareFromMainViewStreams = ({
    videoId,
  }: {
    videoId: string;
  }) => {
    removeFromMainViewStreams({
      mode: streamModes.VIDEO,
      participantId,
      type: streamTypes.SHARE,
      videoId,
    });
  };

  const _handleRemoveInputFileVideoShareFromMainViewStreams = ({
    inputFileVideoId,
  }: {
    inputFileVideoId: string;
  }) => {
    removeFromMainViewStreams({
      mode: streamModes.INPUTFILEVIDYO,
      participantId,
      type: streamTypes.SHARE,
      inputFileVideoId,
    });
  };

  const _handleRemoveFileShareFromMainViewStreams = ({
    fileId,
  }: {
    fileId: string;
  }) => {
    removeFromMainViewStreams({
      mode: streamModes.PDF,
      participantId,
      type: streamTypes.SHARE,
      fileId,
    });
  };

  const _handleAddScreenShareFromMainViewStreams = () => {
    addToMainViewStreams({
      mode: streamModes.SCREEN,
      participantId,
      type: streamTypes.SHARE,
    });
  };
  const _handleRemoveScreenShareFromMainViewStreams = () => {
    removeFromMainViewStreams({
      mode: streamModes.SCREEN,
      participantId,
      type: streamTypes.SHARE,
    });
  };

  const _handleStopVideoShare = ({ videoId }: { videoId: string }) => {
    disableVideoShareStream({ id: videoId });

    _handleRemoveVideoShareFromMainViewStreams({ videoId });
  };

  const _handleStopInputFileVideoShare = ({
    inputFileVideoId,
  }: {
    inputFileVideoId: string;
  }) => {
    disableInputFileVideoShareStream({ id: inputFileVideoId });

    _handleRemoveInputFileVideoShareFromMainViewStreams({ inputFileVideoId });
  };

  const _handleStopFileShare = ({ fileId }: { fileId: string }) => {
    _handleRemoveFileShareFromMainViewStreams({ fileId });

    setTimeout(() => {
      disableFileShareStream({ id: fileId });
    }, 200);
  };

  const { pin, unPin } = usePinnedParticipantsPosition();

  const {
    pinned: confPinned,
    index: confPinnedIndex,
    fileShareFound,
    videoShareFound,
    screenShareEnabled,
    fileShareEnabled,
    videoShareEnabled,
    screenShareIsInMainView,
    fileShareIsInMainView,
    videoShareIsInMainView,
    inputFileVideoShareEnabled,
    inputFileVideoShareFound,
    inputFileVideoShareIsInMainView,
  } = useMemo(() => {
    const participantFilteredInQueueStreams =
      allParticipantsInQueueStreams.filter(
        ({ participantId: _participantId }) => _participantId === participantId
      );

    const screenShareFound = participantFilteredInQueueStreams.find(
      ({ type }) => type === inQueueStreamTypes.SHARE_SCREEN
    );

    const fileShareFound = participantFilteredInQueueStreams.find(
      ({ type }) => type === inQueueStreamTypes.SHARE_PDF
    );

    const videoShareFound = participantFilteredInQueueStreams.find(
      ({ type }) => type === inQueueStreamTypes.SHARE_VIDEO
    );

    const inputFileVideoShareFound = participantFilteredInQueueStreams.find(
      ({ type }) => type === inQueueStreamTypes.SHARE_INPUT_FILE_VIDEO
    );

    const screenShareEnabled = !!screenShareFound;
    const fileShareEnabled = !!fileShareFound;
    const videoShareEnabled = !!videoShareFound;
    const inputFileVideoShareEnabled = !!inputFileVideoShareFound;

    const screenShareStreamId = generateStreamId({
      participantId,
      type: streamTypes.SHARE,
      mode: streamModes.SCREEN,
    });

    const screenShareIsInMainView =
      !!mainViewSelectedStreams.get(screenShareStreamId);

    const fileShareStreamId = generateStreamId({
      participantId,
      type: streamTypes.SHARE,
      mode: streamModes.PDF,
      fileId: fileShareFound?.id,
    });

    const fileShareIsInMainView =
      !!mainViewSelectedStreams.get(fileShareStreamId);

    const videoShareStreamId = generateStreamId({
      participantId,
      type: streamTypes.SHARE,
      mode: streamModes.VIDEO,
      videoId: videoShareFound?.id,
    });

    const inputFileVideoShareStreamId = generateStreamId({
      participantId,
      type: streamTypes.SHARE,
      inputFileVideoId: inputFileVideoShareFound?.id,
      mode: streamModes.INPUTFILEVIDYO,
    });

    const videoShareIsInMainView =
      !!mainViewSelectedStreams.get(videoShareStreamId);

    const inputFileVideoShareIsInMainView = !!mainViewSelectedStreams.get(
      inputFileVideoShareStreamId
    );

    const isInConferenceMainViewIndex =
      conferenceMainViewVisibleWebcamStreams.findIndex(
        ({ participantId: _participantId }) => _participantId === participantId
      );

    const isInConferenceMainView =
      conferenceMainViewVisibleWebcamStreams[isInConferenceMainViewIndex];

    return {
      index: isInConferenceMainViewIndex,
      pinned: isInConferenceMainView?.pinned,
      screenShareFound,
      fileShareFound,
      videoShareFound,
      inputFileVideoShareFound,
      screenShareEnabled,
      fileShareEnabled,
      videoShareEnabled,
      inputFileVideoShareEnabled,
      screenShareIsInMainView,
      fileShareIsInMainView,
      videoShareIsInMainView,
      inputFileVideoShareIsInMainView,
    };
  }, [
    allParticipantsInQueueStreams,
    conferenceMainViewVisibleWebcamStreams,
    mainViewSelectedStreams,
    participantId,
  ]);

  const pinnedRef = useRef(confPinned);
  const indexRef = useRef(confPinnedIndex);

  useEffect(() => {
    pinnedRef.current = confPinned;
  }, [confPinned]);
  useEffect(() => {
    indexRef.current = confPinnedIndex;
  }, [confPinnedIndex]);

  const _handleTogglePin = () => {
    if (pinnedRef.current) {
      unPin({ participantId });
    } else {
      pin({ participantId, positionIndex: indexRef.current });
    }
  };

  const { isOriginalHost, isHost } = useAssignRoleToParticipant({
    participantId,
  });

  return (
    <React.Fragment>
      <div className="mt-2 p-2 hover:bg-custom-blue-50 rounded-md">
        <div className="flex items-center ">
          <div
            style={{ height: containerHeight, marginRight: 12 }}
            className={`h-full aspect-square rounded-md ${
              isInMainView
                ? "bg-green-200 text-green-600"
                : "bg-custom-blue-100 text-white"
            } flex items-center justify-center font-bold text-white relative`}
          >
            {name?.length ? String(name[0]).toUpperCase() : "U"}

            {isHost ? (
              <div
                style={{ bottom: -4, right: -4 }}
                className="absolute p-0.5 rounded-full bg-custom-blue-400"
              >
                <MdSupervisorAccount size={12} className="fill-white" />
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
          <div
            style={{
              width: `calc(100% - ${containerWidth + containerHeight + 12}px)`,
            }}
            className={`truncate text-ellipsis ${
              isInMainView ? "text-green-600" : "text-primary"
            } font-medium`}
          >
            {isLocal ? `${name} (You)` : name}
          </div>

          <div ref={containerRef as React.LegacyRef<HTMLDivElement>}>
            <div className="flex items-center gap-0.5">
              <ParticipantNetworkScoreIconContainer {...{ participantId }} />

              {appMode === appModes.HOST &&
              interactivityMode === interactivityModes.CONFERENCE &&
              confPinned ? (
                <div>
                  <MdPushPin size={18} className={"fill-primary"} />
                </div>
              ) : null}

              {participantRaisedHand ? (
                <div>
                  <MdPanTool size={18} className="fill-primary" />
                </div>
              ) : (
                <React.Fragment />
              )}

              <Tooltip disabled={!micDenied} title="Mic permission denied">
                <div className="relative">
                  {micOn ? (
                    <MdMic
                      size={18}
                      className={`transition-all ${
                        micAudioLevel === 1
                          ? "fill-green-400"
                          : micAudioLevel === 2
                          ? "fill-green-400"
                          : micAudioLevel === 3
                          ? "fill-green-400"
                          : micAudioLevel === 4
                          ? "fill-green-600"
                          : micAudioLevel === 5
                          ? "fill-green-600"
                          : micAudioLevel === 6
                          ? "fill-green-600"
                          : micAudioLevel === 7
                          ? "fill-green-700"
                          : micAudioLevel === 8
                          ? "fill-green-700"
                          : micAudioLevel === 9
                          ? "fill-green-700"
                          : "fill-gray-600"
                      }`}
                    />
                  ) : (
                    <MdMicOff size={18} className="fill-red-500" />
                  )}
                  {micDenied ? (
                    <div className="absolute animate-bounce flex items-center justify-center rounded-full top-0 right-0 h-2 w-2 bg-orange-400 text-white">
                      <MdPriorityHigh size={6} />
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </Tooltip>

              <Tooltip
                disabled={!cameraDenied}
                title="Webcam permission denied"
              >
                <div className="relative">
                  {webcamOn ? (
                    <MdVideocam size={18} className="fill-primary" />
                  ) : (
                    <MdVideocamOff size={18} className="fill-red-500" />
                  )}
                  {cameraDenied ? (
                    <div className="absolute animate-bounce flex items-center justify-center rounded-full top-0 right-0 h-2 w-2 bg-orange-400 text-white">
                      <MdPriorityHigh size={6} />
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </Tooltip>

              <div>
                {screenShareEnabled ? (
                  <MdOutlineDesktopWindows
                    size={18}
                    className={`${
                      screenShareIsInMainView
                        ? "fill-green-600"
                        : "fill-primary"
                    }`}
                  />
                ) : (
                  <React.Fragment />
                )}
              </div>

              <div>
                {fileShareEnabled ? (
                  <MdFilePresent
                    size={18}
                    className={`${
                      fileShareIsInMainView ? "fill-green-600" : "fill-primary"
                    }`}
                  />
                ) : (
                  <React.Fragment />
                )}
              </div>

              <div>
                {videoShareEnabled ? (
                  <MdSlideshow
                    size={18}
                    className={`${
                      videoShareIsInMainView ? "fill-green-600" : "fill-primary"
                    }`}
                  />
                ) : (
                  <React.Fragment />
                )}
              </div>

              <div>
                {inputFileVideoShareEnabled ? (
                  <MdSlideshow
                    size={18}
                    className={`${
                      inputFileVideoShareIsInMainView
                        ? "fill-green-600"
                        : "fill-primary"
                    }`}
                  />
                ) : (
                  <React.Fragment />
                )}
              </div>

              {appMode === appModes.HOST ? (
                <div>
                  <Menu
                    menuButton={
                      <span>
                        <button
                          className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
                        >
                          <div>
                            <MdMoreVert size={18} />
                          </div>
                        </button>
                      </span>
                    }
                    transition
                  >
                    {micDenied ? (
                      <React.Fragment />
                    ) : (
                      <MenuItem
                        className={
                          "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                        }
                        onClick={() => {
                          _handleToggleMic();
                        }}
                      >
                        <div className="flex items-center justify-center gap-1">
                          {micOn ? <MdMic /> : <MdMicOff />}
                          <p className="ml-2">{micOn ? "Mute" : "Unmute"}</p>
                        </div>
                      </MenuItem>
                    )}

                    {cameraDenied ? (
                      <React.Fragment />
                    ) : (
                      <MenuItem
                        className={
                          "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                        }
                        onClick={() => {
                          _handleToggleWebcam();
                        }}
                      >
                        <div className="flex items-center justify-center gap-1">
                          {webcamOn ? <MdVideocam /> : <MdVideocamOff />}
                          <p className="ml-2">
                            {webcamOn ? "Turn off webcam" : "Turn on webcam"}
                          </p>
                        </div>
                      </MenuItem>
                    )}

                    {participantRaisedHand ? (
                      <MenuItem
                        className={
                          "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                        }
                        onClick={() => {
                          disableRaiseHand();
                        }}
                      >
                        <div className="flex items-center justify-center gap-1">
                          <MdPanTool />
                          <p className="ml-2">Lower hand</p>
                        </div>
                      </MenuItem>
                    ) : (
                      <React.Fragment />
                    )}

                    {cameraOrMicDenied ? (
                      <React.Fragment />
                    ) : (
                      <MenuItem
                        className={
                          "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                        }
                        onClick={() => {
                          _handleToggleFromMainViewStreams();
                        }}
                      >
                        <div className="flex items-center justify-center gap-1">
                          {isInMainView ? <MdRemoveCircle /> : <MdStream />}

                          <p className="ml-2">
                            {isInMainView
                              ? "Remove from stream"
                              : "Add to stream"}
                          </p>
                        </div>
                      </MenuItem>
                    )}

                    {screenShareEnabled ? (
                      <MenuItem
                        className={
                          "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                        }
                        onClick={() => {
                          if (screenShareIsInMainView) {
                            _handleRemoveScreenShareFromMainViewStreams();
                          } else {
                            _handleAddScreenShareFromMainViewStreams();
                          }
                        }}
                      >
                        <div className="flex items-center justify-center gap-1">
                          <MdOutlineDesktopWindows />
                          <p className="ml-2">
                            {screenShareIsInMainView
                              ? "Remove Screen Share from stream"
                              : "Add Screen Share to stream"}
                          </p>
                        </div>
                      </MenuItem>
                    ) : (
                      <React.Fragment />
                    )}

                    {fileShareEnabled ? (
                      <React.Fragment>
                        <MenuItem
                          className={
                            "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                          }
                          onClick={() => {
                            _handleStopFileShare({
                              fileId: fileShareFound?.id as string,
                            });
                          }}
                        >
                          <div className="flex items-center justify-center gap-1">
                            <MdFilePresent />
                            <p className="ml-2">Stop PDF/PPT Share</p>
                          </div>
                        </MenuItem>
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}

                    {videoShareEnabled ? (
                      <React.Fragment>
                        <MenuItem
                          className={
                            "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                          }
                          onClick={() => {
                            _handleStopVideoShare({
                              videoId: videoShareFound?.id as string,
                            });
                          }}
                        >
                          <div className="flex items-center justify-center gap-1">
                            <MdSlideshow />
                            <p className="ml-2">Remove video from studio</p>
                          </div>
                        </MenuItem>
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}

                    {inputFileVideoShareEnabled ? (
                      <React.Fragment>
                        <MenuItem
                          className={
                            "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                          }
                          onClick={() => {
                            _handleStopInputFileVideoShare({
                              inputFileVideoId:
                                inputFileVideoShareFound?.id as string,
                            });
                          }}
                        >
                          <div className="flex items-center justify-center gap-1">
                            <MdSlideshow />
                            <p className="ml-2">Remove video from studio</p>
                          </div>
                        </MenuItem>
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}

                    {interactivityMode === interactivityModes.CONFERENCE ? (
                      <React.Fragment>
                        <MenuItem
                          className={
                            "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                          }
                          onClick={() => {
                            _handleTogglePin();
                          }}
                        >
                          <div className="flex items-center justify-center gap-1">
                            {confPinned ? <MdPushPin /> : <MdOutlinePushPin />}

                            <p className="ml-2">
                              {confPinned ? "Unpin" : "Pin"}
                            </p>
                          </div>
                        </MenuItem>
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}

                    {!isLocal && !isOriginalHost ? (
                      <React.Fragment>
                        <MenuItem
                          className={
                            "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                          }
                          onClick={() => {
                            setKickModalVisible(true);
                          }}
                        >
                          <div className="flex items-center justify-center gap-1">
                            <MdLogout />

                            <p className="ml-2">Kick guest from studio</p>
                          </div>
                        </MenuItem>
                      </React.Fragment>
                    ) : (
                      <React.Fragment />
                    )}

                    {isLocal ? (
                      <React.Fragment />
                    ) : (
                      <AssignRoleMenuItemsContainer
                        participantId={participantId}
                      />
                    )}
                  </Menu>
                </div>
              ) : (
                <React.Fragment />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        {...{
          isOpen: kickModalVisible,
          onSuccess: () => {
            askToLeave();

            setKickModalVisible(false);
          },
          onClose: () => {
            setKickModalVisible(false);
          },
          cancelText: "No",
          successText: "Yes, kick",
          title: `Kick ${name} from studio?`,
        }}
      />
    </React.Fragment>
  );
};

const JoinedParticipantsList = ({
  filterRaisedHandParticipantsEnable,
  searchQuery,
}: {
  filterRaisedHandParticipantsEnable: boolean;
  searchQuery: string;
}) => {
  const { raiseHandParticipantIds } = useAppContext();

  const { getParticipantName } = useGetParticipantName();

  const { participantIds } = useParticipantIdsAndCount();

  const { filteredParticipants } = useMemo(() => {
    const participants = participantIds.map((participantId) => {
      return {
        participantId,
        name: getParticipantName(participantId),
      };
    });

    const filteredParticipants = participants
      .filter(({ name }) => {
        const pattern = new RegExp(searchQuery, "i");

        const regexActive = name.match(pattern);

        return !!regexActive;
      })
      .filter(({ participantId }) => {
        if (filterRaisedHandParticipantsEnable) {
          return raiseHandParticipantIds.includes(participantId);
        } else {
          return true;
        }
      });

    return { filteredParticipants };
  }, [
    participantIds,
    searchQuery,
    filterRaisedHandParticipantsEnable,
    raiseHandParticipantIds,
    getParticipantName,
  ]);

  return (
    <React.Fragment>
      {filteredParticipants?.length ? (
        filteredParticipants.map(({ participantId }) => (
          <JoinedParticipantsListItem
            {...{
              key: `joined-participant-list-sidepanel-item-${participantId}`,
              participantId,
            }}
          />
        ))
      ) : (
        <div>
          <p className="text-sm text-gray-500">
            No participants found, please remove search filter or raise hand
            filter.
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

const InterpreterParticipantsListItem = ({
  participantId,
}: {
  participantId: string;
}) => {
  const { allParticipantsInterpreterAgoraStats, appMode } = useAppContext();
  const { interpretations } = useAppConfigContext();
  const { name } = useParticipantName(participantId);

  const containerRef = createRef<HTMLDivElement>();
  const [containerHeight, setContainerHeight] = useState(0);

  const containerHeightRef = useRef(containerHeight);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
    }
  }, [containerRef]);

  const { micOn, activeInterpretationInputAgoraChannelId } = useMemo(() => {
    return (
      allParticipantsInterpreterAgoraStats.find(
        ({ participantId: _participantId }) => participantId === _participantId
      ) || { micOn: false, activeInterpretationInputAgoraChannelId: undefined }
    );
  }, [allParticipantsInterpreterAgoraStats, participantId]);

  const interpretationShortName = useMemo(() => {
    return interpretations.find(
      ({ agoraChannelId }) =>
        agoraChannelId === activeInterpretationInputAgoraChannelId
    )?.shortName;
  }, [interpretations, activeInterpretationInputAgoraChannelId]);

  const { isLocal } = useIsLocalParticipantId(participantId);

  const { interpretationViaAiData } = useInterpretationViaAiData();

  return (
    <div className="mt-2 p-2 hover:bg-custom-blue-50 rounded-md flex items-center justify-center">
      <div className="flex items-center flex-1">
        <div
          ref={containerRef as React.LegacyRef<HTMLDivElement>}
          style={{ marginRight: 12, width: containerHeight }}
          className={`h-full aspect-square rounded-md bg-custom-blue-100 text-white flex items-center justify-center font-bold text-white`}
        >
          {"IN"}
        </div>

        <div className={`text-primary font-medium`}>{name}</div>
      </div>

      {!interpretationViaAiData.length ? (
        <div className="flex items-center justify-center gap-1">
          {micOn ? (
            <MdMic size={18} className={`transition-all fill-gray-600`} />
          ) : (
            <MdMicOff size={18} className="fill-red-500" />
          )}

          <p className="font-bold text-primary">{interpretationShortName}</p>
        </div>
      ) : (
        <React.Fragment />
      )}

      {appMode === appModes.HOST && !interpretationViaAiData.length ? (
        <div className="ml-2">
          <Menu
            menuButton={
              <span>
                <button
                  className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
                >
                  <div>
                    <MdMoreVert size={18} />
                  </div>
                </button>
              </span>
            }
            transition
          >
            {isLocal ? (
              <React.Fragment />
            ) : (
              <AssignRoleMenuItemsContainer participantId={participantId} />
            )}
          </Menu>
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

const InterpreterParticipantsList = () => {
  const { joinedInterpreterParticipantIds } =
    useJoinedInterpreterParticipantIds();

  return joinedInterpreterParticipantIds?.length ? (
    <React.Fragment>
      {joinedInterpreterParticipantIds.map((participantId) => (
        <InterpreterParticipantsListItem
          {...{
            participantId,
            key: `InterpreterParticipantsListItem_${participantId}`,
          }}
        />
      ))}
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

const ParticipantsPanel = ({
  onCloseButtonClick,
}: {
  onCloseButtonClick: () => void;
}) => {
  const { maxParticipantsCount } = useAppConfigContext();

  const [searchQuery, setSearchQuery] = useState("");
  const [waitingRoomListVisible, setWaitingRoomListVisible] = useState(true);

  const [
    filterRaisedHandParticipantsEnable,
    setFilterRaisedHandParticipantsEnable,
  ] = useState(false);

  const { requestedEntries } = useAppContext();

  const { participantsCount } = useParticipantIdsAndCount();

  const { requestedEntriesCount } = useMemo(() => {
    const requestedEntriesCount = requestedEntries?.length || 0;
    return { requestedEntriesCount };
  }, [requestedEntries]);

  const { waitingRoomEnabled, appMode } = useAppContext();

  const { disable: disableWaitingRoom, enable: enableWaitingRoom } =
    useEnableWaitingRoom();

  const _handleToggledLateralSidebar = ({
    mode,
  }: {
    mode: sidePanelModeType;
  }) => {
    if (mode === sidePanelModes.PARTICIPANTS) {
      setWaitingRoomListVisible(false);

      setTimeout(() => {
        setWaitingRoomListVisible(true);
      }, 10);
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.TOGGLED_LATERAL_SIDEBAR,
      _handleToggledLateralSidebar
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.TOGGLED_LATERAL_SIDEBAR,
        _handleToggledLateralSidebar
      );
    };
  }, []);

  const { joinedInterpreterParticipantIds } =
    useJoinedInterpreterParticipantIds();

  return (
    <div>
      <SidePanelTopBar
        onCloseButtonClick={onCloseButtonClick}
        renderRightComponent={
          appMode === appModes.HOST
            ? () => {
                return (
                  <Menu
                    menuButton={
                      <span>
                        <button
                          className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
                        >
                          <div>
                            <MdMoreVert size={18} />
                          </div>
                        </button>
                      </span>
                    }
                    transition
                  >
                    <MenuItem
                      className={
                        "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                      }
                      onClick={() => {
                        if (waitingRoomEnabled) {
                          disableWaitingRoom();
                        } else {
                          enableWaitingRoom();
                        }
                      }}
                    >
                      <div className="flex items-center justify-center gap-1">
                        {waitingRoomEnabled ? (
                          <MdHourglassEmpty />
                        ) : (
                          <MdHourglassDisabled />
                        )}
                        <p className="ml-2">
                          {waitingRoomEnabled
                            ? "Disable waiting room"
                            : "Enable waiting room"}
                        </p>
                      </div>
                    </MenuItem>
                  </Menu>
                );
              }
            : undefined
        }
        title={"Participants"}
      />
      <div className="flex gap-3 mt-3">
        <input
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          placeholder="Find participants"
          className="input flex flex-1 input-bordered"
        />
        <div
          className="flex items-center justify-center p-1 cursor-pointer"
          onClick={() => {
            setFilterRaisedHandParticipantsEnable((s) => !s);
          }}
        >
          {filterRaisedHandParticipantsEnable ? (
            <MdPanTool className="fill-primary" size={24} />
          ) : (
            <MdOutlinePanTool className="fill-primary" size={24} />
          )}
        </div>
      </div>

      {appMode === appModes.HOST && waitingRoomListVisible ? (
        <span
          className={`${quickstartTargetComponentClassNames.waiting_room_section}`}
        >
          <DisclosureContainer
            defaultOpen={waitingRoomEnabled}
            renderTitle={() => {
              return (
                <div className="flex items-center justify-center gap-4">
                  <span>{`Waiting Room (${requestedEntriesCount})`}</span>
                  <div
                    className={`badge ${
                      waitingRoomEnabled
                        ? "badge-success text-white"
                        : "text-gray-400"
                    } badge-outline text-xs font-bold`}
                  >
                    {waitingRoomEnabled ? "ENABLED" : "DISABLED"}
                  </div>
                </div>
              );
            }}
            renderPanel={() => <WaitingRoomParticipantsList />}
          ></DisclosureContainer>
        </span>
      ) : (
        <React.Fragment />
      )}

      <DisclosureContainer
        defaultOpen
        title={`Participants (${`${participantsCount}/${maxParticipantsCount}`})`}
        renderPanel={() => (
          <JoinedParticipantsList
            {...{ searchQuery, filterRaisedHandParticipantsEnable }}
          />
        )}
      ></DisclosureContainer>

      {joinedInterpreterParticipantIds?.length ? (
        <DisclosureContainer
          defaultOpen
          title={`Interpreters (${`${
            joinedInterpreterParticipantIds?.length || 0
          }`})`}
          renderPanel={() => <InterpreterParticipantsList />}
        ></DisclosureContainer>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default ParticipantsPanel;
