import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
import useAppRtcParticipantExtraWebcam from "../../appRtc/useAppRtcParticipantExtraWebcam";

const MainViewExtraWebcamStreamContainer = ({
  streamId,
  participantId,
  extraWebcamId,
}: {
  streamId: string;
  participantId: string;
  extraWebcamId: string;
}) => {
  const { mirrorLocalWebcam } = useAppContext();

  const { isLocal } = useIsLocalParticipantId(participantId);

  const { isActive, isSubscribed, videoTrack } =
    useAppRtcParticipantExtraWebcam({ extraWebcamId, participantId });

  const flipStyle = useMemo(
    () =>
      isLocal && mirrorLocalWebcam
        ? { transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }
        : {},
    [isLocal, mirrorLocalWebcam]
  );

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      className={`relative cursor-pointer rounded`}
      id={`MainViewExtraWebcamStreamContainer-${streamId}`}
    >
      <VideoMediaStreamPlayer
        {...{
          flipStyle,
          videoOn: isActive && isSubscribed,
          videoTrack,
          key: `VideoMediaStreamPlayer-extra-webcam-stream-${videoTrack?.id}-${participantId}-${extraWebcamId}`,
          participantId,
          isWebcam: true,
          rounded: true,
        }}
      />
    </div>
  );
};

export default MainViewExtraWebcamStreamContainer;
