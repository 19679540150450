import React, { useEffect, useMemo, useRef, useState } from "react";
import useFileShareStream from "../../hooks/streams/useFileShareStream";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import { useAppContext } from "../../contexts/appContextDef";
import {
  MdMoreVert,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  // MdEdit,
  MdOutlineSlideshow,
  MdRemoveCircle,
  // MdCancel,
} from "react-icons/md";
import { Menu, MenuItem } from "@szhsin/react-menu";
import ChangeNameModal from "../../components/ChangeNameModal";
// import useFileShareStreams from "../../hooks/streams/useFileShareStreams";
import useParticipantName from "../../hooks/appState/useParticipantName";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";
import { appModes } from "../../utils/constants";
import useAskToLeave from "../../hooks/appState/useAskToLeave";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
// import useToggleParticipantMedia from "../../hooks/appState/useToggleParticipantMedia";
import useFileShareStreams from "../../hooks/streams/useFileShareStreams";

const ItemMenuContainer = ({
  // setChangeNameModalOpen,
  isLocal,
  isInMainView,
  _handleRemoveFromStudio,
  // _handleKickFromStudio,
  _handleRemoveFromMainViewStreams,
}: {
  setChangeNameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLocal: boolean;
  isInMainView: boolean;
  _handleRemoveFromStudio: () => void;
  _handleKickFromStudio: () => void;
  _handleRemoveFromMainViewStreams: () => void;
}) => {
  const { appMode } = useAppContext();

  return (
    <Menu
      menuButton={
        <span>
          <button
            className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
          >
            <div>
              <MdMoreVert size={16} />
            </div>
          </button>
        </span>
      }
      transition
    >
      {isInMainView && appMode === appModes.HOST && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            _handleRemoveFromMainViewStreams();
          }}
        >
          <div className="flex items-center justify-center gap-1">
            <MdRemoveCircle />
            <p className="ml-2">Remove from stream</p>
          </div>
        </MenuItem>
      )}

      {/* {(appMode === appModes.HOST || isLocal) && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            setChangeNameModalOpen(true);
          }}
        >
          <div className="flex items-center justify-center gap-1">
            <MdEdit />
            <p className="ml-2">Edit name</p>
          </div>
        </MenuItem>
      )} */}

      {(appMode === appModes.HOST || isLocal) && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            _handleRemoveFromStudio();
          }}
        >
          <div className="flex items-center justify-center gap-1">
            <MdRemoveCircle />
            <p className="ml-2">Remove from studio</p>
          </div>
        </MenuItem>
      )}

      {/* {appMode === appModes.HOST && !isLocal && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            _handleKickFromStudio();
          }}
        >
          <div className="flex items-center justify-center gap-1">
            <MdCancel />
            <p className="ml-2">Kick from studio</p>
          </div>
        </MenuItem>
      )} */}
    </Menu>
  );
};

const InQueueFileStreamContainer = ({
  fileId,
  participantId,
}: {
  fileId: string;
  participantId: string;
}) => {
  const {
    currentPage,
    gotoNext,
    gotoPrevious,
    currentPageRemoteUrl,
    isFirstPage,
    isLastPage,
    goto,
    allPagesUrl,
    resourceId,
  } = useFileShareStream({ fileId, imageQuality: "high" });

  const { appMode, mainViewSelectedStreams, inQueueItemContainerWidth } =
    useAppContext();

  const { disableFileShareStream } = useFileShareStreams();
  const [controlPanelMouseOver, setControlPanelMouseOver] = useState(false);

  const { isInMainView } = useMemo(() => {
    const streamId = generateStreamId({
      participantId,
      type: streamTypes.SHARE,
      mode: streamModes.PDF,
      fileId,
    });

    const isInMainView = !!mainViewSelectedStreams.get(streamId);

    return { streamId, isInMainView };
  }, [participantId, fileId, mainViewSelectedStreams]);

  const isInMainViewRef = useRef(isInMainView);
  const resourceIdRef = useRef(resourceId);

  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);
  useEffect(() => {
    resourceIdRef.current = resourceId;
  }, [resourceId]);

  const [changeNameModalOpen, setChangeNameModalOpen] = useState(false);
  const { name } = useParticipantName(participantId);

  const { isLocal } = useIsLocalParticipantId(participantId);

  const { askToLeave } = useAskToLeave(participantId);

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const _handleAddToMainViewStreams = () => {
    addToMainViewStreams({
      mode: streamModes.PDF,
      // participantId,
      participantId: `${resourceIdRef.current}`,
      type: streamTypes.SHARE,
      fileId,
    });
  };

  const _handleRemoveFromMainViewStreams = () => {
    removeFromMainViewStreams({
      mode: streamModes.PDF,
      // participantId,
      participantId: `${resourceIdRef.current}`,
      type: streamTypes.SHARE,
      fileId,
    });
  };

  const _handleToggleFromMainViewStreams = () => {
    const _isInMainView = isInMainViewRef.current;

    if (_isInMainView) {
      _handleRemoveFromMainViewStreams();
    } else {
      _handleAddToMainViewStreams();
    }
  };

  const _handleRemoveFromStudio = () => {
    _handleRemoveFromMainViewStreams();

    setTimeout(() => {
      disableFileShareStream({ id: fileId });
      // turnOffFileShareStream({ fileId });
    }, 200);
  };

  const _handleKickFromStudio = () => {
    // TODO: confirm to remove
    // participant.remove();

    askToLeave();
  };

  return (
    <React.Fragment>
      <div
        className={`rounded ${quickstartTargetComponentClassNames.in_queue_local_participant_presentation_stream_container}`}
        style={{ width: inQueueItemContainerWidth }}
      >
        <div
          style={{
            height: (inQueueItemContainerWidth * 9) / 16,
            width: inQueueItemContainerWidth,
          }}
          className="relative flex items-center justify-center bg-custom-blue-50"
        >
          <img
            alt={`inqueue_pdfviewer_${fileId}_${currentPage}`}
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              height: "auto",
              width: "auto",
              display: "block",
            }}
            src={currentPageRemoteUrl || ""}
          />
          {!isInMainView && (
            <div className="top-0 left-0 right-0 bottom-0 absolute bg-opacity-50 bg-white"></div>
          )}
          <div className="top-0 left-0 right-0 bottom-0 absolute flex justify-between">
            {appMode === appModes.HOST && (
              <div className="p-3 flex flex-1 hover:opacity-100 opacity-0 items-end justify-center">
                <button
                  className="btn btn-primary text-white btn-active"
                  onClick={_handleToggleFromMainViewStreams}
                >
                  {isInMainView ? "Remove" : "Add to stream"}
                </button>
              </div>
            )}

            {/* {appMode === appModes.HOST && isInMainView && (
              <div className="absolute top-2 left-2">
                <div
                  className={`rounded-md border-2 transition-all ${
                    isCinemaShare
                      ? "bg-white border-opacity-100 border-primary"
                      : "bg-gray-400 border-gray-400"
                  } flex items-center justify-center`}
                >
                  {isCinemaShare ? (
                    <CinemaIconActive className={"h-8"} />
                  ) : (
                    <CinemaIcon className={"h-8"} />
                  )}
                </div>
              </div>
            )} */}

            <div className="absolute top-2 right-2 flex">
              <ItemMenuContainer
                {...{
                  setChangeNameModalOpen,
                  isLocal,
                  isInMainView,
                  _handleRemoveFromMainViewStreams,
                  _handleRemoveFromStudio,
                  _handleKickFromStudio,
                }}
              />
            </div>
          </div>
        </div>

        <div
          onMouseEnter={() => {
            setControlPanelMouseOver(true);
          }}
          onMouseLeave={() => {
            setControlPanelMouseOver(false);
          }}
          className={`relative flex items-center justify-between px-0.5  ${
            isInMainView
              ? "bg-primary text-white fill-white"
              : "bg-white fill-gray-800 text-gray-800"
          } rounded-b-md `}
        >
          <div className="flex items-center justify-center">
            <MdOutlineSlideshow size={16} />
            <div className="ml-1 relative">
              <Menu
                overflow={"auto"}
                position={"auto"}
                menuButton={
                  <span>
                    <div
                      className={`${
                        isInMainView ? "text-white" : "fill-gray-800"
                      } rounded-md cursor-pointer flex ${
                        controlPanelMouseOver ? "" : "opacity-0"
                      }`}
                    >
                      <p>Slide {currentPage}</p>
                    </div>
                  </span>
                }
                transition
              >
                {allPagesUrl?.map((url, _i) => {
                  const i = _i + 1;

                  const isSelected = i === currentPage;

                  return (
                    <MenuItem
                      style={{ padding: 0 }}
                      className={"hover:bg-custom-blue-50"}
                      onClick={() => {
                        goto(i);
                      }}
                      value={i}
                      key={`file-stream-inqueue-${fileId}-${i}`}
                    >
                      <div className="text-primary p-0.5 flex flex-1 items-center justify-center w-full h-full">
                        <img
                          className="h-16 object-cover"
                          src={url}
                          alt={`file-stream-inqueue-${fileId}-${i}`}
                        />
                        <div className="flex flex-1 p-1 text-sm">
                          <p>{`Slide ${i}`}</p>
                        </div>
                        {isSelected ? <span>✓</span> : <React.Fragment />}
                      </div>
                    </MenuItem>
                  );
                })}
              </Menu>

              {/* <select
                onChange={(e) => {
                  goto(parseInt(e.target.value));
                }}
                value={currentPage}
                className={`${isInMainView ? "bg-primary text-white" : ""} ${
                  controlPanelMouseOver ? "" : "opacity-0"
                }`}
                disabled={!controlPanelMouseOver}
              >
                {totalPagesArray.map((i) => (
                  <option
                    className="bg-white text-black"
                    onSelect={(e) => {}}
                    onClick={() => {
                      goto(i);
                    }}
                    value={i}
                    key={`file-stream-inqueue-${fileId}-${i}`}
                  >
                    {`Slide ${i}`}
                  </option>
                ))}
              </select> */}

              {controlPanelMouseOver ? (
                <React.Fragment />
              ) : (
                <div className="absolute top-0 left-0 right-0 bottom-0">
                  {name}
                </div>
              )}
            </div>
          </div>
          <div className="flex">
            <div>
              <button
                disabled={isFirstPage}
                className={`btn btn-primary text-white btn-circle btn-xs ${
                  isFirstPage ? "opacity-0" : ""
                }`}
                onClick={() => gotoPrevious()}
              >
                <MdKeyboardArrowLeft size={16} />
              </button>
            </div>
            <div className="ml-1">
              <button
                disabled={isLastPage}
                className={`btn btn-primary text-white btn-circle btn-xs ${
                  isLastPage ? "opacity-0" : ""
                }`}
                onClick={() => gotoNext()}
              >
                <MdKeyboardArrowRight size={16} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {changeNameModalOpen && (
        <ChangeNameModal
          {...{
            participantId,
            handleCancel: () => {
              setChangeNameModalOpen(false);
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

export default InQueueFileStreamContainer;
