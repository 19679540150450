import React, { useEffect, useRef } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import {
  prepareConfig,
  useAppConfigContext,
} from "../../contexts/appConfigDef";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const ResetLiveStreamDestinationsListner = () => {
  const { setDestinations } = useAppConfigContext();

  useAppSingalingSubscribe(
    appPubSubTopics.LIVESTREAM_DESTINATIONS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setDestinations(message.destinations);
    }
  );

  return <React.Fragment />;
};

export const useResetLiveStreamDestinations = () => {
  const { publish } = useAppSingalingPublish(
    appPubSubTopics.LIVESTREAM_DESTINATIONS
  );

  const publishRef = useRef<appSingalingPublishType>(publish);

  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  const resetLiveStreamDestinations = async () => {
    const { config, success } = await prepareConfig();

    if (success && config) {
      const destinations = config.destinations;

      publishRef.current(
        JSON.stringify({ destinations })
        // , { persist: false }
      );
    }
  };

  return { resetLiveStreamDestinations };
};

export default ResetLiveStreamDestinationsListner;
