// import { usePubSub } from "@videosdk.live/react-sdk";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "./useValidateHostCanTakeAction";
import { mainViewLayouts } from "../../utils/constants";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useMainViewLayout = () => {
  const {
    sideStackWidthPercentage,
    isStreamsGrid,
    gridWithStreamsPosition,
    mainViewLayoutActive: layout,
    mainViewLayoutSelected: mainViewLayout,
  } = useAppContext();

  // const { publish: mainViewLayoutPublish } = usePubSub(
  //   appPubSubTopics.MAIN_VIEW_LAYOUT
  // );
  const { publish: mainViewLayoutPublish } = useAppSingalingPublish(
    appPubSubTopics.MAIN_VIEW_LAYOUT
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const changeMainViewLayout = async ({
    layout,
    conferenceAutoMainVideoLayoutEnabled,
  }: {
    layout: mainViewLayoutType;
    conferenceAutoMainVideoLayoutEnabled?: boolean;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (!Object.keys(mainViewLayouts).includes(layout)) return;

    mainViewLayoutPublish(
      JSON.stringify({ layout, conferenceAutoMainVideoLayoutEnabled })
      // { persist: true }
    );
  };

  return {
    sideStackWidthPercentage,
    isStreamsGrid,
    gridWithStreamsPosition,
    changeMainViewLayout,
    mainViewLayoutActive: layout,
    mainViewLayoutSelected: mainViewLayout,
  };
};

export default useMainViewLayout;
