import React, { useEffect, useMemo, useState } from "react";
import IconButton, {
  iconButtonVariants,
} from "../bottomControlsContainer/IconButton";
import {
  MdMic,
  MdMicOff,
  MdVideocam,
  MdVideocamOff,
  MdSettings,
} from "react-icons/md";
import SettingsContainer from "../settings/SettingsContainer";
import {
  defaultAvatarImage,
  micCamPermissionToolbar,
  MOMENTO_LOGO_FULL,
} from "../../static/images";
import CachedUserData from "../../utils/CachedUserData";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { appModes, audioAndVideoPermissionStates } from "../../utils/constants";
import Spinner from "../../components/Spinner";
import useIsMobilePortrait from "../../hooks/responsive/useIsMobilePortrait";
import useIsMobileLandscape from "../../hooks/responsive/useIsMobileLandscape";
import { useWindowSize } from "@react-hook/window-size";
import useIsBigScreen from "../../hooks/responsive/useIsBigScreen";
import useIsDesktopOrLaptop from "../../hooks/responsive/useIsDesktopOrLaptop";
import JoinScreenCornerDisplayName from "../../components/JoinScreenCornerDisplayName";
import useIsUltrawidePortrait from "../../hooks/responsive/useIsUltrawidePortrait";
import AskMicPermissionModal from "../../components/AskMicPermissionModal";
import AskCameraPermissionModal from "../../components/AskCameraPermissionModal";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import useRtcLocalParticipantMediaStatsActions from "../../appRtc/useRtcLocalParticipantMediaStatsActions";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useAppRtcLocalParticipantMediaStats from "../../appRtc/useAppRtcLocalParticipantMediaStats";
import { useAppContext } from "../../contexts/appContextDef";

const MicVolumeBar = ({ micOn }: { micOn?: boolean }) => {
  const [volume, setVolume] = useState<number | null>(null);

  const _handleLocalMicVolumeChanged = ({ volume }: { volume: number }) => {
    setVolume(volume);
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.LOCAL_MIC_VOLUME_CHANGED,
      _handleLocalMicVolumeChanged
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.LOCAL_MIC_VOLUME_CHANGED,
        _handleLocalMicVolumeChanged
      );
    };
  }, []);

  return (
    <div className="py-2 px-5 bg-gray-100 flex flex-col items-center w-32 p-3">
      <MdMic size={24} className={"fill-gray-500"} />
      <div className="flex flex-1 w-6 border border-gray-300 bg-white relative mt-3 rounded-sm overflow-hidden shadow-sm">
        <div
          className={`absolute bottom-0 left-0 right-0 transition-all bg-green-500`}
          style={{ height: `${micOn ? (volume || 0) / 2 : 0}%` }}
        ></div>
      </div>
    </div>
  );
};

const JoinScreenContainer = ({
  _handleJoin,
  name: _name,
  participantHeadline: _participantHeadline,
}: {
  _handleJoin: (t: joinStateType) => void;
  name?: string;
  participantHeadline?: string;
}) => {
  const { webcamState, micState, defaultName, embedded } =
    useAppConfigContext();
  const [
    allowJoinWithoutMicWebcamPermission,
    setAllowJoinWithoutMicWebcamPermission,
  ] = useState(false);

  const { isMobilePortrait } = useIsMobilePortrait();
  const { isMobileLandscape } = useIsMobileLandscape();
  const { isBigScreen } = useIsBigScreen();
  const { isDesktopOrLaptop } = useIsDesktopOrLaptop();

  const [name, setName] = useState<string>(_name || defaultName || "");
  const [participantHeadline, setParticipantHeadline] =
    useState(_participantHeadline);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const [nameError, setNameError] = useState(false);

  const {
    selectedWebcamDeviceId,
    selectedMicDeviceId,
    mirrorLocalWebcam,
    appMode,
    waitingRoomEnabled,
  } = useAppContext();

  const _handleToggleSettingsModal = () => {
    setSettingsModalOpen((s) => !s);
  };

  const micCamStateAvailable = useMemo(
    () => webcamState && micState,
    [webcamState, micState]
  );

  const micDenied = useMemo(
    () => micState === audioAndVideoPermissionStates.denied,
    [micState]
  );
  const cameraDenied = useMemo(
    () => webcamState === audioAndVideoPermissionStates.denied,
    [webcamState]
  );

  const micOrWebcamDenied = useMemo(
    () =>
      webcamState === audioAndVideoPermissionStates.denied ||
      micState === audioAndVideoPermissionStates.denied,
    [webcamState, micState]
  );

  const showJoinWithoutMicCamButton = useMemo(
    () =>
      micOrWebcamDenied
        ? allowJoinWithoutMicWebcamPermission
          ? false
          : true
        : false,
    [allowJoinWithoutMicWebcamPermission, micOrWebcamDenied]
  );

  const [windowWidth, windowHeight] = useWindowSize();

  const { isUltrawidePortrait } = useIsUltrawidePortrait();

  const { localParticipantId } = useLocalParticipantId();

  const { micOn, webcamOn } = useParticipantMediaStats(localParticipantId);
  const { toggleMic, toggleWebcam, enableMic, enableWebcam } =
    useRtcLocalParticipantMediaStatsActions();

  const _toggleMic = async () => {
    toggleMic();
  };

  const _toggleWebcam = () => {
    toggleWebcam();
  };

  const { webcamTrack } = useAppRtcLocalParticipantMediaStats();

  const flipStyle = useMemo(
    () =>
      mirrorLocalWebcam
        ? { transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }
        : {},
    [mirrorLocalWebcam]
  );

  useEffect(() => {
    setTimeout(() => {
      enableMic();
    }, 2000);
  }, [micDenied]);

  useEffect(() => {
    setTimeout(() => {
      enableWebcam();
    }, 2000);
  }, [cameraDenied]);

  return (
    <React.Fragment>
      <div
        style={{ width: windowWidth, height: windowHeight, overflow: "auto" }}
        className="flex flex-1 flex-col"
      >
        {embedded ? null : (
          <div className="p-5">
            <a
              target="_blank"
              href="http://momentostream.com/"
              rel="noreferrer"
            >
              <img
                className="h-8"
                src={MOMENTO_LOGO_FULL}
                alt="momento logo full"
              />
            </a>
          </div>
        )}

        {micCamStateAvailable ? (
          <div className="flex flex-1 items-center justify-center">
            <div
              className={`flex items-center justify-center flex-col ${
                isMobilePortrait
                  ? "px-5"
                  : isMobileLandscape
                  ? "px-5"
                  : "w-64 md:w-72 lg:w-96 xl:w-96 2xl:w-96"
              }`}
            >
              <h4
                className={`${
                  isBigScreen || isDesktopOrLaptop ? "text-2xl" : "text-lg"
                } font-semibold text-black text-center`}
              >
                {micOrWebcamDenied
                  ? "The browser has blocked your mic and camera"
                  : "Let's check your camera and mic"}
              </h4>

              <div
                className={`flex items-center justify-center ${
                  isMobileLandscape ? "flex-row" : "flex-col"
                }`}
              >
                <div
                  className={`flex items-center justify-center flex-col ${
                    isMobileLandscape ? "flex-1 p-3" : ""
                  }`}
                >
                  {micOrWebcamDenied ? (
                    <React.Fragment>
                      <div className="flex flex-col items-center">
                        <div
                          className={`${
                            isBigScreen || isDesktopOrLaptop ? "mt-5" : "mt-1"
                          }`}
                        >
                          <img
                            alt={"mic-cam-permission-toolbar"}
                            src={micCamPermissionToolbar}
                          />
                        </div>

                        <p
                          className={`${
                            isBigScreen || isDesktopOrLaptop ? "mt-4" : "mt-2"
                          } text-center`}
                        >
                          To join the broadcast, click the camera icon in your
                          address bar.
                        </p>

                        <p
                          className={`${
                            isBigScreen || isDesktopOrLaptop ? "mt-4" : "mt-2"
                          } text-center`}
                        >
                          Then check the{" "}
                          <span className="font-bold italic">Always allow</span>{" "}
                          option and click{" "}
                          <span className="font-bold italic">Done</span>. Then
                          reload the page.
                        </p>

                        <p
                          className={`text-sm to-gray-800 ${
                            isBigScreen || isDesktopOrLaptop ? "mt-3" : "mt-1"
                          } text-center`}
                        >
                          Still not working? MomentoStream may have lost access
                          to your mic/camera.
                        </p>
                        <a
                          rel="noreferrer"
                          href={
                            "https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop"
                          }
                          target={"_blank"}
                        >
                          <p className="text-sm to-gray-800 mt-1 link link-primary text-center">
                            Follow these steps.
                          </p>
                        </a>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div
                        className={`w-full flex ${
                          isBigScreen || isDesktopOrLaptop ? "mt-5" : "mt-1"
                        } border-gray-300 border rounded-md overflow-hidden`}
                      >
                        <div className="w-full relative flex bg-gray-800">
                          <div
                            className="w-full"
                            style={{
                              height: isUltrawidePortrait
                                ? 80
                                : isBigScreen
                                ? 200
                                : isDesktopOrLaptop
                                ? 180
                                : 160,
                              aspectRatio: 4 / 3,
                            }}
                          >
                            {webcamOn ? (
                              <VideoMediaStreamPlayer
                                {...{
                                  flipStyle,
                                  videoOn: webcamOn,
                                  videoTrack: webcamTrack,
                                  participantId: localParticipantId,
                                  isWebcam: true,
                                  key: `JoinScreenContainer-VideoMediaStreamPlayer-${webcamTrack?.id}-${localParticipantId}`,
                                }}
                              />
                            ) : (
                              <div className="h-full w-full flex items-center justify-center">
                                <div className="h-16 w-16 rounded-full">
                                  <img
                                    alt={`in-queue-webcam-stream-avatar`}
                                    className="rounded-full"
                                    src={defaultAvatarImage}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="absolute bottom-2 left-2 right-2 flex flex-col justify-center items-start">
                            <JoinScreenCornerDisplayName {...{ name, micOn }} />
                          </div>
                        </div>
                        <MicVolumeBar {...{ micOn }} />
                      </div>

                      <div className="mt-5 flex items-center justify-center shadow-lg rounded-lg border border-gray-200">
                        <IconButton
                          {...{
                            onClick: _toggleMic,
                            Icon: micOn ? MdMic : MdMicOff,
                            title: isMobilePortrait
                              ? "Mic"
                              : micOn
                              ? "Mute"
                              : "Unmute",
                            popperTitle: "",
                            variant: micOn
                              ? iconButtonVariants.info
                              : iconButtonVariants.error,
                            maxTitle: isMobilePortrait ? "Mic" : "Unmute",
                            rounded: "rounded-l-md",
                          }}
                        />
                        <IconButton
                          {...{
                            onClick: _toggleWebcam,
                            Icon: webcamOn ? MdVideocam : MdVideocamOff,
                            title: isMobilePortrait
                              ? "Cam"
                              : webcamOn
                              ? "Stop Cam"
                              : "Start Cam",
                            popperTitle: "",
                            variant: webcamOn
                              ? iconButtonVariants.info
                              : iconButtonVariants.error,
                            maxTitle: isMobilePortrait ? "Cam" : "Start Cam",
                          }}
                        />

                        <IconButton
                          {...{
                            onClick: _handleToggleSettingsModal,
                            Icon: MdSettings,
                            title: "Settings",
                            popperTitle: "",
                            variant: iconButtonVariants.info,
                            maxTitle: "Settings",
                          }}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </div>

                <div
                  className={`flex items-center justify-center flex-col ${
                    isMobileLandscape ? "flex-1 p-3" : "w-full"
                  }`}
                >
                  {showJoinWithoutMicCamButton ? (
                    <div className="mt-5">
                      <button
                        onClick={() => {
                          setAllowJoinWithoutMicWebcamPermission(true);
                        }}
                        className="btn btn-primary btn-link"
                      >
                        {"Join without mic/cam"}
                      </button>
                    </div>
                  ) : (
                    <React.Fragment>
                      {defaultName ? null : (
                        <React.Fragment>
                          <div className="mt-5 flex flex-col w-full">
                            <div className="form-control w-full">
                              <label className="label">
                                <span className="label-text">Display name</span>
                              </label>
                              <input
                                onKeyUp={async (e) => {
                                  if (e.key === "Enter") {
                                    if (
                                      name &&
                                      String(name).trim().length > 1
                                    ) {
                                      _handleJoin({
                                        name,
                                        selectedMicDeviceId,
                                        selectedWebcamDeviceId,
                                        mirrorLocalWebcam,
                                        webcamOn,
                                        micOn,
                                        participantHeadline,
                                      } as joinStateType);

                                      CachedUserData.updateName(name);

                                      CachedUserData.updateParticipantHeadline(
                                        participantHeadline || ""
                                      );

                                      setNameError(false);
                                    } else {
                                      setNameError(true);
                                    }
                                  }
                                }}
                                value={name || undefined}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                                type="text"
                                className="input input-bordered w-full"
                              />
                              {nameError && (
                                <p className="text-red-500">
                                  Please enter your name
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="mt-2 flex flex-col w-full">
                            <div className="form-control w-full">
                              <label className="label">
                                <span className="label-text">
                                  Headline (Optional)
                                </span>
                              </label>
                              <input
                                value={participantHeadline}
                                onChange={(e) => {
                                  setParticipantHeadline(e.target.value);
                                }}
                                placeholder="eg: Founder of Imagination Inc"
                                type="text"
                                className="input input-bordered w-full"
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )}

                      <div className="mt-5 w-full">
                        <button
                          onClick={() => {
                            if (name && String(name).trim().length > 1) {
                              _handleJoin({
                                name,
                                selectedMicDeviceId,
                                selectedWebcamDeviceId,
                                mirrorLocalWebcam,
                                webcamOn,
                                micOn,
                                participantHeadline,
                              } as joinStateType);

                              CachedUserData.updateName(name);

                              CachedUserData.updateParticipantHeadline(
                                participantHeadline || ""
                              );

                              setNameError(false);
                            } else {
                              setNameError(true);
                            }
                          }}
                          className="btn btn-primary text-white btn-block"
                        >
                          {appMode === appModes.SPEAKER && waitingRoomEnabled
                            ? micOrWebcamDenied
                              ? "Ask to Join without mic/cam"
                              : "Ask to Join"
                            : micOrWebcamDenied
                            ? "Enter without mic/cam"
                            : "Enter Studio"}
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 items-center justify-center">
            <Spinner h={"h-20"} w={"w-20"} />
          </div>
        )}
      </div>

      <SettingsContainer
        {...{
          isOpen: settingsModalOpen,
          handleCancel: () => {
            setSettingsModalOpen(false);
          },
        }}
      />

      <AskMicPermissionModal micDenied={micDenied} />
      <AskCameraPermissionModal cameraDenied={cameraDenied} />
    </React.Fragment>
  );
};

export default JoinScreenContainer;
