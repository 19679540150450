import React, { Fragment, useEffect, useRef, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";

import { Popover, Transition } from "@headlessui/react";

const ColorPicker = ({
  color: _color,
  setColor: _setColor,
}: {
  color: string;
  setColor: (color: string) => void;
}) => {
  const changedColor = useRef<string>();

  const [color, setColor] = useState(_color);

  useEffect(() => {
    setColor(_color);
  }, [_color]);

  return (
    <div className="">
      <Popover className="relative">
        {({ open }) => (
          <React.Fragment>
            <Popover.Button
              className={`
            ${open ? "" : "text-opacity-90"}
              inline-flex items-center rounded-md px-3 py-2 `}
            >
              <div className="flex">
                <div
                  className="h-12 w-12 rounded-md border border-gray-300"
                  style={{ backgroundColor: color }}
                ></div>
                <div className="border rounded-md border-gray-300 flex items-center justify-center w-28 ml-3 p-2">
                  <p className="text-left flex flex-1">{color}</p>
                </div>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute  mt-3  z-50">
                <div className="bg-white p-2 rounded-md shadow-xl">
                  <HexColorPicker
                    className="rounded-none"
                    color={color}
                    onChange={(color) => {
                      setColor(color);

                      changedColor.current = color;
                    }}
                    onMouseUp={() => {
                      if (changedColor.current) {
                        _setColor(changedColor.current);
                      }
                    }}
                  />
                  <HexColorInput
                    prefixed
                    className="p-2 w-full"
                    color={color}
                    onChange={setColor}
                  />
                </div>
              </Popover.Panel>
            </Transition>
          </React.Fragment>
        )}
      </Popover>
    </div>
  );
};

export default ColorPicker;
