import { useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { MdAdd, MdAddCircleOutline } from "react-icons/md";
import activateBrandAsset from "../../../apis/brands/activate-brand-asset";
import deactivateBrandAsset from "../../../apis/brands/deactivate-brand-asset";
import deleteBrandAsset from "../../../apis/brands/delete-brand-asset";
import updateBrandAsset from "../../../apis/brands/update-brand-asset";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import { useAppContext } from "../../../contexts/appContextDef";
import useBackgrounds from "../../../hooks/brands/useBackgrounds";
import useMainViewBackground from "../../../hooks/activeBrand/useMainViewBackground";
import AddNewOrSelectDefaultAssetsModal from "../../../components/AddNewOrSelectDefaultAssetsModal";
import BrandAssetsDraggableItem from "./BrandAssetsDraggableItem";
import useIsProfessionalPlanActive from "../../../hooks/plans/useIsProfessionalPlanActive";
import Tooltip from "../../../components/Tooltip";
import { createUID } from "../../../utils/createUID";
import BrandUploadingAndProcessingItems from "./BrandUploadingAndProcessingItems";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";
import { appQueueEvents } from "../../../listners/appState/AppQueueListner";
import sleep from "../../../utils/sleep";
import { mainViewBackgroundTypes } from "../../../utils/constants";
import DisclosureContainer from "../../../components/DisclosureContainer";

const BackgroundsContainer = ({
  listenToCloseEvent,
  defaultOpen,
  brandId,
}: {
  listenToCloseEvent?: string;
  defaultOpen?: boolean;
  brandId: string;
}) => {
  const {
    mainViewBackground,
    sortedBackgroundIds: allBrandsSortedBackgroundIds,
    sortedBackgrounds: allBrandsSortedBackgrounds,
    newBackgroundsState,
  } = useAppContext();

  const { userId } = useAppConfigContext();

  const sortedBackgrounds = useMemo(
    () => allBrandsSortedBackgrounds[brandId] || [],
    [allBrandsSortedBackgrounds, brandId]
  );

  const sortedBackgroundIds = useMemo(
    () => allBrandsSortedBackgroundIds[brandId] || [],
    [allBrandsSortedBackgroundIds, brandId]
  );

  const { add, sort, remove, rename } = useBackgrounds({ id: brandId });
  const { changeBackground } = useMainViewBackground();

  const { isProfessionalPlanActive } = useIsProfessionalPlanActive();

  const [_sortedBackgrounds, setSortedBackgrounds] =
    useState(sortedBackgrounds);

  const [assetsSelectionModalOpen, setAssetsSelectionModalOpen] =
    useState(false);
  useEffect(() => {
    setSortedBackgrounds(sortedBackgrounds);
  }, [sortedBackgrounds]);

  const sortedBackgroundIdsRef = useRef(sortedBackgroundIds);

  useEffect(() => {
    sortedBackgroundIdsRef.current = sortedBackgroundIds;
  }, [sortedBackgroundIds]);

  const onFileEvent = async ({
    remoteUrl,
    singleFile,
    fileName,
  }: {
    remoteUrl?: string;
    singleFile?: File;
    fileName?: string;
  }) => {
    const uploadId = createUID();

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BRAND_BACKGROUND,
      //
      isUploading: true,
      isProcessing: false,
      isCompleted: false,
      //
      brandId,
      uploadId,
      name: fileName,
    });

    const newId = await add({ singleFile, remoteUrl, uploadId });

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BRAND_BACKGROUND,
      //
      isUploading: false,
      isProcessing: false,
      isCompleted: true,
      //
      brandId,
      uploadId,
      name: fileName,
    });

    if (newId) {
      await new Promise((resolve) => setTimeout(resolve, 100));

      const _sortedBackgroundIds = [...sortedBackgroundIdsRef.current];

      sort({ ids: [..._sortedBackgroundIds, newId] });
    }

    return { id: newId };
  };

  const reorder = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (
      typeof result?.source?.index == "number" &&
      typeof result?.destination?.index === "number"
    ) {
      const _sortedBackgroundIds = reorder(
        [...sortedBackgroundIdsRef.current],
        result.source.index,
        result.destination.index
      );

      setSortedBackgrounds((s) =>
        _sortedBackgroundIds.map(
          (id) =>
            s.find(({ id: _id }) => id === _id) as singleBrandBackgroundType
        )
      );

      sort({ ids: _sortedBackgroundIds });
    }
  };

  const _handleRemove = async (id: string) => {
    const { success } = await deleteBrandAsset({
      brandId,
      fileId: id,
      userId,
    });

    if (success) {
      const _sortedBackgroundIds = [...sortedBackgroundIdsRef.current];

      remove({ id });

      await new Promise((resolve) => setTimeout(resolve, 100));

      sort({ ids: _sortedBackgroundIds.filter((_id) => id !== _id) });
    }
  };

  const _handleRename = async ({ id, name }: { id: string; name: string }) => {
    const { success } = await updateBrandAsset({
      brandId,
      fileId: id,
      name,
      userId,
    });

    if (success) {
      rename({ name, id });
    }
  };

  const _handleSelectBackground = async ({
    id,
    value,
  }: {
    id: string;
    value: string;
  }) => {
    const { success } = await activateBrandAsset({
      brandId,
      fileId: id,
      type: "background",
      userId,
    });

    if (success) {
      const type = mainViewBackgroundTypes.IMAGE;

      changeBackground({ id, type, value } as singleBrandBackgroundType);
    }
  };

  const _handleDeSelectBackground = async ({ id }: { id: string }) => {
    const { success } = await deactivateBrandAsset({
      brandId,
      fileId: id,
      type: "background",
      userId,
    });

    if (success) {
      const type = mainViewBackgroundTypes.NO_BACKGROUND;

      changeBackground({
        id: null,
        type,
        value: null,
      });
    }
  };

  const newBrandBackgroundsState = useMemo(
    () =>
      newBackgroundsState.filter(
        ({ brandId: _brandId }) => brandId === _brandId
      ),
    [newBackgroundsState, brandId]
  );

  const canAddNewItem = useMemo(
    () =>
      !(
        (_sortedBackgrounds?.length || 0) +
          (newBrandBackgroundsState?.length || 0) >=
        (isProfessionalPlanActive ? 10 : 5)
      ),
    [_sortedBackgrounds, newBrandBackgroundsState, isProfessionalPlanActive]
  );

  const _handleAddAndActivate = async ({
    remoteUrl,
  }: {
    remoteUrl: string;
  }) => {
    const { id } = await onFileEvent({ remoteUrl });

    if (id) {
      await sleep(500);

      _handleSelectBackground({ id, value: remoteUrl });
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_BACKGROUND(brandId),
      _handleAddAndActivate
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_BACKGROUND(brandId),
        _handleAddAndActivate
      );
    };
  }, [brandId]);

  return (
    <DisclosureContainer
      listenToCloseEvent={listenToCloseEvent}
      defaultOpen={defaultOpen}
      title={"Background"}
      dataTip={`You'll see your background if you have a guest, are sharing a screen, or remove your camera from the broadcast.`}
      dataTipn={["Recommended size: 1280 x 720", "Max file size: 20 MB"]}
      renderPanel={() => {
        return (
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className="flex flex-1 flex-col"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {_sortedBackgrounds.map((item, index) => {
                      const id = item.id;
                      const isSelected = mainViewBackground.id === item.id;
                      const remoteUrl = item.remoteUrl;
                      const title = item.name;

                      return (
                        <BrandAssetsDraggableItem
                          {...{
                            key: `brand-backgrounds-item-${id}`,
                            PlayIcon: MdAddCircleOutline,
                            id: id as string,
                            index,
                            isActive: isSelected,
                            landscapeThumbnail: true,
                            name: title as string,
                            onDelete: () => {
                              _handleRemove(id as string);
                            },
                            onPlay: () => {
                              _handleSelectBackground({
                                id: id as string,
                                value: remoteUrl as string,
                              });
                            },
                            onPause: () => {
                              _handleDeSelectBackground({ id: id as string });
                            },
                            onRename: (name) => {
                              _handleRename({
                                id: id as string,
                                name: name as string,
                              });
                            },
                            playText: "Show",
                            thumbnailImage: remoteUrl,
                          }}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <BrandUploadingAndProcessingItems
              {...{ newItemsState: newBrandBackgroundsState }}
            />

            <div className="mt-1">
              <Tooltip
                {...{
                  disabled: canAddNewItem,
                  title:
                    "You have reached the maximum backgrounds upload limit. Delete one to upload a new one.",
                }}
              >
                <button
                  onClick={() => {
                    if (canAddNewItem) {
                      setAssetsSelectionModalOpen(true);
                    }
                  }}
                  className={`gap-2 btn btn-sm btn-primary btn-outline normal-case ${
                    canAddNewItem ? "" : "opacity-60 cursor-not-allowed"
                  }`}
                >
                  <MdAdd size={24} />
                  {"Add new background"}
                </button>
              </Tooltip>
            </div>

            <AddNewOrSelectDefaultAssetsModal
              {...{
                maxFileSizeInMB: 20,
                isOpen: assetsSelectionModalOpen,
                onClose: () => {
                  setAssetsSelectionModalOpen(false);
                },
                onSuccess: () => {
                  setAssetsSelectionModalOpen(false);
                },
                title: "Add Background",
                // acceptFileType: "image/*",
                acceptFileType: ".png,.jpeg,.jpg",
                inputButtonText: "Select image",
                onFileEvent,
                type: "image",
                defaultItems: [
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/backgrounds/Polygonal.jpg",
                    useRemoteUrl: true,
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/backgrounds/Smooth-Blue.jpg",
                    useRemoteUrl: true,
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/backgrounds/Solid-Green.jpg",
                    useRemoteUrl: true,
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/backgrounds/Solid-Orange.jpg",
                    useRemoteUrl: true,
                  },
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/backgrounds/Solid-Purple.jpg",
                    useRemoteUrl: true,
                  },
                ],
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default BackgroundsContainer;
