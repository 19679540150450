import PollListItem from "./PollListItem";
import PollDetail from "./PollDetail";
import { quickstartTargetComponentClassNames } from "../../quickStartTourContainer/QuickStartTourContainer";

const SidePanelPollDetail = ({ id }: { id: string }) => {
  return (
    <div className={`${quickstartTargetComponentClassNames.poll_detail}`}>
      <div>
        <PollListItem id={id} showBackIcon />
      </div>

      <div
        className={`${quickstartTargetComponentClassNames.submit_poll_answer}`}
      >
        <PollDetail id={id} />
      </div>
    </div>
  );
};

export default SidePanelPollDetail;
