import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import SidePanelTopBar from "../sidePanel/SidePanelTopBar";
import {
  MdDelete,
  MdEdit,
  MdGroupAdd,
  MdMoreVert,
  MdPeople,
} from "react-icons/md";
import PrivateChatGroupInfoEditor from "./PrivateChatGroupInfoEditor";
import { useAppContext } from "../../contexts/appContextDef";
import usePrivateChatGroup from "../../hooks/privateChat/usePrivateChatGroup";
import { Menu, MenuItem } from "@szhsin/react-menu";
import sleep from "../../utils/sleep";
import { getSidePanelPrivateChatMessageListMode } from "../sideContainer/SideContainer";
import { appModes } from "../../utils/constants";

const editGroupSettingsModalDefaultState = {
  open: false,
  name: null,
  participantIds: null,
};

export const PrivateChatGroupMenu = ({
  id,
  name,
  participantIds,
}: {
  id: string;
  name: string;
  participantIds: string[];
}) => {
  const [editGroupSettingsModal, setEditGroupSettingsModal] = useState<{
    open: boolean;
    name: null | string;
    participantIds: null | string[];
  }>(editGroupSettingsModalDefaultState);

  const { renameGroup, removeParticipantsFromGroup, addParticipantsFromGroup } =
    usePrivateChatGroup();
  const [editingInProgress, setEditingInProgress] = useState(false);

  const { joinedParticipants } = useAppContext();

  const joinedParticipantsRef = useRef(joinedParticipants);

  useEffect(() => {
    joinedParticipantsRef.current = joinedParticipants;
  }, [joinedParticipants]);

  return (
    <React.Fragment>
      <Menu
        menuButton={
          <span>
            <button
              className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
            >
              <div>
                <MdMoreVert size={16} />
              </div>
            </button>
          </span>
        }
        transition
      >
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            setEditGroupSettingsModal({
              open: true,
              name,
              participantIds,
            });
          }}
        >
          <div className="flex items-center justify-center gap-1">
            <MdEdit />
            <p className="ml-2">Edit</p>
          </div>
        </MenuItem>
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {}}
        >
          <div className="flex items-center justify-center gap-1">
            <MdDelete />
            <p className="ml-2">Delete group</p>
          </div>
        </MenuItem>
      </Menu>

      {editGroupSettingsModal.open ? (
        <PrivateChatGroupInfoEditor
          editingInProgress={editingInProgress}
          defaultName={editGroupSettingsModal.name}
          handleCancle={() => {
            setEditGroupSettingsModal(editGroupSettingsModalDefaultState);
          }}
          defaulSelectedParticipantIds={
            editGroupSettingsModal.participantIds
              ? editGroupSettingsModal.participantIds.map(
                  (studioUserId) =>
                    joinedParticipantsRef.current.find(
                      ({ studioUserId: _studioUserId }) =>
                        _studioUserId === studioUserId
                    )?.participantId || ""
                )
              : []
          }
          handleSave={async ({
            isRenamed,
            name,
            removedParticipantIds: _removedParticipantIds,
            newParticipantIds: _newParticipantIds,
          }) => {
            setEditingInProgress(true);

            if (isRenamed) {
              renameGroup({ id, name });

              await sleep(300);
            }

            if (_removedParticipantIds?.length) {
              const removedParticipantIds = _removedParticipantIds.map(
                (participantId) =>
                  joinedParticipantsRef.current.find(
                    ({ participantId: _participantId }) =>
                      _participantId === participantId
                  )?.studioUserId || ""
              );

              removeParticipantsFromGroup({
                id,
                participantIds: removedParticipantIds,
              });

              await sleep(300);
            }

            if (_newParticipantIds?.length) {
              const newParticipantIds = _newParticipantIds.map(
                (participantId) =>
                  joinedParticipantsRef.current.find(
                    ({ participantId: _participantId }) =>
                      _participantId === participantId
                  )?.studioUserId || ""
              );

              addParticipantsFromGroup({
                id,
                participantIds: newParticipantIds,
              });

              await sleep(300);
            }

            setEditingInProgress(false);

            setEditGroupSettingsModal(editGroupSettingsModalDefaultState);
          }}
        />
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const PrivateChatGroupsContainerItem = ({
  id,
  name,
  participantIds,
}: {
  id: string;
  name: string;
  participantIds: string[];
}) => {
  const {
    appMode,
    setSidePanelPrivateChatMode,
    participatedPrivateChatGroupsUnreadedChats,
  } = useAppContext();

  //
  const container2Ref = createRef<HTMLDivElement>();
  const [container2Width, setContainer2Width] = useState(0);

  const container2WidthRef = useRef();

  useEffect(() => {
    if (container2Ref.current) {
      const { current } = container2Ref;

      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;

      container2WidthRef.current !== width && setContainer2Width(width);
    }
  }, [container2Ref]);

  //
  const container3Ref = createRef<HTMLDivElement>();
  const [container3Height, setContainer3Height] = useState(0);

  const container3HeightRef = useRef();

  useEffect(() => {
    if (container3Ref.current) {
      const { current } = container3Ref;

      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;

      container3HeightRef.current !== height && setContainer3Height(height);
    }
  }, [container3Ref]);

  const isAnyChatUnreaded = useMemo(
    () =>
      participatedPrivateChatGroupsUnreadedChats.find(
        ({ id: _id }) => _id === id
      )?.isAnyChatUnreaded,
    [participatedPrivateChatGroupsUnreadedChats, id]
  );

  return (
    <React.Fragment>
      <div
        onClick={() => {
          setSidePanelPrivateChatMode(
            getSidePanelPrivateChatMessageListMode(id)
          );
        }}
        className="flex w-full border-b p-3 cursor-pointer"
      >
        <div
          className="flex items-center justify-center relative"
          style={{ width: container3Height, height: container3Height }}
        >
          <MdPeople size={24} className="fill-primary" />

          {isAnyChatUnreaded ? (
            <div className="absolute top-1 left-1 h-2 w-2 bg-orange-400 text-white font-bold text-sm rounded-full flex items-center justify-center"></div>
          ) : (
            <React.Fragment />
          )}
        </div>

        <div
          ref={container3Ref as React.LegacyRef<HTMLDivElement>}
          style={{
            marginLeft: 8,
            width: `calc(100% - ${container3Height}px - ${container2Width}px - ${
              appMode === appModes.HOST ? 16 : 8
            }px)`,
          }}
        >
          <div className="truncate text-ellipsis font-bold">{name}</div>

          <div className="text-xs font-gray-500 mt-0.5">
            {participantIds.length} people
          </div>
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="flex items-center justify-center"
          ref={container2Ref as React.LegacyRef<HTMLDivElement>}
        >
          {appMode === appModes.HOST ? (
            <div style={{ marginLeft: 8 }}>
              <PrivateChatGroupMenu {...{ id, name, participantIds }} />
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const PrivateChatGroupsContainer = ({
  onCloseButtonClick,
  containerHeight,
  containerWidth,
}: {
  onCloseButtonClick: () => void;
  containerHeight: number;
  containerWidth: number;
}) => {
  const [privateChatGroupInfoEditorOpen, setPrivateChatGroupInfoEditorOpen] =
    useState(false);

  const { createGroup } = usePrivateChatGroup();

  const { participatedPrivateChatGroups, joinedParticipants } = useAppContext();

  const joinedParticipantsRef = useRef(joinedParticipants);

  useEffect(() => {
    joinedParticipantsRef.current = joinedParticipants;
  }, [joinedParticipants]);

  return (
    <div style={{ height: containerHeight, width: containerWidth }}>
      <SidePanelTopBar
        onCloseButtonClick={onCloseButtonClick}
        title={"Private Chat"}
        renderRightComponent={() => (
          <button
            onClick={() => {
              setPrivateChatGroupInfoEditorOpen((s) => !s);
            }}
            className={`gap-2 btn btn-primary btn-outline normal-case btn-sm`}
          >
            <MdGroupAdd />
          </button>
        )}
      />

      {participatedPrivateChatGroups.length ? (
        participatedPrivateChatGroups.map(
          ({ id, isVisible, name, participantIds }) => {
            return (
              <PrivateChatGroupsContainerItem
                key={`PrivateChatGroupsContainerItem_${id}`}
                {...{ id, isVisible, name, participantIds }}
              />
            );
          }
        )
      ) : (
        <div className="h-56 w-full flex items-center justiffy-center text-center ">
          <p className="text-center w-full">{`No group private chat created yet`}</p>
        </div>
      )}

      {privateChatGroupInfoEditorOpen ? (
        <PrivateChatGroupInfoEditor
          defaultName={""}
          handleCancle={() => {
            setPrivateChatGroupInfoEditorOpen((s) => !s);
          }}
          defaulSelectedParticipantIds={[]}
          isCreating={true}
          handleSave={({ name, participantIds }) => {
            createGroup({
              name,
              participantIds: participantIds.map(
                (participantId) =>
                  joinedParticipantsRef.current.find(
                    ({ participantId: _participantId }) =>
                      _participantId === participantId
                  )?.studioUserId || ""
              ),
            });

            setPrivateChatGroupInfoEditorOpen((s) => !s);
          }}
        />
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default PrivateChatGroupsContainer;
