import { egressManagerApiServerBaseUrl } from "../../utils/constants";

const startBroadcastEgresses = async ({
  broadcastId,
  videosdkMeetingId,
  videosdkSessionId,
  livekitRoomSid,
  recordingEnabled,
  rtmpLivestreamEnabled,
  interpretations,
  rtmpDestinationUrls,
  userId,
}: {
  broadcastId: string;
  videosdkMeetingId?: string;
  videosdkSessionId?: string;
  livekitRoomSid?: string;
  recordingEnabled: boolean;
  rtmpLivestreamEnabled: boolean;
  interpretations: {
    languageCode: string;
    rtmpDestinationUrl: string;
  }[];
  rtmpDestinationUrls: string[];
  userId: string;
}) => {
  try {
    const url = `${egressManagerApiServerBaseUrl}/start-broadcast-egresses`;
    const body = JSON.stringify({
      broadcastId,
      videosdkMeetingId,
      videosdkSessionId,
      livekitRoomSid,
      recordingEnabled,
      rtmpLivestreamEnabled,
      interpretations,
      rtmpDestinationUrls,
      userId,
      pageBaseUrl: `${window.location.origin}${window.location.pathname}`,
    });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true, egresses: json.egresses };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default startBroadcastEgresses;
