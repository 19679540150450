import { appRtcClient, appRtcClients } from "../utils/constants";
import useLivekitRtcRemoteParticipantMediaStats from "./livekitRtc/useLivekitRtcRemoteParticipantMediaStats";

const useAppRtcRemoteParticipantMediaStats =
  appRtcClient === appRtcClients.livekit
    ? useLivekitRtcRemoteParticipantMediaStats
    : (): {
        webcamOn: boolean;
        webcamTrack?: MediaStreamTrack;
        micOn: boolean;
        micTrack?: MediaStreamTrack;
        screenShareVideoOn: boolean;
        screenShareVideoTrack?: MediaStreamTrack;
        screenShareAudioOn: boolean;
        screenShareAudioTrack?: MediaStreamTrack;
        inputFileVideoShareStreamPublications: {
          id: string;
          fileObjectUrl: string;
          inputFileVideoSourceElementId: string;
          videoTrack?: MediaStreamTrack;
          audioTrack?: MediaStreamTrack;
        }[];
        extraWebcamStreams: {
          extraWebcamId: string;
          videoTrack?: MediaStreamTrack;
        }[];
      } => ({
        webcamOn: false,
        webcamTrack: undefined,
        micOn: false,
        micTrack: undefined,
        screenShareVideoOn: false,
        screenShareVideoTrack: undefined,
        screenShareAudioOn: false,
        screenShareAudioTrack: undefined,
        inputFileVideoShareStreamPublications: [],
        extraWebcamStreams: [],
      });

export default useAppRtcRemoteParticipantMediaStats;
