// import { usePubSub } from "@videosdk.live/react-sdk";
import { createUID } from "../../utils/createUID";
import { useEffect, useRef } from "react";
import useLocalParticipantId from "../utils/useLocalParticipantId";
import { getValidateConnectionTopicById } from "../../utils/pubSubTopics";
import { validateConnectionActions } from "../../listners/appState/ValidateConnectionListner";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { useAppContext } from "../../contexts/appContextDef";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

export const signalLevels = {
  good: "good",
  average: "average",
  bad: "bad",
};

const useValidateConnection = () => {
  const { signalLevel } = useAppContext();

  const signalLevelRef = useRef(signalLevel);

  useEffect(() => {
    signalLevelRef.current = signalLevel;
  }, [signalLevel]);

  const { localParticipantId } = useLocalParticipantId();

  // const publishRef = useRef<videosdkPubsubPublishType>();

  const publishRef = useRef<appSingalingPublishType>();

  // const { publish } = usePubSub(
  //   getValidateConnectionTopicById(localParticipantId)
  // );

  const { publish } = useAppSingalingPublish(
    getValidateConnectionTopicById(localParticipantId)
  );

  const validateConnection = async () => {
    const initAtTimestamp = new Date().getTime();

    const { pingInMS }: { pingInMS: number } = await new Promise((resolve) => {
      const publish = publishRef.current;

      const id = createUID();

      appEventEmitter.once(
        appEventEmitterEvents.VALIDATE_CONNECTION_RESPONSE(id),
        () => {
          const pingInMS = new Date().getTime() - initAtTimestamp;

          resolve({ pingInMS });
        }
      );

      const payload = { id, initAtTimestamp };

      if (publish) {
        publish(
          JSON.stringify({ action: validateConnectionActions.INIT, payload })
          // { persist: false }
        );
      }
    });

    return { pingInMS };
  };

  const getIsSignalLevelValid = () => {
    const signalLevel = signalLevelRef.current;

    const isSignalLevelValid =
      signalLevel === signalLevels.good || signalLevel === signalLevels.average;

    return { isSignalLevelValid };
  };

  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  return { validateConnection, getIsSignalLevelValid };
};

export default useValidateConnection;
