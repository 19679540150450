import { Room } from "livekit-client";
import generateLivekitRtcConfig from "../../apis/studio/generate-livekit-rtc-config";

export const createLivekitSignalingClientAndJoinChannel = async ({
  broadcastId,
  appMode,
  studioUserId,
  participantName,
  isInterpreter,
  isRecorder,
  participantId,
}: {
  broadcastId: string;
  appMode: string;
  studioUserId: string;
  participantName: string;
  isInterpreter?: boolean;
  isRecorder?: boolean;
  participantId: string;
}): Promise<{
  sid?: string;
  participantId?: string;
  success: boolean;
  signalingClient?: signalingClientType;
  livekitRtcConfig?: {
    token: string;
    roomName: string;
    participantId: string;
    websocketUrl: string;
  };
  disconnectReason?: string;
  signalingRoomInfo?: {
    roomId: string;
    roomCreatedAt: string;
  };
}> => {
  try {
    const room = new Room();

    const roomName = broadcastId;

    const { success, data } = await generateLivekitRtcConfig({
      participantId,
      roomName,
      appMode,
      studioUserId,
      participantName,
      isInterpreter,
      isRecorder,
    });

    if (success && data) {
      await room.connect(data.websocketUrl, data.token, {
        autoSubscribe: false,
      });

      const participantId = room.localParticipant.identity;
      const sid = room.localParticipant.sid;

      const { sid: roomId, creationTime: roomCreatedAt } = JSON.parse(
        JSON.stringify(room)
      ).roomInfo;

      return {
        sid,
        participantId,
        success: true,
        signalingClient: room,
        livekitRtcConfig: data,
        signalingRoomInfo: { roomId, roomCreatedAt },
      };
    } else {
      return { success: false };
    }
  } catch (err) {
    return { success: false };
  }
};
