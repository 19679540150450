import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";

const useMainViewOverlay = () => {
  const { publish } = useAppSingalingPublish(appPubSubTopics.MAIN_VIEW_OVERLAY);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const changeOverlay = async (
    overlay: mainViewOverlayStateType,
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(JSON.stringify(overlay));
  };

  return { changeOverlay };
};

export default useMainViewOverlay;
