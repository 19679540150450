// import { usePubSub } from "@videosdk.live/react-sdk";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { highlightedChatActions } from "../../listners/appState/HighlightedChatListner";
import { useAppContext } from "../../contexts/appContextDef";
import useValidateHostCanTakeAction from "./useValidateHostCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useHighlightedChat = () => {
  const { highlightedChat } = useAppContext();

  // const { publish } = usePubSub(appPubSubTopics.HIGHLIGHTED_CHAT);
  const { publish } = useAppSingalingPublish(appPubSubTopics.HIGHLIGHTED_CHAT);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const activateHighlightedChat = async (chat: {
    id: string;
    text: string;
    timestamp: number;
    senderName: string;
    initials: string;
    socialPlatform: string[];
    momentoSent: boolean;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: highlightedChatActions.ACTIVATE,
        payload: chat,
      })
      // { persist: true }
    );
  };

  const deactivateHighlightedChat = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ action: highlightedChatActions.DEACTIVATE })
      // , {
      //   persist: true,
      // }
    );
  };

  return {
    highlightedChat,
    activateHighlightedChat,
    deactivateHighlightedChat,
  };
};

export default useHighlightedChat;
