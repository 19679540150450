import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { getBrandOverlaysTopicById } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const overlaysActions = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  RENAME: "RENAME",
  SORT: "SORT",
};

const BrandOverlaysListner = ({ id: brandId }: { id: string }) => {
  const { setSortedOverlayIds, setOverlays } = useAppContext();

  const _handleAdd = (overlay: singleBrandOverlayType) => {
    setOverlays((s) => ({ ...s, [brandId]: [...(s[brandId] || []), overlay] }));
  };

  const _handleRemove = ({ id }: { id: string }) => {
    setOverlays((s) => ({
      ...s,
      [brandId]: s[brandId].filter(({ id: _id }) => _id !== id),
    }));
  };

  const _handleRename = ({ id, name }: { id: string; name: string }) => {
    setOverlays((s) => ({
      ...s,
      [brandId]: s[brandId].map((overlay) =>
        id === overlay.id ? { ...overlay, name } : overlay
      ),
    }));
  };

  const _handleSort = ({ ids }: { ids: string }) => {
    setSortedOverlayIds(
      (s) => ({ ...s, [brandId]: ids } as sortedOverlayIdsType)
    );
  };

  useAppSingalingSubscribe(
    getBrandOverlaysTopicById(brandId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case overlaysActions.ADD:
          _handleAdd(message.payload);
          break;

        case overlaysActions.REMOVE:
          _handleRemove(message.payload);
          break;

        case overlaysActions.RENAME:
          _handleRename(message.payload);
          break;

        case overlaysActions.SORT:
          _handleSort(message.payload);
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default BrandOverlaysListner;
