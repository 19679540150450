import React, { useEffect, useRef } from "react";
import { videoClipPubSubActions } from "../../hooks/activeBrand/useMainViewVideoClip";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const MainViewVideoClipListner = () => {
  const {
    mainViewVideoClip,
    setMainViewVideoClip,
    videosRemoteUrlReadyToBePlayed,
    waitingForVideosRemoteUrlReadyToBePlayed,
  } = useAppContext();

  const videosRemoteUrlReadyToBePlayedRef = useRef(
    videosRemoteUrlReadyToBePlayed
  );
  const waitingForVideosRemoteUrlReadyToBePlayedRef = useRef(
    waitingForVideosRemoteUrlReadyToBePlayed
  );
  const mainViewVideoClipRef = useRef(mainViewVideoClip);

  useEffect(() => {
    videosRemoteUrlReadyToBePlayedRef.current = videosRemoteUrlReadyToBePlayed;
  }, [videosRemoteUrlReadyToBePlayed]);
  useEffect(() => {
    waitingForVideosRemoteUrlReadyToBePlayedRef.current =
      waitingForVideosRemoteUrlReadyToBePlayed;
  }, [waitingForVideosRemoteUrlReadyToBePlayed]);
  useEffect(() => {
    mainViewVideoClipRef.current = mainViewVideoClip;
  }, [mainViewVideoClip]);

  const _handleChangeActiveIdAndRemoteUrl = async ({
    id,
    remoteUrl,
    initTime,
    playedAt,
  }: {
    id: string;
    remoteUrl: string;
    initTime: number;
    playedAt: number;
  }) => {
    setMainViewVideoClip((s) => ({
      ...s,
      remoteUrl,
      id,
      initTime,
      playedAt,
    }));
  };

  const _handleChangeVolume = ({ volume }: { volume: number }) => {
    setMainViewVideoClip((s) => ({ ...s, volume }));
  };

  const _handleChangeLoopState = ({
    loop,
    loopedAt,
  }: {
    loop: boolean;
    loopedAt: number;
  }) => {
    setMainViewVideoClip((s) => ({ ...s, loop, loopedAt }));
  };

  useAppSingalingSubscribe(
    appPubSubTopics.MAIN_VIEW_VIDEO_CLIP,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case videoClipPubSubActions.CHANGE_ACTIVE_ID_AND_REMOTE_URL:
          _handleChangeActiveIdAndRemoteUrl({ ...message.payload });
          break;

        case videoClipPubSubActions.CHANGE_VOLUME:
          _handleChangeVolume({ ...message.payload });
          break;

        case videoClipPubSubActions.LOOP:
          _handleChangeLoopState({ ...message.payload });
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default MainViewVideoClipListner;
