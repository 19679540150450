import React, { useEffect, useMemo, useState } from "react";
import { audioAndVideoPermissionStates } from "../../utils/constants";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import CachedUserData from "../../utils/CachedUserData";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

const AgoraAudioSettingsContainer = ({
  activeTabIndex,
}: {
  activeTabIndex: number;
}) => {
  const [mics, setMics] = useState<MediaDeviceInfo[]>([]);
  const [audioOutputDevice, setAudioOutputDevice] = useState<MediaDeviceInfo[]>(
    []
  );

  const {
    setSelectedSpeakerDeviceId,
    selectedSpeakerDeviceId,
    selectedMicDeviceId,
  } = useAppContext();

  const getMics = async () => {
    if (!navigator?.mediaDevices?.enumerateDevices) return;

    const audioInputDevice: MediaDeviceInfo[] = [];

    const devices = await navigator.mediaDevices.enumerateDevices();

    for (const device of devices) {
      if (device.kind === "audioinput") {
        audioInputDevice.push(device);
      }
    }

    setMics(audioInputDevice);
  };

  const getAudioOutputDevices = async () => {
    if (!navigator?.mediaDevices?.enumerateDevices) return;

    const audioOutputDevice: MediaDeviceInfo[] = [];

    const devices = await navigator.mediaDevices.enumerateDevices();

    for (const device of devices) {
      if (device.kind === "audiooutput") {
        audioOutputDevice.push(device);
      }
    }

    setAudioOutputDevice(audioOutputDevice);
  };

  const _handleChangeMic = async (deviceId: string) => {
    appEventEmitter.emit(
      appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_CHANGE_MIC_DEVICE_ID,
      { deviceId }
    );
  };

  const changeSpeaker = (deviceId: string) => {
    CachedUserData.updateSelectedSpeakerDeviceId(deviceId);

    setSelectedSpeakerDeviceId(deviceId);
  };

  const getDevices = async () => {
    await getMics();

    await getAudioOutputDevices();
  };

  useEffect(() => {
    getDevices();
  }, [activeTabIndex]);

  const { micState } = useAppConfigContext();

  const micDenied = useMemo(
    () => micState === audioAndVideoPermissionStates.denied,
    [micState]
  );

  return (
    <div className="flex flex-1 flex-col relative">
      {micDenied ? (
        <p className="text-red-600 font-bold">
          Your browser has blocked your microphone.
        </p>
      ) : (
        <React.Fragment />
      )}

      {micDenied ? (
        <React.Fragment />
      ) : (
        <div className={`mt-4`}>
          <div>
            <p>Mic</p>
            <select
              onChange={(e) => {
                _handleChangeMic(e.target.value);
              }}
              value={selectedMicDeviceId || ""}
              className="select w-full max-w-xs select-bordered mt-1"
            >
              {mics.map((item) => (
                <option
                  key={`mic-selector-item-${item?.deviceId}`}
                  value={item?.deviceId}
                >
                  {item?.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-4">
            <p>Speaker</p>
            <select
              onChange={(e) => {
                changeSpeaker(e.target.value);
              }}
              value={selectedSpeakerDeviceId || ""}
              className="select w-full max-w-xs select-bordered mt-1"
            >
              {audioOutputDevice.map((item) => (
                <option
                  key={`mic-selector-item-${item?.deviceId}`}
                  value={item?.deviceId}
                >
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {/* {!micOn && (
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-opacity-30 bg-white"></div>
          )} */}
    </div>
  );
};

export default AgoraAudioSettingsContainer;
