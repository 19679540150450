export const momentostreamApiServerBaseUrl =
  "https://app.momentostream.com/api";

export const egressManagerApiServerBaseUrl =
  "https://egress.momentostream.com/api/v1";

export const studioApiServerBaseUrl =
  "https://egress.momentostream.com/studio-api/v1";

export const audioAndVideoPermissionStates: {
  prompt: PermissionState;
  granted: PermissionState;
  denied: PermissionState;
} = {
  prompt: "prompt",
  granted: "granted",
  denied: "denied",
};

export const mainViewMaxWebcamStreamsCount = 12;

export const maxParticipantsCountCapacity = 100;

export const maxMediaAssetSizeMB = 200;

export const businessPlanTypes = {
  free: "free",
  basic: "basic",
  advanced: "advanced",
  professional: "professional",
};

export const businessPlansConfig: {
  [t: string]: {
    maxParticipantsCount: number;
    recordingEnabled: boolean;
    maxStreamingDestinations: number;
  };
} = {
  free: {
    maxParticipantsCount: 6,
    recordingEnabled: false,
    maxStreamingDestinations: 1,
  },
  basic: {
    maxParticipantsCount: 12,
    recordingEnabled: true,
    maxStreamingDestinations: 3,
  },
  advanced: {
    maxParticipantsCount: 100,
    recordingEnabled: true,
    maxStreamingDestinations: 8,
  },
  professional: {
    maxParticipantsCount: 100,
    recordingEnabled: true,
    maxStreamingDestinations: 9,
  },
};

export const numberToWord = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "sight",
  9: "nine",
  10: "ten",
};

export const mainViewItemsAnimationFunctionName = "ease-in";

export const mainViewItemsAnimationDuration_fast = ".2s";
export const mainViewItemsAnimationDuration_slow = "6s";
export const mainViewItemsAnimationDuration_normal = ".4s";

export const mainViewItemsAnimationDuration = ".3s";

export const webcamEncoderConfigs = {
  // 16x9
  n_01_h90p_w160p_15fps: "h90p_w160p",
  n_02_h180p_w320p_15fps: "h180p_w320p",
  n_03_h216p_w384p_15fps: "h216p_w384p",
  n_04_h360p_w640p_20fps: "h360p_w640p", // fixed encoder configs for all, if 16x9 is choice
  n_05_h540p_w960p_25fps: "h540p_w960p",

  n_06_h720p_w1280p_30fps: "h720p_w1280p",

  n_07_h1080p_w1920p_30fps: "h1080p_w1920p",
  n_08_h1440p_w2560p_30fps: "h1440p_w2560p",
  n_09_h2160p_w3840p_30fps: "h2160p_w3840p",
  // 4x3
  n_10_h120p_w160p_15fps: "h120p_w160p",
  n_11_h180p_w240p_15fps: "h180p_w240p",
  n_12_h240p_w320p_15fps: "h240p_w320p",
  n_13_h360p_w480p_20fps: "h360p_w480p",
  n_14_h480p_w640p_25fps: "h480p_w640p", // fixed encoder configs for all, if 16x9 is choice
  n_15_h540p_w720p_30fps: "h540p_w720p",
  n_16_h720p_w960p_30fps: "h720p_w960p",
  n_17_h1080p_w1440p_30fps: "h1080p_w1440p",
  n_18_h1440p_w1920p_30fps: "h1440p_w1920p",
};

export const quickstartDummyParticipants = [
  {
    participantId: `qsdummyparticipantid1`,
    name: "Dummy 1",
  },
  {
    participantId: `qsdummyparticipantid2`,
    name: "Dummy 2",
  },
  {
    participantId: `qsdummyparticipantid3`,
    name: "Dummy 3",
  },
];

export const quickstartDummyPresentationId = "qsdummypresentationid";

export const quickstartAutoOpenPopupFirstTimeId =
  "quickstart-autostarted:2Dec2023";

export const appModes = {
  HOST: "HOST" as appModeType,
  SPEAKER: "SPEAKER" as appModeType,
  VIEWER: "VIEWER" as appModeType,
};

export const interactivityModes = {
  CONFERENCE: "CONFERENCE" as interactivityModeType,
  STUDIO: "STUDIO" as interactivityModeType,
};

export const permissionNames = {
  camera: "camera" as PermissionName,
  microphone: "microphone" as PermissionName,
};

export const sidePanelModes = {
  PRIVATE_CHAT: "PRIVATE_CHAT" as sidePanelModeType,
  CHAT: "CHAT" as sidePanelModeType,
  BRAND: "BRAND" as sidePanelModeType,
  APPS: "APPS" as sidePanelModeType,
  QNA: "QNA" as sidePanelModeType,
  PARTICIPANTS: "PARTICIPANTS" as sidePanelModeType,
};

export const sidePanelAppModes = {
  BANNERS: "BANNERS" as sidePanelAppModeType,
  POLLS: "POLLS" as sidePanelAppModeType,
  WORDCLOUDS: "WORDCLOUDS" as sidePanelAppModeType,
  RESOURCES: "RESOURCES" as sidePanelAppModeType,
};

export const sidePanelBannersModes = {
  BANNERS_LIST: "BANNERS_LIST" as sidePanelBannersModeType,
  BANNER_FOLDERS_LIST: "BANNER_FOLDERS_LIST" as sidePanelBannersModeType,
};

export const mainViewOverlayTypes = {
  NO_OVERLAY: "NO_OVERLAY" as mainViewOverlayType,
  IMAGE: "IMAGE" as mainViewOverlayType,
  FREE_PLAN_OVERLAY: "FREE_PLAN_OVERLAY" as mainViewOverlayType,
};

export const mainViewBackgroundTypes = {
  NO_BACKGROUND: "NO_BACKGROUND" as mainViewBackgroundType,
  IMAGE: "IMAGE" as mainViewBackgroundType,
};

export const inQueueStreamTypes = {
  WEBCAM: "WEBCAM" as inQueueStreamTypeType,
  EXTRAWBCM: "EXTRAWBCM" as inQueueStreamTypeType,
  SHARE_SCREEN: "SHARE_SCREEN" as inQueueStreamTypeType,
  SHARE_PDF: "SHARE_PDF" as inQueueStreamTypeType,
  SHARE_VIDEO: "SHARE_VIDEO" as inQueueStreamTypeType,
  SHARE_INPUT_FILE_VIDEO: "SHARE_INPUT_FILE_VIDEO" as inQueueStreamTypeType,
  SHARE_RTMP_IN: "SHARE_RTMP_IN" as inQueueStreamTypeType,
};

export const mainViewWebcamLayouts = {
  SOLO: "SOLO" as mainViewLayoutType,
  CROPPED: "CROPPED" as mainViewLayoutType,
  GROUP: "GROUP" as mainViewLayoutType,
  SPOTLIGHT: "SPOTLIGHT" as mainViewLayoutType,
};

export const mainViewShareLayouts = {
  NEWS: "NEWS" as mainViewLayoutType,
  SCREEN: "SCREEN" as mainViewLayoutType,
  PICTURE_IN_PICTURE: "PICTURE_IN_PICTURE" as mainViewLayoutType,
  CINEMA: "CINEMA" as mainViewLayoutType,
};

export const mainViewLayouts = {
  ...mainViewWebcamLayouts,
  ...mainViewShareLayouts,
};

export const appSignalingClients = {
  livekit: "livekit" as appSignalingClientType,
};

export const appRtcClients = {
  livekit: "livekit" as appRtcClientType,
};

export const appSignalingClient = appSignalingClients.livekit;
export const appRtcClient = appRtcClients.livekit;

export const participantRoles = {
  RECORDER: "RECORDER" as participantRoleType,
  INTERPRETER: "INTERPRETER" as participantRoleType,
  PARTICIPANT: "PARTICIPANT" as participantRoleType,
};
