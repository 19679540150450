import { MOMENTO_LOGO_FULL } from "../../static/images";
import { useWindowSize } from "@react-hook/window-size";

const DisconnectedOrFailedToJoinContainer = () => {
  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <div
      style={{ width: windowWidth, height: windowHeight }}
      className="flex flex-1 flex-col p-5"
    >
      <div className="">
        <a target="_blank" href="http://momentostream.com/" rel="noreferrer">
          <img
            className="h-8"
            src={MOMENTO_LOGO_FULL}
            alt="momento logo full"
          />
        </a>
      </div>

      <div className="mt-12 text-center">
        <p className="font-bold text-xl">Something went wrong.</p>

        <p className="mt-3 font-medium">
          Please ensure you are connected to the internet. If you are, then this
          may be on our end. <br />
          <a
            className="text-primary hover:underline"
            href="mailto:david@momentosolutions.com"
          >
            Let us know
          </a>{" "}
          if this continues. We're here to help :)
        </p>

        <div className="mt-4">
          <button
            className="btn btn-primary text-white normal-case"
            onClick={() => {
              window.location.reload();
            }}
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisconnectedOrFailedToJoinContainer;
