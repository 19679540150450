const convertObjectArrayToMap = (objectArray: { K: unknown; V: unknown }[]) => {
  const map = new Map();

  objectArray.forEach(({ K, V }) => {
    map.set(K, V);
  });

  return map;
};

export default convertObjectArrayToMap;
