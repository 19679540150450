import {
  useContext,
  createContext,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { defaultAvatarImage } from "../static/images";
import { getPdfInfoFromImages } from "../utils/getPdfInfoFromImages";
import { useAppConfigContext } from "./appConfigDef";
import { MdBlurOn, MdBlock } from "react-icons/md";
import useIsDesktopOrLaptop from "../hooks/responsive/useIsDesktopOrLaptop";
import useIsBigScreen from "../hooks/responsive/useIsBigScreen";
import { getSidePanelPrivateChatMessageListIdFromMode } from "../containers/sideContainer/SideContainer";
import { getAppBannerId } from "../containers/sidePanel/banners/banners/BannerItem";
import useCalculateMainViewLayoutStates from "../hooks/appState/useCalculateMainViewLayoutStates";
import useCalculateMainViewConferenceVisibleWebcamStreams from "../hooks/conference/useCalculateMainViewConferenceVisibleWebcamStreams";
import useAllParticipantsInQueueStreams from "../hooks/appState/useAllParticipantsInQueueStreams";
import useParticipantIdsAndCount from "../hooks/appState/useParticipantIdsAndCount";
import {
  appModes,
  interactivityModes,
  mainViewBackgroundTypes,
  mainViewOverlayTypes,
  sidePanelModes,
} from "../utils/constants";
import {
  LocalTrackPublication as LivekitLocalTrackPublication,
  RemoteTrackPublication as LivekitRemoteTrackPublication,
} from "livekit-client";
import useJoinedParticipantsCalculator from "../hooks/appState/useJoinedParticipantsCalculator";
import useAppRtcAllParticipantsExtraWebcamStreams from "../appRtc/useAppRtcAllParticipantsExtraWebcamStreams";
import CachedUserData from "../utils/CachedUserData";

export const AppContext = createContext({} as appContextType);

export const useAppContext = (): appContextType => useContext(AppContext);

export const AppConsumer = AppContext.Consumer;

export const AppProvider = ({
  children,
  appMode: _appMode,
  selectedMicDeviceId: _selectedMicDeviceId,
  selectedWebcamDeviceId: _selectedWebcamDeviceId,
  selectedSpeakerDeviceId: _selectedSpeakerDeviceId,
  mirrorLocalWebcam: _mirrorLocalWebcam,
  micOn: _joinScreenMicOn,
  webcamOn: _joinScreenWebcamOn,
  participantHeadline,
  signalingClient,
  rtcClient,
  localParticipantId,
  signalingRoomInfo,
  isJoined,
}: appProviderPropsType) => {
  const [appMode, setAppMode] = useState(_appMode);

  const { isDesktopOrLaptop } = useIsDesktopOrLaptop();
  const { isBigScreen } = useIsBigScreen();

  const {
    speakerChatEnabled,
    audienceChatEnabled,
    studioUserId,
    broadcastDestinations,
    metaData,
    defaultActiveInterpretationOutputAgoraChannelId,
    defaultActiveInterpretationInputAgoraChannelId,
    meetingMode,
  } = useAppConfigContext();

  const [activeVideosdkSessionId, setActiveVideosdkSessionId] = useState<
    string | null
  >(null);

  const studioUserIdRef = useRef(studioUserId);

  const [topBarHeight, setTopBarHeight] = useState(56);

  const [mainViewSelectedStreams, setMainViewSelectedStreams] =
    useState<mainViewSelectedStreamsType>(new Map());
  const [mainViewLayout, setMainViewLayout] =
    useState<null | mainViewLayoutType>(null);

  const [previousStudioMainViewLayout, setPreviousStudioMainViewLayout] =
    useState<null | mainViewLayoutType>(null);

  const [mainViewGrid, setMainViewGrid] = useState<string[]>([]);

  const [
    mainViewSoloModeSelectedParticipantId,
    setMainViewSoloModeSelectedParticipantId,
  ] = useState<null | string>(null);
  const [inQueueStreams, setInQueueStreams] = useState<inQueueStreamsType>([]);
  const [activeFileShareStreams, setActiveFileShareStreams] =
    useState<activeFileShareStreamsType>([]);
  const [
    activeFileShareStreamAllParticipants,
    setActiveFileShareStreamAllParticipants,
  ] = useState<activeFileShareStreamAllParticipantsType>([]);

  const [modifiedParticipantsNames, setModifiedParticipantsNames] =
    useState<modifiedParticipantsNamesType>([]);
  const [modifiedParticipantsAvatars, setModifiedParticipantsAvatars] =
    useState<modifiedParticipantsAvatarsType>([]);
  const [
    activeVideoShareStreamAllParticipants,
    setActiveVideoShareStreamAllParticipants,
  ] = useState<activeVideoShareStreamAllParticipantsType>([]);
  const [
    activeInputFileVideoShareStreamAllParticipants,
    setActiveInputFileVideoShareStreamAllParticipants,
  ] = useState<activeInputFileVideoShareStreamAllParticipantsType>([]);

  const [activeVideoShareStreams, setActiveVideoShareStreams] =
    useState<activeVideoShareStreamsType>([]);
  const [
    activeInputFileVideoShareStreams,
    setActiveInputFileVideoShareStreams,
  ] = useState<activeInputFileVideoShareStreamsType>([]);

  const [selectedWebcamDeviceId, setSelectedWebcamDeviceId] = useState<
    string | null
  >(_selectedWebcamDeviceId);
  const [selectedMicDeviceId, setSelectedMicDeviceId] = useState<string | null>(
    _selectedMicDeviceId
  );
  const [selectedSpeakerDeviceId, setSelectedSpeakerDeviceId] = useState<
    string | null
  >(_selectedSpeakerDeviceId);

  const [mirrorLocalWebcam, setMirrorLocalWebcam] =
    useState<boolean>(_mirrorLocalWebcam);

  const [activeSpeakerParticipantId, setActiveSpeakerParticipantId] = useState<
    null | string
  >(null);

  const [sidePanelMode, setSidePanelMode] = useState<null | sidePanelModeType>(
    isDesktopOrLaptop || isBigScreen
      ? appMode === appModes.HOST
        ? sidePanelModes.BRAND
        : appMode === appModes.SPEAKER
        ? sidePanelModes.QNA
        : null
      : null
  );

  const [sidePanelAppMode, setSidePanelAppMode] =
    useState<null | sidePanelAppModeType>(null);
  const [sidePanelBannersMode, setSidePanelBannersMode] =
    useState<null | sidePanelBannersModeType>(null);
  const [sidePanelPrivateChatMode, setSidePanelPrivateChatMode] = useState<
    null | string
  >(null);
  const [bannerFolders, setBannerFolders] = useState<bannerFoldersType>([]);
  const [banners, setBanners] = useState<bannersType>([]);

  const [activeLowerThirtdBannerId, setActiveLowerThirtdBannerId] = useState<
    null | string
  >(null);
  const [activeTickerBannerId, setActiveTickerBannerId] = useState<
    null | string
  >(null);
  const [sidePanelActiveBanerId, setSidePanelActiveBanerId] = useState<
    null | string
  >(null);
  const [sidePanelActivePollId, setSidePanelActivePollId] = useState<
    null | string
  >(null);
  const [sidePanelActiveWordcloudId, setSidePanelActiveWordcloudId] = useState<
    null | string
  >(null);

  const [
    selectedLiveStreamDestinationsIds,
    setSelectedLiveStreamDestinationsIds,
  ] = useState<number[]>(broadcastDestinations || []);
  const [
    erroredLiveStreamDestinationsIds,
    setErroredLiveStreamDestinationsIds,
  ] = useState<erroredLiveStreamDestinationsIdsType>([]);

  const foldersBanners: foldersBannersType = useMemo(
    () =>
      bannerFolders.map(({ id, name }) => ({
        id,
        name,
        banners: banners.filter(({ folderId }) => id == folderId),
      })),
    [bannerFolders, banners]
  );

  const [brandColor, setBrandColor] = useState<{ hexColorCode: string }>({
    hexColorCode: "#FF2445",
  });

  const [showDisplayName, setShowDisplayName] = useState<boolean>(true);

  const [showParticipantHeadline, setShowParticipantHeadline] =
    useState<boolean>(false);

  const [participantHeadlines, setParticipantHeadlines] =
    useState<participantHeadlinesType>({});

  const [muteGuestsWhenVideoPlays, setMuteGuestsWhenVideoPlays] =
    useState<boolean>(true);

  const [muteGuestsWhenAudioPlays, setMuteGuestsWhenAudioPlays] =
    useState<boolean>(false);

  const [brandLogo, setBrandLogo] = useState<{
    id: null | string;
    remoteUrl: null | string;
  }>({
    remoteUrl: null,
    id: null,
  });
  const [mainViewOverlay, setMainViewOverlay] =
    useState<mainViewOverlayStateType>({
      type: mainViewOverlayTypes.NO_OVERLAY,
      value: null,
      id: null,
    });

  const [mainViewVideoClip, setMainViewVideoClip] =
    useState<mainViewVideoClipType>({
      id: null,
      loop: false,
      remoteUrl: null,
      volume: 1,
      loopedAt: null,
      initTime: null,
      playedAt: null,
      durationInSeconds: null,
    });

  const [mainViewBackground, setMainViewBackground] =
    useState<mainViewBackgroundStateType>({
      type: mainViewBackgroundTypes.NO_BACKGROUND,
      value: null,
      id: null,
      remoteUrl: null,
    });

  const [backgroundMusic, setBackgroundMusic] = useState<backgroundMusicType>({
    id: null,
    loop: false,
    remoteUrl: null,
    volume: 1,
    loopedAt: null,
    initTime: null,
    playedAt: null,
    durationInSeconds: null,
  });

  const [interactivityMode, setInteractivityMode] =
    useState<interactivityModeType>(
      meetingMode === "meeting"
        ? interactivityModes.CONFERENCE
        : interactivityModes.STUDIO
    );

  const [appAudioMuted, setAppAudioMuted] = useState<boolean>(false);

  const [
    conferenceModeMaxParticipantCount,
    setConferenceModeMaxParticipantCount,
  ] = useState<number>(9);
  const [
    conferenceModeParticipantAutoCountEnabled,
    setConferenceModeParticipantAutoCountEnabled,
  ] = useState<boolean>(true);
  const [
    conferenceAutoMainVideoLayoutEnabled,
    setConferenceAutoMainVideoLayoutEnabled,
  ] = useState<boolean>(false);

  const [requestedEntries, setRequestedEntries] =
    useState<requestedEntriesType>([]);

  const [brandLogos, setBrandLogos] = useState<brandLogosType>({});
  const [sortedBrandLogoIds, setSortedBrandLogoIds] =
    useState<sortedBrandLogoIdsType>({});

  const [overlays, setOverlays] = useState<overlaysType>({});
  const [sortedOverlayIds, setSortedOverlayIds] =
    useState<sortedOverlayIdsType>({});

  const [backgrounds, setBackgrounds] = useState<backgroundsType>({});
  const [sortedBackgroundIds, setSortedBackgroundIds] =
    useState<sortedBackgroundIdsType>({});

  const [videoClips, setVideoClips] = useState<videoClipsType>({});
  const [sortedVideoClipIds, setSortedVideoClipIds] =
    useState<sortedVideoClipIdsType>({});

  const [backgroundMusics, setBackgroundMusics] =
    useState<backgroundMusicsType>({});
  const [sortedBackgroundMusicIds, setSortedBackgroundMusicIds] =
    useState<sortedBackgroundMusicIdsType>({});

  const [broadcastAssets, setBroadcastAssets] = useState<broadcastAssetsType>(
    []
  );

  const { localParticipantsAvatars, localParticipantsVirtualBackgrounds } =
    useMemo(() => {
      const localParticipantsAvatars = [
        defaultAvatarImage,
        ...broadcastAssets
          .filter(
            (asset) =>
              asset.metaData?.type === "avatar-image" &&
              (asset.owners ? asset.owners.includes(studioUserId) : false)
          )
          .map(({ file }) => file),
      ] as string[];

      const localParticipantsVirtualBackgrounds = broadcastAssets
        .filter(
          (asset) =>
            asset.metaData?.type === "virtual-background-image" &&
            (asset.owners ? asset.owners.includes(studioUserId) : false)
        )
        .map(({ file }) => file) as string[];

      return {
        localParticipantsAvatars,
        localParticipantsVirtualBackgrounds,
      };
    }, [broadcastAssets, studioUserId]);

  const [
    participantsAudioVideoCapturerState,
    setParticipantsAudioVideoCapturerState,
  ] = useState<participantsAudioVideoCapturerStateType>({});

  const [fileUploadError, setFileUploadError] = useState<fileUploadErrorType>({
    visible: false,
    errorMessage: "",
  });

  const [
    activeInterpretationOutputAgoraChannelId,
    setActiveInterpretationOutputAgoraChannelId,
  ] = useState<string | null>(defaultActiveInterpretationOutputAgoraChannelId);

  const [
    activeInterpretationInputAgoraChannelId,
    setActiveInterpretationInputAgoraChannelId,
  ] = useState<string | null>(defaultActiveInterpretationInputAgoraChannelId);

  const [
    allowedEntryRequestParticipantIds,
    setAllowedEntryRequestParticipantIds,
  ] = useState<allowedEntryRequestParticipantIdsType>([]);

  const studioParticipantIdLivekitSidMapRef = useRef<Map<string, string>>(
    new Map()
  );

  const {
    interpreterParticipantIds,
    joinedParticipants,
    recorderParticipantIds,
  } = useJoinedParticipantsCalculator({
    signalingClient,
    studioParticipantIdLivekitSidMapRef,
  });

  const { participantIds } = useParticipantIdsAndCount({
    joinedParticipants,
  });
  const ghostParticipantIds = useMemo<string[]>(
    () => [...recorderParticipantIds, ...interpreterParticipantIds],
    [recorderParticipantIds, interpreterParticipantIds]
  );

  const mainViewSoloModeParticipantId: string | null = useMemo(() => {
    if (
      mainViewSoloModeSelectedParticipantId &&
      participantIds.includes(mainViewSoloModeSelectedParticipantId)
    ) {
      return mainViewSoloModeSelectedParticipantId;
    } else {
      return null;
    }
  }, [participantIds, mainViewSoloModeSelectedParticipantId]);

  const {
    selfPdfs,
    allPdfs,
    selfVideos,
    allVideos,
    selfImages,
    allImages,
    selfVirtualBackgrounds,
  }: {
    selfPdfs: assetPdfsType;
    allPdfs: assetPdfsType;
    selfVideos: assetVideosType;
    allVideos: assetVideosType;
    selfImages: assetImagesType;
    allImages: assetImagesType;
    selfVirtualBackgrounds: assetImagesType;
  } = useMemo(() => {
    const studioUserId = studioUserIdRef.current;

    const allPdfs: assetPdfsType = [];
    const allVideos: assetVideosType = [];
    const allImages: assetImagesType = [];

    broadcastAssets.forEach((asset) => {
      if (asset.metaData?.type === "file-share-stream") {
        allPdfs.push({
          id: asset.id,
          name: asset.name,
          owners: asset.owners,
          pdfInfo: getPdfInfoFromImages(asset.fileData),
          remoteUrl: asset.file as string,
        });
      } else if (asset.metaData?.type === "video-share-stream") {
        allVideos.push({
          id: asset.id,
          name: asset.name,
          owners: asset.owners,
          remoteUrl:
            typeof asset.file === "string"
              ? asset.file
              : asset.file["360"] ||
                asset.file["480"] ||
                asset.file["720"] ||
                asset.file["original"],
        });
      } else if (
        asset.metaData?.type === "avatar-image" ||
        asset.metaData?.type === "virtual-background-image"
      ) {
        allImages.push({
          id: asset.id,
          name: asset.name,
          owners: asset.owners,
          remoteUrl: asset.file as string,
          metaData: asset.metaData,
        });
      }
    });

    const selfPdfs = allPdfs.filter(({ owners }) =>
      owners ? owners.includes(studioUserId) : false
    );

    const selfVideos = allVideos.filter(({ owners }) =>
      owners ? owners.includes(studioUserId) : false
    );

    const selfImages = allImages.filter(({ owners }) =>
      owners ? owners.includes(studioUserId) : false
    );

    const allAvatars = allImages.filter(
      ({ metaData }) => metaData?.type === "avatar-image"
    );

    const selfAvatars = allAvatars.filter(({ owners }) =>
      owners ? owners.includes(studioUserId) : false
    );

    const allVirtualBackgrounds = allImages.filter(
      ({ metaData }) => metaData?.type === "virtual-background-image"
    );

    const selfVirtualBackgrounds = allVirtualBackgrounds.filter(({ owners }) =>
      owners ? owners.includes(studioUserId) : false
    );

    return {
      selfPdfs,
      allPdfs,
      selfVideos,
      allVideos,
      selfImages,
      allImages,
      allAvatars,
      selfAvatars,
      allVirtualBackgrounds,
      selfVirtualBackgrounds,
    };
  }, [broadcastAssets]);

  const sortedBrandLogos: brandLogosType = useMemo(() => {
    const obj: brandLogosType = {};

    Object.keys(sortedBrandLogoIds).forEach((_id) => {
      const _sortedBrandLogoIds = sortedBrandLogoIds[_id];
      const _brandLogos = brandLogos[_id];

      obj[_id] = _sortedBrandLogoIds
        .map((id) => _brandLogos.find(({ id: _id }) => id === _id))
        .filter((s) => s) as singleBrandLogosType;
    });

    return obj;
  }, [brandLogos, sortedBrandLogoIds]);

  const sortedOverlays: overlaysType = useMemo(() => {
    const obj: overlaysType = {};

    Object.keys(sortedOverlayIds).forEach((_id) => {
      const _sortedOverlayIds = sortedOverlayIds[_id];
      const _overlays = overlays[_id];

      obj[_id] = _sortedOverlayIds
        .map((id) => _overlays.find(({ id: _id }) => id === _id))
        .filter((s) => s) as singleBrandOverlaysType;
    });

    return obj;
  }, [overlays, sortedOverlayIds]);

  const sortedBackgrounds: backgroundsType = useMemo(() => {
    const obj: backgroundsType = {};

    Object.keys(sortedBackgroundIds).forEach((_id) => {
      const _sortedBackgroundIds = sortedBackgroundIds[_id];
      const _backgrounds = backgrounds[_id];

      obj[_id] = _sortedBackgroundIds
        .map((id) => _backgrounds.find(({ id: _id }) => id === _id))
        .filter((s) => s) as singleBrandBackgroundsType;
    });

    return obj;
  }, [backgrounds, sortedBackgroundIds]);

  const sortedBackgroundMusics: backgroundMusicsType = useMemo(() => {
    const obj: backgroundMusicsType = {};

    Object.keys(sortedBackgroundMusicIds).forEach((_id) => {
      const _sortedBackgroundMusicIds = sortedBackgroundMusicIds[_id];
      const _backgroundMusics = backgroundMusics[_id];

      obj[_id] = _sortedBackgroundMusicIds
        .map((id) => _backgroundMusics.find(({ id: _id }) => id === _id))
        .filter((s) => s) as singleBrandBackgroundMusicsType;
    });

    return obj;
  }, [backgroundMusics, sortedBackgroundMusicIds]);

  const sortedVideoClips: videoClipsType = useMemo(() => {
    const obj: videoClipsType = {};

    Object.keys(sortedVideoClipIds).forEach((_id) => {
      const _sortedVideoClipIds = sortedVideoClipIds[_id];
      const _videoClips = videoClips[_id];

      obj[_id] = _sortedVideoClipIds
        .map((id) => _videoClips.find(({ id: _id }) => id === _id))
        .filter((s) => s) as singleBrandVideoClipsType;
    });

    return obj;
  }, [videoClips, sortedVideoClipIds]);

  //

  const activeLowerThirtdBanner: null | bannerType = useMemo(() => {
    const banner = banners.find(
      ({ id, type, folderId }) =>
        getAppBannerId({ id, type, folderId }) === activeLowerThirtdBannerId
    );
    if (banner) {
      const { id, type, folderId } = banner;

      return { ...banner, appBannerId: getAppBannerId({ id, type, folderId }) };
    } else {
      return null;
    }
  }, [banners, activeLowerThirtdBannerId]);

  const activeTickerBanner: null | bannerType = useMemo(() => {
    const banner = banners.find(
      ({ id, type, folderId }) =>
        getAppBannerId({ id, type, folderId }) === activeTickerBannerId
    );
    if (banner) {
      const { id, type, folderId } = banner;

      return { ...banner, appBannerId: getAppBannerId({ id, type, folderId }) };
    } else {
      return null;
    }
  }, [banners, activeTickerBannerId]);

  const [newPdfsState, setNewPdfsState] = useState<newPdfsStateType>([]);
  const [newVideoStreamsState, setNewVideoStreamsState] =
    useState<newVideoStreamsStateType>([]);
  const [newBackgroundsState, setNewBackgroundsState] =
    useState<newBackgroundsStateType>([]);
  const [newOverlaysState, setNewOverlaysState] =
    useState<newOverlaysStateType>([]);
  const [newLogosState, setNewLogosState] = useState<newLogosStateType>([]);
  const [newBackgroundMusicsState, setNewBackgroundMusicsState] =
    useState<newBackgroundMusicsStateType>([]);
  const [newMainViewVideoClipsState, setNewMainViewVideoClipsState] =
    useState<newMainViewVideoClipsStateType>([]);

  const [allVideoShareStreamStates, setAllVideoShareStreamStates] =
    useState<allVideoShareStreamStatesType>([]);

  const [
    allInputFileVideoShareStreamStates,
    setAllInputFileVideoShareStreamStates,
  ] = useState<allInputFileVideoShareStreamStatesType>([]);

  // brands
  const [brands, setBrands] = useState<brandsType>([]);
  const [activeBrandId, setActiveBrandId] = useState<string | null>();

  const [raiseHandParticipantIds, setRaiseHandParticipantIds] = useState<
    string[]
  >([]);

  const virtualBackgrounds: virtualBackgroundsType = useMemo(() => {
    const defaultAvatarImageUrls = [
      "https://app.momentostream.com/backgrounds/green-slate.jpg",
      "https://app.momentostream.com/backgrounds/White_Background_(To_id_screen_dust_during_cleanup).jpeg",
      "https://app.momentostream.com/backgrounds/abstract-luxury-gradient-blue-background-smooth-dark-blue-with-black-vignette-studio-banner.jpg",
      "https://cdn.videosdk.live/virtual-background/hill.jpeg",
      "https://cdn.videosdk.live/virtual-background/cloud.jpeg",
      "https://cdn.videosdk.live/virtual-background/wall-with-pot.jpeg",
      "https://cdn.videosdk.live/virtual-background/window-conference.jpeg",
    ];

    return [
      { id: "none", type: "none", Icon: MdBlock, title: "None" },
      { id: `${1}`, type: "blur", Icon: MdBlurOn, title: "Blur" },
      ...defaultAvatarImageUrls.map((url, i) => ({
        id: `${i + 2}`,
        type: "image",
        url,
      })),
      ...selfVirtualBackgrounds.map(({ id, remoteUrl }) => ({
        id,
        type: "image",
        url: remoteUrl,
      })),
    ];
  }, [selfVirtualBackgrounds]);

  const [activeVirtualBackgroundId, setActiveVirtualBackgroundId] = useState<
    string | null
  >(null);

  const [sortedActiveParticipants, setSortedActiveParticipants] =
    useState<sortedActiveParticipantsType>([]);

  const [conferenceOldDataFetched, setConferenceOldDataFetched] =
    useState<boolean>(false);

  const inQueueItemContainerWidth = 180;
  const lateralSidebarWidth = 80;
  const sidePanelWidth = 360;
  const chatEnabled =
    (appMode === appModes.HOST || appMode === appModes.SPEAKER) &&
    (speakerChatEnabled || audienceChatEnabled);

  const [filteredInQueueStreams, setFilteredInQueueStreams] = useState<
    string[]
  >([]);
  const [
    filteredInQueueStreamsSearchQuery,
    setFilteredInQueueStreamsSearchQuery,
  ] = useState<string>("");
  const [raiseHandleSeachEnabled, setRaiseHandleSeachEnabled] =
    useState<boolean>(false);

  const [nonHostCanSeeParticipantsCount, setNonHostCanSeeParticipantsCount] =
    useState<boolean>(false);
  const [raiseHandEnabled, setRaiseHandEnabled] = useState<boolean>(true);

  const [waitingRoomEnabled, setWaitingRoomEnabled] = useState<boolean>(
    !!metaData?.waitingRoomEnabled
  );

  const [showAudioAvatars, setShowAudioAvatars] = useState<boolean>(true);

  const [polls, setPolls] = useState<pollsType>([]);
  const [wordclouds, setWordclouds] = useState<wordcloudsType>([]);

  const [unseenActivatedApps, setUnseenActivatedApps] =
    useState<unseenActivatedAppsType>([]);

  const [isQnAEnabled, setIsQnAEnabled] = useState<boolean>(true);
  const [qnaMessages, setQnaMessages] = useState<qnaMessagesType>([]);
  const [isQnaMessagesLoadingMore, setIsQnaMessagesLoadingMore] =
    useState(false);
  const [qnaMessagesHasMore, setQnaMessagesHasMore] = useState<boolean>(true);
  const [qnaLastSeenMessage, setQnaLastSeenMessage] =
    useState<qnaLastSeenMessageType>({
      id: null,
      index: null,
    });

  const [highlightedChat, setHighlightedChat] = useState<highlightedChatType>({
    enabled: false,
  });

  //

  const [privateChatGroups, setPrivateChatGroups] =
    useState<privateChatGroupsType>([]);
  const [privateChatGroupsMessages, setPrivateChatGroupsMessages] =
    useState<privateChatGroupsMessagesType>(new Map());

  const participatedPrivateChatGroups: privateChatGroupsType = useMemo(
    () =>
      appMode === appModes.HOST || appMode === appModes.VIEWER
        ? privateChatGroups
        : privateChatGroups.filter(({ participantIds }) =>
            participantIds.includes(studioUserId)
          ),
    [privateChatGroups, appMode, studioUserId]
  );

  const [visibledPrivateGroupMessages, setVisibledPrivateGroupMessages] =
    useState<Map<string, string[]>>(new Map());

  const [
    livekitLocalParticipantTrackPublications,
    setLivekitLocalParticipantTrackPublications,
  ] = useState<LivekitLocalTrackPublication[]>([]);

  const [
    livekitRemoteParticipantsTrackPublications,
    setLivekitRemoteParticipantsTrackPublications,
  ] = useState<Map<string, LivekitRemoteTrackPublication[]>>(new Map());

  const {
    participatedPrivateChatGroupsUnreadedChats,
    isAnyGroupsChatUnreaded,
  }: {
    participatedPrivateChatGroupsUnreadedChats: {
      id: string;
      isAnyChatUnreaded: boolean;
    }[];
    isAnyGroupsChatUnreaded: boolean;
  } = useMemo(() => {
    const participatedPrivateChatGroupsUnreadedChats =
      participatedPrivateChatGroups.map(({ id }) => {
        const _privateChatGroupsMessages =
          privateChatGroupsMessages.get(id) || [];

        const _visibledPrivateGroupMessages =
          visibledPrivateGroupMessages.get(id) || [];

        const unreadedChats = _privateChatGroupsMessages.filter(
          ({ id }) => !_visibledPrivateGroupMessages.includes(id)
        );

        return { id, isAnyChatUnreaded: unreadedChats.length > 0 };
      });

    return {
      participatedPrivateChatGroupsUnreadedChats,
      isAnyGroupsChatUnreaded:
        participatedPrivateChatGroupsUnreadedChats.filter(
          ({ isAnyChatUnreaded }) => isAnyChatUnreaded
        ).length > 0,
    };
  }, [
    participatedPrivateChatGroups,
    visibledPrivateGroupMessages,
    privateChatGroupsMessages,
  ]);

  const participatedPrivateChatGroupsRef = useRef(
    participatedPrivateChatGroups
  );
  const sidePanelPrivateChatModeRef = useRef(sidePanelPrivateChatMode);
  const sidePanelModeRef = useRef(sidePanelMode);

  useEffect(() => {
    participatedPrivateChatGroupsRef.current = participatedPrivateChatGroups;
  }, [participatedPrivateChatGroups]);
  useEffect(() => {
    sidePanelPrivateChatModeRef.current = sidePanelPrivateChatMode;
  }, [sidePanelPrivateChatMode]);
  useEffect(() => {
    sidePanelModeRef.current = sidePanelMode;
  }, [sidePanelMode]);

  useEffect(() => {
    setTimeout(() => {
      const participatedPrivateChatGroups =
        participatedPrivateChatGroupsRef.current;
      const sidePanelPrivateChatMode = sidePanelPrivateChatModeRef.current;
      const sidePanelMode = sidePanelModeRef.current;

      if (sidePanelMode === sidePanelModes.PRIVATE_CHAT) {
        const id = getSidePanelPrivateChatMessageListIdFromMode(
          sidePanelPrivateChatMode
        );

        if (
          !participatedPrivateChatGroups.find(
            ({ id: _id }) => `${_id}` === `${id}`
          )
        ) {
          if (participatedPrivateChatGroups.length) {
            setSidePanelPrivateChatMode(null);
          } else {
            setSidePanelPrivateChatMode(null);
            setSidePanelMode(null);
          }
        }
      } else {
        if (sidePanelPrivateChatMode) {
          setSidePanelPrivateChatMode(null);
        }
      }
    }, 5000);
  }, []);

  const { allParticipantsExtraWebcamStreams } =
    useAppRtcAllParticipantsExtraWebcamStreams({
      joinedParticipants,
      livekitLocalParticipantTrackPublications,
      livekitRemoteParticipantsTrackPublications,
      localParticipantId,
    });

  const {
    allParticipantsInQueueStreams,
  }: { allParticipantsInQueueStreams: allParticipantsInQueueStreamsType } =
    useAllParticipantsInQueueStreams({
      inQueueStreams,
      activeFileShareStreamAllParticipants,
      activeVideoShareStreamAllParticipants,
      activeInputFileVideoShareStreamAllParticipants,
      allParticipantsExtraWebcamStreams,
    });

  const {
    conferenceMainViewVisibleWebcamStreams,
  }: {
    conferenceMainViewVisibleWebcamStreams: conferenceMainViewVisibleWebcamStreamsType;
  } = useCalculateMainViewConferenceVisibleWebcamStreams({
    inQueueStreams,
    appMode,
    joinedParticipants,
    conferenceModeMaxParticipantCount,
    conferenceModeParticipantAutoCountEnabled,
    localParticipantId,
    allowedEntryRequestParticipantIds,
    signalingClient,
    studioParticipantIdLivekitSidMapRef,
    //
    //
    setActiveSpeakerParticipantId,
    setConferenceModeMaxParticipantCount,
    setConferenceModeParticipantAutoCountEnabled,
  });

  //
  // main view layout calculation
  //
  const {
    gridWithStreamsPosition,
    isStreamsGrid,
    mainViewLayoutActive,
    mainViewLayoutSelected,
    sideStackWidthPercentage,
    audioOnlyWebcamStreamIds,
  }: {
    gridWithStreamsPosition: gridWithStreamsPositionType;
    isStreamsGrid: boolean;
    mainViewLayoutActive: mainViewLayoutType;
    mainViewLayoutSelected: mainViewLayoutType;
    sideStackWidthPercentage: number;
    audioOnlyWebcamStreamIds: string[];
  } = useCalculateMainViewLayoutStates({
    mainViewGrid,
    mainViewLayout,
    mainViewSelectedStreams,
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
    mainViewSoloModeParticipantId,
    inQueueStreams,
    showAudioAvatars,
    activeSpeakerParticipantId,
    conferenceModeParticipantAutoCountEnabled,
  });

  //
  //

  const [signalLevel, setSignalLevel] = useState<string | null>(null);
  const [
    allParticipantsNetworkSignalLevel,
    setAllParticipantsNetworkSignalLevel,
  ] = useState<Map<string, string>>(new Map());

  const [
    waitingForVideosRemoteUrlReadyToBePlayed,
    setWaitingForVideosRemoteUrlReadyToBePlayed,
  ] = useState<{ remoteUrl: string; id: string }[]>([]);

  const [videosRemoteUrlReadyToBePlayed, setVideosRemoteUrlReadyToBePlayed] =
    useState<{ remoteUrl: string; id: string; durationInSec: number }[]>([]);

  const [localMicMuted, setLocalMicMuted] = useState<boolean>(false);
  const [localWebcamMuted, setLocalWebcamMuted] = useState<boolean>(false);

  const [currentTimeThreshold, setCurrentTimeThreshold] = useState<
    null | number
  >(null);

  const [participantsKeyFrames, setParticipantsKeyFrames] = useState<
    Map<string, string>
  >(new Map());

  const [
    allParticipantsChangeWebcamInProgress,
    setAllParticipantsChangeWebcamInProgress,
  ] = useState<allParticipantsChangeWebcamInProgressType>([]);
  const [
    allParticipantsChangeMicInProgress,
    setAllParticipantsChangeMicInProgress,
  ] = useState<allParticipantsChangeMicInProgressType>([]);

  const [allParticipantsMediaStats, setAllParticipantsMediaStats] = useState<
    Map<string, { [t: string]: boolean }>
  >(new Map());

  const [egresses, setEgresses] = useState<egressesType>([]);

  const [rtmpDestinationIdAndUrls, setRtmpDestinationIdAndUrls] =
    useState<rtmpDestinationIdAndUrlsType>([]);

  const [joyrideProps, setJoyrideProps] = useState<joyridePropsType>({
    enabled: false,
    props: null,
  });

  const [startingEgresses, setStartingEgresses] = useState<boolean>(false);
  const [stoppingEgresses, setStoppingEgresses] = useState<boolean>(false);
  const [editingEgresses, setEditingEgresses] = useState<boolean>(false);

  const [egressesStartRetryingCountdown, setEgressesStartRetryingCountdown] =
    useState<{ active: boolean; countdown: number }>({
      active: false,
      countdown: 0,
    });

  const [egressesProgressState, setEgressesProgressState] =
    useState<egressesProgressStateType>({
      starting: false,
      started: false,
      startingWithOnlyRecording: false,
      startedWithOnlyRecording: false,
    });

  const [isAnyAgoraActiveSpeaker, setIsAnyAgoraActiveSpeaker] =
    useState<boolean>(false);
  const [
    muteOriginalAudioWhenInterpretationActive,
    setMuteOriginalAudioWhenInterpretationActive,
  ] = useState<boolean>(false);

  const [joiningAgoraChannel, setJoiningAgoraChannel] =
    useState<boolean>(false);
  const [leavingAgoraChannel, setLeavingAgoraChannel] =
    useState<boolean>(false);

  const [failedRtmpDestinationUrls, setFailedRtmpDestinationUrls] =
    useState<failedRtmpDestinationUrlsType>([]);

  const [fileShareStreamsCurrentPage, setFileShareStreamsCurrentPage] =
    useState<fileShareStreamsCurrentPageType>({});

  const [localMicOn, setLocalMicOn] = useState(false);
  const [localWebcamOn, setLocalWebcamOn] = useState(false);

  const [turnOnScreenShareInProgress, setTurnOnScreenShareInProgress] =
    useState(false);
  const [
    turnOnInputFileVideoShareInProgress,
    setTurnOnInputFileVideoShareInProgress,
  ] = useState(false);

  const [connectionState, setConnectionState] = useState("");

  const [settingBrandsAndAssets, setSettingBrandsAndAssets] = useState(false);

  const [inQueueStreamsContainerHidden, setInQueueStreamsContainerHidden] =
    useState(false);

  const voluntarilyLeftSession = useRef(false);

  const [agoraConnectedChannelClients, setAgoraConnectedChannelClients] =
    useState<agoraConnectedChannelClientsType>([]);

  const [
    interpretationInputAgoraMicStats,
    setInterpretationInputAgoraMicStats,
  ] = useState({ micOn: false, audioTrack: undefined });

  const [
    allParticipantsInterpreterAgoraStats,
    setAllParticipantsInterpreterAgoraStats,
  ] = useState<
    {
      participantId: string;
      activeInterpretationInputAgoraChannelId?: string | undefined;
      micOn: boolean;
      agoraUId: number;
    }[]
  >([]);

  const { activeWebcamDeviceIds } = useMemo(() => {
    const activeWebcamDeviceIds = livekitLocalParticipantTrackPublications
      .filter(
        (publication) =>
          publication?.source === "camera" &&
          publication?.kind === "video" &&
          publication?.track?.constraints?.deviceId
      )
      .map(
        (publication) => publication?.track?.constraints?.deviceId as string
      );

    return { activeWebcamDeviceIds };
  }, [livekitLocalParticipantTrackPublications]);

  const [turnOnExtraCameraInProgress, setTurnOnExtraCameraInProgress] =
    useState(false);

  const [editExtraCameraInProgress, setEditExtraCameraInProgress] =
    useState(false);

  const [
    localParticipantAITranslateConnectedAgoraChannelIds,
    setLocalParticipantAITranslateConnectedAgoraChannelIds,
  ] = useState<{ agoraChannelId: string; uid: string }[]>([]);

  const [rtmpSourceModalOpen, setRtmpSourceModalOpen] = useState(false);

  const [selectedAudioLanguage, setSelectedAudioLanguage] = useState<
    null | string
  >(CachedUserData.getItem()?.selectedAudioLanguage as string | null);

  const appContextValue: appContextType = {
    setSelectedAudioLanguage,
    selectedAudioLanguage,
    rtmpSourceModalOpen,
    setRtmpSourceModalOpen,
    allParticipantsInterpreterAgoraStats,
    setAllParticipantsInterpreterAgoraStats,
    interpretationInputAgoraMicStats,
    setInterpretationInputAgoraMicStats,
    agoraConnectedChannelClients,
    setAgoraConnectedChannelClients,
    inQueueStreamsContainerHidden,
    setInQueueStreamsContainerHidden,
    voluntarilyLeftSession,
    settingBrandsAndAssets,
    setSettingBrandsAndAssets,
    connectionState,
    setConnectionState,
    localMicOn,
    setLocalMicOn,
    localWebcamOn,
    setLocalWebcamOn,
    turnOnScreenShareInProgress,
    setTurnOnScreenShareInProgress,
    turnOnInputFileVideoShareInProgress,
    setTurnOnInputFileVideoShareInProgress,
    turnOnExtraCameraInProgress,
    setTurnOnExtraCameraInProgress,
    editExtraCameraInProgress,
    setEditExtraCameraInProgress,
    isJoined,
    signalingRoomInfo,
    activeInputFileVideoShareStreamAllParticipants,
    setActiveInputFileVideoShareStreamAllParticipants,
    allInputFileVideoShareStreamStates,
    setAllInputFileVideoShareStreamStates,
    activeInputFileVideoShareStreams,
    setActiveInputFileVideoShareStreams,
    localParticipantId,
    livekitLocalParticipantTrackPublications,
    setLivekitLocalParticipantTrackPublications,
    activeWebcamDeviceIds,
    livekitRemoteParticipantsTrackPublications,
    setLivekitRemoteParticipantsTrackPublications,
    studioParticipantIdLivekitSidMapRef,
    signalingClient,
    rtcClient,
    activeVideosdkSessionId,
    setActiveVideosdkSessionId,
    appMode,
    setAppMode,
    inQueueItemContainerWidth,
    chatEnabled,
    //
    topBarHeight,
    setTopBarHeight,
    mainViewSelectedStreams,
    setMainViewSelectedStreams,
    mainViewLayout,
    setMainViewLayout,
    mainViewGrid,
    setMainViewGrid,
    inQueueStreams,
    setInQueueStreams,
    activeFileShareStreams,
    setActiveFileShareStreams,
    activeFileShareStreamAllParticipants,
    setActiveFileShareStreamAllParticipants,
    allParticipantsInQueueStreams,
    modifiedParticipantsNames,
    setModifiedParticipantsNames,
    modifiedParticipantsAvatars,
    setModifiedParticipantsAvatars,
    activeVideoShareStreamAllParticipants,
    setActiveVideoShareStreamAllParticipants,
    activeVideoShareStreams,
    setActiveVideoShareStreams,
    localParticipantsAvatars,
    localParticipantsVirtualBackgrounds,
    selectedWebcamDeviceId,
    setSelectedWebcamDeviceId,
    mirrorLocalWebcam,
    setMirrorLocalWebcam,
    selectedMicDeviceId,
    setSelectedMicDeviceId,
    selectedSpeakerDeviceId,
    setSelectedSpeakerDeviceId,
    mainViewBackground,
    setMainViewBackground,
    mainViewOverlay,
    setMainViewOverlay,
    lateralSidebarWidth,
    sidePanelWidth,
    sidePanelMode,
    setSidePanelMode,
    mainViewVideoClip,
    setMainViewVideoClip,
    backgroundMusic,
    setBackgroundMusic,
    brandLogo,
    setBrandLogo,
    brandColor,
    setBrandColor,
    _joinScreenMicOn,
    _joinScreenWebcamOn,

    brandLogos,
    setBrandLogos,
    overlays,
    setOverlays,
    videoClips,
    setVideoClips,
    backgrounds,
    setBackgrounds,
    backgroundMusics,
    setBackgroundMusics,

    allVideoShareStreamStates,
    setAllVideoShareStreamStates,
    sortedVideoClipIds,
    setSortedVideoClipIds,
    sortedBackgroundMusicIds,
    setSortedBackgroundMusicIds,
    sortedBackgroundMusics,
    sortedVideoClips,
    sortedOverlays,
    sortedBackgrounds,
    sortedOverlayIds,
    setSortedOverlayIds,
    sortedBackgroundIds,
    setSortedBackgroundIds,
    sortedBrandLogos,
    sortedBrandLogoIds,
    setSortedBrandLogoIds,
    brands,
    setBrands,
    activeBrandId,
    setActiveBrandId,
    broadcastAssets,
    setBroadcastAssets,
    selfPdfs,
    allPdfs,
    selfVideos,
    allVideos,
    selfImages,
    allImages,
    participantsAudioVideoCapturerState,
    setParticipantsAudioVideoCapturerState,
    fileUploadError,
    setFileUploadError,
    showDisplayName,
    setShowDisplayName,
    showParticipantHeadline,
    setShowParticipantHeadline,
    muteGuestsWhenVideoPlays,
    setMuteGuestsWhenVideoPlays,
    muteGuestsWhenAudioPlays,
    setMuteGuestsWhenAudioPlays,
    activeInterpretationOutputAgoraChannelId,
    setActiveInterpretationOutputAgoraChannelId,
    activeInterpretationInputAgoraChannelId,
    setActiveInterpretationInputAgoraChannelId,
    appAudioMuted,
    setAppAudioMuted,
    interpreterParticipantIds,
    recorderParticipantIds,
    ghostParticipantIds,
    raiseHandParticipantIds,
    setRaiseHandParticipantIds,
    virtualBackgrounds,
    activeVirtualBackgroundId,
    setActiveVirtualBackgroundId,
    interactivityMode,
    setInteractivityMode,
    conferenceModeMaxParticipantCount,
    setConferenceModeMaxParticipantCount,
    sortedActiveParticipants,
    setSortedActiveParticipants,
    conferenceMainViewVisibleWebcamStreams,
    joinedParticipants,
    conferenceOldDataFetched,
    setConferenceOldDataFetched,
    filteredInQueueStreams,
    setFilteredInQueueStreams,
    filteredInQueueStreamsSearchQuery,
    setFilteredInQueueStreamsSearchQuery,
    raiseHandleSeachEnabled,
    setRaiseHandleSeachEnabled,
    nonHostCanSeeParticipantsCount,
    setNonHostCanSeeParticipantsCount,
    waitingRoomEnabled,
    setWaitingRoomEnabled,
    sidePanelAppMode,
    setSidePanelAppMode,
    sidePanelBannersMode,
    setSidePanelBannersMode,
    bannerFolders,
    setBannerFolders,
    banners,
    setBanners,
    foldersBanners,
    activeLowerThirtdBanner,
    setActiveLowerThirtdBannerId,
    activeTickerBanner,
    setActiveTickerBannerId,
    sidePanelActiveBanerId,
    setSidePanelActiveBanerId,
    polls,
    setPolls,
    wordclouds,
    setWordclouds,
    sidePanelActivePollId,
    setSidePanelActivePollId,
    sidePanelActiveWordcloudId,
    setSidePanelActiveWordcloudId,
    isQnAEnabled,
    setIsQnAEnabled,
    qnaMessages,
    setQnaMessages,
    highlightedChat,
    setHighlightedChat,
    raiseHandEnabled,
    setRaiseHandEnabled,
    selectedLiveStreamDestinationsIds,
    setSelectedLiveStreamDestinationsIds,
    erroredLiveStreamDestinationsIds,
    setErroredLiveStreamDestinationsIds,
    participantHeadline,
    participantHeadlines,
    setParticipantHeadlines,
    requestedEntries,
    setRequestedEntries,
    privateChatGroups,
    setPrivateChatGroups,
    privateChatGroupsMessages,
    setPrivateChatGroupsMessages,
    participatedPrivateChatGroups,
    sidePanelPrivateChatMode,
    setSidePanelPrivateChatMode,
    visibledPrivateGroupMessages,
    setVisibledPrivateGroupMessages,
    participatedPrivateChatGroupsUnreadedChats,
    isAnyGroupsChatUnreaded,
    newPdfsState,
    setNewPdfsState,
    newVideoStreamsState,
    setNewVideoStreamsState,
    newBackgroundsState,
    setNewBackgroundsState,
    newOverlaysState,
    setNewOverlaysState,
    newLogosState,
    setNewLogosState,
    newBackgroundMusicsState,
    setNewBackgroundMusicsState,
    newMainViewVideoClipsState,
    setNewMainViewVideoClipsState,
    mainViewSoloModeSelectedParticipantId,
    setMainViewSoloModeSelectedParticipantId,
    mainViewSoloModeParticipantId,
    //
    //
    gridWithStreamsPosition,
    isStreamsGrid,
    mainViewLayoutActive,
    mainViewLayoutSelected,
    sideStackWidthPercentage,
    showAudioAvatars,
    setShowAudioAvatars,
    unseenActivatedApps,
    setUnseenActivatedApps,
    audioOnlyWebcamStreamIds,
    isQnaMessagesLoadingMore,
    setIsQnaMessagesLoadingMore,
    qnaMessagesHasMore,
    setQnaMessagesHasMore,
    qnaLastSeenMessage,
    setQnaLastSeenMessage,
    signalLevel,
    setSignalLevel,
    allParticipantsNetworkSignalLevel,
    setAllParticipantsNetworkSignalLevel,
    activeSpeakerParticipantId,
    setActiveSpeakerParticipantId,
    waitingForVideosRemoteUrlReadyToBePlayed,
    setWaitingForVideosRemoteUrlReadyToBePlayed,
    videosRemoteUrlReadyToBePlayed,
    setVideosRemoteUrlReadyToBePlayed,
    localMicMuted,
    setLocalMicMuted,
    localWebcamMuted,
    setLocalWebcamMuted,
    conferenceModeParticipantAutoCountEnabled,
    setConferenceModeParticipantAutoCountEnabled,
    conferenceAutoMainVideoLayoutEnabled,
    setConferenceAutoMainVideoLayoutEnabled,
    currentTimeThreshold,
    setCurrentTimeThreshold,
    previousStudioMainViewLayout,
    setPreviousStudioMainViewLayout,

    participantsKeyFrames,
    setParticipantsKeyFrames,
    allParticipantsChangeWebcamInProgress,
    setAllParticipantsChangeWebcamInProgress,
    allParticipantsChangeMicInProgress,
    setAllParticipantsChangeMicInProgress,
    allParticipantsMediaStats,
    setAllParticipantsMediaStats,

    //

    egresses,
    setEgresses,

    rtmpDestinationIdAndUrls,
    setRtmpDestinationIdAndUrls,
    joyrideProps,
    setJoyrideProps,
    startingEgresses,
    setStartingEgresses,
    stoppingEgresses,
    setStoppingEgresses,
    editingEgresses,
    setEditingEgresses,
    egressesStartRetryingCountdown,
    setEgressesStartRetryingCountdown,
    egressesProgressState,
    setEgressesProgressState,
    isAnyAgoraActiveSpeaker,
    setIsAnyAgoraActiveSpeaker,
    muteOriginalAudioWhenInterpretationActive,
    setMuteOriginalAudioWhenInterpretationActive,
    joiningAgoraChannel,
    setJoiningAgoraChannel,
    leavingAgoraChannel,
    setLeavingAgoraChannel,
    failedRtmpDestinationUrls,
    setFailedRtmpDestinationUrls,

    allowedEntryRequestParticipantIds,
    setAllowedEntryRequestParticipantIds,

    fileShareStreamsCurrentPage,
    setFileShareStreamsCurrentPage,

    localParticipantAITranslateConnectedAgoraChannelIds,
    setLocalParticipantAITranslateConnectedAgoraChannelIds,

    //
    //
  };

  return (
    <AppContext.Provider value={appContextValue}>
      {children}
    </AppContext.Provider>
  );
};
