// import { usePubSub } from "@videosdk.live/react-sdk";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { privateChatGroupActions } from "../../listners/privateChat/PrivateChatGroupListner";
import { createUID } from "../../utils/createUID";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const usePrivateChatGroup = () => {
  // const { publish } = usePubSub(appPubSubTopics.PRIVATE_CHAT_GROUP);

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.PRIVATE_CHAT_GROUP
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const createGroup = async ({
    name,
    participantIds,
  }: {
    name: string;
    participantIds: string[];
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const id = createUID();

    publish(
      JSON.stringify({
        action: privateChatGroupActions.CREATE,
        payload: { id, participantIds, name },
      })
      // { persist: true }
    );
  };

  const deleteGroup = async ({ id }: { id: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: privateChatGroupActions.DELETE,
        payload: { id },
      })
      // { persist: true }
    );
  };

  const renameGroup = async ({ id, name }: { id: string; name: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: privateChatGroupActions.RENAME,
        payload: { id, name },
      })
      // { persist: true }
    );
  };

  const addParticipantsFromGroup = async ({
    id,
    participantIds,
  }: {
    id: string;
    participantIds: string[];
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: privateChatGroupActions.ADD_PARTICIPANTS,
        payload: { id, participantIds },
      })
      // { persist: true }
    );
  };

  const removeParticipantsFromGroup = async ({
    id,
    participantIds,
  }: {
    id: string;
    participantIds: string[];
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: privateChatGroupActions.REMOVE_PARTICIPANTS,
        payload: { id, participantIds },
      })
      // { persist: true }
    );
  };

  const changeVisibilityStateOfGroup = async ({
    id,
    isVisible,
  }: {
    id: string;
    isVisible: boolean;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: privateChatGroupActions.CHANGE_VISIBILITY_STATE,
        payload: { id, isVisible },
      })
      // { persist: true }
    );
  };

  return {
    createGroup,
    deleteGroup,
    renameGroup,
    addParticipantsFromGroup,
    removeParticipantsFromGroup,
    changeVisibilityStateOfGroup,
  };
};

export default usePrivateChatGroup;
