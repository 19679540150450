import useIsMobileLandscape from "./useIsMobileLandscape";
import useIsMobilePortrait from "./useIsMobilePortrait";

const useIsMobilePortraitOrLandscape = () => {
  const { isMobilePortrait } = useIsMobilePortrait();
  const { isMobileLandscape } = useIsMobileLandscape();

  return { isMobilePortraitOrLandscape: isMobilePortrait || isMobileLandscape };
};

export default useIsMobilePortraitOrLandscape;
