// @ts-nocheck

import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  MdAudioFile,
  MdClose,
  MdUploadFile,
  MdVideoFile,
} from "react-icons/md";
import Spinner from "./Spinner";
import { useAppContext } from "../contexts/appContextDef";
import validateSpeakerDeviceId from "../utils/validateSpeakerDeviceId";

const AddNewOrSelectDefaultAssetsDefaultItem = ({
  setSelectedIndex,
  type,
  selectedIndex,
  item,
  i,
}: {
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
  type?: string;
  selectedIndex: number | null;
  item: {
    url: string;
    useRemoteUrl: boolean;
    name?: string;
    video_480?: string;
    video_720?: string;
    original?: string;
    thumbnailUrl?: string;
    assetName?: string;
  };
  i: number;
}) => {
  const { selectedSpeakerDeviceId } = useAppContext();

  const audioPlayer = useRef<StudioHTMLAudioElement>();
  const videoPlayer = useRef<StudioHTMLVideoElement>();

  const { url, name: _name, thumbnailUrl } = item;

  const splitted = url.split("/");

  const name = _name || splitted[splitted.length - 1];

  const isSelected = selectedIndex === i;

  const setSinkIdForAudio = async ({ deviceId }: { deviceId: string }) => {
    const { isValid } = await validateSpeakerDeviceId({ deviceId });

    if (isValid) {
      if (typeof audioPlayer?.current?.setSinkId === "function") {
        audioPlayer.current.setSinkId(deviceId);
      }
    }
  };

  const setSinkIdForVideo = async ({ deviceId }: { deviceId: string }) => {
    const { isValid } = await validateSpeakerDeviceId({ deviceId });

    if (isValid) {
      if (typeof videoPlayer?.current?.setSinkId === "function") {
        videoPlayer.current.setSinkId(deviceId);
      }
    }
  };

  useEffect(() => {
    if (type === "audio") {
      setSinkIdForAudio({ deviceId: selectedSpeakerDeviceId || "" });
    } else if (type === "video") {
      setSinkIdForVideo({ deviceId: selectedSpeakerDeviceId || "" });
    }
  }, [type, selectedSpeakerDeviceId, isSelected]);

  return (
    <div
      onClick={() => {
        setSelectedIndex(() => (isSelected ? null : i));
      }}
      className={`rounded-md p-0.5 border-4 cursor-pointer ${
        isSelected ? "border-primary" : "border-custom-blue-50"
      }`}
    >
      {type === "image" ? (
        <React.Fragment>
          <img
            alt=""
            className={`rounded-md object-cover aspect-video bg-gray-700 h-full w-full`}
            src={url}
          />
        </React.Fragment>
      ) : type === "audio" ? (
        <React.Fragment>
          <div className="aspect-video flex flex-col items-center justify-center p-2">
            <MdAudioFile className="fill-primary" size={32} />
            <p className="mt-1 text-sm text-primary text-center">{name}</p>
          </div>

          {isSelected ? (
            <audio
              ref={audioPlayer as React.LegacyRef<StudioHTMLAudioElement>}
              hidden
              controls={false}
              autoPlay
              src={url}
            />
          ) : (
            <React.Fragment />
          )}
        </React.Fragment>
      ) : type === "video" ? (
        <React.Fragment>
          {isSelected ? (
            <video
              ref={videoPlayer}
              controls={false}
              autoPlay
              className="aspect-video bg-gray-700"
              src={url}
              playsInline
            />
          ) : thumbnailUrl ? (
            <React.Fragment>
              <img
                alt=""
                className={`rounded-md object-cover aspect-video bg-gray-700 h-full w-full`}
                src={thumbnailUrl}
              />
            </React.Fragment>
          ) : (
            <div className="aspect-video flex flex-col items-center justify-center p-2">
              <MdVideoFile className="fill-primary" size={32} />
              <p className="mt-1 text-sm text-primary text-center">{name}</p>
            </div>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

const AddNewOrSelectDefaultAssetsModal = ({
  title,
  isOpen,
  onClose,
  onFileEvent,
  acceptFileType,
  inputButtonText,
  defaultItems,
  type,
  shouldSelect16by9Image,
  maxFileSizeInMB,
}: {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onFileEvent: (t: {
    remoteUrl?: string;
    videoThumbnail?: string;
    video_480?: string;
    video_720?: string;
    assetName?: string;
    singleFile?: File;
    fileName?: string;
  }) => void;
  acceptFileType?: string;
  inputButtonText?: string;
  defaultItems: {
    url: string;
    useRemoteUrl: boolean;
    name?: string;
    video_480?: string;
    video_720?: string;
    original?: string;
    thumbnailUrl?: string;
    assetName?: string;
  }[];
  type?: string;
  shouldSelect16by9Image?: boolean;
  maxFileSizeInMB: number;
}) => {
  const imageInputRef = useRef<HTMLInputElement>();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const [downloadingSelectedFile, setDownloadingSelectedFile] = useState(false);

  const downloadingSelectedFileRef = useRef(downloadingSelectedFile);

  useEffect(() => {
    downloadingSelectedFileRef.current = downloadingSelectedFile;
  }, [downloadingSelectedFile]);

  const _handleClose = () => {
    if (!downloadingSelectedFileRef.current) {
      setSelectedIndex(null);
      setDownloadingSelectedFile(false);

      onClose();
    }
  };

  const { setFileUploadError } = useAppContext();

  return (
    <React.Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          style={{ zIndex: 99999 }}
          as="div"
          className="relative"
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all border-2 border-custom-blue-900">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="flex items-center justify-center">
                      <p className="flex flex-1">{title}</p>

                      <button
                        onClick={() => {
                          _handleClose();
                        }}
                        className="btn btn-ghost btn-sm"
                      >
                        <MdClose />
                      </button>
                    </div>
                  </Dialog.Title>

                  <div className={"mt-6"}>
                    {/* body here */}
                    <div
                      style={{ aspectRatio: 11 / 2 }}
                      className="flex items-center justify-center flex-col"
                    >
                      <p>Select file from your computer</p>
                      <button
                        onClick={() => {
                          imageInputRef.current?.click();
                        }}
                        className={`mt-2 gap-2 btn btn-primary btn-outline text-white normal-case`}
                      >
                        <MdUploadFile size={24} />
                        {inputButtonText}
                      </button>
                    </div>

                    {type === "audio" || type === "video" ? (
                      <div className="mt-3 bg-amber-100 border border-yellow-600 rounded p-2">
                        Only upload {type} you have made or you are authorized
                        to use.
                      </div>
                    ) : (
                      <React.Fragment />
                    )}

                    {defaultItems?.length ? (
                      <div className="mt-3">
                        <p className="text-sm">
                          Don't want to add your own? Try one of these!
                        </p>

                        <div
                          className={`grid ${
                            type === "video" ? "grid-cols-3" : "grid-cols-3"
                          } gap-1 overflow-y-scroll max-h-96 mt-2`}
                        >
                          {defaultItems.map((item, i) => {
                            return (
                              <AddNewOrSelectDefaultAssetsDefaultItem
                                key={`add-new-brand-item-${i}`}
                                {...{
                                  i,
                                  item,
                                  selectedIndex,
                                  setSelectedIndex,
                                  type,
                                }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <React.Fragment />
                    )}
                  </div>

                  <div className="mt-6">
                    <button
                      disabled={selectedIndex === null}
                      type="button"
                      className="btn btn-primary text-white normal-case"
                      onClick={async () => {
                        if (typeof selectedIndex !== "number") {
                          return;
                        }

                        const selectedAsset = defaultItems[selectedIndex];

                        const {
                          url,
                          useRemoteUrl,
                          thumbnailUrl,
                          video_480,
                          video_720,
                          assetName,
                          original,
                        } = selectedAsset;

                        if (useRemoteUrl) {
                          onFileEvent({
                            remoteUrl: original || url,
                            videoThumbnail: thumbnailUrl,
                            video_480,
                            video_720,
                            assetName,
                          });

                          onClose();
                        } else {
                          setDownloadingSelectedFile(true);

                          const res = await fetch(url);

                          const blob = (await res.blob()) as unknown as {
                            name: string;
                          };

                          const splitted = url.split("/");

                          blob.name = splitted[splitted.length - 1];

                          const ev = {
                            target: { files: [blob] },
                          };

                          const singleFile = ev?.target?.files
                            ? ev.target.files[0]
                            : undefined;

                          onFileEvent({
                            singleFile: singleFile as File,
                            fileName: singleFile?.name,
                          });

                          setDownloadingSelectedFile(false);

                          onClose();

                          setSelectedIndex(null);
                        }
                      }}
                    >
                      Select
                    </button>

                    <button
                      type="button"
                      className="btn btn-primary text-white normal-case  ml-3"
                      onClick={_handleClose}
                    >
                      Cancel
                    </button>
                  </div>

                  {downloadingSelectedFile ? (
                    <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-20 flex flex-col items-center justify-center">
                      <Spinner h={"h-16"} w={"w-16"} />
                      <p>please wait ...</p>
                    </div>
                  ) : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <input
        ref={imageInputRef as React.LegacyRef<HTMLInputElement>}
        onChange={async (ev) => {
          const singleFile = ev?.target?.files ? ev.target.files[0] : undefined;

          let isValid = true;
          let isError = false;

          let errorObj = { visible: false, errorMessage: "" };

          const fileEventObj = {
            singleFile,
            fileName: singleFile?.name || "",
          };

          if (!singleFile) {
            errorObj = {
              visible: true,
              errorMessage: "Unknown error occured while selecting file!",
            };

            isError = true;
            isValid = false;
          } else {
            const fileSize = singleFile.size / (1024 * 1024);

            if (fileSize > maxFileSizeInMB) {
              errorObj = {
                visible: true,
                errorMessage: `File size exceeds the maximum limit ~ ${maxFileSizeInMB}MB`,
              };

              isError = true;
              isValid = false;
            }

            if (shouldSelect16by9Image) {
              const { error }: { success?: boolean; error?: boolean } =
                await new Promise((resolve) => {
                  const image = new Image();

                  image.onload = function () {
                    const is16by9Image =
                      (9 * (this as unknown as { width: number }).width) /
                        ((this as unknown as { height: number }).height *
                          16) ===
                      1;

                    if (is16by9Image) {
                      resolve({ success: true });
                    } else {
                      resolve({ error: true });
                    }
                  };

                  image.src = URL.createObjectURL(singleFile);
                });

              if (error) {
                errorObj = {
                  visible: true,
                  errorMessage:
                    "Image aspect ratio should be 16:9 (Recommended size 1280 x 720)",
                };

                isError = true;
                isValid = false;
              }
            }
          }

          if (isValid) {
            onFileEvent(fileEventObj);

            _handleClose();
          } else if (isError) {
            setFileUploadError(errorObj);
          }

          if (imageInputRef.current) {
            imageInputRef.current.value = "";
          }
        }}
        accept={acceptFileType}
        type="file"
        className="hidden"
      />
    </React.Fragment>
  );
};

export default AddNewOrSelectDefaultAssetsModal;
