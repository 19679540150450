import React, { useEffect, useMemo, useState } from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { MdOutlineFullscreen } from "react-icons/md";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

const MainViewFullScreenToggleButton = () => {
  const [isMouseOver_mainview, setIsMouseOver_mainview] = useState(false);
  const [isMouseOver_self, setIsMouseOver_self] = useState(false);

  const { isRecorder } = useAppConfigContext();

  const _handleMainViewContainerMouseOver = ({
    isMouseOver,
  }: {
    isMouseOver: boolean;
  }) => {
    setIsMouseOver_mainview(isMouseOver);
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.MAIN_VIDEO_CONTAINER_MOUSE_OVER,
      _handleMainViewContainerMouseOver
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.MAIN_VIDEO_CONTAINER_MOUSE_OVER,
        _handleMainViewContainerMouseOver
      );
    };
  }, []);

  const isVisible = useMemo(
    () => (isMouseOver_mainview || isMouseOver_self) && !isRecorder,
    [isMouseOver_mainview, isMouseOver_self, isRecorder]
  );

  return isVisible ? (
    <div
      onMouseEnter={() => {
        setIsMouseOver_self(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver_self(false);
      }}
      className="absolute bottom-3 right-3"
    >
      <button
        onClick={() => {
          appEventEmitter.emit(appEventEmitterEvents.TOGGLE_FULLSCREEN, {});
        }}
        className="btn btn-primary text-white btn-sm p-1 m-1 aspect-square"
      >
        <MdOutlineFullscreen size={16} />
      </button>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default MainViewFullScreenToggleButton;
