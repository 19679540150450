// import { usePubSub } from "@videosdk.live/react-sdk";
import addBrandAsset from "../../apis/brands/add-brand-asset";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import { videoClipsActions } from "../../listners/brands/BrandVideoClipsListner";
import { getBrandVideoClipsTopicById } from "../../utils/pubSubTopics";
import addBrandAssetByUrl from "../../apis/brands/add-brand-asset-by-url";
import getBrandAssets from "../../apis/brands/get-brand-assets";
import sleep from "../../utils/sleep";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import waitForRemoteUrlAccessible from "../../utils/waitForRemoteUrlAccessible";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { appQueueEvents } from "../../listners/appState/AppQueueListner";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

export const waitForCompressedVideoInfo = async ({
  brandId,
  userId,
  assetId,
}: {
  brandId: string;
  userId: string;
  assetId: string;
}): Promise<{
  success: boolean;
  data?: { video: { id: string; file: { [t: string]: string } }[] };
}> => {
  const { success, data } = (await getBrandAssets({ userId, brandId })) as {
    success: boolean;
    data: { video: { id: string; file: { [t: string]: string } }[] };
  };

  if (success) {
    const videos = data.video;
    const video = videos.find(({ id: _id }) => `${_id}` === `${assetId}`) as {
      id: string;
      file: { [t: string]: string };
    };

    const { file: remoteUrl } = video;

    const videoThumbnail = remoteUrl["thumbnail"];
    const video480 = remoteUrl["480"];
    const video720 = remoteUrl["720"];

    if (videoThumbnail && video480 && video720) {
      const { success: success1 } = await waitForRemoteUrlAccessible({
        remoteUrl: videoThumbnail,
      });
      const { success: success2 } = await waitForRemoteUrlAccessible({
        remoteUrl: video480,
      });
      const { success: success3 } = await waitForRemoteUrlAccessible({
        remoteUrl: video720,
      });

      if (success1 && success2 && success3) {
        return { data, success };
      } else {
        await sleep(10000);

        return waitForCompressedVideoInfo({ brandId, assetId, userId });
      }
    } else {
      await sleep(10000);

      return waitForCompressedVideoInfo({ brandId, assetId, userId });
    }
  } else {
    return { success: false };
  }
};

const useVideoClips = ({ id: brandId }: { id: string }) => {
  // const { publish } = usePubSub(getBrandVideoClipsTopicById(brandId));

  const { publish } = useAppSingalingPublish(
    getBrandVideoClipsTopicById(brandId)
  );

  const { setFileUploadError, setWaitingForVideosRemoteUrlReadyToBePlayed } =
    useAppContext();
  const { userId } = useAppConfigContext();

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const add = async ({
    remoteUrl,
    uploadId,
    videoThumbnail,
    singleFile,
    //
    assetName,
    video_480,
    video_720,
  }: {
    remoteUrl?: string;
    uploadId?: string;
    videoThumbnail?: string;
    singleFile?: File;
    //
    assetName?: string;
    video_480?: string;
    video_720?: string;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (singleFile) {
      const { success, id, name, error } = await addBrandAsset({
        brandId,
        type: "video",
        userId,
        uploadId,
        singleFile,
        setWaitingForVideosRemoteUrlReadyToBePlayed,
      });

      if (success) {
        appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
          event: appQueueEvents.BRAND_VIDEO_CLIP,
          //
          isUploading: false,
          isProcessing: true,
          isCompleted: false,
          //
          brandId,
          uploadId,
        });

        const { success: getAssetSuccess, data: getAssetData } =
          await waitForCompressedVideoInfo({ userId, brandId, assetId: id });

        if (getAssetSuccess && getAssetData) {
          const videos = getAssetData.video;

          if (videos?.length) {
            const video = videos.find(({ id: _id }) => `${_id}` === `${id}`);

            if (video) {
              const { file: remoteUrl } = video;

              const videoOriginal = remoteUrl["original"];

              const video480 = remoteUrl["480"];
              const video720 = remoteUrl["720"];
              const videoThumbnail = remoteUrl["thumbnail"];

              publish(
                JSON.stringify({
                  action: videoClipsActions.ADD,
                  payload: {
                    id,
                    remoteUrl,
                    videoOriginal,
                    video480,
                    video720,
                    videoThumbnail,
                    name,
                  },
                })
                // { persist: true }
              );

              return id;
            } else {
              setFileUploadError({
                visible: true,
                errorMessage: "Unknown error occured!",
              });
            }
          } else {
            setFileUploadError({
              visible: true,
              errorMessage: "Unknown error occured!",
            });
          }
        } else {
          setFileUploadError({
            visible: true,
            errorMessage: "Unknown error occured!",
          });
        }
      } else if (error) {
        setFileUploadError({
          visible: true,
          errorMessage: error,
        });
      }
    } else if (remoteUrl) {
      const { success, id, name } = await addBrandAssetByUrl({
        brandId,
        userId,
        type: "video",
        fileUrl: remoteUrl,
        thumbnail: videoThumbnail,
        assetName,
        video_480,
        video_720,
      });

      if (success) {
        publish(
          JSON.stringify({
            action: videoClipsActions.ADD,
            payload: {
              id,
              remoteUrl,
              videoOriginal: remoteUrl,
              video480: video_480,
              video720: video_720,
              videoThumbnail,
              name,
            },
          })
          // { persist: true }
        );

        return id;
      } else {
        setFileUploadError({
          visible: true,
          errorMessage: "Unknown error occured!",
        });
      }
    }
  };

  const remove = async ({ id }: { id: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ action: videoClipsActions.REMOVE, payload: { id } })
      // { persist: true }
    );
  };

  const sort = async ({ ids }: { ids: string[] }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ action: videoClipsActions.SORT, payload: { ids } })
      // { persist: true }
    );
  };

  const rename = async ({ id, name }: { id: string; name: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: videoClipsActions.RENAME,
        payload: { id, name },
      })
      // { persist: true }
    );
  };

  return { add, sort, remove, rename };
};

export default useVideoClips;
