import React from "react";
import AllVideoShareStreamListner from "./AllVideoShareStreamListner";
import FileShareStreamsListner from "./FileShareStreamsListner";
import QueuedStreamsListnerAllPeers from "./QueuedStreamsListnerAllPeers";
import VideoShareStreamsListner from "./VideoShareStreamsListner";
import InputFileVideoShareStreamsListner from "./InputFileVideoShareStreamsListner";
import AllInputFileVideoShareStreamListner from "./AllInputFileVideoShareStreamListner";

const StreamsListner = () => {
  return (
    <React.Fragment>
      <AllVideoShareStreamListner />
      <AllInputFileVideoShareStreamListner />
      {/*  */}
      <VideoShareStreamsListner />
      <FileShareStreamsListner />
      <InputFileVideoShareStreamsListner />
      {/*  */}
      <QueuedStreamsListnerAllPeers />
    </React.Fragment>
  );
};

export default StreamsListner;
