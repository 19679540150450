import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const getBrandAssets = async ({
  userId,
  brandId,
}: {
  userId: string;
  brandId: string;
}): Promise<{
  success: boolean;
  brandId?: string;
  data?: {
    background: {
      id: string;
      file: string;
      name: string;
      isActive: boolean | number;
    }[];
    logo: {
      id: string;
      file: string;
      name: string;
      isActive: boolean | number;
    }[];
    music: {
      id: string;
      file: string;
      name: string;
      isActive: boolean | number;
    }[];
    overlay: {
      id: string;
      file: string;
      name: string;
      isActive: boolean | number;
    }[];
    video: {
      id: string;
      file: string | { [t: string]: string };
      name: string;
      isActive: boolean | number;
    }[];
  };
}> => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/get-brand-assets/${userId}/${brandId}`;

    const res = await fetch(url);

    const json = await res.json();

    if (json.success) {
      return { success: true, data: json.data, brandId };
    } else {
      return { success: false };
    }
  } catch (err) {
    return { success: false };
  }
};

export default getBrandAssets;
