// import useIsBigScreen from "./useIsBigScreen";
// import useIsDesktopOrLaptop from "./useIsDesktopOrLaptop";
import useIsPortrait from "./useIsPortrait";
import useIsTabletOrMobile from "./useIsTabletOrMobile";

const useIsMobileLandscape = () => {
  const { isPortrait } = useIsPortrait();
  // const { isDesktopOrLaptop } = useIsDesktopOrLaptop();
  // const { isBigScreen } = useIsBigScreen();
  const { isTabletOrMobile } = useIsTabletOrMobile();

  return {
    isMobileLandscape: isTabletOrMobile && !isPortrait,
  };
};

export default useIsMobileLandscape;
