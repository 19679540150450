import React, { useCallback, useEffect, useRef } from "react";
import { firebaseDB } from "../../utils/firebase";
import {
  ref,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  query,
  orderByChild,
  endAt,
  limitToLast,
  onValue,
} from "firebase/database";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import sleep from "../../utils/sleep";

const removeDuplicateIds = (qnaMessages: qnaMessagesType): qnaMessagesType =>
  qnaMessages.reduce((p: qnaMessagesType, c) => {
    if (!p.some((el: qnaMessageType) => el.id === c.id)) p.push(c);

    return p;
  }, []);

const QnAListner = () => {
  const { broadcastId, userId } = useAppConfigContext();
  const {
    qnaMessages,
    setQnaMessages,
    isQnAEnabled,
    setIsQnAEnabled,
    setIsQnaMessagesLoadingMore,
    isQnaMessagesLoadingMore,
    setQnaLastSeenMessage,
    qnaLastSeenMessage,
    setQnaMessagesHasMore,
  } = useAppContext();

  const broadcastIdRef = useRef(broadcastId);
  const userIdRef = useRef(userId);
  const isQnAEnabledRef = useRef(isQnAEnabled);
  const qnaMessagesRef = useRef(qnaMessages);
  const isQnaMessagesLoadingMoreRef = useRef(isQnaMessagesLoadingMore);
  const qnaLastSeenMessageRef = useRef(qnaLastSeenMessage);

  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);
  useEffect(() => {
    userIdRef.current = userId;
  }, [userId]);
  useEffect(() => {
    isQnAEnabledRef.current = isQnAEnabled;
  }, [isQnAEnabled]);
  useEffect(() => {
    qnaMessagesRef.current = qnaMessages;
  }, [qnaMessages]);
  useEffect(() => {
    isQnaMessagesLoadingMoreRef.current = isQnaMessagesLoadingMore;
  }, [isQnaMessagesLoadingMore]);
  useEffect(() => {
    qnaLastSeenMessageRef.current = qnaLastSeenMessage;
  }, [qnaLastSeenMessage]);

  const subscribeToBroadcastChat = useCallback(
    (startAfterTimestamp: number) => {
      const disabledChatRef = ref(
        firebaseDB,
        `broadcastChat/disabledChat/${broadcastIdRef.current}`
      );

      const chatListRef = ref(
        firebaseDB,
        `broadcastChat/${broadcastIdRef.current}`
      );

      const broadcastChatRef = ref(firebaseDB, `broadcastChat`);

      const unSubscribe0 = onChildRemoved(
        disabledChatRef,
        () => {
          setIsQnAEnabled(true);
        },
        () => {}
      );

      const unSubscribe1 = onChildChanged(
        disabledChatRef,
        (snapshot) => {
          if (snapshot.val() === 1) {
            setIsQnAEnabled(false);
          } else {
            setIsQnAEnabled(true);
          }
        },
        () => {}
      );

      const unSubscribe2 = onChildAdded(
        disabledChatRef,
        (snapshot) => {
          if (snapshot.val() === 1) {
            setIsQnAEnabled(false);
          } else {
            setIsQnAEnabled(true);
          }
        },
        () => {}
      );

      const unSubscribe3 = onChildAdded(
        chatListRef,
        (snapshot) => {
          const value = snapshot.val();
          const id = snapshot.key;

          const {
            userId,
            message,
            name,
            timestamp: _timestamp,
            socialPlatform,
            momentoSent,
          } = value;

          const timestamp =
            `${_timestamp}`.length === 10 ? _timestamp * 1000 : _timestamp;

          if (timestamp <= startAfterTimestamp) {
            return;
          }

          const [one, two] = String(name || "").split(" ");

          const one_i = one ? one[0] : "";
          const two_i = two ? two[0] : "";

          const initials = `${
            one_i || two_i ? `${one_i}${two_i}` : "U"
          }`.toUpperCase();

          setQnaMessages((s) => {
            const qnaMessages = [
              ...s,
              {
                id,
                senderName: name,
                userId,
                timestamp,
                text: message,
                initials,
                isSelf: userIdRef.current === userId,
                chatSrc: "firebase",
                socialPlatform,
                momentoSent,
              },
            ].sort((a, b) =>
              a.timestamp > b.timestamp ? 1 : a.timestamp < b.timestamp ? -1 : 0
            );

            return removeDuplicateIds(
              qnaMessages.map((m, i) => {
                const isNextSelfSender =
                  qnaMessages[i + 1]?.userId === m.userId;

                const previousSameSender =
                  qnaMessages[i - 1]?.userId === m.userId;

                return { ...m, previousSameSender, isNextSelfSender };
              }) as qnaMessagesType
            );
          });

          appEventEmitter.emit(appEventEmitterEvents.QNA_NEW_MESSAGE);
        },
        () => {}
      );

      const unSubscribe4 = onChildRemoved(
        broadcastChatRef,
        async (snapshot) => {
          if (snapshot.key === broadcastIdRef.current) {
            setQnaMessages((m) =>
              removeDuplicateIds(
                m.filter(({ chatSrc }) => chatSrc !== "firebase")
              )
            );

            await sleep(500);

            setQnaLastSeenMessage({ id: null, index: null });
          }
        }
      );

      return {
        unSubscribe0,
        unSubscribe1,
        unSubscribe2,
        unSubscribe3,
        unSubscribe4,
      };
    },
    [setQnaMessages, setQnaLastSeenMessage, setIsQnAEnabled]
  );

  const _handleLoadInitChat = async () => {
    const queryRef = query(
      ref(firebaseDB, `broadcastChat/${broadcastIdRef.current}`),
      orderByChild("timestamp"),
      limitToLast(50)
    );

    const res = (await new Promise((resolve) =>
      onValue(queryRef, (s) => resolve(s.val()))
    )) as {
      [t: string]: {
        userId: string;
        message: string;
        name: string;
        timestamp: number;
        socialPlatform: string[];
        momentoSent: boolean;
      };
    };

    let subscribeToBroadcastChatTimestamp;

    if (res) {
      const qnaMessages = Object.keys(res)
        .map((id) => {
          const {
            userId,
            message,
            name,
            timestamp,
            socialPlatform,
            momentoSent,
          } = res[id];

          const [one, two] = String(name || "").split(" ");

          const one_i = one ? one[0] : "";
          const two_i = two ? two[0] : "";

          const initials = `${
            one_i || two_i ? `${one_i}${two_i}` : "U"
          }`.toUpperCase();

          return {
            id,
            senderName: name,
            userId,
            timestamp,
            text: message,
            initials,
            isSelf: userIdRef.current === userId,
            chatSrc: "firebase",
            socialPlatform,
            momentoSent,
          };
        })
        .sort((a, b) =>
          a.timestamp > b.timestamp ? 1 : a.timestamp < b.timestamp ? -1 : 0
        );

      const _qnaMessages = qnaMessages.map((m, i) => {
        const isNextSelfSender = qnaMessages[i + 1]?.userId === m.userId;

        const previousSameSender = qnaMessages[i - 1]?.userId === m.userId;

        return { ...m, previousSameSender, isNextSelfSender };
      });

      setQnaMessages(removeDuplicateIds(_qnaMessages));

      await sleep(500);

      if (_qnaMessages?.length) {
        setQnaLastSeenMessage({
          id: _qnaMessages[_qnaMessages.length - 1].id,
          index: _qnaMessages.length - 1,
        });

        await sleep(500);
      }

      subscribeToBroadcastChatTimestamp =
        _qnaMessages[_qnaMessages.length - 1]?.timestamp;

      if (qnaMessages?.length < 50) {
        setIsQnaMessagesLoadingMore(false);

        await sleep(150);

        setQnaMessagesHasMore(false);

        await sleep(150);
      }
    } else {
      setIsQnaMessagesLoadingMore(false);

      await sleep(150);

      setQnaMessagesHasMore(false);

      await sleep(150);
    }

    subscribeToBroadcastChat(
      subscribeToBroadcastChatTimestamp || new Date().getTime()
    );
  };

  const _handleLoadPreviousChat = async () => {
    if (isQnaMessagesLoadingMoreRef.current) {
      return;
    }

    setIsQnaMessagesLoadingMore(true);

    await sleep(250);

    const qnaMessages = qnaMessagesRef.current;

    if (!qnaMessages?.length) {
      setIsQnaMessagesLoadingMore(false);

      await sleep(250);

      setQnaMessagesHasMore(false);

      return;
    }

    const queryRef = query(
      ref(firebaseDB, `broadcastChat/${broadcastIdRef.current}`),
      orderByChild("timestamp"),
      endAt(qnaMessages[0]?.timestamp - 1),
      limitToLast(20)
    );

    const res = (await new Promise((resolve) =>
      onValue(queryRef, (s) => resolve(s.val()))
    )) as {
      [t: string]: {
        userId: string;
        message: string;
        name: string;
        timestamp: number;
        socialPlatform: string[];
        momentoSent: boolean;
      };
    };

    if (!res) {
      setIsQnaMessagesLoadingMore(false);

      await sleep(250);

      setQnaMessagesHasMore(false);

      return;
    }

    const messages = Object.keys(res).map((id) => {
      const { userId, message, name, timestamp, socialPlatform, momentoSent } =
        res[id];

      const [one, two] = String(name || "").split(" ");

      const one_i = one ? one[0] : "";
      const two_i = two ? two[0] : "";

      const initials = `${
        one_i || two_i ? `${one_i}${two_i}` : "U"
      }`.toUpperCase();

      return {
        id,
        senderName: name,
        userId,
        timestamp,
        text: message,
        initials,
        isSelf: userIdRef.current === userId,
        chatSrc: "firebase",
        socialPlatform,
        momentoSent,
      };
    });

    const _qnaMessages = [...messages, ...qnaMessages].sort((a, b) =>
      a.timestamp > b.timestamp ? 1 : a.timestamp < b.timestamp ? -1 : 0
    );

    const qnaMessagesToSet = _qnaMessages.map((m, i) => {
      const isNextSelfSender = _qnaMessages[i + 1]?.userId === m.userId;

      const previousSameSender = _qnaMessages[i - 1]?.userId === m.userId;

      return { ...m, previousSameSender, isNextSelfSender };
    });

    const qnaLastSeenMessage = qnaLastSeenMessageRef.current;

    if (qnaLastSeenMessage?.id) {
      const index = qnaMessagesToSet.findIndex(
        ({ id }) => qnaLastSeenMessage?.id === id
      );

      setQnaLastSeenMessage({ id: qnaLastSeenMessage?.id, index });

      await sleep(250);
    }

    setQnaMessages(removeDuplicateIds(qnaMessagesToSet));

    await sleep(250);

    setIsQnaMessagesLoadingMore(false);

    if (messages?.length < 20) {
      await sleep(250);

      setQnaMessagesHasMore(false);
    }
  };

  useEffect(() => {
    _handleLoadInitChat();

    appEventEmitter.on(
      appEventEmitterEvents.QNA_LOAD_PREV_CHAT,
      _handleLoadPreviousChat
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.QNA_LOAD_PREV_CHAT,
        _handleLoadPreviousChat
      );
    };
  }, []);

  return <React.Fragment />;
};

export default QnAListner;
