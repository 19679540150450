import React, { useEffect, useRef } from "react";
import { toggleParticipantMediaActions } from "../../hooks/appState/useToggleParticipantMedia";
import { getToggleParticipantMediaTopicById } from "../../utils/pubSubTopics";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import sleep from "../../utils/sleep";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useRtcLocalParticipantMediaStatsActions from "../../appRtc/useRtcLocalParticipantMediaStatsActions";
import useInputFileVideoShareStreams from "../../hooks/streams/useInputFileVideoShareStreams";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
// import useFileShareStreams from "../../hooks/streams/useFileShareStreams";

const systemCanResetMediaTimeout = 15000;

const LocalParticipantMediaToggleListner = () => {
  const restartWebcamSentBySystemAt = useRef(
    new Date().getTime() - systemCanResetMediaTimeout
  );
  const restartMicSentBySystemAt = useRef(
    new Date().getTime() - systemCanResetMediaTimeout
  );
  const restartScreenShareSentBySystemAt = useRef(
    new Date().getTime() - systemCanResetMediaTimeout
  );

  const { localParticipantId } = useLocalParticipantId();

  const {
    enableScreenShare,
    disableScreenShare,
    enableMic,
    enableWebcam,
    disableMic,
    disableWebcam,
  } = useRtcLocalParticipantMediaStatsActions();
  const { disableInputFileVideoShareStream } = useInputFileVideoShareStreams();
  // const { disableFileShareStream } = useFileShareStreams();

  const { webcamOn, micOn, screenShareOn } =
    useParticipantMediaStats(localParticipantId);

  const webcamOnRef = useRef(webcamOn);
  const micOnRef = useRef(micOn);
  const screenShareOnRef = useRef(screenShareOn);

  useEffect(() => {
    webcamOnRef.current = webcamOn;
  }, [webcamOn]);
  useEffect(() => {
    micOnRef.current = micOn;
  }, [micOn]);
  useEffect(() => {
    screenShareOnRef.current = screenShareOn;
  }, [screenShareOn]);

  const _handleTurnOnWebcam = () => {
    enableWebcam();
  };
  const _handleTurnOffWebcam = () => {
    disableWebcam();
  };
  const _handleturnOnMic = () => {
    enableMic();
  };
  const _handleturnOffMic = () => {
    disableMic();
  };
  const _handleTurnOnScreenShare = () => {
    enableScreenShare();
  };
  const _handleTurnOffScreenShare = () => {
    disableScreenShare();
  };

  const _handleRestartWebcam = async ({
    sentBySystem,
  }: {
    sentBySystem: boolean;
  }) => {
    if (!webcamOnRef.current) {
      return;
    }

    if (
      sentBySystem &&
      restartWebcamSentBySystemAt.current >
        new Date().getTime() - systemCanResetMediaTimeout
    ) {
      return;
    }

    restartWebcamSentBySystemAt.current = new Date().getTime();

    disableWebcam();

    await sleep(1000);

    enableWebcam();
  };

  const _handleRestartMic = async ({
    sentBySystem,
  }: {
    sentBySystem: boolean;
  }) => {
    if (!micOnRef.current) {
      return;
    }

    if (
      sentBySystem &&
      restartMicSentBySystemAt.current >
        new Date().getTime() - systemCanResetMediaTimeout
    ) {
      return;
    }

    restartMicSentBySystemAt.current = new Date().getTime();

    disableMic();

    await sleep(1000);

    enableMic();
  };

  const _handleRestartScreenShare = async ({
    sentBySystem,
  }: {
    sentBySystem: boolean;
  }) => {
    if (!screenShareOnRef.current) {
      return;
    }

    if (
      sentBySystem &&
      restartScreenShareSentBySystemAt.current >
        new Date().getTime() - systemCanResetMediaTimeout
    ) {
      return;
    }

    restartScreenShareSentBySystemAt.current = new Date().getTime();

    disableScreenShare();

    await sleep(1000);

    enableScreenShare();
  };

  const _handleTurnOffInputFileVideoShareStream = ({
    inputFileVideoId,
  }: // sentBySystem,
  {
    inputFileVideoId: string;
    sentBySystem: boolean;
  }) => {
    disableInputFileVideoShareStream({ id: inputFileVideoId });
  };

  const _handleTurnOffExtraWebcamVideoStream = ({
    extraWebcamId,
  }: // sentBySystem,
  {
    extraWebcamId: string;
    sentBySystem: boolean;
  }) => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_TURN_OFF_EXTRA_WEBCAM, {
      extraWebcamId,
    });
  };

  // const _handleTurnOffFileShareStream = ({
  //   fileId,
  // }: // sentBySystem,
  // {
  //   fileId: string;
  //   sentBySystem: boolean;
  // }) => {
  //   disableFileShareStream({ id: fileId });
  // };

  useAppSingalingSubscribe(
    getToggleParticipantMediaTopicById(localParticipantId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const {
        action,
        sentBySystem,
        inputFileVideoId,
        extraWebcamId,
        // fileId
      } = message;

      switch (action) {
        case toggleParticipantMediaActions.TURN_ON_WEBCAM:
          _handleTurnOnWebcam();
          break;

        case toggleParticipantMediaActions.TURN_OFF_WEBCAM:
          _handleTurnOffWebcam();
          break;

        case toggleParticipantMediaActions.TURN_ON_MIC:
          _handleturnOnMic();
          break;

        case toggleParticipantMediaActions.TURN_OFF_MIC:
          _handleturnOffMic();
          break;

        case toggleParticipantMediaActions.TURN_ON_SCREEN_SHARE:
          _handleTurnOnScreenShare();
          break;

        case toggleParticipantMediaActions.TURN_OFF_SCREEN_SHARE:
          _handleTurnOffScreenShare();
          break;

        case toggleParticipantMediaActions.RESTART_WEBCAM:
          _handleRestartWebcam({ sentBySystem });
          break;

        case toggleParticipantMediaActions.RESTART_MIC:
          _handleRestartMic({ sentBySystem });
          break;

        case toggleParticipantMediaActions.RESTART_SCREEN_SHARE:
          _handleRestartScreenShare({ sentBySystem });
          break;

        case toggleParticipantMediaActions.TURN_OFF_INPUT_FILE_VIDEO_SHARE_STREAM:
          _handleTurnOffInputFileVideoShareStream({
            inputFileVideoId,
            sentBySystem,
          });

          break;

        case toggleParticipantMediaActions.TURN_OFF_EXTRA_WEBCAM_VIDEO_STREAM:
          _handleTurnOffExtraWebcamVideoStream({ extraWebcamId, sentBySystem });

          break;

        // case toggleParticipantMediaActions.TURN_OFF_FILE_SHARE_STREAM:
        //   _handleTurnOffFileShareStream({ fileId, sentBySystem });
        //   break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default LocalParticipantMediaToggleListner;
