// @ts-nocheck

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import {
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import validateSpeakerDeviceId from "../../utils/validateSpeakerDeviceId";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { MdPlayArrow } from "react-icons/md";
import { createUID } from "../../utils/createUID";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";
import { interactivityModes } from "../../utils/constants";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
import useAppRtcParticipantMediaStats from "../../appRtc/useAppRtcParticipantMediaStats";
import useRtcRemoteParticipantMediaStatsActions from "../../appRtc/useRtcRemoteParticipantMediaStatsActions";

const AudioMediaStreamTrackPlayer = ({
  audioTrack,
}: {
  audioTrack?: null | MediaStreamTrack;
}) => {
  const [muteDueToError, setMuteDueToError] = useState(false);
  const audioPlayer = useRef<StudioHTMLAudioElement>();

  const audioPlayerId = useMemo(
    () => `AudioMediaStreamTrackPlayer_${createUID()}`,
    []
  );

  const audioPlayerIdRef = useRef(audioPlayerId);
  const muteDueToErrorRef = useRef(muteDueToError);

  const audioTrackRef = useRef(audioTrack);

  useEffect(() => {
    audioTrackRef.current = audioTrack;
  }, [audioTrack]);

  const {
    selectedSpeakerDeviceId,
    mainViewVideoClip,
    appAudioMuted,
    backgroundMusic,
    muteGuestsWhenAudioPlays,
    muteGuestsWhenVideoPlays,
    isAnyAgoraActiveSpeaker,
    muteOriginalAudioWhenInterpretationActive,
    activeInterpretationOutputAgoraChannelId,
  } = useAppContext();

  const forceMuteAudio = useMemo(
    () =>
      (mainViewVideoClip.id && mainViewVideoClip.remoteUrl
        ? !!muteGuestsWhenVideoPlays
        : false) ||
      (backgroundMusic.id && backgroundMusic.playedAt
        ? !!muteGuestsWhenAudioPlays
        : false) ||
      appAudioMuted,
    [
      mainViewVideoClip,
      backgroundMusic,
      appAudioMuted,
      muteGuestsWhenAudioPlays,
      muteGuestsWhenVideoPlays,
    ]
  );

  const volume = useMemo(
    () =>
      muteDueToError ||
      forceMuteAudio ||
      (muteOriginalAudioWhenInterpretationActive &&
        activeInterpretationOutputAgoraChannelId)
        ? 0
        : isAnyAgoraActiveSpeaker
        ? 0.1
        : 1,
    [
      muteDueToError,
      forceMuteAudio,
      isAnyAgoraActiveSpeaker,
      muteOriginalAudioWhenInterpretationActive,
      activeInterpretationOutputAgoraChannelId,
    ]
  );

  const selectedSpeakerDeviceIdRef = useRef(selectedSpeakerDeviceId);

  useEffect(() => {
    selectedSpeakerDeviceIdRef.current = selectedSpeakerDeviceId;
  }, [selectedSpeakerDeviceId]);

  useEffect(() => {
    audioPlayerIdRef.current = audioPlayerId;
  }, [audioPlayerId]);

  useEffect(() => {
    muteDueToErrorRef.current = muteDueToError;
  }, [muteDueToError]);

  const getAudioTag = () => {
    const audioTag = document.getElementById(
      audioPlayerIdRef.current
    ) as StudioHTMLAudioElement | null;

    return audioTag;
  };

  const setSinkIdForAudio = async ({ deviceId }: { deviceId: string }) => {
    const { isValid } = await validateSpeakerDeviceId({ deviceId });

    const audioTag = getAudioTag();

    if (isValid && typeof audioTag?.setSinkId === "function") {
      audioTag.setSinkId(deviceId);
    }
  };

  const _playAudioStream = async ({
    audioTrack,
    forcePlay,
  }: {
    audioTrack?: MediaStreamTrack | null;
    forcePlay?: boolean;
  }) => {
    const audioTag = getAudioTag();

    if (!audioTag) return;

    if (muteDueToErrorRef.current) {
      setMuteDueToError(false);
    }

    if (!audioTrack) {
      audioTag.pause();

      return;
    }

    const mediaStream = new MediaStream();

    mediaStream.addTrack(audioTrack);

    audioTag.srcObject = mediaStream;

    audioTag.muted = false;

    if (!audioTag.paused && !forcePlay) return;

    const playPromise = audioTag.play();

    if (playPromise !== undefined) {
      await new Promise((resolve) => {
        playPromise.then(resolve).catch((err) => {
          if (String(err).includes("NotAllowedError")) {
            if (!muteDueToErrorRef.current) {
              setMuteDueToError(true);
            }

            audioTag.muted = true;

            const mutedPlayPromise = audioTag.play();

            if (mutedPlayPromise !== undefined) {
              mutedPlayPromise.then(resolve).catch(resolve);
            }
          } else {
            resolve(undefined);
          }
        });
      });
    }
  };

  const _handleUserInteractButtonClicked = () => {
    _playAudioStream({
      audioTrack: audioTrackRef.current,
      forcePlay: true,
    });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED,
      _handleUserInteractButtonClicked
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED,
        _handleUserInteractButtonClicked
      );
    };
  }, []);

  useEffect(() => {
    setSinkIdForAudio({
      deviceId: selectedSpeakerDeviceId as string,
    });
  }, [selectedSpeakerDeviceId]);

  useEffect(() => {
    _playAudioStream({
      audioTrack: audioTrack,
      forcePlay: true,
    });
  }, [audioTrack]);

  useEffect(() => {
    const audioTag = getAudioTag();

    if (audioTag) {
      audioTag.muted = forceMuteAudio || muteDueToError;
    }
  }, [forceMuteAudio, muteDueToError]);

  useEffect(() => {
    const audioTag = getAudioTag();

    if (audioTag) {
      audioTag.volume = volume;
    }
  }, [volume]);

  return (
    <React.Fragment>
      <audio
        id={audioPlayerId}
        autoPlay
        playsInline
        controls={false}
        ref={audioPlayer}
      />

      {muteDueToError ? (
        <div
          onClick={() => {
            appEventEmitter.emit(
              appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED
            );
          }}
          className="flex items-center justify-center absolute top-0 bottom-0 right-0 left-0"
        >
          <div
            className="aspect-square rounded-full bg-black bg-opacity-50 p-3 border-4 border-white"
            style={{ height: "30%" }}
          >
            <MdPlayArrow className="fill-white" size={"100%"} />
          </div>
        </div>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const ParticipantAudioTrackListner = ({
  participantId,
  micAudioStreamPlayable,
  screenShareParticipantId,
}: {
  participantId: string;

  micAudioStreamPlayable: boolean;
  screenShareParticipantId?: string;
}) => {
  const { micTrack: audioTrack, screenShareAudioTrack } =
    useAppRtcParticipantMediaStats(participantId);
  const {
    subscribeMicStream,
    subscribeScreenShareAudioStream,
    unsubscribeScreenShareAudioStream,
  } = useRtcRemoteParticipantMediaStatsActions(participantId);

  const { micOn, screenShareAudioOn } = useParticipantMediaStats(participantId);

  const { isLocal } = useIsLocalParticipantId(participantId);

  const audioTrackToPlay = useMemo(() => {
    if (!isLocal && audioTrack && micOn && micAudioStreamPlayable) {
      return audioTrack;
    } else {
      return null;
    }
  }, [micAudioStreamPlayable, audioTrack, isLocal, micOn]);

  const participantIdRef = useRef(participantId);
  const audioTrackRef = useRef(audioTrack);
  const micAudioStreamPlayableRef = useRef(micAudioStreamPlayable);
  const subscribeMicStreamRef = useRef(subscribeMicStream);
  const subscribeScreenShareAudioStreamRef = useRef(
    subscribeScreenShareAudioStream
  );
  const unsubscribeScreenShareAudioStreamRef = useRef(
    unsubscribeScreenShareAudioStream
  );
  const screenShareParticipantIdRef = useRef(screenShareParticipantId);
  const screenShareAudioTrackRef = useRef(screenShareAudioTrack);
  const screenShareAudioOnRef = useRef(screenShareAudioOn);
  const audioTrackToPlayRef = useRef(audioTrackToPlay);

  useEffect(() => {
    participantIdRef.current = participantId;
  }, [participantId]);
  useEffect(() => {
    audioTrackRef.current = audioTrack;
  }, [audioTrack]);

  useEffect(() => {
    micAudioStreamPlayableRef.current = micAudioStreamPlayable;
  }, [micAudioStreamPlayable]);
  useEffect(() => {
    subscribeMicStreamRef.current = subscribeMicStream;
  }, [subscribeMicStream]);
  useEffect(() => {
    subscribeScreenShareAudioStreamRef.current =
      subscribeScreenShareAudioStream;
  }, [subscribeScreenShareAudioStream]);
  useEffect(() => {
    unsubscribeScreenShareAudioStreamRef.current =
      unsubscribeScreenShareAudioStream;
  }, [unsubscribeScreenShareAudioStream]);
  useEffect(() => {
    screenShareParticipantIdRef.current = screenShareParticipantId;
  }, [screenShareParticipantId]);
  useEffect(() => {
    screenShareAudioTrackRef.current = screenShareAudioTrack;
  }, [screenShareAudioTrack]);
  useEffect(() => {
    screenShareAudioOnRef.current = screenShareAudioOn;
  }, [screenShareAudioOn]);
  useEffect(() => {
    audioTrackToPlayRef.current = audioTrackToPlay;
  }, [audioTrackToPlay]);

  useEffect(() => {
    const interval = setInterval(() => {
      const participantId = participantIdRef.current;
      const screenShareParticipantId = screenShareParticipantIdRef.current;
      const screenShareAudioTrack = screenShareAudioTrackRef.current;
      const screenShareAudioOn = screenShareAudioOnRef.current;
      // const micAudioStreamPlayable = micAudioStreamPlayableRef.current;
      const audioTrack = audioTrackRef.current;

      const subscribeScreenShareAudioStream =
        subscribeScreenShareAudioStreamRef.current;
      const unsubscribeScreenShareAudioStream =
        unsubscribeScreenShareAudioStreamRef.current;
      const subscribeMicStream = subscribeMicStreamRef.current;

      if (participantId === screenShareParticipantId) {
        if (screenShareAudioOn) {
          if (!screenShareAudioTrack) {
            subscribeScreenShareAudioStream();
          }
        } else {
          if (screenShareAudioTrack) {
            unsubscribeScreenShareAudioStream();
          }
        }
      } else {
        if (screenShareAudioTrack) {
          unsubscribeScreenShareAudioStream();
        }
      }

      if (!audioTrack) {
        subscribeMicStream();
      }

      // if (micAudioStreamPlayable) {
      //   if (!audioTrack) {
      //     subscribeMicStream();
      //   }
      // } else {
      //   if (audioTrack) {
      //     // unsubscribeMicStream();
      //   } else {
      //     // auto subscribing mic stream for all participants

      //     subscribeMicStream();
      //   }
      // }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <AudioMediaStreamTrackPlayer audioTrack={audioTrackToPlay} />;
};

const ParticipantScreenShareAudioTrackListner = ({
  screenShareParticipantId,
}: {
  screenShareParticipantId?: string;
}) => {
  const { screenShareAudioOn } = useParticipantMediaStats(
    screenShareParticipantId || ""
  );

  const { isLocal } = useIsLocalParticipantId(
    screenShareParticipantId as string
  );

  const { screenShareAudioTrack: audioTrack } = useAppRtcParticipantMediaStats(
    screenShareParticipantId || ""
  );

  const audioTrackToPlay = useMemo(() => {
    if (
      !isLocal &&
      audioTrack &&
      screenShareAudioOn &&
      screenShareParticipantId
    ) {
      return audioTrack;
    } else {
      return null;
    }
  }, [audioTrack, screenShareParticipantId, screenShareAudioOn, isLocal]);

  return <AudioMediaStreamTrackPlayer audioTrack={audioTrackToPlay} />;
};

const ParticipantsAudioStreamsContainer = () => {
  const {
    mainViewSelectedStreams,
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
  } = useAppContext();

  const { micAudioStreamPlayableParticipantIds } = useMemo(() => {
    const micAudioStreamPlayableParticipantIds = [];

    if (interactivityMode === interactivityModes.STUDIO) {
      [...mainViewSelectedStreams.keys()].forEach((streamId) => {
        if (streamId.includes(streamTypes.WEBCAM)) {
          micAudioStreamPlayableParticipantIds.push(streamId.split("_")[0]);
        }
      });
    } else {
      micAudioStreamPlayableParticipantIds.push(
        ...conferenceMainViewVisibleWebcamStreams.map(
          ({ participantId }) => participantId
        )
      );
    }

    return { micAudioStreamPlayableParticipantIds };
  }, [
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
    mainViewSelectedStreams,
  ]);

  const { participantIds } = useParticipantIdsAndCount();

  const mappedParticipantIds = useMemo(
    () =>
      participantIds.map((participantId) => ({
        participantId,
        micAudioStreamPlayable: !!micAudioStreamPlayableParticipantIds.find(
          (_participantId) => _participantId === participantId
        ),
      })),
    [participantIds, micAudioStreamPlayableParticipantIds]
  );

  const screenShareParticipantId = useMemo(
    () =>
      [...new Map(mainViewSelectedStreams).keys()]
        .find(
          (participantStreamId) =>
            participantStreamId.split("_")[1] === streamTypes.SHARE &&
            participantStreamId.split("_")[2] === streamModes.SCREEN
        )
        ?.split("_")[0],
    [mainViewSelectedStreams]
  );

  return (
    <React.Fragment>
      {mappedParticipantIds.map(({ participantId, micAudioStreamPlayable }) => (
        <ParticipantAudioTrackListner
          key={`PARTICIPANT_AUDIO_TRACK_LISTNER_${participantId}`}
          participantId={participantId}
          // onAudioTrack={_handleOnAudioTrack}
          micAudioStreamPlayable={micAudioStreamPlayable}
          screenShareParticipantId={screenShareParticipantId}
        />
      ))}

      <ParticipantScreenShareAudioTrackListner
        key={`PARTICIPANT_SCREEN_SHARE_AUDIO_TRACK_LISTNER_${screenShareParticipantId}`}
        screenShareParticipantId={screenShareParticipantId}
      />
    </React.Fragment>
  );
};

export default ParticipantsAudioStreamsContainer;
