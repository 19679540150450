// import { usePubSub } from "@videosdk.live/react-sdk";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "./useValidateHostCanTakeAction";

const useInteractivityMode = () => {
  // const { publish } = usePubSub(appPubSubTopics.INTERACTIVITY_MODE);
  const { publish } = useAppSingalingPublish(
    appPubSubTopics.INTERACTIVITY_MODE
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const changeInteractivityMode = async ({
    interactivityMode,
  }: {
    interactivityMode: interactivityModeType;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ interactivityMode })
      // , { persist: true }
    );
  };

  return { changeInteractivityMode };
};

export default useInteractivityMode;
