import React, { useMemo } from "react";
import { MdMicOff } from "react-icons/md";
import { useAppConfigContext } from "../contexts/appConfigDef";
import { useAppContext } from "../contexts/appContextDef";
import { isHexLight } from "../utils/isHexLight";
import useHighlightedChat from "../hooks/appState/useHighlightedChat";
import getTransitionStyle from "../utils/getTransitionStyle";
import { quickstartDummyParticipants } from "../utils/constants";

const CornerDisplayName = ({
  micOn,
  name: _name,
  relativeWidth: _relativeWidth,
  relativeHeight,
  participantId,
  mainViewContainerWidth,
}: {
  micOn: boolean;
  name: string;
  relativeWidth: number;
  relativeHeight: number;
  participantId: string;
  mainViewContainerWidth: number;
}) => {
  const name = useMemo(
    () =>
      _name
        ? _name
        : quickstartDummyParticipants.find(
            ({ participantId: _participantId }) =>
              participantId === _participantId
          )?.name,
    [participantId, _name]
  );

  const relativeWidth = useMemo(
    () =>
      _relativeWidth / relativeHeight > 1 ? relativeHeight : _relativeWidth,
    [_relativeWidth, relativeHeight]
  );

  const { highlightedChat } = useHighlightedChat();

  const appContext = useAppContext();
  const { isRecorder } = useAppConfigContext();

  const {
    backgroundColor,
    isBackgroundLight,
    showDisplayName,
    participantHeadline,
    showParticipantHeadline,
  } = useMemo(() => {
    const backgroundColor = appContext?.brandColor?.hexColorCode || "#000000";

    const isBackgroundLight = isHexLight(backgroundColor);

    return {
      backgroundColor,
      isBackgroundLight,
      showDisplayName: appContext?.showDisplayName && name && name?.length > 0,
      showParticipantHeadline:
        appContext?.showParticipantHeadline && name && name?.length > 0,
      participantHeadline:
        (appContext?.participantHeadlines &&
          appContext?.participantHeadlines[participantId]) ||
        "",
    };
  }, [appContext, participantId, name]);

  const showMicIcon = useMemo(() => !micOn && !isRecorder, [isRecorder, micOn]);

  const relativeWidthMultiplier = useMemo(
    () => (relativeWidth <= 25 ? 0.4 : relativeWidth > 50 ? 1 : 0.6),
    [relativeWidth]
  );

  return (
    <div className="flex flex-col items-start">
      {(showMicIcon || showDisplayName) &&
      !appContext?.activeLowerThirtdBanner &&
      !highlightedChat?.enabled ? (
        <div
          style={{
            zIndex: 10,
            backgroundColor,
            paddingTop:
              mainViewContainerWidth * relativeWidthMultiplier * 0.005,
            paddingBottom:
              mainViewContainerWidth * relativeWidthMultiplier * 0.005,
            paddingLeft:
              mainViewContainerWidth * relativeWidthMultiplier * 0.01,
            paddingRight:
              mainViewContainerWidth * relativeWidthMultiplier * 0.01,
            transition: getTransitionStyle([
              "background-color",
              "padding-top",
              "padding-bottom",
              "padding-left",
              "padding-right",
            ]),
          }}
          className={`max-w-full rounded-full ${
            isBackgroundLight
              ? "text-black fill-black"
              : "text-white fill-white"
          } flex items-center justify-center`}
        >
          {showMicIcon && (
            <MdMicOff
              style={{
                transition: getTransitionStyle(["all"]),
              }}
              size={mainViewContainerWidth * relativeWidthMultiplier * 0.03}
            />
          )}
          {showDisplayName ? (
            <div>
              <p
                style={{
                  maxWidth:
                    ((relativeWidth * mainViewContainerWidth) / 100) * 0.5,
                  fontSize:
                    mainViewContainerWidth * relativeWidthMultiplier * 0.028,
                  marginLeft: showMicIcon
                    ? mainViewContainerWidth * relativeWidthMultiplier * 0.005
                    : undefined,
                  transition: getTransitionStyle([
                    "max-width",
                    "font-size",
                    "margin-left",
                  ]),
                }}
                className={`font-bold truncate text-ellipsis`}
              >
                {`${name}`}
              </p>
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
      ) : (
        <React.Fragment />
      )}

      {showParticipantHeadline &&
      participantHeadline &&
      showDisplayName &&
      !appContext?.activeLowerThirtdBanner &&
      !highlightedChat?.enabled ? (
        <div
          style={{
            backgroundColor: isBackgroundLight ? "white" : "black",
            paddingTop:
              mainViewContainerWidth * relativeWidthMultiplier * 0.005,
            paddingBottom:
              mainViewContainerWidth * relativeWidthMultiplier * 0.005,
            paddingLeft:
              mainViewContainerWidth * relativeWidthMultiplier * 0.01,
            paddingRight:
              mainViewContainerWidth * relativeWidthMultiplier * 0.01,
            marginTop:
              -mainViewContainerWidth * relativeWidthMultiplier * 0.006,
            marginLeft:
              mainViewContainerWidth * relativeWidthMultiplier * 0.013,
            borderTopRightRadius:
              mainViewContainerWidth * relativeWidthMultiplier * 0.015,
            borderBottomLeftRadius:
              mainViewContainerWidth * relativeWidthMultiplier * 0.015,
            borderBottomRightRadius:
              mainViewContainerWidth * relativeWidthMultiplier * 0.015,
            transition: getTransitionStyle([
              "background-color",
              "padding-top",
              "padding-bottom",
              "padding-left",
              "padding-right",
              "margin-top",
              "margin-left",
              "border-top-right-radius",
              "border-bottom-left-radius",
              "border-bottom-right-radius",
            ]),
          }}
          className={`max-w-full  ${
            isBackgroundLight
              ? "text-black fill-black"
              : "text-white fill-white"
          } flex items-center justify-center`}
        >
          <div>
            <p
              style={{
                maxWidth:
                  ((relativeWidth * mainViewContainerWidth) / 100) * 0.5,
                fontSize:
                  mainViewContainerWidth * relativeWidthMultiplier * 0.018,
                marginLeft: showMicIcon
                  ? mainViewContainerWidth * relativeWidthMultiplier * 0.005
                  : undefined,
                transition: getTransitionStyle([
                  "max-width",
                  "font-size",
                  "margin-left",
                ]),
              }}
              className={`font-bold truncate text-ellipsis`}
            >
              {`${participantHeadline}`}
            </p>
          </div>
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default CornerDisplayName;
