import React, { useMemo } from "react";
import { MOMENTO_LOGO_FULL } from "../../static/images";
import {
  MdFace,
  MdHighlight,
  MdDesktopWindows,
  MdOndemandVideo,
  MdSignalWifiStatusbar4Bar,
  MdHeadsetMic,
  MdAccountBox,
  MdSlideshow,
} from "react-icons/md";
import Spinner from "../../components/Spinner";
import useIsMobilePortrait from "../../hooks/responsive/useIsMobilePortrait";
import { useWindowSize } from "@react-hook/window-size";
import { appModes } from "../../utils/constants";

const hostMessages = [
  {
    icons: [{ Icon: MdHighlight, rotation: 90 }, { Icon: MdFace }],
    message: "ProTip: Decent lighting helps you look amazing.",
  },
  {
    icons: [{ Icon: MdDesktopWindows }],
    message: "ProTip: Your guests can share their screen too!",
  },
  {
    icons: [{ Icon: MdOndemandVideo }],
    message:
      "ProTip: Remember to greet your viewers that will watch the replay.",
  },
  {
    icons: [{ Icon: MdSignalWifiStatusbar4Bar }],
    message: "ProTip: Plugging in to the internet works better than wifi.",
  },
  {
    icons: [{ Icon: MdAccountBox }],
    message: "ProTip: Use the solo button to fullscreen a guest.",
  },
  {
    icons: [{ Icon: MdSlideshow }],
    message: "ProTip: You can upload short video clips in the Brand section.",
  },
  {
    icons: [{ Icon: MdHeadsetMic }],
    message: "ProTip: Wear earbuds if you experience audio echoes.",
  },
];

const speakerMessages = [
  {
    icons: [{ Icon: MdHighlight, rotation: 90 }, { Icon: MdFace }],
    message: "ProTip: Decent lighting helps you look amazing.",
  },

  {
    icons: [{ Icon: MdSignalWifiStatusbar4Bar }],
    message: "ProTip: Plugging in to the internet works better than wifi.",
  },

  {
    icons: [{ Icon: MdHeadsetMic }],
    message: "ProTip: Wear earbuds if you experience audio echoes.",
  },
];

const InitializingAppContainer = () => {
  //   const { embedded, isRecorder, appMode } = useAppConfigContext();

  const { appMode, isRecorder, embedded } = useMemo(() => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);

    //
    const appMode = urlParams.get("appMode") as appModeType | null;
    const isRecorder = urlParams.get("isRecorder") === "true";

    const _appMode = appMode || (isRecorder ? appModes.VIEWER : appModes.HOST);
    const isInterpreter = urlParams.get("interpreter") === "true";

    const embedded = isInterpreter || urlParams.get("embedded") === "true";

    return { appMode: _appMode, isRecorder, embedded };
  }, []);

  const hostAndSpeakerMessage = useMemo(() => {
    const messages = appMode === appModes.HOST ? hostMessages : speakerMessages;

    let rand = Math.random() * messages.length;

    rand = Math.floor(rand);

    return messages[rand];
  }, [appMode]);

  const { isMobilePortrait } = useIsMobilePortrait();
  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <React.Fragment>
      {isRecorder ? (
        <React.Fragment />
      ) : (
        <div
          style={{ width: windowWidth, height: windowHeight }}
          className="flex flex-1 flex-col p-5"
        >
          {embedded ? (
            <React.Fragment />
          ) : (
            <div>
              <a
                target="_blank"
                href="http://momentostream.com/"
                rel="noreferrer"
              >
                <img
                  className="h-8"
                  src={MOMENTO_LOGO_FULL}
                  alt="momento logo full"
                />
              </a>
            </div>
          )}

          {appMode === appModes.HOST ? (
            <div
              className={`flex flex-1 items-center justify-center flex-col ${
                isMobilePortrait ? "p-5" : ""
              }`}
            >
              {/* <div>
                <h4 className="text-2xl font-semibold text-black">
                  Joining the studio
                </h4>
              </div> */}

              <React.Fragment>
                <div className="flex flex-col items-center justify-center mt-12">
                  <div className="flex items-center justify-center">
                    {hostAndSpeakerMessage.icons.map(
                      ({ Icon, rotation }, i) => {
                        return (
                          <div
                            key={`waiting-to-join-speakers-message-${i}`}
                            style={{
                              transform: `rotate(${rotation || 0}deg)`,
                            }}
                            className={`${i > 0 ? "ml-3" : ""}`}
                          >
                            <Icon size={56} />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <p className="font-bold mt-3 text-center">
                  {hostAndSpeakerMessage.message}
                </p>
              </React.Fragment>

              <div className="mt-12">
                <Spinner h={"h-12"} w={"w-12"} />
                <span className="sr-only">Loading...</span>
              </div>

              <p>Loading</p>
            </div>
          ) : (
            <div className="flex flex-1 items-center justify-center">
              <Spinner h={"h-20"} w={"w-20"} />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default InitializingAppContainer;
