import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  MdAdd,
  MdClose,
  MdDns,
  MdEdit,
  MdPriorityHigh,
  MdRefresh,
} from "react-icons/md";
import { Dialog, Transition } from "@headlessui/react";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import useIsFreePlanActive from "../../../hooks/plans/useIsFreePlanActive";
import useIsBasicPlanActive from "../../../hooks/plans/useIsBasicPlanActive";
import UpgradeNowLine from "../../../components/UpgradeNowLine";
import useActivePlanType from "../../../hooks/plans/useActivePlanType";
import { businessPlansConfig, numberToWord } from "../../../utils/constants";
import Youtube from "../../../static/brand-icons/Youtube";
import Facebook from "../../../static/brand-icons/Facebook";
import Twitch from "../../../static/brand-icons/Twitch";
import {
  momento_live_logo,
  momento_translate_logo,
} from "../../../static/images";
import Tooltip from "../../../components/Tooltip";
import LinkedIn from "../../../static/brand-icons/LinkedIn";
import { useAppContext } from "../../../contexts/appContextDef";
import useLiveStreamDestinations from "../../../hooks/appState/useLiveStreamDestinations";
import { useResetLiveStreamDestinations } from "../../../listners/appState/ResetLiveStreamDestinationsListner";
import useBroadcastEgresses from "../../../hooks/appState/useBroadcastEgresses";
import { createRtmpDestinationUrlFromUrlAndStreamKey } from "../../../listners/appState/BroadcastEgressesListner";
import { getLivekitStreamUrlFromOriginalRtmpUrl } from "../../../utils/getLivekitStreamUrlFromOriginalRtmpUrl";
import Kick from "../../../static/brand-icons/Kick";
import Instagram from "../../../static/brand-icons/Instagram";

const MomentoLiveBroadcastItem = () => {
  const { failedRtmpDestinationUrls } = useAppContext();
  const { defaultDestination } = useAppConfigContext();

  const { isErrored, errorMessage } = useMemo(() => {
    const streamKey = defaultDestination?.streamKey;
    const streamUrl = defaultDestination?.streamUrl;

    const url = createRtmpDestinationUrlFromUrlAndStreamKey({
      streamKey: streamKey as string,
      url: streamUrl as string,
    });

    const { livekitStreamUrl } = getLivekitStreamUrlFromOriginalRtmpUrl({
      url,
    });

    const errFoundFailed = failedRtmpDestinationUrls?.find(
      ({ url: _url }) => _url === livekitStreamUrl || _url === url
    );

    return { isErrored: !!errFoundFailed, errorMessage: errFoundFailed?.error };
  }, [defaultDestination, failedRtmpDestinationUrls]);

  return (
    <Tooltip
      title={"Momento Live"}
      titlen={
        isErrored && errorMessage ? [`Error: ${errorMessage}`] : undefined
      }
    >
      <div
        className={`border-custom-blue-300 border-2 m-1 rounded-full relative`}
      >
        <div
          className={`bg-custom-blue-600 border-primary m-0.5 rounded-full h-12 w-12 flex items-center justify-center aspect-square p-2 border-2 tooltip cursor-not-allowed`}
          onClick={async () => {}}
        >
          <p className="text-white text-lg font-extrabold">M</p>
        </div>

        <div
          style={{
            backgroundColor: "gray",
          }}
          className="absolute flex items-center justify-center -right-1 bottom-0 p-0.5 rounded-full"
        >
          <img
            src={momento_live_logo}
            className={"h-5 w-5 bg-white rounded-full"}
            alt="momento-logo"
          />
        </div>

        {isErrored ? (
          <div className="bg-red-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdPriorityHigh className="fill-white h-3 w-3" />
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </Tooltip>
  );
};

const MomentoTranslateOriginalBroadcastItem = ({ name }: { name: string }) => {
  const { interpretationsOriginalDestination } = useAppConfigContext();
  const { failedRtmpDestinationUrls } = useAppContext();

  const { isErrored, errorMessage } = useMemo(() => {
    const url = createRtmpDestinationUrlFromUrlAndStreamKey({
      streamKey: interpretationsOriginalDestination?.rtmpKey,
      url: interpretationsOriginalDestination?.rtmpUrl,
    });

    const { livekitStreamUrl } = getLivekitStreamUrlFromOriginalRtmpUrl({
      url,
    });

    const found = failedRtmpDestinationUrls?.find(
      ({ url: _url }) => _url === livekitStreamUrl || _url === url
    );

    return { isErrored: !!found, errorMessage: found?.error };
  }, [interpretationsOriginalDestination, failedRtmpDestinationUrls]);

  return (
    <Tooltip
      title={`Momento Translate (${name})`}
      titlen={
        isErrored && errorMessage ? [`Error: ${errorMessage}`] : undefined
      }
    >
      <div
        className={`border-custom-blue-300 border-2 m-1 rounded-full relative`}
      >
        <div
          className={`bg-custom-blue-600 border-primary m-0.5 rounded-full h-12 w-12 flex items-center justify-center aspect-square p-2 border-2 tooltip cursor-not-allowed`}
          onClick={async () => {}}
        >
          <p className="text-white text-lg font-extrabold">M</p>
        </div>
        <div
          style={{
            backgroundColor: "gray",
          }}
          className="absolute flex items-center justify-center -right-1 bottom-0 p-0.5 rounded-full"
        >
          <img
            src={momento_translate_logo}
            className={"h-5 w-5 bg-white rounded-full"}
            alt="momento-logo"
          />
        </div>

        {isErrored ? (
          <div className="bg-red-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdPriorityHigh className="fill-white h-3 w-3" />
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </Tooltip>
  );
};

const MomentoTranslateBroadcastItem = ({
  name,
  url,
}: {
  name: string;
  url: string;
}) => {
  const { failedRtmpDestinationUrls } = useAppContext();

  const { isErrored, errorMessage } = useMemo(() => {
    const { livekitStreamUrl } = getLivekitStreamUrlFromOriginalRtmpUrl({
      url,
    });

    const found = failedRtmpDestinationUrls?.find(
      ({ url: _url }) => _url === livekitStreamUrl || _url === url
    );

    return { isErrored: !!found, errorMessage: found?.error };
  }, [url, failedRtmpDestinationUrls]);

  return (
    <Tooltip
      title={`Momento Translate (${name})`}
      titlen={
        isErrored && errorMessage ? [`Error: ${errorMessage}`] : undefined
      }
    >
      <div
        className={`border-custom-blue-300 border-2 m-1 rounded-full relative`}
      >
        <div
          className={`bg-custom-blue-600 border-primary m-0.5 rounded-full h-12 w-12 flex items-center justify-center aspect-square p-2 border-2 tooltip cursor-not-allowed`}
          onClick={async () => {}}
        >
          <p className="text-white text-lg font-extrabold">M</p>
        </div>
        <div
          style={{
            backgroundColor: "gray",
          }}
          className="absolute flex items-center justify-center -right-1 bottom-0 p-0.5 rounded-full"
        >
          <img
            src={momento_translate_logo}
            className={"h-5 w-5 bg-white rounded-full"}
            alt="momento-logo"
          />
        </div>

        {isErrored ? (
          <div className="bg-red-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdPriorityHigh className="fill-white h-3 w-3" />
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </Tooltip>
  );
};

const AvailableLiveStreamDestinationItem = ({
  id,
  nickName,
  isSelected,
  type,
  canSelectMoreDestinations,
  setSelectedLiveStreamDestinationsIds,
  streamKey,
  url: streamUrl,
}: {
  id: number;
  nickName: string;
  isSelected: boolean;
  type: string;
  canSelectMoreDestinations: boolean;
  setSelectedLiveStreamDestinationsIds: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  streamKey: string;
  url: string;
}) => {
  const {
    erroredLiveStreamDestinationsIds,
    failedRtmpDestinationUrls,
    rtmpDestinationIdAndUrls,
  } = useAppContext();

  const { isErrored, errorMessage } = useMemo(() => {
    const errFound = erroredLiveStreamDestinationsIds?.find(
      ({ id: _id }) => `${id}` === `${_id}`
    );

    const url =
      type.includes("youtube") ||
      type.includes("facebook") ||
      type.includes("linkedin")
        ? rtmpDestinationIdAndUrls.find(({ id: _id }) => `${id}` === `${_id}`)
            ?.rtmpDestinationUrl
        : createRtmpDestinationUrlFromUrlAndStreamKey({
            streamKey,
            url: streamUrl,
          });

    const { livekitStreamUrl } = getLivekitStreamUrlFromOriginalRtmpUrl({
      url: url as string,
    });

    const errFoundFailed = failedRtmpDestinationUrls?.find(
      ({ url: _url }) => _url === livekitStreamUrl || _url === url
    );

    const isErrored = !!errFound || !!errFoundFailed;

    const errorMessage = errFound?.message || errFoundFailed?.error;

    return { isErrored, errorMessage };
  }, [
    id,
    type,
    streamKey,
    streamUrl,
    rtmpDestinationIdAndUrls,
    failedRtmpDestinationUrls,
    erroredLiveStreamDestinationsIds,
  ]);

  const { backgroundColor, Icon } = useMemo(() => {
    return type.includes("youtube")
      ? { backgroundColor: "rgb(254,1,0)", Icon: Youtube }
      : type.includes("facebook")
      ? { backgroundColor: "#1877f2", Icon: Facebook }
      : type.includes("kick")
      ? { backgroundColor: "#000", Icon: Kick }
      : type.includes("instagram")
      ? { backgroundColor: "#fff", Icon: Instagram }
      : type.includes("twitch")
      ? { backgroundColor: "#6441A4", Icon: Twitch }
      : type.includes("linkedin")
      ? { backgroundColor: "#0A66C2", Icon: LinkedIn, isLinkedIn: true }
      : { backgroundColor: "gray", Icon: MdDns };
  }, [type]);

  return (
    <Tooltip
      titlen={
        isErrored && errorMessage ? [`Error: ${errorMessage}`] : undefined
      }
      key={`broadcasting-destinations-${id}`}
      title={nickName}
    >
      <div
        className={`${
          isSelected ? "border-custom-blue-300" : "border-custom-blue-50"
        } border-2 m-1 rounded-full relative`}
      >
        <div
          className={`${
            isSelected
              ? "bg-custom-blue-600 border-primary"
              : canSelectMoreDestinations
              ? "bg-custom-blue-200 border-transparent"
              : "bg-gray-300"
          } m-0.5 rounded-full h-12 w-12 flex items-center justify-center aspect-square p-2 border-2 tooltip cursor-pointer`}
          onClick={async () => {
            if (isSelected || canSelectMoreDestinations) {
              if (isSelected) {
                setSelectedLiveStreamDestinationsIds((_s) => {
                  const s = [..._s];

                  const arr = s.filter((_id) => _id !== id);

                  return arr;
                });
              } else {
                setSelectedLiveStreamDestinationsIds((_s) => {
                  const s = [..._s];

                  const arr = [...s, id];

                  return arr;
                });
              }
            }
          }}
        >
          <p className="text-white text-lg font-extrabold">{`${nickName[0]}`}</p>
        </div>

        <div
          style={{ backgroundColor }}
          className="absolute flex items-center justify-center -right-1 bottom-0 p-1 rounded-full"
        >
          <Icon className={"fill-white h-4 w-4"} />
        </div>

        {isErrored ? (
          <div className="bg-red-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdPriorityHigh className="fill-white h-3 w-3" />
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </Tooltip>
  );
};

const EditYourBroadcast = () => {
  const {
    selectedLiveStreamDestinationsIds: _selectedLiveStreamDestinationsIds,
    rtmpDestinationIdAndUrls,
  } = useAppContext();

  const {
    selectedAvailableLiveStreamDestinations:
      _selectedAvailableLiveStreamDestinations,

    availableLiveStreamDestinations,
  } = useLiveStreamDestinations();

  const {
    defaultDestination,
    interpretations,
    interpretationsOriginalDestination,
  } = useAppConfigContext();
  const { isFreePlanActive } = useIsFreePlanActive();
  const { isBasicPlanActive } = useIsBasicPlanActive();
  const { activePlanType } = useActivePlanType();

  const [isOpen, setIsOpen] = useState(false);
  const [
    selectedLiveStreamDestinationsIds,
    setSelectedLiveStreamDestinationsIds,
  ] = useState(
    _selectedLiveStreamDestinationsIds
      ? [..._selectedLiveStreamDestinationsIds]
      : []
  );

  const { destinationsToBeRemoved, destinationsToBeAdded } = useMemo(() => {
    const destinationsToBeRemoved = _selectedLiveStreamDestinationsIds.filter(
      (id) => !selectedLiveStreamDestinationsIds.includes(id)
    );
    const destinationsToBeAdded = selectedLiveStreamDestinationsIds.filter(
      (id) => !_selectedLiveStreamDestinationsIds.includes(id)
    );

    return { destinationsToBeRemoved, destinationsToBeAdded };
  }, [selectedLiveStreamDestinationsIds, _selectedLiveStreamDestinationsIds]);

  const handleOpen = () => setIsOpen(true);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const maxStreamingDestinations = useMemo(
    () => businessPlansConfig[activePlanType]?.maxStreamingDestinations || 1,
    [activePlanType]
  );

  const canSelectMoreDestinations = useMemo(() => {
    return selectedLiveStreamDestinationsIds.length < maxStreamingDestinations;
  }, [selectedLiveStreamDestinationsIds, maxStreamingDestinations]);

  const selectedAvailableLiveStreamDestinations = useMemo(
    () =>
      (_selectedAvailableLiveStreamDestinations || []).map((destination) => ({
        ...destination,
        isSelected: !!selectedLiveStreamDestinationsIds.find(
          (id) => id === destination.id
        ),
      })),
    [
      _selectedAvailableLiveStreamDestinations,
      selectedLiveStreamDestinationsIds,
    ]
  );

  const selectedLiveStreamDestinationsIdsRef = useRef(
    selectedLiveStreamDestinationsIds
  );
  const destinationsToBeAddedRef = useRef(destinationsToBeAdded);
  const destinationsToBeRemovedRef = useRef(destinationsToBeRemoved);
  const rtmpDestinationIdAndUrlsRef = useRef(rtmpDestinationIdAndUrls);
  const availableLiveStreamDestinationsRef = useRef(
    availableLiveStreamDestinations
  );

  useEffect(() => {
    selectedLiveStreamDestinationsIdsRef.current =
      selectedLiveStreamDestinationsIds;
  }, [selectedLiveStreamDestinationsIds]);
  useEffect(() => {
    destinationsToBeAddedRef.current = destinationsToBeAdded;
  }, [destinationsToBeAdded]);
  useEffect(() => {
    destinationsToBeRemovedRef.current = destinationsToBeRemoved;
  }, [destinationsToBeRemoved]);
  useEffect(() => {
    rtmpDestinationIdAndUrlsRef.current = rtmpDestinationIdAndUrls;
  }, [rtmpDestinationIdAndUrls]);
  useEffect(() => {
    availableLiveStreamDestinationsRef.current =
      availableLiveStreamDestinations;
  }, [availableLiveStreamDestinations]);

  const {
    editBroadcastDestinations,
    egressesEditing,
    egressesStarting,
    egressesStopping,
  } = useBroadcastEgresses();

  const { resetLiveStreamDestinations } = useResetLiveStreamDestinations();

  return (
    <div>
      <span>
        <button
          disabled={egressesEditing || egressesStarting || egressesStopping}
          onClick={handleOpen}
          className="btn btn-primary text-white normal-case btn-sm gap-2"
        >
          <MdEdit size={20} />
          Edit Broadcast Channels
        </button>
      </span>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          style={{ zIndex: 10 }}
          as="div"
          className="relative"
          onClose={() => {
            setSelectedLiveStreamDestinationsIds(
              _selectedLiveStreamDestinationsIds
            );

            handleCancel();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all border-2 border-custom-blue-900">
                  <Dialog.Title as="h3" className={"flex justify-between"}>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Edit your broadcast
                    </h3>

                    <div className="flex gap-3">
                      <button
                        onClick={() => {
                          setSelectedLiveStreamDestinationsIds(
                            _selectedLiveStreamDestinationsIds
                          );

                          handleCancel();
                        }}
                        className="btn btn-ghost btn-primary btn-sm"
                      >
                        <MdClose size={20} />
                      </button>
                    </div>
                  </Dialog.Title>

                  {defaultDestination ||
                  selectedAvailableLiveStreamDestinations?.length ||
                  interpretationsOriginalDestination ||
                  interpretations?.length ? (
                    <React.Fragment>
                      <div className="mt-2">
                        <p>Select destinations</p>

                        <div className="p-1 flex-wrap flex">
                          {defaultDestination ? (
                            <MomentoLiveBroadcastItem />
                          ) : (
                            <React.Fragment />
                          )}

                          {interpretationsOriginalDestination ? (
                            <MomentoTranslateOriginalBroadcastItem
                              {...{ name: "Original" }}
                            />
                          ) : (
                            <React.Fragment />
                          )}

                          {interpretations?.length ? (
                            interpretations.map(
                              ({ name, rtmpKey, rtmpUrl }, i) => (
                                <MomentoTranslateBroadcastItem
                                  {...{
                                    name,
                                    key: `momento-translate-broadcasting-destinations-${i}`,
                                    url: createRtmpDestinationUrlFromUrlAndStreamKey(
                                      {
                                        streamKey: rtmpKey,
                                        url: rtmpUrl,
                                      }
                                    ),
                                  }}
                                />
                              )
                            )
                          ) : (
                            <React.Fragment />
                          )}

                          {selectedAvailableLiveStreamDestinations?.length ? (
                            selectedAvailableLiveStreamDestinations.map(
                              ({
                                id,
                                nickName,
                                isSelected,
                                type,
                                streamKey,
                                url,
                              }) => {
                                return (
                                  <AvailableLiveStreamDestinationItem
                                    {...{
                                      id,
                                      nickName,
                                      isSelected,
                                      type,
                                      canSelectMoreDestinations,
                                      setSelectedLiveStreamDestinationsIds,
                                      streamKey,
                                      url,
                                    }}
                                    key={`broadcasting-destinations-${id}`}
                                  />
                                );
                              }
                            )
                          ) : (
                            <React.Fragment />
                          )}

                          <Tooltip title={"Add destination"}>
                            <div
                              onClick={() => {}}
                              className={`border-custom-blue-300 border-2 m-1 rounded-full relative`}
                            >
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://app.momentostream.com/destinations"
                              >
                                <div
                                  className={`bg-custom-blue-100 border-custom-blue-200 m-0.5 rounded-full h-12 w-12 flex items-center justify-center aspect-square p-2 border-2 toolti cursor-pointer`}
                                  onClick={async () => {}}
                                >
                                  <MdAdd className="text-primary" size={24} />
                                </div>
                              </a>
                            </div>
                          </Tooltip>
                        </div>
                      </div>

                      {isFreePlanActive || isBasicPlanActive ? (
                        <div className="mt-2">
                          <UpgradeNowLine
                            preText={`You cannot stream to more than ${
                              numberToWord[
                                maxStreamingDestinations as
                                  | 1
                                  | 2
                                  | 3
                                  | 4
                                  | 5
                                  | 6
                                  | 7
                                  | 8
                                  | 9
                                  | 10
                              ]
                            } destination${
                              maxStreamingDestinations === 1 ? "" : "(s)"
                            }! `}
                            postText={" to increase the limit."}
                          />
                        </div>
                      ) : null}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="flex items-center justify-center flex-col mt-3">
                        <p>No destination added</p>
                        <div className="mt-3">
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://app.momentostream.com/destinations"
                          >
                            <button className="btn btn-primary text-white normal-case btn-sm gap-1">
                              <MdAdd size={20} />
                              Add destination
                            </button>
                          </a>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  <div className="flex justify-end">
                    <button
                      onClick={() => {
                        resetLiveStreamDestinations();
                      }}
                      className="btn btn-sm btn-ghost normal-case gap-1"
                    >
                      <MdRefresh size={20} />
                      Refresh list
                    </button>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className={`btn btn-primary text-white w-full normal-case flex gap-3`}
                      onClick={async () => {
                        editBroadcastDestinations({
                          destinationsToBeAdded,
                          destinationsToBeRemoved,
                          selectedLiveStreamDestinationsIds:
                            selectedLiveStreamDestinationsIdsRef.current,
                        });

                        handleCancel();
                      }}
                    >
                      Save changes
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default EditYourBroadcast;
