import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useBackgroundMusic from "../../hooks/activeBrand/useBackgroundMusic";
import validateSpeakerDeviceId from "../../utils/validateSpeakerDeviceId";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { MdVolumeOff } from "react-icons/md";

import { createUID } from "../../utils/createUID";
import useGetCurrentTime from "../../hooks/appState/useGetCurrentTime";
import { msToHMS } from "../../utils/msToHMS";

const BackgroundMusicContainerRendered = ({
  id,
  loop,
  remoteUrl,
  volume: _volume,
  playedAt,
  loopedAt,
}: {
  id: string;
  loop: boolean;
  remoteUrl: string;
  volume: number;
  playedAt: number;
  loopedAt: number;
}) => {
  const [playedPer, setPlayedPer] = useState(0);
  const [muteDueToError, setMuteDueToError] = useState(false);

  const {
    appAudioMuted,
    selectedSpeakerDeviceId,
    isAnyAgoraActiveSpeaker,
    muteOriginalAudioWhenInterpretationActive,
    activeInterpretationOutputAgoraChannelId,
  } = useAppContext();

  const { changeActiveIdAndRemoteUrl } = useBackgroundMusic();

  const audioPlayerId = useMemo(
    () => `BackgroundMusicContainerRendered-audioPlayerId-${createUID()}`,
    []
  );

  const volume = useMemo(
    () =>
      muteDueToError ||
      appAudioMuted ||
      (activeInterpretationOutputAgoraChannelId &&
        muteOriginalAudioWhenInterpretationActive)
        ? 0
        : isAnyAgoraActiveSpeaker
        ? 0.1
        : _volume,
    [
      muteDueToError,
      appAudioMuted,
      _volume,
      isAnyAgoraActiveSpeaker,
      muteOriginalAudioWhenInterpretationActive,
      activeInterpretationOutputAgoraChannelId,
    ]
  );

  const idRef = useRef(id);
  const loopRef = useRef(loop);
  const remoteUrlRef = useRef(remoteUrl);
  const volumeRef = useRef(volume);
  const playedAtRef = useRef(playedAt);
  const loopedAtRef = useRef(loopedAt);
  const audioDurationInSecondsRef = useRef<number | null>(null);
  const playedPerRef = useRef(playedPer);
  const audioPlayerIdRef = useRef(audioPlayerId);
  const appAudioMutedRef = useRef(appAudioMuted);
  const audioMuteDueToError = useRef(false);

  const syncedOnDuration = useRef<boolean | string>(false);
  const syncedOnReady = useRef<boolean | string>(false);

  const { getCurrentTime } = useGetCurrentTime();

  const getVolume = (args?: { volume: number }) => {
    const volume =
      audioMuteDueToError.current || appAudioMutedRef.current
        ? 0
        : typeof args?.volume === "number"
        ? args?.volume
        : volumeRef.current;

    return volume;
  };

  const getCurrentTimeToBeSeeked = async () => {
    const audioDurationInSeconds = audioDurationInSecondsRef.current;

    const playedAt = playedAtRef.current;

    const { currentTime } = await getCurrentTime();

    const playedSeconds = (currentTime - playedAt) / 1000;

    const playedSecondsLatestLoop =
      playedSeconds -
      (audioDurationInSeconds || 0) *
        parseInt(`${(playedSeconds || 0) / (audioDurationInSeconds || 0)}`);

    return playedSecondsLatestLoop;
  };

  const getAudioTag = (): StudioHTMLAudioElement | null => {
    return document.getElementById(
      audioPlayerIdRef.current
    ) as StudioHTMLAudioElement | null;
  };

  const seekAudioToCurrentTime = async (
    audioTag: StudioHTMLAudioElement | null
  ) => {
    audioTag = audioTag || getAudioTag();

    if (audioTag) {
      const currentTimeToBeSeeked = await getCurrentTimeToBeSeeked();

      audioTag.currentTime = currentTimeToBeSeeked;
    }
  };

  const playUsingAudioTag = () => {
    if (audioDurationInSecondsRef.current === null) return;

    const audioTag = getAudioTag();

    if (!audioTag) return;

    const volume = getVolume();

    audioTag.volume = volume;

    audioTag.muted = volume === 0;

    if (audioTag && typeof audioTag.play === "function") {
      // audioTag.playsInline = true;
      const playPromise = audioTag.play();

      // error could occur in this play

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            seekAudioToCurrentTime(audioTag);
          })
          .catch((e) => {
            console.log("play-error", e);

            // have to handle the play failure

            setMuteDueToError(true);

            audioMuteDueToError.current = true;

            const volume = getVolume();

            audioTag.volume = volume;

            audioTag.muted = volume === 0;

            // audioTag.playsInline = true;
            const mutedPlayPromise = audioTag.play();

            if (mutedPlayPromise !== undefined) {
              mutedPlayPromise
                .then(() => {
                  seekAudioToCurrentTime(audioTag);
                })
                .catch((e) => {
                  console.log("play-error-again", e);
                });
            }
          });
      }
    }
  };

  const _handleOnEnded = () => {
    if (loopRef.current) {
      playUsingAudioTag();
    } else {
      setPlayedPer(0);
      changeActiveIdAndRemoteUrl({
        id: null,
        playedAt: null,
        remoteUrl: null,
      });

      syncedOnReady.current = false;
      syncedOnDuration.current = false;
    }
  };

  const _handleProgress = ({ played }: { played: number }) => {
    const playedHMS = msToHMS(
      parseInt(`${(audioDurationInSecondsRef.current || 0) * (played || 0)}`) *
        1000
    );
    const totalHMS = msToHMS(
      parseInt(`${audioDurationInSecondsRef.current || 0}`) * 1000
    );

    const subTitle = `${playedHMS} / ${totalHMS}`;

    appEventEmitter.emit(
      appEventEmitterEvents.BRAND_BACKGROUND_MUSIC_PROGRESS(idRef.current),
      subTitle
    );
  };

  const setSinkIdForAudio = async ({ deviceId }: { deviceId: string }) => {
    const { isValid } = await validateSpeakerDeviceId({ deviceId });

    const audioTag = getAudioTag();

    if (isValid && typeof audioTag?.setSinkId === "function") {
      audioTag.setSinkId(deviceId);
    }
  };

  useEffect(() => {
    setSinkIdForAudio({ deviceId: selectedSpeakerDeviceId as string });
  }, [selectedSpeakerDeviceId]);

  const _handleUserInteractButtonClicked = () => {
    setMuteDueToError(false);

    audioMuteDueToError.current = false;

    const audioTag = getAudioTag();

    if (audioTag) {
      const volume = getVolume();

      audioTag.volume = volume;

      audioTag.muted = volume === 0;
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED,
      _handleUserInteractButtonClicked
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED,
        _handleUserInteractButtonClicked
      );
    };
  }, []);

  useEffect(() => {
    const audioTag = getAudioTag();

    if (audioTag) {
      const _volume = getVolume({ volume });

      audioTag.volume = _volume;

      audioTag.muted = _volume === 0;
    }
  }, [volume, muteDueToError, appAudioMuted]);

  useEffect(() => {
    idRef.current = id;
  }, [id]);
  useEffect(() => {
    loopRef.current = loop;
  }, [loop]);
  useEffect(() => {
    remoteUrlRef.current = remoteUrl;
  }, [remoteUrl]);
  useEffect(() => {
    volumeRef.current = volume;
  }, [volume]);
  useEffect(() => {
    playedAtRef.current = playedAt;
  }, [playedAt]);
  useEffect(() => {
    loopedAtRef.current = loopedAt;
  }, [loopedAt]);
  useEffect(() => {
    playedPerRef.current = playedPer;
  }, [playedPer]);
  useEffect(() => {
    audioPlayerIdRef.current = audioPlayerId;
  }, [audioPlayerId]);
  useEffect(() => {
    appAudioMutedRef.current = appAudioMuted;

    const audioTag = getAudioTag();

    if (audioTag) {
      const volume = getVolume();

      audioTag.volume = volume;

      audioTag.muted = volume === 0;
    }
  }, [appAudioMuted]);

  return (
    <React.Fragment>
      <audio
        playsInline
        // playsinline
        controls={false}
        id={audioPlayerId}
        onEnded={_handleOnEnded}
        src={remoteUrl}
        onDurationChange={(e) => {
          audioDurationInSecondsRef.current = (
            e.target as StudioHTMLAudioElement
          ).duration;

          if (
            syncedOnDuration.current ===
            `${idRef.current}-${playedAtRef.current}`
          )
            return;

          syncedOnDuration.current = `${idRef.current}-${playedAtRef.current}`;

          playUsingAudioTag();
        }}
        onCanPlay={() => {
          if (
            syncedOnReady.current === `${idRef.current}-${playedAtRef.current}`
          )
            return;

          syncedOnReady.current = `${idRef.current}-${playedAtRef.current}`;

          playUsingAudioTag();
        }}
        onTimeUpdate={(e) => {
          const played =
            (e.target as StudioHTMLAudioElement).currentTime /
            (audioDurationInSecondsRef.current || 0);

          setPlayedPer(played);

          _handleProgress({ played });
        }}
        style={{ height: 0, width: 0 }}
      />
      {muteDueToError ? (
        <div className="absolute bottom-3 left-0 right-0 flex items-center justify-center">
          <button
            onClick={() => {
              setMuteDueToError(false);

              appEventEmitter.emit(
                appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED
              );
            }}
            className="btn btn-outline text-white normal-case gap-2 bg-black bg-opacity-40"
          >
            <MdVolumeOff size={20} />
            Tap for sound
          </button>
        </div>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const BackgroundMusicContainer = () => {
  const { backgroundMusic } = useAppContext();

  return backgroundMusic.id && backgroundMusic.playedAt ? (
    <BackgroundMusicContainerRendered
      {...{
        ...(backgroundMusic as {
          id: string;
          loop: boolean;
          remoteUrl: string;
          volume: number;
          playedAt: number;
          loopedAt: number;
        }),
        key: `BackgroundMusicContainerRendered_${backgroundMusic.id}_${backgroundMusic.playedAt}`,
      }}
    />
  ) : (
    <React.Fragment />
  );
};

export default BackgroundMusicContainer;
