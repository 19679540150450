import { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { streamTypes } from "../../listners/appState/MainViewParticipantsListner";
import { getShareEquivalantLayout } from "../../listners/appState/MainViewLayoutListner";
import { mainViewLayouts } from "../../utils/constants";

const useGetCalculatedConferenceModeAutoLayout = () => {
  const {
    gridWithStreamsPosition,
    conferenceAutoMainVideoLayoutEnabled,
    conferenceModeParticipantAutoCountEnabled,
    conferenceMainViewVisibleWebcamStreams,
    mainViewSelectedStreams,
  } = useAppContext();

  const gridWithStreamsPositionRef = useRef(gridWithStreamsPosition);
  const conferenceAutoMainVideoLayoutEnabledRef = useRef(
    conferenceAutoMainVideoLayoutEnabled
  );
  const conferenceModeParticipantAutoCountEnabledRef = useRef(
    conferenceModeParticipantAutoCountEnabled
  );
  const conferenceMainViewVisibleWebcamStreamsRef = useRef(
    conferenceMainViewVisibleWebcamStreams
  );
  const mainViewSelectedStreamsRef = useRef(mainViewSelectedStreams);

  useEffect(() => {
    gridWithStreamsPositionRef.current = gridWithStreamsPosition;
  }, [gridWithStreamsPosition]);
  useEffect(() => {
    conferenceAutoMainVideoLayoutEnabledRef.current =
      conferenceAutoMainVideoLayoutEnabled;
  }, [conferenceAutoMainVideoLayoutEnabled]);
  useEffect(() => {
    conferenceModeParticipantAutoCountEnabledRef.current =
      conferenceModeParticipantAutoCountEnabled;
  }, [conferenceModeParticipantAutoCountEnabled]);
  useEffect(() => {
    conferenceMainViewVisibleWebcamStreamsRef.current =
      conferenceMainViewVisibleWebcamStreams;
  }, [conferenceMainViewVisibleWebcamStreams]);
  useEffect(() => {
    mainViewSelectedStreamsRef.current = mainViewSelectedStreams;
  }, [mainViewSelectedStreams]);

  const getCalculatedConferenceModeAutoLayout = () => {
    const gridWithStreamsPosition = gridWithStreamsPositionRef.current;
    const conferenceModeParticipantAutoCountEnabled =
      conferenceModeParticipantAutoCountEnabledRef.current;
    const conferenceAutoMainVideoLayoutEnabled =
      conferenceAutoMainVideoLayoutEnabledRef.current;
    const conferenceMainViewVisibleWebcamStreams =
      conferenceMainViewVisibleWebcamStreamsRef.current;
    const mainViewSelectedStreams = mainViewSelectedStreamsRef.current;

    const webcamStreamsCount = conferenceModeParticipantAutoCountEnabled
      ? conferenceMainViewVisibleWebcamStreams?.length
      : gridWithStreamsPosition?.filter(({ streamId }) =>
          streamId.includes(streamTypes.WEBCAM)
        )?.length || 0;

    const hasShareStream = conferenceAutoMainVideoLayoutEnabled
      ? !![...mainViewSelectedStreams.keys()].find((key) =>
          key.includes(streamTypes.SHARE)
        )
      : !!gridWithStreamsPosition?.find(({ streamId }) =>
          streamId.includes(streamTypes.SHARE)
        );

    const layoutAccordingToWebcamStreamCount =
      webcamStreamsCount > 4
        ? mainViewLayouts.SPOTLIGHT
        : mainViewLayouts.GROUP;

    if (hasShareStream) {
      if (
        conferenceModeParticipantAutoCountEnabled &&
        conferenceAutoMainVideoLayoutEnabled
      ) {
        if (webcamStreamsCount === 0) {
          return { mainViewLayout: mainViewLayouts.CINEMA };
        } else if (webcamStreamsCount === 1) {
          return { mainViewLayout: mainViewLayouts.PICTURE_IN_PICTURE };
        } else {
          return { mainViewLayout: mainViewLayouts.SCREEN };
        }
      } else {
        return {
          mainViewLayout: getShareEquivalantLayout(
            layoutAccordingToWebcamStreamCount
          ),
        };
      }
    } else {
      return {
        mainViewLayout: layoutAccordingToWebcamStreamCount,
      };
    }
  };

  return { getCalculatedConferenceModeAutoLayout };
};

export default useGetCalculatedConferenceModeAutoLayout;
