// @ts-nocheck

import React, { useEffect, useMemo, useRef, useState } from "react";
import useLivekitRtcLocalParticipantTranslatedAudioStreams from "../../appRtc/livekitRtc/useLivekitRtcLocalParticipantTranslatedAudioStreams";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import sleep from "../../utils/sleep";
import { useAppContext } from "../../contexts/appContextDef";
import generateAgoraRtcConfig from "../../apis/studio/generate-agora-rtc-config";
import { createUID } from "../../utils/createUID";
import ISO6391 from "iso-639-1";

export const useInterpretationViaAiData = () => {
  const { interpretations } = useAppConfigContext();

  const interpretationViaAiData = useMemo(
    () =>
      interpretations
        .map(({ agoraChannelId, shortName, interpretationViaAi }) => ({
          language: ISO6391.getName(
            String(shortName || "").toLowerCase()
          ).toLowerCase(),
          agoraChannelId,
          interpretationViaAi,
        }))
        .filter(
          ({ agoraChannelId, interpretationViaAi, language }) =>
            agoraChannelId && interpretationViaAi && language
        ) as { language: string; agoraChannelId: string }[],
    [interpretations]
  );

  return { interpretationViaAiData };
};

const PlayMediaStreamTrack = ({
  audioMediaStreamTrack,
}: {
  audioMediaStreamTrack: MediaStreamTrack;
}) => {
  const id = useMemo(() => `PlayMediaStreamTrack_${createUID()}`, []);

  useEffect(() => {
    const audioTag = document.getElementById(id) as StudioHTMLAudioElement;

    const mediaStream = new MediaStream();

    mediaStream.addTrack(audioMediaStreamTrack);

    audioTag.srcObject = mediaStream;

    audioTag.muted;

    audioTag.volume = 0;

    // audioTag.muted = false;

    // audioTag.volume = 1;

    audioTag
      .play()
      .then(() => {})
      .catch(() => {});
  }, [audioMediaStreamTrack, id]);

  return <audio id={id} />;
};

const LocalParticipantTranslatedAudioBroadcasterContainer = () => {
  const { localParticipantTranslatedAudioStreams } =
    useLivekitRtcLocalParticipantTranslatedAudioStreams();

  const [agoraRtcSdkLoaded, setAgoraRtcSdkLoaded] = useState(false);

  const agoraRtcSdkLoadedRef = useRef(agoraRtcSdkLoaded);
  const localParticipantTranslatedAudioStreamsRef = useRef(
    localParticipantTranslatedAudioStreams
  );

  useEffect(() => {
    agoraRtcSdkLoadedRef.current = agoraRtcSdkLoaded;
  }, [agoraRtcSdkLoaded]);
  useEffect(() => {
    localParticipantTranslatedAudioStreamsRef.current =
      localParticipantTranslatedAudioStreams;
  }, [localParticipantTranslatedAudioStreams]);

  const languageWiseConnectedAgoraClients = useRef<
    { language: string; agoraClient: object }[]
  >([]);

  const publishedMediaStreamTracks = useRef<
    {
      audioMediaStreamTrack: MediaStreamTrack;
      language: string;
      agoraPublishedTrack?: object;
    }[]
  >([]);

  const { setLocalParticipantAITranslateConnectedAgoraChannelIds } =
    useAppContext();

  const { interpretationViaAiData } = useInterpretationViaAiData();

  const _handleLocalParticipantTranslatedAudioStreamsChanged = async ({
    localParticipantTranslatedAudioStreams,
  }: {
    localParticipantTranslatedAudioStreams: {
      language: string;
      audioMediaStreamTrack: MediaStreamTrack;
    }[];
  }) => {
    for (
      let index = 0;
      index < localParticipantTranslatedAudioStreams.length;
      index++
    ) {
      const { audioMediaStreamTrack, language } =
        localParticipantTranslatedAudioStreams[index];

      const indexFound = publishedMediaStreamTracks.current.findIndex(
        ({ language: _language }) => _language === language
      );

      const agoraClient = languageWiseConnectedAgoraClients.current.find(
        ({ language: _language }) => _language === language
      )?.agoraClient;

      //   const audioTag = document.createElement("audio");

      //   const mediaStream = new MediaStream();

      //   mediaStream.addTrack(audioMediaStreamTrack);

      //   audioTag.srcObject = mediaStream;

      //   audioTag.muted = true;
      //   audioTag.volume = 0;
      //   audioTag
      //     .play()
      //     .then(() => {})
      //     .catch(() => {});

      if (agoraClient) {
        if (indexFound !== -1) {
          if (
            publishedMediaStreamTracks.current[indexFound]
              ?.audioMediaStreamTrack?.id !== audioMediaStreamTrack.id
          ) {
            await agoraClient.unpublish(
              publishedMediaStreamTracks.current[indexFound]
                ?.agoraPublishedTrack
            );

            const agoraPublishedTrack = window.AgoraRTC.createCustomAudioTrack({
              mediaStreamTrack: audioMediaStreamTrack,
            });

            publishedMediaStreamTracks.current[indexFound] = {
              ...publishedMediaStreamTracks.current[indexFound],
              agoraPublishedTrack,
            };

            await agoraClient.publish(agoraPublishedTrack);
          }
        } else {
          const agoraPublishedTrack = window.AgoraRTC.createCustomAudioTrack({
            mediaStreamTrack: audioMediaStreamTrack,
          });

          publishedMediaStreamTracks.current.push({
            agoraPublishedTrack,
            audioMediaStreamTrack,
            language,
          });

          await agoraClient.publish(agoraPublishedTrack);
        }
      }
    }
  };

  useEffect(() => {
    _handleLocalParticipantTranslatedAudioStreamsChanged({
      localParticipantTranslatedAudioStreams,
    });
  }, [localParticipantTranslatedAudioStreams]);

  const loadAgoraRtcSdk = async () => {
    if (agoraRtcSdkLoadedRef.current) {
      return;
    }

    await new Promise((resolve) => {
      const agoraRtcSdkSrc =
        // "https://download.agora.io/sdk/release/AgoraRTC_N-4.19.0.js";
        "https://download.agora.io/sdk/release/AgoraRTC_N-4.20.2.js";

      const script = document.createElement("script");

      script.src = agoraRtcSdkSrc;

      script.addEventListener("load", async () => {
        setAgoraRtcSdkLoaded(true);

        await sleep(500);

        resolve(undefined);
      });

      document.head.appendChild(script);
    });

    return;
  };

  const connectAgoraChannel = async ({
    agoraChannelId,
    language,
  }: {
    agoraChannelId: string;
    language: string;
  }) => {
    await loadAgoraRtcSdk();

    const channelName = agoraChannelId;

    const agoraClient = window.AgoraRTC.createClient({
      mode: "rtc",
      codec: "vp8",
    });

    const { success, data } = await generateAgoraRtcConfig({ channelName });

    if (success && data) {
      const { appId, token } = data;

      await agoraClient.join(appId, channelName, token, null);

      languageWiseConnectedAgoraClients.current.push({ agoraClient, language });

      setLocalParticipantAITranslateConnectedAgoraChannelIds((s) => [
        ...s,
        { agoraChannelId, uid: agoraClient.uid },
      ]);
    }
  };

  const connectAgoraChannels = async (
    interpretationViaAiData: {
      language: string;
      agoraChannelId: string;
    }[]
  ) => {
    for (let index = 0; index < interpretationViaAiData.length; index++) {
      const interpretation = interpretationViaAiData[index];

      const { agoraChannelId, language } = interpretation;

      if (agoraChannelId && language) {
        connectAgoraChannel({ agoraChannelId, language });
      }
    }

    _handleLocalParticipantTranslatedAudioStreamsChanged({
      localParticipantTranslatedAudioStreams:
        localParticipantTranslatedAudioStreamsRef.current,
    });
  };

  useEffect(() => {
    connectAgoraChannels(interpretationViaAiData);
  }, [interpretationViaAiData]);

  return (
    <React.Fragment>
      {localParticipantTranslatedAudioStreams.map(
        ({ audioMediaStreamTrack, language }) => {
          return (
            <PlayMediaStreamTrack
              {...{
                key: `${audioMediaStreamTrack.id}_${language}`,
                audioMediaStreamTrack,
              }}
            />
          );
        }
      )}
    </React.Fragment>
  );
};

export default LocalParticipantTranslatedAudioBroadcasterContainer;
