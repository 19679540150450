import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const updateBroadcastAsset = async ({
  broadcastId,
  fileId,
  name,
  owners,
}: {
  broadcastId: string;
  fileId: string;
  name: string;
  owners: string[];
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/update-broadcast-asset`;

    const body = JSON.stringify({ broadcastId, fileId, name, owner: owners });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default updateBroadcastAsset;
