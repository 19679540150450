import { customBlueColor } from "../../utils/colors";

const SpotlightIconActive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101.223 69.818"
    enableBackground={"new 0 0 101.223 69.818"}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st1{fill:#fff}"}</style>
    <g id="Capa_8">
      <path
        style={{ fill: customBlueColor[900] }}
        d="M70.755 25.275c-4.368 0-7.91 2.661-7.91 5.945h15.82c0-3.283-3.541-5.945-7.91-5.945z"
      />
      <circle
        style={{ fill: customBlueColor[900] }}
        cx={70.755}
        cy={18.204}
        r={4.994}
      />
      <path
        style={{ fill: customBlueColor[900] }}
        d="M70.755 50.663c-4.368 0-7.91 2.661-7.91 5.945h15.82c0-3.283-3.541-5.945-7.91-5.945z"
      />
      <circle
        style={{ fill: customBlueColor[900] }}
        cx={70.755}
        cy={43.591}
        r={4.994}
      />
      <path
        style={{ fill: customBlueColor[900] }}
        d="M34.623 39.577c-6.663 0-12.065 4.06-12.065 9.067h24.13c0-5.007-5.402-9.067-12.065-9.067z"
      />
      <circle
        style={{ fill: customBlueColor[900] }}
        cx={34.623}
        cy={28.79}
        r={7.617}
      />
    </g>
  </svg>
);

export default SpotlightIconActive;
