// import { usePubSub } from "@videosdk.live/react-sdk";
import React from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const mainViewSoloModeSelectedParticipantIdActions = {
  SELECT: "SELECT",
  DESELECT: "DESELECT",
};

const MainViewSoloModeSelectedParticipantIdListner = () => {
  const { setMainViewSoloModeSelectedParticipantId } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.MAIN_VIEW_SOLO_MODE_SELECTED_PARTICIPANT_ID,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setMainViewSoloModeSelectedParticipantId(
        message.action === mainViewSoloModeSelectedParticipantIdActions.SELECT
          ? message.payload.participantId
          : null
      );
    }
  );

  return <React.Fragment />;
};

export default MainViewSoloModeSelectedParticipantIdListner;
