import React, { useEffect, useMemo, useRef } from "react";
import createYoutubeBroadcast from "../../apis/broadcasts/create-youtube-broadcast";
import createFacebookBroadcast from "../../apis/broadcasts/create-facebook-broadcast";
import createLinkedinBroadcast from "../../apis/broadcasts/create-linkedin-broadcast";
import { useAppContext } from "../../contexts/appContextDef";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useLiveStreamDestinations from "../../hooks/appState/useLiveStreamDestinations";
import useIsOldest from "../../hooks/appState/useIsOldest";
import getBroadcastEgresses from "../../apis/egress/getBroadcastEgresses";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import createLinkedinPost from "../../apis/broadcasts/create-linkedin-post";
import sleep from "../../utils/sleep";
import startBroadcastEgresses from "../../apis/egress/startBroadcastEgresses";
import stopBroadcastEgresses from "../../apis/egress/stopBroadcastEgresses";
import updateRtmpLivestreamDestinations from "../../apis/egress/updateRtmpLivestreamDestinations";
import updateBroadcastDestinations from "../../apis/broadcasts/update-broadcast-destinations";
import useBroadcastEgresses from "../../hooks/appState/useBroadcastEgresses";
import getFailedRtmpDestinationUrls from "../../apis/egress/getFailedRtmpDestinationUrls";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

export const createRtmpDestinationUrlFromUrlAndStreamKey = ({
  url,
  streamKey,
}: {
  url: string;
  streamKey: string;
}) => {
  return url && streamKey
    ? `${url}${url[url.length - 1] === "/" ? "" : "/"}${streamKey}`
    : "";
};

const BroadcastEgressesListner = () => {
  const {
    erroredLiveStreamDestinationsIds,
    egresses,
    setEgresses,
    appMode,
    rtmpDestinationIdAndUrls,
    setErroredLiveStreamDestinationsIds,
    setStartingEgresses,
    setStoppingEgresses,
    setEditingEgresses,
    setSelectedLiveStreamDestinationsIds,
    egressesStartRetryingCountdown,
    setEgressesStartRetryingCountdown,
    setRtmpDestinationIdAndUrls,
    setEgressesProgressState,
    failedRtmpDestinationUrls,
    setFailedRtmpDestinationUrls,
    signalingRoomInfo,
  } = useAppContext();

  const {
    // token,
    broadcastId,
    defaultDestination,
    interpretationsOriginalDestination,
    interpretations,
    userId,
  } = useAppConfigContext();

  const { getIsOldestHost } = useIsOldest();

  const { egressesStarted } = useBroadcastEgresses();

  const egressesRef = useRef(egresses);
  const appModeRef = useRef(appMode);
  const egressesStartedRef = useRef(egressesStarted);
  const interpretationsRef = useRef(interpretations);
  const userIdRef = useRef(userId);
  const failedRtmpDestinationUrlsRef = useRef(failedRtmpDestinationUrls);
  const signalingRoomInfoRef = useRef(signalingRoomInfo);

  useEffect(() => {
    egressesRef.current = egresses;
  }, [egresses]);
  useEffect(() => {
    appModeRef.current = appMode;
  }, [appMode]);
  useEffect(() => {
    egressesStartedRef.current = egressesStarted;
  }, [egressesStarted]);
  useEffect(() => {
    interpretationsRef.current = interpretations;
  }, [interpretations]);
  useEffect(() => {
    userIdRef.current = userId;
  }, [userId]);
  useEffect(() => {
    failedRtmpDestinationUrlsRef.current = failedRtmpDestinationUrls;
  }, [failedRtmpDestinationUrls]);
  useEffect(() => {
    signalingRoomInfoRef.current = signalingRoomInfo;
  }, [signalingRoomInfo]);

  const egressStateIntervalRef = useRef<null | ReturnType<
    typeof setInterval
  >>();

  const {
    selectedLiveStreamDestinations: _selectedLiveStreamDestinations,
    availableLiveStreamDestinations,
  } = useLiveStreamDestinations();

  const selectedLiveStreamDestinations = useMemo(() => {
    const selectedLiveStreamDestinations = [];

    if (_selectedLiveStreamDestinations?.length) {
      selectedLiveStreamDestinations.push(..._selectedLiveStreamDestinations);
    }

    if (defaultDestination?.streamKey && defaultDestination?.streamUrl) {
      selectedLiveStreamDestinations.push({
        streamKey: defaultDestination.streamKey,
        url: defaultDestination.streamUrl,
      });
    }

    if (
      interpretationsOriginalDestination?.rtmpKey &&
      interpretationsOriginalDestination?.rtmpUrl
    ) {
      selectedLiveStreamDestinations.push({
        streamKey: interpretationsOriginalDestination?.rtmpKey,
        url: interpretationsOriginalDestination?.rtmpUrl,
      });
    }

    return selectedLiveStreamDestinations;
  }, [
    _selectedLiveStreamDestinations,
    defaultDestination,
    interpretationsOriginalDestination,
  ]);

  useAppSingalingSubscribe(
    appPubSubTopics.SELECTED_LIVE_STREAM_DESTINATIONS_IDS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { selectedLiveStreamDestinationsIds } = message;

      setSelectedLiveStreamDestinationsIds(selectedLiveStreamDestinationsIds);
    }
  );

  const { publish: selectedLiveStreamDestinationsIdsPublish } =
    useAppSingalingPublish(
      appPubSubTopics.SELECTED_LIVE_STREAM_DESTINATIONS_IDS
    );

  useAppSingalingSubscribe(
    appPubSubTopics.EGRESSES_PROGRESS_STATE,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const {
        starting,
        started,
        startingWithOnlyRecording,
        startedWithOnlyRecording,
      } = message;

      setEgressesProgressState({
        starting,
        started,
        startingWithOnlyRecording,
        startedWithOnlyRecording,
      });
    }
  );

  const { publish: egressesProgressStatePublish } = useAppSingalingPublish(
    appPubSubTopics.EGRESSES_PROGRESS_STATE
  );

  useAppSingalingSubscribe(
    appPubSubTopics.STARTING_EGRESSES,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setStartingEgresses(message.startingEgresses);
    }
  );

  const { publish: startingEgressesPublish } = useAppSingalingPublish(
    appPubSubTopics.STARTING_EGRESSES
  );

  useAppSingalingSubscribe(
    appPubSubTopics.STOPPING_EGRESSES,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setStoppingEgresses(message.stoppingEgresses);
    }
  );

  const { publish: stoppingEgressesPublish } = useAppSingalingPublish(
    appPubSubTopics.STOPPING_EGRESSES
  );

  useAppSingalingSubscribe(
    appPubSubTopics.EDITING_EGRESSES,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setEditingEgresses(message.editingEgresses);
    }
  );

  const { publish: editingEgressesPublish } = useAppSingalingPublish(
    appPubSubTopics.EDITING_EGRESSES
  );

  useAppSingalingSubscribe(
    appPubSubTopics.RTMP_DESTINATION_ID_AND_URLS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setRtmpDestinationIdAndUrls(message.rtmpDestinationIdAndUrls);
    }
  );

  const { publish: rtmpDestinationIdAndUrlsPublish } = useAppSingalingPublish(
    appPubSubTopics.RTMP_DESTINATION_ID_AND_URLS
  );

  // const tokenRef = useRef(token);
  const broadcastIdRef = useRef(broadcastId);
  const egressesStartRetryingCountdownRef = useRef(
    egressesStartRetryingCountdown
  );
  const selectedLiveStreamDestinationsRef = useRef(
    selectedLiveStreamDestinations
  );
  const erroredLiveStreamDestinationsIdsRef = useRef(
    erroredLiveStreamDestinationsIds
  );

  const rtmpDestinationIdAndUrlsRef = useRef(rtmpDestinationIdAndUrls);
  const availableLiveStreamDestinationsRef = useRef(
    availableLiveStreamDestinations
  );

  const startingEgressesPublishRef = useRef<appSingalingPublishType>(
    startingEgressesPublish
  );
  const stoppingEgressesPublishRef = useRef<appSingalingPublishType>(
    stoppingEgressesPublish
  );
  const editingEgressesPublishRef = useRef<appSingalingPublishType>(
    editingEgressesPublish
  );
  const egressesProgressStatePublishRef = useRef<appSingalingPublishType>(
    egressesProgressStatePublish
  );

  useEffect(() => {
    rtmpDestinationIdAndUrlsRef.current = rtmpDestinationIdAndUrls;
  }, [rtmpDestinationIdAndUrls]);
  useEffect(() => {
    availableLiveStreamDestinationsRef.current =
      availableLiveStreamDestinations;
  }, [availableLiveStreamDestinations]);

  // useEffect(() => {
  //   tokenRef.current = token;
  // }, [token]);
  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);
  useEffect(() => {
    egressesStartRetryingCountdownRef.current = egressesStartRetryingCountdown;
  }, [egressesStartRetryingCountdown]);
  useEffect(() => {
    selectedLiveStreamDestinationsRef.current = selectedLiveStreamDestinations;
  }, [selectedLiveStreamDestinations]);
  useEffect(() => {
    erroredLiveStreamDestinationsIdsRef.current =
      erroredLiveStreamDestinationsIds;
  }, [erroredLiveStreamDestinationsIds]);
  useEffect(() => {
    startingEgressesPublishRef.current = startingEgressesPublish;
  }, [startingEgressesPublish]);
  useEffect(() => {
    stoppingEgressesPublishRef.current = stoppingEgressesPublish;
  }, [stoppingEgressesPublish]);
  useEffect(() => {
    editingEgressesPublishRef.current = editingEgressesPublish;
  }, [editingEgressesPublish]);
  useEffect(() => {
    egressesProgressStatePublishRef.current = egressesProgressStatePublish;
  }, [egressesProgressStatePublish]);

  //

  useAppSingalingSubscribe(
    appPubSubTopics.ERRORED_LIVESTREAM_DESTINATIONS_IDS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { push, pop, message: errorMessage, id } = message;

      if (pop || push) {
        setErroredLiveStreamDestinationsIds((s) => {
          const filtered = [...s].filter(({ id: _id }) => `${_id}` !== `${id}`);

          if (push) {
            filtered.push({ message: errorMessage, id });
          }

          return [...filtered];
        });
      }
    }
  );

  const { publish: erroredLivestreamDestinationsIdsPublish } =
    useAppSingalingPublish(appPubSubTopics.ERRORED_LIVESTREAM_DESTINATIONS_IDS);

  const _handleWaitForEgressesToStarted = async ({
    egresses,
    egressesCountNotMatchingCount: _egressesCountNotMatchingCount,
  }: {
    egresses: egressesType;
    egressesCountNotMatchingCount?: number;
  }): Promise<{ success: boolean; shoulNotdRetryAfterFailed?: boolean }> => {
    let egressesCountNotMatchingCount = _egressesCountNotMatchingCount || 0;

    if (egresses?.length !== egressesRef.current?.length) {
      egressesCountNotMatchingCount += 1;
    }

    if (egressesCountNotMatchingCount === 3) {
      return { success: false, shoulNotdRetryAfterFailed: true };
    }

    const allEgressesStarted =
      egresses.filter(
        ({ egressId }) =>
          egressesRef.current.find(
            ({ egressId: _egressId }) => egressId === _egressId
          )?.status === "STARTED"
      )?.length === egresses?.length;

    if (allEgressesStarted) {
      return { success: true };
    } else {
      await sleep(1000);

      return _handleWaitForEgressesToStarted({
        egresses,
        egressesCountNotMatchingCount,
      });
    }
  };

  const _handleWaitForCreateLinkedinPost = async ({
    destinationId,
  }: {
    destinationId: number;
  }): Promise<{ success: boolean }> => {
    const { success } = await createLinkedinPost({
      destinationId,
      broadcastId: broadcastIdRef.current,
    });

    if (success) {
      return { success };
    } else {
      await sleep(10000);

      return _handleWaitForCreateLinkedinPost({ destinationId });
    }
  };

  const _handleWaitForCreateLinkedinPostUsingDestinationIds = async ({
    destinationIds,
  }: {
    destinationIds: number[];
  }) => {
    for (let index = 0; index < destinationIds.length; index++) {
      const destinationId = destinationIds[index];

      await _handleWaitForCreateLinkedinPost({ destinationId });
    }
  };

  const getRtmpDestinationIdAndUrls = async ({
    liveStreamDestinations,
  }: {
    liveStreamDestinations:
      | {
          id: number;
          nickName: string;
          streamKey: string;
          url: string;
          type: string;
        }[];
  }) => {
    const rtmpDestinationIdAndUrls = [];
    const linkedinLivestreamDestinationIds = [];

    for (let index = 0; index < liveStreamDestinations.length; index++) {
      const destination = liveStreamDestinations[index];

      const { id, streamKey, url, type } = destination;

      const erroredLiveStreamDestinationsIds =
        erroredLiveStreamDestinationsIdsRef.current;

      const isErrored = !!erroredLiveStreamDestinationsIds?.find(
        ({ id: _id }) => `${_id}` === `${id}`
      );

      if (
        typeof type === "string" &&
        (type.includes("youtube") ||
          type.includes("facebook") ||
          type.includes("linkedin"))
      ) {
        if (type.includes("youtube")) {
          const {
            success: successCreateYoutubeBroadcast,
            data: dataCreateYoutubeBroadcast,
            // message: messageCreateYoutubeBroadcast,
          } = await createYoutubeBroadcast({
            destinationId: id,
          });

          if (successCreateYoutubeBroadcast) {
            const { streamUrl, streamKey } = dataCreateYoutubeBroadcast;

            rtmpDestinationIdAndUrls.push({
              id,
              rtmpDestinationUrl: createRtmpDestinationUrlFromUrlAndStreamKey({
                streamKey,
                url: streamUrl,
              }),
            });

            if (isErrored) {
              erroredLivestreamDestinationsIdsPublish(
                JSON.stringify({ pop: true, id: `${id}` })
                // { persist: true }
              );
            }
          } else {
            erroredLivestreamDestinationsIdsPublish(
              JSON.stringify({
                push: true,
                id: `${id}`,
                message: "Error occured while creating Youtube broadcast",
              })
              // { persist: true }
            );
          }
        } else if (type.includes("facebook")) {
          const {
            success: successCreateFacebookBroadcast,
            data: dataCreateFacebookBroadcast,
            // message: messageCreateFacebookBroadcast,
          } = await createFacebookBroadcast({
            destinationId: id,
          });

          if (successCreateFacebookBroadcast) {
            const { streamUrl, streamKey } = dataCreateFacebookBroadcast;

            rtmpDestinationIdAndUrls.push({
              id,
              rtmpDestinationUrl: createRtmpDestinationUrlFromUrlAndStreamKey({
                streamKey,
                url: streamUrl,
              }),
            });

            if (isErrored) {
              erroredLivestreamDestinationsIdsPublish(
                JSON.stringify({ pop: true, id: `${id}` })
                // { persist: true }
              );
            }
          } else {
            erroredLivestreamDestinationsIdsPublish(
              JSON.stringify({
                push: true,
                id: `${id}`,
                message: "Error occured while creating Facebook broadcast",
              })
              // { persist: true }
            );
          }
        } else if (type.includes("linkedin")) {
          const {
            success: successCreateLinkedinBroadcast,
            data: dataCreateLinkedinBroadcast,
            // message: messageCreateLinkedinBroadcast,
          } = await createLinkedinBroadcast({
            destinationId: id,
            broadcastId: broadcastIdRef.current,
          });

          if (successCreateLinkedinBroadcast) {
            const { streamUrl, streamKey } = dataCreateLinkedinBroadcast;

            rtmpDestinationIdAndUrls.push({
              id,
              rtmpDestinationUrl: createRtmpDestinationUrlFromUrlAndStreamKey({
                streamKey,
                url: streamUrl,
              }),
            });

            linkedinLivestreamDestinationIds.push(id);

            if (isErrored) {
              erroredLivestreamDestinationsIdsPublish(
                JSON.stringify({ pop: true, id: `${id}` })
              );
            }
          } else {
            erroredLivestreamDestinationsIdsPublish(
              JSON.stringify({
                push: true,
                id: `${id}`,
                message: "Error occured while creating Linkedin broadcast",
              })
            );
          }
        }
      } else {
        rtmpDestinationIdAndUrls.push({
          id,
          rtmpDestinationUrl: createRtmpDestinationUrlFromUrlAndStreamKey({
            streamKey,
            url,
          }),
        });
      }
    }

    return { rtmpDestinationIdAndUrls, linkedinLivestreamDestinationIds };
  };

  useAppSingalingSubscribe(
    appPubSubTopics.EGRESS_STATE,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setEgresses(message.egresses);
    }
  );

  const { publish: egressStatePublish } = useAppSingalingPublish(
    appPubSubTopics.EGRESS_STATE
  );

  useAppSingalingSubscribe(
    appPubSubTopics.FAILED_RTMP_DESTINATION_URLS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setFailedRtmpDestinationUrls(message.failedRtmpDestinationUrls);
    }
  );

  const { publish: failedRtmpDestinationUrlsPublish } = useAppSingalingPublish(
    appPubSubTopics.FAILED_RTMP_DESTINATION_URLS
  );

  const egressStatePublishRef =
    useRef<appSingalingPublishType>(egressStatePublish);
  const failedRtmpDestinationUrlsPublishRef = useRef<appSingalingPublishType>(
    failedRtmpDestinationUrlsPublish
  );

  useEffect(() => {
    egressStatePublishRef.current = egressStatePublish;
  }, [egressStatePublish]);
  useEffect(() => {
    failedRtmpDestinationUrlsPublishRef.current =
      failedRtmpDestinationUrlsPublish;
  }, [failedRtmpDestinationUrlsPublish]);

  const resetEgressesState = async () => {
    const { success, egresses } = await getBroadcastEgresses({
      broadcastId: broadcastIdRef.current,
    });

    if (success && egresses) {
      if (
        egressesRef.current.filter(
          ({ egressId, status, rtmpLivestream }) =>
            !egresses.find(
              ({
                egressId: _egressId,
                status: _status,
                rtmpLivestream: _rtmpLivestream,
              }) =>
                _egressId === egressId &&
                _status === status &&
                rtmpLivestream?.activeRtmpDestinationUrls?.join(",") ===
                  _rtmpLivestream?.activeRtmpDestinationUrls?.join(",")
            )
        ).length ||
        egresses.filter(
          ({ egressId, status, rtmpLivestream }) =>
            !egressesRef.current.find(
              ({
                egressId: _egressId,
                status: _status,
                rtmpLivestream: _rtmpLivestream,
              }) =>
                _egressId === egressId &&
                _status === status &&
                rtmpLivestream?.activeRtmpDestinationUrls?.join(",") ===
                  _rtmpLivestream?.activeRtmpDestinationUrls?.join(",")
            )
        ).length
      ) {
        egressStatePublishRef.current(
          JSON.stringify({
            egresses: egresses.map(
              ({
                egressId,
                status,
                streamAudioType,
                interpretationLanguageCode,
                egressType,
                recording,
                rtmpLivestream,
                initializedAt,
                activatedAt,
                participantId,
              }) => ({
                egressId,
                status,
                streamAudioType,
                interpretationLanguageCode,
                egressType,
                recording: { enabled: !!recording?.enabled },
                rtmpLivestream: {
                  enabled: !!rtmpLivestream?.enabled,
                  activeRtmpDestinationUrls:
                    rtmpLivestream?.activeRtmpDestinationUrls,
                },
                initializedAt,
                activatedAt,
                participantId,
              })
            ),
          })
        );
      }
    }
  };

  const resetFailedRtmpDestinationUrlsState = async () => {
    const { success, failedRtmpDestinationUrls } =
      await getFailedRtmpDestinationUrls({ userId: userIdRef.current });

    if (success && failedRtmpDestinationUrls) {
      if (
        failedRtmpDestinationUrlsRef.current.filter(
          ({ url }) =>
            !failedRtmpDestinationUrls.find(({ url: _url }) => _url === url)
        )?.length ||
        failedRtmpDestinationUrls.filter(
          ({ url }) =>
            !failedRtmpDestinationUrlsRef.current.find(
              ({ url: _url }) => _url === url
            )
        )?.length
      ) {
        failedRtmpDestinationUrlsPublishRef.current(
          JSON.stringify({ failedRtmpDestinationUrls })
        );
      }
    }
  };

  const initInterval = () => {
    egressStateIntervalRef.current = setInterval(() => {
      const { isOldestHost } = getIsOldestHost();

      if (isOldestHost) {
        resetEgressesState();
        resetFailedRtmpDestinationUrlsState();
      }
    }, 2500);
  };

  const _handleStartBroadcastEgresses = async ({
    recordingEnabled,
  }: {
    recordingEnabled: boolean;
  }) => {
    startingEgressesPublishRef.current(
      JSON.stringify({ startingEgresses: true })
    );

    let success = false;
    let shoulNotdRetryAfterFailed: boolean | null | undefined = false;

    try {
      const { rtmpDestinationIdAndUrls, linkedinLivestreamDestinationIds } =
        await getRtmpDestinationIdAndUrls({
          liveStreamDestinations: selectedLiveStreamDestinationsRef.current as {
            id: number;
            nickName: string;
            streamKey: string;
            url: string;
            type: string;
          }[],
        });

      if (
        rtmpDestinationIdAndUrls.length ===
          selectedLiveStreamDestinationsRef.current?.length ||
        recordingEnabled
      ) {
        egressesProgressStatePublishRef.current(
          JSON.stringify({
            starting: true,
            started: false,
            startingWithOnlyRecording: !rtmpDestinationIdAndUrls.length,
            startedWithOnlyRecording: false,
          })
        );

        const { success: startBroadcastEgressesSuccess, egresses } =
          await startBroadcastEgresses({
            broadcastId: broadcastIdRef.current,
            recordingEnabled,
            rtmpLivestreamEnabled: true,
            interpretations:
              interpretationsRef.current?.map(
                ({ shortName, rtmpKey, rtmpUrl }) => ({
                  languageCode: shortName as string,
                  rtmpDestinationUrl:
                    createRtmpDestinationUrlFromUrlAndStreamKey({
                      streamKey: rtmpKey,
                      url: rtmpUrl,
                    }),
                })
              ) || [],
            rtmpDestinationUrls: rtmpDestinationIdAndUrls.map(
              ({ rtmpDestinationUrl }) => rtmpDestinationUrl
            ),
            livekitRoomSid: signalingRoomInfoRef.current.roomId,
            userId: userIdRef.current,
          });

        if (startBroadcastEgressesSuccess) {
          rtmpDestinationIdAndUrlsPublish(
            JSON.stringify({ rtmpDestinationIdAndUrls })
          );

          for (let index = 0; index < egresses.length; index++) {
            const egress = egresses[index];

            if (egress?.participantId) {
              await sleep(100);
            }
          }

          resetEgressesState();

          const {
            success: egressesStartedSuccess,
            shoulNotdRetryAfterFailed: _shoulNotdRetryAfterFailed,
          } = await _handleWaitForEgressesToStarted({ egresses });

          shoulNotdRetryAfterFailed = _shoulNotdRetryAfterFailed;

          if (egressesStartedSuccess) {
            _handleWaitForCreateLinkedinPostUsingDestinationIds({
              destinationIds: linkedinLivestreamDestinationIds,
            });

            success = true;

            egressesProgressStatePublishRef.current(
              JSON.stringify({
                starting: false,
                started: true,
                startingWithOnlyRecording: false,
                startedWithOnlyRecording: !rtmpDestinationIdAndUrls.length,
              })
            );
          } else {
            egressesProgressStatePublishRef.current(
              JSON.stringify({
                starting: false,
                started: false,
                startingWithOnlyRecording: false,
                startedWithOnlyRecording: false,
              })
            );
          }
        }
      } else {
        appEventEmitter.emit(
          appEventEmitterEvents.START_EGRESSES_FAILED_DUE_TO_NO_OUTPUTS
        );
      }
    } catch (error) {
      //
    }

    startingEgressesPublishRef.current(
      JSON.stringify({ startingEgresses: false })
    );

    return { success, shoulNotdRetryAfterFailed };
  };

  const _handleEditBroadcastDestinations = async ({
    selectedLiveStreamDestinationsIds,
    destinationsToBeAdded,
    destinationsToBeRemoved,
  }: {
    selectedLiveStreamDestinationsIds: number[];
    destinationsToBeAdded: number[];
    destinationsToBeRemoved: number[];
  }) => {
    editingEgressesPublishRef.current(
      JSON.stringify({ editingEgresses: true })
    );

    try {
      if (egressesStartedRef.current) {
        const destinationsToBeAddedWithMetadata = destinationsToBeAdded.map(
          (id) =>
            availableLiveStreamDestinationsRef.current.find(
              ({ id: _id }) => `${id}` === `${_id}`
            ) as {
              id: number;
              nickName: string;
              streamKey: string;
              url: string;
              type: string;
            }
        );

        const rtmpDestinationUrlsToBeRemoved = destinationsToBeRemoved.map(
          (id) =>
            rtmpDestinationIdAndUrlsRef.current.find(
              ({ id: _id }) => `${id}` === `${_id}`
            )?.rtmpDestinationUrl as string
        );

        const {
          rtmpDestinationIdAndUrls: rtmpDestinationUrlsToBeAdded,
          linkedinLivestreamDestinationIds,
        } = await getRtmpDestinationIdAndUrls({
          liveStreamDestinations: destinationsToBeAddedWithMetadata,
        });

        await updateRtmpLivestreamDestinations({
          broadcastId,
          rtmpDestinationUrlsToBeAdded: rtmpDestinationUrlsToBeAdded.map(
            ({ rtmpDestinationUrl }) => rtmpDestinationUrl
          ),
          rtmpDestinationUrlsToBeRemoved,
        });

        _handleWaitForCreateLinkedinPostUsingDestinationIds({
          destinationIds: linkedinLivestreamDestinationIds,
        });

        const rtmpDestinationIdAndUrls = rtmpDestinationIdAndUrlsRef.current;

        const newRtmpDestinationIdAndUrls = [
          ...[...rtmpDestinationIdAndUrls].filter(
            ({ id }) =>
              !destinationsToBeRemoved.find((_id) => `${_id}` === `${id}`)
          ),
          ...rtmpDestinationUrlsToBeAdded,
        ];

        rtmpDestinationIdAndUrlsPublish(
          JSON.stringify({
            rtmpDestinationIdAndUrls: newRtmpDestinationIdAndUrls,
          })
        );

        resetEgressesState();
      }

      await updateBroadcastDestinations({
        broadcastId: broadcastIdRef.current,
        destinations: selectedLiveStreamDestinationsIds,
      });

      selectedLiveStreamDestinationsIdsPublish(
        JSON.stringify({
          selectedLiveStreamDestinationsIds,
        })
      );
    } catch (error) {
      //
    }

    editingEgressesPublishRef.current(
      JSON.stringify({ editingEgresses: false })
    );
  };

  const _handleStopBroadcastEgresses = async () => {
    stoppingEgressesPublishRef.current(
      JSON.stringify({ stoppingEgresses: true })
    );

    try {
      await stopBroadcastEgresses({ broadcastId: broadcastIdRef.current });

      resetEgressesState();
    } catch (error) {
      //
    }

    stoppingEgressesPublishRef.current(
      JSON.stringify({ stoppingEgresses: false })
    );
  };

  const initStartEgressesCountdown = async () => {
    setEgressesStartRetryingCountdown({ active: true, countdown: 10 });

    await new Promise((resolve) => {
      const interval = setInterval(() => {
        const egressesStartRetryingCountdown =
          egressesStartRetryingCountdownRef.current;

        const countdown = egressesStartRetryingCountdown.countdown - 1;

        if (countdown === 0) {
          clearInterval(interval);

          resolve(undefined);
        }

        setEgressesStartRetryingCountdown({
          active: countdown !== 0,
          countdown,
        });
      }, 1000);
    });
  };

  const _handleStartBroadcastEgressesUntilSuccess = async ({
    recordingEnabled,
  }: {
    recordingEnabled: boolean;
  }) => {
    let tries = 0;

    const maxTries = 5;

    let isSucceeded = false;

    let shoulNotdRetryAfterFailed;

    do {
      if (tries > 0) {
        await initStartEgressesCountdown();
      }

      if (egressesStartedRef.current) {
        isSucceeded = true;
      } else {
        const {
          success,
          shoulNotdRetryAfterFailed: _shoulNotdRetryAfterFailed,
        } = await _handleStartBroadcastEgresses({
          recordingEnabled,
        });

        shoulNotdRetryAfterFailed = _shoulNotdRetryAfterFailed;

        isSucceeded = success;
      }

      tries += 1;
    } while (tries < maxTries && !isSucceeded && !shoulNotdRetryAfterFailed);

    return { isSucceeded };
  };

  const _handlePublishEgressesProgressState = ({
    starting,
    started,
    startingWithOnlyRecording,
    startedWithOnlyRecording,
  }: {
    starting: boolean;
    started: boolean;
    startingWithOnlyRecording: boolean;
    startedWithOnlyRecording: boolean;
  }) => {
    egressesProgressStatePublishRef.current(
      JSON.stringify({
        starting,
        started,
        startingWithOnlyRecording,
        startedWithOnlyRecording,
      })
      // { persist: false }
    );
  };

  useEffect(() => {
    initInterval();

    appEventEmitter.on(
      appEventEmitterEvents.START_BROADCAST_EGRESSES,
      _handleStartBroadcastEgressesUntilSuccess
    );

    appEventEmitter.on(
      appEventEmitterEvents.EDIT_BROADCAST_DESTINATIONS,
      _handleEditBroadcastDestinations
    );

    appEventEmitter.on(
      appEventEmitterEvents.STOP_BROADCAST_EGRESSES,
      _handleStopBroadcastEgresses
    );

    appEventEmitter.on(
      appEventEmitterEvents.PUBLISH_EGRESSES_PROGRESS_STATE,
      _handlePublishEgressesProgressState
    );

    return () => {
      if (egressStateIntervalRef.current) {
        clearInterval(egressStateIntervalRef.current);
      }

      appEventEmitter.off(
        appEventEmitterEvents.START_BROADCAST_EGRESSES,
        _handleStartBroadcastEgressesUntilSuccess
      );

      appEventEmitter.off(
        appEventEmitterEvents.EDIT_BROADCAST_DESTINATIONS,
        _handleEditBroadcastDestinations
      );

      appEventEmitter.off(
        appEventEmitterEvents.STOP_BROADCAST_EGRESSES,
        _handleStopBroadcastEgresses
      );

      appEventEmitter.off(
        appEventEmitterEvents.PUBLISH_EGRESSES_PROGRESS_STATE,
        _handlePublishEgressesProgressState
      );
    };
  }, []);

  return <React.Fragment />;
};

export default BroadcastEgressesListner;
