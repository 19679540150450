import React, { createRef, useEffect, useRef, useState } from "react";
import { MdDelete, MdEdit, MdMoreVert } from "react-icons/md";
import { Draggable } from "react-beautiful-dnd";
import Modal from "../../../components/Modal";
import { primaryColor } from "../../../utils/colors";
import useDownloadPart from "../../../hooks/appState/useDownloadPart";
import { appEventEmitter } from "../../../utils/appEventEmitter";
import { Menu, MenuItem } from "@szhsin/react-menu";

const BrandAssetsDraggableItem = ({
  index,
  onDelete,
  isActive,
  onPlay,
  onPause,
  name,
  id,
  onRename,
  landscapeThumbnail,
  ThumbnailIcon,
  thumbnailImage,
  isDragDisabled,
  downloadPartEnabled,
  remoteUrl,
  subTitleEnabled,
  subTitleTopic,
}: {
  index: number;
  onDelete?: (() => void) | null;
  isActive: boolean;
  onPlay?: (() => void) | null;
  onPause?: (() => void) | null;
  name: string;
  id: string;
  onRename?: ((name: string) => void) | null;
  landscapeThumbnail?: boolean;
  ThumbnailIcon?: React.ComponentType<{ fill?: string; size?: number }>;
  thumbnailImage?: string | null;
  isDragDisabled?: boolean;
  downloadPartEnabled?: boolean;
  remoteUrl?: string;
  subTitleEnabled?: boolean;
  subTitleTopic?: string;
}) => {
  const [subTitle, setSubTitle] = useState("");
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [downloadingPart, setDownloadingPart] = useState(false);
  const [renameModelVisible, setRenameModelVisible] = useState({
    visible: false,
    name: "",
  });

  const container2Ref = createRef<HTMLDivElement>();
  const [container2Width, setContainer2Width] = useState(0);

  const container2WidthRef = useRef();
  const downloadPartEnabledRef = useRef(downloadPartEnabled);
  const remoteUrlRef = useRef(remoteUrl);
  const subTitleRef = useRef(subTitle);
  const subTitleTopicRef = useRef(subTitleTopic);

  const { downloadPart } = useDownloadPart();

  useEffect(() => {
    if (container2Ref.current) {
      const { current } = container2Ref;

      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;

      container2WidthRef.current !== width && setContainer2Width(width);
    }
  }, [container2Ref]);

  const container3Ref = createRef<HTMLDivElement>();
  const [container3Width, setContainer3Width] = useState(0);

  const container3WidthRef = useRef();

  useEffect(() => {
    if (container3Ref.current) {
      const { current } = container3Ref;

      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;

      container3WidthRef.current !== width && setContainer3Width(width);
    }
  }, [container3Ref]);

  const _handlePlay = () => {
    if (downloadPartEnabledRef.current && remoteUrlRef.current) {
      // never being used
      //
      setDownloadingPart(true);

      downloadPart(remoteUrlRef.current);

      setTimeout(() => {
        setDownloadingPart(false);

        if (onPlay) {
          onPlay();
        }
      }, 3000);
    } else {
      if (onPlay) {
        onPlay();
      }
    }
  };

  useEffect(() => {
    downloadPartEnabledRef.current = downloadPartEnabled;
  }, [downloadPartEnabled]);

  useEffect(() => {
    remoteUrlRef.current = remoteUrl;
  }, [remoteUrl]);

  useEffect(() => {
    subTitleRef.current = subTitle;
  }, [subTitle]);

  const _handleBrandMediaProgress = (subTitle: string) => {
    setSubTitle(subTitle);
  };

  useEffect(() => {
    const subTitleTopic = subTitleTopicRef.current;

    if (subTitleTopic) {
      appEventEmitter.on(subTitleTopic, _handleBrandMediaProgress);

      return () => {
        appEventEmitter.off(subTitleTopic, _handleBrandMediaProgress);
      };
    }
  }, [subTitleEnabled, id]);

  useEffect(() => {
    if (!subTitleEnabled && subTitleRef.current) {
      setSubTitle("");
    }
  }, [subTitleEnabled]);

  return (
    <React.Fragment>
      <Draggable
        isDragDisabled={downloadingPart || isDragDisabled}
        draggableId={`${id}`}
        index={index}
      >
        {(provided) => (
          <div
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
            className={`flex flex-1 relative mt-2 rounded-md items-center ${
              isDragDisabled ? "cursor-not-allowed" : ""
            }`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div
              style={{ borderWidth: 3 }}
              onClick={isActive ? (onPause as () => void) : _handlePlay}
              className={`w-full flex flex-1 bg-custom-blue-50 bg-opacity-50 rounded-md items-center ${
                isActive
                  ? "border-custom-blue-600"
                  : isMouseOver
                  ? "border-custom-blue-100"
                  : "border-custom-blue-50"
              }`}
            >
              <div
                ref={container2Ref as React.LegacyRef<HTMLDivElement>}
                style={{
                  height: 48,
                  minWidth: landscapeThumbnail ? (48 * 16) / 9 : 48,
                  maxWidth: landscapeThumbnail ? (48 * 16) / 9 : 48,
                }}
                className="bg-white p-3 relative flex items-center justify-center rounded-md overflow-hidden"
              >
                {ThumbnailIcon ? (
                  <ThumbnailIcon fill={primaryColor} size={24} />
                ) : thumbnailImage ? (
                  <img
                    alt="brand-asset-thumbnail"
                    src={thumbnailImage}
                    className={
                      "h-full w-full absolute top-0 left-0 right-0 bottom-0 bg-black rounded-md object-cover"
                    }
                  />
                ) : null}
              </div>
              <div
                style={{
                  minWidth: `calc(100% - ${
                    (container2Width || 0) + (container3Width || 0)
                  }px)`,
                  maxWidth: `calc(100% - ${
                    (container2Width || 0) + (container3Width || 0)
                  }px)`,
                }}
                className="pl-2"
              >
                <div className="truncate text-ellipsis text-primary">
                  <p className="font-medium">
                    {downloadingPart ? "loading..." : name}
                  </p>
                  {subTitleEnabled && subTitle ? (
                    <p className="text-xs text-custom-blue-600">{subTitle}</p>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
              {isActive ? (
                <React.Fragment />
              ) : (
                <div
                  style={{ height: 48, zIndex: 999 }}
                  onClick={(e) => e.stopPropagation()}
                  ref={container3Ref as React.LegacyRef<HTMLDivElement>}
                  className="flex items-center justify-center"
                >
                  <Menu
                    menuButton={
                      <span>
                        <button
                          className={`btn btn-ghost btn-square btn-sm rounded-md`}
                        >
                          <div>
                            <MdMoreVert size={20} />
                          </div>
                        </button>
                      </span>
                    }
                    transition
                  >
                    <MenuItem
                      className={
                        "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                      }
                      onClick={() => {
                        setRenameModelVisible({ visible: true, name });
                      }}
                    >
                      <div className="flex items-center justify-center gap-1">
                        <MdEdit />
                        <p className="ml-2">Rename</p>
                      </div>
                    </MenuItem>

                    <MenuItem
                      className={
                        "px-3 py-2 hover:bg-custom-blue-50 text-primary"
                      }
                      onClick={() => {
                        if (onDelete) {
                          onDelete();
                        }
                      }}
                    >
                      <div className="flex items-center justify-center gap-1">
                        <MdDelete />
                        <p className="ml-2">Remove</p>
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>
        )}
      </Draggable>

      <Modal
        isOpen={renameModelVisible.visible}
        cancelText={"Cancel"}
        onClose={() => {
          setRenameModelVisible({
            visible: false,
            name: "",
          });
        }}
        onSuccess={() => {
          const name = renameModelVisible.name;

          if (onRename) {
            onRename(name);
          }
          setRenameModelVisible({
            visible: false,
            name: "",
          });
        }}
        successText={"Rename"}
        title={"Rename"}
        renderBody={() => {
          return (
            <div>
              <input
                value={renameModelVisible.name}
                onChange={(e) => {
                  setRenameModelVisible({
                    visible: true,
                    name: e.target.value,
                  });
                }}
                type="text"
                placeholder="Rename"
                className="input input-bordered w-full"
              />
            </div>
          );
        }}
      />
    </React.Fragment>
  );
};

export default BrandAssetsDraggableItem;
