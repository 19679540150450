import { useEffect, useState } from "react";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

const useParticipantMicAudioLevel = ({
  participantId,
}: {
  participantId: string;
}) => {
  const [micAudioLevel, setMicAudioLevel] = useState(0);

  const _handleParticipantMicAudioLevelChanged = ({
    micAudioLevel,
  }: {
    micAudioLevel: number;
  }) => {
    setMicAudioLevel(micAudioLevel);
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.PARTICIPANT_MIC_AUDIO_LEVEL(participantId),
      _handleParticipantMicAudioLevelChanged
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.PARTICIPANT_MIC_AUDIO_LEVEL(participantId),
        _handleParticipantMicAudioLevelChanged
      );
    };
  }, []);

  return { micAudioLevel };
};

export default useParticipantMicAudioLevel;
