import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { mediaStreamKinds } from "../../listners/streams/QueuedStreamsListnerAllPeers";

const useParticipantMediaStats = (participantId: string) => {
  const { allParticipantsMediaStats } = useAppContext();

  const { micOn, webcamOn, screenShareOn, screenShareAudioOn } = useMemo(() => {
    const participantsMediaStats = allParticipantsMediaStats.get(participantId);

    const micOn = participantsMediaStats
      ? !!participantsMediaStats[mediaStreamKinds.audio]
      : false;

    const webcamOn = participantsMediaStats
      ? !!participantsMediaStats[mediaStreamKinds.video]
      : false;

    const screenShareOn = participantsMediaStats
      ? !!participantsMediaStats[mediaStreamKinds.share]
      : false;

    const screenShareAudioOn = participantsMediaStats
      ? !!participantsMediaStats[mediaStreamKinds.shareAudio]
      : false;

    return {
      micOn,
      webcamOn,
      screenShareOn,
      screenShareAudioOn,
    };
  }, [participantId, allParticipantsMediaStats]);

  return { micOn, webcamOn, screenShareOn, screenShareAudioOn };
};

export default useParticipantMediaStats;
