import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { getSidePanelPrivateChatMessageListIdFromMode } from "../sideContainer/SideContainer";
import useParticipantName from "../../hooks/appState/useParticipantName";
import SidePanelTopBar from "../sidePanel/SidePanelTopBar";
import { PrivateChatGroupMenu } from "./PrivateChatGroupsContainer";
import usePrivateChatGroupMessages from "../../hooks/privateChat/usePrivateChatGroupMessages";
import { MdSend } from "react-icons/md";
import VisibilitySensor from "react-visibility-sensor";
import Linkify from "linkify-react";
import { appModes } from "../../utils/constants";

const SenderInitialContainer = ({
  initials,
  isSelf,
}: {
  initials: string;
  isSelf: boolean;
}) => {
  return (
    <div
      style={{ fontSize: 12 }}
      className={`relative h-full ${
        isSelf
          ? "bg-custom-blue-100 bg-opacity-40 text-primary"
          : "bg-gray-200 bg-opacity-70 text-gray-900"
      } rounded-lg flex flex-1 items-center justify-center font-bold`}
    >
      {initials}
    </div>
  );
};

const PrivateChatMessageItem = ({
  timestamp,
  text,
  sdkParticipantId,
  isNextSelfSender,
  previousSameSender,
  isSelf,
  chatGroupId,
  id,
}: {
  timestamp: number;
  text: string;
  sdkParticipantId: string;
  isNextSelfSender: boolean;
  previousSameSender: boolean;
  isSelf: boolean;
  chatGroupId: string;
  id: string;
}) => {
  const { visibledPrivateGroupMessages, setVisibledPrivateGroupMessages } =
    useAppContext();

  const displayTime = useMemo(() => {
    const [hh, mm] = new Date(timestamp).toLocaleTimeString().split(":");

    return `${hh}:${mm}`;
  }, [timestamp]);

  const { name } = useParticipantName(sdkParticipantId);

  const initials = useMemo(
    () => (name ? (name[0] ? name[0].toUpperCase() : "U") : "U"),
    [name]
  );

  const visibled = useMemo(
    () => visibledPrivateGroupMessages.get(chatGroupId)?.includes(id),
    [visibledPrivateGroupMessages, id, chatGroupId]
  );

  return (
    <VisibilitySensor
      active={!visibled}
      onChange={(isVisible: boolean) => {
        if (isVisible) {
          setVisibledPrivateGroupMessages((s) => {
            const _s = new Map(s);

            _s.set(chatGroupId, [...(_s.get(chatGroupId) || []), id]);

            return _s;
          });
        }
      }}
    >
      <div
        className={`flex w-full items-end ${
          previousSameSender ? "mt-1" : "mt-2"
        }`}
      >
        <div className="w-8 aspect-square flex items-center justify-center">
          {isSelf ? (
            <React.Fragment />
          ) : isNextSelfSender ? null : (
            <SenderInitialContainer {...{ isSelf, initials }} />
          )}
        </div>

        <div
          className={`flex flex-1 flex-col ${
            isSelf ? "items-end" : "items-start"
          }`}
        >
          {!previousSameSender ? (
            <div>
              <p className={`text-xs px-3 mb-1 text-gray-500`}>{name}</p>
            </div>
          ) : null}
          <div
            className={`mx-1 rounded-xl ${
              isSelf ? "bg-custom-blue-50" : "bg-gray-200 bg-opacity-70"
            } p-2 flex flex-col`}
          >
            <span
              style={{ maxWidth: "100%" }}
              className="font-medium text-sm max-w-full break-all"
            >
              <Linkify
                as={"p"}
                options={{
                  className: "break-all",
                  target: "_blank",
                  render: ({ attributes, content }) => {
                    const { href } = attributes;

                    return (
                      <a
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        className="text-custom-blue-900 hover:underline"
                      >
                        {content}
                      </a>
                    );
                  },
                }}
              >
                {text}
              </Linkify>
            </span>

            <p
              className={`mt-1 text-xs italic text-gray-500 flex ${
                isSelf ? "justify-end" : "justify-start"
              }`}
            >
              {displayTime}
            </p>
          </div>
        </div>

        <div className="w-8 aspect-square flex items-center justify-center">
          {isSelf ? (
            isNextSelfSender ? null : (
              <SenderInitialContainer {...{ isSelf, initials }} />
            )
          ) : (
            <React.Fragment />
          )}
        </div>
      </div>
    </VisibilitySensor>
  );
};

const PrivateChatGroupMessages = ({
  containerHeight,
  containerWidth,
}: {
  containerHeight: number;
  containerWidth: number;
}) => {
  const [textMessage, setTextMessage] = useState("");

  const {
    appMode,
    sidePanelPrivateChatMode,
    privateChatGroupsMessages,
    participatedPrivateChatGroups,
    setSidePanelPrivateChatMode,
  } = useAppContext();

  const id = useMemo(
    () =>
      getSidePanelPrivateChatMessageListIdFromMode(
        sidePanelPrivateChatMode as string
      ),
    [sidePanelPrivateChatMode]
  );

  const messages = useMemo(() => {
    return privateChatGroupsMessages.get(id as string) || [];
  }, [privateChatGroupsMessages, id]);

  const { name, participantIds } = useMemo(() => {
    return (
      participatedPrivateChatGroups.find(({ id: _id }) => id === _id) || {
        name: undefined,
        participantIds: undefined,
      }
    );
  }, [participatedPrivateChatGroups, id]);

  const listRef = useRef<HTMLDivElement>();

  const { sendMessage } = usePrivateChatGroupMessages(`${id}`);

  function handleSend(text: string) {
    if (text.length) {
      setTextMessage("");

      sendMessage({ text });
    }
  }

  //
  //
  const container3Ref = createRef<HTMLDivElement>();
  const [container3Height, setContainer3Height] = useState(0);
  const [container3Width, setContainer3Width] = useState(0);

  const container3HeightRef = useRef<number>();
  const container3WidthRef = useRef<number>();

  useEffect(() => {
    if (container3Ref.current) {
      const { current } = container3Ref;

      const boundingRect = current.getBoundingClientRect();
      const { height, width } = boundingRect;

      container3HeightRef.current !== height && setContainer3Height(height);
      container3WidthRef.current !== width && setContainer3Width(width);
    }
  }, [container3Ref]);
  //
  //
  const container4Ref = createRef<HTMLDivElement>();
  const [container4Height, setContainer4Height] = useState(0);
  const [container4Width, setContainer4Width] = useState(0);

  const container4HeightRef = useRef<number>();
  const container4WidthRef = useRef<number>();

  useEffect(() => {
    if (container4Ref.current) {
      const { current } = container4Ref;

      const boundingRect = current.getBoundingClientRect();
      const { height, width } = boundingRect;

      container4HeightRef.current !== height && setContainer4Height(height);
      container4WidthRef.current !== width && setContainer4Width(width);
    }
  }, [container4Ref]);
  //
  //
  useEffect(() => {
    container3HeightRef.current = container3Height;
  }, [container3Height]);
  useEffect(() => {
    container3WidthRef.current = container3Width;
  }, [container3Width]);
  useEffect(() => {
    container4HeightRef.current = container4Height;
  }, [container4Height]);
  useEffect(() => {
    container4WidthRef.current = container4Width;
  }, [container4Width]);

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  //
  //
  const container5Ref = createRef<HTMLSpanElement>();
  const [container5Width, setContainer5Width] = useState(0);

  const container5WidthRef = useRef();

  useEffect(() => {
    if (container5Ref.current) {
      const { current } = container5Ref;

      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;

      container5WidthRef.current !== width && setContainer5Width(width);
    }
  }, [container5Ref]);

  //
  //

  return id && name && participantIds?.length ? (
    <div style={{ height: containerHeight, width: containerWidth }}>
      <div ref={container3Ref as React.LegacyRef<HTMLDivElement>}>
        <SidePanelTopBar
          {...{
            onBackClick: () => {
              setSidePanelPrivateChatMode(null);
            },
            title: name,
            renderSecondaryTitle: () => (
              <p className="text-xs text-gray-500">{`${participantIds.length} people`}</p>
            ),
            renderRightComponent:
              appMode === appModes.HOST
                ? () => (
                    <PrivateChatGroupMenu {...{ id, name, participantIds }} />
                  )
                : undefined,
          }}
        />
      </div>

      <div
        ref={listRef as React.LegacyRef<HTMLDivElement>}
        style={{
          height:
            containerHeight - (container3Height || 0) - (container4Height || 0),
        }}
        className="flex flex-1 flex-col overflow-y-scroll"
      >
        {messages?.length ? (
          messages.map((message, i) => {
            return (
              <PrivateChatMessageItem
                {...{
                  key: `private-chat-list-item-${id}-${i}`,
                  ...message,
                  chatGroupId: id,
                }}
              />
            );
          })
        ) : (
          <div className="flex flex-1 items-center justify-center">
            <p className="text-gray-600">No Messages</p>
          </div>
        )}
      </div>

      <div
        ref={container4Ref as React.LegacyRef<HTMLDivElement>}
        className="flex w-full items-center border-t border-gray-100 p-1 gap-2"
      >
        <span style={{ width: `calc(100% - ${container5Width}px)` }}>
          <div className="flex flex-1">
            <input
              className="input input-bordered flex flex-1 w-full"
              value={textMessage}
              onChange={(e) => {
                setTextMessage(e.target.value);
              }}
              placeholder="Type a message"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleSend(textMessage);
                }
              }}
            />
          </div>

          {/* <InputEmoji
            value={textMessage}
            onChange={setTextMessage}
            onEnter={handleSend}
            placeholder="Type a message"
          /> */}
        </span>

        <span ref={container5Ref as React.LegacyRef<HTMLSpanElement>}>
          <button
            disabled={textMessage.length === 0}
            className="btn btn-primary text-white btn-sm"
            onClick={() => {
              handleSend(textMessage);
            }}
          >
            <MdSend />
          </button>
        </span>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default PrivateChatGroupMessages;
