import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useBrandShowDisplayNames = () => {
  const { publish } = useAppSingalingPublish(
    appPubSubTopics.BRAND_SHOW_DISPLAY_NAMES
  );
  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const setShowDisplayNames = async (
    {
      showDisplayNames,
    }: {
      showDisplayNames: boolean;
    },
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(JSON.stringify({ showDisplayNames }));
  };

  return { setShowDisplayNames };
};

export default useBrandShowDisplayNames;
