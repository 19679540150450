import { useEffect, useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

const useBroadcastEgresses = (args?: {
  startEgressesFailedDueToNoOutputs?: () => void;
}) => {
  const { egresses, editingEgresses, startingEgresses, stoppingEgresses } =
    useAppContext();

  const {
    egressesEditing,
    egressesStarting,
    egressesStopping,
    egressesStarted,
    egressesStopped,
    egressesActivatedAt,
    isRecordingOriginalAudio,
    isLivestreamingOriginalAudio,
    isLivestreamingInterpretedAudio,
    livestreamingToInterpretationLanguageCodes,
  } = useMemo(() => {
    const egressesEditing = !!editingEgresses;

    const egressesStarting = !!startingEgresses;

    const egressesStopping = !!stoppingEgresses;

    const allEgressesStarted = egresses?.length
      ? egresses?.filter(({ status }) => status === "STARTED")?.length ===
        egresses?.length
      : false;

    const egressesStarted =
      !egressesEditing &&
      !egressesStarting &&
      !egressesStopping &&
      allEgressesStarted;

    const egressesStopped =
      !egressesEditing &&
      !egressesStarting &&
      !egressesStopping &&
      !egressesStarted;

    const egressesActivatedAt = allEgressesStarted
      ? egresses
        ? Math.max(...egresses.map(({ activatedAt }) => activatedAt))
        : 0
      : null;

    const originalAudioTypeEgress = egresses?.find(
      ({ streamAudioType }) => streamAudioType === "ORIGINAL"
    );

    const isRecordingOriginalAudio = allEgressesStarted
      ? !!originalAudioTypeEgress?.recording?.enabled
      : false;

    const isLivestreamingOriginalAudio = allEgressesStarted
      ? !!originalAudioTypeEgress?.rtmpLivestream?.enabled &&
        !!originalAudioTypeEgress?.rtmpLivestream?.activeRtmpDestinationUrls
          ?.length
      : false;

    const isLivestreamingInterpretedAudio = allEgressesStarted
      ? !!egresses?.find(
          ({ streamAudioType }) => streamAudioType === "INTERPRETED"
        )
      : false;

    const livestreamingToInterpretationLanguageCodes = allEgressesStarted
      ? egresses
          ?.filter(({ streamAudioType }) => streamAudioType === "INTERPRETED")
          ?.map(({ interpretationLanguageCode }) => interpretationLanguageCode)
      : [];

    return {
      egressesEditing,
      egressesStarting,
      egressesStopping,
      egressesStarted,
      egressesStopped,
      egressesActivatedAt,
      isRecordingOriginalAudio,
      isLivestreamingOriginalAudio,
      isLivestreamingInterpretedAudio,
      livestreamingToInterpretationLanguageCodes,
    };
  }, [egresses, editingEgresses, startingEgresses, stoppingEgresses]);

  const startBroadcastEgresses = ({
    recordingEnabled,
  }: {
    recordingEnabled: boolean;
  }) => {
    appEventEmitter.emit(appEventEmitterEvents.START_BROADCAST_EGRESSES, {
      recordingEnabled,
    });
  };

  const editBroadcastDestinations = ({
    selectedLiveStreamDestinationsIds,
    destinationsToBeAdded,
    destinationsToBeRemoved,
  }: {
    selectedLiveStreamDestinationsIds: number[];
    destinationsToBeAdded: number[];
    destinationsToBeRemoved: number[];
  }) => {
    appEventEmitter.emit(appEventEmitterEvents.EDIT_BROADCAST_DESTINATIONS, {
      selectedLiveStreamDestinationsIds,
      destinationsToBeAdded,
      destinationsToBeRemoved,
    });
  };

  const stopBroadcastEgresses = () => {
    appEventEmitter.emit(appEventEmitterEvents.STOP_BROADCAST_EGRESSES);
  };

  const _handleStartEgressesFailedDueToNoOutputs = () => {
    if (typeof args?.startEgressesFailedDueToNoOutputs === "function") {
      args.startEgressesFailedDueToNoOutputs();
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.START_EGRESSES_FAILED_DUE_TO_NO_OUTPUTS,
      _handleStartEgressesFailedDueToNoOutputs
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.START_EGRESSES_FAILED_DUE_TO_NO_OUTPUTS,
        _handleStartEgressesFailedDueToNoOutputs
      );
    };
  }, []);

  return {
    egressesEditing,
    egressesStarting,
    egressesStopping,
    egressesStarted,
    egressesStopped,
    egressesActivatedAt,
    isRecordingOriginalAudio,
    isLivestreamingOriginalAudio,
    isLivestreamingInterpretedAudio,
    livestreamingToInterpretationLanguageCodes,
    //
    startBroadcastEgresses,
    editBroadcastDestinations,
    stopBroadcastEgresses,
  };
};

export default useBroadcastEgresses;
