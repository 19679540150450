import React, { createRef, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { useWindowSize } from "@react-hook/window-size";

const FullScreenModalBody = ({
  modalChildren,
}: {
  modalChildren: (t: {
    containerHeight: number;
    containerWidth: number;
  }) => React.ReactNode;
}) => {
  const containerRef = createRef<HTMLDivElement>();
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const containerHeightRef = useRef();
  const containerWidthRef = useRef();

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { width, height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
      containerWidthRef.current !== width && setContainerWidth(width);
    }
  }, [containerRef]);

  return (
    <div
      ref={containerRef as React.LegacyRef<HTMLDivElement>}
      className="flex flex-1 flex-col"
    >
      {typeof modalChildren === "function"
        ? modalChildren({ containerHeight, containerWidth })
        : modalChildren}
      {/* {modalChildren({ containerHeight, containerWidth })} */}
    </div>
  );
};

const FullScreenModalContainer = ({
  isOpen,
  children,
  title,
  handleCloseModal,
  hideTopBar,
}: {
  isOpen: boolean;
  children: (t: {
    containerHeight: number;
    containerWidth: number;
  }) => React.ReactNode;
  title: string;
  handleCloseModal?: () => void;
  hideTopBar?: boolean;
}) => {
  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      closeTimeoutMS={200}
      style={{
        overlay: {
          padding: 0,
          margin: 0,
          height: windowHeight,
          width: windowWidth,
          top: 0,
          left: 0,
          position: "absolute",
          transition: "none",
        },
        content: {
          padding: 0,
          margin: 0,
          height: windowHeight,
          width: windowWidth,
          top: 0,
          left: 0,
          position: "absolute",
          transition: "none",
        },
      }}
      className={"transition-none animate-none"}
    >
      <div
        style={{ height: "100%", width: "100%" }}
        className={"overflow-scroll flex flex-col bg-white"}
      >
        {hideTopBar ? (
          <React.Fragment />
        ) : title || typeof handleCloseModal === "function" ? (
          <div className="flex p-2 items-center justify-center border-b border-b-gray-200">
            {title ? (
              <div className="flex flex-1 text-lg font-bold">{title}</div>
            ) : (
              <React.Fragment />
            )}
            {typeof handleCloseModal === "function" ? (
              <div>
                <button
                  className="btn btn-sm btn-primary text-white"
                  onClick={handleCloseModal}
                >
                  <MdClose />
                </button>
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
        ) : (
          <React.Fragment />
        )}

        <div className="flex flex-1 flex-col">
          <FullScreenModalBody {...{ modalChildren: children }} />
        </div>
      </div>
    </Modal>
  );
};

export default FullScreenModalContainer;
