import React, { useEffect, useRef } from "react";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { toast } from "react-toastify";

const BadNetworkToastListner = () => {
  const lastToastedTimestamp = useRef(new Date().getTime() - 100000);

  const _handleListenBadNetworkToast = ({
    isSignalLevelValid,
  }: {
    isSignalLevelValid: boolean;
  }) => {
    const currentTimestamp = new Date().getTime();

    const diff = currentTimestamp - lastToastedTimestamp.current;

    if (diff > 10000) {
      lastToastedTimestamp.current = currentTimestamp;

      if (typeof isSignalLevelValid === "boolean" && !isSignalLevelValid) {
        toast.error(
          "Error: Please check your internet connection, it seems poor. Hence blocking the actions!"
        );
      }
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.BAD_NETWORK_TOAST,
      _handleListenBadNetworkToast
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.BAD_NETWORK_TOAST,
        _handleListenBadNetworkToast
      );
    };
  }, []);

  return <React.Fragment />;
};

export default BadNetworkToastListner;
