import { useEffect, useMemo, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { assignSelfRoleTopicByParticipantId } from "../../utils/pubSubTopics";
import { appModes, participantRoles } from "../../utils/constants";
import { useAppConfigContext } from "../../contexts/appConfigDef";

const useAssignRoleToParticipant = ({
  participantId,
}: {
  participantId: string;
}) => {
  const { joinedParticipants, interpreterParticipantIds } = useAppContext();

  const { interpretations } = useAppConfigContext();

  const { appMode, isOriginalHost, isInterpreter } = useMemo(() => {
    const joinedParticipant = joinedParticipants.find(
      ({ participantId: _participantId }) => participantId === _participantId
    );

    const isInterpreter = interpreterParticipantIds.find(
      (interpreterParticipantId) => interpreterParticipantId === participantId
    );

    const appMode = joinedParticipant?.appMode
      ? joinedParticipant?.appMode
      : isInterpreter
      ? appModes.VIEWER
      : undefined;

    const isOriginalHost = joinedParticipant?.originalAppMode === appModes.HOST;

    return { appMode, isOriginalHost, isInterpreter };
  }, [joinedParticipants, interpreterParticipantIds, participantId]);

  const isSpeaker = useMemo(() => appMode === appModes.SPEAKER, [appMode]);
  const isHost = useMemo(() => appMode === appModes.HOST, [appMode]);

  const canBeAssignedAsHost = useMemo(
    () => (isOriginalHost ? false : isInterpreter || isSpeaker),
    [isOriginalHost, isInterpreter, isSpeaker]
  );

  const canBeAssignedAsSpeaker = useMemo(
    () => (isOriginalHost ? false : isHost || isInterpreter),
    [isHost, isInterpreter, isOriginalHost]
  );

  const canBeAssignedAsInterpreter = useMemo(
    () =>
      isOriginalHost
        ? false
        : !interpretations.length
        ? false
        : isSpeaker || isHost,
    [isSpeaker, isHost, isOriginalHost, interpretations]
  );

  const assignRoleToParticipant = ({
    role,
    appMode,
  }: {
    role: participantRolesType;
    appMode: appModeType;
  }) => {
    appEventEmitter.emit(appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH, {
      topic: assignSelfRoleTopicByParticipantId(participantId),
      message: JSON.stringify({ role, appMode }),
    });
  };

  const canBeAssignedAsHostRef = useRef(canBeAssignedAsHost);
  const canBeAssignedAsSpeakerRef = useRef(canBeAssignedAsSpeaker);
  const canBeAssignedAsInterpreterRef = useRef(canBeAssignedAsInterpreter);

  useEffect(() => {
    canBeAssignedAsHostRef.current = canBeAssignedAsHost;
  }, [canBeAssignedAsHost]);
  useEffect(() => {
    canBeAssignedAsSpeakerRef.current = canBeAssignedAsSpeaker;
  }, [canBeAssignedAsSpeaker]);
  useEffect(() => {
    canBeAssignedAsInterpreterRef.current = canBeAssignedAsInterpreter;
  }, [canBeAssignedAsInterpreter]);

  const assignAsHost = () => {
    const canBeAssignedAsHost = canBeAssignedAsHostRef.current;

    if (!canBeAssignedAsHost) {
      return;
    }

    assignRoleToParticipant({
      appMode: appModes.HOST,
      role: participantRoles.PARTICIPANT,
    });
  };

  const assignAsSpeaker = () => {
    const canBeAssignedAsSpeaker = canBeAssignedAsSpeakerRef.current;

    if (!canBeAssignedAsSpeaker) {
      return;
    }

    assignRoleToParticipant({
      appMode: appModes.SPEAKER,
      role: participantRoles.PARTICIPANT,
    });
  };

  const assignAsInterpreter = () => {
    const canBeAssignedAsInterpreter = canBeAssignedAsInterpreterRef.current;

    if (!canBeAssignedAsInterpreter) {
      return;
    }

    assignRoleToParticipant({
      appMode: appModes.VIEWER,
      role: participantRoles.INTERPRETER,
    });
  };

  return {
    isSpeaker,
    isHost,
    isOriginalHost,
    canBeAssignedAsHost,
    canBeAssignedAsSpeaker,
    canBeAssignedAsInterpreter,
    assignAsHost,
    assignAsSpeaker,
    assignAsInterpreter,
  };
};

export default useAssignRoleToParticipant;
