import { useMemo } from "react";
import { audioAndVideoPermissionStates } from "../../utils/constants";
import { useAppContext } from "../../contexts/appContextDef";

const useIsParticipantCameraDenied = ({
  participantId,
}: {
  participantId: string;
}) => {
  const { participantsAudioVideoCapturerState } = useAppContext();

  const { cameraPermissionState } = useMemo(() => {
    const participantFound = participantsAudioVideoCapturerState[participantId];

    if (participantFound) {
      return {
        cameraPermissionState: participantFound["camera"],
      };
    } else {
      return { cameraPermissionState: null };
    }
  }, [participantsAudioVideoCapturerState, participantId]);

  const cameraDenied = useMemo(
    () => cameraPermissionState === audioAndVideoPermissionStates.denied,
    [cameraPermissionState]
  );

  return { cameraDenied };
};

export default useIsParticipantCameraDenied;
