import { studioApiServerBaseUrl } from "../../utils/constants";

const generateAgoraRtcConfig = async ({
  channelName,
}: {
  channelName: string;
}): Promise<{
  success: boolean;
  data?: { token: string; appId: string };
}> => {
  try {
    const url = `${studioApiServerBaseUrl}/generate-agora-rtc-config`;

    const body = JSON.stringify({
      channelName,
    });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true, data: json.data };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default generateAgoraRtcConfig;
