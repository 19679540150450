import React, { useEffect, useRef } from "react";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import { getValidateConnectionTopicById } from "../../utils/pubSubTopics";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

export const validateConnectionActions = {
  INIT: "INIT",
  RESPONSE: "RESPONSE",
};

const ValidateConnectionListner = () => {
  const { localParticipantId } = useLocalParticipantId();

  const publishRef = useRef<appSingalingPublishType>();

  useAppSingalingSubscribe(
    getValidateConnectionTopicById(localParticipantId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { action, payload } = message;

      switch (action) {
        case validateConnectionActions.INIT:
          if (publishRef.current) {
            publishRef.current(
              JSON.stringify({
                action: validateConnectionActions.RESPONSE,
                payload,
              })
            );
          }
          break;

        case validateConnectionActions.RESPONSE:
          appEventEmitter.emit(
            appEventEmitterEvents.VALIDATE_CONNECTION_RESPONSE(payload.id),
            payload
          );

          break;

        default:
          break;
      }
    }
  );

  const { publish } = useAppSingalingPublish(
    getValidateConnectionTopicById(localParticipantId)
  );

  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  return <React.Fragment />;
};

export default ValidateConnectionListner;
