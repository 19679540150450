import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import {
  DataPacket_Kind,
  DisconnectReason,
  LocalParticipant,
  RemoteParticipant,
  RoomEvent,
} from "livekit-client";
import { Room as LivekitRoomType } from "livekit-client";
import { safeParseJson } from "../../utils/safeParseJson";
import sleep from "../../utils/sleep";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import {
  allowParticipantEntryRequestTopicByParticipantId,
  appPubSubTopics,
  assignSelfRoleTopicByParticipantId,
  initReqCurrentTimeFrom,
  initReqOldDataFrom,
  resolveReqCurrentTimeTo,
  resolveReqOldDataTo,
} from "../../utils/pubSubTopics";
import {
  appModes,
  participantRoles,
  sidePanelModes,
} from "../../utils/constants";
import convertMapToObjectArray from "../../utils/convertMapToObjectArray";
import convertObjectArrayToMap from "../../utils/convertObjectArrayToMap";
import useSetBrandsAndAssets from "../../hooks/appState/useSetBrandsAndAssets";
import useBrands from "../../hooks/brands/useBrands";
import { useAppConfigContext } from "../../contexts/appConfigDef";

const encoder = new TextEncoder();
const decoder = new TextDecoder();

const oldStatesValueSetterKeys = [
  {
    valueKey: "activeVideoShareStreams",
    setterKey: "setActiveVideoShareStreams",
  },
  {
    valueKey: "activeFileShareStreams",
    setterKey: "setActiveFileShareStreams",
  },
  {
    valueKey: "activeVideoShareStreamAllParticipants",
    setterKey: "setActiveVideoShareStreamAllParticipants",
  },
  {
    valueKey: "activeFileShareStreamAllParticipants",
    setterKey: "setActiveFileShareStreamAllParticipants",
  },
  {
    valueKey: "allVideoShareStreamStates",
    setterKey: "setAllVideoShareStreamStates",
  },
  {
    valueKey: "visibledPrivateGroupMessages",
    setterKey: "setVisibledPrivateGroupMessages",
    isMap: true,
  },
  {
    valueKey: "privateChatGroupsMessages",
    setterKey: "setPrivateChatGroupsMessages",
    isMap: true,
  },
  {
    valueKey: "privateChatGroups",
    setterKey: "setPrivateChatGroups",
  },
  {
    valueKey: "sortedVideoClipIds",
    setterKey: "setSortedVideoClipIds",
  },
  {
    valueKey: "videoClips",
    setterKey: "setVideoClips",
  },
  {
    valueKey: "sortedOverlayIds",
    setterKey: "setSortedOverlayIds",
  },
  {
    valueKey: "overlays",
    setterKey: "setOverlays",
  },
  {
    valueKey: "sortedBrandLogoIds",
    setterKey: "setSortedBrandLogoIds",
  },
  {
    valueKey: "brandLogos",
    setterKey: "setBrandLogos",
  },
  {
    valueKey: "sortedBackgroundIds",
    setterKey: "setSortedBackgroundIds",
  },
  {
    valueKey: "backgrounds",
    setterKey: "setBackgrounds",
  },
  {
    valueKey: "sortedBackgroundMusicIds",
    setterKey: "setSortedBackgroundMusicIds",
  },
  {
    valueKey: "backgroundMusics",
    setterKey: "setBackgroundMusics",
  },
  {
    valueKey: "brands",
    setterKey: "setBrands",
  },
  {
    valueKey: "activeBrandId",
    setterKey: "setActiveBrandId",
  },
  {
    valueKey: "activeLowerThirtdBanner",
    setterKey: "setActiveLowerThirtdBannerId",
  },
  {
    valueKey: "activeTickerBanner",
    setterKey: "setActiveTickerBannerId",
  },
  {
    valueKey: "showAudioAvatars",
    setterKey: "setShowAudioAvatars",
  },
  {
    valueKey: "raiseHandEnabled",
    setterKey: "setRaiseHandEnabled",
  },
  {
    valueKey: "raiseHandParticipantIds",
    setterKey: "setRaiseHandParticipantIds",
  },
  {
    valueKey: "previousStudioMainViewLayout",
    setterKey: "setPreviousStudioMainViewLayout",
  },
  {
    valueKey: "nonHostCanSeeParticipantsCount",
    setterKey: "setNonHostCanSeeParticipantsCount",
  },
  {
    valueKey: "modifiedParticipantsNames",
    setterKey: "setModifiedParticipantsNames",
  },
  {
    valueKey: "modifiedParticipantsAvatars",
    setterKey: "setModifiedParticipantsAvatars",
  },
  {
    valueKey: "mainViewSoloModeSelectedParticipantId",
    setterKey: "setMainViewSoloModeSelectedParticipantId",
  },
  {
    valueKey: "mainViewGrid",
    setterKey: "setMainViewGrid",
  },
  {
    valueKey: "mainViewSelectedStreams",
    setterKey: "setMainViewSelectedStreams",
    isMap: true,
  },
  {
    valueKey: "mainViewLayout",
    setterKey: "setMainViewLayout",
  },
  {
    valueKey: "conferenceAutoMainVideoLayoutEnabled",
    setterKey: "setConferenceAutoMainVideoLayoutEnabled",
  },
  {
    valueKey: "interactivityMode",
    setterKey: "setInteractivityMode",
  },
  {
    valueKey: "participantHeadlines",
    setterKey: "setParticipantHeadlines",
  },
  {
    valueKey: "highlightedChat",
    setterKey: "setHighlightedChat",
  },
  {
    valueKey: "waitingRoomEnabled",
    setterKey: "setWaitingRoomEnabled",
  },
  {
    valueKey: "failedRtmpDestinationUrls",
    setterKey: "setFailedRtmpDestinationUrls",
  },
  {
    valueKey: "egresses",
    setterKey: "setEgresses",
  },
  {
    valueKey: "erroredLiveStreamDestinationsIds",
    setterKey: "setErroredLiveStreamDestinationsIds",
  },
  {
    valueKey: "rtmpDestinationIdAndUrls",
    setterKey: "setRtmpDestinationIdAndUrls",
  },
  {
    valueKey: "participantsAudioVideoCapturerState",
    setterKey: "setParticipantsAudioVideoCapturerState",
  },
  {
    valueKey: "allParticipantsNetworkSignalLevel",
    setterKey: "setAllParticipantsNetworkSignalLevel",
    isMap: true,
  },
  {
    valueKey: "allParticipantsMediaStats",
    setterKey: "setAllParticipantsMediaStats",
    isMap: true,
  },
  {
    valueKey: "inQueueStreams",
    setterKey: "setInQueueStreams",
  },
  {
    valueKey: "mainViewVideoClip",
    setterKey: "setMainViewVideoClip",
  },
  {
    valueKey: "mainViewOverlay",
    setterKey: "setMainViewOverlay",
  },
  {
    valueKey: "mainViewBackground",
    setterKey: "setMainViewBackground",
  },
  {
    valueKey: "showParticipantHeadline",
    setterKey: "setShowParticipantHeadline",
  },
  {
    valueKey: "showDisplayName",
    setterKey: "setShowDisplayName",
  },
  {
    valueKey: "muteGuestsWhenVideoPlays",
    setterKey: "setMuteGuestsWhenVideoPlays",
  },
  {
    valueKey: "muteGuestsWhenAudioPlays",
    setterKey: "setMuteGuestsWhenAudioPlays",
  },
  {
    valueKey: "brandLogo",
    setterKey: "setBrandLogo",
  },
  {
    valueKey: "brandColor",
    setterKey: "setBrandColor",
  },
  {
    valueKey: "backgroundMusic",
    setterKey: "setBackgroundMusic",
  },
  {
    valueKey: "conferenceModeMaxParticipantCount",
    setterKey: "setConferenceModeMaxParticipantCount",
  },
  {
    valueKey: "conferenceModeParticipantAutoCountEnabled",
    setterKey: "setConferenceModeParticipantAutoCountEnabled",
  },
  {
    valueKey: "activeInputFileVideoShareStreamAllParticipants",
    setterKey: "setActiveInputFileVideoShareStreamAllParticipants",
  },
  {
    valueKey: "allInputFileVideoShareStreamStates",
    setterKey: "setAllInputFileVideoShareStreamStates",
  },
  {
    valueKey: "activeInputFileVideoShareStreams",
    setterKey: "setActiveInputFileVideoShareStreams",
  },
  {
    valueKey: "fileShareStreamsCurrentPage",
    setterKey: "setFileShareStreamsCurrentPage",
  },
] as {
  valueKey: appContextDefaultStatesType;
  setterKey: appContextDefaultStateSettersType;
  isMap?: boolean;
}[];

const LivekitSignalingListner = () => {
  const {
    isInterpreter,
    isRecorder,
    setIsInterpreter,
    setIsRecorder,
    interpretations,
  } = useAppConfigContext();
  const appContext = useAppContext();

  const initAt = useRef(new Date().getTime());
  const oldStatesReceived = useRef(false);

  const { signalingClient }: { signalingClient: LivekitRoomType } = appContext;

  const {
    appMode,
    setAppMode,
    studioParticipantIdLivekitSidMapRef,
    joinedParticipants,
    interpreterParticipantIds,
    recorderParticipantIds,
    allowedEntryRequestParticipantIds,
    setAllowedEntryRequestParticipantIds,
    localParticipantId,
    connectionState,
    setConnectionState,
    activeBrandId,
    voluntarilyLeftSession,
    setActiveInterpretationInputAgoraChannelId,
    setSidePanelMode,
  } = appContext;

  const reconnectingTimeout = useRef<null | number>(null);

  const signalingClientRef = useRef(signalingClient);
  const appContextRef = useRef(appContext);
  const allowedEntryRequestParticipantIdsRef = useRef(
    allowedEntryRequestParticipantIds
  );
  const joinedParticipantsRef = useRef(joinedParticipants);
  const interpreterParticipantIdsRef = useRef(interpreterParticipantIds);
  const recorderParticipantIdsRef = useRef(recorderParticipantIds);
  const connectionStateRef = useRef(connectionState);
  const activeBrandIdRef = useRef(activeBrandId);
  const localParticipantIdRef = useRef(localParticipantId);
  const isInterpreterRef = useRef(isInterpreter);
  const isRecorderRef = useRef(isRecorder);
  const appModeRef = useRef(appMode);
  const interpretationsRef = useRef(interpretations);

  useEffect(() => {
    signalingClientRef.current = signalingClient;
  }, [signalingClient]);
  useEffect(() => {
    appContextRef.current = appContext;
  }, [appContext]);
  useEffect(() => {
    allowedEntryRequestParticipantIdsRef.current =
      allowedEntryRequestParticipantIds;
  }, [allowedEntryRequestParticipantIds]);
  useEffect(() => {
    joinedParticipantsRef.current = joinedParticipants;
  }, [joinedParticipants]);
  useEffect(() => {
    interpreterParticipantIdsRef.current = interpreterParticipantIds;
  }, [interpreterParticipantIds]);
  useEffect(() => {
    recorderParticipantIdsRef.current = recorderParticipantIds;
  }, [recorderParticipantIds]);
  useEffect(() => {
    connectionStateRef.current = connectionState;
  }, [connectionState]);
  useEffect(() => {
    activeBrandIdRef.current = activeBrandId;
  }, [activeBrandId]);
  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);
  useEffect(() => {
    isInterpreterRef.current = isInterpreter;
  }, [isInterpreter]);
  useEffect(() => {
    isRecorderRef.current = isRecorder;
  }, [isRecorder]);
  useEffect(() => {
    appModeRef.current = appMode;
  }, [appMode]);
  useEffect(() => {
    interpretationsRef.current = interpretations;
  }, [interpretations]);

  const { setBrandsAndAssets } = useSetBrandsAndAssets();

  const { activatAssetsOfBrand } = useBrands();

  const waitUntillReadyToSendOldStates = async () => {
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (new Date().getTime() - 10000 > initAt.current) {
          clearInterval(interval);

          resolve(undefined);
        }
      }, 500);
    });
  };

  const getOldestJoinedParticipantId = (): string => {
    const signalingClient = signalingClientRef.current;

    const participants = signalingClient.participants;
    const localParticipant = signalingClient.localParticipant;

    let oldestJoinedParticipant: {
      participantId: null | string;
      joinedAt: number;
    } = { participantId: null, joinedAt: new Date().getTime() + 3000 };

    if (
      localParticipant.joinedAt &&
      localParticipant.joinedAt.getTime() < oldestJoinedParticipant.joinedAt
    ) {
      oldestJoinedParticipant = {
        joinedAt: localParticipant.joinedAt.getTime(),
        participantId: localParticipant.identity,
      };
    }

    participants.forEach((participant) => {
      const entryRequestAllowed = safeParseJson(
        participant.metadata
      )?.entryRequestAllowed;

      if (
        participant.joinedAt &&
        participant.joinedAt.getTime() < oldestJoinedParticipant.joinedAt &&
        entryRequestAllowed
      ) {
        oldestJoinedParticipant = {
          joinedAt: participant.joinedAt.getTime(),
          participantId: participant.identity,
        };
      }
    });

    return oldestJoinedParticipant.participantId as string;
  };

  const _handleOnParticipantConnected = (participant: RemoteParticipant) => {
    const parsedMetadata = safeParseJson(participant.metadata);

    const role = parsedMetadata?.role as participantRolesType;

    const participantId = participant.identity;
    const participantSid = participant.sid;

    studioParticipantIdLivekitSidMapRef.current.set(
      participantId,
      participantSid
    );

    const joinedParticipants = joinedParticipantsRef.current;
    const interpreterParticipantIds = interpreterParticipantIdsRef.current;
    const recorderParticipantIds = recorderParticipantIdsRef.current;

    if (role === participantRoles.RECORDER) {
      if (
        !recorderParticipantIds.find(
          (_participantId) => participantId === _participantId
        )
      ) {
        appEventEmitter.emit(
          appEventEmitterEvents.RECORDER_PARTICIPANT_JOINED,
          {
            participantId,
          }
        );
      }
    } else if (role === participantRoles.INTERPRETER) {
      if (
        !interpreterParticipantIds.find(
          (_participantId) => participantId === _participantId
        )
      ) {
        appEventEmitter.emit(
          appEventEmitterEvents.INTERPRETER_PARTICIPANT_JOINED,
          { participantId }
        );
      }
    } else if (role === participantRoles.PARTICIPANT) {
      if (
        !joinedParticipants.find(
          ({ participantId: _participantId }) =>
            participantId === _participantId
        )
      ) {
        appEventEmitter.emit(
          appEventEmitterEvents.SIGNALING_PARTICIPANT_JOINED,
          {
            participantId,
          }
        );
      }
    }
  };

  const _handleOnAllowedEntryRequestParticipantIds = async ({
    allowedEntryRequestParticipantIds,
  }: {
    allowedEntryRequestParticipantIds: allowedEntryRequestParticipantIdsType;
  }) => {
    setAllowedEntryRequestParticipantIds(allowedEntryRequestParticipantIds);

    const notJoinedButAllowedEntries: RemoteParticipant[] = [];

    const signalingClient = signalingClientRef.current;
    const joinedParticipants = joinedParticipantsRef.current;

    signalingClient.participants.forEach((participant) => {
      const participantId = participant.identity;

      if (
        !joinedParticipants.find(
          ({ participantId: _participantId }) =>
            _participantId === participantId
        ) &&
        !!allowedEntryRequestParticipantIds.find(
          ({ participantId: _participantId }) =>
            _participantId === participantId
        )
      ) {
        notJoinedButAllowedEntries.push(participant);
      }
    });

    await sleep(200);

    for (let index = 0; index < notJoinedButAllowedEntries.length; index++) {
      const participant = notJoinedButAllowedEntries[index];

      _handleOnParticipantConnected(participant);

      await sleep(200);
    }
  };

  const resetAllowedEntryRequestParticipantIds = (args?: {
    forceAllowSelf?: boolean;
  }) => {
    const signalingClient = signalingClientRef.current;

    if (!signalingClient) {
      return;
    }

    const allowedEntryRequestParticipantIds: allowedEntryRequestParticipantIdsType =
      [];

    const entryRequestAllowed = safeParseJson(
      signalingClient.localParticipant.metadata
    ).entryRequestAllowed;

    if (entryRequestAllowed || args?.forceAllowSelf) {
      allowedEntryRequestParticipantIds.push({
        participantId: signalingClient.localParticipant.identity,
        sid: signalingClient.localParticipant.sid,
      });
    }

    signalingClient.participants.forEach((participant) => {
      const entryRequestAllowed = safeParseJson(
        participant.metadata
      ).entryRequestAllowed;

      if (entryRequestAllowed) {
        allowedEntryRequestParticipantIds.push({
          participantId: participant.identity,
          sid: participant.sid,
        });
      }
    });

    _handleOnAllowedEntryRequestParticipantIds({
      allowedEntryRequestParticipantIds,
    });
  };

  const init = async (): Promise<void> => {
    const signalingClient = signalingClientRef.current;
    const participants = signalingClient.participants;
    const localParticipant = signalingClient.localParticipant;

    const localParticipantParsedMetadata = safeParseJson(
      localParticipant.metadata
    );

    const localParticipantAppMode =
      localParticipantParsedMetadata?.appMode as appModeType;

    const joinedParticipants: joinedParticipantsType =
      localParticipantAppMode == appModes.HOST ||
      localParticipantAppMode === appModes.SPEAKER
        ? [
            {
              appMode: safeParseJson(localParticipant.metadata)
                ?.appMode as appModeType,
              originalAppMode: safeParseJson(localParticipant.metadata)
                ?.originalAppMode as appModeType,
              studioUserId: safeParseJson(localParticipant.metadata)
                ?.studioUserId as string,
              participantId: localParticipant.identity as string,
              participantName: localParticipant.name as string,
              timestamp: localParticipant.joinedAt?.getTime() as number,
              localTimeThreshold:
                new Date().getTime() -
                (localParticipant.joinedAt?.getTime() as number),
            },
          ]
        : [];

    studioParticipantIdLivekitSidMapRef.current.set(
      localParticipant.identity,
      localParticipant.sid
    );

    const interpreterParticipantIds: string[] = [];
    const recorderParticipantIds: string[] = [];

    [...participants.keys()].forEach((key) => {
      const participant = participants.get(key);

      if (participant) {
        const parsedMetadata = safeParseJson(participant.metadata);

        const appMode = parsedMetadata?.appMode as appModeType;
        const originalAppMode = parsedMetadata?.originalAppMode as appModeType;
        const studioUserId = parsedMetadata?.studioUserId as string;
        const role = parsedMetadata?.role as participantRolesType;
        const entryRequestAllowed = parsedMetadata.entryRequestAllowed;
        const timestamp = participant.joinedAt?.getTime() as number;
        const localTimeThreshold = new Date().getTime() - timestamp;

        const participantId = participant.identity as string;
        const participantName = participant.name as string;
        const participantSid = participant.sid;

        studioParticipantIdLivekitSidMapRef.current.set(
          participantId,
          participantSid
        );

        if (entryRequestAllowed) {
          if (role === participantRoles.RECORDER) {
            recorderParticipantIds.push(participantId);
          } else if (role === participantRoles.INTERPRETER) {
            interpreterParticipantIds.push(participantId);
          } else if (role === participantRoles.PARTICIPANT) {
            joinedParticipants.push({
              appMode,
              participantId,
              studioUserId,
              timestamp,
              participantName,
              localTimeThreshold,
              originalAppMode,
            });
          }
        }
      }
    });

    resetAllowedEntryRequestParticipantIds();

    await sleep(50);

    await setBrandsAndAssets();

    await sleep(50);

    const oldestJoinedParticipantId = getOldestJoinedParticipantId();

    if (oldestJoinedParticipantId !== localParticipant.identity) {
      appEventEmitter.emit(appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH, {
        topic: initReqOldDataFrom(oldestJoinedParticipantId),
        message: {},
      });

      await sleep(2000);

      if (!oldStatesReceived.current) {
        return init();
      }
    } else {
      if (activeBrandIdRef.current) {
        await activatAssetsOfBrand({ id: activeBrandIdRef.current });
      }
    }
  };

  const _handleOnParticipantDisconnected = (participant: RemoteParticipant) => {
    const participantId = participant.identity;
    const parsedMetadata = safeParseJson(participant.metadata);

    const role = parsedMetadata?.role as participantRolesType;
    const studioUserId = parsedMetadata?.studioUserId;

    studioParticipantIdLivekitSidMapRef.current.delete(participantId);

    if (role === participantRoles.RECORDER) {
      appEventEmitter.emit(appEventEmitterEvents.RECORDER_PARTICIPANT_LEFT, {
        participantId,
        studioUserId,
      });
    } else if (role === participantRoles.INTERPRETER) {
      appEventEmitter.emit(appEventEmitterEvents.INTERPRETER_PARTICIPANT_LEFT, {
        participantId,
        studioUserId,
      });
    } else if (role === participantRoles.PARTICIPANT) {
      appEventEmitter.emit(appEventEmitterEvents.SIGNALING_PARTICIPANT_LEFT, {
        participantId,
        studioUserId,
      });
    }
  };

  const _handleOnConnected = () => {};

  const _handleOnDisconnected = (reason: DisconnectReason | undefined) => {
    console.log(reason, "_handleOnDisconnected");

    appEventEmitter.emit(appEventEmitterEvents.LOCAL_PARTICIPANT_LEFT);
  };

  const getOldStates = () => {
    const appContext = appContextRef.current;

    return oldStatesValueSetterKeys.map(({ valueKey, setterKey, isMap }) => ({
      setterKey: setterKey,
      value: isMap
        ? convertMapToObjectArray(appContext[valueKey] as Map<unknown, unknown>)
        : appContext[valueKey],
      isMap,
    }));
  };

  const setOldStates = (
    statesAndSetters: {
      setterKey: appContextDefaultStateSettersType;
      value: unknown;
      isMap?: boolean;
    }[]
  ) => {
    oldStatesReceived.current = true;

    for (let index = 0; index < statesAndSetters.length; index++) {
      const {
        setterKey,
        value,
        isMap,
      }: {
        setterKey: appContextDefaultStateSettersType;
        value: unknown;
        isMap?: boolean;
      } = statesAndSetters[index];

      const setter = appContextRef.current[setterKey] as (t: unknown) => void;

      setter(
        isMap
          ? convertObjectArrayToMap(value as { K: unknown; V: unknown }[])
          : value
      );
    }
  };

  const _handleSendOldData = async (participant: RemoteParticipant) => {
    const participantId = participant.identity;

    await waitUntillReadyToSendOldStates();

    const statesAndSetters = getOldStates();

    appEventEmitter.emit(appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH, {
      topic: resolveReqOldDataTo(participantId),
      message: { statesAndSetters },
    });
  };

  const _handleOnDataReceived = (
    payload: Uint8Array,
    participant?: RemoteParticipant | undefined,
    _?: DataPacket_Kind | undefined,
    topic?: string | undefined
  ) => {
    if (!topic || !participant || !payload) {
      return;
    }

    const participantId = participant.identity;

    const message = safeParseJson(decoder.decode(payload));

    if (
      topic ===
      initReqOldDataFrom(signalingClientRef.current.localParticipant.identity)
    ) {
      _handleSendOldData(participant);

      return;
    }

    if (
      topic ===
      resolveReqOldDataTo(signalingClientRef.current.localParticipant.identity)
    ) {
      const { statesAndSetters } = message;

      setOldStates(statesAndSetters);

      return;
    }

    if (
      topic ===
      initReqCurrentTimeFrom(
        signalingClientRef.current.localParticipant.identity
      )
    ) {
      const timestamp = new Date().getTime();

      const participantId = participant.identity;

      const { id } = message;

      appEventEmitter.emit(appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH, {
        topic: resolveReqCurrentTimeTo(participantId),
        message: { id, timestamp },
      });

      return;
    }

    if (
      topic ===
      resolveReqCurrentTimeTo(
        signalingClientRef.current.localParticipant.identity
      )
    ) {
      const { id, timestamp } = message;

      appEventEmitter.emit(appEventEmitterEvents.RESOLVE_REQ_CURRENT_TIME(id), {
        timestamp,
      });

      return;
    }

    appEventEmitter.emit(
      appEventEmitterEvents.SIGNALING_PUBSUB_SUBSCRIBE(topic),
      { message, participantId }
    );

    return;
  };

  const _handleSignalingPubSubPublish = async ({
    topic,
    message,
  }: {
    topic: string;
    message: unknown;
  }) => {
    const signalingClient = signalingClientRef.current;

    const data = encoder.encode(
      typeof message === "string" ? message : JSON.stringify(message)
    );

    appEventEmitter.emit(
      appEventEmitterEvents.SIGNALING_PUBSUB_SUBSCRIBE(topic),
      {
        message,
        participantId: signalingClient.localParticipant.identity,
      }
    );

    await signalingClient.localParticipant.publishData(
      data,
      DataPacket_Kind.RELIABLE,
      { topic }
    );
  };

  const _handleInitReqCurrentTime = ({ id }: { id: string }) => {
    const oldestJoinedParticipantId = getOldestJoinedParticipantId();

    const signalingClient = signalingClientRef.current;

    if (
      oldestJoinedParticipantId === signalingClient.localParticipant.identity
    ) {
      appEventEmitter.emit(appEventEmitterEvents.RESOLVE_REQ_CURRENT_TIME(id), {
        timestamp: new Date().getTime(),
      });
    } else {
      appEventEmitter.emit(appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH, {
        topic: initReqCurrentTimeFrom(oldestJoinedParticipantId),
        message: { id },
      });
    }
  };

  const _handleLeaveFromSession = async () => {
    voluntarilyLeftSession.current = true;

    await signalingClientRef.current.disconnect();
  };

  const _handleAllowSelfEntryRequest = () => {
    const signalingClient = signalingClientRef.current;

    if (!signalingClient) {
      return;
    }

    const oldMetadata = safeParseJson(
      signalingClient.localParticipant.metadata
    );

    const newMetadaa = { ...oldMetadata, entryRequestAllowed: true };

    signalingClient.localParticipant.setMetadata(JSON.stringify(newMetadaa));

    resetAllowedEntryRequestParticipantIds({ forceAllowSelf: true });
  };

  const _handleAssignSelfRole = async ({
    message,
  }: {
    message: { appMode: appModeType; role: participantRoleType };
  }) => {
    const { appMode, role } = message;

    const signalingClient = signalingClientRef.current;

    if (!signalingClient) {
      return;
    }

    const oldMetadata = safeParseJson(
      signalingClient.localParticipant.metadata
    );

    const oldRole = oldMetadata.role;

    if (
      oldRole === participantRoles.PARTICIPANT &&
      role === participantRoles.INTERPRETER
    ) {
      setActiveInterpretationInputAgoraChannelId(
        interpretationsRef.current.filter(
          ({ agoraChannelId }) => agoraChannelId
        )[0].agoraChannelId as string
      );

      await sleep(500);

      appEventEmitter.emit(
        appEventEmitterEvents.RTC_TURN_OFF_MEDIA_AND_PUBLICATIONS
      );
    } else if (
      oldRole === participantRoles.INTERPRETER &&
      role === participantRoles.PARTICIPANT
    ) {
      setActiveInterpretationInputAgoraChannelId(null);

      await sleep(500);

      appEventEmitter.emit(
        appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_OFF_MIC
      );
    }

    const newMetadaa = { ...oldMetadata, role, appMode };

    signalingClient.localParticipant.setMetadata(JSON.stringify(newMetadaa));
  };

  const _handleParticipantEntryRequestDenied = ({
    message,
  }: {
    message: { participantId: string };
  }) => {
    if (message.participantId === localParticipantIdRef.current) {
      appEventEmitter.emit(
        appEventEmitterEvents.LOCAL_PARTICIPANT_ENTRY_REQUEST_DENIED
      );
    }
  };

  const _handleOnEntryRequestAllowed = (
    participant: LocalParticipant | RemoteParticipant
  ) => {
    const parsedMetadata = safeParseJson(participant.metadata);

    const entryRequestAllowed = parsedMetadata.entryRequestAllowed;

    if (entryRequestAllowed) {
      resetAllowedEntryRequestParticipantIds();

      if (participant.identity === localParticipantIdRef.current) {
        init();
      }
    }
  };

  const _handleOnLocalParticipantNewRoleAssigned = (
    participant: LocalParticipant | RemoteParticipant
  ) => {
    if (participant.identity === localParticipantIdRef.current) {
      const parsedMetadata = safeParseJson(participant.metadata);

      const role = parsedMetadata?.role as participantRolesType;
      const newAppMode = parsedMetadata?.appMode as appModeType;
      const newIsInterpreter = role === participantRoles.INTERPRETER;
      const newIsRecorder = role === participantRoles.RECORDER;

      const oldAppMode = appModeRef.current;
      const oldIsInterpreter = isInterpreterRef.current;
      const oldIsRecorder = isRecorderRef.current;

      if (newAppMode !== oldAppMode) {
        setAppMode(newAppMode);
      }

      if (newIsInterpreter !== oldIsInterpreter) {
        setIsInterpreter(newIsInterpreter);
      }

      if (newIsRecorder !== oldIsRecorder) {
        setIsRecorder(newIsRecorder);
      }

      if (newAppMode === appModes.HOST) {
        setSidePanelMode(sidePanelModes.BRAND);
      } else if (newAppMode === appModes.SPEAKER) {
        setSidePanelMode(sidePanelModes.QNA);
      } else {
        setSidePanelMode(null);
      }
    }
  };

  const _handleOnParticipantMetadataChanged = (
    metadata: string | undefined,
    participant: LocalParticipant | RemoteParticipant
  ) => {
    if (!metadata) {
      return;
    }

    _handleOnEntryRequestAllowed(participant);

    _handleOnLocalParticipantNewRoleAssigned(participant);
  };

  const resettingConnectionState = useRef(false);

  const resetConnectionState = async () => {
    if (resettingConnectionState.current) {
      return;
    }

    resettingConnectionState.current = true;

    try {
      const newConnectionState = signalingClientRef.current.state;
      const oldConnectionState = connectionStateRef.current;

      if (newConnectionState !== oldConnectionState) {
        setConnectionState(newConnectionState);

        if (newConnectionState === "disconnected") {
          //
        }
        if (newConnectionState === "connecting") {
          //
        }
        if (newConnectionState === "connected") {
          if (oldConnectionState === "reconnecting") {
            await init();
          }
        }
        if (newConnectionState === "reconnecting") {
          //
        }
      }

      if (newConnectionState === "reconnecting") {
        if (!reconnectingTimeout.current) {
          reconnectingTimeout.current = new Date().getTime();
        }

        if (new Date().getTime() - 60000 > reconnectingTimeout.current) {
          await signalingClientRef.current.disconnect();

          appEventEmitter.emit(
            appEventEmitterEvents.DISCONNECTED_OR_FAILED_TO_JOIN
          );
        }
      }
    } catch (error) {
      //
    }

    resettingConnectionState.current = false;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      resetConnectionState();

      resetAllowedEntryRequestParticipantIds();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    init();

    const signalingClient = signalingClientRef.current;

    signalingClient.on(
      RoomEvent.ParticipantDisconnected,
      _handleOnParticipantDisconnected
    );

    signalingClient.on(RoomEvent.Disconnected, _handleOnDisconnected);

    signalingClient.on(RoomEvent.DataReceived, _handleOnDataReceived);

    signalingClient.on(
      RoomEvent.ParticipantMetadataChanged,
      _handleOnParticipantMetadataChanged
    );

    appEventEmitter.on(
      appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH,
      _handleSignalingPubSubPublish
    );

    appEventEmitter.on(
      appEventEmitterEvents.INIT_REQ_CURRENT_TIME,
      _handleInitReqCurrentTime
    );

    appEventEmitter.on(
      appEventEmitterEvents.LOCAL_PARTICIPANT_JOINED,
      _handleOnConnected
    );

    appEventEmitter.on(
      appEventEmitterEvents.LEAVE_FROM_SESSION,
      _handleLeaveFromSession
    );

    appEventEmitter.on(
      appEventEmitterEvents.SIGNALING_PUBSUB_SUBSCRIBE(
        allowParticipantEntryRequestTopicByParticipantId(localParticipantId)
      ),
      _handleAllowSelfEntryRequest
    );

    appEventEmitter.on(
      appEventEmitterEvents.SIGNALING_PUBSUB_SUBSCRIBE(
        appPubSubTopics.PARTICIPANT_ENTRY_REQUEST_DENIED
      ),
      _handleParticipantEntryRequestDenied
    );

    appEventEmitter.on(
      appEventEmitterEvents.SIGNALING_PUBSUB_SUBSCRIBE(
        assignSelfRoleTopicByParticipantId(localParticipantId)
      ),
      _handleAssignSelfRole
    );

    return () => {
      signalingClient.disconnect();

      signalingClient.off(
        RoomEvent.ParticipantDisconnected,
        _handleOnParticipantDisconnected
      );

      signalingClient.off(RoomEvent.Disconnected, _handleOnDisconnected);

      signalingClient.off(RoomEvent.DataReceived, _handleOnDataReceived);

      signalingClient.off(
        RoomEvent.ParticipantMetadataChanged,
        _handleOnParticipantMetadataChanged
      );

      appEventEmitter.off(
        appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH,
        _handleSignalingPubSubPublish
      );

      appEventEmitter.off(
        appEventEmitterEvents.INIT_REQ_CURRENT_TIME,
        _handleInitReqCurrentTime
      );

      appEventEmitter.off(
        appEventEmitterEvents.LOCAL_PARTICIPANT_JOINED,
        _handleOnConnected
      );

      appEventEmitter.off(
        appEventEmitterEvents.LEAVE_FROM_SESSION,
        _handleLeaveFromSession
      );

      appEventEmitter.off(
        appEventEmitterEvents.SIGNALING_PUBSUB_SUBSCRIBE(
          allowParticipantEntryRequestTopicByParticipantId(localParticipantId)
        ),
        _handleAllowSelfEntryRequest
      );

      appEventEmitter.off(
        appEventEmitterEvents.SIGNALING_PUBSUB_SUBSCRIBE(
          appPubSubTopics.PARTICIPANT_ENTRY_REQUEST_DENIED
        ),
        _handleParticipantEntryRequestDenied
      );

      appEventEmitter.on(
        appEventEmitterEvents.SIGNALING_PUBSUB_SUBSCRIBE(
          assignSelfRoleTopicByParticipantId(localParticipantId)
        ),
        _handleAssignSelfRole
      );
    };
  }, []);

  return <React.Fragment />;
};

export default LivekitSignalingListner;
