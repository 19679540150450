import { useEffect, useRef, useState } from "react";
import QNAPanel from "../sidePanel/QNAPanel";

const MainViewQNAShareStreamContainer = ({
  relativeWidth,
  mainViewContainerWidth,
}: {
  relativeWidth: number;
  mainViewContainerWidth: number;
}) => {
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const containerHeightRef = useRef(containerHeight);
  const containerWidthRef = useRef(containerWidth);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);
  useEffect(() => {
    containerWidthRef.current = containerWidth;
  }, [containerWidth]);

  const calculateContainerSize = () => {
    const element = document.getElementById(
      "main-view-qna-share-stream-container"
    );

    if (element) {
      const boundingRect = element.getBoundingClientRect();
      const { width, height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
      containerWidthRef.current !== width && setContainerWidth(width);
    }
  };

  const intervalRef = useRef<null | ReturnType<typeof setInterval>>();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      calculateContainerSize();
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <div id={"main-view-qna-share-stream-container"} className="h-full w-full">
      <QNAPanel
        {...{
          containerHeight,
          containerWidth,
          streamingView: true,
          relativeWidth,
          mainViewContainerWidth,
          alwaysScrollToBottom: true,
        }}
      />
    </div>
  );
};

export default MainViewQNAShareStreamContainer;
