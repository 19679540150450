import { createUID } from "../../utils/createUID";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateSpeakerCanTakeAction from "../appState/useValidateSpeakerCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

export const fileShareStreamTypes = {
  PPT: "PPT",
  PDF: "PDF",
};

export const fileShareStreamActions = {
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
};

const useFileShareStreams = () => {
  const { publish: fileShareStreamPublish } = useAppSingalingPublish(
    appPubSubTopics.FILE_SHARE_STREAM_ENABLED
  );

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  const enableFileShareStream = async ({
    remoteUrl,
    type,
    pdfInfo,
    id: _id,
    resourceId,
  }: {
    remoteUrl: string;
    type: string;
    pdfInfo: pdfInfoType;
    id?: string;
    resourceId: string;
  }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    if (
      type === fileShareStreamTypes.PPT ||
      type === fileShareStreamTypes.PDF
    ) {
      const id = _id || createUID();

      fileShareStreamPublish(
        JSON.stringify({
          id,
          type,
          remoteUrl,
          resourceId,
          pdfInfo,
          action: fileShareStreamActions.ENABLE,
        })
      );
    }
  };

  const disableFileShareStream = async ({ id }: { id: string }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    fileShareStreamPublish(
      JSON.stringify({ id, action: fileShareStreamActions.DISABLE })
    );
  };

  return { enableFileShareStream, disableFileShareStream };
};

export default useFileShareStreams;
