import React, { useEffect, useRef, useState } from "react";
import { MdVolumeOff } from "react-icons/md";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { useAppContext } from "../../contexts/appContextDef";

const MainViewUserInteractButtonVideoClipRendered = () => {
  const [muteDueToError, setMuteDueToError] = useState(false);

  const muteDueToErrorRef = useRef(muteDueToError);

  useEffect(() => {
    muteDueToErrorRef.current = muteDueToError;
  }, [muteDueToError]);

  const _handleMainViewVideoClipPlayErrored = () => {
    if (muteDueToErrorRef.current) return;

    setMuteDueToError(true);
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.MAIN_VIEW_VIDEO_CLIP_PLAY_ERRORED,
      _handleMainViewVideoClipPlayErrored
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.MAIN_VIEW_VIDEO_CLIP_PLAY_ERRORED,
        _handleMainViewVideoClipPlayErrored
      );
    };
  }, []);

  return muteDueToError ? (
    <div className="absolute bottom-3 left-0 right-0 flex items-center justify-center">
      <button
        onClick={() => {
          setMuteDueToError(false);

          appEventEmitter.emit(
            appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED
          );
        }}
        className="btn btn-outline text-white normal-case gap-2 bg-black bg-opacity-40"
      >
        <MdVolumeOff size={20} />
        Tap for sound
      </button>
    </div>
  ) : (
    <React.Fragment />
  );
};

const MainViewUserInteractButtonVideoClip = () => {
  const { mainViewVideoClip } = useAppContext();

  return mainViewVideoClip.id && mainViewVideoClip.remoteUrl ? (
    <MainViewUserInteractButtonVideoClipRendered />
  ) : (
    <React.Fragment />
  );
};

export default MainViewUserInteractButtonVideoClip;
