import React, { useEffect, useMemo, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useParticipantAvatar from "../../hooks/appState/useParticipantAvatar";
import { MdPushPin, MdOutlinePushPin } from "react-icons/md";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import usePinnedParticipantsPosition from "../../hooks/conference/usePinnedParticipantsPosition";
import { isHexLight } from "../../utils/isHexLight";
import useIsActiveSpeaker from "../../hooks/appState/useIsActiveSpeaker";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import ActiveSpeakerAnimation from "../../components/ActiveSpeakerAnimation";
import { appModes, interactivityModes } from "../../utils/constants";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
import useAppRtcParticipantMediaStats from "../../appRtc/useAppRtcParticipantMediaStats";

export const ParticipantWebcamPinContainer = ({
  participantId,
}: {
  participantId: string;
}) => {
  const {
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
    brandColor,
    appMode,
  } = useAppContext();

  const { backgroundColor, isBackgroundLight } = useMemo(() => {
    const backgroundColor = brandColor?.hexColorCode || "#000000";

    const isBackgroundLight = isHexLight(backgroundColor);

    return {
      backgroundColor,
      isBackgroundLight,
    };
  }, [brandColor]);

  const { pinned, index } = useMemo(() => {
    const isInConferenceMainViewIndex =
      conferenceMainViewVisibleWebcamStreams.findIndex(
        ({ participantId: _participantId }) => _participantId === participantId
      );

    const isInConferenceMainView =
      conferenceMainViewVisibleWebcamStreams[isInConferenceMainViewIndex];

    return {
      pinned: isInConferenceMainView?.pinned,
      index: isInConferenceMainViewIndex,
      isInMainView: isInConferenceMainViewIndex !== -1,
    };
  }, [conferenceMainViewVisibleWebcamStreams, participantId]);

  const pinnedRef = useRef(pinned);
  const indexRef = useRef(index);

  useEffect(() => {
    pinnedRef.current = pinned;
  }, [pinned]);
  useEffect(() => {
    indexRef.current = index;
  }, [index]);

  const { pin, unPin } = usePinnedParticipantsPosition();

  const _handleTogglePin = () => {
    if (pinnedRef.current) {
      unPin({ participantId });
    } else {
      pin({ participantId, positionIndex: indexRef.current });
    }
  };

  return interactivityMode === interactivityModes.CONFERENCE &&
    appMode === appModes.HOST ? (
    <div
      style={{ backgroundColor }}
      className="mr-1 rounded-full p-1 aspect-square h-full cursor-pointer"
      onClick={_handleTogglePin}
    >
      {pinned ? (
        <MdPushPin size={20} fill={isBackgroundLight ? "#000" : "#fff"} />
      ) : (
        <MdOutlinePushPin
          size={20}
          fill={isBackgroundLight ? "#000" : "#fff"}
        />
      )}
    </div>
  ) : (
    <React.Fragment />
  );
};

const MainViewWebcamStreamContainer = ({
  streamId,
  participantId,
}: {
  streamId: string;
  participantId: string;
}) => {
  const { mirrorLocalWebcam } = useAppContext();

  const { webcamTrack } = useAppRtcParticipantMediaStats(participantId);

  const { isLocal } = useIsLocalParticipantId(participantId);

  const { webcamOn } = useParticipantMediaStats(participantId);

  const { isActiveSpeaker } = useIsActiveSpeaker({ participantId });

  const { avatarRemoteUrl } = useParticipantAvatar(participantId);

  const flipStyle = useMemo(
    () =>
      isLocal && mirrorLocalWebcam
        ? { transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }
        : {},
    [isLocal, mirrorLocalWebcam]
  );

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      className={`relative cursor-pointer rounded`}
      id={`MainViewWebcamStreamContainer-${streamId}`}
    >
      {webcamOn && webcamTrack ? (
        <VideoMediaStreamPlayer
          {...{
            flipStyle,
            videoOn: webcamOn,
            videoTrack: webcamTrack,
            key: `VideoMediaStreamPlayer-webcam-stream-${webcamTrack.id}-${participantId}`,
            participantId,
            isWebcam: true,
            rounded: true,
          }}
        />
      ) : (
        <div className="h-full w-full flex items-center justify-center bg-gray-700 rounded">
          <div
            style={{ height: `${(100 / 3) * 2}%` }}
            className="rounded-full relative aspect-square"
          >
            {isActiveSpeaker && <ActiveSpeakerAnimation />}
            <div
              style={{ height: `50%` }}
              className="absolute aspect-square transition-all top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow-2xl rounded-full overflow-hidden"
            >
              <img
                alt={`in-queue-webcam-stream-avatar`}
                className="rounded-full h-full w-full z-50"
                src={avatarRemoteUrl}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainViewWebcamStreamContainer;
