import React from "react";
import { useAppContext } from "../../contexts/appContextDef";

const MainViewLogoContainer = () => {
  const { brandLogo } = useAppContext();

  return brandLogo.remoteUrl && brandLogo.id ? (
    <div
      style={{ height: "15%" }}
      className="aspect-square absolute top-3 right-3 pointer-events-none"
    >
      <img
        alt={`main-view-brand-logo-${brandLogo.id}`}
        src={brandLogo.remoteUrl}
        className="h-full w-full object-contain"
      />
    </div>
  ) : (
    <React.Fragment />
  );
};

export default MainViewLogoContainer;
