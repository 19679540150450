import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";

const useMainViewBackground = () => {
  const { publish } = useAppSingalingPublish(
    appPubSubTopics.MAIN_VIEW_BACKGROUND
  );
  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const changeBackground = async (
    background: singleBrandBackgroundType,
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(JSON.stringify(background));
  };

  return { changeBackground };
};

export default useMainViewBackground;
