import { useMemo } from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { useAppConfigContext } from "../contexts/appConfigDef";
import { useAppContext } from "../contexts/appContextDef";
import IconButton, {
  iconButtonVariants,
} from "../containers/bottomControlsContainer/IconButton";

const InputInterpretationsContainer = () => {
  const { interpretations } = useAppConfigContext();
  const {
    activeInterpretationInputAgoraChannelId,
    setActiveInterpretationInputAgoraChannelId,
    leavingAgoraChannel,
    joiningAgoraChannel,
  } = useAppContext();

  const maxTitle = useMemo(() => {
    let maxTitle = "Off";

    interpretations.forEach(({ name }) => {
      if (name.length > maxTitle.length) {
        maxTitle = name;
      }
    });

    return maxTitle;
  }, [interpretations]);

  const activeInterpretations = useMemo(() => {
    return interpretations.find(
      ({ agoraChannelId }) =>
        agoraChannelId === activeInterpretationInputAgoraChannelId
    );
  }, [interpretations, activeInterpretationInputAgoraChannelId]);

  return (
    <Menu
      direction="top"
      menuButton={
        <div>
          <IconButton
            {...{
              disabled: joiningAgoraChannel || leavingAgoraChannel,
              title: activeInterpretations ? activeInterpretations.name : "Off",
              popperTitle: "",
              variant: iconButtonVariants.info,
              maxTitle,
              isProcessing: joiningAgoraChannel || leavingAgoraChannel,
              isMenuButton: true,
              imageIcon: activeInterpretations?.icon as string,
            }}
          />
        </div>
      }
      transition
    >
      {[...interpretations].map(
        ({
          agoraChannelId,
          name,
          // icon
        }) => {
          const isActive =
            !agoraChannelId && !activeInterpretationInputAgoraChannelId
              ? true
              : agoraChannelId === activeInterpretationInputAgoraChannelId;

          return (
            <MenuItem
              disabled={joiningAgoraChannel || leavingAgoraChannel}
              type="checkbox"
              checked={isActive}
              key={`interpretations-channel-selector-item-${agoraChannelId}`}
              className={`px-3 py-2  hover:bg-custom-blue-50 text-primary`}
              onClick={() => {
                if (
                  activeInterpretationInputAgoraChannelId !== agoraChannelId
                ) {
                  setActiveInterpretationInputAgoraChannelId(
                    agoraChannelId || null
                  );
                }
              }}
            >
              <div className="flex items-center justify-center gap-1">
                <p className="ml-2">{name}</p>
              </div>
            </MenuItem>
          );
        }
      )}
    </Menu>
  );
};

export default InputInterpretationsContainer;
