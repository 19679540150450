import { useState } from "react";
import { MdSearch, MdClose, MdPanTool } from "react-icons/md";
import { useAppContext } from "../../contexts/appContextDef";
import useIsMobilePortraitOrLandscape from "../../hooks/responsive/useIsMobilePortraitOrLandscape";
import Tooltip from "../../components/Tooltip";

const FilterInqueueStreams = () => {
  const [searchEnabled, setSearchEnabled] = useState(false);

  const {
    setFilteredInQueueStreamsSearchQuery,
    filteredInQueueStreamsSearchQuery,
    setRaiseHandleSeachEnabled,
    raiseHandleSeachEnabled,
  } = useAppContext();

  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  return (
    <div
      className={`h-full p-1 pr-2 flex ${
        isMobilePortraitOrLandscape ? "" : "border-r-2"
      }`}
    >
      <div className="h-full">
        <Tooltip title={"Search Speakers"}>
          <div className={"tooltip-right relative"}>
            <button
              onClick={() => {
                setSearchEnabled((s) => !s);
              }}
              className="btn btn-primary text-white"
            >
              <MdSearch size={16} />
            </button>
            {filteredInQueueStreamsSearchQuery?.length > 0 ||
            raiseHandleSeachEnabled ? (
              <div className="absolute -top-1 -right-1 w-3 h-3 rounded-full  bg-green-500"></div>
            ) : null}
            {searchEnabled ? (
              <div className="bg-custom-blue-50 rounded-md absolute top-0 left-0 z-50 p-2 h-16 flex items-center justify-center border border-custom-blue-300">
                <div className="flex items-center justify-center relative">
                  <input
                    autoFocus
                    placeholder="Search here.."
                    className="input pr-12"
                    value={filteredInQueueStreamsSearchQuery}
                    onChange={(e) => {
                      setFilteredInQueueStreamsSearchQuery(e.target.value);
                    }}
                  />
                  <div
                    onClick={() => {
                      setFilteredInQueueStreamsSearchQuery("");
                    }}
                    className="absolute cursor-pointer right-0 top-0 bottom-0 w-12 flex items-center justify-center"
                  >
                    <MdClose />
                  </div>
                </div>

                <div
                  onClick={() => {
                    setRaiseHandleSeachEnabled((s) => !s);
                  }}
                  className="ml-2 flex items-center justify-center"
                >
                  <button>
                    <MdPanTool
                      size={20}
                      className={`${
                        raiseHandleSeachEnabled
                          ? "fill-custom-blue-900"
                          : "fill-custom-blue-400"
                      }`}
                    />
                  </button>
                </div>

                <div className="ml-2">
                  <button
                    className="btn btn-primary text-white btn-sm"
                    onClick={() => {
                      setSearchEnabled((s) => !s);
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default FilterInqueueStreams;
