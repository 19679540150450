import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useMainViewLayout from "../../hooks/appState/useMainViewLayout";
import useValidateConnection from "../../hooks/appState/useValidateConnection";
import useIsOldest from "../../hooks/appState/useIsOldest";
import useGetCalculatedConferenceModeAutoLayout from "../../hooks/conference/useGetCalculatedConferenceModeAutoLayout";
import { interactivityModes } from "../../utils/constants";

const ConferenceAutoLayoutListner = () => {
  const stopCalculateConferenceAutoLayoutIntervalRef = useRef<null | ReturnType<
    typeof setInterval
  >>();

  const {
    interactivityMode,
    conferenceAutoMainVideoLayoutEnabled,
    mainViewLayout,
  } = useAppContext();

  const mainViewLayoutRef = useRef(mainViewLayout);

  useEffect(() => {
    mainViewLayoutRef.current = mainViewLayout;
  }, [mainViewLayout]);

  const { changeMainViewLayout } = useMainViewLayout();
  const { getIsOldestParticipant } = useIsOldest();
  const { getIsSignalLevelValid } = useValidateConnection();
  const { getCalculatedConferenceModeAutoLayout } =
    useGetCalculatedConferenceModeAutoLayout();

  const calculateConferenceAutoLayout = async () => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (!isOldestParticipant) return;

    const oldMainViewLayout = mainViewLayoutRef.current;

    const { mainViewLayout: newMainViewLayout } =
      getCalculatedConferenceModeAutoLayout();

    if (newMainViewLayout === oldMainViewLayout) return;

    const { isSignalLevelValid } = getIsSignalLevelValid();

    if (!isSignalLevelValid) return;

    changeMainViewLayout({
      layout: newMainViewLayout,
      conferenceAutoMainVideoLayoutEnabled: true,
    });
  };

  const startCalculateConferenceAutoLayout = () => {
    if (stopCalculateConferenceAutoLayoutIntervalRef.current) return;

    stopCalculateConferenceAutoLayoutIntervalRef.current = setInterval(() => {
      calculateConferenceAutoLayout();
    }, 500);
  };

  const stopCalculateConferenceAutoLayout = () => {
    if (!stopCalculateConferenceAutoLayoutIntervalRef.current) return;

    const calculatedConferenceModeMaxParticipantCountInterval =
      stopCalculateConferenceAutoLayoutIntervalRef.current;

    stopCalculateConferenceAutoLayoutIntervalRef.current = null;

    clearInterval(calculatedConferenceModeMaxParticipantCountInterval);
  };

  useEffect(() => {
    if (
      interactivityMode === interactivityModes.CONFERENCE &&
      conferenceAutoMainVideoLayoutEnabled
    ) {
      startCalculateConferenceAutoLayout();
    } else {
      stopCalculateConferenceAutoLayout();
    }
  }, [interactivityMode, conferenceAutoMainVideoLayoutEnabled]);

  return <React.Fragment />;
};

export default ConferenceAutoLayoutListner;
