import { customBlueColor } from "../../utils/colors";

const CinemaIconActive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101.223 69.818"
    enableBackground="new 0 0 101.223 69.818"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{ fill: customBlueColor[900] }}
      d="M8.945 13.21h83.334v43.398H8.945z"
    />
  </svg>
);

export default CinemaIconActive;
