// import useValidateConnection from "./useValidateConnection";

import { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";

const useValidateSpeakerCanTakeAction = () => {
  const { isJoined } = useAppContext();

  const isJoinedRef = useRef(isJoined);

  useEffect(() => {
    isJoinedRef.current = isJoined;
  }, [isJoined]);

  // const { getIsSignalLevelValid } = useValidateConnection();

  const validateSpeakerCanTakeAction = async () => {
    // const { isSignalLevelValid } = getIsSignalLevelValid();

    // // other validations comes here
    // const isSpeakerValidToTakeAction = isSignalLevelValid;

    // return { isSpeakerValidToTakeAction };

    return { isSpeakerValidToTakeAction: isJoinedRef.current };
  };

  return { validateSpeakerCanTakeAction };
};

export default useValidateSpeakerCanTakeAction;
