import React, { useEffect, useRef, useState } from "react";
import createWordcloud from "../../../apis/apps/wordclouds/create-wordcloud";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import Modal from "../../../components/Modal";

const CreateNewWordcloudContainer = ({
  isCreating,
  setIsCreating,
}: {
  isCreating: boolean;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { broadcastId } = useAppConfigContext();

  const [question, setQuestion] = useState("");

  const broadcastIdRef = useRef(broadcastId);
  const questionRef = useRef(question);

  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);
  useEffect(() => {
    questionRef.current = question;
  }, [question]);

  const stopCreating = () => {
    setIsCreating(false);
    setQuestion("");
  };

  const _handleCreateNewWordcloud = ({ question }: { question: string }) => {
    createWordcloud({ broadcastId: broadcastIdRef.current, question });

    stopCreating();
  };

  return (
    <Modal
      successText={"Create"}
      isOpen={isCreating}
      cancelText={"Cancel"}
      title={"Create new wordcloud"}
      onClose={() => {
        stopCreating();
      }}
      onSuccess={() => {
        _handleCreateNewWordcloud({
          question: questionRef.current,
        });
      }}
      renderBody={() => {
        return (
          <input
            className="input input-primary input-outline w-full"
            placeholder="Wordcloud question"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
        );
      }}
    />
  );
};

export default CreateNewWordcloudContainer;
