import { momentostreamApiServerBaseUrl } from "../../../utils/constants";

const activateWordcloud = async ({
  broadcastId,
  wordcloudId,
}: {
  broadcastId: string;
  wordcloudId: string;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/activate-wordcloud`;

    const body = JSON.stringify({ broadcastId, wordcloudId });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default activateWordcloud;
