import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import React from "react";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const PreviousStudioMainViewLayoutListner = () => {
  const { setPreviousStudioMainViewLayout } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.PREVIOUS_STUDIO_MAIN_VIEW_LAYOUT,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const previousStudioMainViewLayout = message.previousStudioMainViewLayout;

      setPreviousStudioMainViewLayout(previousStudioMainViewLayout);
    }
  );

  return <React.Fragment />;
};

export default PreviousStudioMainViewLayoutListner;
