import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import { Menu, MenuItem } from "@szhsin/react-menu";
import ChangeNameModal from "../../components/ChangeNameModal";
import {
  MdMoreVert,
  MdOutlineDesktopWindows,
  MdDelete,
  MdRemoveCircle,
} from "react-icons/md";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import useParticipantName from "../../hooks/appState/useParticipantName";

import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";
import { appModes } from "../../utils/constants";
import useAskToLeave from "../../hooks/appState/useAskToLeave";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
import useAppRtcParticipantMediaStats from "../../appRtc/useAppRtcParticipantMediaStats";
import useToggleParticipantMedia from "../../hooks/appState/useToggleParticipantMedia";

const ItemMenuContainer = ({
  isInMainView,
  removeFromMainViewStreams,
  isLocal,
  onStopScreen,
}: {
  setChangeNameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRemove: () => void;
  isInMainView: boolean;
  removeFromMainViewStreams: () => void;
  isLocal: boolean;
  onStopScreen: () => void;
}) => {
  const { appMode } = useAppContext();

  return (
    <Menu
      menuButton={
        <span>
          <button
            className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
          >
            <div>
              <MdMoreVert size={16} />
            </div>
          </button>
        </span>
      }
      transition
    >
      {appMode === appModes.SPEAKER && isInMainView && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={removeFromMainViewStreams}
        >
          <div className="flex items-center justify-center">
            <MdRemoveCircle />
            <p className="ml-2">Remove from stream</p>
          </div>
        </MenuItem>
      )}

      {(appMode === appModes.HOST || isLocal) && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={onStopScreen}
        >
          <div className="flex items-center justify-center">
            <MdDelete />
            <p className="ml-2">
              {appMode === appModes.HOST
                ? "Remove from Studio"
                : "Stop Screen Share"}
            </p>
          </div>
        </MenuItem>
      )}
    </Menu>
  );
};

const InQueueScreenShareStreamContainer = ({
  participantId,
}: {
  participantId: string;
}) => {
  const { screenShareVideoTrack } =
    useAppRtcParticipantMediaStats(participantId);

  const { isLocal } = useIsLocalParticipantId(participantId);

  const { screenShareOn } = useParticipantMediaStats(participantId);

  const { turnOffScreenShare } = useToggleParticipantMedia(participantId);

  const { mainViewSelectedStreams, inQueueItemContainerWidth, appMode } =
    useAppContext();

  const { askToLeave } = useAskToLeave(participantId);

  const { name } = useParticipantName(participantId);

  const [changeNameModalOpen, setChangeNameModalOpen] = useState(false);

  const { isInMainView } = useMemo(() => {
    const streamId = generateStreamId({
      participantId,
      type: streamTypes.SHARE,
      mode: streamModes.SCREEN,
    });

    const isInMainView = !!mainViewSelectedStreams.get(streamId);

    return { isInMainView };
  }, [participantId, mainViewSelectedStreams]);

  const isInMainViewRef = useRef(isInMainView);

  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const _handleAddToMainViewStreams = () => {
    addToMainViewStreams({
      mode: streamModes.SCREEN,
      participantId,
      type: streamTypes.SHARE,
    });
  };

  const _handleRemoveFromMainViewStreams = () => {
    removeFromMainViewStreams({
      mode: streamModes.SCREEN,
      participantId,
      type: streamTypes.SHARE,
    });
  };

  const _handleToggleFromMainViewStreams = () => {
    const _isInMainView = isInMainViewRef.current;

    if (_isInMainView) {
      _handleRemoveFromMainViewStreams();
    } else {
      _handleAddToMainViewStreams();
    }
  };

  const _handleRemoveParticipant = () => {
    // TODO: confirm to remove
    // participantRef.current.remove();

    askToLeave();
  };

  const onStopScreen = () => {
    turnOffScreenShare();
    // disableScreenShare();
  };

  return (
    <React.Fragment>
      <div
        className={`rounded ${
          isLocal
            ? quickstartTargetComponentClassNames.in_queue_local_participant_screen_share_stream_container
            : ""
        }`}
        style={{ width: inQueueItemContainerWidth }}
      >
        <div
          style={{
            height: (inQueueItemContainerWidth * 9) / 16,
            width: inQueueItemContainerWidth,
          }}
          className="relative bg-custom-blue-50"
        >
          {screenShareOn && (
            <React.Fragment>
              <VideoMediaStreamPlayer
                {...{
                  videoOn: screenShareOn,
                  // videoStream: screenShareStream,
                  videoTrack: screenShareVideoTrack,
                  key: `VideoMediaStreamPlayer-screenshare-stream-${screenShareVideoTrack?.id}-${participantId}`,
                  participantId,
                  isShare: true,
                }}
              />
              {!isInMainView && (
                <div className="top-0 left-0 right-0 bottom-0 absolute bg-opacity-50 bg-white"></div>
              )}
            </React.Fragment>
          )}
          <div className="top-0 left-0 right-0 bottom-0 absolute flex justify-between">
            {appMode === appModes.HOST && (
              <div className="p-3 flex flex-1 hover:opacity-100 opacity-0 items-end justify-center">
                <button
                  className="btn btn-primary text-white btn-active"
                  onClick={_handleToggleFromMainViewStreams}
                >
                  {isInMainView ? "Remove" : "Add to stream"}
                </button>
              </div>
            )}

            <div className="absolute top-2 right-2 flex">
              <ItemMenuContainer
                isLocal={isLocal}
                onRemove={_handleRemoveParticipant}
                onStopScreen={onStopScreen}
                setChangeNameModalOpen={setChangeNameModalOpen}
                removeFromMainViewStreams={_handleRemoveFromMainViewStreams}
                isInMainView={isInMainView}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex items-center p-0.5 ${
            isInMainView
              ? "bg-primary text-white fill-white"
              : "bg-white fill-gray-800 text-gray-800"
          } rounded-b-md overflow-hidden`}
        >
          <div className="w-6 flex items-center justify-center">
            <MdOutlineDesktopWindows size={16} />
          </div>
          <p className="truncate text-ellipsis ml-0.5 text-sm">{name}</p>
        </div>
      </div>

      {changeNameModalOpen && (
        <ChangeNameModal
          {...{
            participantId,
            handleCancel: () => {
              setChangeNameModalOpen(false);
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

export default InQueueScreenShareStreamContainer;
