import { useWindowSize } from "@react-hook/window-size";

const AccountFreezedContainer = () => {
  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <div
      style={{ width: windowWidth, height: windowHeight }}
      className="flex flex-1 flex-col items-center justify-center"
    >
      <div className="px-12 py-5 border rounded-lg border-gray-300 max-w-lg">
        <h3 className="text-4xl font-medium">Account blocked</h3>

        <p className="mt-3">
          Can't access session because the account is on hold. Please contact
          admin
        </p>

        <div className="flex mt-5">
          <div>
            <a href="mailto:david@momentosolutions.com">
              <button className="btn btn-outline btn-primary text-white normal-case">
                Contact us
              </button>
            </a>
          </div>
          <div className="flex flex-1 ml-3">
            <button
              onClick={() => {
                window.location.replace("https://momentostream.com/");
              }}
              className="btn btn-block btn-primary text-white normal-case"
            >
              Return to MomentoStream
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountFreezedContainer;
