// @ts-nocheck

import React, { useEffect, useRef } from "react";
import { useAppContext } from "../contexts/appContextDef";
import ReactModal from "react-modal";
import { useAppConfigContext } from "../contexts/appConfigDef";
import useClipboard from "react-use-clipboard";
import { MdClose } from "react-icons/md";

const RtmpSourceModal = () => {
  const { rtmpSourceModalOpen, setRtmpSourceModalOpen } = useAppContext();
  const { thirdPartyRtmpData } = useAppConfigContext();

  const streamUrlInputRef = useRef<HTMLInputElement>();

  const streamKeyInputRef = useRef<HTMLInputElement>();

  const [isCopiedstreamUrl, setCopiedstreamUrl] = useClipboard(
    thirdPartyRtmpData?.streamUrl as string,
    { successDuration: 2000 }
  );

  const [isCopiedstreamKey, setCopiedstreamKey] = useClipboard(
    thirdPartyRtmpData?.streamKey as string,
    { successDuration: 2000 }
  );

  useEffect(() => {
    if (isCopiedstreamUrl) {
      streamUrlInputRef.current?.select();
    }
  }, [isCopiedstreamUrl]);

  useEffect(() => {
    if (isCopiedstreamKey) {
      streamKeyInputRef.current?.select();
    }
  }, [isCopiedstreamKey]);

  return rtmpSourceModalOpen ? (
    <ReactModal
      shouldFocusAfterRender={false}
      style={{
        overlay: { backgroundColor: "#00000066" },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
      isOpen={true}
    >
      <div className="bg-white rounded-lg">
        <div className="flex items-center justify-between border-b border-solid border-slate-200 rounded-t">
          <h4 className="text-xl font-semibold text-primary">RTMP Source</h4>
          <button
            className="btn btn-ghost rounded-full"
            onClick={() => setRtmpSourceModalOpen(false)}
          >
            <MdClose />
          </button>
        </div>

        <div className="flex items-center justify-between mt-3">
          <div>
            <p>
              Bring your stream from 3rd party tools like Zoom or OBS into
              Studio.
            </p>

            <div className="mt-5">
              <div className="w-full flex items-center justify-center">
                <input
                  ref={streamUrlInputRef as React.LegacyRef<HTMLInputElement>}
                  value={thirdPartyRtmpData?.streamUrl}
                  className="flex flex-1 input input-bordered input-primary"
                />
                <div className="ml-3">
                  <button
                    className="btn btn-primary text-white normal-case"
                    onClick={setCopiedstreamUrl}
                  >
                    {isCopiedstreamUrl ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>

              <div className="w-full flex items-center justify-center mt-3">
                <input
                  ref={streamKeyInputRef as React.LegacyRef<HTMLInputElement>}
                  value={thirdPartyRtmpData?.streamKey}
                  className="flex flex-1 input input-bordered input-primary"
                />
                <div className="ml-3">
                  <button
                    className="btn btn-primary text-white normal-case"
                    onClick={setCopiedstreamKey}
                  >
                    {isCopiedstreamKey ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  ) : (
    <React.Fragment />
  );
};

export default RtmpSourceModal;
