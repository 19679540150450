import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useMemo, useRef, useState } from "react";
import { useAppContext } from "../contexts/appContextDef";
import { MdAdd } from "react-icons/md";
import { useAppConfigContext } from "../contexts/appConfigDef";
import addBroadcastAsset from "../apis/broadcasts/add-broadcast-asset";
import useBroadcastAssets from "../hooks/appState/useBroadcastAssets";
import { safeParseJson } from "../utils/safeParseJson";
import Tooltip from "./Tooltip";

const ChangeAvatarModal = ({
  isOpen,
  handleCancel,
  handleSave,
  src: _src,
}: {
  isOpen: boolean;
  handleCancel: () => void;
  handleSave: (t: { remoteUrl: string; isOld: boolean }) => void;
  src: string;
}) => {
  const [src, setSrc] = useState(_src);

  const { localParticipantsAvatars, setFileUploadError } = useAppContext();
  const { broadcastId, studioUserId } = useAppConfigContext();
  const { addAsset } = useBroadcastAssets();

  const avatars = useMemo(
    () => [
      {
        src: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/user-avatar.png",
      },
      ...localParticipantsAvatars.map((src) => ({ src, isOld: true })),
    ],
    [localParticipantsAvatars]
  );

  const imageInputRef = useRef<HTMLInputElement>(null);

  const onImageFileEvent = async ({
    singleFile,
  }: {
    singleFile: File | null;
  }) => {
    const { remoteUrl, success, data, error } = await addBroadcastAsset({
      broadcastId,
      owner: studioUserId,
      metaData: { type: "avatar-image" },
      singleFile,
    });

    if (success) {
      addAsset({ data: { ...data, metaData: safeParseJson(data.metaData) } });

      handleSave({ remoteUrl, isOld: false });
    } else if (error) {
      setFileUploadError({
        visible: true,
        errorMessage: error,
      });
    }
  };

  return (
    <React.Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          style={{ zIndex: 99999 }}
          as="div"
          className="relative z-10"
          onClose={handleCancel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Edit Avatar
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="flex-1 flex-wrap flex">
                      <React.Fragment>
                        {avatars.map(({ src: _src }, i) => {
                          const isActive = _src === src;

                          return (
                            <div
                              key={`avatar_item_selector_${i}`}
                              onClick={() => {
                                setSrc(_src);
                              }}
                              className={`cursor-pointer h-24 w-24 ml-1 mt-1 rounded-full overflow-hidden ${
                                isActive
                                  ? "border-4 border-gray-600"
                                  : "border-4 border-gray-100 hover:border-gray-300"
                              }`}
                            >
                              <img
                                alt={`avatar_item_selector_${i}`}
                                src={_src}
                                className={"h-24 w-24"}
                              />
                            </div>
                          );
                        })}

                        <Tooltip title={"Upload from computer"}>
                          <div
                            onClick={() => {
                              imageInputRef.current?.click();
                            }}
                            className="border-4 border-gray-100 hover:border-gray-300 rounded-full tooltip-top cursor-pointer h-24 w-24 ml-1 mt-1 flex items-center justify-center"
                          >
                            <MdAdd className="h-14 w-14" />
                          </div>
                        </Tooltip>
                      </React.Fragment>

                      {/* file input */}
                      <input
                        ref={imageInputRef as React.LegacyRef<HTMLInputElement>}
                        onChange={(e) => {
                          const singleFile = e?.target?.files
                            ? e.target.files[0]
                            : null;

                          onImageFileEvent({ singleFile });

                          if (imageInputRef.current) {
                            imageInputRef.current.value = "";
                          }
                        }}
                        accept="image/*"
                        type="file"
                        className="hidden"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-custom-blue-100 px-4 py-2 text-sm font-medium text-custom-blue-900 hover:bg-custom-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-custom-blue-500 focus-visible:ring-offset-2"
                      onClick={() =>
                        handleSave({ remoteUrl: src, isOld: true })
                      }
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-custom-blue-100 px-4 py-2 text-sm font-medium text-custom-blue-900 hover:bg-custom-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-custom-blue-500 focus-visible:ring-offset-2"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};

export default ChangeAvatarModal;
