import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { useAppConfigContext } from "../../contexts/appConfigDef";

const useLiveStreamDestinations = () => {
  const { destinations } = useAppConfigContext();

  const { selectedLiveStreamDestinationsIds } = useAppContext();

  const availableLiveStreamDestinations = useMemo(() => {
    return destinations.map(({ id, data, name, type }) => ({
      id,
      nickName: name,
      streamKey: data.streamKey,
      url: data.streamUrl,
      type,
    }));
  }, [destinations]);

  const {
    selectedLiveStreamDestinations,
    selectedAvailableLiveStreamDestinations,
  } = useMemo(() => {
    const selectedAvailableLiveStreamDestinations =
      availableLiveStreamDestinations.map(
        ({ id, nickName, url, streamKey, type }) => ({
          id,
          nickName,
          url,
          streamKey,
          isSelected:
            selectedLiveStreamDestinationsIds.findIndex((_id) => _id === id) !==
            -1,
          type,
        })
      );

    const selectedLiveStreamDestinations =
      selectedAvailableLiveStreamDestinations.filter(
        ({ isSelected }) => isSelected
      );

    return {
      selectedLiveStreamDestinations,
      selectedAvailableLiveStreamDestinations,
    };
  }, [selectedLiveStreamDestinationsIds, availableLiveStreamDestinations]);

  return {
    availableLiveStreamDestinations,
    selectedLiveStreamDestinations,
    selectedAvailableLiveStreamDestinations,
  };
};

export default useLiveStreamDestinations;
