import { useMemo } from "react";
import ReactWordcloud from "react-wordcloud";
import useWordcloud from "../../hooks/apps/wordcloud/useWordcloud";

const MainViewAppWordcloudShareStreamContainer = ({
  wordcloudId,
  relativeWidth,
  mainViewContainerWidth,
}: {
  wordcloudId: string;
  relativeWidth: number;
  mainViewContainerWidth: number;
}) => {
  const { answers, question } = useWordcloud({ id: wordcloudId });

  return useMemo(() => {
    const words: { text: string; value: number }[] = [];

    if (answers && typeof answers === "object") {
      Object.keys(answers).forEach((k) => {
        const key = `${k}`.toLowerCase();

        const wordIndex = words.findIndex(({ text: k }) => k === key);

        if (wordIndex === -1) {
          words.push({
            text: key,
            value: answers[k],
          });
        } else {
          words[wordIndex] = {
            text: key,
            value: (words[wordIndex]?.value || 0) + answers[k],
          };
        }
      });
    }

    return (
      <div
        id={`wordcloud-canvas-container-${wordcloudId}`}
        className="flex flex-1 flex-col p-3 h-full"
      >
        <div className="flex items-center justify-center text-primary">
          <div className="flex flex-1 items-center justify-center">
            <p
              style={{
                fontSize: relativeWidth * mainViewContainerWidth * 0.00045,
              }}
              className="text-center font-bold text-center"
            >
              Wordcloud
            </p>
          </div>
        </div>
        <div className="mt-2">
          <p className="text-2xl font-extrabold">{question}</p>
        </div>
        <ReactWordcloud
          options={{
            deterministic: true,
            rotations: 0,
            fontSizes: [
              relativeWidth * mainViewContainerWidth * 0.0004,
              relativeWidth * mainViewContainerWidth * 0.00096,
            ],
            fontFamily: "Lato",
            fontWeight: "900",
          }}
          words={words}
        />
      </div>
    );
  }, [answers, question, wordcloudId, relativeWidth, mainViewContainerWidth]);
};

export default MainViewAppWordcloudShareStreamContainer;
