import { useAppContext } from "../../contexts/appContextDef";
import BrandOverlaysListner from "./BrandOverlaysListner";

const AllBrandsOverlaysListner = () => {
  const { brands } = useAppContext();

  return brands.map(({ id }) => (
    <BrandOverlaysListner key={`BrandOverlaysListner_${id}`} id={id} />
  ));
};

export default AllBrandsOverlaysListner;
