import React, { useEffect, useMemo, useRef, useState } from "react";
import useInputFileVideoShareStream from "../../hooks/streams/useInputFileVideoShareStream";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import { createUID } from "../../utils/createUID";
import { useAppContext } from "../../contexts/appContextDef";
import validateSpeakerDeviceId from "../../utils/validateSpeakerDeviceId";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { VideoStreamVolumeControl } from "../inQueueStreams/InQueueVideoStreamContainer";
import { MdPause, MdPlayArrow } from "react-icons/md";

export const MainViewInputFileVideoShareStreamBottomContainer = ({
  inputFileVideoId,
}: {
  inputFileVideoId: string;
}) => {
  const {
    playing,
    volume,
    changeVolume,
    seekTo,
    currentTime,
    durationInSec,
    pause,
    play,
  } = useInputFileVideoShareStream({ inputFileVideoId });

  return (
    <div className={`p-3 bg-opacity-50 bg-black`}>
      <div>
        <input
          className="w-full"
          type="range"
          min={0}
          max={durationInSec}
          step={1}
          value={currentTime || 0}
          onChange={(e) => {
            const seekToSec = parseInt((e.target as HTMLInputElement).value);

            seekTo({ seekToSec });
          }}
        />
      </div>
      <div className="flex items-center justify-start">
        <button
          onClick={() => {
            if (playing) {
              pause();
            } else {
              play();
            }
          }}
        >
          {playing ? (
            <MdPause className="fill-white" />
          ) : (
            <MdPlayArrow className="fill-white" />
          )}
        </button>

        <VideoStreamVolumeControl
          {...{ volume: volume as number, changeVolume, fill: "fill-white" }}
        />
      </div>
    </div>
  );
};

const MainViewInputFileVideoShareStreamContainer = ({
  inputFileVideoId,
  participantId,
}: {
  inputFileVideoId: string;
  participantId: string;
}) => {
  const [muteDueToError, setMuteDueToError] = useState(false);

  const {
    appAudioMuted,
    selectedSpeakerDeviceId,

    isAnyAgoraActiveSpeaker,
    muteOriginalAudioWhenInterpretationActive,
    activeInterpretationOutputAgoraChannelId,
  } = useAppContext();

  const {
    videoTrack,
    audioTrack,
    volume: _volume,
    playing,
  } = useInputFileVideoShareStream({
    inputFileVideoId,
  });

  const audioMuteDueToError = useRef(false);

  const volume = useMemo(
    () =>
      muteDueToError ||
      appAudioMuted ||
      (muteOriginalAudioWhenInterpretationActive &&
        activeInterpretationOutputAgoraChannelId)
        ? 0
        : isAnyAgoraActiveSpeaker
        ? 0.1
        : typeof _volume === "number"
        ? _volume
        : 0.5,
    [
      muteDueToError,
      appAudioMuted,
      _volume,
      isAnyAgoraActiveSpeaker,
      muteOriginalAudioWhenInterpretationActive,
      activeInterpretationOutputAgoraChannelId,
    ]
  );

  const audioPlayerId = useMemo(() => createUID(), []);

  const audioPlayerIdRef = useRef(audioPlayerId);
  const audioTrackRef = useRef(audioTrack);
  const volumeRef = useRef(volume);
  const appAudioMutedRef = useRef(appAudioMuted);

  useEffect(() => {
    audioPlayerIdRef.current = audioPlayerId;
  }, [audioPlayerId]);
  useEffect(() => {
    audioTrackRef.current = audioTrack;
  }, [audioTrack]);
  useEffect(() => {
    volumeRef.current = volume;
  }, [volume]);
  useEffect(() => {
    appAudioMutedRef.current = appAudioMuted;
  }, [appAudioMuted]);

  const getAudioTag = (): StudioHTMLAudioElement | null => {
    return document.getElementById(
      audioPlayerIdRef.current
    ) as StudioHTMLAudioElement | null;
  };

  const getVolume = (args?: { volume: number }) => {
    const volume =
      audioMuteDueToError.current || appAudioMutedRef.current
        ? 0
        : typeof args?.volume === "number"
        ? args?.volume
        : volumeRef.current;

    return volume;
  };

  const _playAudio = async () => {
    const audioTag = getAudioTag();
    const audioTrack = audioTrackRef.current;
    if (audioTag && audioTrack) {
      const mediaStream = new MediaStream();

      mediaStream.addTrack(audioTrack);

      audioTag.srcObject = mediaStream;

      const playPromise = audioTag.play();

      if (playPromise !== undefined) {
        await new Promise((resolve) => {
          playPromise
            .then(() => {
              resolve(undefined);
            })
            .catch((e) => {
              console.log("play-error", e);

              // have to handle the play failure

              setMuteDueToError(true);

              audioMuteDueToError.current = true;

              const volume = getVolume();

              audioTag.volume = volume;

              audioTag.muted = volume === 0;

              appEventEmitter.emit(
                appEventEmitterEvents.MAIN_VIEW_INPUT_FILE_VIDEO_SHARE_STREAM_PLAY_ERRORED
              );

              const mutedPlayPromise = audioTag.play();

              if (mutedPlayPromise !== undefined) {
                mutedPlayPromise
                  .then(() => {
                    resolve(undefined);
                  })
                  .catch((e) => {
                    console.log("play-error-again", e);

                    resolve(undefined);
                  });
              }
            });
        });
      }
    }
  };

  const setSinkIdForAudio = async ({ deviceId }: { deviceId: string }) => {
    const { isValid } = await validateSpeakerDeviceId({ deviceId });

    const audioTag = getAudioTag();

    if (isValid && typeof audioTag?.setSinkId === "function") {
      audioTag.setSinkId(deviceId);
    }
  };

  useEffect(() => {
    setSinkIdForAudio({ deviceId: selectedSpeakerDeviceId as string });
  }, [selectedSpeakerDeviceId]);

  useEffect(() => {
    if (playing) {
      _playAudio();
    }
  }, [playing, audioTrack]);

  useEffect(() => {
    const audioTag = getAudioTag();

    if (audioTag) {
      audioTag.volume = volume;
    }
  }, [volume]);

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      className="relative cursor-pointer"
    >
      {audioTrack ? <audio id={audioPlayerId} /> : null}
      {videoTrack && (
        <React.Fragment>
          <div
            style={{ height: "100%", width: "100%" }}
            className="relative overflow-hidden flex items-center justify-center"
          >
            <VideoMediaStreamPlayer
              {...{
                videoTrack: videoTrack,
                videoOn: !!videoTrack,
                participantId,
                isShare: true,
                key: `MainViewInputFileVideoShareStreamContainer-${videoTrack.id}-${inputFileVideoId}-${participantId}`,
              }}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default MainViewInputFileVideoShareStreamContainer;
