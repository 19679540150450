// import { usePubSub } from "@videosdk.live/react-sdk";
import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const ShowAudioAvatarsListner = () => {
  const { setShowAudioAvatars } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.VIDEOS_READY_TO_BE_PLAYED,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setShowAudioAvatars(message.payload.enabled);

      if (!message.payload.enabled) {
        setTimeout(() => {
          appEventEmitter.emit(
            appEventEmitterEvents.MAIN_VIEW_AUDIO_ONLY_PARTICIPANTS_POPUP,
            {}
          );
        }, 500);
      }
    }
  );

  return <React.Fragment />;
};

export default ShowAudioAvatarsListner;
