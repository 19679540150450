import { useAppContext } from "../../contexts/appContextDef";
import { momentoStreamDefaultOverlay } from "../../static/images";
import { mainViewOverlayTypes } from "../../utils/constants";

const MainViewOverlay = () => {
  const { mainViewOverlay } = useAppContext();

  return mainViewOverlay.type === mainViewOverlayTypes.NO_OVERLAY ? null : (
    <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none">
      <img
        alt="main-view-overlay"
        src={
          mainViewOverlay.type === mainViewOverlayTypes.FREE_PLAN_OVERLAY
            ? momentoStreamDefaultOverlay
            : mainViewOverlay.value
        }
        className="h-full w-full max-w-full max-h-full block object-cover"
      />
    </div>
  );
};

export default MainViewOverlay;
