import { egressManagerApiServerBaseUrl } from "../../utils/constants";

const stopAIInterpreter = async ({ broadcastId }: { broadcastId: string }) => {
  try {
    const url = `${egressManagerApiServerBaseUrl}/stop-ai-interpreter`;

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ broadcastId }),
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default stopAIInterpreter;
