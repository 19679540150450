import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";

const useBrandMuteGuestsWhenVideoPlays = () => {
  const { publish } = useAppSingalingPublish(
    appPubSubTopics.MUTE_GUESTS_WHEN_VIDEO_PLAYS
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const setMuteGuestsWhenVideoPlays = async (
    {
      muteGuestsWhenVideoPlays,
    }: {
      muteGuestsWhenVideoPlays: boolean;
    },
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(JSON.stringify({ muteGuestsWhenVideoPlays }));
  };

  return { setMuteGuestsWhenVideoPlays };
};

export default useBrandMuteGuestsWhenVideoPlays;
