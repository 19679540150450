import React from "react";
import { MdArrowBack, MdClose } from "react-icons/md";

const SidePanelTopBar = ({
  onBackClick,
  renderRightComponent,
  title,
  renderSecondaryTitle,
  onCloseButtonClick,
}: {
  onBackClick?: () => void;
  renderRightComponent?: () => React.ReactNode;
  title: string;
  renderSecondaryTitle?: () => React.ReactNode;
  onCloseButtonClick?: () => void;
}) => {
  return (
    <div className="w-full p-3 flex border-b-2 items-center">
      {typeof onBackClick === "function" ? (
        <button
          onClick={onBackClick}
          className="btn btn-outline btn-primary btn-circle btn-sm"
        >
          <MdArrowBack size={18} />
        </button>
      ) : null}

      <div
        className={`flex flex-1 flex-col ${
          typeof onBackClick === "function" ? "ml-3" : ""
        } justify-center`}
      >
        <p className="text-md font-bold text-primary">{title}</p>
        {typeof renderSecondaryTitle === "function" ? (
          renderSecondaryTitle()
        ) : (
          <React.Fragment />
        )}
      </div>

      {typeof renderRightComponent === "function" ? (
        <div className="ml-3 text-primary fill-primary">
          {renderRightComponent()}
        </div>
      ) : (
        <React.Fragment />
      )}

      {typeof onCloseButtonClick === "function" ? (
        <button
          className="btn btn-sm btn-primary text-white ml-1"
          onClick={onCloseButtonClick}
        >
          <MdClose />
        </button>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default SidePanelTopBar;
