import { useWindowSize } from "@react-hook/window-size";

const LocalParticipantEntryRequestDenied = () => {
  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <div
      style={{ width: windowWidth, height: windowHeight }}
      className="flex flex-1 flex-col items-center justify-center"
    >
      <div className="px-12 py-5 border rounded-lg border-gray-300 max-w-lg">
        <h3 className="text-4xl font-medium">Host denied your request</h3>

        <div className="flex mt-5">
          <div className="flex flex-1">
            <button
              onClick={() => {
                window.location.replace("https://momentostream.com/");
              }}
              className="btn btn-block btn-primary text-white normal-case"
            >
              Return to MomentoStream
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalParticipantEntryRequestDenied;
