// import { usePubSub } from "@videosdk.live/react-sdk";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import { pinnedParticipantsPositionActions } from "./useCalculateMainViewConferenceVisibleWebcamStreams";

const usePinnedParticipantsPosition = () => {
  // const { publish } = usePubSub(appPubSubTopics.PINNED_PARTICIPANTS_POSITION);

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.PINNED_PARTICIPANTS_POSITION
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const pin = async ({
    participantId,
    positionIndex,
  }: {
    participantId: string;
    positionIndex: number;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: pinnedParticipantsPositionActions.PIN,
        payload: { participantId, positionIndex },
      })
      // { persist: false }
    );
  };

  const unPin = async ({ participantId }: { participantId: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: pinnedParticipantsPositionActions.UNPIN,
        payload: { participantId },
      })
      // { persist: false }
    );
  };

  return { pin, unPin };
};

export default usePinnedParticipantsPosition;
