import React, { useMemo, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { mainViewBackgroundTypes } from "../../utils/constants";

const MainViewBackgroundImage = () => {
  const [loadedImageIds, setLoadedImageIds] = useState<string[]>([]);

  const { mainViewBackground } = useAppContext();

  const isLoaded = useMemo(
    () => !!loadedImageIds.find((id) => (id === mainViewBackground.id ? 1 : 0)),
    [loadedImageIds, mainViewBackground]
  );

  return mainViewBackground.value &&
    mainViewBackground.type === mainViewBackgroundTypes.IMAGE ? (
    <div
      style={{ opacity: isLoaded ? 1 : 0 }}
      className="absolute top-0 left-0 right-0 bottom-0"
    >
      <img
        onLoad={() => {
          const id = mainViewBackground.id;

          if (id && !isLoaded) {
            setLoadedImageIds((s) => [...s, id]);
          }
        }}
        alt="main-view-bg"
        src={mainViewBackground.value}
        className="h-full w-full max-w-full max-h-full block object-cover"
      />
    </div>
  ) : (
    <React.Fragment />
  );
};

export default MainViewBackgroundImage;
