import { momentostreamApiServerBaseUrl } from "../../../utils/constants";

const getAllBanners = async ({ userId }: { userId: string }) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/${userId}/banner`;

    const res = await fetch(url);

    const json = await res.json();

    if (json.success) {
      return { success: true, data: json.data };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default getAllBanners;
