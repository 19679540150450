import React, { useEffect, useMemo, useState } from "react";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import useParticipantAvatar from "../../hooks/appState/useParticipantAvatar";
import { audioAndVideoPermissionStates } from "../../utils/constants";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useRtcLocalParticipantMediaStatsActions from "../../appRtc/useRtcLocalParticipantMediaStatsActions";
import useAppRtcLocalParticipantMediaStats from "../../appRtc/useAppRtcLocalParticipantMediaStats";

const MeetingCameraSettingsContainer = ({
  activeTabIndex,
}: {
  activeTabIndex: number;
}) => {
  const [webcams, setWebcams] = useState<{ deviceId: string; label: string }[]>(
    []
  );

  const { localParticipantId } = useLocalParticipantId();

  const { selectedWebcamDeviceId, setMirrorLocalWebcam, mirrorLocalWebcam } =
    useAppContext();

  const { __webcamTrack: webcamTrack } = useAppRtcLocalParticipantMediaStats();

  const { getWebcams: _getWebcams, changeWebcamDeviceId } =
    useRtcLocalParticipantMediaStatsActions();

  const getWebcams = async () => {
    const webcams = await _getWebcams();

    webcams && webcams?.length && setWebcams(webcams);
  };

  const _handleChangeWebcam = async (deviceId: string) => {
    changeWebcamDeviceId(deviceId);
  };

  const { avatarRemoteUrl } = useParticipantAvatar(localParticipantId);

  const getDevices = async () => {
    await getWebcams();
  };

  useEffect(() => {
    getDevices();
  }, [activeTabIndex]);

  const flipStyle = useMemo(
    () =>
      mirrorLocalWebcam
        ? { transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }
        : {},
    [mirrorLocalWebcam]
  );

  const { webcamState } = useAppConfigContext();

  const cameraDenied = useMemo(
    () => webcamState === audioAndVideoPermissionStates.denied,
    [webcamState]
  );

  return (
    <div className="flex flex-1 flex-col relative">
      {cameraDenied ? (
        <p className="text-red-600 font-bold">
          Your browser has blocked your camera.
        </p>
      ) : (
        <React.Fragment />
      )}

      {cameraDenied ? (
        <React.Fragment />
      ) : (
        <React.Fragment>
          <div className={`w-full bg-gray-800 aspect-video mt-4`}>
            {webcamTrack ? (
              <div className="aspect-video">
                <VideoMediaStreamPlayer
                  {...{
                    flipStyle,
                    videoOn: !!webcamTrack,
                    videoTrack: webcamTrack,
                    participantId: localParticipantId,
                    isWebcam: true,
                  }}
                />
              </div>
            ) : (
              <div className="h-full w-full flex items-center justify-center">
                <div className="h-36 w-36 rounded-full">
                  <img
                    alt={`in-queue-webcam-stream-avatar`}
                    className="rounded-full"
                    src={avatarRemoteUrl}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="mt-4">
            <p>Camera</p>
            <select
              onChange={(e) => {
                _handleChangeWebcam(e.target.value);
              }}
              value={selectedWebcamDeviceId || undefined}
              className="select w-full max-w-xs select-bordered mt-1"
            >
              {webcams.map((item) => (
                <option
                  key={`wemcam-selector-item-${item?.deviceId}`}
                  value={item?.deviceId}
                >
                  {item?.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-4">
            <div className="form-control">
              <label className="label cursor-pointer">
                <input
                  onChange={() => {
                    setMirrorLocalWebcam((s) => !s);
                  }}
                  type="checkbox"
                  checked={mirrorLocalWebcam}
                  className="checkbox checkbox-sm"
                />
                <span className="label-text font-medium ml-2">
                  Mirror my camera
                </span>
              </label>
            </div>
            {mirrorLocalWebcam && (
              <p className="text-sm text-gray-500">
                Heads up, if you have an overlay that covers a lot of the
                screen, you might cover yourself.
              </p>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const CameraSettingsContainer = ({
  activeTabIndex,
}: {
  activeTabIndex: number;
}) => {
  return <MeetingCameraSettingsContainer {...{ activeTabIndex }} />;
};

export default CameraSettingsContainer;
