const Kick = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22.04 25.71"
    {...props}
  >
    <path
      fill="#53fc18"
      fillRule="evenodd"
      d="M0 0h8.264v5.713h2.755V2.857h2.754V0h8.264v8.57h-2.755v2.857h-2.754v2.856h2.755v2.857h2.754v8.57h-8.264v-2.857H11.02v-2.856H8.264v5.713H0V0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Kick;
