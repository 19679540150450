import { useEffect, useRef, useState } from "react";
import { MdAdd, MdRemove } from "react-icons/md";
import createPoll from "../../../apis/apps/polls/create-poll";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import Modal from "../../../components/Modal";

const optionDefaultValue = { text: "", error: null };

const CreateNewPollContainer = ({
  isCreating,
  setIsCreating,
}: {
  isCreating: boolean;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { broadcastId } = useAppConfigContext();

  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([
    optionDefaultValue,
    optionDefaultValue,
  ]);

  const broadcastIdRef = useRef(broadcastId);
  const questionRef = useRef(question);
  const optionsRef = useRef(options);

  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);

  useEffect(() => {
    questionRef.current = question;
  }, [question]);
  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  const stopCreating = () => {
    setIsCreating(false);
    setQuestion("");
    setOptions([optionDefaultValue, optionDefaultValue]);
  };

  const _handleCreateNewPoll = ({
    question,
    options,
  }: {
    question: string;
    options: string[];
  }) => {
    createPoll({ broadcastId: broadcastIdRef.current, options, question });

    stopCreating();
  };

  return (
    <Modal
      successText={"Create"}
      isOpen={isCreating}
      cancelText={"Cancel"}
      title={"Create new poll"}
      onClose={() => {
        stopCreating();
      }}
      onSuccess={() => {
        _handleCreateNewPoll({
          question: questionRef.current,
          options: optionsRef.current.map(({ text }) => text),
        });
      }}
      renderBody={() => {
        return (
          <div>
            <div className="form-control w-full">
              <div>
                <p className="text-md font-regular">Poll question</p>
              </div>
              <input
                className="input w-full input-bordered input-primary mt-1"
                placeholder="Poll question"
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />
            </div>

            <div className="mt-3 py-1">
              <div>
                <p className="text-md font-regular">Add options</p>
              </div>

              <div className=" max-h-56 px-1.5 overflow-y-scroll py-1">
                {options.map((option, i) => {
                  const { text } = option;

                  return (
                    <div
                      className="mt-1.5 flex gap-2 items-center"
                      key={`create-poll-option-${i}`}
                    >
                      <div className="flex flex-1">
                        <input
                          className="input w-full input-bordered input-primary input-sm"
                          placeholder={`Option ${i + 1}`}
                          value={text}
                          onChange={(e) => {
                            setOptions((s) => {
                              s[i] = { ...s[i], text: e.target.value };

                              return [...s];
                            });
                          }}
                        />
                      </div>

                      <div>
                        <button
                          disabled={options.length > 2 ? false : true}
                          className="btn btn-xs btn-error btn-circle btn-outline"
                          onClick={() => {
                            if (options.length > 2) {
                              setOptions((s) => {
                                s.splice(i, 1);

                                return [...s];
                              });
                            }
                          }}
                        >
                          <MdRemove size={16} />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-2 flex justify-end">
                <button
                  className="btn btn-xs gap-1 btn-primary btn-ghost normal-case"
                  onClick={() => {
                    setOptions((s) => [...s, optionDefaultValue]);
                  }}
                >
                  <MdAdd />
                  Add new option
                </button>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default CreateNewPollContainer;
