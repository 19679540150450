import axios from "axios";
import { momentostreamApiServerBaseUrl } from "../../utils/constants";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { getReadyToBePlayedVideoIds } from "../../components/WaitUntilVideosAreReadyToBePlayed";
import { createUID } from "../../utils/createUID";

const addBrandAsset = async ({
  brandId,
  userId,
  type,
  singleFile,
  uploadId,
  setWaitingForVideosRemoteUrlReadyToBePlayed,
}: {
  brandId: string;
  userId: string;
  type: string;
  singleFile: File | null;
  uploadId?: string;
  setWaitingForVideosRemoteUrlReadyToBePlayed?: React.Dispatch<
    React.SetStateAction<{ remoteUrl: string; id: string }[]>
  >;
}) => {
  try {
    if (singleFile) {
      if (type === "video") {
        const id = getReadyToBePlayedVideoIds({
          id: createUID(),
          other: true,
        });

        if (setWaitingForVideosRemoteUrlReadyToBePlayed) {
          setWaitingForVideosRemoteUrlReadyToBePlayed((s) => [
            ...s,
            { remoteUrl: URL.createObjectURL(singleFile), id },
          ]);
        }
        const { durationInSec }: { durationInSec: number } = await new Promise(
          (resolve) => {
            appEventEmitter.on(
              appEventEmitterEvents.VIDEO_READY_TO_BE_PLAYED(id),
              resolve
            );
          }
        );

        if (durationInSec / 60 > 10) {
          return {
            success: false,
            error: "Video duration must be less 10 minutes.",
          };
        }
      }

      const formData = new FormData();

      formData.append("file", singleFile, singleFile.name);
      formData.append("brandId", brandId);
      formData.append("userId", userId);
      formData.append("type", type);

      const url = `${momentostreamApiServerBaseUrl}/add-brand-asset`;

      const res = await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: ({ progress, upload }) => {
          if (uploadId) {
            appEventEmitter.emit(
              appEventEmitterEvents.ASSET_UPLOADING_AND_PROCESSING_PROGRESS(
                uploadId
              ),
              { progress, upload }
            );
          }
        },
      });

      if (res.data.success) {
        const { file: remoteUrl, id, name } = res.data.data;

        return { success: true, remoteUrl, id, name };
      } else {
        return { success: false, error: res.data?.message?.file[0] };
      }
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default addBrandAsset;
