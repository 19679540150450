import React from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import useHostInQueueStreams from "../../hooks/appState/useHostInQueueStreams";
import useSpeakerInQueueStreams from "../../hooks/appState/useSpeakerInQueueStreams";
import { appModes } from "../../utils/constants";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

const MainViewToggleInQueueStreamContainerButton = ({
  isMouseOver,
}: {
  isMouseOver: boolean;
}) => {
  const { meetingMode } = useAppConfigContext();

  const {
    appMode,
    inQueueStreamsContainerHidden,
    setInQueueStreamsContainerHidden,
  } = useAppContext();

  const { toggleHostInQueueStreamsButtonVisible } = useHostInQueueStreams();
  const { toggleSpeakerInQueueStreamsButtonVisible } =
    useSpeakerInQueueStreams();

  return (
    <React.Fragment>
      {meetingMode === "meeting" &&
      isMouseOver &&
      (appMode === appModes.HOST
        ? toggleHostInQueueStreamsButtonVisible
        : appMode === appModes.SPEAKER
        ? toggleSpeakerInQueueStreamsButtonVisible
        : false) ? (
        <div className="flex absolute bottom-0 left-0">
          <button
            onClick={() => {
              setInQueueStreamsContainerHidden((s) => !s);
            }}
            className="btn btn-primary btn-circle btn-sm"
          >
            {inQueueStreamsContainerHidden ? (
              <MdExpandLess size={20} />
            ) : (
              <MdExpandMore size={20} />
            )}
          </button>
        </div>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

export default MainViewToggleInQueueStreamContainerButton;
