import axios from "axios";
import {
  maxMediaAssetSizeMB,
  momentostreamApiServerBaseUrl,
} from "../../utils/constants";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { getReadyToBePlayedVideoIds } from "../../components/WaitUntilVideosAreReadyToBePlayed";
import { createUID } from "../../utils/createUID";

const addBroadcastAsset = async ({
  broadcastId,
  owner,
  metaData,
  uploadId,
  singleFile,
  setWaitingForVideosRemoteUrlReadyToBePlayed,
}: {
  broadcastId: string;
  owner: string;
  metaData?: { type: string };
  uploadId?: string;
  singleFile: File | null;
  setWaitingForVideosRemoteUrlReadyToBePlayed?: React.Dispatch<
    React.SetStateAction<{ remoteUrl: string; id: string }[]>
  >;
}) => {
  if (singleFile) {
    if (metaData?.type === "video-share-stream") {
      const id = getReadyToBePlayedVideoIds({
        id: createUID(),
        other: true,
      });
      if (setWaitingForVideosRemoteUrlReadyToBePlayed) {
        setWaitingForVideosRemoteUrlReadyToBePlayed((s) => [
          ...s,
          { remoteUrl: URL.createObjectURL(singleFile), id },
        ]);
      }

      const { durationInSec }: { durationInSec: number } = await new Promise(
        (resolve) => {
          appEventEmitter.on(
            appEventEmitterEvents.VIDEO_READY_TO_BE_PLAYED(id),
            resolve
          );
        }
      );

      if (durationInSec / 60 > 10) {
        return {
          success: false,
          error: "Video duration must be less 10 minutes.",
        };
      }
    }

    const formData = new FormData();

    const fileSize = singleFile.size / (1024 * 1024);

    if (fileSize > maxMediaAssetSizeMB) {
      return {
        success: false,
        error: `File size exceeds the maximum limit ~ ${maxMediaAssetSizeMB}MB`,
      };
    }

    formData.append("file", singleFile);
    formData.append("broadcastId", broadcastId);
    formData.append("owner", owner);
    formData.append("metaData", JSON.stringify(metaData));

    const url = `${momentostreamApiServerBaseUrl}/add-broadcast-asset`;

    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: ({ progress, upload }) => {
        if (uploadId) {
          appEventEmitter.emit(
            appEventEmitterEvents.ASSET_UPLOADING_AND_PROCESSING_PROGRESS(
              uploadId
            ),
            { progress, upload }
          );
        }
      },
    });

    if (res.data.success) {
      const { file: remoteUrl, id, name, fileData } = res.data.data;

      return {
        success: true,
        remoteUrl,
        id,
        name,
        fileData,
        data: res.data.data,
      };
    } else {
      let error = "";

      if (typeof res.data?.message === "string") {
        error = res.data?.message;
      } else if (
        res.data?.message?.file &&
        typeof res.data?.message?.file[0] === "string"
      ) {
        error = res.data?.message?.file[0];
      }

      return { success: false, error };
    }
  } else {
    return { success: false };
  }
};

export default addBroadcastAsset;
