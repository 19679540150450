const removeExtensionFromFileName = (name: string) => {
  if (typeof name === "string") {
    const splitted = name.split(".");

    if (splitted.length > 1) {
      const last = splitted[splitted.length - 1];

      if (
        ["mp3", "mp4", "wav", "png", "jpg", "jpeg", "svg", "gif"].includes(last)
      ) {
        splitted.pop();

        return splitted.join(".");
      } else {
        return name;
      }
    } else {
      return name;
    }
  } else {
    return name;
  }
};

export default removeExtensionFromFileName;
