import React, { useMemo } from "react";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import useParticipantName from "../../hooks/appState/useParticipantName";
import { useAppContext } from "../../contexts/appContextDef";
import { isHexLight } from "../../utils/isHexLight";
import getTransitionStyle from "../../utils/getTransitionStyle";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useAppRtcParticipantMediaStats from "../../appRtc/useAppRtcParticipantMediaStats";

const BottomLeftContainer = ({
  name,
  relativeWidth,
  mainViewContainerWidth,
}: {
  name: string;
  relativeWidth: number;
  mainViewContainerWidth: number;
}) => {
  const appContext = useAppContext();

  const { backgroundColor, isBackgroundLight } = useMemo(() => {
    const backgroundColor = appContext?.brandColor?.hexColorCode || "#000000";

    const isBackgroundLight = isHexLight(backgroundColor);

    return {
      backgroundColor,
      isBackgroundLight,
    };
  }, [appContext]);

  return (
    <div
      style={{
        left: (relativeWidth > 50 ? 1 : 0.6) * mainViewContainerWidth * 0.007,
        bottom: (relativeWidth > 50 ? 1 : 0.6) * mainViewContainerWidth * 0.007,
        paddingTop:
          mainViewContainerWidth * (relativeWidth > 50 ? 1 : 0.6) * 0.005,
        paddingBottom:
          mainViewContainerWidth * (relativeWidth > 50 ? 1 : 0.6) * 0.005,
        paddingLeft:
          mainViewContainerWidth * (relativeWidth > 50 ? 1 : 0.6) * 0.01,
        paddingRight:
          mainViewContainerWidth * (relativeWidth > 50 ? 1 : 0.6) * 0.01,
        fontSize:
          mainViewContainerWidth * (relativeWidth > 50 ? 1 : 0.6) * 0.01,
        backgroundColor,
        transition: getTransitionStyle([
          "left",
          "bottom",
          "padding-top",
          "padding-bottom",
          "padding-left",
          "padding-right",
          "font-size",
          "background-color",
        ]),
      }}
      className={`absolute ${
        isBackgroundLight ? "text-black fill-black" : "text-white fill-white"
      } rounded-full flex items-center justify-center font-extrabold truncate text-ellipsis`}
    >
      {name}
    </div>
  );
};

const MainViewScreenShareStreamContainer = ({
  participantId,
  relativeWidth,
  mainViewContainerWidth,
}: {
  participantId: string;
  relativeWidth: number;
  mainViewContainerWidth: number;
}) => {
  const { localParticipantId } = useLocalParticipantId();

  const { screenShareVideoTrack } =
    useAppRtcParticipantMediaStats(participantId);

  const { screenShareOn } = useParticipantMediaStats(participantId);

  const { name } = useParticipantName(participantId);

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      className="relative cursor-pointer"
    >
      {screenShareOn && screenShareVideoTrack && (
        <React.Fragment>
          <div
            style={{ height: "100%", width: "100%" }}
            className="relative overflow-hidden"
          >
            <VideoMediaStreamPlayer
              {...{
                videoTrack: screenShareVideoTrack,
                videoOn: screenShareOn,
                key: `VideoMediaStreamPlayer-screenshare-stream-${screenShareVideoTrack.id}-${localParticipantId}`,
                participantId,
                isShare: true,
              }}
            />
          </div>
        </React.Fragment>
      )}

      <div className="absolute top-0 bottom-0 left-0 right-0">
        <BottomLeftContainer
          {...{ name, relativeWidth, mainViewContainerWidth }}
        />
      </div>
    </div>
  );
};

export default MainViewScreenShareStreamContainer;
