import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const createFacebookBroadcast = async ({
  destinationId,
}: {
  destinationId: number;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/create-facebook-broadcast`;

    const body = JSON.stringify({ destinationId });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true, data: json.data };
    } else {
      return { success: false, message: json.message };
    }
  } catch (error) {
    return { success: false };
  }
};

export default createFacebookBroadcast;
