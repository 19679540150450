import { useEffect, useRef, useState } from "react";
import { createUID } from "../utils/createUID";

const useAnime = ({ removeMe }: { removeMe: () => void }) => {
  const interval = useRef<null | ReturnType<typeof setInterval>>();
  const timeout = useRef<null | ReturnType<typeof setTimeout>>();
  const [anime, setAnime] = useState(0);

  const init = () => {
    const animationDuration = 3000;
    const transformOnDuration = 10;

    interval.current = setInterval(() => {
      setAnime((s) => s + transformOnDuration / animationDuration);
    }, transformOnDuration);

    timeout.current = setTimeout(() => {
      if (interval.current) {
        clearInterval(interval.current);
      }

      setTimeout(() => {
        removeMe();
      }, 500);
    }, animationDuration);
  };

  useEffect(() => {
    init();

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }

      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return { anime };
};

const Disc = ({ removeMe }: { removeMe: () => void }) => {
  const { anime } = useAnime({ removeMe });

  return (
    <div
      style={{ opacity: (1 - anime) / 2 }}
      className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center"
    >
      <div
        className="rounded-full"
        style={{
          height: `${anime * 100}%`,
          width: `${anime * 100}%`,
          backgroundColor: "white",
        }}
      ></div>
    </div>
  );
};

const Ring = ({ removeMe }: { removeMe: () => void }) => {
  const { anime } = useAnime({ removeMe });

  return (
    <div
      style={{ opacity: (1 - anime) / 2 }}
      className="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center"
    >
      <div
        className="rounded-full"
        style={{
          height: `${anime * 100}%`,
          width: `${anime * 100}%`,
          borderWidth: anime <= 0.5 ? (anime / 0.5) * 6 : 6,
          borderColor: "white",
        }}
      ></div>
    </div>
  );
};

const ActiveSpeakerAnimation = () => {
  const [children, setChildren] = useState<
    {
      type: string;
      id: string;
    }[]
  >([]);

  const count = useRef(1);
  const interval = useRef<null | ReturnType<typeof setInterval>>();

  const init = () => {
    interval.current = setInterval(() => {
      if (count.current % 6 === 0) {
        setChildren((s) => [...s, { type: "disc", id: createUID() }]);
      } else {
        setChildren((s) => [...s, { type: "ring", id: createUID() }]);
      }

      count.current = count.current + 1;
    }, 1000);
  };

  const stop = () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  useEffect(() => {
    init();

    return () => {
      stop();
    };
  }, []);

  return (
    <div className="relative" style={{ height: "100%", width: "100%" }}>
      {children.map(({ type, id }) => {
        return type === "disc" ? (
          <Disc
            key={`active-speaker-anime-child-${id}`}
            removeMe={() => {
              setChildren((s) => s.filter(({ id: _id }) => _id !== id));
            }}
          />
        ) : (
          <Ring
            key={`active-speaker-anime-child-${id}`}
            removeMe={() => {
              setChildren((s) => s.filter(({ id: _id }) => _id !== id));
            }}
          />
        );
      })}
    </div>
  );
};

export default ActiveSpeakerAnimation;
