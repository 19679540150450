import { appRtcClient, appRtcClients } from "../utils/constants";
import useLivekitRtcLocalParticipantMediaStats from "./livekitRtc/useLivekitRtcLocalParticipantMediaStats";

const useAppRtcLocalParticipantMediaStats =
  appRtcClient === appRtcClients.livekit
    ? useLivekitRtcLocalParticipantMediaStats
    : (): {
        __webcamOn: boolean;
        __webcamTrack?: MediaStreamTrack;
        __micOn: boolean;
        __micTrack?: MediaStreamTrack;
        webcamOn: boolean;
        webcamTrack: MediaStreamTrack | undefined;
        micOn: boolean;
        micTrack: MediaStreamTrack | undefined;
        screenShareVideoOn: boolean;
        screenShareVideoTrack: MediaStreamTrack | undefined;
        screenShareAudioOn: boolean;
        screenShareAudioTrack: MediaStreamTrack | undefined;
        inputFileVideoShareStreamPublications: {
          id: string;
          fileObjectUrl: string;
          inputFileVideoSourceElementId: string;
          videoTrack?: MediaStreamTrack;
          audioTrack?: MediaStreamTrack;
        }[];
        extraWebcamStreams: {
          extraWebcamId: string;
          videoTrack?: MediaStreamTrack;
        }[];
      } => ({
        webcamOn: false,
        webcamTrack: undefined,
        micOn: false,
        micTrack: undefined,
        screenShareVideoOn: false,
        screenShareVideoTrack: undefined,
        screenShareAudioOn: false,
        screenShareAudioTrack: undefined,
        inputFileVideoShareStreamPublications: [],
        extraWebcamStreams: [],
        __webcamOn: false,
        __webcamTrack: undefined,
        __micOn: false,
        __micTrack: undefined,
      });

export default useAppRtcLocalParticipantMediaStats;
