import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useIsParticipantMicDenied from "../../hooks/appState/useIsParticipantMicDenied";
import useIsParticipantCameraDenied from "../../hooks/appState/useIsParticipantCameraDenied";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import useMainViewLayout from "../../hooks/appState/useMainViewLayout";
import useFileShareStreams from "../../hooks/streams/useFileShareStreams";
import useBannerFolders from "../../hooks/banners/useBannerFolders";
import useActiveBanner from "../../hooks/banners/useActiveBanner";
import useBanners from "../../hooks/banners/useBanners";
import setQuickStart from "../../apis/broadcasts/set-quick-start";
import sleep from "../../utils/sleep";
import useBrands from "../../hooks/brands/useBrands";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import {
  interactivityModes,
  quickstartAutoOpenPopupFirstTimeId,
  quickstartDummyParticipants,
  quickstartDummyPresentationId,
  sidePanelModes,
  sidePanelAppModes,
  sidePanelBannersModes,
  mainViewLayouts,
} from "../../utils/constants";
import answerWordcloud from "../../apis/apps/wordclouds/answer-wordcloud";
import useInteractivityMode from "../../hooks/appState/useInteractivityMode";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

import { bannerTypes } from "../../listners/banners/ActiveBannerListner";
import { getAppBannerId } from "../sidePanel/banners/banners/BannerItem";
import createPoll from "../../apis/apps/polls/create-poll";
import createWordcloud from "../../apis/apps/wordclouds/create-wordcloud";
import answerPoll from "../../apis/apps/polls/answer-poll";
import { useWindowSize } from "@react-hook/window-size";
import {
  MdClose,
  MdOutlineRadioButtonUnchecked,
  MdQuestionMark,
  MdRefresh,
  MdTaskAlt,
} from "react-icons/md";
import Modal from "react-modal";

import Tooltip from "../../components/Tooltip";
import deleteWordcloud from "../../apis/apps/wordclouds/delete-wordcloud";
import deletePoll from "../../apis/apps/polls/delete-poll";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import {
  removeMSQuickstartBannerFolders,
  removeMSQuickstartBrands,
  removeMSQuickstartPolls,
  removeMSQuickstartWordclouds,
  setMSQuickstartBannerFolders,
  setMSQuickstartBrands,
  setMSQuickstartPolls,
  setMSQuickstartWordclouds,
} from "../../apis/quickstart";
import { CallBackProps } from "react-joyride";
import useRtcLocalParticipantMediaStatsActions from "../../appRtc/useRtcLocalParticipantMediaStatsActions";

export const quickstartTargetComponentClassNames = {
  //
  //
  main_view_container: "main_view_container",
  host_controls_container: "host_controls_container",
  local_mic_toggle_bottom_controls_button:
    "local_mic_toggle_bottom_controls_button",
  local_webcam_toggle_bottom_controls_button:
    "local_webcam_toggle_bottom_controls_button",
  in_queue_local_participant_webcam_stream_container:
    "in_queue_local_participant_webcam_stream_container",
  invite_speakers_and_add_to_stream: "invite_speakers_and_add_to_stream",
  local_share_bottom_controls_button: "local_share_bottom_controls_button",
  in_queue_local_participant_screen_share_stream_container:
    "in_queue_local_participant_screen_share_stream_container",
  in_queue_local_participant_presentation_stream_container:
    "in_queue_local_participant_presentation_stream_container",
  main_view_layouts_control_container: "main_view_layouts_control_container",
  interactivity_mode_container: "interactivity_mode_container",
  interactivity_mode_container_studio: "interactivity_mode_container_studio",
  interactivity_mode_container_conference:
    "interactivity_mode_container_conference",
  main_view_layouts_control_layout_selection_container:
    "main_view_layouts_control_layout_selection_container",
  auto_layout_icon_container: "auto_layout_icon_container",
  //
  //
  brand_panel_button: "brand_panel_button",
  brand_list: "brand_list",
  brand_theme: "brand_theme",
  brand_logo: "brand_logo",
  brand_overlay: "brand_overlay",
  brand_video: "brand_video",
  brand_background: "brand_background",
  brand_music: "brand_music",
  brand_main_view_container: "brand_main_view_container",

  //
  //
  broadcast_section: "broadcast_section",
  private_chat_panel_button: "private_chat_panel_button",
  qna_panel_button: "qna_panel_button",
  participants_panel_button: "participants_panel_button",
  waiting_room_section: "waiting_room_section",
  //
  //
  apps_section: "apps_section",
  banners_section: "banners_section",
  create_banner_folder: "create_banner_folder",
  create_banners: "create_banners",
  banner_main_view_container: "banner_main_view_container",
  //
  //
  polls_section: "polls_section",
  create_poll: "create_poll",
  poll_detail: "poll_detail",
  submit_poll_answer: "submit_poll_answer",
  poll_main_view_container: "poll_main_view_container",
  //
  //
  wordclouds_section: "wordclouds_section",
  create_wordcloud: "create_wordcloud",
  wordcloud_detail: "wordcloud_detail",
  submit_wordcloud_answer: "submit_wordcloud_answer",
  wordcloud_main_view_container: "wordcloud_main_view_container",

  //
  //
};

const quickStartSteps = {
  strmV1: "strmV1",
  brndV1: "brndV1",
  brdcstChtV1: "brdcstChtV1",
  bnrV1: "bnrV1",
  polV1: "polV1",
  wrdcldV1: "wrdcldV1",
};

const quickStartStepsTitle = {
  strmV1: "Stream view, Layouts and Backstage area.",
  brndV1: "How to use brands and customize the Stream view?",
  brdcstChtV1:
    "Broadcast, Q&A with audience and private chat groups with speakers.",
  bnrV1: "How to use banners?",
  polV1: "How to create polls and let your audience interact with it?",
  wrdcldV1: "How to create wordclouds and let your audience interact with it?",
};

const totalQuickStartSteps = Object.keys(quickStartSteps).length;

const init_stream_section_and_layout_v1_steps = [
  {
    content: {
      title: "Quickstart step 1",
      description: quickStartStepsTitle.strmV1,
      primaryButtonText: "Start",
      doItLater: true,
    },
    placement: "center",
    target: "body",
  },
  {
    target: `.${quickstartTargetComponentClassNames.main_view_container}`,
    content: {
      title: "This is Stream view",
      description:
        "Anything visible in this area will be broadcasted to live streaming providers such as Momento Live, YouTube, Facebook, Twitch, etc., when you click 'Go Live.' Additionally, it will be recorded if the recording feature is activated.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.host_controls_container}`,
    content: {
      title: "This is Backstage area",
      description:
        "In this section, webcam streams, screen shares, and slideshare streams of all participants will be displayed by default. Please note that an item will only become visible in the stream view once it is added to the stream.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.local_mic_toggle_bottom_controls_button}`,
    content: {
      description:
        "With this, you can toggle your microphone, and the audio will be streamed.",
      primaryButtonText: "Turn on mic",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.local_webcam_toggle_bottom_controls_button}`,
    content: {
      description:
        "Using this, you can toggle your webcam, and the video will be streamed.",
      primaryButtonText: "Turn on webcam",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.in_queue_local_participant_webcam_stream_container}`,
    content: {
      title: "Your backstage view",
      description:
        "You are not currently added to the Stream view. By clicking 'Add to stream', you will be included in the Stream view.",
      primaryButtonText: "Add to stream",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.invite_speakers_and_add_to_stream}`,
    content: {
      title: "Invite speakers",
      description:
        "Invite speakers to join your stream so they can share information with your viewers.",
      primaryButtonText: "Invite and Add to stream",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_ADD_DUMMY_WEBCAM_STREAMS,
      waitForSomeSecondsToGoNextOnClickButtonText: "inviting speakers...",
      waitForSomeSecondsToGoNextOnClickDuration: 4000,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.local_share_bottom_controls_button}`,
    content: {
      description:
        "From here, you can enable stream sharing. You can share your screen, and you can also share videos and slides from your computer. Clicking the 'Share presentation' button will prompt you to share your screen.",
      primaryButtonText: "Share presentation",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_ADD_DUMMY_PRESENTATION,
      waitForSomeSecondsToGoNextOnClickButtonText: "adding presentation...",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.in_queue_local_participant_presentation_stream_container}`,
    content: {
      title: "You presentation is in backstage view",
      description:
        "Your presentation is currently not added to the Stream view. By clicking 'Add to stream', your presentation will be included in the Stream view.",
      primaryButtonText: "Add to stream",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.main_view_layouts_control_container}`,
    content: {
      title: "Stream view Layout controls",
      description:
        "With this section, you can switch between different layouts in the Stream view. Additionally, you have the option to change between interactivity modes.",
      primaryButtonText: "Change layouts !!!",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.main_view_layouts_control_layout_selection_container}`,
    content: {
      description:
        "You can switch between different layouts, such as 'Solo layout,' 'Group layout,' or 'SCREEN layout.'",
      waitForStepCompletedEventToNext:
        appEventEmitterEvents.QUICKSTART_CHANGE_LAYOUTS_COMPLETED,
      waitForStepCompletedEventToNextButtonText: "changing layouts...",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.interactivity_mode_container}`,
    content: {
      description: "Choose between Interactivity modes",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.interactivity_mode_container_studio}`,
    content: {
      title: "Studio mode",
      description:
        "In Studio mode, the host has strict control over which streams are visible in the Stream view.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.interactivity_mode_container_conference}`,
    content: {
      title: "Conference mode",
      description:
        "In Conference mode, the system operates in an automatic mode where the Studio intelligently decides which streams should be visible in the Stream view. Additionally, the host can exert control over streams using the 'Pin' feature, exclusive to Conference mode. You also have the option to set the maximum number of participants to be visible in the Stream area.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.auto_layout_icon_container}`,
    content: {
      title: "Auto Layout",
      description: `Only in Conference mode, there is also an "Auto Layout" feature in which Studio will intelligently decide which layout should be active in Stream view.`,
      primaryButtonText: "Finish",
    },
  },
];

const init_brand_v1_steps = [
  {
    content: {
      title: "Quickstart step 2",
      description: quickStartStepsTitle.brndV1,
      primaryButtonText: "Start",
      doItLater: true,
    },
    placement: "center",
    target: "body",
  },
  {
    target: `.${quickstartTargetComponentClassNames.brand_panel_button}`,
    content: {
      title: "Brands section",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.brand_list}`,
    content: {
      title: "Brands list and actions",
      description:
        "You can create a new brand, edit the brand name, or delete an existing brand.",
      primaryButtonText: "Create new brand",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_CREATE_NEW_BRAND,
      waitForSomeSecondsToGoNextOnClickButtonText: "creating new brand...",
      waitForSomeSecondsToGoNextOnClickDuration: 5000,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.brand_theme}`,
    content: {
      title: "Theme",
      description:
        "You have the option to set the brand color and toggle whether you want to display the speaker's name on the Stream view.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.brand_logo}`,
    content: {
      title: "Logo",
      description:
        "The selected logo will be shown on the top right corner of Stream view.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_LOGO,
      waitForSomeSecondsToGoNextOnClickButtonText:
        "adding logo and activating...",
      waitForSomeSecondsToGoNextOnClickDuration: 5000,
      primaryButtonText: "Add logo and activate",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.brand_overlay}`,
    content: {
      title: "Overlays",
      description:
        "The overlay will be displayed at the very top of the Stream view. You can choose from default options or upload your own image or GIF files as an overlay.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.brand_video}`,
    content: {
      title: "Videos",
      description:
        "You can play a countdown video or any other video in the Stream view. Choose from default options or upload your own video files as video clips.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_VIDEO_CLIP,
      waitForSomeSecondsToGoNextOnClickButtonText:
        "adding video and activating...",
      waitForSomeSecondsToGoNextOnClickDuration: 5000,
      primaryButtonText: "Add video and activate",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.brand_background}`,
    content: {
      title: "Backgrounds",
      description:
        "You can set a background for the Stream view by choosing from default options or uploading your own image files as backgrounds.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_BACKGROUND,
      waitForSomeSecondsToGoNextOnClickButtonText:
        "adding background image and activating...",
      waitForSomeSecondsToGoNextOnClickDuration: 5000,
      primaryButtonText: "Add background and activate",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.brand_music}`,
    content: {
      title: "Background Music",
      description:
        "As the name suggests, you can play background music. Choose from default options or upload your own music files as background music.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_BACKGROUND_MUSIC,
      waitForSomeSecondsToGoNextOnClickButtonText:
        "adding music and activating...",
      waitForSomeSecondsToGoNextOnClickDuration: 3000,
      primaryButtonText: "Add music and activate",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.brand_main_view_container}`,
    content: {
      title: "Active brand in Stream view",
      waitForSomeSecondsToGoNextOnClick: true,
      waitForSomeSecondsToGoNextOnClickButtonText: "deleting brand...",
      waitForSomeSecondsToGoNextOnClickDuration: 3000,
      primaryButtonText: "Finish",
    },
  },
];

const init_go_live_private_chat_qna_participants_v1_steps = [
  {
    content: {
      title: "Quickstart step 3",
      description: quickStartStepsTitle.brdcstChtV1,
      primaryButtonText: "Start",
      doItLater: true,
    },
    placement: "center",
    target: "body",
  },
  {
    target: `.${quickstartTargetComponentClassNames.broadcast_section}`,
    content: {
      title: "Broadcast section",
      description:
        "From here, you can edit the broadcast destination. After selecting your broadcast channels, you can go live on those channels. Additionally, you can start recording while going live.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.private_chat_panel_button}`,
    content: {
      title: "Private chat",
      description:
        "In this section, you can engage in private chat conversations with other speakers who have joined the studio in the same session. You also have the option to create multiple private chat groups.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.qna_panel_button}`,
    content: {
      title: "Q&A",
      description:
        "Here, you can chat with your audience. You will receive all incoming messages from the audience who has joined your live stream on Momento Live, Youtube, Twitch, etc.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.participants_panel_button}`,
    content: {
      title: "Participants",
      description:
        "In this section, all speakers who have joined your stream will be visible.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.waiting_room_section}`,
    content: {
      title: "Waiting room",
      description:
        "By default, all speakers can join the studio using the share URL. However, you can control each participant's ability to join the studio by enabling the waiting room.",
      primaryButtonText: "Finish",
    },
  },
];

const init_app_banner_v1_steps = [
  {
    content: {
      title: "Quickstart step 4",
      description: quickStartStepsTitle.bnrV1,
      primaryButtonText: "Start",
      doItLater: true,
    },
    placement: "center",
    target: "body",
  },
  {
    target: `.${quickstartTargetComponentClassNames.apps_section}`,
    content: {
      title: "Apps",
      description:
        "In the Apps section, you can engage with your audience by creating polls, word clouds, and more. Additionally, you have the option to create banners that will be visible in the stream view.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.banners_section}`,
    content: {
      title: "Banners",
      description:
        "Banners are utilized to highlight text on the stream view. You can create two types of banners: static banners and ticket banners, the latter scrolling across the bottom.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_APP_OPEN_BANNER_SECTION,
      waitForSomeSecondsToGoNextOnClickDuration: 200,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.create_banner_folder}`,
    content: {
      title: "Create a banner folder",
      description:
        "You can organize multiple banners into a single folder based on your use case.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICHSTART_CREATE_BANNER_FOLDER,
      waitForSomeSecondsToGoNextOnClickButtonText: "creating banner folder...",
      waitForSomeSecondsToGoNextOnClickDuration: 2000,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.create_banners}`,
    content: {
      title: "Create banners",
      description:
        "Create static and ticket banners within the new folder, and activate them on the stream view.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICHSTART_CREATE_BANNERS,
      waitForSomeSecondsToGoNextOnClickButtonText: "creating banners...",
      waitForSomeSecondsToGoNextOnClickDuration: 2000,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.banner_main_view_container}`,
    content: {
      title: "Active banners in Stream View",
      waitForSomeSecondsToGoNextOnClick: true,
      waitForSomeSecondsToGoNextOnClickButtonText: "deleting banners...",
      waitForSomeSecondsToGoNextOnClickDuration: 3000,
      primaryButtonText: "Finish",
    },
  },
];

const init_app_poll_v1_steps = [
  {
    content: {
      title: "Quickstart step 5",
      description: quickStartStepsTitle.polV1,
      primaryButtonText: "Start",
      doItLater: true,
    },
    placement: "center",
    target: "body",
  },
  {
    target: `.${quickstartTargetComponentClassNames.polls_section}`,
    content: {
      title: "Polls",
      description:
        "Polls are essential for gathering opinions from your audience. When a poll is active, it becomes visible to the audience in the Q&A section, allowing them to submit their answers. You also have the option to add the poll to the stream view.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_APP_OPEN_POLL_SECTION,
      waitForSomeSecondsToGoNextOnClickDuration: 200,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.create_poll}`,
    content: {
      title: "Create poll",
      description:
        "Creating a poll requires a question and multiple options. The audience can select any open option from the given choices.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_APP_CREATE_POLL,
      waitForSomeSecondsToGoNextOnClickButtonText: "Creating poll...",
      waitForSomeSecondsToGoNextOnClickDuration: 3000,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.poll_detail}`,
    content: {
      title: "Add poll to stream",
      description:
        "When you add a poll to the stream view, it becomes visible on the live stream, allowing the audience to view and participate in poll activities.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.submit_poll_answer}`,
    content: {
      title: "Submit poll answer",
      primaryButtonText: "Submit answer",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_SUBMIT_POLL_ANSWER,
      waitForSomeSecondsToGoNextOnClickButtonText: "submitting answer...",
      waitForSomeSecondsToGoNextOnClickDuration: 2000,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.poll_main_view_container}`,
    content: {
      title: "Active poll in Stream view",
      primaryButtonText: "Finish",
      waitForSomeSecondsToGoNextOnClick: true,
      waitForSomeSecondsToGoNextOnClickButtonText: "deleting poll...",
      waitForSomeSecondsToGoNextOnClickDuration: 3000,
    },
  },
];

const init_app_wordcloud_v1_steps = [
  {
    content: {
      title: "Quickstart step 6",
      description: quickStartStepsTitle.wrdcldV1,
      primaryButtonText: "Start",
      doItLater: true,
    },
    placement: "center",
    target: "body",
  },
  {
    target: `.${quickstartTargetComponentClassNames.wordclouds_section}`,
    content: {
      title: "Wordclouds",
      description:
        "Word Clouds are essential when you want diverse responses from your audience. Each unique answer contributes to the Word Cloud, with more frequent answers appearing larger. You can also showcase the Word Cloud on the stream view for added engagement.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_APP_OPEN_WORDCLOUD_SECTION,
      waitForSomeSecondsToGoNextOnClickDuration: 200,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.create_wordcloud}`,
    content: {
      title: "Create wordcloud",
      description:
        "Creating a Word Cloud requires only a question, as users can submit unique single-word answers. Please note that audience responses will be restricted to a single word.",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_APP_CREATE_WORDCLOUD,
      waitForSomeSecondsToGoNextOnClickButtonText: "Creating wordcloud...",
      waitForSomeSecondsToGoNextOnClickDuration: 3000,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.wordcloud_detail}`,
    content: {
      title: "Add wordcloud to stream",
      description:
        "When you add a wordcloud to the stream view, it becomes visible on the live stream, allowing the audience to view and participate in wordcloud activities.",
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.submit_wordcloud_answer}`,
    content: {
      title: "Submit wordcloud answer",
      primaryButtonText: "Submit answer",
      waitForSomeSecondsToGoNextOnClick: true,
      emitAppEventBeforeOnPrimaryClick:
        appEventEmitterEvents.QUICKSTART_SUBMIT_WORDCLOUD_ANSWER,
      waitForSomeSecondsToGoNextOnClickButtonText: "submitting answer...",
      waitForSomeSecondsToGoNextOnClickDuration: 3000,
    },
  },
  {
    target: `.${quickstartTargetComponentClassNames.wordcloud_main_view_container}`,
    content: {
      title: "Active wordcloud in Stream view",
      primaryButtonText: "Finish",
      waitForSomeSecondsToGoNextOnClick: true,
      waitForSomeSecondsToGoNextOnClickButtonText: "deleting wordcloud...",
      waitForSomeSecondsToGoNextOnClickDuration: 3000,
    },
  },
];

const QuickStartTourBeaconComponent = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  return (
    <React.Fragment>
      <button
        className="hidden fixed top-0 left-0"
        ref={(el) => {
          if (el) {
            el.click();
          }
        }}
        onClick={onClick}
      ></button>
    </React.Fragment>
  );
};

const QuickStartTourTooltipComponent = ({
  step,
  skipProps,
  primaryProps,
}: {
  step: {
    content: {
      doItLater?: boolean;
      title: string;
      description: string;
      primaryButtonText?: string;
      waitForStepCompletedEventToNext?: string;
      waitForStepCompletedEventToNextButtonText?: boolean;
      waitForSomeSecondsToGoNextOnClick?: boolean;
      waitForSomeSecondsToGoNextOnClickButtonText?: string;
      waitForSomeSecondsToGoNextOnClickDuration?: number;
      emitAppEventBeforeOnPrimaryClick?: string;
    };
  };
  skipProps: {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
  primaryProps: {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
}) => {
  const {
    doItLater,
    title,
    description,
    primaryButtonText,
    waitForStepCompletedEventToNext,
    waitForStepCompletedEventToNextButtonText,
    waitForSomeSecondsToGoNextOnClick,
    waitForSomeSecondsToGoNextOnClickButtonText,
    waitForSomeSecondsToGoNextOnClickDuration,
    emitAppEventBeforeOnPrimaryClick,
  } = step.content;

  const { setJoyrideProps } = useAppContext();

  const [primaryButtonVisible, setPrimaryButtonVisible] = useState(
    !waitForStepCompletedEventToNext
  );

  const [waitingForNSeconds, setWaitingForNSeconds] = useState(false);

  const [windowWidth] = useWindowSize();

  const _handleStepCompletedEventToNext = () => {
    setPrimaryButtonVisible(true);
  };

  useEffect(() => {
    if (waitForStepCompletedEventToNext) {
      appEventEmitter.on(
        waitForStepCompletedEventToNext,
        _handleStepCompletedEventToNext
      );

      return () => {
        appEventEmitter.off(
          waitForStepCompletedEventToNext,
          _handleStepCompletedEventToNext
        );
      };
    }
  }, [waitForStepCompletedEventToNext]);

  return (
    <div
      style={{ maxWidth: windowWidth * 0.4 }}
      className="bg-white p-3 rounded-lg flex flex-col gap-1"
    >
      {title ? (
        <p className="font-bold text-lg">{title}</p>
      ) : (
        <React.Fragment />
      )}

      {description ? (
        <p className="font-medium text-md">{description}</p>
      ) : (
        <React.Fragment />
      )}

      <div
        className={`mt-2 flex justify-between flex-row-reverse items-center ${
          primaryButtonVisible && !waitingForNSeconds
            ? ""
            : "opacity-50 cursor-none pointer-events-none"
        }`}
      >
        <button
          autoFocus
          className="btn btn-primary btn-sm text-white normal-case"
          onClick={
            waitForSomeSecondsToGoNextOnClick
              ? async (e) => {
                  appEventEmitter.emit(
                    appEventEmitterEvents.QUICKSTART_EVENT_EMITTED,
                    { type: emitAppEventBeforeOnPrimaryClick }
                  );

                  setWaitingForNSeconds(true);

                  await sleep(
                    waitForSomeSecondsToGoNextOnClickDuration || 1500
                  );

                  setWaitingForNSeconds(false);

                  primaryProps.onClick(e);
                }
              : primaryProps.onClick
          }
        >
          {waitingForNSeconds
            ? waitForSomeSecondsToGoNextOnClickButtonText
            : primaryButtonVisible
            ? primaryButtonText || "Next"
            : waitForStepCompletedEventToNextButtonText}
        </button>

        {doItLater ? (
          <button
            className="btn btn-primary btn-outline btn-sm text-white normal-case"
            onClick={async (e) => {
              skipProps.onClick(e);

              await sleep(2000);

              setJoyrideProps({ enabled: false, props: null });
            }}
          >
            Do it later
          </button>
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  );
};

const defaultJoyrideProps = {
  run: true,
  continuous: true,
  spotlightPadding: 0,
  spotlightClicks: false,
  disableScrolling: true,
  disableOverlayClose: true,
  disableCloseOnEsc: true,
  scrollDuration: 0,
  beaconComponent: QuickStartTourBeaconComponent,
  tooltipComponent: QuickStartTourTooltipComponent,
};

const QuickStartTourListner = () => {
  const {
    activeBrandId,
    joyrideProps,
    setJoyrideProps,
    mainViewSelectedStreams,
    allParticipantsInQueueStreams,
    interactivityMode,
    //
    sidePanelMode,
    sidePanelAppMode,
    sidePanelBannersMode,
    sidePanelPrivateChatMode,
    sidePanelActiveBanerId,
    sidePanelActivePollId,
    sidePanelActiveWordcloudId,
    //
    setSidePanelMode,
    setSidePanelAppMode,
    setSidePanelBannersMode,
    setSidePanelPrivateChatMode,
    setSidePanelActiveBanerId,
    setSidePanelActivePollId,
    setSidePanelActiveWordcloudId,
    setWordclouds,
    setPolls,
    //
  } = useAppContext();

  const {
    studioUserId,
    broadcastId,
    quickstartStepsCompleted,
    quickstartStepsSkipped,
    setQuickstartStepsCompleted: _setQuickstartStepsCompleted,
    setQuickstartStepsSkipped: _setQuickstartStepsSkipped,
  } = useAppConfigContext();

  const { localParticipantId } = useLocalParticipantId();

  const { micDenied } = useIsParticipantMicDenied({
    participantId: localParticipantId,
  });
  const { cameraDenied } = useIsParticipantCameraDenied({
    participantId: localParticipantId,
  });

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const { changeMainViewLayout } = useMainViewLayout();
  const { enableFileShareStream, disableFileShareStream } =
    useFileShareStreams();
  const { createBannerFolder, deleteBannerFolder } = useBannerFolders();
  const { toggleActiveLowerThird, toggleActiveTicker } = useActiveBanner();
  const { createBanner } = useBanners();

  const quickstartStepsRemaining = useMemo(() => {
    const quickstartStepsCompletedAndSkipped = [
      ...quickstartStepsCompleted,
      ...quickstartStepsSkipped,
    ];

    const quickstartStepsRemaining = Object.keys(quickStartSteps).filter(
      (key) => {
        return !quickstartStepsCompletedAndSkipped.find((step) => step === key);
      }
    );

    return quickstartStepsRemaining;
  }, [quickstartStepsCompleted, quickstartStepsSkipped]);

  const interactivityModeRef = useRef(interactivityMode);
  const micDeniedRef = useRef(micDenied);
  const cameraDeniedRef = useRef(cameraDenied);
  const localParticipantIdRef = useRef(localParticipantId);
  const broadcastIdRef = useRef(broadcastId);
  const studioUserIdRef = useRef(studioUserId);
  const quickstartStepsCompletedRef = useRef(quickstartStepsCompleted);
  const quickstartStepsSkippedRef = useRef(quickstartStepsSkipped);
  const quickstartStepsRemainingRef = useRef(quickstartStepsRemaining);

  const sidePanelModeRef = useRef(sidePanelMode);
  const sidePanelAppModeRef = useRef(sidePanelAppMode);
  const sidePanelBannersModeRef = useRef(sidePanelBannersMode);
  const sidePanelPrivateChatModeRef = useRef(sidePanelPrivateChatMode);
  const sidePanelActiveBanerIdRef = useRef(sidePanelActiveBanerId);
  const sidePanelActivePollIdRef = useRef(sidePanelActivePollId);
  const sidePanelActiveWordcloudIdRef = useRef(sidePanelActiveWordcloudId);

  useEffect(() => {
    interactivityModeRef.current = interactivityMode;
  }, [interactivityMode]);
  useEffect(() => {
    micDeniedRef.current = micDenied;
  }, [micDenied]);
  useEffect(() => {
    cameraDeniedRef.current = cameraDenied;
  }, [cameraDenied]);
  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);
  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);
  useEffect(() => {
    studioUserIdRef.current = studioUserId;
  }, [studioUserId]);
  useEffect(() => {
    quickstartStepsCompletedRef.current = quickstartStepsCompleted;
  }, [quickstartStepsCompleted]);
  useEffect(() => {
    quickstartStepsSkippedRef.current = quickstartStepsSkipped;
  }, [quickstartStepsSkipped]);
  useEffect(() => {
    quickstartStepsRemainingRef.current = quickstartStepsRemaining;
  }, [quickstartStepsRemaining]);
  useEffect(() => {
    sidePanelModeRef.current = sidePanelMode;
  }, [sidePanelMode]);
  useEffect(() => {
    sidePanelAppModeRef.current = sidePanelAppMode;
  }, [sidePanelAppMode]);
  useEffect(() => {
    sidePanelBannersModeRef.current = sidePanelBannersMode;
  }, [sidePanelBannersMode]);
  useEffect(() => {
    sidePanelPrivateChatModeRef.current = sidePanelPrivateChatMode;
  }, [sidePanelPrivateChatMode]);
  useEffect(() => {
    sidePanelActiveBanerIdRef.current = sidePanelActiveBanerId;
  }, [sidePanelActiveBanerId]);
  useEffect(() => {
    sidePanelActivePollIdRef.current = sidePanelActivePollId;
  }, [sidePanelActivePollId]);
  useEffect(() => {
    sidePanelActiveWordcloudIdRef.current = sidePanelActiveWordcloudId;
  }, [sidePanelActiveWordcloudId]);

  const setQuickstartStepsCompletedAndSkipped = async ({
    quickstartStepsCompleted: _quickstartStepsCompleted,
    quickstartStepsSkipped: _quickstartStepsSkipped,
  }: {
    quickstartStepsCompleted: string[];
    quickstartStepsSkipped: string[];
  }) => {
    const quickstartStepsCompleted = _quickstartStepsCompleted?.length
      ? _quickstartStepsCompleted
      : ["N/A"];

    const quickstartStepsSkipped = _quickstartStepsSkipped?.length
      ? _quickstartStepsSkipped
      : ["N/A"];

    const { success } = await setQuickStart({
      quickstartStepsCompleted,
      quickstartStepsSkipped,
      userId: studioUserIdRef.current,
    });

    if (success) {
      _setQuickstartStepsCompleted(quickstartStepsCompleted);

      await sleep(100);

      _setQuickstartStepsSkipped(quickstartStepsSkipped);

      await sleep(100);
    }
  };

  const _handleStepCompleted = async (step: string) => {
    const quickstartStepsCompleted = quickstartStepsCompletedRef.current;
    const quickstartStepsSkipped = quickstartStepsSkippedRef.current;

    await setQuickstartStepsCompletedAndSkipped({
      quickstartStepsCompleted: [...quickstartStepsCompleted, step],
      quickstartStepsSkipped: quickstartStepsSkipped.filter((s) => s !== step),
    });
  };

  const { toggleMic, toggleWebcam, disableWebcam, disableMic } =
    useRtcLocalParticipantMediaStatsActions();

  const { micOn, webcamOn } = useParticipantMediaStats(localParticipantId);

  const {
    add: addBrand,
    active: activeBrand,
    remove: removeBrand,
  } = useBrands();

  const {
    isInMainView,
    anyDummyParticipantsInMainView,
    isFileShareStreamInMainView,
    hasFileShareStreamInQueue,
  } = useMemo(() => {
    const streamId = generateStreamId({
      participantId: localParticipantId,
      type: streamTypes.WEBCAM,
    });

    const anyDummyParticipantsInMainView = !!quickstartDummyParticipants
      ?.map(({ participantId }) => {
        const streamId = generateStreamId({
          participantId,
          type: streamTypes.WEBCAM,
        });

        return !!mainViewSelectedStreams.get(streamId);
      })
      ?.filter((s) => s)?.length;

    const isInMainView = !!mainViewSelectedStreams.get(streamId);

    const screenShareStreamId = generateStreamId({
      mode: streamModes.PDF,
      participantId: localParticipantIdRef.current,
      type: streamTypes.SHARE,
      fileId: quickstartDummyPresentationId,
    });

    const isFileShareStreamInMainView =
      !!mainViewSelectedStreams.get(screenShareStreamId);

    const hasFileShareStreamInQueue = !!allParticipantsInQueueStreams.filter(
      ({ participantId, type }) =>
        type.includes(streamTypes.SHARE) &&
        (type.includes(streamModes.PDF) || type.includes(streamModes.PPT)) &&
        participantId === localParticipantId
    ).length;

    return {
      isInMainView,
      anyDummyParticipantsInMainView,
      isFileShareStreamInMainView,
      hasFileShareStreamInQueue,
    };
  }, [
    localParticipantId,
    mainViewSelectedStreams,
    allParticipantsInQueueStreams,
  ]);

  const isInMainViewRef = useRef(isInMainView);
  const isFileShareStreamInMainViewRef = useRef(isFileShareStreamInMainView);
  const activeBrandIdRef = useRef(activeBrandId);
  const anyDummyParticipantsInMainViewRef = useRef(
    anyDummyParticipantsInMainView
  );
  const micOnRef = useRef(micOn);
  const webcamOnRef = useRef(webcamOn);
  const hasFileShareStreamInQueueRef = useRef(hasFileShareStreamInQueue);
  const joyridePropsRef = useRef(joyrideProps);

  const previousActiveBrandIdRef = useRef<string | null | undefined>();
  const autoGeneratedBrandIdRef = useRef<string>();

  const listenForNewPollCreatedRef = useRef<boolean>();
  const listenForNewWordcloudCreatedRef = useRef<boolean>();
  const newPollCreatedIdRef = useRef<string>();
  const newPollCreatedOptionIdRef = useRef<string>();
  const newWordcloudCreatedIdRef = useRef<string>();
  const newCreateBannerFolderIdRef = useRef<string>();
  const newCreatedIdLowerThirdBannerRef = useRef();
  const newCreatedIdTickerBannerRef = useRef();

  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);
  useEffect(() => {
    isFileShareStreamInMainViewRef.current = isFileShareStreamInMainView;
  }, [isFileShareStreamInMainView]);
  useEffect(() => {
    activeBrandIdRef.current = activeBrandId;
  }, [activeBrandId]);
  useEffect(() => {
    anyDummyParticipantsInMainViewRef.current = anyDummyParticipantsInMainView;
  }, [anyDummyParticipantsInMainView]);
  useEffect(() => {
    micOnRef.current = micOn;
  }, [micOn]);
  useEffect(() => {
    webcamOnRef.current = webcamOn;
  }, [webcamOn]);
  useEffect(() => {
    hasFileShareStreamInQueueRef.current = hasFileShareStreamInQueue;
  }, [hasFileShareStreamInQueue]);
  useEffect(() => {
    joyridePropsRef.current = joyrideProps;
  }, [joyrideProps]);

  const _handleAddDummyWebcamsStreamToMainViewStreams = async () => {
    for (let index = 0; index < quickstartDummyParticipants.length; index++) {
      const { participantId } = quickstartDummyParticipants[index];

      await sleep(1000);

      addToMainViewStreams({
        participantId,
        type: streamTypes.WEBCAM,
      });
    }
  };

  const _handleRemoveDummyWebcamsStreamFromMainViewStreams = async () => {
    if (anyDummyParticipantsInMainViewRef.current) {
      for (let index = 0; index < quickstartDummyParticipants.length; index++) {
        const { participantId } = quickstartDummyParticipants[index];

        removeFromMainViewStreams({
          participantId,
          type: streamTypes.WEBCAM,
        });

        await sleep(200);
      }
    }
  };

  const _handleAddWebcamStreamToMainViewStreams = () => {
    addToMainViewStreams({
      participantId: localParticipantIdRef.current,
      type: streamTypes.WEBCAM,
    });
  };

  const _handleRemoveWebcamStreamFromMainViewStreams = async () => {
    if (isInMainViewRef.current) {
      removeFromMainViewStreams({
        participantId: localParticipantIdRef.current,
        type: streamTypes.WEBCAM,
      });

      await sleep(200);
    }
  };

  const _handleAddPresentationStreamToMainViewStreams = () => {
    addToMainViewStreams({
      mode: streamModes.PDF,
      // participantId: localParticipantIdRef.current,
      participantId: `${quickstartDummyPresentationId}`,
      type: streamTypes.SHARE,
      fileId: quickstartDummyPresentationId,
    });
  };

  const _handleRemovePresentationStreamFromMainViewStreams = async () => {
    if (isFileShareStreamInMainViewRef.current)
      removeFromMainViewStreams({
        mode: streamModes.PDF,
        participantId: localParticipantIdRef.current,
        type: streamTypes.SHARE,
        fileId: quickstartDummyPresentationId,
      });

    await sleep(200);
  };

  const _handleAddPollToMainViewStreams = () => {
    addToMainViewStreams({
      mode: streamModes.APPPOLL,
      participantId: localParticipantIdRef.current,
      type: streamTypes.SHARE,
      pollId: newPollCreatedIdRef.current,
    });
  };

  const _handleRemovePollFromMainViewStreams = () => {
    removeFromMainViewStreams({
      mode: streamModes.APPPOLL,
      participantId: localParticipantIdRef.current,
      type: streamTypes.SHARE,
      pollId: newPollCreatedIdRef.current,
    });
  };

  const _handleAddWordcloudToMainViewStreams = () => {
    addToMainViewStreams({
      mode: streamModes.APPWORDCLOUD,
      participantId: localParticipantIdRef.current,
      type: streamTypes.SHARE,
      wordcloudId: newWordcloudCreatedIdRef.current,
    });
  };

  const _handleRemoveWordcloudFromMainViewStreams = () => {
    removeFromMainViewStreams({
      mode: streamModes.APPWORDCLOUD,
      participantId: localParticipantIdRef.current,
      type: streamTypes.SHARE,
      wordcloudId: newWordcloudCreatedIdRef.current,
    });
  };

  const disableDummyPresentation = async () => {
    if (hasFileShareStreamInQueueRef.current) {
      disableFileShareStream({ id: quickstartDummyPresentationId });

      await sleep(100);
    }
  };

  const _answerPoll = async () => {
    const id = newPollCreatedIdRef.current;
    const optionId = newPollCreatedOptionIdRef.current;

    if (id && optionId) {
      await answerPoll({ broadcastId, optionId, pollId: id });

      await sleep(300);

      setPolls((s) => {
        const index = s.findIndex(({ id: _id }) => `${id}` === `${_id}`);

        s[index] = { ...s[index], selfSubmittedOptionId: optionId };

        return [...s];
      });
    }
  };

  const _answerWordcloud = async () => {
    const id = newWordcloudCreatedIdRef.current;
    const answer = "pineapple";
    const answer2 = "apple";
    const answer3 = "mango";

    await answerWordcloud({ broadcastId, answer, wordcloudId: id as string });
    await answerWordcloud({ broadcastId, answer, wordcloudId: id as string });
    await answerWordcloud({ broadcastId, answer, wordcloudId: id as string });
    await answerWordcloud({
      broadcastId,
      answer: answer2,
      wordcloudId: id as string,
    });
    await answerWordcloud({
      broadcastId,
      answer: answer2,
      wordcloudId: id as string,
    });
    await answerWordcloud({
      broadcastId,
      answer: answer3,
      wordcloudId: id as string,
    });

    await sleep(300);

    setWordclouds((s) => {
      const index = s.findIndex(({ id: _id }) => `${_id}` === `${id}`);

      s[index] = { ...s[index], selfSubmittedAnswer: answer };

      return [...s];
    });
  };

  const { changeInteractivityMode } = useInteractivityMode();

  const waitForCameraPermissionGranted = async () => {
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        const cameraDenied = cameraDeniedRef.current;

        if (!cameraDenied) {
          clearInterval(interval);

          resolve(undefined);
        }
      }, 1000);
    });
  };

  const waitForMicPermissionGranted = async () => {
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        const micDenied = micDeniedRef.current;

        if (!micDenied) {
          clearInterval(interval);

          resolve(undefined);
        }
      }, 1000);
    });
  };

  const resetStreamView = async () => {
    if (cameraDeniedRef.current) {
      appEventEmitter.emit(appEventEmitterEvents.ASK_CAMERA_PERMISSION);
      await waitForCameraPermissionGranted();
    }

    if (micDeniedRef.current) {
      appEventEmitter.emit(appEventEmitterEvents.ASK_MIC_PERMISSION);
      await waitForMicPermissionGranted();
    }

    await _handleRemoveWebcamStreamFromMainViewStreams();
    await _handleRemoveDummyWebcamsStreamFromMainViewStreams();
    await _handleRemovePresentationStreamFromMainViewStreams();

    if (interactivityModeRef.current === interactivityModes.CONFERENCE) {
      changeInteractivityMode({ interactivityMode: interactivityModes.STUDIO });

      await sleep(100);
    }

    if (webcamOnRef.current) {
      disableWebcam();
      await sleep(100);
    }

    if (micOnRef.current) {
      disableMic();
      await sleep(100);
    }

    await disableDummyPresentation();
  };

  const resetSidePanelModes = () => {
    if (sidePanelAppModeRef.current) {
      setSidePanelAppMode(null);
    }
    if (sidePanelBannersModeRef.current) {
      setSidePanelBannersMode(null);
    }
    if (sidePanelPrivateChatModeRef.current) {
      setSidePanelPrivateChatMode(null);
    }
    if (sidePanelActiveBanerIdRef.current) {
      setSidePanelActiveBanerId(null);
    }
    if (sidePanelActivePollIdRef.current) {
      setSidePanelActivePollId(null);
    }
    if (sidePanelActiveWordcloudIdRef.current) {
      setSidePanelActiveWordcloudId(null);
    }
  };

  const _handleClearJoyrideProps = async () => {
    if (joyridePropsRef.current?.enabled) {
      setJoyrideProps({ enabled: false, props: null });
      await sleep(1000);
    }
  };

  const init_stream_section_and_layout_v1 = async () => {
    await _handleClearJoyrideProps();

    await resetStreamView();

    const steps = init_stream_section_and_layout_v1_steps;

    setJoyrideProps({
      enabled: true,
      props: { steps, ...defaultJoyrideProps },
    });
  };

  const init_brand_v1 = async () => {
    await _handleClearJoyrideProps();

    resetSidePanelModes();

    setSidePanelMode(sidePanelModes.BRAND);

    const steps = init_brand_v1_steps;

    setJoyrideProps({
      enabled: true,
      props: { steps, ...defaultJoyrideProps },
    });
  };

  const init_go_live_private_chat_qna_participants_v1 = async () => {
    await _handleClearJoyrideProps();

    resetSidePanelModes();

    setSidePanelMode(sidePanelModes.PRIVATE_CHAT);

    const steps = init_go_live_private_chat_qna_participants_v1_steps;

    setJoyrideProps({
      enabled: true,
      props: { steps, ...defaultJoyrideProps },
    });
  };

  const init_app_banner_v1 = async () => {
    await _handleClearJoyrideProps();

    resetSidePanelModes();

    setSidePanelMode(sidePanelModes.APPS);

    const steps = init_app_banner_v1_steps;

    setJoyrideProps({
      enabled: true,
      props: { steps, ...defaultJoyrideProps },
    });
  };

  const init_app_poll_v1 = async () => {
    await _handleClearJoyrideProps();

    resetSidePanelModes();

    setSidePanelMode(sidePanelModes.APPS);

    const steps = init_app_poll_v1_steps;

    setJoyrideProps({
      enabled: true,
      props: { steps, ...defaultJoyrideProps },
    });
  };

  const init_app_wordcloud_v1 = async () => {
    await _handleClearJoyrideProps();

    resetSidePanelModes();

    setSidePanelMode(sidePanelModes.APPS);

    const steps = init_app_wordcloud_v1_steps;

    setJoyrideProps({
      enabled: true,
      props: { steps, ...defaultJoyrideProps },
    });
  };

  const _handleChangeLayouts = async () => {
    changeMainViewLayout({ layout: mainViewLayouts.SOLO });
    await sleep(2000);
    changeMainViewLayout({ layout: mainViewLayouts.CROPPED });
    await sleep(2000);
    changeMainViewLayout({ layout: mainViewLayouts.GROUP });
    await sleep(2000);
    changeMainViewLayout({ layout: mainViewLayouts.SPOTLIGHT });
    await sleep(2000);
    changeMainViewLayout({ layout: mainViewLayouts.NEWS });
    await sleep(2000);
    changeMainViewLayout({ layout: mainViewLayouts.SCREEN });
    await sleep(2000);
    changeMainViewLayout({ layout: mainViewLayouts.CINEMA });
    await sleep(2000);
    changeMainViewLayout({ layout: mainViewLayouts.PICTURE_IN_PICTURE });

    appEventEmitter.emit(
      appEventEmitterEvents.QUICKSTART_CHANGE_LAYOUTS_COMPLETED
    );
  };

  const _handleDeleteAutoGeneerateBrandAndActivatePreviousBrand = async () => {
    if (previousActiveBrandIdRef.current) {
      await activeBrand({ id: previousActiveBrandIdRef.current });
    }

    await sleep(1000);

    await removeMSQuickstartBrands({ userId: studioUserIdRef.current });

    if (autoGeneratedBrandIdRef.current) {
      await removeBrand({ id: autoGeneratedBrandIdRef.current, force: true });
    }
  };

  //
  //
  //

  const _handle_strmV1_completed = async () => {
    await _handleClearJoyrideProps();

    await _handleStepCompleted(quickStartSteps.strmV1);

    await resetStreamView();

    init_brand_v1();
  };

  const _handle_brndV1_completed = async () => {
    await _handleClearJoyrideProps();

    await _handleStepCompleted(quickStartSteps.brndV1);

    _handleDeleteAutoGeneerateBrandAndActivatePreviousBrand();

    init_go_live_private_chat_qna_participants_v1();
  };

  const _handle_brdcstChtV1_completed = async () => {
    await _handleClearJoyrideProps();

    await _handleStepCompleted(quickStartSteps.brdcstChtV1);

    init_app_banner_v1();
  };

  const _handle_bnrV1_completed = async () => {
    await _handleClearJoyrideProps();

    await _handleStepCompleted(quickStartSteps.bnrV1);

    await removeMSQuickstartBannerFolders({ userId: studioUserIdRef.current });

    await deleteBannerFolder({
      folderId: `${newCreateBannerFolderIdRef.current}`,
    });

    init_app_poll_v1();
  };

  const _handle_polV1_completed = async () => {
    await _handleClearJoyrideProps();

    _handleRemovePollFromMainViewStreams();

    await removeMSQuickstartPolls({ broadcastId: broadcastIdRef.current });

    await deletePoll({
      broadcastId: broadcastIdRef.current as string,
      pollId: newPollCreatedIdRef.current as string,
    });

    await _handleStepCompleted(quickStartSteps.polV1);

    init_app_wordcloud_v1();
  };

  const _handle_wrdcldV1_completed = async () => {
    await _handleClearJoyrideProps();

    _handleRemoveWordcloudFromMainViewStreams();

    await removeMSQuickstartWordclouds({ broadcastId: broadcastIdRef.current });

    await deleteWordcloud({
      broadcastId: broadcastIdRef.current as string,
      wordcloudId: newWordcloudCreatedIdRef.current as string,
    });

    await _handleStepCompleted(quickStartSteps.wrdcldV1);
  };

  const _handleJoyrideCallback = (data: CallBackProps) => {
    if (!data) {
      return;
    }

    if (data.action === "next" && data.lifecycle === "complete") {
      switch (data?.step?.target) {
        case `.${quickstartTargetComponentClassNames.local_mic_toggle_bottom_controls_button}`:
          toggleMic();
          break;

        case `.${quickstartTargetComponentClassNames.local_webcam_toggle_bottom_controls_button}`:
          toggleWebcam();
          break;

        case `.${quickstartTargetComponentClassNames.in_queue_local_participant_webcam_stream_container}`:
          _handleAddWebcamStreamToMainViewStreams();
          break;

        case `.${quickstartTargetComponentClassNames.in_queue_local_participant_presentation_stream_container}`:
          _handleAddPresentationStreamToMainViewStreams();
          break;

        case `.${quickstartTargetComponentClassNames.main_view_layouts_control_container}`:
          _handleChangeLayouts();
          break;

        case `.${quickstartTargetComponentClassNames.interactivity_mode_container_studio}`:
          changeInteractivityMode({
            interactivityMode: interactivityModes.CONFERENCE,
          });

          appEventEmitter.emit(
            appEventEmitterEvents.HIDE_HOST_IN_QUEUE_POPUP_MESSAGE
          );
          break;

        case `.${quickstartTargetComponentClassNames.brand_logo}`:
          appEventEmitter.emit(
            appEventEmitterEvents.QUICKSTART_CLOSE_BRAND_THEME
          );
          break;

        case `.${quickstartTargetComponentClassNames.brand_overlay}`:
          appEventEmitter.emit(
            appEventEmitterEvents.QUICKSTART_CLOSE_BRAND_LOGO
          );

          appEventEmitter.emit(
            appEventEmitterEvents.QUICKSTART_SCROLL_BRAND_PANEL_TO_BOTTOM
          );
          break;

        case `.${quickstartTargetComponentClassNames.brand_video}`:
          appEventEmitter.emit(
            appEventEmitterEvents.QUICKSTART_CLOSE_BRAND_OVERLAY
          );

          appEventEmitter.emit(
            appEventEmitterEvents.QUICKSTART_SCROLL_BRAND_PANEL_TO_BOTTOM
          );

          break;

        case `.${quickstartTargetComponentClassNames.brand_background}`:
          appEventEmitter.emit(
            appEventEmitterEvents.QUICKSTART_SCROLL_BRAND_PANEL_TO_BOTTOM
          );

          break;

        case `.${quickstartTargetComponentClassNames.brand_music}`:
          appEventEmitter.emit(
            appEventEmitterEvents.QUICKSTART_SCROLL_BRAND_PANEL_TO_BOTTOM
          );

          break;

        case `.${quickstartTargetComponentClassNames.broadcast_section}`:
          setSidePanelMode(sidePanelModes.PRIVATE_CHAT);
          break;

        case `.${quickstartTargetComponentClassNames.private_chat_panel_button}`:
          setSidePanelMode(sidePanelModes.QNA);
          break;

        case `.${quickstartTargetComponentClassNames.qna_panel_button}`:
          setSidePanelMode(sidePanelModes.PARTICIPANTS);
          break;

        case `.${quickstartTargetComponentClassNames.poll_detail}`:
          _handleAddPollToMainViewStreams();
          break;

        case `.${quickstartTargetComponentClassNames.wordcloud_detail}`:
          _handleAddWordcloudToMainViewStreams();
          break;

        //
        //
        //
        //

        case `.${quickstartTargetComponentClassNames.auto_layout_icon_container}`:
          _handle_strmV1_completed();
          break;

        case `.${quickstartTargetComponentClassNames.brand_main_view_container}`:
          _handle_brndV1_completed();
          break;

        case `.${quickstartTargetComponentClassNames.waiting_room_section}`:
          _handle_brdcstChtV1_completed();
          break;

        case `.${quickstartTargetComponentClassNames.banner_main_view_container}`:
          _handle_bnrV1_completed();
          break;

        case `.${quickstartTargetComponentClassNames.poll_main_view_container}`:
          _handle_polV1_completed();
          break;

        case `.${quickstartTargetComponentClassNames.wordcloud_main_view_container}`:
          _handle_wrdcldV1_completed();
          break;

        default:
          break;
      }
    }
  };

  const _handleQuickstartAddDummyWebcamStreams = () => {
    _handleAddDummyWebcamsStreamToMainViewStreams();
  };

  const _handleQuickstartAddDummyPresentation = () => {
    enableFileShareStream({
      id: quickstartDummyPresentationId,
      type: "PDF",
      pdfInfo: {
        totalPages: 2,
        pagesRemoteUrls: [
          {
            high: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/slides/quickstart-slide/slide-01.jpg",
          },
          {
            high: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/slides/quickstart-slide/slide-02.jpg",
          },
        ],
      },
      remoteUrl:
        "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/slides/quickstart-slide/original.pptx",
      resourceId: "",
    });
  };

  const _handleQuickstartCreateNewBrand = async () => {
    previousActiveBrandIdRef.current = activeBrandIdRef.current;

    const id = await addBrand({ name: "Auto generated", defaultOpen: true });

    autoGeneratedBrandIdRef.current = id;

    await sleep(100);

    await activeBrand({ id });

    await setMSQuickstartBrands({
      userId: studioUserIdRef.current,
      data: [id],
    });
  };

  const _handleQuickstartActiveBrandAddAndActivateLogo = () => {
    const activeBrandId = activeBrandIdRef.current;

    if (activeBrandId) {
      appEventEmitter.emit(
        appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_LOGO(activeBrandId),
        {
          remoteUrl:
            "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/logo/momento_logo.png",
        }
      );
    }
  };

  const _handleQuickstartActiveBrandAddAndActivateVideoClip = () => {
    const activeBrandId = activeBrandIdRef.current;

    if (activeBrandId) {
      appEventEmitter.emit(
        appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_VIDEO_CLIP(activeBrandId),
        {
          remoteUrl:
            "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/10-sec-countdown/original.mp4",
          videoThumbnail:
            "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/10-sec-countdown/thumbnail.jpeg",
          video_480:
            "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/10-sec-countdown/480.mp4",
          video_720:
            "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/10-sec-countdown/720.mp4",
          assetName: "10-sec-countdown",
        }
      );
    }
  };

  const _handleQuickstartActiveBrandAddAndActivateBackground = () => {
    const activeBrandId = activeBrandIdRef.current;

    if (activeBrandId) {
      appEventEmitter.emit(
        appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_BACKGROUND(activeBrandId),
        {
          remoteUrl:
            "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/backgrounds/Polygonal.jpg",
        }
      );
    }
  };

  const _handleQuickstartActiveBrandAddAndActivateBackgroundMusic = () => {
    const activeBrandId = activeBrandIdRef.current;

    if (activeBrandId) {
      appEventEmitter.emit(
        appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_BACKGROUND_MUSIC(
          activeBrandId
        ),
        {
          remoteUrl:
            "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/music/abstract-fashion-pop.mp3",
        }
      );
    }
  };

  const _handlePollCreated = async (poll: {
    id: string;
    options: { [t: string]: { option: string } };
  }) => {
    const { id, options } = poll;

    const optionId = Object.keys(options).find(
      (key) => options[key].option === "pineapple"
    );

    if (listenForNewPollCreatedRef.current) {
      newPollCreatedIdRef.current = id;
      newPollCreatedOptionIdRef.current = optionId;
      listenForNewPollCreatedRef.current = false;

      setSidePanelActivePollId(newPollCreatedIdRef.current);

      await setMSQuickstartPolls({
        broadcastId: broadcastIdRef.current,
        data: [newPollCreatedIdRef.current as string],
      });
    }
  };

  const _handleWordcloudCreated = async (wordcloud: { id: string }) => {
    const { id } = wordcloud;

    if (listenForNewWordcloudCreatedRef.current) {
      newWordcloudCreatedIdRef.current = id;

      listenForNewWordcloudCreatedRef.current = false;

      setSidePanelActiveWordcloudId(newWordcloudCreatedIdRef.current);

      await setMSQuickstartWordclouds({
        broadcastId: broadcastIdRef.current,
        data: [newWordcloudCreatedIdRef.current],
      });
    }
  };

  const _handleQuickstartAppOpenBannerSection = () => {
    setSidePanelAppMode(sidePanelAppModes.BANNERS);

    setSidePanelBannersMode(sidePanelBannersModes.BANNER_FOLDERS_LIST);
  };

  const _handleQuickstartAppOpenPollSection = () => {
    setSidePanelAppMode(sidePanelAppModes.POLLS);
  };

  const _handleQuickstartAppOpenWordcloudSection = () => {
    setSidePanelAppMode(sidePanelAppModes.WORDCLOUDS);
  };

  const _handleQuichstartAppCreatePoll = () => {
    listenForNewPollCreatedRef.current = true;

    createPoll({
      broadcastId: broadcastIdRef.current,
      options: ["apple", "pineapple", "mango"],
      question: "which is your favourite fruit?",
    });
  };

  const _handleQuichstartAppCreateWordcloud = () => {
    listenForNewWordcloudCreatedRef.current = true;

    createWordcloud({
      broadcastId: broadcastIdRef.current,
      question: "which is your favourite fruit?",
    });
  };

  const _handleSubmitPollAnswer = () => {
    _answerPoll();
  };

  const _handleSubmitWordcloudAnswer = () => {
    _answerWordcloud();
  };

  const _handleCreateBannerFolder = async () => {
    const id = await createBannerFolder({ name: "new folder" });

    if (id) {
      setSidePanelBannersMode(sidePanelBannersModes.BANNERS_LIST);

      setSidePanelActiveBanerId(id);

      newCreateBannerFolderIdRef.current = id;

      await setMSQuickstartBannerFolders({
        userId: studioUserIdRef.current,
        data: [newCreateBannerFolderIdRef.current as string],
      });
    }
  };

  const _handleQuickstartCreateBanners = async () => {
    newCreatedIdLowerThirdBannerRef.current = await createBanner({
      folderId: newCreateBannerFolderIdRef.current as string,
      position: "left",
      primaryText: "Hello World",
      secondaryText: "Secondary Text",
      type: bannerTypes.lowerThird,
    });

    newCreatedIdTickerBannerRef.current = await createBanner({
      folderId: newCreateBannerFolderIdRef.current as string,
      position: "left",
      primaryText: "Hello World",
      type: bannerTypes.ticker,
    });

    await sleep(1000);

    toggleActiveLowerThird({
      enable: true,
      bannerId: getAppBannerId({
        folderId: `${newCreateBannerFolderIdRef.current}`,
        id: `${newCreatedIdLowerThirdBannerRef.current}`,
        type: bannerTypes.lowerThird,
      }),
    });

    await sleep(1000);

    toggleActiveTicker({
      enable: true,
      bannerId: getAppBannerId({
        folderId: `${newCreateBannerFolderIdRef.current}`,
        id: `${newCreatedIdTickerBannerRef.current}`,
        type: bannerTypes.ticker,
      }),
    });
  };

  const _handleQuickStartEventEmitted = ({ type }: { type: string }) => {
    switch (type) {
      case appEventEmitterEvents.QUICKSTART_ADD_DUMMY_WEBCAM_STREAMS:
        _handleQuickstartAddDummyWebcamStreams();
        break;

      case appEventEmitterEvents.QUICKSTART_ADD_DUMMY_PRESENTATION:
        _handleQuickstartAddDummyPresentation();
        break;

      case appEventEmitterEvents.QUICKSTART_CREATE_NEW_BRAND:
        _handleQuickstartCreateNewBrand();
        break;

      case appEventEmitterEvents.QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_LOGO:
        _handleQuickstartActiveBrandAddAndActivateLogo();
        break;

      case appEventEmitterEvents.QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_VIDEO_CLIP:
        _handleQuickstartActiveBrandAddAndActivateVideoClip();
        break;

      case appEventEmitterEvents.QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_BACKGROUND:
        _handleQuickstartActiveBrandAddAndActivateBackground();
        break;

      case appEventEmitterEvents.QUICKSTART_ACTIVE_BRAND_ADD_AND_ACTIVATE_BACKGROUND_MUSIC:
        _handleQuickstartActiveBrandAddAndActivateBackgroundMusic();
        break;

      case appEventEmitterEvents.QUICKSTART_APP_OPEN_BANNER_SECTION:
        _handleQuickstartAppOpenBannerSection();
        break;

      case appEventEmitterEvents.QUICKSTART_APP_OPEN_POLL_SECTION:
        _handleQuickstartAppOpenPollSection();
        break;

      case appEventEmitterEvents.QUICKSTART_APP_OPEN_WORDCLOUD_SECTION:
        _handleQuickstartAppOpenWordcloudSection();
        break;

      case appEventEmitterEvents.QUICKSTART_APP_CREATE_POLL:
        _handleQuichstartAppCreatePoll();
        break;

      case appEventEmitterEvents.QUICKSTART_APP_CREATE_WORDCLOUD:
        _handleQuichstartAppCreateWordcloud();
        break;

      case appEventEmitterEvents.QUICKSTART_SUBMIT_POLL_ANSWER:
        _handleSubmitPollAnswer();
        break;

      case appEventEmitterEvents.QUICKSTART_SUBMIT_WORDCLOUD_ANSWER:
        _handleSubmitWordcloudAnswer();
        break;

      case appEventEmitterEvents.QUICHSTART_CREATE_BANNER_FOLDER:
        _handleCreateBannerFolder();
        break;

      case appEventEmitterEvents.QUICHSTART_CREATE_BANNERS:
        _handleQuickstartCreateBanners();
        break;

      default:
        break;
    }
  };

  const _handleSkipAll = async () => {
    await setQuickstartStepsCompletedAndSkipped({
      quickstartStepsCompleted: quickstartStepsCompletedRef.current,
      quickstartStepsSkipped: quickstartStepsRemainingRef.current,
    });
  };

  const _handleContinue = async () => {
    await _handleClearJoyrideProps();

    const quickstartStepsRemaining = quickstartStepsRemainingRef.current;

    if (quickstartStepsRemaining?.length) {
      const step = quickstartStepsRemaining[0];

      switch (step) {
        case quickStartSteps.strmV1:
          init_stream_section_and_layout_v1();

          break;
        case quickStartSteps.brndV1:
          init_brand_v1();

          break;
        case quickStartSteps.brdcstChtV1:
          init_go_live_private_chat_qna_participants_v1();

          break;
        case quickStartSteps.bnrV1:
          init_app_banner_v1();

          break;
        case quickStartSteps.polV1:
          init_app_poll_v1();

          break;
        case quickStartSteps.wrdcldV1:
          init_app_wordcloud_v1();

          break;
        default:
          break;
      }
    }
  };

  const _handleRestartFromBeginning = async () => {
    await _handleClearJoyrideProps();

    await setQuickstartStepsCompletedAndSkipped({
      quickstartStepsCompleted: [],
      quickstartStepsSkipped: [],
    });

    init_stream_section_and_layout_v1();
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.JOYRIDE_CALLBACK,
      _handleJoyrideCallback
    );
    appEventEmitter.on(
      appEventEmitterEvents.QUICKSTART_EVENT_EMITTED,
      _handleQuickStartEventEmitted
    );
    appEventEmitter.on(appEventEmitterEvents.POLL_CREATED, _handlePollCreated);
    appEventEmitter.on(
      appEventEmitterEvents.WORDCLOUD_CREATED,
      _handleWordcloudCreated
    );
    appEventEmitter.on(
      appEventEmitterEvents.QUICKSTART_SKIP_ALL,
      _handleSkipAll
    );
    appEventEmitter.on(
      appEventEmitterEvents.QUICKSTART_CONTINUE,
      _handleContinue
    );
    appEventEmitter.on(
      appEventEmitterEvents.QUICKSTART_RESTART_FROM_BEGINNING,
      _handleRestartFromBeginning
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.JOYRIDE_CALLBACK,
        _handleJoyrideCallback
      );
      appEventEmitter.off(
        appEventEmitterEvents.QUICKSTART_EVENT_EMITTED,
        _handleQuickStartEventEmitted
      );
      appEventEmitter.off(
        appEventEmitterEvents.POLL_CREATED,
        _handlePollCreated
      );
      appEventEmitter.off(
        appEventEmitterEvents.WORDCLOUD_CREATED,
        _handleWordcloudCreated
      );
      appEventEmitter.off(
        appEventEmitterEvents.QUICKSTART_SKIP_ALL,
        _handleSkipAll
      );
      appEventEmitter.off(
        appEventEmitterEvents.QUICKSTART_CONTINUE,
        _handleContinue
      );
      appEventEmitter.off(
        appEventEmitterEvents.QUICKSTART_RESTART_FROM_BEGINNING,
        _handleRestartFromBeginning
      );
    };
  }, []);

  return <React.Fragment />;
};

const QuickStartTourContainer = () => {
  const [
    quickstartStepsProgressModalVisible,
    setQuickstartStepsProgressModalVisible,
  ] = useState(false);

  const { quickstartStepsCompleted, quickstartStepsSkipped } =
    useAppConfigContext();

  const quickstartStepsRemaining = useMemo(() => {
    const quickstartStepsCompletedAndSkipped = [
      ...quickstartStepsCompleted,
      ...quickstartStepsSkipped,
    ];

    const quickstartStepsRemaining = Object.keys(quickStartSteps).filter(
      (key) => {
        return !quickstartStepsCompletedAndSkipped.find((step) => step === key);
      }
    );

    return quickstartStepsRemaining;
  }, [quickstartStepsCompleted, quickstartStepsSkipped]);

  const quickstartStepsProgress = useMemo(
    () => totalQuickStartSteps - quickstartStepsRemaining.length,
    [quickstartStepsRemaining]
  );

  const quickstartStepsProgressRef = useRef(quickstartStepsProgress);

  useEffect(() => {
    quickstartStepsProgressRef.current = quickstartStepsProgress;
  }, [quickstartStepsProgress]);

  const _handleOpen = () => {
    setQuickstartStepsProgressModalVisible(true);
  };

  const _handleClose = () => {
    setQuickstartStepsProgressModalVisible(false);
  };

  const _handleSkipAll = async () => {
    _handleClose();

    await sleep(200);

    appEventEmitter.emit(appEventEmitterEvents.QUICKSTART_SKIP_ALL);
  };

  const _handleContinue = async () => {
    _handleClose();

    await sleep(200);

    appEventEmitter.emit(appEventEmitterEvents.QUICKSTART_CONTINUE);
  };

  const _handleRestartFromBeginning = async () => {
    _handleClose();

    await sleep(200);

    appEventEmitter.emit(
      appEventEmitterEvents.QUICKSTART_RESTART_FROM_BEGINNING
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const autostarted = localStorage.getItem(
        quickstartAutoOpenPopupFirstTimeId
      );

      if (!autostarted && !quickstartStepsProgressRef.current) {
        setQuickstartStepsProgressModalVisible(true);
        localStorage.setItem(
          quickstartAutoOpenPopupFirstTimeId,
          `${new Date().getTime()}`
        );
      }
    }, 5000);
  }, []);

  return quickstartStepsRemaining.length ? (
    <React.Fragment>
      <button
        onClick={_handleOpen}
        className="btn btn-primary bg-custom-blue-50 btn-outline normal-case relative"
      >
        <div className="flex flex-col gap-1">
          <p className="text-md text-start font-bold">Quickstart </p>
          <p className="text-xs font-normal">{`${quickstartStepsRemaining.length} steps remaining`}</p>
        </div>

        <div className="top-1 right-1 absolute rounded-full animate-bounce">
          <MdQuestionMark fill={"#FF2445"} size={12} />
        </div>
      </button>

      <QuickStartTourListner />

      {/* <Modal
        open={quickstartStepsProgressModalVisible}
        disableAutoFocus
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
         */}
      <Modal
        isOpen={quickstartStepsProgressModalVisible}
        shouldFocusAfterRender={false}
        style={{
          overlay: { backgroundColor: "#00000066" },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <div className="bg-white rounded-lg p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              {quickstartStepsProgress ? (
                <div
                  className="radial-progress bg-primary text-primary-content border-4 border-primary"
                  style={
                    {
                      "--value": `${
                        (quickstartStepsProgress / totalQuickStartSteps) * 100
                      }`,
                      "--size": "4rem",
                    } as React.CSSProperties
                  }
                  role="progressbar"
                >
                  {quickstartStepsProgress}
                </div>
              ) : (
                <React.Fragment />
              )}

              <div className="flex flex-col gap-1">
                <p className="text-lg text-start font-bold">Quickstart </p>
                <p className="text-sm font-normal">{`${quickstartStepsRemaining.length} steps remaining`}</p>
              </div>
            </div>

            <div className="flex gap-3">
              <Tooltip title={"Restart from beginning"}>
                <button
                  onClick={_handleRestartFromBeginning}
                  className="btn btn-primary btn-outline rounded-full"
                >
                  <MdRefresh size={16} />
                </button>
              </Tooltip>

              <button
                onClick={_handleClose}
                className="btn btn-ghost rounded-full"
              >
                <MdClose size={16} />
              </button>
            </div>
          </div>

          <div className="mt-3">
            {Object.keys(quickStartSteps).map((key) => {
              const title =
                quickStartStepsTitle[key as quickStartStepsKeysType];

              const isCompleted = !!quickstartStepsCompleted.find(
                (step) => step === key
              );
              const isSkipped = !!quickstartStepsSkipped.find(
                (step) => step === key
              );

              return (
                <div className="p-1 flex items-center gap-3">
                  <div>
                    {isCompleted || isSkipped ? (
                      <React.Fragment>
                        <MdTaskAlt className="fill-green-600" size={20} />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <MdOutlineRadioButtonUnchecked
                          className="fill-green-600"
                          size={20}
                        />
                      </React.Fragment>
                    )}
                  </div>

                  <p className="font-medium text-md">{title}</p>
                </div>
              );
            })}
          </div>

          <div className="mt-6 flex gap-3">
            <button
              type="button"
              className="btn btn-primary text-white normal-case btn-outline btn-sm"
              onClick={_handleContinue}
            >
              {`Continue from step ${quickstartStepsProgress + 1}`}
            </button>

            <button
              type="button"
              className="btn btn-primary text-white normal-case btn-outline btn-error btn-sm"
              onClick={_handleSkipAll}
            >
              Skip all steps
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

export default QuickStartTourContainer;
