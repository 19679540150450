import { customBlueColor } from "../../utils/colors";

const PictureInPictureIconActive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101.223 69.818"
    enableBackground="new 0 0 101.223 69.818"
    xmlSpace="preserve"
    {...props}
  >
    <g id="Capa_8">
      <path
        style={{ fill: customBlueColor[900] }}
        d="M8.945 13.21h83.334v43.398H8.945z"
      />
      <path
        style={{ fill: customBlueColor[100] }}
        d="M23.424 32.653a4.994 4.994 0 1 1 0 9.988 4.994 4.994 0 0 1 0-9.988zM15.515 50.663c0-3.283 3.541-5.945 7.91-5.945s7.91 2.661 7.91 5.945h-15.82z"
      />
    </g>
  </svg>
);

export default PictureInPictureIconActive;
