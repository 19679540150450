import React, { useEffect, useMemo, useRef, useState } from "react";
import { Menu, MenuItem, MenuDivider } from "@szhsin/react-menu";
import {
  MdAddToQueue,
  MdOutlineDesktopWindows,
  MdArrowBackIos,
  MdUpload,
  MdArrowForwardIos,
  MdDelete,
  MdSlideshow,
  MdMovieCreation,
  MdVideoCall,
  MdMovie,
} from "react-icons/md";
import { IconType } from "react-icons";
import IconButton, { iconButtonVariants } from "./IconButton";
import { useAppContext } from "../../contexts/appContextDef";
import deleteBroadcastAsset from "../../apis/broadcasts/delete-broadcast-asset";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import useBroadcastAssets from "../../hooks/appState/useBroadcastAssets";
import Tooltip from "../../components/Tooltip";
import Modal from "../../components/Modal";
import { appModes, appRtcClient, appRtcClients } from "../../utils/constants";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import {
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import sleep from "../../utils/sleep";
// import { getToggleParticipantMediaTopicById } from "../../utils/pubSubTopics";
// import { toggleParticipantMediaActions } from "../../hooks/appState/useToggleParticipantMedia";
import useFileShareStreams from "../../hooks/streams/useFileShareStreams";
import Spinner from "../../components/Spinner";
import useIsDesktopOrLaptop from "../../hooks/responsive/useIsDesktopOrLaptop";

export const ShareFileFromComputerContainer = (t?: { title?: string }) => {
  const fileShareInputRef = useRef<HTMLInputElement>();

  const _handleInitShareFile = () => {
    if (fileShareInputRef.current) {
      fileShareInputRef.current.click();
    }
  };

  return (
    <React.Fragment>
      {/* file input */}

      <input
        name="file-share"
        accept="application/pdf,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation"
        onChange={(e) => {
          const singleFile = e?.target?.files ? e.target.files[0] : null;
          const fileName = singleFile?.name;

          appEventEmitter.emit(
            appEventEmitterEvents.ADD_PDF_AND_PPT_FILE_EVENT,
            { fileName: fileName as string, singleFile }
          );

          if (fileShareInputRef.current) {
            fileShareInputRef.current.value = "";
          }
        }}
        type={"file"}
        ref={fileShareInputRef as React.LegacyRef<HTMLInputElement>}
        className="hidden"
      />
      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={_handleInitShareFile}
      >
        <div className="flex items-center justify-center gap-1">
          <MdUpload />
          {t?.title || "Your computer"}
        </div>
      </MenuItem>
    </React.Fragment>
  );
};

const ShareFileFromUploadedItem = ({
  remoteUrl,
  pdfInfo,
  name,
  id,
}: {
  remoteUrl: string;
  pdfInfo: pdfInfoType;
  name: string;
  id: string;
}) => {
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);

  const { broadcastId } = useAppConfigContext();
  const { removeAsset } = useBroadcastAssets();

  const { activeFileShareStreamAllParticipants, broadcastAssets } =
    useAppContext();
  const { disableFileShareStream } = useFileShareStreams();

  const broadcastAsset = useMemo(
    () => broadcastAssets.find(({ id: _id }) => `${id}` === `${_id}`),
    [broadcastAssets, id]
  );

  const broadcastAssetRef = useRef(broadcastAsset);

  useEffect(() => {
    broadcastAssetRef.current = broadcastAsset;
  }, [broadcastAsset]);

  const {
    isActive,
    // activeFileShareStreamParticipantId,
    activeFileShareStreamFileId,
  } = useMemo(() => {
    const activeFileShareStream = activeFileShareStreamAllParticipants?.find(
      ({ resourceId }) => `${resourceId}` === `${broadcastAsset?.id}`
    );

    return {
      isActive: !!activeFileShareStream,
      // activeFileShareStreamParticipantId:
      //   activeFileShareStream?.participantId as string,
      activeFileShareStreamFileId: activeFileShareStream?.id as string,
    };
  }, [broadcastAsset, activeFileShareStreamAllParticipants]);

  // const activeFileShareStreamParticipantIdRef = useRef(
  //   activeFileShareStreamParticipantId
  // );

  const activeFileShareStreamFileIdRef = useRef(activeFileShareStreamFileId);

  // useEffect(() => {
  //   activeFileShareStreamParticipantIdRef.current =
  //     activeFileShareStreamParticipantId;
  // }, [activeFileShareStreamParticipantId]);
  useEffect(() => {
    activeFileShareStreamFileIdRef.current = activeFileShareStreamFileId;
  }, [activeFileShareStreamFileId]);

  const { removeFromMainViewStreams } = useMainViewParticipants();

  const _handleRemoveFromStudio = async () => {
    removeFromMainViewStreams({
      mode: streamModes.PDF,
      participantId: `${broadcastAssetRef.current?.id}`,
      type: streamTypes.SHARE,
      fileId: activeFileShareStreamFileIdRef.current,
    });

    await sleep(300);

    disableFileShareStream({ id: activeFileShareStreamFileIdRef.current });

    // appEventEmitter.emit(appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH, {
    //   topic: getToggleParticipantMediaTopicById(
    //     activeFileShareStreamParticipantIdRef.current
    //   ),
    //   message: JSON.stringify({
    //     action: toggleParticipantMediaActions.TURN_OFF_FILE_SHARE_STREAM,
    //     sentBySystem: false,
    //     fileId: activeFileShareStreamFileIdRef.current,
    //   }),
    // });
  };

  const _handleDelete = async () => {
    const { success } = await deleteBroadcastAsset({
      broadcastId,
      fileId: id,
    });

    if (success) {
      await _handleRemoveFromStudio();

      removeAsset({ fileId: id });
    }
  };

  return (
    <React.Fragment>
      <MenuItem
        checked //</React.Fragment>={isActive}
        className={`px-3 py-2 ${
          isActive ? "bg-custom-blue-50" : ""
        } hover:bg-custom-blue-50 text-primary`}
        onClick={() => {
          if (!isActive) {
            appEventEmitter.emit(appEventEmitterEvents.ADD_PDF_FROM_UPLOADED, {
              remoteUrl,
              pdfInfo,
              resourceId: id,
            });
          }
        }}
      >
        <div className="flex flex-1">
          <p
            style={{ flex: 1, maxWidth: 280 }}
            className={`overflow-hidden whitespace-nowrap overflow-ellipsis ${
              isActive ? "font-bold" : ""
            }`}
          >
            {name}
          </p>

          <Tooltip title={"Delete"}>
            <div className="ml-3">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setConfirmDeleteModalVisible(true);
                }}
              >
                <MdDelete />
              </button>
            </div>
          </Tooltip>
        </div>
      </MenuItem>

      <Modal
        {...{
          isOpen: confirmDeleteModalVisible,

          onSuccess: () => {
            _handleDelete();
            setConfirmDeleteModalVisible(false);
          },
          onClose: () => {
            setConfirmDeleteModalVisible(false);
          },
          cancelText: "Cancle",
          successText: "Delete",
          title: "Are you sure you want to delete this slide?",
        }}
      />
    </React.Fragment>
  );
};

const ShareFileFromUploaded = ({
  pdfs,
}: {
  pdfs: { id: string; name: string; remoteUrl: string; pdfInfo: pdfInfoType }[];
}) => {
  return pdfs.map(({ id, name, remoteUrl, pdfInfo }) => {
    return (
      <ShareFileFromUploadedItem
        {...{
          name,
          pdfInfo,
          remoteUrl,
          key: `bottom-share-slides-option${id}`,
          id,
        }}
      />
    );
  });
};

const ShareVideoFromUploadedItem = ({
  _handleVideoSelect,
  remoteUrl,
  name,
  id,
}: {
  remoteUrl: string;
  name: string;
  id: string;
  _handleVideoSelect: (t: { remoteUrl: string; id: string }) => void;
}) => {
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);

  const { broadcastId } = useAppConfigContext();
  const { removeAsset } = useBroadcastAssets();

  const _handleDelete = async () => {
    const { success } = await deleteBroadcastAsset({
      broadcastId,
      fileId: id,
    });

    if (success) {
      removeAsset({ fileId: id });
    }
  };

  return (
    <React.Fragment>
      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={() => {
          _handleVideoSelect({ remoteUrl, id });
        }}
      >
        <div className="flex flex-1">
          <p
            style={{ flex: 1, maxWidth: 280 }}
            className="overflow-hidden whitespace-nowrap overflow-ellipsis"
          >
            {name}
          </p>

          <Tooltip title={"Delete"}>
            <div className="ml-3">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setConfirmDeleteModalVisible(true);
                }}
              >
                <MdDelete />
              </button>
            </div>
          </Tooltip>
        </div>
      </MenuItem>

      <Modal
        {...{
          isOpen: confirmDeleteModalVisible,

          onSuccess: () => {
            _handleDelete();

            setConfirmDeleteModalVisible(false);
          },
          onClose: () => {
            setConfirmDeleteModalVisible(false);
          },
          cancelText: "Cancle",
          successText: "Delete",
          title: "Are you sure you want to delete this vidoe?",
        }}
      />
    </React.Fragment>
  );
};

const ShareVideoFromUploaded = ({
  _handleVideoSelect,
  videos,
}: {
  _handleVideoSelect: (t: { remoteUrl: string; id: string }) => void;
  videos: { id: string; remoteUrl: string; name: string }[];
}) => {
  return videos.map(({ id, remoteUrl, name }) => (
    <ShareVideoFromUploadedItem
      {...{
        _handleVideoSelect,
        remoteUrl,
        name,
        key: `bottom-share-video-option${id}`,
        id,
      }}
    />
  ));
};

const ShareVideoFromComputerContainer = ({
  _handleVideoFileEvent,
}: {
  _handleVideoFileEvent: (t: {
    singleFile: File | null;
    fileName: string;
  }) => void;
}) => {
  const videoShareInputRef = useRef<HTMLInputElement>();

  const _handleInitShareVideo = () => {
    if (videoShareInputRef.current) {
      videoShareInputRef.current.click();
    }
  };

  return (
    <React.Fragment>
      {/* file input */}

      <input
        name="video-share"
        // accept="video/mp4,video/x-m4v,video/*"
        accept=".mp4,.m4v,.webm,.flv"
        onChange={(e) => {
          const singleFile = e?.target?.files ? e.target.files[0] : null;
          const fileName = singleFile?.name;

          _handleVideoFileEvent({ singleFile, fileName: fileName as string });

          if (videoShareInputRef.current) {
            videoShareInputRef.current.value = "";
          }
        }}
        type={"file"}
        ref={videoShareInputRef as React.LegacyRef<HTMLInputElement>}
        className="hidden"
      />
      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={_handleInitShareVideo}
      >
        <div className="flex items-center justify-center gap-1">
          <MdUpload />
          Your computer
        </div>
      </MenuItem>
    </React.Fragment>
  );
};

const AddExtraCameraContainer = () => {
  const { turnOnExtraCameraInProgress } = useAppContext();

  return (
    <MenuItem
      disabled={turnOnExtraCameraInProgress}
      className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
      onClick={() => {
        appEventEmitter.emit(appEventEmitterEvents.RTC_TURN_ON_EXTRA_WEBCAM);
      }}
    >
      <div className="flex items-center justify-center gap-1">
        <MdVideoCall />
        Extra camera
        {turnOnExtraCameraInProgress ? (
          <Spinner h={"h-4"} w={"w-4"} />
        ) : (
          <React.Fragment />
        )}
      </div>
    </MenuItem>
  );
};

const ShareInputFileVideoFromComputerContainer = ({
  _handleInputFileVideoFileEvent,
}: {
  _handleInputFileVideoFileEvent: (t: { fileObjectUrl: string }) => void;
}) => {
  const videoShareInputRef = useRef<HTMLInputElement>();

  const _handleInitShareVideo = () => {
    if (videoShareInputRef.current) {
      videoShareInputRef.current.click();
    }
  };

  return (
    <React.Fragment>
      {/* file input */}

      <input
        name="video-share"
        // accept="video/mp4,video/x-m4v,video/*"
        accept=".mp4,.m4v,.webm,.flv"
        onChange={(e) => {
          const singleFile = e?.target?.files ? e.target.files[0] : null;

          if (singleFile) {
            const objectURL = URL.createObjectURL(singleFile);

            _handleInputFileVideoFileEvent({ fileObjectUrl: objectURL });
          }

          if (videoShareInputRef.current) {
            videoShareInputRef.current.value = "";
          }
        }}
        type={"file"}
        ref={videoShareInputRef as React.LegacyRef<HTMLInputElement>}
        className="hidden"
      />
      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={_handleInitShareVideo}
      >
        <div className="flex items-center justify-center gap-1">
          <MdMovieCreation />
          Video File
        </div>
      </MenuItem>
    </React.Fragment>
  );
};

const MenuButton = () => {
  const { turnOnScreenShareInProgress, turnOnInputFileVideoShareInProgress } =
    useAppContext();

  return (
    <div>
      <IconButton
        {...{
          Icon: MdAddToQueue,
          title: "Share",
          popperTitle: "",
          variant: iconButtonVariants.info,
          maxTitle: "Share",
          isMenuButton: true,
          isProcessing:
            turnOnScreenShareInProgress || turnOnInputFileVideoShareInProgress,
          disabled:
            turnOnScreenShareInProgress || turnOnInputFileVideoShareInProgress,
        }}
      />
    </div>
  );
};

const SubMenuTitleMenu = ({
  onClick,
  title,
}: {
  onClick: () => void;
  title: string;
}) => {
  return (
    <React.Fragment>
      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={(e) => {
          e.stopPropagation = true;
          e.keepOpen = true;

          setTimeout(() => {
            onClick();
          }, 150);
        }}
      >
        <MdArrowBackIos />
        {title}
      </MenuItem>
      <MenuDivider />
    </React.Fragment>
  );
};

const MainMenuSubItems = ({
  onClick,
  title,
  Icon,
}: {
  onClick: () => void;
  title: string;
  Icon: IconType;
}) => {
  return (
    <MenuItem
      onClick={(e) => {
        e.stopPropagation = true;
        e.keepOpen = true;

        setTimeout(() => {
          onClick();
        }, 150);
      }}
      className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
    >
      <div className="flex flex-1 items-center justify-between">
        <div className="flex items-center justify-center gap-1">
          <Icon />
          {title}
        </div>
        <MdArrowForwardIos />
      </div>
    </MenuItem>
  );
};

const ShareContainer = ({
  screenShareOn,
  _handleToggleScreenshare,
  _handleVideoFileEvent,
  _handleVideoSelect,
  _handleInputFileVideoFileEvent,
}: {
  screenShareOn: boolean;
  _handleToggleScreenshare: () => void;
  _handleVideoFileEvent: (t: {
    singleFile: File | null;
    fileName: string;
  }) => void;
  _handleVideoSelect: (t: { remoteUrl: string; id: string }) => void;
  _handleInputFileVideoFileEvent: (t: { fileObjectUrl: string }) => void;
}) => {
  const [slidesMenuOpen, setSlidesMenuOpen] = useState(false);
  const [videosMenuOpen, setVideosMenuOpen] = useState(false);

  const {
    allPdfs,
    selfPdfs,
    allVideos,
    selfVideos,
    appMode,
    setRtmpSourceModalOpen,
  } = useAppContext();

  const pdfs = useMemo(
    () => (appMode === appModes.HOST ? allPdfs : selfPdfs),
    [appMode, allPdfs, selfPdfs]
  );

  const videos = useMemo(
    () => (appMode === appModes.HOST ? allVideos : selfVideos),
    [allVideos, selfVideos, appMode]
  );

  const { isDesktopOrLaptop } = useIsDesktopOrLaptop();

  const { thirdPartyRtmpData } = useAppConfigContext();

  return (
    <div>
      {slidesMenuOpen ? (
        <Menu menuButton={MenuButton} defaultChecked transition>
          <SubMenuTitleMenu
            onClick={() => setSlidesMenuOpen(false)}
            title="Slides"
          />
          {pdfs.length ? (
            <React.Fragment>
              <ShareFileFromUploaded {...{ pdfs }} />
              <MenuDivider />
            </React.Fragment>
          ) : null}
          <p className="px-3 py-1 text-xs">add file from...</p>
          <ShareFileFromComputerContainer
          // _handlePdfAndPptFileEvent={_handlePdfAndPptFileEvent}
          />
        </Menu>
      ) : videosMenuOpen ? (
        <Menu menuButton={MenuButton} defaultChecked transition>
          <SubMenuTitleMenu
            onClick={() => setVideosMenuOpen(false)}
            title="Video Play"
          />
          {videos.length ? (
            <React.Fragment>
              <ShareVideoFromUploaded {...{ videos, _handleVideoSelect }} />
              <MenuDivider />
            </React.Fragment>
          ) : null}
          <p className="px-3 py-1 text-xs">add file from...</p>
          <ShareVideoFromComputerContainer
            _handleVideoFileEvent={_handleVideoFileEvent}
          />
        </Menu>
      ) : (
        <Menu menuButton={MenuButton} transition>
          <MainMenuSubItems
            onClick={() => setSlidesMenuOpen(true)}
            title={"Slides"}
            Icon={MdSlideshow}
          />

          {isDesktopOrLaptop ? <AddExtraCameraContainer /> : <React.Fragment />}

          {appRtcClient === appRtcClients.livekit ? (
            <ShareInputFileVideoFromComputerContainer
              _handleInputFileVideoFileEvent={_handleInputFileVideoFileEvent}
            />
          ) : (
            <MainMenuSubItems
              onClick={() => setVideosMenuOpen(true)}
              title={"Video Play"}
              Icon={MdMovieCreation}
            />
          )}

          <MenuDivider />
          <MenuItem
            className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
            onClick={_handleToggleScreenshare}
          >
            <div className="flex items-center justify-center gap-1">
              <MdOutlineDesktopWindows />
              {screenShareOn ? (
                <React.Fragment>Stop Screen Share</React.Fragment>
              ) : (
                <React.Fragment>Share Screen</React.Fragment>
              )}
            </div>
          </MenuItem>

          {appMode === appModes.HOST && thirdPartyRtmpData ? (
            <React.Fragment>
              <MenuDivider />
              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={() => {
                  setRtmpSourceModalOpen(true);
                }}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdMovie />
                  RTMP Source
                </div>
              </MenuItem>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </Menu>
      )}
    </div>
  );
};

export default ShareContainer;
