import { momentostreamApiServerBaseUrl } from "../../../utils/constants";

const createPoll = async ({
  broadcastId,
  question,
  options,
}: {
  broadcastId: string;
  question: string;
  options: string[];
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/create-poll`;

    const body = JSON.stringify({ broadcastId, question, options });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default createPoll;
