import { egressManagerApiServerBaseUrl } from "../../utils/constants";

const getFailedRtmpDestinationUrls = async ({
  userId,
}: {
  userId: string;
}): Promise<{
  success: boolean;
  failedRtmpDestinationUrls?: failedRtmpDestinationUrlsType;
}> => {
  try {
    const url = `${egressManagerApiServerBaseUrl}/failed-rtmp-destination-urls/${userId}`;

    const res = await fetch(url);

    const json = await res.json();

    if (json.success) {
      return {
        success: true,
        failedRtmpDestinationUrls: json.failedRtmpDestinationUrls,
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default getFailedRtmpDestinationUrls;
