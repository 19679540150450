import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";

const useBrandMuteGuestsWhenAudioPlays = () => {
  const { publish } = useAppSingalingPublish(
    appPubSubTopics.MUTE_GUESTS_WHEN_AUDIO_PLAYS
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const setMuteGuestsWhenAudioPlays = async (
    {
      muteGuestsWhenAudioPlays,
    }: {
      muteGuestsWhenAudioPlays: boolean;
    },
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(JSON.stringify({ muteGuestsWhenAudioPlays }));
  };

  return { setMuteGuestsWhenAudioPlays };
};

export default useBrandMuteGuestsWhenAudioPlays;
