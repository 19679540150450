import React from "react";
import ConferenceAutoLayoutListner from "./ConferenceAutoLayoutListner";
import ConferenceModeMaxParticipantCount from "./ConferenceModeMaxParticipantCount";

const ConferenceListner = () => {
  return (
    <React.Fragment>
      <ConferenceModeMaxParticipantCount />
      <ConferenceAutoLayoutListner />
    </React.Fragment>
  );
};

export default ConferenceListner;
