// import { usePubSub } from "@videosdk.live/react-sdk";
import { useRef } from "react";
import { createUID } from "../../utils/createUID";
import sleep from "../../utils/sleep";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import useValidateSpeakerCanTakeAction from "../appState/useValidateSpeakerCanTakeAction";
import { inQueueStreamTypes } from "../../utils/constants";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

export const inputFileVideoShareStreamActions = {
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
};

const useInputFileVideoShareStreams = () => {
  const { allParticipantsInQueueStreams } = useAppContext();

  const allParticipantsInQueueStreamsRef = useRef(
    allParticipantsInQueueStreams
  );

  const { publish: inputFileVideoShareStreamPublish } = useAppSingalingPublish(
    appPubSubTopics.INPUT_FILE_VIDEO_SHARE_STREAM_ENABLED
  );

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  const enableInputFileVideoShareStream = async ({
    fileObjectUrl,
    durationInSec,
  }: {
    fileObjectUrl: string;
    durationInSec: number;
  }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    const id = createUID();

    const {
      success,
      data,
    }: {
      success: boolean;
      data?: {
        id: string;
        fileObjectUrl: string;
        inputFileVideoSourceElementId: string;
        videoPublicationTrackSid: string;
        audioPublicationTrackSid?: string;
      };
    } = await new Promise((resolve) => {
      appEventEmitter.emit(
        appEventEmitterEvents.RTC_ENABLE_INPUT_FILE_VIDEO_SHARE_STREAM,
        { id, fileObjectUrl }
      );

      appEventEmitter.once(
        appEventEmitterEvents.RTC_INPUT_FILE_VIDEO_SHARE_STREAM_ENABLED(id),
        ({ success, data }) => {
          resolve({ success, data });
        }
      );
    });

    if (success && data) {
      inputFileVideoShareStreamPublish(
        JSON.stringify({
          id,
          rtcClientMetadata: JSON.stringify(data),
          durationInSec,
          action: inputFileVideoShareStreamActions.ENABLE,
        })
      );
    }
  };

  const disableInputFileVideoShareStream = async ({ id }: { id: string }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    inputFileVideoShareStreamPublish(
      JSON.stringify({ id, action: inputFileVideoShareStreamActions.DISABLE })
    );
  };

  const disableInputFileVideoShareStreamsOfParticipant = async ({
    participantId,
  }: {
    participantId: string;
  }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    const participantInQueueInputFileVideoStreams =
      allParticipantsInQueueStreamsRef.current.filter(
        ({ participantId: _participantId, type }) =>
          _participantId === participantId &&
          type === inQueueStreamTypes.SHARE_INPUT_FILE_VIDEO
      );

    for (
      let index = 0;
      index < participantInQueueInputFileVideoStreams.length;
      index++
    ) {
      const participantInQueueInputFileVideoStream =
        participantInQueueInputFileVideoStreams[index];

      disableInputFileVideoShareStream({
        id: `${participantInQueueInputFileVideoStream.id}`,
      });

      await sleep();
    }
  };

  return {
    enableInputFileVideoShareStream,
    disableInputFileVideoShareStream,
    disableInputFileVideoShareStreamsOfParticipant,
  };
};

export default useInputFileVideoShareStreams;
