import React, { useEffect, useRef } from "react";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useJoinedParticipantsCountAndIsMaxedOutjoinedParticipants } from "../../listners/appState/WaitingRoomParticipantsListner";
import { appModes } from "../../utils/constants";
import { useAppContext } from "../../contexts/appContextDef";
import useAllowParticipantEntryRequest from "../../hooks/appState/useAllowParticipantEntryRequest";
import { MOMENTO_LOGO_FULL } from "../../static/images";
import { useAppConfigContext } from "../../contexts/appConfigDef";

const WaitingToJoinContainer = ({
  participantId,
  name,
  sid,
  appMode,
  onEntryAllowed,
}: {
  participantId: string;
  name: string;
  sid: string;
  appMode: appModeType;
  onEntryAllowed: () => void;
}) => {
  const { allowedEntryRequestParticipantIds, waitingRoomEnabled } =
    useAppContext();

  const { joinedParticipantsCount, isMaxedOutjoinedParticipants } =
    useJoinedParticipantsCountAndIsMaxedOutjoinedParticipants();

  const { publish: participantJoinRequestEntryPublish } =
    useAppSingalingPublish(appPubSubTopics.PARTICIPANT_JOIN_REQUEST_ENTRY);

  const { allowParticipantEntryRequest } = useAllowParticipantEntryRequest();

  const participantIdRef = useRef(participantId);
  const nameRef = useRef(name);
  const sidRef = useRef(sid);
  const appModeRef = useRef(appMode);
  const joinedParticipantsCountRef = useRef(joinedParticipantsCount);
  const isMaxedOutjoinedParticipantsRef = useRef(isMaxedOutjoinedParticipants);
  const allowedEntryRequestParticipantIdsRef = useRef(
    allowedEntryRequestParticipantIds
  );
  const participantJoinRequestEntryPublishRef = useRef(
    participantJoinRequestEntryPublish
  );
  const allowParticipantEntryRequestRef = useRef(allowParticipantEntryRequest);
  const waitingRoomEnabledRef = useRef(waitingRoomEnabled);

  useEffect(() => {
    participantIdRef.current = participantId;
  }, [participantId]);
  useEffect(() => {
    nameRef.current = name;
  }, [name]);
  useEffect(() => {
    sidRef.current = sid;
  }, [sid]);
  useEffect(() => {
    appModeRef.current = appMode;
  }, [appMode]);
  useEffect(() => {
    joinedParticipantsCountRef.current = joinedParticipantsCount;
  }, [joinedParticipantsCount]);
  useEffect(() => {
    isMaxedOutjoinedParticipantsRef.current = isMaxedOutjoinedParticipants;
  }, [isMaxedOutjoinedParticipants]);
  useEffect(() => {
    allowedEntryRequestParticipantIdsRef.current =
      allowedEntryRequestParticipantIds;
  }, [allowedEntryRequestParticipantIds]);
  useEffect(() => {
    participantJoinRequestEntryPublishRef.current =
      participantJoinRequestEntryPublish;
  }, [participantJoinRequestEntryPublish]);
  useEffect(() => {
    allowParticipantEntryRequestRef.current = allowParticipantEntryRequest;
  }, [allowParticipantEntryRequest]);
  useEffect(() => {
    waitingRoomEnabledRef.current = waitingRoomEnabled;
  }, [waitingRoomEnabled]);

  const _handleAllowSelf = () => {
    const participantId = participantIdRef.current;
    const sid = sidRef.current;

    const isNotAllowedAllowed =
      !allowedEntryRequestParticipantIdsRef.current.find(
        ({ participantId: _participantId, sid: _sid }) =>
          participantId === _participantId && sid === _sid
      );

    if (isNotAllowedAllowed) {
      allowParticipantEntryRequestRef.current(participantId);
    }
  };

  const requestEntryOrAllowSelf = () => {
    const participantId = participantIdRef.current;
    const name = nameRef.current;
    const sid = sidRef.current;
    const appMode = appModeRef.current;
    const waitingRoomEnabled = waitingRoomEnabledRef.current;

    if (
      appMode === appModes.HOST ||
      appMode === appModes.VIEWER ||
      (appMode === appModes.SPEAKER && !waitingRoomEnabled)
    ) {
      _handleAllowSelf();

      return;
    }

    if (appMode === appModes.SPEAKER) {
      participantJoinRequestEntryPublishRef.current(
        JSON.stringify({ participantId, name, sid })
      );
    }
  };

  useEffect(() => {
    requestEntryOrAllowSelf();

    const interval = setInterval(() => {
      requestEntryOrAllowSelf();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const onEntryAllowedRef = useRef(onEntryAllowed);
  const onEntryAllowedInvoked = useRef(false);

  useEffect(() => {
    onEntryAllowedRef.current = onEntryAllowed;
  }, [onEntryAllowed]);

  useEffect(() => {
    const isAllowed = allowedEntryRequestParticipantIds.find(
      ({ participantId: _participantId, sid: _sid }) =>
        participantId === _participantId && sid === _sid
    );

    if (isAllowed && !onEntryAllowedInvoked.current) {
      onEntryAllowedInvoked.current = true;

      onEntryAllowedRef.current();
    }
  }, [allowedEntryRequestParticipantIds, participantId, sid]);

  const { meetingName } = useAppConfigContext();

  return waitingRoomEnabled && appMode === appModes.SPEAKER ? (
    <div style={{ height: "100vh", width: "100vw" }} className="flex flex-col">
      <div className="p-5">
        <a target="_blank" href="http://momentostream.com/" rel="noreferrer">
          <img
            className="h-8"
            src={MOMENTO_LOGO_FULL}
            alt="momento logo full"
          />
        </a>
      </div>

      <div className="flex flex-col flex-1 items-center justify-center text-xl">
        <div>
          <p className="font-medium">
            Please wait, the studio host will let you in soon.
          </p>
        </div>
        <div className="mt-2">
          <p className="font-bold">{meetingName}</p>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment>sadfasdfasd</React.Fragment>
  );
};

export default WaitingToJoinContainer;
