import { useEffect, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { MdOutlineSlideshow, MdMovie } from "react-icons/md";
import Spinner from "../../components/Spinner";
import useParticipantName from "../../hooks/appState/useParticipantName";
import { streamModes } from "../../listners/appState/MainViewParticipantsListner";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";

const InQueueUploadingContainer = ({
  name: fileName,
  isProcessing,
  isUploading,
  mode,
  id,
}: {
  name: string;
  isProcessing: boolean;
  isUploading: boolean;
  mode: string;
  id: string;
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const { localParticipantId } = useLocalParticipantId();

  const { inQueueItemContainerWidth } = useAppContext();
  const { name } = useParticipantName(localParticipantId);

  const _handleAssetUploadingAndProcessingProgress = ({
    progress,
    upload,
  }: {
    progress: number;
    upload?: boolean;
  }) => {
    if (upload) {
      setUploadProgress(progress);
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.ASSET_UPLOADING_AND_PROCESSING_PROGRESS(id),
      _handleAssetUploadingAndProcessingProgress
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.ASSET_UPLOADING_AND_PROCESSING_PROGRESS(id),
        _handleAssetUploadingAndProcessingProgress
      );
    };
  }, [id]);

  return (
    <div className="rounded" style={{ width: inQueueItemContainerWidth }}>
      <div
        style={{
          height: (inQueueItemContainerWidth * 9) / 16,
          width: inQueueItemContainerWidth,
        }}
        className="flex items-center flex-col justify-center bg-custom-blue-50"
      >
        <div>
          {isUploading ? (
            <div
              className="radial-progress text-primary"
              style={
                {
                  "--value": parseInt(`${uploadProgress * 100}`),
                  "--size": "32px",
                  "--thickness": "3px",
                } as React.CSSProperties
              }
            ></div>
          ) : (
            <Spinner h={"h-8"} w={"w-8"} />
          )}
        </div>
        <div className="text-primary text-center mt-2">
          <p>
            {`${
              fileName
                ? `${
                    fileName?.length > 10
                      ? `${fileName.substring(0, 10)}... `
                      : fileName
                  } is `
                : ""
            }${isUploading ? "uploading" : isProcessing ? "processing" : ""}`}
          </p>
        </div>
      </div>

      <div
        className={`flex items-center justify-between p-0.5  rounded-b-md overflow-hidden bg-white`}
      >
        <div className="flex items-center justify-center">
          {mode === streamModes.PDF ? (
            <MdOutlineSlideshow size={16} />
          ) : mode === streamModes.VIDEO ? (
            <MdMovie size={16} />
          ) : null}
          <div className="ml-1">
            <p>{name}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InQueueUploadingContainer;
