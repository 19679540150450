import React, { useEffect } from "react";
import CachedUserData from "../../utils/CachedUserData";

const ViewerJoinScreenContainer = ({
  _handleJoin,
  name,
}: {
  _handleJoin: (t: joinStateType) => void;
  name: string;
}) => {
  useEffect(() => {
    _handleJoin({
      name,
      selectedMicDeviceId: null,
      selectedWebcamDeviceId: null,
      mirrorLocalWebcam: false,
      webcamOn: false,
      micOn: false,
    } as joinStateType);

    CachedUserData.updateName(name);

    CachedUserData.updateParticipantHeadline("");
  }, [name, _handleJoin]);

  return <React.Fragment />;
};

export default ViewerJoinScreenContainer;
