import { useAppContext } from "../../../contexts/appContextDef";
import PollListItem from "./PollListItem";

const PollsList = () => {
  const { polls } = useAppContext();

  return polls?.length ? (
    <div className="p-1">
      {polls.map((poll) => (
        <PollListItem id={poll.id} key={`poll-list-item-${poll.id}`} />
      ))}
    </div>
  ) : (
    <div className="h-full w-full flex flex-1 items-center justify-center">
      No Polls
    </div>
  );
};

export default PollsList;
