import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import sleep from "../../utils/sleep";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import useRtcLocalParticipantMediaStatsActions from "../../appRtc/useRtcLocalParticipantMediaStatsActions";

const InitialMediaDeviceIdListner = () => {
  const {
    _joinScreenMicOn,
    _joinScreenWebcamOn,
    participantHeadline,
    setParticipantHeadlines,
  } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.PARTICIPANT_HEADLINE,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { participantHeadline, participantId } = message;

      setParticipantHeadlines((s) => {
        const _s = { ...s };

        _s[participantId] = participantHeadline;

        return _s;
      });
    }
  );

  const { publish: participantHeadlinePublish } = useAppSingalingPublish(
    appPubSubTopics.PARTICIPANT_HEADLINE
  );

  const { enableMic, enableWebcam } = useRtcLocalParticipantMediaStatsActions();

  const { localParticipantId } = useLocalParticipantId();

  const enableWebcamRef = useRef(enableWebcam);
  const enableMicRef = useRef(enableMic);

  const participantHeadlinePublishRef = useRef<appSingalingPublishType>(
    participantHeadlinePublish
  );

  const localParticipantIdRef = useRef(localParticipantId);

  const _joinScreenMicOnRef = useRef(_joinScreenMicOn);
  const _joinScreenWebcamOnRef = useRef(_joinScreenWebcamOn);
  const participantHeadlineRef = useRef(participantHeadline);

  useEffect(() => {
    enableMicRef.current = enableMic;
  }, [enableMic]);
  useEffect(() => {
    enableWebcamRef.current = enableWebcam;
  }, [enableWebcam]);
  useEffect(() => {
    participantHeadlinePublishRef.current = participantHeadlinePublish;
  }, [participantHeadlinePublish]);
  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);
  useEffect(() => {
    _joinScreenMicOnRef.current = _joinScreenMicOn;
  }, [_joinScreenMicOn]);
  useEffect(() => {
    _joinScreenWebcamOnRef.current = _joinScreenWebcamOn;
  }, [_joinScreenWebcamOn]);
  useEffect(() => {
    participantHeadlineRef.current = participantHeadline;
  }, [participantHeadline]);

  const _handleOnMeetingJoined = async ({
    _joinScreenMicOn,
    _joinScreenWebcamOn,
    participantHeadline,
  }: {
    _joinScreenMicOn: boolean;
    _joinScreenWebcamOn: boolean;
    participantHeadline: string | null;
  }) => {
    const enableMic = enableMicRef.current;
    const enableWebcam = enableWebcamRef.current;

    if (_joinScreenMicOn) {
      enableMic();

      await sleep(200);
    }

    if (_joinScreenWebcamOn) {
      enableWebcam();

      await sleep(200);
    }

    participantHeadlinePublishRef.current(
      JSON.stringify({
        participantHeadline: participantHeadline || "",
        participantId: localParticipantIdRef.current,
      })
      // { persist: true }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const _joinScreenMicOn = _joinScreenMicOnRef.current;
      const _joinScreenWebcamOn = _joinScreenWebcamOnRef.current;
      const participantHeadline = participantHeadlineRef.current;

      _handleOnMeetingJoined({
        _joinScreenMicOn,
        _joinScreenWebcamOn,
        participantHeadline,
      });
    }, 1000);
  }, []);

  return <React.Fragment />;
};

export default InitialMediaDeviceIdListner;
