import { useMemo } from "react";
import useLivekitRtcAllParticipantsExtraWebcamStreams from "./useLivekitRtcAllParticipantsExtraWebcamStreams";

const useLivekitRtcParticipantExtraWebcam = ({
  extraWebcamId,
  participantId,
}: {
  extraWebcamId: string;
  participantId: string;
}) => {
  const { allParticipantsExtraWebcamStreams } =
    useLivekitRtcAllParticipantsExtraWebcamStreams();

  const {
    isActive,
    isSubscribed,
    participantsExtraWebcamStream,
    videoTrack,
    deviceId,
  } = useMemo(() => {
    const participantsExtraWebcamStream = allParticipantsExtraWebcamStreams
      .get(participantId)
      ?.find(
        ({ extraWebcamId: _extraWebcamId }) => extraWebcamId === _extraWebcamId
      );

    if (participantsExtraWebcamStream) {
      const isSubscribed = !!participantsExtraWebcamStream?.videoTrack;

      return {
        isActive: true,
        isSubscribed,
        videoTrack: participantsExtraWebcamStream?.videoTrack,
        deviceId: participantsExtraWebcamStream?.deviceId,
        participantsExtraWebcamStream,
      };
    } else {
      return {
        isActive: false,
        participantsExtraWebcamStream: undefined,
        isSubscribed: false,
        videoTrack: undefined,
        deviceId: undefined,
      };
    }
  }, [allParticipantsExtraWebcamStreams, extraWebcamId, participantId]);

  return {
    isActive,
    isSubscribed,
    participantsExtraWebcamStream,
    videoTrack,
    deviceId,
  };
};

export default useLivekitRtcParticipantExtraWebcam;
