import React from "react";
import BackgroundMusicListner from "./BackgroundMusicListner";
import BrandColorListner from "./BrandColorListner";
import BrandLogoListner from "./BrandLogoListner";
import BrandMuteGuestsWhenAudioPlaysListner from "./BrandMuteGuestsWhenAudioPlaysListner";
import BrandMuteGuestsWhenVideoPlaysListner from "./BrandMuteGuestsWhenVideoPlaysListner";
import BrandShowDisplayNamesListner from "./BrandShowDisplayNamesListner";
import BrandShowHeadlinesListner from "./BrandShowHeadlinesListner";
import MainViewBackgroundListner from "./MainViewBackgroundListner";
import MainViewOverlayListner from "./MainViewOverlayListner";
import MainViewVideoClipListner from "./MainViewVideoClipListner";

const ActiveBrandListner = () => {
  return (
    <React.Fragment>
      <BackgroundMusicListner />
      <MainViewBackgroundListner />
      <BrandColorListner />
      <MainViewOverlayListner />
      <BrandLogoListner />
      <MainViewVideoClipListner />
      <BrandShowDisplayNamesListner />
      <BrandShowHeadlinesListner />
      <BrandMuteGuestsWhenAudioPlaysListner />
      <BrandMuteGuestsWhenVideoPlaysListner />
    </React.Fragment>
  );
};

export default ActiveBrandListner;
