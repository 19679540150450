import { onValue, query, ref, set, remove } from "firebase/database";
import { firebaseDB } from "../utils/firebase";

const getMSQuickstartBrands = async ({
  userId,
}: {
  userId: string;
}): Promise<string[]> => {
  const brandsRef = query(ref(firebaseDB, `MSQuickstart/brands/${userId}`));

  const brandsRes: string[] = await new Promise((resolve) =>
    onValue(brandsRef, (s) => resolve(s.val()))
  );

  return brandsRes;
};

const getMSQuickstartBannerFolders = async ({
  userId,
}: {
  userId: string;
}): Promise<string[]> => {
  const bannerFoldersRef = query(
    ref(firebaseDB, `MSQuickstart/bannerFolders/${userId}`)
  );

  const bannerFoldersRes = (await new Promise((resolve) =>
    onValue(bannerFoldersRef, (s) => resolve(s.val()))
  )) as string[];

  return bannerFoldersRes;
};

const getMSQuickstartPolls = async ({
  broadcastId,
}: {
  broadcastId: string;
}): Promise<string[]> => {
  const pollsRef = query(ref(firebaseDB, `MSQuickstart/polls/${broadcastId}`));

  const pollsRes = (await new Promise((resolve) =>
    onValue(pollsRef, (s) => resolve(s.val()))
  )) as string[];

  return pollsRes;
};

const getMSQuickstartWordclouds = async ({
  broadcastId,
}: {
  broadcastId: string;
}): Promise<string[]> => {
  const wordcloudsRef = query(
    ref(firebaseDB, `MSQuickstart/wordclouds/${broadcastId}`)
  );

  const wordcloudsRes = (await new Promise((resolve) =>
    onValue(wordcloudsRef, (s) => resolve(s.val()))
  )) as string[];

  return wordcloudsRes;
};

const setMSQuickstartBrands = async ({
  userId,
  data,
}: {
  userId: string;
  data: string[];
}) => {
  await set(ref(firebaseDB, `MSQuickstart/brands/${userId}`), data);
};

const setMSQuickstartBannerFolders = async ({
  userId,
  data,
}: {
  userId: string;
  data: string[];
}) => {
  await set(ref(firebaseDB, `MSQuickstart/bannerFolders/${userId}`), data);
};

const setMSQuickstartPolls = async ({
  broadcastId,
  data,
}: {
  broadcastId: string;
  data: string[];
}) => {
  await set(ref(firebaseDB, `MSQuickstart/polls/${broadcastId}`), data);
};

const setMSQuickstartWordclouds = async ({
  broadcastId,
  data,
}: {
  broadcastId: string;
  data: string[];
}) => {
  await set(ref(firebaseDB, `MSQuickstart/wordclouds/${broadcastId}`), data);
};

const removeMSQuickstartBrands = async ({ userId }: { userId: string }) => {
  await remove(ref(firebaseDB, `MSQuickstart/brands/${userId}`));
};

const removeMSQuickstartBannerFolders = async ({
  userId,
}: {
  userId: string;
}) => {
  await remove(ref(firebaseDB, `MSQuickstart/bannerFolders/${userId}`));
};

const removeMSQuickstartPolls = async ({
  broadcastId,
}: {
  broadcastId: string;
}) => {
  await remove(ref(firebaseDB, `MSQuickstart/polls/${broadcastId}`));
};

const removeMSQuickstartWordclouds = async ({
  broadcastId,
}: {
  broadcastId: string;
}) => {
  await remove(ref(firebaseDB, `MSQuickstart/wordclouds/${broadcastId}`));
};

export {
  getMSQuickstartBrands,
  getMSQuickstartBannerFolders,
  getMSQuickstartPolls,
  getMSQuickstartWordclouds,
  setMSQuickstartBrands,
  setMSQuickstartBannerFolders,
  setMSQuickstartPolls,
  setMSQuickstartWordclouds,
  removeMSQuickstartBrands,
  removeMSQuickstartBannerFolders,
  removeMSQuickstartPolls,
  removeMSQuickstartWordclouds,
};
