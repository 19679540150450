// @ts-nocheck

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import useAppRtcLocalParticipantMediaStats from "../../appRtc/useAppRtcLocalParticipantMediaStats";
import sleep from "../../utils/sleep";
import generateAgoraRtcConfig from "../../apis/studio/generate-agora-rtc-config";
import { useAppContext } from "../../contexts/appContextDef";
import { interactivityModes } from "../../utils/constants";
import {
  generateStreamId,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";

const OriginalAudioPublisherAgora = () => {
  const {
    selectedMicDeviceId,
    localParticipantId,

    mainViewSelectedStreams,
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
  } = useAppContext();
  const { originalAudioPublishAgoraChannelId } = useAppConfigContext();

  const isConnecting = useRef(false);

  const { micTrack } = useAppRtcLocalParticipantMediaStats();

  const agoraClientRef = useRef(null);
  const selectedMicDeviceIdRef = useRef(selectedMicDeviceId);
  const micTrackRef = useRef(micTrack);

  useEffect(() => {
    micTrackRef.current = micTrack;
  }, [micTrack]);
  useEffect(() => {
    selectedMicDeviceIdRef.current = selectedMicDeviceId;
  }, [selectedMicDeviceId]);

  const { isInMainView } = useMemo(() => {
    if (interactivityMode === interactivityModes.STUDIO) {
      const streamId = generateStreamId({
        participantId: localParticipantId,
        type: streamTypes.WEBCAM,
      });

      const isInMainView = !!mainViewSelectedStreams.get(streamId);

      return { isInMainView };
    } else {
      const isInConferenceMainViewIndex =
        conferenceMainViewVisibleWebcamStreams.findIndex(
          ({ participantId: _participantId }) =>
            _participantId === localParticipantId
        );

      const isInConferenceMainView =
        conferenceMainViewVisibleWebcamStreams[isInConferenceMainViewIndex];

      return {
        pinned: isInConferenceMainView?.pinned,
        index: isInConferenceMainViewIndex,
        isInMainView: isInConferenceMainViewIndex !== -1,
      };
    }
  }, [
    localParticipantId,
    mainViewSelectedStreams,
    interactivityMode,
    conferenceMainViewVisibleWebcamStreams,
  ]);

  const publish = async ({ micTrack, selectedMicDeviceId }) => {
    try {
      if (
        agoraClientRef.current &&
        micTrack &&
        window.AgoraRTC?.createMicrophoneAudioTrack
      ) {
        const microphoneAudioTrack =
          await window.AgoraRTC.createMicrophoneAudioTrack({
            microphoneId: selectedMicDeviceId,
          });

        if (microphoneAudioTrack) {
          await agoraClientRef.current.publish(microphoneAudioTrack);
        }
      }
    } catch (error) {
      //
    }
  };

  const unpublish = async () => {
    try {
      if (agoraClientRef.current) {
        agoraClientRef.current.unpublish(agoraClientRef.current.localTracks);
      }
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    try {
      if (micTrack && selectedMicDeviceId && isInMainView) {
        publish({ micTrack, selectedMicDeviceId });
      } else {
        unpublish();
      }
    } catch (error) {
      //
    }
  }, [micTrack, isInMainView, selectedMicDeviceId]);

  const [agoraRtcSdkLoaded, setAgoraRtcSdkLoaded] = useState(false);

  const agoraRtcSdkLoadedRef = useRef(agoraRtcSdkLoaded);

  const loadAgoraRtcSdk = async () => {
    if (agoraRtcSdkLoadedRef.current) {
      return;
    }

    await new Promise((resolve) => {
      const agoraRtcSdkSrc =
        "https://download.agora.io/sdk/release/AgoraRTC_N-4.20.2.js";

      const script = document.createElement("script");

      script.src = agoraRtcSdkSrc;

      script.addEventListener("load", async () => {
        setAgoraRtcSdkLoaded(true);

        await sleep(500);

        resolve(undefined);
      });

      document.head.appendChild(script);
    });

    return;
  };

  const connectAgoraChannel = async ({
    channelName,
  }: {
    channelName: string;
  }) => {
    if (isConnecting.current) {
      return;
    }

    isConnecting.current = true;

    try {
      await loadAgoraRtcSdk();

      const client = window.AgoraRTC.createClient({
        mode: "rtc",
        codec: "vp8",
      });

      const { success, data } = await generateAgoraRtcConfig({ channelName });

      if (success && data) {
        const { appId, token } = data;

        await client.join(appId, channelName, token, null);
      }

      agoraClientRef.current = client;

      if (micTrackRef.current && selectedMicDeviceIdRef.current) {
        publish({
          micTrack: micTrackRef.current,
          selectedMicDeviceId: selectedMicDeviceIdRef.current,
        });
      }
    } catch (error) {
      //
    }

    isConnecting.current = false;
  };

  useEffect(() => {
    try {
      connectAgoraChannel({
        channelName: originalAudioPublishAgoraChannelId as string,
      });
    } catch (error) {
      //
    }

    return () => {
      if (agoraClientRef.current) {
        agoraClientRef.current.leave();
      }
    };
  }, []);

  return <React.Fragment />;
};

export default OriginalAudioPublisherAgora;
