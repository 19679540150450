import React from "react";
import { MdLaunch } from "react-icons/md";

const UpgradeNowLine = ({
  preText,
  postText,
  breakText,
}: {
  preText: string;
  postText: string;
  breakText?: boolean;
}) => {
  return (
    <p className="text-center font-medium">
      {preText}
      {breakText ? <br /> : <React.Fragment />}
      <a
        rel="noreferrer"
        target="_blank"
        className="link link-hover text-primary font-bold shrink-0 inline-flex items-center justify-center"
        href="https://app.momentostream.com/plans/"
      >
        Upgrade now <MdLaunch />
      </a>
      {postText}
    </p>
  );
};

export default UpgradeNowLine;
