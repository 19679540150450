import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";
import useGetParticipantName from "../../hooks/appState/useGetParticipantName";

const FilteredInQueueStreamsListner = () => {
  const {
    filteredInQueueStreams,
    setFilteredInQueueStreams,
    filteredInQueueStreamsSearchQuery,

    raiseHandParticipantIds,
    raiseHandleSeachEnabled,
  } = useAppContext();

  const { participantIds } = useParticipantIdsAndCount();

  const { getParticipantName } = useGetParticipantName();

  const filteredInQueueStreamsRef = useRef(filteredInQueueStreams);

  useEffect(() => {
    filteredInQueueStreamsRef.current = filteredInQueueStreams;
  }, [filteredInQueueStreams]);

  useEffect(() => {
    const filteredParticipantIds = [
      ...participantIds.filter((participantId) => {
        const name = getParticipantName(participantId);
        const pattern = new RegExp(filteredInQueueStreamsSearchQuery, "i");
        const regexActive = name.match(pattern);
        const raiseHandActive = raiseHandleSeachEnabled
          ? raiseHandParticipantIds.includes(participantId)
          : true;
        return raiseHandleSeachEnabled ? !!raiseHandActive : !!regexActive;
      }),
    ];

    if (
      filteredInQueueStreamsRef.current
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
        .join("") !==
      filteredParticipantIds
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
        .join("")
    ) {
      setFilteredInQueueStreams(filteredParticipantIds);
    }
  }, [
    setFilteredInQueueStreams,
    participantIds,
    filteredInQueueStreamsSearchQuery,
    raiseHandParticipantIds,
    raiseHandleSeachEnabled,
    getParticipantName,
  ]);

  return <React.Fragment />;
};

export default FilteredInQueueStreamsListner;
