import { studioApiServerBaseUrl } from "../../utils/constants";

const generateLivekitRtcConfig = async ({
  roomName,
  participantId,
  studioUserId,
  appMode,
  isRecorder,
  isInterpreter,
  participantName,
}: {
  roomName: string;
  participantId: string;
  studioUserId: string;
  appMode: string;
  isRecorder?: boolean;
  isInterpreter?: boolean;
  participantName: string;
}): Promise<{
  success: boolean;
  data?: {
    token: string;
    roomName: string;
    participantId: string;
    websocketUrl: string;
  };
}> => {
  try {
    const url = `${studioApiServerBaseUrl}/generate-livekit-rtc-config`;

    const body = JSON.stringify({
      roomName,
      participantId,
      studioUserId,
      appMode,
      isRecorder,
      isInterpreter,
      participantName,
    });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true, data: json.data };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default generateLivekitRtcConfig;
