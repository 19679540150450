import { useMemo } from "react";
import { audioAndVideoPermissionStates } from "../../utils/constants";
import { useAppContext } from "../../contexts/appContextDef";

const useIsParticipantMicDenied = ({
  participantId,
}: {
  participantId: string;
}) => {
  const { participantsAudioVideoCapturerState } = useAppContext();

  const { micPermissionState } = useMemo(() => {
    const participantFound = participantsAudioVideoCapturerState[participantId];

    if (participantFound) {
      return {
        micPermissionState: participantFound["mic"],
      };
    } else {
      return { micPermissionState: null };
    }
  }, [participantsAudioVideoCapturerState, participantId]);

  const micDenied = useMemo(
    () => micPermissionState === audioAndVideoPermissionStates.denied,
    [micPermissionState]
  );

  return { micDenied };
};

export default useIsParticipantMicDenied;
