import { useEffect, useRef } from "react";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { broadcastAssetsActions } from "../../listners/appState/BroadcastAssetsListner";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateSpeakerCanTakeAction from "./useValidateSpeakerCanTakeAction";

const useBroadcastAssets = () => {
  const { studioUserId } = useAppConfigContext();

  const { publish } = useAppSingalingPublish(appPubSubTopics.BROADCAST_ASSETS);

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  const studioUserIdRef = useRef(studioUserId);

  useEffect(() => {
    studioUserIdRef.current = studioUserId;
  }, [studioUserId]);

  const addAsset = async ({ data }: { data: broadcastAssetType }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    publish(
      JSON.stringify({
        payload: { ...data, owners: [studioUserIdRef.current] },
        action: broadcastAssetsActions.ADD,
      })
    );
  };

  const updateAsset = async ({ data }: { data: broadcastAssetType }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    publish(
      JSON.stringify({
        payload: data,
        action: broadcastAssetsActions.UPDATE,
      })
    );
  };

  const removeAsset = async ({ fileId }: { fileId: string }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    publish(
      JSON.stringify({
        payload: { fileId },
        action: broadcastAssetsActions.REMOVE,
      })
    );
  };

  return { addAsset, updateAsset, removeAsset };
};

export default useBroadcastAssets;
