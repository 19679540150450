import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import DisclosureContainer from "../../components/DisclosureContainer";
import { Menu, MenuItem } from "@szhsin/react-menu";
import {
  MdAdd,
  MdClose,
  MdDelete,
  MdDownload,
  MdEdit,
  MdMoreVert,
  MdPlaylistAdd,
  MdPlaylistRemove,
  MdRemove,
} from "react-icons/md";
import deleteBroadcastAsset from "../../apis/broadcasts/delete-broadcast-asset";
import Modal from "react-modal";
import useBroadcastAssets from "../../hooks/appState/useBroadcastAssets";
import updateBroadcastAsset from "../../apis/broadcasts/update-broadcast-asset";
import useParticipantName from "../../hooks/appState/useParticipantName";
import SidePanelTopBar from "../sidePanel/SidePanelTopBar";
import { ShareFileFromComputerContainer } from "../bottomControlsContainer/ShareContainer";
import AppModal from "../../components/Modal";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import Spinner from "../../components/Spinner";
import { useWindowSize } from "@react-hook/window-size";
// import { toggleParticipantMediaActions } from "../../hooks/appState/useToggleParticipantMedia";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import {
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
// import { getToggleParticipantMediaTopicById } from "../../utils/pubSubTopics";
import sleep from "../../utils/sleep";
import useFileShareStreams from "../../hooks/streams/useFileShareStreams";
import { primaryColor } from "../../utils/colors";

const ParticipantName = ({ participantId }: { participantId: string }) => {
  const { name } = useParticipantName(participantId);

  return name;
};

const EditAssetOwnersContainer = ({
  owners: _owners,
  onUpdate,
  onCancel,
}: {
  owners: string[];
  onUpdate: ({ owners }: { owners: string[] }) => Promise<void>;
  onCancel: () => void;
}) => {
  const [owners, setOwners] = useState(_owners);
  const { joinedParticipants } = useAppContext();

  const { joinedParticipantOwners, joinedParticipantNonOwners } =
    useMemo(() => {
      const joinedParticipantOwners: joinedParticipantsType = [];
      const joinedParticipantNonOwners: joinedParticipantsType = [];

      joinedParticipants.forEach((joinedParticipant) => {
        const { studioUserId } = joinedParticipant;

        const isOwner = owners.includes(studioUserId);

        if (isOwner) {
          joinedParticipantOwners.push(joinedParticipant);
        } else {
          joinedParticipantNonOwners.push(joinedParticipant);
        }
      });

      return { joinedParticipantOwners, joinedParticipantNonOwners };
    }, [joinedParticipants, owners]);

  const addOwner = (id: string) => {
    setOwners((s) => [...s, id]);
  };

  const removeOwner = (id: string) => {
    setOwners((s) => s.filter((_id) => `${_id}` !== `${id}`));
  };

  const [screenWidth] = useWindowSize();

  return (
    <div>
      {/* <div>
        <p className="text-xl font-bold text-primary">Edit ownership</p>
      </div> */}

      <div className="flex">
        <div className="flex flex-1 items-center">
          <h4 className="text-xl font-semibold text-primary">Edit ownership</h4>
        </div>

        <button
          className="btn btn-ghost rounded-full"
          onClick={() => {
            onCancel();
          }}
        >
          <MdClose />
        </button>
      </div>

      <div className="mt-6 flex">
        <div style={{ width: (screenWidth * 3) / 8 }} className="p-3">
          <p className="font-medium text-md">Owners of resource</p>
          <div className="mt-2 h-60 overflow-y-scroll">
            {joinedParticipantOwners.length ? (
              joinedParticipantOwners.map(({ participantId, studioUserId }) => (
                <div className="flex p-2 items-center justify-center font-medium hover:bg-custom-blue-50 text-primary rounded-md">
                  <p className="flex flex-1">
                    <ParticipantName {...{ participantId }} />
                  </p>

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      removeOwner(studioUserId);
                    }}
                  >
                    <MdRemove size={20} />
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-1 items-center justify-center h-32">
                <p>No owners of the resource</p>
              </div>
            )}
          </div>
        </div>

        <div
          style={{ width: (screenWidth * 3) / 8 }}
          className="p-3 border-l border-primary"
        >
          <p className="font-medium text-md">Add new owners</p>
          <div className="mt-2 h-60 overflow-y-scroll">
            {joinedParticipantNonOwners.length ? (
              joinedParticipantNonOwners.map(
                ({ participantId, studioUserId }) => (
                  <div className="flex p-2 items-center justify-center font-medium hover:bg-custom-blue-50 text-primary rounded-md">
                    <p className="flex flex-1">
                      <ParticipantName {...{ participantId }} />
                    </p>

                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        addOwner(studioUserId);
                      }}
                    >
                      <MdAdd size={20} />
                    </div>
                  </div>
                )
              )
            ) : (
              <div className="flex flex-1 items-center justify-center h-32">
                <p>No more participants to add</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex mt-6 gap-3">
        <button
          disabled={!owners.length}
          onClick={() => {
            onUpdate({ owners });
          }}
          type="button"
          className="btn btn-primary text-white normal-case btn-outline btn-sm"
        >
          Save
        </button>

        <button
          onClick={() => {
            onCancel();
          }}
          type="button"
          className="btn text-white normal-case btn-outline btn-error btn-sm"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const SlideItemMenu = ({
  setEditOwnershipOpen,
  pdf,
  setConfirmDeleteModalVisible,
  name,
  isActive,
  _handleRemoveFromStudio,
}: {
  setEditOwnershipOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setConfirmDeleteModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  pdf: {
    id: string;
    name: string;
    owners: string[];
    pdfInfo: pdfInfoType;
    remoteUrl: string;
  };
  name: string;
  isActive: boolean;
  _handleRemoveFromStudio: () => void;
}) => {
  return (
    <Menu
      menuButton={
        <span>
          <button
            className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
          >
            <div>
              <MdMoreVert size={16} />
            </div>
          </button>
        </span>
      }
      transition
    >
      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={() => {
          setEditOwnershipOpen(true);
        }}
      >
        <div className="flex items-center justify-center gap-1">
          <MdEdit />
          <p className="ml-2">Edit ownership</p>
        </div>
      </MenuItem>

      {isActive ? (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            _handleRemoveFromStudio();
          }}
        >
          <div className="flex items-center justify-center gap-1">
            <MdPlaylistRemove />
            <p className="ml-2">Remove from studio</p>
          </div>
        </MenuItem>
      ) : (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            appEventEmitter.emit(appEventEmitterEvents.ADD_PDF_FROM_UPLOADED, {
              remoteUrl: pdf.remoteUrl,
              pdfInfo: pdf.pdfInfo,
              resourceId: pdf.id,
            });
          }}
        >
          <div className="flex items-center justify-center gap-1">
            <MdPlaylistAdd />
            <p className="ml-2">Add to studio</p>
          </div>
        </MenuItem>
      )}

      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={() => {
          fetch(pdf.remoteUrl as string)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = name;
              link.click();
            })
            .catch((error) => console.error("Error downloading file:", error));
        }}
      >
        <div className="flex items-center justify-center gap-1">
          <MdDownload />
          <p className="ml-2">Download</p>
        </div>
      </MenuItem>

      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={() => {
          setConfirmDeleteModalVisible(true);
        }}
      >
        <div className="flex items-center justify-center gap-1">
          <MdDelete />
          <p className="ml-2">Delete</p>
        </div>
      </MenuItem>
    </Menu>
  );
};

const SlideItemContainer = ({
  pdf,
}: {
  pdf: {
    id: string;
    name: string;
    owners: string[];
    pdfInfo: pdfInfoType;
    remoteUrl: string;
  };
}) => {
  const { broadcastId } = useAppConfigContext();
  const {
    joinedParticipants,
    broadcastAssets,
    activeFileShareStreamAllParticipants,
  } = useAppContext();

  const [editOwnershipOpen, setEditOwnershipOpen] = useState(false);
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);

  const { updateAsset, removeAsset } = useBroadcastAssets();
  const { disableFileShareStream } = useFileShareStreams();

  const owners = useMemo(() => pdf.owners, [pdf]);

  const { fileId, name } = useMemo(() => ({ ...pdf, fileId: pdf.id }), [pdf]);

  const { joinedParticipantOwners } = useMemo(() => {
    const joinedParticipantOwners: joinedParticipantsType = [];

    joinedParticipants.forEach((joinedParticipant) => {
      const { studioUserId } = joinedParticipant;

      const isOwner = owners.includes(studioUserId);

      if (isOwner) {
        joinedParticipantOwners.push(joinedParticipant);
      }
    });

    return { joinedParticipantOwners };
  }, [joinedParticipants, owners]);

  const broadcastAsset = useMemo(
    () => broadcastAssets.find(({ id }) => `${id}` === `${pdf.id}`),
    [broadcastAssets, pdf]
  );

  const broadcastAssetRef = useRef(broadcastAsset);

  useEffect(() => {
    broadcastAssetRef.current = broadcastAsset;
  }, [broadcastAsset]);

  const {
    isActive,
    // activeFileShareStreamParticipantId,
    activeFileShareStreamFileId,
  } = useMemo(() => {
    const activeFileShareStream = activeFileShareStreamAllParticipants?.find(
      ({ resourceId }) => `${resourceId}` === `${broadcastAsset?.id}`
    );

    return {
      isActive: !!activeFileShareStream,
      // activeFileShareStreamParticipantId:
      //   activeFileShareStream?.participantId as string,
      activeFileShareStreamFileId: activeFileShareStream?.id as string,
    };
  }, [broadcastAsset, activeFileShareStreamAllParticipants]);

  // const activeFileShareStreamParticipantIdRef = useRef(
  //   activeFileShareStreamParticipantId
  // );

  const activeFileShareStreamFileIdRef = useRef(activeFileShareStreamFileId);

  // useEffect(() => {
  //   activeFileShareStreamParticipantIdRef.current =
  //     activeFileShareStreamParticipantId;
  // }, [activeFileShareStreamParticipantId]);
  useEffect(() => {
    activeFileShareStreamFileIdRef.current = activeFileShareStreamFileId;
  }, [activeFileShareStreamFileId]);

  const { removeFromMainViewStreams } = useMainViewParticipants();

  const _handleRemoveFromStudio = async () => {
    removeFromMainViewStreams({
      mode: streamModes.PDF,
      participantId: `${broadcastAssetRef.current?.id}`,
      type: streamTypes.SHARE,
      fileId: activeFileShareStreamFileIdRef.current,
    });

    await sleep(300);

    disableFileShareStream({ id: activeFileShareStreamFileIdRef.current });

    // appEventEmitter.emit(appEventEmitterEvents.SIGNALING_PUBSUB_PUBLISH, {
    //   topic: getToggleParticipantMediaTopicById(
    //     activeFileShareStreamParticipantIdRef.current
    //   ),
    //   message: JSON.stringify({
    //     action: toggleParticipantMediaActions.TURN_OFF_FILE_SHARE_STREAM,
    //     sentBySystem: false,
    //     fileId: activeFileShareStreamFileIdRef.current,
    //   }),
    // });
  };

  return (
    <React.Fragment>
      <div className="mt-2 p-1 hover:bg-custom-blue-50 rounded-md flex flex-1 items-center justify-center gap-2">
        <div
          className="aspect-square  rounded-md bg-custom-blue-100 flex items-center justify-center font-bold text-white"
          style={{ height: 32, width: 32 }}
        >
          {name[0]}
        </div>
        <div className="flex flex-1 flex-col">
          <div className={`truncate text-ellipsis text-primary font-medium`}>
            <p>{name}</p>
          </div>
          <div className="flex gap-2 items-center">
            <p className="text-primary text-sm font-light">
              {joinedParticipantOwners.length} owners
            </p>
            <div
              className={`badge ${
                isActive ? "badge-success text-white" : "badge text-gray-300"
              } badge-outline text-xs font-bold`}
            >
              ACTIVE
            </div>
          </div>
        </div>
        <div>
          <SlideItemMenu
            {...{
              pdf,
              name,
              setConfirmDeleteModalVisible,
              setEditOwnershipOpen,
              isActive,
              _handleRemoveFromStudio,
            }}
          />
        </div>
      </div>

      <AppModal
        {...{
          isOpen: confirmDeleteModalVisible,
          onSuccess: async () => {
            const { success } = await deleteBroadcastAsset({
              broadcastId,
              fileId,
            });

            if (success) {
              await _handleRemoveFromStudio();

              removeAsset({ fileId });
            }
            setConfirmDeleteModalVisible(false);
          },
          onClose: () => {
            setConfirmDeleteModalVisible(false);
          },
          cancelText: "Cancle",
          successText: "Delete",
          title: "Are you sure you want to delete this slide?",
        }}
      />

      <Modal
        isOpen={editOwnershipOpen}
        onRequestClose={() => {
          setEditOwnershipOpen(false);
        }}
        shouldFocusAfterRender={false}
        style={{
          overlay: { backgroundColor: "#00000066" },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderWidth: "2px",
            borderColor: primaryColor,
            borderRadius: 8,
          },
        }}
      >
        <div className="flex items-center overflow-hidden bg-white">
          {editOwnershipOpen ? (
            <EditAssetOwnersContainer
              {...{
                owners,
                onUpdate: async ({ owners }: { owners: string[] }) => {
                  const { success } = await updateBroadcastAsset({
                    broadcastId,
                    fileId,
                    owners,
                    name,
                  });

                  if (success && broadcastAsset) {
                    updateAsset({ data: { ...broadcastAsset, owners } });
                  }

                  setEditOwnershipOpen(false);
                },
                onCancel: () => {
                  setEditOwnershipOpen(false);
                },
              }}
            />
          ) : (
            <React.Fragment />
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

const AllSlidesContainer = ({ allPdfs }: { allPdfs: assetPdfsType }) => {
  const { newPdfsState } = useAppContext();

  return (
    <div>
      {allPdfs.map((pdf, i) => (
        <SlideItemContainer {...{ pdf, key: `SlideItemContainer-${i}` }} />
      ))}

      {newPdfsState.map((newPdf) => (
        <div
          className="mt-2 p-2 hover:bg-custom-blue-50 rounded-md flex flex-1 items-center justify-center gap-3 text-primary"
          key={`AllSlidesContainer-newPdfsState-${newPdf.id}`}
        >
          <Spinner h={"h-6"} w={"w-6"} />

          {`${newPdf.name} is ${
            newPdf.isUploading ? "uploading" : "processing"
          }...`}
        </div>
      ))}
    </div>
  );
};

const ResourcesContainer = ({
  containerHeight,
  containerWidth,
}: {
  containerHeight: number;
  containerWidth: number;
}) => {
  const { setSidePanelAppMode, allPdfs } = useAppContext();

  return (
    <div style={{ height: containerHeight, width: containerWidth }}>
      <div>
        <SidePanelTopBar
          title="Resources"
          onBackClick={() => {
            setSidePanelAppMode(null);
          }}
          renderRightComponent={() => (
            <Menu
              menuButton={
                <button
                  className={`btn btn-primary btn-outline normal-case btn-sm`}
                >
                  <MdAdd size={24} />
                </button>
              }
              transition
            >
              <ShareFileFromComputerContainer title={"Slides"} />
            </Menu>
          )}
        />
      </div>

      <div>
        <div>
          <DisclosureContainer
            defaultOpen
            title={`Slides (${allPdfs.length})`}
            renderPanel={() => <AllSlidesContainer {...{ allPdfs }} />}
          />
        </div>
      </div>
    </div>
  );
};

export default ResourcesContainer;
