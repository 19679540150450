import { egressManagerApiServerBaseUrl } from "../../utils/constants";

const startAIInterpreter = async ({ broadcastId }: { broadcastId: string }) => {
  try {
    const url = `${egressManagerApiServerBaseUrl}/start-ai-interpreter`;

    // const url =
    //   "https://c556-2409-40c1-103d-d744-1978-c47a-13bb-75d6.ngrok-free.app/api/v1/start-ai-interpreter";

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ broadcastId }),
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true, egress: json.egress };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default startAIInterpreter;
