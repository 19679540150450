import React, { useMemo } from "react";
import Youtube from "../../../static/brand-icons/Youtube";
import Facebook from "../../../static/brand-icons/Facebook";
import Twitch from "../../../static/brand-icons/Twitch";
import { MdCheck, MdDns, MdPriorityHigh } from "react-icons/md";
import {
  momento_live_logo,
  momento_translate_logo,
} from "../../../static/images";
import Tooltip from "../../../components/Tooltip";
import LinkedIn from "../../../static/brand-icons/LinkedIn";
import { useAppContext } from "../../../contexts/appContextDef";
import useLiveStreamDestinations from "../../../hooks/appState/useLiveStreamDestinations";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import useBroadcastEgressesTimer from "../../../hooks/appState/useBroadcastEgressesTimer";
import useBroadcastEgresses from "../../../hooks/appState/useBroadcastEgresses";
import { createRtmpDestinationUrlFromUrlAndStreamKey } from "../../../listners/appState/BroadcastEgressesListner";
import { getLivekitStreamUrlFromOriginalRtmpUrl } from "../../../utils/getLivekitStreamUrlFromOriginalRtmpUrl";
import Kick from "../../../static/brand-icons/Kick";
import Instagram from "../../../static/brand-icons/Instagram";

const SelectedBroadcastItem = ({
  id,
  nickName,
  isSelected,
  type,
  streamKey,
  url: streamUrl,
}: {
  id: string;
  nickName: string;
  isSelected: boolean;
  type: string;
  streamKey: string;
  url: string;
}) => {
  const {
    erroredLiveStreamDestinationsIds,
    failedRtmpDestinationUrls,
    rtmpDestinationIdAndUrls,
  } = useAppContext();

  const { isErrored, errorMessage } = useMemo(() => {
    const errFound = erroredLiveStreamDestinationsIds?.find(
      ({ id: _id }) => `${id}` === `${_id}`
    );

    const url =
      type.includes("youtube") ||
      type.includes("facebook") ||
      type.includes("linkedin")
        ? rtmpDestinationIdAndUrls.find(({ id: _id }) => `${id}` === `${_id}`)
            ?.rtmpDestinationUrl
        : createRtmpDestinationUrlFromUrlAndStreamKey({
            streamKey,
            url: streamUrl,
          });

    const { livekitStreamUrl } = getLivekitStreamUrlFromOriginalRtmpUrl({
      url: url as string,
    });

    const errFoundFailed = failedRtmpDestinationUrls?.find(
      ({ url: _url }) => _url === livekitStreamUrl || _url === url
    );

    const isErrored = !!errFound || !!errFoundFailed;

    const errorMessage = errFound?.message || errFoundFailed?.error;

    return { isErrored, errorMessage };
  }, [
    id,
    type,
    streamKey,
    streamUrl,
    rtmpDestinationIdAndUrls,
    failedRtmpDestinationUrls,
    erroredLiveStreamDestinationsIds,
  ]);

  const { backgroundColor, Icon, isLinkedIn } = useMemo(() => {
    return type.includes("youtube")
      ? { backgroundColor: "rgb(254,1,0)", Icon: Youtube }
      : type.includes("facebook")
      ? { backgroundColor: "#1877f2", Icon: Facebook }
      : type.includes("kick")
      ? { backgroundColor: "#000", Icon: Kick }
      : type.includes("instagram")
      ? { backgroundColor: "#fff", Icon: Instagram }
      : type.includes("twitch")
      ? { backgroundColor: "#6441A4", Icon: Twitch }
      : type.includes("linkedin")
      ? { backgroundColor: "#0A66C2", Icon: LinkedIn, isLinkedIn: true }
      : { backgroundColor: "gray", Icon: MdDns };
  }, [type]);

  const { isLivestreamingOriginalAudio } = useBroadcastEgresses();

  const { hhmmss } = useBroadcastEgressesTimer();

  const { tooltipTitle, tooltipTitleN } = useMemo(() => {
    const tooltipTitle = nickName;

    const tooltipTitleN =
      isErrored && errorMessage
        ? [`Error: ${errorMessage}`]
        : isLivestreamingOriginalAudio && isLinkedIn
        ? [
            `• Duration: ${hhmmss} (Max 4 hours)`,
            "• Aspect Ratio: 16:9",
            "• Resolution: 1080p",
            "• Frame Rate: max 30fps",
            "• Key Frame Interval: 60 frames @ 30fps (GOP Size), or every 2 seconds",
            "• Bitrate: 6mbps video; 128kbps audio, 48K sample rate",
            "• Encoding: H264 video; AAC audio",
            "• Protocol: RTMP/RTMPS",
          ]
        : undefined;
    return { tooltipTitle, tooltipTitleN };
  }, [
    hhmmss,
    isErrored,
    errorMessage,
    isLivestreamingOriginalAudio,
    nickName,
    isLinkedIn,
  ]);

  return (
    <Tooltip titlen={tooltipTitleN} title={tooltipTitle}>
      <div
        className={`${
          isSelected ? "border-custom-blue-300" : "border-custom-blue-50"
        } border-2 m-1 rounded-full relative`}
      >
        <div
          className={`${"bg-custom-blue-600 border-primary"} m-0.5 rounded-full h-8 w-8 flex items-center justify-center aspect-square p-2 border-2 tooltip cursor-pointer`}
        >
          <p className="text-white text-lg font-extrabold">{`${nickName[0]}`}</p>
        </div>

        <div
          style={{ backgroundColor }}
          className="absolute flex items-center justify-center -right-1 bottom-0 p-0.5 rounded-full"
        >
          <Icon className={"fill-white h-3 w-3"} />
        </div>

        {isErrored ? (
          <div className="bg-red-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdPriorityHigh className="fill-white h-3 w-3" />
          </div>
        ) : isLivestreamingOriginalAudio ? (
          <div className="bg-green-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdCheck className="fill-white h-3 w-3" />
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </Tooltip>
  );
};

const MomentoLiveBroadcastItem = () => {
  const { failedRtmpDestinationUrls } = useAppContext();
  const { defaultDestination } = useAppConfigContext();
  const { isLivestreamingOriginalAudio } = useBroadcastEgresses();

  const { isErrored, errorMessage } = useMemo(() => {
    const streamKey = defaultDestination?.streamKey as string;
    const streamUrl = defaultDestination?.streamUrl as string;

    const url = createRtmpDestinationUrlFromUrlAndStreamKey({
      streamKey,
      url: streamUrl,
    });

    const { livekitStreamUrl } = getLivekitStreamUrlFromOriginalRtmpUrl({
      url,
    });

    const errFoundFailed = failedRtmpDestinationUrls?.find(
      ({ url: _url }) => _url === livekitStreamUrl || _url === url
    );

    return { isErrored: !!errFoundFailed, errorMessage: errFoundFailed?.error };
  }, [defaultDestination, failedRtmpDestinationUrls]);

  return (
    <Tooltip
      title={"Momento Live"}
      titlen={
        isErrored && errorMessage ? [`Error: ${errorMessage}`] : undefined
      }
    >
      <div
        className={`border-custom-blue-300 border-2 m-1 rounded-full relative`}
      >
        <div
          className={`bg-custom-blue-600 border-primary m-0.5 rounded-full h-8 w-8 flex items-center justify-center aspect-square p-2 border-2 tooltip cursor-not-allowed`}
        >
          <p className="text-white text-lg font-extrabold">M</p>
        </div>

        <div
          style={{
            backgroundColor: "gray",
          }}
          className="absolute flex items-center justify-center -right-1 bottom-0 p-0.5 rounded-full"
        >
          <img
            src={momento_live_logo}
            className={"h-3 w-3 bg-white rounded-full"}
            alt="momento-logo"
          />
        </div>

        {isErrored ? (
          <div className="bg-red-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdPriorityHigh className="fill-white h-3 w-3" />
          </div>
        ) : isLivestreamingOriginalAudio ? (
          <div className="bg-green-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdCheck className="fill-white h-3 w-3" />
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </Tooltip>
  );
};

const MomentoTranslateOriginalBroadcastItem = () => {
  const { interpretationsOriginalDestination } = useAppConfigContext();
  const { isLivestreamingOriginalAudio } = useBroadcastEgresses();
  const { failedRtmpDestinationUrls } = useAppContext();

  const { isErrored, errorMessage } = useMemo(() => {
    const url = createRtmpDestinationUrlFromUrlAndStreamKey({
      streamKey: interpretationsOriginalDestination?.rtmpKey,
      url: interpretationsOriginalDestination?.rtmpUrl,
    });

    const { livekitStreamUrl } = getLivekitStreamUrlFromOriginalRtmpUrl({
      url,
    });

    const found = failedRtmpDestinationUrls?.find(
      ({ url: _url }) => _url === livekitStreamUrl || _url === url
    );

    return { isErrored: !!found, errorMessage: found?.error };
  }, [interpretationsOriginalDestination, failedRtmpDestinationUrls]);

  return (
    <Tooltip
      title={`Momento Translate (Original)`}
      titlen={
        isErrored && errorMessage ? [`Error: ${errorMessage}`] : undefined
      }
    >
      <div
        className={`border-custom-blue-300 border-2 m-1 rounded-full relative`}
      >
        <div
          className={`bg-custom-blue-600 border-primary m-0.5 rounded-full h-8 w-8 flex items-center justify-center aspect-square p-2 border-2 tooltip cursor-not-allowed`}
        >
          <p className="text-white text-lg font-extrabold">M</p>
        </div>

        <div
          style={{
            backgroundColor: "gray",
          }}
          className="absolute flex items-center justify-center -right-1 bottom-0 p-0 rounded-full"
        >
          <img
            src={momento_translate_logo}
            className={"h-4 w-4 bg-white rounded-full"}
            alt="momento-logo"
          />
        </div>

        {isErrored ? (
          <div className="bg-red-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdPriorityHigh className="fill-white h-3 w-3" />
          </div>
        ) : isLivestreamingOriginalAudio ? (
          <div className="bg-green-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdCheck className="fill-white h-3 w-3" />
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </Tooltip>
  );
};

const MomentoTranslateBroadcastItem = ({
  name,
  interpretationLanguageCode,
  url,
}: {
  name?: string;
  interpretationLanguageCode?: string;
  url: string;
}) => {
  const { failedRtmpDestinationUrls } = useAppContext();
  const { livestreamingToInterpretationLanguageCodes } = useBroadcastEgresses();

  const { isErrored, errorMessage } = useMemo(() => {
    const { livekitStreamUrl } = getLivekitStreamUrlFromOriginalRtmpUrl({
      url,
    });

    const found = failedRtmpDestinationUrls?.find(
      ({ url: _url }) => _url === livekitStreamUrl || _url === url
    );

    return { isErrored: !!found, errorMessage: found?.error };
  }, [url, failedRtmpDestinationUrls]);

  return (
    <Tooltip
      title={`Momento Translate (${name})`}
      titlen={
        isErrored && errorMessage ? [`Error: ${errorMessage}`] : undefined
      }
    >
      <div
        className={`border-custom-blue-300 border-2 m-1 rounded-full relative`}
      >
        <div
          className={`bg-custom-blue-600 border-primary m-0.5 rounded-full h-8 w-8 flex items-center justify-center aspect-square p-2 border-2 tooltip cursor-not-allowed`}
        >
          <p className="text-white text-lg font-extrabold">M</p>
        </div>

        <div
          style={{
            backgroundColor: "gray",
          }}
          className="absolute flex items-center justify-center -right-1 bottom-0 p-0 rounded-full"
        >
          <img
            src={momento_translate_logo}
            className={"h-4 w-4 bg-white rounded-full"}
            alt="momento-logo"
          />
        </div>

        {isErrored ? (
          <div className="bg-red-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdPriorityHigh className="fill-white h-3 w-3" />
          </div>
        ) : livestreamingToInterpretationLanguageCodes?.includes(
            interpretationLanguageCode as string
          ) ? (
          <div className="bg-green-500 absolute flex items-center justify-center -left-1 bottom-0 p-0.5 rounded-full">
            <MdCheck className="fill-white h-3 w-3" />
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </Tooltip>
  );
};

const MomentoTranslateBroadcastItems = () => {
  const { interpretations } = useAppConfigContext();

  return interpretations.map(({ shortName, name, rtmpKey, rtmpUrl }) => (
    <MomentoTranslateBroadcastItem
      {...{
        key: `MomentoTranslateBroadcastItem_${shortName}`,
        interpretationLanguageCode: shortName,
        name,
        url: createRtmpDestinationUrlFromUrlAndStreamKey({
          streamKey: rtmpKey,
          url: rtmpUrl,
        }),
      }}
    />
  ));
};

const SelectedBroadcastsContainer = () => {
  const { selectedLiveStreamDestinations } = useLiveStreamDestinations();
  const {
    defaultDestination,
    interpretations,
    interpretationsOriginalDestination,
  } = useAppConfigContext();

  return defaultDestination ||
    interpretationsOriginalDestination ||
    interpretations?.length ||
    selectedLiveStreamDestinations.length ? (
    <div className="flex">
      {defaultDestination ? <MomentoLiveBroadcastItem /> : <React.Fragment />}

      {interpretationsOriginalDestination ? (
        <MomentoTranslateOriginalBroadcastItem />
      ) : (
        <React.Fragment />
      )}

      {interpretations?.length ? (
        <MomentoTranslateBroadcastItems />
      ) : (
        <React.Fragment />
      )}

      {selectedLiveStreamDestinations.map(
        ({ id, nickName, isSelected, type, streamKey, url }) => (
          <SelectedBroadcastItem
            key={`selected-broadcasting-destinations-${id}`}
            {...{
              id: `${id}`,
              nickName,
              isSelected,
              type,
              streamKey,
              url,
            }}
          />
        )
      )}
    </div>
  ) : (
    <React.Fragment />
  );
};

export default SelectedBroadcastsContainer;
