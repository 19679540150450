import React, { useEffect, useMemo, useRef, useState } from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import {
  MdExpandLess,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdMic,
  MdMicOff,
  MdMoreVert,
  MdScreenShare,
} from "react-icons/md";
import useMainViewLayout from "../../hooks/appState/useMainViewLayout";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import { useAppContext } from "../../contexts/appContextDef";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import useSwappedParticipants from "../../hooks/conference/useSwappedParticipants";
import useToggleParticipantMedia from "../../hooks/appState/useToggleParticipantMedia";
import { PollControlsMenu } from "../sidePanel/polls/PollListItem";
import { WordcloudControlsMenu } from "../sidePanel/wordclouds/WordcloudsListItem";
import { QNAPanelControlsMenu } from "../sidePanel/QNAPanel";
import { ParticipantWebcamPinContainer } from "./MainViewWebcamStreamContainer";
import CornerDisplayName from "../../components/CornerDisplayName";
import useParticipantName from "../../hooks/appState/useParticipantName";
import useFileShareStream from "../../hooks/streams/useFileShareStream";
import getTransitionStyle from "../../utils/getTransitionStyle";
import { MainViewVideoShareStreamBottomContainer } from "./MainViewVideoShareStreamContainer";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import {
  appModes,
  interactivityModes,
  mainViewLayouts,
} from "../../utils/constants";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
import { MainViewInputFileVideoShareStreamBottomContainer } from "./MainViewInputFileVideoShareStreamContainer";

const defaultDraggingState = {
  isDragging: false,
  draggedStreamId: null,
  draggingOverStreamId: null,
};

export const mainViewHoveredItemDataDefaultValue = {
  isMouseOver: false,
  data: {},
};

const MainViewHoveredItemWebcamStreamMenu = ({
  participantId,
  relativeWidth,
  relativeHeight,
  mainViewContainerWidth,
  isMouseOver,
}: {
  participantId: string;
  relativeWidth: number;
  relativeHeight: number;
  mainViewContainerWidth: number;
  isMouseOver: boolean;
}) => {
  const { appMode, mainViewSelectedStreams } = useAppContext();

  const { micOn } = useParticipantMediaStats(participantId);

  const { changeMainViewLayout, mainViewLayoutActive } = useMainViewLayout();

  const { swapWebcamStreamWithTopStream, removeFromMainViewStreams } =
    useMainViewParticipants();

  const { turnOnMic, turnOffMic } = useToggleParticipantMedia(participantId);
  const { name } = useParticipantName(participantId);

  const micOnRef = useRef(micOn);
  const appModeRef = useRef(appMode);

  useEffect(() => {
    micOnRef.current = micOn;
  }, [micOn]);

  useEffect(() => {
    appModeRef.current = appMode;
  }, [appMode]);

  const isShareStreamActiveInMainView = useMemo(
    () =>
      !![...mainViewSelectedStreams.keys()].find((streamId) =>
        streamId.includes(streamTypes.SHARE)
      ),
    [mainViewSelectedStreams]
  );

  const isSoloLayoutActive = useMemo(() => {
    return mainViewLayoutActive === mainViewLayouts.SOLO;
  }, [mainViewLayoutActive]);

  const _handleExitFromSolo = () => {
    changeMainViewLayout({ layout: mainViewLayouts.GROUP });
  };

  const _handkeSwapToTopAndSwitchToSolo = () => {
    swapWebcamStreamWithTopStream({
      streamId: generateStreamId({ participantId, type: streamTypes.WEBCAM }),
    });

    setTimeout(() => {
      changeMainViewLayout({ layout: mainViewLayouts.SOLO });
    }, 1000);
  };

  const _handleRemoveFromMainView = () => {
    removeFromMainViewStreams({
      participantId,
      type: streamTypes.WEBCAM,
    });
  };

  const _handleToggleMic = () => {
    const micOn = micOnRef.current;

    if (micOn) {
      turnOffMic();
    } else {
      turnOnMic();
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          right:
            (relativeWidth > 50 ? 1 : 0.6) * mainViewContainerWidth * 0.007,
          left: (relativeWidth > 50 ? 1 : 0.6) * mainViewContainerWidth * 0.007,
          bottom:
            (relativeWidth > 50 ? 1 : 0.6) * mainViewContainerWidth * 0.007,
          transition: getTransitionStyle(["bottom", "left", "right"]),
        }}
        className={`absolute flex items-center justify-start`}
      >
        {appMode === appModes.HOST ? (
          <React.Fragment>
            <ParticipantWebcamPinContainer {...{ participantId }} />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        <CornerDisplayName
          {...{
            micOn,
            name,
            relativeWidth,
            relativeHeight,
            mainViewContainerWidth,
            participantId,
          }}
        />
      </div>

      {appMode === appModes.HOST && isMouseOver ? (
        <React.Fragment>
          <div className={`absolute right-3 top-3`}>
            <Menu
              direction={
                mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE
                  ? "top"
                  : "left"
              }
              arrow
              menuButton={
                <span className="top-imp  relative">
                  <button
                    className={`btn btn-primary text-white btn-square btn-sm rounded-md `}
                  >
                    <div>
                      <MdMoreVert size={16} />
                    </div>
                  </button>
                </span>
              }
              transition
            >
              {!isShareStreamActiveInMainView &&
                (isSoloLayoutActive ? (
                  <MenuItem
                    className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                    onClick={_handleExitFromSolo}
                  >
                    <div className="flex items-center justify-center gap-1">
                      <MdScreenShare />
                      Exit Solo Layout
                    </div>
                  </MenuItem>
                ) : (
                  <MenuItem
                    className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                    onClick={_handkeSwapToTopAndSwitchToSolo}
                  >
                    <div className="flex items-center justify-center gap-1">
                      <MdScreenShare />
                      Solo Layout
                    </div>
                  </MenuItem>
                ))}
              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={_handleRemoveFromMainView}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdScreenShare />
                  Remove from stream
                </div>
              </MenuItem>

              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={_handleToggleMic}
              >
                <div className="flex items-center justify-center gap-1">
                  {micOn ? <MdMic /> : <MdMicOff />}
                  {micOn ? (
                    <React.Fragment>Mute</React.Fragment>
                  ) : (
                    <React.Fragment>Unmute</React.Fragment>
                  )}
                </div>
              </MenuItem>
            </Menu>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const MainViewHoveredItemScreenShareStreamMenu = ({
  participantId,
}: {
  participantId: string;
}) => {
  const { changeMainViewLayout, mainViewLayoutActive } = useMainViewLayout();
  const { appMode } = useAppContext();

  const { removeFromMainViewStreams } = useMainViewParticipants();

  const isCinemaLayoutActive = useMemo(
    () => mainViewLayoutActive === mainViewLayouts.CINEMA,
    [mainViewLayoutActive]
  );

  const _handleEnterFullscreenLayout = () => {
    changeMainViewLayout({ layout: mainViewLayouts.CINEMA });
  };
  const _handleExitFullscreenLayout = () => {
    changeMainViewLayout({ layout: mainViewLayouts.SCREEN });
  };

  const _handleRemoveFromMainView = () => {
    removeFromMainViewStreams({
      mode: streamModes.SCREEN,
      participantId,
      type: streamTypes.SHARE,
    });
  };

  return appMode === appModes.HOST ? (
    <React.Fragment>
      <div className={`absolute right-3 top-3`}>
        <Menu
          direction="left"
          arrow
          menuButton={
            <span>
              <button
                className={`btn btn-primary text-white btn-square btn-sm rounded-md btn-active`}
              >
                <div>
                  <MdMoreVert size={16} />
                </div>
              </button>
            </span>
          }
          transition
        >
          {isCinemaLayoutActive ? (
            <MenuItem
              className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
              onClick={_handleExitFullscreenLayout}
            >
              <div className="flex items-center justify-center gap-1">
                <MdScreenShare />
                Exit fullscreen layout
              </div>
            </MenuItem>
          ) : (
            <MenuItem
              className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
              onClick={_handleEnterFullscreenLayout}
            >
              <div className="flex items-center justify-center gap-1">
                <MdScreenShare />
                Fullscreen layout
              </div>
            </MenuItem>
          )}
          <MenuItem
            className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
            onClick={_handleRemoveFromMainView}
          >
            <div className="flex items-center justify-center gap-1">
              <MdScreenShare />
              Remove from stream
            </div>
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

const MainViewHoveredItemFileShareStreamMenu = ({
  participantId,
  fileId,
}: {
  participantId: string;
  fileId: string;
}) => {
  const {
    currentPage,
    gotoNext,
    gotoPrevious,

    isFirstPage,
    isLastPage,
    goto,
    allPagesUrl,
    isOwner,
  } = useFileShareStream({ fileId, imageQuality: "high" });

  const { appMode } = useAppContext();

  const { mainViewLayoutActive, changeMainViewLayout } = useMainViewLayout();
  const { removeFromMainViewStreams } = useMainViewParticipants();

  const isCinemaLayoutActive = useMemo(
    () => mainViewLayoutActive === mainViewLayouts.CINEMA,
    [mainViewLayoutActive]
  );

  const _handleEnterFullscreenLayout = () => {
    changeMainViewLayout({ layout: mainViewLayouts.CINEMA });
  };

  const _handleExitFullscreenLayout = () => {
    changeMainViewLayout({ layout: mainViewLayouts.SCREEN });
  };

  const _handleRemoveFromMainView = () => {
    removeFromMainViewStreams({
      mode: streamModes.PDF,
      participantId,
      type: streamTypes.SHARE,
      fileId,
    });
  };

  const { isLocal } = useIsLocalParticipantId(participantId);

  return (
    <React.Fragment>
      {appMode === appModes.HOST ? (
        <React.Fragment>
          <div className={`absolute right-3 top-3`}>
            <Menu
              direction="left"
              arrow
              menuButton={
                <button className="btn btn-primary text-white">
                  <MdMoreVert />
                </button>
              }
              transition
            >
              {isCinemaLayoutActive ? (
                <MenuItem
                  className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                  onClick={_handleExitFullscreenLayout}
                >
                  <div className="flex items-center justify-center gap-1">
                    <MdScreenShare />
                    Exit fullscreen layout
                  </div>
                </MenuItem>
              ) : (
                <MenuItem
                  className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                  onClick={_handleEnterFullscreenLayout}
                >
                  <div className="flex items-center justify-center gap-1">
                    <MdScreenShare />
                    Fullscreen layout
                  </div>
                </MenuItem>
              )}
              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={_handleRemoveFromMainView}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdScreenShare />
                  Remove from stream
                </div>
              </MenuItem>
            </Menu>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}

      {appMode === appModes.HOST || isLocal || isOwner ? (
        <React.Fragment>
          <div
            style={{ top: "25%" }}
            className={`absolute overflow-hidden left-0 right-0 bottom-3 flex items-end justify-center`}
          >
            <div className="bg-primary bg-opacity-50 flex items-center justify-center p-2 rounded-md">
              <Menu
                overflow={"auto"}
                position={"auto"}
                direction="left"
                menuButton={
                  <button
                    className={`btn btn-sm btn-primary text-white rounded-md cursor-pointer flex gap-2`}
                  >
                    <MdExpandLess />
                    <p className="normal-case">Slide {currentPage}</p>
                  </button>
                }
                transition
              >
                {allPagesUrl?.map((url, _i) => {
                  const i = _i + 1;

                  const isSelected = i === currentPage;

                  return (
                    <MenuItem
                      checked={isSelected}
                      style={{ padding: 0 }}
                      className={"hover:bg-custom-blue-50"}
                      onClick={() => {
                        goto(i);
                      }}
                      value={i}
                      key={`file-stream-inqueue-${fileId}-${i}`}
                    >
                      <div className="text-primary p-0.5 flex flex-1 items-center justify-center w-full h-full">
                        <img
                          className="h-20 object-cover"
                          src={url}
                          alt={`file-stream-inqueue-${fileId}-${i}`}
                        />
                        <div className="flex flex-1 p-1 text-sm">
                          <p>{`Slide ${i}`}</p>
                        </div>
                        {isSelected ? <span>✓</span> : <React.Fragment />}
                      </div>
                    </MenuItem>
                  );
                })}
              </Menu>

              <div className="flex ml-3">
                <div>
                  <button
                    disabled={isFirstPage}
                    className={`btn btn-primary text-white btn-circle btn-xs ${
                      isFirstPage ? "opacity-0" : ""
                    }`}
                    onClick={() => gotoPrevious()}
                  >
                    <MdKeyboardArrowLeft size={16} />
                  </button>
                </div>
                <div className="ml-1">
                  <button
                    disabled={isLastPage}
                    className={`btn btn-primary text-white btn-circle btn-xs ${
                      isLastPage ? "opacity-0" : ""
                    }`}
                    onClick={() => gotoNext()}
                  >
                    <MdKeyboardArrowRight size={16} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const MainViewHoveredItemVideoShareStreamMenu = ({
  participantId,
  videoId,
}: {
  participantId: string;
  videoId: string;
}) => {
  const { changeMainViewLayout, mainViewLayoutActive } = useMainViewLayout();
  const { removeFromMainViewStreams } = useMainViewParticipants();
  const { appMode } = useAppContext();
  const isCinemaLayoutActive = useMemo(
    () => mainViewLayoutActive === mainViewLayouts.CINEMA,
    [mainViewLayoutActive]
  );

  const _handleEnterFullscreenLayout = () => {
    changeMainViewLayout({ layout: mainViewLayouts.CINEMA });
  };
  const _handleExitFullscreenLayout = () => {
    changeMainViewLayout({ layout: mainViewLayouts.SCREEN });
  };

  const _handleRemoveFromMainView = () => {
    removeFromMainViewStreams({
      mode: streamModes.VIDEO,
      participantId,
      type: streamTypes.SHARE,
      videoId,
    });
  };

  const { isLocal } = useIsLocalParticipantId(participantId);

  return (
    <React.Fragment>
      {appMode === appModes.HOST ? (
        <React.Fragment>
          <div className={`absolute right-3 top-3`}>
            <Menu
              direction="left"
              arrow
              menuButton={
                <span>
                  <button
                    className={`btn btn-primary text-white btn-square btn-sm rounded-md`}
                  >
                    <div>
                      <MdMoreVert size={16} />
                    </div>
                  </button>
                </span>
              }
              transition
            >
              {isCinemaLayoutActive ? (
                <MenuItem
                  className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                  onClick={_handleExitFullscreenLayout}
                >
                  <div className="flex items-center justify-center gap-1">
                    <MdScreenShare />
                    Exit fullscreen layout
                  </div>
                </MenuItem>
              ) : (
                <MenuItem
                  className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                  onClick={_handleEnterFullscreenLayout}
                >
                  <div className="flex items-center justify-center gap-1">
                    <MdScreenShare />
                    Fullscreen layout
                  </div>
                </MenuItem>
              )}
              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={_handleRemoveFromMainView}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdScreenShare />
                  Remove from stream
                </div>
              </MenuItem>
            </Menu>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}

      {(appMode === appModes.HOST || isLocal) && (
        <div className="absolute bottom-3 left-0 right-0">
          <MainViewVideoShareStreamBottomContainer videoId={videoId} />
        </div>
      )}
    </React.Fragment>
  );
};

const MainViewHoveredItemAppPollShareStreamMenu = ({
  pollId,
}: {
  pollId: string;
}) => {
  const { appMode } = useAppContext();

  return appMode === appModes.HOST ? (
    <React.Fragment>
      <div className={`absolute right-3 top-3`}>
        <PollControlsMenu {...{ id: pollId }} />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

const MainViewHoveredItemAppWordcloudShareStreamMenu = ({
  wordcloudId,
}: {
  wordcloudId: string;
}) => {
  const { appMode } = useAppContext();
  return appMode === appModes.HOST ? (
    <React.Fragment>
      <div className={`absolute right-3 top-3`}>
        <WordcloudControlsMenu {...{ id: wordcloudId }} />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

const MainViewHoveredItemQNAShareStreamMenu = () => {
  const { appMode } = useAppContext();

  return appMode === appModes.HOST ? (
    <React.Fragment>
      <div className={`absolute right-3 top-3`}>
        <QNAPanelControlsMenu />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};

const MainViewHoveredItemInputFileVideoShareStreamMenu = ({
  participantId,
  inputFileVideoId,
}: {
  participantId: string;
  inputFileVideoId: string;
}) => {
  const { changeMainViewLayout, mainViewLayoutActive } = useMainViewLayout();
  const { removeFromMainViewStreams } = useMainViewParticipants();
  const { appMode } = useAppContext();
  const isCinemaLayoutActive = useMemo(
    () => mainViewLayoutActive === mainViewLayouts.CINEMA,
    [mainViewLayoutActive]
  );

  const _handleEnterFullscreenLayout = () => {
    changeMainViewLayout({ layout: mainViewLayouts.CINEMA });
  };
  const _handleExitFullscreenLayout = () => {
    changeMainViewLayout({ layout: mainViewLayouts.SCREEN });
  };

  const _handleRemoveFromMainView = () => {
    removeFromMainViewStreams({
      mode: streamModes.INPUTFILEVIDYO,
      participantId,
      type: streamTypes.SHARE,
      inputFileVideoId,
    });
  };

  const { isLocal } = useIsLocalParticipantId(participantId);

  return (
    <React.Fragment>
      {appMode === appModes.HOST ? (
        <React.Fragment>
          <div className={`absolute right-3 top-3`}>
            <Menu
              direction="left"
              arrow
              menuButton={
                <span>
                  <button
                    className={`btn btn-primary text-white btn-square btn-sm rounded-md`}
                  >
                    <div>
                      <MdMoreVert size={16} />
                    </div>
                  </button>
                </span>
              }
              transition
            >
              {isCinemaLayoutActive ? (
                <MenuItem
                  className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                  onClick={_handleExitFullscreenLayout}
                >
                  <div className="flex items-center justify-center gap-1">
                    <MdScreenShare />
                    Exit fullscreen layout
                  </div>
                </MenuItem>
              ) : (
                <MenuItem
                  className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                  onClick={_handleEnterFullscreenLayout}
                >
                  <div className="flex items-center justify-center gap-1">
                    <MdScreenShare />
                    Fullscreen layout
                  </div>
                </MenuItem>
              )}
              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={_handleRemoveFromMainView}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdScreenShare />
                  Remove from stream
                </div>
              </MenuItem>
            </Menu>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}

      {(appMode === appModes.HOST || isLocal) && (
        <div className="absolute bottom-3 left-0 right-0">
          <MainViewInputFileVideoShareStreamBottomContainer
            inputFileVideoId={inputFileVideoId}
          />
        </div>
      )}
    </React.Fragment>
  );
};

const MainViewHoveredItemExtraWebcamStreamMenu = ({
  participantId,
  extraWebcamId,
  relativeWidth,
  relativeHeight,
  mainViewContainerWidth,
  isMouseOver,
}: {
  participantId: string;
  extraWebcamId: string;
  relativeWidth: number;
  relativeHeight: number;
  mainViewContainerWidth: number;
  isMouseOver: boolean;
}) => {
  const { appMode } = useAppContext();

  const { removeFromMainViewStreams } = useMainViewParticipants();

  const { micOn } = useParticipantMediaStats(participantId);
  const { name } = useParticipantName(participantId);

  const _handleRemoveFromMainView = () => {
    removeFromMainViewStreams({
      participantId,
      type: streamTypes.EXTRAWBCM,
      extraWebcamId,
    });
  };

  return (
    <React.Fragment>
      <div
        style={{
          right:
            (relativeWidth > 50 ? 1 : 0.6) * mainViewContainerWidth * 0.007,
          left: (relativeWidth > 50 ? 1 : 0.6) * mainViewContainerWidth * 0.007,
          bottom:
            (relativeWidth > 50 ? 1 : 0.6) * mainViewContainerWidth * 0.007,
          transition: getTransitionStyle(["bottom", "left", "right"]),
        }}
        className={`absolute flex items-center justify-start`}
      >
        <CornerDisplayName
          {...{
            micOn,
            name,
            relativeWidth,
            relativeHeight,
            mainViewContainerWidth,
            participantId,
          }}
        />
      </div>

      {appMode === appModes.HOST && isMouseOver ? (
        <React.Fragment>
          <div className={`absolute right-3 top-3`}>
            <Menu
              direction="left"
              arrow
              menuButton={
                <span>
                  <button
                    className={`btn btn-primary text-white btn-square btn-sm rounded-md`}
                  >
                    <div>
                      <MdMoreVert size={16} />
                    </div>
                  </button>
                </span>
              }
              transition
            >
              <MenuItem
                className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
                onClick={_handleRemoveFromMainView}
              >
                <div className="flex items-center justify-center gap-1">
                  <MdScreenShare />
                  Remove from stream
                </div>
              </MenuItem>
            </Menu>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const MainViewHoveredItem = ({
  streamIdMeta,
  streamId,
  relativeTop,
  relativeLeft,
  relativeHeight,
  relativeWidth,
  draggingState,
  setDraggingState,
  mainViewContainerWidth,
  itemsVisible,
  itemsDraggable,
}: {
  streamIdMeta: streamIdMetaType;
  streamId: string;
  relativeTop: number;
  relativeLeft: number;
  relativeHeight: number;
  relativeWidth: number;
  draggingState: {
    isDragging: boolean;
    draggedStreamId: null | string | undefined;
    draggingOverStreamId: null | string | undefined;
  };
  setDraggingState: React.Dispatch<
    React.SetStateAction<{
      isDragging: boolean;
      draggedStreamId: null | string | undefined;
      draggingOverStreamId: null | string | undefined;
    }>
  >;
  mainViewContainerWidth: number;
  itemsVisible: boolean;
  itemsDraggable: boolean;
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => setMounted(true), 50);

    return () => {
      setTimeout(() => setMounted(false), 50);
    };
  }, []);

  const { appMode, interactivityMode, conferenceMainViewVisibleWebcamStreams } =
    useAppContext();

  const { swapMainViewStreams } = useMainViewParticipants();
  const { swap: swapConferenceStreams } = useSwappedParticipants();
  const { mainViewLayoutActive } = useMainViewLayout();

  const { isDraggingOver, isDraggingOverSelf } = useMemo(() => {
    const isDraggingOver = draggingState.draggingOverStreamId === streamId;
    const isDraggingOverSelf = draggingState.draggedStreamId === streamId;

    return { isDraggingOver, isDraggingOverSelf };
  }, [draggingState, streamId]);

  const interactivityModeRef = useRef(interactivityMode);
  const conferenceMainViewVisibleWebcamStreamsRef = useRef(
    conferenceMainViewVisibleWebcamStreams
  );
  const draggingStateRef = useRef(draggingState);

  useEffect(() => {
    interactivityModeRef.current = interactivityMode;
  }, [interactivityMode]);
  useEffect(() => {
    draggingStateRef.current = draggingState;
  }, [draggingState]);
  useEffect(() => {
    conferenceMainViewVisibleWebcamStreamsRef.current =
      conferenceMainViewVisibleWebcamStreams;
  }, [conferenceMainViewVisibleWebcamStreams]);

  const [isMouseOver, setIsMouseOver] = useState(false);

  const {
    isWebcamStream,
    isVideoShareStream,
    isScreenShareStream,
    isFileShareStream,
    fileId,
    videoId,
    shareMode,
    participantId,
    isShareStream,
    isAppPollShareStream,
    isAppWordcloudShareStream,
    isQNAShareStream,
    pollId,
    wordcloudId,
    qnaId,
    inputFileVideoId,
    isInputFileVideoShareStream,
    extraWebcamId,
    isExtraWebcamStream,
    rtmpInId,
    isRtmpInShareStream,
  } = useMemo(() => streamIdMeta, [streamIdMeta]);

  const _handleSwapConferenceWebcamStreams = ({
    streamId1,
    streamId2,
  }: {
    streamId1: string;
    streamId2: string;
  }) => {
    if (!streamId1 || !streamId2) return;

    if (
      streamId1.includes(`_${streamTypes.SHARE}_`) ||
      streamId2.includes(`_${streamTypes.SHARE}_`)
    )
      return;

    const participantId1 = streamId1.split("_")[0];
    const participantId2 = streamId2.split("_")[0];

    const index1 = conferenceMainViewVisibleWebcamStreamsRef.current.findIndex(
      ({ participantId: _participantId }) => _participantId === participantId1
    );
    const index2 = conferenceMainViewVisibleWebcamStreamsRef.current.findIndex(
      ({ participantId: _participantId }) => _participantId === participantId2
    );

    swapConferenceStreams({ index1, index2, participantId1, participantId2 });
  };

  const { shouldMaintainVideoAspectRatio } = useMemo(
    () => ({
      shouldMaintainVideoAspectRatio:
        !isShareStream &&
        (mainViewLayoutActive === mainViewLayouts.CROPPED
          ? false
          : mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE
          ? false
          : true),
    }),
    [mainViewLayoutActive, isShareStream]
  );

  const mouseDownOffsetRef = useRef<{ offsetX?: number; offsetY?: number }>();

  const { Component, props } = useMemo(() => {
    const { Component, props } = isWebcamStream
      ? {
          Component: MainViewHoveredItemWebcamStreamMenu,
          props: {
            participantId,
            streamId,
            relativeWidth,
            relativeHeight,
            mainViewContainerWidth,
            isMouseOver,
          },
        }
      : isScreenShareStream
      ? isMouseOver
        ? {
            Component: MainViewHoveredItemScreenShareStreamMenu,
            props: { participantId },
          }
        : { Component: React.Fragment, props: {} }
      : isFileShareStream
      ? isMouseOver
        ? {
            Component: MainViewHoveredItemFileShareStreamMenu,
            props: {
              participantId,
              fileId,
              mode: shareMode,
            },
          }
        : { Component: React.Fragment, props: {} }
      : isVideoShareStream
      ? isMouseOver
        ? {
            Component: MainViewHoveredItemVideoShareStreamMenu,
            props: { participantId, videoId },
          }
        : { Component: React.Fragment, props: {} }
      : isAppPollShareStream
      ? isMouseOver
        ? {
            Component: MainViewHoveredItemAppPollShareStreamMenu,
            props: { pollId },
          }
        : { Component: React.Fragment, props: {} }
      : isAppWordcloudShareStream
      ? isMouseOver
        ? {
            Component: MainViewHoveredItemAppWordcloudShareStreamMenu,
            props: { wordcloudId },
          }
        : { Component: React.Fragment, props: {} }
      : isQNAShareStream
      ? isMouseOver
        ? {
            Component: MainViewHoveredItemQNAShareStreamMenu,
            props: { qnaId },
          }
        : { Component: React.Fragment, props: {} }
      : isInputFileVideoShareStream
      ? isMouseOver
        ? {
            Component: MainViewHoveredItemInputFileVideoShareStreamMenu,
            props: { participantId, inputFileVideoId },
          }
        : { Component: React.Fragment, props: {} }
      : isExtraWebcamStream
      ? {
          Component: MainViewHoveredItemExtraWebcamStreamMenu,
          props: {
            participantId,
            extraWebcamId,
            relativeWidth,
            relativeHeight,
            mainViewContainerWidth,
            isMouseOver,
          },
        }
      : isRtmpInShareStream
      ? isMouseOver
        ? { Component: React.Fragment, props: { rtmpInId } }
        : { Component: React.Fragment, props: {} }
      : { Component: React.Fragment, props: {} };

    return {
      Component: Component as React.ElementType,
      props: props as
        | {
            participantId: string;
            streamId: string;
            relativeWidth: number;
            relativeHeight: number;
            mainViewContainerWidth: number;
            isMouseOver: boolean;
          }
        | { participantId: string }
        | { participantId: string; videoId: string }
        | { wordcloudId: string }
        | { qnaId: string }
        | { participantId: string; inputFileVideoId: string }
        | { participantId: string; extraWebcamId: string }
        | { rtmpInId: string }
        | object,
    };
  }, [
    isWebcamStream,
    isScreenShareStream,
    isFileShareStream,
    isVideoShareStream,
    isAppPollShareStream,
    isAppWordcloudShareStream,
    isQNAShareStream,
    isInputFileVideoShareStream,
    participantId,
    streamId,
    relativeHeight,
    relativeWidth,
    mainViewContainerWidth,
    fileId,
    shareMode,
    videoId,
    pollId,
    wordcloudId,
    qnaId,
    inputFileVideoId,
    isMouseOver,
    extraWebcamId,
    isExtraWebcamStream,
    rtmpInId,
    isRtmpInShareStream,
  ]);

  const streamParentContainerEdgeStyle = useMemo(() => {
    const edge = isShareStream
      ? 0
      : isWebcamStream || isExtraWebcamStream
      ? mainViewLayoutActive === mainViewLayouts.CROPPED
        ? `0.5%`
        : mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE
        ? "0.5%"
        : "1.5%"
      : undefined;

    return {
      bottom: edge,
      left: edge,
      top: edge,
      right: edge,
      transition: getTransitionStyle(["bottom", "left", "top", "right"]),
    };
  }, [
    isShareStream,
    isExtraWebcamStream,
    isWebcamStream,
    mainViewLayoutActive,
  ]);

  const streamGrandParentContainerStyle = useMemo(
    () => ({
      top:
        shouldMaintainVideoAspectRatio &&
        (isExtraWebcamStream || isWebcamStream)
          ? "50%"
          : undefined,
      transform:
        shouldMaintainVideoAspectRatio &&
        (isExtraWebcamStream || isWebcamStream)
          ? "translate(0%, -50%)"
          : undefined,
      transition: getTransitionStyle([
        "transform",
        "top",
        "right",
        "left",
        "bottom",
        "aspect-ratio",
        "height",
        "padding-top",
      ]),
    }),
    [shouldMaintainVideoAspectRatio, isExtraWebcamStream, isWebcamStream]
  );

  const { streamGrandParentContainerClassName } = useMemo(() => {
    const aspectVideoClassName = "aspect-video";

    const streamGrandParentContainerClassName = `absolute ${
      isShareStream
        ? "top-0 bottom-0 right-0 left-0"
        : mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE &&
          relativeWidth > relativeHeight &&
          (isExtraWebcamStream || isWebcamStream)
        ? `top-0 bottom-0 ${aspectVideoClassName}`
        : shouldMaintainVideoAspectRatio
        ? `${aspectVideoClassName} right-0 left-0`
        : "top-0 bottom-0 right-0 left-0"
    }`;

    const streamGrandParentContainerAspectVideoStyles =
      streamGrandParentContainerClassName.includes(aspectVideoClassName)
        ? { height: 0, paddingTop: `56.25%`, overflow: "hidden" }
        : { height: "100%", paddingTop: 0, overflow: undefined };

    return {
      streamGrandParentContainerAspectVideoStyles,
      streamGrandParentContainerClassName: streamGrandParentContainerClassName,
    };
  }, [
    isShareStream,
    mainViewLayoutActive,
    relativeWidth,
    relativeHeight,
    isWebcamStream,
    isExtraWebcamStream,
    shouldMaintainVideoAspectRatio,
  ]);

  const draggable = useMemo(
    () =>
      appMode === appModes.HOST &&
      (isExtraWebcamStream || isWebcamStream) &&
      itemsDraggable,
    [appMode, isWebcamStream, isExtraWebcamStream, itemsDraggable]
  );

  return (
    <div
      onMouseDown={(e) => {
        const { offsetX, offsetY } = e.nativeEvent;

        mouseDownOffsetRef.current = { offsetX, offsetY };
      }}
      onMouseEnter={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
      draggable={draggable}
      onDragEnter={(e) => {
        e.preventDefault();
      }}
      onDrag={(e) => {
        e.preventDefault();
      }}
      onDragStart={(e) => {
        const el = document.getElementById(`MVPCD-${streamId}`);

        if (el) {
          e.dataTransfer.setDragImage(
            el,
            mouseDownOffsetRef.current?.offsetX || 0,
            mouseDownOffsetRef.current?.offsetY || 0
          );
        }

        setDraggingState(({ draggingOverStreamId }) => ({
          isDragging: true,
          draggedStreamId: streamId as string,
          draggingOverStreamId: draggingOverStreamId as string,
        }));
      }}
      onDragOver={(e) => {
        e.preventDefault();

        e.dataTransfer.dropEffect = "move";

        if (isWebcamStream || isExtraWebcamStream) {
          setDraggingState(({ isDragging, draggedStreamId }) => ({
            isDragging,
            draggedStreamId,
            draggingOverStreamId: streamId,
          }));
        }
      }}
      onDragEnd={() => {
        const { draggingOverStreamId, draggedStreamId } =
          draggingStateRef.current;

        if (interactivityModeRef.current === interactivityModes.CONFERENCE) {
          if (draggingOverStreamId && draggedStreamId) {
            _handleSwapConferenceWebcamStreams({
              streamId1: draggingOverStreamId,
              streamId2: draggedStreamId,
            });
          }
        } else if (interactivityModeRef.current === interactivityModes.STUDIO) {
          if (draggingOverStreamId && draggedStreamId) {
            swapMainViewStreams({
              streamId1: draggingOverStreamId,
              streamId2: draggedStreamId,
            });
          }
        }

        setDraggingState(defaultDraggingState);
      }}
      className={`absolute`}
      style={{
        height: `${relativeHeight}%`,
        left: `${relativeLeft}%`,
        top: `${relativeTop}%`,
        width: `${relativeWidth}%`,
        transform: `scale(${mounted ? 1 : 0})`,
        opacity: itemsVisible ? 1 : 0,
        transition: getTransitionStyle([
          "height",
          "left",
          "top",
          "width",
          "transform",
          "opacity",
        ]),
        cursor: "unset",
      }}
    >
      <div className={`h-full relative w-full`}>
        <div
          style={{
            ...streamGrandParentContainerStyle,
          }}
          className={streamGrandParentContainerClassName}
        >
          <div
            style={streamParentContainerEdgeStyle}
            className={`absolute top-0 bottom-0 left-0 right-0 absolute transition-all`}
          >
            <Component {...props} />
            {!isDraggingOverSelf && isDraggingOver && (
              <div className="p-3 absolute top-0 bottom-0 left-0 right-0 border-4 flex items-center justify-center bg-black bg-opacity-50">
                <p className="text-white text-3xl font-semibold">
                  Drop here to swap
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MainViewHoveredItems = ({
  gridContainerStyles,
  mainViewContainerWidth,
}: {
  gridContainerStyles?: React.CSSProperties;
  mainViewContainerWidth: number;
}) => {
  const { mainViewVideoClip } = useAppContext();
  const [draggingState, setDraggingState] = useState<{
    isDragging: boolean;
    draggedStreamId: null | string | undefined;
    draggingOverStreamId: null | string | undefined;
  }>(defaultDraggingState);

  const { gridWithStreamsPosition } = useMainViewLayout();

  const { itemsVisible, itemsDraggable } = useMemo(() => {
    return {
      itemsVisible: !mainViewVideoClip.remoteUrl,
      itemsDraggable: !mainViewVideoClip.remoteUrl,
    };
  }, [mainViewVideoClip]);

  const shareOnTopGridWithStreamsPosition = useMemo(() => {
    const _gridWithStreamsPosition = [...gridWithStreamsPosition];

    const index = _gridWithStreamsPosition.findIndex(({ streamId }) =>
      streamId.includes(streamTypes.SHARE)
    );

    if (index !== -1) {
      const stream = _gridWithStreamsPosition[index];
      _gridWithStreamsPosition.splice(index);
      _gridWithStreamsPosition.push(stream);
    }

    return _gridWithStreamsPosition;
  }, [gridWithStreamsPosition]);

  return (
    <div
      onMouseEnter={() => {
        appEventEmitter.emit(
          appEventEmitterEvents.MAIN_VIDEO_CONTAINER_MOUSE_OVER,
          { isMouseOver: true }
        );
      }}
      onMouseLeave={() => {
        appEventEmitter.emit(
          appEventEmitterEvents.MAIN_VIDEO_CONTAINER_MOUSE_OVER,
          { isMouseOver: false }
        );
      }}
      className="h-full w-full relative"
    >
      <div style={gridContainerStyles}>
        {shareOnTopGridWithStreamsPosition.map((gridItem) => (
          <MainViewHoveredItem
            {...{
              key: `MVPHC-${gridItem.streamId}`,
              draggingState,
              setDraggingState,
              mainViewContainerWidth,
              itemsVisible,
              itemsDraggable,
              ...gridItem,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default MainViewHoveredItems;
