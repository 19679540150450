import { useState } from "react";
import { MdAdd, MdClose } from "react-icons/md";
import useBanners from "../../../../hooks/banners/useBanners";
import { bannerTypes } from "../../../../listners/banners/ActiveBannerListner";
import { quickstartTargetComponentClassNames } from "../../../quickStartTourContainer/QuickStartTourContainer";

const CreateNewBanner = ({ folderId }: { folderId: number | string }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [isTicker, setIsTicker] = useState(false);

  const [primaryText, setPrimaryText] = useState("");
  const [secondaryText, setSecondaryText] = useState("");

  const { createBanner } = useBanners();

  return (
    <span className={quickstartTargetComponentClassNames.create_banners}>
      {isCreating ? (
        <div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Primary text</span>
            </label>
            <input
              placeholder="Enter a primary text..."
              className="input input-bordered w-full"
              value={primaryText}
              onChange={(e) => {
                setPrimaryText(e.target.value);
              }}
            />
            {/* <label className="label">
          <span className="label-text-alt">Bottom Right label</span>
        </label> */}
          </div>

          {isTicker ? null : (
            <div className="form-control w-full mt-2">
              <label className="label">
                <span className="label-text">Secondary text</span>
              </label>
              <input
                placeholder="Enter a secondary text..."
                className="input input-bordered w-full"
                value={secondaryText}
                onChange={(e) => {
                  setSecondaryText(e.target.value);
                }}
              />
              {/* <label className="label">
            <span className="label-text-alt">Bottom Right label</span>
          </label> */}
            </div>
          )}

          <div className="form-control mt-2">
            <label className="label cursor-pointer">
              <span className="label-text">Scroll across bottom (ticker)</span>
              <input
                type="checkbox"
                checked={isTicker}
                onChange={() => {
                  setIsTicker((s) => !s);
                }}
                className="checkbox checkbox-primary"
              />
            </label>
          </div>

          <div className="flex items-center mt-3">
            <button
              onClick={() => {
                createBanner({
                  folderId,
                  position: "left",
                  primaryText,
                  secondaryText: isTicker ? undefined : secondaryText,
                  type: isTicker ? bannerTypes.ticker : bannerTypes.lowerThird,
                });

                setIsCreating(false);
                setIsTicker(false);
                setPrimaryText("");
                setSecondaryText("");
              }}
              className="btn btn-primary btn-sm text-white"
            >
              Add banner
            </button>

            <button
              onClick={() => {
                setIsCreating(false);
                setIsTicker(false);
                setPrimaryText("");
                setSecondaryText("");
              }}
              className="btn btn-ghost btn-sm ml-3"
            >
              <MdClose />
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={() => {
            setIsCreating(true);
          }}
          className="btn btn-ghost btn-sm"
        >
          <MdAdd />
          <p className="ml-1">Create a banner</p>
        </button>
      )}
    </span>
  );
};

export default CreateNewBanner;
