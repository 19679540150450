import { useEffect, useMemo, useRef } from "react";
import activateWordcloud from "../../../apis/apps/wordclouds/activate-wordcloud";
import answerWordcloud from "../../../apis/apps/wordclouds/answer-wordcloud";
import deactivateWordcloud from "../../../apis/apps/wordclouds/deactivate-wordcloud";
import deleteWordcloud from "../../../apis/apps/wordclouds/delete-wordcloud";
import { useAppContext } from "../../../contexts/appContextDef";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import useMainViewParticipants from "../../appState/useMainViewParticipants";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../../listners/appState/MainViewParticipantsListner";
import clearWordcloud from "../../../apis/apps/wordclouds/clear-wordcloud";
import useValidateHostCanTakeAction from "../../appState/useValidateHostCanTakeAction";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";

const useWordcloud = ({ id }: { id: string }) => {
  const { wordclouds, setWordclouds, mainViewSelectedStreams } =
    useAppContext();

  const { broadcastId } = useAppConfigContext();
  const participantId = "wordcloudAddParticipantId";

  const wordcloud = useMemo(
    () => wordclouds.find(({ id: _id }) => `${_id}` === `${id}`),
    [wordclouds, id]
  );

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const { isInMainView, isActive, selfSubmittedAnswer, answers, question } =
    useMemo(() => {
      const streamId = generateStreamId({
        participantId,
        type: streamTypes.SHARE,
        mode: streamModes.APPWORDCLOUD,
        wordcloudId: id,
      });

      const isInMainView = !!mainViewSelectedStreams.get(streamId);

      const isActive = `${wordcloud?.status}` === "1";

      const question = wordcloud?.question;
      const selfSubmittedAnswer = wordcloud?.selfSubmittedAnswer;
      const answers = wordcloud?.answers;

      return { isInMainView, isActive, selfSubmittedAnswer, answers, question };
    }, [id, participantId, mainViewSelectedStreams, wordcloud]);

  const isInMainViewRef = useRef(isInMainView);
  const isActiveRef = useRef(isActive);
  const selfSubmittedAnswerRef = useRef(selfSubmittedAnswer);
  const idRef = useRef(id);
  const questionRef = useRef(question);

  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);
  useEffect(() => {
    isActiveRef.current = isActive;
  }, [isActive]);
  useEffect(() => {
    selfSubmittedAnswerRef.current = selfSubmittedAnswer;
  }, [selfSubmittedAnswer]);
  useEffect(() => {
    idRef.current = id;
  }, [id]);
  useEffect(() => {
    questionRef.current = question;
  }, [question]);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const _answerWordcloud = async ({ answer }: { answer: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (isActiveRef.current && !selfSubmittedAnswerRef.current) {
      await answerWordcloud({ broadcastId, answer, wordcloudId: id });

      setTimeout(() => {
        setWordclouds((s) => {
          const index = s.findIndex(({ id: _id }) => `${_id}` === `${id}`);

          s[index] = { ...s[index], selfSubmittedAnswer: answer };

          return [...s];
        });
      }, 300);
    }
  };

  const _addToMainViewStreams = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (!isInMainViewRef.current) {
      addToMainViewStreams({
        mode: streamModes.APPWORDCLOUD,
        participantId,
        type: streamTypes.SHARE,
        wordcloudId: id,
      });
    }
  };

  const _removeFromMainViewStreams = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (isInMainViewRef.current) {
      removeFromMainViewStreams({
        mode: streamModes.APPWORDCLOUD,
        participantId,
        type: streamTypes.SHARE,
        wordcloudId: id,
      });
    }
  };

  const _activateWordcloud = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (!isActiveRef.current) {
      await activateWordcloud({ broadcastId, wordcloudId: idRef.current });
    }
  };

  const _deactivateWordcloud = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (isActiveRef.current) {
      await deactivateWordcloud({ broadcastId, wordcloudId: idRef.current });
    }
  };

  const _deleteWordcloud = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    await _removeFromMainViewStreams();

    await deleteWordcloud({ broadcastId, wordcloudId: idRef.current });
  };

  const _clearWordcloud = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    await clearWordcloud({ broadcastId, wordcloudId: idRef.current });

    appEventEmitter.emit(appEventEmitterEvents.COPY_WORDCLOUD_AND_DELETE_OLD, {
      oldPollId: idRef.current,
      question: questionRef.current,
      isActive: isActiveRef.current,
      isInMainView: isInMainViewRef.current,
    });
  };

  const toggleActive = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (isActiveRef.current) {
      _deactivateWordcloud();
    } else {
      _activateWordcloud();
    }
  };

  const toggleMainViewStreams = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (isInMainViewRef.current) {
      _removeFromMainViewStreams();
    } else {
      _addToMainViewStreams();
    }
  };

  return {
    question,
    answers,
    selfSubmittedAnswer,
    isActive,
    isInMainView,
    toggleActive,
    toggleMainViewStreams,
    deleteWordcloud: _deleteWordcloud,
    answerWordcloud: _answerWordcloud,
    activateWordcloud: _activateWordcloud,
    deactivateWordcloud: _deactivateWordcloud,
    addToMainViewStreams: _addToMainViewStreams,
    clearWordcloud: _clearWordcloud,
    removeFromMainViewStreams: _removeFromMainViewStreams,
  };
};

export default useWordcloud;
