const PermissionsBlockedChrome = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path fill="#fff" d="M0 0h1080v1080H0z" />
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M120.5 942.36V210.83a72.326 72.326 0 0 1 21.178-51.144A72.319 72.319 0 0 1 192.82 138.5h767.26"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M120.5 942.36V210.83a72.326 72.326 0 0 1 21.178-51.144A72.319 72.319 0 0 1 192.82 138.5h767.26"
      />
      <path
        fill="#fff"
        d="M960.08 256.93h-110a23.43 23.43 0 0 1-23.43-23.43v-71.56a23.428 23.428 0 0 0-6.859-16.574 23.435 23.435 0 0 0-16.571-6.866H536.29a23.435 23.435 0 0 0-16.571 6.866 23.43 23.43 0 0 0-6.859 16.574v71.56a23.432 23.432 0 0 1-23.43 23.43H120.5v137.62h839.58"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M960.08 256.93h-110a23.43 23.43 0 0 1-23.43-23.43v-71.56a23.428 23.428 0 0 0-6.859-16.574 23.435 23.435 0 0 0-16.571-6.866H536.29a23.435 23.435 0 0 0-16.571 6.866 23.43 23.43 0 0 0-6.859 16.574v71.56a23.432 23.432 0 0 1-23.43 23.43H120.5v137.62h839.58"
      />
      <path
        fill="#FF8669"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M210.81 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86Z"
      />
      <path
        fill="#FFB929"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M274.32 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86Z"
      />
      <path
        fill="#6DD58C"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M337.84 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86Z"
      />
      <path
        fill="#fff"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52Z"
      />
      <path
        fill="#fff"
        d="M460.21 353.89a38.369 38.369 0 0 1-64.265-31.835 38.364 38.364 0 0 1 28.046-33.32 38.368 38.368 0 0 1 41.119 14.355"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M460.21 353.89a38.369 38.369 0 0 1-64.265-31.835 38.364 38.364 0 0 1 28.046-33.32 38.368 38.368 0 0 1 41.119 14.355"
      />
      <path
        fill="#191C1E"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m470.57 298.9 2.52 16.75-15.95-5.72 13.43-11.03Z"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M236.18 329.44h-63.69m29.13 29.13-29.13-29.13 29.13-29.12m72.7 29.12h63.69m-29.13 29.13 29.13-29.13-29.13-29.12"
      />
      <path
        fill="#C2E7FF"
        stroke="#C2E7FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M679.55 562.59H514.16c-10.598 0-19.19 8.589-19.19 19.185 0 10.596 8.592 19.185 19.19 19.185h165.39c10.598 0 19.19-8.589 19.19-19.185 0-10.596-8.592-19.185-19.19-19.185Zm0 245.6H514.16c-10.598 0-19.19 8.589-19.19 19.185 0 10.596 8.592 19.185 19.19 19.185h165.39c10.598 0 19.19-8.589 19.19-19.185 0-10.596-8.592-19.185-19.19-19.185Z"
      />
      <path
        fill="#FF8669"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M294.11 741.88c0-19.507-15.813-35.32-35.32-35.32-19.507 0-35.32 15.813-35.32 35.32v83.58c0 19.507 15.813 35.32 35.32 35.32 19.507 0 35.32-15.813 35.32-35.32v-83.58Z"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M313.51 826.95a54.72 54.72 0 1 1-109.44 0m54.72 54.72v31.84m-35.32 0h70.64"
      />
      <path
        fill="#FF8669"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m370.59 520.1-42.9 34.55v-33.56a16.689 16.689 0 0 0-4.914-11.803 16.682 16.682 0 0 0-11.826-4.857H199.06a16.699 16.699 0 0 0-11.843 4.849 16.682 16.682 0 0 0-4.927 11.811v121.34a16.693 16.693 0 0 0 16.75 16.62h111.91a16.682 16.682 0 0 0 16.74-16.62v-28.85l43.34 31.66a11.923 11.923 0 0 0 12.42 1.068 11.925 11.925 0 0 0 6.58-10.588V529.28a11.924 11.924 0 0 0-6.83-10.71 11.916 11.916 0 0 0-12.61 1.53v0Z"
      />
      <path
        fill="#C2E7FF"
        stroke="#C2E7FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M590.9 274.8h369.18V376H590.9a50.612 50.612 0 0 1-50.61-50.61v0a50.612 50.612 0 0 1 50.61-50.59v0Z"
      />
      <path
        fill="#fff"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M625.31 322.08h-27.15a7.88 7.88 0 0 0-7.88 7.88v17.19a7.88 7.88 0 0 0 7.88 7.88h27.15a7.88 7.88 0 0 0 7.88-7.88v-17.19a7.88 7.88 0 0 0-7.88-7.88Z"
      />
      <path
        fill="#fff"
        d="M595.13 322.09v-12.86a16.764 16.764 0 0 1 4.895-11.877 16.757 16.757 0 0 1 11.865-4.923 16.76 16.76 0 0 1 16.76 16.76v12.86"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M595.13 322.09v-12.86a16.764 16.764 0 0 1 4.895-11.877 16.757 16.757 0 0 1 11.865-4.923v0a16.76 16.76 0 0 1 16.76 16.76v12.86"
      />
      <path
        fill="#fff"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52Z"
      />
      <path
        fill="#fff"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M521.29 325.74a149.004 149.004 0 0 0 39.1 100.87h220.75a149.512 149.512 0 0 0 1.15-200.47h-223a149 149 0 0 0-38 99.6v0Z"
      />
      <path
        fill="#C2E7FF"
        stroke="#C2E7FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M562.74 326.15a79.61 79.61 0 0 0 23.314 56.286 79.614 79.614 0 0 0 56.286 23.314h154.75a149.584 149.584 0 0 0 .52-159.2H642.34a79.6 79.6 0 0 0-79.6 79.6v0Z"
      />
      <path
        fill="#191C1E"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M696.46 320.91h-42.71c-6.843 0-12.39 5.547-12.39 12.39v27.04c0 6.843 5.547 12.39 12.39 12.39h42.71c6.843 0 12.39-5.547 12.39-12.39V333.3c0-6.843-5.547-12.39-12.39-12.39Z"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M648.99 320.92V300.7a26.357 26.357 0 0 1 7.721-18.639 26.357 26.357 0 0 1 18.639-7.721v0a26.357 26.357 0 0 1 18.639 7.721 26.357 26.357 0 0 1 7.721 18.639v20.22"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52Z"
      />
      <path
        fill="#fff"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M884.74 525.52 690.2 358.28a7.997 7.997 0 0 0-8.558-1.208 8 8 0 0 0-4.662 7.278v266a9.51 9.51 0 0 0 17.17 5.65l59.92-81.16 50.64 99.84a18.009 18.009 0 0 0 10.476 8.993 17.998 17.998 0 0 0 13.764-1.073v0a18.027 18.027 0 0 0 5.544-4.339 18.016 18.016 0 0 0 4.293-13.124 17.98 17.98 0 0 0-1.907-6.777l-48.75-96.1h90.4a9.516 9.516 0 0 0 8.931-6.209 9.517 9.517 0 0 0-2.721-10.531Z"
      />
      <path
        fill="#FFDDDB"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M514.8 625.12c23.936 0 43.34-19.404 43.34-43.34 0-23.936-19.404-43.34-43.34-43.34-23.936 0-43.34 19.404-43.34 43.34 0 23.936 19.404 43.34 43.34 43.34Zm0 245.6c23.936 0 43.34-19.404 43.34-43.34 0-23.936-19.404-43.34-43.34-43.34-23.936 0-43.34 19.404-43.34 43.34 0 23.936 19.404 43.34 43.34 43.34Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M119 137h842.58v806.85H119z" />
      </clipPath>
    </defs>
  </svg>
);
export default PermissionsBlockedChrome;
