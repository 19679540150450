// import { usePubSub } from "@videosdk.live/react-sdk";
import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const BrandMuteGuestsWhenVideoPlaysListner = () => {
  const { setMuteGuestsWhenVideoPlays } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.MUTE_GUESTS_WHEN_VIDEO_PLAYS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setMuteGuestsWhenVideoPlays(message?.muteGuestsWhenVideoPlays);
    }
  );

  return <React.Fragment />;
};

export default BrandMuteGuestsWhenVideoPlaysListner;
