import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const getRemainingTime = async ({
  broadcastId,
}: {
  broadcastId: string;
}): Promise<{ success: boolean; data?: { remainingTime: number } }> => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/remaining-time/${broadcastId}`;

    const res = await fetch(url);

    const json = await res.json();

    if (json.success) {
      return { success: true, data: json.data };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default getRemainingTime;
