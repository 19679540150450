const CachedUserData: {
  getItem: () => null | { [t: string]: string };
  setItem: (data: object) => void;
  updateName: (name: string) => void;
  updateParticipantHeadline: (participantHeadline: string) => void;
  updateSelectedMicDeviceId: (selectedMicDeviceId: string) => void;
  updateSelectedWebcamDeviceId: (selectedWebcamDeviceId: string) => void;
  updateSelectedSpeakerDeviceId: (selectedSpeakerDeviceId: string) => void;
  updateSelectedAudioLanguage: (selectedAudioLanguage: string) => void;
} = {
  getItem: () => {
    try {
      const localStorageUserData = localStorage.getItem(localStorageKey);

      if (localStorageUserData) {
        return JSON.parse(localStorageUserData);
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  },
  setItem: (data: object) => {
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(data));
    } catch (error) {
      //
    }
  },
  updateName: (name: string) => {
    try {
      const localStorageUserData = localStorage.getItem(localStorageKey);

      if (!localStorageUserData) return;

      localStorage.setItem(
        localStorageKey,
        JSON.stringify({ ...JSON.parse(localStorageUserData), name })
      );
    } catch (error) {
      //
    }
  },
  updateParticipantHeadline: (participantHeadline: string) => {
    try {
      const localStorageUserData = localStorage.getItem(localStorageKey);

      if (!localStorageUserData) return;

      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          ...JSON.parse(localStorageUserData),
          participantHeadline,
        })
      );
    } catch (error) {
      //
    }
  },
  updateSelectedMicDeviceId: (selectedMicDeviceId: string) => {
    try {
      const localStorageUserData = localStorage.getItem(localStorageKey);

      if (!localStorageUserData) return;

      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          ...JSON.parse(localStorageUserData),
          selectedMicDeviceId,
        })
      );
    } catch (error) {
      //
    }
  },
  updateSelectedWebcamDeviceId: (selectedWebcamDeviceId: string) => {
    try {
      const localStorageUserData = localStorage.getItem(localStorageKey);

      if (!localStorageUserData) return;

      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          ...JSON.parse(localStorageUserData),
          selectedWebcamDeviceId,
        })
      );
    } catch (error) {
      //
    }
  },
  updateSelectedSpeakerDeviceId: (selectedSpeakerDeviceId: string) => {
    try {
      const localStorageUserData = localStorage.getItem(localStorageKey);

      if (!localStorageUserData) return;

      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          ...JSON.parse(localStorageUserData),
          selectedSpeakerDeviceId,
        })
      );
    } catch (error) {
      //
    }
  },
  updateSelectedAudioLanguage: (selectedAudioLanguage: string) => {
    try {
      const localStorageUserData = localStorage.getItem(localStorageKey);

      if (!localStorageUserData) return;

      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          ...JSON.parse(localStorageUserData),
          selectedAudioLanguage,
        })
      );
    } catch (error) {
      //
    }
  },
};

const localStorageKey = "momento-streams-user-data";

export default CachedUserData;
