import { useAppContext } from "../../contexts/appContextDef";
import {
  MdOutlineViewTimeline,
  MdOutlineWbCloudy,
  MdOutlinePoll,
  MdPermMedia,
} from "react-icons/md";

import SidePanelTopBar from "./SidePanelTopBar";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";
import {
  sidePanelAppModes,
  sidePanelBannersModes,
} from "../../utils/constants";

const AppsPanel = ({
  onCloseButtonClick,
}: {
  onCloseButtonClick: () => void;
}) => {
  const { setSidePanelAppMode, setSidePanelBannersMode } = useAppContext();

  return (
    <div>
      <SidePanelTopBar onCloseButtonClick={onCloseButtonClick} title={"Apps"} />
      <div className="flex w-full grid grid-cols-3 gap-7 mt-3">
        {[
          {
            title: "Banners",
            onClick: () => {
              setSidePanelAppMode(sidePanelAppModes.BANNERS);

              setSidePanelBannersMode(
                sidePanelBannersModes.BANNER_FOLDERS_LIST
              );
            },
            Icon: MdOutlineViewTimeline,
            quickstartTargetComponentClassName:
              quickstartTargetComponentClassNames.banners_section,
          },
          {
            title: "Polls",
            onClick: () => {
              setSidePanelAppMode(sidePanelAppModes.POLLS);
            },
            Icon: MdOutlinePoll,
            quickstartTargetComponentClassName:
              quickstartTargetComponentClassNames.polls_section,
          },
          {
            title: "Wordclouds",
            onClick: () => {
              setSidePanelAppMode(sidePanelAppModes.WORDCLOUDS);
            },
            Icon: MdOutlineWbCloudy,
            quickstartTargetComponentClassName:
              quickstartTargetComponentClassNames.wordclouds_section,
          },
          {
            title: "Resources",
            onClick: () => {
              setSidePanelAppMode(sidePanelAppModes.RESOURCES);
            },
            Icon: MdPermMedia,
          },
        ].map(
          ({ onClick, title, Icon, quickstartTargetComponentClassName }, i) => {
            return (
              <div
                onClick={onClick}
                key={`apps-list=${i}`}
                className={`aspect-square flex flex-col cursor-pointer items-center justify-center rounded-md hover:bg-gray-300 ${quickstartTargetComponentClassName}`}
              >
                <div className="text-primary text-sm font-medium fill-primary flex-1 items-center justify-center flex-col rounded-md flex">
                  <Icon size={40} />

                  <p className="mt-1">{title}</p>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default AppsPanel;
