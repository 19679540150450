import React, { useEffect, useRef } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const ModifiedParticipantsAvatarsListner = () => {
  const { setModifiedParticipantsAvatars } = useAppContext();

  const { localParticipantId } = useLocalParticipantId();

  const localParticipantIdRef = useRef(localParticipantId);

  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);

  useAppSingalingSubscribe(
    appPubSubTopics.MODIFY_PARTICIPANT_AVATAR,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { participantId, avatarRemoteUrl, isOld } = message;

      setModifiedParticipantsAvatars((modifiedParticipantsAvatars) => {
        const _modifiedParticipantsAvatars = [...modifiedParticipantsAvatars];

        const participantAvatarModefiedIndex_old =
          _modifiedParticipantsAvatars.findIndex(
            ({ participantId: _participantId }) =>
              _participantId === participantId
          );

        if (participantAvatarModefiedIndex_old === -1) {
          _modifiedParticipantsAvatars.push({ participantId, avatarRemoteUrl });
        } else {
          _modifiedParticipantsAvatars[participantAvatarModefiedIndex_old] = {
            participantId,
            avatarRemoteUrl,
          };
        }

        return _modifiedParticipantsAvatars;
      });

      if (participantId === localParticipantIdRef.current && !isOld) {
        // TODO:
        // setLocalParticipantsAvatars((s) => [...s, avatarRemoteUrl]);
      }
    }
  );

  return <React.Fragment />;
};

export default ModifiedParticipantsAvatarsListner;
