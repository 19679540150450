import { useAppContext } from "../../contexts/appContextDef";
import { getVideoStreamTopicById } from "../../utils/pubSubTopics";
import React from "react";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import { safeParseJson } from "../../utils/safeParseJson";

export const videoShareStreamPubSubActions = {
  SEEK_TO: "SEEK_TO",
  PLAY: "PLAY",
  PAUSE: "PAUSE",
  PLAYING_STATE_CHANGED: "PLAYING_STATE_CHANGED",
  VOLUME_CHANGED: "VOLUME_CHANGED",
  PLAYING_AND_SEEK_TO: "PLAYING_AND_SEEK_TO",
};

const VideoShareStreamListner = ({ videoId }: { videoId: string }) => {
  const { setAllVideoShareStreamStates } = useAppContext();

  const _handleUpdatePlayingAndSeekDataChanged = ({
    playing,
    seekToSec,
    timestamp,
  }: {
    playing: boolean;
    seekToSec: number;
    timestamp: number;
  }) => {
    setAllVideoShareStreamStates((s) => {
      const arr = [...s];
      const index = arr.findIndex(({ id }) => id === videoId);

      if (index === -1) {
        arr.push({
          id: videoId,
          latestSeekData: { seekToSec, timestamp },
          playing,
          playingStateChangedTimestamp: timestamp,
        });
      } else {
        arr[index] = {
          ...arr[index],
          latestSeekData: { seekToSec, timestamp },
          playing,
          playingStateChangedTimestamp: timestamp,
        };
      }

      return arr;
    });
  };

  const _handleVolumeChanged = ({ volume }: { volume: number }) => {
    setAllVideoShareStreamStates((s) => {
      const arr = [...s];
      const index = arr.findIndex(({ id }) => id === videoId);

      if (index === -1) {
        arr.push({ volume, id: videoId });
      } else {
        arr[index] = { ...arr[index], volume };
      }

      return arr;
    });
  };

  useAppSingalingSubscribe(
    getVideoStreamTopicById(videoId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      if (message.action === videoShareStreamPubSubActions.VOLUME_CHANGED) {
        _handleVolumeChanged(message.payload as { volume: number });
      } else if (
        message.action === videoShareStreamPubSubActions.PLAYING_AND_SEEK_TO
      ) {
        _handleUpdatePlayingAndSeekDataChanged({
          ...(message.payload as {
            playing: boolean;
            seekToSec: number;
            timestamp: number;
          }),
          timestamp: new Date().getTime(),
        });
      }
    }
  );

  return <React.Fragment />;
};

const AllVideoShareStreamListner = () => {
  const { activeVideoShareStreamAllParticipants } = useAppContext();

  return activeVideoShareStreamAllParticipants.map(
    ({ id, participantId, remoteUrl, timestamp, type }) => (
      <VideoShareStreamListner
        {...{
          videoId: id,
          participantId,
          remoteUrl,
          timestamp,
          type,
          key: `VideoShareStreamListner_${id}`,
        }}
      />
    )
  );
};

export default AllVideoShareStreamListner;
