// @ts-nocheck

import React, { useEffect } from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";

const MainViewRtmpInShareStreamContainer = () => {
  const { thirdPartyRtmpData } = useAppConfigContext();

  // const { mainViewSelectedStreams, inQueueItemContainerWidth, appMode } =
  //   useAppContext();

  useEffect(() => {
    const videoTagId = `RtmpInStreamPlayer-videotag-${thirdPartyRtmpData?.playbackId}`;
    const video = document.getElementById(videoTagId) as StudioHTMLVideoElement;

    if (video?.captureStream) {
      const mediaStream = video.captureStream();

      const inQueueVideoTagId = `MainViewRtmpInShareStreamContainer-videotag-${thirdPartyRtmpData?.playbackId}`;

      const inQueueVideo = document.getElementById(
        inQueueVideoTagId
      ) as StudioHTMLVideoElement;
      inQueueVideo.srcObject = mediaStream;
    }
  }, [thirdPartyRtmpData]);

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      className="relative cursor-pointer"
    >
      <React.Fragment>
        <div
          style={{ height: "100%", width: "100%" }}
          className="relative overflow-hidden"
        >
          <video
            id={`MainViewRtmpInShareStreamContainer-videotag-${thirdPartyRtmpData?.playbackId}`}
            autoPlay
            muted
            playsInline
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
          />
        </div>
      </React.Fragment>

      {/* <div className="absolute top-0 bottom-0 left-0 right-0">
      <BottomLeftContainer
        {...{ name, relativeWidth, mainViewContainerWidth }}
      />
    </div> */}
    </div>
  );
};

export default MainViewRtmpInShareStreamContainer;
