import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import useAppRtcLocalParticipantMediaStats from "../../appRtc/useAppRtcLocalParticipantMediaStats";
import useParticipantMediaStats from "../../hooks/appState/useParticipantMediaStats";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";

const getAudioLevelFromVolume = ({ volume }: { volume: number }) => {
  return {
    audioLevel: parseInt(`${(typeof volume === "number" ? volume : 0) / 10}`),
  };
};

const LocalMicVolumeListner = () => {
  const { setLocalMicMuted, setLocalWebcamMuted } = useAppContext();

  const [localMicVolumeLevel, setLocalMicVolumeLevel] = useState(0);

  const audioAnalyserIntervalRef = useRef<null | ReturnType<
    typeof setInterval
  >>();
  const localMicVolumeLevelRef = useRef(localMicVolumeLevel);
  const audioLevelBroadcastedAt = useRef(new Date().getTime());
  // const publishRef = useRef<videosdkPubsubPublishType>();

  useEffect(() => {
    localMicVolumeLevelRef.current = localMicVolumeLevel;
  }, [localMicVolumeLevel]);

  // const { publish } = usePubSub(
  //   appPubSubTopics.ALL_PARTICIPANTS_MIC_VOLUME_LEVEL
  // );

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.ALL_PARTICIPANTS_MIC_VOLUME_LEVEL
  );

  const publishRef = useRef<appSingalingPublishType>(publish);

  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  const { localParticipantId } = useLocalParticipantId();

  // const { micStream, webcamStream } = useParticipant(localParticipantId);

  const { micOn, webcamOn } = useParticipantMediaStats(localParticipantId);

  const {
    // micOn, webcamOn,
    micTrack,
    webcamTrack,
  } = useAppRtcLocalParticipantMediaStats();

  const _handleLocalMicVolumeLevelChanged = useCallback(
    ({ localMicVolumeLevel }: { localMicVolumeLevel: number }) => {
      setLocalMicVolumeLevel(localMicVolumeLevel);

      const currentTimestamp = new Date().getTime();

      const diff = currentTimestamp - audioLevelBroadcastedAt.current;

      if (diff > 1000 || localMicVolumeLevel === 0) {
        audioLevelBroadcastedAt.current = currentTimestamp;

        const publish = publishRef.current;

        if (publish) {
          publish(
            JSON.stringify({ micVolumeLevel: localMicVolumeLevel })
            // , {
            //   persist: false,
            // }
          );
        }
      }
    },
    []
  );

  const _handleOnVolumeChanged = useCallback(
    ({ volume }: { volume: number }) => {
      appEventEmitter.emit(appEventEmitterEvents.LOCAL_MIC_VOLUME_CHANGED, {
        volume,
      });

      const { audioLevel } = getAudioLevelFromVolume({ volume });

      if (localMicVolumeLevelRef.current !== audioLevel) {
        _handleLocalMicVolumeLevelChanged({ localMicVolumeLevel: audioLevel });
      }
    },
    [_handleLocalMicVolumeLevelChanged]
  );

  const stopAudioAnalyse = useCallback(() => {
    if (audioAnalyserIntervalRef.current) {
      clearInterval(audioAnalyserIntervalRef.current);
    }

    _handleOnVolumeChanged({ volume: 0 });
  }, [_handleOnVolumeChanged]);

  const analyseAudio = useCallback(
    (audioTrack: MediaStreamTrack) => {
      const audioStream = new MediaStream([audioTrack]);
      const audioContext = new AudioContext();

      const audioSource = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();

      analyser.fftSize = 512;
      analyser.minDecibels = -127;
      analyser.maxDecibels = 0;
      analyser.smoothingTimeConstant = 0.4;

      audioSource.connect(analyser);

      const volumes = new Uint8Array(analyser.frequencyBinCount);

      const volumeCallback = () => {
        analyser.getByteFrequencyData(volumes);

        const volumeSum = volumes.reduce((sum, vol) => sum + vol);
        const averageVolume = volumeSum / volumes.length;

        _handleOnVolumeChanged({ volume: averageVolume });
      };

      audioAnalyserIntervalRef.current = setInterval(volumeCallback, 100);

      setLocalMicMuted(audioTrack.muted);

      audioTrack.onmute = () => {
        setLocalMicMuted(true);
      };

      audioTrack.onunmute = () => {
        setLocalMicMuted(false);
      };
    },
    [_handleOnVolumeChanged, setLocalMicMuted]
  );

  const analyseVideo = useCallback(
    (videoTrack: MediaStreamTrack) => {
      setLocalWebcamMuted(videoTrack.muted);

      videoTrack.onmute = () => {
        setLocalWebcamMuted(true);
      };

      videoTrack.onunmute = () => {
        setLocalWebcamMuted(false);
      };
    },
    [setLocalWebcamMuted]
  );

  useEffect(() => {
    if (micOn && micTrack) {
      analyseAudio(micTrack);
    } else {
      stopAudioAnalyse();
    }
  }, [micOn, micTrack, stopAudioAnalyse, analyseAudio]);

  useEffect(() => {
    if (webcamOn && webcamTrack) {
      analyseVideo(webcamTrack);
    } else {
      //
    }
  }, [webcamOn, webcamTrack, analyseVideo]);

  return <React.Fragment />;
};

export default LocalMicVolumeListner;
