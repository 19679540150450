// @ts-nocheck

import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import BrandPanel from "./BrandPanel";
import AppsPanel from "./AppsPanel";
import BannerFoldersContainer from "./banners/bannerFolders/BannerFoldersContainer";
import BannersContainer from "./banners/banners/BannersContainer";
import PollsContainer from "./polls/PollsContainer";
import WordcloudContainer from "./wordclouds/WordcloudContainer";
import SidePanelPollDetail from "./polls/SidePanelPollDetail";
import SidePanelWordcloudDetail from "./wordclouds/SidePanelWordcloudDetail";
import QNAPanel from "./QNAPanel";
import ParticipantsPanel from "./ParticipantsPanel";
import PrivateChatGroupsContainer from "../privateChatContainer/PrivateChatGroupsContainer";
import PrivateChatGroupMessages from "../privateChatContainer/PrivateChatGroupMessages";
import useShouldRenderSidePanelItemContainer from "../../hooks/utils/useShouldRenderSidePanelItemContainer";
import { createUID } from "../../utils/createUID";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useIsMobilePortraitOrLandscape from "../../hooks/responsive/useIsMobilePortraitOrLandscape";
import ResourcesContainer from "../resourcesContainer/ResourcesContainer";

const SidePanel = () => {
  const {
    sidePanelWidth,
    sidePanelMode,
    sidePanelActivePollId,
    sidePanelActiveWordcloudId,
  } = useAppContext();

  const containerRef = createRef<HTMLDivElement>();
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const containerHeightRef = useRef(containerHeight);
  const containerWidthRef = useRef(containerWidth);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);
  useEffect(() => {
    containerWidthRef.current = containerWidth;
  }, [containerWidth]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { width, height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
      containerWidthRef.current !== width && setContainerWidth(width);
    }
  }, [containerRef]);

  const {
    shouldRenderPrivateChatGroupsContainer,
    shouldRenderBrandPanel,
    shouldRenderQNAPanel,
    shouldRenderAppsPanel,
    shouldRenderBannerFoldersContainer,
    shouldRenderBannersContainer,
    shouldRenderPollsContainer,
    shouldRenderWordcloudContainer,
    shouldRenderSidePanelPollDetail,
    shouldRenderSidePanelWordcloudDetail,
    shouldRenderParticipantsPanel,
    shouldRenderPrivateChatGroupMessages,
    shouldRenderResourcesContainer,
  } = useShouldRenderSidePanelItemContainer();

  const Items: {
    Component: React.ElementType;
    props:
      | object
      | { containerHeight: number; containerWidth: number }
      | { id: string };
    containerProps: { key: string; className: string };
  }[] = useMemo(() => {
    return [
      {
        Component: PrivateChatGroupsContainer,
        props: { containerHeight, containerWidth },
        containerProps: {
          key: "SidePanelItem-PrivateChatGroupsContainer",
          className: shouldRenderPrivateChatGroupsContainer
            ? "block"
            : "hidden",
        },
      },
      {
        Component: BrandPanel,
        props: {},
        containerProps: {
          key: "SidePanelItem-BrandPanel",
          className: shouldRenderBrandPanel ? "block" : "hidden",
        },
      },
      {
        Component: QNAPanel,
        props: { containerHeight, containerWidth },
        containerProps: {
          key: "SidePanelItem-QNAPanel",
          className: shouldRenderQNAPanel ? "block" : "hidden",
        },
      },
      {
        Component: AppsPanel,
        props: {},
        containerProps: {
          key: "SidePanelItem-AppsPanel",
          className: shouldRenderAppsPanel ? "block" : "hidden",
        },
      },
      {
        Component: BannerFoldersContainer,
        props: {},
        containerProps: {
          key: "SidePanelItem-BannerFoldersContainer",
          className: shouldRenderBannerFoldersContainer ? "block" : "hidden",
        },
      },
      {
        Component: BannersContainer,
        props: {},
        containerProps: {
          key: "SidePanelItem-BannersContainer",
          className: shouldRenderBannersContainer ? "block" : "hidden",
        },
      },
      {
        Component: PollsContainer,
        props: {},
        containerProps: {
          key: "SidePanelItem-PollsContainer",
          className: shouldRenderPollsContainer ? "block" : "hidden",
        },
      },
      {
        Component: WordcloudContainer,
        props: {},
        containerProps: {
          key: "SidePanelItem-WordcloudContainer",
          className: shouldRenderWordcloudContainer ? "block" : "hidden",
        },
      },
      {
        Component: SidePanelPollDetail,
        props: { id: sidePanelActivePollId },
        containerProps: {
          key: "SidePanelItem-SidePanelPollDetail",
          className: shouldRenderSidePanelPollDetail ? "block" : "hidden",
        },
      },
      {
        Component: SidePanelWordcloudDetail,
        props: { id: sidePanelActiveWordcloudId },
        containerProps: {
          key: "SidePanelItem-SidePanelWordcloudDetail",
          className: shouldRenderSidePanelWordcloudDetail ? "block" : "hidden",
        },
      },
      {
        Component: ParticipantsPanel,
        props: { containerHeight, containerWidth },
        containerProps: {
          key: "SidePanelItem-ParticipantsPanel",
          className: shouldRenderParticipantsPanel ? "block" : "hidden",
        },
      },
      {
        Component: PrivateChatGroupMessages,
        props: { containerHeight, containerWidth },
        containerProps: {
          key: "SidePanelItem-PrivateChatGroupMessages",
          className: shouldRenderPrivateChatGroupMessages ? "block" : "hidden",
        },
      },
      {
        Component: ResourcesContainer,
        props: { containerHeight, containerWidth },
        containerProps: {
          key: "SidePanelItem-ResourcesContainer",
          className: shouldRenderResourcesContainer ? "block" : "hidden",
        },
      },
    ];
  }, [
    containerHeight,
    containerWidth,
    shouldRenderPrivateChatGroupsContainer,
    shouldRenderBrandPanel,
    shouldRenderQNAPanel,
    shouldRenderAppsPanel,
    shouldRenderBannerFoldersContainer,
    shouldRenderBannersContainer,
    shouldRenderPollsContainer,
    shouldRenderWordcloudContainer,
    shouldRenderSidePanelPollDetail,
    shouldRenderSidePanelWordcloudDetail,
    shouldRenderParticipantsPanel,
    shouldRenderPrivateChatGroupMessages,
    shouldRenderResourcesContainer,
    sidePanelActivePollId,
    sidePanelActiveWordcloudId,
  ]);

  const containerId = useMemo(createUID, []);

  const containerIdRef = useRef(containerId);

  useEffect(() => {
    containerIdRef.current = containerId;
  }, [containerId]);

  const startRemoveStylePropertyFromContainerInterval = () => {
    setInterval(() => {
      const elem = document.getElementById(
        containerIdRef.current
      ) as StudioHTMLElement | null;

      if (elem) {
        elem.style = undefined;
      }
    }, 10000);
  };

  const _handleQuickstartScrollBrandPnaelToBottom = () => {
    const elem = document.getElementById(containerIdRef.current);

    if (elem) {
      elem.scrollTo(0, elem.scrollHeight);
    }
  };

  useEffect(() => {
    startRemoveStylePropertyFromContainerInterval();

    appEventEmitter.on(
      appEventEmitterEvents.QUICKSTART_SCROLL_BRAND_PANEL_TO_BOTTOM,
      _handleQuickstartScrollBrandPnaelToBottom
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.QUICKSTART_SCROLL_BRAND_PANEL_TO_BOTTOM,
        _handleQuickstartScrollBrandPnaelToBottom
      );
    };
  }, []);

  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  return (
    <div
      className={`border-l border-gray-300 overflow-y-scroll overflow-x-hidden max-h-full h-full ${
        sidePanelMode ? "block" : "hidden"
      }`}
      id={containerId}
    >
      <div
        className="flex flex-1 h-full w-full flex-col border-gray-300"
        ref={containerRef as React.LegacyRef<HTMLDivElement>}
        style={{
          width: sidePanelWidth - (isMobilePortraitOrLandscape ? 0 : 3),
          paddingLeft: 4,
          paddingRight: 4,
        }}
      >
        {Items.map(({ Component, containerProps, props }) => (
          <div {...containerProps}>
            <Component {...props} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidePanel;
