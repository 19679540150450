import { useState } from "react";
import SidePanelTopBar from "../SidePanelTopBar";
import { useAppContext } from "../../../contexts/appContextDef";
import CreateNewWordcloudContainer from "./CreateNewWordcloudContainer";
import WordcloudsList from "./WordcloudsList";
import { MdAdd } from "react-icons/md";
import { quickstartTargetComponentClassNames } from "../../quickStartTourContainer/QuickStartTourContainer";

const WordcloudContainer = () => {
  const { setSidePanelAppMode } = useAppContext();
  const [isCreating, setIsCreating] = useState(false);

  return (
    <div className="flex flex-1 flex-col">
      <SidePanelTopBar
        title={"Wordclouds"}
        onBackClick={() => {
          setSidePanelAppMode(null);
        }}
        renderRightComponent={() => (
          <button
            onClick={() => {
              setIsCreating(true);
            }}
            className={`gap-2 btn btn-primary btn-outline normal-case btn-sm ${quickstartTargetComponentClassNames.create_wordcloud}`}
          >
            <MdAdd size={24} />
          </button>
        )}
      />

      <WordcloudsList />

      <CreateNewWordcloudContainer {...{ isCreating, setIsCreating }} />
    </div>
  );
};

export default WordcloudContainer;
