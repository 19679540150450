import React, { Fragment, useMemo } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { MdCheck, MdUnfoldMore, MdAdd } from "react-icons/md";

export default function Select({
  items,
  value,
  setValue,
}: {
  items: { icon?: string; id: string; title: string }[];
  value: string;
  setValue: (value: string) => void;
}) {
  const selected = useMemo(
    () =>
      items.find(({ id }) => id === value) || {
        title: "",
        name: "--Select--",
        id: "",
      },
    [items, value]
  );

  return (
    <div className="w-40">
      <Listbox value={selected.id} onChange={setValue}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate text-base font-bold text-primary">
              {selected.title}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <MdUnfoldMore
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="shadow-2xl absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {items.map((item) => {
                const Icon = item.icon === "add" ? MdAdd : MdCheck;

                const iconColor = item.icon
                  ? "text-gray-500"
                  : "text-custom-blue-900";

                return (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active
                          ? "bg-custom-blue-50 text-custom-blue-900 "
                          : "text-custom-blue-900"
                      }`
                    }
                    value={item.id}
                  >
                    {({ selected }) => (
                      <React.Fragment>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {item.title}
                        </span>
                        {selected || item.icon ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${iconColor}`}
                          >
                            <Icon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </React.Fragment>
                    )}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
