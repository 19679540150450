import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import updateBroadcastMeta from "../../apis/broadcasts/update-broadcast-meta";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useEffect, useRef } from "react";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useEnableWaitingRoom = () => {
  const { publish } = useAppSingalingPublish(
    appPubSubTopics.ENABLE_WAITING_ROOM
  );
  const { broadcastId } = useAppConfigContext();

  const broadcastIdRef = useRef(broadcastId);

  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const enable = async (opt?: { force: true }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    const { success } = await updateBroadcastMeta({
      broadcastId: broadcastIdRef.current,
      metaData: { waitingRoomEnabled: true },
    });

    if (success) {
      publish(JSON.stringify({ payload: { enabled: true } }));
    }
  };

  const disable = async (opt?: { force: true }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    const { success } = await updateBroadcastMeta({
      broadcastId: broadcastIdRef.current,
      metaData: { waitingRoomEnabled: false },
    });

    if (success) {
      publish(JSON.stringify({ payload: { enabled: false } }));
    }
  };

  return { enable, disable };
};

export default useEnableWaitingRoom;
