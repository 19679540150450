const PermissionsBlockedFirefox = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={1080} height={1080} {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillRule="nonzero" d="M0 0h1080v1080H0z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M120.5 942.36V210.83a72.32 72.32 0 0 1 72.32-72.33h767.26"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M120.5 942.36V210.83a72.32 72.32 0 0 1 72.32-72.33h767.26"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M960.08 256.93h-110c-12.94 0-23.43-10.49-23.43-23.43v-71.56a23.428 23.428 0 0 0-23.43-23.44H536.29a23.433 23.433 0 0 0-23.43 23.44v71.56c-.001 12.94-10.49 23.429-23.43 23.43H120.5v137.62h839.58"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M960.08 256.93h-110c-12.94 0-23.43-10.49-23.43-23.43v-71.56a23.428 23.428 0 0 0-23.43-23.44H536.29a23.433 23.433 0 0 0-23.43 23.44v71.56c-.001 12.94-10.49 23.429-23.43 23.43H120.5v137.62h839.58"
      />
      <path
        fill="#FF8669"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M210.81 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86h0Z"
      />
      <path
        fill="#FFB929"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M274.32 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86h0Z"
      />
      <path
        fill="#6DD58C"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M337.84 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86h0Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M460.21 353.89a38.369 38.369 0 0 1-64.265-31.835 38.364 38.364 0 0 1 28.046-33.32 38.368 38.368 0 0 1 41.119 14.355"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M460.21 353.89a38.369 38.369 0 0 1-64.265-31.835 38.364 38.364 0 0 1 28.046-33.32 38.368 38.368 0 0 1 41.119 14.355"
      />
      <path
        fill="#191C1E"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m470.57 298.9 2.52 16.75-15.95-5.72z"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M236.18 329.44h-63.69m29.13 29.13-29.13-29.13 29.13-29.12m72.7 29.12h63.69m-29.13 29.13 29.13-29.13-29.13-29.12"
      />
      <path
        fill="#FF8669"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M354.11 741.88c0-19.507-15.813-35.32-35.32-35.32-19.507 0-35.32 15.813-35.32 35.32v83.58c0 19.507 15.813 35.32 35.32 35.32 19.507 0 35.32-15.813 35.32-35.32v-83.58Z"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M373.51 826.95a54.72 54.72 0 1 1-109.44 0m54.72 54.72v31.84m-35.32 0h70.64"
      />
      <path
        fill="#FF8669"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m430.59 520.1-42.9 34.55v-33.56a16.683 16.683 0 0 0-16.74-16.66H259.06a16.7 16.7 0 0 0-11.843 4.849 16.682 16.682 0 0 0-4.927 11.811v121.34c.04 9.213 7.537 16.652 16.75 16.62h111.91c9.212.032 16.706-7.408 16.74-16.62v-28.85l43.34 31.66a11.923 11.923 0 0 0 12.42 1.068 11.925 11.925 0 0 0 6.58-10.588V529.28a11.924 11.924 0 0 0-6.83-10.71 11.916 11.916 0 0 0-12.61 1.53h0Z"
      />
      <path
        fill="#C2E7FF"
        fillRule="nonzero"
        stroke="#C2E7FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M590.9 274.8h369.18V376H590.9c-27.95-.001-50.61-22.66-50.61-50.61h0c.012-27.943 22.667-50.589 50.61-50.59h0Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M625.31 322.08h-27.15a7.88 7.88 0 0 0-7.88 7.88v17.19a7.88 7.88 0 0 0 7.88 7.88h27.15a7.88 7.88 0 0 0 7.88-7.88v-17.19a7.88 7.88 0 0 0-7.88-7.88h0Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M595.13 322.09v-12.86a16.764 16.764 0 0 1 16.76-16.8c9.256 0 16.76 7.504 16.76 16.76v12.86"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M595.13 322.09v-12.86a16.764 16.764 0 0 1 16.76-16.8h0c9.256 0 16.76 7.504 16.76 16.76v12.86"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M521.29 325.74a149.005 149.005 0 0 0 39.1 100.87h220.75c51.762-56.63 52.26-143.25 1.15-200.47h-223a149.001 149.001 0 0 0-38 99.6h0Z"
      />
      <path
        fill="#C2E7FF"
        fillRule="nonzero"
        stroke="#C2E7FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M562.74 326.15a79.606 79.606 0 0 0 23.314 56.286 79.608 79.608 0 0 0 56.286 23.314h154.75c30.743-48.56 30.945-110.44.52-159.2H642.34c-43.962 0-79.6 35.638-79.6 79.6h0Z"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M884.74 525.52 690.2 358.28a8 8 0 0 0-13.22 6.07v266a9.51 9.51 0 0 0 17.17 5.65l59.92-81.16 50.64 99.84a17.998 17.998 0 0 0 24.24 7.92h0c2.115-1.068 4-2.542 5.544-4.339a18.016 18.016 0 0 0 4.293-13.124 17.98 17.98 0 0 0-1.907-6.777l-48.75-96.1h90.4a9.516 9.516 0 0 0 8.931-6.209 9.517 9.517 0 0 0-2.721-10.531h0Z"
      />
      <g stroke="#191C1E" strokeWidth={4} transform="translate(623 279)">
        <ellipse
          cx={84.632}
          cy={20.5}
          rx={18.368}
          ry={18.5}
          transform="rotate(180 84.632 20.5)"
        />
        <path strokeLinecap="square" d="M1 20h63" />
        <circle cx={20.5} cy={60.5} r={18.5} />
        <path strokeLinecap="square" d="M105 60H42" />
      </g>
      <path fill="#FFF" fillRule="nonzero" d="M0 0h1080v1080H0z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M120.5 942.36V210.83a72.32 72.32 0 0 1 72.32-72.33h767.26"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M120.5 942.36V210.83a72.32 72.32 0 0 1 72.32-72.33h767.26"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M960.08 256.93h-110c-12.94 0-23.43-10.49-23.43-23.43v-71.56a23.428 23.428 0 0 0-23.43-23.44H536.29a23.433 23.433 0 0 0-23.43 23.44v71.56c-.001 12.94-10.49 23.429-23.43 23.43H120.5v137.62h839.58"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M960.08 256.93h-110c-12.94 0-23.43-10.49-23.43-23.43v-71.56a23.428 23.428 0 0 0-23.43-23.44H536.29a23.433 23.433 0 0 0-23.43 23.44v71.56c-.001 12.94-10.49 23.429-23.43 23.43H120.5v137.62h839.58"
      />
      <path
        fill="#FF8669"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M210.81 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86h0Z"
      />
      <path
        fill="#FFB929"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M274.32 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86h0Z"
      />
      <path
        fill="#6DD58C"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M337.84 223.85c10.416 0 18.86-8.444 18.86-18.86 0-10.416-8.444-18.86-18.86-18.86-10.416 0-18.86 8.444-18.86 18.86 0 10.416 8.444 18.86 18.86 18.86h0Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M460.21 353.89a38.369 38.369 0 0 1-64.265-31.835 38.364 38.364 0 0 1 28.046-33.32 38.368 38.368 0 0 1 41.119 14.355"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M460.21 353.89a38.369 38.369 0 0 1-64.265-31.835 38.364 38.364 0 0 1 28.046-33.32 38.368 38.368 0 0 1 41.119 14.355"
      />
      <path
        fill="#191C1E"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m470.57 298.9 2.52 16.75-15.95-5.72z"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M236.18 329.44h-63.69m29.13 29.13-29.13-29.13 29.13-29.12m72.7 29.12h63.69m-29.13 29.13 29.13-29.13-29.13-29.12"
      />
      <path
        fill="#FF8669"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M354.11 741.88c0-19.507-15.813-35.32-35.32-35.32-19.507 0-35.32 15.813-35.32 35.32v83.58c0 19.507 15.813 35.32 35.32 35.32 19.507 0 35.32-15.813 35.32-35.32v-83.58Z"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M373.51 826.95a54.72 54.72 0 1 1-109.44 0m54.72 54.72v31.84m-35.32 0h70.64"
      />
      <path
        fill="#FF8669"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="m430.59 520.1-42.9 34.55v-33.56a16.683 16.683 0 0 0-16.74-16.66H259.06a16.7 16.7 0 0 0-11.843 4.849 16.682 16.682 0 0 0-4.927 11.811v121.34c.04 9.213 7.537 16.652 16.75 16.62h111.91c9.212.032 16.706-7.408 16.74-16.62v-28.85l43.34 31.66a11.923 11.923 0 0 0 12.42 1.068 11.925 11.925 0 0 0 6.58-10.588V529.28a11.924 11.924 0 0 0-6.83-10.71 11.916 11.916 0 0 0-12.61 1.53h0Z"
      />
      <path
        fill="#C2E7FF"
        fillRule="nonzero"
        stroke="#C2E7FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M590.9 274.8h369.18V376H590.9c-27.95-.001-50.61-22.66-50.61-50.61h0c.012-27.943 22.667-50.589 50.61-50.59h0Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M625.31 322.08h-27.15a7.88 7.88 0 0 0-7.88 7.88v17.19a7.88 7.88 0 0 0 7.88 7.88h27.15a7.88 7.88 0 0 0 7.88-7.88v-17.19a7.88 7.88 0 0 0-7.88-7.88h0Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M595.13 322.09v-12.86a16.764 16.764 0 0 1 16.76-16.8c9.256 0 16.76 7.504 16.76 16.76v12.86"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M595.13 322.09v-12.86a16.764 16.764 0 0 1 16.76-16.8h0c9.256 0 16.76 7.504 16.76 16.76v12.86"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M521.29 325.74a149.005 149.005 0 0 0 39.1 100.87h220.75c51.762-56.63 52.26-143.25 1.15-200.47h-223a149.001 149.001 0 0 0-38 99.6h0Z"
      />
      <path
        fill="#C2E7FF"
        fillRule="nonzero"
        stroke="#C2E7FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M562.74 326.15a79.606 79.606 0 0 0 23.314 56.286 79.608 79.608 0 0 0 56.286 23.314h154.75c30.743-48.56 30.945-110.44.52-159.2H642.34c-43.962 0-79.6 35.638-79.6 79.6h0Z"
      />
      <path
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M670.77 475.25c82.578 0 149.52-66.942 149.52-149.52 0-82.578-66.942-149.52-149.52-149.52-82.578 0-149.52 66.942-149.52 149.52 0 82.578 66.942 149.52 149.52 149.52Z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        stroke="#191C1E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M884.74 525.52 690.2 358.28a8 8 0 0 0-13.22 6.07v266a9.51 9.51 0 0 0 17.17 5.65l59.92-81.16 50.64 99.84a17.998 17.998 0 0 0 24.24 7.92h0c2.115-1.068 4-2.542 5.544-4.339a18.016 18.016 0 0 0 4.293-13.124 17.98 17.98 0 0 0-1.907-6.777l-48.75-96.1h90.4a9.516 9.516 0 0 0 8.931-6.209 9.517 9.517 0 0 0-2.721-10.531h0Z"
      />
      <g stroke="#191C1E" strokeWidth={4} transform="translate(623 279)">
        <ellipse
          cx={84.632}
          cy={20.5}
          rx={18.368}
          ry={18.5}
          transform="rotate(180 84.632 20.5)"
        />
        <path strokeLinecap="square" d="M1 20h63" />
        <circle cx={20.5} cy={60.5} r={18.5} />
        <path strokeLinecap="square" d="M105 60H42" />
      </g>
    </g>
  </svg>
);
export default PermissionsBlockedFirefox;
