import React from "react";
// , { useEffect, useRef }
import { useAppContext } from "../../contexts/appContextDef";
import {
  // appPubSubTopics,
  getFileStreamTopicById,
} from "../../utils/pubSubTopics";
// import { fileShareStreamActions } from "../../hooks/streams/useFileShareStreams";
// import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import { fileShareStreamPubSubActions } from "../../hooks/streams/useFileShareStream";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import { safeParseJson } from "../../utils/safeParseJson";

export const FileShareStreamListner = ({ fileId }: { fileId: string }) => {
  const { setFileShareStreamsCurrentPage } = useAppContext();

  useAppSingalingSubscribe(
    getFileStreamTopicById(fileId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case fileShareStreamPubSubActions.GOTO_PAGE:
          setFileShareStreamsCurrentPage((s) => ({
            ...s,
            [fileId]: message.payload.currentPage,
          }));

          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

const FileShareStreamsListner = () => {
  // const { setActiveFileShareStreams } = useAppContext();

  // const { localParticipantId } = useLocalParticipantId();

  // const localParticipantIdRef = useRef(localParticipantId);

  // useEffect(() => {
  //   localParticipantIdRef.current = localParticipantId;
  // }, [localParticipantId]);

  // useAppSingalingSubscribe(
  //   appPubSubTopics.FILE_SHARE_STREAM_ENABLED,
  //   ({ message: receivedMessage, participantId }) => {
  //     const message = safeParseJson(receivedMessage);

  //     const localParticipantId = localParticipantIdRef.current;

  //     if (participantId === localParticipantId) {
  //       setActiveFileShareStreams((streams) =>
  //         message.action === fileShareStreamActions.ENABLE
  //           ? [...streams, message]
  //           : streams.filter((stream) => stream.id !== message.id)
  //       );
  //     }
  //   }
  // );

  return <React.Fragment />;
};

export default FileShareStreamsListner;
