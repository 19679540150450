// import { usePubSub } from "@videosdk.live/react-sdk";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";

const usePreviousStudioMainViewLayout = () => {
  // const { publish } = usePubSub(
  //   appPubSubTopics.PREVIOUS_STUDIO_MAIN_VIEW_LAYOUT
  // );

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.PREVIOUS_STUDIO_MAIN_VIEW_LAYOUT
  );

  const setPreviousStudioMainViewLayout = ({
    previousStudioMainViewLayout,
  }: {
    previousStudioMainViewLayout: mainViewLayoutType;
  }) => {
    publish(
      JSON.stringify({ previousStudioMainViewLayout })
      // , {
      //   persist: true,
      // }
    );
  };

  return { setPreviousStudioMainViewLayout };
};

export default usePreviousStudioMainViewLayout;
