import React, { useEffect, useMemo, useRef } from "react";
import { MdAdd, MdVideocamOff } from "react-icons/md";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import addBroadcastAsset from "../../apis/broadcasts/add-broadcast-asset";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import useBroadcastAssets from "../../hooks/appState/useBroadcastAssets";
import { safeParseJson } from "../../utils/safeParseJson";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useAppRtcLocalParticipantMediaStats from "../../appRtc/useAppRtcLocalParticipantMediaStats";
import useRtcLocalParticipantMediaStatsActions from "../../appRtc/useRtcLocalParticipantMediaStatsActions";

const VirtualBackgroundSettingsContainer = () => {
  const {
    virtualBackgrounds,
    activeVirtualBackgroundId,
    mirrorLocalWebcam,
    setFileUploadError,
  } = useAppContext();

  const { broadcastId, studioUserId } = useAppConfigContext();

  const { addAsset } = useBroadcastAssets();

  const { localParticipantId } = useLocalParticipantId();

  const { __webcamTrack: webcamTrack } = useAppRtcLocalParticipantMediaStats();

  const activeVirtualBackgroundIdRef = useRef(activeVirtualBackgroundId);

  useEffect(() => {
    activeVirtualBackgroundIdRef.current = activeVirtualBackgroundId;
  }, [activeVirtualBackgroundId]);

  const { setVirtualBackgroundId } = useRtcLocalParticipantMediaStatsActions();
  const virtualBackgroundsRef = useRef(virtualBackgrounds);

  useEffect(() => {
    virtualBackgroundsRef.current = virtualBackgrounds;
  }, [virtualBackgrounds]);

  const _handleChangeVirtualBackground = async (id: string) => {
    if (activeVirtualBackgroundIdRef.current !== id) {
      setVirtualBackgroundId(id);
    }
  };

  const flipStyle = useMemo(
    () =>
      mirrorLocalWebcam
        ? { transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }
        : {},
    [mirrorLocalWebcam]
  );

  const imageInputRef = useRef<HTMLInputElement>(null);

  const onImageFileEvent = async ({
    singleFile,
  }: {
    singleFile: File | null;
  }) => {
    const { success, data, error } = await addBroadcastAsset({
      broadcastId,
      owner: studioUserId,
      metaData: { type: "virtual-background-image" },
      singleFile,
    });

    if (success) {
      addAsset({
        data: {
          ...data,
          metaData: safeParseJson(data.metaData),
          owners: [studioUserId],
        },
      });
    } else if (error) {
      setFileUploadError({
        visible: true,
        errorMessage: error,
      });
    }
  };

  return (
    <div className="flex flex-1 flex-col relative">
      <div className="rounded-md overflow-hidden">
        <p className="bg-gray-600 text-sm text-center text-white">
          For best performance, we recommend using a green screen
        </p>
        <div className="w-full aspect-video bg-gray-800">
          {webcamTrack ? (
            <VideoMediaStreamPlayer
              {...{
                flipStyle,
                videoOn: !!webcamTrack,
                videoTrack: webcamTrack,
                key: `VideoMediaStreamPlayer-webcam-stream-${webcamTrack.id}-${localParticipantId}`,
                participantId: localParticipantId,
                isWebcam: true,
              }}
            />
          ) : (
            <div className="flex flex-1 items-center justify-center h-full">
              <MdVideocamOff size={40} className={"fill-gray-200"} />
            </div>
          )}
        </div>
      </div>

      <div className="mt-4">
        <p className="text-sm text-gray-500 font-bold">Virtual backgrounds</p>
        <div className="flex flex-wrap mt-0.5">
          {virtualBackgrounds.map(({ id, url, Icon, title }, i) => {
            const isActive = id === activeVirtualBackgroundId;

            return (
              <div
                key={`virtual-backgrounds-item${id}`}
                onClick={() => _handleChangeVirtualBackground(id)}
                className={`m-1 ${
                  isActive ? "border-gray-500" : "border-gray-200"
                }`}
                style={{ borderWidth: 3 }}
              >
                <div
                  className={`aspect-video h-16 bg-white border-2 ${
                    isActive ? "border-white" : "border-white"
                  } overflow-hidden cursor-pointer`}
                >
                  {Icon ? (
                    <div className="flex items-center justify-center p-2 flex-col">
                      <Icon className="fill-gray-600" size={24} />
                      <p className="text-gray-600">{title}</p>
                    </div>
                  ) : (
                    <div>
                      <img
                        className="h-full w-full object-cover"
                        alt={`virtual-bg-url-${i}`}
                        src={url}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          <div
            onClick={() => {
              imageInputRef.current?.click();
            }}
            className={`m-1 border-gray-200`}
            style={{ borderWidth: 3 }}
          >
            <div
              className={`flex items-center justify-center aspect-video h-16 bg-white border-2 border-white overflow-hidden cursor-pointer`}
            >
              <div className="flex items-center justify-center p-2 flex-col">
                <MdAdd className="fill-gray-600" size={24} />
              </div>
            </div>
          </div>

          {/* file input */}
          <input
            ref={imageInputRef as React.LegacyRef<HTMLInputElement>}
            onChange={(e) => {
              const singleFile = e?.target?.files ? e.target.files[0] : null;

              onImageFileEvent({ singleFile });

              if (imageInputRef.current) {
                imageInputRef.current.value = "";
              }
            }}
            accept="image/*"
            type="file"
            className="hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default VirtualBackgroundSettingsContainer;
