import React, { useEffect, useMemo, useRef, useState } from "react";
import { createUID } from "../utils/createUID";

const VideoMediaStreamPlayer = ({
  videoOn,
  videoTrack,
  flipStyle,
  blur,
  participantId,
  // isShare,
  isWebcam,
  rounded,
}: {
  videoOn: boolean;
  videoTrack?: MediaStreamTrack;
  flipStyle?: React.CSSProperties;
  blur?: boolean;
  participantId: string;
  isShare?: boolean;
  isWebcam?: boolean;
  rounded?: boolean;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const videoPlayerId = useMemo(
    () => `VideoMediaStreamPlayer-${createUID()}`,
    []
  );

  const videoPlayerIdRef = useRef(videoPlayerId);
  const isPlayingRef = useRef(isPlaying);

  useEffect(() => {
    videoPlayerIdRef.current = videoPlayerId;
  }, [videoPlayerId]);
  useEffect(() => {
    isPlayingRef.current = isPlaying;
  }, [isPlaying]);

  // const { restartWebcam, restartScreenShare } =
  //   useToggleParticipantMedia(participantId);

  // const totalRestartedCount = useRef(0);

  // const [restartingPlayer, setRestartingPlayer] = useState(false);

  // const intervalRef = useRef<null | ReturnType<typeof setInterval>>();
  const lastProgressedAt = useRef(new Date().getTime());
  // const restartingPlayerRef = useRef(restartingPlayer);
  const participantIdRef = useRef(participantId);
  // const isShareRef = useRef(isShare);
  const isWebcamRef = useRef(isWebcam);

  // useEffect(() => {
  //   restartingPlayerRef.current = restartingPlayer;
  // }, [restartingPlayer]);
  useEffect(() => {
    participantIdRef.current = participantId;
  }, [participantId]);
  // useEffect(() => {
  //   isShareRef.current = isShare;
  // }, [isShare]);
  useEffect(() => {
    isWebcamRef.current = isWebcam;
  }, [isWebcam]);

  // const notProgressedForMoreThenFiveSecondsCount = useRef(0);

  // const { getIsSignalLevelValid } = useValidateConnection();

  // const { mediaStream } = useMemo(() => {
  //   const _videoTrack = videoTrack;

  //   if (videoOn && _videoTrack && !restartingPlayer) {
  //     const mediaStream = new MediaStream();

  //     mediaStream.addTrack(_videoTrack);

  //     return { mediaStream };
  //   } else {
  //     return { mediaStream: null };
  //   }
  // }, [videoOn, videoTrack, restartingPlayer]);

  const { mediaStream } = useMemo(() => {
    const _videoTrack = videoTrack;

    if (videoOn && _videoTrack) {
      const mediaStream = new MediaStream();

      mediaStream.addTrack(_videoTrack);

      return { mediaStream };
    } else {
      return { mediaStream: null };
    }
  }, [videoOn, videoTrack]);

  const mediaStreamRef = useRef(mediaStream);

  useEffect(() => {
    mediaStreamRef.current = mediaStream;
  }, [mediaStream]);

  const getVideoTag = () => {
    return document.getElementById(
      videoPlayerIdRef.current
    ) as StudioHTMLVideoElement | null;
  };

  const _playVideo = () => {
    const mediaStream = mediaStreamRef.current;

    if (!mediaStream) return;

    const videoTag = getVideoTag();

    if (!videoTag) return;

    if (!videoTag?.paused) return;

    videoTag.volume = 0;

    videoTag.muted = true;

    videoTag.playsInline = true;

    videoTag.srcObject = mediaStream;

    const playPromise = videoTag.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {})
        .catch((e) => {
          console.log("play-error", e);
        });
    }
  };

  // useEffect(() => {
  //   _handleStartInterval();

  //   return () => {
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //   };
  // }, []);

  // return videoOn && videoTrack && mediaStream && !restartingPlayer ? (
  return videoOn && videoTrack && mediaStream ? (
    <video
      ref={() => {
        _playVideo();
      }}
      onPlay={() => {
        const isPlaying = isPlayingRef.current;

        if (!isPlaying) {
          setIsPlaying(true);
        }
      }}
      onPause={() => {
        const isPlaying = isPlayingRef.current;

        if (isPlaying) {
          setIsPlaying(false);
        }
      }}
      id={videoPlayerId}
      height={"100%"}
      width={"100%"}
      muted
      // playsinline // very very imp prop
      playsInline // very very imp prop
      autoPlay
      style={{
        ...flipStyle,
        height: "100%",
        width: "100%",
        filter: blur ? "blur(2rem)" : undefined,
        objectFit: isWebcam ? "cover" : undefined,
        backgroundColor: "transparent",
        opacity: isPlaying ? 1 : 0,
      }}
      onTimeUpdate={() => {
        lastProgressedAt.current = new Date().getTime();
      }}
      controls={false}
      className={`${rounded ? "rounded" : ""}`}
    />
  ) : (
    <React.Fragment />
  );
};

export default VideoMediaStreamPlayer;
