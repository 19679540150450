import { useEffect, useRef, useState } from "react";
import useWordcloud from "../../../hooks/apps/wordcloud/useWordcloud";
import { MdDelete } from "react-icons/md";
import WordcloudsListItem from "./WordcloudsListItem";
import deleteWordcloudAnswer from "../../../apis/apps/wordclouds/delete-wordcloud-answer";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import Spinner from "../../../components/Spinner";
import { quickstartTargetComponentClassNames } from "../../quickStartTourContainer/QuickStartTourContainer";

const SidePanelWordcloudDetail = ({ id }: { id: string }) => {
  const [deleting, setDeleting] = useState(false);

  const { broadcastId } = useAppConfigContext();

  const { question, answers } = useWordcloud({ id });

  const broadcastIdRef = useRef(broadcastId);

  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);

  return (
    <div className={`${quickstartTargetComponentClassNames.wordcloud_detail}`}>
      <WordcloudsListItem id={id} showBackIcon />

      <div
        className={`p-1 mt-3 ${quickstartTargetComponentClassNames.submit_wordcloud_answer}`}
      >
        <div>
          <p className="text-2xl font-extrabold">{question}</p>
        </div>
        <div className="mt-2">
          {answers && Object.keys(answers).length > 0 ? (
            Object.keys(answers).map((key) => {
              const count = answers[key];

              return (
                <div
                  key={`side-panel-wordcloud-submitted-answers${key}`}
                  className="flex mt-1 p-2 border-t border-gray-200"
                >
                  <div className="flex flex-1">
                    <p className="font-medium">{`${key} (${count})`}</p>
                  </div>

                  <div>
                    {deleting ? (
                      <Spinner h={"h-6"} w={"w-6"} />
                    ) : (
                      <button
                        onClick={async () => {
                          setDeleting(true);

                          await deleteWordcloudAnswer({
                            answer: key,
                            wordcloudId: id,
                            broadcastId: broadcastIdRef.current,
                          });

                          setDeleting(false);
                        }}
                        className="btn btn-sm btn-error btn-outline"
                      >
                        <MdDelete />
                      </button>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SidePanelWordcloudDetail;
