import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import { useAppContext } from "../../contexts/appContextDef";
import { useEffect, useRef } from "react";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

export const videoClipPubSubActions = {
  CHANGE_ACTIVE_ID_AND_REMOTE_URL: "CHANGE_ACTIVE_ID_AND_REMOTE_URL",
  CHANGE_VOLUME: "CHANGE_VOLUME",
  LOOP: "LOOP",
};

const useMainViewVideoClip = () => {
  const { mainViewVideoClip } = useAppContext();

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.MAIN_VIEW_VIDEO_CLIP
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const mainViewVideoClipRef = useRef(mainViewVideoClip);

  useEffect(() => {
    mainViewVideoClipRef.current = mainViewVideoClip;
  }, [mainViewVideoClip]);

  const changeActiveIdAndRemoteUrl = async (
    {
      id,
      videoOriginal,
      video360,
      video480,
      video720,
      videoThumbnail,
      remoteUrl,
      playedAt,
      initTime,
    }: {
      id?: string | null | number;
      videoOriginal?: string | null;
      video360?: string | null;
      video480?: string | null;
      video720?: string | null;
      videoThumbnail?: string | null;
      remoteUrl?: string | null;
      playedAt?: number | null;
      initTime?: number | null;
    },
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    const mainViewVideoClip = mainViewVideoClipRef.current;

    if (
      id &&
      remoteUrl &&
      mainViewVideoClip?.id &&
      mainViewVideoClip?.remoteUrl &&
      mainViewVideoClip?.initTime &&
      initTime
    ) {
      if (initTime <= mainViewVideoClip?.initTime) {
        return;
      }
    }

    publish(
      JSON.stringify({
        payload: {
          id,
          remoteUrl,
          videoOriginal,
          video360,
          video480,
          video720,
          videoThumbnail,
          playedAt,
          initTime,
        },
        action: videoClipPubSubActions.CHANGE_ACTIVE_ID_AND_REMOTE_URL,
      })
    );
  };

  const changeVolume = async (
    { volume }: { volume: number },
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(
      JSON.stringify({
        payload: { volume },
        action: videoClipPubSubActions.CHANGE_VOLUME,
      })
    );
  };

  const changeLoopState = async (
    {
      loop,
      loopedAt,
    }: {
      loop: boolean | null;
      loopedAt: number | null;
    },
    opt?: { force: true }
  ) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction && !opt?.force) return;

    publish(
      JSON.stringify({
        payload: { loop, loopedAt },
        action: videoClipPubSubActions.LOOP,
      })
    );
  };

  return { changeActiveIdAndRemoteUrl, changeVolume, changeLoopState };
};

export default useMainViewVideoClip;
