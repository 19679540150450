import { useWindowSize } from "@react-hook/window-size";

const useIsUltrawidePortrait = () => {
  const [windowWidth, windowHeight] = useWindowSize();

  return {
    isUltrawidePortrait:
      parseFloat((windowWidth / windowHeight).toFixed(3)) < 0.43,
  };
};

export default useIsUltrawidePortrait;
