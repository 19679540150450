// @ts-nocheck

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ParticipantsAudioStreamsContainer from "./ParticipantsAudioStreamsContainer";
import BackgroundMusicContainer from "./BackgroundMusicContainer";
import MainViewVideoClipContainer from "./MainViewVideoClipContainer";
import useMainViewLayout from "../../hooks/appState/useMainViewLayout";
import BannerContainer from "./BannerContainer";
import MainViewHoveredItems from "./MainViewHoveredItems";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import MainViewLogoContainer from "./MainViewLogoContainer";
import MainViewLiveTimer from "./MainViewLiveTimer";
import MainViewOverlay from "./MainViewOverlay";
import MainViewBackgroundImage from "./MainViewBackgroundImage";
import MainViewContainerGridItemsMapper from "./MainViewContainerGridItemsMapper";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { primaryColor } from "../../utils/colors";
import MainViewAudioOnlyParticipantsPopupContainer from "./MainViewAudioOnlyParticipantsPopupContainer";
import getTransitionStyle from "../../utils/getTransitionStyle";
import MainViewUserInteractButtonVideoClip from "./MainViewUserInteractButtonVideoClip";
import MainViewUserInteractButtonVideoShare from "./MainViewUserInteractButtonVideoShare";
import { createUID } from "../../utils/createUID";
import MainViewFullScreenToggleButton from "./MainViewFullScreenToggleButton";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";
import MainViewEgressesProgressContainer from "./MainViewEgressesProgressContainer";
import { appModes, mainViewLayouts } from "../../utils/constants";
import MainViewUserInteractButtonInputFileVideoShare from "./MainViewUserInteractButtonInputFileVideoShare";
import MainViewToggleInQueueStreamContainerButton from "./MainViewToggleInQueueStreamContainerButton";
import { useAppContext } from "../../contexts/appContextDef";

const MainViewContainer = ({
  height,
  width,
}: {
  height: number;
  width: number;
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const mainViewContainerId = useMemo(
    () => `mainViewContainerId-${createUID()}`,
    []
  );

  const mainViewContainerIdRef = useRef(mainViewContainerId);

  useEffect(() => {
    mainViewContainerIdRef.current = mainViewContainerId;
  }, [mainViewContainerId]);

  const { gridWithStreamsPosition, mainViewLayoutActive } = useMainViewLayout();
  const { isRecorder } = useAppConfigContext();
  const { appMode } = useAppContext();

  const showSurroundPadding = useMemo(
    () =>
      (mainViewLayoutActive === mainViewLayouts.SOLO ||
        mainViewLayoutActive === mainViewLayouts.GROUP) &&
      gridWithStreamsPosition?.length === 1,
    [mainViewLayoutActive, gridWithStreamsPosition]
  );

  const _handleToggleFullScreen = useCallback(() => {
    try {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        const elem = document.getElementById(mainViewContainerIdRef.current);

        if (elem) {
          if (elem.requestFullscreen) {
            elem.requestFullscreen().catch((e) => {
              console.log(`request to full screen is rejected due to ${e}`);
            });
          } else if (elem.webkitRequestFullscreen) {
            /*  Safari */
            elem.webkitRequestFullscreen().catch(() => {});
          } else if (elem.msRequestFullscreen) {
            /*  IE11 */
            elem.msRequestFullscreen().catch(() => {});
          }
        }
      }
    } catch (error) {
      //
    }
  }, []);

  const gridContainerStyles = useMemo(
    () => ({
      position: "absolute",
      transition: getTransitionStyle(["top", "left", "right", "bottom"]),
      top: showSurroundPadding
        ? "7%"
        : mainViewLayoutActive === mainViewLayouts.CINEMA ||
          mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE
        ? 0
        : 0,
      left: showSurroundPadding
        ? "7%"
        : mainViewLayoutActive === mainViewLayouts.CINEMA ||
          mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE
        ? 0
        : 0,
      right: showSurroundPadding
        ? "7%"
        : mainViewLayoutActive === mainViewLayouts.CINEMA ||
          mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE
        ? 0
        : 0,
      bottom: showSurroundPadding
        ? "7%"
        : mainViewLayoutActive === mainViewLayouts.CINEMA ||
          mainViewLayoutActive === mainViewLayouts.PICTURE_IN_PICTURE
        ? 0
        : 0,
    }),
    [mainViewLayoutActive, showSurroundPadding]
  );

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.TOGGLE_FULLSCREEN,
      _handleToggleFullScreen
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.TOGGLE_FULLSCREEN,
        _handleToggleFullScreen
      );
    };
  }, [_handleToggleFullScreen]);

  const { mainViewContainerHeight, mainViewContainerWidth } = useMemo(() => {
    let mainViewContainerHeight, mainViewContainerWidth;

    if (width / height > 16 / 9) {
      mainViewContainerHeight = height;
      mainViewContainerWidth = (16 * mainViewContainerHeight) / 9;
    } else if (width / height < 16 / 9) {
      mainViewContainerWidth = width;
      mainViewContainerHeight = (9 * mainViewContainerWidth) / 16;
    } else {
      mainViewContainerWidth = width;
      mainViewContainerHeight = (9 * mainViewContainerWidth) / 16;
    }

    return { mainViewContainerHeight, mainViewContainerWidth };
  }, [height, width]);

  return (
    <React.Fragment>
      <div
        onMouseEnter={() => {
          setIsMouseOver(true);
        }}
        onMouseLeave={() => {
          setIsMouseOver(false);
        }}
        className="flex items-center justify-center relative"
        style={{ height, width }}
      >
        <div
          id={mainViewContainerId}
          className={`relative bg-black rounded overflow-hidden aspect-video ${quickstartTargetComponentClassNames.main_view_container} ${quickstartTargetComponentClassNames.brand_main_view_container} ${quickstartTargetComponentClassNames.banner_main_view_container} ${quickstartTargetComponentClassNames.poll_main_view_container} ${quickstartTargetComponentClassNames.wordcloud_main_view_container} `}
          style={{
            borderWidth: isRecorder ? 0 : 1,
            borderColor: primaryColor,
            height: mainViewContainerHeight,
            width: mainViewContainerWidth,
          }}
        >
          <MainViewBackgroundImage />

          <MainViewContainerGridItemsMapper
            {...{
              gridContainerStyles: gridContainerStyles as React.CSSProperties,
              mainViewContainerHeight,
              mainViewContainerWidth,
            }}
          />

          <MainViewLogoContainer />

          <BannerContainer
            {...{
              mainViewContainerHeight,
              mainViewContainerWidth,
            }}
          />

          <MainViewHoveredItems
            {...{
              gridContainerStyles: gridContainerStyles as React.CSSProperties,
              mainViewContainerWidth,
            }}
          />

          <MainViewOverlay />

          <MainViewVideoClipContainer />

          {appMode === appModes.HOST || appMode === appModes.SPEAKER ? (
            <MainViewLiveTimer />
          ) : (
            <React.Fragment />
          )}

          <MainViewAudioOnlyParticipantsPopupContainer />

          <MainViewUserInteractButtonVideoClip />

          <MainViewUserInteractButtonVideoShare />

          <MainViewUserInteractButtonInputFileVideoShare />

          <ParticipantsAudioStreamsContainer />

          <BackgroundMusicContainer />

          <MainViewFullScreenToggleButton />

          {appMode === appModes.HOST || appMode === appModes.SPEAKER ? (
            <MainViewEgressesProgressContainer
              {...{ mainViewContainerWidth }}
            />
          ) : (
            <React.Fragment />
          )}
        </div>

        <MainViewToggleInQueueStreamContainerButton {...{ isMouseOver }} />
      </div>
    </React.Fragment>
  );
};

export default MainViewContainer;
