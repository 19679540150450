import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAJdmrJhqZuz0LYXE8aSKjacPvKEc3bYWI",
  authDomain: "momento-live.firebaseapp.com",
  databaseURL: "https://momento-live.firebaseio.com",
  projectId: "momento-live",
  storageBucket: "momento-live.appspot.com",
  messagingSenderId: "305389064487",
  appId: "1:305389064487:web:98edb4019632c6f5795902",
};

const firebaseApp = initializeApp(firebaseConfig);

const firebaseDB = getDatabase(firebaseApp);

export { firebaseApp, firebaseDB };
