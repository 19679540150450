import { momentostreamApiServerBaseUrl } from "../../../utils/constants";

const createBanner = async ({
  userId,
  folderId,
  type,
  position,
  primaryText,
  secondaryText,
}: {
  userId: string;
  folderId: string | number;
  type: string;
  position: string;
  primaryText: string;
  secondaryText?: string;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/${userId}/banner`;
    const body = JSON.stringify({
      folderId,
      type,
      position,
      primaryText,
      secondaryText,
    });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true, data: json.data };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default createBanner;
