import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const setQuickStart = async ({
  userId,
  quickstartStepsCompleted,
  quickstartStepsSkipped,
}: {
  userId: string;
  quickstartStepsCompleted: string[];
  quickstartStepsSkipped: string[];
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/set-quick-start`;

    const body = JSON.stringify({
      userId,
      quickstartStepsCompleted,
      quickstartStepsSkipped,
    });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default setQuickStart;
