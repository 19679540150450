import { useAppContext } from "../../contexts/appContextDef";
import BrandVideoClipsListner from "./BrandVideoClipsListner";

const AllBrandsVideoClipsListner = () => {
  const { brands } = useAppContext();

  return brands.map(({ id }) => (
    <BrandVideoClipsListner key={`BrandVideoClipsListner_${id}`} id={id} />
  ));
};

export default AllBrandsVideoClipsListner;
