import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const activateBrandAsset = async ({
  brandId,
  userId,
  type,
  fileId,
}: {
  brandId: string;
  userId: string;
  type: string;
  fileId: string;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/activate-brand-asset`;

    const body = JSON.stringify({ brandId, userId, type, fileId });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default activateBrandAsset;
