export const primaryColor = "#1A3188";

export const customBlueColor = {
  50: "#e8ecfd",
  100: "#a4b0e0",
  200: "#8696d3",
  300: "#6e81c7",
  400: "#4e63b1",
  500: "#4056ac",
  600: "#2f469f",
  700: "#1a3188",
  800: "#0f2884",
  900: "#041c77",
};
