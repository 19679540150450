import { useMemo } from "react";
import { MdTranslate } from "react-icons/md";
import { Menu, MenuItem, MenuDivider } from "@szhsin/react-menu";
import { useAppConfigContext } from "../contexts/appConfigDef";
import { useAppContext } from "../contexts/appContextDef";
import IconButton, {
  iconButtonVariants,
} from "../containers/bottomControlsContainer/IconButton";

const InterpretationsContainer = () => {
  const { interpretations } = useAppConfigContext();
  const {
    activeInterpretationOutputAgoraChannelId,
    setActiveInterpretationOutputAgoraChannelId,
    muteOriginalAudioWhenInterpretationActive,
    setMuteOriginalAudioWhenInterpretationActive,
    leavingAgoraChannel,
    joiningAgoraChannel,
  } = useAppContext();

  const maxTitle = useMemo(() => {
    let maxTitle = "Off";

    interpretations.forEach(({ name }) => {
      if (name.length > maxTitle.length) {
        maxTitle = name;
      }
    });

    return maxTitle;
  }, [interpretations]);

  const activeInterpretations = useMemo(() => {
    return interpretations.find(
      ({ agoraChannelId }) =>
        agoraChannelId === activeInterpretationOutputAgoraChannelId
    );
  }, [interpretations, activeInterpretationOutputAgoraChannelId]);

  return (
    <Menu
      menuButton={
        <div>
          <IconButton
            {...{
              disabled: joiningAgoraChannel || leavingAgoraChannel,
              Icon: MdTranslate,
              title: activeInterpretations ? activeInterpretations.name : "Off",
              popperTitle: "",
              variant: activeInterpretations
                ? iconButtonVariants.primary
                : iconButtonVariants.info,
              maxTitle,
              rounded: "rounded-l-md",
              isProcessing: joiningAgoraChannel || leavingAgoraChannel,
              isMenuButton: true,
            }}
          />
        </div>
      }
      transition
    >
      {[{ agoraChannelId: null, name: "Off" }, ...interpretations].map(
        ({ agoraChannelId, name }) => {
          const isActive =
            !agoraChannelId && !activeInterpretationOutputAgoraChannelId
              ? true
              : agoraChannelId === activeInterpretationOutputAgoraChannelId;

          return (
            <MenuItem
              disabled={joiningAgoraChannel || leavingAgoraChannel}
              type="checkbox"
              checked={isActive}
              key={`interpretations-channel-selector-item-${agoraChannelId}`}
              className={`px-3 py-2  hover:bg-custom-blue-50 text-primary`}
              onClick={() => {
                if (
                  activeInterpretationOutputAgoraChannelId !== agoraChannelId
                ) {
                  setActiveInterpretationOutputAgoraChannelId(
                    agoraChannelId || null
                  );
                }
              }}
            >
              <div className="flex items-center justify-center gap-1">
                <p className="ml-2">{name}</p>
              </div>
            </MenuItem>
          );
        }
      )}

      <MenuDivider />

      <MenuItem
        disabled={
          joiningAgoraChannel ||
          leavingAgoraChannel ||
          !activeInterpretationOutputAgoraChannelId
        }
        type="checkbox"
        checked={muteOriginalAudioWhenInterpretationActive}
        className={`px-3 py-2  hover:bg-custom-blue-50 text-primary`}
        onClick={async () => {
          setMuteOriginalAudioWhenInterpretationActive((s) => !s);
        }}
      >
        <div className="flex items-center justify-center gap-1">
          {/* <MdVolumeOff /> */}

          <p className="ml-2">Mute original</p>
        </div>
      </MenuItem>
    </Menu>
  );
};

export default InterpretationsContainer;
