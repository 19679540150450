import { momentostreamApiServerBaseUrl } from "../../utils/constants";
import { safeParseJson } from "../../utils/safeParseJson";

export const parseVideoShareAsset = (asset: fetchedBroadcastAssetType) => {
  return asset;
};

export const parseFileShareAsset = (asset: fetchedBroadcastAssetType) => {
  return asset;
};

const getBroadcastAssets = async ({
  broadcastId,
}: {
  broadcastId: string;
}): Promise<{ success: boolean; assets?: broadcastAssetsType }> => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/get-broadcast-assets/${broadcastId}`;

    const res = await fetch(url);

    const json = await res.json();

    if (json.success) {
      return {
        success: true,

        assets: json.data?.map((asset: fetchedBroadcastAssetType) => {
          const metaData = asset?.metaData
            ? safeParseJson(asset?.metaData)
            : null;

          return { ...asset, metaData };
        }) as broadcastAssetsType,
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default getBroadcastAssets;
