// import { usePubSub } from "@videosdk.live/react-sdk";
import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { defaultAvatarImage } from "../../static/images";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateSpeakerCanTakeAction from "./useValidateSpeakerCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useParticipantAvatar = (participantId: string) => {
  const { modifiedParticipantsAvatars } = useAppContext();

  // const { publish: modifyAvatarPublish } = usePubSub(
  //   appPubSubTopics.MODIFY_PARTICIPANT_AVATAR
  // );

  const { publish: modifyAvatarPublish } = useAppSingalingPublish(
    appPubSubTopics.MODIFY_PARTICIPANT_AVATAR
  );

  const { avatarRemoteUrl, isSet } = useMemo(() => {
    const modifiedAvatar = modifiedParticipantsAvatars.find(
      ({ participantId: _participantId }) => _participantId === participantId
    );

    return {
      avatarRemoteUrl: modifiedAvatar
        ? modifiedAvatar.avatarRemoteUrl
        : defaultAvatarImage,
      isSet: !!modifiedAvatar?.avatarRemoteUrl,
    };
  }, [modifiedParticipantsAvatars, participantId]);

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  const changeAvatar = async ({
    avatarRemoteUrl,
    isOld,
  }: {
    avatarRemoteUrl: string;
    isOld?: boolean;
  }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    modifyAvatarPublish(
      JSON.stringify({ participantId, avatarRemoteUrl, isOld })
      // { persist: true }
    );
  };

  return { changeAvatar, avatarRemoteUrl, isSet };
};

export default useParticipantAvatar;
