import React, { useCallback, useEffect, useRef } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { mediaStreamKinds } from "../streams/QueuedStreamsListnerAllPeers";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import useAppRtcLocalParticipantMediaStats from "../../appRtc/useAppRtcLocalParticipantMediaStats";
import sleep from "../../utils/sleep";

const AllParticipantsMediaStatsListner = () => {
  const { setAllParticipantsMediaStats } = useAppContext();

  const { localParticipantId } = useLocalParticipantId();

  const {
    webcamOn: sdkWebcamOn,
    micOn: sdkMicOn,
    screenShareVideoOn: sdkScreenShareVideoOn,
    screenShareAudioOn: sdkScreenShareAudioOn,
    webcamTrack,
    micTrack,
    screenShareVideoTrack,
    screenShareAudioTrack,
  } = useAppRtcLocalParticipantMediaStats();

  const localParticipantIdRef = useRef(localParticipantId);

  useAppSingalingSubscribe(
    appPubSubTopics.ALL_PARTICIPANTS_MEDIA_STATS,
    async ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { participantId, stats } = message;

      setAllParticipantsMediaStats((s) => {
        const _s = new Map(s);

        _s.set(participantId, stats);

        return _s;
      });

      const timestamp = new Date().getTime();

      const sdkMicOn = stats[mediaStreamKinds.audio];
      const sdkWebcamOn = stats[mediaStreamKinds.video];
      const sdkScreenShareVideoOn = stats[mediaStreamKinds.share];
      const sdkScreenShareAudioOn = stats[mediaStreamKinds.shareAudio];

      appEventEmitter.emit(
        appEventEmitterEvents.PARTICIPANT_MEDIA_STATS_CHANGED,
        {
          enabled: sdkMicOn,
          participantId,
          kind: mediaStreamKinds.audio,
          timestamp,
        }
      );

      await sleep(100);

      appEventEmitter.emit(
        appEventEmitterEvents.PARTICIPANT_MEDIA_STATS_CHANGED,
        {
          enabled: sdkWebcamOn,
          participantId,
          kind: mediaStreamKinds.video,
          timestamp,
        }
      );

      await sleep(100);

      appEventEmitter.emit(
        appEventEmitterEvents.PARTICIPANT_MEDIA_STATS_CHANGED,
        {
          enabled: sdkScreenShareVideoOn,
          participantId,
          kind: mediaStreamKinds.share,
          timestamp,
        }
      );

      await sleep(100);

      appEventEmitter.emit(
        appEventEmitterEvents.PARTICIPANT_MEDIA_STATS_CHANGED,
        {
          enabled: sdkScreenShareAudioOn,
          participantId,
          kind: mediaStreamKinds.shareAudio,
          timestamp,
        }
      );
    }
  );

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.ALL_PARTICIPANTS_MEDIA_STATS
  );

  const publishRef = useRef<appSingalingPublishType>(publish);
  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  const resetMediaStats = async ({
    sdkMicOn,
    sdkWebcamOn,
    sdkScreenShareVideoOn,
    sdkScreenShareAudioOn,
  }: {
    sdkMicOn?: boolean;
    sdkWebcamOn?: boolean;
    sdkScreenShareVideoOn?: boolean;
    sdkScreenShareAudioOn?: boolean;
  }) => {
    const localParticipantId = localParticipantIdRef.current;
    const publish = publishRef.current;

    publish(
      JSON.stringify({
        participantId: localParticipantId,
        stats: {
          [mediaStreamKinds.audio]: sdkMicOn,
          [mediaStreamKinds.video]: sdkWebcamOn,
          [mediaStreamKinds.share]: sdkScreenShareVideoOn,
          [mediaStreamKinds.shareAudio]: sdkScreenShareAudioOn,
        },
      })
    );
  };

  const micTrackRef = useRef(micTrack);
  const webcamTrackRef = useRef(webcamTrack);
  const screenShareVideoTrackRef = useRef(screenShareVideoTrack);
  const screenShareAudioTrackRef = useRef(screenShareAudioTrack);
  const sdkMicOnRef = useRef(sdkMicOn);
  const sdkWebcamOnRef = useRef(sdkWebcamOn);
  const sdkScreenShareVideoOnRef = useRef(sdkScreenShareVideoOn);
  const sdkScreenShareAudioOnRef = useRef(sdkScreenShareAudioOn);

  useEffect(() => {
    micTrackRef.current = micTrack;
  }, [micTrack]);
  useEffect(() => {
    webcamTrackRef.current = webcamTrack;
  }, [webcamTrack]);
  useEffect(() => {
    screenShareVideoTrackRef.current = screenShareVideoTrack;
  }, [screenShareVideoTrack]);
  useEffect(() => {
    screenShareAudioTrackRef.current = screenShareAudioTrack;
  }, [screenShareAudioTrack]);
  useEffect(() => {
    sdkMicOnRef.current = sdkMicOn;
  }, [sdkMicOn]);
  useEffect(() => {
    sdkWebcamOnRef.current = sdkWebcamOn;
  }, [sdkWebcamOn]);
  useEffect(() => {
    sdkScreenShareVideoOnRef.current = sdkScreenShareVideoOn;
  }, [sdkScreenShareVideoOn]);
  useEffect(() => {
    sdkScreenShareAudioOnRef.current = sdkScreenShareAudioOn;
  }, [sdkScreenShareAudioOn]);

  const checkAndSetMediaStats = useCallback(async () => {
    const micTrack = micTrackRef.current;
    const webcamTrack = webcamTrackRef.current;
    const screenShareVideoTrack = screenShareVideoTrackRef.current;
    const screenShareAudioTrack = screenShareAudioTrackRef.current;
    const sdkMicOn = sdkMicOnRef.current;
    const sdkWebcamOn = sdkWebcamOnRef.current;
    const sdkScreenShareVideoOn = sdkScreenShareVideoOnRef.current;
    const sdkScreenShareAudioOn = sdkScreenShareAudioOnRef.current;

    const _sdkMicOn = !!(sdkMicOn && micTrack);
    const _sdkWebcamOn = !!(sdkWebcamOn && webcamTrack);
    const _sdkScreenShareVideoOn = !!(
      sdkScreenShareVideoOn && screenShareVideoTrack
    );
    const _sdkScreenShareAudioOn = !!(
      sdkScreenShareAudioOn && screenShareAudioTrack
    );

    await resetMediaStats({
      sdkMicOn: _sdkMicOn,
      sdkWebcamOn: _sdkWebcamOn,
      sdkScreenShareVideoOn: _sdkScreenShareVideoOn,
      sdkScreenShareAudioOn: _sdkScreenShareAudioOn,
    });
  }, []);

  useEffect(() => {
    checkAndSetMediaStats();
  }, [
    checkAndSetMediaStats,
    micTrack,
    webcamTrack,
    screenShareVideoTrack,
    screenShareAudioTrack,
    sdkMicOn,
    sdkWebcamOn,
    sdkScreenShareVideoOn,
    sdkScreenShareAudioOn,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkAndSetMediaStats();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <React.Fragment />;
};

export default AllParticipantsMediaStatsListner;
