import React, { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { getPrivateChatGroupMessageTopicById } from "../../utils/pubSubTopics";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const PrivateChatGroupMessagesListner = ({ id }: { id: string }) => {
  const { studioUserId } = useAppConfigContext();
  const { setPrivateChatGroupsMessages } = useAppContext();

  const studioUserIdRef = useRef(studioUserId);

  useEffect(() => {
    studioUserIdRef.current = studioUserId;
  }, [studioUserId]);

  useAppSingalingSubscribe(
    getPrivateChatGroupMessageTopicById(id),
    ({ message: receivedMessage, participantId: sdkParticipantId }) => {
      const message = safeParseJson(receivedMessage);

      const { payload } = message;

      const { senderId, id: messageId, type, text } = payload;

      setPrivateChatGroupsMessages((s) => {
        const privateChatGroupsMessages = new Map(s);

        const messages = privateChatGroupsMessages.get(id)
          ? [...(privateChatGroupsMessages.get(id) || [])]
          : [];

        messages.push({
          id: messageId,
          timestamp: new Date().getTime(),
          type,
          text,
          participantId: senderId,
          isSelf: studioUserIdRef.current === senderId,
          sdkParticipantId,
        } as {
          id: string;
          timestamp: number;
          type: string;
          text: string;
          participantId: string;
          isSelf: boolean;
          sdkParticipantId: string;
          isNextSelfSender: boolean;
          previousSameSender: boolean;
        });

        privateChatGroupsMessages.set(
          id,
          messages.map((message, i) => {
            const isNextSelfSender =
              messages[i + 1]?.participantId === message.participantId;

            const previousSameSender =
              messages[i - 1]?.participantId === message.participantId;

            return {
              ...message,
              isNextSelfSender,
              previousSameSender,
            };
          })
        );

        return privateChatGroupsMessages;
      });
    }
  );

  return <React.Fragment />;
};

export default PrivateChatGroupMessagesListner;
