import { LocalTrackPublication } from "livekit-client";
import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { safeParseJson } from "../../utils/safeParseJson";
import {
  checkIsMicLivekitPublication,
  checkIsScreenShareAudioLivekitPublication,
  checkIsScreenShareVideoLivekitPublication,
  checkIsWebcamLivekitPublication,
} from "./useLivekitRtcRemoteParticipantMediaStats";
import useAppRtcAllParticipantsExtraWebcamStreams from "./useLivekitRtcAllParticipantsExtraWebcamStreams";

const useLivekitRtcLocalParticipantMediaStats = (): {
  __webcamOn: boolean;
  __webcamTrack?: MediaStreamTrack;
  __micOn: boolean;
  __micTrack?: MediaStreamTrack;
  __webcamTrackPublication?: LocalTrackPublication;
  __micTrackPublication?: LocalTrackPublication;
  webcamOn: boolean;
  micOn: boolean;
  screenShareVideoOn: boolean;
  screenShareAudioOn: boolean;
  webcamTrack?: MediaStreamTrack;
  micTrack?: MediaStreamTrack;
  screenShareVideoTrack?: MediaStreamTrack;
  screenShareAudioTrack?: MediaStreamTrack;
  webcamTrackPublication?: LocalTrackPublication;
  micTrackPublication?: LocalTrackPublication;
  screenShareVideoTrackPublication?: LocalTrackPublication;
  screenShareAudioTrackPublication?: LocalTrackPublication;
  inputFileVideoShareStreamPublications: {
    id: string;
    fileObjectUrl: string;
    inputFileVideoSourceElementId: string;
    videoTrack?: MediaStreamTrack;
    audioTrack?: MediaStreamTrack;
    videoPublication?: LocalTrackPublication;
    audioPublication?: LocalTrackPublication;
  }[];
  extraWebcamStreams: {
    extraWebcamId: string;
    videoTrack?: MediaStreamTrack;
  }[];
} => {
  const appContext = useAppContext();

  const {
    livekitLocalParticipantTrackPublications,
  }: { livekitLocalParticipantTrackPublications: LocalTrackPublication[] } =
    appContext;

  const {
    localParticipantId,
    activeInputFileVideoShareStreamAllParticipants,
    localMicOn,
    localWebcamOn,
  } = appContext;

  const participantActiveInputFileVideoShareStreams = useMemo(() => {
    return activeInputFileVideoShareStreamAllParticipants.filter(
      ({ participantId: _participantId }) =>
        localParticipantId === _participantId
    );
  }, [activeInputFileVideoShareStreamAllParticipants, localParticipantId]);

  const { allParticipantsExtraWebcamStreams } =
    useAppRtcAllParticipantsExtraWebcamStreams();

  const {
    __webcamOn,
    __webcamTrack,
    __micOn,
    __micTrack,
    __webcamTrackPublication,
    __micTrackPublication,
    webcamOn,
    webcamTrack,
    micOn,
    micTrack,
    screenShareVideoOn,
    screenShareVideoTrack,
    screenShareAudioOn,
    screenShareAudioTrack,
    webcamTrackPublication,
    micTrackPublication,
    screenShareVideoTrackPublication,
    screenShareAudioTrackPublication,
    inputFileVideoShareStreamPublications,
    extraWebcamStreams,
  }: {
    __webcamOn: boolean;
    __webcamTrack?: MediaStreamTrack;
    __micOn: boolean;
    __micTrack?: MediaStreamTrack;
    __webcamTrackPublication?: LocalTrackPublication;
    __micTrackPublication?: LocalTrackPublication;

    webcamOn: boolean;
    webcamTrack?: MediaStreamTrack;
    micOn: boolean;
    micTrack?: MediaStreamTrack;
    webcamTrackPublication?: LocalTrackPublication;
    micTrackPublication?: LocalTrackPublication;

    screenShareVideoOn: boolean;
    screenShareVideoTrack?: MediaStreamTrack;
    screenShareAudioOn: boolean;
    screenShareAudioTrack?: MediaStreamTrack;
    screenShareVideoTrackPublication?: LocalTrackPublication;
    screenShareAudioTrackPublication?: LocalTrackPublication;
    inputFileVideoShareStreamPublications: {
      id: string;
      fileObjectUrl: string;
      inputFileVideoSourceElementId: string;
      videoTrack?: MediaStreamTrack;
      audioTrack?: MediaStreamTrack;
      videoPublication?: LocalTrackPublication;
      audioPublication?: LocalTrackPublication;
    }[];
    extraWebcamStreams: {
      extraWebcamId: string;
      videoTrack?: MediaStreamTrack;
    }[];
  } = useMemo(() => {
    let webcamOn: boolean = false;
    let webcamTrack: MediaStreamTrack | undefined = undefined;
    let micOn: boolean = false;
    let micTrack: MediaStreamTrack | undefined = undefined;
    let screenShareVideoOn: boolean = false;
    let screenShareVideoTrack: MediaStreamTrack | undefined = undefined;
    let screenShareAudioOn: boolean = false;
    let screenShareAudioTrack: MediaStreamTrack | undefined = undefined;
    let webcamTrackPublication: LocalTrackPublication | undefined = undefined;
    let micTrackPublication: LocalTrackPublication | undefined = undefined;
    let screenShareVideoTrackPublication: LocalTrackPublication | undefined =
      undefined;
    let screenShareAudioTrackPublication: LocalTrackPublication | undefined =
      undefined;
    const inputFileVideoShareStreamPublications: {
      id: string;
      fileObjectUrl: string;
      inputFileVideoSourceElementId: string;
      videoTrack?: MediaStreamTrack;
      audioTrack?: MediaStreamTrack;
      videoPublication?: LocalTrackPublication;
      audioPublication?: LocalTrackPublication;
    }[] = [];

    livekitLocalParticipantTrackPublications.forEach((publication) => {
      const { track, trackSid } = publication;

      if (checkIsWebcamLivekitPublication(publication)) {
        webcamOn = true;

        if (track) {
          webcamTrack = track.mediaStreamTrack;
        }

        webcamTrackPublication = publication;
      } else if (checkIsMicLivekitPublication(publication)) {
        // if (!track?.isMuted) { // commented because it was not unpublishing when forced
        micOn = true;

        if (track) {
          micTrack = track.mediaStreamTrack;
        }

        micTrackPublication = publication;
        // }
      } else if (checkIsScreenShareVideoLivekitPublication(publication)) {
        screenShareVideoOn = true;

        if (track) {
          screenShareVideoTrack = track.mediaStreamTrack;
        }

        screenShareVideoTrackPublication = publication;
      } else if (checkIsScreenShareAudioLivekitPublication(publication)) {
        screenShareAudioOn = true;

        if (track) {
          screenShareAudioTrack = track.mediaStreamTrack;
        }

        screenShareAudioTrackPublication = publication;
      } else {
        participantActiveInputFileVideoShareStreams.forEach(
          ({ rtcClientMetadata }) => {
            const {
              id,
              fileObjectUrl,
              inputFileVideoSourceElementId,
              videoPublicationTrackSid,
              audioPublicationTrackSid,
            } = safeParseJson(rtcClientMetadata);

            if (trackSid === videoPublicationTrackSid) {
              const indexFound =
                inputFileVideoShareStreamPublications.findIndex(
                  ({ id: _id }) => id === _id
                );

              if (indexFound === -1) {
                inputFileVideoShareStreamPublications.push({
                  id,
                  fileObjectUrl,
                  inputFileVideoSourceElementId,
                  videoPublication: publication,
                  videoTrack: track?.mediaStreamTrack,
                });
              } else {
                inputFileVideoShareStreamPublications[indexFound] = {
                  ...inputFileVideoShareStreamPublications[indexFound],
                  videoPublication: publication,
                  videoTrack: track?.mediaStreamTrack,
                };
              }
            } else if (trackSid === audioPublicationTrackSid) {
              const indexFound =
                inputFileVideoShareStreamPublications.findIndex(
                  ({ id: _id }) => id === _id
                );

              if (indexFound === -1) {
                inputFileVideoShareStreamPublications.push({
                  id,
                  fileObjectUrl,
                  inputFileVideoSourceElementId,
                  audioPublication: publication,
                  audioTrack: track?.mediaStreamTrack,
                });
              } else {
                inputFileVideoShareStreamPublications[indexFound] = {
                  ...inputFileVideoShareStreamPublications[indexFound],
                  audioPublication: publication,
                  audioTrack: track?.mediaStreamTrack,
                };
              }
            }
          }
        );
      }
    });

    const webcamObj = { webcamOn, webcamTrack, webcamTrackPublication };

    const micObj = {
      micOn,
      micTrack,
      micTrackPublication,
    };

    return {
      //
      __webcamOn: webcamObj.webcamOn,
      __webcamTrack: webcamObj.webcamTrack,
      __webcamTrackPublication: webcamObj.webcamTrackPublication,
      //
      __micOn: micObj.micOn,
      __micTrack: micObj.micTrack,
      __micTrackPublication: micObj.micTrackPublication,

      ...(localWebcamOn
        ? webcamObj
        : {
            webcamOn: false,
            webcamTrack: undefined,
            webcamTrackPublication: undefined,
          }),
      ...(localMicOn
        ? micObj
        : {
            micOn: false,
            micTrack: undefined,
            micTrackPublication: undefined,
          }),

      //
      screenShareVideoOn,
      screenShareVideoTrack,
      screenShareAudioOn,
      screenShareAudioTrack,
      screenShareVideoTrackPublication,
      screenShareAudioTrackPublication,
      //
      inputFileVideoShareStreamPublications,
      extraWebcamStreams:
        allParticipantsExtraWebcamStreams.get(localParticipantId) || [],
    };
  }, [
    localParticipantId,
    localMicOn,
    localWebcamOn,
    livekitLocalParticipantTrackPublications,
    participantActiveInputFileVideoShareStreams,
    allParticipantsExtraWebcamStreams,
  ]);

  return {
    __webcamOn,
    __webcamTrack,
    __micOn,
    __micTrack,
    __webcamTrackPublication,
    __micTrackPublication,
    webcamOn,
    webcamTrack,
    micOn,
    micTrack,
    screenShareVideoOn,
    screenShareVideoTrack,
    screenShareAudioOn,
    screenShareAudioTrack,
    webcamTrackPublication,
    micTrackPublication,
    screenShareVideoTrackPublication,
    screenShareAudioTrackPublication,
    inputFileVideoShareStreamPublications,
    extraWebcamStreams,
  };
};

export default useLivekitRtcLocalParticipantMediaStats;
