import { customBlueColor } from "../../utils/colors";

const GroupIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101.223 69.818"
    enableBackground="new 0 0 101.223 69.818"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        style={{ fill: customBlueColor[100] }}
        d="M4.578 18.485H49.03v35.723H4.578z"
      />
      <path
        style={{ fill: "#fff" }}
        d="M26.804 39.815c-4.996 0-9.046 3.044-9.046 6.799h18.093c0-3.756-4.051-6.799-9.047-6.799z"
      />
      <circle style={{ fill: "#fff" }} cx={26.804} cy={31.726} r={5.711} />
      <path
        style={{ fill: customBlueColor[100] }}
        d="M52.193 18.485h44.452v35.723H52.193z"
      />
      <path
        style={{ fill: "#fff" }}
        d="M74.419 39.815c-4.996 0-9.046 3.044-9.046 6.799h18.093c-.001-3.756-4.051-6.799-9.047-6.799z"
      />
      <circle style={{ fill: "#fff" }} cx={74.419} cy={31.726} r={5.711} />
    </g>
  </svg>
);

export default GroupIcon;
