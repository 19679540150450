// import { usePubSub } from "@videosdk.live/react-sdk";
import React from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const VideosReadyToBePlayedListner = () => {
  const { setWaitingForVideosRemoteUrlReadyToBePlayed } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.VIDEOS_READY_TO_BE_PLAYED,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { remoteUrl, id } = message;

      setWaitingForVideosRemoteUrlReadyToBePlayed((s) => [
        ...s,
        { remoteUrl, id },
      ]);
    }
  );

  return <React.Fragment />;
};

export default VideosReadyToBePlayedListner;
