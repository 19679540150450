// import useIsBigScreen from "./useIsBigScreen";
// import useIsDesktopOrLaptop from "./useIsDesktopOrLaptop";
import useIsPortrait from "./useIsPortrait";
import useIsTabletOrMobile from "./useIsTabletOrMobile";

const useIsMobilePortrait = () => {
  const { isPortrait } = useIsPortrait();
  // const { isDesktopOrLaptop } = useIsDesktopOrLaptop();
  // const { isBigScreen } = useIsBigScreen();
  const { isTabletOrMobile } = useIsTabletOrMobile();

  return {
    isMobilePortrait: isTabletOrMobile && isPortrait,
  };
};

export default useIsMobilePortrait;
