import { LocalTrackPublication, RemoteTrackPublication } from "livekit-client";
import { useAppContext } from "../../contexts/appContextDef";
import { useMemo } from "react";
import { checkIsExtraWebcamLivekitPublication } from "./useLivekitRtcRemoteParticipantMediaStats";

const useLivekitRtcAllParticipantsExtraWebcamStreams = (argsAppContext?: {
  livekitRemoteParticipantsTrackPublications: Map<
    string,
    RemoteTrackPublication[]
  >;
  livekitLocalParticipantTrackPublications: LocalTrackPublication[];
  joinedParticipants: joinedParticipantsType;
  localParticipantId: string;
}) => {
  const _appContext = useAppContext();

  const appContext = useMemo(
    () => argsAppContext || _appContext,
    [argsAppContext, _appContext]
  );

  const {
    livekitLocalParticipantTrackPublications,
    livekitRemoteParticipantsTrackPublications,
  }: {
    livekitRemoteParticipantsTrackPublications: Map<
      string,
      RemoteTrackPublication[]
    >;
    livekitLocalParticipantTrackPublications: LocalTrackPublication[];
  } = appContext;

  const { joinedParticipants, localParticipantId } = appContext;

  const { allParticipantsExtraWebcamStreams } = useMemo(() => {
    const remoteJoinedParticipants = joinedParticipants.filter(
      ({ participantId }) => participantId !== localParticipantId
    );

    const participantsExtraWebcamStreams = remoteJoinedParticipants.map(
      ({ participantId }) => {
        const livekitRemoteParticipantTrackPublications =
          livekitRemoteParticipantsTrackPublications.get(participantId);

        if (livekitRemoteParticipantTrackPublications) {
          const extraWebcamPublications =
            livekitRemoteParticipantTrackPublications.filter((publication) =>
              checkIsExtraWebcamLivekitPublication(publication)
            );

          const uniqueExtraWebcamPublications: {
            extraWebcamId: string;
            timestamp: number;
            publication: RemoteTrackPublication;
            deviceId?: string;
          }[] = [];

          extraWebcamPublications.forEach((publication) => {
            const { trackName } = publication;
            const splitted = trackName.split("_");

            const extraWebcamId = splitted[2];
            const timestamp = parseInt(splitted[3]);

            const foundIndex = uniqueExtraWebcamPublications.findIndex(
              ({ extraWebcamId: _extraWebcamId }) =>
                _extraWebcamId === extraWebcamId
            );

            const found = uniqueExtraWebcamPublications[foundIndex];

            if (found) {
              if (timestamp > found.timestamp) {
                uniqueExtraWebcamPublications[foundIndex] = {
                  extraWebcamId,
                  timestamp,
                  publication,
                };
              }
            } else {
              uniqueExtraWebcamPublications.push({
                extraWebcamId,
                timestamp,
                publication,
              });
            }
          });

          const extraWebcamStreams = uniqueExtraWebcamPublications.map(
            ({ extraWebcamId, publication, timestamp }) => ({
              extraWebcamId,
              videoTrack: publication?.track?.mediaStreamTrack,
              timestamp,
            })
          );

          return { extraWebcamStreams, participantId };
        } else {
          return { extraWebcamStreams: [], participantId };
        }
      }
    );

    const localParticipantExtraWebcamPublications =
      livekitLocalParticipantTrackPublications.filter((publication) =>
        checkIsExtraWebcamLivekitPublication(publication)
      );

    const localParticipantUniqueExtraWebcamPublications: {
      extraWebcamId: string;
      timestamp: number;
      publication: LocalTrackPublication;
      deviceId: string;
    }[] = [];

    localParticipantExtraWebcamPublications.forEach((publication) => {
      const { trackName } = publication;
      const splitted = trackName.split("_");

      const deviceId = publication?.track?.constraints?.deviceId as string;

      const extraWebcamId = splitted[2];
      const timestamp = parseInt(splitted[3]);

      const foundIndex =
        localParticipantUniqueExtraWebcamPublications.findIndex(
          ({ extraWebcamId: _extraWebcamId }) =>
            _extraWebcamId === extraWebcamId
        );

      const found = localParticipantUniqueExtraWebcamPublications[foundIndex];

      if (found && timestamp > found.timestamp) {
        localParticipantUniqueExtraWebcamPublications[foundIndex] = {
          extraWebcamId,
          timestamp,
          publication,
          deviceId,
        };
      } else {
        localParticipantUniqueExtraWebcamPublications.push({
          extraWebcamId,
          timestamp,
          publication,
          deviceId,
        });
      }
    });

    const localParticipantExtraWebcamStreams =
      localParticipantUniqueExtraWebcamPublications.map(
        ({ extraWebcamId, publication, timestamp, deviceId }) => ({
          extraWebcamId,
          videoTrack: publication?.track?.mediaStreamTrack,
          timestamp,
          deviceId,
        })
      );

    participantsExtraWebcamStreams.push({
      extraWebcamStreams: localParticipantExtraWebcamStreams,
      participantId: localParticipantId,
    });

    const participantsExtraWebcamStreamsMap: allParticipantsExtraWebcamStreamsType =
      new Map();

    participantsExtraWebcamStreams.forEach(
      ({ extraWebcamStreams, participantId }) => {
        participantsExtraWebcamStreamsMap.set(
          participantId,
          extraWebcamStreams
        );
      }
    );

    return {
      allParticipantsExtraWebcamStreams: participantsExtraWebcamStreamsMap,
    };
  }, [
    joinedParticipants,
    localParticipantId,
    livekitLocalParticipantTrackPublications,
    livekitRemoteParticipantsTrackPublications,
  ]);

  return { allParticipantsExtraWebcamStreams };
};

export default useLivekitRtcAllParticipantsExtraWebcamStreams;
