// import { usePubSub } from "@videosdk.live/react-sdk";
import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const BrandMuteGuestsWhenAudioPlaysListner = () => {
  const { setMuteGuestsWhenAudioPlays } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.MUTE_GUESTS_WHEN_AUDIO_PLAYS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      setMuteGuestsWhenAudioPlays(message?.muteGuestsWhenAudioPlays);
    }
  );

  return <React.Fragment />;
};

export default BrandMuteGuestsWhenAudioPlaysListner;
