import React from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const privateChatGroupActions = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  RENAME: "RENAME",
  ADD_PARTICIPANTS: "ADD_PARTICIPANTS",
  REMOVE_PARTICIPANTS: "REMOVE_PARTICIPANTS",
  CHANGE_VISIBILITY_STATE: "CHANGE_VISIBILITY_STATE",
};

const PrivateChatGroupListner = () => {
  const { setPrivateChatGroups } = useAppContext();

  const _handleCreate = ({
    id,
    name,
    participantIds,
  }: {
    id: string;
    name: string;
    participantIds: string[];
  }) => {
    setPrivateChatGroups((s) => [
      ...s,
      { id, name, isVisible: true, participantIds },
    ]);
  };

  const _handleDelete = ({ id }: { id: string }) => {
    setPrivateChatGroups((s) => [...s.filter(({ id: _id }) => _id !== id)]);
  };

  const _handleAddParticipants = ({
    id,
    participantIds,
  }: {
    id: string;
    participantIds: string[];
  }) => {
    setPrivateChatGroups((s) => {
      const groups = [...s];

      const groupIndex = groups.findIndex(({ id: _id }) => _id === id);

      if (groupIndex !== -1) {
        groups[groupIndex] = {
          ...groups[groupIndex],
          participantIds: [
            ...groups[groupIndex]["participantIds"],
            ...participantIds,
          ],
        };
      }

      return groups;
    });
  };

  const _handleRemoveParticipants = ({
    id,
    participantIds,
  }: {
    id: string;
    participantIds: string[];
  }) => {
    setPrivateChatGroups((s) => {
      const groups = [...s];

      const groupIndex = groups.findIndex(({ id: _id }) => _id === id);

      if (groupIndex !== -1) {
        groups[groupIndex] = {
          ...groups[groupIndex],
          participantIds: [
            ...groups[groupIndex]["participantIds"].filter(
              (participantId) => !participantIds.includes(participantId)
            ),
          ],
        };
      }

      return groups;
    });
  };

  const _handleRename = ({ id, name }: { id: string; name: string }) => {
    setPrivateChatGroups((s) => {
      const groups = [...s];

      const groupIndex = groups.findIndex(({ id: _id }) => _id === id);

      if (groupIndex !== -1) {
        groups[groupIndex] = { ...groups[groupIndex], name };
      }

      return groups;
    });
  };

  const _setVisibilityState = ({
    id,
    isVisible,
  }: {
    id: string;
    isVisible: boolean;
  }) => {
    setPrivateChatGroups((s) => {
      const groups = [...s];

      const groupIndex = groups.findIndex(({ id: _id }) => _id === id);

      if (groupIndex !== -1) {
        groups[groupIndex] = { ...groups[groupIndex], isVisible };
      }

      return groups;
    });
  };

  useAppSingalingSubscribe(
    appPubSubTopics.PRIVATE_CHAT_GROUP,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { action, payload } = message;

      switch (action) {
        case privateChatGroupActions.CREATE:
          _handleCreate(payload);
          break;

        case privateChatGroupActions.DELETE:
          _handleDelete(payload);
          break;

        case privateChatGroupActions.RENAME:
          _handleRename(payload);
          break;

        case privateChatGroupActions.ADD_PARTICIPANTS:
          _handleAddParticipants(payload);
          break;

        case privateChatGroupActions.REMOVE_PARTICIPANTS:
          _handleRemoveParticipants(payload);
          break;

        case privateChatGroupActions.CHANGE_VISIBILITY_STATE:
          _setVisibilityState(payload);
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default PrivateChatGroupListner;
