// import { usePubSub } from "@videosdk.live/react-sdk";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";

const useDownloadPart = () => {
  // const { publish } = usePubSub(appPubSubTopics.DOWNLOAD_PART);
  const { publish } = useAppSingalingPublish(appPubSubTopics.DOWNLOAD_PART);

  const downloadPart = (remoteUrl: string) => {
    publish(
      JSON.stringify({ remoteUrl })
      // , { persist: false }
    );
  };

  return { downloadPart };
};

export default useDownloadPart;
