import { useAppContext } from "../../contexts/appContextDef";
import useEnableWaitingRoom from "../../hooks/activeBrand/useEnableWaitingRoom";
import useNonHostCanSeeParticipantsCount from "../../hooks/activeBrand/useNonHostCanSeeParticipantsCount";
import useRaiseHandSettings from "../../hooks/appState/useRaiseHandSettings";
import useShowAudioAvatars from "../../hooks/activeBrand/useShowAudioAvatars";
import Tooltip from "../../components/Tooltip";
import { MdHelpOutline } from "react-icons/md";

const AppGeneralSettingsContainer = () => {
  const {
    nonHostCanSeeParticipantsCount,
    waitingRoomEnabled,
    raiseHandEnabled,
    showAudioAvatars,
  } = useAppContext();

  const { disable: disableRaiseHand, enable: enabledRaiseHand } =
    useRaiseHandSettings();

  const { disable: disableWaitingRoom, enable: enableWaitingRoom } =
    useEnableWaitingRoom();

  const {
    disable: disableNonHostCanSeeParticipantsCount,
    enable: enableNonHostCanSeeParticipantsCount,
  } = useNonHostCanSeeParticipantsCount();

  const {
    disable: disableUseShowAudioAvatars,
    enable: enableUseShowAudioAvatars,
  } = useShowAudioAvatars();

  return (
    <div>
      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">Allow users to raise hand</span>
          <input
            onChange={() => {
              if (raiseHandEnabled) {
                disableRaiseHand({ force: true });
              } else {
                enabledRaiseHand({ force: true });
              }
            }}
            type="checkbox"
            checked={raiseHandEnabled}
            className="checkbox checkbox-primary"
          />
        </label>
      </div>

      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">
            Allow participants see participants list
          </span>
          <input
            onChange={() => {
              if (nonHostCanSeeParticipantsCount) {
                disableNonHostCanSeeParticipantsCount({ force: true });
              } else {
                enableNonHostCanSeeParticipantsCount({ force: true });
              }
            }}
            type="checkbox"
            checked={nonHostCanSeeParticipantsCount}
            className="checkbox checkbox-primary"
          />
        </label>
      </div>

      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">Enable waiting room</span>
          <input
            onChange={() => {
              if (waitingRoomEnabled) {
                disableWaitingRoom({ force: true });
              } else {
                enableWaitingRoom({ force: true });
              }
            }}
            type="checkbox"
            checked={waitingRoomEnabled}
            className="checkbox checkbox-primary"
          />
        </label>
      </div>

      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="flex items-center justify-center">
            <span className="label-text">Enable Audio avatars</span>

            <Tooltip
              title={
                "Show an avatar when a user's camera is off. Uncheck this if you want users to be completely hidden when their camera is off. This will only apply for Studio mode."
              }
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="rounded-full p-0.5 ml-1"
              >
                <MdHelpOutline size={18} />
              </button>
            </Tooltip>
          </span>
          <input
            onChange={() => {
              if (showAudioAvatars) {
                disableUseShowAudioAvatars({ force: true });
              } else {
                enableUseShowAudioAvatars({ force: true });
              }
            }}
            type="checkbox"
            checked={showAudioAvatars}
            className="checkbox checkbox-primary"
          />
        </label>
      </div>
    </div>
  );
};

export default AppGeneralSettingsContainer;
