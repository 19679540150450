import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const createLinkedinBroadcast = async ({
  destinationId,
  broadcastId,
}: {
  destinationId: number;
  broadcastId: string;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/create-linkedin-broadcast`;

    const body = JSON.stringify({ destinationId, broadcastId });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true, data: json.data };
    } else {
      return { success: false, message: json.message };
    }
  } catch (error) {
    return { success: false };
  }
};

export default createLinkedinBroadcast;
