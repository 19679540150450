// import { usePubSub } from "@videosdk.live/react-sdk";
import React from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const highlightedChatActions = {
  ACTIVATE: "ACTIVATE",
  DEACTIVATE: "DEACTIVATE",
};

const HighlightedChatListner = () => {
  const { setHighlightedChat } = useAppContext();

  const _handleHighlightedChat = ({
    action,
    payload,
  }: {
    action: string;
    payload: {
      id: string;
      text: string;
      timestamp: number;
      senderName: string;
      initials: string;
      socialPlatform: string[];
      momentoSent: boolean;
    };
  }) => {
    switch (action) {
      case highlightedChatActions.ACTIVATE:
        setHighlightedChat({ enabled: true, ...payload });
        break;

      case highlightedChatActions.DEACTIVATE:
        setHighlightedChat({ enabled: false });

        break;

      default:
    }
  };

  useAppSingalingSubscribe(
    appPubSubTopics.HIGHLIGHTED_CHAT,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      _handleHighlightedChat(message);
    }
  );

  return <React.Fragment />;
};

export default HighlightedChatListner;
