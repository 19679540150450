import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { getBrandVideoClipsTopicById } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const videoClipsActions = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  RENAME: "RENAME",
  SORT: "SORT",
};

const BrandVideoClipsListner = ({ id: brandId }: { id: string }) => {
  const { setVideoClips, setSortedVideoClipIds } = useAppContext();

  const _handleAdd = (videoClip: singleBrandVideoClipType) => {
    setVideoClips((s) => ({
      ...s,
      [brandId]: [...(s[brandId] || []), videoClip],
    }));
  };

  const _handleRemove = ({ id }: { id: string }) => {
    setVideoClips((s) => ({
      ...s,
      [brandId]: s[brandId].filter(({ id: _id }) => _id !== id),
    }));
  };

  const _handleRename = ({ id, name }: { id: string; name: string }) => {
    setVideoClips((s) => ({
      ...s,
      [brandId]: s[brandId].map((videoClip) =>
        id === videoClip.id ? { ...videoClip, name } : videoClip
      ),
    }));
  };

  const _handleSort = ({ ids }: { ids: string[] }) => {
    setSortedVideoClipIds((s) => ({ ...s, [brandId]: ids }));
  };

  useAppSingalingSubscribe(
    getBrandVideoClipsTopicById(brandId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case videoClipsActions.ADD:
          _handleAdd(message.payload);
          break;

        case videoClipsActions.REMOVE:
          _handleRemove(message.payload);
          break;

        case videoClipsActions.RENAME:
          _handleRename(message.payload);
          break;

        case videoClipsActions.SORT:
          _handleSort(message.payload);
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default BrandVideoClipsListner;
