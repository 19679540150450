import { getToggleParticipantMediaTopicById } from "../../utils/pubSubTopics";
import useValidateHostCanTakeAction from "./useValidateHostCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

export const toggleParticipantMediaActions = {
  TURN_ON_WEBCAM: "TURN_ON_WEBCAM",
  TURN_OFF_WEBCAM: "TURN_OFF_WEBCAM",
  TURN_ON_MIC: "TURN_ON_MIC",
  TURN_OFF_MIC: "TURN_OFF_MIC",
  TURN_ON_SCREEN_SHARE: "TURN_ON_SCREEN_SHARE",
  TURN_OFF_SCREEN_SHARE: "TURN_OFF_SCREEN_SHARE",
  RESTART_WEBCAM: "RESTART_WEBCAM",
  RESTART_MIC: "RESTART_MIC",
  RESTART_SCREEN_SHARE: "RESTART_SCREEN_SHARE",
  TURN_OFF_INPUT_FILE_VIDEO_SHARE_STREAM:
    "TURN_OFF_INPUT_FILE_VIDEO_SHARE_STREAM",
  TURN_OFF_FILE_SHARE_STREAM: "TURN_OFF_FILE_SHARE_STREAM",
  TURN_OFF_EXTRA_WEBCAM_VIDEO_STREAM: "TURN_OFF_EXTRA_WEBCAM_VIDEO_STREAM",
};

const useToggleParticipantMedia = (participantId: string) => {
  const { publish } = useAppSingalingPublish(
    getToggleParticipantMediaTopicById(participantId)
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const turnOnWebcam = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ action: toggleParticipantMediaActions.TURN_ON_WEBCAM })
    );
  };

  const turnOffWebcam = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ action: toggleParticipantMediaActions.TURN_OFF_WEBCAM })
    );
  };

  const turnOnMic = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ action: toggleParticipantMediaActions.TURN_ON_MIC })
    );
  };

  const turnOffMic = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ action: toggleParticipantMediaActions.TURN_OFF_MIC })
    );
  };

  const turnOnScreenShare = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: toggleParticipantMediaActions.TURN_ON_SCREEN_SHARE,
      })
    );
  };

  const turnOffScreenShare = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: toggleParticipantMediaActions.TURN_OFF_SCREEN_SHARE,
      })
    );
  };

  const restartWebcam = (args?: { sentBySystem?: boolean }) => {
    publish(
      JSON.stringify({
        action: toggleParticipantMediaActions.RESTART_WEBCAM,
        sentBySystem: args?.sentBySystem,
      })
    );
  };

  const restartMic = (args?: { sentBySystem?: boolean }) => {
    publish(
      JSON.stringify({
        action: toggleParticipantMediaActions.RESTART_MIC,
        sentBySystem: args?.sentBySystem,
      })
    );
  };

  const restartScreenShare = (args?: { sentBySystem?: boolean }) => {
    publish(
      JSON.stringify({
        action: toggleParticipantMediaActions.RESTART_SCREEN_SHARE,
        sentBySystem: args?.sentBySystem,
      })
    );
  };

  const turnOffInputFileVideoShareStream = ({
    inputFileVideoId,
    sentBySystem,
  }: {
    inputFileVideoId: string;
    sentBySystem?: boolean;
  }) => {
    publish(
      JSON.stringify({
        action:
          toggleParticipantMediaActions.TURN_OFF_INPUT_FILE_VIDEO_SHARE_STREAM,
        sentBySystem,
        inputFileVideoId,
      })
    );
  };

  const turnOffExtraWebcamVideoStream = ({
    extraWebcamId,
    sentBySystem,
  }: {
    extraWebcamId: string;
    sentBySystem?: boolean;
  }) => {
    publish(
      JSON.stringify({
        action:
          toggleParticipantMediaActions.TURN_OFF_EXTRA_WEBCAM_VIDEO_STREAM,
        extraWebcamId,
        sentBySystem,
      })
    );
  };

  return {
    turnOnWebcam,
    turnOffWebcam,
    turnOnMic,
    turnOffMic,
    turnOnScreenShare,
    turnOffScreenShare,
    restartWebcam,
    restartMic,
    restartScreenShare,
    turnOffInputFileVideoShareStream,
    turnOffExtraWebcamVideoStream,
  };
};

export default useToggleParticipantMedia;
