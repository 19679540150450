import React, { useEffect, useRef } from "react";
// import { usePubSub } from "@videosdk.live/react-sdk";
import { useAppContext } from "../../contexts/appContextDef";
import {
  appPubSubTopics,
  // appPubSubTopics,
  // getToggleParticipantMediaTopicById,
} from "../../utils/pubSubTopics";
import sleep from "../../utils/sleep";
import { streamModes, streamTypes } from "./MainViewParticipantsListner";
import { mediaStreamKinds } from "../streams/QueuedStreamsListnerAllPeers";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useMainViewLayout from "../../hooks/appState/useMainViewLayout";
import usePreviousStudioMainViewLayout from "../../hooks/appState/usePreviousStudioMainViewLayout";
import useConferenceModeMaxParticipantCount from "../../hooks/conference/useConferenceModeMaxParticipantCount";
import useGetCalculatedConferenceModeMaxParticipantCount from "../../hooks/conference/useGetCalculatedConferenceModeMaxParticipantCount";
import { interactivityModes } from "../../utils/constants";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const InteractivityModeListner = () => {
  const { localParticipantId } = useLocalParticipantId();

  const {
    interactivityMode,
    setInteractivityMode,
    mainViewSelectedStreams,
    inQueueStreams,
    mainViewLayout,
    mainViewLayoutSelected,
    mainViewLayoutActive,
    previousStudioMainViewLayout,
  } = useAppContext();

  const { changeMainViewLayout } = useMainViewLayout();
  const { setPreviousStudioMainViewLayout } = usePreviousStudioMainViewLayout();
  const { changeConferenceModeMaxParticipantCount } =
    useConferenceModeMaxParticipantCount();
  const { getCalculatedConferenceModeMaxParticipantCount } =
    useGetCalculatedConferenceModeMaxParticipantCount();

  const mainViewSelectedStreamsRef = useRef(mainViewSelectedStreams);
  const inQueueStreamsRef = useRef(inQueueStreams);
  const localParticipantIdRef = useRef(localParticipantId);
  const interactivityModeRef = useRef(interactivityMode);
  const mainViewLayoutRef = useRef(mainViewLayout);
  const mainViewLayoutSelectedRef = useRef(mainViewLayoutSelected);
  const mainViewLayoutActiveRef = useRef(mainViewLayoutActive);
  const previousStudioMainViewLayoutRef = useRef(previousStudioMainViewLayout);

  useEffect(() => {
    mainViewSelectedStreamsRef.current = mainViewSelectedStreams;
  }, [mainViewSelectedStreams]);
  useEffect(() => {
    inQueueStreamsRef.current = inQueueStreams;
  }, [inQueueStreams]);
  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);
  useEffect(() => {
    interactivityModeRef.current = interactivityMode;
  }, [interactivityMode]);
  useEffect(() => {
    mainViewLayoutRef.current = mainViewLayout;
  }, [mainViewLayout]);
  useEffect(() => {
    mainViewLayoutSelectedRef.current = mainViewLayoutSelected;
  }, [mainViewLayoutSelected]);
  useEffect(() => {
    mainViewLayoutActiveRef.current = mainViewLayoutActive;
  }, [mainViewLayoutActive]);
  useEffect(() => {
    previousStudioMainViewLayoutRef.current = previousStudioMainViewLayout;
  }, [previousStudioMainViewLayout]);

  const { addToMainViewStreams } = useMainViewParticipants();

  useAppSingalingSubscribe(
    appPubSubTopics.INTERACTIVITY_MODE,
    async ({ message: receivedMessage, participantId: senderId }) => {
      const message = safeParseJson(receivedMessage);

      const localParticipantId = localParticipantIdRef.current;

      const prevInteractivityMode = interactivityModeRef.current;
      const prevMainViewLayout = mainViewLayoutRef.current;
      const prevMainViewLayoutSelected = mainViewLayoutSelectedRef.current;
      const prevMainViewLayoutActive = mainViewLayoutActiveRef.current;

      setInteractivityMode(message.interactivityMode);

      await sleep(200);

      if (message.interactivityMode === interactivityModes.CONFERENCE) {
        if (localParticipantId === senderId) {
          const mainViewSelectedStreams = mainViewSelectedStreamsRef.current;

          const inQueueStreams = inQueueStreamsRef.current;

          const isShareStreamActive = [...mainViewSelectedStreams.keys()].find(
            (key) => key.includes(streamTypes.SHARE)
          );

          if (!isShareStreamActive) {
            const isScreenShareStreamInQueue = inQueueStreams.find(
              ({ kind }) => kind === mediaStreamKinds.share
            );

            if (isScreenShareStreamInQueue) {
              await sleep(200);

              addToMainViewStreams({
                mode: streamModes.SCREEN,
                participantId: isScreenShareStreamInQueue.participantId,
                type: streamTypes.SHARE,
              });
            }
          }

          const previousStudioMainViewLayout =
            prevMainViewLayout ||
            prevMainViewLayoutSelected ||
            prevMainViewLayoutActive;

          if (prevInteractivityMode === interactivityModes.STUDIO) {
            await sleep(200);
            setPreviousStudioMainViewLayout({ previousStudioMainViewLayout });
          }

          await sleep(200);

          changeMainViewLayout({
            layout: previousStudioMainViewLayout,
            conferenceAutoMainVideoLayoutEnabled: true,
          });

          const { conferenceModeMaxParticipantCount } =
            getCalculatedConferenceModeMaxParticipantCount();

          await sleep(200);

          changeConferenceModeMaxParticipantCount({
            conferenceModeMaxParticipantCount:
              conferenceModeMaxParticipantCount,
            conferenceModeParticipantAutoCountEnabled: true,
          });
        }
      } else if (message.interactivityMode === interactivityModes.STUDIO) {
        setTimeout(() => {
          appEventEmitter.emit(
            appEventEmitterEvents.MAIN_VIEW_AUDIO_ONLY_PARTICIPANTS_POPUP,
            {}
          );
        }, 500);

        const previousStudioMainViewLayout =
          previousStudioMainViewLayoutRef.current;

        if (localParticipantId === senderId) {
          if (
            previousStudioMainViewLayout &&
            prevInteractivityMode === interactivityModes.CONFERENCE
          ) {
            await sleep(200);

            changeMainViewLayout({
              layout: previousStudioMainViewLayout,
              conferenceAutoMainVideoLayoutEnabled: false,
            });
          }
        }
      }
    }
  );

  return <React.Fragment />;
};

export default InteractivityModeListner;
