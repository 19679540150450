import { useWindowSize } from "@react-hook/window-size";
import useIsMobilePortrait from "../hooks/responsive/useIsMobilePortrait";
import { ERROR_404, MOMENTO_LOGO_FULL } from "../static/images";

const Error404Page = () => {
  const { isMobilePortrait } = useIsMobilePortrait();
  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <div
      style={{ width: windowWidth, height: windowHeight }}
      className="flex flex-1 flex-col"
    >
      <div className="p-5">
        <a target="_blank" href="https://momentostream.com/" rel="noreferrer">
          <img
            className="h-8"
            src={MOMENTO_LOGO_FULL}
            alt="momento logo full"
          />
        </a>
      </div>

      <div
        className={`flex flex-1 items-center justify-center ${
          isMobilePortrait ? "p-5" : ""
        }`}
      >
        <div className="flex items-center flex-col">
          <p className="text-3xl font-bold text-center">Session not found</p>
          <p className="mt-3 font-bold text-center">
            The requested URL was not found. You may contact the organization.
          </p>

          <div className="mt-5 w-7/12">
            <img className="h-full " src={ERROR_404} alt="error-404" />
          </div>

          <div className="mt-5 flex flex-1">
            <a
              rel="noreferrer"
              target={"_blank"}
              href="https://momentostream.com"
            >
              <button className="btn btn-primary text-white btn-wide normal-case">
                Go to MomentoStream
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404Page;
