import { useEffect, useRef, useState } from "react";
import { Room as LivekitRoom } from "livekit-client";
import { safeParseJson } from "../../utils/safeParseJson";
import { appModes, participantRoles } from "../../utils/constants";

export const useGetJoinedParticipants = ({
  signalingClient,
  studioParticipantIdLivekitSidMapRef,
}: {
  signalingClient: LivekitRoom;
  studioParticipantIdLivekitSidMapRef: React.MutableRefObject<
    Map<string, string>
  >;
}) => {
  const signalingClientRef = useRef(signalingClient);

  useEffect(() => {
    signalingClientRef.current = signalingClient;
  }, [signalingClient]);

  const getJoinedParticipants = () => {
    const signalingClient = signalingClientRef.current;

    const participants = signalingClient.participants;

    const localParticipant = signalingClient.localParticipant;

    const localParticipantAppMode = safeParseJson(localParticipant.metadata)
      ?.appMode as appModeType;
    const interpreterParticipantIds: string[] = [];
    const recorderParticipantIds: string[] = [];
    const joinedParticipants: joinedParticipantsType =
      localParticipantAppMode == appModes.HOST ||
      localParticipantAppMode === appModes.SPEAKER
        ? [
            {
              appMode: safeParseJson(localParticipant.metadata)
                ?.appMode as appModeType,
              originalAppMode: safeParseJson(localParticipant.metadata)
                ?.originalAppMode as appModeType,
              studioUserId: safeParseJson(localParticipant.metadata)
                ?.studioUserId as string,
              participantId: localParticipant.identity as string,
              participantName: localParticipant.name as string,
              timestamp: localParticipant.joinedAt?.getTime() as number,
              localTimeThreshold:
                new Date().getTime() -
                (localParticipant.joinedAt?.getTime() as number),
            },
          ]
        : [];

    participants.forEach((participant) => {
      const parsedMetadata = safeParseJson(participant.metadata);

      const role = parsedMetadata?.role as participantRolesType;
      const appMode = parsedMetadata?.appMode as appModeType;
      const originalAppMode = parsedMetadata?.originalAppMode as appModeType;
      const entryRequestAllowed =
        parsedMetadata?.entryRequestAllowed as boolean;
      const studioUserId = parsedMetadata?.studioUserId as string;
      const timestamp = participant.joinedAt?.getTime() as number;
      const localTimeThreshold = new Date().getTime() - timestamp;

      const participantId = participant.identity as string;
      const participantName = participant.name as string;
      const participantSid = participant.sid;

      studioParticipantIdLivekitSidMapRef.current.set(
        participantId,
        participantSid
      );

      if (entryRequestAllowed) {
        if (role === participantRoles.RECORDER) {
          recorderParticipantIds.push(participantId);
        } else if (role === participantRoles.INTERPRETER) {
          interpreterParticipantIds.push(participantId);
        } else if (role === participantRoles.PARTICIPANT) {
          joinedParticipants.push({
            appMode,
            participantId,
            studioUserId,
            timestamp,
            participantName,
            localTimeThreshold,
            originalAppMode,
          });
        }
      }
    });

    return {
      interpreterParticipantIds,
      recorderParticipantIds,
      joinedParticipants,
    };
  };

  return { getJoinedParticipants };
};

const useJoinedParticipantsCalculator = ({
  signalingClient,
  studioParticipantIdLivekitSidMapRef,
}: {
  signalingClient: LivekitRoom;
  studioParticipantIdLivekitSidMapRef: React.MutableRefObject<
    Map<string, string>
  >;
}) => {
  const [_joinedParticipants, setJoinedParticipants] =
    useState<joinedParticipantsType>([]);
  const [_recorderParticipantIds, setRecorderParticipantIds] = useState<
    string[]
  >([]);
  const [_interpreterParticipantIds, setInterpreterParticipantIds] = useState<
    string[]
  >([]);

  const { getJoinedParticipants } = useGetJoinedParticipants({
    signalingClient,
    studioParticipantIdLivekitSidMapRef,
  });

  const _interpreterParticipantIdsRef = useRef(_interpreterParticipantIds);
  const _recorderParticipantIdsRef = useRef(_recorderParticipantIds);
  const _joinedParticipantsRef = useRef(_joinedParticipants);
  const getJoinedParticipantsRef = useRef(getJoinedParticipants);

  useEffect(() => {
    _interpreterParticipantIdsRef.current = _interpreterParticipantIds;
  }, [_interpreterParticipantIds]);
  useEffect(() => {
    _recorderParticipantIdsRef.current = _recorderParticipantIds;
  }, [_recorderParticipantIds]);
  useEffect(() => {
    _joinedParticipantsRef.current = _joinedParticipants;
  }, [_joinedParticipants]);
  useEffect(() => {
    getJoinedParticipantsRef.current = getJoinedParticipants;
  }, [getJoinedParticipants]);

  useEffect(() => {
    const interval = setInterval(() => {
      const _interpreterParticipantIds = _interpreterParticipantIdsRef.current;
      const _recorderParticipantIds = _recorderParticipantIdsRef.current;
      const _joinedParticipants = _joinedParticipantsRef.current;

      const {
        interpreterParticipantIds,
        joinedParticipants,
        recorderParticipantIds,
      } = getJoinedParticipantsRef.current();

      if (
        _interpreterParticipantIds
          .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
          .join("") !==
        interpreterParticipantIds
          .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
          .join("")
      ) {
        //
        setInterpreterParticipantIds(interpreterParticipantIds);
      }

      if (
        _recorderParticipantIds
          .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
          .join("") !==
        recorderParticipantIds
          .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
          .join("")
      ) {
        //
        setRecorderParticipantIds(recorderParticipantIds);
      }

      if (
        _joinedParticipants
          .map(({ participantId, appMode }) => `${participantId}_${appMode}`)
          .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
          .join("") !==
        joinedParticipants
          .map(({ participantId }) => participantId)
          .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
          .join("")
      ) {
        //
        setJoinedParticipants(joinedParticipants);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return {
    interpreterParticipantIds: _interpreterParticipantIds,
    recorderParticipantIds: _recorderParticipantIds,
    joinedParticipants: _joinedParticipants,
  };
};

export default useJoinedParticipantsCalculator;
