import React, { useMemo, useState } from "react";
import CameraSettingsContainer from "./CameraSettingsContainer";
import AudioSettingsContainer from "./AudioSettingsContainer";
import VirtualBackgroundSettingsContainer from "./VirtualBackgroundSettingsContainer";
import {
  MdVideocam,
  MdOutlineMic,
  MdClose,
  MdAccountBox,
  MdSettings,
} from "react-icons/md";
import AppGeneralSettingsContainer from "./AppGeneralSettingsContainer";
import useIsMobilePortrait from "../../hooks/responsive/useIsMobilePortrait";
import { appModes } from "../../utils/constants";
import { useAppContext } from "../../contexts/appContextDef";

const tabs = [
  {
    title: "General",
    Icon: MdSettings,
    PanelContainer: AppGeneralSettingsContainer,
    onlyHost: true,
  },
  {
    title: "Camera",
    Icon: MdVideocam,
    PanelContainer: CameraSettingsContainer,
  },
  {
    title: "Audio",
    Icon: MdOutlineMic,
    PanelContainer: AudioSettingsContainer,
  },
  {
    title: "Virtual background",
    Icon: MdAccountBox,
    PanelContainer: VirtualBackgroundSettingsContainer,
    isNotMobilePortrait: true,
  },
];

const SettingsContainer = ({
  isOpen,
  handleCancel,
}: {
  isOpen: boolean;
  handleCancel: () => void;
}) => {
  const { appMode } = useAppContext();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { isMobilePortrait } = useIsMobilePortrait();

  const _tabs = useMemo(
    () =>
      tabs.filter(
        ({ onlyHost, isNotMobilePortrait }) =>
          (onlyHost ? appMode === appModes.HOST : true) &&
          (isNotMobilePortrait ? !isMobilePortrait : true)
      ),
    [isMobilePortrait, appMode]
  );

  const { PanelContainer } = useMemo(
    () => _tabs[activeTabIndex],
    [activeTabIndex, _tabs]
  );

  return isOpen ? (
    <React.Fragment>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-3 mx-auto max-w-6xl">
          <div className="bg-white shadow-lg rounded-md overflow-hidden border-2 border-custom-blue-900">
            <div className="flex items-center justify-between p-3 border-b border-solid border-slate-200 rounded-t">
              <h4 className="text-xl font-semibold text-primary">Settings</h4>
              <button
                className="btn btn-ghost rounded-full"
                onClick={() => handleCancel()}
              >
                <MdClose />
              </button>
            </div>
            <div className="flex md:w-[640px] md:h-[480px]">
              <div className="bg-gray-50">
                {_tabs.map(({ Icon, title }, i) => {
                  const isActive = i === activeTabIndex;

                  return (
                    <div
                      key={`settings-item-selector${i}`}
                      className={`flex cursor-pointer items-center pl-3 pr-6 py-3 ${
                        isActive ? "bg-primary" : "hover:bg-custom-blue-50"
                      }`}
                      onClick={() => {
                        setActiveTabIndex(i);
                      }}
                    >
                      <Icon
                        className={`${
                          isActive ? "fill-custom-blue-50" : "text-primary"
                        }`}
                      />
                      <p
                        className={`ml-2 text-sm ${
                          isActive ? "text-custom-blue-50" : "text-primary"
                        } `}
                      >
                        {title}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-1 overflow-y-scroll p-4 flex-col">
                <PanelContainer {...{ activeTabIndex }} />
                <div className="h-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </React.Fragment>
  ) : null;
};

export default SettingsContainer;
