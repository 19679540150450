import React, { useEffect, useRef } from "react";
import useValidateConnection, {
  signalLevels,
} from "../../hooks/appState/useValidateConnection";
import { useAppContext } from "../../contexts/appContextDef";
// import { usePubSub } from "@videosdk.live/react-sdk";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import sleep from "../../utils/sleep";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { Room as LivekitRoomType } from "livekit-client";
import { appSignalingClient, appSignalingClients } from "../../utils/constants";

const lastNPingsLimit = 10;

const goodPingThreshold = 200;
const averagePingThreshold = 500;

const LivekitLocalNetworkSignalLevelListner = () => {
  const countRef = useRef(0);
  const validatingConnection = useRef(false);

  const initTimestamp = useRef(new Date().getTime());
  const { signalLevel, setSignalLevel } = useAppContext();
  const { localParticipantId } = useLocalParticipantId();

  // const { publish } = usePubSub(
  //   appPubSubTopics.ALL_PARTICIPANTS_NETWORK_SIGNAL_LEVEL
  // );

  const appContext = useAppContext();

  const { signalingClient }: { signalingClient: LivekitRoomType } = appContext;

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.ALL_PARTICIPANTS_NETWORK_SIGNAL_LEVEL
  );

  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const signalLevelRef = useRef(signalLevel);
  // const publishRef = useRef<videosdkPubsubPublishType>(publish);

  const publishRef = useRef<appSingalingPublishType>(publish);

  const initValidateConnection = async (args?: { forcePublish: boolean }) => {
    if (validatingConnection.current) return;

    validatingConnection.current = true;

    countRef.current = countRef.current + 1;

    const forcePublish = args?.forcePublish;

    let signalLevel: string = "";

    const currentTimestamp = new Date().getTime();

    if (currentTimestamp - initTimestamp.current > 5000) {
      const connectionQuality =
        signalingClient.localParticipant.connectionQuality;

      if (connectionQuality === "excellent") {
        signalLevel = signalLevels.good;
      }
      if (connectionQuality === "good") {
        signalLevel = signalLevels.average;
      }
      if (connectionQuality === "poor") {
        signalLevel = signalLevels.bad;
      }
    } else {
      signalLevel = signalLevels.good;
    }

    if (
      signalLevel !== signalLevelRef.current ||
      forcePublish ||
      countRef.current % 10 === 0
    ) {
      setSignalLevel(signalLevel);

      await sleep(200);

      const publish = publishRef.current;

      publish(
        JSON.stringify({ participantId: localParticipantId, signalLevel })
        // { persist: true }
      );
    }

    validatingConnection.current = false;
  };

  const _handleStartInterval = () => {
    initValidateConnection({ forcePublish: true });

    const interval = setInterval(() => {
      initValidateConnection();
    }, 1000);

    intervalRef.current = interval;
  };

  useEffect(() => {
    _handleStartInterval();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    signalLevelRef.current = signalLevel;
  }, [signalLevel]);
  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  return <React.Fragment />;
};

const getSignalLevelFromPingInMS = ({ pingInMS }: { pingInMS: number }) => {
  let signalLevel;

  if (pingInMS < goodPingThreshold) {
    signalLevel = signalLevels.good;
  } else if (pingInMS < averagePingThreshold) {
    signalLevel = signalLevels.average;
  } else {
    signalLevel = signalLevels.bad;
  }

  return signalLevel;
};

const getModeSignalLevels = (signalLevelsArr: string[]) => {
  const signalLevelCount: { [t: string]: number } = {};

  signalLevelsArr.forEach((signalLevel) => {
    signalLevelCount[signalLevel] = (signalLevelCount[signalLevel] || 0) + 1;
  });

  const sortedSignalLevels = Object.keys(signalLevelCount).sort(
    (a, b) => signalLevelCount[a] - signalLevelCount[b]
  );

  return sortedSignalLevels.length > 1
    ? sortedSignalLevels[0] === signalLevels.bad &&
      signalLevelCount[signalLevels.bad] === 10
      ? sortedSignalLevels[0]
      : sortedSignalLevels[1]
    : sortedSignalLevels[0];
};

const _LocalNetworkSignalLevelListner = () => {
  const countRef = useRef(0);
  const validatingConnection = useRef(false);
  const lastNPings = useRef<
    {
      pingInMS: number;
      signalLevel: string;
    }[]
  >([]);

  const initTimestamp = useRef(new Date().getTime());
  const { signalLevel, setSignalLevel } = useAppContext();
  const { localParticipantId } = useLocalParticipantId();

  // const { publish } = usePubSub(
  //   appPubSubTopics.ALL_PARTICIPANTS_NETWORK_SIGNAL_LEVEL
  // );

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.ALL_PARTICIPANTS_NETWORK_SIGNAL_LEVEL
  );

  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const signalLevelRef = useRef(signalLevel);
  // const publishRef = useRef<videosdkPubsubPublishType>(publish);

  const publishRef = useRef<appSingalingPublishType>(publish);

  const { validateConnection } = useValidateConnection();

  const initValidateConnection = async (args?: { forcePublish: boolean }) => {
    if (validatingConnection.current) return;

    validatingConnection.current = true;

    countRef.current = countRef.current + 1;

    const forcePublish = args?.forcePublish;

    let signalLevel;

    const currentTimestamp = new Date().getTime();

    if (currentTimestamp - initTimestamp.current > 5000) {
      const validateConnectionRes = await validateConnection();

      const pingInMS = validateConnectionRes.pingInMS;

      if (lastNPings.current.length === lastNPingsLimit) {
        lastNPings.current.shift();
      }

      lastNPings.current.push({
        pingInMS,
        signalLevel: getSignalLevelFromPingInMS({ pingInMS }),
      });

      signalLevel = getModeSignalLevels(
        lastNPings.current.map(({ signalLevel }) => signalLevel)
      );

      // const weightageAveragePing =
      //   lastNPings.current.reduce((a, b) => a + b, 0) /
      //   lastNPings.current.length;

      // if (weightageAveragePing < goodPingThreshold) {
      //   signalLevel = signalLevels.good;
      // } else if (weightageAveragePing < averagePingThreshold) {
      //   signalLevel = signalLevels.average;
      // } else {
      //   signalLevel = signalLevels.bad;
      // }
    } else {
      signalLevel = signalLevels.good;
    }

    if (
      signalLevel !== signalLevelRef.current ||
      forcePublish ||
      countRef.current % 10 === 0
    ) {
      setSignalLevel(signalLevel);

      await sleep(200);

      const publish = publishRef.current;

      publish(
        JSON.stringify({ participantId: localParticipantId, signalLevel })
        // { persist: true }
      );
    }

    validatingConnection.current = false;
  };

  const _handleStartInterval = () => {
    initValidateConnection({ forcePublish: true });

    const interval = setInterval(() => {
      initValidateConnection();
    }, 1000);

    intervalRef.current = interval;
  };

  useEffect(() => {
    _handleStartInterval();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    signalLevelRef.current = signalLevel;
  }, [signalLevel]);
  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  return <React.Fragment />;
};

const LocalNetworkSignalLevelListner =
  appSignalingClient === appSignalingClients.livekit
    ? LivekitLocalNetworkSignalLevelListner
    : _LocalNetworkSignalLevelListner;

export default LocalNetworkSignalLevelListner;
