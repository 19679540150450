import { useAppContext } from "../../contexts/appContextDef";
import BrandLogosListner from "./BrandLogosListner";

const AllBrandsLogosListner = () => {
  const { brands } = useAppContext();

  return brands.map(({ id }) => (
    <BrandLogosListner key={`BrandLogosListner_${id}`} id={id} />
  ));
};

export default AllBrandsLogosListner;
