import React, { useEffect, useRef, useState } from "react";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../utils/appEventEmitter";
import Modal from "react-modal";
import useAppRtcParticipantExtraWebcam from "../appRtc/useAppRtcParticipantExtraWebcam";
import useLocalParticipantId from "../hooks/utils/useLocalParticipantId";
import VideoMediaStreamPlayer from "./VideoMediaStreamPlayer";
import useRtcLocalParticipantMediaStatsActions from "../appRtc/useRtcLocalParticipantMediaStatsActions";
// import { MdClose } from "react-icons/md";
import { useAppContext } from "../contexts/appContextDef";
import Spinner from "./Spinner";
import { primaryColor } from "../utils/colors";
import { MdClose } from "react-icons/md";

const ExtraWebcamSettingsModal = ({
  extraWebcamId,
  _handleClose,
  _handleSave,
}: {
  extraWebcamId: string;
  _handleClose: () => void;
  _handleSave: () => void;
}) => {
  const { editExtraCameraInProgress } = useAppContext();

  const [webcams, setWebcams] = useState<MediaDeviceInfo[]>([]);

  const { getWebcams } = useRtcLocalParticipantMediaStatsActions();

  const { localParticipantId } = useLocalParticipantId();

  const { isActive, isSubscribed, videoTrack, deviceId } =
    useAppRtcParticipantExtraWebcam({
      extraWebcamId,
      participantId: localParticipantId,
    });

  const resetWebcamList = async () => {
    const webcams = await getWebcams();

    setWebcams(webcams);
  };

  useEffect(() => {
    resetWebcamList();
  }, []);

  return (
    <Modal
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      isOpen={true}
      // onRequestClose={() => {
      //   _handleClose();
      // }}
      shouldFocusAfterRender={false}
      style={{
        overlay: { backgroundColor: "#00000066" },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderWidth: "2px",
          borderColor: primaryColor,
          borderRadius: 8,
        },
      }}
    >
      <div className="relative flex items-center rounded-xl overflow-hidden bg-white">
        <div>
          <div className="flex">
            <div className="flex flex-1 items-center">
              <h4 className="text-xl font-semibold text-primary">
                Share an extra camera
              </h4>
            </div>

            <button
              className="btn btn-ghost rounded-full"
              onClick={() => {
                _handleClose();
              }}
            >
              <MdClose />
            </button>
          </div>

          <div style={{ height: 200, aspectRatio: 19 / 9 }} className="mt-4">
            <VideoMediaStreamPlayer
              {...{
                videoOn: isActive && isSubscribed,
                videoTrack,
                participantId: localParticipantId,
                isShare: false,
                key: `local-extra-webcam-settings-VideoMediaStreamPlayer-${extraWebcamId}-${videoTrack?.id}`,
              }}
            />
          </div>

          <div className="mt-4 flex-1 flex w-full flex-col">
            <p>Camera</p>
            <select
              onChange={(e) => {
                appEventEmitter.emit(
                  appEventEmitterEvents.RTC_CHANGE_EXTRA_WEBCAM_DEVICE_ID,
                  {
                    extraWebcamId,
                    deviceId: e.target.value,
                  }
                );
              }}
              value={deviceId}
              className="select w-full select-bordered mt-1"
            >
              {webcams.map((item) => (
                <option
                  key={`extra-wemcam-selector-item-${extraWebcamId}-${item?.deviceId}`}
                  value={item?.deviceId}
                >
                  {item?.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-4">
            <button
              onClick={() => {
                _handleSave();
              }}
              className="w-full btn-primary btn text-white"
            >
              Share
            </button>
          </div>
        </div>

        {/* <div className="absolute right-0 top-0">
          <button
            onClick={() => {
              _handleClose();
            }}
            className="btn btn-ghost rounded-full"
          >
            <MdClose />
          </button>
        </div> */}

        {editExtraCameraInProgress ? (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <div className="p-5 bg-black bg-opacity-50">
              <Spinner h={"h-16"} w={"w-16"} />
            </div>
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </Modal>
  );
};

const ExtraWebcamSettingsModalContainer = () => {
  const [modalStates, setModalStates] = useState<{
    open: boolean;
    extraWebcamId?: string;
    isInitialized: boolean;
  }>({ open: false, extraWebcamId: undefined, isInitialized: false });

  const modalStatesRef = useRef(modalStates);

  useEffect(() => {
    modalStatesRef.current = modalStates;
  }, [modalStates]);

  const _handleOpenExtraWebcamSettings = ({
    extraWebcamId,
    isInitialized,
  }: {
    extraWebcamId: string;
    isInitialized?: boolean;
  }) => {
    setModalStates({
      open: true,
      extraWebcamId,
      isInitialized: !!isInitialized,
    });
  };

  const _handleClose = () => {
    // const modalStates = modalStatesRef.current;

    // if (modalStates.isInitialized && modalStates.extraWebcamId) {
    //   appEventEmitter.emit(appEventEmitterEvents.RTC_TURN_OFF_EXTRA_WEBCAM, {
    //     extraWebcamId: modalStates.extraWebcamId,
    //   });
    // }

    setModalStates({
      open: false,
      extraWebcamId: undefined,
      isInitialized: false,
    });
  };

  const _handleSave = () => {
    setModalStates({
      open: false,
      extraWebcamId: undefined,
      isInitialized: false,
    });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.OPEN_EXTRA_WEBCAM_SETTINGS,
      _handleOpenExtraWebcamSettings
    );

    // setTimeout(() => {
    //   appEventEmitter.emit(appEventEmitterEvents.RTC_TURN_ON_EXTRA_WEBCAM);
    // }, 5000);

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.OPEN_EXTRA_WEBCAM_SETTINGS,
        _handleOpenExtraWebcamSettings
      );
    };
  }, []);

  return (
    <React.Fragment>
      {modalStates?.open && modalStates?.extraWebcamId ? (
        <ExtraWebcamSettingsModal
          extraWebcamId={modalStates.extraWebcamId}
          _handleClose={_handleClose}
          _handleSave={_handleSave}
        />
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

export default ExtraWebcamSettingsModalContainer;
