import FileUploadErrorModal from "../../components/FileUploadErrorModal";
import WaitUntilVideosAreReadyToBePlayed from "../../components/WaitUntilVideosAreReadyToBePlayed";
import { useAppContext } from "../../contexts/appContextDef";
import useIsFreePlanActive from "../../hooks/plans/useIsFreePlanActive";
import AllParticipantsChangeMediaDeviceInProgressListner from "./AllParticipantsChangeMediaDeviceInProgressListner";
import AllParticipantsMicVolumeLevelListner from "./AllParticipantsMicVolumeLevelListner";
import AllParticipantsNetworkSignalLevelListner from "./AllParticipantsNetworkSignalLevelListner";
import AllParticipantsWebcamKeyframeListner from "./AllParticipantsWebcamKeyframeListner";
import AppQueueListner from "./AppQueueListner";
import AudioAndVideoPermissionStatesListner from "./AudioAndVideoPermissionStatesListner";
import BadNetworkToastListner from "./BadNetworkToastListner";
import BroadcastAssetsListner from "./BroadcastAssetsListner";
import BroadcastTimerListner from "./BroadcastTimerListner";
import DownloadPartListner from "./DownloadPartListner";
import EnableWaitingRoomListner from "./EnableWaitingRoomListner";
import FilteredInQueueStreamsListner from "./FilteredInQueueStreamsListner";
import HighlightedChatListner from "./HighlightedChatListner";
import InitialMediaDeviceIdListner from "./InitialMediaDeviceIdListner";
import InteractivityModeListner from "./InteractivityModeListner";
import LocalMicVolumeListner from "./LocalMicVolumeListner";
import LocalNetworkSignalLevelListner from "./LocalNetworkSignalLevelListner";
import LocalParticipantMediaToggleListner from "./LocalParticipantMediaToggleListner";
import MainViewLayoutListner from "./MainViewLayoutListner";
import MainViewParticipantsListner from "./MainViewParticipantsListner";
import MainViewSoloModeSelectedParticipantIdListner from "./MainViewSoloModeSelectedParticipantIdListner";
import ModifiedParticipantsAvatarsListner from "./ModifiedParticipantsAvatarsListner";
import ModifiedParticipantsNamesListner from "./ModifiedParticipantsNamesListner";
import NonHostCanSeeParticipantsCountListner from "./NonHostCanSeeParticipantsCountListner";
import PreviousStudioMainViewLayoutListner from "./PreviousStudioLayoutListner";
import QnAListner from "./QnAListner";
import RaiseHandListner from "./RaiseHandListner";
import RaiseHandSettingsListner from "./RaiseHandSettingsListner";
import ShowAudioAvatarsListner from "./ShowAudioAvatarsListner";
import ValidateConnectionListner from "./ValidateConnectionListner";
import VideosReadyToBePlayedListner from "./VideosReadyToBePlayedListner";
import WaitingRoomParticipantsListner from "./WaitingRoomParticipantsListner";
import AllParticipantsMediaStatsListner from "./AllParticipantsMediaStatsListner";
import ResetLiveStreamDestinationsListner from "./ResetLiveStreamDestinationsListner";
import BroadcastEgressesListner from "./BroadcastEgressesListner";
import React from "react";
import { appModes } from "../../utils/constants";
import EndBroadcastListner from "./EndBroadcastListner";
import AskToLeaveListner from "./AskToLeaveListner";
import RecorderParticipantOnlyTimeoutAutoCloseRoom from "./RecorderParticipantOnlyTimeoutAutoCloseRoom";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import AIInterpreterContainer from "./AIInterpreterContainer";
import LocalParticipantTranslatedAudioBroadcasterContainer, {
  useInterpretationViaAiData,
} from "./LocalParticipantTranslatedAudioBroadcasterContainer";
import AutoStartAIInterpretation from "./AutoStartAIInterpretation";
// import ThirdPartyRtmpStreamListner from "./ThirdPartyRtmpStreamListner";

const AppStateListner = () => {
  const {
    isRecorder,
    isInterpreter,
    // thirdPartyRtmpData
  } = useAppConfigContext();
  const { appMode } = useAppContext();
  const { isFreePlanActive } = useIsFreePlanActive();

  const { interpretationViaAiData } = useInterpretationViaAiData();

  return (
    <React.Fragment>
      <FileUploadErrorModal />
      <AudioAndVideoPermissionStatesListner />
      <ModifiedParticipantsNamesListner />
      <BroadcastAssetsListner />
      <LocalParticipantMediaToggleListner />
      <RaiseHandListner />
      <InitialMediaDeviceIdListner />
      <MainViewLayoutListner />
      <InteractivityModeListner />
      <MainViewParticipantsListner />
      <ModifiedParticipantsAvatarsListner />
      <FilteredInQueueStreamsListner />
      <NonHostCanSeeParticipantsCountListner />
      <EnableWaitingRoomListner />
      {isFreePlanActive && <BroadcastTimerListner />}
      <QnAListner />
      <HighlightedChatListner />
      <RaiseHandSettingsListner />
      <DownloadPartListner />
      <WaitingRoomParticipantsListner />
      <MainViewSoloModeSelectedParticipantIdListner />
      <ShowAudioAvatarsListner />
      <ValidateConnectionListner />
      {appMode === appModes.HOST || appMode === appModes.SPEAKER ? (
        <LocalNetworkSignalLevelListner />
      ) : (
        <React.Fragment />
      )}
      <AllParticipantsNetworkSignalLevelListner />
      <LocalMicVolumeListner />
      <AllParticipantsMicVolumeLevelListner />
      <VideosReadyToBePlayedListner />
      <WaitUntilVideosAreReadyToBePlayed />
      {appMode === appModes.HOST || appMode === appModes.SPEAKER ? (
        <BadNetworkToastListner />
      ) : (
        <React.Fragment />
      )}
      <PreviousStudioMainViewLayoutListner />
      <AppQueueListner />
      {/* <ParticipantWebcamAndScreenShareStreamQualityListner /> */}
      <AllParticipantsWebcamKeyframeListner />
      <AllParticipantsChangeMediaDeviceInProgressListner />
      <AllParticipantsMediaStatsListner />
      <ResetLiveStreamDestinationsListner />
      <BroadcastEgressesListner />
      <EndBroadcastListner />
      <AskToLeaveListner />
      {(appMode === appModes.VIEWER && isRecorder) ||
      (isInterpreter && interpretationViaAiData.length) ? (
        <RecorderParticipantOnlyTimeoutAutoCloseRoom />
      ) : (
        <React.Fragment />
      )}

      {appMode === appModes.VIEWER &&
      isInterpreter &&
      interpretationViaAiData?.length ? (
        <AIInterpreterContainer
          languages={interpretationViaAiData.map(({ language }) => language)}
        />
      ) : (
        <React.Fragment />
      )}

      {(appMode === appModes.HOST || appMode === appModes.SPEAKER) &&
      interpretationViaAiData?.length ? (
        <LocalParticipantTranslatedAudioBroadcasterContainer />
      ) : (
        <React.Fragment />
      )}

      {(appMode === appModes.HOST || appMode === appModes.SPEAKER) &&
      interpretationViaAiData?.length ? (
        <AutoStartAIInterpretation />
      ) : (
        <React.Fragment />
      )}

      {/* {thirdPartyRtmpData ? (
        <ThirdPartyRtmpStreamListner />
      ) : (
        <React.Fragment />
      )} */}
    </React.Fragment>
  );
};

export default AppStateListner;
