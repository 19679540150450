import React, { useEffect, useRef } from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import { videoShareStreamActions } from "../../hooks/streams/useVideoShareStreams";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import { safeParseJson } from "../../utils/safeParseJson";

const VideoShareStreamsListner = () => {
  const { setActiveVideoShareStreams } = useAppContext();

  const { localParticipantId } = useLocalParticipantId();

  const localParticipantIdRef = useRef(localParticipantId);

  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);

  useAppSingalingSubscribe(
    appPubSubTopics.VIDEO_SHARE_STREAM_ENABLED,
    ({ message: receivedMessage, participantId }) => {
      const message = safeParseJson(receivedMessage);

      if (participantId === localParticipantId) {
        setActiveVideoShareStreams((streams) =>
          message.action === videoShareStreamActions.ENABLE
            ? [...streams, message]
            : streams.filter((stream) => stream.id !== message.id)
        );
      }
    }
  );

  return <React.Fragment />;
};

export default VideoShareStreamsListner;
