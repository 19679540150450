import { momentostreamApiServerBaseUrl } from "../../../utils/constants";

const deleteBannerFolder = async ({
  userId,
  folderId,
}: {
  userId: string;
  folderId: string;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/${userId}/banner-folder/${folderId}`;

    const res = await fetch(url, {
      method: "DELETE",
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default deleteBannerFolder;
