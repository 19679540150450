import { customBlueColor } from "../../utils/colors";

const ScreenIconActive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101.223 69.818"
    enableBackground="new 0 0 101.223 69.818"
    xmlSpace="preserve"
    {...props}
  >
    <g id="Capa_8">
      <path
        style={{ fill: customBlueColor[900] }}
        d="M18.092 25.275c-4.368 0-7.91 2.661-7.91 5.945h15.82c0-3.283-3.541-5.945-7.91-5.945z"
      />
      <circle
        style={{ fill: customBlueColor[900] }}
        cx={18.092}
        cy={18.204}
        r={4.994}
      />
      <path
        style={{ fill: customBlueColor[900] }}
        d="M18.092 50.663c-4.368 0-7.91 2.661-7.91 5.945h15.82c0-3.283-3.541-5.945-7.91-5.945z"
      />
      <circle
        style={{ fill: customBlueColor[900] }}
        cx={18.092}
        cy={43.591}
        r={4.994}
      />
      <path
        style={{ fill: customBlueColor[900] }}
        d="M33.815 13.21H91.04v43.398H33.815z"
      />
    </g>
  </svg>
);

export default ScreenIconActive;
