import React, { useEffect, useMemo, useRef, useState } from "react";
import { MdAdd, MdCancel } from "react-icons/md";
import { useAppContext } from "../../../contexts/appContextDef";
import useBrandLogos from "../../../hooks/brands/useBrandLogos";
import deactivateBrandAsset from "../../../apis/brands/deactivate-brand-asset";
import activateBrandAsset from "../../../apis/brands/activate-brand-asset";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import useBrandLogo from "../../../hooks/activeBrand/useBrandLogo";
import Tooltip from "../../../components/Tooltip";
import useIsProfessionalPlanActive from "../../../hooks/plans/useIsProfessionalPlanActive";
import deleteBrandAsset from "../../../apis/brands/delete-brand-asset";
import { createUID } from "../../../utils/createUID";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";
import { appQueueEvents } from "../../../listners/appState/AppQueueListner";
import sleep from "../../../utils/sleep";
import DisclosureContainer from "../../../components/DisclosureContainer";

const BrandLogoItem = ({
  id,
  remoteUrl,
  brandLogo,
  brandId,
  userId,
  changeLogo,
}: {
  id: string;
  remoteUrl: string | null;
  brandLogo: { id: string | null };
  brandId: string;
  userId: string;
  changeLogo: (t: { id: string | null; remoteUrl: null | string }) => void;
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const isSelected = id === brandLogo.id;

  const { remove } = useBrandLogos({ id: brandId });

  const arraySquareGrid = useMemo(
    () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    []
  );

  return (
    <div
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      onClick={async () => {
        if (isSelected) {
          const { success } = await deactivateBrandAsset({
            brandId,
            fileId: id,
            type: "logo",
            userId,
          });

          if (success) {
            changeLogo({ id: null, remoteUrl: null });
          }
        } else {
          const { success } = await activateBrandAsset({
            brandId,
            fileId: id,
            type: "logo",
            userId,
          });

          if (success) {
            changeLogo({ id, remoteUrl });
          }
        }
      }}
      className={`border-4 hover:shadow-lg overflow-hidden aspect-square rounded-lg relative ${
        isSelected
          ? "border-primary border-opacity-70"
          : "border-opacity-0 hover:border-opacity-40 border-gray-400"
      }`}
    >
      <div className="absolute flex flex-1 flex-col top-0 right-0 bottom-0 left-0">
        {arraySquareGrid.map((i) => (
          <div className="flex flex-1">
            {arraySquareGrid.map((j) => (
              <div
                className={`flex flex-1  ${
                  i % 2 === 0
                    ? j % 2 === 0
                      ? ""
                      : "bg-gray-200"
                    : j % 2 === 0
                    ? "bg-gray-200"
                    : ""
                }`}
              ></div>
            ))}
          </div>
        ))}
      </div>

      <img
        src={remoteUrl || ""}
        alt={id}
        className={
          "absolute flex flex-1 top-0 right-0 bottom-0 left-0 h-full w-full object-contain"
        }
      />

      {isMouseOver && !isSelected ? (
        <div className="absolute right-0 top-0">
          <button
            onClick={async (e) => {
              e.stopPropagation();

              const { success } = await deleteBrandAsset({
                brandId,
                fileId: id,
                userId,
              });

              if (success) {
                remove({ id });
              }
            }}
          >
            <MdCancel />
          </button>
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

const BrandLogoUploadingAndProcessingItem = ({ id }: { id: string }) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const _handleAssetUploadingAndProcessingProgress = ({
    progress,
    upload,
  }: {
    progress: number;
    upload?: boolean;
  }) => {
    if (upload) {
      setUploadProgress(progress);
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.ASSET_UPLOADING_AND_PROCESSING_PROGRESS(id),
      _handleAssetUploadingAndProcessingProgress
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.ASSET_UPLOADING_AND_PROCESSING_PROGRESS(id),
        _handleAssetUploadingAndProcessingProgress
      );
    };
  }, [id]);

  return (
    <div
      key={`${id}`}
      className={`disabled aspect-square flex items-center justify-center rounded-lg border-2 hover:bg-gray-100 border-gray-200`}
    >
      <div
        className="radial-progress text-primary"
        style={
          {
            "--value": parseInt(`${uploadProgress * 100}`),
            "--size": "40px",
            "--thickness": "4px",
          } as React.CSSProperties
        }
      ></div>
    </div>
  );
};

const BrandLogosContainer = ({
  listenToCloseEvent,
  defaultOpen,
  brandId,
}: {
  listenToCloseEvent?: string;
  defaultOpen?: boolean;
  brandId: string;
}) => {
  const {
    brandLogo,
    sortedBrandLogos: allBrandsSortedBrandLogos,
    sortedBrandLogoIds: allBrandsSortedBrandLogoIds,
    setFileUploadError,
    newLogosState,
  } = useAppContext();

  const imageInputRef = useRef<HTMLInputElement>();

  const { userId } = useAppConfigContext();

  const sortedBrandLogos = useMemo(
    () => allBrandsSortedBrandLogos[brandId] || [],
    [allBrandsSortedBrandLogos, brandId]
  );

  const sortedBrandLogoIds = useMemo(
    () => allBrandsSortedBrandLogoIds[brandId] || [],
    [allBrandsSortedBrandLogoIds, brandId]
  );

  const { add, sort } = useBrandLogos({ id: brandId });

  const { isProfessionalPlanActive } = useIsProfessionalPlanActive();

  const addRef = useRef(add);

  useEffect(() => {
    addRef.current = add;
  }, [add]);

  const { changeLogo } = useBrandLogo();

  const [_sortedBrandLogos, setSortedBrandLogos] = useState(sortedBrandLogos);

  useEffect(() => {
    setSortedBrandLogos(sortedBrandLogos);
  }, [sortedBrandLogos]);

  const sortedBrandLogoIdsRef = useRef(sortedBrandLogoIds);

  useEffect(() => {
    sortedBrandLogoIdsRef.current = sortedBrandLogoIds;
  }, [sortedBrandLogoIds]);

  const onFileEvent = async ({
    remoteUrl,
    singleFile,
    fileName,
  }: {
    remoteUrl?: string;
    singleFile?: File;
    fileName?: string;
  }) => {
    const uploadId = createUID();

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BRAND_LOGO,
      //
      isUploading: true,
      isProcessing: false,
      isCompleted: false,
      //
      brandId,
      uploadId,
      name: fileName,
    });

    const newId = await addRef.current({ singleFile, remoteUrl, uploadId });

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BRAND_LOGO,
      //
      isUploading: false,
      isProcessing: false,
      isCompleted: true,
      //
      brandId,
      uploadId,
      name: fileName,
    });

    if (newId) {
      await new Promise((resolve) => setTimeout(resolve, 100));

      const _sortedBrandLogoIds = [...sortedBrandLogoIdsRef.current];

      sort({ ids: [..._sortedBrandLogoIds, newId] });
    }

    return { id: newId };
  };

  const newBrandLogosState = useMemo(
    () => newLogosState.filter(({ brandId: _brandId }) => brandId === _brandId),
    [newLogosState, brandId]
  );

  const canAddNewItem = useMemo(
    () =>
      !(
        (_sortedBrandLogos?.length || 0) + (newBrandLogosState?.length || 0) >=
        (isProfessionalPlanActive ? 10 : 5)
      ),
    [_sortedBrandLogos, newBrandLogosState, isProfessionalPlanActive]
  );

  const _handleAddAndActivate = async ({
    remoteUrl,
  }: {
    remoteUrl: string;
  }) => {
    const { id } = await onFileEvent({ remoteUrl });

    if (id) {
      await sleep(500);

      changeLogo({ id, remoteUrl });
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_LOGO(brandId),
      _handleAddAndActivate
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_LOGO(brandId),
        _handleAddAndActivate
      );
    };
  }, [brandId]);

  return (
    <React.Fragment>
      <DisclosureContainer
        listenToCloseEvent={listenToCloseEvent}
        defaultOpen={defaultOpen}
        title={"Logo"}
        dataTip={`Adding your logo to the broadcast makes you look like a pro. Plus, it's great marketing if people are sharing your streams! Try using a PNG or GIF with a transparent background.`}
        dataTipn={["Recommended size: 200 x 200", "Max file size: 20 MB"]}
        renderPanel={() => {
          return (
            <div>
              <div className="grid grid-cols-4 gap-1">
                {_sortedBrandLogos.map(({ id, remoteUrl }) => (
                  <BrandLogoItem
                    {...{
                      brandId,
                      brandLogo,
                      changeLogo,
                      id,
                      remoteUrl,
                      userId,
                      key: `brand-${brandId}-logo-item-${id}`,
                    }}
                  />
                ))}

                {newBrandLogosState.length ? (
                  newBrandLogosState.map(({ id }) => (
                    <BrandLogoUploadingAndProcessingItem
                      {...{ key: `${id}`, id }}
                    />
                  ))
                ) : (
                  <React.Fragment />
                )}

                <Tooltip
                  title={
                    canAddNewItem
                      ? "Upload logo"
                      : "You have reached the maximum logos upload limit. Delete one to upload a new one."
                  }
                >
                  <div
                    onClick={() => {
                      if (canAddNewItem) {
                        imageInputRef.current?.click();
                        // setAssetsSelectionModalOpen(true);
                      }
                    }}
                  >
                    <button
                      disabled={!canAddNewItem}
                      className={
                        "aspect-square btn-outline btn btn-primary flex items-center justify-center h-full w-full"
                      }
                    >
                      <MdAdd size={24} />
                    </button>
                  </div>
                </Tooltip>
              </div>
              <div></div>
            </div>
          );
        }}
      />

      <input
        ref={imageInputRef as React.LegacyRef<HTMLInputElement>}
        onChange={async (e) => {
          const ev = e;

          const singleFile = ev?.target?.files ? ev.target.files[0] : null;

          if (singleFile) {
            const fileSize = singleFile.size / (1024 * 1024);

            if (fileSize > 20) {
              setFileUploadError({
                visible: true,
                errorMessage: `File size exceeds the maximum limit ~ ${20}MB`,
              });

              return;
            }

            onFileEvent({
              singleFile,
              fileName: singleFile?.name,
            });
          } else {
            //
          }

          if (imageInputRef.current) {
            imageInputRef.current.value = "";
          }
        }}
        accept={"image/*"}
        type="file"
        className="hidden"
      />
    </React.Fragment>
  );
};

export default BrandLogosContainer;
