// @ts-nocheck

import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import {
  MdVolumeUp,
  MdVolumeOff,
  MdCancel,
  MdMicOff,
  MdSettings,
} from "react-icons/md";
import { useAppContext } from "../../contexts/appContextDef";
import IconButton, {
  iconButtonVariants,
} from "../bottomControlsContainer/IconButton";
import Modal from "../../components/Modal";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { audioAndVideoPermissionStates } from "../../utils/constants";
import { VolumeIndicationMicIcon } from "../bottomControlsContainer/BottomControlsContainer";
import InterpretationsContainer from "../../components/InterpretationsContainer";
import AgoraSettingsContainer from "../agoraSettings/AgoraSettingsContainer";
import InputInterpretationsContainer from "../../components/InputInterpretationsContainer";

const LocalMicIcon = ({
  size,
  className,
}: {
  size: number;
  className?: string;
}) => {
  const [volume, setVolume] = useState<number | null>(null);

  const _handleLocalMicVolumeChanged = ({ volume }: { volume: number }) => {
    setVolume(volume);
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.LOCAL_MIC_VOLUME_CHANGED,
      _handleLocalMicVolumeChanged
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.LOCAL_MIC_VOLUME_CHANGED,
        _handleLocalMicVolumeChanged
      );
    };
  }, []);

  return (
    <VolumeIndicationMicIcon {...{ className, size, volume, muted: false }} />
  );
};

export const AgoraInterpreterMicIconButton = () => {
  const { interpretationInputAgoraMicStats } = useAppContext();
  const { micState } = useAppConfigContext();

  const micDenied = useMemo(
    () => micState === audioAndVideoPermissionStates.denied,
    [micState]
  );

  const askMicPermission = () => {
    appEventEmitter.emit(appEventEmitterEvents.ASK_MIC_PERMISSION);
  };

  const micOn = useMemo(
    () => interpretationInputAgoraMicStats.micOn,
    [interpretationInputAgoraMicStats]
  );

  const micOnRef = useRef(micOn);

  useEffect(() => {
    micOnRef.current = micOn;
  }, [micOn]);

  const _toggleMic = () => {
    const micOn = micOnRef.current;

    if (micOn) {
      appEventEmitter.emit(
        appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_OFF_MIC
      );
    } else {
      appEventEmitter.emit(
        appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_ON_MIC
      );
    }
  };

  return (
    <IconButton
      {...{
        onClick: micDenied ? askMicPermission : _toggleMic,
        Icon: micOn ? LocalMicIcon : MdMicOff,
        title: micOn ? "Mute" : "Unmute",
        popperTitle: "",
        variant: micOn ? iconButtonVariants.info : iconButtonVariants.error,
        maxTitle: "Unmute",
        disabled: micDenied,
        disabledTitle: "Mic permission not granted",
        tooltipPlacement: "centerAbove",
      }}
    />
  );
};

const InterpreterBottomControlsContainer = ({
  onContainerHeight,
}: {
  onContainerHeight?: (height: number) => void;
}) => {
  const { setAppAudioMuted, appAudioMuted } = useAppContext();
  const [leaveConfirmationModalVisible, setLeaveConfirmationModalVisible] =
    useState(false);
  const _handleToggleAppAudio = () => {
    setAppAudioMuted((s) => !s);
  };
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const containerRef = createRef<HTMLDivElement>();

  const [containerHeight, setContainerHeight] = useState(0);

  const containerHeightRef = useRef(containerHeight);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
    }
  }, [containerRef]);

  useEffect(() => {
    if (typeof onContainerHeight === "function") {
      onContainerHeight(containerHeight);
    }
  }, [containerHeight]);

  return (
    <React.Fragment>
      <div
        ref={containerRef as React.LegacyRef<HTMLDivElement>}
        className="flex items-center justify-center"
      >
        <div className="flex items-center justify-center border border-gray-200 rounded-md shadow-md">
          <IconButton
            {...{
              onClick: _handleToggleAppAudio,
              Icon: appAudioMuted ? MdVolumeOff : MdVolumeUp,
              title: appAudioMuted ? "Unmute" : "Mute",
              popperTitle: "",
              variant: appAudioMuted
                ? iconButtonVariants.error
                : iconButtonVariants.info,
              maxTitle: "Unmute",
              rounded: "rounded-l-md",
            }}
          />
          {/*  */}
          <InterpretationsContainer />
          {/*  */}
          <AgoraInterpreterMicIconButton />
          {/*  */}
          <IconButton
            {...{
              onClick: () => {
                setSettingsModalOpen((s) => !s);
              },
              Icon: MdSettings,
              title: "Settings",
              popperTitle: "",
              variant: iconButtonVariants.info,
              maxTitle: "Settings",
            }}
          />
          {/*  */}
          <InputInterpretationsContainer />
          {/*  */}
          <IconButton
            {...{
              onClick: () => {
                setLeaveConfirmationModalVisible(true);
              },
              Icon: MdCancel,
              title: "Leave Studio",
              popperTitle: "",
              variant: iconButtonVariants.info,
              maxTitle: "Leave Studio",
              iconFillVarient: iconButtonVariants.error,
              rounded: "rounded-r-md",
            }}
          />
        </div>
      </div>
      {/*  */}
      <Modal
        {...{
          isOpen: leaveConfirmationModalVisible,
          onSuccess: () => {
            appEventEmitter.emit(appEventEmitterEvents.LEAVE_FROM_SESSION, {});

            setLeaveConfirmationModalVisible(false);
          },
          onClose: () => {
            setLeaveConfirmationModalVisible(false);
          },
          cancelText: "No, stay",
          successText: "Yes, leave",
          title: "Are you sure to leave the studio?",
        }}
      />
      {/*  */}
      <AgoraSettingsContainer
        {...{
          isOpen: settingsModalOpen,
          handleCancel: () => {
            setSettingsModalOpen(false);
          },
        }}
      />
    </React.Fragment>
  );
};

export default InterpreterBottomControlsContainer;
