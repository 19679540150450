import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../utils/useLocalParticipantId";
import { streamModes } from "../../listners/appState/MainViewParticipantsListner";
import { inQueueStreamTypes } from "../../utils/constants";
import { useAppConfigContext } from "../../contexts/appConfigDef";

const useSpeakerInQueueStreams = () => {
  const { localParticipantId } = useLocalParticipantId();

  const {
    allParticipantsInQueueStreams,
    newPdfsState,
    newVideoStreamsState,
    inQueueStreamsContainerHidden,
    broadcastAssets,
  } = useAppContext();

  const { meetingMode, studioUserId } = useAppConfigContext();

  const localParticipantFilteredInQueueStreams = useMemo(
    () =>
      allParticipantsInQueueStreams
        .filter(
          ({ participantId, type, resourceId }) =>
            (type === inQueueStreamTypes.SHARE_PDF
              ? broadcastAssets
                  .find(({ id }) => `${id}` === `${resourceId}`)
                  ?.owners?.includes(studioUserId)
              : participantId === localParticipantId) &&
            (meetingMode === "meeting"
              ? type !== inQueueStreamTypes.WEBCAM
              : true)
        )
        .map((stream) => ({
          ...stream,
          id: stream.id || stream.extraWebcamId,
        })),
    [
      allParticipantsInQueueStreams,
      meetingMode,
      broadcastAssets,
      studioUserId,
      localParticipantId,
    ]
  );

  const isLocalParticipantInQueue = useMemo(
    () =>
      !!localParticipantFilteredInQueueStreams.find(
        ({ participantId, type }) =>
          participantId === localParticipantId &&
          type === inQueueStreamTypes.WEBCAM
      ),
    [localParticipantFilteredInQueueStreams, localParticipantId]
  );

  const newBroadcastAssetsStates = useMemo(
    () => [
      ...(newPdfsState?.length
        ? newPdfsState.map((s) => ({ ...s, mode: streamModes.PDF }))
        : []),
      ...(newVideoStreamsState?.length
        ? newVideoStreamsState.map((s) => ({ ...s, mode: streamModes.VIDEO }))
        : []),
    ],
    [newPdfsState, newVideoStreamsState]
  );

  const toggleSpeakerInQueueStreamsButtonVisible = useMemo(
    () =>
      localParticipantFilteredInQueueStreams.length ||
      newBroadcastAssetsStates.length,
    [localParticipantFilteredInQueueStreams, newBroadcastAssetsStates]
  );

  const speakerInQueueStreamsContainerHiddenInMeetingMode = useMemo(
    () =>
      meetingMode === "meeting" &&
      ((!newBroadcastAssetsStates.length &&
        !localParticipantFilteredInQueueStreams.length) ||
        inQueueStreamsContainerHidden),
    [
      meetingMode,
      inQueueStreamsContainerHidden,
      newBroadcastAssetsStates,
      localParticipantFilteredInQueueStreams,
    ]
  );

  return {
    newBroadcastAssetsStates,
    isLocalParticipantInQueue,
    localParticipantFilteredInQueueStreams,
    toggleSpeakerInQueueStreamsButtonVisible,
    speakerInQueueStreamsContainerHiddenInMeetingMode,
  };
};

export default useSpeakerInQueueStreams;
