import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../utils/appEventEmitter";

const useRtcLocalParticipantMediaStatsActions = () => {
  const enableWebcam = () => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_TURN_ON_WEBCAM);
  };
  const disableWebcam = () => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_TURN_OFF_WEBCAM);
  };
  const toggleWebcam = () => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_TOGGLE_WEBCAM);
  };
  const enableMic = () => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_TURN_ON_MIC);
  };
  const disableMic = () => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_TURN_OFF_MIC);
  };
  const toggleMic = () => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_TOGGLE_MIC);
  };
  const enableScreenShare = () => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_TURN_ON_SCREEN_SHARE);
  };
  const disableScreenShare = () => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_TURN_OFF_SCREEN_SHARE);
  };
  const toggleScreenShare = () => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_TOGGLE_SCREEN_SHARE);
  };

  const setVirtualBackgroundId = (id: string) => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_SET_VIRTUAL_BACKGROUND, {
      id,
    });
  };

  const getWebcams = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();

    const webcams = devices.filter(({ kind }) => kind === "videoinput");

    return webcams;
  };

  const getMics = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();

    const mics = devices.filter(({ kind }) => kind === "audioinput");

    return mics;
  };

  const changeWebcamDeviceId = (deviceId: string) => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_CHANGE_WEBCAM_DEVICE_ID, {
      deviceId,
    });
  };

  const changeMicDeviceId = (deviceId: string) => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_CHANGE_MIC_DEVICE_ID, {
      deviceId,
    });
  };

  return {
    enableWebcam,
    disableWebcam,
    toggleWebcam,
    enableMic,
    disableMic,
    toggleMic,
    enableScreenShare,
    disableScreenShare,
    toggleScreenShare,
    setVirtualBackgroundId,
    getWebcams,
    getMics,
    changeWebcamDeviceId,
    changeMicDeviceId,
  };
};

export default useRtcLocalParticipantMediaStatsActions;
