import { useEffect, useMemo, useRef } from "react";
import activatePoll from "../../../apis/apps/polls/activate-poll";
import answerPoll from "../../../apis/apps/polls/answer-poll";
import deactivatePoll from "../../../apis/apps/polls/deactivate-poll";
import deletePoll from "../../../apis/apps/polls/delete-poll";
import { useAppContext } from "../../../contexts/appContextDef";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../../listners/appState/MainViewParticipantsListner";
import useMainViewParticipants from "../../appState/useMainViewParticipants";
import clearPoll from "../../../apis/apps/polls/clear-poll";
import useValidateHostCanTakeAction from "../../appState/useValidateHostCanTakeAction";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";
import { mainViewLayouts } from "../../../utils/constants";

const usePoll = ({ id }: { id: string }) => {
  const { polls, setPolls, mainViewSelectedStreams, mainViewLayoutActive } =
    useAppContext();
  const { broadcastId } = useAppConfigContext();
  const participantId = "pollAddParticipantId";

  const poll = useMemo(
    () => polls.find(({ id: _id }) => `${_id}` === `${id}`),
    [polls, id]
  );

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const isCinemaLayoutActive = useMemo(
    () => mainViewLayoutActive === mainViewLayouts.CINEMA,
    [mainViewLayoutActive]
  );

  const { isInMainView, isActive, options, question, selfSubmittedOptionId } =
    useMemo(() => {
      const streamId = generateStreamId({
        participantId,
        type: streamTypes.SHARE,
        mode: streamModes.APPPOLL,
        pollId: id,
      });

      const isInMainView = !!mainViewSelectedStreams.get(streamId);

      const isActive = `${poll?.status}` === "1";

      const options = poll?.options
        ? Object.keys(poll.options).map((id) => poll.options[parseInt(id)])
        : [];

      const question = poll?.question;
      const selfSubmittedOptionId = poll?.selfSubmittedOptionId;

      return {
        isInMainView,
        isActive,
        options,
        question,
        selfSubmittedOptionId,
      };
    }, [id, participantId, mainViewSelectedStreams, poll]);

  const isInMainViewRef = useRef(isInMainView);
  const isActiveRef = useRef(isActive);
  const selfSubmittedOptionIdRef = useRef(selfSubmittedOptionId);
  const idRef = useRef(id);

  const optionsRef = useRef(options);
  const questionRef = useRef(question);

  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);
  useEffect(() => {
    isActiveRef.current = isActive;
  }, [isActive]);
  useEffect(() => {
    selfSubmittedOptionIdRef.current = selfSubmittedOptionId;
  }, [selfSubmittedOptionId]);
  useEffect(() => {
    idRef.current = id;
  }, [id]);
  useEffect(() => {
    optionsRef.current = options;
  }, [options]);
  useEffect(() => {
    questionRef.current = question;
  }, [question]);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const _answerPoll = async ({ optionId }: { optionId: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (isActiveRef.current && !selfSubmittedOptionIdRef.current) {
      await answerPoll({ broadcastId, optionId, pollId: idRef.current });

      setTimeout(() => {
        setPolls((s) => {
          const index = s.findIndex(({ id }) => `${id}` === `${idRef.current}`);

          s[index] = { ...s[index], selfSubmittedOptionId: optionId };

          return [...s];
        });
      }, 300);
    }
  };

  const _addToMainViewStreams = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (!isInMainViewRef.current) {
      addToMainViewStreams({
        mode: streamModes.APPPOLL,
        participantId,
        type: streamTypes.SHARE,
        pollId: idRef.current,
      });
    }
  };

  const _removeFromMainViewStreams = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (isInMainViewRef.current) {
      removeFromMainViewStreams({
        mode: streamModes.APPPOLL,
        participantId,
        type: streamTypes.SHARE,
        pollId: idRef.current,
      });
    }
  };

  const _activatePoll = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (!isActiveRef.current) {
      await activatePoll({ broadcastId, pollId: idRef.current });
    }
  };

  const _deactivatePoll = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (isActiveRef.current) {
      await deactivatePoll({ broadcastId, pollId: idRef.current });
    }
  };

  const _deletePoll = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    await _removeFromMainViewStreams();

    await deletePoll({ broadcastId, pollId: idRef.current });
  };

  const _clearPoll = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    appEventEmitter.emit(appEventEmitterEvents.COPY_POLL_AND_DELETE_OLD, {
      oldPollId: idRef.current,
      question: questionRef.current,
      options: optionsRef.current,
      isActive: isActiveRef.current,
      isInMainView: isInMainViewRef.current,
    });

    await clearPoll({ broadcastId, pollId: idRef.current });
  };

  const toggleActive = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (isActiveRef.current) {
      _deactivatePoll();
    } else {
      _activatePoll();
    }
  };

  const toggleMainViewStreams = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (isInMainViewRef.current) {
      _removeFromMainViewStreams();
    } else {
      _addToMainViewStreams();
    }
  };

  return {
    options,
    question,
    selfSubmittedOptionId,
    isActive,
    isInMainView,
    isCinemaLayoutActive,
    toggleActive,
    toggleMainViewStreams,
    deletePoll: _deletePoll,
    answerPoll: _answerPoll,
    activatePoll: _activatePoll,
    deactivatePoll: _deactivatePoll,
    clearPoll: _clearPoll,
    addToMainViewStreams: _addToMainViewStreams,
    removeFromMainViewStreams: _removeFromMainViewStreams,
  };
};

export default usePoll;
