const validateSpeakerDeviceId = async ({ deviceId }: { deviceId: string }) => {
  if (!navigator?.mediaDevices?.enumerateDevices) {
    return { isValid: false };
  }

  const audioOutputDevice = [];

  const devices = await navigator.mediaDevices.enumerateDevices();

  for (const device of devices) {
    if (device.kind === "audiooutput") {
      audioOutputDevice.push(device);
    }
  }

  return {
    isValid: !!audioOutputDevice.find(
      ({ deviceId: _deviceId }) => deviceId === _deviceId
    ),
  };
};

export default validateSpeakerDeviceId;
