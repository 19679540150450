import React, { useEffect, useMemo, useRef, useState } from "react";
import useAppRtcParticipantExtraWebcam from "../../appRtc/useAppRtcParticipantExtraWebcam";
import { useAppContext } from "../../contexts/appContextDef";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
import useParticipantName from "../../hooks/appState/useParticipantName";
import useParticipantAvatar from "../../hooks/appState/useParticipantAvatar";
import VideoMediaStreamPlayer from "../../components/VideoMediaStreamPlayer";
import {
  MdDelete,
  MdEdit,
  MdMoreVert,
  MdSettings,
  MdVideocam,
} from "react-icons/md";
import { ParticipantNetworkScoreIconContainer } from "./InQueueWebcamStreamContainer";
import {
  generateStreamId,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import { appModes, interactivityModes } from "../../utils/constants";
import { Menu, MenuItem } from "@szhsin/react-menu";
import ChangeNameModal from "../../components/ChangeNameModal";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useToggleParticipantMedia from "../../hooks/appState/useToggleParticipantMedia";

const ItemMenuContainer = ({
  setChangeNameModalOpen,
  isLocal,
  extraWebcamId,
  participantId,
}: {
  setChangeNameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLocal: boolean;
  extraWebcamId: string;
  participantId: string;
}) => {
  const { appMode } = useAppContext();

  const { turnOffExtraWebcamVideoStream } =
    useToggleParticipantMedia(participantId);

  return (
    <Menu
      arrow
      direction="right"
      position="initial"
      menuButton={
        <span>
          <button
            className={`btn btn-primary text-white btn-square btn-xs rounded-none rounded-md`}
          >
            <div>
              <MdMoreVert size={16} />
            </div>
          </button>
        </span>
      }
      transition
    >
      {isLocal && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            appEventEmitter.emit(
              appEventEmitterEvents.OPEN_EXTRA_WEBCAM_SETTINGS,
              { extraWebcamId }
            );
          }}
        >
          <div className="flex items-center justify-center">
            <MdSettings />
            <p className="ml-2">Camera settings</p>
          </div>
        </MenuItem>
      )}

      {(appMode === appModes.HOST || isLocal) && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            setChangeNameModalOpen(true);
          }}
        >
          <div className="flex items-center justify-center">
            <MdEdit />
            <p className="ml-2">Edit name</p>
          </div>
        </MenuItem>
      )}

      {(appMode === appModes.HOST || isLocal) && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            turnOffExtraWebcamVideoStream({ extraWebcamId });
          }}
        >
          <div className="flex items-center justify-center">
            <MdDelete />
            <p className="ml-2">Stop camera</p>
          </div>
        </MenuItem>
      )}
    </Menu>
  );
};

const InQueueExtraWebcamStreamContainer = ({
  extraWebcamId,
  participantId,
}: {
  extraWebcamId: string;
  participantId: string;
}) => {
  const { isActive, isSubscribed, videoTrack } =
    useAppRtcParticipantExtraWebcam({ extraWebcamId, participantId });

  const { isLocal } = useIsLocalParticipantId(participantId);

  const {
    mainViewSelectedStreams,
    inQueueItemContainerWidth,
    mirrorLocalWebcam,
    appMode,
    interactivityMode,
  } = useAppContext();

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const { name } = useParticipantName(participantId);
  const { avatarRemoteUrl } = useParticipantAvatar(participantId);

  const [changeNameModalOpen, setChangeNameModalOpen] = useState(false);

  const { isInMainView } = useMemo(() => {
    const streamId = generateStreamId({
      participantId,
      type: streamTypes.EXTRAWBCM,
      extraWebcamId,
    });

    const isInMainView = !!mainViewSelectedStreams.get(streamId);

    return { isInMainView };
  }, [participantId, mainViewSelectedStreams, extraWebcamId]);

  const isInMainViewRef = useRef(isInMainView);

  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);

  const flipStyle = useMemo(
    () =>
      isLocal && mirrorLocalWebcam
        ? { transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)" }
        : {},
    [isLocal, mirrorLocalWebcam]
  );

  const _handleAddToMainViewStreams = () => {
    addToMainViewStreams({
      participantId,
      type: streamTypes.EXTRAWBCM,
      extraWebcamId,
    });
  };

  const _handleRemoveFromMainViewStreams = () => {
    removeFromMainViewStreams({
      participantId,
      type: streamTypes.EXTRAWBCM,
      extraWebcamId,
    });
  };

  const _handleToggleFromMainViewStreams = () => {
    const _isInMainView = isInMainViewRef.current;

    if (_isInMainView) {
      _handleRemoveFromMainViewStreams();
    } else {
      _handleAddToMainViewStreams();
    }
  };

  return (
    <React.Fragment>
      <div style={{ width: inQueueItemContainerWidth }}>
        <div
          style={{
            height: (inQueueItemContainerWidth * 9) / 16,
            width: inQueueItemContainerWidth,
          }}
          className="relative video-cover bg-custom-blue-50"
        >
          {isActive && isSubscribed ? (
            <div
              id={`InQueueExtraWebcamStreamContainer-${participantId}`}
              className="h-full w-full rounded-t-md overflow-hidden relative"
            >
              <VideoMediaStreamPlayer
                {...{
                  flipStyle,
                  videoOn: isActive && isSubscribed,
                  videoTrack,
                  key: `VideoMediaStreamPlayer-webcam-stream-${videoTrack?.id}-${participantId}`,
                  participantId,
                  isWebcam: true,
                }}
              />
            </div>
          ) : (
            <div className="flex h-full w-full items-center justify-center rounded-t-md overflow-hidden">
              <div className="rounded-full p-3">
                <img
                  alt={`in-queue-webcam-stream-avatar`}
                  className="w-10 h-10 rounded-full"
                  src={avatarRemoteUrl}
                />
              </div>
            </div>
          )}

          <div className="top-0 left-0 right-0 bottom-0 absolute flex justify-between">
            {/* only if host */}
            {appMode === appModes.HOST &&
              interactivityMode === interactivityModes.STUDIO && (
                <div className="p-3 flex flex-1 hover:opacity-100 opacity-0 items-end justify-center">
                  <button
                    className="btn btn-primary text-white btn-active"
                    onClick={_handleToggleFromMainViewStreams}
                  >
                    {isInMainView ? "Remove" : "Add to stream"}
                  </button>
                </div>
              )}

            {/* {(appMode === appModes.HOST || isLocal) && (
          <div className="absolute top-2 left-2 flex items-center justify-center ">
            {appMode === appModes.HOST &&
            interactivityMode === interactivityModes.CONFERENCE ? (
              <div
                onClick={() => {
                  _handleTogglePin();
                }}
                className="cursor-pointer  rounded-md p-0.5 fill-custom-blue-600 bg-custom-blue-200"
              >
                {confPinned ? (
                  <MdPushPin className={"h-4 w-4 fill-custom-blue-600"} />
                ) : (
                  <MdOutlinePushPin
                    className={"h-4 w-4 fill-custom-blue-600"}
                  />
                )}
              </div>
            ) : (
              <div
                onClick={() => {
                  _handleToggleSoloMode();
                }}
                className={`cursor-pointer h-8 flex items-center justify-center aspect-square rounded p-0.5 fill-custom-blue-600 ${
                  isSoloParticipant ? "bg-primary" : "bg-custom-blue-100"
                }`}
              >
                {isSoloParticipant ? (
                  <MdPerson className={"h-6 w-6 fill-white"} />
                ) : (
                  <MdOutlinePersonOutline
                    className={"h-6 w-6 fill-custom-blue-400"}
                  />
                )}
              </div>
            )}

            {participantRaisedHand ? (
              <div
                onClick={() => {
                  toggleRaiseHand();
                }}
                className="cursor-pointer ml-1 rounded-md p-0.5 fill-custom-blue-700 bg-custom-blue-100"
              >
                <MdPanTool className={"h-4 w-4 fill-custom-blue-700"} />
              </div>
            ) : null}
          </div>
        )} */}

            <div className="absolute top-2 right-2 flex">
              <ItemMenuContainer
                {...{
                  setChangeNameModalOpen,
                  isLocal,
                  extraWebcamId,
                  participantId,
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex items-center p-0.5 ${
            isInMainView
              ? "bg-primary text-white fill-white"
              : "bg-white fill-gray-800 text-gray-800"
          } rounded-b-md overflow-hidden gap-0.5`}
        >
          <div className="w-6 flex items-center justify-center">
            <MdVideocam size={16} />
          </div>
          <p className="truncate text-ellipsis text-sm flex-1">{name}</p>
          <ParticipantNetworkScoreIconContainer {...{ participantId }} />
        </div>
      </div>
      {/* {changeAvatarModalOpen && (
    <ChangeAvatarModal
      {...{
        isOpen: changeAvatarModalOpen,
        handleCancel: () => {
          setChangeAvatarModalOpen(false);
        },
        handleSave: ({ remoteUrl, isOld }) => {
          changeAvatar({ avatarRemoteUrl: remoteUrl, isOld });
          setChangeAvatarModalOpen(false);
        },
        src: avatarRemoteUrl,
      }}
    />
  )} */}
      {changeNameModalOpen && (
        <ChangeNameModal
          {...{
            participantId,
            handleCancel: () => {
              setChangeNameModalOpen(false);
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

export default InQueueExtraWebcamStreamContainer;
