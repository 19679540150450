import { appRtcClient, appRtcClients } from "../utils/constants";
import useLivekitRtcParticipantExtraWebcam from "./livekitRtc/useLivekitRtcParticipantExtraWebcam";

const useAppRtcParticipantExtraWebcam =
  appRtcClient === appRtcClients.livekit
    ? useLivekitRtcParticipantExtraWebcam
    : () => ({
        isActive: false,
        participantsExtraWebcamStream: undefined,
        isSubscribed: false,
        videoTrack: undefined,
        deviceId: undefined,
      });

export default useAppRtcParticipantExtraWebcam;
