// import { useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import getRemainingTime from "../../apis/broadcasts/get-remaining-time";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import updateRemainingTime from "../../apis/broadcasts/update-remaining-time";
import stopBroadcastEgresses from "../../apis/egress/stopBroadcastEgresses";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";
// import { appModes } from "../../utils/constants";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const BroadcastTimerListner = () => {
  const totalTimeLeftRef = useRef<{ totalTimeLeftInSeconds: number | null }>({
    totalTimeLeftInSeconds: null,
  });

  const { joinedParticipants } = useAppContext();
  const { localParticipantId } = useLocalParticipantId();
  const { broadcastId } = useAppConfigContext();

  const broadcastIdRef = useRef(broadcastId);

  const lastUpdateDRemainingTime = useRef<number | null>(null);

  const broadcastRemainingTimePublishRef = useRef<appSingalingPublishType>();

  const isTimerRunningRef = useRef(false);
  const intervalRef = useRef<null | ReturnType<typeof setInterval>>(null);
  const endCallPublishRef = useRef<appSingalingPublishType | null>(null);

  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);

  const { participantIds } = useParticipantIdsAndCount();

  const isOldestParticipant = useMemo(() => {
    let oldest: { timestamp: number; participantId: string | null } = {
      timestamp: new Date().getTime() + 3 * 1000,
      participantId: null,
    };

    joinedParticipants.forEach(({ timestamp, participantId }) => {
      if (
        timestamp < oldest.timestamp &&
        participantIds.includes(participantId)
      ) {
        oldest = { timestamp, participantId };
      }
    });

    if (oldest.participantId === localParticipantId) {
      return true;
    } else {
      return false;
    }
  }, [participantIds, joinedParticipants, localParticipantId]);

  const isOldestParticipantRef = useRef(isOldestParticipant);

  const _handleEndSession = async () => {
    await stopBroadcastEgresses({ broadcastId: broadcastIdRef.current });

    typeof endCallPublishRef.current === "function" &&
      endCallPublishRef.current(
        JSON.stringify({ end: true, endedReason: "ended_due_to_timeout" })
        // , {
        //   persist: false,
        // }
      );
  };

  const _handleUpdateRemainingTime = async () => {
    const newRemainingTimeValue = Math.ceil(
      (totalTimeLeftRef.current.totalTimeLeftInSeconds || 0) / 60
    );

    if (newRemainingTimeValue !== lastUpdateDRemainingTime.current) {
      lastUpdateDRemainingTime.current = newRemainingTimeValue;

      await updateRemainingTime({
        broadcastId: broadcastIdRef.current,
        remainingTime: newRemainingTimeValue,
      });
    }
  };

  const _handleResumeTimer = useCallback(async () => {
    if (!isTimerRunningRef.current) {
      isTimerRunningRef.current = true;

      const { success, data } = await getRemainingTime({
        broadcastId: broadcastIdRef.current,
      });

      if (success && data) {
        const timeLeftInSeconds = parseInt(`${data.remainingTime}`) * 60;

        totalTimeLeftRef.current.totalTimeLeftInSeconds = timeLeftInSeconds;

        const interval = setInterval(() => {
          if (totalTimeLeftRef.current.totalTimeLeftInSeconds === 0) {
            _handleEndSession();

            if (intervalRef.current) {
              clearInterval(intervalRef.current);
            }
          } else {
            let timeLeftInSeconds;

            if ((totalTimeLeftRef.current.totalTimeLeftInSeconds || 0) < 1) {
              timeLeftInSeconds = 0;
            } else {
              timeLeftInSeconds =
                (totalTimeLeftRef.current.totalTimeLeftInSeconds || 0) - 1;
            }

            totalTimeLeftRef.current.totalTimeLeftInSeconds = timeLeftInSeconds;

            if (
              typeof broadcastRemainingTimePublishRef.current === "function"
            ) {
              broadcastRemainingTimePublishRef.current(
                JSON.stringify({ totalTimeLeftInSeconds: timeLeftInSeconds })
                // { persist: false }
              );
            }
          }

          _handleUpdateRemainingTime();
        }, 1000);

        intervalRef.current = interval;
      }
    }
  }, []);

  const _handleStopTimer = useCallback(() => {
    if (isTimerRunningRef.current) {
      isTimerRunningRef.current = false;

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, []);

  useEffect(() => {
    isOldestParticipantRef.current = isOldestParticipant;
  }, [isOldestParticipant]);

  const { participantsCount } = useParticipantIdsAndCount();

  useEffect(() => {
    if (isOldestParticipant && participantsCount > 1) {
      _handleResumeTimer();
    } else {
      _handleStopTimer();
    }
  }, [
    participantsCount,
    isOldestParticipant,
    _handleResumeTimer,
    _handleStopTimer,
  ]);

  const { publish: endCallPublish } = useAppSingalingPublish(
    appPubSubTopics.END_CALL
  );

  const { publish: broadcastRemainingTimePublish } = useAppSingalingPublish(
    appPubSubTopics.BROADCAST_REMAINING_TIME
  );

  useAppSingalingSubscribe(
    appPubSubTopics.BROADCAST_REMAINING_TIME,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const totalTimeLeftInSeconds = message.totalTimeLeftInSeconds;

      appEventEmitter.emit(
        appEventEmitterEvents.TOTAL_TIME_REMAINING_IN_SECONDS,
        { totalTimeLeftInSeconds }
      );

      if (!isOldestParticipantRef.current) {
        totalTimeLeftRef.current.totalTimeLeftInSeconds =
          totalTimeLeftInSeconds;
      }
    }
  );

  useEffect(() => {
    broadcastRemainingTimePublishRef.current = broadcastRemainingTimePublish;
  }, [broadcastRemainingTimePublish]);
  useEffect(() => {
    endCallPublishRef.current = endCallPublish;
  }, [endCallPublish]);

  return <React.Fragment />;
};

export default BroadcastTimerListner;
