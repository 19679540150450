import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import { MOMENTO_LOGO } from "../../static/images";
import ParticipantCountContainer from "./participantCountContainer/ParticipantCountContainer";
import StartStreamingContainer from "./startStreamingContainer/StartStreamingContainer";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import Modal from "react-modal";
import { useWindowSize } from "@react-hook/window-size";
import useIsFreePlanActive from "../../hooks/plans/useIsFreePlanActive";
import BroadcastTimer from "./BroadcastTimer";
import QuickStartTourContainer from "../quickStartTourContainer/QuickStartTourContainer";
import useIsMobilePortraitOrLandscape from "../../hooks/responsive/useIsMobilePortraitOrLandscape";
import { appModes } from "../../utils/constants";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";

const topbarFeatures = {
  streaming: "streaming",
  participantCount: "participantCount",
  waitingRoomParticipants: "waitingRoomParticipants",
  broadcastTimer: "broadcastTimer",
};

const Topbar = ({
  onContainerHeight,
}: {
  onContainerHeight?: (height: number) => void;
}) => {
  const [expanded, setExpanded] = useState(false);

  const { meetingName, meetingMode } = useAppConfigContext();
  const {
    topBarHeight,
    appMode,
    nonHostCanSeeParticipantsCount,
    joinedParticipants,
  } = useAppContext();

  const { localParticipantId } = useLocalParticipantId();

  const originalAppMode = useMemo(
    () =>
      joinedParticipants.find(
        ({ participantId }) => participantId === localParticipantId
      )?.originalAppMode,
    [localParticipantId, joinedParticipants]
  );

  const { isFreePlanActive } = useIsFreePlanActive();

  const topbarVisibleFeatures = useMemo(() => {
    const topbarVisibleFeatures = [];

    if (
      appMode === appModes.HOST ||
      (appMode === appModes.SPEAKER && nonHostCanSeeParticipantsCount)
    ) {
      topbarVisibleFeatures.push(topbarFeatures.participantCount);
    }

    if (isFreePlanActive) {
      topbarVisibleFeatures.push(topbarFeatures.broadcastTimer);
    }

    if (appMode === appModes.HOST) {
      topbarVisibleFeatures.push(topbarFeatures.streaming);
    }

    return topbarVisibleFeatures;
  }, [appMode, nonHostCanSeeParticipantsCount, isFreePlanActive]);

  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  const [windowWidth, windowHeight] = useWindowSize();

  const _renderBrandAndTitle = () => (
    <div className="flex h-full items-center justify-center">
      <a
        href="https://momentostream.com/"
        className="h-full"
        target="_blank"
        rel="noreferrer"
      >
        <img className="h-full" src={MOMENTO_LOGO} alt="momento-logo" />
      </a>
      <div className="ml-3">
        <p className="font-bold">{meetingName}</p>
      </div>
    </div>
  );

  const containerRef = createRef<HTMLDivElement>();

  const [containerHeight, setContainerHeight] = useState(0);

  const containerHeightRef = useRef<number>();

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
    }
  }, [containerRef]);

  useEffect(() => {
    if (typeof onContainerHeight === "function") {
      onContainerHeight(containerHeight);
    }
  }, [containerHeight]);

  return (
    <React.Fragment>
      <div
        ref={containerRef as React.LegacyRef<HTMLDivElement>}
        className="border-b border-gray-300 py-1 px-3 flex items-center justify-between"
        style={{ height: topBarHeight }}
      >
        <div
          style={{ height: topBarHeight }}
          className="flex gap-8 items-center"
        >
          {_renderBrandAndTitle()}
          {!isMobilePortraitOrLandscape &&
          originalAppMode === appModes.HOST &&
          meetingMode === "full" ? (
            <QuickStartTourContainer />
          ) : (
            <React.Fragment />
          )}
        </div>
        {isMobilePortraitOrLandscape && topbarVisibleFeatures.length ? (
          <div className="flex">
            {topbarVisibleFeatures.includes(topbarFeatures.broadcastTimer) ? (
              <div className="flex flex-1 items-center justify-center mr-3">
                <BroadcastTimer />
              </div>
            ) : null}
            <button
              onClick={() => {
                setExpanded((s) => !s);
              }}
              className="btn btn-sm btn-primary btn-outline"
            >
              {expanded ? (
                <MdExpandLess size={20} />
              ) : (
                <MdExpandMore size={20} />
              )}
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            {topbarVisibleFeatures.map((feature, i) => {
              return (
                <div
                  key={`topbar-visible-features-${feature}-${i}`}
                  className={`flex`}
                >
                  {i === 0 ? (
                    <React.Fragment />
                  ) : (
                    <div
                      style={{ width: 1 }}
                      className={`mx-3 bg-gray-300`}
                    ></div>
                  )}
                  {feature === topbarFeatures.streaming ? (
                    <StartStreamingContainer />
                  ) : feature === topbarFeatures.participantCount ? (
                    <ParticipantCountContainer />
                  ) : feature === topbarFeatures.broadcastTimer ? (
                    <BroadcastTimer />
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>

      {isMobilePortraitOrLandscape ? (
        <Modal
          preventScroll
          shouldCloseOnOverlayClick
          onRequestClose={() => {
            setExpanded((s) => !s);
          }}
          isOpen={expanded}
          style={{
            overlay: {
              padding: 0,
              margin: 0,
              height: windowHeight,
              width: windowWidth,
              top: 0,
              left: 0,
              position: "absolute",
              backgroundColor: "rgba(255, 255, 255, 0.4)",
            },
            content: {
              padding: 0,
              margin: 0,
              height: windowHeight,
              width: windowWidth,
              top: 0,
              left: 0,
              position: "absolute",
              backgroundColor: "rgba(255, 255, 255, 0.4)",
            },
          }}
        >
          <div className="bg-white">
            <div
              style={{ height: topBarHeight }}
              className="border-b border-gray-300 py-1 px-3 flex items-center justify-between"
            >
              {_renderBrandAndTitle()}
              <div>
                <button
                  onClick={() => {
                    setExpanded((s) => !s);
                  }}
                  className="btn btn-sm btn-primary btn-outline"
                >
                  {expanded ? (
                    <MdExpandLess size={20} />
                  ) : (
                    <MdExpandMore size={20} />
                  )}
                </button>
              </div>
            </div>

            <div className="p-3">
              <div className="flex">
                {topbarVisibleFeatures.includes(
                  topbarFeatures.participantCount
                ) ? (
                  <div className="flex flex-1 items-center justify-center">
                    <ParticipantCountContainer />
                  </div>
                ) : null}
              </div>
              {topbarVisibleFeatures.includes(topbarFeatures.streaming) ? (
                <React.Fragment>
                  <div style={{ height: 2 }} className="bg-gray-200 my-3"></div>
                  <StartStreamingContainer />
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default Topbar;
