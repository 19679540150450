import { momentostreamApiServerBaseUrl } from "../../utils/constants";
import { getMSQuickstartBrands, removeMSQuickstartBrands } from "../quickstart";
import activateBrand from "./activate-brand";
import deleteBrand from "./delete-brand";

const getUserBrands = async ({ userId }: { userId: string }) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/get-user-brands/${userId}`;

    const res = await fetch(url);

    const json = await res.json();

    if (json.success) {
      const msQuickstartBrands = await getMSQuickstartBrands({ userId });

      let brands: {
        id: string;
        isActive: number | boolean;
        name: string;
        color: string;
        showDisplayNames: boolean;
        showHeadlines: boolean;
        muteGuestsWhenAudioPlays: boolean;
        muteGuestsWhenVideoPlays: boolean;
      }[] = json.data;

      if (msQuickstartBrands?.length < brands?.length) {
        for (let index = 0; index < json.data.length; index++) {
          const brand = json.data[index];

          if (msQuickstartBrands.includes(brand.id)) {
            await deleteBrand({ brandId: brand.id, userId });

            brands = brands?.filter(({ id }) => `${id}` !== `${brand.id}`);
          }
        }

        await removeMSQuickstartBrands({ userId });
      }

      const activeBrand = brands?.find(({ isActive }) => isActive);

      if (!activeBrand && brands?.length) {
        brands[0].isActive = 1;

        await activateBrand({ brandId: brands[0].id, userId });
      }

      return { success: true, brands };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default getUserBrands;
