import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { getBrandLogosTopicById } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const brandLogosActions = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  RENAME: "RENAME",
  SORT: "SORT",
};

const BrandLogosListner = ({ id: brandId }: { id: string }) => {
  const { setBrandLogos, setSortedBrandLogoIds } = useAppContext();

  const _handleAdd = (brandLogo: singleBrandLogoType) => {
    setBrandLogos((s) => ({
      ...s,
      [brandId]: [...(s[brandId] || []), brandLogo],
    }));
  };

  const _handleRemove = ({ id }: { id: string }) => {
    setBrandLogos((s) => ({
      ...s,
      [brandId]: s[brandId].filter(({ id: _id }) => _id !== id),
    }));
  };

  const _handleRename = ({ id, name }: { id: string; name: string }) => {
    setBrandLogos((s) => ({
      ...s,
      [brandId]: s[brandId].map((brandLogo) =>
        id === brandLogo.id ? { ...brandLogo, name } : brandLogo
      ),
    }));
  };

  const _handleSort = ({ ids }: { ids: string[] }) => {
    setSortedBrandLogoIds((s) => ({ ...s, [brandId]: ids }));
  };

  useAppSingalingSubscribe(
    getBrandLogosTopicById(brandId),
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case brandLogosActions.ADD:
          _handleAdd(message.payload);
          break;

        case brandLogosActions.REMOVE:
          _handleRemove(message.payload);
          break;

        case brandLogosActions.RENAME:
          _handleRename(message.payload);
          break;

        case brandLogosActions.SORT:
          _handleSort(message.payload);
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default BrandLogosListner;
