import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import {
  MdGroups,
  MdOutlineGroups,
  MdSupervisedUserCircle,
  MdOutlineSupervisedUserCircle,
} from "react-icons/md";
import { useAppContext } from "../../contexts/appContextDef";
import useInteractivityMode from "../../hooks/appState/useInteractivityMode";
import useConferenceModeMaxParticipantCount from "../../hooks/conference/useConferenceModeMaxParticipantCount";
import { layoutDisplayNamesAndIcons } from "../../listners/appState/MainViewLayoutListner";
import useMainViewLayout from "../../hooks/appState/useMainViewLayout";
import {
  interactivityModes,
  mainViewLayouts,
  mainViewMaxWebcamStreamsCount,
  mainViewShareLayouts,
  mainViewWebcamLayouts,
} from "../../utils/constants";
import useIsMobilePortrait from "../../hooks/responsive/useIsMobilePortrait";
import useIsMobilePortraitOrLandscape from "../../hooks/responsive/useIsMobilePortraitOrLandscape";
import Tooltip from "../../components/Tooltip";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useGetCalculatedConferenceModeMaxParticipantCount from "../../hooks/conference/useGetCalculatedConferenceModeMaxParticipantCount";
import useGetCalculatedConferenceModeAutoLayout from "../../hooks/conference/useGetCalculatedConferenceModeAutoLayout";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";

const AutoLayoutIconContainer = () => {
  const { conferenceAutoMainVideoLayoutEnabled, interactivityMode } =
    useAppContext();

  const { changeMainViewLayout } = useMainViewLayout();

  const { getCalculatedConferenceModeAutoLayout } =
    useGetCalculatedConferenceModeAutoLayout();

  return interactivityMode === interactivityModes.CONFERENCE ? (
    <Tooltip title={`Auto layout (Shift + A)`}>
      <div
        onClick={() => {
          const { mainViewLayout } = getCalculatedConferenceModeAutoLayout();

          changeMainViewLayout({
            conferenceAutoMainVideoLayoutEnabled: true,
            layout: mainViewLayout,
          });
        }}
        style={{ fontFamily: "Lato" }}
        className={`tracking-widest text-xs font-bold ${
          conferenceAutoMainVideoLayoutEnabled
            ? "text-custom-blue-900"
            : "text-custom-blue-100"
        } mr-1 rounded-md border-2 transition-all ${
          conferenceAutoMainVideoLayoutEnabled
            ? "bg-custom-blue-50 border-opacity-100  border-primary"
            : "bg-white border-custom-blue-100 "
        } flex items-center cursor-pointer justify-center px-1 py-2 h-full ${
          quickstartTargetComponentClassNames.auto_layout_icon_container
        }`}
      >
        AUTO
      </div>
    </Tooltip>
  ) : (
    <React.Fragment />
  );
};

const LayoutIconContainer = ({ layout }: { layout: mainViewLayoutType }) => {
  const { conferenceAutoMainVideoLayoutEnabled } = useAppContext();
  const { changeMainViewLayout, mainViewLayoutSelected } = useMainViewLayout();

  const { isActive } = useMemo(() => {
    const isActive =
      mainViewLayoutSelected === layout &&
      !conferenceAutoMainVideoLayoutEnabled;

    return { isActive };
  }, [layout, conferenceAutoMainVideoLayoutEnabled, mainViewLayoutSelected]);

  const { layoutName, Icon, ActiveIcon, shortcut } = useMemo(() => {
    const { layoutName, Icon, ActiveIcon, shortcut } =
      layoutDisplayNamesAndIcons[layout];
    return { layoutName, Icon, ActiveIcon, shortcut };
  }, [layout]);

  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  return (
    <Tooltip title={`${layoutName} (${shortcut})`}>
      <div
        className={`rounded-md border-2 transition-all ${
          isActive
            ? "bg-white border-primary"
            : "bg-white border-custom-blue-100"
        } flex items-center justify-center`}
      >
        <button
          onClick={() => {
            changeMainViewLayout({ layout });
          }}
        >
          {isActive ? (
            <ActiveIcon
              className={`${isMobilePortraitOrLandscape ? "h-6" : "h-8"}`}
            />
          ) : (
            <Icon
              className={`${isMobilePortraitOrLandscape ? "h-6" : "h-8"}`}
            />
          )}
        </button>
      </div>
    </Tooltip>
  );
};

const InteractivityModeContainer = () => {
  const { isMobilePortrait } = useIsMobilePortrait();
  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  const {
    interactivityMode,
    conferenceModeMaxParticipantCount,
    conferenceModeParticipantAutoCountEnabled,
  } = useAppContext();

  const { changeConferenceModeMaxParticipantCount } =
    useConferenceModeMaxParticipantCount();

  const { isStudioInteractivityMode, isConferenceInteractivityMode } = useMemo(
    () => ({
      isStudioInteractivityMode:
        interactivityMode === interactivityModes.STUDIO,

      isConferenceInteractivityMode:
        interactivityMode === interactivityModes.CONFERENCE,
    }),
    [interactivityMode]
  );

  const { changeInteractivityMode } = useInteractivityMode();

  const conferenceModeMaxParticipantLimit = useMemo(
    () => [...Array(mainViewMaxWebcamStreamsCount).keys()],
    []
  );

  const { getCalculatedConferenceModeMaxParticipantCount } =
    useGetCalculatedConferenceModeMaxParticipantCount();

  return (
    <div
      className={`flex ${
        isMobilePortrait ? "flex-col" : ""
      } gap-2 items-center justify-center h-full ${
        quickstartTargetComponentClassNames.interactivity_mode_container
      }`}
    >
      {[
        {
          quickstartTargetComponentClassName:
            quickstartTargetComponentClassNames.interactivity_mode_container_studio,
          isActive: isStudioInteractivityMode,
          enable: () => {
            changeInteractivityMode({
              interactivityMode: interactivityModes.STUDIO,
            });
          },
          dataTip: "Studio mode (Shift + S)",
          ActiveIcon: MdSupervisedUserCircle,
          Icon: MdOutlineSupervisedUserCircle,
        },
        {
          quickstartTargetComponentClassName:
            quickstartTargetComponentClassNames.interactivity_mode_container_conference,
          isActive: isConferenceInteractivityMode,
          enable: async () => {
            changeInteractivityMode({
              interactivityMode: interactivityModes.CONFERENCE,
            });

            appEventEmitter.emit(
              appEventEmitterEvents.HIDE_HOST_IN_QUEUE_POPUP_MESSAGE
            );
          },
          dataTip: "Conference mode (Shift + C)",
          ActiveIcon: MdGroups,
          Icon: MdOutlineGroups,
          renderRightContainer:
            isMobilePortrait && !isConferenceInteractivityMode
              ? undefined
              : () => {
                  return (
                    <div
                      className={`text-primary bg-custom-blue-50 ${
                        isConferenceInteractivityMode
                          ? ""
                          : "opacity-0 cursor-none pointer-events-none"
                      }`}
                    >
                      <select
                        className="text-primary bg-custom-blue-50 font-medium"
                        onChange={(e) => {
                          if (e.target.value === "auto") {
                            const { conferenceModeMaxParticipantCount } =
                              getCalculatedConferenceModeMaxParticipantCount();

                            changeConferenceModeMaxParticipantCount({
                              conferenceModeMaxParticipantCount:
                                conferenceModeMaxParticipantCount,
                              conferenceModeParticipantAutoCountEnabled: true,
                            });
                          } else {
                            changeConferenceModeMaxParticipantCount({
                              conferenceModeMaxParticipantCount: parseInt(
                                e.target.value
                              ),
                              conferenceModeParticipantAutoCountEnabled: false,
                            });
                          }
                        }}
                        value={
                          conferenceModeParticipantAutoCountEnabled
                            ? "auto"
                            : conferenceModeMaxParticipantCount
                        }
                      >
                        <option value={"auto"}>Auto</option>

                        {conferenceModeMaxParticipantLimit.map((i) => (
                          <option
                            value={i + 1}
                            key={`max-participant-selectoroption-${i}`}
                          >
                            {`${i + 1}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  );
                },
        },
      ].map(
        (
          {
            quickstartTargetComponentClassName,
            isActive,
            enable,
            Icon,
            ActiveIcon,
            dataTip,
            renderRightContainer,
          },
          i
        ) => {
          return (
            <Tooltip
              key={`interactivity-selection-item-${i}`}
              title={`${dataTip}`}
            >
              <div
                onClick={enable}
                className={`rounded-md border-2 transition-all ${
                  isActive
                    ? "bg-custom-blue-50 border-opacity-100  border-primary"
                    : "bg-white border-custom-blue-100 "
                } flex items-center cursor-pointer justify-center px-4 h-full ${quickstartTargetComponentClassName}`}
              >
                {isActive ? (
                  <ActiveIcon
                    size={isMobilePortraitOrLandscape ? 24 : 32}
                    className={"fill-custom-blue-900 h-full"}
                  />
                ) : (
                  <Icon
                    size={isMobilePortraitOrLandscape ? 24 : 32}
                    className={"h-full fill-custom-blue-100"}
                  />
                )}

                {typeof renderRightContainer === "function" && isActive ? (
                  <div
                    className="flex items-center justify-center h-full"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div className="h-4 mx-2 w-0.5 bg-primary"></div>
                    {renderRightContainer()}
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </div>
            </Tooltip>
          );
        }
      )}
    </div>
  );
};

const MainViewLayoutsControlContainer = ({
  onContainerHeight,
}: {
  onContainerHeight?: (height: number) => void;
}) => {
  const { interactivityMode } = useAppContext();
  const { isMobilePortrait } = useIsMobilePortrait();
  const { changeMainViewLayout } = useMainViewLayout();
  const { changeInteractivityMode } = useInteractivityMode();

  const { getCalculatedConferenceModeAutoLayout } =
    useGetCalculatedConferenceModeAutoLayout();

  const interactivityModeRef = useRef(interactivityMode);

  useEffect(() => {
    interactivityModeRef.current = interactivityMode;
  }, [interactivityMode]);

  const _handleKeyPressed = async (e: KeyboardEvent) => {
    if (
      e.shiftKey &&
      document?.activeElement?.tagName?.toLowerCase() !== "input"
    ) {
      switch (e.code) {
        case "Digit1":
          changeMainViewLayout({ layout: mainViewLayouts.SOLO });
          break;
        case "Digit2":
          changeMainViewLayout({ layout: mainViewLayouts.CROPPED });
          break;
        case "Digit3":
          changeMainViewLayout({ layout: mainViewLayouts.GROUP });
          break;
        case "Digit4":
          changeMainViewLayout({ layout: mainViewLayouts.SPOTLIGHT });
          break;
        case "Digit5":
          changeMainViewLayout({ layout: mainViewLayouts.NEWS });
          break;
        case "Digit6":
          changeMainViewLayout({ layout: mainViewLayouts.SCREEN });
          break;
        case "Digit7":
          changeMainViewLayout({ layout: mainViewLayouts.PICTURE_IN_PICTURE });
          break;
        case "Digit8":
          changeMainViewLayout({ layout: mainViewLayouts.CINEMA });
          break;

        case "KeyA":
          if (interactivityModeRef.current === interactivityModes.CONFERENCE) {
            const { mainViewLayout } = getCalculatedConferenceModeAutoLayout();

            changeMainViewLayout({
              conferenceAutoMainVideoLayoutEnabled: true,
              layout: mainViewLayout,
            });
          }

          break;

        case "KeyS":
          changeInteractivityMode({
            interactivityMode: interactivityModes.STUDIO,
          });

          break;
        case "KeyC":
          changeInteractivityMode({
            interactivityMode: interactivityModes.CONFERENCE,
          });

          appEventEmitter.emit(
            appEventEmitterEvents.HIDE_HOST_IN_QUEUE_POPUP_MESSAGE
          );

          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", _handleKeyPressed);

    return () => {
      document.removeEventListener("keypress", _handleKeyPressed);
    };
  }, []);

  const { isMobilePortraitOrLandscape } = useIsMobilePortraitOrLandscape();

  const containerRef = createRef<HTMLDivElement>();

  const [containerHeight, setContainerHeight] = useState(0);

  const containerHeightRef = useRef(containerHeight);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
    }
  }, [containerRef]);

  useEffect(() => {
    if (typeof onContainerHeight === "function") {
      onContainerHeight(containerHeight);
    }
  }, [containerHeight]);

  return (
    <div
      ref={containerRef as React.LegacyRef<HTMLDivElement>}
      className={`select-none flex items-center justify-center p-1.5 ${quickstartTargetComponentClassNames.main_view_layouts_control_container}`}
    >
      <div
        className={`flex items-center justify-center ${quickstartTargetComponentClassNames.main_view_layouts_control_layout_selection_container}`}
      >
        {isMobilePortrait ? (
          <div className="flex felx-1 flex-col">
            <div className="flex flex-1">
              <AutoLayoutIconContainer />
              {Object.keys(mainViewWebcamLayouts).map((layout, i) => (
                <div
                  className={`${i === 0 ? "" : "ml-2"}`}
                  key={`layout-selection-item-${layout}_${i}`}
                >
                  <LayoutIconContainer layout={layout as mainViewLayoutType} />
                </div>
              ))}
            </div>
            <div className="flex flex-1 mt-2">
              {Object.keys(mainViewShareLayouts).map((layout, i) => (
                <div
                  className={`${i === 0 ? "" : "ml-2"}`}
                  key={`layout-selection-item-${layout}_${i}`}
                >
                  <LayoutIconContainer layout={layout as mainViewLayoutType} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <React.Fragment>
            <AutoLayoutIconContainer />
            {Object.keys(mainViewLayouts).map((layout, i) => (
              <div
                key={`layout-selection-item-${layout}_${i}`}
                className={i === 0 ? "" : "ml-1"}
              >
                <LayoutIconContainer layout={layout as mainViewLayoutType} />
              </div>
            ))}
          </React.Fragment>
        )}
      </div>

      <div
        style={{ width: 2, height: (containerHeight * 2) / 3 }}
        className={` bg-gray-500 ${
          isMobilePortraitOrLandscape ? "mx-2" : "mx-4"
        }`}
      ></div>

      <InteractivityModeContainer />
    </div>
  );
};

export default MainViewLayoutsControlContainer;
