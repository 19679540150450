import { businessPlanTypes } from "../../utils/constants";
import useActivePlanType from "./useActivePlanType";

const useIsProfessionalPlanActive = () => {
  const { activePlanType } = useActivePlanType();

  return {
    isProfessionalPlanActive: activePlanType === businessPlanTypes.professional,
  };
};

export default useIsProfessionalPlanActive;
