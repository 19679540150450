import React from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const bannersActions = {
  CREATE_BANNER: "CREATE_BANNER",
  DELETE_BANNER: "DELETE_BANNER",
  UPDATE_BANNER: "UPDATE_BANNER",
};

const BannersListner = () => {
  const { setBanners } = useAppContext();

  const _handleCreateBanner = ({
    payload,
  }: {
    payload: { banner: bannerType };
  }) => {
    setBanners((s) => [...s, payload.banner]);
  };

  const _handleDeleteBanner = ({
    payload,
  }: {
    payload: { bannerId: number };
  }) => {
    setBanners((s) => s.filter(({ id }) => id !== payload.bannerId));
  };

  const _handleUpdateBanner = ({
    payload,
  }: {
    payload: { banner: bannerType };
  }) => {
    setBanners((s) => {
      const banners = [...s];

      const index = banners.findIndex(({ id }) => id !== payload.banner.id);

      if (index !== -1) {
        banners[index] = payload.banner;
      }

      return banners;
    });
  };

  useAppSingalingSubscribe(
    appPubSubTopics.BANNERS,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      switch (message.action) {
        case bannersActions.CREATE_BANNER:
          _handleCreateBanner({
            payload: message.payload,
          });
          break;

        case bannersActions.DELETE_BANNER:
          _handleDeleteBanner({
            payload: message.payload,
          });
          break;

        case bannersActions.UPDATE_BANNER:
          _handleUpdateBanner({
            payload: message.payload,
          });
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default BannersListner;
