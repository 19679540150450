import useMainViewLayout from "../../hooks/appState/useMainViewLayout";
import MainViewParticipantContainer from "./MainViewParticipantContainer";

const MainViewContainerGridItemsMapper = ({
  gridContainerStyles,
  mainViewContainerHeight,
  mainViewContainerWidth,
}: {
  gridContainerStyles?: React.CSSProperties;
  mainViewContainerHeight: number;
  mainViewContainerWidth: number;
}) => {
  const { gridWithStreamsPosition } = useMainViewLayout();

  return (
    <div style={gridContainerStyles}>
      {gridWithStreamsPosition.map((gridItem) => (
        <span
          {...{
            key: `MVPC-${gridItem.streamId}`,
            id: `MVPC-${gridItem.streamId}`,
          }}
        >
          <MainViewParticipantContainer
            {...{
              ...gridItem,
              mainViewContainerHeight,
              mainViewContainerWidth,
            }}
          />
        </span>
      ))}
    </div>
  );
};

export default MainViewContainerGridItemsMapper;
