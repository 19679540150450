import { appModes } from "../../utils/constants";
import { createUID } from "../../utils/createUID";

export const APP_ENVS = { DEV: "DEV", PROD: "PROD" };

export const APP_ENV = APP_ENVS.PROD;

const getTestBroadcastData = async (): Promise<{
  success: boolean;
  data?: appConfigType;
}> => {
  const broadcastId = "00307223840f0e10db1cadefb4c65290";

  const location = window.location;
  const urlParams = new URLSearchParams(location.search);

  const appMode = urlParams.get("appMode") as appModeType | null;
  // const askJoin = urlParams.get("askJoin") === "true";

  const isInterpreter = urlParams.get("interpreter") === "true";
  const isRecorder = urlParams.get("isRecorder") === "true";
  const interpretationLanguageCode = isRecorder
    ? urlParams.get("interpretationLanguageCode")
    : null;
  const embedded = isInterpreter || urlParams.get("embedded") === "true";
  const participantId = isRecorder
    ? urlParams.get("participantId")
    : createUID();

  return {
    success: true,
    data: {
      isInterpreter,
      interpretationLanguageCode,
      embedded,
      isRecorder,
      participantId,
      appMode: appMode || (isRecorder ? appModes.VIEWER : appModes.HOST),
      defaultName: null,
      cometChatUserId: "ab97687f22e196658dfe5300fc3232d2",
      meetingName: "Test",
      meetingId: "l8yv-s1mj-qypp",
      meetingType: "studio",
      speakerChatEnabled: true,
      audienceChatEnabled: false,
      speakerChatGroupId: "ab97687f22e196658dfe5300fc3232d2",
      audienceChatGroupId: "",
      cometChatAppID: "225864f23b847f8b",
      cometChatRegion: "eu",
      cometChatAuthKey: "8d3502a31a22d70688fca0e382af17fd89edec89",
      //
      destinations: [
        {
          id: 194,
          user_id: 5,
          name: "kick destination",
          type: "kick",
          data: {
            streamUrl: "rtmp://global-live.mux.com:5222/app",
            streamKey: "9db7671f-35c7-02d2-cb0a-3067107cb62d",
          },
          created_at: "2024-02-08T18:09:01.000000Z",
          updated_at: "2024-02-08T18:09:01.000000Z",
        },
        {
          id: 193,
          user_id: 5,
          name: "Insta Destination",
          type: "instagram",
          data: {
            streamUrl: "rtmp://global-live.mux.com:5222/app",
            streamKey: "d0fa67e8-c4db-d17e-68a0-19bf9330b551",
          },
          created_at: "2024-02-08T18:08:16.000000Z",
          updated_at: "2024-02-08T18:08:16.000000Z",
        },
        {
          id: 130,
          user_id: 6,
          name: "Momento Stream Live",
          type: "facebook_profile",
          data: {
            streamUrl: "rtmps://live-api-s.facebook.com:443/rtmp",
            streamKey:
              "2658800767603060?s_asc=1&s_bl=1&s_oil=2&s_psm=1&s_pub=1&s_sw=0&s_tids=1&s_vt=api-s&a=AbwIyOVmJnjZi62I",
            streamId: "2658800767603060",
            accessToken:
              "EAARq1aZBN7IcBO2iJHAzngTsA0EnkZCzdVB0Tn2ZBjAQEuBxx71Mpt3ZCOlOTek10cnRwsPSbCBPgfUE7h37X34lM0BM61Mp1AdgGPdcfnvGRRZAidukrUDPUvo0bTm43WtuXXZBOZBS9TcBqINfZCFDr17V89etUIJKuLMFjUjUMOJaFsYSarSLWGBFMUxlM6vCdeYAhnJ0XIvdYl476YAsmT99nvjTxndOWcbNrXYJsv5MDAsC1mex",
          },
          created_at: "2023-11-09T17:03:49.000000Z",
          updated_at: "2023-11-13T08:28:28.000000Z",
        },
        {
          id: 80,
          user_id: 6,
          name: "Momento Stream Live",
          type: "linkedin_profile",
          data: {
            streamUrl:
              "rtmp://f084a78eefc14e1c85fd02fcb9c09f41.channel.media.azure.net:1935/live/76edf654c9674bf4af4deb342ac7468c",
            streamKey: "c5cc6ed0e1694cb68b34f0d411b4feef",
            asset: "urn:li:digitalmediaAsset:D4E24AQFZ4RCRNBX0Ew",
            accessToken:
              "AQVFnT0bYcZRaDcXouwXtTphLH5rh-w3Z6t-0lMBOaDWBodTvNyKuNGiMTkkUuyikNf1GClzIPLfucFmY3jQalWxH8miB7Sj5KgjsySTqk3EuN03e4S95uVkp2nYktDedqHrIhuomRkPpJOttKxQcD2vasTITPbHvwtddTT9sPXGIYuC5DJnetLiAs3SMhAp128Z1FMTyTE8gEjrAHIBGHBmczHBCTYP4UrpsG9UArwZdgmnmczDhzDpngzL1J0cCSAqNCpM7bCTsJo5IEhjIQ7APe6nIXrqp5XRNb7eiD-iOB6tE5QP14WPj7gUuGTGINiAN8cDWnAEeSJMyYyjIskJLomoDA",
          },
          created_at: "2023-08-25T20:10:26.000000Z",
          updated_at: "2023-11-13T11:36:43.000000Z",
        },
        {
          id: 33,
          user_id: 6,
          name: "Momento Stream Live (Youtube)",
          type: "custom_rtmp",
          data: {
            streamUrl: "rtmp://a.rtmp.youtube.com/live2",
            streamKey: "t40w-mj8h-5fjg-xqv1-6046",
          },
          created_at: "2023-06-17T05:19:05.000000Z",
          updated_at: "2023-06-17T05:19:20.000000Z",
        },
        {
          id: 32,
          user_id: 6,
          name: "Momento Stream Live",
          type: "twitch",
          data: {
            refreshToken: "gy5t2lzsaisisz7n1arztsstoxj7y4g57rnhuu46jr8uyetqxq",
            streamUrl: "rtmp://live.twitch.tv/app/",
            streamKey: "live_921544007_b3ubgRq0ibzeJ7vL9gIOIhhZ9hwqJc",
          },
          created_at: "2023-06-17T05:16:41.000000Z",
          updated_at: "2023-06-17T05:16:41.000000Z",
        },
      ],
      //
      userId: "1c91d314b667f6efd508aa740a7f4f3c",
      broadcastUserId: "1c91d314b667f6efd508aa740a7f4f3c",
      // interpretations: [],
      //
      // interpretations: [
      //   {
      //     name: "original",
      //     rtmpUrl: "rtmp://global-live.mux.com:5222/app/",
      //     rtmpKey: "3a00a789-9924-7f59-e4b7-686f69b10769",
      //     type: "audio",
      //     interpretationViaAi: true,
      //   },
      //   {
      //     agoraChannelId: "c857400e784588f8d5631c5420ac1af8",
      //     name: "English",
      //     shortName: "EN",
      //     type: "audio",
      //     icon: "https://app.momentotranslate.com/assets/img/details-icon/interpretation_room_name.svg",
      //     rtmpUrl: "rtmp://global-live.mux.com:5222/app/",
      //     rtmpKey: "61fb8be4-c972-40f0-e047-1b8cf5fcab0a",
      //     interpretationViaAi: true,
      //   },
      //   {
      //     agoraChannelId: "3f955494b8d21d9028127925b8b388a5",
      //     name: "French",
      //     shortName: "FR",
      //     type: "audio",
      //     icon: "https://app.momentotranslate.com/assets/img/details-icon/interpretation_room_name.svg",
      //     rtmpUrl: "rtmp://global-live.mux.com:5222/app/",
      //     rtmpKey: "f710a4cd-def4-95fa-c974-1a7a1aee4af3",
      //     interpretationViaAi: true,
      //   },
      // ],

      interpretations: [
        {
          name: "original",
          rtmpUrl: "rtmp://global-live.mux.com:5222/app/",
          rtmpKey: "pq6k-r3z2-keuz-s2e1-71fz",
          type: "audio",
          interpretationViaAi: false,
        },
        {
          agoraChannelId: "947c4697ead8f9dbabf0eaebb62e15a0",
          name: "English",
          shortName: "EN",
          type: "audio",
          icon: "https://app.momentotranslate.com/assets/img/details-icon/interpretation_room_name.svg",
          rtmpUrl: "rtmp://global-live.mux.com:5222/app/",
          rtmpKey: "pq6k-r3z2-keuz-71fz-s2e1",
          interpretationViaAi: false,
        },
        {
          agoraChannelId: "80c1836107748c9044acd5fd4c4d07d1",
          name: "French",
          shortName: "FR",
          type: "audio",
          icon: "https://app.momentotranslate.com/assets/img/details-icon/interpretation_room_name.svg",
          rtmpUrl: "rtmp://global-live.mux.com:5222/app/",
          rtmpKey: "pq6k-r3z2-s2e1-71fz-keuz",
          interpretationViaAi: false,
        },
      ],
      broadcastDestinations: [33],
      planType: "professional", // "free",
      chatUrl:
        "https://app.momentostream.com/broadcast-chat/ab97687f22e196658dfe5300fc3232d2",
      isFreeze: false,
      quickstartStepsCompleted: ["strmV1"],
      quickstartStepsSkipped: ["N/A"],
      defaultDestination: { streamKey: "streamKey", streamUrl: "streamUrl" },
      metaData: { waitingRoomEnabled: false },
      broadcastId,
      maxParticipantsCount: 6,
      maxStreamingDestinations: 10,
      name: null,
      participantHeadline: null,
      recordingEnabled: false,
      studioUserId: null,
      meetingMode: "full", // full | minimal | meeting
      thirdPartyRtmpData: {
        streamUrl: "rtmp://global-live.mux.com:5222/app",
        streamKey: "90d9b5eb-a62e-ffda-b203-d97f2f6edc1d",
        playbackId: "BR5PS42K3CZQyI02E66SHQ9toyea1KHtxAaJB6iSR9wE",
      },
      originalAudioPublishAgoraChannelId: "",
    },
  };
};

const getBroadcastData = async ({
  broadcastId,
}: {
  broadcastId: string;
}): Promise<{
  success: boolean;
  data?: appConfigType;
}> => {
  const location = window.location;
  const urlParams = new URLSearchParams(location.search);

  try {
    const hostKey = urlParams.get("hostKey");
    const intKey = urlParams.get("intKey");

    const urlParamsToAttack: { key: string; value: string }[] = [];

    if (hostKey) {
      urlParamsToAttack.push({ key: "hostKey", value: hostKey });
    }

    if (intKey) {
      urlParamsToAttack.push({ key: "intKey", value: intKey });
    }

    const url = `https://app.momentostream.com/get-broadcast-data/${broadcastId}?${urlParamsToAttack
      .map(({ key, value }) => `${key}=${value}`)
      .join("&")}`;

    const res = await fetch(url);

    const json = await res.json();

    if (json.success) {
      const isRecorder = urlParams.get("isRecorder") === "true";
      const isInterpreter = json.data?.isInterpreter
        ? true
        : urlParams.get("interpreter") === "true";
      const interpretationLanguageCode = isRecorder
        ? urlParams.get("interpretationLanguageCode")
        : null;
      const embedded = isInterpreter || urlParams.get("embedded") === "true";

      const appMode =
        isRecorder || isInterpreter ? appModes.VIEWER : json.data.appMode;

      const participantId =
        isRecorder || isInterpreter
          ? urlParams.get("participantId") || createUID()
          : createUID();

      const meetingMode = json.data.meetingMode || "full";

      return {
        success: true,
        data: {
          ...json.data,
          isRecorder,
          participantId,
          appMode,
          isInterpreter,
          interpretationLanguageCode,
          embedded,
          meetingMode,
        } as appConfigType,
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default APP_ENV === "DEV" ? getTestBroadcastData : getBroadcastData;
