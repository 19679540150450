// @ts-nocheck

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import {
  Room as LivekitRoomType,
  ScreenSharePresets,
  VideoPresets,
  createLocalAudioTrack,
} from "livekit-client";
import {
  LocalParticipant,
  LocalTrack,
  LocalTrackPublication,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  RoomEvent,
  VideoQuality,
  createLocalScreenTracks,
  createLocalVideoTrack,
} from "livekit-client";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useLivekitRtcLocalParticipantMediaStats from "./useLivekitRtcLocalParticipantMediaStats";
import {
  checkIsExtraWebcamLivekitPublication,
  checkIsMicLivekitPublication,
  checkIsScreenShareAudioLivekitPublication,
  checkIsScreenShareVideoLivekitPublication,
  checkIsWebcamLivekitPublication,
} from "./useLivekitRtcRemoteParticipantMediaStats";
import { BackgroundBlur, VirtualBackground } from "@livekit/track-processors";
import { safeParseJson } from "../../utils/safeParseJson";
import useInputFileVideoShareStream from "../../hooks/streams/useInputFileVideoShareStream";
import sleep from "../../utils/sleep";
import useLocalParticipantId from "../../hooks/utils/useLocalParticipantId";
import useIsParticipantMicDenied from "../../hooks/appState/useIsParticipantMicDenied";
import useIsParticipantCameraDenied from "../../hooks/appState/useIsParticipantCameraDenied";
import { createUID } from "../../utils/createUID";
import useRtcLocalParticipantMediaStatsActions from "../useRtcLocalParticipantMediaStatsActions";

const RemoteParticipantsRtcMediaListner = () => {
  const appContext = useAppContext();

  const {
    rtcClient,
    setLivekitRemoteParticipantsTrackPublications,
  }: {
    rtcClient: LivekitRoomType;
    livekitRemoteParticipantsTrackPublications: Map<
      string,
      RemoteTrackPublication[]
    >;
    setLivekitRemoteParticipantsTrackPublications: React.Dispatch<
      React.SetStateAction<Map<string, RemoteTrackPublication[]>>
    >;
  } = appContext;

  const rtcClientRef = useRef(rtcClient);
  const setLivekitRemoteParticipantsTrackPublicationsRef = useRef(
    setLivekitRemoteParticipantsTrackPublications
  );

  useEffect(() => {
    rtcClientRef.current = rtcClient;
  }, [rtcClient]);
  useEffect(() => {
    setLivekitRemoteParticipantsTrackPublicationsRef.current =
      setLivekitRemoteParticipantsTrackPublications;
  }, [setLivekitRemoteParticipantsTrackPublications]);

  const resetRemoteTracks = () => {
    const livekitRemoteParticipantsTrackPublications = new Map();

    const rtcClient = rtcClientRef.current;

    rtcClient.participants.forEach((participant) => {
      const participantId = participant.identity;

      const publications = participant.getTracks() as RemoteTrackPublication[];

      livekitRemoteParticipantsTrackPublications.set(
        participantId,
        publications
      );
    });

    setLivekitRemoteParticipantsTrackPublicationsRef.current(
      livekitRemoteParticipantsTrackPublications
    );
  };

  const resetRemoteTracksRef = useRef(resetRemoteTracks);

  const _handleTrackPublished = (
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) => {
    setLivekitRemoteParticipantsTrackPublicationsRef.current((s) => {
      const _s = new Map(s);

      _s.set(participant.identity, [
        ...(_s.get(participant.identity) || []),
        publication,
      ]);

      return _s;
    });
  };

  const _handleTrackUnPublished = (
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) => {
    setLivekitRemoteParticipantsTrackPublicationsRef.current((s) => {
      const _s = new Map(s);

      _s.set(participant.identity, [
        ...(_s.get(participant.identity) || []).filter(
          ({ trackSid }) => trackSid !== publication.trackSid
        ),
      ]);

      return _s;
    });

    if (publication.trackName.includes("extraWebcam")) {
      appEventEmitter.emit(appEventEmitterEvents.RTC_EXTRA_WEBCAM_TURNED_OFF, {
        participantId: participant.identity,
        extraWebcamId: publication.trackName.split("_")[2],
      });
    }
  };

  const _handleTrackSubscribed = (
    _: RemoteTrack,
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) => {
    setLivekitRemoteParticipantsTrackPublicationsRef.current((s) => {
      const _s = new Map(s);

      _s.set(participant.identity, [
        ...(_s.get(participant.identity) || []).filter(
          ({ trackSid }) => trackSid !== publication.trackSid
        ),
        publication,
      ]);

      return _s;
    });
  };

  const _handleTrackUnsubscribed = (
    _: RemoteTrack,
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) => {
    setLivekitRemoteParticipantsTrackPublicationsRef.current((s) => {
      const _s = new Map(s);

      _s.set(participant.identity, [
        ...(_s.get(participant.identity) || []).filter(
          ({ trackSid }) => trackSid !== publication.trackSid
        ),
        publication,
      ]);

      return _s;
    });
  };

  useEffect(() => {
    if (rtcClient) {
      resetRemoteTracksRef.current();

      rtcClient.on(RoomEvent.TrackPublished, _handleTrackPublished);

      rtcClient.on(RoomEvent.TrackUnpublished, _handleTrackUnPublished);

      rtcClient.on(RoomEvent.TrackSubscribed, _handleTrackSubscribed);

      rtcClient.on(RoomEvent.TrackUnsubscribed, _handleTrackUnsubscribed);

      return () => {
        rtcClient.off(RoomEvent.TrackPublished, _handleTrackPublished);

        rtcClient.off(RoomEvent.TrackUnpublished, _handleTrackUnPublished);

        rtcClient.off(RoomEvent.TrackSubscribed, _handleTrackSubscribed);

        rtcClient.off(RoomEvent.TrackUnsubscribed, _handleTrackUnsubscribed);
      };
    }
  }, [rtcClient]);

  useEffect(() => {
    const interval = setInterval(() => {
      resetRemoteTracksRef.current();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <React.Fragment />;
};

const LocalParticipantRtcMediaListner = () => {
  const appContext = useAppContext();

  const {
    rtcClient,
    setLivekitLocalParticipantTrackPublications,
  }: {
    rtcClient: LivekitRoomType;
    setLivekitLocalParticipantTrackPublications: React.Dispatch<
      React.SetStateAction<LocalTrackPublication[]>
    >;
  } = appContext;

  const participant: LocalParticipant = useMemo(() => {
    return rtcClient.localParticipant;
  }, [rtcClient]);

  const setLivekitLocalParticipantTrackPublicationsRef = useRef(
    setLivekitLocalParticipantTrackPublications
  );
  const participantRef = useRef(participant);

  useEffect(() => {
    participantRef.current = participant;
  }, [participant]);

  const resetLocalTracks = () => {
    const publications = participant.getTracks() as LocalTrackPublication[];

    setLivekitLocalParticipantTrackPublicationsRef.current(publications);
  };

  const resetLocalTracksRef = useRef(resetLocalTracks);

  useEffect(() => {
    setLivekitLocalParticipantTrackPublicationsRef.current =
      setLivekitLocalParticipantTrackPublications;
  }, [setLivekitLocalParticipantTrackPublications]);

  const _handleLocalTrackPublished = (publication: LocalTrackPublication) => {
    setLivekitLocalParticipantTrackPublicationsRef.current((s) => [
      ...s,
      publication,
    ]);
  };

  const _handleLocalTrackUnpublished = (publication: LocalTrackPublication) => {
    setLivekitLocalParticipantTrackPublicationsRef.current((s) =>
      s.filter(({ trackSid }) => trackSid !== publication.trackSid)
    );

    if (publication.trackName.includes("extraWebcam")) {
      appEventEmitter.emit(appEventEmitterEvents.RTC_EXTRA_WEBCAM_TURNED_OFF, {
        participantId: participantRef.current.identity,
        extraWebcamId: publication.trackName.split("_")[2],
      });
    }
  };

  useEffect(() => {
    if (participant) {
      resetLocalTracksRef.current();

      participant.on(RoomEvent.LocalTrackPublished, _handleLocalTrackPublished);
      participant.on(
        RoomEvent.LocalTrackUnpublished,
        _handleLocalTrackUnpublished
      );

      return () => {
        participant.off(
          RoomEvent.LocalTrackPublished,
          _handleLocalTrackPublished
        );
        participant.off(
          RoomEvent.LocalTrackUnpublished,
          _handleLocalTrackUnpublished
        );
      };
    }
  }, [participant]);

  useEffect(() => {
    const interval = setInterval(() => {
      resetLocalTracksRef.current();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <React.Fragment />;
};

const InputFileVideoShareStreamSourceElement = ({
  id,
  fileObjectUrl,
}: {
  id: string;
  fileObjectUrl: string;
}) => {
  const { playing } = useInputFileVideoShareStream({
    inputFileVideoId: id,
  });

  const idRef = useRef(id);

  useEffect(() => {
    idRef.current = id;
  }, [id]);

  const getVideoEl = () => {
    const inputFileVideoSourceElementId = `input-file-video-share-stream-source-element-${idRef.current}`;

    const videoEl = document.getElementById(
      inputFileVideoSourceElementId
    ) as StudioHTMLVideoElement;

    return videoEl;
  };

  const _handleSeekTo = ({ seekToSec }: { seekToSec: number }) => {
    const videoEl = getVideoEl();

    if (videoEl) {
      videoEl.currentTime = seekToSec;
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.RTC_INPUT_FILE_VIDEO_SHARE_STREAM_SEEK_TO(id),
      _handleSeekTo
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.RTC_INPUT_FILE_VIDEO_SHARE_STREAM_SEEK_TO(id),
        _handleSeekTo
      );
    };
  }, [id]);

  useEffect(() => {
    const videoEl = getVideoEl();

    if (videoEl) {
      if (playing) {
        if (videoEl.paused) {
          videoEl.play();
        }
      } else {
        if (!videoEl.paused) {
          videoEl.pause();
        }
      }
    }
  }, [playing]);

  return (
    <video
      id={`input-file-video-share-stream-source-element-${id}`}
      style={{ height: 0, width: 0, position: "fixed" }}
      muted={false}
      controls={false}
      src={fileObjectUrl}
      autoPlay
      onPlay={() => {
        if (!playing) {
          const videoEl = getVideoEl();

          if (videoEl) {
            videoEl.pause();
          }
        }
      }}
      onPause={() => {
        if (playing) {
          const videoEl = getVideoEl();

          if (videoEl) {
            videoEl.play();
          }
        }
      }}
      onTimeUpdate={(e) => {
        const currentTime = (e.target as StudioHTMLAudioElement).currentTime;

        appEventEmitter.emit(
          appEventEmitterEvents.RTC_INPUT_FILE_VIDEO_SHARE_STREAM_CURRENT_TIME_CHANGED(
            id
          ),
          { currentTime }
        );

        const videoEl = getVideoEl();

        if (videoEl) {
          if (playing) {
            if (videoEl.paused) {
              videoEl.play();
            }
          } else {
            if (!videoEl.paused) {
              videoEl.pause();
            }
          }

          if (videoEl.volume !== 0.0001) {
            videoEl.volume = 0.0001;
          }
        }
      }}
    />
  );
};

const LivekitRtcListner = () => {
  const appContext = useAppContext();

  const [
    inputFileVideoShareStreamFileObjectUrls,
    setInputFileVideoShareStreamFileObjectUrls,
  ] = useState<{ fileObjectUrl: string; id: string }[]>([]);

  const {
    rtcClient,
    livekitRemoteParticipantsTrackPublications,
    livekitLocalParticipantTrackPublications,
  }: {
    rtcClient: LivekitRoomType;
    livekitLocalParticipantTrackPublications: LocalTrackPublication[];
    livekitRemoteParticipantsTrackPublications: Map<
      string,
      RemoteTrackPublication[]
    >;
  } = appContext;

  const {
    virtualBackgrounds,
    activeInputFileVideoShareStreamAllParticipants,

    selectedWebcamDeviceId,
    setSelectedWebcamDeviceId,

    selectedMicDeviceId,
    setSelectedMicDeviceId,

    activeVirtualBackgroundId,
    setActiveVirtualBackgroundId,

    localMicOn,
    setLocalMicOn,
    localWebcamOn,
    setLocalWebcamOn,

    turnOnScreenShareInProgress,
    setTurnOnScreenShareInProgress,
    turnOnInputFileVideoShareInProgress,
    setTurnOnInputFileVideoShareInProgress,
    turnOnExtraCameraInProgress,
    setTurnOnExtraCameraInProgress,
    activeWebcamDeviceIds,
    editExtraCameraInProgress,
    setEditExtraCameraInProgress,
  } = appContext;

  const rtcClientRef = useRef(rtcClient);

  useEffect(() => {
    rtcClientRef.current = rtcClient;
  }, [rtcClient]);

  const { localParticipantId } = useLocalParticipantId();

  const { micDenied } = useIsParticipantMicDenied({
    participantId: localParticipantId,
  });
  const { cameraDenied } = useIsParticipantCameraDenied({
    participantId: localParticipantId,
  });

  const {
    __webcamOn: webcamOn,
    __micOn: micOn,
    screenShareVideoOn,
    screenShareAudioOn,
    //
    __webcamTrackPublication: webcamTrackPublication,
    __micTrackPublication: micTrackPublication,
    screenShareVideoTrackPublication,
    screenShareAudioTrackPublication,
    inputFileVideoShareStreamPublications,
  } = useLivekitRtcLocalParticipantMediaStats();

  const { getWebcams } = useRtcLocalParticipantMediaStatsActions();

  const webcamOnRef = useRef(webcamOn);
  const micOnRef = useRef(micOn);
  const screenShareVideoOnRef = useRef(screenShareVideoOn);
  const screenShareAudioOnRef = useRef(screenShareAudioOn);
  const localMicOnRef = useRef(localMicOn);
  const setLocalMicOnRef = useRef(setLocalMicOn);
  const localWebcamOnRef = useRef(localWebcamOn);
  const setLocalWebcamOnRef = useRef(setLocalWebcamOn);

  const webcamTrackPublicationRef = useRef(webcamTrackPublication);
  const micTrackPublicationRef = useRef(micTrackPublication);
  const screenShareVideoTrackPublicationRef = useRef(
    screenShareVideoTrackPublication
  );
  const screenShareAudioTrackPublicationRef = useRef(
    screenShareAudioTrackPublication
  );
  const inputFileVideoShareStreamPublicationsRef = useRef(
    inputFileVideoShareStreamPublications
  );
  const livekitRemoteParticipantsTrackPublicationsRef = useRef(
    livekitRemoteParticipantsTrackPublications
  );
  const virtualBackgroundsRef = useRef(virtualBackgrounds);
  const selectedWebcamDeviceIdRef = useRef(selectedWebcamDeviceId);
  const selectedMicDeviceIdRef = useRef(selectedMicDeviceId);
  const activeVirtualBackgroundIdRef = useRef(activeVirtualBackgroundId);
  const setVirtualBackgroundInProgress = useRef(false);
  const activeInputFileVideoShareStreamAllParticipantsRef = useRef(
    activeInputFileVideoShareStreamAllParticipants
  );
  const turnOnScreenShareInProgressRef = useRef(turnOnScreenShareInProgress);
  const setTurnOnScreenShareInProgressRef = useRef(
    setTurnOnScreenShareInProgress
  );
  const turnOnInputFileVideoShareInProgressRef = useRef(
    turnOnInputFileVideoShareInProgress
  );
  const setTurnOnInputFileVideoShareInProgressRef = useRef(
    setTurnOnInputFileVideoShareInProgress
  );
  const turnOnExtraCameraInProgressRef = useRef(turnOnExtraCameraInProgress);
  const setTurnOnExtraCameraInProgressRef = useRef(
    setTurnOnExtraCameraInProgress
  );
  const editExtraCameraInProgressRef = useRef(editExtraCameraInProgress);
  const setEditExtraCameraInProgressRef = useRef(setEditExtraCameraInProgress);
  const micDeniedRef = useRef(micDenied);
  const cameraDeniedRef = useRef(cameraDenied);
  const localParticipantIdRef = useRef(localParticipantId);
  const activeWebcamDeviceIdsRef = useRef(activeWebcamDeviceIds);
  const livekitLocalParticipantTrackPublicationsRef = useRef(
    livekitLocalParticipantTrackPublications
  );
  //
  //
  useEffect(() => {
    webcamOnRef.current = webcamOn;
  }, [webcamOn]);
  useEffect(() => {
    micOnRef.current = micOn;
  }, [micOn]);
  useEffect(() => {
    screenShareVideoOnRef.current = screenShareVideoOn;
  }, [screenShareVideoOn]);
  useEffect(() => {
    screenShareAudioOnRef.current = screenShareAudioOn;
  }, [screenShareAudioOn]);
  useEffect(() => {
    localMicOnRef.current = localMicOn;
  }, [localMicOn]);
  useEffect(() => {
    setLocalMicOnRef.current = setLocalMicOn;
  }, [setLocalMicOn]);
  useEffect(() => {
    localWebcamOnRef.current = localWebcamOn;
  }, [localWebcamOn]);
  useEffect(() => {
    setLocalWebcamOnRef.current = setLocalWebcamOn;
  }, [setLocalWebcamOn]);
  useEffect(() => {
    webcamTrackPublicationRef.current = webcamTrackPublication;
  }, [webcamTrackPublication]);
  useEffect(() => {
    micTrackPublicationRef.current = micTrackPublication;
  }, [micTrackPublication]);
  useEffect(() => {
    screenShareVideoTrackPublicationRef.current =
      screenShareVideoTrackPublication;
  }, [screenShareVideoTrackPublication]);
  useEffect(() => {
    screenShareAudioTrackPublicationRef.current =
      screenShareAudioTrackPublication;
  }, [screenShareAudioTrackPublication]);
  useEffect(() => {
    livekitRemoteParticipantsTrackPublicationsRef.current =
      livekitRemoteParticipantsTrackPublications;
  }, [livekitRemoteParticipantsTrackPublications]);
  useEffect(() => {
    inputFileVideoShareStreamPublicationsRef.current =
      inputFileVideoShareStreamPublications;
  }, [inputFileVideoShareStreamPublications]);
  useEffect(() => {
    virtualBackgroundsRef.current = virtualBackgrounds;
  }, [virtualBackgrounds]);
  useEffect(() => {
    selectedWebcamDeviceIdRef.current = selectedWebcamDeviceId;
  }, [selectedWebcamDeviceId]);
  useEffect(() => {
    selectedMicDeviceIdRef.current = selectedMicDeviceId;
  }, [selectedMicDeviceId]);
  useEffect(() => {
    activeVirtualBackgroundIdRef.current = activeVirtualBackgroundId;
  }, [activeVirtualBackgroundId]);
  useEffect(() => {
    activeInputFileVideoShareStreamAllParticipantsRef.current =
      activeInputFileVideoShareStreamAllParticipants;
  }, [activeInputFileVideoShareStreamAllParticipants]);
  useEffect(() => {
    turnOnScreenShareInProgressRef.current = turnOnScreenShareInProgress;
  }, [turnOnScreenShareInProgress]);
  useEffect(() => {
    setTurnOnScreenShareInProgressRef.current = setTurnOnScreenShareInProgress;
  }, [setTurnOnScreenShareInProgress]);
  useEffect(() => {
    turnOnInputFileVideoShareInProgressRef.current =
      turnOnInputFileVideoShareInProgress;
  }, [turnOnInputFileVideoShareInProgress]);
  useEffect(() => {
    setTurnOnInputFileVideoShareInProgressRef.current =
      setTurnOnInputFileVideoShareInProgress;
  }, [setTurnOnInputFileVideoShareInProgress]);
  useEffect(() => {
    turnOnExtraCameraInProgressRef.current = turnOnExtraCameraInProgress;
  }, [turnOnExtraCameraInProgress]);
  useEffect(() => {
    setTurnOnExtraCameraInProgressRef.current = setTurnOnExtraCameraInProgress;
  }, [setTurnOnExtraCameraInProgress]);
  useEffect(() => {
    editExtraCameraInProgressRef.current = editExtraCameraInProgress;
  }, [editExtraCameraInProgress]);
  useEffect(() => {
    setEditExtraCameraInProgressRef.current = setEditExtraCameraInProgress;
  }, [setEditExtraCameraInProgress]);
  useEffect(() => {
    micDeniedRef.current = micDenied;
  }, [micDenied]);
  useEffect(() => {
    cameraDeniedRef.current = cameraDenied;
  }, [cameraDenied]);
  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);
  useEffect(() => {
    activeWebcamDeviceIdsRef.current = activeWebcamDeviceIds;
  }, [activeWebcamDeviceIds]);
  useEffect(() => {
    livekitLocalParticipantTrackPublicationsRef.current =
      livekitLocalParticipantTrackPublications;
  }, [livekitLocalParticipantTrackPublications]);

  const getRemoteTrackPublication = ({
    participantId,
    remoteTrackPublicationType,
    inputFileVideoId,
    extraWebcamId,
  }: {
    participantId: string;
    remoteTrackPublicationType:
      | "webcam"
      | "mic"
      | "screenShareVideo"
      | "screenShareAudio"
      | "inputFileVideoShareVideo"
      | "inputFileVideoShareAudio"
      | "extraWebcam";
    inputFileVideoId?: string;
    extraWebcamId?: string;
  }): RemoteTrackPublication | undefined => {
    const livekitRemoteParticipantsTrackPublications =
      livekitRemoteParticipantsTrackPublicationsRef.current;

    const publications =
      livekitRemoteParticipantsTrackPublications.get(participantId);

    if (publications) {
      if (
        remoteTrackPublicationType === "inputFileVideoShareAudio" ||
        remoteTrackPublicationType === "inputFileVideoShareVideo"
      ) {
        const inputFileVideoShareStream =
          activeInputFileVideoShareStreamAllParticipantsRef.current.find(
            ({ participantId: _participantId, rtcClientMetadata }) => {
              if (participantId === _participantId) {
                const { id } = safeParseJson(rtcClientMetadata);

                if (id === inputFileVideoId) {
                  return true;
                }
              }
            }
          );
        let videoPublicationTrackSid: string | null = null;
        let audioPublicationTrackSid: string | null = null;

        if (inputFileVideoShareStream) {
          const {
            videoPublicationTrackSid: _videoPublicationTrackSid,
            audioPublicationTrackSid: _audioPublicationTrackSid,
          } = safeParseJson(inputFileVideoShareStream.rtcClientMetadata);

          videoPublicationTrackSid = _videoPublicationTrackSid;
          audioPublicationTrackSid = _audioPublicationTrackSid;
        }

        if (
          remoteTrackPublicationType === "inputFileVideoShareAudio" &&
          audioPublicationTrackSid
        ) {
          const publication = publications.find(
            ({ trackSid }) => trackSid === audioPublicationTrackSid
          );

          return publication;
        } else if (
          remoteTrackPublicationType === "inputFileVideoShareVideo" &&
          videoPublicationTrackSid
        ) {
          const publication = publications.find(
            ({ trackSid }) => trackSid === videoPublicationTrackSid
          );

          return publication;
        }
      } else {
        const checker =
          remoteTrackPublicationType === "webcam"
            ? checkIsWebcamLivekitPublication
            : remoteTrackPublicationType === "mic"
            ? checkIsMicLivekitPublication
            : remoteTrackPublicationType === "extraWebcam"
            ? checkIsExtraWebcamLivekitPublication
            : remoteTrackPublicationType === "screenShareVideo"
            ? checkIsScreenShareVideoLivekitPublication
            : checkIsScreenShareAudioLivekitPublication;

        const publication = publications.find(
          (publication) =>
            checker(publication) &&
            (remoteTrackPublicationType === "extraWebcam"
              ? publication.trackName.includes(extraWebcamId as string)
              : true)
        );

        return publication;
      }
    }
  };

  const _handleRtcSetSubscribeRemoteWebcamStream = ({
    participantId,
    subscribe,
  }: {
    participantId: string;
    subscribe: boolean;
  }) => {
    const webcamPublication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "webcam",
    });

    if (webcamPublication) {
      webcamPublication.setSubscribed(subscribe);
    }
  };

  const _handleRtcSetSubscribeRemoteMicStream = ({
    participantId,
    subscribe,
  }: {
    participantId: string;
    subscribe: boolean;
  }) => {
    const micPublication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "mic",
    });

    if (micPublication) {
      micPublication.setSubscribed(subscribe);
    }
  };

  const _handleRtcSetSubscribeRemoteScreenShareVideoStream = ({
    participantId,
    subscribe,
  }: {
    participantId: string;
    subscribe: boolean;
  }) => {
    const webcamPublication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "screenShareVideo",
    });

    if (webcamPublication) {
      webcamPublication.setSubscribed(subscribe);
    }
  };

  const _handleRtcSetSubscribeRemoteScreenShareAudioStream = ({
    participantId,
    subscribe,
  }: {
    participantId: string;
    subscribe: boolean;
  }) => {
    const webcamPublication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "screenShareAudio",
    });

    if (webcamPublication) {
      webcamPublication.setSubscribed(subscribe);
    }
  };

  const _handleRtcSubscribeRemoteWebcamStream = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    _handleRtcSetSubscribeRemoteWebcamStream({
      participantId,
      subscribe: true,
    });
  };

  const _handleRtcSubscribeRemoteMicStream = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    _handleRtcSetSubscribeRemoteMicStream({ participantId, subscribe: true });
  };

  const _handleRtcSubscribeRemoteScreenShareVideoStream = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    _handleRtcSetSubscribeRemoteScreenShareVideoStream({
      participantId,
      subscribe: true,
    });
  };

  const _handleRtcSubscribeRemoteScreenShareAudioStream = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    _handleRtcSetSubscribeRemoteScreenShareAudioStream({
      participantId,
      subscribe: true,
    });
  };

  const _handleRtcUnsubscribeRemoteWebcamStream = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    _handleRtcSetSubscribeRemoteWebcamStream({
      participantId,
      subscribe: false,
    });
  };

  const _handleRtcUnsubscribeRemoteMicStream = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    _handleRtcSetSubscribeRemoteMicStream({ participantId, subscribe: false });
  };

  const _handleRtcUnsubscribeRemoteScreenShareVideoStream = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    _handleRtcSetSubscribeRemoteScreenShareVideoStream({
      participantId,
      subscribe: false,
    });
  };

  const _handleRtcUnsubscribeRemoteScreenShareAudioStream = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    _handleRtcSetSubscribeRemoteScreenShareAudioStream({
      participantId,
      subscribe: false,
    });
  };

  const _handleRtcSetRemoteWebcamQuality = ({
    participantId,
    quality,
  }: {
    participantId: string;
    quality: "low" | "med" | "high";
  }) => {
    const publication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "webcam",
    });

    if (publication) {
      const livekitQuality =
        quality === "low"
          ? VideoQuality.LOW
          : quality === "med"
          ? VideoQuality.MEDIUM
          : quality === "high"
          ? VideoQuality.HIGH
          : undefined;

      if (livekitQuality !== undefined) {
        publication.setVideoQuality(livekitQuality);
      }
    }
  };

  const _handleRtcSetRemoteScreenShareVideoQuality = ({
    participantId,
    quality,
  }: {
    participantId: string;
    quality: "low" | "med" | "high";
  }) => {
    const publication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "screenShareVideo",
    });

    if (publication) {
      const livekitQuality =
        quality === "low"
          ? VideoQuality.LOW
          : quality === "med"
          ? VideoQuality.MEDIUM
          : quality === "high"
          ? VideoQuality.HIGH
          : undefined;

      if (livekitQuality !== undefined) {
        publication.setVideoQuality(livekitQuality);
      }
    }
  };

  const setVideoTrackProcessor = async ({
    virtualBackgroundId,
    videoTrack,
  }: {
    virtualBackgroundId: string;
    videoTrack: LocalTrack;
  }) => {
    const virtualBackground = virtualBackgroundsRef.current.find(
      ({ id: _id }) => _id === virtualBackgroundId
    ) as virtualBackgroundType;

    const { type, url } = virtualBackground;

    const isProcessing = !!videoTrack.getProcessor();

    if (isProcessing) {
      await videoTrack.stopProcessor();
    }

    if (type === "none") {
      //  do nothing
    } else if (type === "blur") {
      try {
        await videoTrack.setProcessor(BackgroundBlur(10));
      } catch (error) {
        //
        console.log("track processing failed", error);
      }
    } else if (type === "image" && url) {
      try {
        await videoTrack.setProcessor(VirtualBackground(url));
      } catch (error) {
        //
        console.log("track processing failed", error);
      }
    }
  };

  const _handleTurnOnWebcam = async (args?: {
    deviceId?: string;
    virtualBackgroundId?: string;
  }) => {
    if (localWebcamOnRef.current) {
      return;
    }

    if (!webcamOnRef.current) {
      const rtcClient = rtcClientRef.current;

      const deviceId =
        args?.deviceId || selectedWebcamDeviceIdRef.current || undefined;

      const videoTrack = await createLocalVideoTrack({
        deviceId,
        // resolution: { height: 540, width: 960 },
        resolution: { height: 360, width: 640 },
      });

      const virtualBackgroundId =
        args?.virtualBackgroundId ||
        activeVirtualBackgroundIdRef.current ||
        undefined;

      if (videoTrack && virtualBackgroundId) {
        await setVideoTrackProcessor({ virtualBackgroundId, videoTrack });
      }

      const webcamPublication = await rtcClient.localParticipant.publishTrack(
        videoTrack,
        {
          videoSimulcastLayers: [VideoPresets.h360, VideoPresets.h216],
          name: `ogWebcam_${
            localParticipantIdRef.current
          }_${createUID()}_${new Date().getTime()}`,
        }
      );

      const webcamDeviceId = webcamPublication?.track?.constraints?.deviceId;

      if (webcamDeviceId !== selectedWebcamDeviceIdRef.current) {
        if (webcamDeviceId) {
          setSelectedWebcamDeviceId(webcamDeviceId.toString());
        }
      }
    }

    setLocalWebcamOnRef.current(true);
  };

  const _handleTurnOffWebcam = async (turnOffPublication?: boolean) => {
    if (!localWebcamOnRef.current && !turnOffPublication) {
      return;
    }

    if (turnOffPublication) {
      const rtcClient = rtcClientRef.current;

      if (
        webcamTrackPublicationRef.current &&
        webcamTrackPublicationRef.current.track
      ) {
        await rtcClient.localParticipant.unpublishTrack(
          webcamTrackPublicationRef.current.track
        );
      }
    }

    setLocalWebcamOnRef.current(false);
  };

  const _handleSetWebcamTrackProcessor = async ({ id }: { id: string }) => {
    if (setVirtualBackgroundInProgress.current) {
      return;
    }

    setVirtualBackgroundInProgress.current = true;

    setActiveVirtualBackgroundId(id);

    const webcamOn = webcamOnRef.current;
    const webcamTrack = webcamTrackPublicationRef.current?.track;

    if (webcamOn && webcamTrack) {
      await _handleTurnOffWebcam(true);

      await sleep(2000);

      await _handleTurnOnWebcam({ virtualBackgroundId: id });
    }

    setVirtualBackgroundInProgress.current = false;
  };

  const _handleToggleWebcam = async () => {
    if (localWebcamOnRef.current) {
      _handleTurnOffWebcam();
    } else {
      _handleTurnOnWebcam();
    }
  };

  const _handleChangeWebcamDeviceId = async ({
    deviceId,
  }: {
    deviceId: string;
  }) => {
    setSelectedWebcamDeviceId(deviceId);

    const webcamOn = webcamOnRef.current;
    const webcamTrack = webcamTrackPublicationRef.current?.track;

    if (webcamOn && webcamTrack) {
      await _handleTurnOffWebcam(true);

      await sleep(2000);

      await _handleTurnOnWebcam({ deviceId });
    }
  };

  const _handleTurnOnExtraWebcam = async (t?: {
    extraWebcamId?: string;
    isEditing?: boolean;
    deviceId?: string;
  }) => {
    const turnOnExtraCameraInProgress = turnOnExtraCameraInProgressRef.current;
    const setTurnOnExtraCameraInProgress =
      setTurnOnExtraCameraInProgressRef.current;

    if (turnOnExtraCameraInProgress) {
      return;
    }

    setTurnOnExtraCameraInProgress(true);

    await sleep(500);

    try {
      const webcams = await getWebcams();

      if (webcams.length) {
        const activeWebcamDeviceIds = activeWebcamDeviceIdsRef.current;

        const inActiveWebcams = webcams.filter(
          ({ deviceId }) =>
            !activeWebcamDeviceIds.find((id) => `${id}` === `${deviceId}`)
        );

        // if (!inActiveWebcams.length) {
        //   return;
        // }

        const deviceId = t?.deviceId
          ? t?.deviceId
          : inActiveWebcams?.length
          ? inActiveWebcams[0].deviceId
          : webcams[0].deviceId;

        const videoTrack = await createLocalVideoTrack({
          deviceId,
          // resolution: { height: 540, width: 960 },
          resolution: { height: 360, width: 640 },
        });

        const extraWebcamId = t?.extraWebcamId || createUID();

        await rtcClient.localParticipant.publishTrack(videoTrack, {
          videoSimulcastLayers: [VideoPresets.h360, VideoPresets.h216],
          name: `extraWebcam_${
            localParticipantIdRef.current
          }_${extraWebcamId}_${new Date().getTime()}`,
        });

        if (!t?.isEditing) {
          appEventEmitter.emit(
            appEventEmitterEvents.OPEN_EXTRA_WEBCAM_SETTINGS,
            {
              extraWebcamId,
              isInitialized: true,
            }
          );
        }
      }
    } catch (error) {
      //
    }

    setTurnOnExtraCameraInProgress(false);

    await sleep(500);
  };

  const _handleTurnOffExtraWebcam = async ({
    extraWebcamId,
  }: {
    extraWebcamId: string;
  }) => {
    const livekitLocalParticipantTrackPublications =
      livekitLocalParticipantTrackPublicationsRef.current;

    const extraWebcamPublication =
      livekitLocalParticipantTrackPublications.find(({ trackName }) =>
        trackName.includes(extraWebcamId)
      );

    if (extraWebcamPublication?.track) {
      await rtcClient.localParticipant.unpublishTrack(
        extraWebcamPublication.track
      );
    }
  };

  const _handleChangeExtraWebcamDeviceId = async ({
    extraWebcamId,
    deviceId,
  }: {
    extraWebcamId: string;
    deviceId: string;
  }) => {
    const editExtraCameraInProgress = editExtraCameraInProgressRef.current;
    const setEditExtraCameraInProgress =
      setEditExtraCameraInProgressRef.current;

    if (editExtraCameraInProgress) {
      return;
    }

    setEditExtraCameraInProgress(true);

    await sleep(500);

    try {
      const livekitLocalParticipantTrackPublications =
        livekitLocalParticipantTrackPublicationsRef.current;

      const extraWebcamPublication =
        livekitLocalParticipantTrackPublications.find(({ trackName }) =>
          trackName.includes(extraWebcamId)
        );

      await _handleTurnOnExtraWebcam({
        deviceId,
        extraWebcamId,
        isEditing: true,
      });

      await sleep(1000);

      if (extraWebcamPublication?.track) {
        await rtcClient.localParticipant.unpublishTrack(
          extraWebcamPublication.track
        );
      }
    } catch (error) {
      //
    }

    setEditExtraCameraInProgress(false);

    await sleep(500);
  };

  const _handleTurnOnMic = async (args?: { deviceId?: string }) => {
    if (localMicOnRef.current) {
      return;
    }

    if (!micOnRef.current) {
      const rtcClient = rtcClientRef.current;

      const deviceId =
        args?.deviceId || selectedMicDeviceIdRef.current || undefined;

      const audioTrack = await createLocalAudioTrack({ deviceId });

      const micPublication = await rtcClient.localParticipant.publishTrack(
        audioTrack,
        {
          name: `ogMic_${
            localParticipantIdRef.current
          }_${createUID()}_${new Date().getTime()}`,
        }
      );

      const micDeviceId = micPublication?.track?.constraints?.deviceId;

      if (micDeviceId !== selectedMicDeviceIdRef.current) {
        if (micDeviceId) {
          setSelectedMicDeviceId(micDeviceId.toString());
        }
      }
    }

    setLocalMicOnRef.current(true);
  };

  const _handleTurnOffMic = async (turnOffPublication?: boolean) => {
    if (!localMicOnRef.current && !turnOffPublication) {
      return;
    }

    if (turnOffPublication) {
      const rtcClient = rtcClientRef.current;

      if (
        micTrackPublicationRef.current &&
        micTrackPublicationRef.current.track
      ) {
        await rtcClient.localParticipant.unpublishTrack(
          micTrackPublicationRef.current.track
        );
      }
    }

    setLocalMicOnRef.current(false);
  };

  const _handleToggleMic = async () => {
    if (localMicOnRef.current) {
      _handleTurnOffMic();
    } else {
      _handleTurnOnMic();
    }
  };

  const _handleChangeMicDeviceId = async ({
    deviceId,
  }: {
    deviceId: string;
  }) => {
    setSelectedMicDeviceId(deviceId);

    const micOn = micOnRef.current;
    const micTrack = micTrackPublicationRef.current?.track;

    if (micOn && micTrack) {
      await _handleTurnOffMic(true);

      await _handleTurnOnMic({ deviceId });
    }
  };

  const _handleTurnOnScreenShare = async () => {
    if (turnOnScreenShareInProgressRef.current) return;

    setTurnOnScreenShareInProgressRef.current(true);

    try {
      const rtcClient = rtcClientRef.current;

      const tracks = await createLocalScreenTracks({
        audio: true,
        video: true,
        resolution: { height: 720, width: 1280 },
      });

      for (let index = 0; index < tracks.length; index++) {
        const track = tracks[index];

        await rtcClient.localParticipant.publishTrack(track, {
          screenShareSimulcastLayers: [ScreenSharePresets.h360fps3],
          name: `${
            track.kind === "video" ? "ogScreenShareVideo" : "ogScreenShareAudio"
          }_${
            localParticipantIdRef.current
          }_${createUID()}_${new Date().getTime()}`,
        });
      }
    } catch (err) {
      //
    }

    setTurnOnScreenShareInProgressRef.current(false);
  };

  const _handleTurnOffScreenShare = async () => {
    const rtcClient = rtcClientRef.current;

    if (
      screenShareVideoTrackPublicationRef.current &&
      screenShareVideoTrackPublicationRef.current.track
    ) {
      await rtcClient.localParticipant.unpublishTrack(
        screenShareVideoTrackPublicationRef.current.track
      );
    }

    if (
      screenShareAudioTrackPublicationRef.current &&
      screenShareAudioTrackPublicationRef.current.track
    ) {
      await rtcClient.localParticipant.unpublishTrack(
        screenShareAudioTrackPublicationRef.current.track
      );
    }
  };

  const _handleToggleScreenShare = async () => {
    if (screenShareVideoOnRef.current || screenShareAudioOnRef.current) {
      _handleTurnOffScreenShare();
    } else {
      _handleTurnOnScreenShare();
    }
  };

  const _handleEnableInputFileVideoShareStream = async ({
    id,
    fileObjectUrl,
  }: {
    id: string;
    fileObjectUrl: string;
  }) => {
    if (turnOnInputFileVideoShareInProgressRef.current) {
      return;
    }

    const rtcClient = rtcClientRef.current;

    if (!rtcClient) {
      return;
    }

    setTurnOnInputFileVideoShareInProgressRef.current(true);

    try {
      setInputFileVideoShareStreamFileObjectUrls((s) => [
        ...s,
        { id, fileObjectUrl },
      ]);

      const {
        inputFileVideoSourceElementId,
        videoEl,
      }: {
        inputFileVideoSourceElementId: string;
        videoEl: StudioHTMLVideoElement;
      } = await new Promise((resolve) => {
        const interval = setInterval(() => {
          const inputFileVideoSourceElementId = `input-file-video-share-stream-source-element-${id}`;

          const videoEl = document.getElementById(
            inputFileVideoSourceElementId
          ) as StudioHTMLVideoElement;

          if (videoEl && videoEl.readyState === 4) {
            clearInterval(interval);

            resolve({ inputFileVideoSourceElementId, videoEl });
          }
        }, 100);
      });

      if (videoEl && videoEl.captureStream) {
        const videoHeight = videoEl.videoHeight;
        const videoWidth = videoEl.videoWidth;

        const stream = videoEl.captureStream();

        const tracks = stream.getTracks();

        let audioTrack: MediaStreamTrack | null = null;
        let videoTrack: MediaStreamTrack | null = null;

        for (let index = 0; index < tracks.length; index++) {
          const track = tracks[index];

          if (track.kind === "audio") {
            audioTrack = track;
          } else if (track.kind === "video") {
            videoTrack = track;
          }
        }

        if (!videoTrack) {
          setTurnOnInputFileVideoShareInProgressRef.current(false);

          return;
        }

        let audioPublication: LocalTrackPublication | null = null;

        if (audioTrack) {
          audioPublication = await rtcClient.localParticipant.publishTrack(
            audioTrack,
            {
              name: `ogInputFileVideoShareAudio_${
                localParticipantIdRef.current
              }_${createUID()}_${new Date().getTime()}`,
            }
          );
        }

        //
        //

        if (videoHeight > videoWidth) {
          if (videoHeight > 1280) {
            await videoTrack.applyConstraints({
              height: 1280,
              width: (videoWidth * 1280) / videoHeight,
            });
          }
        } else {
          if (videoWidth > 1280) {
            await videoTrack.applyConstraints({
              width: 1280,
              height: (videoHeight * 1280) / videoWidth,
            });
          }
        }

        const videoPublication = await rtcClient.localParticipant.publishTrack(
          videoTrack,
          {
            screenShareEncoding: { maxFramerate: 30, maxBitrate: 2500000 },
            name: `ogInputFileVideoShareVideo_${
              localParticipantIdRef.current
            }_${createUID()}_${new Date().getTime()}`,
          }
        );

        const videoPublicationTrackSid = videoPublication.trackSid;
        const audioPublicationTrackSid = audioPublication?.trackSid;

        appEventEmitter.emit(
          appEventEmitterEvents.RTC_INPUT_FILE_VIDEO_SHARE_STREAM_ENABLED(id),
          {
            success: true,
            data: {
              id,
              fileObjectUrl,
              inputFileVideoSourceElementId,
              videoPublicationTrackSid,
              audioPublicationTrackSid,
            },
          }
        );
      } else {
        appEventEmitter.emit(
          appEventEmitterEvents.RTC_INPUT_FILE_VIDEO_SHARE_STREAM_ENABLED(id),
          { success: false }
        );
      }
    } catch (error) {
      //
    }

    setTurnOnInputFileVideoShareInProgressRef.current(false);
  };

  const _handleDisableInputFileVideoShareStream = async ({
    id,
  }: {
    id: string;
  }) => {
    const inputFileVideoShareStreamPublications =
      inputFileVideoShareStreamPublicationsRef.current;
    const rtcClient = rtcClientRef.current;

    const publication = inputFileVideoShareStreamPublications.find(
      ({ id: _id }) => _id === id
    );

    if (publication) {
      if (publication.videoPublication?.track) {
        await rtcClient.localParticipant.unpublishTrack(
          publication.videoPublication.track
        );
      }

      if (publication.audioPublication?.track) {
        await rtcClient.localParticipant.unpublishTrack(
          publication.audioPublication.track
        );
      }
    }
  };

  const _handleRtcSubscribeRemoteInputFileVideoShareVideoStream = async ({
    inputFileVideoId,
    participantId,
  }: {
    inputFileVideoId: string;
    participantId: string;
  }) => {
    const publication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "inputFileVideoShareVideo",
      inputFileVideoId,
    });

    if (publication) {
      publication.setSubscribed(true);
    }
  };

  const _handleRtcUnsubscribeRemoteInputFileVideoShareVideoStream = async ({
    inputFileVideoId,
    participantId,
  }: {
    inputFileVideoId: string;
    participantId: string;
  }) => {
    const publication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "inputFileVideoShareVideo",
      inputFileVideoId,
    });

    if (publication) {
      publication.setSubscribed(false);
    }
  };

  const _handleRtcSubscribeRemoteInputFileVideoShareAudioStream = async ({
    inputFileVideoId,
    participantId,
  }: {
    inputFileVideoId: string;
    participantId: string;
  }) => {
    const publication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "inputFileVideoShareAudio",
      inputFileVideoId,
    });

    if (publication) {
      publication.setSubscribed(true);
    }
  };

  const _handleRtcUnsubscribeRemoteInputFileVideoShareAudioStream = async ({
    inputFileVideoId,
    participantId,
  }: {
    inputFileVideoId: string;
    participantId: string;
  }) => {
    const publication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "inputFileVideoShareAudio",
      inputFileVideoId,
    });

    if (publication) {
      publication.setSubscribed(false);
    }
  };

  const _handleRtcSetRemoteInputFileVideoShareVideoQuality = async ({
    inputFileVideoId,
    participantId,
    quality,
  }: {
    inputFileVideoId: string;
    participantId: string;
    quality: "low" | "med" | "high";
  }) => {
    const publication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "inputFileVideoShareVideo",
      inputFileVideoId,
    });

    if (publication) {
      const livekitQuality =
        quality === "low"
          ? VideoQuality.LOW
          : quality === "med"
          ? VideoQuality.MEDIUM
          : quality === "high"
          ? VideoQuality.HIGH
          : undefined;

      if (livekitQuality !== undefined) {
        publication.setVideoQuality(livekitQuality);
      }
    }
  };

  const _handleRtcTurnOffMediaAndPublications = async () => {
    await _handleTurnOffWebcam(true);

    await sleep(500);

    await _handleTurnOffMic(true);

    await sleep(500);

    await _handleTurnOffScreenShare();

    await sleep(500);

    for (
      let index = 0;
      index < inputFileVideoShareStreamPublicationsRef.current.length;
      index++
    ) {
      const publications =
        inputFileVideoShareStreamPublicationsRef.current[index];

      await _handleDisableInputFileVideoShareStream({ id: publications.id });

      await sleep(500);
    }
  };

  const _handleRtcSubscribeRemoveExtraWebcamVideoStream = async ({
    extraWebcamId,
    participantId,
  }: {
    extraWebcamId: string;
    participantId: string;
  }) => {
    const publication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "extraWebcam",
      extraWebcamId,
    });

    if (publication) {
      publication.setSubscribed(true);
    }
  };

  const _handleRtcUnsubscribeRemoveExtraWebcamVideoStream = async ({
    extraWebcamId,
    participantId,
  }: {
    extraWebcamId: string;
    participantId: string;
  }) => {
    const publication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "extraWebcam",
      extraWebcamId,
    });

    if (publication) {
      publication.setSubscribed(false);
    }
  };

  const _handleRtcSetRemoteExtraWebcamVideoQuality = async ({
    extraWebcamId,
    participantId,
    quality,
  }: {
    extraWebcamId: string;
    participantId: string;
    quality: "low" | "med" | "high";
  }) => {
    const publication = getRemoteTrackPublication({
      participantId,
      remoteTrackPublicationType: "extraWebcam",
      extraWebcamId,
    });

    if (publication) {
      const livekitQuality =
        quality === "low"
          ? VideoQuality.LOW
          : quality === "med"
          ? VideoQuality.MEDIUM
          : quality === "high"
          ? VideoQuality.HIGH
          : undefined;

      if (livekitQuality !== undefined) {
        publication.setVideoQuality(livekitQuality);
      }
    }
  };

  const _handleRtcTurnOnParticipantTranslatedAudioStream = async ({
    participantId,
    audioMediaStreamTrack,
    language,
  }: {
    participantId: string;
    audioMediaStreamTrack: MediaStreamTrack;
    language: string;
  }) => {
    try {
      const rtcClient = rtcClientRef.current;

      if (!rtcClient) {
        return;
      }

      await rtcClient.localParticipant.publishTrack(audioMediaStreamTrack, {
        name: `translatedAudio_${participantId}_${language.toLowerCase()}_${createUID()}_${new Date().getTime()}`,
        red: true,
        dtx: true,
      });
    } catch (error) {
      //
    }
  };

  const _handleRtcTurnOffParticipantAllTranslatedAudioStreams = async ({
    participantId,
  }: {
    participantId: string;
  }) => {
    const participantPublications =
      livekitLocalParticipantTrackPublicationsRef.current.filter(
        ({ trackName }) => trackName.includes(participantId)
      );

    const participantTracks = participantPublications
      .filter(({ track }) => track)
      .map(({ track }) => track as LocalTrack);

    if (participantTracks.length) {
      await rtcClient.localParticipant.unpublishTracks(participantTracks);
    }
  };

  useEffect(() => {
    const rtcClient = rtcClientRef.current;

    appEventEmitter.on(
      appEventEmitterEvents.RTC_TURN_ON_WEBCAM,
      _handleTurnOnWebcam
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_TURN_OFF_WEBCAM,
      _handleTurnOffWebcam
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_TOGGLE_WEBCAM,
      _handleToggleWebcam
    );
    appEventEmitter.on(appEventEmitterEvents.RTC_TURN_ON_MIC, _handleTurnOnMic);
    appEventEmitter.on(
      appEventEmitterEvents.RTC_TURN_OFF_MIC,
      _handleTurnOffMic
    );
    appEventEmitter.on(appEventEmitterEvents.RTC_TOGGLE_MIC, _handleToggleMic);
    appEventEmitter.on(
      appEventEmitterEvents.RTC_TURN_ON_SCREEN_SHARE,
      _handleTurnOnScreenShare
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_TURN_OFF_SCREEN_SHARE,
      _handleTurnOffScreenShare
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_TOGGLE_SCREEN_SHARE,
      _handleToggleScreenShare
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SET_VIRTUAL_BACKGROUND,
      _handleSetWebcamTrackProcessor
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_WEBCAM_STREAM,
      _handleRtcSubscribeRemoteWebcamStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_MIC_STREAM,
      _handleRtcSubscribeRemoteMicStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_SCREEN_SHARE_VIDEO_STREAM,
      _handleRtcSubscribeRemoteScreenShareVideoStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_SCREEN_SHARE_AUDIO_STREAM,
      _handleRtcSubscribeRemoteScreenShareAudioStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_WEBCAM_STREAM,
      _handleRtcUnsubscribeRemoteWebcamStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_MIC_STREAM,
      _handleRtcUnsubscribeRemoteMicStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_SCREEN_SHARE_VIDEO_STREAM,
      _handleRtcUnsubscribeRemoteScreenShareVideoStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_SCREEN_SHARE_AUDIO_STREAM,
      _handleRtcUnsubscribeRemoteScreenShareAudioStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SET_REMOTE_WEBCAM_QUALITY,
      _handleRtcSetRemoteWebcamQuality
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SET_REMOTE_SCREEN_SHARE_VIDEO_QUALITY,
      _handleRtcSetRemoteScreenShareVideoQuality
    );

    appEventEmitter.on(
      appEventEmitterEvents.RTC_CHANGE_WEBCAM_DEVICE_ID,
      _handleChangeWebcamDeviceId
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_CHANGE_MIC_DEVICE_ID,
      _handleChangeMicDeviceId
    );

    appEventEmitter.on(
      appEventEmitterEvents.RTC_ENABLE_INPUT_FILE_VIDEO_SHARE_STREAM,
      _handleEnableInputFileVideoShareStream
    );

    appEventEmitter.on(
      appEventEmitterEvents.RTC_DISABLE_INPUT_FILE_VIDEO_SHARE_STREAM,
      _handleDisableInputFileVideoShareStream
    );

    appEventEmitter.on(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_STREAM,
      _handleRtcSubscribeRemoteInputFileVideoShareVideoStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_STREAM,
      _handleRtcUnsubscribeRemoteInputFileVideoShareVideoStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_AUDIO_STREAM,
      _handleRtcSubscribeRemoteInputFileVideoShareAudioStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_AUDIO_STREAM,
      _handleRtcUnsubscribeRemoteInputFileVideoShareAudioStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SET_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_QUALITY,
      _handleRtcSetRemoteInputFileVideoShareVideoQuality
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_TURN_OFF_MEDIA_AND_PUBLICATIONS,
      _handleRtcTurnOffMediaAndPublications
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_TURN_ON_EXTRA_WEBCAM,
      _handleTurnOnExtraWebcam
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_TURN_OFF_EXTRA_WEBCAM,
      _handleTurnOffExtraWebcam
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_CHANGE_EXTRA_WEBCAM_DEVICE_ID,
      _handleChangeExtraWebcamDeviceId
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_EXTRA_WEBCAM_VIDEO_STREAM,
      _handleRtcSubscribeRemoveExtraWebcamVideoStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_EXTRA_WEBCAM_VIDEO_STREAM,
      _handleRtcUnsubscribeRemoveExtraWebcamVideoStream
    );
    appEventEmitter.on(
      appEventEmitterEvents.RTC_SET_REMOTE_EXTRA_WEBCAM_VIDEO_QUALITY,
      _handleRtcSetRemoteExtraWebcamVideoQuality
    );

    appEventEmitter.on(
      appEventEmitterEvents.RTC_TURN_ON_PARTICIPANT_TRANSLATED_AUDIO_STREAM,
      _handleRtcTurnOnParticipantTranslatedAudioStream
    );

    appEventEmitter.on(
      appEventEmitterEvents.RTC_TURN_OFF_PARTICIPANT_ALL_TRANSLATED_AUDIO_STREAMS,
      _handleRtcTurnOffParticipantAllTranslatedAudioStreams
    );

    return () => {
      rtcClient.disconnect();

      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_ON_WEBCAM,
        _handleTurnOnWebcam
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_OFF_WEBCAM,
        _handleTurnOffWebcam
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TOGGLE_WEBCAM,
        _handleToggleWebcam
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_ON_MIC,
        _handleTurnOnMic
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_OFF_MIC,
        _handleTurnOffMic
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TOGGLE_MIC,
        _handleToggleMic
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_ON_SCREEN_SHARE,
        _handleTurnOnScreenShare
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_OFF_SCREEN_SHARE,
        _handleTurnOffScreenShare
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TOGGLE_SCREEN_SHARE,
        _handleToggleScreenShare
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SET_VIRTUAL_BACKGROUND,
        _handleSetWebcamTrackProcessor
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_WEBCAM_STREAM,
        _handleRtcSubscribeRemoteWebcamStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_MIC_STREAM,
        _handleRtcSubscribeRemoteMicStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_SCREEN_SHARE_VIDEO_STREAM,
        _handleRtcSubscribeRemoteScreenShareVideoStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_SCREEN_SHARE_AUDIO_STREAM,
        _handleRtcSubscribeRemoteScreenShareAudioStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_WEBCAM_STREAM,
        _handleRtcUnsubscribeRemoteWebcamStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_MIC_STREAM,
        _handleRtcUnsubscribeRemoteMicStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_SCREEN_SHARE_VIDEO_STREAM,
        _handleRtcUnsubscribeRemoteScreenShareVideoStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_SCREEN_SHARE_AUDIO_STREAM,
        _handleRtcUnsubscribeRemoteScreenShareAudioStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SET_REMOTE_WEBCAM_QUALITY,
        _handleRtcSetRemoteWebcamQuality
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SET_REMOTE_SCREEN_SHARE_VIDEO_QUALITY,
        _handleRtcSetRemoteScreenShareVideoQuality
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_CHANGE_WEBCAM_DEVICE_ID,
        _handleChangeWebcamDeviceId
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_CHANGE_MIC_DEVICE_ID,
        _handleChangeMicDeviceId
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_ENABLE_INPUT_FILE_VIDEO_SHARE_STREAM,
        _handleEnableInputFileVideoShareStream
      );

      appEventEmitter.off(
        appEventEmitterEvents.RTC_DISABLE_INPUT_FILE_VIDEO_SHARE_STREAM,
        _handleDisableInputFileVideoShareStream
      );

      appEventEmitter.off(
        appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_STREAM,
        _handleRtcSubscribeRemoteInputFileVideoShareVideoStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_STREAM,
        _handleRtcUnsubscribeRemoteInputFileVideoShareVideoStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_AUDIO_STREAM,
        _handleRtcSubscribeRemoteInputFileVideoShareAudioStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_AUDIO_STREAM,
        _handleRtcUnsubscribeRemoteInputFileVideoShareAudioStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SET_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_QUALITY,
        _handleRtcSetRemoteInputFileVideoShareVideoQuality
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_OFF_MEDIA_AND_PUBLICATIONS,
        _handleRtcTurnOffMediaAndPublications
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_ON_EXTRA_WEBCAM,
        _handleTurnOnExtraWebcam
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_OFF_EXTRA_WEBCAM,
        _handleTurnOffExtraWebcam
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_CHANGE_EXTRA_WEBCAM_DEVICE_ID,
        _handleChangeExtraWebcamDeviceId
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_EXTRA_WEBCAM_VIDEO_STREAM,
        _handleRtcSubscribeRemoveExtraWebcamVideoStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_EXTRA_WEBCAM_VIDEO_STREAM,
        _handleRtcUnsubscribeRemoveExtraWebcamVideoStream
      );
      appEventEmitter.off(
        appEventEmitterEvents.RTC_SET_REMOTE_EXTRA_WEBCAM_VIDEO_QUALITY,
        _handleRtcSetRemoteExtraWebcamVideoQuality
      );

      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_ON_PARTICIPANT_TRANSLATED_AUDIO_STREAM,
        _handleRtcTurnOnParticipantTranslatedAudioStream
      );

      appEventEmitter.off(
        appEventEmitterEvents.RTC_TURN_OFF_PARTICIPANT_ALL_TRANSLATED_AUDIO_STREAMS,
        _handleRtcTurnOffParticipantAllTranslatedAudioStreams
      );
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const setLocalWebcamOn = setLocalWebcamOnRef.current;
      const localWebcamOn = localWebcamOnRef.current;
      const webcamOn = webcamOnRef.current;
      const cameraDenied = cameraDeniedRef.current;

      const setLocalMicOn = setLocalMicOnRef.current;
      const localMicOn = localMicOnRef.current;
      const micOn = micOnRef.current;
      const micDenied = micDeniedRef.current;

      if (cameraDenied && (localWebcamOn || webcamOn)) {
        await _handleTurnOffWebcam(true);
      }

      if (micDenied && (localMicOn || micOn)) {
        await _handleTurnOffMic(true);
      }

      if (localWebcamOn && !webcamOn) {
        setLocalWebcamOn(false);
      }

      if (localMicOn && !micOn) {
        setLocalMicOn(false);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          top: 0,
          left: 0,
          height: 0,
          width: 0,
          overflow: "hidden",
          position: "fixed",
        }}
      >
        {inputFileVideoShareStreamFileObjectUrls.map(
          ({ id, fileObjectUrl }) => (
            <InputFileVideoShareStreamSourceElement
              {...{
                fileObjectUrl,
                id,
                key: `input-file-video-share-stream-source-element-${id}`,
              }}
            />
          )
        )}
      </div>

      <LocalParticipantRtcMediaListner />
      <RemoteParticipantsRtcMediaListner />
    </React.Fragment>
  );
};

export default LivekitRtcListner;
