import { permissionNames } from "./constants";

const getMicPermissionState = async (): Promise<PermissionState> => {
  try {
    return new Promise((resolve) => {
      if (window?.navigator?.permissions?.query) {
        window.navigator.permissions
          .query({ name: permissionNames.microphone })
          .then((result) => {
            resolve(result.state);
          })
          .catch(() => {
            resolve("prompt");
          });
      } else {
        resolve("prompt");
      }
    });
  } catch (err) {
    return "prompt";
  }
};

export default getMicPermissionState;
