import { useAppContext } from "../../contexts/appContextDef";
import BrandBackgroundsListner from "./BrandBackgroundsListner";

const AllBrandsBackgroundsListner = () => {
  const { brands } = useAppContext();

  return brands.map(({ id }) => (
    <BrandBackgroundsListner key={`BrandBackgroundsListner_${id}`} id={id} />
  ));
};

export default AllBrandsBackgroundsListner;
