import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import useMainViewParticipants from "../../hooks/appState/useMainViewParticipants";
import {
  MdMoreVert,
  MdRemoveCircle,
  MdDelete,
  MdCancel,
  MdMovie,
  MdPlayArrow,
  MdPause,
  MdVolumeUp,
  MdVolumeDown,
  MdVolumeOff,
} from "react-icons/md";
import { Menu, MenuItem } from "@szhsin/react-menu";
import ChangeNameModal from "../../components/ChangeNameModal";
import useVideoShareStreams from "../../hooks/streams/useVideoShareStreams";
import useVideoShareStream from "../../hooks/streams/useVideoShareStream";
import useParticipantName from "../../hooks/appState/useParticipantName";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import useIsOldest from "../../hooks/appState/useIsOldest";
import { appModes } from "../../utils/constants";
import useAskToLeave from "../../hooks/appState/useAskToLeave";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";

export const getSettedCurrentTimeOfVideoShareStream = ({
  playing,
  durationInSec,
  latestSeekDataIimestamp,
  latestSeekDataSeekToSec,
}: {
  playing: boolean;
  durationInSec: number;
  latestSeekDataIimestamp: number;
  latestSeekDataSeekToSec: number;
}) => {
  const currentTime = new Date().getTime();

  const seekedAtTime = new Date(latestSeekDataIimestamp).getTime();

  const lastSeekedBeforeSec = (currentTime - seekedAtTime) / 1000;

  const currentVideoTimeSec = playing
    ? lastSeekedBeforeSec + latestSeekDataSeekToSec
    : latestSeekDataSeekToSec;

  let settedCurrentTimeOfVideo;

  if (currentVideoTimeSec >= durationInSec) {
    settedCurrentTimeOfVideo = durationInSec;
  } else {
    settedCurrentTimeOfVideo = currentVideoTimeSec;
  }

  return { settedCurrentTimeOfVideo };
};

export const VideoStreamVolumeControl = ({
  volume,
  changeVolume,
  fill,
  alwaysShowSlider,
  iconSize,
}: {
  volume: number;
  changeVolume: (t: { volume: number }) => void;
  fill?: string;
  alwaysShowSlider?: boolean;
  iconSize?: number;
}) => {
  const inputRef = createRef<HTMLInputElement>();

  const [isMouseOver, setIsMouseOver] = useState(false);

  const VolumeIcon = useMemo(() => {
    return volume === 0
      ? MdVolumeOff
      : volume > 0 && volume < 0.5
      ? MdVolumeDown
      : MdVolumeUp;
  }, [volume]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = `${volume}`;
    }
  }, [volume, inputRef]);

  return (
    <div
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      className={"flex"}
    >
      <div
        onClick={() => {
          changeVolume({ volume: volume === 0 ? 0.5 : 0 });
        }}
        className="aspect-square h-full flex-1"
      >
        <VolumeIcon
          size={iconSize}
          className={`${fill ? fill : "fill-gray-800"}`}
        />
      </div>
      {(isMouseOver || alwaysShowSlider) && (
        <div className="flex flex-1">
          <input
            className="p-0 m-0"
            ref={inputRef as React.LegacyRef<HTMLInputElement>}
            type={"range"}
            min={0}
            max={1}
            step={0.1}
            onChange={(e) => {
              changeVolume({
                volume: parseFloat((e.target as HTMLInputElement).value),
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

const ItemMenuContainer = ({
  onRemove,
  isInMainView,
  removeFromMainViewStreams,
  isLocal,
  onStopVideo,
}: {
  setChangeNameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRemove: () => void;
  isInMainView: boolean;
  removeFromMainViewStreams: () => void;
  isLocal: boolean;
  onStopVideo: () => void;
}) => {
  const { appMode } = useAppContext();

  return (
    <Menu
      menuButton={
        <span>
          <button
            className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
          >
            <div>
              <MdMoreVert size={16} />
            </div>
          </button>
        </span>
      }
      transition
    >
      {appMode === appModes.SPEAKER && isInMainView && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={removeFromMainViewStreams}
        >
          <div className="flex items-center justify-center">
            <MdRemoveCircle />
            <p className="ml-2">Remove from stream</p>
          </div>
        </MenuItem>
      )}
      {/* {(appMode === appModes.HOST || isLocal) && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {
            setChangeNameModalOpen(true);
          }}
        >
          <div className="flex items-center justify-center">
            <MdEdit />
            <p className="ml-2">Edit name</p>
          </div>
        </MenuItem>
      )} */}
      {isLocal && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={onStopVideo}
        >
          <div className="flex items-center justify-center">
            <MdDelete />
            <p className="ml-2">Remove from studio</p>
          </div>
        </MenuItem>
      )}
      {appMode === appModes.HOST && !isLocal && (
        <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={onRemove}
        >
          <div className="flex items-center justify-center">
            <MdCancel />
            <p className="ml-2">Kick from studio</p>
          </div>
        </MenuItem>
      )}
    </Menu>
  );
};

const InQueueVideoStreamContainer = ({
  videoId,
  participantId,
}: {
  videoId: string;
  participantId: string;
}) => {
  const [isReadyAndDurationFound, setIsReadyAndDurationFound] = useState({
    isReady: false,
    isDurationFound: false,
  });

  const { isLocal } = useIsLocalParticipantId(participantId);

  const { disableVideoShareStream } = useVideoShareStreams();

  const {
    durationInSec,
    playing,
    remoteUrl,
    //
    pauseAndSeek,
    playAndSeek,
    togglePlayingAndSeek,
    //
    volume,
    changeVolume,
    latestSeekDataIimestamp,
    latestSeekDataSeekToSec,
    playingStateChangedTimestamp,
  } = useVideoShareStream({ videoId });

  const playingRef = useRef(playing);
  const isReadyAndDurationFoundRef = useRef(isReadyAndDurationFound);

  const videoPlayerId = useMemo(
    () => `InQueueVideoStreamContainer-${videoId}`,
    [videoId]
  );

  const videoPlayerIdRef = useRef(videoPlayerId);

  useEffect(() => {
    videoPlayerIdRef.current = videoPlayerId;
  }, [videoPlayerId]);

  useEffect(() => {
    playingRef.current = playing;
  }, [playing]);
  useEffect(() => {
    isReadyAndDurationFoundRef.current = isReadyAndDurationFound;
  }, [isReadyAndDurationFound]);
  const playingStateChangedTimestampRef = useRef(playingStateChangedTimestamp);

  const playedPerRef = useRef<number>();

  const { mainViewSelectedStreams, inQueueItemContainerWidth, appMode } =
    useAppContext();

  const { name } = useParticipantName(participantId);

  const { askToLeave } = useAskToLeave(participantId);

  const [currentTime, setCurrentTime] = useState(0);
  const [changeNameModalOpen, setChangeNameModalOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const { isInMainView } = useMemo(() => {
    const streamId = generateStreamId({
      participantId,
      type: streamTypes.SHARE,
      mode: streamModes.VIDEO,
      videoId,
    });

    const isInMainView = !!mainViewSelectedStreams.get(streamId);

    return { streamId, isInMainView };
  }, [participantId, videoId, mainViewSelectedStreams]);

  const isInMainViewRef = useRef(isInMainView);
  const currentTimeRef = useRef(currentTime);
  const durationInSecRef = useRef(durationInSec);

  useEffect(() => {
    durationInSecRef.current = durationInSec;
  }, [durationInSec]);
  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);
  useEffect(() => {
    currentTimeRef.current = currentTime;
  }, [currentTime]);
  useEffect(() => {
    playingStateChangedTimestampRef.current = playingStateChangedTimestamp;
  }, [playingStateChangedTimestamp]);

  const getVideoTag = () => {
    return document.getElementById(
      videoPlayerIdRef.current
    ) as StudioHTMLVideoElement | null;
  };

  useEffect(() => {
    if (
      latestSeekDataSeekToSec &&
      latestSeekDataIimestamp &&
      isReadyAndDurationFound.isDurationFound &&
      isReadyAndDurationFound.isReady
    ) {
      const { settedCurrentTimeOfVideo } =
        getSettedCurrentTimeOfVideoShareStream({
          durationInSec: durationInSecRef.current as number,
          latestSeekDataIimestamp,
          latestSeekDataSeekToSec,
          playing: playing as boolean,
        });

      const videoTag = getVideoTag();

      if (videoTag) {
        videoTag.currentTime = settedCurrentTimeOfVideo;
      }
    }
  }, [
    playing,
    latestSeekDataIimestamp,
    latestSeekDataSeekToSec,
    isReadyAndDurationFound,
  ]);

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const _handleAddToMainViewStreams = () => {
    addToMainViewStreams({
      mode: streamModes.VIDEO,
      participantId,
      type: streamTypes.SHARE,
      videoId,
    });
  };

  const _handleRemoveFromMainViewStreams = () => {
    removeFromMainViewStreams({
      mode: streamModes.VIDEO,
      participantId,
      type: streamTypes.SHARE,
      videoId,
    });
  };

  const _handleToggleFromMainViewStreams = () => {
    const _isInMainView = isInMainViewRef.current;

    if (_isInMainView) {
      _handleRemoveFromMainViewStreams();
    } else {
      _handleAddToMainViewStreams();
    }
  };

  const _handleRemoveParticipant = () => {
    // TODO: confirm to remove
    // participantRef.current.remove();

    askToLeave();
  };

  const _handleStopVideoShare = () => {
    disableVideoShareStream({ id: videoId });

    _handleRemoveFromMainViewStreams();
  };

  const { getIsOldestParticipant } = useIsOldest();

  const _handleOnEnded = () => {
    const { isOldestParticipant } = getIsOldestParticipant();

    if (isOldestParticipant) {
      pauseAndSeek({ seekToSec: 0.000001 });
    }
  };

  const _playVideo = () => {
    const videoTag = getVideoTag();

    if (videoTag && typeof videoTag.play === "function") {
      videoTag.volume = 0;

      videoTag.muted = true;

      videoTag.playsInline = true;

      const playPromise = videoTag.play();

      // error could occur in this play

      if (playPromise !== undefined) {
        playPromise
          .then(() => {})
          .catch(() => {
            // have to handle the play failure
          });
      }
    }
  };

  const _pauseVideo = () => {
    const videoTag = getVideoTag();

    if (videoTag) {
      if (typeof videoTag.pause === "function") {
        videoTag.pause();
      }
    }
  };

  useEffect(() => {
    const videoTag = getVideoTag();

    if (!videoTag) return;

    const isVideoPaused = videoTag.paused;

    if (playing && isVideoPaused) {
      _playVideo();
    } else if (!playing && !isVideoPaused) {
      _pauseVideo();
    }
  }, [playing]);

  return (
    <React.Fragment>
      <div
        onMouseEnter={() => {
          setIsMouseOver(true);
        }}
        onMouseLeave={() => {
          setIsMouseOver(false);
        }}
        className="rounded"
        style={{ width: inQueueItemContainerWidth }}
      >
        <div
          style={{
            height: (inQueueItemContainerWidth * 9) / 16,
            width: inQueueItemContainerWidth,
          }}
          className="relative video-cover bg-custom-blue-50"
        >
          {remoteUrl && (
            <React.Fragment>
              <video
                onDurationChange={() => {
                  if (!isReadyAndDurationFoundRef.current.isDurationFound) {
                    setIsReadyAndDurationFound((s) => ({
                      ...s,
                      isDurationFound: true,
                    }));
                  }
                }}
                onCanPlay={() => {
                  if (!isReadyAndDurationFoundRef.current.isReady) {
                    setIsReadyAndDurationFound((s) => ({
                      ...s,
                      isReady: true,
                    }));
                  }
                }}
                onTimeUpdate={(e) => {
                  const played =
                    (e.target as StudioHTMLVideoElement).currentTime /
                    (durationInSecRef.current || 0);
                  const playedSeconds = (e.target as StudioHTMLVideoElement)
                    .currentTime;

                  setCurrentTime(playedSeconds);

                  if (playedPerRef.current) {
                    playedPerRef.current = played;
                  }
                }}
                muted
                autoPlay
                id={videoPlayerId}
                src={remoteUrl}
                onEnded={_handleOnEnded}
                onPlay={() => {
                  if (!playing) {
                    _pauseVideo();
                  }
                }}
                onPause={() => {
                  if (playing) {
                    _playVideo();
                  }
                }}
                // playsinline // very very imp prop
                playsInline // very very imp prop
                controls={false}
                height={"100%"}
                width={"100%"}
              />
            </React.Fragment>
          )}

          {!isInMainView && (
            <div className="top-0 left-0 right-0 bottom-0 absolute bg-opacity-50 bg-white"></div>
          )}

          <div className="top-0 left-0 right-0 bottom-0 absolute flex justify-between">
            {appMode === appModes.HOST && (
              <div className="p-3 flex flex-1 hover:opacity-100 opacity-0 items-end justify-center">
                <button
                  className="btn btn-primary text-white btn-active"
                  onClick={_handleToggleFromMainViewStreams}
                >
                  {isInMainView ? "Remove" : "Add to stream"}
                </button>
              </div>
            )}

            <div className="absolute top-2 right-2 flex">
              <ItemMenuContainer
                isLocal={isLocal}
                onRemove={_handleRemoveParticipant}
                onStopVideo={_handleStopVideoShare}
                setChangeNameModalOpen={setChangeNameModalOpen}
                removeFromMainViewStreams={_handleRemoveFromMainViewStreams}
                isInMainView={isInMainView}
              />
            </div>
          </div>
        </div>

        <div
          className={`flex items-center p-0.5 ${
            isInMainView
              ? "bg-primary text-white fill-white"
              : "bg-white text-gray-800 fill-gray-800"
          } relative overflow-hidden rounded-b-md`}
        >
          {isMouseOver && (
            <div className="absolute top-0 right-0 left-0 -translate-y-1/2">
              <input
                className="w-full"
                type="range"
                min={0}
                max={1}
                step={0.001}
                value={currentTime / (durationInSec || 0)}
                onChange={(e) => {
                  const seekToSec =
                    parseInt((e.target as HTMLInputElement).value) *
                    (durationInSec || 0);

                  const videoTag = getVideoTag();

                  if (videoTag) {
                    videoTag.currentTime = seekToSec;
                  }
                }}
                onMouseUp={(e) => {
                  const seekToSec =
                    parseInt((e.target as HTMLInputElement).value) *
                    (durationInSec || 0);

                  if (playing) {
                    playAndSeek({ seekToSec });
                  } else {
                    pauseAndSeek({ seekToSec });
                  }
                }}
              />
            </div>
          )}

          <div className="flex items-center rounded-b-md overflow-hidden relative">
            <div className="w-6 flex items-center justify-center">
              <MdMovie size={16} />
            </div>
            <p className="truncate text-ellipsis ml-0.5 text-sm">{name}</p>
            {isMouseOver ? (
              <div className="absolute top-0 left-0 right-0 bottom-0 rounded-b-md overflow-hidden fill-gray-800 bg-white items-center flex">
                <button
                  onClick={() => {
                    const seekToSec = currentTimeRef.current;

                    togglePlayingAndSeek({ seekToSec });
                  }}
                >
                  {playing ? (
                    <MdPause className="fill-gray-800" />
                  ) : (
                    <MdPlayArrow className="fill-gray-800" />
                  )}
                </button>

                <VideoStreamVolumeControl
                  volume={volume as number}
                  changeVolume={changeVolume}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {changeNameModalOpen && (
        <ChangeNameModal
          {...{
            participantId,
            handleCancel: () => {
              setChangeNameModalOpen(false);
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

export default InQueueVideoStreamContainer;
