// import { usePubSub } from "@videosdk.live/react-sdk";
import addBrandAsset from "../../apis/brands/add-brand-asset";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import { overlaysActions } from "../../listners/brands/BrandOverlaysListner";
import { getBrandOverlaysTopicById } from "../../utils/pubSubTopics";
import addBrandAssetByUrl from "../../apis/brands/add-brand-asset-by-url";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useOverlays = ({ id: brandId }: { id: string }) => {
  // const { publish } = usePubSub(getBrandOverlaysTopicById(brandId));

  const { publish } = useAppSingalingPublish(
    getBrandOverlaysTopicById(brandId)
  );

  const { setFileUploadError } = useAppContext();
  const { userId } = useAppConfigContext();

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const add = async ({
    singleFile,
    remoteUrl,
    uploadId,
  }: {
    singleFile?: File;
    remoteUrl?: string;
    uploadId?: string;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    if (singleFile) {
      const { success, id, name, remoteUrl, error } = await addBrandAsset({
        brandId,
        type: "overlay",
        userId,
        uploadId,
        singleFile,
      });

      if (success) {
        publish(
          JSON.stringify({
            action: overlaysActions.ADD,
            payload: { id, remoteUrl, name },
          })
          // { persist: true }
        );

        return id;
      } else if (error) {
        setFileUploadError({
          visible: true,
          errorMessage: error,
        });
      }
    } else if (remoteUrl) {
      const { success, id, name } = await addBrandAssetByUrl({
        brandId,
        userId,
        type: "overlay",
        fileUrl: remoteUrl,
      });

      if (success) {
        publish(
          JSON.stringify({
            action: overlaysActions.ADD,
            payload: { id, remoteUrl, name },
          })
          // { persist: true }
        );

        return id;
      } else {
        setFileUploadError({
          visible: true,
          errorMessage: "Unknown error occured!",
        });
      }
    }
  };

  const remove = async ({ id }: { id: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ action: overlaysActions.REMOVE, payload: { id } })
      // { persist: true }
    );
  };

  const sort = async ({ ids }: { ids: string[] }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ action: overlaysActions.SORT, payload: { ids } })
      // { persist: true }
    );
  };

  const rename = async ({ id, name }: { id: string; name: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({ action: overlaysActions.RENAME, payload: { id, name } })
      // { persist: true }
    );
  };

  return { add, sort, remove, rename };
};

export default useOverlays;
