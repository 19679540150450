import React, { useEffect, useMemo, useState } from "react";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../utils/appEventEmitter";
import { detect as detechBrowser } from "detect-browser";
import PermissionsBlockedChrome from "../static/svgs/PermissionsBlockedChrome";
import { MdLockOutline } from "react-icons/md";
import PermissionsBlockedSafari from "../static/svgs/PermissionsBlockedSafari";
import PermissionsBlockedFirefox from "../static/svgs/PermissionsBlockedFirefox";
import UrlBarMediaPermissionIcon from "../static/svgs/UrlBarMediaPermissionIcon";
import useIsParticipantCameraDenied from "../hooks/appState/useIsParticipantCameraDenied";
import useLocalParticipantId from "../hooks/utils/useLocalParticipantId";
import Modal from "react-modal";

const AskCameraPermissionModal = ({
  cameraDenied,
}: {
  cameraDenied: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const _handleAskCameraPermission = () => {
    setOpen(true);
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.ASK_CAMERA_PERMISSION,
      _handleAskCameraPermission
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.ASK_CAMERA_PERMISSION,
        _handleAskCameraPermission
      );
    };
  }, []);

  const browser = useMemo(() => detechBrowser(), []);

  useEffect(() => {
    if (open && !cameraDenied) {
      setOpen(false);
    }
  }, [cameraDenied, open]);

  return open ? (
    <Modal
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      shouldFocusAfterRender={false}
      style={{
        overlay: { backgroundColor: "#00000066" },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <div className="flex items-center rounded-xl overflow-hidden bg-white p-5">
        {browser?.name.includes("edge") || browser?.name.includes("chrome") ? (
          <div
            style={{ height: 1080 * 0.4, width: 1080 * 0.4 * 2 }}
            className="flex"
          >
            <div style={{ height: 1080 * 0.4, width: 1080 * 0.4 }}>
              <PermissionsBlockedChrome
                {...{
                  width: 1080,
                  height: 1080,
                  style: {
                    width: 1080,
                    height: 1080,
                    transform: "scale(0.4)",
                    transformOrigin: "0 0",
                  },
                }}
              />
            </div>
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl font-medium">
                MomentoStream is blocked from using your camera
              </p>
              <div className="mt-5 gap-2 flex flex-col">
                <p className="flex items-center">
                  1. Click the{" "}
                  <span className="mx-1">
                    <MdLockOutline />
                  </span>{" "}
                  lock icon in your browser's address bar
                </p>

                <p className="flex items-center">2. Turn on camera</p>
              </div>
            </div>
          </div>
        ) : browser?.name === "safari" ? (
          <div
            style={{ height: 1080 * 0.4, width: 1080 * 0.4 * 2 }}
            className="flex"
          >
            <div style={{ height: 1080 * 0.4, width: 1080 * 0.4 }}>
              <PermissionsBlockedSafari
                {...{
                  width: 1080,
                  height: 1080,
                  style: {
                    width: 1080,
                    height: 1080,
                    transform: "scale(0.4)",
                    transformOrigin: "0 0",
                  },
                }}
              />
            </div>
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl font-medium">
                MomentoStream is blocked from using your camera
              </p>
              <div className="mt-5 gap-2 flex flex-col">
                <p className="flex items-center">
                  1. Click the "Safari" menu and open "Settings for
                  app.momentostream.com"
                </p>

                <p className="flex items-center">2. Allow camera</p>
              </div>
            </div>
          </div>
        ) : browser?.name.includes("firefox") ? (
          <div
            style={{ height: 1080 * 0.4, width: 1080 * 0.4 * 2 }}
            className="flex"
          >
            <div style={{ height: 1080 * 0.4, width: 1080 * 0.4 }}>
              <PermissionsBlockedFirefox
                {...{
                  width: 1080,
                  height: 1080,
                  style: {
                    width: 1080,
                    height: 1080,
                    transform: "scale(0.4)",
                    transformOrigin: "0 0",
                  },
                }}
              />
            </div>
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl font-medium">
                MomentoStream is blocked from using your camera
              </p>
              <div className="mt-5 gap-2 flex flex-col">
                <p className="flex items-center">
                  1. Click the{" "}
                  <span className="mx-1">
                    <UrlBarMediaPermissionIcon />
                  </span>{" "}
                  lock icon in your browser's address bar
                </p>

                <p className="flex items-center">2. Turn on camera</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-96">
            <div className="flex flex-wrap">
              <p className="text-2xl font-medium">
                Camera and microphone are blocked
              </p>
              <p
                style={{ flexWrap: "wrap" }}
                className="flex justify-center items-center mt-5"
              >
                <p>
                  MomentoStream requires access to your camera and microphone.
                </p>
                <p>
                  Click the camera blocked icon in your browser's address bar.
                </p>
              </p>
            </div>
          </div>
        )}
      </div>
    </Modal>
  ) : (
    <React.Fragment />
  );
};

export const AskCameraPermissionModalInMeeting = () => {
  const { localParticipantId } = useLocalParticipantId();

  const { cameraDenied } = useIsParticipantCameraDenied({
    participantId: localParticipantId,
  });

  return <AskCameraPermissionModal cameraDenied={cameraDenied} />;
};

export default AskCameraPermissionModal;
