import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import {
  sidePanelAppModes,
  sidePanelBannersModes,
  sidePanelModes,
} from "../../utils/constants";

const useShouldRenderSidePanelItemContainer = () => {
  const {
    sidePanelMode,
    sidePanelAppMode,
    sidePanelBannersMode,
    sidePanelActivePollId,
    sidePanelActiveWordcloudId,
    sidePanelPrivateChatMode,
  } = useAppContext();

  const {
    shouldRenderPrivateChatGroupsContainer,
    shouldRenderBrandPanel,
    shouldRenderQNAPanel,
    shouldRenderAppsPanel,
    shouldRenderBannerFoldersContainer,
    shouldRenderBannersContainer,
    shouldRenderPollsContainer,
    shouldRenderWordcloudContainer,
    shouldRenderSidePanelPollDetail,
    shouldRenderSidePanelWordcloudDetail,
    shouldRenderParticipantsPanel,
    shouldRenderPrivateChatGroupMessages,
    shouldRenderResourcesContainer,
  } = useMemo(() => {
    return {
      shouldRenderPrivateChatGroupsContainer:
        sidePanelMode === sidePanelModes.PRIVATE_CHAT &&
        sidePanelPrivateChatMode === null,

      shouldRenderBrandPanel: sidePanelMode === sidePanelModes.BRAND,

      shouldRenderQNAPanel: sidePanelMode === sidePanelModes.QNA,

      shouldRenderAppsPanel:
        sidePanelMode === sidePanelModes.APPS && sidePanelAppMode === null,

      shouldRenderBannerFoldersContainer:
        sidePanelMode === sidePanelModes.APPS &&
        sidePanelAppMode === sidePanelAppModes.BANNERS &&
        sidePanelBannersMode === sidePanelBannersModes.BANNER_FOLDERS_LIST,

      shouldRenderBannersContainer:
        sidePanelMode === sidePanelModes.APPS &&
        sidePanelAppMode === sidePanelAppModes.BANNERS &&
        sidePanelBannersMode === sidePanelBannersModes.BANNERS_LIST,

      shouldRenderPollsContainer:
        sidePanelMode === sidePanelModes.APPS &&
        sidePanelAppMode === sidePanelAppModes.POLLS &&
        !sidePanelActivePollId,

      shouldRenderWordcloudContainer:
        sidePanelMode === sidePanelModes.APPS &&
        sidePanelAppMode === sidePanelAppModes.WORDCLOUDS &&
        !sidePanelActiveWordcloudId,

      shouldRenderSidePanelPollDetail:
        sidePanelMode === sidePanelModes.APPS &&
        sidePanelAppMode === sidePanelAppModes.POLLS &&
        sidePanelActivePollId,

      shouldRenderSidePanelWordcloudDetail:
        sidePanelMode === sidePanelModes.APPS &&
        sidePanelAppMode === sidePanelAppModes.WORDCLOUDS &&
        sidePanelActiveWordcloudId,

      shouldRenderParticipantsPanel:
        sidePanelMode === sidePanelModes.PARTICIPANTS,

      shouldRenderPrivateChatGroupMessages:
        sidePanelMode === sidePanelModes.PRIVATE_CHAT &&
        sidePanelPrivateChatMode,

      shouldRenderResourcesContainer:
        sidePanelMode === sidePanelModes.APPS &&
        sidePanelAppMode === sidePanelAppModes.RESOURCES,
    };
  }, [
    sidePanelMode,
    sidePanelPrivateChatMode,
    sidePanelAppMode,
    sidePanelBannersMode,
    sidePanelActivePollId,
    sidePanelActiveWordcloudId,
  ]);

  return {
    shouldRenderPrivateChatGroupsContainer,
    shouldRenderBrandPanel,
    shouldRenderQNAPanel,
    shouldRenderAppsPanel,
    shouldRenderBannerFoldersContainer,
    shouldRenderBannersContainer,
    shouldRenderPollsContainer,
    shouldRenderWordcloudContainer,
    shouldRenderSidePanelPollDetail,
    shouldRenderSidePanelWordcloudDetail,
    shouldRenderParticipantsPanel,
    shouldRenderPrivateChatGroupMessages,
    shouldRenderResourcesContainer,
  };
};

export default useShouldRenderSidePanelItemContainer;
