import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const addBrandAssetByUrl = async ({
  brandId,
  userId,
  type,
  fileUrl,
  thumbnail,
  assetName,
  video_480,
  video_720,
}: {
  brandId: string;
  userId: string;
  type: string;
  fileUrl: string;
  thumbnail?: string;
  assetName?: string;
  video_480?: string;
  video_720?: string;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/add-brand-asset-by-url`;

    const body = JSON.stringify({
      brandId,
      userId,
      type,
      fileUrl,
      thumbnail,
      name: assetName,
      480: video_480,
      720: video_720,
    });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();
    if (json.success) {
      return { success: true, ...json.data };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default addBrandAssetByUrl;
