import React, { useEffect, useState } from "react";
import { MdExpandLess, MdHelpOutline } from "react-icons/md";
import Tooltip from "./Tooltip";
import { appEventEmitter } from "../utils/appEventEmitter";

const CloseDisclosureListner = ({
  listenToCloseEvent,
  close,
}: {
  listenToCloseEvent?: string;
  close: () => void;
}) => {
  const _handleClose = () => {
    close();
  };

  useEffect(() => {
    if (listenToCloseEvent) {
      appEventEmitter.on(listenToCloseEvent, _handleClose);

      return () => {
        appEventEmitter.off(listenToCloseEvent, _handleClose);
      };
    }
  }, [listenToCloseEvent]);

  return <React.Fragment />;
};

const DisclosureContainer = ({
  title,
  renderPanel,
  dataTip,
  dataTipn,
  renderTitle,
  defaultOpen,
  listenToCloseEvent,
}: {
  title?: string;
  renderPanel?: () => React.ReactElement;
  dataTip?: string;
  dataTipn?: string[];
  renderTitle?: () => React.ReactElement;
  defaultOpen?: boolean;
  listenToCloseEvent?: string;
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const close = () => {
    setOpen(false);
  };

  return (
    <div className="w-full mt-5">
      <div
        className="flex w-full text-md items-center justify-between rounded-md bg-custom-blue-50 p-3 text-left cursor-pointer font-medium text-primary"
        onClick={() => {
          setOpen((s) => !s);
        }}
      >
        <div className="flex items-center justify-center">
          <div>
            {title ? (
              <span>{title}</span>
            ) : renderTitle ? (
              renderTitle()
            ) : (
              <React.Fragment />
            )}
          </div>
          <div className="flex items-center justify-center">
            {dataTip ? (
              <Tooltip title={dataTip} titlen={dataTipn}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="flex items-center justify-center rounded-full p-0.5 ml-1"
                >
                  <MdHelpOutline size={18} />
                </button>
              </Tooltip>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>

        <div className="flex items-center justify-center">
          <MdExpandLess
            className={`${
              open ? "rotate-180 transform" : ""
            } h-5 w-5 text-primary`}
          />
        </div>
      </div>

      {open ? (
        <div className="px-2 py-4">
          {typeof renderPanel === "function" && renderPanel()}
        </div>
      ) : (
        <React.Fragment />
      )}

      {listenToCloseEvent ? (
        <CloseDisclosureListner {...{ close, listenToCloseEvent }} />
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default DisclosureContainer;
