import { ref, set } from "firebase/database";
import { useAppContext } from "../../contexts/appContextDef";
import { firebaseDB } from "../../utils/firebase";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useEffect, useMemo, useRef } from "react";
import { nanoid } from "nanoid";
import clearChat from "../../apis/broadcasts/clear-chat";
import enableChat from "../../apis/broadcasts/enable-chat";
import disableChat from "../../apis/broadcasts/disable-chat";
import useParticipantName from "./useParticipantName";
import useLocalParticipantId from "../utils/useLocalParticipantId";
import useMainViewParticipants from "./useMainViewParticipants";
import {
  generateStreamId,
  streamModes,
  streamTypes,
} from "../../listners/appState/MainViewParticipantsListner";
import sendSocialMessage from "../../apis/broadcasts/send-social-message";
import useValidateHostCanTakeAction from "./useValidateHostCanTakeAction";
import useValidateSpeakerCanTakeAction from "./useValidateSpeakerCanTakeAction";
import { mainViewLayouts } from "../../utils/constants";

const useQnA = () => {
  const { broadcastId, userId } = useAppConfigContext();
  const {
    qnaMessages,
    isQnAEnabled,
    mainViewLayoutActive,
    mainViewSelectedStreams,
  } = useAppContext();

  const { localParticipantId } = useLocalParticipantId();
  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const participantId = "qnaAddParticipantId";

  const isCinemaLayoutActive = useMemo(
    () => mainViewLayoutActive === mainViewLayouts.CINEMA,
    [mainViewLayoutActive]
  );

  const { name } = useParticipantName(localParticipantId);

  const { isInMainView, isActive } = useMemo(() => {
    const streamId = generateStreamId({
      participantId,
      type: streamTypes.SHARE,
      mode: streamModes.QNA,
      qnaId: "public",
    });

    const isInMainView = !!mainViewSelectedStreams.get(streamId);

    const isActive = !!isQnAEnabled;

    return {
      isInMainView,
      isActive,
    };
  }, [participantId, mainViewSelectedStreams, isQnAEnabled]);

  const broadcastIdRef = useRef(broadcastId);
  const userIdRef = useRef(userId);
  const qnaMessagesRef = useRef(qnaMessages);
  const isQnAEnabledRef = useRef(isQnAEnabled);
  const nameRef = useRef(name);
  const isInMainViewRef = useRef(isInMainView);

  useEffect(() => {
    isInMainViewRef.current = isInMainView;
  }, [isInMainView]);
  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);
  useEffect(() => {
    userIdRef.current = userId;
  }, [userId]);
  useEffect(() => {
    qnaMessagesRef.current = qnaMessages;
  }, [qnaMessages]);
  useEffect(() => {
    isQnAEnabledRef.current = isQnAEnabled;
  }, [isQnAEnabled]);
  useEffect(() => {
    nameRef.current = name;
  }, [name]);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  const sendMessage = async ({
    message,
    momentoSent,
    socialPlatform,
    socialDesticationIds,
  }: {
    message: string;
    momentoSent?: boolean;
    socialPlatform?: string[];
    socialDesticationIds?: number[];
  }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    const broadcastId = broadcastIdRef.current;

    const obj = {
      timestamp: new Date().getTime(),
      userId,
      message,
      name: nameRef.current,
    };

    await set(
      ref(firebaseDB, `broadcastChat/${broadcastId}/${nanoid()}`),
      socialPlatform
        ? { momentoSent: !!momentoSent, socialPlatform, ...obj }
        : obj
    );

    if (socialPlatform && socialDesticationIds?.length) {
      await sendSocialMessage({
        broadcastId,
        destinationIds: socialDesticationIds,
        message,
      });
    }
  };

  const _clearChat = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    clearChat({ broadcastId, userId });
  };

  const _enableChat = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    enableChat({ broadcastId, userId });
  };
  const _disableChat = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    disableChat({ broadcastId, userId });
  };

  const toggleActiveStatus = async () => {
    if (isQnAEnabledRef.current) {
      _disableChat();
    } else {
      _enableChat();
    }
  };

  const _addToMainViewStreams = () => {
    if (!isInMainViewRef.current) {
      addToMainViewStreams({
        mode: streamModes.QNA,
        participantId,
        type: streamTypes.SHARE,
        qnaId: "public",
      });
    }
  };

  const _removeFromMainViewStreams = () => {
    if (isInMainViewRef.current) {
      removeFromMainViewStreams({
        mode: streamModes.QNA,
        participantId,
        type: streamTypes.SHARE,
        qnaId: "public",
      });
    }
  };

  const toggleMainViewStreams = () => {
    if (isInMainViewRef.current) {
      _removeFromMainViewStreams();
    } else {
      _addToMainViewStreams();
    }
  };

  return {
    sendMessage,
    isActive,
    toggleActiveStatus,
    qnaMessages,
    clearChat: _clearChat,
    enableChat: _enableChat,
    disableChat: _disableChat,
    toggleMainViewStreams,
    isCinemaLayoutActive,
    isInMainView,
  };
};

export default useQnA;
