import { momentostreamApiServerBaseUrl } from "../../../utils/constants";

const getAllBannerFolders = async ({
  userId,
}: {
  userId: string;
}): Promise<{ success: boolean; data?: bannerFoldersType }> => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/${userId}/banner-folder`;

    const res = await fetch(url);

    const json = await res.json();

    if (json.success) {
      return { success: true, data: json.data };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default getAllBannerFolders;
