import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import SidePanelTopBar from "./SidePanelTopBar";
import {
  MdClearAll,
  MdComment,
  MdCommentsDisabled,
  MdKeyboardArrowDown,
  MdMoreVert,
  MdScreenShare,
  MdSend,
  MdShare,
  MdStar,
  MdStarOutline,
  MdStream,
} from "react-icons/md";
import { Menu, MenuItem, MenuHeader } from "@szhsin/react-menu";
import useQnA from "../../hooks/appState/useQnA";
import useMainViewLayout from "../../hooks/appState/useMainViewLayout";
import Modal from "../../components/Modal";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import useClipboard from "react-use-clipboard";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { useAppContext } from "../../contexts/appContextDef";
import useWordcloud from "../../hooks/apps/wordcloud/useWordcloud";
import usePoll from "../../hooks/apps/polls/usePoll";
import useHighlightedChat from "../../hooks/appState/useHighlightedChat";
import Tooltip from "../../components/Tooltip";
import LinkedIn from "../../static/brand-icons/LinkedIn";
import Twitch from "../../static/brand-icons/Twitch";
import Facebook from "../../static/brand-icons/Facebook";
import Youtube from "../../static/brand-icons/Youtube";
import { momento_live_logo } from "../../static/images";
import Linkify from "linkify-react";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import VisibilitySensor from "react-visibility-sensor";
import Spinner from "../../components/Spinner";
import useBroadcastEgresses from "../../hooks/appState/useBroadcastEgresses";
import {
  appModes,
  mainViewLayouts,
  sidePanelModes,
} from "../../utils/constants";

const SenderInitialContainer = ({
  mainViewContainerWidth,
  initials,
  isSelf,
  streamingView,
  socialPlatformWithInitials,
  socialType,
}: {
  mainViewContainerWidth: number;
  initials: string;
  isSelf: boolean;
  streamingView: boolean;
  socialPlatformWithInitials: boolean;
  socialType?: string;
}) => {
  return (
    <div
      style={{ fontSize: streamingView ? mainViewContainerWidth * 0.012 : 12 }}
      className={`relative h-full ${
        isSelf
          ? "bg-custom-blue-100 bg-opacity-40 text-primary"
          : "bg-gray-200 bg-opacity-70 text-gray-900"
      } rounded-lg flex flex-1 items-center justify-center font-bold`}
    >
      {initials}
      {socialPlatformWithInitials ? (
        <div
          style={{
            right: -4,
            bottom: -4,
            height: 16,
            width: 16,
            backgroundColor:
              socialType === "youtube"
                ? "rgb(254,1,0)"
                : socialType === "facebook"
                ? "#1877f2"
                : socialType === "twitch"
                ? "#6441A4"
                : socialType === "linkedin"
                ? "#0A66C2"
                : "",
          }}
          className="absolute flex items-center justify-center rounded-sm"
        >
          {socialType === "youtube" ? (
            <Youtube
              style={{ height: 12, width: 12 }}
              className={"fill-white"}
            />
          ) : socialType === "facebook" ? (
            <Facebook
              style={{ height: 12, width: 12 }}
              className={"fill-white"}
            />
          ) : socialType === "twitch" ? (
            <Twitch
              style={{ height: 12, width: 12 }}
              className={"fill-white"}
            />
          ) : socialType === "linkedin" ? (
            <LinkedIn
              style={{ height: 12, width: 12 }}
              className={"fill-white"}
            />
          ) : (
            <React.Fragment />
          )}
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

const HighlightChatIcon = ({
  isOver,
  isHighlighted,
  _handleActivateHighlight,
  _handleDeactivateHighlight,
}: {
  isOver: boolean;
  isHighlighted: boolean;
  _handleActivateHighlight: () => void;
  _handleDeactivateHighlight: () => void;
}) => {
  return isHighlighted || isOver ? (
    <div>
      <button
        onClick={() => {
          if (isHighlighted) {
            _handleDeactivateHighlight();
          } else {
            _handleActivateHighlight();
          }
        }}
        className={`btn btn-xs ${
          isHighlighted ? "" : "btn-outline"
        } btn-primary m-0 p-1`}
      >
        {isHighlighted ? (
          <MdStar />
        ) : isOver ? (
          <MdStarOutline />
        ) : (
          <React.Fragment />
        )}
      </button>
    </div>
  ) : (
    <React.Fragment />
  );
};

const QNAMessageItem = ({
  id,
  text,
  timestamp,
  previousSameSender,
  isSelf,
  senderName,
  isNextSelfSender,
  initials,
  mainViewContainerWidth,
  streamingView,
  socialPlatform,
  momentoSent,
  index,
}: {
  id: string;
  text: string;
  timestamp: number;
  previousSameSender: boolean;
  isSelf: boolean;
  senderName: string;
  isNextSelfSender: boolean;
  initials: string;
  mainViewContainerWidth: number;
  streamingView: boolean;
  socialPlatform: string[];
  momentoSent: boolean;
  index: number;
}) => {
  const { appMode, setQnaLastSeenMessage, qnaLastSeenMessage } =
    useAppContext();

  const [isOver, setIsOver] = useState(false);
  const {
    activateHighlightedChat,
    deactivateHighlightedChat,
    highlightedChat,
  } = useHighlightedChat();

  const qnaLastSeenMessageRef = useRef(qnaLastSeenMessage);

  useEffect(() => {
    qnaLastSeenMessageRef.current = qnaLastSeenMessage;
  }, [qnaLastSeenMessage]);

  const isHighlighted = useMemo(
    () => highlightedChat.id === id,
    [id, highlightedChat]
  );

  const displayTime = useMemo(() => {
    const [hh, mm] = new Date(timestamp * 1000).toLocaleTimeString().split(":");

    return `${hh}:${mm}`;
  }, [timestamp]);

  const _handleActivateHighlight = () => {
    activateHighlightedChat({
      id,
      text,
      timestamp,
      senderName,
      initials,
      socialPlatform,
      momentoSent,
    });
  };

  const _handleDeactivateHighlight = () => {
    deactivateHighlightedChat();
  };

  const {
    socialPlatformWithInitials,
    socialType,
    socialPlatformWithMessage,
    socialPlatforms,
  } = useMemo(() => {
    if (socialPlatform) {
      if (socialPlatform.length) {
        if (socialPlatform.length === 1) {
          if (momentoSent) {
            return {
              socialPlatformWithInitials: false,
              socialPlatformWithMessage: true,
              socialPlatforms: socialPlatform,
            };
          } else {
            return {
              socialPlatformWithInitials: true,
              socialType: socialPlatform[0],
            };
          }
        } else {
          return {
            socialPlatformWithInitials: false,
            socialPlatformWithMessage: true,
            socialPlatforms: socialPlatform,
          };
        }
      } else {
        return { socialPlatformWithInitials: false };
      }
    } else {
      return { socialPlatformWithInitials: false };
    }
  }, [socialPlatform, momentoSent]);

  const seen = useMemo(
    () =>
      !qnaLastSeenMessage.index ? false : index <= qnaLastSeenMessage?.index,
    [qnaLastSeenMessage, index]
  );

  return (
    <VisibilitySensor
      active={!seen}
      onChange={(isVisible: boolean) => {
        if (isVisible) {
          const qnaLastSeenMessage = qnaLastSeenMessageRef.current;

          if (!qnaLastSeenMessage.index || index > qnaLastSeenMessage.index) {
            setQnaLastSeenMessage({ index, id });
          }
        }
      }}
    >
      <div
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        className={`flex w-full items-end ${
          previousSameSender ? "mt-1" : "mt-2"
        }`}
      >
        <div
          style={{ width: streamingView ? mainViewContainerWidth * 0.045 : 36 }}
          className="aspect-square flex items-center justify-center"
        >
          {isSelf ? (
            appMode === appModes.HOST ? (
              <HighlightChatIcon
                {...{
                  isOver,
                  isHighlighted,
                  _handleActivateHighlight,
                  _handleDeactivateHighlight,
                }}
              />
            ) : null
          ) : isNextSelfSender ? null : (
            <SenderInitialContainer
              {...{
                streamingView,
                mainViewContainerWidth,
                isSelf,
                initials,
                socialPlatformWithInitials,
                socialType,
              }}
            />
          )}
        </div>
        {/*  */}
        <div
          className={`flex flex-1 flex-col ${
            isSelf ? "items-end" : "items-start"
          }`}
        >
          {!previousSameSender ? (
            <div>
              <p
                style={{
                  fontSize: streamingView
                    ? mainViewContainerWidth * 0.015
                    : undefined,
                }}
                className={`${
                  streamingView ? "" : "text-xs"
                } px-3 mb-1 text-gray-500`}
              >
                {senderName}
              </p>
            </div>
          ) : null}
          <div
            className={`mx-1 rounded-xl ${
              isSelf ? "bg-custom-blue-50" : "bg-gray-200 bg-opacity-70"
            } p-2 flex flex-col`}
          >
            <span
              style={{
                maxWidth: "100%",
                fontSize: streamingView
                  ? mainViewContainerWidth * 0.0165
                  : undefined,
              }}
              className={`font-medium ${
                streamingView ? "" : "text-sm"
              } max-w-full break-all`}
            >
              <Linkify
                as={"p"}
                options={{
                  target: "_blank",
                  render: ({ attributes, content }) => {
                    const { href } = attributes;

                    return (
                      <a
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        className="text-custom-blue-900 hover:underline"
                      >
                        {content}
                      </a>
                    );
                  },
                }}
              >
                {text}
              </Linkify>
            </span>

            <p
              className={`mt-1 ${
                streamingView ? "" : "text-xs"
              } italic text-gray-500 flex ${
                isSelf ? "justify-end" : "justify-start"
              }`}
              style={{
                fontSize: streamingView
                  ? mainViewContainerWidth * 0.014
                  : undefined,
              }}
            >
              {displayTime}
            </p>

            {socialPlatformWithMessage ? (
              <div className="mt-1 flex gap-1">
                {momentoSent ? (
                  <div
                    style={{
                      height: 12,
                      width: 12,
                      backgroundColor: "gray",
                    }}
                    className="flex items-center justify-center rounded-sm"
                  >
                    <img
                      src={momento_live_logo}
                      className={
                        "h-full w-full aspect-square bg-white rounded-full"
                      }
                      alt="momento-logo"
                    />
                  </div>
                ) : (
                  <React.Fragment />
                )}

                {socialPlatforms.map((socialPlatform) => {
                  return (
                    <div
                      key={`chat-message-social-platform=${id}-${socialPlatform}`}
                      style={{
                        height: 12,
                        width: 12,
                        backgroundColor:
                          socialPlatform === "youtube"
                            ? "rgb(254,1,0)"
                            : socialPlatform === "facebook"
                            ? "#1877f2"
                            : socialPlatform === "twitch"
                            ? "#6441A4"
                            : socialPlatform === "linkedin"
                            ? "#0A66C2"
                            : "",
                      }}
                      className="flex items-center justify-center rounded-sm"
                    >
                      {socialPlatform === "youtube" ? (
                        <Youtube
                          style={{ height: 8, width: 8 }}
                          className={"fill-white"}
                        />
                      ) : socialPlatform === "facebook" ? (
                        <Facebook
                          style={{ height: 8, width: 8 }}
                          className={"fill-white"}
                        />
                      ) : socialPlatform === "twitch" ? (
                        <Twitch
                          style={{ height: 8, width: 8 }}
                          className={"fill-white"}
                        />
                      ) : socialPlatform === "linkedin" ? (
                        <LinkedIn
                          style={{ height: 8, width: 8 }}
                          className={"fill-white"}
                        />
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
        {/*  */}
        <div
          style={{ width: streamingView ? mainViewContainerWidth * 0.045 : 36 }}
          className="aspect-square flex items-center justify-center"
        >
          {isSelf ? (
            isNextSelfSender ? null : (
              <SenderInitialContainer
                {...{
                  streamingView,
                  mainViewContainerWidth,
                  isSelf,
                  initials,
                  socialPlatformWithInitials,
                  socialType,
                }}
              />
            )
          ) : appMode === appModes.HOST ? (
            <HighlightChatIcon
              {...{
                isOver,
                isHighlighted,
                _handleActivateHighlight,
                _handleDeactivateHighlight,
              }}
            />
          ) : null}
        </div>
      </div>
    </VisibilitySensor>
  );
};

const QNAMessageSend = () => {
  const [textMessage, setTextMessage] = useState("");

  const { sendMessage } = useQnA();

  const { destinations } = useAppConfigContext();

  const { selectedLiveStreamDestinationsIds } = useAppContext();

  const { isLivestreamingOriginalAudio: isLiveStreaming } =
    useBroadcastEgresses();

  const availableLiveStreamDestinations = useMemo(() => {
    return destinations.map(({ id, data, name, type }) => ({
      id,
      nickName: name,
      streamKey: data.streamKey,
      url: data.streamUrl,
      type,
    }));
  }, [destinations]);

  const { selectedLiveStreamDestinations } = useMemo(() => {
    const selectedAvailableLiveStreamDestinations =
      availableLiveStreamDestinations.map(
        ({ id, nickName, url, streamKey, type }) => ({
          id,
          nickName,
          url,
          streamKey,
          isSelected:
            selectedLiveStreamDestinationsIds.findIndex((_id) => _id === id) !==
            -1,
          type,
        })
      );

    const selectedLiveStreamDestinations =
      selectedAvailableLiveStreamDestinations.filter(
        ({ isSelected }) => isSelected
      );

    return { selectedLiveStreamDestinations };
  }, [selectedLiveStreamDestinationsIds, availableLiveStreamDestinations]);

  const {
    availableSocialPlatformsForChat,
  }: {
    availableSocialPlatformsForChat: {
      id?: number;
      platform: string;
      nickName: string;
    }[];
  } = useMemo(() => {
    const availableSocialPlatformsForChat: {
      id: number;
      platform: string;
      nickName: string;
    }[] = [];

    selectedLiveStreamDestinations.forEach((destination) => {
      const { type, id, nickName } = destination;

      const socialPlatformTypes = [
        "youtube",
        "twitch",
        // "facebook",
        "linkedin",
      ];

      const socialFound = socialPlatformTypes.find((_type) => {
        return type.includes(_type);
      });

      if (socialFound) {
        availableSocialPlatformsForChat.push({
          id,
          platform: socialFound,
          nickName,
        });
      }
    });

    return {
      availableSocialPlatformsForChat: [
        { platform: "momento", nickName: "Momento" },
        ...availableSocialPlatformsForChat,
      ],
    };
  }, [selectedLiveStreamDestinations]);

  const [
    selectedSocialPlatformsForChatIndex,
    setSelectedSocialPlatformsForChatIndex,
  ] = useState(availableSocialPlatformsForChat.map((_, i) => i));

  const { selectedSocialPlatformsForChat } = useMemo(
    () => ({
      selectedSocialPlatformsForChat: selectedSocialPlatformsForChatIndex.map(
        (i) => availableSocialPlatformsForChat[i]
      ),
    }),
    [selectedSocialPlatformsForChatIndex, availableSocialPlatformsForChat]
  );

  const isLiveStreamingRef = useRef(isLiveStreaming);
  const textMessageRef = useRef(textMessage);
  const selectedLiveStreamDestinationsRef = useRef(
    selectedLiveStreamDestinations
  );
  const selectedSocialPlatformsForChatRef = useRef(
    selectedSocialPlatformsForChat
  );

  useEffect(() => {
    isLiveStreamingRef.current = isLiveStreaming;
  }, [isLiveStreaming]);
  useEffect(() => {
    textMessageRef.current = textMessage;
  }, [textMessage]);
  useEffect(() => {
    selectedLiveStreamDestinationsRef.current = selectedLiveStreamDestinations;
  }, [selectedLiveStreamDestinations]);
  useEffect(() => {
    selectedSocialPlatformsForChatRef.current = selectedSocialPlatformsForChat;
  }, [selectedSocialPlatformsForChat]);

  const handleSend = async () => {
    const textMessage = textMessageRef.current;

    if (textMessage.length) {
      setTextMessage("");

      if (isLiveStreamingRef.current) {
        let momentoSent = false;
        const socialPlatform: string[] = [];
        const socialDesticationIds: number[] = [];

        selectedSocialPlatformsForChatRef.current.forEach(
          ({ id, platform }) => {
            if (platform === "momento") {
              momentoSent = true;
            } else if (id) {
              socialPlatform.push(platform);
              socialDesticationIds.push(id);
            }
          }
        );

        await sendMessage({
          message: textMessage,
          momentoSent,
          socialPlatform,
          socialDesticationIds,
        });
      } else {
        sendMessage({ message: textMessage });
      }
    }
  };

  useEffect(() => {
    setSelectedSocialPlatformsForChatIndex(
      availableSocialPlatformsForChat.map((_, i) => i)
    );
  }, [availableSocialPlatformsForChat]);

  const { uniqueSelectedSocialPlatformsForChat } = useMemo(() => {
    const uniqueSelectedSocialPlatformsForChat: {
      id?: number;
      platform: string;
      nickName: string;
    }[] = [];

    selectedSocialPlatformsForChat.forEach((p) => {
      if (p) {
        const { platform } = p;
        const found = uniqueSelectedSocialPlatformsForChat.find(
          ({ platform: _platform }) => platform === _platform
        );

        if (!found) {
          uniqueSelectedSocialPlatformsForChat.push(p);
        }
      }
    });

    return { uniqueSelectedSocialPlatformsForChat };
  }, [selectedSocialPlatformsForChat]);

  //
  //
  const container3Ref = createRef<HTMLSpanElement>();
  const [container3Width, setContainer3Width] = useState(0);

  const container3WidthRef = useRef();

  useEffect(() => {
    if (container3Ref.current) {
      const { current } = container3Ref;

      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;

      container3WidthRef.current !== width && setContainer3Width(width);
    } else {
      if (container3WidthRef.current) {
        setContainer3Width(0);
      }
    }
  }, [container3Ref]);

  //
  //

  const container4Ref = createRef<HTMLSpanElement>();
  const [container4Width, setContainer4Width] = useState(0);

  const container4WidthRef = useRef();

  useEffect(() => {
    if (container4Ref.current) {
      const { current } = container4Ref;

      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;

      container4WidthRef.current !== width && setContainer4Width(width);
    }
  }, [container4Ref]);

  return (
    <div className="flex w-full items-center border-t border-gray-100 p-1 gap-2">
      {isLiveStreaming ? (
        <span
          className="select-none"
          ref={container3Ref as React.LegacyRef<HTMLSpanElement>}
        >
          <React.Fragment>
            <Menu
              menuButton={
                <div className="flex items-center bg-gray-200 hover:bg-gray-300 rounded-sm cursor-pointer">
                  <div
                    className={`${
                      uniqueSelectedSocialPlatformsForChat.length > 4
                        ? "grid grid-cols-3"
                        : uniqueSelectedSocialPlatformsForChat.length > 1
                        ? "grid grid-cols-2"
                        : "grid grid-cols-1"
                    } h-10 w-10 rounded-sm overflow-hidden items-center justify-center`}
                  >
                    {uniqueSelectedSocialPlatformsForChat.map(
                      ({ platform }, index) => {
                        return (
                          <div
                            key={`qna-chat-rtmp-selected-platform-${platform}-${index}`}
                            style={{
                              backgroundColor:
                                platform === "youtube"
                                  ? "rgb(254,1,0)"
                                  : platform === "facebook"
                                  ? "#1877f2"
                                  : platform === "twitch"
                                  ? "#6441A4"
                                  : platform === "linkedin"
                                  ? "#0A66C2"
                                  : "gray",
                            }}
                            className="flex flex-1 items-center justify-center p-1 aspect-square"
                          >
                            {platform === "momento" ? (
                              <img
                                src={momento_live_logo}
                                className={
                                  "h-full w-full aspect-square bg-white rounded-full"
                                }
                                alt="momento-logo"
                              />
                            ) : platform === "youtube" ? (
                              <Youtube
                                className={
                                  "fill-white h-full w-full aspect-square"
                                }
                              />
                            ) : platform === "facebook" ? (
                              <Facebook
                                className={
                                  "fill-white h-full w-full aspect-square"
                                }
                              />
                            ) : platform === "twitch" ? (
                              <Twitch
                                className={
                                  "fill-white h-full w-full aspect-square"
                                }
                              />
                            ) : platform === "linkedin" ? (
                              <LinkedIn
                                className={
                                  "fill-white h-full w-full aspect-square"
                                }
                              />
                            ) : (
                              <React.Fragment />
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div>
                    <MdKeyboardArrowDown size={16} />
                  </div>
                </div>
              }
            >
              <MenuHeader>Select Broadcasts</MenuHeader>

              {availableSocialPlatformsForChat.map(
                ({ platform, nickName }, i) => {
                  return (
                    <MenuItem
                      key={`qna-chat-rtmp-available-platform-${platform}-${i}`}
                      disabled={
                        selectedSocialPlatformsForChatIndex.includes(i) &&
                        selectedSocialPlatformsForChatIndex.length === 1
                      }
                      type="checkbox"
                      checked={selectedSocialPlatformsForChatIndex.includes(i)}
                      onClick={(e) => {
                        e.stopPropagation = true;
                        e.keepOpen = true;

                        const _selectedSocialPlatformsForChatIndex = [
                          ...selectedSocialPlatformsForChatIndex,
                        ];

                        const index =
                          selectedSocialPlatformsForChatIndex.findIndex(
                            (_i) => _i === i
                          );

                        if (index === -1) {
                          _selectedSocialPlatformsForChatIndex.push(i);
                        } else {
                          if (_selectedSocialPlatformsForChatIndex.length > 1) {
                            _selectedSocialPlatformsForChatIndex.splice(
                              index,
                              1
                            );
                          }
                        }

                        setSelectedSocialPlatformsForChatIndex(
                          _selectedSocialPlatformsForChatIndex
                        );
                      }}
                    >
                      <div className="flex gap-2 items-center">
                        <div
                          className="items-center justify-center flex h-6 w-6 rounded-full"
                          style={{
                            backgroundColor:
                              platform === "momento"
                                ? "gray"
                                : platform === "youtube"
                                ? "rgb(254,1,0)"
                                : platform === "facebook"
                                ? "#1877f2"
                                : platform === "twitch"
                                ? "#6441A4"
                                : platform === "linkedin"
                                ? "#0A66C2"
                                : "",
                          }}
                        >
                          {platform === "momento" ? (
                            <img
                              src={momento_live_logo}
                              className={"h-4 w-4 bg-white rounded-full"}
                              alt="momento-logo"
                            />
                          ) : platform === "youtube" ? (
                            <Youtube className={"fill-white h-4 w-4"} />
                          ) : platform === "facebook" ? (
                            <Facebook className={"fill-white h-4 w-4"} />
                          ) : platform === "twitch" ? (
                            <Twitch className={"fill-white h-4 w-4"} />
                          ) : platform === "linkedin" ? (
                            <LinkedIn className={"fill-white h-4 w-4"} />
                          ) : (
                            <React.Fragment />
                          )}
                        </div>
                        <div style={{ maxWidth: 120 }}>
                          <p
                            style={{ textTransform: "capitalize" }}
                            className="text-sm"
                          >
                            {nickName}
                          </p>
                        </div>
                      </div>
                    </MenuItem>
                  );
                }
              )}
            </Menu>
          </React.Fragment>
        </span>
      ) : (
        <React.Fragment />
      )}
      <div
        style={{
          width: `calc(100% - ${
            (isLiveStreaming ? container3Width : 0) + container4Width
          }px)`,
        }}
      >
        <div className="flex flex-1">
          <input
            className="input input-bordered flex flex-1 w-full"
            value={textMessage}
            onChange={(e) => {
              setTextMessage(e.target.value);
            }}
            placeholder="Type a message"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSend();
              }
            }}
          />
        </div>

        {/* <InputEmoji
          value={textMessage}
          onChange={setTextMessage}
          onEnter={handleSend}
          placeholder="Type a message"
        /> */}
      </div>
      <span ref={container4Ref as React.LegacyRef<HTMLSpanElement>}>
        <button
          disabled={textMessage.length === 0}
          className="btn btn-primary text-white btn-sm"
          onClick={() => {
            handleSend();
          }}
        >
          <MdSend />
        </button>
      </span>
    </div>
  );
};

const QNAChatList = ({
  containerHeight: _containerHeight,
  containerWidth: _containerWidth,
  streamingView,
  relativeWidth,
  mainViewContainerWidth,
  alwaysScrollToBottom,
}: {
  containerHeight: number;
  containerWidth: number;
  streamingView: boolean;
  relativeWidth: number;
  mainViewContainerWidth: number;
  alwaysScrollToBottom?: boolean;
}) => {
  const { appMode, qnaMessagesHasMore, isQnaMessagesLoadingMore } =
    useAppContext();

  const { qnaMessages, isActive } = useQnA();

  const containerRef = createRef<HTMLDivElement>();
  const [containerHeight, setContainerHeight] = useState(0);

  const containerHeightRef = useRef<number | null>();
  const scrolledToBottom = useRef(false);
  const qnaMessagesRef = useRef(qnaMessages);
  const lastMessageIdBeforeLoadedMoreRef = useRef<string | undefined>();
  const canLoadMoreRef = useRef(false);
  const _containerHeightRef = useRef(_containerHeight);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);
  useEffect(() => {
    _containerHeightRef.current = _containerHeight;
  }, [_containerHeight]);
  useEffect(() => {
    qnaMessagesRef.current = qnaMessages;

    if (
      lastMessageIdBeforeLoadedMoreRef.current &&
      qnaMessages[0] &&
      lastMessageIdBeforeLoadedMoreRef.current !== qnaMessages[0].id
    ) {
      const lastMessage = document.getElementById(
        `q&a-chat-list-item-${lastMessageIdBeforeLoadedMoreRef.current}`
      );

      if (lastMessage) {
        setTimeout(() => {
          lastMessage.scrollIntoView({});
          lastMessageIdBeforeLoadedMoreRef.current = undefined;
        }, 100);
      }
    }
  }, [qnaMessages]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;

      containerHeightRef.current !== height && setContainerHeight(height);
    }
  }, [containerRef]);

  const chatlistRef = useRef<HTMLDivElement>();

  const { sendMessageContainerVisible, chatDisabledByHostMessageVisible } =
    useMemo(() => {
      const sendMessageContainerVisible = streamingView
        ? false
        : isActive
        ? true
        : appMode === appModes.HOST;

      const chatDisabledByHostMessageVisible = isActive
        ? false
        : appMode === appModes.SPEAKER;

      return { sendMessageContainerVisible, chatDisabledByHostMessageVisible };
    }, [isActive, appMode, streamingView]);

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && canLoadMoreRef.current) {
          lastMessageIdBeforeLoadedMoreRef.current =
            qnaMessagesRef.current[0]?.id;

          appEventEmitter.emit(appEventEmitterEvents.QNA_LOAD_PREV_CHAT);
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  const _handleScrollToBottom = () => {
    if (chatlistRef.current) {
      chatlistRef.current.scrollTop = chatlistRef.current.scrollHeight;
    }
  };

  const _scrollToBottomOnInit = async () => {
    if (!scrolledToBottom.current) {
      scrolledToBottom.current = true;

      setTimeout(() => {
        _handleScrollToBottom();
      }, 500);
    }
  };

  const _handleEnableLoadPreviousChatAfter5Seconds = () => {
    setTimeout(() => {
      canLoadMoreRef.current = true;
    }, 5000);
  };

  useEffect(() => {
    _handleEnableLoadPreviousChatAfter5Seconds();
  }, []);

  const _handleOnQNANewMessage = () => {
    if (chatlistRef.current) {
      const containerHeight =
        _containerHeightRef.current - (containerHeightRef.current || 0);

      const diff =
        chatlistRef.current.scrollHeight - chatlistRef.current.scrollTop;

      if (containerHeight - diff >= -(120 * containerHeight) / 400) {
        setTimeout(() => {
          _handleScrollToBottom();
        }, 50);
      }
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.TOGGLED_LATERAL_SIDEBAR,
      _scrollToBottomOnInit
    );

    appEventEmitter.on(
      appEventEmitterEvents.QNA_NEW_MESSAGE,
      _handleOnQNANewMessage
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.TOGGLED_LATERAL_SIDEBAR,
        _scrollToBottomOnInit
      );

      appEventEmitter.off(
        appEventEmitterEvents.QNA_NEW_MESSAGE,
        _handleOnQNANewMessage
      );
    };
  }, []);

  useEffect(() => {
    if (alwaysScrollToBottom) {
      setTimeout(() => {
        _handleScrollToBottom();
      }, 5000);
    }
  }, [alwaysScrollToBottom]);

  return (
    <div
      style={{ height: _containerHeight, width: _containerWidth }}
      className="overflow-hidden"
    >
      <div
        ref={chatlistRef as React.LegacyRef<HTMLDivElement>}
        style={{
          height: _containerHeight - containerHeight - 8,
          width: _containerWidth - 8,
          marginLeft: 2,
          marginRight: 2,
          marginTop: 4,
          marginBottom: 4,
          paddingLeft: 2,
          paddingRight: 2,
        }}
        className={`flex flex-1 flex-col ${
          streamingView || alwaysScrollToBottom
            ? "overflow-hidden"
            : "overflow-y-scroll"
        }`}
      >
        {qnaMessagesHasMore ? (
          <div ref={observerTarget}></div>
        ) : (
          <React.Fragment />
        )}

        {isQnaMessagesLoadingMore || qnaMessagesHasMore ? (
          <div className="flex items-center justify-center px-3 py-6">
            <Spinner h={"h-8"} w={"w-8"} />
          </div>
        ) : (
          <React.Fragment />
        )}

        {qnaMessages.map((message, i) => (
          <span
            {...{
              key: `q&a-chat-list-item-${message.id}`,
              id: `q&a-chat-list-item-${message.id}`,
            }}
          >
            <QNAMessageItem
              {...{
                ...message,
                relativeWidth,
                mainViewContainerWidth,
                streamingView,
                index: i,
              }}
            />
          </span>
        ))}
      </div>

      <div
        ref={containerRef as React.LegacyRef<HTMLDivElement>}
        className="flex items-center"
      >
        {sendMessageContainerVisible ? (
          <QNAMessageSend />
        ) : chatDisabledByHostMessageVisible ? (
          <div className="bg-amber-100 rounded p-2 w-full">
            <p className="text-center">Host has disabled the Q&A</p>
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  );
};

export const QNAPanelControlsMenu = () => {
  const { chatUrl } = useAppConfigContext();
  const [shareModalEnabled, setShareModalEnabled] = useState(false);

  const embedUrl = `<div style="width:100%;height:Opx;position:relative;padding-bottom:56.25%;"><iframe src="${chatUrl}" width="100%" height="100%" frameborder="O" allowfullscren style="width:100%;height:100%;position:absolute;left:Opx;top:0px;overflow:hidden;"></iframe></div>`;

  const [isCopied, setCopied] = useClipboard(chatUrl, {
    successDuration: 2000,
  });
  const [isCopiedEmbed, setCopiedEmbed] = useClipboard(embedUrl, {
    successDuration: 2000,
  });

  const {
    clearChat,
    isActive,
    toggleActiveStatus,
    toggleMainViewStreams,
    isInMainView,
  } = useQnA();

  const { conferenceAutoMainVideoLayoutEnabled } = useAppContext();

  const { changeMainViewLayout, mainViewLayoutActive } = useMainViewLayout();

  const isCinemaLayoutActive = useMemo(
    () => mainViewLayoutActive === mainViewLayouts.CINEMA,
    [mainViewLayoutActive]
  );
  const chatUrlRef = useRef<HTMLInputElement>();
  const embedUrlRef = useRef<HTMLTextAreaElement>();
  const conferenceAutoMainVideoLayoutEnabledRef = useRef(
    conferenceAutoMainVideoLayoutEnabled
  );

  const _handleEnterFullscreenLayout = () => {
    const conferenceAutoMainVideoLayoutEnabled =
      conferenceAutoMainVideoLayoutEnabledRef.current;

    changeMainViewLayout({
      layout: mainViewLayouts.CINEMA,
      conferenceAutoMainVideoLayoutEnabled,
    });
  };
  const _handleExitFullscreenLayout = () => {
    const conferenceAutoMainVideoLayoutEnabled =
      conferenceAutoMainVideoLayoutEnabledRef.current;

    changeMainViewLayout({
      layout: mainViewLayouts.SCREEN,
      conferenceAutoMainVideoLayoutEnabled,
    });
  };

  useEffect(() => {
    if (isCopied) {
      chatUrlRef.current?.select();
    }
  }, [isCopied]);

  useEffect(() => {
    if (isCopiedEmbed) {
      embedUrlRef.current?.select();
    }
  }, [isCopiedEmbed]);

  useEffect(() => {
    conferenceAutoMainVideoLayoutEnabledRef.current =
      conferenceAutoMainVideoLayoutEnabled;
  }, [conferenceAutoMainVideoLayoutEnabled]);

  return (
    <Menu
      menuButton={
        <span>
          <button
            className={`btn btn-primary text-white btn-square btn-xs rounded-md`}
          >
            <div>
              <MdMoreVert size={16} />
            </div>
          </button>
        </span>
      }
      transition
    >
      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={() => {
          toggleActiveStatus();
        }}
      >
        <div className="flex items-center justify-center gap-1">
          {isActive ? (
            <React.Fragment>
              <MdCommentsDisabled />
              Deactivate Q&A
            </React.Fragment>
          ) : (
            <React.Fragment>
              <MdComment />
              Activate Q&A
            </React.Fragment>
          )}
        </div>
      </MenuItem>

      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={() => {
          clearChat();
        }}
      >
        <div className="flex items-center justify-center gap-1">
          <MdClearAll />
          Clear Chat
        </div>
      </MenuItem>

      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={() => {
          toggleMainViewStreams();
        }}
      >
        <div className="flex items-center justify-center gap-1">
          <MdStream />
          {isInMainView ? "Remove from stream" : "Add to stream"}
        </div>
      </MenuItem>

      {isInMainView ? (
        isCinemaLayoutActive ? (
          <MenuItem
            className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
            onClick={_handleExitFullscreenLayout}
          >
            <div className="flex items-center justify-center gap-1">
              <MdScreenShare />
              Exit fullscreen layout
            </div>
          </MenuItem>
        ) : (
          <MenuItem
            className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
            onClick={_handleEnterFullscreenLayout}
          >
            <div className="flex items-center justify-center gap-1">
              <MdScreenShare />
              Fullscreen layout
            </div>
          </MenuItem>
        )
      ) : (
        <React.Fragment />
      )}

      <MenuItem
        className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
        onClick={() => {
          setShareModalEnabled((s) => !s);
        }}
      >
        <div className="flex items-center justify-center gap-1">
          <MdShare />
          Share
        </div>
      </MenuItem>

      {/* <MenuItem
          className={"px-3 py-2 hover:bg-custom-blue-50 text-primary"}
          onClick={() => {}}
        >
          <div className="flex items-center justify-center gap-1">Download</div>
        </MenuItem> */}

      <Modal
        isOpen={shareModalEnabled}
        renderOnlyBody
        showCloseIcon
        onClose={() => {
          setShareModalEnabled(false);
        }}
        title={"Share with viewers"}
        renderBody={() => {
          return (
            <Tabs>
              <TabList>
                <Tab>Link</Tab>
                <Tab>Embed</Tab>
              </TabList>

              <TabPanel>
                <div className="w-full flex items-center justify-center">
                  <input
                    ref={chatUrlRef as React.LegacyRef<HTMLInputElement>}
                    value={chatUrl}
                    className="flex flex-1 input input-bordered input-primary"
                  />
                  <div className="ml-3">
                    <button
                      className="btn btn-primary text-white normal-case"
                      onClick={setCopied}
                    >
                      {isCopied ? "Copied!" : "Copy"}
                    </button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="w-full flex flex-col items-center justify-center w-full">
                  <textarea
                    rows={4}
                    ref={embedUrlRef as React.LegacyRef<HTMLTextAreaElement>}
                    value={embedUrl}
                    className="flex flex-1 input input-bordered input-primary w-full"
                  />
                  <div className="mt-2 w-full">
                    <button
                      className="btn btn-primary text-white normal-case w-full"
                      onClick={setCopiedEmbed}
                    >
                      {isCopiedEmbed ? "Copied!" : "Copy"}
                    </button>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          );
        }}
      />
    </Menu>
  );
};

const ActiveQNAPoll = ({
  id,
  answerSubmitted,
}: {
  id: number;
  answerSubmitted: () => void;
}) => {
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
  const { answerPoll, question, options } = usePoll({ id: `${id}` });

  return (
    <div className="p-1 mt-2">
      <p className="font-medium text-xl">{question}</p>

      <div className="mt-2">
        {options.map((option) => {
          const isSelected = `${option.id}` === `${selectedOptionId}`;

          return (
            <div
              key={`qna-panel-active-poll-option-${option.id}`}
              onClick={() => {
                if (isSelected) {
                  setSelectedOptionId(null);
                } else {
                  setSelectedOptionId(`${option.id}`);
                }
              }}
              className={`${
                isSelected ? "bg-custom-blue-300 text-white" : "bg-white"
              } p-2 mt-1 rounded cursor-pointer font-medium`}
            >
              <p>{option.option}</p>
            </div>
          );
        })}
      </div>

      <div className="mt-3">
        <button
          disabled={selectedOptionId === null}
          type="button"
          className="w-full btn btn-primary text-white normal-case btn-outline btn-sm"
          onClick={async () => {
            if (selectedOptionId !== null) {
              await answerPoll({ optionId: selectedOptionId });

              setSelectedOptionId(null);

              answerSubmitted();
            }
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const ActiveQNAWordcloud = ({
  id,
  answerSubmitted,
}: {
  id: number;
  answerSubmitted: () => void;
}) => {
  const [answer, setAnswer] = useState<string | undefined>(undefined);
  const { answerWordcloud, question } = useWordcloud({ id: `${id}` });

  return (
    <div className="p-1 mt-2">
      <p className="font-medium text-xl">{question}</p>

      <div className="mt-2">
        <input
          onChange={(e) => {
            setAnswer(String(e.target.value).toLowerCase().trim());
          }}
          value={answer}
          placeholder="submit answer..."
          className="input input-primary input-outline w-full"
        />
      </div>
      <div className="mt-3">
        <button
          disabled={!answer}
          type="button"
          className="w-full btn btn-primary text-white normal-case btn-outline btn-sm"
          onClick={async () => {
            if (answer) {
              await answerWordcloud({ answer });

              setAnswer(undefined);

              answerSubmitted();
            }
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const ActiveQNAApps = () => {
  const { polls, wordclouds, unseenActivatedApps, setUnseenActivatedApps } =
    useAppContext();

  const openActiveAppTabIntervalRef = useRef<null | ReturnType<
    typeof setInterval
  >>(null);

  const {
    activePoll,
    activeWordcloud,
    isPollActive,
    isWordcloudActive,
    isPollAnswerSubmitted,
    isWordcloudAnswerSubmitted,
  } = useMemo(() => {
    const activePoll = polls.find((poll) => `${poll?.status}` === "1");
    const activeWordcloud = wordclouds.find(
      (wordcloud) => `${wordcloud?.status}` === "1"
    );

    const isPollActive = !!activePoll;
    const isWordcloudActive = !!activeWordcloud;

    const isPollAnswerSubmitted = isPollActive
      ? !!activePoll?.selfSubmittedOptionId
      : false;
    const isWordcloudAnswerSubmitted = isWordcloudActive
      ? !!activeWordcloud?.selfSubmittedAnswer
      : false;

    return {
      activePoll,
      activeWordcloud,
      isPollActive,
      isWordcloudActive,
      isPollAnswerSubmitted,
      isWordcloudAnswerSubmitted,
    };
  }, [polls, wordclouds]);

  const shouldRenderPollApp = useMemo(
    () => isPollActive && !isPollAnswerSubmitted,
    [isPollActive, isPollAnswerSubmitted]
  );

  const shouldRenderWhiteboardApp = useMemo(
    () => isWordcloudActive && !isWordcloudAnswerSubmitted,
    [isWordcloudActive, isWordcloudAnswerSubmitted]
  );

  const [activeAppTabMode, setActiveAppTabMode] = useState(
    shouldRenderPollApp
      ? "poll"
      : shouldRenderWhiteboardApp
      ? "wordcloud"
      : null
  );

  const activeAppTabModeRef = useRef(activeAppTabMode);
  const shouldRenderPollAppRef = useRef(shouldRenderPollApp);
  const shouldRenderWhiteboardAppRef = useRef(shouldRenderWhiteboardApp);

  useEffect(() => {
    activeAppTabModeRef.current = activeAppTabMode;
  }, [activeAppTabMode]);

  useEffect(() => {
    shouldRenderPollAppRef.current = shouldRenderPollApp;
  }, [shouldRenderPollApp]);
  useEffect(() => {
    shouldRenderWhiteboardAppRef.current = shouldRenderWhiteboardApp;
  }, [shouldRenderWhiteboardApp]);

  const { pollUnseen, wordcloudUnseen } = useMemo(
    () => ({
      pollUnseen: unseenActivatedApps.find(({ type }) => type === "poll"),
      wordcloudUnseen: unseenActivatedApps.find(
        ({ type }) => type === "wordcloud"
      ),
    }),
    [unseenActivatedApps]
  );

  const pollUnseenRef = useRef(pollUnseen);
  const wordcloudUnseenRef = useRef(wordcloudUnseen);

  useEffect(() => {
    pollUnseenRef.current = pollUnseen;
  }, [pollUnseen]);
  useEffect(() => {
    wordcloudUnseenRef.current = wordcloudUnseen;
  }, [wordcloudUnseen]);

  const _handleToggledLateralSidebar = ({
    mode,
  }: {
    mode: sidePanelModeType;
  }) => {
    if (mode === sidePanelModes.QNA) {
      const activeAppTabMode = activeAppTabModeRef.current;
      const shouldRenderPollApp = shouldRenderPollAppRef.current;
      const shouldRenderWhiteboardApp = shouldRenderWhiteboardAppRef.current;
      const pollUnseen = pollUnseenRef.current;
      const wordcloudUnseen = wordcloudUnseenRef.current;

      if (shouldRenderPollApp || shouldRenderWhiteboardApp) {
        if (
          !activeAppTabMode ||
          (!shouldRenderPollApp && activeAppTabMode === "poll") ||
          (!shouldRenderWhiteboardApp && activeAppTabMode === "wordcloud")
        ) {
          setActiveAppTabMode(
            shouldRenderPollApp
              ? "poll"
              : shouldRenderWhiteboardApp
              ? "wordcloud"
              : null
          );

          if (shouldRenderPollApp && pollUnseen) {
            setUnseenActivatedApps((s) =>
              [...s].filter(({ type }) => type !== "poll")
            );
          } else if (shouldRenderWhiteboardApp && wordcloudUnseen) {
            setUnseenActivatedApps((s) =>
              [...s].filter(({ type }) => type !== "wordcloud")
            );
          }
        } else {
          if (activeAppTabMode === "poll" && pollUnseen) {
            setUnseenActivatedApps((s) =>
              [...s].filter(({ type }) => type !== "poll")
            );
          } else if (activeAppTabMode === "wordcloud" && wordcloudUnseen) {
            setUnseenActivatedApps((s) =>
              [...s].filter(({ type }) => type !== "wordcloud")
            );
          }
        }
      }
    }
  };

  const openActiveAppTabInterval = () => {
    openActiveAppTabIntervalRef.current = setInterval(() => {
      _handleToggledLateralSidebar({ mode: sidePanelModes.QNA });
    }, 5000);
  };

  useEffect(() => {
    openActiveAppTabInterval();

    appEventEmitter.on(
      appEventEmitterEvents.TOGGLED_LATERAL_SIDEBAR,
      _handleToggledLateralSidebar
    );

    return () => {
      if (openActiveAppTabIntervalRef.current) {
        clearInterval(openActiveAppTabIntervalRef.current);
      }

      appEventEmitter.off(
        appEventEmitterEvents.TOGGLED_LATERAL_SIDEBAR,
        _handleToggledLateralSidebar
      );
    };
  }, []);

  return (
    <React.Fragment>
      {shouldRenderPollApp || shouldRenderWhiteboardApp ? (
        <div className="bg-custom-blue-50 p-1 rounded-md mt-2">
          <div className="flex gap-2 mt-3 border-b-2 border-custom-blue-100">
            {shouldRenderPollApp ? (
              <div
                className={`relative px-3 py-2 cursor-pointer rounded-t-md font-medium ${
                  activeAppTabMode === "poll"
                    ? "bg-primary text-white"
                    : "bg-custom-blue-100 text-primary"
                }`}
                onClick={() => {
                  setActiveAppTabMode("poll");

                  if (pollUnseen) {
                    setUnseenActivatedApps((s) =>
                      [...s].filter(({ type }) => type !== "poll")
                    );
                  }
                }}
              >
                <p>Poll</p>

                {pollUnseen ? (
                  <div className="animate-ping absolute top-0.5 left-0.5 h-2 w-2 bg-orange-400 text-white font-bold text-sm rounded-full flex items-center justify-center"></div>
                ) : (
                  <React.Fragment />
                )}
              </div>
            ) : (
              <React.Fragment />
            )}

            {shouldRenderWhiteboardApp ? (
              <div
                className={`relative px-3 py-2 cursor-pointer rounded-t-md font-medium ${
                  activeAppTabMode === "wordcloud"
                    ? "bg-primary text-white"
                    : "bg-custom-blue-100 text-primary"
                }`}
                onClick={() => {
                  setActiveAppTabMode("wordcloud");

                  if (wordcloudUnseen) {
                    setUnseenActivatedApps((s) =>
                      [...s].filter(({ type }) => type !== "wordcloud")
                    );
                  }
                }}
              >
                <p>Wordcloud</p>

                {wordcloudUnseen ? (
                  <div className="animate-ping absolute top-0.5 left-0.5 h-2 w-2 bg-orange-400 text-white font-bold text-sm rounded-full flex items-center justify-center"></div>
                ) : (
                  <React.Fragment />
                )}
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>

          <div>
            {activeAppTabMode === "poll" && activePoll?.id ? (
              <ActiveQNAPoll
                id={activePoll?.id}
                answerSubmitted={() => {
                  setTimeout(() => {
                    _handleToggledLateralSidebar({ mode: sidePanelModes.QNA });
                  }, 300);
                }}
              />
            ) : activeAppTabMode === "wordcloud" && activeWordcloud?.id ? (
              <ActiveQNAWordcloud
                id={activeWordcloud?.id}
                answerSubmitted={() => {
                  setTimeout(() => {
                    _handleToggledLateralSidebar({ mode: sidePanelModes.QNA });
                  }, 300);
                }}
              />
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const QNATags = () => {
  const { isActive, isInMainView } = useQnA();

  const { highlightedChat, deactivateHighlightedChat } = useHighlightedChat();

  return (
    <div className="flex gap-2 mt-1 items-center">
      <div
        className={`badge ${
          isActive ? "badge-success text-white" : "badge text-gray-300"
        } badge-outline text-xs font-bold`}
      >
        ACTIVE
      </div>

      <div
        className={`badge ${
          isInMainView ? "badge-success text-white" : "text-gray-300"
        } badge-outline text-xs font-bold`}
      >
        STREAMING
      </div>

      {highlightedChat.enabled ? (
        <Tooltip title={highlightedChat.text}>
          <div
            onClick={() => {
              deactivateHighlightedChat();
            }}
            className="text-xs cursor-pointer badge badge-ghost hover:badge-primary"
          >
            <MdStar />
          </div>
        </Tooltip>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

const QNAPanel = ({
  containerHeight: _containerHeight,
  containerWidth: _containerWidth,
  streamingView,
  relativeWidth,
  mainViewContainerWidth,
  onCloseButtonClick,
  alwaysScrollToBottom,
}: {
  containerHeight: number;
  containerWidth: number;
  streamingView: boolean;
  relativeWidth: number;
  mainViewContainerWidth: number;
  onCloseButtonClick?: () => void;
  alwaysScrollToBottom: boolean;
}) => {
  const { appMode } = useAppContext();

  const containerRef = useRef<HTMLElement>();
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const containerHeightRef = useRef(containerHeight);
  const containerWidthRef = useRef(containerWidth);

  useEffect(() => {
    containerHeightRef.current = containerHeight;
  }, [containerHeight]);
  useEffect(() => {
    containerWidthRef.current = containerWidth;
  }, [containerWidth]);

  useEffect(() => {
    setInterval(() => {
      if (containerRef.current) {
        const boundingRect = containerRef.current?.getBoundingClientRect();
        const { width, height } = boundingRect;

        if (containerHeightRef.current !== height) {
          setContainerHeight(height);
        }
        if (containerWidthRef.current !== width) {
          setContainerWidth(width);
        }
      }
    }, 1000);
  }, []);

  return (
    <div style={{ height: _containerHeight, width: _containerWidth }}>
      <div ref={containerRef as React.LegacyRef<HTMLDivElement> | undefined}>
        <SidePanelTopBar
          title={"Q&A"}
          onCloseButtonClick={onCloseButtonClick}
          renderRightComponent={
            streamingView
              ? undefined
              : appMode === appModes.HOST
              ? QNAPanelControlsMenu
              : undefined
          }
          renderSecondaryTitle={
            streamingView
              ? undefined
              : appMode === appModes.HOST
              ? QNATags
              : undefined
          }
        />

        {streamingView ? <React.Fragment /> : <ActiveQNAApps />}
      </div>

      <QNAChatList
        {...{
          containerHeight: _containerHeight - containerHeight,
          containerWidth: containerWidth,
          streamingView,
          relativeWidth,
          mainViewContainerWidth,
          alwaysScrollToBottom,
        }}
      />
    </div>
  );
};

export default QNAPanel;
