import { customBlueColor } from "../../utils/colors";

const NewsIconActive = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101.223 69.818"
    enableBackground="new 0 0 101.223 69.818"
    xmlSpace="preserve"
    {...props}
  >
    <g id="Capa_8">
      <path
        style={{ fill: customBlueColor[900] }}
        d="M25.4 39.577c-6.663 0-12.065 4.06-12.065 9.067h24.13c0-5.007-5.402-9.067-12.065-9.067z"
      />
      <circle
        style={{ fill: customBlueColor[900] }}
        cx={25.4}
        cy={28.79}
        r={7.617}
      />
      <path
        style={{ fill: customBlueColor[900] }}
        d="M45.081 21.173h42.807v27.471H45.081z"
      />
    </g>
  </svg>
);

export default NewsIconActive;
