import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../../contexts/appContextDef";
import useMainViewVideoClip from "../../../hooks/activeBrand/useMainViewVideoClip";
import useVideoClips from "../../../hooks/brands/useVideoClips";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { MdPlayCircleOutline, MdMovieCreation, MdAdd } from "react-icons/md";
import { VideoStreamVolumeControl } from "../../inQueueStreams/InQueueVideoStreamContainer";
import updateBrandAsset from "../../../apis/brands/update-brand-asset";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import deleteBrandAsset from "../../../apis/brands/delete-brand-asset";
import AddNewOrSelectDefaultAssetsModal from "../../../components/AddNewOrSelectDefaultAssetsModal";
import BrandAssetsDraggableItem from "./BrandAssetsDraggableItem";
import useIsProfessionalPlanActive from "../../../hooks/plans/useIsProfessionalPlanActive";
import Tooltip from "../../../components/Tooltip";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";
import { createUID } from "../../../utils/createUID";
import BrandUploadingAndProcessingItems from "./BrandUploadingAndProcessingItems";
import removeExtensionFromFileName from "../../../utils/removeExtensionFromFileName";
import useGetCurrentTime from "../../../hooks/appState/useGetCurrentTime";
import { appQueueEvents } from "../../../listners/appState/AppQueueListner";
import sleep from "../../../utils/sleep";
import updateBrand from "../../../apis/brands/update-brand";
import useBrandMuteGuestsWhenVideoPlays from "../../../hooks/activeBrand/useBrandMuteGuestsWhenVideoPlays";
import DisclosureContainer from "../../../components/DisclosureContainer";

const VideoClipsContainer = ({
  listenToCloseEvent,
  defaultOpen,
  brandId,
}: {
  listenToCloseEvent?: string;
  defaultOpen?: boolean;
  brandId: string;
}) => {
  const {
    sortedVideoClipIds: allBrandsSortedVideoClipIds,
    sortedVideoClips: allBrandsSortedVideoClips,
    mainViewVideoClip,
    newMainViewVideoClipsState,
    muteGuestsWhenVideoPlays,
  } = useAppContext();

  const { userId } = useAppConfigContext();

  const sortedVideoClips = useMemo(
    () => allBrandsSortedVideoClips[brandId] || [],
    [allBrandsSortedVideoClips, brandId]
  );

  const sortedVideoClipIds = useMemo(
    () => allBrandsSortedVideoClipIds[brandId] || [],
    [allBrandsSortedVideoClipIds, brandId]
  );

  const { add, remove, sort, rename } = useVideoClips({ id: brandId });
  const { changeActiveIdAndRemoteUrl, changeLoopState, changeVolume } =
    useMainViewVideoClip();

  const { isProfessionalPlanActive } = useIsProfessionalPlanActive();

  const { setMuteGuestsWhenVideoPlays } = useBrandMuteGuestsWhenVideoPlays();

  const [_sortedVideoClips, setSortedVideoClips] = useState(sortedVideoClips);

  const [assetsSelectionModalOpen, setAssetsSelectionModalOpen] =
    useState(false);

  const brandIdRef = useRef(brandId);

  useEffect(() => {
    brandIdRef.current = brandId;
  }, [brandId]);

  useEffect(() => {
    setSortedVideoClips(sortedVideoClips);
  }, [sortedVideoClips]);

  const sortedVideoClipIdsRef = useRef(sortedVideoClipIds);

  useEffect(() => {
    sortedVideoClipIdsRef.current = sortedVideoClipIds;
  }, [sortedVideoClipIds]);

  const reorder = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (
      typeof result?.source?.index == "number" &&
      typeof result?.destination?.index === "number"
    ) {
      const _sortedVideoClipIds = reorder(
        [...sortedVideoClipIdsRef.current],
        result.source.index,
        result.destination.index
      );

      setSortedVideoClips((sortedVideoClips) =>
        _sortedVideoClipIds.map(
          (id) =>
            sortedVideoClips.find(
              ({ id: _id }) => id === _id
            ) as singleBrandVideoClipType
        )
      );

      sort({ ids: _sortedVideoClipIds });
    }
  };

  const _handleAdd = async ({
    remoteUrl,
    videoThumbnail,
    singleFile,
    fileName,
    assetName,
    video_480,
    video_720,
  }: {
    remoteUrl?: string;
    videoThumbnail?: string;
    singleFile?: File;
    fileName?: string;
    assetName?: string;
    video_480?: string;
    video_720?: string;
  }) => {
    const uploadId = createUID();

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BRAND_VIDEO_CLIP,
      isUploading: true,
      isProcessing: false,
      isCompleted: false,
      brandId,
      uploadId,
      name: fileName,
    });

    const newId = await add({
      remoteUrl,
      uploadId,
      videoThumbnail,
      singleFile,
      assetName,
      video_480,
      video_720,
    });

    appEventEmitter.emit(appEventEmitterEvents.APP_QUEUE, {
      event: appQueueEvents.BRAND_VIDEO_CLIP,
      isUploading: false,
      isProcessing: false,
      isCompleted: true,
      //
      brandId,
      uploadId,
      name: fileName,
    });

    if (newId) {
      await new Promise((resolve) => setTimeout(resolve, 100));

      const _sortedVideoClipIds = [...sortedVideoClipIdsRef.current];

      sort({ ids: [..._sortedVideoClipIds, newId] });
    }

    return { id: newId };
  };

  const _handleRemove = async (id: string) => {
    const { success } = await deleteBrandAsset({
      brandId,
      fileId: id,
      userId,
    });

    if (success) {
      const _sortedVideoClipIds = [...sortedVideoClipIdsRef.current];

      remove({ id });

      await new Promise((resolve) => setTimeout(resolve, 100));

      sort({ ids: _sortedVideoClipIds.filter((_id) => id !== _id) });
    }
  };

  const _handleRename = async ({ id, name }: { id: string; name: string }) => {
    const { success } = await updateBrandAsset({
      brandId,
      fileId: id,
      name,
      userId,
    });

    if (success) {
      rename({ name, id });
    }
  };

  const { getCurrentTime } = useGetCurrentTime();

  const _handlePlay = async ({
    id,
    video360,
    video480,
    video720,
    videoOriginal,
    remoteUrl: _remoteUrl,
  }: {
    id: string;
    video360?: string;
    video480?: string;
    video720?: string;
    videoOriginal?: string;
    remoteUrl?: string;
  }) => {
    const { currentTime } = await getCurrentTime();

    const initTime = new Date(currentTime).getTime();

    const remoteUrl =
      video360 ||
      video480 ||
      video720 ||
      videoOriginal ||
      (typeof _remoteUrl === "string" ? _remoteUrl : "");

    changeActiveIdAndRemoteUrl({
      id,
      remoteUrl,
      playedAt: initTime,
      initTime,
    });
  };

  const _handlePause = () => {
    changeActiveIdAndRemoteUrl({
      id: null,
      remoteUrl: null,
      playedAt: null,
    });
  };

  const newBrandMainViewVideoClipsState = useMemo(
    () =>
      newMainViewVideoClipsState.filter(
        ({ brandId: _brandId }) => brandId === _brandId
      ),
    [brandId, newMainViewVideoClipsState]
  );

  const canAddNewItem = useMemo(
    () =>
      !(
        (_sortedVideoClips?.length || 0) +
          (newBrandMainViewVideoClipsState?.length || 0) >=
        (isProfessionalPlanActive ? 10 : 5)
      ),
    [
      _sortedVideoClips,
      newBrandMainViewVideoClipsState,
      isProfessionalPlanActive,
    ]
  );

  const _handleAddAndActivate = async ({
    remoteUrl,
    videoThumbnail,
    video_480,
    video_720,
    assetName,
  }: {
    remoteUrl: string;
    videoThumbnail: string;
    video_480: string;
    video_720: string;
    assetName: string;
  }) => {
    const { id } = await _handleAdd({
      remoteUrl,
      videoThumbnail,
      video_480,
      video_720,
      assetName,
    });

    if (id) {
      await sleep(500);

      _handlePlay({
        id,
        remoteUrl,
        video480: video_480,
        video720: video_720,
        videoOriginal: remoteUrl,
      });
    }
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_VIDEO_CLIP(brandId),
      _handleAddAndActivate
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.BRAND_ADD_AND_ACTIVATE_VIDEO_CLIP(brandId),
        _handleAddAndActivate
      );
    };
  }, [brandId]);

  const _handleSetMuteGuestsWhenVideoPlays = async ({
    muteGuestsWhenVideoPlays,
  }: {
    muteGuestsWhenVideoPlays: boolean;
  }) => {
    const { success } = await updateBrand({
      brandId: brandIdRef.current,
      muteGuestsWhenVideoPlays,
      userId,
    });

    if (success) {
      setMuteGuestsWhenVideoPlays({ muteGuestsWhenVideoPlays });
    }
  };

  return (
    <DisclosureContainer
      listenToCloseEvent={listenToCloseEvent}
      defaultOpen={defaultOpen}
      title={"Videos"}
      dataTip={`Upload short video clips (perfect for intros/outros).`}
      dataTipn={[
        "Recommended size: 1280 x 720",
        "Max file size: 200 MB",
        "Max video duration: 10 minutes",
        "Allowed file type: .mp4, .m4v, .webm, .flv",
      ]}
      renderPanel={() => {
        return (
          <React.Fragment>
            <div className="flex flex-col">
              <div className="flex gap-4">
                <div>
                  <VideoStreamVolumeControl
                    iconSize={22}
                    fill={"fill-primary"}
                    volume={mainViewVideoClip.volume}
                    changeVolume={(volume) => {
                      changeVolume(volume);
                    }}
                    alwaysShowSlider
                  />
                </div>
                <div className="flex gap-2">
                  <input
                    checked={mainViewVideoClip.loop}
                    onChange={() => {
                      changeLoopState({
                        loop: !mainViewVideoClip.loop,
                        loopedAt: new Date().getTime(),
                      });
                    }}
                    type={"checkbox"}
                  />
                  <p>Loop</p>
                </div>
              </div>
              <div className="flex gap-2">
                <input
                  checked={muteGuestsWhenVideoPlays}
                  onChange={() => {
                    _handleSetMuteGuestsWhenVideoPlays({
                      muteGuestsWhenVideoPlays: !muteGuestsWhenVideoPlays,
                    });
                  }}
                  type={"checkbox"}
                />
                <p>Mute guests when video plays</p>
              </div>
            </div>
            <div className="mt-3">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      className="flex flex-1 flex-col"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {_sortedVideoClips.map((item, index) => {
                        const isActive = item.id === mainViewVideoClip.id;

                        return (
                          <BrandAssetsDraggableItem
                            {...{
                              key: `brand-videos-and-audios-item-${item.id}`,
                              id: item.id,
                              index,
                              onDelete: () => _handleRemove(item.id),
                              isActive,
                              name: removeExtensionFromFileName(item.name),
                              onPause: _handlePause,
                              remoteUrl: item.remoteUrl,
                              onPlay: () => {
                                _handlePlay({
                                  id: item.id,
                                  remoteUrl: item.remoteUrl,
                                  videoOriginal: item.videoOriginal,
                                  video360: item.video360,
                                  video480: item.video480,
                                  video720: item.video720,
                                });
                              },
                              onRename: (name: string): void => {
                                _handleRename({ id: item.id, name });
                              },
                              thumbnailImage: item.videoThumbnail || undefined,
                              landscapeThumbnail: true,
                              ThumbnailIcon: item.videoThumbnail
                                ? undefined
                                : MdMovieCreation,
                              PlayIcon: MdPlayCircleOutline,
                              playText: "Play",
                              subTitleEnabled: isActive,
                              subTitleTopic:
                                appEventEmitterEvents.BRAND_VIDEO_CLIP_PROGRESS(
                                  item.id
                                ),
                            }}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <BrandUploadingAndProcessingItems
              {...{ newItemsState: newBrandMainViewVideoClipsState }}
            />

            <div className="mt-1">
              <Tooltip
                {...{
                  disabled: canAddNewItem,
                  title:
                    "You have reached the maximum videos upload limit. Delete one to upload a new one.",
                }}
              >
                <button
                  onClick={() => {
                    if (canAddNewItem) {
                      setAssetsSelectionModalOpen(true);
                    }
                  }}
                  className={`gap-2 btn btn-sm btn-primary btn-outline normal-case ${
                    canAddNewItem ? "" : "opacity-60 cursor-not-allowed"
                  }`}
                >
                  <MdAdd size={24} />

                  {"Add new video"}
                </button>
              </Tooltip>
            </div>

            <AddNewOrSelectDefaultAssetsModal
              {...{
                maxFileSizeInMB: 200,
                isOpen: assetsSelectionModalOpen,
                onClose: () => {
                  setAssetsSelectionModalOpen(false);
                },
                onSuccess: () => {
                  setAssetsSelectionModalOpen(false);
                },
                title: "Add Video",
                acceptFileType: ".mp4,.m4v,.webm,.flv",
                inputButtonText: "Select video",
                onFileEvent: _handleAdd,
                type: "video",
                defaultItems: [
                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/10-sec-countdown/480.mp4",
                    useRemoteUrl: true,
                    name: "10 sec countdown",
                    video_480:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/10-sec-countdown/480.mp4",
                    video_720:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/10-sec-countdown/720.mp4",
                    original:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/10-sec-countdown/original.mp4",
                    thumbnailUrl:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/10-sec-countdown/thumbnail.jpeg",
                    assetName: "10-sec-countdown",
                  },

                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/30-sec-countdown/480.mp4",
                    useRemoteUrl: true,
                    name: "30 sec countdown",
                    video_480:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/30-sec-countdown/480.mp4",
                    video_720:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/30-sec-countdown/720.mp4",
                    original:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/30-sec-countdown/original.mp4",
                    thumbnailUrl:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/30-sec-countdown/thumbnail.jpeg",
                    assetName: "30-sec-countdown",
                  },

                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/starting-soon/480.mp4",
                    useRemoteUrl: true,
                    name: "Starting soon",
                    video_480:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/starting-soon/480.mp4",
                    video_720:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/starting-soon/720.mp4",
                    original:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/starting-soon/original.mp4",
                    thumbnailUrl:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/starting-soon/thumbnail.jpeg",
                    assetName: "starting-soon",
                  },

                  {
                    url: "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/thank-you-for-watching/480.mp4",
                    useRemoteUrl: true,
                    name: "Thank you for watching",
                    video_480:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/thank-you-for-watching/480.mp4",
                    video_720:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/thank-you-for-watching/720.mp4",
                    original:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/thank-you-for-watching/original.mp4",
                    thumbnailUrl:
                      "https://d3vc14lubtaqnr.cloudfront.net/Momentostream/studio-assets/videos/thank-you-for-watching/thumbnail.jpeg",
                    assetName: "thank-you-for-watching",
                  },
                ],
              }}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

export default VideoClipsContainer;
