import { LocalTrackPublication, RemoteTrackPublication } from "livekit-client";
import { useAppContext } from "../../contexts/appContextDef";
import { useMemo } from "react";
import { checkIsMicLivekitPublication } from "./useLivekitRtcRemoteParticipantMediaStats";

const useLivekitRtcAllParticipantsOgMicStreams = (argsAppContext?: {
  livekitRemoteParticipantsTrackPublications: Map<
    string,
    RemoteTrackPublication[]
  >;
  livekitLocalParticipantTrackPublications: LocalTrackPublication[];
  joinedParticipants: joinedParticipantsType;
  localParticipantId: string;
}) => {
  const _appContext = useAppContext();

  const appContext = useMemo(
    () => argsAppContext || _appContext,
    [argsAppContext, _appContext]
  );

  const {
    livekitLocalParticipantTrackPublications,
    livekitRemoteParticipantsTrackPublications,
  }: {
    livekitRemoteParticipantsTrackPublications: Map<
      string,
      RemoteTrackPublication[]
    >;
    livekitLocalParticipantTrackPublications: LocalTrackPublication[];
  } = appContext;

  const { joinedParticipants, localParticipantId } = appContext;

  const { allParticipantsOgMicStreams } = useMemo(() => {
    const remoteJoinedParticipants = joinedParticipants.filter(
      ({ participantId }) => participantId !== localParticipantId
    );

    const participantsOgMicStreams = remoteJoinedParticipants.map(
      ({ participantId }) => {
        const livekitRemoteParticipantTrackPublications =
          livekitRemoteParticipantsTrackPublications.get(participantId);

        if (livekitRemoteParticipantTrackPublications) {
          const ogMicPublication =
            livekitRemoteParticipantTrackPublications.find((publication) =>
              checkIsMicLivekitPublication(publication)
            );

          return {
            ogMicTrack: ogMicPublication?.track?.mediaStreamTrack,
            participantId,
            isLocal: false,
          };
        } else {
          return { ogMicTrack: undefined, participantId, isLocal: false };
        }
      }
    );

    const localParticipantOgMicPublication =
      livekitLocalParticipantTrackPublications.find((publication) =>
        checkIsMicLivekitPublication(publication)
      );

    participantsOgMicStreams.push({
      ogMicTrack:
        localParticipantOgMicPublication?.track?.mediaStreamTrack || undefined,
      participantId: localParticipantId,
      isLocal: true,
    });

    const allParticipantsOgMicStreams: allParticipantsOgMicStreamsType =
      new Map();

    participantsOgMicStreams.forEach(
      ({ ogMicTrack, participantId, isLocal }) => {
        allParticipantsOgMicStreams.set(participantId, {
          ogMicTrack,
          participantId,
          isLocal,
        });
      }
    );

    return { allParticipantsOgMicStreams };
  }, [
    joinedParticipants,
    localParticipantId,
    livekitLocalParticipantTrackPublications,
    livekitRemoteParticipantsTrackPublications,
  ]);

  return { allParticipantsOgMicStreams };
};

export default useLivekitRtcAllParticipantsOgMicStreams;
