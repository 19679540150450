// import { usePubSub } from "@videosdk.live/react-sdk";
import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import {
  CinemaIcon,
  CinemaIconActive,
  CroppedIcon,
  CroppedIconActive,
  GroupIcon,
  GroupIconActive,
  NewsIcon,
  NewsIconActive,
  PictureInPictureIcon,
  PictureInPictureIconActive,
  ScreenIcon,
  ScreenIconActive,
  SoloIcon,
  SoloIconActive,
  SpotlightIcon,
  SpotlightIconActive,
} from "../../static/icons";
import {
  mainViewShareLayouts,
  mainViewWebcamLayouts,
} from "../../utils/constants";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

export const groupGridMetaSize: {
  [t: number]: {
    r?: number;
    c?: number;
    r0?: number;
    r1?: number;
    r2?: number;
  };
} = {
  1: { r: 1, c: 1, r0: 1 },
  2: { r: 1, c: 2, r0: 2 },
  3: { r: 2, c: 2, r0: 2, r1: 1 },
  4: { r: 2, c: 2, r0: 2, r1: 2 },
  5: { r: 2, c: 3, r0: 3, r1: 2 },
  6: { r: 2, c: 3, r0: 3, r1: 3 },
  7: { r: 3, c: 3, r0: 2, r1: 3, r2: 2 },
  8: { r: 3, c: 3, r0: 3, r1: 2, r2: 3 },
  9: { r: 3, c: 3, r0: 3, r1: 3, r2: 3 },
  10: { r: 3, c: 4, r0: 3, r1: 4, r2: 3 },
  11: { r: 3, c: 4, r0: 4, r1: 3, r2: 4 },
  12: { r: 3, c: 4, r0: 4, r1: 4, r2: 4 },
};

export const croppedGridMetaSize: {
  [t: number]: {
    r?: number;
    c?: number;
    r0?: number;
    r1?: number;
    r2?: number;
  };
} = {
  ...groupGridMetaSize,
  3: { r: 1, c: 3, r0: 3 },
};

export const layoutDisplayNamesAndIcons = {
  SOLO: {
    Icon: SoloIcon,
    ActiveIcon: SoloIconActive,
    layoutName: "Solo layout",
    shortcut: "Shift + 1",
  },
  CROPPED: {
    Icon: CroppedIcon,
    ActiveIcon: CroppedIconActive,
    layoutName: "Cropped layout",
    shortcut: "Shift + 2",
  },
  GROUP: {
    Icon: GroupIcon,
    ActiveIcon: GroupIconActive,
    layoutName: "Group layout",
    shortcut: "Shift + 3",
  },
  SPOTLIGHT: {
    Icon: SpotlightIcon,
    ActiveIcon: SpotlightIconActive,
    layoutName: "Spotlight layout",
    shortcut: "Shift + 4",
  },
  NEWS: {
    Icon: NewsIcon,
    ActiveIcon: NewsIconActive,
    layoutName: "News layout",
    shortcut: "Shift + 5",
  },
  SCREEN: {
    Icon: ScreenIcon,
    ActiveIcon: ScreenIconActive,
    layoutName: "Screen layout",
    shortcut: "Shift + 6",
  },
  PICTURE_IN_PICTURE: {
    Icon: PictureInPictureIcon,
    ActiveIcon: PictureInPictureIconActive,
    layoutName: "Picture-in-picture layout",
    shortcut: "Shift + 7",
  },
  CINEMA: {
    Icon: CinemaIcon,
    ActiveIcon: CinemaIconActive,
    layoutName: "Cinema layout",
    shortcut: "Shift + 8",
  },
};

export const getShareEquivalantLayout = (layout: mainViewLayoutType) => {
  switch (layout) {
    case mainViewWebcamLayouts.SOLO:
      return mainViewShareLayouts.NEWS;

    case mainViewWebcamLayouts.CROPPED:
      return mainViewShareLayouts.SCREEN;

    case mainViewWebcamLayouts.GROUP:
      return mainViewShareLayouts.PICTURE_IN_PICTURE;

    case mainViewWebcamLayouts.SPOTLIGHT:
      return mainViewShareLayouts.CINEMA;

    default:
      return layout;
  }
};

const MainViewLayoutListner = () => {
  const { setMainViewLayout, setConferenceAutoMainVideoLayoutEnabled } =
    useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.MAIN_VIEW_LAYOUT,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { conferenceAutoMainVideoLayoutEnabled, layout } = message;

      setMainViewLayout(layout);

      setConferenceAutoMainVideoLayoutEnabled(
        conferenceAutoMainVideoLayoutEnabled
      );
    }
  );

  return <React.Fragment />;
};

export default MainViewLayoutListner;
