// import { usePubSub } from "@videosdk.live/react-sdk";
import { useMemo } from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useValidateSpeakerCanTakeAction from "./useValidateSpeakerCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useParticipantName = (participantId: string) => {
  const { modifiedParticipantsNames, joinedParticipants } = useAppContext();

  // const { publish: modifyNamePublish } = usePubSub(
  //   appPubSubTopics.MODIFY_PARTICIPANT_NAME
  // );

  const { publish: modifyNamePublish } = useAppSingalingPublish(
    appPubSubTopics.MODIFY_PARTICIPANT_NAME
  );

  const name = useMemo(() => {
    const modifiedName = modifiedParticipantsNames?.find(
      ({ participantId: _participantId }) => _participantId === participantId
    );

    return (
      modifiedName?.name ||
      joinedParticipants?.find(
        ({ participantId: _participantId }) => _participantId === participantId
      )?.participantName ||
      ""
    );
  }, [joinedParticipants, modifiedParticipantsNames, participantId]);

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  const changeName = async (name: string, opt?: { force: true }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction && !opt?.force) return;

    modifyNamePublish(
      JSON.stringify({ participantId, name })
      // , {
      //   persist: true,
      // }
    );
  };

  return { changeName, name };
};

export default useParticipantName;
