// import { usePubSub } from "@videosdk.live/react-sdk";
import React from "react";
import { useAppContext } from "../../contexts/appContextDef";
import { backgroundMusicPubSubActions } from "../../hooks/activeBrand/useBackgroundMusic";
import { appPubSubTopics } from "../../utils/pubSubTopics";
// import groupBy from "lodash.groupby";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const BackgroundMusicListner = () => {
  const { setBackgroundMusic } = useAppContext();

  const _handleChangeActiveIdAndRemoteUrl = ({
    id,
    remoteUrl,
    initTime,
    playedAt,
    // timestamp,
    durationInSeconds,
  }: {
    id: string;
    remoteUrl: string;
    initTime: number;
    playedAt: number;
    // timestamp: number;
    durationInSeconds: number;
  }) => {
    setBackgroundMusic((s) => ({
      ...s,
      remoteUrl,
      id,
      playedAt,
      initTime,
      durationInSeconds,
    }));
  };

  const _handleChangeVolume = ({ volume }: { volume: number }) => {
    setBackgroundMusic((s) => ({ ...s, volume }));
  };

  const _handleChangeLoopState = ({
    loop,
    loopedAt,
  }: // timestamp,
  {
    loopedAt: number;
    // timestamp: number;
    loop: boolean;
  }) => {
    setBackgroundMusic((s) => ({ ...s, loop, loopedAt }));
  };

  useAppSingalingSubscribe(
    appPubSubTopics.BACKGROUND_MUSIC,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      // const timestamp = new Date().getTime();

      switch (message.action) {
        case backgroundMusicPubSubActions.CHANGE_ACTIVE_ID_AND_REMOTE_URL:
          _handleChangeActiveIdAndRemoteUrl({ ...message.payload });
          break;

        case backgroundMusicPubSubActions.CHANGE_VOLUME:
          _handleChangeVolume({ ...message.payload });
          break;

        case backgroundMusicPubSubActions.LOOP:
          _handleChangeLoopState({ ...message.payload });
          break;

        default:
      }
    }
  );

  return <React.Fragment />;
};

export default BackgroundMusicListner;
