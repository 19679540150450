import React, { useEffect, useRef } from "react";
import useIsOldest from "../../hooks/appState/useIsOldest";
import { useAppContext } from "../../contexts/appContextDef";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import startAIInterpreter from "../../apis/egress/startAIInterpreter";

const AutoStartAIInterpretation = () => {
  const { broadcastId } = useAppConfigContext();
  const { interpreterParticipantIds } = useAppContext();
  const { getIsOldestParticipant } = useIsOldest();

  const getIsOldestParticipantRef = useRef(getIsOldestParticipant);
  const interpreterParticipantIdsRef = useRef(interpreterParticipantIds);
  const broadcastIdRef = useRef(broadcastId);

  useEffect(() => {
    getIsOldestParticipantRef.current = getIsOldestParticipant;
  }, [getIsOldestParticipant]);
  useEffect(() => {
    interpreterParticipantIdsRef.current = interpreterParticipantIds;
  }, [interpreterParticipantIds]);
  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);

  const checkIfAIInterpretationShouldStart = async () => {
    const getIsOldestParticipant = getIsOldestParticipantRef.current;

    const { isOldestParticipant } = getIsOldestParticipant();

    if (isOldestParticipant) {
      const interpreterParticipantIds = interpreterParticipantIdsRef.current;

      if (!interpreterParticipantIds.length) {
        startAIInterpreter({ broadcastId: broadcastIdRef.current });
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkIfAIInterpretationShouldStart();
    }, 10_000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <React.Fragment />;
};

export default AutoStartAIInterpretation;
