// @ts-nocheck

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppContext } from "../../contexts/appContextDef";
import sleep from "../../utils/sleep";
import generateAgoraRtcConfig from "../../apis/studio/generate-agora-rtc-config";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import { safeParseJson } from "../../utils/safeParseJson";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { createUID } from "../../utils/createUID";
import validateSpeakerDeviceId from "../../utils/validateSpeakerDeviceId";
import { MdPlayArrow } from "react-icons/md";

const AgoraAudioMediaStreamTrackPlayer = ({
  audioTrack,
}: {
  audioTrack: MediaStreamTrack;
}) => {
  const [muteDueToError, setMuteDueToError] = useState(false);
  const audioPlayer = useRef<StudioHTMLAudioElement>();

  const audioPlayerId = useMemo(
    () => `AudioMediaStreamTrackPlayer_${createUID()}`,
    []
  );

  const audioPlayerIdRef = useRef(audioPlayerId);
  const muteDueToErrorRef = useRef(muteDueToError);

  const audioTrackRef = useRef(audioTrack);

  useEffect(() => {
    audioTrackRef.current = audioTrack;
  }, [audioTrack]);

  const { selectedSpeakerDeviceId } = useAppContext();

  const selectedSpeakerDeviceIdRef = useRef(selectedSpeakerDeviceId);

  useEffect(() => {
    selectedSpeakerDeviceIdRef.current = selectedSpeakerDeviceId;
  }, [selectedSpeakerDeviceId]);

  useEffect(() => {
    audioPlayerIdRef.current = audioPlayerId;
  }, [audioPlayerId]);

  useEffect(() => {
    muteDueToErrorRef.current = muteDueToError;
  }, [muteDueToError]);

  const getAudioTag = () => {
    const audioTag = document.getElementById(
      audioPlayerIdRef.current
    ) as StudioHTMLAudioElement | null;

    return audioTag;
  };

  const setSinkIdForAudio = async ({ deviceId }: { deviceId: string }) => {
    const { isValid } = await validateSpeakerDeviceId({ deviceId });

    const audioTag = getAudioTag();

    if (isValid && typeof audioTag?.setSinkId === "function") {
      audioTag.setSinkId(deviceId);
    }
  };

  const _playAudioStream = async ({
    audioTrack,
  }: {
    audioTrack?: MediaStreamTrack | null;
  }) => {
    return;

    //

    const audioTag = getAudioTag();

    if (!audioTag) return;

    if (muteDueToErrorRef.current) {
      setMuteDueToError(false);
    }

    if (!audioTrack) {
      audioTag.pause();

      return;
    }

    const mediaStream = new MediaStream();

    mediaStream.addTrack(audioTrack);

    audioTag.srcObject = mediaStream;

    audioTag.volume = 1;

    audioTag.muted = false;

    // if (!audioTag.paused && !forcePlay) return;

    const playPromise = audioTag.play();

    if (playPromise !== undefined) {
      await new Promise((resolve) => {
        playPromise.then(resolve).catch((err) => {
          if (String(err).includes("NotAllowedError")) {
            if (!muteDueToErrorRef.current) {
              setMuteDueToError(true);
            }

            audioTag.muted = true;

            const mutedPlayPromise = audioTag.play();

            if (mutedPlayPromise !== undefined) {
              mutedPlayPromise.then(resolve).catch(resolve);
            }
          } else {
            resolve(undefined);
          }
        });
      });
    }
  };

  const _handleUserInteractButtonClicked = () => {
    _playAudioStream({
      audioTrack: audioTrackRef.current,
    });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED,
      _handleUserInteractButtonClicked
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED,
        _handleUserInteractButtonClicked
      );
    };
  }, []);

  useEffect(() => {
    setSinkIdForAudio({
      deviceId: selectedSpeakerDeviceId as string,
    });
  }, [selectedSpeakerDeviceId]);

  useEffect(() => {
    _playAudioStream({
      audioTrack: audioTrack,
    });
  }, [audioTrack]);

  return (
    <React.Fragment>
      <audio
        id={audioPlayerId}
        autoPlay
        playsInline
        controls={false}
        ref={audioPlayer as React.LegacyRef<StudioHTMLAudioElement>}
      />

      {muteDueToError ? (
        <div
          onClick={() => {
            appEventEmitter.emit(
              appEventEmitterEvents.USER_INTERACT_BUTTON_CLICKED
            );
          }}
          className="flex items-center justify-center absolute top-0 bottom-0 right-0 left-0"
        >
          <div
            className="aspect-square rounded-full bg-black bg-opacity-50 p-3 border-4 border-white"
            style={{ height: "20%" }}
          >
            <MdPlayArrow className="fill-white" size={"100%"} />
          </div>
        </div>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

const AgoraLocalMicTrackVolumeListner = ({
  mediaStreamTrack,
}: {
  mediaStreamTrack: MediaStreamTrack;
}) => {
  const audioAnalyserIntervalRef = useRef<null | ReturnType<
    typeof setInterval
  >>();
  const _handleOnVolumeChanged = useCallback(
    ({ volume }: { volume: number }) => {
      appEventEmitter.emit(appEventEmitterEvents.LOCAL_MIC_VOLUME_CHANGED, {
        volume,
      });
    },
    []
  );

  const stopAudioAnalyse = useCallback(() => {
    if (audioAnalyserIntervalRef.current) {
      clearInterval(audioAnalyserIntervalRef.current);
    }

    _handleOnVolumeChanged({ volume: 0 });
  }, [_handleOnVolumeChanged]);

  const analyseAudio = useCallback(
    (audioTrack: MediaStreamTrack) => {
      const audioStream = new MediaStream([audioTrack]);
      const audioContext = new AudioContext();

      const audioSource = audioContext.createMediaStreamSource(audioStream);
      const analyser = audioContext.createAnalyser();

      analyser.fftSize = 512;
      analyser.minDecibels = -127;
      analyser.maxDecibels = 0;
      analyser.smoothingTimeConstant = 0.4;

      audioSource.connect(analyser);

      const volumes = new Uint8Array(analyser.frequencyBinCount);

      const volumeCallback = () => {
        analyser.getByteFrequencyData(volumes);

        const volumeSum = volumes.reduce((sum, vol) => sum + vol);
        const averageVolume = volumeSum / volumes.length;

        _handleOnVolumeChanged({ volume: averageVolume });
      };

      audioAnalyserIntervalRef.current = setInterval(volumeCallback, 100);
    },
    [_handleOnVolumeChanged]
  );

  useEffect(() => {
    if (mediaStreamTrack) {
      analyseAudio(mediaStreamTrack);
    } else {
      stopAudioAnalyse();
    }
  }, [mediaStreamTrack, stopAudioAnalyse, analyseAudio]);

  return <React.Fragment />;
};

const AgoraConnectedChannelClientListner = ({
  agoraChannelId,
  loadAgoraRtcSdk,
  speakerLastSpeakedAt,
}: {
  agoraChannelId: string;
  loadAgoraRtcSdk: () => Promise<void>;
  speakerLastSpeakedAt: React.MutableRefObject<number | undefined>;
}) => {
  const isConnecting = useRef(false);

  const {
    selectedMicDeviceId,
    agoraConnectedChannelClients,
    setAgoraConnectedChannelClients,
    activeInterpretationOutputAgoraChannelId,
    activeInterpretationInputAgoraChannelId,
    localParticipantAITranslateConnectedAgoraChannelIds,
  } = useAppContext();

  const { interpretations, isInterpreter } = useAppConfigContext();

  const isActiveInterpretationOutputAgoraChannelId = useMemo(
    () =>
      !!interpretations.find(
        ({ agoraChannelId: _agoraChannelId }) =>
          _agoraChannelId === activeInterpretationOutputAgoraChannelId &&
          agoraChannelId === _agoraChannelId
      ),
    [agoraChannelId, activeInterpretationOutputAgoraChannelId, interpretations]
  );

  const isActiveInterpretationInputAgoraChannelId = useMemo(
    () =>
      !!interpretations.find(
        ({ agoraChannelId: _agoraChannelId }) =>
          _agoraChannelId === activeInterpretationInputAgoraChannelId &&
          agoraChannelId === _agoraChannelId
      ),
    [agoraChannelId, activeInterpretationInputAgoraChannelId, interpretations]
  );

  const { isConnected, client } = useMemo(() => {
    const agoraConnectedChannelClient = agoraConnectedChannelClients.find(
      ({ agoraChannelId: _agoraChannelId }) =>
        agoraChannelId === _agoraChannelId
    );

    return {
      client: agoraConnectedChannelClient?.client,
      isConnected: !!agoraConnectedChannelClient,
    };
  }, [agoraConnectedChannelClients, agoraChannelId]);

  const isInterpreterRef = useRef(isInterpreter);
  const isConnectedRef = useRef(isConnected);
  const clientRef = useRef(client);
  const isActiveInterpretationOutputAgoraChannelIdRef = useRef(
    isActiveInterpretationOutputAgoraChannelId
  );
  const isActiveInterpretationInputAgoraChannelIdRef = useRef(
    isActiveInterpretationInputAgoraChannelId
  );
  const agoraConnectedChannelClientsRef = useRef(agoraConnectedChannelClients);
  const selectedMicDeviceIdRef = useRef(selectedMicDeviceId);
  const localParticipantAITranslateConnectedAgoraChannelIdsRef = useRef(
    localParticipantAITranslateConnectedAgoraChannelIds
  );

  useEffect(() => {
    isInterpreterRef.current = isInterpreter;
  }, [isInterpreter]);
  useEffect(() => {
    isConnectedRef.current = isConnected;
  }, [isConnected]);
  useEffect(() => {
    clientRef.current = client;
  }, [client]);
  useEffect(() => {
    isActiveInterpretationOutputAgoraChannelIdRef.current =
      isActiveInterpretationOutputAgoraChannelId;
  }, [isActiveInterpretationOutputAgoraChannelId]);
  useEffect(() => {
    isActiveInterpretationInputAgoraChannelIdRef.current =
      isActiveInterpretationInputAgoraChannelId;
  }, [isActiveInterpretationInputAgoraChannelId]);
  useEffect(() => {
    agoraConnectedChannelClientsRef.current = agoraConnectedChannelClients;
  }, [agoraConnectedChannelClients]);
  useEffect(() => {
    selectedMicDeviceIdRef.current = selectedMicDeviceId;
  }, [selectedMicDeviceId]);
  useEffect(() => {
    localParticipantAITranslateConnectedAgoraChannelIdsRef.current =
      localParticipantAITranslateConnectedAgoraChannelIds;
  }, [localParticipantAITranslateConnectedAgoraChannelIds]);

  const connectAgoraChannel = async () => {
    if (isConnecting.current) {
      return;
    }

    isConnecting.current = true;

    try {
      await loadAgoraRtcSdk();

      const channelName = agoraChannelId;

      const client = window.AgoraRTC.createClient({
        mode: "rtc",
        codec: "vp8",
      });

      const { success, data } = await generateAgoraRtcConfig({ channelName });

      if (success && data) {
        const { appId, token } = data;

        await client.join(appId, channelName, token, null);
      }

      const agoraConnectedChannelClients =
        agoraConnectedChannelClientsRef.current;

      const foundIndex = agoraConnectedChannelClients.findIndex(
        ({ agoraChannelId: _agoraChannelId }) =>
          agoraChannelId === _agoraChannelId
      );

      if (foundIndex !== -1) {
        setAgoraConnectedChannelClients((s) =>
          s.map((agoraConnectedChannelClient) =>
            agoraConnectedChannelClient.agoraChannelId === agoraChannelId
              ? { ...agoraConnectedChannelClient, isForAudioInput: false }
              : agoraConnectedChannelClient
          )
        );
      } else {
        setAgoraConnectedChannelClients((s) => [
          ...s,
          {
            agoraChannelId,
            client,
            isForAudioInput: false,
            isForAudioOutput: false,
          },
        ]);
      }

      await sleep(1000);
    } catch (error) {
      //
    }

    isConnecting.current = false;
  };

  const startListningEventsForOutputChannel = async () => {
    const client = clientRef.current;

    if (!client) {
      return;
    }

    for (let index = 0; index < client.remoteUsers.length; index++) {
      const remoteUser = client.remoteUsers[index];

      if (
        !localParticipantAITranslateConnectedAgoraChannelIdsRef.current.find(
          ({ uid }) => uid === remoteUser?.uid
        )
      ) {
        if (remoteUser.hasAudio) {
          const remoteTrack = await client.subscribe(remoteUser, "audio");

          remoteTrack.play();
        }
      }
    }

    client.on("user-published", async (remoteUser, mediaType) => {
      if (
        !localParticipantAITranslateConnectedAgoraChannelIdsRef.current.find(
          ({ uid }) => uid === remoteUser?.uid
        )
      ) {
        if (mediaType === "audio") {
          const remoteTrack = await client.subscribe(remoteUser, "audio");

          remoteTrack.play();
        }
      }
    });

    client.enableAudioVolumeIndicator();

    client.on("volume-indicator", (volumes?: { level: number }[]) => {
      const volumeLevels = volumes?.map(({ level }) => level);

      const isAnyActiveSpeaker = !!volumeLevels?.filter(
        (volumeLevel) => 60 <= volumeLevel
      )?.length;

      if (
        isAnyActiveSpeaker &&
        isActiveInterpretationOutputAgoraChannelIdRef.current
      ) {
        speakerLastSpeakedAt.current = new Date().getTime();
      }
    });
  };

  const stopListningEventsForOutputChannel = async () => {
    const client = clientRef.current;

    if (!client) {
      return;
    }

    for (let index = 0; index < client.remoteUsers.length; index++) {
      const remoteUser = client.remoteUsers[index];

      if (remoteUser.hasAudio && remoteUser.audioTrack) {
        await client.unsubscribe(remoteUser, "audio");
      }
    }
  };

  const disconnectInputAgoraChannelClient = async () => {
    const client = clientRef.current;

    if (!client) {
      return;
    }

    if (client.localTracks?.length) {
      await client.unpublish(client.localTracks);
    }
  };

  const _handleTurnOnMic = async (t?: {
    deviceId: string;
    agoraChannelId?: string;
  }) => {
    if (
      !isActiveInterpretationInputAgoraChannelIdRef.current &&
      t?.agoraChannelId !== agoraChannelId
    ) {
      return;
    }

    const client = clientRef.current;

    if (!client) {
      return;
    }

    if (client?.localTracks?.length) {
      const micTrack = client?.localTracks?.find(
        ({ trackMediaType }) => trackMediaType === "audio"
      );

      await micTrack?.setMuted(false);
    } else {
      const microphones = await window.AgoraRTC.getMicrophones();

      const deviceId =
        t?.deviceId ||
        selectedMicDeviceIdRef.current ||
        (microphones?.length && microphones[0].deviceId);

      if (deviceId) {
        const microphoneAudioTrack =
          await window.AgoraRTC.createMicrophoneAudioTrack({
            microphoneId: deviceId,
          });

        if (microphoneAudioTrack) {
          await client.publish(microphoneAudioTrack);
        }
      }
    }
  };

  const _handleTurnOffMic = async (t?: { force?: boolean }) => {
    const client = clientRef.current;

    if (!client) {
      return;
    }

    if (client?.localTracks?.length) {
      if (t?.force) {
        await client.unpublish(client.localTracks);
      } else {
        const micTrack = client?.localTracks?.find(
          ({ trackMediaType }) => trackMediaType === "audio"
        );

        await micTrack?.setMuted(true);
      }
    }
  };

  const _handleChangeMicDeviceId = async ({
    deviceId,
  }: {
    deviceId: string;
  }) => {
    await _handleTurnOffMic({ force: true });

    await _handleTurnOnMic({ deviceId });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (isInterpreterRef.current ||
          isActiveInterpretationOutputAgoraChannelIdRef.current ||
          isActiveInterpretationInputAgoraChannelIdRef.current) &&
        !isConnectedRef.current
      ) {
        connectAgoraChannel();
      }

      if (!isActiveInterpretationOutputAgoraChannelIdRef.current) {
        stopListningEventsForOutputChannel();
      }

      if (!isInterpreterRef.current) {
        disconnectInputAgoraChannelClient();
      } else {
        if (!isActiveInterpretationInputAgoraChannelIdRef.current) {
          _handleTurnOffMic();
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isConnected && isActiveInterpretationOutputAgoraChannelId) {
      startListningEventsForOutputChannel();
    }
  }, [isConnected, isActiveInterpretationOutputAgoraChannelId]);

  useEffect(() => {
    if (isConnected && isActiveInterpretationInputAgoraChannelId) {
      // _handleTurnOffMic();
    }
  }, [isConnected, isActiveInterpretationInputAgoraChannelId]);

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_ON_MIC,
      _handleTurnOnMic
    );

    appEventEmitter.on(
      appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_OFF_MIC,
      _handleTurnOffMic
    );

    appEventEmitter.on(
      appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_CHANGE_MIC_DEVICE_ID,
      _handleChangeMicDeviceId
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_ON_MIC,
        _handleTurnOnMic
      );

      appEventEmitter.off(
        appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_OFF_MIC,
        _handleTurnOffMic
      );

      appEventEmitter.off(
        appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_CHANGE_MIC_DEVICE_ID,
        _handleChangeMicDeviceId
      );
    };
  }, []);

  return <React.Fragment />;
};

const AgoraInterpreterListner = () => {
  const [agoraRtcSdkLoaded, setAgoraRtcSdkLoaded] = useState(false);
  const [remoteParticipantsAudioTrack, setRemoteParticipantsAudioTrack] =
    useState<
      {
        audioTrack: MediaStreamTrack;
        participantId: string;
      }[]
    >([]);

  // for (let index = 0; index < client.remoteUsers.length; index++) {
  //   const remoteUser = client.remoteUsers[index];

  //   if (remoteUser.hasAudio && remoteUser.audioTrack) {
  //     await client.unsubscribe(remoteUser, "audio");
  //   }
  // }

  const { interpretations } = useAppConfigContext();

  const {
    selectedSpeakerDeviceId,
    isAnyAgoraActiveSpeaker,
    agoraConnectedChannelClients,
    setAgoraConnectedChannelClients,
    activeInterpretationOutputAgoraChannelId,
    activeInterpretationInputAgoraChannelId,
    setIsAnyAgoraActiveSpeaker,
    selectedMicDeviceId,
    setSelectedMicDeviceId,
    interpreterParticipantIds,
    interpretationInputAgoraMicStats,
    setInterpretationInputAgoraMicStats,
    allParticipantsInterpreterAgoraStats,
    setAllParticipantsInterpreterAgoraStats,
  } = useAppContext();

  const activeSpeakerWithThresholdInterval = useRef<null | ReturnType<
    typeof setInterval
  >>();
  const agoraRtcSdkLoadedRef = useRef(agoraRtcSdkLoaded);
  const isAnyAgoraActiveSpeakerRef = useRef(isAnyAgoraActiveSpeaker);
  const speakerLastSpeakedAt = useRef<number>();
  const selectedMicDeviceIdRef = useRef(selectedMicDeviceId);
  const interpretationInputAgoraMicStatsRef = useRef(
    interpretationInputAgoraMicStats
  );
  const activeInterpretationOutputAgoraChannelIdRef = useRef(
    activeInterpretationOutputAgoraChannelId
  );
  const activeInterpretationInputAgoraChannelIdRef = useRef(
    activeInterpretationInputAgoraChannelId
  );
  const allParticipantsInterpreterAgoraStatsRef = useRef(
    allParticipantsInterpreterAgoraStats
  );
  const agoraConnectedChannelClientsRef = useRef(agoraConnectedChannelClients);
  const selectedSpeakerDeviceIdRef = useRef(selectedSpeakerDeviceId);
  const interpreterParticipantIdsRef = useRef(interpreterParticipantIds);

  useEffect(() => {
    agoraRtcSdkLoadedRef.current = agoraRtcSdkLoaded;
  }, [agoraRtcSdkLoaded]);
  useEffect(() => {
    isAnyAgoraActiveSpeakerRef.current = isAnyAgoraActiveSpeaker;
  }, [isAnyAgoraActiveSpeaker]);
  useEffect(() => {
    selectedMicDeviceIdRef.current = selectedMicDeviceId;
  }, [selectedMicDeviceId]);
  useEffect(() => {
    interpretationInputAgoraMicStatsRef.current =
      interpretationInputAgoraMicStats;
  }, [interpretationInputAgoraMicStats]);
  useEffect(() => {
    activeInterpretationOutputAgoraChannelIdRef.current =
      activeInterpretationOutputAgoraChannelId;
  }, [activeInterpretationOutputAgoraChannelId]);
  useEffect(() => {
    activeInterpretationInputAgoraChannelIdRef.current =
      activeInterpretationInputAgoraChannelId;
  }, [activeInterpretationInputAgoraChannelId]);
  useEffect(() => {
    allParticipantsInterpreterAgoraStatsRef.current =
      allParticipantsInterpreterAgoraStats;
  }, [allParticipantsInterpreterAgoraStats]);
  useEffect(() => {
    agoraConnectedChannelClientsRef.current = agoraConnectedChannelClients;
  }, [agoraConnectedChannelClients]);
  useEffect(() => {
    selectedSpeakerDeviceIdRef.current = selectedSpeakerDeviceId;
  }, [selectedSpeakerDeviceId]);
  useEffect(() => {
    interpreterParticipantIdsRef.current = interpreterParticipantIds;
  }, [interpreterParticipantIds]);

  const loadAgoraRtcSdk = async () => {
    if (agoraRtcSdkLoadedRef.current) {
      return;
    }

    await new Promise((resolve) => {
      const agoraRtcSdkSrc =
        // "https://download.agora.io/sdk/release/AgoraRTC_N-4.19.0.js";
        "https://download.agora.io/sdk/release/AgoraRTC_N-4.20.2.js";

      const script = document.createElement("script");

      script.src = agoraRtcSdkSrc;

      script.addEventListener("load", async () => {
        setAgoraRtcSdkLoaded(true);

        await sleep(500);

        resolve(undefined);
      });

      document.head.appendChild(script);
    });

    return;
  };

  const connectInputAgoraChannelClient = async ({
    agoraChannelId,
  }: {
    agoraChannelId: string;
  }) => {
    try {
      const micOnBeforeSwitching =
        interpretationInputAgoraMicStatsRef.current.micOn;

      if (micOnBeforeSwitching) {
        appEventEmitter.emit(
          appEventEmitterEvents.AGORA_INTERPRETATION_INPUT_CHANNEL_TURN_ON_MIC,
          { agoraChannelId }
        );
      }
    } catch (error) {
      //
    }
  };

  const _handleActiveInterpretationInputAgoraChannelIdChanged = ({
    activeInterpretationInputAgoraChannelId,
  }: {
    activeInterpretationInputAgoraChannelId: string;
  }) => {
    connectInputAgoraChannelClient({
      agoraChannelId: activeInterpretationInputAgoraChannelId,
    });
  };

  const setActiveSpeakerWithThreshold = () => {
    let isAnyAgoraActiveSpeaker;

    if (speakerLastSpeakedAt.current) {
      isAnyAgoraActiveSpeaker =
        speakerLastSpeakedAt.current + 5000 > new Date().getTime();
    } else {
      isAnyAgoraActiveSpeaker = false;
    }

    if (isAnyAgoraActiveSpeaker !== isAnyAgoraActiveSpeakerRef.current) {
      setIsAnyAgoraActiveSpeaker(isAnyAgoraActiveSpeaker);
    }
  };

  const resetRemoteUserAudioTracks = () => {
    const agoraConnectedChannelClients =
      agoraConnectedChannelClientsRef.current;

    const activeInterpretationOutputAgoraChannelId =
      activeInterpretationOutputAgoraChannelIdRef.current;

    const interpreterParticipantIds = interpreterParticipantIdsRef.current;

    const allParticipantsInterpreterAgoraStats =
      allParticipantsInterpreterAgoraStatsRef.current;

    const inputClient = agoraConnectedChannelClients.find(
      ({ agoraChannelId }) =>
        agoraChannelId === activeInterpretationOutputAgoraChannelId
    )?.client;

    const remoteParticipantsAudioTrack = [];

    if (inputClient) {
      for (let index = 0; index < inputClient.remoteUsers.length; index++) {
        const remoteUser = inputClient.remoteUsers[index];

        if (remoteUser.hasAudio && remoteUser.audioTrack) {
          const isJoined = allParticipantsInterpreterAgoraStats.find(
            ({
              agoraUId,
              participantId,
              micOn,
              activeInterpretationInputAgoraChannelId,
            }) =>
              agoraUId === remoteUser.uid &&
              interpreterParticipantIds.includes(participantId) &&
              activeInterpretationInputAgoraChannelId ===
                activeInterpretationOutputAgoraChannelId &&
              micOn
          );

          if (isJoined) {
            const audioTrack = remoteUser.audioTrack;

            remoteParticipantsAudioTrack.push({
              audioTrack,
              participantId: isJoined.participantId,
            });
          }
        }
      }
    }

    setRemoteParticipantsAudioTrack(remoteParticipantsAudioTrack);
  };

  const initInterval = () => {
    activeSpeakerWithThresholdInterval.current = setInterval(() => {
      setActiveSpeakerWithThreshold();

      resetRemoteUserAudioTracks();
    }, 1000);
  };

  useEffect(() => {
    initInterval();

    return () => {
      if (activeSpeakerWithThresholdInterval.current) {
        clearInterval(activeSpeakerWithThresholdInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (activeInterpretationInputAgoraChannelId) {
      _handleActiveInterpretationInputAgoraChannelIdChanged({
        activeInterpretationInputAgoraChannelId,
      });
    }
  }, [activeInterpretationInputAgoraChannelId]);

  const leaveAllConnectedClients = () => {
    const agoraConnectedChannelClients =
      agoraConnectedChannelClientsRef.current;

    for (let index = 0; index < agoraConnectedChannelClients.length; index++) {
      const { client } = agoraConnectedChannelClients[index];

      if (client) {
        client.leave();
      }
    }

    setAgoraConnectedChannelClients([]);
  };

  const { publish } = useAppSingalingPublish(
    appPubSubTopics.INTERPRETER_AGORA_STATS
  );

  useAppSingalingSubscribe(
    appPubSubTopics.INTERPRETER_AGORA_STATS,
    ({ message: receivedMessage, participantId }) => {
      const message = safeParseJson(receivedMessage);

      const { activeInterpretationInputAgoraChannelId, micOn, agoraUId } =
        message;

      const participantInterpreterAgoraStats =
        allParticipantsInterpreterAgoraStatsRef.current.find(
          ({ participantId: _participantId }) =>
            participantId === _participantId
        );

      if (participantInterpreterAgoraStats) {
        if (
          participantInterpreterAgoraStats.micOn !== micOn ||
          participantInterpreterAgoraStats.activeInterpretationInputAgoraChannelId !==
            activeInterpretationInputAgoraChannelId
        ) {
          setAllParticipantsInterpreterAgoraStats((s) => [
            ...s.filter(
              ({ participantId: _participantId }) =>
                _participantId !== participantId
            ),
            {
              micOn,
              participantId,
              activeInterpretationInputAgoraChannelId,
              agoraUId,
            },
          ]);
        }
      } else {
        setAllParticipantsInterpreterAgoraStats((s) => [
          ...s,
          {
            micOn,
            participantId,
            activeInterpretationInputAgoraChannelId,
            agoraUId,
          },
        ]);
      }
    }
  );

  const publishRef = useRef(publish);

  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  const resetInterpretationInputAgoraMicStats = () => {
    const interval = setInterval(() => {
      const agoraConnectedChannelClients =
        agoraConnectedChannelClientsRef.current;

      const activeInterpretationInputAgoraChannelId =
        activeInterpretationInputAgoraChannelIdRef.current;

      const agoraConnectedChannelInputClient =
        agoraConnectedChannelClients.find(
          ({ agoraChannelId }) =>
            agoraChannelId === activeInterpretationInputAgoraChannelId
        )?.client;

      const micTrack = agoraConnectedChannelInputClient?.localTracks?.find(
        ({ trackMediaType }) => trackMediaType === "audio"
      );

      const micOn = !!micTrack && (micTrack ? !micTrack._muted : false);
      const audioTrack = micOn ? micTrack : undefined;

      const isSame =
        interpretationInputAgoraMicStatsRef.current?.micOn === micOn &&
        audioTrack?._ID ===
          interpretationInputAgoraMicStatsRef.current?.audioTrack?._ID &&
        audioTrack?._muted ===
          interpretationInputAgoraMicStatsRef.current?.audioTrack?._muted;

      if (!isSame) {
        setInterpretationInputAgoraMicStats({ micOn, audioTrack });
      }

      if (
        audioTrack?._config?.microphoneId &&
        audioTrack?._config?.microphoneId !== selectedMicDeviceIdRef.current
      ) {
        setSelectedMicDeviceId(audioTrack?._config?.microphoneId);
      }
    }, 500);

    return interval;
  };

  const startSendingInterpreterAgoraStats = () => {
    const interval = setInterval(() => {
      const activeInterpretationInputAgoraChannelId =
        activeInterpretationInputAgoraChannelIdRef.current;

      const inputClient = agoraConnectedChannelClientsRef.current.find(
        ({ agoraChannelId }) =>
          agoraChannelId === activeInterpretationInputAgoraChannelId
      )?.client;

      const micOn = interpretationInputAgoraMicStatsRef.current?.micOn;

      publishRef.current(
        JSON.stringify({
          activeInterpretationInputAgoraChannelId,
          micOn,
          agoraUId: inputClient?._joinInfo?.apResponse?.uid,
        })
      );
    }, 5000);

    return interval;
  };

  useEffect(() => {
    const interval1 = startSendingInterpreterAgoraStats();
    const interval2 = resetInterpretationInputAgoraMicStats();

    return () => {
      leaveAllConnectedClients();

      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, []);

  const agoraInterpretations = useMemo(
    () => interpretations.filter(({ agoraChannelId }) => agoraChannelId),
    [interpretations]
  );

  return (
    <React.Fragment>
      {remoteParticipantsAudioTrack.map(({ participantId, audioTrack }) => (
        <AgoraAudioMediaStreamTrackPlayer
          audioTrack={audioTrack._mediaStreamTrack}
          key={`AgoraAudioMediaStreamTrackPlayer-${participantId}`}
        />
      ))}

      {agoraInterpretations.map(({ agoraChannelId }) => (
        <AgoraConnectedChannelClientListner
          {...{
            agoraChannelId: agoraChannelId as string,
            loadAgoraRtcSdk,
            speakerLastSpeakedAt,
          }}
        />
      ))}

      {interpretationInputAgoraMicStats.micOn &&
      interpretationInputAgoraMicStats.audioTrack ? (
        <AgoraLocalMicTrackVolumeListner
          key={`AgoraLocalMicTrackVolumeListner-${interpretationInputAgoraMicStats?.audioTrack?._ID}-${activeInterpretationInputAgoraChannelId}`}
          mediaStreamTrack={
            interpretationInputAgoraMicStats.audioTrack._mediaStreamTrack
          }
        />
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

export default AgoraInterpreterListner;
