import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";
import { askToLeaveTopicByParticipantId } from "../../utils/pubSubTopics";

const useAskToLeave = (participantId: string) => {
  const { publish } = useAppSingalingPublish(
    askToLeaveTopicByParticipantId(participantId)
  );

  const askToLeave = () => {
    publish(JSON.stringify({ leave: true }));
  };

  return { askToLeave };
};

export default useAskToLeave;
