import useIsLocalParticipantId from "../hooks/appState/useIsLocalParticipantId";
import useAppRtcLocalParticipantMediaStats from "./useAppRtcLocalParticipantMediaStats";
import useAppRtcRemoteParticipantMediaStats from "./useAppRtcRemoteParticipantMediaStats";

const useAppRtcParticipantMediaStats = (participantId: string) => {
  const { isLocal } = useIsLocalParticipantId(participantId);

  const localParticipantMediaStats = useAppRtcLocalParticipantMediaStats();
  const remoteParticipantMediaStats =
    useAppRtcRemoteParticipantMediaStats(participantId);

  return isLocal ? localParticipantMediaStats : remoteParticipantMediaStats;
};

export default useAppRtcParticipantMediaStats;
