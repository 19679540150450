import React from "react";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { safeParseJson } from "../../utils/safeParseJson";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";

const EndBroadcastListner = () => {
  const { setSessionEndedDueToTimeout } = useAppConfigContext();

  useAppSingalingSubscribe(
    appPubSubTopics.END_CALL,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { end, endedReason } = message;

      if (end) {
        if (endedReason === "ended_due_to_timeout") {
          setSessionEndedDueToTimeout(true);
        }

        appEventEmitter.emit(appEventEmitterEvents.LEAVE_FROM_SESSION, {});
      }
    }
  );

  return <React.Fragment />;
};

export default EndBroadcastListner;
