// import { usePubSub } from "@videosdk.live/react-sdk";
import createBannerFolder from "../../apis/apps/banners/create-banner-folder";
import deleteBannerFolder from "../../apis/apps/banners/delete-banner-folder";
import updateBannerFolder from "../../apis/apps/banners/update-banner-folder";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { bannerFoldersActions } from "../../listners/banners/BannerFoldersListner";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import { useAppContext } from "../../contexts/appContextDef";
import { useEffect, useRef } from "react";
import deleteBanner from "../../apis/apps/banners/delete-banner";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useBannerFolders = () => {
  const { userId } = useAppConfigContext();
  const { banners } = useAppContext();

  const bannersRef = useRef(banners);

  useEffect(() => {
    bannersRef.current = banners;
  }, [banners]);

  // const { publish } = usePubSub(appPubSubTopics.BANNER_FOLDERS);

  const { publish } = useAppSingalingPublish(appPubSubTopics.BANNER_FOLDERS);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const _createBannerFolder = async ({ name }: { name: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const { success, data } = await createBannerFolder({ name, userId });

    if (success) {
      publish(
        JSON.stringify({
          action: bannerFoldersActions.CREATE_FOLDER,
          payload: { folder: data },
        })
        // { persist: true }
      );
    }

    return data?.id;
  };

  const _deleteBannerFolder = async ({ folderId }: { folderId: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const { success } = await deleteBannerFolder({ folderId, userId });

    const bannersToBeDeleted = bannersRef.current.filter(
      ({ folderId: _folderId }) =>
        parseInt(`${_folderId}`) === parseInt(folderId)
    );

    for (let index = 0; index < bannersToBeDeleted.length; index++) {
      const bannerToBeDeleted = bannersToBeDeleted[index];

      await deleteBanner({ userId, bannerId: `${bannerToBeDeleted.id}` });
    }

    if (success) {
      publish(
        JSON.stringify({
          action: bannerFoldersActions.DELETE_FOLDER,
          payload: {
            folderId,
          },
        })
        // { persist: true }
      );
    }
  };

  const _updateBannerFolder = async ({
    folderId,
    name,
  }: {
    folderId: string;
    name: string;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    const { success, data } = await updateBannerFolder({
      folderId,
      name,
      userId,
    });

    if (success) {
      publish(
        JSON.stringify({
          action: bannerFoldersActions.UPDATE_FOLDER,
          payload: {
            folder: data,
          },
        })
        // { persist: true }
      );
    }
  };

  return {
    createBannerFolder: _createBannerFolder,
    deleteBannerFolder: _deleteBannerFolder,
    updateBannerFolder: _updateBannerFolder,
  };
};

export default useBannerFolders;
