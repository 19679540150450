import React, { useCallback, useEffect, useRef } from "react";
import { useAppContext } from "../../../contexts/appContextDef";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import { firebaseDB } from "../../../utils/firebase";
import { onChildAdded, onChildRemoved, ref } from "firebase/database";
import WordcloudListner, {
  aggregateWordcloudAnswers,
} from "./WordcloudListner";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../../utils/appEventEmitter";
import createWordcloud from "../../../apis/apps/wordclouds/create-wordcloud";
import deleteWordcloud from "../../../apis/apps/wordclouds/delete-wordcloud";
import deactivateWordcloud from "../../../apis/apps/wordclouds/deactivate-wordcloud";
import useMainViewParticipants from "../../../hooks/appState/useMainViewParticipants";
import {
  streamModes,
  streamTypes,
} from "../../appState/MainViewParticipantsListner";
import useLocalParticipantId from "../../../hooks/utils/useLocalParticipantId";
import sleep from "../../../utils/sleep";
import {
  getMSQuickstartWordclouds,
  removeMSQuickstartWordclouds,
} from "../../../apis/quickstart";

const WordcloudsListner = () => {
  const {
    wordclouds,
    setWordclouds,
    sidePanelActiveWordcloudId,
    setSidePanelActiveWordcloudId,
    setUnseenActivatedApps,
  } = useAppContext();

  const oldWordcloudDataForCopyRef = useRef<{
    listenForNew: boolean;
    oldWordcloudId: string;
    question: string;
    isActive: boolean;
    isInMainView: boolean;
  }>();
  const { localParticipantId } = useLocalParticipantId();

  const { broadcastId } = useAppConfigContext();

  const broadcastIdRef = useRef(broadcastId);
  const sidePanelActiveWordcloudIdRef = useRef(sidePanelActiveWordcloudId);
  const localParticipantIdRef = useRef(localParticipantId);

  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);

  useEffect(() => {
    sidePanelActiveWordcloudIdRef.current = sidePanelActiveWordcloudId;
  }, [sidePanelActiveWordcloudId]);
  useEffect(() => {
    localParticipantIdRef.current = localParticipantId;
  }, [localParticipantId]);

  const { addToMainViewStreams, removeFromMainViewStreams } =
    useMainViewParticipants();

  const onWordcloudCreated = async (newWordcloud: wordcloudType) => {
    const sidePanelActiveWordcloudId = sidePanelActiveWordcloudIdRef.current;

    if (oldWordcloudDataForCopyRef.current?.listenForNew) {
      await sleep(200);

      const { oldWordcloudId, isActive, isInMainView } =
        oldWordcloudDataForCopyRef.current;

      await deleteWordcloud({
        broadcastId: broadcastIdRef.current,
        wordcloudId: oldWordcloudId,
      });

      if (isActive) {
        await deactivateWordcloud({
          broadcastId: broadcastIdRef.current,
          wordcloudId: `${oldWordcloudId}`,
        });

        await sleep(200);

        // await activatePoll({
        //   broadcastId: broadcastIdRef.current,
        //   pollId: newPoll.id,
        // });
      }

      if (isInMainView) {
        removeFromMainViewStreams({
          mode: streamModes.APPPOLL,
          participantId: localParticipantIdRef.current,
          type: streamTypes.SHARE,
          pollId: oldWordcloudId,
        });

        await sleep(200);

        addToMainViewStreams({
          mode: streamModes.APPPOLL,
          participantId: localParticipantIdRef.current,
          type: streamTypes.SHARE,
          wordcloudId: `${newWordcloud.id}`,
        });
      }

      if (sidePanelActiveWordcloudId === oldWordcloudId) {
        setSidePanelActiveWordcloudId(`${newWordcloud.id}`);
      }

      oldWordcloudDataForCopyRef.current = undefined;
    }
  };

  const subscribeWordClouds = useCallback(() => {
    const wordcloudRef = ref(
      firebaseDB,
      `broadcastWordcloud/${broadcastIdRef.current}`
    );

    const unsubscribeAdded = onChildAdded(
      wordcloudRef,
      async (snapshot) => {
        const value = snapshot.val();

        const msQuickstartWordclouds = await getMSQuickstartWordclouds({
          broadcastId: broadcastIdRef.current,
        });

        if (msQuickstartWordclouds?.includes(value.id)) {
          await deleteWordcloud({
            broadcastId: broadcastIdRef.current,
            wordcloudId: value.id,
          });

          await removeMSQuickstartWordclouds({
            broadcastId: broadcastIdRef.current,
          });
        } else {
          appEventEmitter.emit(appEventEmitterEvents.WORDCLOUD_CREATED, value);

          onWordcloudCreated(value);

          setWordclouds((s) => [
            ...s,
            {
              ...value,
              selfSubmittedAnswer: null,
              answers: aggregateWordcloudAnswers(value.answers || []),
            },
          ]);

          await sleep(1000);

          if (value?.status) {
            setUnseenActivatedApps((s) => {
              const _s = [...s].filter(({ type }) => type !== "wordcloud");

              _s.push({ type: "wordcloud", id: value?.id });

              return _s;
            });
          }
        }
      },
      () => {}
    );

    const unsubscribeRemoved = onChildRemoved(
      wordcloudRef,
      (snapshot) => {
        setWordclouds((s) => {
          const index = s.findIndex(
            ({ id: _id }) => `${_id}` === `${snapshot.key}`
          );

          if (index !== -1) {
            s.splice(index, 1);
          }

          return [...s];
        });

        if (`${sidePanelActiveWordcloudIdRef.current}` === `${snapshot.key}`) {
          setSidePanelActiveWordcloudId(null);
        }
      },
      () => {}
    );

    return { unsubscribeAdded, unsubscribeRemoved };
  }, [setWordclouds, setSidePanelActiveWordcloudId, setUnseenActivatedApps]);

  useEffect(() => {
    const { unsubscribeAdded, unsubscribeRemoved } = subscribeWordClouds();

    return () => {
      unsubscribeAdded();
      unsubscribeRemoved();
    };
  }, [subscribeWordClouds]);

  const copyWordcloudAndDeleteOld = async ({
    oldWordcloudId,
    question,
    isActive,
    isInMainView,
  }: {
    oldWordcloudId: string;
    question: string;
    isActive: boolean;
    isInMainView: boolean;
  }) => {
    oldWordcloudDataForCopyRef.current = {
      listenForNew: true,
      oldWordcloudId,
      question,
      isActive,
      isInMainView,
    };

    createWordcloud({
      broadcastId: broadcastIdRef.current,
      question,
    });
  };

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.COPY_WORDCLOUD_AND_DELETE_OLD,
      copyWordcloudAndDeleteOld
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.COPY_WORDCLOUD_AND_DELETE_OLD,
        copyWordcloudAndDeleteOld
      );
    };
  }, []);

  return (
    <React.Fragment>
      {wordclouds.map((wordcloud) => (
        <WordcloudListner
          {...{ key: `wordcloud-listner-${wordcloud.id}`, id: wordcloud.id }}
        />
      ))}
    </React.Fragment>
  );
};

export default WordcloudsListner;
