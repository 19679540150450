import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../utils/appEventEmitter";

const useRtcRemoteParticipantMediaStatsActions = (participantId: string) => {
  const subscribeWebcamStream = () => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_WEBCAM_STREAM,
      { participantId }
    );
  };

  const subscribeMicStream = () => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_MIC_STREAM,
      { participantId }
    );
  };

  const subscribeScreenShareVideoStream = () => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_SCREEN_SHARE_VIDEO_STREAM,
      { participantId }
    );
  };

  const subscribeScreenShareAudioStream = () => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_SCREEN_SHARE_AUDIO_STREAM,
      { participantId }
    );
  };

  const unsubscribeWebcamStream = () => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_WEBCAM_STREAM,
      { participantId }
    );
  };

  const unsubscribeMicStream = () => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_MIC_STREAM,
      { participantId }
    );
  };

  const unsubscribeScreenShareVideoStream = () => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_SCREEN_SHARE_VIDEO_STREAM,
      { participantId }
    );
  };

  const unsubscribeScreenShareAudioStream = () => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_SCREEN_SHARE_AUDIO_STREAM,
      { participantId }
    );
  };

  const setWebcamQuality = (quality: "low" | "med" | "high") => {
    appEventEmitter.emit(appEventEmitterEvents.RTC_SET_REMOTE_WEBCAM_QUALITY, {
      participantId,
      quality,
    });
  };

  const setScreenShareVideoQuality = (quality: "low" | "med" | "high") => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_SET_REMOTE_SCREEN_SHARE_VIDEO_QUALITY,
      { participantId, quality }
    );
  };

  const subscribeInputFileVideoShareVideoStream = ({
    inputFileVideoId,
  }: {
    inputFileVideoId: string;
  }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_STREAM,
      { inputFileVideoId, participantId }
    );
  };

  const unsubscribeInputFileVideoShareVideoStream = ({
    inputFileVideoId,
  }: {
    inputFileVideoId: string;
  }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_STREAM,
      { inputFileVideoId, participantId }
    );
  };

  const subscribeInputFileVideoShareAudioStream = ({
    inputFileVideoId,
  }: {
    inputFileVideoId: string;
  }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_AUDIO_STREAM,
      { inputFileVideoId, participantId }
    );
  };

  const unsubscribeInputFileVideoShareAudioStream = ({
    inputFileVideoId,
  }: {
    inputFileVideoId: string;
  }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_INPUT_FILE_VIDEO_SHARE_AUDIO_STREAM,
      { inputFileVideoId, participantId }
    );
  };

  const setInputFileVideoShareVideoQuality = ({
    inputFileVideoId,
    quality,
  }: {
    inputFileVideoId: string;
    quality: "low" | "med" | "high";
  }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_SET_REMOTE_INPUT_FILE_VIDEO_SHARE_VIDEO_QUALITY,
      { inputFileVideoId, quality, participantId }
    );
  };

  const subscribeExtraWebcamVideoStream = ({
    extraWebcamId,
  }: {
    extraWebcamId: string;
  }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_SUBSCRIBE_REMOTE_EXTRA_WEBCAM_VIDEO_STREAM,
      { extraWebcamId, participantId }
    );
  };

  const unsubscribeExtraWebcamVideoStream = ({
    extraWebcamId,
  }: {
    extraWebcamId: string;
  }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_UNSUBSCRIBE_REMOTE_EXTRA_WEBCAM_VIDEO_STREAM,
      { extraWebcamId, participantId }
    );
  };

  const setExtraWebcamVideoQuality = ({
    extraWebcamId,
    quality,
  }: {
    extraWebcamId: string;
    quality: "low" | "med" | "high";
  }) => {
    appEventEmitter.emit(
      appEventEmitterEvents.RTC_SET_REMOTE_EXTRA_WEBCAM_VIDEO_QUALITY,
      { extraWebcamId, quality, participantId }
    );
  };

  return {
    subscribeWebcamStream,
    subscribeMicStream,
    subscribeScreenShareVideoStream,
    subscribeScreenShareAudioStream,
    unsubscribeWebcamStream,
    unsubscribeMicStream,
    unsubscribeScreenShareVideoStream,
    unsubscribeScreenShareAudioStream,
    setWebcamQuality,
    setScreenShareVideoQuality,

    subscribeInputFileVideoShareVideoStream,
    unsubscribeInputFileVideoShareVideoStream,
    subscribeInputFileVideoShareAudioStream,
    unsubscribeInputFileVideoShareAudioStream,
    setInputFileVideoShareVideoQuality,
    subscribeExtraWebcamVideoStream,
    unsubscribeExtraWebcamVideoStream,
    setExtraWebcamVideoQuality,
  };
};

export default useRtcRemoteParticipantMediaStatsActions;
