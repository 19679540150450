import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../../../contexts/appContextDef";
import useBanners from "../../../../hooks/banners/useBanners";
import { bannerTypes } from "../../../../listners/banners/ActiveBannerListner";
import { MdDelete, MdWest } from "react-icons/md";
import useActiveBanner from "../../../../hooks/banners/useActiveBanner";

export const getAppBannerId = ({
  folderId,
  id,
  type,
}: {
  folderId: string | number;
  id: string | number;
  type: string;
}) => `f-${folderId}-b-${id}-t-${type}`;

const BannerItem = ({
  folderId,
  id,
  primaryText,
  secondaryText,
  type,
}: {
  folderId: string | number;
  id: string | number;
  primaryText: string;
  secondaryText: string;
  type: string;
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { activeLowerThirtdBanner, activeTickerBanner } = useAppContext();

  const { toggleActiveLowerThird, toggleActiveTicker } = useActiveBanner();

  const { deleteBanner } = useBanners();

  const { isSelected, appBannerId } = useMemo(() => {
    const appBannerId = getAppBannerId({ folderId, id, type });

    return {
      appBannerId,
      isSelected:
        activeLowerThirtdBanner?.appBannerId === appBannerId ||
        activeTickerBanner?.appBannerId === appBannerId,
    };
  }, [folderId, id, type, activeLowerThirtdBanner, activeTickerBanner]);

  const isTicker = useMemo(() => type === bannerTypes.ticker, [type]);

  const isTickerRef = useRef(isTicker);
  const isSelectedRef = useRef(isSelected);

  useEffect(() => {
    isTickerRef.current = isTicker;
  }, [isTicker]);
  useEffect(() => {
    isSelectedRef.current = isSelected;
  }, [isSelected]);

  return (
    <div
      onClick={() => {
        if (isTickerRef.current) {
          if (isSelectedRef.current) {
            toggleActiveTicker({ enable: false });
          } else {
            toggleActiveTicker({ enable: true, bannerId: appBannerId });
          }
        } else {
          if (isSelectedRef.current) {
            toggleActiveLowerThird({ enable: false });
          } else {
            toggleActiveLowerThird({ enable: true, bannerId: appBannerId });
          }
        }
      }}
      onMouseEnter={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
      style={{
        backgroundColor: isSelected
          ? "#8696d3"
          : isMouseOver
          ? "#e8ecfd"
          : undefined,
      }}
      className="p-3 cursor-pointer relative mt-2 rounded-md"
    >
      <p className="text-md text-gray-900 font-medium whitespace-nowrap overflow-hidden text-ellipsis">
        {primaryText}
      </p>
      {isTicker ? (
        <div className="flex justify-start mt-2">
          <div className="bg-gray-400 font-bold text-sm px-2 py-1 rounded-md flex items-center fill-white text-white">
            <MdWest size={14} />
            {" Ticker "}
            <MdWest size={14} />
          </div>
        </div>
      ) : secondaryText ? (
        <p className="text-sm text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis">
          {secondaryText}
        </p>
      ) : (
        <React.Fragment />
      )}

      {isMouseOver ? (
        <div className="absolute top-0 left-0 right-0 bottom-0">
          <div className="absolute right-2 top-2">
            {/* <button className="btn btn-primary btn-xs fill-white text-white">
              <MdEdit />
            </button> */}
            <button
              className="btn btn-primary btn-xs ml-1 fill-white text-white"
              onClick={(e) => {
                e.stopPropagation();

                deleteBanner({ bannerId: id });
              }}
            >
              <MdDelete />
            </button>
          </div>
        </div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default BannerItem;
