import { egressManagerApiServerBaseUrl } from "../../utils/constants";

const stopBroadcastEgresses = async ({
  broadcastId,
}: {
  broadcastId: string;
}) => {
  try {
    const url = `${egressManagerApiServerBaseUrl}/stop-broadcast-egresses`;
    const body = JSON.stringify({
      broadcastId,
    });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default stopBroadcastEgresses;
