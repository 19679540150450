// import { usePubSub } from "@videosdk.live/react-sdk";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { activeBannerActions } from "../../listners/banners/ActiveBannerListner";
import { useEffect, useRef } from "react";
import useValidateHostCanTakeAction from "../appState/useValidateHostCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useActiveBanner = () => {
  // const { publish } = usePubSub(appPubSubTopics.ACTIVE_BANNER);

  const { publish } = useAppSingalingPublish(appPubSubTopics.ACTIVE_BANNER);

  // const publishRef = useRef<videosdkPubsubPublishType>(publish);

  const publishRef = useRef<appSingalingPublishType>(publish);

  useEffect(() => {
    publishRef.current = publish;
  }, [publish]);

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const toggleActiveLowerThird = async ({
    enable,
    bannerId,
  }: {
    enable: boolean;
    bannerId?: string;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publishRef.current(
      JSON.stringify({
        payload: { enable, bannerId },
        action: activeBannerActions.TOGGLE_ACTIVE_LOWER_THIRD,
      })
      // { persist: true }
    );
  };

  const toggleActiveTicker = async ({
    enable,
    bannerId,
  }: {
    enable: boolean;
    bannerId?: string;
  }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publishRef.current(
      JSON.stringify({
        payload: { enable, bannerId },
        action: activeBannerActions.TOGGLE_ACTIVE_TICKER,
      })
      // { persist: true }
    );
  };

  return { toggleActiveLowerThird, toggleActiveTicker };
};

export default useActiveBanner;
