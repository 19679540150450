import { createRef, useEffect, useRef, useState } from "react";
import LateralSidebar from "../lateralSidebar/LateralSidebar";
import SidePanel from "../sidePanel/SidePanel";
import {
  MdColorLens,
  MdChatBubble,
  MdApps,
  MdQuestionAnswer,
  MdPeopleAlt,
} from "react-icons/md";
import { quickstartTargetComponentClassNames } from "../quickStartTourContainer/QuickStartTourContainer";

export const getSidePanelPrivateChatMessageListMode = (id: string) =>
  `SIDE_PANEL_PRIVATE_CHAT_MESSAGES_${id}`;

export const getSidePanelPrivateChatMessageListIdFromMode = (
  mode: string | null
) => {
  if (typeof mode === "string") {
    return mode.split("_")[5];
  } else {
    return null;
  }
};

export const sidePanelModesWithTitleAndIcon = {
  PRIVATE_CHAT: {
    title: "Private Chat",
    Icon: MdChatBubble,
    showChatBadge: true,
    hostOnly: false,
    quickstartTargetComponentClassName:
      quickstartTargetComponentClassNames.private_chat_panel_button,
    showQNABadge: false,
    showParticipantBadge: false,
  },
  BRAND: {
    title: "Brand",
    Icon: MdColorLens,
    hostOnly: true,
    quickstartTargetComponentClassName:
      quickstartTargetComponentClassNames.brand_panel_button,
    showChatBadge: false,
    showQNABadge: false,
    showParticipantBadge: false,
  },
  QNA: {
    title: "Q&A",
    Icon: MdQuestionAnswer,
    showQNABadge: true,
    hostOnly: false,
    quickstartTargetComponentClassName:
      quickstartTargetComponentClassNames.qna_panel_button,
    showChatBadge: false,
    showParticipantBadge: false,
  },
  APPS: {
    title: "Apps",
    Icon: MdApps,
    hostOnly: true,
    quickstartTargetComponentClassName:
      quickstartTargetComponentClassNames.apps_section,
    showChatBadge: false,
    showQNABadge: false,
    showParticipantBadge: false,
  },
  PARTICIPANTS: {
    title: "Participants",
    Icon: MdPeopleAlt,
    hostOnly: false,
    showParticipantBadge: true,
    quickstartTargetComponentClassName:
      quickstartTargetComponentClassNames.participants_panel_button,
    showChatBadge: false,
    showQNABadge: false,
  },
};

const SideContainer = ({
  containerHeight,
  onContainerWidth,
}: {
  containerHeight: number;
  onContainerWidth?: (width: number) => void;
}) => {
  const containerRef = createRef<HTMLDivElement>();

  const [containerWidth, setContainerWidth] = useState(0);

  const containerWidthRef = useRef(containerWidth);

  useEffect(() => {
    containerWidthRef.current = containerWidth;
  }, [containerWidth]);

  useEffect(() => {
    if (containerRef.current) {
      const { current } = containerRef;

      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;

      containerWidthRef.current !== width && setContainerWidth(width);
    }
  }, [containerRef]);

  useEffect(() => {
    if (typeof onContainerWidth === "function") {
      onContainerWidth(containerWidth);
    }
  }, [containerWidth]);

  return (
    <div
      ref={containerRef}
      style={{ height: containerHeight }}
      className="flex"
    >
      <SidePanel />
      <LateralSidebar />
    </div>
  );
};

export default SideContainer;
