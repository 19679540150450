// import useValidateConnection from "./useValidateConnection";
// import {
//   appEventEmitter,
//   appEventEmitterEvents,
// } from "../../utils/appEventEmitter";

import { useEffect, useRef } from "react";
import { useAppContext } from "../../contexts/appContextDef";

const useValidateHostCanTakeAction = () => {
  const { isJoined } = useAppContext();

  const isJoinedRef = useRef(isJoined);

  useEffect(() => {
    isJoinedRef.current = isJoined;
  }, [isJoined]);

  // const { getIsSignalLevelValid } = useValidateConnection();

  const validateHostCanTakeAction = async () => {
    // const { isSignalLevelValid } = getIsSignalLevelValid();

    // // other validations comes here
    // const isHostValidToTakeAction = isSignalLevelValid;

    // if (!isHostValidToTakeAction) {
    //   appEventEmitter.emit(appEventEmitterEvents.BAD_NETWORK_TOAST, {
    //     isSignalLevelValid,
    //   });
    // }

    // return { isHostValidToTakeAction };

    return { isHostValidToTakeAction: isJoinedRef.current };
  };

  return { validateHostCanTakeAction };
};

export default useValidateHostCanTakeAction;
