// import { useParticipant } from "@videosdk.live/react-sdk";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppConfigContext } from "../../contexts/appConfigDef";
import useMainViewLayout from "../../hooks/appState/useMainViewLayout";
import {
  generateStreamId,
  streamTypes,
  streamModes,
} from "../../listners/appState/MainViewParticipantsListner";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";
import { appModes, inQueueStreamTypes } from "../../utils/constants";
// import useAppRtcParticipantMediaStats from "../../appRtc/useAppRtcParticipantMediaStats";
import useRtcRemoteParticipantMediaStatsActions from "../../appRtc/useRtcRemoteParticipantMediaStatsActions";
import useIsLocalParticipantId from "../../hooks/appState/useIsLocalParticipantId";
import useAppRtcParticipantMediaStats from "../../appRtc/useAppRtcParticipantMediaStats";
import useAppRtcRemoteParticipantMediaStats from "../../appRtc/useAppRtcRemoteParticipantMediaStats";
import { useAppContext } from "../../contexts/appContextDef";
import useAppRtcParticipantExtraWebcam from "../../appRtc/useAppRtcParticipantExtraWebcam";
import useAppRtcAllParticipantsExtraWebcamStreams from "../../appRtc/useAppRtcAllParticipantsExtraWebcamStreams";

const ParticipantWebcamStreamsListner = ({
  participantId,
  quality,
  paused,
  isOgWebcam,
  extraWebcamId,
}: {
  participantId: string;
  quality: string;
  paused: boolean;
  isOgWebcam: boolean;
  extraWebcamId?: string;
}) => {
  const { isLocal } = useIsLocalParticipantId(participantId);

  const previouslySetQuality = useRef<string>();

  const { webcamTrack } = useAppRtcParticipantMediaStats(participantId);
  const { videoTrack: extraWebcamVideoTrack } = useAppRtcParticipantExtraWebcam(
    { extraWebcamId: extraWebcamId as string, participantId }
  );
  const {
    subscribeWebcamStream,
    unsubscribeWebcamStream,
    setWebcamQuality,
    subscribeExtraWebcamVideoStream,
    unsubscribeExtraWebcamVideoStream,
    setExtraWebcamVideoQuality,
  } = useRtcRemoteParticipantMediaStatsActions(participantId);

  const qualityRef = useRef(quality);
  const pausedRef = useRef(paused);
  const isOgWebcamRef = useRef(isOgWebcam);
  const extraWebcamIdRef = useRef(extraWebcamId);
  const isLocalRef = useRef(isLocal);
  const webcamTrackRef = useRef(webcamTrack);
  const subscribeWebcamStreamRef = useRef(subscribeWebcamStream);
  const unsubscribeWebcamStreamRef = useRef(unsubscribeWebcamStream);
  const setWebcamQualityRef = useRef(setWebcamQuality);
  const subscribeExtraWebcamVideoStreamRef = useRef(
    subscribeExtraWebcamVideoStream
  );
  const unsubscribeExtraWebcamVideoStreamRef = useRef(
    unsubscribeExtraWebcamVideoStream
  );
  const setExtraWebcamVideoQualityRef = useRef(setExtraWebcamVideoQuality);
  const extraWebcamVideoTrackRef = useRef(extraWebcamVideoTrack);

  useEffect(() => {
    qualityRef.current = quality;
  }, [quality]);
  useEffect(() => {
    pausedRef.current = paused;
  }, [paused]);
  useEffect(() => {
    isOgWebcamRef.current = isOgWebcam;
  }, [isOgWebcam]);
  useEffect(() => {
    extraWebcamIdRef.current = extraWebcamId;
  }, [extraWebcamId]);
  useEffect(() => {
    isLocalRef.current = isLocal;
  }, [isLocal]);
  useEffect(() => {
    webcamTrackRef.current = webcamTrack;
  }, [webcamTrack]);
  useEffect(() => {
    subscribeWebcamStreamRef.current = subscribeWebcamStream;
  }, [subscribeWebcamStream]);
  useEffect(() => {
    unsubscribeWebcamStreamRef.current = unsubscribeWebcamStream;
  }, [unsubscribeWebcamStream]);
  useEffect(() => {
    setWebcamQualityRef.current = setWebcamQuality;
  }, [setWebcamQuality]);
  useEffect(() => {
    subscribeExtraWebcamVideoStreamRef.current =
      subscribeExtraWebcamVideoStream;
  }, [subscribeExtraWebcamVideoStream]);
  useEffect(() => {
    unsubscribeExtraWebcamVideoStreamRef.current =
      unsubscribeExtraWebcamVideoStream;
  }, [unsubscribeExtraWebcamVideoStream]);
  useEffect(() => {
    setExtraWebcamVideoQualityRef.current = setExtraWebcamVideoQuality;
  }, [setExtraWebcamVideoQuality]);
  useEffect(() => {
    extraWebcamVideoTrackRef.current = extraWebcamVideoTrack;
  }, [extraWebcamVideoTrack]);

  const adjectQualityAndStreamDownloading = () => {
    const quality = qualityRef.current;
    const paused = pausedRef.current;
    const isOgWebcam = isOgWebcamRef.current;
    const extraWebcamId = extraWebcamIdRef.current;

    if (isOgWebcam) {
      const webcamTrack = webcamTrackRef.current;
      const subscribeWebcamStream = subscribeWebcamStreamRef.current;
      const unsubscribeWebcamStream = unsubscribeWebcamStreamRef.current;
      const setWebcamQuality = setWebcamQualityRef.current;

      if (paused) {
        if (webcamTrack) {
          unsubscribeWebcamStream();
        }
      } else {
        if (!webcamTrack) {
          subscribeWebcamStream();
        }
      }

      if (previouslySetQuality.current !== quality) {
        previouslySetQuality.current = quality;

        setWebcamQuality(quality as "high" | "med" | "low");
      }
    } else if (extraWebcamId) {
      const extraWebcamVideoTrack = extraWebcamVideoTrackRef.current;
      const subscribeExtraWebcamVideoStream =
        subscribeExtraWebcamVideoStreamRef.current;
      const unsubscribeExtraWebcamVideoStream =
        unsubscribeExtraWebcamVideoStreamRef.current;
      const setExtraWebcamVideoQuality = setExtraWebcamVideoQualityRef.current;

      if (paused) {
        if (extraWebcamVideoTrack) {
          unsubscribeExtraWebcamVideoStream({ extraWebcamId });
        }
      } else {
        if (!extraWebcamVideoTrack) {
          subscribeExtraWebcamVideoStream({ extraWebcamId });
        }
      }

      if (previouslySetQuality.current !== quality) {
        previouslySetQuality.current = quality;

        setExtraWebcamVideoQuality({
          quality: quality as "high" | "med" | "low",
          extraWebcamId,
        });
      }
    }
  };

  const startInterval = () => {
    const interval = setInterval(() => {
      if (!isLocalRef.current) {
        adjectQualityAndStreamDownloading();
      }
    }, 1000);

    return interval;
  };

  useEffect(() => {
    const interval = startInterval();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <React.Fragment />;
};

const ParticipantShareStreamsListner = ({
  fileShareInputFileVideoPaused,
  participantId,
  screenShareVideoPaused,
  inputFileVideoShareStreamVideoQuality,
  screenShareVideoQuality,
  mainViewInputFileVideoId,
  unpausedInputFileVideoShareVisibleStreamIds,
}: {
  fileShareInputFileVideoPaused: boolean;
  participantId: string;
  screenShareVideoPaused: boolean;
  inputFileVideoShareStreamVideoQuality: string;
  screenShareVideoQuality: string;
  mainViewInputFileVideoId?: string;
  unpausedInputFileVideoShareVisibleStreamIds: string[];
}) => {
  const { isLocal } = useIsLocalParticipantId(participantId);
  const {
    screenShareVideoOn,
    screenShareVideoTrack,
    inputFileVideoShareStreamPublications,
  } = useAppRtcRemoteParticipantMediaStats(participantId);
  const {
    subscribeScreenShareVideoStream,
    unsubscribeScreenShareVideoStream,
    setScreenShareVideoQuality,
    unsubscribeInputFileVideoShareVideoStream,
    subscribeInputFileVideoShareVideoStream,
    setInputFileVideoShareVideoQuality,
    subscribeInputFileVideoShareAudioStream,
    unsubscribeInputFileVideoShareAudioStream,
  } = useRtcRemoteParticipantMediaStatsActions(participantId);

  const screenShareVideoPausedRef = useRef(screenShareVideoPaused);
  const screenShareVideoQualityRef = useRef(screenShareVideoQuality);
  const isLocalRef = useRef(isLocal);
  const screenShareVideoOnRef = useRef(screenShareVideoOn);
  const screenShareVideoTrackRef = useRef(screenShareVideoTrack);
  const subscribeScreenShareVideoStreamRef = useRef(
    subscribeScreenShareVideoStream
  );
  const unsubscribeScreenShareVideoStreamRef = useRef(
    unsubscribeScreenShareVideoStream
  );
  const setScreenShareVideoQualityRef = useRef(setScreenShareVideoQuality);
  const inputFileVideoShareStreamPublicationsRef = useRef(
    inputFileVideoShareStreamPublications
  );
  const fileShareInputFileVideoPausedRef = useRef(
    fileShareInputFileVideoPaused
  );
  const inputFileVideoShareStreamVideoQualityRef = useRef(
    inputFileVideoShareStreamVideoQuality
  );
  const mainViewInputFileVideoIdRef = useRef(mainViewInputFileVideoId);
  const unpausedInputFileVideoShareVisibleStreamIdsRef = useRef(
    unpausedInputFileVideoShareVisibleStreamIds
  );
  const unsubscribeInputFileVideoShareVideoStreamRef = useRef(
    unsubscribeInputFileVideoShareVideoStream
  );
  const subscribeInputFileVideoShareVideoStreamRef = useRef(
    subscribeInputFileVideoShareVideoStream
  );
  const setInputFileVideoShareVideoQualityRef = useRef(
    setInputFileVideoShareVideoQuality
  );
  const subscribeInputFileVideoShareAudioStreamRef = useRef(
    subscribeInputFileVideoShareAudioStream
  );
  const unsubscribeInputFileVideoShareAudioStreamRef = useRef(
    unsubscribeInputFileVideoShareAudioStream
  );

  useEffect(() => {
    screenShareVideoPausedRef.current = screenShareVideoPaused;
  }, [screenShareVideoPaused]);
  useEffect(() => {
    screenShareVideoQualityRef.current = screenShareVideoQuality;
  }, [screenShareVideoQuality]);
  useEffect(() => {
    isLocalRef.current = isLocal;
  }, [isLocal]);
  useEffect(() => {
    screenShareVideoOnRef.current = screenShareVideoOn;
  }, [screenShareVideoOn]);
  useEffect(() => {
    screenShareVideoTrackRef.current = screenShareVideoTrack;
  }, [screenShareVideoTrack]);
  useEffect(() => {
    subscribeScreenShareVideoStreamRef.current =
      subscribeScreenShareVideoStream;
  }, [subscribeScreenShareVideoStream]);
  useEffect(() => {
    unsubscribeScreenShareVideoStreamRef.current =
      unsubscribeScreenShareVideoStream;
  }, [unsubscribeScreenShareVideoStream]);
  useEffect(() => {
    setScreenShareVideoQualityRef.current = setScreenShareVideoQuality;
  }, [setScreenShareVideoQuality]);
  useEffect(() => {
    inputFileVideoShareStreamPublicationsRef.current =
      inputFileVideoShareStreamPublications;
  }, [inputFileVideoShareStreamPublications]);
  useEffect(() => {
    fileShareInputFileVideoPausedRef.current = fileShareInputFileVideoPaused;
  }, [fileShareInputFileVideoPaused]);
  useEffect(() => {
    inputFileVideoShareStreamVideoQualityRef.current =
      inputFileVideoShareStreamVideoQuality;
  }, [inputFileVideoShareStreamVideoQuality]);
  useEffect(() => {
    mainViewInputFileVideoIdRef.current = mainViewInputFileVideoId;
  }, [mainViewInputFileVideoId]);
  useEffect(() => {
    unpausedInputFileVideoShareVisibleStreamIdsRef.current =
      unpausedInputFileVideoShareVisibleStreamIds;
  }, [unpausedInputFileVideoShareVisibleStreamIds]);
  useEffect(() => {
    unsubscribeInputFileVideoShareVideoStreamRef.current =
      unsubscribeInputFileVideoShareVideoStream;
  }, [unsubscribeInputFileVideoShareVideoStream]);
  useEffect(() => {
    subscribeInputFileVideoShareVideoStreamRef.current =
      subscribeInputFileVideoShareVideoStream;
  }, [subscribeInputFileVideoShareVideoStream]);
  useEffect(() => {
    setInputFileVideoShareVideoQualityRef.current =
      setInputFileVideoShareVideoQuality;
  }, [setInputFileVideoShareVideoQuality]);
  useEffect(() => {
    subscribeInputFileVideoShareAudioStreamRef.current =
      subscribeInputFileVideoShareAudioStream;
  }, [subscribeInputFileVideoShareAudioStream]);
  useEffect(() => {
    unsubscribeInputFileVideoShareAudioStreamRef.current =
      unsubscribeInputFileVideoShareAudioStream;
  }, [unsubscribeInputFileVideoShareAudioStream]);

  const adjectQualityAndStreamDownloading = () => {
    const screenShareVideoPaused = screenShareVideoPausedRef.current;
    const screenShareVideoQuality = screenShareVideoQualityRef.current;
    const screenShareVideoOn = screenShareVideoOnRef.current;
    const screenShareVideoTrack = screenShareVideoTrackRef.current;
    const inputFileVideoShareStreamPublications =
      inputFileVideoShareStreamPublicationsRef.current;
    const fileShareInputFileVideoPaused =
      fileShareInputFileVideoPausedRef.current;
    const inputFileVideoShareStreamVideoQuality =
      inputFileVideoShareStreamVideoQualityRef.current;
    const mainViewInputFileVideoId = mainViewInputFileVideoIdRef.current;
    const unpausedInputFileVideoShareVisibleStreamIds =
      unpausedInputFileVideoShareVisibleStreamIdsRef.current;

    const subscribeScreenShareVideoStream =
      subscribeScreenShareVideoStreamRef.current;
    const unsubscribeScreenShareVideoStream =
      unsubscribeScreenShareVideoStreamRef.current;
    const setScreenShareVideoQuality = setScreenShareVideoQualityRef.current;
    const unsubscribeInputFileVideoShareVideoStream =
      unsubscribeInputFileVideoShareVideoStreamRef.current;
    const subscribeInputFileVideoShareVideoStream =
      subscribeInputFileVideoShareVideoStreamRef.current;
    const setInputFileVideoShareVideoQuality =
      setInputFileVideoShareVideoQualityRef.current;
    const subscribeInputFileVideoShareAudioStream =
      subscribeInputFileVideoShareAudioStreamRef.current;
    const unsubscribeInputFileVideoShareAudioStream =
      unsubscribeInputFileVideoShareAudioStreamRef.current;

    if (screenShareVideoPaused) {
      if (screenShareVideoTrack) {
        unsubscribeScreenShareVideoStream();
      }
    } else {
      if (screenShareVideoOn) {
        if (!screenShareVideoTrack) {
          subscribeScreenShareVideoStream();
        } else {
          setScreenShareVideoQuality(
            screenShareVideoQuality as "low" | "med" | "high"
          );
        }
      }
    }

    for (
      let index = 0;
      index < inputFileVideoShareStreamPublications.length;
      index++
    ) {
      const { id } = inputFileVideoShareStreamPublications[index];

      if (id === mainViewInputFileVideoId && !fileShareInputFileVideoPaused) {
        subscribeInputFileVideoShareVideoStream({ inputFileVideoId: id });

        subscribeInputFileVideoShareAudioStream({ inputFileVideoId: id });

        setInputFileVideoShareVideoQuality({
          inputFileVideoId: id,
          quality: inputFileVideoShareStreamVideoQuality as
            | "low"
            | "med"
            | "high",
        });
      } else {
        if (unpausedInputFileVideoShareVisibleStreamIds.includes(id)) {
          subscribeInputFileVideoShareVideoStream({ inputFileVideoId: id });

          setInputFileVideoShareVideoQuality({
            inputFileVideoId: id,
            quality: "low" as "low" | "med" | "high",
          });
        } else {
          unsubscribeInputFileVideoShareVideoStream({ inputFileVideoId: id });

          unsubscribeInputFileVideoShareAudioStream({ inputFileVideoId: id });
        }
      }
    }
  };

  const startInterval = () => {
    const interval = setInterval(() => {
      if (!isLocalRef.current) {
        adjectQualityAndStreamDownloading();
      }
    }, 1000);

    return interval;
  };

  useEffect(() => {
    const interval = startInterval();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <React.Fragment />;
};

const AppRemoteRtcStreamsListner = () => {
  const { isRecorder } = useAppConfigContext();
  const { appMode } = useAppContext();

  const [inQueueVisibleStreams, setInQueueVisibleStreams] = useState<
    {
      isVisible: boolean;
      participantId: string;
      streamType: string;
      inputFileVideoId?: string;
      extraWebcamId?: string;
    }[]
  >([]);

  const { participantIds } = useParticipantIdsAndCount();

  const { gridWithStreamsPosition } = useMainViewLayout();

  const { allParticipantsExtraWebcamStreams } =
    useAppRtcAllParticipantsExtraWebcamStreams();

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.INQUEUE_STREAM_VISIBLE,
      ({
        isVisible,
        participantId,
        streamType,
        inputFileVideoId,
        extraWebcamId,
      }) => {
        setInQueueVisibleStreams((s) => {
          const found = s.find(
            ({
              participantId: _participantId,
              streamType: _streamType,
              inputFileVideoId: _inputFileVideoId,
              extraWebcamId: _extraWebcamId,
            }) =>
              _participantId === participantId &&
              streamType === _streamType &&
              inputFileVideoId === _inputFileVideoId &&
              extraWebcamId === _extraWebcamId
          );

          if (found) {
            return s.map((p) => {
              const { participantId: _participantId, streamType: _streamType } =
                p;

              if (
                streamType === _streamType &&
                _participantId === participantId
              ) {
                return {
                  isVisible,
                  participantId,
                  streamType,
                  inputFileVideoId,
                  extraWebcamId,
                };
              } else {
                return p;
              }
            });
          } else {
            return [
              ...s,
              {
                isVisible,
                participantId,
                streamType,
                inputFileVideoId,
                extraWebcamId,
              },
            ];
          }
        });
      }
    );
  }, []);

  const { participantIdsInQueueVisible } = useMemo(() => {
    const participantIdsInQueueVisible: {
      participantId: string;
      quality: string;
      paused: boolean;
      isOgWebcam: boolean;
      extraWebcamId?: string;
    }[] = [];

    participantIds.forEach((participantId) => {
      //
      //
      // og webcam
      const mainViewStream_ogWebcam = gridWithStreamsPosition.find(
        ({ streamId: _streamId }) => {
          const streamId = generateStreamId({
            participantId,
            type: streamTypes.WEBCAM,
          });

          return streamId === _streamId;
        }
      );

      let quality_ogWebcam;
      let paused_ogWebcam;
      let surfaceArea_ogWebcam;

      const isVisibleInQueueStreams_ogWebcam = !!inQueueVisibleStreams.find(
        ({ participantId: _participantId, isVisible, streamType }) =>
          _participantId === participantId &&
          isVisible &&
          streamType === streamTypes.WEBCAM
      );

      if (mainViewStream_ogWebcam) {
        const { relativeHeight, relativeWidth } = mainViewStream_ogWebcam;

        surfaceArea_ogWebcam = (relativeHeight * relativeWidth) / 100;

        if (surfaceArea_ogWebcam > 0 && surfaceArea_ogWebcam <= 15) {
          quality_ogWebcam = isRecorder ? "med" : "low";
        } else if (surfaceArea_ogWebcam > 15 && surfaceArea_ogWebcam <= 50) {
          quality_ogWebcam = isRecorder ? "high" : "med";
        } else if (surfaceArea_ogWebcam > 50 && surfaceArea_ogWebcam <= 100) {
          quality_ogWebcam = "high";
        } else {
          quality_ogWebcam = "med";
        }

        paused_ogWebcam = false;
      } else {
        quality_ogWebcam = "low";

        if (isVisibleInQueueStreams_ogWebcam && appMode === appModes.HOST) {
          paused_ogWebcam = false;
        } else {
          paused_ogWebcam = true;
        }
      }

      participantIdsInQueueVisible.push({
        participantId,
        quality: quality_ogWebcam,
        paused: paused_ogWebcam,
        isOgWebcam: true,
      });

      //
      //
      // extra webcam

      const participantsExtraWebcamStreams =
        allParticipantsExtraWebcamStreams.get(participantId) || [];

      participantsExtraWebcamStreams.forEach(({ extraWebcamId }) => {
        const mainViewStream_extraWebcam = gridWithStreamsPosition.find(
          ({ streamId: _streamId }) => {
            const streamId = generateStreamId({
              participantId,
              type: streamTypes.EXTRAWBCM,
              extraWebcamId,
            });

            return streamId === _streamId;
          }
        );

        let quality_extraWebcam;
        let paused_extraWebcam;
        let surfaceArea_extraWebcam;

        const isVisibleInQueueStreams_extraWebcam =
          !!inQueueVisibleStreams.find(
            ({
              participantId: _participantId,
              isVisible,
              streamType,
              extraWebcamId: _extraWebcamId,
            }) =>
              _participantId === participantId &&
              isVisible &&
              streamType === inQueueStreamTypes.EXTRAWBCM &&
              extraWebcamId === _extraWebcamId
          );

        if (mainViewStream_extraWebcam) {
          const { relativeHeight, relativeWidth } = mainViewStream_extraWebcam;

          surfaceArea_extraWebcam = (relativeHeight * relativeWidth) / 100;

          if (surfaceArea_extraWebcam > 0 && surfaceArea_extraWebcam <= 15) {
            quality_extraWebcam = isRecorder ? "med" : "low";
          } else if (
            surfaceArea_extraWebcam > 15 &&
            surfaceArea_extraWebcam <= 50
          ) {
            quality_extraWebcam = isRecorder ? "high" : "med";
          } else if (
            surfaceArea_extraWebcam > 50 &&
            surfaceArea_extraWebcam <= 100
          ) {
            quality_extraWebcam = "high";
          } else {
            quality_extraWebcam = "med";
          }

          paused_extraWebcam = false;
        } else {
          quality_extraWebcam = "low";

          if (
            isVisibleInQueueStreams_extraWebcam &&
            appMode === appModes.HOST
          ) {
            paused_extraWebcam = false;
          } else {
            paused_extraWebcam = true;
          }
        }

        participantIdsInQueueVisible.push({
          isOgWebcam: false,
          participantId,
          paused: paused_extraWebcam,
          quality: quality_extraWebcam,
          extraWebcamId: extraWebcamId as string,
        });
      });
    });

    return { participantIdsInQueueVisible };
  }, [
    participantIds,
    isRecorder,
    inQueueVisibleStreams,
    gridWithStreamsPosition,
    appMode,
    allParticipantsExtraWebcamStreams,
  ]);

  const { screenShareAndFileShareVideoVisibleStreams } = useMemo(() => {
    const [screenShareStreamParticipantId] =
      gridWithStreamsPosition
        .find(
          ({ streamId }) =>
            streamId.includes(streamTypes.SHARE) &&
            streamId.includes(streamModes.SCREEN)
        )
        ?.streamId.split("_") || [];

    const inputFileVideoStreamId =
      gridWithStreamsPosition
        .find(
          ({ streamId }) =>
            streamId.includes(streamTypes.SHARE) &&
            streamId.includes(streamModes.INPUTFILEVIDYO)
        )
        ?.streamId.split("_") || [];

    const fileShareVideoStreamParticipantId = inputFileVideoStreamId[0];
    const inputFileVideoId = inputFileVideoStreamId[3];

    const screenShareAndFileShareVideoVisibleStreams = participantIds.map(
      (participantId) => {
        // if (participantId === fileShareVideoStreamParticipantId) {
        //   return {
        //     participantId,
        //     fileShareInputFileVideoPaused: false,
        //     inputFileVideoShareStreamVideoQuality: "high",
        //     screenShareVideoQuality: "low",
        //     screenShareVideoPaused: true,
        //     unpausedInputFileVideoShareVisibleStreamIds:[    inputFileVideoId],
        //   };
        // } else if (participantId === screenShareStreamParticipantId) {
        //   return {
        //     participantId,
        //     fileShareInputFileVideoPaused: true,
        //     screenShareVideoPaused: false,
        //     screenShareVideoQuality: "high",
        //     inputFileVideoShareStreamVideoQuality: "low",unpausedInputFileVideoShareVisibleStreamIds:[]
        //   };
        // } else {
        if (appMode === appModes.HOST) {
          const screenShareVideoPaused = !inQueueVisibleStreams.find(
            ({ participantId: _participantId, isVisible, streamType }) =>
              _participantId === participantId &&
              isVisible &&
              streamType === inQueueStreamTypes.SHARE_SCREEN
          );

          const inputFileVideoShareVisibleStreams =
            inQueueVisibleStreams.filter(
              ({ participantId: _participantId, isVisible, streamType }) =>
                _participantId === participantId &&
                isVisible &&
                streamType === inQueueStreamTypes.SHARE_INPUT_FILE_VIDEO
            );

          return {
            participantId,
            mainViewInputFileVideoId:
              fileShareVideoStreamParticipantId === participantId
                ? inputFileVideoId
                : undefined,
            fileShareInputFileVideoPaused:
              fileShareVideoStreamParticipantId !== participantId,
            screenShareVideoPaused:
              participantId === screenShareStreamParticipantId
                ? false
                : screenShareVideoPaused,
            inputFileVideoShareStreamVideoQuality:
              participantId === fileShareVideoStreamParticipantId
                ? "high"
                : "low",
            screenShareVideoQuality:
              participantId === screenShareStreamParticipantId ? "high" : "low",
            unpausedInputFileVideoShareVisibleStreamIds:
              inputFileVideoShareVisibleStreams.map(
                ({ inputFileVideoId }) => inputFileVideoId as string
              ),
          };
        } else {
          return {
            participantId,
            mainViewInputFileVideoId:
              fileShareVideoStreamParticipantId === participantId
                ? inputFileVideoId
                : undefined,
            fileShareInputFileVideoPaused:
              fileShareVideoStreamParticipantId !== participantId,
            screenShareVideoPaused:
              participantId !== screenShareStreamParticipantId,
            inputFileVideoShareStreamVideoQuality:
              participantId === fileShareVideoStreamParticipantId
                ? "high"
                : "low",
            screenShareVideoQuality:
              participantId === screenShareStreamParticipantId ? "high" : "low",
            unpausedInputFileVideoShareVisibleStreamIds: [],
          };
        }
        // }
      }
    );

    return { screenShareAndFileShareVideoVisibleStreams };
  }, [participantIds, inQueueVisibleStreams, gridWithStreamsPosition, appMode]);

  return (
    <React.Fragment>
      {participantIdsInQueueVisible.map(
        ({ participantId, quality, paused, isOgWebcam, extraWebcamId }) => (
          <ParticipantWebcamStreamsListner
            {...{
              key: `ParticipantWebcamStreamsListner-${
                isOgWebcam ? "ogWebcam" : "extraWebcam"
              }-${participantId}${isOgWebcam ? "" : `-${extraWebcamId}`}`,
              participantId,
              quality,
              paused,
              isOgWebcam,
              extraWebcamId,
            }}
          />
        )
      )}

      {screenShareAndFileShareVideoVisibleStreams.map(
        ({
          fileShareInputFileVideoPaused,
          participantId,
          screenShareVideoPaused,
          inputFileVideoShareStreamVideoQuality,
          screenShareVideoQuality,
          mainViewInputFileVideoId,
          unpausedInputFileVideoShareVisibleStreamIds,
        }) => (
          <ParticipantShareStreamsListner
            {...{
              key: `ParticipantShareStreamsListner-${participantId}`,
              fileShareInputFileVideoPaused,
              participantId,
              screenShareVideoPaused,
              inputFileVideoShareStreamVideoQuality,
              screenShareVideoQuality,
              mainViewInputFileVideoId,
              unpausedInputFileVideoShareVisibleStreamIds,
            }}
          />
        )
      )}
    </React.Fragment>
  );
};

export default AppRemoteRtcStreamsListner;
