// import { usePubSub } from "@videosdk.live/react-sdk";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { mainViewSoloModeSelectedParticipantIdActions } from "../../listners/appState/MainViewSoloModeSelectedParticipantIdListner";
import useValidateHostCanTakeAction from "./useValidateHostCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useMainViewSoloModeSelectedParticipantId = () => {
  // const { publish } = usePubSub(
  //   appPubSubTopics.MAIN_VIEW_SOLO_MODE_SELECTED_PARTICIPANT_ID
  // );
  const { publish } = useAppSingalingPublish(
    appPubSubTopics.MAIN_VIEW_SOLO_MODE_SELECTED_PARTICIPANT_ID
  );

  const { validateHostCanTakeAction } = useValidateHostCanTakeAction();

  const select = async ({ participantId }: { participantId: string }) => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: mainViewSoloModeSelectedParticipantIdActions.SELECT,
        payload: { participantId },
      })
      // { persist: true }
    );
  };

  const deselect = async () => {
    const { isHostValidToTakeAction } = await validateHostCanTakeAction();

    if (!isHostValidToTakeAction) return;

    publish(
      JSON.stringify({
        action: mainViewSoloModeSelectedParticipantIdActions.DESELECT,
      })
      // { persist: true }
    );
  };

  return { select, deselect };
};

export default useMainViewSoloModeSelectedParticipantId;
