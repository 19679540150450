import { businessPlanTypes } from "../../utils/constants";
import useActivePlanType from "./useActivePlanType";

const useIsBasicPlanActive = () => {
  const { activePlanType } = useActivePlanType();

  return {
    isBasicPlanActive: activePlanType === businessPlanTypes.basic,
  };
};

export default useIsBasicPlanActive;
