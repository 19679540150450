import { momentostreamApiServerBaseUrl } from "../../../utils/constants";

const updateBannerFolder = async ({
  userId,
  name,
  folderId,
}: {
  userId: string;
  name: string;
  folderId: string;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/${userId}/banner-folder/${folderId}`;
    const body = JSON.stringify({ name });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true, data: json.data };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default updateBannerFolder;
