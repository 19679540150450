import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  appEventEmitter,
  appEventEmitterEvents,
} from "../../utils/appEventEmitter";
import { MdAccessTime, MdClose } from "react-icons/md";
import { useAppContext } from "../../contexts/appContextDef";
import UpgradeNowLine from "../../components/UpgradeNowLine";
import useIsMobilePortrait from "../../hooks/responsive/useIsMobilePortrait";
import Modal from "../../components/Modal";
import useParticipantIdsAndCount from "../../hooks/appState/useParticipantIdsAndCount";

const BroadcastTimer = () => {
  const [totalTimeLeftInSeconds, setTotalTimeLeftInSeconds] = useState<
    number | null
  >(null);
  const [shouldBlink, setShouldBlink] = useState(false);
  const { isMobilePortrait } = useIsMobilePortrait();

  const [upgradeToRemoveTimerModalOpen, setUpgradeToRemoveTimerModalOpen] =
    useState(false);
  const { topBarHeight } = useAppContext();

  const _handleTotalTimeRemainingInSeconds = ({
    totalTimeLeftInSeconds,
  }: {
    totalTimeLeftInSeconds: number;
  }) => {
    setTotalTimeLeftInSeconds(totalTimeLeftInSeconds);
  };

  const { participantsCount } = useParticipantIdsAndCount();

  const isTimerRunning = useMemo(() => {
    return participantsCount > 1;
  }, [participantsCount]);

  useEffect(() => {
    appEventEmitter.on(
      appEventEmitterEvents.TOTAL_TIME_REMAINING_IN_SECONDS,
      _handleTotalTimeRemainingInSeconds
    );

    return () => {
      appEventEmitter.off(
        appEventEmitterEvents.TOTAL_TIME_REMAINING_IN_SECONDS,
        _handleTotalTimeRemainingInSeconds
      );
    };
  }, []);

  const mmss = useMemo(() => {
    if (totalTimeLeftInSeconds === null) {
      return "";
    } else {
      const totalTimeLeftInMilliSeconds = totalTimeLeftInSeconds * 1000;
      const mmss =
        totalTimeLeftInMilliSeconds / 1000 > 3600
          ? new Date(totalTimeLeftInMilliSeconds).toISOString().slice(11, 19)
          : new Date(totalTimeLeftInMilliSeconds).toISOString().slice(14, 19);

      return mmss;
    }
  }, [totalTimeLeftInSeconds]);

  const shouldAlert = useMemo(() => {
    if (totalTimeLeftInSeconds === null) {
      return false;
    } else {
      if (totalTimeLeftInSeconds < 300) {
        return true;
      } else {
        return false;
      }
    }
  }, [totalTimeLeftInSeconds]);

  const alertBlinkingIntervalRef = useRef<null | ReturnType<
    typeof setInterval
  >>();
  const isBlinking = useRef(false);

  useEffect(() => {
    if (shouldAlert && !isBlinking.current) {
      isBlinking.current = true;
      const interval = setInterval(() => {
        setShouldBlink((s) => !s);
      }, 1000);

      alertBlinkingIntervalRef.current = interval;
    } else if (isBlinking.current) {
      isBlinking.current = false;

      if (alertBlinkingIntervalRef.current) {
        clearInterval(alertBlinkingIntervalRef.current);
      }
    }

    return () => {
      if (alertBlinkingIntervalRef.current) {
        clearInterval(alertBlinkingIntervalRef.current);
      }
    };
  }, [shouldAlert]);
  const shouldApplyOpacityStyle = useMemo(
    () =>
      shouldAlert
        ? shouldBlink
          ? true
          : false
        : isTimerRunning
        ? false
        : true,
    [shouldAlert, shouldBlink, isTimerRunning]
  );

  return totalTimeLeftInSeconds === null ? (
    <React.Fragment />
  ) : (
    <React.Fragment>
      <div
        style={{
          transition: "opacity .2s linear",
          opacity: shouldApplyOpacityStyle ? 0.6 : 1,
        }}
        onClick={() => {
          setUpgradeToRemoveTimerModalOpen(true);
        }}
        className={`flex items-center justify-center px-2 py-0.5 border-2 ${
          shouldAlert
            ? "fill-red-600 text-red-600 border-red-600 bg-red-50"
            : "fill-primary text-primary border-primary"
        } rounded-full cursor-pointer`}
      >
        <p className="font-semibold">{mmss}</p>
        <div className="ml-1">
          <MdAccessTime size={20} />
        </div>
      </div>

      {isMobilePortrait ? (
        <React.Fragment>
          <Modal
            {...{
              onClose: () => {
                setUpgradeToRemoveTimerModalOpen(false);
              },
              onSuccess: () => {},
              successText: "title",
              cancelText: "",
              title: "",
              isOpen: upgradeToRemoveTimerModalOpen,
              setIsOpen: setUpgradeToRemoveTimerModalOpen,
              showCloseIcon: true,
              renderOnlyBody: true,
              renderBody: () => {
                return (
                  <div className="flex flex-1 w-full">
                    <UpgradeNowLine
                      preText={"Session time is limited in free plan! "}
                      postText={" to run unlimited time sessions."}
                    />
                  </div>
                );
              },
            }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {upgradeToRemoveTimerModalOpen ? (
            <div
              style={{
                position: "fixed",
                top: topBarHeight,
                zIndex: 99999,
              }}
              className={
                "p-3 bg-white right-3 border-2 border-custom-blue-900 rounded-2xl shadow-md flex items-center justify-center"
              }
            >
              <UpgradeNowLine
                preText={"Session time is limited in free plan! "}
                postText={" to run unlimited time sessions."}
              />

              <button
                onClick={() => {
                  setUpgradeToRemoveTimerModalOpen(false);
                }}
                className="btn btn-ghost btn-sm"
              >
                <MdClose />
              </button>
            </div>
          ) : (
            <React.Fragment />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BroadcastTimer;
