import { momentostreamApiServerBaseUrl } from "../../utils/constants";

const createBrand = async ({
  userId,
  name,
}: {
  userId: string;
  name: string;
}) => {
  try {
    const url = `${momentostreamApiServerBaseUrl}/create-brand`;

    const body = JSON.stringify({
      userId,
      name,
      color: "#FF2445",
      showDisplayNames: true,
    });

    const res = await fetch(url, {
      method: "POST",
      body,
      headers: { "content-type": "application/json" },
    });

    const json = await res.json();

    if (json.success) {
      return { success: true, brandId: json.data.brandId };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export default createBrand;
