import React, { useCallback, useEffect, useRef } from "react";
import { useAppConfigContext } from "../../../contexts/appConfigDef";
import { useAppContext } from "../../../contexts/appContextDef";
import {
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
} from "firebase/database";
import { firebaseDB } from "../../../utils/firebase";

export const aggregateWordcloudAnswers = (answers: { [t: string]: number }) => {
  const ans: { [t: string]: number } = {};

  if (answers) {
    Object.keys(answers).map((key) => (ans[key] = answers[key]));
  }

  return ans;
};

const WordcloudListner = ({ id }: { id: number }) => {
  const { broadcastId } = useAppConfigContext();
  const { setWordclouds, setUnseenActivatedApps } = useAppContext();

  // const broadcastId = "9cb3670de49ecf12b793465bb0782521";

  const broadcastIdRef = useRef(broadcastId);

  const idRef = useRef(id);

  useEffect(() => {
    broadcastIdRef.current = broadcastId;
  }, [broadcastId]);

  useEffect(() => {
    idRef.current = id;
  }, [id]);

  const subscribeWordCloud = useCallback(() => {
    const wordcloudRef = ref(
      firebaseDB,
      `/broadcastWordcloud/${broadcastIdRef.current}/${idRef.current}`
    );

    const unsubscribeChildAdded = onChildAdded(
      wordcloudRef,
      (snapshot) => {
        const value = snapshot.val();

        setWordclouds((s) => {
          const index = s.findIndex(({ id }) => id === idRef.current);

          if (index !== -1) {
            s[index] = {
              ...s[index],
              [`${snapshot.key}`]:
                `${snapshot.key}` === "answers"
                  ? aggregateWordcloudAnswers(value)
                  : value,
            };

            return [...s];
          } else {
            return [...s];
          }
        });
      },
      () => {}
    );

    const unsubscribeChildChanged = onChildChanged(
      wordcloudRef,
      (snapshot) => {
        const value = snapshot.val();

        const wordcloudId = idRef.current;

        setWordclouds((s) => {
          const index = s.findIndex(({ id }) => id === wordcloudId);

          if (index !== -1) {
            s[index] = {
              ...s[index],
              [`${snapshot.key}`]:
                `${snapshot.key}` === "answers"
                  ? aggregateWordcloudAnswers(value)
                  : value,
            };

            return [...s];
          } else {
            return [...s];
          }
        });

        setTimeout(() => {
          if (snapshot.key === "status") {
            setUnseenActivatedApps((s) => {
              const _s = [...s].filter(({ type }) => type !== "wordcloud");

              if (value) {
                _s.push({ type: "wordcloud", id: `${wordcloudId}` });
              }

              return _s;
            });
          }
        }, 1000);
      },
      () => {}
    );

    onChildRemoved(wordcloudRef, (snapshot) => {
      if (snapshot.key === "answers") {
        const keys = Object.keys(snapshot.val()).map((key) => key);

        setWordclouds((s) => {
          const index = s.findIndex(({ id }) => id === idRef.current);

          if (index !== -1) {
            const ans: { [t: string]: number } = {};

            Object.keys(s[index].answers).forEach((key) => {
              if (!keys.includes(key)) {
                ans[key] = s[index].answers[key];
              }
            });

            s[index] = { ...s[index], answers: ans };

            return [...s];
          } else {
            return [...s];
          }
        });
      }
    });

    return { unsubscribeChildAdded, unsubscribeChildChanged };
  }, [setWordclouds, setUnseenActivatedApps]);

  useEffect(() => {
    const { unsubscribeChildAdded, unsubscribeChildChanged } =
      subscribeWordCloud();

    return () => {
      unsubscribeChildAdded();
      unsubscribeChildChanged();
    };
  }, [subscribeWordCloud]);

  return <React.Fragment />;
};

export default WordcloudListner;
