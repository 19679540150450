import usePoll from "../../../hooks/apps/polls/usePoll";
import getTransitionStyle from "../../../utils/getTransitionStyle";

const PollDetail = ({
  relativeWidth,
  mainViewContainerWidth,
  streamingView,
  id,
}: {
  relativeWidth?: number;
  mainViewContainerWidth?: number;
  streamingView?: boolean;
  id: string | number;
}) => {
  const { options, question } = usePoll({ id: `${id}` });

  return (
    <div className="mt-2">
      <div>
        <p
          style={{
            fontSize:
              streamingView && relativeWidth && mainViewContainerWidth
                ? relativeWidth * mainViewContainerWidth * 0.0004
                : undefined,
            transition: getTransitionStyle(["font-size"]),
          }}
          className={`${streamingView ? "" : "text-xl"} font-extrabold`}
        >
          {question}
        </p>
      </div>
      <div className="mt-4">
        {options.map((_option) => {
          const { option, percentage: _percentage, voteCount, id } = _option;

          const percentage = parseInt(`${_percentage}`);

          const progressPercentageMaxWidth = 70;

          return (
            <div key={`poll-option-${id}`} className="mt-2">
              <p
                style={{
                  fontSize:
                    streamingView && relativeWidth && mainViewContainerWidth
                      ? relativeWidth * mainViewContainerWidth * 0.00025
                      : undefined,
                  transition: getTransitionStyle(["font-size"]),
                }}
                className="font-medium"
              >
                {option}
              </p>
              <div className="flex items-center">
                <div
                  className="flex items-center bg-primary rounded-full bg-opacity-30"
                  style={{ width: `${progressPercentageMaxWidth}%`, height: 6 }}
                >
                  <div
                    className="h-full bg-primary rounded-full"
                    style={{
                      width: `${percentage}%`,
                      transition: "width 0.2s",
                    }}
                  ></div>
                </div>

                <div style={{ width: "2%" }}></div>

                <div
                  className={`font-medium`}
                  style={{
                    fontSize:
                      streamingView && relativeWidth && mainViewContainerWidth
                        ? relativeWidth * mainViewContainerWidth * 0.00025
                        : undefined,
                    width: `${100 - (progressPercentageMaxWidth + 2)}%`,
                  }}
                >{`${percentage}% ${
                  streamingView
                    ? ""
                    : `(${parseInt(`${voteCount}`).toLocaleString()})`
                }`}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PollDetail;
