// import { usePubSub } from "@videosdk.live/react-sdk";
import React from "react";
import { appPubSubTopics } from "../../utils/pubSubTopics";
import { useAppContext } from "../../contexts/appContextDef";
// import groupBy from "lodash.groupby";
import { safeParseJson } from "../../utils/safeParseJson";
import useAppSingalingSubscribe from "../../appSingaling/useAppSingalingSubscribe";

const AllParticipantsNetworkSignalLevelListner = () => {
  const { setAllParticipantsNetworkSignalLevel } = useAppContext();

  useAppSingalingSubscribe(
    appPubSubTopics.ALL_PARTICIPANTS_NETWORK_SIGNAL_LEVEL,
    ({ message: receivedMessage }) => {
      const message = safeParseJson(receivedMessage);

      const { participantId, signalLevel } = message;

      setAllParticipantsNetworkSignalLevel((s) => {
        const _s = new Map(s);

        _s.set(participantId, signalLevel);

        return _s;
      });
    }
  );

  return <React.Fragment />;
};

export default AllParticipantsNetworkSignalLevelListner;
