import { useEffect, useMemo, useRef, useState } from "react";
// import { usePubSub } from "@videosdk.live/react-sdk";
import { videoShareStreamPubSubActions } from "../../listners/streams/AllVideoShareStreamListner";
import { useAppContext } from "../../contexts/appContextDef";
import { getVideoStreamTopicById } from "../../utils/pubSubTopics";
import useValidateSpeakerCanTakeAction from "../appState/useValidateSpeakerCanTakeAction";
import useAppSingalingPublish from "../../appSingaling/useAppSingalingPublish";

const useVideoShareStream = ({ videoId }: { videoId: string }) => {
  const {
    activeVideoShareStreamAllParticipants: activeVideoShareStreams,
    allVideoShareStreamStates,
  } = useAppContext();

  const currentVideoStream = useMemo(() => {
    const currentVideoStream = activeVideoShareStreams.find(
      ({ id }) => id === videoId
    );

    return currentVideoStream;
  }, [activeVideoShareStreams, videoId]);

  const { _height, _width, _id, _durationInSec } = useMemo(() => {
    const _height = currentVideoStream?.height;
    const _width = currentVideoStream?.width;
    const _id = currentVideoStream?.id;

    const _durationInSec = currentVideoStream?.durationInSec;

    return {
      _height,
      _width,
      _id,
      _durationInSec,
    };
  }, [currentVideoStream]);

  const {
    _playing,
    _latestSeekData_seekToSec,
    _latestSeekData_timestamp,
    _volume,
    _playingStateChangedTimestamp,
  } = useMemo(() => {
    const currentVideoStream = allVideoShareStreamStates.find(
      ({ id }) => id === videoId
    );

    const _playing = currentVideoStream?.playing;
    const _latestSeekData_seekToSec =
      currentVideoStream?.latestSeekData?.seekToSec;
    const _latestSeekData_timestamp =
      currentVideoStream?.latestSeekData?.timestamp;
    const _volume = currentVideoStream?.volume;
    const _playingStateChangedTimestamp =
      currentVideoStream?.playingStateChangedTimestamp;

    return {
      _playing,
      _latestSeekData_seekToSec,
      _latestSeekData_timestamp,
      _volume,
      _playingStateChangedTimestamp,
    };
  }, [allVideoShareStreamStates, videoId]);

  //
  const _playingRef = useRef(_playing);
  const _latestSeekData_seekToSecRef = useRef(_latestSeekData_seekToSec);
  const _latestSeekData_timestampRef = useRef(_latestSeekData_timestamp);
  const _volumeRef = useRef(_volume);

  //
  useEffect(() => {
    _playingRef.current = _playing;
  }, [_playing]);
  useEffect(() => {
    _latestSeekData_seekToSecRef.current = _latestSeekData_seekToSec;
  }, [_latestSeekData_seekToSec]);
  useEffect(() => {
    _latestSeekData_timestampRef.current = _latestSeekData_timestamp;
  }, [_latestSeekData_timestamp]);
  useEffect(() => {
    _volumeRef.current = _volume;
  }, [_volume]);

  const [remoteUrl, setRemoteUrl] = useState<string | null>();

  const remoteUrlRef = useRef(remoteUrl);

  useEffect(() => {
    remoteUrlRef.current = remoteUrl;
  }, [remoteUrl]);

  useEffect(() => {
    const _remoteUrl = activeVideoShareStreams.find(
      ({ id }) => id === videoId
    )?.remoteUrl;

    if (!remoteUrlRef.current && _remoteUrl) {
      setRemoteUrl(_remoteUrl);
    }
  }, [activeVideoShareStreams, videoId]);

  // const { publish: videoStreamPublish } = usePubSub(
  //   getVideoStreamTopicById(videoId)
  // );

  const { publish: videoStreamPublish } = useAppSingalingPublish(
    getVideoStreamTopicById(videoId)
  );

  const { validateSpeakerCanTakeAction } = useValidateSpeakerCanTakeAction();

  const playAndSeek = async (args?: { seekToSec: number }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    videoStreamPublish(
      JSON.stringify({
        action: videoShareStreamPubSubActions.PLAYING_AND_SEEK_TO,
        payload: { playing: true, seekToSec: args?.seekToSec },
      })
      // { persist: true }
    );
  };

  const pauseAndSeek = async (args?: { seekToSec: number }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    videoStreamPublish(
      JSON.stringify({
        action: videoShareStreamPubSubActions.PLAYING_AND_SEEK_TO,
        payload: { playing: false, seekToSec: args?.seekToSec },
      })
      // { persist: true }
    );
  };

  const togglePlayingAndSeek = async (args?: { seekToSec: number }) => {
    if (_playingRef.current) {
      await pauseAndSeek(args);
    } else {
      await playAndSeek(args);
    }
  };

  const changeVolume = async ({ volume }: { volume: number }) => {
    const { isSpeakerValidToTakeAction } = await validateSpeakerCanTakeAction();

    if (!isSpeakerValidToTakeAction) return;

    videoStreamPublish(
      JSON.stringify({
        action: videoShareStreamPubSubActions.VOLUME_CHANGED,
        payload: { volume },
      })
      // { persist: true }
    );
  };

  return {
    playAndSeek,
    pauseAndSeek,
    togglePlayingAndSeek,
    //
    changeVolume,
    //
    latestSeekData: {
      timestamp: _latestSeekData_timestamp,
      seekToSec: _latestSeekData_seekToSec,
    },
    playing: _playing,
    volume: _volume,
    remoteUrl,
    height: _height,
    width: _width,
    durationInSec: _durationInSec,
    id: _id,
    latestSeekDataIimestamp: _latestSeekData_timestamp,
    latestSeekDataSeekToSec: _latestSeekData_seekToSec,
    playingStateChangedTimestamp: _playingStateChangedTimestamp,
  };
};

export default useVideoShareStream;
